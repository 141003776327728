import React, { Component } from "react";
import { FlexboxGrid, Col } from "rsuite";

import { Collapse } from "antd";
import { observer } from "mobx-react";
const { Panel } = Collapse;

class ClientSection extends Component {
  render() {
    let data = this.props.data;
    return (
      <div>
        <Collapse>
          <Panel
            header={
              <>
                <b>Client Details: </b>
                {data.name}
              </>
            }
            key="1"
          >
            <div style={{ fontSize: 12 }}>
              <FlexboxGrid justify="space-around" align="top">
                <Col md={8} sm={12} xs={24}>
                  <p>
                    <b>Name: </b>
                    {data.name}
                    <br />
                    <b>Type: </b>
                    {data.type}
                    <br />
                    <b>Heard of Us: </b>
                    {data.heardofus}
                  </p>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <p>
                    <b>Phone 1: </b>
                    {data.phone}
                    <br />
                    <b>Phone 2: </b>
                    {data.phone2}
                    <br />
                    <b>Email: </b>
                    {data.email}
                  </p>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <p>
                    <b>Lead Type: </b>
                    {data.leadtype}
                    <br />
                    <b>Remarks: </b>
                    {data.remarks}
                  </p>
                </Col>
              </FlexboxGrid>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default observer(ClientSection);
