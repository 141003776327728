import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
  LoadPanel,
  Pager,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { Tooltip } from "@material-ui/core";
import warehouseSummaryStore from "../../../../Stores/Financial/WarehouseSummaryStore";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import Col2 from "../../../../Components/Columns/Col2";
import Col4 from "../../../../Components/Columns/Col4";
import { Statistic } from "antd";

class StorageChargesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.StatusCell = this.StatusCell.bind(this);
    this.DelChg = this.DelChg.bind(this);
    this.StoChg = this.StoChg.bind(this);
    this.TotalChg = this.TotalChg.bind(this);
    this.GoToClient = this.GoToClient.bind(this);
  }

  componentDidMount() {}

  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending") {
      color = "orange";
    } else if (status === "Expired" || status === "Cancelled") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }
  DelChg(cellData) {
    if (cellData.data.deltotalchg === 0) return "-";
    return PRKD(cellData.data.deltotalchg);
  }
  StoChg(cellData) {
    if (cellData.data.storchg === 0) return "-";
    return PRKD(cellData.data.storchg);
  }
  TotalChg(cellData) {
    if (cellData.data.totalchg === 0) return "-";
    return PRKD(cellData.data.totalchg);
  }
  GoToClient(cellData) {
    return (
      <IconButton
        icon={<Icon icon="cube" />}
        onClick={() =>
          this.props.history.push(`/ac/dashboard/${cellData.data._id}`)
        }
        circle
        color="green"
        size="xs"
      />
    );
  }

  render() {
    if (!warehouseSummaryStore.loaded.clients) {
      return (
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              loading={warehouseSummaryStore.loading.clients}
              icon={<Icon icon="refresh" />}
              color="green"
              block
              onClick={() => warehouseSummaryStore.getWarehouseData(true)}
            >
              Load Data
            </IconButton>
          </Col2>
        </FlexboxGrid>
      );
    }
    let stats = warehouseSummaryStore.stats;
    let clients = warehouseSummaryStore.clients;
    return (
      <div>
        <LoadBack loading={warehouseSummaryStore.loading.clients}>
          <FlexboxGrid justify="space-around">
            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Clients"
                  value={clients.length}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>
            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Storage Charges"
                  value={PRKD(stats.clients.storage)}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>
            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Delivery Charges"
                  value={PRKD(stats.clients.delivery)}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>

            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Total Charges"
                  value={PRKD(stats.clients.total)}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>
          </FlexboxGrid>
          <DataGrid
            dataSource={warehouseSummaryStore.clients}
            keyExpr="clientNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
            defaultPaging={10}
          >
            <LoadPanel enabled={true} />
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[20, 50, 100]}
              showNavigationButtons={true}
            />

            <Column
              dataField="clientNumId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="name"
              dataType="date"
              caption="Client"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={120}
            />
            <Column
              dataField="status"
              caption="Status"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
              cellRender={this.StatusCell}
            />
            <Column
              dataField="startdate"
              dataType="date"
              caption="Start Date"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
            />
            <Column
              dataField="enddate"
              dataType="date"
              caption="End Date"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
            />
            <Column
              dataField="totalcbm"
              dataType="text"
              caption="CBM Today"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              width={80}
            />
            <Column caption="Charges Details" alignment={"center"}>
              <Column
                dataField="cbmstart"
                dataType="text"
                caption="Start CBM"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                width={80}
              />
              <Column
                dataField="cbmend"
                dataType="text"
                caption="End CBM"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                width={80}
              />
              <Column
                dataField="storchg"
                dataType="text"
                caption="Storage Charges"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={120}
                cellRender={this.StoChg}
              />
              <Column
                dataField="deltotalchg"
                dataType="text"
                caption="Del Charges"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={120}
                cellRender={this.DelChg}
              />
              <Column
                dataField="totalchg"
                dataType="text"
                caption="All Charges"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={120}
                sortOrder="desc"
                cellRender={this.TotalChg}
              />
            </Column>

            <Column
              caption="Go To Client"
              alignment="center"
              width={80}
              cellRender={this.GoToClient}
            />

            <Summary>
              <TotalItem
                column="name"
                summaryType="count"
                displayFormat={"{0} Clients"}
              />
              <TotalItem
                column="cbmstart"
                summaryType="sum"
                valueFormat={"#,##0"}
                displayFormat={"{0}"}
              />
              <TotalItem
                column="cbmend"
                summaryType="sum"
                valueFormat={"#,##0"}
                displayFormat={"{0}"}
              />
              <TotalItem
                column="storchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="deltotalchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="totalchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Toolbar>
              <Item name="Refresh" location="before">
                <Button
                  icon="refresh"
                  onClick={() => warehouseSummaryStore.getWarehouseData(true)}
                />
              </Item>

              <Tooltip title={`Choose Columns`} arrow placement="left">
                <Item name="columnChooserButton" />
              </Tooltip>
              <Item name="exportButton" />
            </Toolbar>
            <Export enabled={true} />
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(StorageChargesSummary));
