import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text4: {
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
});

class TermsAndConditions extends Component {
  render() {
    return (
      <View>
        <Text style={styles.header} break>
          Terms and Conditions
        </Text>
        <Text style={styles.text2}>
          Following are the Term and Conditions for relocation services, between
          the owner / shipper of the goods named in this documentation and Easy
          Move Co. Hawally Kuwait.
        </Text>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flexDirection: "column", width: "45%" }}>
            <Text style={styles.text2}>
              1. Customer's responsibility during removal
            </Text>
            <Text style={styles.text4}>
              {" "}
              a) It will be the customer's sole responsibility to ensure that
              nothing is taken away in error or left behind.
            </Text>
            <Text style={styles.text4}>
              {" "}
              b) The customer will obtain at his own expense all documents and
              permissions necessary to for the removal being completed.
            </Text>
            <Text style={styles.text4}>
              {" "}
              c) The customer will take responsibility for the security of his
              goods at the departure and destination points by being present
              himself or arranging for someone else to represent him.
            </Text>
            <Text style={styles.text4}>
              {" "}
              d) The customer must adequately prepare and stabilize all
              appliances and electronic equipment prior to their removal.
            </Text>
            <Text style={styles.text4}>
              {" "}
              e) The customer must arrange and pay for any necessary parking
              facilities for the move van.
            </Text>
            <Text style={styles.text4}>
              {" "}
              f) The customer must ensure that there is adequate and suitable
              access for Easy Move Freight vehicles.
            </Text>
            <Text style={styles.text4}>
              Easy Move Freight will not be liable for any loss or damage, costs
              or additional harges that may arise from any of these matters.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>2. Excluded Goods</Text>
            <Text style={styles.text4}>
              {" "}
              The following items are specifically excluded from this contract
              and if you ask us to move, store or ship them, we do not accept
              any responsibility for loss or damage;
            </Text>
            <Text style={styles.text4}>
              {" "}
              a) Jewelry, items of precious nature.
            </Text>
            <Text style={styles.text4}>
              {" "}
              b) Potentially dangerous, damaging or explosive items.
            </Text>
            <Text style={styles.text4}>
              {" "}
              c) Goods which are legally prohibited, either in the country of
              origin or destination. It is the customer's responsibility to
              identify and exclude such prohibited goods.
            </Text>
            <Text style={styles.text4}>
              {" "}
              d) Goods likely to encourage vermin or other pests or to cause
              infection.
            </Text>
            <Text style={styles.text4}>
              {" "}
              e) Refrigerated or frozen food or drink.
            </Text>
            <Text style={styles.text4}>
              {" "}
              f) Any plants and animals, including pets.
            </Text>
            <Text style={styles.text4}>
              {" "}
              g) Computer / electronic equipment and any data storage devices.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>3. Company's Liability</Text>
            <Text style={styles.text4}>
              {" "}
              The company's liability shall be limited to value of actual loss
              or damage to a maximum of 60 fils per kg. We shall not be liable
              for loss or damage resulting from:
            </Text>
            <Text style={styles.text4}>
              {" "}
              a) War, invasion, strikes, acts of foreign enemies or other such
              events outside our reasonable control.
            </Text>
            <Text style={styles.text4}>
              {" "}
              b) Normal wear and tear, leakage's or evaporation, atmospheric or
              climatic changes.
            </Text>
            <Text style={styles.text4}>
              {" "}
              c) Any damage to goods which are not packed / unpacked by us.
            </Text>
            <Text style={styles.text4}>
              {" "}
              All vehicles including boats and car are to be insured failing
              which the company shall not be liable for any loss/damage to them.
            </Text>
            <Text style={styles.text4}>
              {" "}
              The customer may elect to complete a valued inventory of his goods
              and purchase All Risk Insurance in which event coverage will
              increase to the values so purchased and the terms and conditions
              of the insurance policy will apply.
            </Text>
          </View>
          <View style={{ flexDirection: "column", width: "10%" }}>
            <Text> </Text>
          </View>
          <View style={{ flexDirection: "column", width: "45%" }}>
            <Text style={styles.text2}>4. Jurisdiction</Text>
            <Text style={styles.text4}>
              {" "}
              This contract is subject to the laws of Kuwait.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>5. Disputes</Text>
            <Text style={styles.text4}>
              {" "}
              If there is a dispute arising from this Agreement, which cannot be
              resolved, either party may refer it to judicial arbitration under
              Ministerial Resolution No. 43/1995.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>6. Claims</Text>
            <Text style={styles.text4}>
              {" "}
              All claims for loss or damage to the goods should be submitted to
              the Company. Initial written notification of such loss or damage,
              must be reported within 3 days of delivery of the goods. A full
              written report, setting out the details of the claim, including
              estimates of the amount claimed must be submitted to the Company
              within 45 days of full delivery. In the absence of these
              notitifations the customer hereby waives and agrees to waive all
              claims for loss or damage.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>7. Your address while in store</Text>
            <Text style={styles.text4}>
              {" "}
              The customer will at all times keep the company informed in
              writing of his current address.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>8. Inventory</Text>
            <Text style={styles.text4}>
              {" "}
              When we prepare and inventory and present or send it to you it
              will be accepted as a conclusive and accurate list of property
              received unless, within 7 days of receiving it, the company is
              notified in writing of any error/omission.
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.text2}>9. Storage Contracts</Text>
            <Text style={styles.text4}>
              {" "}
              a. The company is entitled to revise charges from time to time and
              will give the customer at least 28 days notice of any charges
              which will not take effect until the beginning of the next
              chargeable period of storage.
            </Text>
            <Text style={styles.text4}>
              {" "}
              b. Charges are billed and are payable 3 months in advance. If the
              goods are removed from storage before the expirey of the 3 month
              period the customer's account will be credited for the un-expired
              portion of the period, but not for periods of less than one
              complete week.
            </Text>
            <Text style={styles.text4}>
              {" "}
              The company shall have a general lien on any and all property
              deposited with it. In the event that storage and other related
              charges are not paid when due, and after the required notice to
              the owner and public announcement, the goods shall be sold at
              public auction as required by law, with the proceeds going first
              to pay accrued storage and related charges and the expenses
              incurred in the sale of the goods.
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default observer(TermsAndConditions);
