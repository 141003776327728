import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Visibility from "@material-ui/icons/Visibility";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }

  render() {
    let data = this.props.data;
    let err = this.props.err;
    let errmessage = this.props.errmessage;
    let type = this.props.type;
    let label = this.props.label;
    let disabled = this.props.disabled;

    let autofocus = this.props.autofocus;

    return (
      <div>
        <TextField
          error={err}
          helperText={err && errmessage}
          variant="standard"
          margin="normal"
          fullWidth
          id={type}
          label={label}
          name={type}
          autoComplete={type}
          disabled={disabled}
          autoFocus={autofocus}
          type={this.state.showPassword ? "text" : "password"}
          value={data}
          onChange={(e) => this.props.action(type, e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKeyOutlinedIcon
                  style={{
                    color: data.length >= 6 ? "black" : "#a3a3a3",
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle Password Visiblity"
                  onMouseOver={() => this.setState({ showPassword: true })}
                  onMouseLeave={() => this.setState({ showPassword: false })}
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

export default withRouter(observer(PasswordInput));
