import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newAimStore from "../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import SingleAIMOrderHeader from "./SingleAIMOrderHeader";
import userStore from "../../../../Stores/UserStore";
import { message, Tabs } from "antd";
import { Notification } from "rsuite";
import CostSummaryTable from "../Modals/Supporting/CostSummaryTable";
import JobDetails from "./Tabs/JobDetails";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import IMCharges from "./Tabs/IMCharges";
import IMTrack from "./Tabs/IMTrack";
import IMQuotes from "./Tabs/IMQuotes";
import ItemTab from "./Tabs/ItemTab";
import AddAttTemplate from "./Tabs/AddAttTemplate";
import InvNotes from "./Tabs/InvNotes";
import Services from "./Tabs/Services";
import AdditionalCharge from "./Tabs/AdditionalCharge";
import Discounts from "./Tabs/Discounts";

const instance = axios.create({ baseURL: baseURL });

const { TabPane } = Tabs;
class SingleAIMOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      showApprove: false,
      showReject: false,
      load: { direction: false },
      tab: "Summary",
      errmsgopen: false,
    };

    this.postChanges = this.postChanges.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.clientEditVal = this.clientEditVal.bind(this);
    this.changeST = this.changeST.bind(this);
    this.cancelJob = this.cancelJob.bind(this);
  }
  onHide() {
    this.setState({
      showApprove: false,
      showReject: false,
      showCancel: false,
      load: false,
    });
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  clientEditVal(val, label) {
    if (!newAimStore.stopedit) {
      newAimStore.editOrder(val, label);
    } else {
      if (!this.state.errmsgopen) {
        this.setState({ errmsgopen: true });
        message
          .warning("Please contact our office to make modifications.")
          .then(() => this.setState({ errmsgopen: false }));
      }
    }
  }
  editVal(val, label) {
    if (userStore.user.staffaccount) {
      newAimStore.editOrder(val, label);
    } else {
      if (!this.state.errmsgopen) {
        this.setState({ errmsgopen: true });
        message
          .warning("Please contact our office to make modifications.")
          .then(() => this.setState({ errmsgopen: false }));
      }
    }
  }

  cancelJob = async (free, type) => {};
  postChanges = async (label, title, index) => {
    let data = { [label]: newAimStore.newaim[label] };
    let reloadpage = false;

    this.setState({ load: { [label]: true } });
    await instance
      .put(`ac/aimorder/edit/${this.props.match.params.aimorderid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.updateOrgOrder(label);

        message.success(`Order Updated (${title})`);
        if (reloadpage) {
          newAimStore.getSingleACOrder(false, true);
        }
        this.setState({ load: { [label]: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  componentDidMount() {
    newAimStore.getSingleACOrder(this.props.match.params.aimorderid);
  }

  render() {
    let staffButs;
    let aim = newAimStore.oldaim;
    let load = this.state.load;

    return (
      <LoadBack loading={newAimStore.loading.newaim}>
        <SingleAIMOrderHeader changeST={this.changeST} />
        {userStore.user.staffaccount && staffButs}

        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Cost Summary"} key={"Summary"}>
              <CostSummaryTable aim={aim} />
            </TabPane>
            <TabPane tab={"Job Details"} key={"Job Details"}>
              <JobDetails
                editVal={this.editVal}
                clientEditVal={this.clientEditVal}
                postChanges={this.postChanges}
                load={load}
              />
            </TabPane>
            <TabPane tab={"Services"} key={"Services"}>
              <Services />
              <AdditionalCharge />
              <Discounts />
            </TabPane>
            {userStore.user.staffaccount && (
              <TabPane tab={"IM Charges"} key={"IM Charges"}>
                <IMCharges
                  editVal={this.editVal}
                  postChanges={this.postChanges}
                  load={load}
                />
              </TabPane>
            )}
            <TabPane tab={"Tracking"} key={"Tracking"}>
              <IMTrack />
            </TabPane>
            {userStore.user.staffaccount && (
              <TabPane tab={"IM Quotes"} key={"IM Quotes"}>
                <IMQuotes />
              </TabPane>
            )}
            <TabPane tab={"Items List"} key={"Items List"}>
              <ItemTab />
            </TabPane>
            <TabPane tab={"Documents"} key={"Documents"}>
              <AddAttTemplate attType={"Additional"} />
            </TabPane>

            <TabPane tab={"Inv. Notes"} key={"Inv. Notes"}>
              <InvNotes
                load={load.invnotes}
                editVal={this.editVal}
                updateVal={this.postChanges}
              />
            </TabPane>
          </Tabs>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(SingleAIMOrderPage));
