import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol80white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "80%",
  },
  tableCol80blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "80%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  subheader: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
    textAlign: "left",
    color: "black",
    fontWeight: "extrabold",
  },
});

class PackingListRow extends Component {
  render() {
    let job = this.props.job;
    let items = job.items;
    let output = <View />;

    output = items.map((item, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol80white}>{item.itemname}</Text>
        <Text style={styles.tableCol20white}>{item.qty}</Text>
      </View>
    ));

    return (
      <View>
        <Text style={styles.tableHeaderStyle}>
          {job.jobtype}-{job.numId} Items List ({job.total} Items)
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol80blue}>Items ({items.length})</Text>
          <Text style={styles.tableCol20blue}>Quantity ({job.total})</Text>
        </View>
        {output}
      </View>
    );
  }
}

export default observer(PackingListRow);
