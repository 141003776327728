import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import { PRKD, Parsed } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class WHMSinglePalletStore {
  constructor() {
    this.company = { _id: "" };
    this.pallet = { _id: "" };
    this.palletitems = [];
    this.loading = {
      pallet: false,
    };
    this.loaded = {
      pallet: false,
    };

    makeObservable(this, {
      pallet: observable,
      company: observable,
      palletitems: observable,
      loading: observable,
      loaded: observable,
      palletstats: computed,
    });
  }

  // FETCH
  getPallet(palletid, override) {
    let loaded;
    if (this.loading.pallet || this.loaded.pallet) loaded = true;
    if (override) loaded = false;
    if (palletid) {
      if (palletid !== this.pallet._id) {
        loaded = false;
      }
    } else {
      palletid = this.pallet._id;
    }
    if (!loaded) {
      this.loading.pallet = true;
      return instance
        .get(`/whm/palletitem/singlepallet/${palletid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.pallet = data;
          this.palletitems = data.itempallet;
          this.loading.pallet = false;
          this.loaded.pallet = true;
          console.log("WHM Single Pallet Fetcehed");
        })
        .catch((err) => {
          this.loading.pallet = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get palletstats() {
    let totalcbm = 0;
    let totalqty = 0;
    let totalitems = this.palletitems.length;

    for (let i = 0; i < this.palletitems.length; i++) {
      totalcbm += Parsed(this.palletitems[i].totalcbm);
      totalqty += Parsed(this.palletitems[i].quantity);
    }
    return {
      cbm: PRKD(totalcbm),
      qty: PRKD(totalqty),
      items: PRKD(totalitems),
    };
  }
}

const whmSinglePalletStore = new WHMSinglePalletStore();
export default whmSinglePalletStore;
