import { computed, makeObservable, observable } from "mobx";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import axios from "axios";
const instance = axios.create({ baseURL: baseURL });

class NewAIMStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.newaim = {
      status: "Requested",
      subtype: "Door to Door",
      remarks: "",
      excservices: [],
      incservices: [],
      invnotes: "",
      direction: "Inbound",
      scac: "No Tracking",
      blnumber: "",

      cppack: 0,
      cpouttrans: 0,
      cpexport: 0,
      cpship: 0,
      cpdest: 0,
      cpunpack: 0,
      cpintrans: 0,
      cpimport: 0,
      cpministry: 0,
      cpinspect: 0,

      commodity: "General Cargo",
      shippingmethod: "Sea",
      containertype: "",
      shipfrom: "",
      shipto: "",
      length: 0,
      width: 0,
      height: 0,
      weight: 0,

      // Section 2
      choriginship: 0, // Origin + Shipping
      chinsurance: 0, // Insurance
      chtransport: 0, // Transporation
      chclearance: 0, // Global Clearance
      chbayan: 0, // Bayan
      chothersec2: 0, // Other Section 2

      // Section 3
      chship: 0, // Shipping Line
      chdthc: 0, // DTHC

      // Section 4
      chimport: 0, // Import Customs
      chcustom: 0, // Customs Duty
      chpai: 0, // PAI
      chepa: 0, // EPA
      chrelease: 0, // Container Release
      chdemurrage: 0, // Demurrage Charges
      chdetention: 0, // Detention Charges
      chport: 0, // Port Fees
      chdelord: 0, // Delivery Order
      chterhand: 0, // Terminal Handling
      chdestclear: 0, // Destination Clearance
      chshuttle: 0, // Shuttle Charges
      chdeststore: 0, // Destination Storage
      chassembly: 0, // Assembling and Unloading
      chcontreturn: 0, // Container Return
      chothersec4: 0, // Other Section 4

      danggoodch: 0,
      danghandch: 0,
      charges: { finalcost: 0 },
      addcharge: [],
      discount: [],
      imtracks: [],
      imquotes: [],
    };
    this.oldaim = {
      status: "Requested",
      subtype: "Door to Door",
      remarks: "",
      excservices: [],
      incservices: [],
      invnotes: "",
      direction: "Inbound",

      cppack: 0,
      cpouttrans: 0,
      cpexport: 0,
      cpship: 0,
      cpdest: 0,
      cpunpack: 0,
      cpintrans: 0,
      cpimport: 0,
      cpministry: 0,
      cpinspect: 0,

      commodity: "General Cargo",
      shippingmethod: "Sea",
      containertype: "",
      shipfrom: "",
      shipto: "",
      length: 0,
      width: 0,
      height: 0,
      weight: 0,

      // Section 2
      choriginship: 0, // Origin + Shipping
      chinsurance: 0, // Insurance
      chtransport: 0, // Transporation
      chclearance: 0, // Global Clearance
      chbayan: 0, // Bayan
      chothersec2: 0, // Other Section 2

      // Section 3
      chship: 0, // Shipping Line
      chdthc: 0, // DTHC

      // Section 4
      chimport: 0, // Import Customs
      chcustom: 0, // Customs Duty
      chpai: 0, // PAI
      chepa: 0, // EPA
      chrelease: 0, // Container Release
      chdemurrage: 0, // Demurrage Charges
      chdetention: 0, // Detention Charges
      chport: 0, // Port Fees
      chdelord: 0, // Delivery Order
      chterhand: 0, // Terminal Handling
      chdestclear: 0, // Destination Clearance
      chshuttle: 0, // Shuttle Charges
      chdeststore: 0, // Destination Storage
      chassembly: 0, // Assembling and Unloading
      chcontreturn: 0, // Container Return
      chothersec4: 0, // Other Section 4

      danggoodch: 0,
      danghandch: 0,
      charges: { finalcost: 0 },

      addcharge: [],
      discount: [],
      imtracks: [],
      imquotes: [],
    };

    this.loading = {
      account: false,
      newaim: false,
    };
    this.loaded = { items: false };

    makeObservable(this, {
      account: observable,
      newaim: observable,
      oldaim: observable,
      loading: observable,
      loaded: observable,
      stopedit: computed,
    });
  }

  // FETCH
  getAccount(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.account = true;

      return instance
        .get(`/ac/account/getaccount/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((account) => {
          this.account = account;
          this.loading.account = false;
          console.log("Account Fetched");
        })
        .catch((err) => {
          this.loading.account = false;
          this.account = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getSingleACOrder(aimorderid, override) {
    let loaded = false;
    let aimId = this.newaim._id;
    if (aimorderid) aimId = aimorderid;
    if (aimId === this.newaim._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.newaim = true;

      return instance
        .get(`/ac/aimorder/get/byid/${aimId}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.client;
          this.newaim = { ...order };

          this.oldaim = { ...order };

          this.loading.newaim = false;
          console.log("Single AIM Order Fetched");
        })
        .catch((err) => {
          this.loading.newdel = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get stopedit() {
    if (!userStore.user.staffaccount) {
      if (!userStore.user.comprequest) return true;
      if (
        newAimStore.newaim.status === "Booked" ||
        newAimStore.newaim.status === "In Transit" ||
        newAimStore.newaim.status === "Completed"
      ) {
        return true;
      }
    }
    return false;
  }

  removeList(label, index) {
    let oldList = [...newAimStore.oldaim[label]];
    oldList.splice(index, 1);
    newAimStore.newaim[label] = [...oldList];
    newAimStore.oldaim[label] = [...oldList];
  }
  newList(label, item) {
    newAimStore.newaim[label] = [...newAimStore.newaim[label], { ...item }];
    newAimStore.oldaim[label] = [...newAimStore.oldaim[label], { ...item }];
    newAimStore.getSingleACOrder(false, true);
  }
  editList(label, index, item) {
    newAimStore.newaim[label][index] = { ...item };
    newAimStore.oldaim[label][index] = { ...item };
  }
  editListItem(label, index, itemlabel, itemval) {
    newAimStore.newaim[label][index][itemlabel] = itemval;
    newAimStore.oldaim[label][index][itemlabel] = itemval;
  }
  bulkAddList(label, items) {
    let oldList = [...newAimStore.oldaim[label]];
    for (let j = 0; j < items.length; j++) {
      oldList = [...oldList, { ...items[j] }];
    }
    newAimStore.newaim[label] = [...oldList];
    newAimStore.oldaim[label] = [...oldList];
  }

  // ------- SERVICES ------
  selectService(type, action, ser) {
    let incsjs = [...this.newaim.incservices];
    let excsjs = [...this.newaim.excservices];

    let serIndex = -1;
    if (type === "Include") {
      if (action === "add") {
        incsjs.push(ser);
        let excIndex = excsjs.findIndex((exc) => exc === ser);
        if (excIndex > -1) {
          excsjs.splice(excIndex, 1);
        }
      } else {
        serIndex = incsjs.findIndex((inc) => inc === ser);
        incsjs.splice(serIndex, 1);
      }
    } else {
      if (action === "add") {
        excsjs.push(ser);
        let incIndex = incsjs.findIndex((inc) => inc === ser);
        if (incIndex > -1) {
          incsjs.splice(incIndex, 1);
        }
      } else {
        serIndex = excsjs.findIndex((inc) => inc === ser);
        excsjs.splice(serIndex, 1);
      }
    }

    this.newaim.excservices = [...excsjs];
    this.newaim.incservices = [...incsjs];
  }

  replaceServices() {
    this.oldaim.incservices = [...this.newaim.incservices];
    this.oldaim.excservices = [...this.newaim.excservices];
  }
  resetServices() {
    this.newaim.incservices = [...this.oldaim.incservices];
    this.newaim.excservices = [...this.oldaim.excservices];
  }

  editDiscount(val, label, index) {
    newAimStore.newaim.discount[index][label] = val;
  }
  editAddCharge(val, label, index) {
    newAimStore.newaim.addcharge[index][label] = val;
  }

  removeAddCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newAimStore.newaim[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newAimStore.newaim[label][i]];
      }
    }
    newAimStore.newaim[label] = [...newchgs];
  }
  removeOrgCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newAimStore.oldaim[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newAimStore.oldaim[label][i]];
      }
    }
    newAimStore.oldaim[label] = [...newchgs];
  }
  updateOrgAddChgNew(charge, label) {
    newAimStore.oldaim[label] = [...newAimStore.oldaim[label], charge];
    newAimStore.newaim[label] = [...newAimStore.newaim[label], charge];
  }
  updateOrgAddChg(val, label) {
    newAimStore.oldaim[label] = val;
  }

  editOrder(val, label) {
    newAimStore.newaim[label] = val;
  }

  updateOrgOrder(label) {
    let newaim = { ...newAimStore.newaim };
    newAimStore.oldaim[label] = newaim[label];
  }

  editAttachmentTitle(index, val) {
    newAimStore.newaim.attachments[index].title = val;
  }
  createNewOrder() {
    this.loaded.items = false;

    this.newaim = {
      status: "Requested",
      subtype: "Door to Door",
      remarks: "",
      excservices: [],
      incservices: [],
      invnotes: "",
      direction: "Inbound",
      scac: "No Tracking",
      blnumber: "",

      cppack: 0,
      cpouttrans: 0,
      cpexport: 0,
      cpship: 0,
      cpdest: 0,
      cpunpack: 0,
      cpintrans: 0,
      cpimport: 0,
      cpministry: 0,
      cpinspect: 0,

      commodity: "General Cargo",
      shippingmethod: "Sea",
      containertype: "",
      shipfrom: "",
      shipto: "",
      length: 0,
      width: 0,
      height: 0,
      weight: 0,

      // Section 2
      choriginship: 0, // Origin + Shipping
      chinsurance: 0, // Insurance
      chtransport: 0, // Transporation
      chclearance: 0, // Global Clearance
      chbayan: 0, // Bayan
      chothersec2: 0, // Other Section 2

      // Section 3
      chship: 0, // Shipping Line
      chdthc: 0, // DTHC

      // Section 4
      chimport: 0, // Import Customs
      chcustom: 0, // Customs Duty
      chpai: 0, // PAI
      chepa: 0, // EPA
      chrelease: 0, // Container Release
      chdemurrage: 0, // Demurrage Charges
      chdetention: 0, // Detention Charges
      chport: 0, // Port Fees
      chdelord: 0, // Delivery Order
      chterhand: 0, // Terminal Handling
      chdestclear: 0, // Destination Clearance
      chshuttle: 0, // Shuttle Charges
      chdeststore: 0, // Destination Storage
      chassembly: 0, // Assembling and Unloading
      chcontreturn: 0, // Container Return
      chothersec4: 0, // Other Section 4

      danggoodch: 0,
      danghandch: 0,
    };
  }

  startLoading() {
    this.loading = true;
  }
}

const newAimStore = new NewAIMStore();
export default newAimStore;
