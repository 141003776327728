import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class AccountAdminStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.users = [];

    this.loading = {
      users: false,
      newacc: false,
    };
    this.loaded = { users: false };

    makeObservable(this, {
      account: observable,
      loading: observable,
      loaded: observable,
    });
  }

  // FETCH
  getUsers(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.users = true;

      return instance
        .get(`/ac/accountadmin/users/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.account = data.client;
          this.users = data.users;
          this.loading.users = false;
          this.loaded.users = true;
          console.log("Account Fetched");
        })
        .catch((err) => {
          this.loading.users = false;
          this.loaded.users = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // Functions:

  editMainUser(user) {
    let index = this.users.findIndex((usold) => usold._id === user._id);
    let usnew = [...this.users];
    usnew[index] = user;
    this.users = usnew;
  }

  startLoading() {
    this.loading = true;
  }
}

const accountAdminStore = new AccountAdminStore();
export default accountAdminStore;
