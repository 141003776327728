import { observer } from "mobx-react";
import React, { Component } from "react";
import { Spin } from "antd";

class LoadBack extends Component {
  render() {
    return (
      <Spin
        spinning={this.props.loading}
        size="large"
        tip="Loading..."
        style={{ color: "black" }}
      >
        {this.props.children}
      </Spin>
    );
  }
}

export default observer(LoadBack);
