import { message, Tooltip } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Loader, Uploader, Notification, FlexboxGrid } from "rsuite";
import { Col, Icon, HelpBlock, IconButton, ButtonGroup } from "rsuite";
import moment from "moment";

import AttachmentModal from "./AttachmentModal";

import axios from "axios";

import ImageCropModal from "../ImageCropper/ImageCropModal";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";

const instance = axios.create({ baseURL: baseURL });

class AttachmentTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      src: null,
      itemindex: 0,
    };
    this.hideModal = this.hideModal.bind(this);
    this.changeDelete = this.changeDelete.bind(this);
  }

  hideModal() {
    this.setState({ show: false, src: null });
  }

  changeDelete(val) {
    this.setState({ delete: val });
  }

  deleteDocument = async (attId, itemindex) => {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let type = sj.jobtype;

    this.setState({ loading: true });
    Notification["info"]({ title: "Deleting.. Please Wait" });

    let data = {
      attId: attId,
      type: type,
      subJobId: sj._id,
    };

    await instance
      .put(`media/sidejob/delete`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.removeList("attachments", itemindex, ind);

        Notification.close();
        message.success("Document Deleted");
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  checkFileType = async (file) => {
    if (file.blobFile.type.substring(0, 3) === "ima") {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(file.blobFile);
    } else {
      this.uploadDocument(file.blobFile);
    }
  };

  uploadDocument = async (file) => {
    console.log("Started Attachment Upload");
    let attType = this.props.attType;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let type = sj.jobtype;

    this.setState({ loading: true });
    let fd = new FormData();
    fd.append(
      "file",
      file,
      `${type}_${Math.round(Math.random() * 100)}_${file.name}`
    );
    fd.append("sidejobId", sj._id);
    fd.append("sj", sj._id);
    fd.append("sidejobtype", type);
    fd.append("type", attType);
    fd.append("title", attType);
    console.log("Saving Attachment");
    await instance
      .post("media/sidejob/upload/single", fd, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.newList("attachments", res.data, ind);

        this.setState({ loading: false, show: false, src: null });
        message.success(`${attType} Uploaded.`);
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];

    let attType = this.props.attType;

    let initialatts = sj.attachments;
    let atts = [];
    let allatts = [];

    if (initialatts) {
      if (initialatts.length > 0) {
        allatts = initialatts.filter((att) => att.type === attType);
      }
    }
    if (allatts) {
      if (allatts.length > 0) {
        atts = allatts;
      }
    }

    let content;
    if (editview) {
      content = (
        <Uploader
          multiple={false}
          fileListVisible={false}
          onChange={(file) => {
            let finalfile = file[file.length - 1];

            return new Promise((resolve, reject) => {
              let fileSize = finalfile.blobFile.size / 1000000;

              if (
                (finalfile.blobFile.type.substring(0, 3) !== "ima") &
                (fileSize > 10)
              ) {
                message.error("Not Uploaded. Must be less than 10 MB.");
                reject();
              } else {
                this.checkFileType(finalfile);
                resolve();
              }
            });
          }}
          draggable
        >
          <div
            style={{
              height: 50,
              margin: "1%",
              backgroundColor: "rgba(96,214,112,0.26)",
              boxShadow: "0px 0px 3px black",
              border: "1px solid black",
            }}
          >
            <Icon icon="plus" size="lg" />
            <p>Add New</p>
          </div>
        </Uploader>
      );
    }

    if (this.state.loading) {
      content = (
        <div>
          <div active style={{ height: 70 }}>
            <Loader content="Uploading Document..." />
          </div>
        </div>
      );
    }
    let uploaded = atts.map((att, index) => (
      <FlexboxGrid
        key={index}
        style={{
          borderRadius: 5,
          boxShadow: "0px 0px 1px black",
          padding: 2,
          marginRight: 5,
          height: 50,
          border: "1px solid black",
        }}
      >
        <ButtonGroup>
          <IconButton
            icon={<Icon icon="file-text-o" />}
            size="md"
            onClick={() => {
              this.setState({ attachment: att, show: true, itemindex: index });
            }}
          >
            <HelpBlock style={{ fontSize: 10 }}>
              Att #{att.attachmentNumId}
              <br />(<i>{moment(att.createdAt).fromNow()}</i>)
            </HelpBlock>
          </IconButton>
        </ButtonGroup>
      </FlexboxGrid>
    ));

    let flag = false;
    if (this.props.quality & (atts.length <= 0)) {
      flag = true;
    }
    return (
      <div>
        <AttachmentModal
          hideModal={this.hideModal}
          changeDelete={this.changeDelete}
          deleteDocument={this.deleteDocument}
          show={this.state.show}
          attachment={this.state.attachment}
          deleteactive={this.state.delete}
          itemindex={this.state.itemindex}
          editview={editview}
        />
        <ImageCropModal
          loading={this.state.loading}
          uploadDocument={this.uploadDocument}
          hideModal={this.hideModal}
          src={this.state.src}
        />

        <FlexboxGrid align="middle">
          <Col md={6} sm={8} xs={24}>
            <Tooltip title={flag && `${attType} Required.`}>
              <h6 style={{ color: flag && "red" }}>
                {attType}
                {atts.length > 0 && ` (${atts.length})`}:
              </h6>
            </Tooltip>
          </Col>
          <Col md={18} sm={16} xs={24}>
            <FlexboxGrid>
              {content}
              <span style={{ width: 5 }}> </span>
              {uploaded}
            </FlexboxGrid>
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(AttachmentTemplate));
