import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

import SingleLink from "./SingleLink";

class SignedIn extends Component {
  render() {
    return (
      <div>
        <SingleLink
          page="/profile"
          icon="cog"
          title="Profile"
          hdc={this.props.hdc}
        />
      </div>
    );
  }
}

export default withRouter(observer(SignedIn));
