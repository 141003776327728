import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Div14 from "../../../../../../Components/Dividers/Div14";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import ItemsList from "./ItemsList";

class ItemsStep extends Component {
  componentDidMount() {
    newDeliveryStore.getStandardAccountItems(false);
  }
  render() {
    let tos = newDeliveryStore.newdel.toaddress;

    return (
      <div>
        <Div14>Select a Single Delivery Type:</Div14>
        <FlexboxGrid justify="center">
          {tos.map((to, index) => (
            <ItemsList to={to} index={index} key={index} />
          ))}
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(ItemsStep);
