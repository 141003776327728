import React, { Component } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import acClearanceStore from "../../../../Stores/AccountUpdated/ACC/ACClearanceStore";
import { Divider, Icon } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import { InputGroup } from "rsuite";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import jobcolors from "../../../../Static/jobcolors/jobcolors";
import OrderModal from "../Modal/OrderModal";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class ACCCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.changeST = this.changeST.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  componentDidMount() {
    acClearanceStore.getCalendarJobs(true);
  }

  onHide() {
    this.setState({ show: false });
  }
  changeST(label, val) {
    this.setState({ [label]: val });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let evt = event;

    let final;

    if (evt.status === "Completed") final = jobcolors["completed"];
    if (evt.status === "In Transit") final = jobcolors["intransit"];
    if (evt.status === "Requested") final = jobcolors["requested"];
    if (evt.status === "Booked") final = jobcolors["booked"];

    if (evt.status === "Cancelled") {
      final = jobcolors["cancelled"];
    }

    var style = {
      backgroundColor: final.back,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 10,
      height: 15,
      color: final.font,
    };
    return {
      style: style,
    };
  };
  render() {
    let data = acClearanceStore.calendarjobs;

    return (
      <LoadBack loading={acClearanceStore.loading.calendar}>
        <Div14>Custom Clearance Vessel Arrival Calendar</Div14>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <DatePickerSTD
              title={"From Date"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={acClearanceStore.startdate}
              size="large"
              editVal={acClearanceStore.editDate}
            />
            <InputGroup.Button
              color="green"
              loading={acClearanceStore.loading.order}
              onClick={() => {
                acClearanceStore.getOrders(false, true);
              }}
            >
              Reload <Divider vertical />
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
          <Calendar
            localizer={localizer}
            events={data}
            eventPropGetter={this.eventStyleGetter}
            startAccessor={"vesselarrival"}
            endAccessor={"vesselarrival"}
            style={{ height: 500 }}
            defaultDate={new Date()}
            views={["month"]}
            titleAccessor={"calendartitle"}
            popup
            onSelectEvent={(e) => {
              modalACOrderStore.selectACC(e);
              this.setState({ show: true });
            }}
            onNavigate={() => console.log(" ")}
          />
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(ACCCalendar));
