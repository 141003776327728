import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import Col2 from "../../../../Components/Columns/Col2";
import Col3 from "../../../../Components/Columns/Col3";
import heardofuslist from "../../../../Static/Lists/HeardOfUs";
import leadtypeslist from "../../../../Static/Lists/LeadTypes";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import { DatePicker } from "antd";
import { InputGroup } from "rsuite";
import moment from "moment";
import NewStorCont from "./NewStorCont";
import NewDelCont from "./NewDelCont";

class NewAccountTemplate extends Component {
  render() {
    let data = newAccountStore.account;

    let dataquality = [];
    if (data.name === "") dataquality.push("Client Name Missing");
    if ((data.email === "") & (data.phone === "") & (data.phone2 === "")) {
      dataquality.push("Add at least an Email or a Phone Number");
    }

    return (
      <div style={{ padding: "1%" }}>
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          <Div14>Client Details:</Div14>
          <FlexboxGrid>
            <Col2>
              <TextInputSTD
                title="Name"
                val={data.name}
                label="name"
                size="large"
                editVal={newAccountStore.editAccount}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Status"
                val={data.status}
                label="status"
                size="large"
                editVal={newAccountStore.editAccount}
                opts={["Pending", "Active", "Cancelled", "Expired"]}
              />
              <br />
            </Col2>
          </FlexboxGrid>

          <FlexboxGrid>
            <Col3>
              <TextInputSTD
                title="Email"
                val={data.email}
                label="email"
                size="large"
                editVal={newAccountStore.editAccount}
              />
              <br />
            </Col3>
            <Col3>
              <TextInputSTD
                title="Phone"
                val={data.phone}
                label="phone"
                size="large"
                editVal={newAccountStore.editAccount}
              />
              <br />
            </Col3>
            <Col3>
              <TextInputSTD
                title="Phone 2"
                val={data.phone2}
                label="phone2"
                size="large"
                editVal={newAccountStore.editAccount}
              />
              <br />
            </Col3>
          </FlexboxGrid>

          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Heard of Us"
                val={data.heardofus}
                label="heardofus"
                size="large"
                editVal={newAccountStore.editAccount}
                opts={heardofuslist}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Heard of Us"
                val={data.leadtype}
                label="leadtype"
                size="large"
                editVal={newAccountStore.editAccount}
                opts={leadtypeslist}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid justify="center" align="middle">
            <Col3>
              <DatePickerSTD
                title="Start Date"
                val={data.startdate}
                label="startdate"
                editVal={newAccountStore.editAccount}
              />
              <br />
            </Col3>
            <Col3>
              <InputGroup>
                <InputGroup.Addon>End Date</InputGroup.Addon>
                <DatePicker
                  style={{ width: "100%" }}
                  //format={"DD-MMM-YY @ HA"}
                  format={"DD-MMM-YY"}
                  value={moment(data.enddate)}
                  allowClear={false}
                  size={"large"}
                  picker={"date"}
                  showMinute={false}
                  disabledDate={(date) =>
                    moment(date).isBefore(moment(data.startdate).add(1, "day"))
                  }
                  onChange={(date) => {
                    newAccountStore.editAccount(date, "enddate");
                  }}
                />
              </InputGroup>

              <br />
            </Col3>
            <Col3>
              <h6 style={{ textAlign: "center" }}>
                Contract Length ={" "}
                {moment(data.enddate).from(data.startdate, true)}
              </h6>
              <br />
            </Col3>
          </FlexboxGrid>
        </div>
        <br />
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          <Div14>Jobs</Div14>
          <Div14>
            International Move and Custom Clearance
            <br />
            <HelpBlock>Pricing will be individually per Job</HelpBlock>
          </Div14>

          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={<Icon icon="ship" />}
                appearance={data.aim ? "primary" : "ghost"}
                color="green"
                onClick={() => newAccountStore.editAccount(!data.aim, "aim")}
                block
              >
                {data.aim ? "Remove" : "Add"} International Move
              </IconButton>
              <br />
            </Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="plane" />}
                appearance={data.acc ? "primary" : "ghost"}
                color="green"
                placement="right"
                onClick={() => newAccountStore.editAccount(!data.acc, "acc")}
                block
              >
                {data.acc ? "Remove" : "Add"} Custom Clearance
              </IconButton>
            </Col2>
          </FlexboxGrid>
        </div>
        <br />
        <NewDelCont />
        <br />
        <NewStorCont />
      </div>
    );
  }
}

export default observer(NewAccountTemplate);
