import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";
import mgtOverviewStore from "../../../../Stores/Management/MgtOverviewStore";

class StackedBarGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let mgtdata = mgtOverviewStore.local;
    let data = [
      {
        series: "LM",
        name: "LM",
        data: mgtdata[this.props.type].LM,
      },
      {
        series: "IM",
        name: "IM",
        data: mgtdata[this.props.type].IM,
      },
      {
        series: "CC",
        name: "CC",
        data: mgtdata[this.props.type].CC,
      },
      {
        series: "ST",
        name: "ST",
        data: mgtdata[this.props.type].ST,
      },
    ];

    let options = {
      chart: { type: "bar", height: 450, stacked: true },
      plotOptions: {
        bar: { horizontal: false, dataLabels: { position: "bottom" } },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },

        formatter: function (val) {
          return Math.round(val);
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: this.props.xaxis,
        title: {
          text: this.props.label,
        },
      },
      legend: { show: true, position: "top" },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        title: {
          text: this.props.label,
        },
        labels: {
          formatter: function (val) {
            return Math.round(val);
          },
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return Math.abs(val);
          },
        },
      },

      title: {
        text: this.props.title,
        align: "center",
        marginTop: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    };

    return (
      <div>
        <ReactApexChart
          options={options}
          series={data}
          type="bar"
          height={300}
        />
      </div>
    );
  }
}

export default withRouter(observer(StackedBarGraph));
