import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Badge, Tag } from "antd";
class StatusIndicator extends Component {
  render() {
    let data = this.props.data;
    let statuscolor = "orange";
    let statustype = "processing";
    let text = "Pending";
    if (data.val) {
      statuscolor = "green";
      statustype = "default";
      text = moment(data.date).format("DD-MMM-YY");
    }
    return (
      <Tag color={statuscolor} span={2} style={{ fontWeight: "bold" }}>
        <Badge
          status={statustype}
          style={{ color: statuscolor }}
          color={statuscolor}
          text={text}
        />
      </Tag>
    );
  }
}

export default withRouter(observer(StatusIndicator));
