import { JDQualityCalc } from "./SupportingQuality/JDQualityCalc";
import { AttQualityCalc } from "./SupportingQuality/ATTQualityCalc";

function CCQualityCalc(data) {
  let finalerror = [0, 0];
  let general = CCQualityGeneral(data);
  let key = CCQualityKeyEvents(data);
  let docs = CCQualityDocs(data);
  let dates = JDQualityCalc(data);
  let atts = AttQualityCalc(data);

  finalerror[0] = general[0] + key[0] + docs[0] + dates[0] + atts[0];
  finalerror[1] = general[1] + key[1] + docs[1] + dates[1] + atts[1];
  finalerror[2] = [
    ...general[2],
    ...key[2],
    ...docs[2],
    ...dates[2],
    ...atts[2],
  ];
  return finalerror;
}

function CCQualityGeneral(data) {
  let errors = 0;
  let total = 9;
  let items = [];
  if (data.cc.invoicetas.length === 0) items.push("Invoice TAs");
  if (data.cc.lastprice === 0) items.push("Price");
  if (data.cbm === 0) items.push("CBM");
  if (data.cc.shippingline === "") items.push("Shipping Line");
  if (data.cc.blnumber === "") items.push("BL Number");
  if (data.cc.commodity === "") items.push("Commodity");
  if (data.cc.brandsupplier === "") items.push("Brand Supplier");
  if (data.cc.originlocation === "") items.push("Origin Location");
  if (data.cc.containercount === 0) items.push("Container Count");
  if (data.cc.packages === 0) items.push("Packages");
  errors = items.length;
  return [errors, total, items];
}

function CCQualityKeyEvents(data) {
  let errors = 0;
  let total = 1;
  let items = [];
  if (data.vessalarrival === 0) items.push("Vessel Arrival Date");

  if (data.status === "Converted") {
    total = 4;
    if (data.doccol === 0 || data.doccoldate === undefined)
      items.push("Documents Collected Date");
    if (data.bayanissue === 0 || data.bayanissuedate === undefined)
      items.push("Bayan Date");
    if (data.cleardone === 0 || data.cleardonedate === undefined)
      items.push("Clearance Date");
  }
  errors = items.length;
  return [errors, total, items];
}
function CCQualityDocs(data) {
  let errors = 0;
  let total = 0;
  let atts = data.attachments;
  let items = [];
  if (data.status === "Converted") {
    total = 8;
    let bl = atts.filter((att) => att.title === "Bill of Lading");
    let pl = atts.filter((att) => att.title === "Packing List");
    let co = atts.filter((att) => att.title === "Certificate of Origin");
    let iq = atts.filter((att) => att.title === "Iqrar Report");
    let ci = atts.filter((att) => att.title === "Commercial Invoice");
    let id = atts.filter((att) => att.title === "Invoice Delivery");
    let dp = atts.filter((att) => att.title === "Driver Paper");
    let dn = atts.filter((att) => att.title === "Delivery Note");
    if (bl.length === 0) items.push("Bill of Lading Attachment");
    if (pl.length === 0) items.push("Packing List Attachment");
    if (co.length === 0) items.push("Certificate of Origin Attachment");
    if (iq.length === 0) items.push("Iqrar Report Attachment");
    if (ci.length === 0) items.push("Commercial Invoice Attachment");
    if (id.length === 0) items.push("Invoice Delivery Attachment");
    if (dp.length === 0) items.push("Driver Paper Attachment");
    if (dn.length === 0) items.push("Delivery Note Attachment");
  }

  errors = items.length;
  return [errors, total, items];
}

export { CCQualityCalc, CCQualityGeneral, CCQualityDocs, CCQualityKeyEvents };
