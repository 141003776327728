import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Col, Button } from "rsuite";
import paymentStore from "../../../../../Stores/SideJobs/PaymentStore";

class PaymentButtons extends Component {
  render() {
    let buttondata = [
      {
        title: "Cash",
        type: "Income",
        subtype: "Job Related",
        specific: "Customer Payment",
        method: "Cash",
        account: "Cash",
        color: "black",
      },
      {
        title: "Online Link",
        type: "Income",
        subtype: "Job Related",
        specific: "Customer Payment",
        method: "Online Link",
        account: "Boubyan",
        color: "black",
      },
      {
        title: "Bank Transfer",
        type: "Income",
        subtype: "Job Related",
        specific: "Customer Payment",
        method: "Bank Transfer",
        account: "KFH",
        color: "black",
      },
      {
        title: "Cheque",
        type: "Income",
        subtype: "Job Related",
        specific: "Customer Payment",
        method: "Cheque",
        account: "KFH",
        color: "black",
      },
      {
        title: "Job Expense",
        type: "Expense Variable",
        subtype: "Job Related",
        specific: "Customer Payment",
        method: "Cheque",
        account: "KFH",
        color: "red",
      },
    ];

    let buttons = buttondata.map((but, index) => (
      <Col md={4} sm={8} xs={12} key={index}>
        <Button
          size="sm"
          block
          appearance="ghost"
          style={{
            backgroundColor: "white",
            border: `1px solid ${but.color}`,
            color: but.color,
          }}
          onClick={() => {
            paymentStore.createNewPayment();
            paymentStore.editPayment(but.type, "type");
            paymentStore.editPayment(but.specific, "specific");
            paymentStore.editPayment(but.subtype, "subtype");
            paymentStore.editPayment(but.method, "method");
            paymentStore.editPayment(but.account, "account");
            this.props.showHideModal("New");
          }}
        >
          {but.title}
        </Button>
      </Col>
    ));

    return <FlexboxGrid>{buttons}</FlexboxGrid>;
  }
}

export default observer(PaymentButtons);
