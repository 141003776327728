let leadtypeslist = [
  "Received Call",
  "Missed Call",
  "Email Lead",
  "Online Lead",
  "Referral",
  "Sales Team",
  "Other",
];
export default leadtypeslist;
