import { observer } from "mobx-react";

import React, { Component } from "react";
import { Divider, Icon, IconButton } from "rsuite";

import { Tabs } from "antd";

import { withRouter } from "react-router";

import OrderTable from "./OrderTable";
import { Tooltip } from "@material-ui/core";
import { InputGroup } from "rsuite";
import singleAccountStore from "../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import acClearanceStore from "../../../../../../Stores/AccountUpdated/ACC/ACClearanceStore";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import DatePickerSTD from "../../../../../../Components/Inputs/STD/DatePickerSTD";
import FlexboxButton from "../../../../../../Components/Modal/FlexboxButton";
import modalACOrderStore from "../../../../../../Stores/ModalStores/ModalACOrderStore";
import OrderModal from "../../../Modal/OrderModal";
const { TabPane } = Tabs;

class OrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, tab: "Pending Jobs" };
    this.onHide = this.onHide.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }
  componentDidMount() {
    singleAccountStore.getACOrders(this.props.clientid);
    acClearanceStore.getOrders(this.props.clientid, true);
  }
  onHide() {
    this.setState({ show: false });
  }
  ActionButs(cellData) {
    return (
      <>
        <Tooltip title={`View Order`} arrow>
          <IconButton
            icon={<Icon icon="eye" />}
            color="green"
            circle
            size="xs"
            onClick={() => {
              modalACOrderStore.selectACC(cellData.data);
              this.setState({ show: true });
            }}
          />
        </Tooltip>
      </>
    );
  }
  render() {
    return (
      <LoadBack loading={acClearanceStore.loading.order}>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <DatePickerSTD
              title={"From Date"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={acClearanceStore.startdate}
              size="large"
              editVal={acClearanceStore.editDate}
            />
            <InputGroup.Button
              color="green"
              loading={acClearanceStore.loading.order}
              onClick={() => {
                acClearanceStore.getOrders(false, true);
              }}
            >
              Reload <Divider vertical />
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
          centered
        >
          <TabPane tab={"Pending Jobs"} key={"Pending Jobs"}>
            <OrderTable
              data={acClearanceStore.orders}
              type="Current"
              actionButs={this.ActionButs}
            />
          </TabPane>
          <TabPane tab={"Past Jobs"} key={"Past Jobs"}>
            {acClearanceStore.loaded.pastorders ? (
              <OrderTable
                data={acClearanceStore.pastorders}
                type="Past"
                actionButs={this.ActionButs}
              />
            ) : (
              <FlexboxButton
                icon="eye"
                color="green"
                text="Load Past Jobs"
                app="primary"
                oC={() => acClearanceStore.getCustomOrderStatus("Completed")}
              />
            )}
          </TabPane>
          <TabPane tab={"Cancelled Jobs"} key={"Cancelled Jobs"}>
            {acClearanceStore.loaded.cancelledorders ? (
              <OrderTable
                data={acClearanceStore.cancelledorders}
                type="Cancel"
                actionButs={this.ActionButs}
              />
            ) : (
              <FlexboxButton
                icon="eye"
                color="green"
                text="Load Cancelled Deliveries"
                app="primary"
                oC={() => acClearanceStore.getCustomOrderStatus("Cancelled")}
              />
            )}
          </TabPane>
        </Tabs>

        <br />
      </LoadBack>
    );
  }
}

export default withRouter(observer(OrderMain));
