import { message, Statistic } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, IconButton, Notification } from "rsuite";
import { DatePicker } from "antd";
import { InputGroup, Icon } from "rsuite";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { Parsed } from "../../../../../Functions/Pricing/PRKDCalc";
import userStore from "../../../../../Stores/UserStore";
import { TRC } from "../../../../../Functions/Timing/TimingRulesCalc";
import { TimingCheck } from "../../../../../Functions/Timing/TimingCheck";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import DeliveryTimeRequestTable from "../../../StorageAccount/Dashboard/NewStorage/Steps/Supporting/DeliveryTimeRequestTable";

const instance = axios.create({ baseURL: baseURL });

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

class Timing extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.postJobDate = this.postJobDate.bind(this);
    this.changeFee = this.changeFee.bind(this);
  }
  changeFee = async (fee) => {
    let delCont = newDeliveryStore.delCont;
    let expfee = delCont.expressfee;
    let affee = delCont.afterhourfee;
    let bothfee = Parsed(affee) + Parsed(expfee);
    let prices = { ...newDeliveryStore.newdel.prices };
    let timetype = prices.timetype;
    let expact =
      timetype === "Express + After Hour Delivery" ||
      timetype === "Express Delivery";
    let afact =
      timetype === "Express + After Hour Delivery" ||
      timetype === "After Hour Delivery";

    prices.timetype = "";
    prices.timeprice = 0;

    if (fee === "Express") {
      if (expact) {
        if (afact) {
          prices.timetype = "After Hour Delivery";
          prices.timeprice = affee;
        }
      } else {
        if (afact) {
          prices.timetype = "Express + After Hour Delivery";
          prices.timeprice = bothfee;
        } else {
          prices.timetype = "Express Delivery";
          prices.timeprice = expfee;
        }
      }
    } else {
      if (afact) {
        if (expact) {
          prices.timetype = "Express Delivery";
          prices.timeprice = expfee;
        }
      } else {
        if (expact) {
          prices.timetype = "Express + After Hour Delivery";
          prices.timeprice = bothfee;
        } else {
          prices.timetype = "After Hour Delivery";
          prices.timeprice = affee;
        }
      }
    }
    let data = { prices: prices };

    this.setState({ loading: true });
    await instance
      .put(`ac/delivery/edit/${newDeliveryStore.olddel._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Time Fees Updated`);

        newDeliveryStore.editOrder(prices, "prices");
        newDeliveryStore.updateOrgOrder("prices");

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  postJobDate = async () => {
    let data = { jobdate: newDeliveryStore.newdel.jobdate };
    let prices = { ...newDeliveryStore.newdel.prices };
    let ppt = newDeliveryStore.delCont.pricepertrip;
    let day = moment(newDeliveryStore.newdel.jobdate).day();

    let alldays = [
      { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
      { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
      { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
      { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
      { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
      { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
      { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
    ];
    if (alldays[day].price > 0) {
      prices.weekday = alldays[day].title;
      prices.weekdayprice = alldays[day].price;
    }
    data.prices = { ...prices };

    this.setState({ loading: true });
    await instance
      .put(`ac/delivery/edit/${newDeliveryStore.olddel._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Job Date Updated`);
        newDeliveryStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  disabledDate(current) {
    let delCont = newDeliveryStore.delCont;
    let hrs = delCont.excludedhours;

    let exdays = [];
    let afdays = [];
    let range = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    for (let i = 0; i < range.length; i++) {
      if (hrs[range[i]] === "No") exdays = [...exdays, i];
      if (hrs[range[i]] === "After") afdays = [...afdays, i];
    }

    let excluded = false;

    for (let i = 0; i < exdays.length; i++) {
      if (moment(current).day() === exdays[i]) excluded = true;
    }

    let earliest = TRC(
      delCont,
      true,
      true,
      newDeliveryStore.neworder.createdAt
    );

    return current < earliest || excluded;
  }
  disabledTime(current) {
    let min1 = range(1, 15);
    let min2 = range(16, 30);
    let min3 = range(31, 45);
    let min4 = range(46, 60);
    let minutes = [...min1, ...min2, ...min3, ...min4];

    let delCont = newDeliveryStore.delCont;
    let hrs = delCont.excludedhours;
    let exhrs = [];
    let afhrs = [];
    for (let i = 0; i < 24; i++) {
      if (hrs[`${i}`] === "No") exhrs = [...exhrs, i];
      if (hrs[`${i}`] === "After") afhrs = [...afhrs, i];
    }

    return {
      disabledHours: () => exhrs,
      disabledMinutes: () => minutes,
    };
  }
  render() {
    let newdel = newDeliveryStore.newdel;
    let olddel = newDeliveryStore.olddel;
    let delCont = newDeliveryStore.delCont;

    let load = this.props.load;

    let staffActions;
    if (userStore.user.staffaccount) {
      let changed =
        moment(newdel.jobdate).format("DD-MMM-YY HH:mm") !==
        moment(olddel.jobdate).format("DD-MMM-YY HH:mm");

      let timecheck = TimingCheck(delCont, olddel.jobdate, olddel.createdAt);
      let expact =
        olddel.prices.timetype === "Express + After Hour Delivery" ||
        olddel.prices.timetype === "Express Delivery";
      let afact =
        olddel.prices.timetype === "Express + After Hour Delivery" ||
        olddel.prices.timetype === "After Hour Delivery";

      let addFeeButton = (
        <FlexboxGrid>
          <Col2>
            <IconButton
              loading={this.state.loading}
              onClick={() => this.changeFee("Express")}
              block
              icon={<Icon icon="usd" />}
              color="green"
              appearance={expact ? "primary" : "ghost"}
            >
              {expact ? "Express Fee Charged" : "No Express Fee"}
            </IconButton>
            <HelpBlock style={{ textAlign: "center" }}>
              Express fee should {timecheck[0] ? "" : "NOT "}be applied
            </HelpBlock>
          </Col2>
          <Col2>
            <IconButton
              loading={this.state.loading}
              onClick={() => this.changeFee("After Hour")}
              block
              icon={<Icon icon="usd" />}
              color="green"
              appearance={afact ? "primary" : "ghost"}
            >
              {afact ? "After Hour Fee Charged" : "No After Hour Fee"}
            </IconButton>
            <HelpBlock style={{ textAlign: "center" }}>
              After Hour fee should {timecheck[1] ? "" : "NOT "}be applied
            </HelpBlock>
          </Col2>
        </FlexboxGrid>
      );

      staffActions = (
        <div>
          <Div14>Staff Actions:</Div14>
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                {changed && (
                  <InputGroup.Button
                    loading={load}
                    onClick={() =>
                      newDeliveryStore.editOrder(
                        moment(olddel.jobdate),
                        "jobdate"
                      )
                    }
                  >
                    <Icon icon="undo" />
                  </InputGroup.Button>
                )}
                <InputGroup.Addon>Modify Job Date</InputGroup.Addon>
                <DatePicker
                  style={{ width: "100%" }}
                  showTime={{ format: "HH:mm" }}
                  format="DD-MMM-YY HH:mm"
                  minuteStep={15}
                  showSecond={false}
                  allowClear={false}
                  size="large"
                  picker="date"
                  showNow={false}
                  showToday={false}
                  inputReadOnly
                  value={moment(newdel.jobdate)}
                  onChange={(date) =>
                    newDeliveryStore.editOrder(date, "jobdate")
                  }
                  onOk={(date) => newDeliveryStore.editOrder(date, "jobdate")}
                />
                {changed && (
                  <InputGroup.Button
                    color="green"
                    loading={load}
                    onClick={() => this.postJobDate("jobdate", "Job Date")}
                  >
                    <Icon icon="check" />
                  </InputGroup.Button>
                )}
              </InputGroup>
              {changed && (
                <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                  Changed from{" "}
                  <i>"{moment(olddel.jobddate).format("DD-MMM-YY HH:mm")}"</i>
                </HelpBlock>
              )}
            </Col2>
            <Col2>{addFeeButton}</Col2>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <div>
        <Div14>Job Time</Div14>
        <FlexboxGrid justify="center">
          <Statistic
            title={olddel.prices.timetype}
            value={moment(olddel.jobdate).format("DD-MMM-YY HH:mm")}
            style={{
              margin: "0 32px",
              textAlign: "center",
            }}
          />
        </FlexboxGrid>
        <HelpBlock style={{ textAlign: "center" }}>
          {moment(olddel.jobdate).fromNow()}
        </HelpBlock>
        {staffActions}
        <DeliveryTimeRequestTable delCont={delCont} />
      </div>
    );
  }
}

export default observer(Timing);
