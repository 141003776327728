import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmTruckStore from "../WHMStores/WHMTruckStore";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import { Divider, FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import { Tabs, message } from "antd";
import userStore from "../../../Stores/UserStore";

import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import WHMTruckTable from "./WHMTruckTable";
import Div14 from "../../../Components/Dividers/Div14";
import TabPane from "antd/lib/tabs/TabPane";
import TruckTripTable from "./TruckTripTable";

import DatePickerSTD from "../../../Components/Inputs/STD/DatePickerSTD";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class WHMTruckPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      name: "",
      oldname: "",
      loading: false,
      tab: "",
    };
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.createNew = this.createNew.bind(this);
  }
  createNew = async (name) => {
    let data = { name: name };

    this.setState({ loading: true });
    await instance
      .post(`whm/trucks/newtruck`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        whmTruckStore.getTrucks(true);

        message.success(`New Truck Added`);
        this.onHide();
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        Notification["error"]({ title: `An error occured` });
      });
  };
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  onHide() {
    this.setState({ show: false, name: "", oldname: "" });
  }
  componentDidMount() {
    whmTruckStore.getTrucks();
  }

  render() {
    let daily = whmTruckStore.dailysummary;
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Add New Truck"]}
              oC1={() => this.onHide()}
              oC2={() => this.createNew(this.state.name)}
              disabled2={this.state.name === ""}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          title="Add New Truck"
        >
          <TextInputSTD
            title="Truck Name"
            val={this.state.name}
            label="name"
            editVal={this.editVal}
          />
        </UnifiedModal>
        <Div14>
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            size="xs"
            circle
            onClick={() => this.setState({ show: true })}
          />
          <Divider vertical />
          Truck Table <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            circle
            size="xs"
            onClick={() => whmTruckStore.getTrucks(true)}
          />
        </Div14>
        <WHMTruckTable />
        <Div14>Truck History</Div14>
        <FlexboxGrid style={{ margin: "2%" }}>
          <DatePickerSTD
            title={"Select Date"}
            format={"DD-MMM-YY"}
            picker="date"
            label="date"
            size="large"
            editVal={whmTruckStore.changeDate}
            val={whmTruckStore.selecteddate}
          />
        </FlexboxGrid>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
            if (e === "All Trucks") {
              whmTruckStore.getAllTruckTrips(true);
            } else {
              whmTruckStore.getTruckTrips(e, true);
            }
          }}
        >
          <TabPane tab={"All Trucks"} key={"All Trucks"}>
            <TruckTripTable
              loading={whmTruckStore.loading.trucktrips}
              data={whmTruckStore.trucktripwithavg}
            />
            <Div14>Daily Summary:</Div14>
            <p style={{ textAlign: "center" }}>
              {daily.dailytrucktrip} Trips
              <br />
              Average Loading Time = {daily.averageloading} min
              <br />
              Average Unloading Time = {daily.averageunloading} min
              <br />
              Total Moved = {daily.palletsmoved} Pallets /{" "}
              {PRKD(daily.cbmmoved)} CBM
              <br /> Truck 1 = {daily.t1pallet} Pallets / {PRKD(daily.t1cbm)}{" "}
              CBM
              <br /> Truck 2 = {daily.t2pallet} Pallets / {PRKD(daily.t2cbm)}{" "}
              CBM
              <br /> Truck 3 = {daily.t3pallet} Pallets / {PRKD(daily.t3cbm)}{" "}
              CBM
            </p>
          </TabPane>
          {whmTruckStore.trucks.map((tr) => (
            <TabPane tab={tr.name} key={tr._id}>
              <TruckTripTable
                loading={whmTruckStore.loading.trucktrips}
                data={whmTruckStore.trucktripwithavg}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(WHMTruckPage));
