import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Col,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Col3 from "../../../../Components/Columns/Col3";
import Div14 from "../../../../Components/Dividers/Div14";
import SelectInputSTU from "../../../../Components/Inputs/Updatable/SelectInputSTU";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import axios from "axios";

import EditTimingSection from "./EditTimingSection";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";
import NumbInputSTU from "../../../../Components/Inputs/Updatable/NumbInputSTU";
import TextAreaInputSTU from "../../../../Components/Inputs/Updatable/TextAreaInputSTU";
import EditOptions from "./EditOptions";
import EditDelOptions from "./EditDelOptions";

const instance = axios.create({ baseURL: baseURL });

class EditDelCont extends Component {
  constructor(props) {
    super(props);
    this.state = { load: {} };
    this.editLoc = this.editLoc.bind(this);
    this.updateDelCont = this.updateDelCont.bind(this);
    this.updateDelContMain = this.updateDelContMain.bind(this);
    this.updateDelContDay = this.updateDelContDay.bind(this);
    this.updateDelContZone = this.updateDelContZone.bind(this);
    this.updateDelContTime = this.updateDelContTime.bind(this);
    this.updateOptions = this.updateOptions.bind(this);
  }
  updateDelCont = async (data, label, type) => {
    let delCont = newAccountStore.delCont;
    this.setState({ load: { [label]: true } });
    return instance
      .put(`/ac/account/editdelcont/${delCont.client}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        if (type === "Main") {
          newAccountStore.updateDelCont(delCont[label], label);
        } else if (type === "Day") {
          newAccountStore.updateDelDay(delCont[label], label);
        } else if (type === "Zone") {
          newAccountStore.updateDelZone(delCont[label], label);
        } else if (type === "Time") {
          newAccountStore.updateDelTime(data);
        } else if (type === "Options") {
          newAccountStore.updateOptions(data);
        }
        message.success(
          `Successfully Updated Delivery Contract Info (${label})`
        );
        this.setState({ load: { [label]: false } });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ load: { [label]: false } });
      });
  };
  updateDelContMain = async (label) => {
    let delCont = newAccountStore.delCont;
    let data = { [label]: delCont[label] };
    if (label === "adel") {
      if (delCont[label] === false) {
        data.ast = false;
      }
    }
    this.updateDelCont(data, label, "Main");
  };
  updateDelContDay = async (label) => {
    let data = newAccountStore.orgdelCont.pricepertrip;
    data[label] = newAccountStore.delCont.pricepertrip[label];
    this.updateDelCont(data, "pricepertrip", "Day");
  };
  updateDelContZone = async (label) => {
    let data = newAccountStore.orgdelCont.zoneadd;
    data[label] = newAccountStore.delCont.zoneadd[label];
    this.updateDelCont(data, "zoneadd", "Zone");
  };
  updateDelContTime = async () => {
    let data = { excludedhours: newAccountStore.delCont.excludedhours };
    this.updateDelCont(data, "excludedhours", "Time");
  };
  updateOptions = async () => {
    let data = { options: newAccountStore.delCont.options };
    this.updateDelCont(data, "options", "Options");
  };
  editLoc(loc) {
    newAccountStore.editStorContEMLOC(loc);
  }
  render() {
    let data = newAccountStore.orgaccount;
    let newdata = newAccountStore.account;
    let delCont = newAccountStore.orgdelCont;
    let newdelCont = newAccountStore.delCont;

    let delpricing;
    let deladditional;
    let delmain;
    let dailydelsandzones;
    let load = this.state.load;
    if (newdata.adel) {
      let pptlist = [
        { title: "Sunday", label: "sun" },
        { title: "Monday", label: "mon" },
        { title: "Tuesday", label: "tue" },
        { title: "Wednesday", label: "wed" },
        { title: "Thursday", label: "thu" },
        { title: "Friday", label: "fri" },
        { title: "Saturday", label: "sat" },
      ];
      let zonelist = [
        { title: "Zone 1", label: "z1" },
        { title: "Zone 2", label: "z2" },
        { title: "Zone 3", label: "z3" },
        { title: "Zone 4", label: "z4" },
        { title: "Zone 5", label: "z5" },
      ];

      dailydelsandzones = (
        <div>
          <FlexboxGrid>
            <Col2>
              <Div14>Day Delivery Rate (KD):</Div14>
              {pptlist.map((ppt, index) => (
                <NumbInputSTU
                  key={index}
                  val={newdelCont.pricepertrip[ppt.label]}
                  org={delCont.pricepertrip[ppt.label]}
                  label={ppt.label}
                  title={ppt.title}
                  size="large"
                  editVal={newAccountStore.editDelDay}
                  updateVal={this.updateDelContDay}
                  load={load.pricepertrip}
                />
              ))}
            </Col2>
            <Col2>
              <Div14>Zone Additional (KD):</Div14>
              {zonelist.map((zone, index) => (
                <NumbInputSTU
                  key={index}
                  val={newdelCont.zoneadd[zone.label]}
                  org={delCont.zoneadd[zone.label]}
                  label={zone.label}
                  title={zone.title}
                  size="large"
                  editVal={newAccountStore.editDelZone}
                  updateVal={this.updateDelContZone}
                  load={load.zoneadd}
                />
              ))}
              <NumbInputSTU
                val={newdelCont.dropoffmult}
                org={delCont.dropoffmult}
                label="dropoffmult"
                title="Multi-Dropoff Multiplier"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.dropoffmult}
              />
              <NumbInputSTU
                val={newdelCont.nongroundfloorprice}
                org={delCont.nongroundfloorprice}
                label="nongroundfloorprice"
                title="Non-Ground Floor Charge (KD)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.nongroundfloorprice}
              />
            </Col2>
          </FlexboxGrid>
        </div>
      );

      if (delCont.maintype === "Dedicated Staff") {
        delpricing = (
          <FlexboxGrid justify="center">
            <Col3>
              <NumbInputSTU
                val={newdelCont.fixedweekly}
                org={delCont.fixedweekly}
                label="fixedweekly"
                title="Weekly Rate (KD)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.fixedweekly}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTU
                val={newdelCont.staffcount}
                org={delCont.staffcount}
                label="staffcount"
                title="Staff Count"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.staffcount}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTU
                val={newdelCont.carcount}
                org={delCont.carcount}
                label="carcount"
                title="Truck Count"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.carcount}
              />
              <br />
            </Col3>
          </FlexboxGrid>
        );
      } else if (delCont.maintype === "Dedicated Staff - Monthly") {
        delpricing = (
          <FlexboxGrid justify="center">
            <Col3>
              <NumbInputSTU
                val={newdelCont.fixedweekly}
                org={delCont.fixedweekly}
                label="fixedweekly"
                title="Monthly Rate (KD)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.fixedweekly}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTU
                val={newdelCont.staffcount}
                org={delCont.staffcount}
                label="staffcount"
                title="Staff Count"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.staffcount}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTU
                val={newdelCont.carcount}
                org={delCont.carcount}
                label="carcount"
                title="Truck Count"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.carcount}
              />
              <br />
            </Col3>
          </FlexboxGrid>
        );
      } else if (delCont.maintype === "Daily Per Truck") {
        delpricing = (
          <div>
            <FlexboxGrid justify="center">
              <Col2>
                <NumbInputSTU
                  val={newdelCont.pricepertruck}
                  org={delCont.pricepertruck}
                  label="pricepertruck"
                  title="Price Per Truck"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                  updateVal={this.updateDelContMain}
                  load={load.pricepertruck}
                />

                <br />
              </Col2>
              <Col2>
                <NumbInputSTU
                  val={newdelCont.carcount}
                  org={delCont.carcount}
                  label="carcount"
                  title="Min Daily Truck"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                  updateVal={this.updateDelContMain}
                  load={load.carcount}
                />
                <br />
              </Col2>
            </FlexboxGrid>
          </div>
        );
      } else if (delCont.maintype === "Fixed Per Type") {
        delpricing = (
          <div>
            <EditDelOptions />
            {dailydelsandzones}
          </div>
        );
      } else if (delCont.maintype === "Fixed Per Trip") {
        delpricing = (
          <div>
            <FlexboxGrid justify="center">
              <Col2>
                <NumbInputSTU
                  val={newdelCont.freeweeklytrips}
                  org={delCont.freeweeklytrips}
                  label="freeweeklytrips"
                  title="Free Weekly Trips"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                  updateVal={this.updateDelContMain}
                  load={load.freeweeklytrips}
                />
                <br />
              </Col2>
              <Col2>
                <NumbInputSTU
                  val={newdelCont.maxcbmpertrip}
                  org={delCont.maxcbmpertrip}
                  label="maxcbmpertrip"
                  title="Max CBM Per Trip"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                  updateVal={this.updateDelContMain}
                  load={load.maxcbmpertrip}
                />
                <br />
              </Col2>
            </FlexboxGrid>
            {dailydelsandzones}
          </div>
        );
      }
      deladditional = (
        <div>
          <Div14>Additional Details:</Div14>
          <FlexboxGrid justify="center">
            <Col3>
              <NumbInputSTU
                val={newdelCont.timetoreq}
                org={delCont.timetoreq}
                label="timetoreq"
                title="Time to Request (hrs)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.timetoreq}
              />
              <NumbInputSTU
                val={newdelCont.afterhourfee}
                org={delCont.afterhourfee}
                label="afterhourfee"
                title="After Hour Fees (KD)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.afterhourfee}
              />
            </Col3>
            <Col3>
              <NumbInputSTU
                val={newdelCont.expresstime}
                org={delCont.expresstime}
                label="expresstime"
                title="Express Request (hrs)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.expresstime}
              />
              <NumbInputSTU
                val={newdelCont.expressfee}
                org={delCont.expressfee}
                label="expressfee"
                title="Express Fees (KD)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.expressfee}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTU
                val={newdelCont.cancelhours}
                org={delCont.cancelhours}
                label="cancelhours"
                title="Hours to Cancel (hrs)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.cancelhours}
              />
              <NumbInputSTU
                val={newdelCont.cancelprice}
                org={delCont.cancelprice}
                label="cancelprice"
                title="Cancellation Fees (KD)"
                size="large"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
                load={load.cancelprice}
              />
              <br />
            </Col3>

            <Col md={24} sm={24} xs={24}>
              <TextAreaInputSTU
                val={newdelCont.remarks}
                org={delCont.remarks}
                title="Delivery Contract Remarks"
                label="remarks"
                load={load.remarks}
                size="sm"
                editVal={newAccountStore.editDelCont}
                updateVal={this.updateDelContMain}
              />
            </Col>
          </FlexboxGrid>
        </div>
      );

      delmain = (
        <div>
          <Div14>Main Delivery Contract Type:</Div14>
          <SelectInputSTU
            val={newdelCont.maintype}
            org={delCont.maintype}
            label="maintype"
            title="Main Type"
            size="large"
            editVal={newAccountStore.editDelCont}
            updateVal={this.updateDelContMain}
            opts={[
              "Dedicated Staff",
              "Dedicated Staff - Monthly",
              "Daily Per Truck",
              "Fixed Per Type",
              "Fixed Per Trip",
            ]}
          />
          <Div14>Pricing Method:</Div14>
          {delpricing}
          {deladditional}
          <EditTimingSection updateVal={this.updateDelContTime} />
          <br />
          <EditOptions updateVal={this.updateOptions} />
        </div>
      );
    }

    return (
      <div
        style={{
          padding: "2%",
          border: "1px solid black",
          borderRadius: "4px",
        }}
      >
        <Div14>Delivery Contract</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              icon={<Icon icon="cube" />}
              appearance={newdata.adel ? "primary" : "ghost"}
              color="green"
              onClick={() => {
                if (newdata.adel) {
                  newAccountStore.editAccount(false, "ast");
                }
                newAccountStore.editAccount(!newdata.adel, "adel");
              }}
              block
            >
              {newdata.adel ? "Remove" : "Add"} Delivery Contract
            </IconButton>
            {newdata.adel !== data.adel && (
              <div>
                <FlexboxGrid justify="center">
                  <Col md={24} sm={24} xs={24}>
                    <HelpBlock
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >{`${
                      data.adel ? "De-Activated" : "Activated"
                    } Deliveries Contract`}</HelpBlock>
                  </Col>
                  <Col2>
                    <IconButton
                      icon={<Icon icon="undo" />}
                      onClick={() =>
                        newAccountStore.editAccount(data.adel, "adel")
                      }
                      block
                      color="red"
                    >
                      Undo Changes
                    </IconButton>
                  </Col2>
                  <Col2>
                    <IconButton
                      icon={<Icon icon="save" />}
                      onClick={() => this.props.updateAcc("adel")}
                      block
                      color="green"
                    >
                      Save Changes
                    </IconButton>
                  </Col2>
                </FlexboxGrid>
              </div>
            )}
          </Col2>
        </FlexboxGrid>
        {data.adel && delmain}
      </div>
    );
  }
}

export default observer(EditDelCont);
