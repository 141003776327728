import React, { Component } from "react";

import {
  DataSheetGrid,
  floatColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";

import "react-datasheet/lib/react-datasheet.css";
import { observer } from "mobx-react";

class AddBulkList extends Component {
  render() {
    const columns = [
      { ...keyColumn("name", textColumn), title: "Item Name*" },
      { ...keyColumn("serialnumber", textColumn), title: "Serial No.*" },
      { ...keyColumn("length", floatColumn), title: "L (cm)" },
      { ...keyColumn("width", floatColumn), title: "W (cm)" },
      { ...keyColumn("height", floatColumn), title: "H (cm)" },
      { ...keyColumn("quantity", floatColumn), title: "Qty*" },
    ];

    return (
      <DataSheetGrid
        value={this.props.grid}
        onChange={(e) => this.props.editGrid(e)}
        columns={columns}
        autoAddRow
        style={{ backgroundColor: "red" }}
        createRow={() => ({
          name: "",
          serialnumber: "",
          length: 1,
          width: 1,
          height: 1,
          quantity: 1,
        })}
      />
    );
  }
}

export default observer(AddBulkList);
