import React, { Component } from "react";
import { FlexboxGrid, Col } from "rsuite";

import { Collapse } from "antd";
import { observer } from "mobx-react";
import ServicesSection from "./Supporting/ServicesSection";
import PackingListSummary from "./Supporting/PackingListSummary";
import JobDateSummary from "./Supporting/JobDateSummary";
import LMSummary from "./SubSections/LMSummary";
import { StatusColorCalc } from "../../../../../Functions/Colors/StatusColorCalc";
import { LMPriceCalc } from "../../../../../Functions/Pricing/LMPriceCalc";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

import { CCPriceCalc } from "../../../../../Functions/Pricing/CCPriceCalc";
import CCSummary from "./SubSections/CCSummary";
import { IMPriceCalc } from "../../../../../Functions/Pricing/IMPriceCalc";
import IMSummary from "./SubSections/IMSummary";
import { STPriceCalc } from "../../../../../Functions/Pricing/STPriceCalc";
import moment from "moment";
import STSummary from "./SubSections/STSummary";
import userStore from "../../../../../Stores/UserStore";
const { Panel } = Collapse;

class SJSection extends Component {
  render() {
    let data = this.props.data;

    let backColor = StatusColorCalc(data.status);
    let price;
    let finalDetails;
    let panelheader;
    let numId = data[`${data.jobtype.toLowerCase()}NumId`];

    if (data.jobtype === "LM") {
      price = LMPriceCalc(data);
      panelheader = (
        <>
          <b>
            {data.jobtype}-{numId} {data.status} {data.cbm} CBM:{" "}
          </b>
          {PRKD(price[1])} KD + {PRKD(price[2])} KD = {PRKD(price[0])} KD
        </>
      );
      finalDetails = (
        <>
          <Col md={8} sm={12} xs={24}>
            <LMSummary sj={data} />
            <ServicesSection data={data} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <JobDateSummary jds={data.jobdate} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <PackingListSummary items={data.items} />
          </Col>
        </>
      );
    } else if (data.jobtype === "ST") {
      price = STPriceCalc(data);
      panelheader = (
        <>
          <b>
            {data.jobtype}-{numId} {data.status} ({data.storagestatus}){" "}
            {data.cbm} CBM:{" "}
          </b>
          {PRKD(price[1])} KD + {PRKD(price[2])} KD = {PRKD(price[0])} KD (
          {price[4]} {price[5]}) starting{" "}
          {moment(data.start).format("DD-MMM-YY")}
        </>
      );
      finalDetails = (
        <>
          <Col md={8} sm={12} xs={24}>
            <STSummary sj={data} />
            <ServicesSection data={data} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <JobDateSummary jds={data.jobdate} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <PackingListSummary items={data.items} />
          </Col>
        </>
      );
    } else if (data.jobtype === "IM") {
      price = IMPriceCalc(data);
      panelheader = (
        <>
          <b>
            {data.jobtype}-{numId} {data.status} ({data.im.direction}){" "}
            {data.cbm} CBM:{" "}
          </b>
          {PRKD(price[1])} KD + {PRKD(price[2])} KD = {PRKD(price[0])} KD
        </>
      );
      finalDetails = (
        <>
          <Col md={8} sm={12} xs={24}>
            <IMSummary sj={data} section={"Pricing"} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <JobDateSummary jds={data.jobdate} />
            <IMSummary sj={data} section={"Tracking"} />
            {userStore.user.jobs && <IMSummary sj={data} section={"Quotes"} />}
          </Col>
          <Col md={8} sm={12} xs={24}>
            <ServicesSection data={data} />
            <PackingListSummary items={data.items} />
          </Col>
        </>
      );
    } else if (data.jobtype === "CC") {
      price = CCPriceCalc(data);
      panelheader = (
        <>
          <b>
            {data.jobtype}-{numId} {data.status} {data.cbm} CBM:{" "}
          </b>
          {PRKD(price[1])} KD + {PRKD(price[2])} KD = {PRKD(price[0])} KD
        </>
      );
      finalDetails = (
        <>
          <Col md={8} sm={12} xs={24}>
            <CCSummary data={data} section={"Pricing"} />
            <CCSummary data={data} section={"Attachment"} />
            <JobDateSummary jds={data.jobdate} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <CCSummary data={data} section={"General"} />
          </Col>
          <Col md={8} sm={12} xs={24}>
            <ServicesSection data={data} />
            <PackingListSummary items={data.items} />
          </Col>
        </>
      );
    }

    if (this.props.short) {
      return finalDetails;
    }

    return (
      <div>
        <Collapse>
          <Panel
            style={{ backgroundColor: backColor }}
            header={panelheader}
            key="1"
          >
            <div style={{ fontSize: 12 }}>
              <FlexboxGrid justify="space-around" align="top">
                {finalDetails}
              </FlexboxGrid>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default observer(SJSection);
