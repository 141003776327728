import { observer } from "mobx-react";
import React, { Component } from "react";

import { Badge, Descriptions, Tag } from "antd";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class OrderSummaryTable extends Component {
  render() {
    let aim = this.props.aim;

    let account = this.props.account;

    let statuscolor = "green";
    let statustype = "default";
    if (aim.status === "Requested") statuscolor = "orange";
    if (aim.status === "Booked") statuscolor = "blue";
    if (aim.status === "In Transit") {
      statuscolor = "blue";
      statustype = "processing";
    }
    if (aim.status === "Completed") statuscolor = "green";
    if (aim.status === "Cancelled") statuscolor = "red";

    return (
      <div>
        <Descriptions
          title={this.props.hidetitle ? false : "Order Summary"}
          bordered
          size="small"
        >
          <Descriptions.Item label="AIM Order ID">
            {aim.aimOrderNumId}
          </Descriptions.Item>
          <Descriptions.Item label="Method">
            {aim.shippingmethod}
          </Descriptions.Item>
          <Descriptions.Item label="Type">{aim.direction}</Descriptions.Item>
          <Descriptions.Item label="Subtype">{aim.subtype}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={statuscolor} style={{ fontWeight: "bold" }}>
              <Badge
                status={statustype}
                style={{ color: statuscolor }}
                color={statuscolor}
                text={aim.status}
              />
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Client">
            {account.clientNumId} - {account.name}
          </Descriptions.Item>
          <Descriptions.Item label="From">{aim.shipfrom}</Descriptions.Item>
          <Descriptions.Item label="To">{aim.shipto}</Descriptions.Item>

          {!this.props.hidecost && (
            <Descriptions.Item
              label="Total Job Cost"
              span={24}
              labelStyle={{ fontWeight: "bold" }}
              contentStyle={{ fontWeight: "bold" }}
            >
              {aim.charges
                ? aim.charges.finalcost
                  ? PRKD(aim.charges.finalcost)
                  : 0
                : 0}{" "}
              KD
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  }
}

export default observer(OrderSummaryTable);
