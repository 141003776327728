import React, { Component } from "react";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton, Col, InputGroup } from "rsuite";
import { Notification, Input } from "rsuite";
import axios from "axios";
import { Select } from "antd";

import moment from "moment";
import imSupplierStore from "../../../Stores/AdditionalStores/IMSupplierStore";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import ParagraphLoader from "../../../Components/Loaders/ParagraphLoader";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import allcountrieslist from "../../../Static/Lists/imlists/AllCountries";
import imsuppliertypes from "../../../Static/Lists/imlists/imsuppliertypes";

const { Option } = Select;

const instance = axios.create({ baseURL: baseURL });

class SupplierTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      selectedSupplier: { company: "", type: "" },
      editedSupplier: { company: "", type: "" },
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNewSupplier = this.postNewSupplier.bind(this);
  }

  changeVal(label, val) {
    let supplier = { ...this.state.editedSupplier };
    supplier[label] = val;
    this.setState({ editedSupplier: supplier });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ showEdit: val });
    } else {
      this.setState({
        selectedSupplier: { company: "", type: "" },
        editedSupplier: { company: "", type: "" },
        showEdit: false,
      });
    }
  }

  postNewSupplier = async (supplier) => {
    this.changeVal("loading", true);

    return instance
      .put(`/imadditional/supplier/edit/${supplier._id}`, supplier, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        imSupplierStore.getSuppliers();
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Updated IM Supplier ${supplier.company}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    if (imSupplierStore.loading) {
      return <ParagraphLoader />;
    }

    let data = this.props.supplier;
    let tableTitle = "IM Supplier List";

    let columnnames = [
      { title: "Company", field: "company", defaultSort: "asc" },
      { title: "Country", field: "country" },
      { title: "City", field: "city" },
      { title: "Type", field: "type" },
      { title: "Name", field: "contactname" },
      { title: "Number", field: "contactnumber" },
      { title: "Email", field: "contactemail" },
      { title: "Local Office", field: "kuwaitoffice" },
      { title: "Org.", field: "organization" },
      { title: "Rating", field: "rating" },
      { title: "Remark", field: "remarks" },
    ];

    let disabled = false;
    if (this.state.selectedSupplier === this.state.editedSupplier) {
      disabled = true;
    }
    if (this.state.editedSupplier.company === "") disabled = true;
    if (this.state.editedSupplier.city === "") disabled = true;
    if (this.state.editedSupplier.contactname === "") disabled = true;
    if (this.state.editedSupplier.contactnumber === "") disabled = true;
    if (this.state.editedSupplier.contactemail === "") disabled = true;

    let countryoptions = allcountrieslist.map((val, index) => (
      <Option value={val.name} key={index}>
        {val.name}
      </Option>
    ));
    let supplieroptions = imsuppliertypes.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));

    return (
      <div>
        <UnifiedModal
          show={this.state.showEdit}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={disabled}
                    loading={this.state.loading}
                    onClick={() =>
                      this.postNewSupplier(this.state.editedSupplier)
                    }
                  >
                    Save Changes
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h5>Update IM Supplier Details</h5>
          <InputGroup>
            <InputGroup.Addon>Company</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedSupplier.company}
              onChange={(e) => this.changeVal("company", e)}
            />
            {this.state.editedSupplier.company === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
            {this.state.editedSupplier.company !==
              this.state.selectedSupplier.company && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Country</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.editedSupplier.country}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("country", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countryoptions}
            </Select>
            {this.state.editedSupplier.country !==
              this.state.selectedSupplier.country && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>City</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedSupplier.city}
              onChange={(e) => this.changeVal("city", e)}
            />
            {this.state.editedSupplier.city === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
            {this.state.editedSupplier.city !==
              this.state.selectedSupplier.city && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Type</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.editedSupplier.type}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("type", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {supplieroptions}
            </Select>
            {this.state.editedSupplier.type !==
              this.state.selectedSupplier.type && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Name</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedSupplier.contactname}
              onChange={(e) => this.changeVal("contactname", e)}
            />
            {this.state.editedSupplier.contactname === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
            {this.state.editedSupplier.contactname !==
              this.state.selectedSupplier.contactname && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Number</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedSupplier.contactnumber}
              onChange={(e) => this.changeVal("contactnumber", e)}
            />
            {this.state.editedSupplier.contactnumber === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
            {this.state.editedSupplier.contactnumber !==
              this.state.selectedSupplier.contactnumber && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Email</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedSupplier.contactemail}
              onChange={(e) => this.changeVal("contactemail", e)}
            />
            {this.state.editedSupplier.contactemail === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
            {this.state.editedSupplier.contactemail !==
              this.state.selectedSupplier.contactemail && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Kuwait Office</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.editedSupplier.kuwaitoffice}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("kuwaitoffice", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"Yes"}> Yes </Option>
              <Option value={"No"}> No </Option>
            </Select>
            {this.state.editedSupplier.kuwaitoffice !==
              this.state.selectedSupplier.kuwaitoffice && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Rating</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.editedSupplier.rating}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("rating", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"Good"}> Good </Option>
              <Option value={"Slow"}> Slow </Option>
              <Option value={"Expensive"}> Expensive </Option>
            </Select>
            {this.state.editedSupplier.rating !==
              this.state.selectedSupplier.rating && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Organization</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.editedSupplier.organization}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("organization", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"None"}> None </Option>
              <Option value={"ARA"}> ARA </Option>
              <Option value={"AMSA"}> AMSA </Option>
              <Option value={"FIATA"}> FIATA </Option>
              <Option value={"FIDI"}> FIDI </Option>
              <Option value={"IAM"}> IAM </Option>
              <Option value={"Other"}> Other </Option>
            </Select>
            {this.state.editedSupplier.organization !==
              this.state.selectedSupplier.organization && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Remarks</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedSupplier.remarks}
              onChange={(e) => this.changeVal("remarks", e)}
            />
            {this.state.editedSupplier.remarks !==
              this.state.selectedSupplier.remarks && (
              <InputGroup.Addon>
                <Icon icon="check-circle" style={{ color: "green" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
        </UnifiedModal>

        {!imSupplierStore.loading && (
          <MaterialTable
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  this.setState({ selectedSupplier: rowData });
                  this.setState({ editedSupplier: rowData });
                  this.showHideModal(true);
                },
              },
              {
                icon: "refresh",
                isFreeAction: true,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  imSupplierStore.getSuppliers(true);
                },
              },
            ]}
          />
        )}
        <br />
      </div>
    );
  }
}

export default withRouter(observer(SupplierTable));
