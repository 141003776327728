import { computed, makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import moment from "moment";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Notification } from "rsuite";
import { Parsed } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class DelInvoiceStore {
  constructor() {
    this.newdelinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: "",
      clientname: "",
      clientemail: "",
      clientphone: "",
      delInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
    this.recs = [];
    this.delinvoices = [];
    this.charges = [];
    this.client = { storageContract: {}, _id: "" };
    this.payments = [];
    this.loading = false;
    this.cancelled = false;
    this.finalinvoice = {
      delInvoiceNumId: "",
      clientname: "",
      clientemail: "",
      clientphone: "",
      title: "",
      date: "",
      status: "",
      remarks: "",
      client: "",
      showBank: "",
      invnotes: "",
      charges: [],
    };

    makeObservable(this, {
      delinvoices: observable,
      newdelinvoice: observable,
      recs: observable,
      charges: observable,
      payments: observable,
      finalinvoice: observable,

      client: observable,
      loading: observable,
      cancelled: observable,
      newrecs: computed,
      totalinvandpays: computed,
    });
  }

  // FETCH
  getInvoiceAndPaymentByClient(newclientid, overide) {
    let clid = this.client._id;
    if (newclientid) clid = newclientid;
    if (!overide) {
      if (this.loading) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/delinvoice/getinvoiceandpaymentbyclient/${clid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.delinvoices = res.data.invoices;
        this.client = res.data.client;
        this.payments = res.data.payments;
        this.loading = false;

        console.log("Del Invoices and Payments Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getInvoiceByClient(newclientid, overide) {
    let clid = this.client._id;
    if (newclientid) clid = newclientid;
    if (!overide) {
      if (this.loading) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/delinvoice/getbyclient/${clid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.delinvoices = res.data.invoices;
        this.client = res.data.client;
        this.cancelled = false;
        this.loading = false;
        console.log("Del Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getRemainingInvoiceItems(clientid, overide) {
    if (!overide) {
      if (this.loading || this.clientid === clientid) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/delinvoice/getremaining/${clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.delinvoices = res.data.invoices;
        this.recs = res.data.remaining;
        this.client = res.data.client;
        this.loading = false;
        console.log("Del Remaining Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getDelInvoiceById(acinvid) {
    this.loading = true;
    return instance
      .get(`/jf/delinvoice/getbydelinvid/${acinvid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.newdelinvoice = res.data.invoice;
        this.recs = res.data.remaining;
        this.client = res.data.client;
        this.loading = false;
        console.log("Del Remaining Invoices Fetched By ID");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getCancelledByClient() {
    this.loading = true;
    return instance
      .get(`/jf/delinvoice/cancelledbyclient/${this.client._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.cancelled = true;
        this.delinvoices = [...this.delinvoices, ...res.data];
        this.loading = false;
        console.log("Del Cancelled Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getPaymentsByClient(clientid) {
    this.loading = true;
    return instance
      .get(`/jf/delinvoice/getinvoiceandpaymentbyclient/${clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.delinvoices = res.data.invoices;
        this.client = res.data.client;
        this.payments = res.data.payments;
        this.loading = false;
        console.log("Del Payments Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  createNewInvoice() {
    this.newdelinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: this.client._id,
      clientname: this.client.name,
      clientemail: this.client.email,
      clientphone: this.client.phone,
      delInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
  }

  selectInvoice(inv) {
    this.newdelinvoice = { ...inv };
  }

  editInvoice(val, label) {
    delInvoiceStore.newdelinvoice[label] = val;
  }

  changeCharge(chg) {
    let newchg;
    if (chg.selected) {
      newchg = this.newdelinvoice.charges.filter(
        (oldchg) =>
          moment(oldchg.startday).format("DD-MMM-YY") !==
          moment(chg.startday).format("DD-MMM-YY")
      );
    } else {
      let newchgs = [...this.newdelinvoice.charges, chg];
      newchg = newchgs.sort((a, b) => moment(a.startday) - moment(b.startday));
    }
    this.newdelinvoice.charges = [...newchg];
  }

  editFinalInvoice(data) {
    let charges = [];
    let singlecharge = {};
    let deliveryids = [];
    let deliverys = [];
    let singledel = {};

    for (let i = 0; i < data.charges.length; i++) {
      deliverys = [];
      deliveryids = [];

      if (data.charges[i].deliverys) {
        if (data.charges[i].deliverys.length > 0) {
          for (let j = 0; j < data.charges[i].deliverys.length; j++) {
            singledel = {
              deliveryNumId: data.charges[i].deliverys[j].deliveryNumId,
              jobdate: data.charges[i].deliverys[j].jobdate,
              status: data.charges[i].deliverys[j].status,
              dropoffs: data.charges[i].deliverys[j].toaddress.length,
              additional: data.charges[i].deliverys[j].charges.additional,
              cancel: data.charges[i].deliverys[j].charges.cancel,
              discount: data.charges[i].deliverys[j].charges.discount,
              location: data.charges[i].deliverys[j].charges.location,
              main: data.charges[i].deliverys[j].charges.main,
              option: data.charges[i].deliverys[j].charges.option,
              totaljob: data.charges[i].deliverys[j].charges.totaljob,
            };
            deliveryids = [...deliveryids, data.charges[i].deliverys[j]._id];
            deliverys = [...deliverys, singledel];
          }
        }
      }

      singlecharge = {
        startday: data.charges[i].startday,
        endday: data.charges[i].end,
        additionalchg: data.charges[i].additionalchg,
        discountchg: data.charges[i].discountchg,
        locationchg: data.charges[i].locationchg,
        mainchg: data.charges[i].mainchg,
        missingtruckjob: data.charges[i].missingtruckjob,
        minchg: data.charges[i].minchg,
        finalcost: data.charges[i].finalcost,
        optionchg: data.charges[i].optionchg,
        totaljobchg: data.charges[i].totaljobchg,
        deliveryids: deliveryids,
        deliverys: deliverys,
        missing: data.charges[i].missing,
      };
      charges = [...charges, singlecharge];
    }

    let final = {
      delInvoiceNumId: data.delInvoiceNumId,
      clientname: data.clientname,
      clientemail: data.clientemail,
      clientphone: data.clientphone,
      title: data.title,
      date: data.date,
      status: data.status,
      remarks: data.remarks,
      client: data.client,
      showBank: data.showBank,
      invnotes: data.invnotes,
      charges: charges,
    };
    this.finalinvoice = final;
  }

  get newrecs() {
    let single = {};
    let final = [];
    for (let i = 0; i < this.recs.length; i++) {
      single = { ...this.recs[i], selected: false };
      for (let j = 0; j < this.newdelinvoice.charges.length; j++) {
        if (
          moment(this.recs[i].startday).format("DD-MMM-YY") ===
          moment(this.newdelinvoice.charges[j].startday).format("DD-MMM-YY")
        ) {
          single.selected = true;
        }
      }
      final = [...final, single];
    }
    return final;
  }

  get totalinvandpays() {
    let invs = this.delinvoices.filter((inv) => inv.status !== "Cancelled");

    let totalinvoiced = 0;
    for (let i = 0; i < invs.length; i++) {
      totalinvoiced += Parsed(invs[i].tfinalcost);
    }
    let totalpaid = 0;
    for (let i = 0; i < this.payments.length; i++) {
      totalpaid += Parsed(this.payments[i].amount);
    }
    return { totalinv: totalinvoiced, totalpaid: totalpaid };
  }
}

const delInvoiceStore = new DelInvoiceStore();
export default delInvoiceStore;
