import { observer } from "mobx-react";
import React, { Component } from "react";

import axios from "axios";

import { message } from "antd";

import {
  Col,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import baseURL from "../../../../../Static/baseURL/baseURL";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import userStore from "../../../../../Stores/UserStore";

import locationtypelist from "../../../../../Static/Lists/LocationType";

import zoneStore from "../../../../../Stores/Admin/Lists/ZoneStore";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import Col4 from "../../../../../Components/Columns/Col4";

const instance = axios.create({ baseURL: baseURL });

class LocationModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, show: false };
    this.updateLocation = this.updateLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({ show: false });
  }
  updateLocation = async () => {
    this.setState({ loading: true });

    let data = this.props.loc;
    if (PRKD(data.floor) !== 0) {
      data.ngfchg = newStorageStore.delCont.nongroundfloorprice;
    } else {
      data.ngfchg = 0;
    }

    let zoneind = zoneStore.zones.findIndex((zone) => zone.name === data.city);
    if (zoneind >= 0) {
      data.zonetype = zoneStore.zones[zoneind].zone;
      data.zonecost = newStorageStore.delCont.zoneadd[`z${data.zonetype}`];
    }

    if (data._id === "New") {
      data.acorder = newStorageStore.neworder._id;
      await instance
        .put(`ac/location/newstolocation`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          newStorageStore.getSingleACOrder(false, true);
          this.props.onHide();
          message.success(`New Location Added`);
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });

          Notification["error"]({ title: `An error occured` });
        });
    } else {
      await instance
        .put(`ac/location/editlocation/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          newStorageStore.getSingleACOrder(false, true);
          this.props.onHide();
          message.success(`Location Updated`);
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });

          Notification["error"]({ title: `An error occured` });
        });
    }
  };
  deleteLocation = async () => {
    this.setState({ loading: true });

    let data = {};
    await instance
      .put(`ac/location/deletelocation/${this.props.loc._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.props.onHide();
        message.success(`Location Deleted`);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let loc = this.props.loc;
    let org = this.props.org;

    let inout = newStorageStore.delstoinout.inout;

    let hlabel = "From";
    if (inout === "Out") hlabel = "To";

    let disabled = false;
    if (loc.type === "") disabled = true;
    if (loc.name === "") disabled = true;
    if (loc.phone === "") disabled = true;
    if (loc.city === "") disabled = true;
    if (loc.floor === "") disabled = true;
    if (loc.address === "") disabled = true;
    let changed = false;
    if (loc.type !== org.type) changed = true;
    if (loc.name !== org.name) changed = true;
    if (loc.phone !== org.phone) changed = true;
    if (loc.city !== org.city) changed = true;
    if (loc.floor !== org.floor) changed = true;
    if (loc.address !== org.address) changed = true;

    let zchg = 0;
    let ztype;
    let zoneind = zoneStore.zones.findIndex((zone) => zone.name === loc.city);
    if (zoneind >= 0) {
      ztype = zoneStore.zones[zoneind].zone;
      zchg = newStorageStore.delCont.zoneadd[`z${ztype}`];
    }
    let fchg = 0;
    if (PRKD(loc.floor) !== 0) {
      fchg = newStorageStore.delCont.nongroundfloorprice;
    }

    return (
      <div>
        <UnifiedModal
          title="Delete Location"
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() => this.deleteLocation()}
              disabled2={loc.acitemmove.length > 0}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          {loc.acitemmove.length > 0 ? (
            <div>
              {" "}
              There are {loc.acitemmove.length} Items attached to this location.
              <br />
              <b>
                Please Delete these items from the Items List to delete the
                location.
              </b>
              <div style={{ paddingLeft: "4%" }}>
                {loc.acitemmove.map((it, index) => (
                  <>
                    <b>{it.acitem.name}</b> (Qty: {it.actualquantity})
                    <br />
                  </>
                ))}
              </div>
            </div>
          ) : (
            "Are you sure you want to Delete this location?"
          )}
        </UnifiedModal>
        {loc._id !== "New" && (
          <FlexboxGrid justify="end">
            <Col4>
              <IconButton
                icon={<Icon icon="trash" />}
                color="red"
                onClick={() => this.setState({ show: true })}
                block
              >
                Delete Location
              </IconButton>
            </Col4>
          </FlexboxGrid>
        )}
        <FlexboxGrid justify="center">
          <Col md={24} sm={24} xs={24}>
            <div style={{ padding: "2%" }}>
              <SelectInputSTD
                title="Address Type"
                val={loc.type}
                label="type"
                size="large"
                opts={locationtypelist}
                editVal={this.props.editVal}
              />
              <br />
              <TextInputSTD
                title={`${hlabel} Name`}
                val={loc.name}
                label="name"
                size="lg"
                editVal={this.props.editVal}
              />
              <br />
              <TextInputSTD
                title={`${hlabel} Phone`}
                val={loc.phone}
                label="phone"
                size="lg"
                editVal={this.props.editVal}
              />
              <br />
              <SelectInputSTD
                title="City"
                val={loc.city}
                label="city"
                size="large"
                opts={zoneStore.zones.map((zone) => zone.name)}
                editVal={this.props.editVal}
              />
              <HelpBlock
                style={{
                  textAlign: "center",
                  color: zchg > 0 ? "red" : "green",
                }}
              >
                {zchg > 0 &&
                  `${loc.city} in Zone ${ztype} (+ ${PRKD(
                    zchg
                  )} KD Zone Delivery Charge)`}
              </HelpBlock>
              <br />
              <NumbInputSTD
                title="Floor"
                val={loc.floor}
                label="floor"
                size="large"
                min={-9}
                editVal={this.props.editVal}
              />
              {fchg > 0 && (
                <HelpBlock style={{ color: "red", textAlign: "center" }}>
                  Non-Ground Floor Charge (+ {PRKD(fchg)} KD)
                </HelpBlock>
              )}
              <br />
              <TextAreaInputSTD
                title="Address"
                val={loc.address}
                label="address"
                size="large"
                editVal={this.props.editVal}
              />
            </div>
          </Col>
        </FlexboxGrid>
        <hr />
        <ModalFooter
          icon={["close", "save"]}
          color={["red", "green"]}
          app={["ghost", "default"]}
          text={[
            "Cancel Changes",
            loc._id === "New" ? "Add New Location" : "Save Changes",
          ]}
          oC1={() => this.props.onHide()}
          oC2={() => this.updateLocation()}
          disabled2={disabled || !changed}
          loading1={this.state.loading}
          loading2={this.state.loading}
        />
      </div>
    );
  }
}

export default observer(LocationModal);
