import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { Tabs } from "antd";
import Div14 from "../../../../Components/Dividers/Div14";

import IncomeData from "../Tabs/IncomeData";
import ExpenseData from "../Tabs/ExpenseData";

const { TabPane } = Tabs;
class EMOverviewMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Income" };
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        <Div14>Financial Tabs</Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Income"} key={"Income"}>
            <IncomeData />
          </TabPane>
          <TabPane tab={"Expense"} key={"Expense"}>
            <ExpenseData />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(EMOverviewMain));
