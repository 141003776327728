import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class WHMPalletListStore {
  constructor() {
    this.prepared = [];
    this.loadpallet = [];
    this.unloadpallet = [];
    this.stacked = [];
    this.loading = {
      prepared: false,
      loadpallet: false,
      unloadpallet: false,
      stacked: false,
    };
    this.loaded = {
      prepared: false,
      loadpallet: false,
      unloadpallet: false,
      stacked: false,
    };

    makeObservable(this, {
      prepared: observable,
      loadpallet: observable,
      unloadpallet: observable,
      stacked: observable,
      loading: observable,
      loaded: observable,
      preparedstats: computed,
      loadedstats: computed,
      unloadedstats: computed,
      stackedstats: computed,
    });
  }

  // FETCH
  getPreparedPallets(override) {
    let loaded;
    if (this.loading.prepared || this.loaded.prepared) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.prepared = true;
      return instance
        .get(`/whm/pallets/preparedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.prepared = data;
          this.loading.prepared = false;
          this.loaded.prepared = true;
          console.log("WHM Prepared Pallets Fetched");
        })
        .catch((err) => {
          this.loading.prepared = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getLoadedPallets(override) {
    let loaded;
    if (this.loading.loadpallet || this.loaded.loadpallet) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.loadpallet = true;
      return instance
        .get(`/whm/pallets/loadedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.loadpallet = data;
          this.loading.loadpallet = false;
          this.loaded.loadpallet = true;
          console.log("WHM Loaded Pallets Fetched");
        })
        .catch((err) => {
          this.loading.loadpallet = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getUnLoadedPallets(override) {
    let loaded;
    if (this.loading.unloadpallet || this.loaded.unloadpallet) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.unloadpallet = true;
      return instance
        .get(`/whm/pallets/unloadedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.unloadpallet = data;
          this.loading.unloadpallet = false;
          this.loaded.unloadpallet = true;
          console.log("WHM Unloaded Pallets Fetched");
        })
        .catch((err) => {
          this.loading.unloadpallet = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getStackedPallets(override) {
    let loaded;
    if (this.loading.stacked || this.loaded.stacked) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.stacked = true;
      return instance
        .get(`/whm/pallets/stackedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.stacked = data;
          this.loading.stacked = false;
          this.loaded.stacked = true;
          console.log("WHM Stacked Pallets Fetched");
        })
        .catch((err) => {
          this.loading.stacked = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get preparedstats() {
    return this.prepared;
  }
  get loadedstats() {
    return this.loadpallet;
  }
  get unloadedstats() {
    return this.unloadpallet;
  }
  get stackedstats() {
    return this.stacked;
  }
}

const whmPalletListStore = new WHMPalletListStore();
export default whmPalletListStore;
