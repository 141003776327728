import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { HelpBlock } from "rsuite";

import NewDamageForm from "./NewDamageForm";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import moment from "moment";
import SelectInputSTU from "../../../../../../Components/Inputs/Updatable/SelectInputSTU";
import DamageReportTable from "./DamageReportTable";
import userStore from "../../../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import { message } from "antd";
import DeleteDRModal from "./DeleteDRModal";
import ClientSectionMain from "./ClientSection/ClientSectionMain";
import DateInputSTU from "../../../../../../Components/Inputs/Updatable/DateInputSTU";

const instance = axios.create({ baseURL: baseURL });

class DamageReportMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: {},
      showdel: false,
    };
    this.updateVal = this.updateVal.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({ showdel: false });
  }
  updateVal = async (label) => {
    let dr = newStorageStore.neworder.damagereport;
    let data = { [label]: newStorageStore.neworder.damagereport[label] };

    let url = `ac/damagereport/edit/${dr._id}`;

    this.setState({ loading: true });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.updateDR(label);
        this.setState({ loading: false });
        message.success("Damage Report Updated");
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("An Error Occured");
      });
  };

  render() {
    if (!newStorageStore.neworder.damagereport) {
      return <NewDamageForm toaddress={newStorageStore.oldorder.toaddress} />;
    }
    let dr = newStorageStore.neworder.damagereport;
    let olddr = newStorageStore.oldorder.damagereport;

    return (
      <div>
        <Div14>
          Damage Report #{dr.damageReportNumId} as of{" "}
          {moment(dr.createdAt).format("DD-MMM-YY")}
        </Div14>
        {userStore.user.staffaccount && (
          <p>
            <b>Created By {dr.requester.username} </b> on{" "}
            {moment(dr.createdAt).format("DD-MMM-YY HH:mm")}
          </p>
        )}
        <SelectInputSTU
          val={dr.status}
          org={olddr.status}
          label="status"
          load={this.state.load.status}
          title="Status"
          size="large"
          editVal={newStorageStore.editDR}
          updateVal={this.updateVal}
          opts={["Pending", "Submitted", "Closed"]}
        />
        {userStore.user.staffaccount && (
          <HelpBlock>
            {dr.status === "Pending"
              ? "Report is hidden from client and is under progress."
              : dr.status === "Submitted"
              ? "Report is visible to Client and is pending approval from client."
              : dr.status === "Closed"
              ? "Report is approved by client and closed."
              : ""}
          </HelpBlock>
        )}
        <br />
        <DamageReportTable />
        <br />
        {olddr.status !== "Pending" && (
          <>
            <Div14>Client Section:</Div14>
            <DateInputSTU
              val={dr.notifiedClient}
              org={olddr.notifiedClient}
              label="exitdate"
              load={this.state.load.notifiedClient}
              title="Notified Client On"
              size="sm"
              editVal={newStorageStore.editDR}
              updateVal={this.updateVal}
            />
            <br />
            <ClientSectionMain />
          </>
        )}
        <hr />
        {userStore.user.staffaccount && <DeleteDRModal />}
      </div>
    );
  }
}

export default withRouter(observer(DamageReportMain));
