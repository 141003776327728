import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import { Parsed, PRKD } from "../../Functions/Pricing/PRKDCalc";
import { message } from "antd";
import carStore from "../AdditionalStores/CarStore";
import clientStore from "../ClientStore";
import { Buffer } from "buffer";

const instance = axios.create({ baseURL: baseURL });

class SingleAccountStore {
  constructor() {
    this.clientid = "";
    this.account = { aim: false, acc: false, alm: false, ast: false, _id: "" };
    this.storCont = { _id: "" };
    this.delCont = { _id: "" };
    this.loaded = {
      account: false,
      order: false,
      oldstock: false,
      current: false,
    };
    this.loading = {
      account: false,
      order: false,
      oldstock: false,
      current: false,
    };
    this.selectedorder = { _id: "", services: { timetype: "" } };
    this.currentitem = [];
    this.oldstockitem = [];
    this.neworder = {
      jobtype: "Delivery In",
      addcharge: [],
      location: {
        selected: "New",
        name: "",
        phone: "",
        type: "",
        city: "",
        floor: 0,
        address: "",
      },
      emlocation: { name: "" },
      items: [],
      orderoptions: [],
    };

    makeObservable(this, {
      clientid: observable,
      account: observable,
      delCont: observable,
      storCont: observable,
      loaded: observable,
      loading: observable,
      selectedorder: observable,
      neworder: observable,
      oldstockitem: observable,
      currentitem: observable,
      stordelinout: computed,
      currentitems: computed,
      oldstockitems: computed,
    });
  }

  // FETCH
  getAccount(clientID, override) {
    let loaded = false;
    if ((this.clientid === clientID) & this.loaded.account) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.clientid = clientID;
      this.loaded.account = false;
      this.loaded.oldstock = false;
      this.loaded.current = false;
      this.loading.account = true;
      this.account = {
        aim: false,
        acc: false,
        alm: false,
        ast: false,
        _id: "",
      };

      return instance
        .get(`/ac/account/accountbyclient/${clientID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          if (accounts.account) {
            this.account = accounts.account;
            if (accounts.account.storageContract) {
              this.account.ast = true;
              this.storCont = accounts.account.storageContract;
            }
            if (accounts.account.deliveryContract) {
              this.account.alm = true;
              this.delCont = accounts.account.deliveryContract;
            }
          } else if (!accounts.client) {
            this.account = {
              aim: false,
              acc: false,
              alm: false,
              ast: false,
              _id: "",
            };
          } else {
            this.account = {
              aim: false,
              acc: false,
              alm: false,
              ast: false,
              _id: "",
            };
          }
          this.loaded.account = true;
          this.loading.account = false;
          console.log("Accounts Fetched from Single Account");
        })
        .catch((err) => {
          this.account = {
            aim: false,
            acc: false,
            alm: false,
            ast: false,
            _id: "",
          };
          this.loading.account = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getSingleOrder(acorderid, override) {
    let loaded = false;
    if ((this.selectedorder._id === acorderid) & this.loaded.order) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.selectedorder._id = acorderid;
      this.loaded.order = false;
      this.loading.order = true;

      return instance
        .get(`/ac/warehouserequest/acorder/${acorderid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((sto) => {
          let order = { ...sto };
          let items = [];
          for (let i = 0; i < sto.acitemmove.length; i++) {
            items = [
              ...items,
              {
                itemmove: sto.acitemmove[i]._id,
                qty: sto.acitemmove[i].quantity,
                cbm: PRKD(
                  Parsed(sto.acitemmove[i].acitem.cbm) *
                    Parsed(sto.acitemmove[i].quantity)
                ),
                dimension: `${Parsed(sto.acitemmove[i].acitem.length)}x${Parsed(
                  sto.acitemmove[i].acitem.width
                )}x${Parsed(sto.acitemmove[i].acitem.height)}`,
                ...sto.acitemmove[i].acitem,
              },
            ];
          }
          order.items = [...items];
          let buffereddata;
          let b64;
          if (order.delSignature) {
            buffereddata = order.delSignature.signature;
            b64 = new Buffer(buffereddata).toString("base64");
            order.delsig = `data:image/png;base64,${b64}`;
          }
          if (order.storSignature) {
            buffereddata = order.storSignature.signature;
            b64 = new Buffer(buffereddata).toString("base64");
            order.stosig = `data:image/png;base64,${b64}`;
          }

          singleAccountStore.selectedorder = { ...order };
          singleAccountStore.neworder = { ...order };
          this.loaded.order = true;
          this.loading.order = false;
          this.getAccount(order.account.client, true);
          clientStore.getSingleClients(order.account.client);

          if (order.status === "In Transit") {
            carStore.selectCar(order.trucktrack.truckapi);
          }

          console.log("Order Fetched");
        })
        .catch((err) => {
          this.order = {
            _id: "",
          };
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getOldItems(override, accountid, locationid) {
    let loaded = false;
    if (this.loaded.oldstock) {
      loaded = true;
    }

    if (override) loaded = false;

    let acorderid = this.account._id;
    if (accountid) acorderid = accountid;
    if (!loaded) {
      this.loaded.oldstock = false;
      this.loading.oldstock = true;
      let url = `/ac/itemsrequest/oldstock/${acorderid}`;
      if (locationid) {
        url = `/ac/itemsrequest/oldstockloc/${locationid}/${acorderid}`;
      }
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((sto) => {
          this.oldstockitem = [...sto];

          this.loaded.oldstock = true;
          this.loading.oldstock = false;

          console.log("Old Items Fetched");
        })
        .catch((err) => {
          this.oldstockitem = [];
          this.loading.oldstock = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Old Items.`,
          });
        });
    }
  }
  getCurrentItems(override, accountid, locationid) {
    let loaded = false;
    if (this.loaded.current) {
      loaded = true;
    }

    if (override) loaded = false;

    let acorderid = this.account._id;
    if (accountid) acorderid = accountid;
    if (!loaded) {
      this.loaded.current = false;
      this.loading.current = true;
      let url = `/ac/itemsrequest/current/${acorderid}`;
      if (locationid) {
        url = `/ac/itemsrequest/currentloc/${locationid}/${acorderid}`;
      }
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((sto) => {
          this.currentitem = [...sto];

          this.loaded.current = true;
          this.loading.current = false;

          console.log("Existing Items Fetched");
        })
        .catch((err) => {
          this.currentitem = [];
          this.loading.current = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Existing Items.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get stordelinout() {
    let stordel = "Delivery";
    let inout = "Out";
    let jobtype = this.neworder.jobtype;
    if (jobtype === "Storage In" || jobtype === "Storage Out") {
      stordel = "Storage";
    }
    if (jobtype === "Storage In" || jobtype === "Delivery In") {
      inout = "In";
    }
    return { stordel: stordel, inout: inout };
  }

  get oldstockitems() {
    let items = this.oldstockitem;
    let final = [];
    let found = false;
    let oitems = this.neworder.items;
    for (let j = 0; j < items.length; j++) {
      for (let i = 0; i < oitems.length; i++) {
        if (oitems[i]._id === items[j]._id) found = true;
      }
      if (!found) final = [...final, items[j]];
      found = false;
    }
    return final;
  }
  get currentitems() {
    let items = this.currentitem;
    let final = [];
    let found = false;
    let oitems = this.neworder.items;
    for (let j = 0; j < items.length; j++) {
      for (let i = 0; i < oitems.length; i++) {
        if (oitems[i]._id === items[j]._id) found = true;
      }
      if (!found) final = [...final, items[j]];
      found = false;
    }
    return final;
  }

  // FUNCTIONS:
  resetOrder() {
    this.neworder = {
      jobtype: "Delivery In",
      location: {
        selected: "New",
        name: "",
        phone: "",
        type: "",
        city: "",
        floor: 0,
        address: "",
      },
      addcharge: [],
      emlocation: { name: "" },
      items: [],

      orderoptions: [],
    };
  }

  changeOrder(val, label) {
    let order = { ...singleAccountStore.neworder };
    order[label] = val;
    singleAccountStore.neworder = { ...order };
  }

  changeLocation(val, label) {
    let order = { ...singleAccountStore.neworder };
    order.location[label] = val;
    singleAccountStore.neworder = { ...order };
  }
  selectLocation(loc, type) {
    singleAccountStore.neworder[type] = loc;
  }

  addNewLocation(data) {
    let locations = [...this.account.locations, data];
    this.account.locations = [...locations];
  }
  editItem(item) {
    let index = item.index;
    let order = { ...singleAccountStore.neworder };
    order.items[index] = item;
    singleAccountStore.neworder = { ...order };
    message.success(`Item Edited (${item.name})`);
  }

  addItem(item) {
    singleAccountStore.neworder.items = [
      ...singleAccountStore.neworder.items,
      item,
    ];
    message.success(`Item Added from Order (${item.name})`);
  }
  removeItem(index) {
    let items = [...singleAccountStore.neworder.items];
    message.warning(`Item Removed from Order (${items[index].name})`);
    let finalitems = [];
    for (let i = 0; i < items.length; i++) {
      if (i !== index) finalitems = [...finalitems, items[i]];
    }
    singleAccountStore.neworder.items = [...finalitems];
  }

  updateOption(option) {
    let order = { ...singleAccountStore.neworder };

    let ind = order.orderoptions.findIndex((opt) => opt.desc === option.desc);
    if (ind < 0) {
      order.orderoptions = [...order.orderoptions, option];
    } else {
      let newopts = [];
      for (let i = 0; i < order.orderoptions.length; i++) {
        if (i !== ind) {
          newopts = [...newopts, order.orderoptions[i]];
        }
      }
      order.orderoptions = [...newopts];
    }
    singleAccountStore.neworder = { ...order };
  }

  addAddCharge() {
    let newchg = { price: 0, name: "" };
    singleAccountStore.neworder.addcharge = [
      ...singleAccountStore.neworder.addcharge,
      newchg,
    ];
  }
  editAddCharge(val, label, index) {
    singleAccountStore.neworder.addcharge[index][label] = val;
  }
  removeAddCharge(ind) {
    let final = [];
    for (let i = 0; i < singleAccountStore.neworder.addcharge.length; i++) {
      if (i !== ind) {
        final = [...final, singleAccountStore.neworder.addcharge[i]];
      }
    }
    singleAccountStore.neworder.addcharge = [...final];
  }

  editSelectedOrderItem(type, item, inout) {
    let ind = -1;
    if (type === "Edit") {
      ind = this.selectedorder.items.findIndex((it) => it._id === item._id);
      this.selectedorder.items[ind].qty = item.qty;
    } else if (type === "Delete") {
      let newitems = [];
      for (let i = 0; i < this.selectedorder.items.length; i++) {
        if (this.selectedorder.items[i]._id !== item._id) {
          newitems = [...newitems, this.selectedorder.items[i]];
        }
      }
      this.selectedorder.items = [...newitems];
    }
  }

  // ----- DATE ----
}

const singleAccountStore = new SingleAccountStore();
export default singleAccountStore;
