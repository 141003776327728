import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid } from "rsuite";
import Div14 from "../../../../../../../../../Components/Dividers/Div14";
import DatePickerSTD from "../../../../../../../../../Components/Inputs/STD/DatePickerSTD";
import SelectInputSTD from "../../../../../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../../../../../Components/Inputs/STD/TextInputSTD";
import acInvoiceStore from "../../../../../../../../../Stores/Financial/ACInvoiceStore";

class Step1 extends Component {
  render() {
    let inv = acInvoiceStore.newacinvoice;

    return (
      <div>
        <Div14>Step 1: Main Info</Div14>
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <SelectInputSTD
              title="Status"
              val={inv.status}
              label="status"
              size="large"
              opts={["Pending", "Paid", "Cancelled"]}
              editVal={acInvoiceStore.editInvoice}
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <TextInputSTD
              title="Title"
              val={inv.title}
              label="title"
              editVal={acInvoiceStore.editInvoice}
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <DatePickerSTD
              title="Invoice Date"
              editVal={acInvoiceStore.editInvoice}
              val={inv.date}
              label="date"
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <TextAreaInputSTD
              title="Inv Remarks"
              val={inv.remarks}
              label="remarks"
              editVal={acInvoiceStore.editInvoice}
            />
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(Step1);
