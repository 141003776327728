import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

import DeliveryTimeRequestTable from "./Supporting/DeliveryTimeRequestTable";

import moment from "moment";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import { TimingCheck } from "../../../../../../Functions/Timing/TimingCheck";

import DeliveryTimePicker from "./Supporting/DeliveryTimePicker";

class SACStep2 extends Component {
  render() {
    let order = newStorageStore.neworder;
    let delCont = newStorageStore.delCont;
    let timechgs = newStorageStore.timechgs;
    

    let earliest = timechgs.earliest;
    let express = timechgs.express;
    let expressaf = timechgs.expressaf;
    let earliestaf = timechgs.earliestaf;

    let hideexpress = false;
    let hideaf = false;
    let hideexpressaf = false;

    if (
      moment(earliest).format("DD-MMM-YY HH:mm A") ===
      moment(express).format("DD-MMM-YY HH:mm A")
    ) {
      hideexpress = true;
    }
    if (
      moment(earliest).format("DD-MMM-YY HH:mm A") ===
      moment(earliestaf).format("DD-MMM-YY HH:mm A")
    ) {
      hideaf = true;
    }

    if (
      moment(earliestaf).format("DD-MMM-YY HH:mm A") ===
        moment(expressaf).format("DD-MMM-YY HH:mm A") ||
      moment(express).format("DD-MMM-YY HH:mm A") ===
        moment(expressaf).format("DD-MMM-YY HH:mm A")
    ) {
      hideexpressaf = true;
    }

    let deliveryCost;

    if (!order.jobdate) {
      deliveryCost = (
        <h4 style={{ color: "red" }}>
          Please select a date to calculate additional costs.
        </h4>
      );
    } else {
      let timcost2 = TimingCheck(delCont, order.jobdate, order.createdAt);

      let fasttime = [];
      if (timcost2[0] & timcost2[1] & !hideexpressaf) {
        fasttime = [
          {
            price: PRKD(
              Parsed(delCont.expressfee) + Parsed(delCont.afterhourfee)
            ),
            title: "Express + After Hour Delivery",
          },
        ];
      } else if (timcost2[1] & !hideaf) {
        fasttime = [
          {
            price: PRKD(delCont.afterhourfee),
            title: "After Hour Delivery",
          },
        ];
      } else if (timcost2[0] & !hideexpress) {
        fasttime = [
          {
            price: PRKD(delCont.expressfee),
            title: "Express Delivery",
          },
        ];
      } else {
        console.log("No Delivery Time Charge");
      }

      if (fasttime.length > 0) {
        deliveryCost = (
          <h4 style={{ color: "green" }}>
            {fasttime[0].title} Time Selected (+ {fasttime[0].price} KD)
          </h4>
        );
      } else {
        deliveryCost = (
          <h4 style={{ color: "green" }}>Regular Delivery Time Selected</h4>
        );
      }
    }

    let finalcharges = newStorageStore.timechgs.charges;
    let totalcharges = 0;
    for (let i = 0; i < finalcharges.length; i++) {
      totalcharges += Parsed(finalcharges[i].value);
    }

    return (
      <div>
        <Div14>Step 2: Select Timing</Div14>
        <hr />
        <FlexboxGrid>
          <Col2>
            <Div14>Select Time:</Div14>
            <DeliveryTimePicker />
            <div style={{ textAlign: "center" }}>{deliveryCost}</div>
            <Div14>{order.jobtype} Initial Charges:</Div14>
            <p style={{ paddingLeft: "5px" }}>
              {newStorageStore.timechgs.charges
                .filter((ch) => ch.show)
                .map((charge, index) => (
                  <div key={index}>
                    <b>{charge.title} = </b> {PRKD(charge.value)} KD
                    <br />
                  </div>
                ))}
            </p>
            {order.jobdate ? (
              <Div14>{PRKD(totalcharges)} KD Total</Div14>
            ) : (
              <div />
            )}
          </Col2>
          <Col2>
            <Div14>Auto Time Options:</Div14>
            <FlexboxGrid justify="center" align="middle">
              <Col2>
                <IconButton
                  icon={<Icon icon="clock-o" />}
                  color="green"
                  block
                  appearance={
                    moment(earliest).format("DD-MMM-YY HH:mm A") !==
                    moment(order.jobdate).format("DD-MMM-YY HH:mm A")
                      ? "ghost"
                      : "default"
                  }
                  onClick={() => newStorageStore.editOrder(earliest, "jobdate")}
                >
                  Select Earliest Date/Time
                </IconButton>
              </Col2>
              <Col2>
                <HelpBlock style={{ textAlign: "center" }}>
                  {moment(earliest).format("DD-MMM-YY HH:mm A")}
                </HelpBlock>
              </Col2>
            </FlexboxGrid>
            <br />
            {!hideexpress && (
              <FlexboxGrid justify="center" align="middle">
                <Col2>
                  <IconButton
                    block
                    icon={<Icon icon="clock-o" />}
                    color="yellow"
                    appearance={
                      moment(express).format("DD-MMM-YY HH:mm A") !==
                      moment(order.jobdate).format("DD-MMM-YY HH:mm A")
                        ? "ghost"
                        : "default"
                    }
                    onClick={() =>
                      newStorageStore.editOrder(express, "jobdate")
                    }
                  >
                    Express (+{PRKD(delCont.expressfee)} KD)
                  </IconButton>
                </Col2>
                <Col2>
                  <HelpBlock style={{ textAlign: "center" }}>
                    {moment(express).format("DD-MMM-YY HH:mm A")}
                    <br />
                    {moment(earliest).from(express, true)} faster
                  </HelpBlock>
                </Col2>
                <br />
              </FlexboxGrid>
            )}

            {!hideaf && (
              <FlexboxGrid justify="center" align="middle">
                <Col2>
                  <IconButton
                    block
                    icon={<Icon icon="clock-o" />}
                    color="yellow"
                    appearance={
                      moment(earliestaf).format("DD-MMM-YY HH:mm A") !==
                      moment(order.jobdate).format("DD-MMM-YY HH:mm A")
                        ? "ghost"
                        : "default"
                    }
                    onClick={() =>
                      newStorageStore.editOrder(earliestaf, "jobdate")
                    }
                  >
                    After Hour (+{PRKD(delCont.afterhourfee)} KD)
                  </IconButton>
                </Col2>
                <Col2>
                  <HelpBlock style={{ textAlign: "center" }}>
                    {moment(earliestaf).format("DD-MMM-YY HH:mm A")}
                    <br />
                    {moment(earliest).from(earliestaf, true)} faster
                  </HelpBlock>
                </Col2>
                <br />
              </FlexboxGrid>
            )}

            {!hideexpressaf && (
              <FlexboxGrid justify="center" align="middle">
                <Col2>
                  <IconButton
                    block
                    icon={<Icon icon="clock-o" />}
                    color="yellow"
                    appearance={
                      moment(expressaf).format("DD-MMM-YY HH:mm A") !==
                      moment(order.jobdate).format("DD-MMM-YY HH:mm A")
                        ? "ghost"
                        : "default"
                    }
                    onClick={() =>
                      newStorageStore.editOrder(expressaf, "jobdate")
                    }
                  >
                    Express + After Hour (+
                    {PRKD(
                      Parsed(delCont.expressfee) + Parsed(delCont.afterhourfee)
                    )}{" "}
                    KD)
                  </IconButton>
                </Col2>
                <Col2>
                  <HelpBlock style={{ textAlign: "center" }}>
                    {moment(expressaf).format("DD-MMM-YY HH:mm A")}
                    <br />
                    {moment(earliest).from(expressaf, true)} faster
                  </HelpBlock>
                </Col2>
                <br />
              </FlexboxGrid>
            )}
          </Col2>
        </FlexboxGrid>
        <br />

        <br />
        <FlexboxGrid justify="center" style={{ textAlign: "center" }}>
          <Col2>
            <Div14>{order.jobtype} Details:</Div14>

            <p>
              <b>Job Type: </b> {order.jobtype} <br />
              <b>Min Request Time: </b> {delCont.timetoreq} Hours
              <br />
              <b>Min Express Request Time: </b> {delCont.expresstime} Hours
              <br />
              <b>Earliest Time To Delivery: </b>{" "}
              {moment(earliest).format("DD-MMM-YY")} at{" "}
              {moment(earliest).format("HH:mm A")}
              <br />
              {moment(earliestaf) < moment(earliest) && (
                <>
                  <b>
                    + {PRKD(delCont.afterhourfee)} KD: AFTER HOURS Time To
                    Delivery:{" "}
                  </b>{" "}
                  {moment(earliestaf).format("DD-MMM-YY")} at{" "}
                  {moment(earliestaf).format("HH:mm A")} (
                  {moment(earliest).from(earliestaf, true)} faster)
                  <br />
                </>
              )}
              {moment(express) < moment(earliest) && (
                <>
                  <b>
                    + {PRKD(delCont.expressfee)} KD: EXPRESS Time To Delivery:{" "}
                  </b>{" "}
                  {moment(express).format("DD-MMM-YY")} at{" "}
                  {moment(express).format("HH:mm A")} (
                  {moment(earliest).from(express, true)} faster)
                  <br />
                </>
              )}
              {moment(expressaf) < moment(earliestaf) && (
                <>
                  <b>
                    +{" "}
                    {PRKD(
                      Parsed(delCont.afterhourfee) + Parsed(delCont.expressfee)
                    )}{" "}
                    KD: EXPRESS + AFTER HOUR Time To Delivery:{" "}
                  </b>{" "}
                  {moment(expressaf).format("DD-MMM-YY")} at{" "}
                  {moment(expressaf).format("HH:mm A")} (
                  {moment(earliest).from(expressaf, true)} faster)
                  <br />
                </>
              )}
            </p>
            <br />
          </Col2>
        </FlexboxGrid>
        <DeliveryTimeRequestTable delCont={delCont} />
      </div>
    );
  }
}

export default observer(SACStep2);
