import { observer } from "mobx-react";
import React, { Component } from "react";
import axios from "axios";
import { Select } from "antd";
import {
  Col,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import { InputGroup } from "rsuite";
import baseURL from "../../../../../Static/baseURL/baseURL";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import userStore from "../../../../../Stores/UserStore";
import Div14 from "../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import locationtypelist from "../../../../../Static/Lists/LocationType";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import zoneStore from "../../../../../Stores/Admin/Lists/ZoneStore";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";
import Col2 from "../../../../../Components/Columns/Col2";

const { Option } = Select;

const instance = axios.create({ baseURL: baseURL });

class DACStep2Supporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saved: false,
    };
    this.saveNewLocation = this.saveNewLocation.bind(this);
  }
  saveNewLocation = async () => {
    this.setState({ loading: true });
    let data = this.props.loc;
    data.client = newDeliveryStore.account._id;

    return instance
      .post(`/additional/location/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false, saved: true });

        this.props.addToOrder();
        Notification["success"]({
          title: `New Location Created (${data.name}).`,
        });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    let loc = this.props.loc;

    let del = newDeliveryStore.newdel;
    let locs = newDeliveryStore.locations;

    let savedLocs = locs.map((loc) => loc.name);
    savedLocs = ["New", ...savedLocs];

    let hlabel = "To";

    let code2 = "t";
    if (del.fromaddress.selected !== "New") {
      savedLocs = savedLocs.filter((sl) => sl !== del.fromaddress.selected);
    }
    for (let i = 0; i < del.toaddress.length; i++) {
      savedLocs = savedLocs.filter((sl) => sl !== del.toaddress[i].selected);
    }

    let disabled = false;
    if (loc.type === "") disabled = true;
    if (loc.city === "") disabled = true;
    if (loc.floor === "") disabled = true;
    if (loc.address === "") disabled = true;
    if (loc.name === "") disabled = true;
    if (loc.phone === "") disabled = true;

    let options = savedLocs.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));
    let zchg = 0;
    let ztype;
    let zoneind = zoneStore.zones.findIndex((zone) => zone.name === loc.city);
    if (zoneind >= 0) {
      ztype = zoneStore.zones[zoneind].zone;
      zchg = newDeliveryStore.delCont.zoneadd[`z${ztype}`];
    }
    let fchg = 0;
    if (PRKD(loc.floor) !== 0) {
      fchg = newDeliveryStore.delCont.nongroundfloorprice;
    }
    return (
      <div>
        <Div14>Dropoff Location</Div14>
        <FlexboxGrid justify="center">
          <Col md={16} sm={20} xs={24}>
            <InputGroup>
              <InputGroup.Addon>Saved Locations</InputGroup.Addon>
              <Select
                showSearch
                size={"large"}
                value={loc.selected}
                style={{ width: "100%" }}
                placeholder={"Saved Locations"}
                optionFilterProp="children"
                onChange={(e, index) => {
                  let data = {
                    selected: "New",
                    type: "",
                    city: "",
                    floor: 0,
                    address: "",
                    name: "",
                    phone: "",
                  };

                  if (e !== "New") {
                    data = locs.filter((loc) => loc.name === e)[0];
                    data.selected = e;
                  }

                  this.props.selectLoc(data);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {options}
              </Select>
            </InputGroup>
          </Col>
        </FlexboxGrid>
        <hr />
        <FlexboxGrid justify="center">
          <Col md={16} sm={20} xs={24}>
            <div
              style={{
                padding: "2%",
                border: "1px solid black",
                borderRadius: "5px",
                boxShadow: "1px 1px 5px black",
              }}
            >
              <Div14>Final Address</Div14>
              <SelectInputSTD
                title="Address Type"
                val={loc.type}
                label="type"
                size="large"
                opts={locationtypelist}
                editVal={this.props.editVal}
              />
              <br />
              <TextInputSTD
                title={`${hlabel} Name`}
                val={loc.name}
                label="name"
                size="lg"
                editVal={this.props.editVal}
              />
              <br />
              <TextInputSTD
                title={`${hlabel} Phone`}
                val={loc.phone}
                label="phone"
                size="lg"
                editVal={this.props.editVal}
              />
              <br />
              <SelectInputSTD
                title="City"
                val={loc.city}
                label="city"
                size="large"
                opts={zoneStore.zones.map((zone) => zone.name)}
                editVal={this.props.editVal}
              />
              <HelpBlock
                style={{
                  textAlign: "center",
                  color: del.prices[`${code2}zchg`] > 0 ? "red" : "green",
                }}
              >
                {zchg > 0 &&
                  `${loc.city} in Zone ${ztype} (+ ${PRKD(
                    zchg
                  )} KD Zone Delivery Charge)`}
              </HelpBlock>
              <br />
              <NumbInputSTD
                title="Floor"
                val={loc.floor}
                label="floor"
                size="large"
                min={-9}
                editVal={this.props.editVal}
              />
              {fchg > 0 && (
                <HelpBlock style={{ color: "red", textAlign: "center" }}>
                  Non-Ground Floor Charge (+ {PRKD(fchg)} KD)
                </HelpBlock>
              )}
              <br />
              <TextAreaInputSTD
                title="Address"
                val={loc.address}
                label="address"
                size="large"
                editVal={this.props.editVal}
              />
              <br />
              <Col2>
                <IconButton
                  icon={<Icon icon="plus" />}
                  loading={this.state.loading}
                  block
                  color="green"
                  onClick={() => this.props.addToOrder(zchg, ztype, fchg)}
                  disabled={disabled}
                >
                  Add To Order
                </IconButton>
              </Col2>
              <Col2>
                {loc.selected === "New" && (
                  <IconButton
                    color="green"
                    icon={<Icon icon="save" />}
                    loading={this.state.loading}
                    appearance="ghost"
                    onClick={() => this.saveNewLocation()}
                    block
                    disabled={disabled}
                  >
                    Save New Address
                  </IconButton>
                )}
              </Col2>
              <br />
            </div>
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(DACStep2Supporting);
