import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import { FlexboxGrid, Icon, IconButton, Col, Button, HelpBlock } from "rsuite";
import { Notification } from "rsuite";

import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import ParagraphLoader from "../../../Components/Loaders/ParagraphLoader";
import Div14 from "../../../Components/Dividers/Div14";
import paymentOptionStore from "../../../Stores/AdditionalStores/PaymentOptionStore";
import SelectInputSTD from "../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import PaymentOptionTable from "./PaymentOptionTable";

const instance = axios.create({ baseURL: baseURL });

class PaymentOptionMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      type: "Income",
      subtype: "",
      cash: "Cash",
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNewPaymentOption = this.postNewPaymentOption.bind(this);
  }

  componentDidMount() {
    paymentOptionStore.getOptions();
  }

  changeVal(val, type) {
    this.setState({ [type]: val });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        loading: false,
      });
    }
  }

  postNewPaymentOption = async () => {
    let newservice = {
      title: this.state.title,
      type: this.state.type,
      subtype: this.state.subtype,
      cash: this.state.cash,
    };
    this.changeVal("loading", true);
    let url = "/jf/paymentoption/new";

    return instance
      .post(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        paymentOptionStore.getOptions();
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Added New Payment Option: ${newservice.title}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };
  render() {
    if (paymentOptionStore.loading) {
      return <ParagraphLoader />;
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={
                      this.state.title === "" ||
                      this.state.type === "" ||
                      this.state.subtype === "" ||
                      this.state.cash === ""
                    }
                    loading={this.state.loading}
                    onClick={() => this.postNewPaymentOption()}
                  >
                    Create Payment Option
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h5>New Payment Option Details</h5>
          <SelectInputSTD
            title="Type"
            val={this.state.type}
            label="type"
            size="large"
            opts={["Income", "Expense Fixed", "Expense Variable", "Transfer"]}
            editVal={this.changeVal}
          />
          <br />
          <SelectInputSTD
            title="Sub Type"
            val={this.state.cash}
            label="cash"
            size="large"
            opts={["Cash", "Non-Cash"]}
            editVal={this.changeVal}
          />
          <br />
          <SelectInputSTD
            title="Sub Type"
            val={this.state.subtype}
            label="subtype"
            size="large"
            opts={[
              "Job Related",
              "Staff Related",
              "Office Related",
              "Bank Related",
              "Equipment Related",
              "Vehicle Related",
              "Job Materials",
              "Marketing",
              "Other",
            ]}
            editVal={this.changeVal}
          />
          <HelpBlock>Such as Job Related, Refund, Damage...</HelpBlock>
          <br />
          <TextInputSTD
            title="Title"
            editVal={this.changeVal}
            val={this.state.title}
            label="title"
          />
          <HelpBlock>Name of the Payment</HelpBlock>
        </UnifiedModal>
        <Div14>Payment Options: </Div14>
        <FlexboxGrid justify="center">
          <h6>Types of Payment Options</h6>
        </FlexboxGrid>
        <div style={{ margin: "1%" }}>
          <PaymentOptionTable options={paymentOptionStore.options} />
        </div>
        <FlexboxGrid justify="center">
          <Col md={6} sm={8} xs={24}>
            <Button
              color="green"
              onClick={() => {
                this.showHideModal(true);
              }}
              block
            >
              Add New Payment Option
            </Button>
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(PaymentOptionMain));
