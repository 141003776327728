import { observer } from "mobx-react";
import React, { Component } from "react";

import { Descriptions } from "antd";

import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class ServicesSummaryTable extends Component {
  render() {
    let del = this.props.del;
    let content;
    if (del.maintype === "Fixed Per Type") {
      let typemap = del.toaddress.map((to, ind) => (
        <Descriptions.Item
          key={ind}
          label={`Dropoff ${ind + 1}: ${PRKD(to.service.price)} KD`}
          labelStyle={{ fontWeight: "bold" }}
          span={3}
        >
          {to.service.type}
        </Descriptions.Item>
      ));
      content = (
        <Descriptions
          title={this.props.hidetitle ? false : "Selected Services"}
          bordered
          size="middle"
        >
          {typemap}
        </Descriptions>
      );
    }

    return (
      <div>
        {content}
        <hr />
      </div>
    );
  }
}

export default observer(ServicesSummaryTable);
