import { observer } from "mobx-react";
import React, { Component } from "react";
import { message, DatePicker, Collapse } from "antd";
import moment from "moment";
import { FlexboxGrid, Col, InputGroup } from "rsuite";
import { Icon, HelpBlock, Notification, IconButton } from "rsuite";
import axios from "axios";

import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import SJSelectInput from "../../../../../../../Components/Inputs/SJ/SJSelectInput";
import Col2 from "../../../../../../../Components/Columns/Col2";
import locationtypelist from "../../../../../../../Static/Lists/LocationType";
import SJTextAreaInput from "../../../../../../../Components/Inputs/SJ/SJTextAreaInput";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const instance = axios.create({ baseURL: baseURL });

class JobDateRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: {},
    };
    this.editVal = this.editVal.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  onHide(val) {
    this.setState({ show: val });
  }

  editVal(val, label) {
    let index = this.props.index;
    let ind = this.props.ind;
    sjStore.editItem("jobdate", label, val, index, ind);
  }

  postEdit = async (label) => {
    this.setState({ loading: { [label]: true } });
    let index = this.props.index;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let esj = sjStore.esjs[ind];

    let ejd = esj.jobdate[index];
    let item = ejd[label];

    let data = { subjobtype: sj.jobtype, dateUniId: ejd._id };
    data[label] = ejd[label];
    if (label === "date") {
      data.dateto = ejd.dateto;
    }

    await instance
      .put(`additional/jobdates/edit/${ejd._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: { [label]: false } });
        sjStore.changeItem("jobdate", label, item, index, ind);
        if (label === "date") {
          sjStore.changeItem("jobdate", "dateto", ejd.dateto, index, ind);
        }
        message.success(`Date (${label}) Updated.`);
      })
      .catch((err) => {
        this.setState({ loading: { [label]: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  postDelete = async (label) => {
    this.props.changeLoad(true);
    let index = this.props.index;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];

    let jd = sj.jobdate[index];
    this.setState({ loading: { [label]: true } });
    let data = { jobdateid: jd._id, type: sj.jobtype, subJobId: sj._id };

    await instance
      .put(`additional/jobdates/delete/${jd._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.removeList("jobdate", index, ind);
        message.success(`Date Deleted.`);
        this.onHide(false);
        this.setState({ loading: { [label]: false } });
        this.props.changeLoad(false);
      })
      .catch((err) => {
        this.setState({ loading: { [label]: false } });
        this.props.changeLoad(false);

        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let index = this.props.index;
    let editview = this.props.editview;
    let quality = this.props.quality;

    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let jd = sj.jobdate[index];
    let ejd = sjStore.esjs[ind].jobdate[index];
    let jdto = jd.to;

    let datetypes = ["Packing", "Moving", "Packing and Moving", "Delivery"];

    if (sj.jobtype === "ST") {
      datetypes = ["Storage In", "Storage Out"];
    }

    let qualityerr = false;

    if (quality) {
      if ((jd.status === "Pending") & (moment(jd.dateto) < moment())) {
        qualityerr = true;
      }
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title="Delete Date"
          onHide={this.onHide}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  loading={this.state.loading.delete}
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide(false)}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  loading={this.state.loading.delete}
                  block
                  icon={<Icon icon="close" />}
                  color="red"
                  onClick={() => this.postDelete("delete")}
                >
                  Yes, Delete
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <h6>Are you sure you want to delete this date?</h6>
          <p>
            <b>Job Dates:</b>{" "}
            {moment(jd.date).format("DD-MMM-YY") ===
            moment(jd.dateto).format("DD-MMM-YY")
              ? moment(jd.date).format("DD-MMM-YY")
              : `${moment(jd.date).format("DD-MMM-YY")} to ${moment(
                  jd.dateto
                ).format("DD-MMM-YY")}`}
            <br />
            <b>Status:</b> {jd.status}
            <br />
            <b>Type:</b> {jd.type}
            <br />
            <b>From:</b> {jd.fromtype}: {jd.from}
            <br />
            {jd.type !== "Packing" && (
              <div>
                <b>To:</b> {jd.to}: {jd.from}
              </div>
            )}
          </p>
        </UnifiedModal>

        <Collapse>
          <Panel
            header={`${jd.status}: ${jd.type} ${moment(jd.date).format(
              "DD-MMM-YY"
            )} to ${moment(jd.dateto).format("DD-MMM-YY")} (${jd.fromtype}${
              jd.totype !== "" ? " to " + jd.totype : ""
            }).`}
            style={{
              backgroundColor: qualityerr
                ? "#ff968c"
                : jd.status === "Complete" && "#e6ffe9",
              padding: 0,
              margin: 0,
            }}
          >
            <FlexboxGrid>
              <Col md={20} sm={16} xs={24}>
                <InputGroup>
                  {(jd.date !== ejd.date || jd.dateto !== ejd.dateto) && (
                    <InputGroup.Button
                      loading={false}
                      onClick={() => {
                        sjStore.editItem(
                          "jobdate",
                          "date",
                          jd.date,
                          index,
                          ind
                        );
                        sjStore.editItem(
                          "jobdate",
                          "dateto",
                          jd.dateto,
                          index,
                          ind
                        );
                      }}
                    >
                      <Icon icon="refresh" />
                    </InputGroup.Button>
                  )}
                  {sj.jobtype === "ST" ? (
                    <DatePicker
                      style={{ width: "100%" }}
                      //format={"DD-MMM-YY @ HA"}
                      format={"DD-MMM-YY"}
                      value={moment(ejd.date)}
                      allowClear={false}
                      showMinute={false}
                      inputReadOnly={!editview}
                      onChange={(date) => {
                        if (editview) {
                          sjStore.editItem("jobdate", "date", date, index, ind);
                          sjStore.editItem(
                            "jobdate",
                            "dateto",
                            date,
                            index,
                            ind
                          );
                        }
                      }}
                    />
                  ) : (
                    <RangePicker
                      style={{ width: "100%" }}
                      //format={"DD-MMM-YY @ HA"}
                      format={"DD-MMM-YY"}
                      value={[moment(ejd.date), moment(ejd.dateto)]}
                      allowClear={false}
                      showMinute={false}
                      onChange={(date) => {
                        if (editview) {
                          sjStore.editItem(
                            "jobdate",
                            "date",
                            date[0],
                            index,
                            ind
                          );
                          sjStore.editItem(
                            "jobdate",
                            "dateto",
                            date[1],
                            index,
                            ind
                          );
                        }
                      }}
                    />
                  )}
                  {(jd.date !== ejd.date || jd.dateto !== ejd.dateto) && (
                    <InputGroup.Button
                      color="green"
                      loading={false}
                      onClick={() => this.postEdit("date")}
                    >
                      <Icon icon="check" />
                    </InputGroup.Button>
                  )}
                </InputGroup>
                {(jd.date !== ejd.date || jd.dateto !== ejd.dateto) && (
                  <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                    Changed from{" "}
                    <i>
                      "{moment(jd.date).format("DD-MMM-YY @ HA")} -{" "}
                      {moment(jd.dateto).format("DD-MMM-YY @ HA")}"
                    </i>
                  </HelpBlock>
                )}
              </Col>
              <Col md={4} sm={8} xs={12}>
                <IconButton
                  size="sm"
                  block
                  icon={<Icon icon="trash" />}
                  appearance="ghost"
                  color="red"
                  onClick={() => this.onHide(true)}
                >
                  Delete
                </IconButton>
              </Col>
              <Col2>
                <SJSelectInput
                  ind={ind}
                  label="type"
                  title="Type"
                  load={this.state.loading.type}
                  size="sm"
                  editVal={this.editVal}
                  updateVal={this.postEdit}
                  opts={datetypes}
                  value={ejd.type}
                  org={jd.type}
                  read={!editview}
                />
              </Col2>
              <Col2>
                <SJSelectInput
                  ind={ind}
                  label="status"
                  title="Status"
                  load={this.state.loading.status}
                  size="sm"
                  editVal={this.editVal}
                  updateVal={this.postEdit}
                  opts={["Pending", "Complete"]}
                  value={ejd.status}
                  org={jd.status}
                  quality={qualityerr}
                  qualitytext={"Date has passed, must complete or delete."}
                  read={!editview}
                />
              </Col2>
              <Col2>
                {sj.jobtype !== "ST" && (
                  <SJSelectInput
                    ind={ind}
                    label="fromtype"
                    title="From"
                    load={this.state.loading.fromtype}
                    size="sm"
                    editVal={this.editVal}
                    updateVal={this.postEdit}
                    opts={locationtypelist}
                    value={ejd.fromtype}
                    org={jd.fromtype}
                    read={!editview}
                  />
                )}
              </Col2>
              <Col2>
                {(jd.type !== "Packing") & (sj.jobtype !== "ST") ? (
                  <SJSelectInput
                    ind={ind}
                    label="totype"
                    title="To"
                    load={this.state.loading.totype}
                    size="sm"
                    editVal={this.editVal}
                    updateVal={this.postEdit}
                    opts={locationtypelist}
                    value={ejd.totype}
                    org={jd.totype}
                    read={!editview}
                  />
                ) : (
                  <div />
                )}
              </Col2>
              <Col2>
                {sj.jobtype !== "ST" && (
                  <SJTextAreaInput
                    ind={ind}
                    label="from"
                    title="From"
                    load={this.state.loading.from}
                    size="sm"
                    editVal={this.editVal}
                    updateVal={this.postEdit}
                    value={ejd.from}
                    org={jd.from}
                    read={!editview}
                  />
                )}
              </Col2>
              <Col2>
                {(jd.type !== "Packing") & (sj.jobtype !== "ST") ? (
                  <SJTextAreaInput
                    ind={ind}
                    label="to"
                    title="To"
                    load={this.state.loading.to}
                    size="sm"
                    editVal={this.editVal}
                    updateVal={this.postEdit}
                    value={ejd.to}
                    org={jdto}
                    read={!editview}
                  />
                ) : (
                  <div />
                )}
              </Col2>
            </FlexboxGrid>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default observer(JobDateRow);
