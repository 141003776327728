import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Countdown from "react-countdown";
import moment from "moment";
import whmTruckStore from "../../Pages/WarehouseMoveAug2023/WHMStores/WHMTruckStore";

const renderer = ({ hours, minutes, seconds, completed }) => {
  return (
    <span
      style={{
        fontWeight: "bolder",
        fontSize: 18,
        color: minutes <= whmTruckStore.targets.unloading ? "green" : "red",
      }}
    >
      {hours > 0 && `${hours}:`}
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}{" "}
      {minutes <= whmTruckStore.targets.unloading ? "On Time" : "Delayed"}
    </span>
  );
};

class CountDownUnloading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let starttime = this.props.starttime;
    return (
      <Countdown
        overtime
        zeroPadTime={2}
        renderer={renderer}
        date={Date.now() + moment(starttime).diff(moment(), "milliseconds")}
      />
    );
  }
}

export default withRouter(observer(CountDownUnloading));
