import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { InputGroup } from "rsuite";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import OrderModal from "../../../../../StorageAccount/Modals/OrderModal";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import modalACOrderStore from "../../../../../../../Stores/ModalStores/ModalACOrderStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";

import { DatePicker } from "antd";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class SACCalendarPage extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.changeST = this.changeST.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    staffStorageStore.getCalendar();
  }

  onHide() {
    this.setState({ show: false });
  }
  changeST(label, val) {
    this.setState({ [label]: val });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let evt = event;

    let incomp = { backgroundColor: "#006103", textColor: "white" };
    let outcomp = { backgroundColor: "#614301", textColor: "white" };
    let intrans = { backgroundColor: "#129903", textColor: "white" };
    let outtrans = { backgroundColor: "#b37b00", textColor: "white" };
    let inbook = { backgroundColor: "#acf2a5", textColor: "black" };
    let outbook = { backgroundColor: "#e6cd95", textColor: "black" };
    let final;
    if (evt.jobtype === "Delivery In" || evt.jobtype === "Storage In") {
      final = inbook;
      if (evt.status === "Completed") final = incomp;
      if (evt.status === "In Transit") final = intrans;
    } else if (
      evt.jobtype === "Delivery Out" ||
      evt.jobtype === "Storage Out"
    ) {
      final = outbook;
      if (evt.status === "Completed") final = outcomp;
      if (evt.status === "In Transit") final = outtrans;
    } else {
      final = outcomp;
    }
    if (evt.status === "Cancelled") {
      final = { backgroundColor: "#730006", textColor: "white" };
    }

    var style = {
      backgroundColor: final.backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 10,
      height: 15,
      color: final.textColor,
    };
    return {
      style: style,
    };
  };

  calendarStyle = (day) => {
    return {
      style: {
        backgroundColor: moment(day).day() === 5 && "#ebebeb",
      },
    };
  };

  render() {
    let data = staffStorageStore.calendar;

    return (
      <div>
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <InputGroup.Addon>Month</InputGroup.Addon>
            <DatePicker
              allowClear={false}
              style={{ width: "100%" }}
              //format={"DD-MMM-YY @ HA"}
              format={"MMM-YY"}
              value={moment(staffStorageStore.startdate)}
              size={"large"}
              picker={"month"}
              showMinute={false}
              onChange={(date) => {
                staffStorageStore.changeDate(date);
                staffStorageStore.getCalendar();
              }}
            />
          </InputGroup>
        </div>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <Div14>Storage Calendar Page</Div14>
        <LoadBack loading={staffStorageStore.loading.calendar}>
          <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
            <Calendar
              localizer={localizer}
              events={data}
              eventPropGetter={this.eventStyleGetter}
              startAccessor={"start"}
              endAccessor={"start"}
              style={{ height: 500 }}
              defaultDate={new Date()}
              views={["month"]}
              titleAccessor={"title"}
              date={staffStorageStore.startdate}
              popup
              onSelectEvent={(e) => {
                modalACOrderStore.selectOrder(e);
                this.setState({ show: true });
              }}
              onNavigate={(e) => {
                staffStorageStore.changeDate(moment(e).startOf("M"));
                staffStorageStore.getCalendar();
              }}
              dayPropGetter={this.calendarStyle}
            />
          </div>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(SACCalendarPage));
