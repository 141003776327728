import { withRouter } from "react-router-dom";
import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Loader } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import Div14 from "../../../Components/Dividers/Div14";
import DeliveryTimeline from "../../../Components/Timeline/DeliveryTimeline";
import deliveryTrackingStore from "../../../Stores/Tracking/DeliveryTrackingStore";
import carStore from "../../../Stores/AdditionalStores/CarStore";
import DeliveryTrackingLoading from "./components/DeliveryTrackingLoading";

import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import DeliveryMap from "../../Management/CarMaps/DeliveryMap";
import userStore from "../../../Stores/UserStore";
import ModalFooter from "../../../Components/Modal/ModalFooter";

class MainDeliveryTracking extends Component {
  componentDidMount() {
    deliveryTrackingStore.getDelivery(this.props.match.params.delid);
  }
  render() {
    let dts = deliveryTrackingStore;
    let delid = this.props.match.params.delid;

    if (!dts.del || delid !== dts.delid || dts.loading) {
      return (
        <div>
          <DeliveryTrackingLoading />
        </div>
      );
    }
    if (!dts.del._id) {
      return (
        <div>
          <Div14>Delivery Tracking Page</Div14>
          <Empty description="Delivery  Not Found">
            <IconButton
              icon={<Icon icon="truck" />}
              onClick={() => this.props.history.push("/")}
              color="blue"
            >
              Go To Home Page
            </IconButton>
          </Empty>
        </div>
      );
    }

    let del = dts.del;

    let itemlist = (
      <div
        style={{
          paddingLeft: "1%",
          paddingRight: "1%",
        }}
      >
        <DataGrid
          id="gridContainer"
          dataSource={del.types}
          showBorders={true}
          style={{ fontSize: 12 }}
          showRowLines
          showColumnLines
          rowAlternationEnabled
          wordWrapEnabled
        >
          <Selection mode="single" />
          <Column dataField="type" caption="Item" />
          <Column dataField="subtype" caption="Remarks" />

          <Column dataField="quantity" caption="Qty" />
          <Column dataField="cbm" caption="CBM" />

          <Summary>
            <TotalItem
              column="item"
              summaryType="count"
              displayFormat="{0} Items"
            />
            <TotalItem
              column="quantity"
              summaryType="sum"
              displayFormat="{0} Packages"
            />
            <TotalItem column="cbm" summaryType="sum" displayFormat="{0} CBM" />
          </Summary>
        </DataGrid>
      </div>
    );

    let actions = (
      <ModalFooter
        icon={["home", "user"]}
        color={["blue", "green"]}
        app={["default", "default"]}
        text={["Easy Move Home", "Sign In"]}
        oC1={() => this.props.history.push("/")}
        oC2={() => this.props.history.push("/login")}
      />
    );
    if (userStore.user.jobs) {
      if (del.account) {
        actions = (
          <ModalFooter
            icon={["truck", "user"]}
            color={["blue", "green"]}
            app={["default", "default"]}
            text={["Go to All Deliveries", "Go to Client Account Page"]}
            oC1={() => this.props.history.push("/accounts/staff/deliveries")}
            oC2={() =>
              this.props.history.push(
                `/account/dashboard/${del.account.client._id}`
              )
            }
          />
        );
      } else {
      }
    }

    let accountName = <div />;
    if (del.account) {
      if (del.account.client) {
        if (del.account.client.name) {
          accountName = (
            <h4 style={{ textAlign: "center", fontStyle: "italic" }}>
              <br />
              <b style={{ textAlign: "center", fontStyle: "normal" }}>
                Account:{" "}
              </b>
              {del.account.client.name}
            </h4>
          );
        }
      }
    }

    let finalcontent = (
      <div>
        {accountName}
        <Div14>Delivery Tracking Page (Delivery #{del.deliveryNumId})</Div14>
        <br />
        <DeliveryTimeline del={del} />
        <Div14>Address</Div14>
        <FlexboxGrid style={{ textAlign: "center" }}>
          <Col2>
            <h4>From:</h4>
            <h6>
              {del.fromcity} - {del.fromtype}
            </h6>
            <p>{del.from}</p>
          </Col2>
          <Col2>
            <h4>To:</h4>
            <h6>
              {del.tocity} - {del.totype}
            </h6>
            <p>{del.to}</p>
          </Col2>
        </FlexboxGrid>
        <Div14>Items</Div14>
        {itemlist}
      </div>
    );

    let carTrack;

    if (del.status === "In Transit") {
      if (del.trucktrack) {
        if (del.trucktrack.name !== "No Tracking") {
          if (carStore.loading) {
            carTrack = <Loader />;
          } else {
            let car = carStore.singlecar;

            carTrack = (
              <div>
                <Div14>Live Tracking</Div14>
                <DeliveryMap markers={car} />
              </div>
            );
            return (
              <div>
                <FlexboxGrid>
                  <Col2>{finalcontent}</Col2>
                  <Col2>{carTrack}</Col2>
                </FlexboxGrid>
                <hr />
                {actions}
              </div>
            );
          }
        }
      }
    }

    return (
      <div>
        {finalcontent}
        {actions}
      </div>
    );
  }
}

export default withRouter(observer(MainDeliveryTracking));
