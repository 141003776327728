import { Image, message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Loader, Uploader, Notification, FlexboxGrid } from "rsuite";
import { Col, Icon } from "rsuite";

import axios from "axios";
import ImageCropModal from "../../../SingleJob/IndividualTabs/SubJobs/Attachments/ImageCropper/ImageCropModal";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import hrStore from "../../../../Stores/HR/HRStore";

const instance = axios.create({ baseURL: baseURL });

class AttachmentTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      src: null,
      itemindex: 0,
    };
    this.hideModal = this.hideModal.bind(this);
    this.changeDelete = this.changeDelete.bind(this);
  }

  hideModal() {
    this.setState({ show: false, src: null });
  }

  changeDelete(val) {
    this.setState({ delete: val });
  }

  deleteDocument = async (attId) => {
    this.setState({ loading: true });
    Notification["info"]({ title: "Deleting.. Please Wait" });

    let data = {
      attId: attId,
    };

    await instance
      .put(`media/sidejob/delete`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHRDocs(true);
        Notification.close();
        message.success("Document Deleted");
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  checkFileType = async (file) => {
    if (file.blobFile.type.substring(0, 3) === "ima") {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(file.blobFile);
    } else {
      this.uploadDocument(file.blobFile);
    }
  };

  uploadDocument = async (file) => {
    console.log("Started Attachment Upload");
    let attType = "Staff";
    let hrdocid = this.props.hrdoc._id;
    let type = "Staff";
    let staffNumId = this.props.hrdoc.staff.hrStaffNumId;
    let title = this.props.hrdoc.title;

    this.setState({ loading: true });
    let fd = new FormData();
    fd.append(
      "file",
      file,
      `${attType}_${Math.round(Math.random() * 10000)}_${title}`
    );
    fd.append("hrdoc", hrdocid);
    fd.append("numId", staffNumId);
    fd.append("title", title);
    fd.append("type", type);

    console.log("Saving Attachment");
    await instance
      .post("media/sidejob/upload/nonjob", fd, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHRDocs();

        this.setState({ loading: false, show: false, src: null });
        message.success(`${title} Document Uploaded.`);
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let content = (
      <Uploader
        multiple={false}
        fileListVisible={false}
        onChange={(file) => {
          let finalfile = file[file.length - 1];

          return new Promise((resolve, reject) => {
            let fileSize = finalfile.blobFile.size / 1000000;

            if (
              (finalfile.blobFile.type.substring(0, 3) !== "ima") &
              (fileSize > 10)
            ) {
              message.error("Not Uploaded. Must be less than 10 MB.");
              reject();
            } else {
              this.checkFileType(finalfile);
              resolve();
            }
          });
        }}
        draggable
      >
        <div
          style={{
            margin: "1%",
            backgroundColor: "rgba(96,214,112,0.26)",
            boxShadow: "0px 0px 3px black",
            border: "1px solid black",
          }}
        >
          <Icon icon="plus" size="2x" />
          <p>Add an Attachment</p>
        </div>
      </Uploader>
    );
    if (this.state.loading) {
      content = (
        <div>
          <div active style={{ height: 70 }}>
            <Loader content="Uploading Document..." />
          </div>
        </div>
      );
    }
    let hrdoc = this.props.hrdoc;

    let viewtype = "iframe";
    let finalview;
    if (hrdoc.attachment) {
      let attachment = hrdoc.attachment;
      if (attachment !== null) {
        let attlink = attachment.attachment;
        let documenttype = attlink.substring(
          attlink.length - 3,
          attlink.length
        );

        if (documenttype === "jpg" || documenttype === "png") {
          viewtype = "picture";
          finalview = (
            <Image
              src={`${attlink}?alt=media`}
              width={"auto"}
              height={"auto"}
            />
          );
        }
      }

      content = (
        <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          {viewtype === "picture" ? (
            finalview
          ) : (
            <iframe
              src={`${attachment.attachment}?alt=media`}
              title={`${attachment.attachment}`}
              height={500}
              width={"100%"}
              style={{ zoom: 0.5 }}
            ></iframe>
          )}
        </div>
      );
    }

    return (
      <div>
        <ImageCropModal
          loading={this.state.loading}
          uploadDocument={this.uploadDocument}
          hideModal={this.hideModal}
          src={this.state.src}
        />

        <FlexboxGrid align="middle">
          <Col md={18} sm={16} xs={24}>
            <FlexboxGrid>{content}</FlexboxGrid>
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(AttachmentTemplate));
