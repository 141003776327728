import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Notification } from "rsuite";
import axios from "axios";

import baseURL from "../../../../../../../Static/baseURL/baseURL";
import packinglist from "../../../../../../../Static/Lists/packinglist";
import userStore from "../../../../../../../Stores/UserStore";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import ItemSheet from "./ItemSheet";
import MatTableSTD from "../../../../../../../Components/Tables/MatTableSTD";
import NewItemModal from "./NewItemModal";
import DeleteItemModal from "./DeleteItemModal";

const instance = axios.create({ baseURL: baseURL });

class ItemTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "New",
      showEdit: false,
      showDelete: false,
      selectedTrack: {
        item: "",
        cbm: 0,
        quantity: 1,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        remarks: "",
        _id: "",
      },
      editedTrack: {
        item: "",
        cbm: 0,
        quantity: 1,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        remarks: "",
        _id: "",
      },
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.fastFill = this.fastFill.bind(this);
  }

  fastFill(itemindex) {
    let item = { ...packinglist[itemindex] };
    let editTrack = { ...this.state.editedTrack };
    editTrack.item = item.name;
    editTrack.cbm =
      Math.round((100 * (item.length * item.width * item.height)) / 1000000) /
      100;
    editTrack.length = item.length;
    editTrack.width = item.width;
    editTrack.height = item.height;
    this.setState({ editedTrack: editTrack });
  }

  changeVal(label, val) {
    let track = { ...this.state.editedTrack };
    track[label] = val;
    this.setState({ editedTrack: track });
  }

  showHideModal(val) {
    if (val === "delete") {
      this.setState({ showDelete: true });
    } else if (val === "New") {
      this.setState({
        type: val,
        showEdit: true,
        selectedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
        editedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
      });
    } else if (val) {
      this.setState({ showEdit: true, type: "Edit" });
    } else {
      this.setState({
        selectedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
        editedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
        showEdit: false,
        showDelete: false,
        showNew: false,
      });
    }
  }

  postNew = async (item) => {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];

    this.setState({ loading: true });
    let data = {
      item: item.item,
      cbm: item.cbm,
      length: item.length,
      width: item.width,
      height: item.height,
      quantity: item.quantity,
      weight: item.weight,
      remarks: item.remarks,
      sj: sj._id,
    };

    return instance
      .post(`/additional/item/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.newList("items", res.data, ind);

        this.setState({ loading: false });
        Notification["success"]({
          title: `New Item Created.`,
          description: <div>{data.item}</div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  postEdit = async (item) => {
    let itemindex = item.tableData.id;
    let ind = this.props.ind;

    this.setState({ loading: true });

    return instance
      .put(`/additional/item/edit/${item._id}`, item, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.changeList("items", item, itemindex, ind);

        this.setState({ loading: false });
        Notification["success"]({
          title: `Updated Item Details.`,
          description: <div>{item.title}</div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  removeItem = async (item) => {
    let itemindex = item.tableData.id;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];

    this.setState({ loading: true });

    let data = { itemid: item._id, type: sj.jobtype, subJobId: sj._id };

    return instance
      .put(`/additional/item/delete/${item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        Notification["success"]({
          title: `Deleted Item.`,
          description: <div>{item.title}</div>,
        });
        this.showHideModal(false);
        sjStore.removeList("items", itemindex, ind);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    let ind = this.props.ind;
    let editview = this.props.editview;

    let sj = sjStore.sjs[ind];
    let data = sj.items;

    let subjobtype = sj.jobtype;
    let subjobindex = ind;

    let disabled = false;
    if (this.state.selectedTrack === this.state.editedTrack) {
      disabled = true;
    }
    if (this.state.editedTrack.item === "") disabled = true;
    if (this.state.editedTrack.cbm < 0) disabled = true;
    if (this.state.editedTrack.quantity === 0) disabled = true;
    if (this.state.editedTrack.cbm === null) disabled = true;
    if (this.state.editedTrack.quantity === null) disabled = true;
    if (this.state.editedTrack.remarks === 0) disabled = true;

    let hideTable = false;

    let columns = [
      { title: "Name", field: "item", defaultSort: "desc" },
      { title: "CBM", field: "cbm" },
      { title: "Qty", field: "quantity" },
      { title: "L (cm)", field: "length" },
      { title: "W (cm)", field: "width" },
      { title: "H (cm)", field: "height" },
      { title: "Weight (kg)", field: "weight" },
      { title: "Remark", field: "remarks" },
    ];

    let actions = [
      {
        icon: "add",
        tooltip: "Add New Item",
        isFreeAction: true,
        hidden: !editview,
        onClick: (event, rowData) => {
          this.setState({ selectedTrack: rowData });
          this.setState({ editedTrack: rowData });

          this.showHideModal("New");
        },
      },
      {
        icon: "edit",
        tooltip: "Edit",
        hidden: !editview,
        onClick: (event, rowData) => {
          this.setState({ selectedTrack: rowData });
          this.setState({ editedTrack: rowData });

          this.showHideModal("Edit");
        },
      },
      {
        icon: "delete",
        hidden: !editview,
        tooltip: "Delete",
        onClick: (event, rowData) => {
          this.setState({ selectedTrack: rowData });
          this.setState({ editedTrack: rowData });

          this.showHideModal("delete");
        },
      },
    ];

    return (
      <div>
        <NewItemModal
          ind={ind}
          title="New Item"
          fastFill={this.fastFill}
          type={this.state.type}
          disabled={disabled}
          loading={this.state.loading}
          edited={this.state.editedTrack}
          selected={this.state.selectedTrack}
          changeVal={this.changeVal}
          show={this.state.showEdit}
          showHideModal={this.showHideModal}
          postNew={this.postNew}
          postEdit={this.postEdit}
        />
        <DeleteItemModal
          ind={ind}
          title="New Item"
          fastFill={this.fastFill}
          type={this.state.type}
          loading={this.state.loading}
          edited={this.state.editedTrack}
          selected={this.state.selectedTrack}
          changeVal={this.changeVal}
          show={this.state.showDelete}
          showHideModal={this.showHideModal}
          removeItem={this.removeItem}
        />
        {this.state.loading ? (
          <div style={{ minHeight: "200px" }} />
        ) : (
          <MatTableSTD
            data={data}
            title="Packing List"
            columns={columns}
            actions={actions}
            loading={hideTable}
          />
        )}
        <br />

        {editview && (
          <ItemSheet
            ind={ind}
            subjob={sj}
            subjobtype={subjobtype}
            subjobindex={subjobindex}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(ItemTable));
