import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import AIMChargeHeader from "../Charges/AIMChargeHeader";
import NewAIMInvoiceModal from "./NewAIMInvoiceModal";

import aimInvoiceStore from "../../../../../../Stores/Financial/AIMInvoiceStore";
import InvoiceTable from "./InvoiceTable";
import EditAIMInvoiceModal from "./EditAIMInvoiceModal";

class MainInvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = { shownew: false, showedit: false };
    this.onHide = this.onHide.bind(this);
    this.showNew = this.showNew.bind(this);
    this.showEdit = this.showEdit.bind(this);
  }
  onHide() {
    this.setState({ shownew: false, showedit: false });
  }
  showNew() {
    this.setState({ shownew: true });
    aimInvoiceStore.createNewInvoice(true);
  }
  showEdit() {
    this.setState({ showedit: true });
  }
  componentDidMount() {
    aimInvoiceStore.getInvPayments(true);
  }

  render() {
    return (
      <div>
        <NewAIMInvoiceModal onHide={this.onHide} show={this.state.shownew} />
        <EditAIMInvoiceModal onHide={this.onHide} show={this.state.showedit} />

        <AIMChargeHeader hidedetails />
        <InvoiceTable onNewClick={this.showNew} onEditClick={this.showEdit} />
      </div>
    );
  }
}

export default withRouter(observer(MainInvoicePage));
