import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import damageReportStore from "../../../Stores/Account/DamageReportStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import DamageReportTable from "./DamageReportTable";
import { Tabs } from "antd";

const { TabPane } = Tabs;

class MainDamageReport extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Pending" };
  }
  componentDidMount() {
    damageReportStore.getPendingDamageReports();
  }

  render() {
    return (
      <LoadBack loading={damageReportStore.loading.pending}>
        <br />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"Staff List"}
          tabPosition="top"
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane
            tab={<div>Pending Damage Reports</div>}
            key={"Pending"}
            disabled={this.state.locked}
          >
            <DamageReportTable
              data={damageReportStore.pendingdrs}
              type="pending"
            />
          </TabPane>
          <TabPane
            tab={<div>Closed Damage Reports</div>}
            key={"Closed"}
            disabled={this.state.locked}
          >
            <DamageReportTable
              type="closed"
              data={damageReportStore.closeddrs}
            />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(MainDamageReport));
