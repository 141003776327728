import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react";
import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { withRouter } from "react-router";
import { Button, Col, FlexboxGrid, HelpBlock } from "rsuite";

class ImageCropModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      croppedImage: null,
      src: null,
      disable: false,
      crop: {
        unit: "%",
        width: 90,
        height: 90,
      },
    };
  }

  hideModal() {
    this.setState({
      loading: false,
      show: false,
      croppedImage: null,
      src: null,
      crop: {
        unit: "%",
        width: 90,
        height: 90,
      },
    });
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.png"
      );

      this.setState({ croppedImage: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    // canvas.width = crop.width;
    // canvas.height = crop.height;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          // resolve(this.fileUrl);
          resolve(blob);
        },
        "image/png",
        0.5
      );
    });
  }

  submitImage = async (file) => {
    try {
      await this.props.uploadDocument(file);
      this.setState({
        loading: false,
        show: false,
        attachment: null,
        delete: false,
        croppedImage: null,
        src: null,
        disable: false,
        crop: {
          unit: "%",
          width: 90,
          height: 90,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let src = this.props.src;
    let crop = this.state.crop;
    let imagesize = 0;

    if (this.state.croppedImage) {
      if (this.state.croppedImage.size) {
        imagesize = this.state.croppedImage.size / 1000000;
      }
    }

    let imgerr = imagesize > 10;

    return (
      <Modal
        open={src !== null}
        onOk={() => this.props.uploadDocument(this.state.croppedImage)}
        confirmLoading={this.props.loading}
        footer={null}
      >
        <div style={{ padding: "5%" }}>
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                color="red"
                appearance="ghost"
                disabled={this.props.loading}
                onClick={() => {
                  this.props.hideModal();
                  this.hideModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                loading={this.props.loading}
                onClick={() => this.submitImage(this.state.croppedImage)}
                block
                disabled={imgerr}
                color="green"
              >
                Submit
              </Button>
            </Col>
          </FlexboxGrid>
          <HelpBlock style={{ color: imgerr && "red" }}>
            Image Size: {parseFloat(imagesize).toFixed(2)} MB.{" "}
            {imgerr && " Image Size Too Large, Please Crop."}
          </HelpBlock>
          <hr />
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </div>
      </Modal>
    );
  }
}

export default withRouter(observer(ImageCropModal));
