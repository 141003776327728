import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Icon, IconButton } from "rsuite";

class OrderItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let items = this.props.items;
    let item = this.props.item;
    let newind = items.findIndex((it) => it.acItemNumId === item.acItemNumId);

    return (
      <div>
        <IconButton
          size="xs"
          icon={<Icon icon={newind < 0 ? "circle" : "check-circle"} />}
          color={newind < 0 ? "default" : "green"}
          onClick={() => this.props.changeItem(item)}
        >
          {item.acItemNumId}: {item.itemname} (SN: {item.itemserialnumber}) x{" "}
          {item.actualquantity}
        </IconButton>
      </div>
    );
  }
}

export default withRouter(observer(OrderItemList));
