import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./App.css";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import CDNlinks from "./Static/CDN/CDNlinks";
import MiniDrawerPage from "./Components/HeaderFooter/MiniDrawerPage";

import Views from "./Views/Views";
import userStore from "./Stores/UserStore";
import NotSignedInHeader from "./Components/HeaderFooter/NotSignedInHeader";
import InitializeGoogleAnalytics from "./Static/GoogleAnalytics/googleAnalytics";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { height: props.height };
  }

  componentDidMount() {
    this.setState({ height: window.innerHeight - 100 + "px" });
    userStore.checkForToken();
  }
  render() {
    InitializeGoogleAnalytics();

    let underconstruction = false;

    if (underconstruction) {
      return (
        <>
          <CDNlinks />
          <NotSignedInHeader>
            <Views underconstruction />
          </NotSignedInHeader>
        </>
      );
    }

    if (!userStore.signedIn) {
      return (
        <>
          <CDNlinks />
          <NotSignedInHeader>
            <Views />
          </NotSignedInHeader>
        </>
      );
    }
    return (
      <div>
        <CDNlinks />
        <MiniDrawerPage>
          <Views />
        </MiniDrawerPage>
      </div>
    );
  }
}
export default withRouter(observer(App));
