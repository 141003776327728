import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newAimStore from "../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import AIMStep1 from "./Steps/AIMStep1";
import Div14 from "../../../../Components/Dividers/Div14";
import TimelineComp from "../../../../Components/Timeline/TimelineComp";
import AIMStep2 from "./Steps/AIMStep2";
import NextBackButton from "./Supporting/NextBackButton";
import AIMStep3 from "./Steps/AIMStep3";
import serviceStore from "../../../../Stores/AdditionalStores/ServiceStore";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { Notification } from "rsuite";
import AIMStep4 from "./Steps/AIMStep4";

const instance = axios.create({ baseURL: baseURL });

class NewAIMOrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      show: false,
      type: "New",
    };
    this.changeStep = this.changeStep.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
  }

  onHide() {
    this.setState({ show: false });
  }
  changeStep(val) {
    if ((this.state.step === 1) & (val < 0)) {
      this.props.history.push(
        `/ac/dashboard/${this.props.match.params.clientid}`
      );
    } else if (this.state.step === 1) {
      this.setState({ step: this.state.step + val });
    } else {
      this.setState({ step: this.state.step + val });
    }
  }
  postNew() {
    let data = newAimStore.newaim;
    data.client = this.props.match.params.clientid;

    this.setState({ loading: true });
    let url = "/ac/aimorder/new";
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        Notification["success"]({
          title: `New International Move Request Created.`,
        });
        this.props.history.push(`/aim/dash/${data.client}`);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });

        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  componentDidMount() {
    newAimStore.createNewOrder();
    serviceStore.getServices();
  }

  render() {
    let newaim = newAimStore.newaim;
    let allsteps = ["Initial Details", "Specifications", "Submit"];
    if (newaim.shippingmethod === "Sea") {
      allsteps = ["Initial Details", "Specifications", "Tracking", "Submit"];
    }
    let content = <AIMStep1 />;
    let step = this.state.step;
    if (step === 2) {
      content = <AIMStep2 />;
    } else if (step === 3) {
      content = <AIMStep3 />;
    } else if (step === 4) {
      content = <AIMStep4 />;
    }
    return (
      <div>
        <Div14>New International Move Account Request</Div14>
        <TimelineComp steps={allsteps} step={step - 1} />
        <NextBackButton
          step={this.state.step}
          changeStep={this.changeStep}
          allsteps={allsteps}
          postNew={this.postNew}
        />
        {content}
      </div>
    );
  }
}

export default withRouter(observer(NewAIMOrderMain));
