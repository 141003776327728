import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col4 from "../../../../Components/Columns/Col4";
import Div14 from "../../../../Components/Dividers/Div14";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";

class TimeButs extends Component {
  render() {
    let opts = this.props.opts;
    let content = opts.map((opt, index) => (
      <IconButton
        appearance="ghost"
        style={{
          color: "black",
          fontWeight: "bold",
          backgroundColor:
            opt.value === "No"
              ? "#ffbdbd"
              : opt.value === "Yes"
              ? "#8fffa3"
              : "#fdffb5",
        }}
        key={index}
        block
        color={
          opt.value === "No" ? "red" : opt.value === "Yes" ? "green" : "yellow"
        }
        icon={
          <Icon
            icon={
              opt.value === "No"
                ? "close"
                : opt.value === "Yes"
                ? "check"
                : "clock-o"
            }
          />
        }
        onClick={() => {
          let value = "Yes";
          if (opt.value === "No") value = "Yes";
          if (opt.value === "After") value = "No";
          if (opt.value === "Yes") value = "After";

          newAccountStore.editDelTime(value, opt.label);
        }}
      >
        <div style={{ textAlign: "left" }}>
          {opt.type === "hour" ? `${opt.label}:00` : opt.type} (
          {opt.value === "Yes"
            ? "Regular Delivery"
            : opt.value === "No"
            ? "No Delivery"
            : "After Hours"}
          )
        </div>
      </IconButton>
    ));
    return content;
  }
}

class TimingSection extends Component {
  render() {
    let delCont = newAccountStore.delCont;
    let hrs = delCont.excludedhours;

    let opts1 = [
      { type: "hour", label: "0", value: hrs["0"] },
      { type: "hour", label: "1", value: hrs["1"] },
      { type: "hour", label: "2", value: hrs["2"] },
      { type: "hour", label: "3", value: hrs["3"] },
      { type: "hour", label: "4", value: hrs["4"] },
      { type: "hour", label: "5", value: hrs["5"] },
      { type: "hour", label: "6", value: hrs["6"] },
      { type: "hour", label: "7", value: hrs["7"] },
    ];
    let opts2 = [
      { type: "hour", label: "8", value: hrs["8"] },
      { type: "hour", label: "9", value: hrs["9"] },
      { type: "hour", label: "10", value: hrs["10"] },
      { type: "hour", label: "11", value: hrs["11"] },
      { type: "hour", label: "12", value: hrs["12"] },
      { type: "hour", label: "13", value: hrs["13"] },
      { type: "hour", label: "14", value: hrs["14"] },
      { type: "hour", label: "15", value: hrs["15"] },
    ];
    let opts3 = [
      { type: "hour", label: "16", value: hrs["16"] },
      { type: "hour", label: "17", value: hrs["17"] },
      { type: "hour", label: "18", value: hrs["18"] },
      { type: "hour", label: "19", value: hrs["19"] },
      { type: "hour", label: "20", value: hrs["20"] },
      { type: "hour", label: "21", value: hrs["21"] },
      { type: "hour", label: "22", value: hrs["22"] },
      { type: "hour", label: "23", value: hrs["23"] },
    ];
    let opts4 = [
      { type: "Sunday", label: "sun", value: hrs.sun },
      { type: "Monday", label: "mon", value: hrs.mon },
      { type: "Tuesday", label: "tue", value: hrs.tue },
      { type: "Wednesday", label: "wed", value: hrs.wed },
      { type: "Thursday", label: "thu", value: hrs.thu },
      { type: "Friday", label: "fri", value: hrs.fri },
      { type: "Saturday", label: "sat", value: hrs.sat },
    ];

    return (
      <div>
        <Div14>Timing Section:</Div14>
        <FlexboxGrid>
          <Col4>
            <Div14>Days of the Week</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts4} />
            </FlexboxGrid>
          </Col4>
          <Col4>
            <Div14>0:00 to 7:00</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts1} />
            </FlexboxGrid>
          </Col4>
          <Col4>
            <Div14>8:00 to 15:00</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts2} />
            </FlexboxGrid>
          </Col4>

          <Col4>
            <Div14>16:00 to 23:00</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts3} />
            </FlexboxGrid>
          </Col4>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(TimingSection);
