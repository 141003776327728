import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import mgtOverviewStore from "../../../../Stores/Management/MgtOverviewStore";
import OverviewBarGraph from "../Graph/OverviewBarGraph";
import { Icon, IconButton, Loader } from "rsuite";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import HeardOfUsGraph from "../Graph/HeardOfUsGraph";
import { Tabs } from "antd";
import Div14 from "../../../../Components/Dividers/Div14";

const { TabPane } = Tabs;
class ClientData extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Clients" };
  }
  componentDidMount() {
    mgtOverviewStore.getClients();
  }

  render() {
    if (mgtOverviewStore.loading.clients) {
      return <Loader />;
    }
    return (
      <LoadBack loading={mgtOverviewStore.loading.clients}>
        <Div14>
          New Client Details
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() => mgtOverviewStore.getClients(true)}
            loading={mgtOverviewStore.loading.clients}
            size="xs"
          />
        </Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Clients"} key={"Clients"}>
            <OverviewBarGraph
              data={mgtOverviewStore.clients.clientcount}
              xaxis={mgtOverviewStore.clients.cats}
              label="Clients"
              title="Monthly Clients"
            />
          </TabPane>
          <TabPane tab={"Heard of Us"} key={"Heard of Us"}>
            <HeardOfUsGraph
              data={mgtOverviewStore.clients.hosdata}
              xaxis={mgtOverviewStore.clients.cats}
              label="Clients"
              title="How They Heard of Us"
            />
          </TabPane>
          <TabPane tab={"Lead Type"} key={"Lead Type"}>
            <HeardOfUsGraph
              data={mgtOverviewStore.clients.ltdata}
              xaxis={mgtOverviewStore.clients.cats}
              label="Clients"
              title="Breakdown of Contact Method"
            />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(ClientData));
