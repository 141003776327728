import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import { Button, FlexboxGrid, Icon, InputGroup } from "rsuite";
import { DatePicker, Tabs } from "antd";
import MonthlyDeliveryReport from "./MonthlyDeliveryReport";
import DeliveryDetails from "./StatisticsPages/DeliveryDetails";
import StatisticsHeader from "./StatisticsPages/StatisticsHeader";
import StaticItemsList from "./StatisticsPages/StaticItemsList";
import UnmovedItems from "./StatisticsPages/UnmovedItems";
import acStorStatStore from "../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";
import moment from "moment";

import MonthlyOverview from "./StatisticsPages/MonthlyOverview";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class StatisticsMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Monthly Report" };
    this.changeDate = this.changeDate.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  reloadData() {
    if (this.state.tab === "Monthly Report") {
      acStorStatStore.getMonthlyOverview(true);
    } else if (this.state.tab === "Static Items") {
      acStorStatStore.getStaticItems(true);
    } else {
      acStorStatStore.getStats(true);
    }
  }
  changeDate(val) {
    let startdate = moment()
      .subtract(val - 1, "month")
      .startOf("M");
    let enddate = moment().endOf("month");
    acStorStatStore.changeDateRange([startdate, enddate]);
    this.reloadData();
  }

  componentDidMount() {
    acStorStatStore.getStats(true);
  }

  render() {
    let timeopts = [
      { title: "1 Month", value: 1 },
      { title: "2 Month", value: 2 },
      { title: "3 Month", value: 3 },
      { title: "6 Month", value: 6 },
      { title: "9 Month", value: 9 },
      { title: "12 Month", value: 12 },
    ];
    return (
      <LoadBack loading={acStorStatStore.loading.stats}>
        <StatisticsHeader />

        <hr />
        <FlexboxGrid justify="space-between">
          {timeopts.map((it, index) => (
            <Button
              color="green"
              appearance="ghost"
              size="xs"
              key={index}
              onClick={() => this.changeDate(it.value)}
            >
              {it.title}
            </Button>
          ))}
        </FlexboxGrid>
        <br />
        <InputGroup>
          <InputGroup.Addon>Date Range</InputGroup.Addon>
          <RangePicker
            style={{ width: "100%" }}
            //format={"DD-MMM-YY @ HA"}
            format={"MMM-YY"}
            value={[acStorStatStore.startdate, acStorStatStore.enddate]}
            allowClear={false}
            size={"large"}
            picker="month"
            showMinute={false}
            onChange={(date) => {
              acStorStatStore.changeDateRange(date);
            }}
          />
          <InputGroup.Button
            color="green"
            loading={acStorStatStore.loading.stats}
            onClick={() => this.reloadData()}
          >
            <Icon icon="refresh" />
          </InputGroup.Button>
        </InputGroup>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Monthly Report"} key={"Monthly Report"}>
            <MonthlyOverview />
          </TabPane>
          <TabPane tab={"Delivery Details"} key={"Delivery Details"}>
            <DeliveryDetails />
          </TabPane>
          <TabPane tab={"Delivery Report"} key={"Delivery Report"}>
            <MonthlyDeliveryReport />
          </TabPane>
          <TabPane tab={"Monthly Movement"} key={"Monthly Movement"}>
            <StaticItemsList />
          </TabPane>
          <TabPane tab={"Unmoved Items"} key={"Unmoved Items"}>
            <UnmovedItems />
          </TabPane>
        </Tabs>

        <hr />
      </LoadBack>
    );
  }
}

export default withRouter(observer(StatisticsMain));
