import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../Components/Dividers/Div14";
import accountStore from "../../../../../Stores/Account/AccountStore";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { FlexboxGrid, Icon, IconButton, Loader } from "rsuite";
import DeliveryModal from "../Delivery/DeliveryModal";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import StorageModal from "../Storage/StorageModal";
import Col2 from "../../../../../Components/Columns/Col2";
import modalACOrderStore from "../../../../../Stores/ModalStores/ModalACOrderStore";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class AccountCalendarTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      del: true,
      sto: true,
      aim: true,
      acc: true,
      delmodal: false,
      stomodal: false,
      deldata: {},
      stodata: {},
      showComp: false,
      showCanc: false,
    };
    this.changeST = this.changeST.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    this.setState({
      delmodal: false,
      deldata: {},
      stomodal: false,
      stodata: {},
    });
  }
  changeST(label, val) {
    this.setState({ [label]: val });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let evt = event.data;
    let incomp = { backgroundColor: "#006103", textColor: "white" };
    let outcomp = { backgroundColor: "#614301", textColor: "white" };
    let intrans = { backgroundColor: "#129903", textColor: "white" };
    let outtrans = { backgroundColor: "#b37b00", textColor: "white" };
    let inbook = { backgroundColor: "#acf2a5", textColor: "black" };
    let outbook = { backgroundColor: "#e6cd95", textColor: "black" };
    let final;
    if (evt.jobtype === "Delivery In" || evt.jobtype === "Storage In") {
      final = inbook;
      if (evt.status === "Completed") final = incomp;
      if (evt.status === "In Transit") final = intrans;
    } else if (
      evt.jobtype === "Delivery Out" ||
      evt.jobtype === "Storage Out"
    ) {
      final = outbook;
      if (evt.status === "Completed") final = outcomp;
      if (evt.status === "In Transit") final = outtrans;
    } else {
      final = outcomp;
    }
    if (evt.status === "Cancelled") {
      final = { backgroundColor: "#730006", textColor: "white" };
    }

    var style = {
      backgroundColor: final.backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 10,
      height: 15,
      color: final.textColor,
    };
    return {
      style: style,
    };
  };
  render() {
    let dels = accountStore.upcomingdels;
    if (this.state.showComp) {
      dels = [...dels, ...accountStore.pastdels];
    }
    let data = [];
    let singledata = {};

    if (accountStore.loadingacc || accountStore.loadingdel) {
      return (
        <div>
          <Div14>Calendar</Div14>
          <br />
          <Loader speed="slow" />
        </div>
      );
    }
    if (this.state.del) {
      for (let i = 0; i < dels.length; i++) {
        if (
          dels[i].jobtype === "Storage Out" ||
          dels[i].jobtype === "Delivery Out" ||
          dels[i].jobtype === "Storage In" ||
          dels[i].jobtype === "Delivery In"
        ) {
          singledata = {
            title: `${dels[i].status} - ${dels[i].jobtype} ${PRKD(
              dels[i].totalqty
            )} items - ${PRKD(dels[i].totalcbm)} CBM (${
              dels[i].emlocation.name
            })`,
            date: dels[i].jobdate,
            data: dels[i],
          };
        } else if (dels[i].jobtype === "Delivery") {
          singledata = {
            title: `${dels[i].status} - From ${dels[i].fromcity} To ${dels[i].tocity} `,
            date: dels[i].date,
            data: dels[i],
          };
        }
        data = [...data, singledata];
      }
    }

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <DeliveryModal
          del={this.state.deldata}
          onHide={this.onHide}
          show={this.state.delmodal}
        />
        <StorageModal onHide={this.onHide} show={this.state.stomodal} />
        <Div14>Account Calendar</Div14>

        <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <FlexboxGrid>
            <Col2></Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="truck" />}
                block
                color="green"
                appearance={this.state.showComp ? "primary" : "ghost"}
                onClick={() =>
                  this.setState({ showComp: !this.state.showComp })
                }
              >
                {this.state.showComp ? "Hide" : "Show"} Completed
              </IconButton>
            </Col2>
          </FlexboxGrid>
        </div>
        <br />
        <Calendar
          localizer={localizer}
          events={data}
          eventPropGetter={this.eventStyleGetter}
          startAccessor={"date"}
          endAccessor={"date"}
          style={{ height: 500 }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor={"title"}
          popup
          onSelectEvent={(e) => {
            if (e.data.jobtype === "Delivery") {
              this.setState({ deldata: e.data, delmodal: true });
            } else if (
              e.data.jobtype === "Delivery In" ||
              e.data.jobtype === "Delivery Out" ||
              e.data.jobtype === "Storage In" ||
              e.data.jobtype === "Storage Out"
            ) {
              modalACOrderStore.getSingleACOrder(e.data._id, true);
              this.setState({ stomodal: true });
            }
          }}
          onNavigate={(e) => console.log(e)}
        />
      </div>
    );
  }
}

export default observer(AccountCalendarTab);
