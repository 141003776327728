import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../Components/Dividers/Div14";

import RangePickerSTD from "../../../Components/Inputs/STD/RangePickerSTD";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import { message } from "antd";
import Col2 from "../../../Components/Columns/Col2";

import moment from "moment";
import StarRatingSTD from "../../../Components/Inputs/STD/StarRatingSTD";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import MaterialTable from "material-table";
import DeliverySummary from "../../Accounts/Dashboard/Tabs/Delivery/DeliverySummary";

import { withRouter } from "react-router";
import singleJobStore from "../../../Stores/SingleJobStore";
import sjStore from "../../../Stores/SideJobs/SJStore";

const instance = axios.create({ baseURL: baseURL });

class FeedbackMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: [],
      date: [moment().subtract(14, "days"), moment()],
      loading: false,
      reviewed: true,
      del: { account: { deliveryContract: {} } },
      job: {},
      showDel: false,
      showJob: false,
    };
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.changeToReviewed = this.changeToReviewed.bind(this);
    this.showModal = this.showModal.bind(this);
  }
  showModal(row) {
    if (row.type === "Job") {
      this.setState({ job: row.job, showJob: true });
    } else {
      this.setState({ del: row.del, showDel: true });
    }
  }
  onHide() {
    this.setState({ showDel: false, showJob: false });
  }
  componentDidMount() {
    this.loadFeedback();
  }
  editVal(date, label) {
    this.setState({ date: date });
  }
  loadFeedback = async () => {
    this.setState({ loading: true });
    let data = { date: this.state.date };
    await instance
      .put(`track/feedback/bydate`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ feedback: res.data });
        message.success(`Feedback Loaded`);
        this.setState({ loading: false });
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };
  changeToReviewed = async (row) => {
    this.setState({ loading: true });

    await instance
      .put(`track/feedback/review/${row._id}`, row, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Feedback Reviewed`);
        this.loadFeedback();
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let data = [...this.state.feedback];
    if (!this.state.reviewed) {
      data = data.filter((dt) => !dt.reviewed);
    }

    let columns = [
      { title: "ID", field: "feedbackNumId" },
      {
        title: "Rating",
        field: "rating",
        render: (rowData) => (
          <div>
            <Icon icon={rowData.reviewed ? "check-circle" : "info-circle"} />{" "}
            <StarRatingSTD small val={rowData.rating} />
          </div>
        ),
      },
      { title: "Remarks", field: "remarks" },
      {
        title: "Type",
        field: "type",
        render: (rowData) => (
          <b>
            {rowData.type} #
            {rowData.type === "Job"
              ? rowData.job.jobNumId
              : rowData.del.deliveryNumId}
          </b>
        ),
      },
      {
        title: "Client",
        field: "client",
        render: (rowData) => (
          <b>
            {rowData.type === "Job"
              ? `(${rowData.job.client.clientNumId}) ${rowData.job.client.name}`
              : `(${rowData.del.account.client.clientNumId}) ${rowData.del.account.client.name}`}
          </b>
        ),
      },
    ];

    let actions = [
      (rowData) => ({
        icon: "web",
        tooltip: `Go To ${rowData.type}`,
        onClick: (event, rowData) => {
          if (rowData.type === "Job") {
            this.props.history.push(`/singlejob/${rowData.job._id}`);
            singleJobStore.getJob(rowData.job._id, true);
            sjStore.getSJs(rowData.job._id, true);
          } else if (rowData.type === "Delivery") {
            this.props.history.push(`/trackdelivery/${rowData.del._id}`);
          }
        },
      }),
      (rowData) => ({
        icon: "check",
        tooltip: "Click Reviewed",
        hidden: rowData.reviewed,
        onClick: (event, rowData) => {
          this.changeToReviewed(rowData);
        },
      }),
    ];

    let title = "Customer Feedback";

    return (
      <div>
        <UnifiedModal
          show={this.state.showJob}
          title="Job Details"
          onHide={this.onHide}
          footer={<div />}
        >
          <h6>JOB DETAILS PAGE</h6>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showDel}
          title="Delivery Details"
          onHide={this.onHide}
          footer={<div />}
        >
          <DeliverySummary
            del={this.state.del}
            delCont={this.state.del.account.deliveryContract}
            account={this.state.del.account}
          />
        </UnifiedModal>
        <Div14>Feedback Review</Div14>
        <br />
        <RangePickerSTD
          val={this.state.date}
          editVal={this.editVal}
          label="date"
          title="Date Range"
          size="sm"
        />
        <br />
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              block
              icon={<Icon icon="refresh" />}
              color="yellow"
              onClick={() => this.loadFeedback()}
              disabled={this.state.date.length === 0}
            >
              Load Feedback
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              block
              icon={<Icon icon={this.state.reviewed ? "eye" : "eye-slash"} />}
              color="green"
              onClick={() => this.setState({ reviewed: !this.state.reviewed })}
              appearance={this.state.reviewed ? "default" : "ghost"}
            >
              {this.state.reviewed ? "Showing Reviewed" : "Hiding Reviewed"}
            </IconButton>
          </Col2>
        </FlexboxGrid>
        <hr />

        <MaterialTable
          isLoading={this.state.loading}
          title={data.length + " " + title}
          columns={columns}
          style={{ fontSize: 12 }}
          data={data}
          onRowClick={(event, row) => this.showModal(row)}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : title
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={actions}
        />
      </div>
    );
  }
}

export default withRouter(observer(FeedbackMain));
