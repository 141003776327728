import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Empty } from "antd";

import { Badge, Divider, Icon, Loader } from "rsuite";
import notificationStore from "../../../Stores/NotificationStore";
import UnifiedModal from "../../Modal/UnifiedModal";
import NotificationRow from "./NotificationRow";
import LoadBack from "../../Loaders/LoadBack";
import Div14 from "../../Dividers/Div14";
import FlexboxButton from "../../Modal/FlexboxButton";
import { Tooltip } from "@material-ui/core";

class NotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Unread", show: false };
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({ show: false, tab: "Unread" });
    notificationStore.markAsRead();
  }

  render() {
    let nfts = notificationStore;
    let loading = nfts.loading.unread;
    let read = nfts.read;
    let unread = nfts.unread;
    let empty = false;
    if ((read.length === 0) & (unread.length === 0)) empty = true;

    return (
      <>
        <Badge content={unread.length > 0 ? unread.length : false}>
          {loading ? (
            <Loader size="xs" />
          ) : (
            <Tooltip title={"Notifications"} arrow placement="right">
              <Icon
                icon={"bell"}
                size="lg"
                onClick={() => {
                  this.setState({ show: true });
                  nfts.getAllNotifications();
                }}
              />
            </Tooltip>
          )}
        </Badge>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          footer={false}
          title="Notification Page"
        >
          <LoadBack loading={loading}>
            {!empty && (
              <FlexboxButton
                icon="trash"
                text="Clear Notifications"
                color="red"
                size="xs"
                app="ghost"
                loading={false}
                oC={() => {
                  notificationStore.deleteAllNotifications();
                }}
              />
            )}
            {unread.length > 0 && (
              <>
                <Div14>New Notifications:</Div14>
                <NotificationRow notify={unread} onHide={this.onHide} />
                <hr />
              </>
            )}
            {read.length > 0 && (
              <>
                <Div14>Old Notifications:</Div14>
                <NotificationRow notify={read} onHide={this.onHide} />
              </>
            )}
            {empty && <Empty description="No Notifications at this time." />}
          </LoadBack>
        </UnifiedModal>
        <Divider vertical />
      </>
    );
  }
}

export default withRouter(observer(NotificationPage));
