import { Container } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, FlexboxGrid, HelpBlock, Loader } from "rsuite";
import { Parallax } from "react-parallax";
import background from "../../../Static/Images/background.jpg";
import { Button } from "rsuite";
import userStore from "../../../Stores/UserStore";
import { Result, Spin } from "antd";
import Div14 from "../../../Components/Dividers/Div14";

class AuthMain extends Component {
  render() {
    let footerlogin = (
      <Button
        block
        color="blue"
        disabled={userStore.loading}
        style={{ boxShadow: "0px 0px 3px black" }}
        onClick={() =>
          this.props.history.push(`/referral/login/${this.props.token}`)
        }
      >
        Login to Existing Account
      </Button>
    );
    let footersignup = (
      <Button
        block
        color="blue"
        style={{ boxShadow: "0px 0px 3px black" }}
        disabled={userStore.loading}
        onClick={() =>
          this.props.history.push(`/referral/signup/${this.props.token}`)
        }
      >
        Create a New Account
      </Button>
    );
    let footerpassreset = (
      <Button
        block
        appearance="subtle"
        onClick={() => this.props.history.push("/forgotpass")}
      >
        Reset Password
      </Button>
    );

    let client = this.props.client;
    let formerrorr = false;
    let expired = this.props.expired;
    let linkused = this.props.linkused;
    let clientdata = (
      <>
        <Div14>
          Invitation from {client.name} ({client.clientNumId})
        </Div14>
        <HelpBlock>
          You have been invited by {client.name}, please use the below form to
          login to an existing account or register for a new one.
        </HelpBlock>
      </>
    );
    if (client.clientNumId === "" || expired) {
      formerrorr = true;
      clientdata = (
        <Result
          status="warning"
          title="Link has expired."
          subTitle="Kindly note the link has expired or is not available. Please contact your staff to share another link or our Easy Move office for support. You can use the below buttons to Login or Register."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => this.props.history.push("/login")}
            >
              Login
            </Button>,
            <Button
              type="primary"
              key="console"
              onClick={() => this.props.history.push("/signup")}
            >
              Register
            </Button>,
          ]}
        />
      );
    } else if (linkused) {
      formerrorr = true;
      clientdata = (
        <Result
          status="warning"
          title="Link has already been used."
          subTitle="Kindly note the link has already been used and is not available. Please contact your staff to share another link or our Easy Move office for support. You can use the below buttons to Login or Register."
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => this.props.history.push("/login")}
            >
              Login
            </Button>,
            <Button
              type="primary"
              key="console"
              onClick={() => this.props.history.push("/signup")}
            >
              Register
            </Button>,
          ]}
        />
      );
    }
    if (this.props.loading) {
      formerrorr = false;
      clientdata = <Loader center />;
    }

    if (formerrorr) {
      return (
        <Spin
          spinning={userStore.loading}
          size="large"
          tip="Loading..."
          style={{ color: "black" }}
        >
          <div
            style={{
              background: "radial-gradient(circle, #242526, #242526)",
            }}
          >
            <Parallax
              blur={1}
              bgImage={background}
              bgImageAlt="Background Image"
              strength={1}
              style={{
                minHeight: "95vh",
                boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
                WebkitMaskImage:
                  "linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)",
              }}
            >
              <div style={{ minHeight: "95vh" }} />
            </Parallax>
            <Container style={{ position: "absolute", top: 75 }}>
              <FlexboxGrid justify="center">
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  style={{
                    margin: "2%",
                    padding: "2%",
                    borderRadius: 10,
                    boxShadow: "0px 0px 5px black",
                    background:
                      "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                  }}
                >
                  {clientdata}
                </Col>
              </FlexboxGrid>
            </Container>
          </div>
        </Spin>
      );
    }

    return (
      <Spin
        spinning={userStore.loading}
        size="large"
        tip="Loading..."
        style={{ color: "black" }}
      >
        <div
          style={{
            background: "radial-gradient(circle, #242526, #242526)",
          }}
        >
          <Parallax
            blur={1}
            bgImage={background}
            bgImageAlt="Background Image"
            strength={1}
            style={{
              minHeight: "95vh",
              boxShadow: "10px 10px 70px -50px rgba(0,0,0,0.75)",
              WebkitMaskImage:
                "linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)",
            }}
          >
            <div style={{ minHeight: "95vh" }} />
          </Parallax>
          <Container style={{ position: "absolute", top: 75 }}>
            <FlexboxGrid>
              <Col
                xs={24}
                sm={24}
                md={8}
                style={{
                  margin: "2%",
                  padding: "2%",
                  borderRadius: 10,
                  boxShadow: "0px 0px 5px black",
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                }}
              >
                {clientdata}
              </Col>
            </FlexboxGrid>
            <FlexboxGrid>
              <Col
                xs={24}
                sm={24}
                md={8}
                style={{
                  margin: "2%",
                  padding: "2%",
                  borderRadius: 10,
                  boxShadow: "0px 0px 5px black",
                  minHeight: "60vh",
                  background:
                    "radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)",
                }}
              >
                {this.props.children}
                <br />
                <hr />
                {this.props.type !== "login" && footerlogin}
                {this.props.type !== "signup" && footersignup}
                {this.props.type !== "passreset" && footerpassreset}
              </Col>
            </FlexboxGrid>
          </Container>
        </div>
      </Spin>
    );
  }
}

export default withRouter(observer(AuthMain));
