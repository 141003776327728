import React, { Component } from "react";
import { InputGroup, InputNumber } from "rsuite";

class NumbInputSTD extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;
    let label = this.props.label;
    let val = this.props.val;
    let size = "lg";
    if (this.props.size) size = this.props.size;
    let min = 0;
    if (this.props.min) min = this.props.min;
    let max = 100000000;
    if (this.props.max) max = this.props.max;

    let index = this.props.index;
    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <InputNumber
          min={min}
          max={max}
          size={size}
          value={val}
          disabled={disabled}
          onChange={(e) => this.props.editVal(e, label, index)}
          scrollable={false}
        />
      </InputGroup>
    );
  }
}

export default NumbInputSTD;
