import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import Div14 from "../../../../../../Components/Dividers/Div14";
import OrderItemList from "./OrderItemList";
import SingleItemRow from "./SingleItemRow";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class NewDamageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      show: false,
      items: [],
      remarks: "",
      loading: false,
    };

    this.changeStep = this.changeStep.bind(this);
    this.changeItem = this.changeItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postNewReport = this.postNewReport.bind(this);
  }

  postNewReport = async () => {
    let items = [];

    for (let i = 0; i < this.state.items.length; i++) {
      items = [
        ...items,
        {
          acitem: this.state.items[i].acitem.itemid,
          qty: this.state.items[i].qty,
          type: this.state.items[i].type,
          remarks: this.state.items[i].remarks,
        },
      ];
    }
    let report = {
      acorder: newStorageStore.oldorder._id,
      item: [...items],
      status: "Pending",
    };
    let url = `ac/damagereport/new`;

    this.setState({ loading: true });

    await instance
      .post(url, report, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
        message.success("Damage Report Created");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  onHide() {
    this.setState({
      show: false,
      items: [],
      remarks: [],
      loading: false,
    });
  }
  editItem(val, label, index) {
    let items = [...this.state.items];
    items[index][label] = val;

    this.setState({ items: items });
  }
  changeItem(item) {
    let items = [...this.state.items];
    let findIndex = items.findIndex(
      (it) => it.acItemNumId === item.acItemNumId
    );

    if (findIndex < 0) {
      items = [
        ...items,
        {
          acitem: item,
          qty: 0,
          type: "",
          remarks: "",
          acItemNumId: item.acItemNumId,
        },
      ];
    } else {
      items.splice(findIndex, 1);
    }
    this.setState({ items: items });
  }
  changeStep(val) {
    if (this.state.step + val < 1) {
      this.onHide();
    } else if (this.state.step + val > 2) {
      this.postNewReport();
    } else {
      this.setState({ step: this.state.step + val });
    }
  }

  render() {
    let step = this.state.step;
    let content;
    let disabled = false;
    let items = this.state.items;

    if (step === 1) {
      disabled = items.length <= 0;
      content = (
        <div>
          <Div14>{items.length} Items Selected</Div14>
          {this.props.toaddress.map((to, index) => (
            <div key={index}>
              <Div14>Items List - Address #{index + 1}</Div14>
              {to.acitemmove.map((item, ind) => (
                <OrderItemList
                  items={items}
                  item={item}
                  key={ind}
                  changeItem={this.changeItem}
                />
              ))}
            </div>
          ))}
        </div>
      );
    } else if (step === 2) {
      disabled = items.length <= 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].qty <= 0) disabled = true;
        if (items[i].type === "") disabled = true;
      }
      content = (
        <div>
          {items.map((item, index) => (
            <SingleItemRow
              items={items}
              key={index}
              index={index}
              editItem={this.editItem}
            />
          ))}
        </div>
      );
    }

    let footer = (
      <ModalFooter
        icon={[
          step === 1 ? "close" : "chevron-left",
          step === 2 ? "save" : "chevron-right",
        ]}
        color={["red", "green"]}
        app={["ghost", "default"]}
        text={[
          step === 1 ? "Cancel" : "Back",
          step === 2 ? "Create Report" : "Next",
        ]}
        oC1={() => this.changeStep(-1)}
        oC2={() => this.changeStep(1)}
        disabled2={disabled}
        loading1={this.state.loading}
        loading2={this.state.loading}
      />
    );
    if (this.state.show) {
      return (
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={false}
        >
          <h3>
            New Damage Report for AC Order #{" "}
            {newStorageStore.neworder.acOrderNumId}
          </h3>
          <hr />
          {content}
          <hr />
          {footer}
        </UnifiedModal>
      );
    } else {
      return (
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              block
              color="orange"
              icon={<Icon icon="exclamation-triangle" />}
              onClick={() => this.setState({ show: true, type: "New" })}
            >
              New Damage Report
            </IconButton>
          </Col2>
        </FlexboxGrid>
      );
    }
  }
}

export default withRouter(observer(NewDamageForm));
