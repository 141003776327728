import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Box, AppBar, Drawer } from "@material-ui/core";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@material-ui/core";
import { CssBaseline } from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { observer } from "mobx-react";
import logo from "../../Static/Images/easymoveicon.png";

import colors from "../../Static/Brand/colors";

import SignInButton from "./SignInButton";
import EMstaffTabs from "./Tabs/Staff/EMstaffTabs";
import userStore from "../../Stores/UserStore";
import SignedIn from "./Tabs/SignedIn";
import Management from "./Tabs/Accordians/Management";
import Admin from "./Tabs/Accordians/Admin";
import Financial from "./Tabs/Accordians/Financial";
import LocationHeader from "../../Views/Other/LocationHeader";
import Div14 from "../Dividers/Div14";
import HRSystem from "./Tabs/Accordians/HRSystem";
import AllUsers from "./Tabs/Accordians/AllUsers";
import AllClientTabs from "./Tabs/ClientTab/AllClientTabs";
import SingleLink from "./Tabs/SingleLink";
import WarehouseMoveTab from "./Tabs/Accordians/WarehouseMoveTab";
import OperHead from "./Tabs/Accordians/OperHead";

let primcol = colors.primcol;

let drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 12010,
    height: 50,
    color: "white",
    backgroundColor: primcol,
    // background:
    //   "linear-gradient(0deg, rgba(51,47,47,1) 0%, rgba(70,70,70,1) 100%)",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarTrans: {
    zIndex: 12010,
    height: 50,
    color: "white",
    background:
      "linear-gradient(0deg, rgba(10,89,158,0.95) 0%, rgba(18,115,199,0.95) 100%)",
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  hide: {
    display: "none",
  },
  drawer: {
    zIndex: 1000,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    justify: "center",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    //width: !userStore.signedIn ? 0 : theme.spacing(7) + 1,
    [theme.breakpoints.down("xl")]: {
      width: 60,
    },
    [theme.breakpoints.down("lg")]: {
      width: 60,
    },
    [theme.breakpoints.down("md")]: {
      width: 0,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const MiniDrawer = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <Box display="block" displayPrint="none" style={{ zIndex: 200 }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={
            scrollPosition > 150
              ? clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })
              : clsx(classes.appBarTrans, {
                  [classes.appBarShift]: open,
                })
          }
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>

            {open ? (
              <MenuIcon />
            ) : (
              <img
                src={logo}
                height="30"
                className="d-inline-block align-top"
                alt="Easy Move Logo"
              />
            )}
            <div
              style={{
                color: "white",
                flexGrow: 1,
                justifyContent: "start",
                paddingLeft: "0%",
              }}
              onClick={handleDrawerClose}
            />
            <div
              style={{
                color: "white",
                flexGrow: 1,
                justifyContent: "center",
                paddingLeft: "0%",
                fontWeight: "bold",
              }}
              onClick={handleDrawerClose}
            >
              <LocationHeader />
            </div>
            <SignInButton />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="left"
          open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {open ? (
            <div className={classes.toolbar}>
              {userStore.signedIn && (
                <SignedIn hdc={handleDrawerClose} open={open} />
              )}
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
          ) : (
            <div className={classes.toolbar}></div>
          )}

          {userStore.user && userStore.user.jobs && (
            <SingleLink
              page="/combinedcalendar"
              icon="calendar"
              title="Calendar"
              hdc={handleDrawerClose}
            />
          )}
          <AllClientTabs open={open} hdc={handleDrawerClose} />

          {userStore.user && userStore.user.group === "EMStaff" && (
            <EMstaffTabs hdc={handleDrawerClose} open={open} />
          )}

          {userStore.user && userStore.user.finance && (
            <Financial open={open} hdc={handleDrawerClose} />
          )}
          {userStore.user && userStore.user.mgt && (
            <Management open={open} hdc={handleDrawerClose} />
          )}
          {userStore.user && userStore.user.hr && (
            <HRSystem open={open} hdc={handleDrawerClose} staff={true} />
          )}
          {userStore.user && userStore.user.admin && (
            <Admin open={open} hdc={handleDrawerClose} />
          )}
          {userStore.user && userStore.user.whmoveview && (
            <WarehouseMoveTab open={open} hdc={handleDrawerClose} />
          )}
          {userStore.user && userStore.user.operhead && (
            <OperHead open={open} hdc={handleDrawerClose} />
          )}
          <AllUsers open={open} hdc={handleDrawerClose} />

          <Div14 />
          <br />
        </Drawer>
      </Box>
      <main style={{ width: "100%", overflowX: "hidden" }}>
        <Box display="block" displayPrint="none">
          <div style={{ height: 50 }} />
        </Box>
        {children}
      </main>
    </div>
  );
};

export default withRouter(observer(MiniDrawer));
