import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import { Notification } from "rsuite";
import baseURL from "../../../../Static/baseURL/baseURL";

import userStore from "../../../../Stores/UserStore";
import serviceStore from "../../../../Stores/AdditionalStores/ServiceStore";
import Div14 from "../../../../Components/Dividers/Div14";
import TimelineComp from "../../../../Components/Timeline/TimelineComp";
import NextBackButton from "./Supporting/NextBackButton";
import ACCStep1 from "./Steps/ACCStep1";
import ACCStep2 from "./Steps/ACCStep2";
import ACCStep3 from "./Steps/ACCStep3";
import newACCOrderStore from "../../../../Stores/AccountUpdated/ACC/NewACCStore";
import ACCStep4 from "./Steps/ACCStep4";

const instance = axios.create({ baseURL: baseURL });

class NewAIMOrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      show: false,
      type: "New",
    };
    this.changeStep = this.changeStep.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
  }

  onHide() {
    this.setState({ show: false });
  }
  changeStep(val) {
    if ((this.state.step === 1) & (val < 0)) {
      this.props.history.push(`/acc/dash/${this.props.match.params.clientid}`);
    } else if (this.state.step === 1) {
      this.setState({ step: this.state.step + val });
    } else {
      this.setState({ step: this.state.step + val });
    }
  }
  postNew() {
    let data = newACCOrderStore.newacc;
    data.client = this.props.match.params.clientid;

    this.setState({ loading: true });
    let url = "/ac/accorder/new";
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        Notification["success"]({
          title: `New Customer Clearance Request Created.`,
        });
        this.props.history.push(`/acc/dash/${data.client}`);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });

        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  componentDidMount() {
    newACCOrderStore.createNewOrder();
    serviceStore.getServices();
  }

  render() {
    let allsteps = ["Initial Details", "Specifications", "Items", "Submit"];

    let content = <ACCStep1 />;
    let step = this.state.step;
    if (step === 2) {
      content = <ACCStep2 />;
    } else if (step === 3) {
      content = <ACCStep3 />;
    } else if (step === 4) {
      content = <ACCStep4 />;
    }
    return (
      <div>
        <Div14>New Customer Clearance Account Request</Div14>
        <TimelineComp steps={allsteps} step={step - 1} />
        <NextBackButton
          step={this.state.step}
          changeStep={this.changeStep}
          allsteps={allsteps}
          postNew={this.postNew}
        />
        {content}
      </div>
    );
  }
}

export default withRouter(observer(NewAIMOrderMain));
