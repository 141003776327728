import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import equipmentStore from "../../../Stores/EquipmentStores/EquipmentStore";

import LoadBack from "../../../Components/Loaders/LoadBack";

import { Tabs } from "antd";
import EquipmentTable from "./EquipmentTabs/EquipmentTable";
import Div14 from "../../../Components/Dividers/Div14";
import FuelTable from "./EquipmentTabs/FuelTable";
import ServiceTable from "./EquipmentTabs/ServiceTable";
import ChecklistTable from "./EquipmentTabs/ChecklistTable";

const { TabPane } = Tabs;

class EquipmentMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Equipment List" };
  }

  render() {
    return (
      <LoadBack loading={equipmentStore.loading.equipments}>
        <Div14>Equipment Main Page</Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Equipment List"} key={"Equipment List"}>
            <EquipmentTable />
          </TabPane>
          <TabPane tab={"Fuel Record"} key={"Fuel Record"}>
            <FuelTable />
          </TabPane>
          <TabPane tab={"Service Records"} key={"Service Records"}>
            <ServiceTable />
          </TabPane>
          <TabPane tab={"Checklist"} key={"Checklist"}>
            <ChecklistTable />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(EquipmentMain));
