import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, {
  Column,
  Grouping,
  Selection,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import moment from "moment";
import { PRKD, Parsed } from "../../Functions/Pricing/PRKDCalc";
import JobDetailsModal from "../Lists/SideJobLists/Template/JobDetailsModal";
import singleJobStore from "../../Stores/SingleJobStore";
import sjStore from "../../Stores/SideJobs/SJStore";
class JobsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      subjob: { type: "" },
      job: {},
      jobId: "",
    };
    this.DaysColumn = this.DaysColumn.bind(this);
    this.CBMTotal = this.CBMTotal.bind(this);
    this.PriceTotal = this.PriceTotal.bind(this);
    this.SubJobId = this.SubJobId.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  onSelect(data) {
    if (data) {
      if (data.selectedRowsData) {
        if (data.selectedRowsData[0]) {
          this.showHideModal(true, data.selectedRowsData[0]);
        }
      }
    }
  }
  fetchJobDetails(jobId) {
    singleJobStore.getJob(jobId, true);
    sjStore.getSJs(jobId, true);
  }

  showHideModal(val, subjob) {
    if (val) {
      this.setState({ show: val, jobId: subjob.job, subjob: subjob });

      this.fetchJobDetails(subjob.job);
    } else {
      this.setState({ show: false, jobId: "", subjob: { type: "" } });
    }
  }
  SubJobId(cellData) {
    return `${cellData.data.jobtype}-${cellData.data.sjNumId}`;
  }
  DaysColumn(cellData) {
    let start = cellData.data.start;
    let end = cellData.data.end;
    let diff = PRKD(moment(end).diff(moment(start), "days") + 1);

    return diff;
  }
  CBMTotal(cellData) {
    let start = cellData.data.start;
    let end = cellData.data.end;
    let diff = moment(end).diff(moment(start), "days") + 1;

    return PRKD(Parsed(diff) * Parsed(cellData.data.statperday.cbm));
  }
  PriceTotal(cellData) {
    let start = cellData.data.start;
    let end = cellData.data.end;
    let diff = moment(end).diff(moment(start), "days") + 1;

    return PRKD(Parsed(diff) * Parsed(cellData.data.statperday.price));
  }

  render() {
    let data = this.props.data;

    return (
      <div>
        <JobDetailsModal
          show={this.state.show}
          showModal={this.showHideModal}
          loading={false}
          subjob={this.state.subjob}
          jobId={this.state.jobId}
          type={this.state.subjob.jobtype}
        />
        <DataGrid
          dataSource={data}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
          onSelectionChanged={this.onSelect}
        >
          <Selection mode="single" />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column caption="Actions" cellRender={this.ActionButs} width={100} />
          <Column
            dataField="sjNumId"
            caption="ID"
            alignment="center"
            width={80}
            cellRender={this.SubJobId}
          />
          <Column
            dataField="clientname"
            caption="Client"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="start"
            caption="Start"
            alignment="center"
            width={80}
            dataType={"date"}
            format={"dd-MMM-yy"}
          />
          <Column
            dataField="end"
            caption="End"
            alignment="center"
            width={80}
            dataType={"date"}
            format={"dd-MMM-yy"}
          />
          <Column
            dataField="days"
            caption="Days"
            alignment="center"
            minWidth={80}
            cellRender={this.DaysColumn}
          />

          <Column
            dataField="statperday.cbm"
            caption="CBM"
            alignment="center"
            minWidth={80}
            cellRender={this.CBMTotal}
          />
          <Column
            dataField="statperday.price"
            caption="Price"
            alignment="center"
            minWidth={80}
            cellRender={this.PriceTotal}
          />
          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(JobsTable));
