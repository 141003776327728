import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../Static/baseURL/baseURL";
import userStore from "./UserStore";

const instance = axios.create({ baseURL: baseURL });

class ClientStore {
  constructor() {
    this.clients = [];
    this.clientsloaded = false;
    this.clientjobloaded = true;
    this.loading = false;
    this.clientJobs = [];
    this.selectedClient = { name: "", clientNumId: "", clientId: "" };
    this.filter = { type: "" };
    this.singleClient = { name: "", clientNumId: "", clientId: "" };

    makeObservable(this, {
      clients: observable,
      clientsloaded: observable,
      clientjobloaded: observable,
      loading: observable,
      clientJobs: observable,
      selectedClient: observable,
      singleClient: observable,
      filter: observable,
      filtClients: computed,
      companyList: computed,
    });
  }

  // FETCH
  getClients(override) {
    let loaded = false;
    if (this.loading || this.clientsloaded) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading = true;
      return instance
        .get(`/client/basic/get/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.clients = res.data;
          this.clientsloaded = true;
          this.loading = false;
          console.log("Clients Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getSingleClients(clientid, override) {
    if (clientid !== this.singleClient._id || override) {
      let loadstart = true;
      this.loading = loadstart;
      return instance
        .get(`/client/basic/get/clientbyid/${clientid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.singleClient = res.data;
          this.loading = false;
          console.log("Single Client Fetched");
        })
        .catch((err) => {
          this.singleClient = { clientNumId: "", _id: "" };
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getClientJobs(client) {
    return instance
      .get(`/client/basic/getjob/${client._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.selectedClient = client;
        this.clientJobs = res.data;
        console.log("Client Jobs Fetched");
        return true;
      })
      .catch((err) => {
        this.clientJobs = [];
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  get companyList() {
    return this.clients.filter((client) => client.type === "Company");
  }

  get filtClients() {
    let clients = [...this.clients];
    if (this.filter.type !== "") {
      return clients.filter((client) => client.type === this.filter.type);
    }
    return clients;
  }

  // FUNCTIONS:

  setFilter(type, val) {
    this.filter[type] = val;
  }
  resetFilter() {
    this.filter = { type: "" };
  }

  startLoading() {
    this.loading = true;
  }
}

const clientStore = new ClientStore();
export default clientStore;
