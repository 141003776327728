import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";
import mgtAttendanceStore from "../../../Stores/Management/MgtAttendanceStore";
import MaterialTable from "material-table";
import moment from "moment";
import Div14 from "../../../Components/Dividers/Div14";

class AttendanceGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let options = {
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: mgtAttendanceStore.attendancedata.dates,
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "Sign Ins",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };

    let seriesValue = [
      {
        name: "Attendance",
        data: mgtAttendanceStore.attendancedata.count,
      },
    ];
    let attendance = mgtAttendanceStore.attendancedata.attendance;
    let columnnames = [
      {
        title: "User",
        field: "createdAt",
        render: (rowData) => {
          return mgtAttendanceStore.selectedUser.username;
        },
      },
      {
        title: "Date",
        field: "createdAt",
        defaultSort: "desc",
        render: (rowData) => {
          return moment(rowData.createdAt).format("DD-MMM-YY HH:mm");
        },
      },
      {
        title: "Time Since",
        field: "createdAt",

        render: (rowData) => {
          return moment(rowData.createdAt).fromNow();
        },
      },
    ];
    return (
      <div>
        {mgtAttendanceStore.loaded.singleuser && (
          <>
            <Div14>
              {mgtAttendanceStore.selectedUser.username} Attendance Graph
            </Div14>
            <ReactApexChart
              series={seriesValue}
              options={options}
              type="bar"
              height={200}
            />

            <MaterialTable
              isLoading={this.props.loading}
              title={
                attendance.length +
                " Attendance for " +
                mgtAttendanceStore.selectedUser.username
              }
              columns={columnnames}
              style={{ fontSize: 12 }}
              data={attendance}
              options={{
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: true,
                pageSize: 20,
                pageSizeOptions: [20, 50, 100],
                toolbarButtonAlignment: "left",
                maxBodyHeight: 1000,
                tableLayout: "auto",
                exportButton: true,
                exportFileName: `Attendance as of ${moment(new Date()).format(
                  "DD-MMM-YY"
                )}`,
                padding: "dense",
              }}
              onRowClick={(event, row) => {
                if (row._id !== mgtAttendanceStore.selecteduserid) {
                  mgtAttendanceStore.getAttendance(row._id, true);
                }
              }}
            />
          </>
        )}
      </div>
    );
  }
}
export default withRouter(observer(AttendanceGraph));
