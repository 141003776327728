import { withRouter } from "react-router";
import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../Components/Dividers/Div14";
import DeliveryNotePDF from "../../../../../Components/PDFs/Accounts/Deliveries/DeliveryNotePDF";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { Collapse } from "antd";

const { Panel } = Collapse;

class DeliveryNote extends Component {
  constructor(props) {
    super(props);
    this.state = { key: "0" };
    this.onChange = this.onChange.bind(this);
  }
  onChange(key) {
    this.setState({ key: key });
  }

  render() {
    let sto = newStorageStore.oldorder;
    let account = newStorageStore.account;
    let inout = newStorageStore.delstoinout.inout;

    return (
      <div>
        <Div14>Delivery Note{sto.toaddress.length > 1 ? "s" : ""}</Div14>
        <Collapse defaultActiveKey={[""]} onChange={this.onChange} accordion>
          {sto.toaddress.map((to, index) => (
            <Panel
              header={`Location #${to.order} Delivery Note (${to.status})`}
              key={index}
              style={{
                backgroundColor:
                  to.status === "Completed" ? "#d3ffcc" : "#fccaca",
              }}
            >
              <DeliveryNotePDF
                sto={sto}
                client={account}
                type={inout}
                key={index}
                to={to}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export default withRouter(observer(DeliveryNote));
