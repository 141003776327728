import { observer } from "mobx-react";
import React, { Component } from "react";
import { Icon, IconButton, FlexboxGrid, Loader, HelpBlock } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import Div14 from "../../../Components/Dividers/Div14";
import IMCCJobs from "./Sections/IMCCJobs";
import SelectInputSTU from "../../../Components/Inputs/Updatable/SelectInputSTU";
import { Empty } from "antd";
import DateInputSTU from "../../../Components/Inputs/Updatable/DateInputSTU";
import StorageSections from "./Sections/StorageSections";
import editAccountStore from "../../../Stores/Account/EditAccountStore";
import moment from "moment";

class EditAccountMain extends Component {
  componentDidMount() {
    editAccountStore.getAccount(this.props.match.params.clientid);
  }

  render() {
    if (editAccountStore.loading) {
      return <Loader center />;
    } else if (!editAccountStore.loaded) {
      return <Empty description="Account Not Found" />;
    }

    let edited = editAccountStore.edited;
    let original = editAccountStore.original;
    let load = editAccountStore.load;
    let dates = [
      { title: "Start Date", label: "startdate" },
      { title: "End Date", label: "enddate" },
    ];

    let daterows = dates.map((date, index) => (
      <Col2 key={index}>
        <DateInputSTU
          val={edited[date.label]}
          org={original[date.label]}
          label={date.label}
          load={load.status}
          title={date.title}
          size="large"
          format={"DD-MMM-YY"}
          picker="date"
          editVal={editAccountStore.editmain}
          updateVal={editAccountStore.updateAccountMain}
        />
      </Col2>
    ));
    return (
      <div>
        <Div14>
          Account Details: ({original.client.name}) Account #
          {original.accountNumId}{" "}
        </Div14>
        <br />

        <br />
        <div style={{ padding: "2%" }}>
          <SelectInputSTU
            val={edited.status}
            org={original.status}
            label="status"
            load={load.status}
            title="Type"
            size="sm"
            editVal={editAccountStore.editmain}
            updateVal={editAccountStore.updateAccountMain}
            opts={["Pending", "Active", "Expired", "Cancelled"]}
          />
          <br />
          {daterows}
          <br />
          <HelpBlock style={{ textAlign: "center", fontStyle: "italic" }}>
            <b>Contract Duration: </b>
            {moment(edited.enddate).from(edited.startdate, true)}{" "}
          </HelpBlock>
          <br />
        </div>
        <IMCCJobs />
        <br />
        <div>
          <Div14>
            Delivery and Storage Section {`${edited.deliveryContract}`}
          </Div14>
          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={
                  <Icon
                    icon={
                      edited.deliveryContract !== null
                        ? "check-circle"
                        : "circle"
                    }
                  />
                }
                block
                appearance={
                  edited.deliveryContract !== null ? "primary" : "ghost"
                }
                color={edited.deliveryContract !== null ? "green" : "red"}
                onClick={() =>
                  editAccountStore.changeContract("deliveryContract")
                }
                loading={load.deliveryContract}
              >
                Delivery Contract
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                icon={
                  <Icon
                    icon={edited.storageContract ? "check-circle" : "circle"}
                  />
                }
                block
                appearance={edited.storageContract ? "primary" : "ghost"}
                color={edited.storageContract ? "green" : "red"}
                onClick={() =>
                  editAccountStore.changeContract("storageContract")
                }
                loading={load.storageContract}
              >
                Storage Contract
              </IconButton>
            </Col2>
          </FlexboxGrid>
          {edited.storageContract !== null && (
            <StorageSections edited={edited} original={original} />
          )}
        </div>
        <br />
      </div>
    );
  }
}

export default observer(EditAccountMain);
