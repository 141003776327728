import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import completeDelStore from "../../../../../../Stores/AccountUpdated/Delivery/CompleteDelStore";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import DeliveryNotFound from "../../../../StorageAccount/SingleACOrderPage/PickupDropoff/DeliveryNotFound";
import { Descriptions } from "antd";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import Div14 from "../../../../../../Components/Dividers/Div14";
import OrderSummaryTable from "../../../Modals/Supporting/OrderSummaryTable";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import OrderSummary from "./OrderSummary";
import SignPickup from "./SignPickup";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

class CompletePickup extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, showdelete: false, loading: true };
  }
  Dimension(cellData) {
    return `${PRKD(cellData.data.length / 100)} m x ${PRKD(
      cellData.data.width / 100
    )} m x ${PRKD(cellData.data.height / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.quantity) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  componentDidMount() {
    completeDelStore.getSingleACOrder(this.props.match.params.deliveryid, true);
    this.setState({
      step: 1,
      showdelete: false,
      loading: false,
    });
  }

  render() {
    let content;
    let rows;
    let del = completeDelStore.newdel;
    let account = completeDelStore.account;
    let timechg = newDeliveryStore.ctimechg;

    if (
      completeDelStore.newdel.status !== "Booked" ||
      completeDelStore.loading
    ) {
      content = <DeliveryNotFound />;
    } else {
      let from = del.fromaddress;
      if (del.maintype === "Fixed Per Type") {
        rows = (
          <div>
            <div
              style={{
                borderRadius: "5px",
                boxShadow: "1px 1px 5px black",
                padding: "1%",
                margin: "1%",
              }}
            >
              <Div14>Services:</Div14>
              <Descriptions
                labelStyle={{ width: "30%" }}
                title={false}
                bordered
                size="small"
                style={{ paddingBottom: "5px" }}
              >
                {del.toaddress.map((to, ind) => (
                  <>
                    <Descriptions.Item
                      label={`Service Dropoff #${ind + 1}: ${to.service.type}`}
                      span={3}
                      key={ind}
                    >
                      {PRKD(to.service.price)} KD
                    </Descriptions.Item>
                  </>
                ))}
              </Descriptions>
            </div>
          </div>
        );
      } else {
        rows = del.toaddress.map((to, index) => (
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
            key={index}
          >
            <Div14>Picked Up Items for Location {to.order}:</Div14>

            <DataGrid
              dataSource={to.items}
              keyExpr="name"
              allowColumnReordering={true}
              allowColumnResizing={true}
              showBorders={true}
              rowAlternationEnabled
              style={{ fontSize: 10 }}
              hoverStateEnabled={true}
              noDataText="No Items Added"
            >
              <FilterRow visible={false} applyFilter />
              <GroupPanel visible={false} />

              <Scrolling mode="standard" showScrollbar="always" />

              <ColumnFixing enabled={true} />
              <Column caption="Item Details" alignment="center">
                <Column
                  dataField="name"
                  caption="Item"
                  alignment="center"
                  minWidth={80}
                />
                <Column
                  dataField="serialnumber"
                  caption="Ser. No."
                  alignment="center"
                  minWidth={80}
                />
              </Column>
              <Column
                dataField="dimensions"
                caption="Dimensions"
                alignment="center"
                minWidth={140}
                cellRender={this.Dimension}
              />
              <Column
                dataField="quantity"
                dataType="number"
                caption="Quantity"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={80}
              />
              <Column caption="CBM Calcs" alignment="center">
                <Column
                  dataField="cbmperitem"
                  dataType="number"
                  caption="CBM Per Item"
                  alignment="center"
                  minWidth={80}
                  cellRender={this.CBMPerItem}
                />
                <Column
                  dataField="totalcbm"
                  dataType="number"
                  caption="Total CBM"
                  alignment="center"
                  minWidth={80}
                  cellRender={this.TotalCBM}
                />
              </Column>
            </DataGrid>
          </div>
        ));
      }
      content = (
        <div>
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <Div14>Order Summary:</Div14>
            <OrderSummaryTable
              del={del}
              hidetitle
              hidecost
              account={completeDelStore.account}
              timechg={newDeliveryStore.ctimechg}
            />
            <Div14>Pickup Location:</Div14>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Address" span={24}>
                {from.address}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{from.name}</Descriptions.Item>
              <Descriptions.Item label="Phone">{from.phone}</Descriptions.Item>
              <Descriptions.Item label="Type">{from.type}</Descriptions.Item>
              <Descriptions.Item label="City">{from.city}</Descriptions.Item>
              <Descriptions.Item label="Floor">{from.floor}</Descriptions.Item>
            </Descriptions>
          </div>
          {rows}
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <Div14>Signature:</Div14>
            <SignPickup type="Pickup" />
          </div>
          <hr />
          <OrderSummary del={del} account={account} timechg={timechg} />
        </div>
      );
    }
    return <LoadBack loading={completeDelStore.loading}>{content}</LoadBack>;
  }
}

export default withRouter(observer(CompletePickup));
