import { observer } from "mobx-react";
import React, { Component } from "react";

import { Descriptions } from "antd";

class ContainerInfo extends Component {
  render() {
    let acc = this.props.acc;

    let data2 = [
      { title: "Container Type", val: acc.container },
      { title: "# of Containers", val: acc.containercount },
      { title: "Packages", val: acc.packages },
      { title: "Weight (KG)", val: acc.weight },
    ];

    return (
      <div>
        <Descriptions bordered size="small">
          {data2.map((val, index) => (
            <Descriptions.Item
              key={index}
              label={val.title}
              span={2}
              labelStyle={{
                textAlign: "right",
                fontWeight: "bold",
                width: "30%",
              }}
            >
              {val.val}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    );
  }
}

export default observer(ContainerInfo);
