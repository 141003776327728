import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import { Parsed, PRKD } from "../../../Functions/Pricing/PRKDCalc";

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

const instance = axios.create({ baseURL: baseURL });

class WarehouseAccountStore {
  constructor() {
    this.accounts = [];
    this.expired = [];
    this.pending = [];

    this.loading = {
      accounts: false,
      acorders: false,
      expired: false,
      pending: false,
    };
    this.loaded = {
      accounts: false,
      acorders: false,
      expired: false,
      pending: false,
    };

    makeObservable(this, {
      accounts: observable,
      pending: observable,
      expired: observable,

      loading: observable,
      loaded: observable,
      breakdownPerWH: computed,
      activeclientdata: computed,
    });
  }

  // FETCH
  getAccounts(override) {
    let loaded = false;
    if (this.loaded.accounts) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.accounts = false;
      this.loading.accounts = true;
      this.expired = [];
      this.pending = [];
      this.loaded.expired = false;
      this.loaded.pending = false;

      return instance
        .get(`/ac/account/whaccounts`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          this.accounts = accounts;
          this.loaded.accounts = true;
          this.loading.accounts = false;
          console.log("Accounts Fetched from WH Account");
        })
        .catch((err) => {
          this.loading.accounts = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getCustom(type, override) {
    let loaded = false;
    let status = "";
    if (type === "Expired") {
      if (this.loaded.expired) loaded = true;
      if (override) loaded = false;
      if (!loaded) status = "expired";
    } else if (type === "Pending") {
      if (this.loaded.pending) loaded = true;
      if (override) loaded = false;
      if (!loaded) status = "pending";
    } else {
      loaded = true;
    }
    if (!loaded) {
      this[status] = [];
      this.loaded[status] = false;
    }

    if (!loaded) {
      this.loading.accounts = true;
      return instance
        .get(`/ac/account/customwhaccountstatus/${status}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          this[status] = accounts;

          this.loaded[status] = true;
          this.loading.accounts = false;
          console.log(`${type} Accounts Fetched`);
        })
        .catch((err) => {
          this.loading.accounts = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching ${type} Account.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get breakdownPerWH() {
    let data = groupBy(this.accounts, "emloc");
    let final = [];
    let single = { name: "", qty: 0, cbm: 0 };
    let cbm = 0;
    let qty = 0;
    let warehouses = Object.keys(data);

    for (let i = 0; i < warehouses.length; i++) {
      single = { name: warehouses[i], qty: 0, cbm: 0 };
      cbm = 0;
      qty = 0;
      for (let j = 0; j < data[warehouses[i]].length; j++) {
        cbm += Parsed(data[warehouses[i]][j].totalcbm);
        qty += Parsed(data[warehouses[i]][j].totalqty);
      }
      single = { name: warehouses[i], qty: qty, cbm: cbm };
      final = [...final, single];
    }
    return final;
  }

  get activeclientdata() {
    let data = [];
    let totalcbm = 5000;

    for (let i = 0; i < this.accounts.length; i++) {
      totalcbm -= Parsed(this.accounts[i].totalcbm);
      data = [
        ...data,
        {
          x: this.accounts[i].name,
          y: PRKD(this.accounts[i].totalcbm),
        },
      ];
    }
    data = [{ x: "Remaining CBM", y: PRKD(totalcbm) }, ...data];
    return data;
  }

  // FUNCTIONS:

  startLoading() {
    this.loading = true;
  }
}

const warehouseAccountStore = new WarehouseAccountStore();
export default warehouseAccountStore;
