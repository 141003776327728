import { observer } from "mobx-react";
import React, { Component } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Html from "react-pdf-html";

const stylesheet = {
  // clear margins for all <p> tags
  p: {
    margin: 0,
    padding: 0,
    lineHeight: 1,
    fontSize: 10,
  },
  strong: {
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    lineHeight: 1,
    fontSize: 10,
  },
};

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
});

class InvoiceNotes extends Component {
  render() {
    let inv = this.props.inv;

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>Invoice Notes</Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Html
          collapse
          style={{
            fontSize: 10,
            margin: 0,
            padding: 0,
            lineHeight: 1,
            marginLeft: 5,
            marginRight: 5,
          }}
          stylesheet={stylesheet}
        >
          {inv.invnotes}
        </Html>
      </View>
    );
  }
}

export default observer(InvoiceNotes);
