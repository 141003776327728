import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Layout, Menu } from "antd";
import logo from "../../Static/Images/easymoveicon.png";
import { Icon, IconButton } from "rsuite";

const { Header } = Layout;

class NotSignedInHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.headerClick = this.headerClick.bind(this);
  }

  headerClick(val) {
    this.props.history.push(`/${val.key}`);
  }
  render() {
    let headerlinks = [
      {
        label: "Services",
        key: "em-solutions",
        children: [
          { label: "Storage", key: "em-storage" },
          { label: "Local Move", key: "em-localmove" },
          { label: "International Moves", key: "em-internationalmove" },
          { label: "Custom Clearance", key: "em-customclearance" },
        ],
      },
      {
        label: "Supply Chain",
        key: "em-supplychain",
        children: [
          { label: "Warehousing", key: "em-warehousing" },
          { label: "Last Mile", key: "em-lastmile" },
          { label: "Freight Forwarding", key: "em-freightforwarding" },
          { label: "Custom Clearance", key: "em-customclearance" },
          { label: "Fulfillment", key: "em-fullfillment" },
        ],
      },
      { label: "About Us", key: "em-aboutus" },
      { label: "Contact Us", key: "em-contactus" },
    ];

    let title = window.location.pathname.slice(1);
    if (title === "") {
      title = "em-home";
    }

    return (
      <>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            height: "40",
          }}
        >
          <img
            src={logo}
            className="logo"
            height="40"
            alt="Easy Move Logo"
            onClick={() => this.props.history.push("em-home")}
            style={{
              float: "left",
              height: "40px",
              margin: "16px 24px 16px 0",
            }}
          />
          {window.innerWidth > 600 ? (
            <IconButton
              icon={<Icon icon="user" />}
              onClick={() => this.props.history.push("/login")}
              color="green"
              size="sm"
              style={{ float: "right", margin: "16px 24px 16px 0" }}
            >
              Sign In
            </IconButton>
          ) : (
            <IconButton
              circle
              size="sm"
              icon={<Icon icon="user" />}
              onClick={() => this.props.history.push("/login")}
              color="green"
              style={{ float: "right", margin: "16px 24px 16px 0" }}
            />
          )}
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            selectedKeys={[title]}
            items={headerlinks}
            onClick={this.headerClick}
          />
        </Header>
        <div style={{ position: "absolute", width: "100%" }}>
          {this.props.children}
        </div>
      </>
    );
  }
}

export default withRouter(observer(NotSignedInHeader));
