import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Div14 from "../../../../../../Components/Dividers/Div14";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import AddBulkList from "./Supporting/ItemsSupport/AddBulkList";
import AddedItemsList from "./Supporting/ItemsSupport/AddedItemsList";
import NewEditItemModal from "./Supporting/ItemsSupport/NewEditItemModal";
import ExistingRows from "./Supporting/ItemsSupport/ExistingRows/ExistingRows";
import Col3 from "../../../../../../Components/Columns/Col3";

class SACStep4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showdelete: false,
      show: false,
      showbulk: false,
      showexist: false,
      type: "New",
      loading: false,
      item: {
        _id: "New",
        acItemNumId: "New",
        itemlength: 0,
        itemwidth: 0,
        itemheight: 0,
        quantity: 1,
        actualquantity: 1,
        itemname: "",
        itemserialnumber: "",
        itemproductid: "",
        index: 0,
      },
    };
    this.selectItem = this.selectItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.addSingle = this.addSingle.bind(this);
    this.onHide = this.onHide.bind(this);
    this.submitAdd = this.submitAdd.bind(this);
  }

  addSingle() {
    this.setState({
      show: true,
      type: "New",
      item: {
        _id: "New",
        acItemNumId: "New",
        itemlength: 0,
        itemwidth: 0,
        itemheight: 0,
        quantity: 1,
        actualquantity: 1,
        itemname: "",
        itemserialnumber: "",
        itemproductid: "",
        index: 0,
      },
    });
  }
  editItem(val, label) {
    let locked = false;

    if (this.state.item.acItemNumId !== "New") {
      if ((label !== "quantity") & (label !== "actualquantity")) {
        locked = true;
      }
    }

    if (locked) {
      console.log("Locked Field");
    } else {
      let newit = { ...this.state.item };
      newit[label] = val;
      if (label === "actualquantity") {
        newit.quantity = val;
      }

      this.setState({ item: newit });
    }
  }
  selectItem(data) {
    let item = data.data;
    item.index = data.rowIndex;

    this.setState({ item: item, type: "Edit", show: true });
  }
  submitAdd() {
    newStorageStore.addEditSingleItem(
      this.state.type,
      this.state.item,
      false,
      this.props.index
    );

    this.onHide();
  }
  onHide() {
    this.setState({
      show: false,
      showbulk: false,
      showexist: false,
      type: "New",
      loading: false,
    });
  }
  render() {
    let inout = newStorageStore.delstoinout.inout;

    if (this.state.type === "Existing") {
      return <ExistingRows onHide={this.onHide} index={this.props.index} />;
      // return <AddExistingList onHide={this.onHide} />;
    }

    return (
      <div
        style={{
          margin: "3px",
          padding: "2px",
          borderRadius: "5px",
          border: "1px solid grey",
          boxShadow: "1px 1px 5px black",
        }}
      >
        <NewEditItemModal
          submitAdd={this.submitAdd}
          item={this.state.item}
          editItem={this.editItem}
          show={this.state.show}
          type={this.state.type}
          onHide={this.onHide}
          index={this.props.index}
        />
        <AddBulkList
          index={this.props.index}
          show={this.state.showbulk}
          onHide={this.onHide}
        />
        <ExistingRows
          onHide={this.onHide}
          index={this.props.index}
          show={this.state.showexist}
        />

        <Div14>
          Location #{this.props.index + 1}:{" "}
          {newStorageStore.neworder.toaddress[this.props.index].name} -{" "}
          {newStorageStore.neworder.toaddress[this.props.index].city}
        </Div14>

        {this.state.loading ? (
          <div style={{ height: "500px" }} />
        ) : (
          <div>
            <FlexboxGrid>
              <Col3>
                {inout === "In" && (
                  <IconButton
                    size="xs"
                    block
                    color="green"
                    appearance="ghost"
                    icon={<Icon icon="cube" />}
                    onClick={() => this.addSingle()}
                  >
                    Add Single Item
                  </IconButton>
                )}
              </Col3>

              <Col3>
                {inout === "In" && (
                  <IconButton
                    size="xs"
                    block
                    color="green"
                    appearance="ghost"
                    icon={<Icon icon="multiple-lines-chart" />}
                    onClick={() =>
                      this.setState({ showbulk: true, loading: true })
                    }
                  >
                    Add Bulk
                  </IconButton>
                )}
              </Col3>
              <Col3>
                <IconButton
                  size="xs"
                  block
                  color="green"
                  appearance="ghost"
                  icon={<Icon icon="cube" />}
                  onClick={() => {
                    newStorageStore.selectExisting(this.props.index);
                    newStorageStore.selectPast(this.props.index);
                    this.setState({ showexist: true, loading: true });
                    newStorageStore.getExistingItems(
                      false,
                      true,
                      this.props.index
                    );
                  }}
                >
                  Add Existing Item
                </IconButton>
              </Col3>
            </FlexboxGrid>
            <br />
            <AddedItemsList
              addSingle={this.addSingle}
              loading={this.state.loading}
              selectItem={this.selectItem}
              index={this.props.index}
              acitemmove={
                newStorageStore.neworder.toaddress[this.props.index].acitemmove
              }
            />
          </div>
        )}
        <hr />

        <FlexboxGrid></FlexboxGrid>
      </div>
    );
  }
}

export default observer(SACStep4);
