import React, { Component } from "react";
import { observer } from "mobx-react";
import { Tabs } from "antd";

import { Loader, Placeholder } from "rsuite";
import singleJobStore from "../../Stores/SingleJobStore";
import { Paper } from "@material-ui/core";

import JobHeader from "./IndividualTabs/JobHeader";
import ClientDetails from "./IndividualTabs/Client/ClientDetails";
import SubJobMain from "./IndividualTabs/SubJobs/SubJobMain";
import SummaryMain from "./IndividualTabs/Summary/SummaryMain";
import sjStore from "../../Stores/SideJobs/SJStore";
import InvoiceTab from "./IndividualTabs/Financials/Invoices/InvoiceTab";
import PaymentMain from "./IndividualTabs/Financials/Payments/PaymentMain";
import userStore from "../../Stores/UserStore";
import QualityMain from "./IndividualTabs/SubJobs/SJTabs/Others/Quality/QualityMain";

import serviceStore from "../../Stores/AdditionalStores/ServiceStore";
import FeedbackTab from "./IndividualTabs/Feedback/FeedbackTab";

const { TabPane } = Tabs;
const { Paragraph } = Placeholder;

class SingleJobMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "home", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }

  componentDidMount() {
    if (singleJobStore.job._id !== this.props.match.params.singlejobid) {
      singleJobStore.getJob(this.props.match.params.singlejobid);
      sjStore.getSJs(this.props.match.params.singlejobid);
      serviceStore.getServices();
    }
  }
  componentDidUpdate() {
    if (singleJobStore.job._id !== this.props.match.params.singlejobid) {
      singleJobStore.getJob(this.props.match.params.singlejobid);
      sjStore.getSJs(this.props.match.params.singlejobid);
    }
  }

  render() {
    if (singleJobStore.loading) {
      return (
        <Paper>
          <div style={{ minHeight: "80vh" }}>
            <Paragraph rows={16}>
              <Loader center content="loading" />
            </Paragraph>
          </div>
        </Paper>
      );
    }

    let quality = userStore.user.quality;
    let editview = userStore.user.jobs;

    let sjs = sjStore.sjs.filter((sj) => sj.status !== "Cancelled");

    return (
      <div>
        <JobHeader quality={quality} editview={editview} />
        <Paper>
          <div style={{ minHeight: "80vh" }}>
            <Tabs
              activeKey={this.state.tab}
              defaultActiveKey={0}
              tabPosition={"top"}
              type="card"
              onChange={(e) => {
                if (e === "3") {
                  singleJobStore.updateProposals(sjStore.sjs);
                }
                this.setState({ tab: e });
              }}
            >
              <TabPane
                tab={"Summary"}
                key={"home"}
                disabled={this.state.locked}
              >
                <SummaryMain sjs={sjStore.sjs} />
              </TabPane>
              <TabPane
                tab={"Client Details"}
                key={1}
                disabled={this.state.locked}
              >
                <ClientDetails quality={quality} editview={editview} />
              </TabPane>
              <TabPane tab={"Invoices"} key={3} disabled={this.state.locked}>
                <InvoiceTab quality={quality} editview={editview} />
              </TabPane>
              <TabPane tab={"Payments"} key={4} disabled={this.state.locked}>
                <PaymentMain quality={quality} editview={editview} />
              </TabPane>
              <TabPane tab={"Sub Jobs"} key={5} disabled={this.state.locked}>
                <SubJobMain editview={editview} />
              </TabPane>
              <TabPane tab={"Feedback"} key={6} disabled={this.state.locked}>
                <FeedbackTab editview={editview} />
              </TabPane>
              {quality && (
                <TabPane tab={"Quality"} key={10} disabled={this.state.locked}>
                  <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Quality Page for {sjs.length} Jobs
                  </h6>
                  {sjs.map((sj, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "1%",
                        margin: "1%",
                        border: "1px solid black",
                        borderRadius: 10,
                        boxShadow: "1px 1px 1px black",
                      }}
                    >
                      <QualityMain sj={sj} />
                    </div>
                  ))}
                </TabPane>
              )}
            </Tabs>
          </div>
        </Paper>
      </div>
    );
  }
}

export default observer(SingleJobMain);
