import { observer } from "mobx-react";
import React, { Component } from "react";
import { Empty } from "antd";

import MaterialTable from "material-table";
import { withRouter } from "react-router";
import moment from "moment";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";

class DeliveryDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      delmodal: false,
      deldata: {},
      delcharge: true,
      addchg: true,
      percbmchg: true,
      discount: true,
    };
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({
      delmodal: false,
      deldata: {},
    });
  }
  render() {
    let content = (
      <Empty
        description="No Deliveries during this period"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: "50px" }}
      />
    );

    let columns = [
      {
        title: "DelID",
        field: "deliveryNumId",
        render: (row) => {
          return (
            <>
              <IconButton
                color="green"
                appearance="ghost"
                size="xs"
                icon={<Icon icon="web" />}
                onClick={() => this.props.history.push(`/delorder/${row._id}`)}
              />
              <Divider vertical />
              <b>{row.deliveryNumId}</b>
            </>
          );
        },
      },
      {
        title: "Date",
        field: "jobdate",
        render: (row) => {
          return <b>{moment(row.jobdate).format("DD-MMM-YY")}</b>;
        },
      },
      { title: "From", field: "fromaddress.city" },
      { title: "Dropoffs", field: "toaddress.length" },
      {
        title: "Status",
        field: "status",
        render: (row) => {
          let color = "green";
          if (row.status === "Cancelled") color = "red";
          return <b style={{ color: color }}>{row.status}</b>;
        },
      },
    ];

    let data = this.props.data.data.deliverys;
    let missing = this.props.data.data.missing;

    let missingrows;
    if (missing) {
      if (missing.length) {
        if (missing.length > 0) {
          content = <div />;
          missingrows = (
            <>
              <br />
              <b>{missing.length} Empty Days: </b>
              <FlexboxGrid>
                {missing.map(
                  (mis, index) =>
                    `${moment(mis.date).format("DD-MMM-YY")} (${PRKD(
                      mis.price
                    )} KD)${index === missing.length ? "." : ", "}`
                )}
              </FlexboxGrid>
            </>
          );
        }
      }
    }

    if (data.length) {
      if (data.length > 0) {
        content = (
          <div>
            <MaterialTable
              columns={columns}
              style={{ fontSize: 12 }}
              data={data}
              options={{
                search: false,
                actionsColumnIndex: 0,
                exportAllData: false,
                paging: false,
                toolbarButtonAlignment: "left",
                maxBodyHeight: 500,
                tableLayout: "auto",
                exportButton: false,
                padding: "dense",
                toolbar: false,
              }}
            />
          </div>
        );
      }
    }

    return (
      <div>
        {content}

        {missingrows}
      </div>
    );
  }
}

export default withRouter(observer(DeliveryDetailView));
