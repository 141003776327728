import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import SinglePalletModal from "../SinglePalletModal/SinglePalletModal";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";
import { Divider, Icon, IconButton } from "rsuite";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import userStore from "../../../Stores/UserStore";
import whmTruckTripStore from "../WHMStores/WHMTruckTripStore";
import baseURL from "../../../Static/baseURL/baseURL";
import axios from "axios";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class TruckTripPalletTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showdelete: false,
      pallet: { _id: "", whmPalletNumId: "", cbm: 0, qty: 0, clientname: "" },
    };
    this.onHide = this.onHide.bind(this);
    this.onEdit = this.onEdit.bind(this);

    this.QTYColumn = this.QTYColumn.bind(this);
    this.ActionButtons = this.ActionButtons.bind(this);
    this.CBMColumn = this.CBMColumn.bind(this);
    this.deletePalletFromTrip = this.deletePalletFromTrip.bind(this);
  }
  deletePalletFromTrip = async (pallet) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/deletepalletfromtrip/${pallet._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        whmTruckTripStore.getTruckTrip(this.props.match.params.trucktrip, true);

        message.success(`Pallet Deleted from Truck Trip`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };
  ActionButtons(cellData) {
    return (
      <>
        <IconButton
          icon={<Icon icon="trash" />}
          color="red"
          circle
          size="xs"
          onClick={() =>
            this.setState({ showdelete: true, pallet: cellData.data })
          }
        />
        <Divider vertical />
        <IconButton
          icon={<Icon icon="eye" />}
          color="green"
          circle
          size="xs"
          onClick={() => {
            whmSinglePalletStore.getPallet(cellData.data._id);
            this.setState({ show: true, pallet: cellData.data });
          }}
        />
      </>
    );
  }
  QTYColumn(cellData) {
    let data = cellData.data.qty;
    let qty = 0;
    if (data > 0) return PRKD(qty);
    return "-";
  }
  CBMColumn(cellData) {
    let data = cellData.data.cbm;
    let cbm = 0;
    if (data > 0) return PRKD(cbm);
    return "-";
  }

  onHide() {
    this.setState({ show: false, showdelete: false });
  }
  onEdit(pallet) {
    this.props.history.push(`/whm/company/${pallet.client._id}`);
    this.onHide();
  }

  render() {
    let data = this.props.data;
    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() => this.deletePalletFromTrip(this.state.pallet)}
            />
          }
        >
          <h3>
            Are you sure you want to delete Pallet #
            {this.state.pallet.whmPalletNumId} from this Truck Trip?
          </h3>
          <hr />
          <p>
            <b>Client:</b> {this.state.pallet.clientname}
          </p>
          <p>
            <b>QTY:</b> {this.state.pallet.qty}
          </p>
          <p>
            <b>CBM:</b> {PRKD(this.state.pallet.cbm)}
          </p>
        </UnifiedModal>
        <SinglePalletModal
          show={this.state.show}
          onHide={this.onHide}
          actionbut={true}
          actionbuttext="Go To Company"
          onEdit={this.onEdit}
        />
        <DataGrid
          dataSource={data}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <Selection mode="single" />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />

          <Scrolling mode="standard" showScrollbar="always" />
          {!this.props.hideActions && (
            <ColumnChooser enabled={true} mode="select" />
          )}
          <ColumnFixing enabled={true} />

          {!this.props.hideActions && (
            <Column
              caption="Actions"
              alignment="center"
              width={120}
              cellRender={this.ActionButtons}
            />
          )}
          <Column
            dataField="whmPalletNumId"
            caption="ID"
            alignment="center"
            width={80}
          />
          <Column
            dataField="clientname"
            caption="Client"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            width={80}
          />
          <Column
            dataField="items"
            caption="Items"
            alignment="center"
            width={80}
          />
          <Column dataField="qty" caption="QTY" alignment="center" width={80} />
          <Column
            dataField="cbm"
            caption="CBM"
            alignment="center"
            width={80}
            format={{ type: "fixedPoint", precision: 3 }}
          />

          {!this.props.hideActions && <Export enabled={true} />}
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(TruckTripPalletTable));
