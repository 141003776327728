import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../Components/Loaders/LoadBack";
import whmOldWarehouseStore from "../WHMStores/WHMOldWarehouseStore";
import Div14 from "../../../Components/Dividers/Div14";
import TabPane from "antd/lib/tabs/TabPane";
import { Tabs } from "antd";
import WHMPalletListTable from "../FullPalletList/WHMPalletListTable";

import FlexboxButton from "../../../Components/Modal/FlexboxButton";
import WHMNewLoadingJob from "./WHMNewLoadingJob";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

class WHMOldWarehouseMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    whmOldWarehouseStore.getPallets();
    whmOldWarehouseStore.getTrucks();
    whmOldWarehouseStore.getTruckTrip();
  }
  render() {
    return (
      <LoadBack
        loading={
          whmOldWarehouseStore.loading.pallets ||
          whmOldWarehouseStore.loading.trucks
        }
      >
        <Div14>Old Warehouse</Div14>

        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={"top"}
          centered
          onChange={(e) => this.setState({ tab: e })}
        >
          <TabPane tab={`Truck Activity`} key="Truck Activity">
            <FlexboxGrid
              justify="end"
              style={{ marginRight: "5%", marginTop: "1%" }}
            >
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => {
                  whmOldWarehouseStore.getTrucks(true);
                  whmOldWarehouseStore.getTruckTrip(true);
                }}
                color="orange"
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <Div14>Active Loading Jobs</Div14>
            {!whmOldWarehouseStore.currentlyloading.value ? (
              <WHMNewLoadingJob />
            ) : (
              <FlexboxButton
                icon="truck"
                color="green"
                text={"Go To Loading Page"}
                oC={() =>
                  this.props.history.push(
                    `/whm/loading/${whmOldWarehouseStore.trucktrip._id}`
                  )
                }
              />
            )}
          </TabPane>
          <TabPane tab={`Prepared Pallets`} key="Prepared Pallets">
            <FlexboxGrid
              justify="end"
              style={{ marginRight: "5%", marginTop: "1%" }}
            >
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => whmOldWarehouseStore.getPallets(true)}
                color="orange"
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <WHMPalletListTable data={whmOldWarehouseStore.pallets} prepared />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMOldWarehouseMain));
