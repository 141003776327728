import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import SelectInputSTD from "../../../../../../Components/Inputs/STD/SelectInputSTD";
import NumbInputSTD from "../../../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import damagereportlist from "../../../../../../Static/Lists/damagereportlist";

class SingleItemRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let index = this.props.index;
    let item = this.props.items[index];

    return (
      <div
        style={{
          border: "1px solid black",
          borderRadius: "5px",
          margin: "1%",
        }}
      >
        <p style={{ textAlign: "center" }}>
          {item.acitem.acItemNumId}: {item.acitem.itemname} (SN:{" "}
          {item.acitem.itemserialnumber})
        </p>

        <FlexboxGrid>
          <Col2>
            <NumbInputSTD
              title="Qty"
              label="qty"
              index={index}
              min={0}
              size="small"
              max={item.acitem.actualquantity}
              val={item.qty}
              editVal={this.props.editItem}
            />
          </Col2>
          <Col2>
            <SelectInputSTD
              title="Damage Type"
              label="type"
              opts={damagereportlist}
              index={index}
              val={item.type}
              editVal={this.props.editItem}
            />
          </Col2>
        </FlexboxGrid>

        <TextAreaInputSTD
          title="Item Remarks"
          label="remarks"
          size="large"
          editVal={this.props.editItem}
          val={item.remarks}
          index={index}
        />
      </div>
    );
  }
}

export default withRouter(observer(SingleItemRow));
