import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { FlexboxGrid } from "rsuite";
import { Statistic } from "antd";

import moment from "moment";
import staffStorageStatStore from "../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import LoadBack from "../../../../../../../../Components/Loaders/LoadBack";
import Col4 from "../../../../../../../../Components/Columns/Col4";
import { PRKD } from "../../../../../../../../Functions/Pricing/PRKDCalc";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import FullItemListTable from "./FullItemListTable";

class UnmovedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    staffStorageStatStore.getStaticItems();
  }

  render() {
    let startdate = staffStorageStatStore.startdate;
    let enddate = staffStorageStatStore.enddate;
    let monthdiff = moment(enddate).diff(startdate, "month") + 1;
    let daysdiff = moment(enddate).diff(startdate, "days") + 1;
    return (
      <LoadBack loading={staffStorageStatStore.loading.staticitems}>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title={`Months (${daysdiff} days)`}
                precision={0}
                value={PRKD(monthdiff)}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Static Items"
                value={PRKD(
                  staffStorageStatStore.addmovestats.staticitems.length
                )}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Static CBM"
                value={PRKD(staffStorageStatStore.addmovestats.staticcbm)}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Stagnant Monthly Price (KD)"
                value={PRKD(staffStorageStatStore.addmovestats.monthlyprice)}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <Div14>Full Item Movement List</Div14>
        <FullItemListTable />
      </LoadBack>
    );
  }
}

export default withRouter(observer(UnmovedItems));
