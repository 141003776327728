import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import Div14 from "../../../Components/Dividers/Div14";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import completeDeliveryStore from "../../../Stores/Account/CompleteDeliveryStore";
import carStore from "../../../Stores/AdditionalStores/CarStore";
import StorageInOutModal from "./CloseDelSteps/StorageInOut/StorageInOutModal";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class STDBut extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;

    let icon = "truck";
    if (title === "Storage In" || title === "Storage Out") icon = "cube";
    return (
      <IconButton
        block
        onClick={() => this.props.OC(title)}
        icon={<Icon icon={icon} />}
        color="green"
        disabled={disabled}
      >
        {title}
      </IconButton>
    );
  }
}

class CloseDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fulltype: "",
      show: false,
      signature: {
        sig: null,
        type: "",
        name: "",
        email: "",
        remark: "",
        _id: "",
        trucktrack: "No Tracking",
        loading: false,
        changed: false,
        deliverdate: undefined,
      },

      nulled: null,
    };
    this.closeDel = this.closeDel.bind(this);
    this.onHide = this.onHide.bind(this);
    this.changeST = this.changeST.bind(this);
  }
  componentDidMount() {
    carStore.getCars();
  }
  closeDel(type) {
    let newtype;
    if (type === "Pickup" || type === "Delivery") newtype = "Delivery";
    if (type === "Storage In" || type === "Storage Out") newtype = type;
    this.setState({ show: true, fulltype: type });
    this.changeST(newtype, "type");
    if (type === "Pickup" || type === "Delivery") {
      this.changeST(moment(), "deliverdate");
    }
  }
  onHide() {
    this.setState({ show: false });
  }
  changeST(val, label) {
    let newdata;
    if ((val === null) & (label === "sig")) {
      let newsig = this.state.signature;
      newdata = {
        sig: this.state.nulled,
        type: newsig.type,
        name: newsig.name,
        email: newsig.email,
        remark: newsig.remark,
        trucktrack: newsig.trucktrack,
        _id: "",
        loading: false,
        changed: false,
      };
    } else {
      newdata = { ...this.state.signature };
      newdata[label] = val;
    }

    this.setState({ signature: newdata });
  }

  render() {
    let stordel = this.props.stordel;
    let inout = this.props.inout;
    let sto = this.props.sto;
    let cds = completeDeliveryStore;
    let sig = this.state.signature;
    let but1;
    let title = "";

    if ((stordel === "Storage") & (inout === "In")) {
      if (sto.status === "Booked" || sto.status === "Requested") {
        but1 = <STDBut title="Storage In" OC={this.closeDel} />;
        title = "Confirm Storage In:";
      }
    } else if ((stordel === "Storage") & (inout === "Out")) {
      if (sto.status === "Booked" || sto.status === "Requested") {
        title = "Confirm Storage Out:";
        but1 = <STDBut title="Storage Out" OC={this.closeDel} />;
      }
    } else if ((stordel === "Delivery") & (inout === "In")) {
      if (sto.status === "Booked" || sto.status === "Requested") {
        title = "Confirm Pickup:";
        but1 = <STDBut title="Pickup" OC={this.closeDel} />;
      } else if (sto.status === "In Transit") {
        title = "Confirm Storage In:";
        but1 = <STDBut title="Storage In" OC={this.closeDel} />;
      }
    } else if ((stordel === "Delivery") & (inout === "Out")) {
      if (sto.status === "Booked" || sto.status === "Requested") {
        title = "Confirm Storage Out:";
        but1 = <STDBut title="Storage Out" OC={this.closeDel} />;
      } else if (sto.status === "In Transit") {
        title = "Confirm Storage Delivery:";
        but1 = <STDBut title="Delivery" OC={this.closeDel} />;
      }
    }

    let emailNote = { color: "grey", message: "Optional" };
    let emailval = false;

    if (sig.email !== "") {
      emailval = validateEmail(sig.email);
      if (emailval) {
        emailNote = {
          color: "green",
          message: `The Delivery Note will be sent to ${sig.email}.`,
        };
      } else {
        emailNote = {
          color: "red",
          message: "Please Write a Valid Email Address.",
        };
      }
    }

    return (
      <div>
        <UnifiedModal show={this.state.show} onHide={this.onHide} footer={null}>
          <StorageInOutModal
            inout={inout}
            emailNote={emailNote}
            data={cds.neworder}
            changeST={this.changeST}
            sig={sig}
            onHide={this.onHide}
            fulltype={this.state.fulltype}
          />
        </UnifiedModal>
        <Div14>{title}</Div14>
        <FlexboxGrid justify="center">
          <Col2>{but1}</Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(CloseDelivery);
