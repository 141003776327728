import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Image } from "antd";

class HoverImage extends Component {
  constructor(props) {
    super(props);
    this.state = { mouseOver: false };
  }

  render() {
    let color = "black";
    if (this.props.color) color = this.props.color;
    let style = {
      transform: this.state.mouseOver ? "scale(0.9)" : "scale(1)",
      boxShadow: `0px 0px 10px ${color}`,
      borderRadius: "10px",
    };
    return (
      <div style={{ margin: "2%" }}>
        <Image
          src={this.props.image}
          style={style}
          preview={false}
          onMouseOver={() => this.setState({ mouseOver: true })}
          onMouseOut={() => this.setState({ mouseOver: false })}
        />
      </div>
    );
  }
}

export default withRouter(observer(HoverImage));
