import { observer } from "mobx-react";
import React, { Component } from "react";
import { Loader } from "rsuite";
import DelMap from "../../../Components/Maps/SelectLocationMap/DelMap";

class AllTruckMap extends Component {
  constructor(props) {
    super(props);
    this.state = { zoom: 19 };
    this.changeCenter = this.changeCenter.bind(this);
  }
  changeCenter(lat, lng) {}

  render() {
    let zoom = this.state.zoom;
    let devices = this.props.markers;

    let center = [devices[0].lat, devices[0].lng];
    let map = (
      <DelMap
        zoom={zoom}
        center={center}
        markers={devices}
        onChildClick={this.changeCenter}
      />
    );

    if (this.props.loading) {
      map = <Loader size="lg" center />;
    }

    return (
      <div
        style={{ border: "1px solid black", boxShadow: "1px 1px 5px black" }}
      >
        {map}
      </div>
    );
  }
}

export default observer(AllTruckMap);
