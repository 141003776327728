import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import equipmentStore from "../../../../Stores/EquipmentStores/EquipmentStore";

class NewEquipModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let eq = equipmentStore.equipment;
    return (
      <div>
        <SelectInputSTD
          title="Type"
          val={eq.type}
          label="type"
          opts={["Truck", "Pickup", "ForkLift", "Generator", "Other"]}
          size="large"
          editVal={equipmentStore.changeEquipment}
        />
        <br />
        <SelectInputSTD
          title="Status"
          val={eq.status}
          label="status"
          opts={["Active", "Expired"]}
          size="large"
          editVal={equipmentStore.changeEquipment}
        />
        <br />
        <TextInputSTD
          title="Plate"
          val={eq.license}
          label="license"
          editVal={equipmentStore.changeEquipment}
        />
        <br />
        <TextInputSTD
          title="Remarks"
          val={eq.remark}
          label="remark"
          editVal={equipmentStore.changeEquipment}
        />
        <br />
        <DatePickerSTD
          title="Active Since"
          val={eq.date}
          label="date"
          editVal={equipmentStore.changeEquipment}
        />
        <br />
        {eq.status === "Expired" && (
          <DatePickerSTD
            title="Expired On"
            val={eq.dateto}
            label="dateto"
            editVal={equipmentStore.changeEquipment}
          />
        )}
        <br />
      </div>
    );
  }
}

export default withRouter(observer(NewEquipModal));
