import { observer } from "mobx-react";
import React, { Component } from "react";

import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";

import MainAccountInfo from "../../StorageAccount/SubContractSummary/MainAccountInfo";
import StorageContractInfo from "../../StorageAccount/SubContractSummary/StorageContractInfo";
import DeliveryContractInfo from "../../StorageAccount/SubContractSummary/DeliveryContractInfo";

class AccountInfo extends Component {
  render() {
    let account = singleAccountStore.account;

    return (
      <div>
        <MainAccountInfo client={account} />
        {account.adel && (
          <DeliveryContractInfo delCont={account.deliveryContract} />
        )}
        {account.ast && (
          <StorageContractInfo storCont={account.storageContract} />
        )}
      </div>
    );
  }
}

export default observer(AccountInfo);
