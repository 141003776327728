import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@material-ui/core";

import SingleLink from "../SingleLink";
import { FlexboxGrid, Avatar } from "rsuite";

class Accounts extends Component {
  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={
            <Tooltip title="Staff Accounts" arrow placement="right">
              <Avatar circle size="sm" style={{ background: "#303f5e" }}>
                SA
              </Avatar>
            </Tooltip>
          }
        >
          {this.props.open && <Typography>Staff Accounts</Typography>}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <FlexboxGrid>
            <SingleLink
              page="/accounts/calendar"
              icon="calendar"
              title="Calendar"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/accounts/clients"
              icon="people-group"
              title="Accounts"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/sacstaff/dash"
              icon="cube"
              title="Warehouse"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/damagereports"
              icon="list"
              title="Damage Reports"
              hdc={this.props.hdc}
            />
          </FlexboxGrid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withRouter(observer(Accounts));
