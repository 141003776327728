import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class ACDeliveryStore {
  constructor() {
    this.clientId = "";
    this.cancelledorders = [];
    this.pastorders = [];
    this.calendarjobs = [];
    this.loading = {
      inventory: false,
      finance: false,
      order: false,
      storagehistory: false,
      itemmovement: false,
      item: false,
      items: false,
      calendar: false,
      delcharges: false,
      dailyactivities: false,
    };
    this.loaded = {
      pastorders: false,
      cancelledorders: false,
      inventory: false,
      finance: false,
      order: false,
      storagehistory: false,
      itemmovement: false,
      items: false,
      calendar: false,
      delcharges: false,
      dailyactivities: false,
    };
    this.client = {};
    this.orders = [];
    this.singledel = {};
    this.startdate = moment().subtract(180, "days");

    this.delcharges = [];
    this.dailyactivities = [];

    makeObservable(this, {
      clientId: observable,
      loading: observable,
      loaded: observable,
      startdate: observable,
      singledel: observable,
      orders: observable,
      cancelledorders: observable,
      pastorders: observable,
      calendarjobs: observable,
      delcharges: observable,
      dailyactivities: observable,
    });
  }

  // FETCH

  getDailyActivities(clientId, override) {
    let newclient = this.clientId;
    let loaded = false;
    if (this.loading.dailyactivities & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;
    if (clientId) newclient = clientId;

    if (!loaded) {
      this.loading.dailyactivities = true;
      this.loaded.dailyactivities = false;
      let url = `/ac/advdelivery/getdeldailycharges/${newclient}`;
      this.clientId = newclient;
      let data = { startdate: this.startdate };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.dailyactivities = data;
          this.loading.dailyactivities = false;
          this.loaded.dailyactivities = true;
          console.log("Daily Delivery Charges Fetched");
        })
        .catch((err) => {
          this.loading.dailyactivities = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Delivery Charges.`,
          });
        });
    }
  }
  getDeliveryCharges(clientId, override) {
    let newclient = this.clientId;
    let loaded = false;
    if (this.loading.delcharges & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;
    if (clientId) newclient = clientId;

    if (!loaded) {
      this.loading.delcharges = true;

      this.loaded.delcharges = false;
      let url = `/ac/advdelivery/getdelcharges/${newclient}`;
      this.clientId = newclient;
      let data = { startdate: this.startdate };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.delcharges = data;

          this.loading.delcharges = false;
          this.loaded.delcharges = true;
          console.log("Delivery Charges Fetched");
        })
        .catch((err) => {
          this.loading.delcharges = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Delivery Charges.`,
          });
        });
    }
  }
  getCalendarJobs(clientId, override) {
    let newclient = this.clientId;
    let loaded = false;
    if (this.loading.calendar & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;
    if (clientId) newclient = clientId;

    if (!loaded) {
      this.loading.calendar = true;

      this.loaded.calendar = false;
      let url = `/ac/delivery/calendar/byclient/${newclient}`;
      this.clientId = newclient;
      let data = { startdate: this.startdate };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.calendarjobs = data;

          this.loading.calendar = false;
          this.loaded.calendar = true;
          console.log("Delivery Calendar Fetched");
        })
        .catch((err) => {
          this.loading.calendar = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Delivery Calendar.`,
          });
        });
    }
  }
  getOrders(clientId, override) {
    let newclient = this.clientId;
    let loaded = false;
    if (this.loading.order & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;
    if (clientId) newclient = clientId;

    if (!loaded) {
      this.loading.order = true;
      this.loaded.delcharges = false;

      this.loaded.order = false;
      let url = `/ac/delivery/get/byclient/${newclient}`;
      this.clientId = newclient;
      let data = { startdate: this.startdate };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.orders = data.orders;

          this.client = data.client;
          this.loading.order = false;
          this.loaded.order = true;
          this.loaded.pastorders = false;
          this.loaded.cancelledorders = false;
          console.log("Orders Fetched");
        })
        .catch((err) => {
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Orders.`,
          });
        });
    }
  }
  getCustomOrderStatus(status, override) {
    let loaded = false;
    let clID = this.clientId;
    let urlcode;
    if (status === "Cancelled") {
      if (this.loaded.cancelledorders) loaded = true;
      urlcode = "cancelledbyclient";
    } else if (status === "Completed") {
      if (this.loaded.pastorders) loaded = true;
      urlcode = "completedbyclient";
    }
    if (override) loaded = false;
    if (!loaded) {
      this.loading.order = true;
      this.loaded.order = false;
      let data = { startdate: this.startdate };

      return instance
        .put(`/ac/delivery/get/${urlcode}/${clID}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          if (status === "Cancelled") {
            this.cancelledorders = orders;
            this.loaded.cancelledorders = true;
            console.log("Cancelled Deliveries Fetched for Client");
          } else if (status === "Completed") {
            this.pastorders = orders;
            this.loaded.pastorders = true;
            console.log("Past Deliveries Fetched for Client");
          }
          this.loading.order = false;
        })
        .catch((err) => {
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching ${status} Deliveries for Client.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  editDate(val) {
    acDeliveryStore.startdate = val;
  }

  // ----- DATE ----
}

const acDeliveryStore = new ACDeliveryStore();
export default acDeliveryStore;
