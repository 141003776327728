import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid } from "rsuite";

import { Statistic } from "antd";
import moment from "moment";
import whmTruckStore from "../WHMStores/WHMTruckStore";

class TruckTripStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let trips = this.props.trips;
    let loadjobs = 0;
    let unloadjobs = 0;
    let loadtime = 0;
    let unloadtime = 0;
    let loaddelay = 0;
    let unloaddelay = 0;

    for (let i = 0; i < trips.length; i++) {
      if (trips[i].status === "Loading Start") {
      } else {
        loadjobs += 1;
        loadtime += moment(trips[i].loadingend).diff(
          trips[i].createdAt,
          "minutes"
        );
        loaddelay +=
          moment(trips[i].loadingend).diff(trips[i].createdAt, "minutes") -
          whmTruckStore.targets.loading;

        if (trips[i].status === "Unloading End") {
          unloadjobs += 1;
          unloadtime += moment(trips[i].unloadingend).diff(
            trips[i].unloadingstart,
            "minutes"
          );

          unloaddelay +=
            moment(trips[i].unloadingend).diff(
              trips[i].unloadingstart,
              "minutes"
            ) - whmTruckStore.targets.unloading;
        }
      }
    }
    if (loadtime > 0) {
      if (loadjobs > 0) loadtime = loadtime / loadjobs;
    }
    if (unloadtime > 0) {
      if (unloadjobs > 0) unloadtime = unloadtime / unloadjobs;
    }

    let data = [
      { title: "Loading Jobs", val: loadjobs, red: false },
      { title: "UnLoading Jobs", val: unloadjobs, red: false },
      {
        suffix: "m",
        title: "Avg Loading Time",
        val: loadtime,
        red: loadtime > whmTruckStore.targets.unloading,
      },
      {
        suffix: "m",
        title: "Avg Unloading Time",
        val: unloadtime,
        red: unloadtime > whmTruckStore.targets.unloading,
      },
      {
        suffix: "m",
        title: "Loading Delay",
        val: loaddelay,
        red: loaddelay > 0,
      },
      {
        suffix: "m",
        title: "Unloading Delay",
        val: unloaddelay,
        red: unloaddelay > 0,
      },
    ];

    return (
      <FlexboxGrid>
        {data.map((stat, index) => (
          <Col key={index} md={4} sm={4} xs={8}>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "1%",
              }}
            >
              <Statistic
                title={stat.title}
                value={stat.val}
                precision={0}
                valueStyle={{ color: stat.red ? "red" : "#3f8600" }}
                suffix={stat.suffix}
              />
            </div>
          </Col>
        ))}
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(TruckTripStats));
