import { observer } from "mobx-react";
import React, { Component } from "react";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import TimelineComp from "../../../../Components/Timeline/TimelineComp";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";
import { Tabs } from "antd";
import ItemsList from "./Supporting/ItemsList";
import OrderSummaryTable from "./Supporting/OrderSummaryTable";
import CostSummaryTable from "./Supporting/CostSummaryTable";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import { withRouter } from "react-router";
import newStorageStore from "../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import LocationsSummaryList from "./Supporting/LocationsSummaryList";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import { Loader } from "rsuite";

const { TabPane } = Tabs;

class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Summary" };
  }

  render() {
    let sto = modalACOrderStore.sto;
    let account = sto.client;
    let allsteps = [];
    let step = 3;
    allsteps = ["Requested", "Booked"];
    if (sto.status === "Requested") step = 1;
    if (sto.status === "Booked") step = 2;

    if (sto.status === "In Transit") {
      allsteps = [...allsteps, "In Transit", "Delivered"];
    } else {
      allsteps = [...allsteps, "Delivered"];
    }

    let showlocs = false;

    if (sto.jobtype === "Delivery In" || "Delivery Out") {
      showlocs = true;
    }

    let content = <Loader />;

    if (modalACOrderStore.loading.sto) {
      content = <Loader />;
    } else {
      content = (
        <>
          <TimelineComp steps={allsteps} step={step} />
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={"home"}
            tabPosition={"top"}
            centered
            type="card"
            onChange={(e) => this.setState({ tab: e })}
          >
            <TabPane tab={`Summary`} key="Summary">
              <OrderSummaryTable order={sto} account={account} />
            </TabPane>
            {showlocs && (
              <TabPane tab={`Locations`} key="Locations">
                <LocationsSummaryList order={sto} account={account} />
              </TabPane>
            )}
            <TabPane tab={`Items List`} key="Items List">
              <ItemsList toadd={sto.toaddress} />
            </TabPane>
            <TabPane tab={`Costs`} key="Costs">
              <CostSummaryTable order={sto} />
            </TabPane>
          </Tabs>
        </>
      );
    }

    return (
      <LoadBack loading={modalACOrderStore.loading.sto}>
        <UnifiedModal
          show={this.props.show}
          onHide={this.props.onHide}
          title={`Storage Request #${sto.acOrderNumId} for ${sto.client.name}`}
          footer={
            <ModalFooter
              icon={["building", "save"]}
              color={["blue", "green"]}
              app={["ghost", "default"]}
              text={["Go to Company Page", "Go To Order"]}
              oC1={() =>
                this.props.history.push(`/ac/dashboard/${sto.client._id}`)
              }
              oC2={() => {
                newStorageStore.getSingleACOrder(sto._id, true);
                this.props.history.push(`/acorder/${sto._id}`);
              }}
              loading1={modalACOrderStore.loading.sto}
              loading2={modalACOrderStore.loading.sto}
            />
          }
        >
          {content}
        </UnifiedModal>
      </LoadBack>
    );
  }
}

export default withRouter(observer(OrderModal));
