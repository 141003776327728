import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";
import HeaderFooterPDF from "../../Template/HeaderFooterPDF";
import HeaderSection from "./HeaderSection";
import { Empty } from "antd";
import { InputGroup, Input, Icon, HelpBlock, Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { pdf } from "@react-pdf/renderer";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class DeliveryNotePDF extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", loading: false };
    this.editVal = this.editVal.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val, loading: false });
  }

  async sendEmail() {
    try {
      let sto = this.props.sto;
      let to = this.props.to;
      let client = this.props.client;
      let type = this.props.type;

      let emailtitle = "Pickup";
      if (type === "Out") emailtitle = "Delivery";

      this.setState({ loading: true });

      let docTitle = `Delivery Note ${sto.acOrderNumId} for Location ${
        to.order
      }-${moment(to.delSignature.date).format("YYMMDDHH")} for ${client.name}`;
      const blob = await pdf(
        <Document
          author={"Easy Move Logistics Co."}
          title={docTitle}
          creator={"Easy Move Logistics Co."}
        >
          <HeaderFooterPDF>
            <HeaderSection sto={sto} client={client} type={type} to={to} />
          </HeaderFooterPDF>
        </Document>
      ).toBlob();
      var file = new File([blob], `${docTitle}.pdf`, {
        lastModified: new Date().getTime(),
        type: "pdf",
      });

      const formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("title", docTitle);
      formData.append("numId", sto.acOrderNumId);
      formData.append("date", moment(to.delSignature.date).format("DD-MMM-YY"));
      formData.append("city", to.city);
      formData.append("totype", to.type);
      formData.append("maintype", sto.jobtype);
      formData.append("address", to.address);
      formData.append("accountname", client.name);
      formData.append("emailtitle", emailtitle);
      formData.append("Attachment", file, `${docTitle}.pdf`);

      return instance
        .put(`track/emailstorage/emailsinglefrom/${sto._id}`, formData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((res) => {
          Notification["success"]("Email Sent.");
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]("Not able to send");
        });
    } catch (e) {
      this.setState({ loading: false });
      Notification["error"]("Not able to send");
      console.error(e);
    }
  }
  render() {
    let sto = this.props.sto;
    let to = this.props.to;
    let client = this.props.client;
    let type = this.props.type;

    let showbutton = false;
    let showtext = false;
    let disabled = true;
    if (this.state.email.length > 3) {
      showbutton = true;
      disabled = !validateEmail(this.state.email);
      showtext = !validateEmail(this.state.email);
    }

    return (
      <div>
        {to.status === "Completed" ? (
          <>
            <InputGroup>
              <InputGroup.Addon>Send Email</InputGroup.Addon>
              <Input
                size={"lg"}
                value={this.state.email}
                disabled={this.state.loading}
                onChange={(e) => this.editVal(e, "email")}
              />
              {showbutton && (
                <>
                  <InputGroup.Button
                    color="green"
                    loading={this.state.loading}
                    onClick={() => this.sendEmail()}
                    disabled={disabled}
                  >
                    <Icon icon="send" />
                  </InputGroup.Button>
                </>
              )}
            </InputGroup>
            {showtext && (
              <HelpBlock style={{ fontStyle: "italic", color: "orange" }}>
                Please Enter a Valid Email.
              </HelpBlock>
            )}
            <hr />
            <PDFViewer
              width={"100%"}
              height={600}
              fileName={`Delivery Note ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
            >
              <Document
                author={"Easy Move Logistics Co."}
                title={`Delivery Note ${sto.acOrderNumId} for Location ${
                  to.order
                }-
                ${moment(to.delSignature.date).format("YYMMDDHH")} for ${
                  client.name
                }`}
                creator={"Easy Move Logistics Co."}
              >
                <HeaderFooterPDF>
                  <HeaderSection
                    sto={sto}
                    client={client}
                    type={type}
                    to={to}
                  />
                </HeaderFooterPDF>
              </Document>
            </PDFViewer>
          </>
        ) : (
          <Empty description="Delivery Pending" />
        )}
      </div>
    );
  }
}

export default observer(DeliveryNotePDF);
