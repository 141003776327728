let locationtypelist = [
  "Apartment",
  "Facility",
  "House",
  "Office",
  "Port",
  "Warehouse",
  "Other",
];
export default locationtypelist;
