import { InputNumber, message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, InputGroup } from "rsuite";
import Col3 from "../../../../Components/Columns/Col3";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { Input } from "rsuite";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import Col2 from "../../../../Components/Columns/Col2";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import completeDeliveryStore from "../../../../Stores/Account/CompleteDeliveryStore";

const instance = axios.create({ baseURL: baseURL });

class NewItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      item: {
        name: "",
        productid: "",
        serialnumber: "",
        length: 0,
        width: 0,
        height: 0,
        quantity: 1,
        remarks: "",
      },
    };
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
  }
  showHideModal(val) {
    this.setState({ show: val });
  }
  editVal(label, val) {
    let item = { ...this.state.item };
    item[label] = val;
    this.setState({ item: item });
  }
  postNew = async (item) => {
    let accountid = this.props.accountid;
    let orderid = this.props.orderid;
    let warehouseid = this.props.warehouseid;
    let data = {
      account: accountid,
      name: item.name,
      serialnumber: item.serialnumber,
      productid: item.productid,
      length: item.length,
      width: item.width,
      height: item.height,
      qty: item.quantity,
      orderid: orderid,
      warehouse: warehouseid,
    };

    let url = "/ac/advitemmove/newitem";
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        completeDeliveryStore.addItemMove(res.data);
        message.success("Created New Item");
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Count Not Create New Item");
      });
  };

  postEdit() {}
  render() {
    let type = "New";
    let disabled = false;
    let edited = this.state.item;

    if (edited.item === "" || edited.productid === "") disabled = true;
    if (edited.quantity <= 0) disabled = true;

    let footer = (
      <div>
        <FlexboxGrid>
          <Col3>
            <IconButton
              icon={<Icon icon="close" />}
              color="red"
              block
              onClick={() => this.showHideModal(false)}
              disabled={this.state.loading}
            >
              Cancel
            </IconButton>
          </Col3>
          <Col3>
            <IconButton
              icon={<Icon icon="plus" />}
              color="green"
              block
              disabled={disabled}
              loading={this.state.loading}
              onClick={() => {
                if (type === "New") {
                  this.postNew(edited);
                } else if (type === "Edit") {
                  this.postEdit(edited);
                }
              }}
            >
              {type === "Edit" ? "Save Changes" : "Create Item"}
            </IconButton>
          </Col3>
        </FlexboxGrid>
      </div>
    );

    return (
      <div>
        <IconButton
          block
          icon={<Icon icon="plus" />}
          onClick={() => this.setState({ show: true })}
          color="blue"
        >
          New Item
        </IconButton>
        <UnifiedModal
          show={this.state.show}
          onHide={this.showHideModal}
          footer={footer}
        >
          <h5>{type} Item Details</h5>

          <InputGroup>
            <InputGroup.Addon
              style={{
                backgroundColor: edited.name === "" && "#ffa6a6",
              }}
            >
              Item
            </InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={edited.name}
              onChange={(e) => this.editVal("name", e)}
            />
          </InputGroup>
          <br />
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                <InputGroup.Addon
                  style={{
                    backgroundColor: edited.productid === "" && "#ffa6a6",
                  }}
                >
                  Product ID
                </InputGroup.Addon>
                <Input
                  size="lg"
                  disabled={this.state.loading}
                  value={edited.productid}
                  onChange={(e) => this.editVal("productid", e)}
                />
              </InputGroup>
            </Col2>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Serial Number</InputGroup.Addon>
                <Input
                  size="lg"
                  disabled={this.state.loading}
                  value={edited.serialnumber}
                  onChange={(e) => this.editVal("serialnumber", e)}
                />
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>L (cm)</InputGroup.Addon>
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                  disabled={this.state.loading}
                  value={edited.length}
                  onChange={(e) => this.editVal("length", e)}
                />
              </InputGroup>
            </Col2>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>W (cm)</InputGroup.Addon>
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                  disabled={this.state.loading}
                  value={edited.width}
                  onChange={(e) => this.editVal("width", e)}
                />
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>H (cm)</InputGroup.Addon>
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                  disabled={this.state.loading}
                  value={edited.height}
                  onChange={(e) => this.editVal("height", e)}
                />
              </InputGroup>
            </Col2>
            <Col2>
              <InputGroup>
                <InputGroup.Addon
                  style={{
                    backgroundColor: edited.quantity <= 0 && "#ffa6a6",
                  }}
                >
                  Quantity
                </InputGroup.Addon>
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  min={1}
                  disabled={this.state.loading}
                  value={edited.quantity}
                  onChange={(e) => this.editVal("quantity", e)}
                />
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <br />
          <h6>
            Calculated CBM ={" "}
            {PRKD(
              (edited.length / 100) *
                (edited.width / 100) *
                (edited.height / 100)
            )}
          </h6>
          <FlexboxGrid></FlexboxGrid>
          <br />
          <InputGroup>
            <InputGroup.Addon>Remarks</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={edited.remarks}
              onChange={(e) => this.editVal("remarks", e)}
            />
          </InputGroup>
        </UnifiedModal>
      </div>
    );
  }
}

export default observer(NewItemModal);
