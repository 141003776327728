import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import Col2 from "../Columns/Col2";

class ModalFooter extends Component {
  render() {
    let icon = this.props.icon;
    let text = this.props.text;
    let color = this.props.color;
    let app = this.props.app;
    let size = "md";
    let disabled1 = this.props.disabled1;
    let loading1 = this.props.loading1;
    let disabled2 = this.props.disabled2;
    let loading2 = this.props.loading2;
    if (this.props.size) size = this.props.size;

    return (
      <FlexboxGrid>
        <Col2>
          <IconButton
            icon={<Icon icon={icon[0]} />}
            color={color[0]}
            appearance={app[0]}
            block
            size={size}
            onClick={this.props.oC1}
            loading={loading1}
            disabled={disabled1}
          >
            {text[0]}
          </IconButton>
        </Col2>
        <Col2>
          <IconButton
            icon={<Icon icon={icon[1]} />}
            color={color[1]}
            appearance={app[1]}
            block
            size={size}
            onClick={this.props.oC2}
            loading={loading2}
            disabled={disabled2}
            placement="right"
          >
            {text[1]}
          </IconButton>
        </Col2>
      </FlexboxGrid>
    );
  }
}

export default observer(ModalFooter);
