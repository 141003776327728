import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import singleAccountCalendarStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountCalendarStore";
import { Divider, Icon } from "rsuite";
import jobcolors from "../../../../Static/jobcolors/jobcolors";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import Div14 from "../../../../Components/Dividers/Div14";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { InputGroup } from "rsuite";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import ACCOrderModal from "../../ACCAccount/Modal/OrderModal";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";
import STOOrderModal from "../../StorageAccount/Modals/OrderModal";
import AIMOrderModal from "../../AIMAccount/Modals/OrderModal";
import DELOrderModal from "../../DeliveryAccount/Modals/OrderModal";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class CombinedCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showacc: false,
      showaim: false,
      showdel: false,
      showast: false,
    };
    this.selectJob = this.selectJob.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({
      showacc: false,
      showaim: false,
      showdel: false,
      showast: false,
    });
  }
  selectJob(job) {
    if (job.actype === "ACC") {
      modalACOrderStore.getACCFromJob(job._id);
      this.setState({ showacc: true });
    } else if (job.actype === "AST") {
      modalACOrderStore.getASTfromOrderId(job._id);
      this.setState({ showast: true });
    } else if (job.actype === "AIM") {
      modalACOrderStore.getAIMfromTrack(job.aimOrder._id);
      this.setState({ showaim: true });
    } else if (job.actype === "DEL") {
      modalACOrderStore.getDELfromOrderId(job._id);
      this.setState({ showdel: true });
    }
  }

  componentDidMount() {
    //singleAccountStore.getAccount(false, true);
    singleAccountCalendarStore.getCalendarJobs();
  }
  eventStyleGetter = (event, start, end, isSelected) => {
    let evt = event;

    let final = jobcolors["completed"];

    if (evt.status === "Completed") final = jobcolors["completed"];
    if (evt.status === "Complete") final = jobcolors["completed"];
    if (evt.status === "In Transit") final = jobcolors["intransit"];
    if (evt.status === "Pending") final = jobcolors["intransit"];
    if (evt.status === "Requested") final = jobcolors["requested"];
    if (evt.status === "Booked") final = jobcolors["booked"];

    if (evt.status === "Cancelled") {
      final = jobcolors["cancelled"];
    }

    var style = {
      backgroundColor: final.back,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 10,
      height: 15,
      color: final.font,
    };
    return {
      style: style,
    };
  };

  render() {
    let data = singleAccountCalendarStore.calendar;

    return (
      <div>
        <ACCOrderModal show={this.state.showacc} onHide={this.onHide} />
        <STOOrderModal show={this.state.showast} onHide={this.onHide} />
        <AIMOrderModal show={this.state.showaim} onHide={this.onHide} />
        <DELOrderModal show={this.state.showdel} onHide={this.onHide} />
        <LoadBack loading={singleAccountCalendarStore.loading.calendar}>
          <Div14>Account Calendar</Div14>
          <div style={{ marginTop: "5px" }}>
            <InputGroup>
              <DatePickerSTD
                title={"From Date"}
                format={"DD-MMM-YY"}
                picker="date"
                label={"startdate"}
                val={singleAccountCalendarStore.startdate}
                size="large"
                editVal={singleAccountCalendarStore.editDate}
              />
              <InputGroup.Button
                color="green"
                loading={singleAccountCalendarStore.loading.calendar}
                onClick={() => {
                  singleAccountCalendarStore.getCalendarJobs();
                }}
              >
                Reload <Divider vertical />
                <Icon icon="refresh" />
              </InputGroup.Button>
            </InputGroup>
          </div>
          <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
            <Calendar
              localizer={localizer}
              events={data}
              eventPropGetter={this.eventStyleGetter}
              startAccessor={"calstart"}
              endAccessor={"calstart"}
              style={{ height: 500 }}
              defaultDate={new Date()}
              views={["month"]}
              titleAccessor={"calendartitle"}
              popup
              onSelectEvent={(e) => {
                this.selectJob(e);
              }}
              onNavigate={() => console.log(" ")}
            />
          </div>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(CombinedCalendar));
