import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import InvoiceTable from "./InvoiceTable";

import accInvoiceStore from "../../../../../../Stores/Financial/ACCInvoiceStore";
import NewACCInvoiceModal from "./NewACCInvoiceModal";
import EditACCInvoiceModal from "./EditACCInvoiceModal";
import ACCChargeHeader from "../Charges/ACCChargeHeader";

class MainInvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = { shownew: false, showedit: false };
    this.onHide = this.onHide.bind(this);
    this.showNew = this.showNew.bind(this);
    this.showEdit = this.showEdit.bind(this);
  }
  onHide() {
    this.setState({ shownew: false, showedit: false });
  }
  showNew() {
    this.setState({ shownew: true });
    accInvoiceStore.createNewInvoice(true);
  }
  showEdit() {
    this.setState({ showedit: true });
  }
  componentDidMount() {
    accInvoiceStore.getInvPayments(true);
  }

  render() {
    return (
      <div>
        <NewACCInvoiceModal onHide={this.onHide} show={this.state.shownew} />
        <EditACCInvoiceModal onHide={this.onHide} show={this.state.showedit} />

        <ACCChargeHeader hidedetails />
        <InvoiceTable onNewClick={this.showNew} onEditClick={this.showEdit} />
      </div>
    );
  }
}

export default withRouter(observer(MainInvoicePage));
