import { Empty } from "antd";
import React, { Component } from "react";

class EmptyForm extends Component {
  render() {
    return (
      <div>
        <Empty description={<span>{this.props.text}</span>}>
          {this.props.children}
        </Empty>
      </div>
    );
  }
}

export default EmptyForm;
