import { observer } from "mobx-react";
import React, { Component } from "react";
import { Statistic } from "antd";
import { FlexboxGrid, HelpBlock } from "rsuite";
import moment from "moment";
import acInternationalStore from "../../../../../../Stores/AccountUpdated/AIM/ACInternationalStore";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import Col4 from "../../../../../../Components/Columns/Col4";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import FlexboxButton from "../../../../../../Components/Modal/FlexboxButton";
import aimInvoiceStore from "../../../../../../Stores/Financial/AIMInvoiceStore";

class AIMChargeHeader extends Component {
  render() {
    let data = acInternationalStore.client;
    let daysactive = moment().diff(data.startdate, "days");
    if (data.status !== "Active") {
      daysactive = moment(data.enddate).diff(data.startdate, "days");
    }

    let totalchg = 0;
    let totalinv = 0;
    let totalpaid = 0;

    if (aimInvoiceStore.loaded.invpayments) {
      let aimchgs = aimInvoiceStore.recs;

      for (let i = 0; i < aimchgs.length; i++) {
        totalchg += Parsed(aimchgs[i].price);
      }
      totalinv = aimInvoiceStore.totalinvandpays.totalinv;
      totalpaid = aimInvoiceStore.totalinvandpays.totalpaid;
    }
    let totalremaining = totalchg - totalpaid;

    return (
      <LoadBack loading={aimInvoiceStore.loading.invpayments}>
        {!this.props.hidedetails && (
          <>
            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Contract Status"
                  value={data.status}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Start Date"
                  value={moment(data.startdate).format("DD-MMM-YYYY")}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="End Date"
                  value={moment(data.enddate).format("DD-MMM-YYYY")}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Days Active"
                  value={daysactive}
                  suffix="days"
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
            </FlexboxGrid>
          </>
        )}
        {aimInvoiceStore.loaded.invpayments ? (
          <>
            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Total Charge"
                  value={PRKD(totalchg)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  As of {moment().format("DD-MMM-YY")}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Total Invoiced"
                  value={PRKD(totalinv)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalinv) === PRKD(totalchg) ? "green" : "red",
                  }}
                >
                  {PRKD(totalinv) === PRKD(totalchg)
                    ? "Charges Matched"
                    : PRKD(totalinv) > PRKD(totalchg)
                    ? "Over Invoiced"
                    : "Under Invoiced"}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Total Paid"
                  value={PRKD(totalpaid)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalpaid) === PRKD(totalinv) ? "green" : "red",
                  }}
                >
                  {PRKD(totalpaid) === PRKD(totalinv)
                    ? "Invoices Matched"
                    : PRKD(totalpaid) > PRKD(totalinv)
                    ? "Over Paid"
                    : "Under Paid"}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title={
                    totalremaining >= 0
                      ? "Remaining Charges"
                      : "Available Credit"
                  }
                  value={
                    PRKD(totalremaining) === 0
                      ? "-"
                      : Math.abs(PRKD(totalremaining))
                  }
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalremaining) === 0 ? "green" : "red",
                  }}
                >
                  {PRKD(totalremaining) === 0
                    ? "All Charges Met"
                    : PRKD(totalremaining) < 0
                    ? "Over Paid"
                    : "Overdue Balance"}
                </HelpBlock>
                <hr />
              </Col4>
            </FlexboxGrid>
          </>
        ) : (
          <FlexboxButton
            icon="reload"
            text="Load International Account Charges"
            color="green"
            app="primary"
            loading={aimInvoiceStore.loading.invpayments}
            oC={() => {
              aimInvoiceStore.getInvPayments();
            }}
          />
        )}
      </LoadBack>
    );
  }
}

export default observer(AIMChargeHeader);
