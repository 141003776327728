import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon, Notification, Loader } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import accountStore from "../../../../../Stores/Account/AccountStore";

import BasicDesc from "../../../../../Components/Descriptions/BasicDesc";
import Col3 from "../../../../../Components/Columns/Col3";
import userStore from "../../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

import { Tabs } from "antd";
import CostSummary from "./SupportingModal/CostSummary";
import ItemSummary from "./SupportingModal/ItemSummary";
import StorDelTimeline from "../../../../../Components/Timeline/StorDelTimeline";
import modalACOrderStore from "../../../../../Stores/ModalStores/ModalACOrderStore";

const { TabPane } = Tabs;

const instance = axios.create({ baseURL: baseURL });

class StorageModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, tab: "Summary", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
    this.approvereject = this.approvereject.bind(this);
  }
  approvereject(newstatus) {
    let sto = modalACOrderStore.sto;
    let data = { status: newstatus };
    if (newstatus === "Booked") {
      data.bookeddate = moment();
    }
    if (newstatus === "Cancelled") {
      data.canceldate = moment();
    }
    let url = `/ac/warehouserequest/edit/${sto._id}`;

    return instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        Notification["success"]({
          title: `Updated  #${sto.acOrderNumId} status to ${newstatus}.`,
        });
        this.props.onHide();
        accountStore.getACOrders(true);
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }

  render() {
    let content = <Loader center />;

    let sto = modalACOrderStore.sto;

    if (!modalACOrderStore.loading.sto) {
      let actions = (
        <div>
          <Div14>Staff Actions</Div14>
          <FlexboxGrid>
            <Col3>
              <IconButton
                loading={this.state.loading}
                size="sm"
                block
                icon={<Icon icon="check" />}
                color="green"
                onClick={() => this.approvereject("Booked")}
              >
                Approve Request
              </IconButton>
            </Col3>
            <Col3></Col3>
            <Col3>
              <IconButton
                loading={this.state.loading}
                size="sm"
                block
                icon={<Icon icon="trash" />}
                color="red"
                onClick={() => this.approvereject("Cancelled")}
              >
                Cancel Request
              </IconButton>
            </Col3>
          </FlexboxGrid>
          <hr />
        </div>
      );
      if ((sto.status !== "Requested") & !this.props.edit) {
        actions = <div />;
      }
      if (userStore.user.group === "Client") {
        actions = <div />;
      }

      let orderdata;

      orderdata = [
        { label: "Job Type", val: sto.jobtype },
        {
          label: "Date / Time",
          val: moment(sto.jobdate).format("DD-MMM-YY HH:mm A"),
        },
        { label: "Status", val: sto.status },
        { label: "Warehouse", val: sto.emlocation ? sto.emlocation.name : "" },
        { label: "Item Count", val: PRKD(sto.totalqty) },
        { label: "Item CBM", val: PRKD(sto.totalcbm) },
      ];
      if (sto.jobtype === "Delivery In") {
        orderdata.splice(3, 0, {
          label: "From",
          val: `${sto.location.city}: ${sto.location.address}`,
          span: 24,
        });
      } else if (sto.jobtype === "Delivery Out") {
        orderdata.splice(4, 0, {
          label: "To",
          span: 60,
          val: `${sto.location.city}: ${sto.location.address}`,
        });
      }
      let warehouse = (
        <div>
          <BasicDesc
            items={[{ label: "Warehouse", val: sto.emlocation.name, span: 24 }]}
          />
          <br />
        </div>
      );

      let deltitle;
      if (sto.jobtype === "Delivery In") deltitle = "From";
      if (sto.jobtype === "Delivery Out") deltitle = "To";
      let locationaddress = (
        <div>
          <BasicDesc
            items={[
              {
                label: `${deltitle} Address`,
                val: sto.location.address,
                span: 24,
              },
              { label: `Name`, val: sto.location.name },
              { label: `Number`, val: sto.location.number },
              { label: `Type`, val: sto.location.type },
              { label: `Floor`, val: sto.location.floor },
              { label: `City`, val: sto.location.city },
            ]}
          />
          <br />
        </div>
      );

      let showComplete = false;
      if (userStore.user.jobs) {
        if (sto.status !== "Cancelled") {
          if (sto.status !== "Completed") {
            showComplete = true;
          }
        }
      }

      content = (
        <div>
          <StorDelTimeline del={sto} />
          {!userStore.user.clientview && actions}
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane
              tab={"Summary"}
              key={"Summary"}
              disabled={this.state.locked}
            >
              <Div14>Storage Request Summary</Div14>
              <BasicDesc items={orderdata} />
            </TabPane>
            <TabPane
              tab={
                sto.jobtype === "Delivery In" || sto.jobtype === "Delivery Out"
                  ? "From / To"
                  : "Warehouse"
              }
              key={"Address"}
              disabled={this.state.locked}
            >
              {sto.jobtype === "Delivery In" && locationaddress}
              {warehouse}
              {sto.jobtype === "Delivery Out" && locationaddress}
            </TabPane>
            <TabPane
              tab={"Charges"}
              key={"Charges"}
              disabled={this.state.locked}
            >
              <CostSummary sto={sto} />
            </TabPane>
            <TabPane
              tab={"Items List"}
              key={"Items"}
              disabled={this.state.locked}
            >
              <ItemSummary sto={sto} />
            </TabPane>
          </Tabs>

          <hr />
          <FlexboxGrid>
            <Col2>
              <IconButton
                color="blue"
                block
                onClick={() => this.props.history.push(`/acorder/${sto._id}`)}
                icon={<Icon icon="map" />}
              >
                Track Request
              </IconButton>
            </Col2>
            {sto.status === "Requested" ? (
              <Col2> </Col2>
            ) : (
              <Col2>
                {showComplete ? (
                  <IconButton
                    color="green"
                    block
                    onClick={() =>
                      this.props.history.push(`/acconfirmdel0/${sto._id}`)
                    }
                    icon={<Icon icon="map" />}
                  >
                    Complete Delivery
                  </IconButton>
                ) : (
                  <div />
                )}
              </Col2>
            )}
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.onHide}
        footer={false}
        title={`${sto.status} - AC Order #${sto.acOrderNumId} for ${moment(
          sto.jobdate
        ).format("DD-MMM-YY")}`}
      >
        {content}
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(StorageModal));
