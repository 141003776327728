import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, { Column, Grouping } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Summary, TotalItem } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { Divider, Icon, IconButton } from "rsuite";
import moment from "moment";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import accInvoiceStore from "../../../../../../Stores/Financial/ACCInvoiceStore";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import Div14 from "../../../../../../Components/Dividers/Div14";
import ACCChargeHeader from "./ACCChargeHeader";

class ACCCharges extends Component {
  constructor(props) {
    super(props);
    this.state = { autoExpandAll: true };
    this.onExporting = this.onExporting.bind(this);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ACC_Charges_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  DiffCell(cellData, label) {
    let data = cellData.data[label];
    if (data < 0) return <b style={{ color: "green" }}>{PRKD(data)} KD</b>;
    if (data > 0) return <b style={{ color: "red" }}>{PRKD(data)} KD</b>;
    return <>-</>;
  }
  InvoicedCheck(cellData) {
    let data = cellData.data.invoiced;
    if (data)
      return <Icon size="xs" icon="check-circle" style={{ color: "green" }} />;
    return <Icon size="xs" icon="circle" style={{ color: "red" }} />;
  }
  componentDidMount() {
    accInvoiceStore.getACCCharges();
  }

  render() {
    return (
      <LoadBack loading={accInvoiceStore.loading.charges}>
        <ACCChargeHeader />
        <div
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            textAlign: "center",
            alignContent: "center",
          }}
        >
          <Div14>
            International Move Charges <Divider vertical />
            <IconButton
              icon={<Icon icon="refresh" />}
              size="xs"
              onClick={() => {
                accInvoiceStore.getACCCharges(true);
              }}
              circle
            />
          </Div14>

          <DataGrid
            dataSource={accInvoiceStore.recs}
            keyExpr="ind"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />

            <Column
              dataField="ind"
              caption="#"
              alignment="center"
              minWidth={80}
            />
            <Column caption="Job Details" alignment="center">
              <Column
                dataField="accOrderNumId"
                dataType="number"
                caption="ACC Order"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={80}
              />
              <Column
                dataField="status"
                caption="Status"
                alignment="center"
                minWidth={80}
              />
            </Column>
            <Column caption="Charge Details" alignment="center">
              <Column
                dataField="type"
                caption="Type"
                alignment="center"
                minWidth={160}
              />
              <Column
                dataField="title"
                caption="Title"
                alignment="center"
                minWidth={160}
              />
            </Column>
            <Column
              dataField="price"
              dataType="number"
              caption="Charge (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={160}
              cellRender={(e) => this.DiffCell(e, "price")}
            />
            <Column
              dataField="price"
              dataType="number"
              caption="Invoiced"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={160}
              cellRender={(e) => this.InvoicedCheck(e, "price")}
            />
            <Summary>
              <TotalItem
                column="accOrderNumId"
                summaryType="count"
                displayFormat={`Charges: `}
              />
              <TotalItem
                column="status"
                summaryType="count"
                displayFormat={`${accInvoiceStore.recs.length}`}
              />
              <TotalItem
                column="price"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Export enabled={true} />
          </DataGrid>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(ACCCharges));
