import { message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Checkbox, CheckboxGroup, HelpBlock, IconButton } from "rsuite";
import { Col, Icon, FlexboxGrid, Notification } from "rsuite";

import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import serviceStore from "../../../../../Stores/AdditionalStores/ServiceStore";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import userStore from "../../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class ServicesTab extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  componentDidMount() {
    serviceStore.getServices();
  }

  updateVal(type, action, ser) {
    newAimStore.selectService(type, action, ser);
  }
  saveChanges = async () => {
    let newaim = newAimStore.newaim;
    let oldaim = newAimStore.oldaim;

    this.setState({ loading: true });
    let data = {};

    data.incservices = [...newaim.incservices];
    data.excservices = [...newaim.excservices];

    await instance
      .put(`ac/aimorder/edit/${oldaim._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.replaceServices();
        this.setState({ loading: false });
        message.success(`Services Updated.`);
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An error occured`,
        });
      });
  };

  render() {
    let editview = !newAimStore.stopedit;
    let newaim = newAimStore.newaim;
    let oldaim = newAimStore.oldaim;

    let services = serviceStore.imservice;

    let incSer = newaim.incservices;
    let excSer = newaim.excservices;

    let incservice = [];
    let excservice = [];

    for (let i = 0; i < services.length; i++) {
      if (incSer.some((e) => e === services[i].title)) {
        incservice.push(services[i].title);
      }
      if (excSer.some((e) => e === services[i].title)) {
        excservice.push(services[i].title);
      }
    }

    let incBoxes = services.map((ser, ind) => (
      <Checkbox
        disabled={this.state.loading}
        key={ind}
        checked={incservice.includes(ser.title)}
        onClick={() => {
          if (editview) {
            if (incservice.includes(ser.title)) {
              this.updateVal("Include", "remove", ser.title);
            } else {
              this.updateVal("Include", "add", ser.title);
            }
          }
        }}
      >
        {ser.title}
      </Checkbox>
    ));

    let excBoxes = services.map((ser, ind) => (
      <Checkbox
        disabled={this.state.loading}
        key={ind}
        checked={excservice.includes(ser.title)}
        onClick={() => {
          if (editview) {
            if (excservice.includes(ser.title)) {
              this.updateVal("Exclude", "remove", ser.title);
            } else {
              this.updateVal("Exclude", "add", ser.title);
            }
          }
        }}
      >
        {ser.title}
      </Checkbox>
    ));

    let originalInc = [];
    let originalExc = [];
    let changedInc = [];
    let changedExc = [];

    for (let i = 0; i < incSer.length; i++) {
      changedInc.push(incSer[i]);
    }
    for (let i = 0; i < excSer.length; i++) {
      changedExc.push(excSer[i]);
    }
    for (let i = 0; i < oldaim.incservices.length; i++) {
      originalInc.push(oldaim.incservices[i]);
    }
    for (let i = 0; i < oldaim.excservices.length; i++) {
      originalExc.push(oldaim.excservices[i]);
    }
    changedInc = changedInc.sort().join(", ");
    changedExc = changedExc.sort().join(", ");
    originalInc = originalInc.sort().join(", ");
    originalExc = originalExc.sort().join(", ");

    let finalchanged;

    if (editview) {
      if (changedInc !== originalInc || originalExc !== changedExc) {
        finalchanged = (
          <FlexboxGrid>
            <Col md={8} sm={12} xs={24}>
              <IconButton
                block
                icon={<Icon icon="refresh" />}
                onClick={() => newAimStore.resetServices()}
              >
                Reset Changes
              </IconButton>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <IconButton
                block
                color="green"
                icon={<Icon icon="check" />}
                onClick={() => this.saveChanges()}
              >
                Save Changes
              </IconButton>
            </Col>
          </FlexboxGrid>
        );
      }
    }
    return (
      <div>
        <CheckboxGroup inline>
          <h6>Included: ({incservice.length}) </h6>
          {incBoxes}
        </CheckboxGroup>
        <CheckboxGroup inline>
          <h6>Excluded: ({excservice.length}) </h6>
          {excBoxes}
        </CheckboxGroup>
        {changedInc !== originalInc && (
          <HelpBlock>Included: {originalInc}</HelpBlock>
        )}
        {changedExc !== originalExc && (
          <HelpBlock>Excluded: {originalExc}</HelpBlock>
        )}
        {finalchanged}
      </div>
    );
  }
}

export default observer(ServicesTab);
