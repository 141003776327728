import { observer } from "mobx-react";
import React, { Component } from "react";

import singleJobStore from "../../../../../../Stores/SingleJobStore";
import { Notification, FlexboxGrid, IconButton, Icon, Loader } from "rsuite";
import moment from "moment";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";
import InvoiceDetails from "./InvoiceDetails";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../../../Components/Columns/Col2";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import IntroPage from "../../../../../../Components/PDFs/Invoices/IntroPage";
import DataGrid, { Column, Grouping } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Summary, TotalItem } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { MasterDetail, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";

const instance = axios.create({ baseURL: baseURL });

class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showInv: false,
      inv: {
        invoiceNumId: -1,
        title: "",
        status: "",
        subJobs: [],
        load: false,
        _id: "",
      },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.deleteInvoice = this.deleteInvoice.bind(this);
    this.onExporting = this.onExporting.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
    this.SubJobsColumn = this.SubJobsColumn.bind(this);
  }
  SubJobsColumn(cellData) {
    return cellData.data.subJobs
      .map((sj) => `${sj.jobtype}-${sj.numId}`)
      .join(", ");
  }
  ActionButs(cellData) {
    return (
      <>
        <IconButton
          icon={<Icon icon="save" />}
          color="green"
          circle
          size="xs"
          onClick={() => {
            this.setState({ inv: cellData.data, showInv: true });
          }}
        />
        <span> </span>
        {userStore.user.jobs ? (
          <IconButton
            icon={<Icon icon="pencil" />}
            color="yellow"
            circle
            size="xs"
            onClick={() => {
              invoiceStore.changeInvoice(cellData.data);
              this.props.changeST(true, "new");
            }}
          />
        ) : (
          <div />
        )}
        <span> </span>
        {userStore.user.jobs ? (
          <IconButton
            icon={<Icon icon="trash" />}
            color="red"
            circle
            size="xs"
            onClick={() => {
              this.setState({
                inv: cellData.data,
                show: true,
              });
            }}
          />
        ) : (
          <div />
        )}
      </>
    );
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Invoices_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({ show: false });
      this.setState({ showInv: false });
    }
  }

  deleteInvoice = async () => {
    this.setState({ load: true });
    let status = "Cancelled";
    if (this.state.inv.status === "Cancelled") status = "Pending";
    let data = { invoiceid: this.state.inv._id, status: status };

    let url = "";

    url = `jf/invoice/deletebyid`;

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ load: false });
        this.showHideModal(false);
        invoiceStore.getInvoiceByJob(singleJobStore.job._id);
        Notification["success"]({ title: `Invoice Deleted` });
      })
      .catch((err) => {
        this.setState({ load: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = this.props.editview;
    let data = invoiceStore.invoices;

    if (!editview) {
      data = data.filter((inv) => inv.status !== "Cancelled");
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title={
            this.state.inv.status === "Cancelled"
              ? `Reactivate Invoice #${this.state.inv.invoiceNumId}`
              : `Delete Invoice #${this.state.inv.invoiceNumId}`
          }
          onHide={this.showHideModal}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  loading={this.state.load}
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => this.showHideModal(false)}
                >
                  No, Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  loading={this.state.load}
                  block
                  color={
                    this.state.inv.status === "Cancelled" ? "green" : "red"
                  }
                  icon={
                    <Icon
                      icon={
                        this.state.inv.status === "Cancelled"
                          ? "check"
                          : "trash"
                      }
                    />
                  }
                  onClick={() => this.deleteInvoice()}
                >
                  Yes,{" "}
                  {this.state.inv.status === "Cancelled"
                    ? "Reactivate"
                    : "Delete"}
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <h6>
            Are you sure you want to{" "}
            {this.state.inv.status === "Cancelled" ? "reactivate" : "delete"}{" "}
            this invoice?
          </h6>
          <p style={{ paddingLeft: "5px" }}>
            <b>Title: </b>
            {this.state.inv.title}
            <br />
            <b>Amount: </b>
            {PRKD(this.state.inv.total)} KD
            <br />
            <b>Status: </b>
            {this.state.inv.status}
            <br />
            <b>Sub Jobs: ({this.state.inv.subJobs.length}) </b>
            {this.state.inv.subJobs
              .map((sj) => `${sj.jobtype}-${sj.numId}`)
              .join(", ")}
            <br />
          </p>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showInv}
          title={`Invoice #${this.state.inv.invoiceNumId}`}
          onHide={this.showHideModal}
          footer={<div />}
        >
          <IntroPage inv={this.state.inv} />
        </UnifiedModal>
        {invoiceStore.loading ? (
          <Loader center />
        ) : (
          <div>
            <DataGrid
              dataSource={data}
              keyExpr="invoiceNumId"
              allowColumnReordering={true}
              allowColumnResizing={true}
              showBorders={true}
              rowAlternationEnabled
              onExporting={this.onExporting}
              style={{ fontSize: 10 }}
              searchPanel={false}
              hoverStateEnabled={true}
            >
              <FilterRow visible={false} applyFilter />
              <GroupPanel visible={false} />
              <SearchPanel visible={true} />
              <Grouping autoExpandAll={this.state.autoExpandAll} />
              <Scrolling mode="standard" showScrollbar="always" />
              <ColumnChooser enabled={true} mode="select" />
              <ColumnFixing enabled={true} />
              <Column
                caption="Actions"
                cellRender={this.ActionButs}
                width={100}
              />
              <Column
                dataField="invoiceNumId"
                caption="ID"
                alignment="center"
                width={80}
              />
              <Column
                dataField="title"
                caption="Title"
                alignment="center"
                minWidth={80}
              />
              <Column
                dataField="status"
                caption="Status"
                alignment="center"
                minWidth={80}
              />
              <Column
                dataField="subjobs.length"
                caption="Subjobs"
                alignment="center"
                minWidth={80}
                cellRender={this.SubJobsColumn}
              />
              <Column
                dataField="total"
                caption="Total"
                alignment="center"
                minWidth={80}
              />
              <Column
                dataField="date"
                dataType="date"
                caption="Date"
                alignment="center"
                format={"dd-MMM-yy"}
                minWidth={80}
              />

              <MasterDetail enabled={true} component={InvoiceDetails} />
              <Summary>
                <TotalItem
                  column="invoiceNumId"
                  summaryType="count"
                  valueFormat={"#,##0.00"}
                  displayFormat={"{0}"}
                />
                <TotalItem
                  column="total"
                  summaryType="sum"
                  valueFormat={"#,##0.00"}
                  displayFormat={"{0} KD"}
                />
              </Summary>
              <Export enabled={true} />
            </DataGrid>
          </div>
        )}
      </div>
    );
  }
}

export default observer(InvoiceTable);
