import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import axios from "axios";

import { FlexboxGrid, Icon, IconButton, Col } from "rsuite";

import { Notification } from "rsuite";
import lmtypes from "../../../../Static/Lists/jobsubtypes/lmtypes";
import imtypes from "../../../../Static/Lists/jobsubtypes/imtypes";
import sttypes from "../../../../Static/Lists/jobsubtypes/sttypes";
import cctypes from "../../../../Static/Lists/jobsubtypes/cctypes";

import singleJobStore from "../../../../Stores/SingleJobStore";
import userStore from "../../../../Stores/UserStore";

import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import jobTypeList from "../../../../Static/Lists/JobTypeList";
import statuslist from "../../../../Static/Lists/StatusList";
import SelectInputs from "../../../Lists/ClientsList/Inputs/SelectInputs";
import NumberInputAntd from "../../../Lists/ClientsList/Inputs/NumberInputAntd";
import baseURL from "../../../../Static/baseURL/baseURL";
import sjStore from "../../../../Stores/SideJobs/SJStore";
import { Tooltip } from "antd";
import commoditytypes from "../../../../Static/Lists/jobsubtypes/imlists/commoditytypes";

const instance = axios.create({ baseURL: baseURL });

class AddSubJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      jobtype: "Local Move",
      jobsubtype: "Full Service",
      jobstatus: "Lead",
      jobcbm: 0,
      loading: false,
      direction: "Inbound",
      shippingmode: "Sea",
      commodity: "Personsal Effect",
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }

  changeVal(type, val) {
    this.setState({ [type]: val });
    if (type === "jobtype") {
      if (val === "Local Move") this.setState({ jobsubtype: lmtypes[0] });
      if (val === "International Move")
        this.setState({ jobsubtype: imtypes[0] });
      if (val === "Storage") this.setState({ jobsubtype: sttypes[0] });
      if (val === "Custom Clearance") this.setState({ jobsubtype: cctypes[0] });
    }
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        jobtype: "Local Move",
        jobsubtype: "Full Service",
        jobstatus: "Lead",
        jobcbm: 0,
        showNewJob: false,
      });
    }
  }

  postNewJob = async () => {
    let jobtype = this.state.jobtype;
    let short = "";
    if (jobtype === "Local Move") short = "LM";
    if (jobtype === "International Move") short = "IM";
    if (jobtype === "Custom Clearance") short = "CC";
    if (jobtype === "Storage") short = "ST";

    let newjobdata = {
      client: singleJobStore.job.client._id,
      remarks: "",
      jobtype: short,
      subtype: this.state.jobsubtype,
      status: this.state.jobstatus,
      cbm: this.state.jobcbm,
      jobid: singleJobStore.job._id,
      direction: this.state.direction,
      shippingmode: this.state.shippingmode,
      commodity: this.state.commodity,
    };

    this.changeVal("loading", true);
    let url = `/job/sidejobs/sjs/addsj`;

    return instance
      .post(url, newjobdata, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.getSJs(this.props.match.params.singlejobid, true);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Added New ${jobtype}.`,
          description: (
            <div>
              {this.state.jobstatus}: {this.state.jobsubtype} for{" "}
              {this.state.jobcbm} CBM
            </div>
          ),
        });
        this.showHideModal(false);
        this.setState({ show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    let subtypelist = lmtypes;
    let hide = singleJobStore.hideCancelled;
    if (this.state.jobtype === "Local Move") subtypelist = lmtypes;
    if (this.state.jobtype === "International Move") subtypelist = imtypes;
    if (this.state.jobtype === "Storage") subtypelist = sttypes;
    if (this.state.jobtype === "Custom Clearance") subtypelist = cctypes;

    return (
      <div>
        <FlexboxGrid justify="end">
          <Col md={24} sm={24} xs={24}>
            <Tooltip title={`${hide ? "Show" : "Hide"} Cancellations`}>
              <IconButton
                block
                icon={<Icon icon="close" />}
                appearance={hide ? "ghost" : "primary"}
                color="red"
                onClick={() => singleJobStore.changeCancelled()}
                disabled={this.state.show}
              >
                {hide ? "Show" : "Hide"}
              </IconButton>
            </Tooltip>
          </Col>
        </FlexboxGrid>
        <br />
        <FlexboxGrid justify="end">
          <Col md={24} sm={24} xs={24}>
            <Tooltip title="Add New Job">
              <IconButton
                block
                icon={<Icon icon="plus" />}
                appearance={"primary"}
                color="green"
                onClick={() =>
                  this.setState({
                    show: true,
                    jobtype: "Local Move",
                    jobsubtype: "Full Service",
                  })
                }
                disabled={this.state.show}
              >
                New
              </IconButton>
            </Tooltip>
          </Col>
        </FlexboxGrid>
        <UnifiedModal
          show={this.state.show}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={
                      (this.state.jobtype !== "Custom Clearance") &
                      (this.state.jobcbm === null)
                    }
                    loading={this.state.loading}
                    onClick={() => this.postNewJob()}
                  >
                    Create New {this.state.jobtype}
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h4>
            <b>
              <i> {singleJobStore.job.client.name}</i>
            </b>{" "}
            - Job # {singleJobStore.job.jobNumId}
          </h4>

          <div>
            <FlexboxGrid>
              <Col md={6} sm={12} xs={24}>
                <SelectInputs
                  options={jobTypeList}
                  val={this.state.jobtype}
                  label="Job Type"
                  type={"jobtype"}
                  changeVal={this.changeVal}
                />
              </Col>
              {this.state.jobtype === "International Move" && (
                <Col md={6} sm={12} xs={24}>
                  <SelectInputs
                    options={["Air", "Land", "Sea"]}
                    val={this.state.shippingmode}
                    label="Shipping Method"
                    type={"shippingmode"}
                    changeVal={this.changeVal}
                  />
                </Col>
              )}
              {this.state.jobtype === "International Move" && (
                <Col md={6} sm={12} xs={24}>
                  <SelectInputs
                    options={commoditytypes}
                    val={this.state.commodity}
                    label="Commodity"
                    type={"commodity"}
                    changeVal={this.changeVal}
                  />
                </Col>
              )}

              {this.state.jobtype === "International Move" && (
                <Col md={6} sm={12} xs={24}>
                  <SelectInputs
                    options={["Inbound", "Outbound"]}
                    val={this.state.direction}
                    label="Direction"
                    type={"direction"}
                    changeVal={this.changeVal}
                  />
                </Col>
              )}
              <Col md={6} sm={12} xs={24}>
                <SelectInputs
                  options={subtypelist}
                  val={this.state.jobsubtype}
                  label="Sub Type"
                  type={"jobsubtype"}
                  changeVal={this.changeVal}
                />
              </Col>
              <Col md={6} sm={12} xs={24}>
                <SelectInputs
                  options={statuslist}
                  val={this.state.jobstatus}
                  label="Status"
                  type={"jobstatus"}
                  changeVal={this.changeVal}
                />
              </Col>
              <Col md={6} sm={12} xs={24}>
                {this.state.jobtype !== "Custom Clearance" ? (
                  <NumberInputAntd
                    label="CBM"
                    type="jobcbm"
                    val={this.state.jobcbm}
                    changeVal={this.changeVal}
                  />
                ) : (
                  <SelectInputs
                    options={["Air", "Land", "Sea"]}
                    val={this.state.shippingmode}
                    label="Shipping Method"
                    type={"shippingmode"}
                    changeVal={this.changeVal}
                  />
                )}
              </Col>
            </FlexboxGrid>
          </div>
        </UnifiedModal>

        <br />
      </div>
    );
  }
}

export default withRouter(observer(AddSubJob));
