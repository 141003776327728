import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import userStore from "../../../../../Stores/UserStore";
import MatTableSTD from "../../../../../Components/Tables/MatTableSTD";
import NewItemModal from "./Items/NewItemModal";
import DeleteItemModal from "./Items/DeleteItemModal";
import ItemSheet from "./Items/ItemSheet";

const instance = axios.create({ baseURL: baseURL });

class ItemTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "New",
      showEdit: false,
      showDelete: false,
      selectedTrack: {
        item: "",
        cbm: 0,
        quantity: 1,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        remarks: "",
        _id: "",
      },
      editedTrack: {
        item: "",
        cbm: 0,
        quantity: 1,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        remarks: "",
        _id: "",
      },
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  changeVal(label, val) {
    let track = { ...this.state.editedTrack };
    track[label] = val;
    this.setState({ editedTrack: track });
  }

  showHideModal(val) {
    if (val === "delete") {
      this.setState({ showDelete: true });
    } else if (val === "New") {
      this.setState({
        type: val,
        showEdit: true,
        selectedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
        editedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
      });
    } else if (val) {
      this.setState({ showEdit: true, type: "Edit" });
    } else {
      this.setState({
        selectedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
        editedTrack: {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
        showEdit: false,
        showDelete: false,
        showNew: false,
      });
    }
  }

  postNew = async (item) => {
    let aim = newAimStore.oldaim;

    this.setState({ loading: true });
    let data = {
      item: item.item,
      cbm: item.cbm,
      length: item.length,
      width: item.width,
      height: item.height,
      quantity: item.quantity,
      weight: item.weight,
      remarks: item.remarks,
      aimOrder: aim._id,
    };

    return instance
      .post(`/additional/item/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.newList("items", res.data);

        this.setState({ loading: false });
        Notification["success"]({
          title: `New Item Created.`,
          description: <div>{data.item}</div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  postEdit = async (item) => {
    let itemindex = item.tableData.id;

    this.setState({ loading: true });

    return instance
      .put(`/additional/item/edit/${item._id}`, item, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.editList("items", itemindex, item);

        this.setState({ loading: false });
        Notification["success"]({
          title: `Updated Item Details.`,
          description: <div>{item.title}</div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  removeItem = async (item) => {
    let itemindex = item.tableData.id;

    this.setState({ loading: true });

    let data = { itemid: item._id };

    return instance
      .put(`/additional/item/delete/${item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        Notification["success"]({
          title: `Deleted Item.`,
          description: <div>{item.title}</div>,
        });
        this.showHideModal(false);
        newAimStore.removeList("items", itemindex);

        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    let editview = !newAimStore.stopedit;

    let aim = newAimStore.oldaim;
    let data = aim.items;

    let disabled = false;
    if (this.state.selectedTrack === this.state.editedTrack) {
      disabled = true;
    }
    if (this.state.editedTrack.item === "") disabled = true;
    if (this.state.editedTrack.cbm < 0) disabled = true;
    if (this.state.editedTrack.quantity === 0) disabled = true;
    if (this.state.editedTrack.cbm === null) disabled = true;
    if (this.state.editedTrack.quantity === null) disabled = true;
    if (this.state.editedTrack.remarks === 0) disabled = true;

    let hideTable = false;

    let columns = [
      { title: "Name", field: "item", defaultSort: "desc" },
      { title: "CBM", field: "cbm" },
      { title: "Qty", field: "quantity" },
      { title: "L (cm)", field: "length" },
      { title: "W (cm)", field: "width" },
      { title: "H (cm)", field: "height" },
      { title: "Weight (kg)", field: "weight" },
      { title: "Remark", field: "remarks" },
    ];

    let actions = [
      {
        icon: "add",
        tooltip: "Add New Item",
        isFreeAction: true,
        hidden: !editview,
        onClick: (event, rowData) => {
          this.setState({ selectedTrack: rowData });
          this.setState({ editedTrack: rowData });

          this.showHideModal("New");
        },
      },
      {
        icon: "edit",
        tooltip: "Edit",
        hidden: !editview,
        onClick: (event, rowData) => {
          this.setState({ selectedTrack: rowData });
          this.setState({ editedTrack: rowData });

          this.showHideModal("Edit");
        },
      },
      {
        icon: "delete",
        hidden: !editview,
        tooltip: "Delete",
        onClick: (event, rowData) => {
          this.setState({ selectedTrack: rowData });
          this.setState({ editedTrack: rowData });

          this.showHideModal("delete");
        },
      },
    ];

    return (
      <div>
        <NewItemModal
          title="New Item"
          type={this.state.type}
          disabled={disabled}
          loading={this.state.loading}
          edited={this.state.editedTrack}
          selected={this.state.selectedTrack}
          changeVal={this.changeVal}
          show={this.state.showEdit}
          showHideModal={this.showHideModal}
          postNew={this.postNew}
          postEdit={this.postEdit}
        />
        <DeleteItemModal
          title="New Item"
          fastFill={this.fastFill}
          type={this.state.type}
          loading={this.state.loading}
          edited={this.state.editedTrack}
          selected={this.state.selectedTrack}
          changeVal={this.changeVal}
          show={this.state.showDelete}
          showHideModal={this.showHideModal}
          removeItem={this.removeItem}
        />
        {this.state.loading ? (
          <div style={{ minHeight: "200px" }} />
        ) : (
          <MatTableSTD
            data={data}
            title="Packing List"
            columns={columns}
            actions={actions}
            loading={hideTable}
          />
        )}
        <br />
        {!newAimStore.stopedit && <ItemSheet aim={aim} />}
      </div>
    );
  }
}

export default withRouter(observer(ItemTable));
