import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class AdUserStore {
  constructor() {
    this.users = [];
    this.editusers = [];
    this.loading = false;
    this.dataloaded = false;
    this.singleClient = {};
    this.filter = "All";

    makeObservable(this, {
      users: observable,
      singleClient: observable,
      filter: observable,
      editusers: observable,
      loading: observable,
      dataloaded: observable,
      filtusers: computed,
    });
  }

  // FETCH
  getUsers() {
    this.loading = true;
    return instance
      .get(`/adstaff/user/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((users) => {
        this.users = users;
        this.editusers = [...users];
        this.loading = false;
        console.log("All Users Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Users.`,
        });
      });
  }

  // POST

  getClientId(clientNumId) {
    this.loading = true;
    return instance
      .get(`/adstaff/user/client/byId/${clientNumId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((singleClient) => {
        this.singleClient = singleClient;
        this.loading = false;
        console.log("Client Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Client.`,
        });
      });
  }

  // COMPUTATIONS:

  get filtusers() {
    let finalusers = [...this.users];
    if (this.filter !== "All") {
      finalusers = finalusers.filter((user) => user.group === this.filter);
    }

    return finalusers;
  }

  // FUNCTIONS:
  // ----- DATE ----

  changefilt(label) {
    if (this.filter === label) {
      this.filter = "All";
    } else {
      this.filter = label;
    }
  }

  editMainUser(user) {
    let index = this.users.findIndex((usold) => usold._id === user._id);
    let usnew = [...this.users];
    usnew[index] = user;
    this.users = usnew;
  }

  startLoading() {
    this.loading = true;
  }
}

const adUserStore = new AdUserStore();
export default adUserStore;
