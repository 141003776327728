import React, { Component } from "react";
import { InputGroup, Input, Icon } from "rsuite";

class ChecklistTextInputSTD extends Component {
  render() {
    let title = this.props.title;

    let label = this.props.label;
    let val = this.props.val;
    let size = "lg";
    if (this.props.size) size = this.props.size;
    let index = this.props.index;
    let okvalue = this.props.okvalue;

    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <InputGroup.Button
          color={okvalue === val ? "green" : "orange"}
          appearance={okvalue === val ? "primary" : "ghost"}
          onClick={() => {
            if (okvalue === val) {
              this.props.editVal("", label, index);
            } else {
              this.props.editVal(okvalue, label, index);
            }
          }}
        >
          <Icon icon={okvalue === val ? "check" : "exclamation-triangle"} />
        </InputGroup.Button>

        <Input
          size={size}
          value={val}
          disabled={okvalue === val}
          onChange={(e) => this.props.editVal(e, label, index)}
        />
      </InputGroup>
    );
  }
}

export default ChecklistTextInputSTD;
