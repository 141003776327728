import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableCol100blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol10blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol25blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol100white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol10white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol25white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
});
class FixedPerTypePDF extends Component {
  render() {
    let to = this.props.to;

    let itemslist = to.items.map((item, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol10white}>{index + 1}</Text>
        <Text style={styles.tableCol25white}>{item.name}</Text>
        <Text style={styles.tableCol25white}>{item.serialnumber}</Text>
        <Text style={styles.tableCol10white}>{item.quantity}</Text>
        <Text style={styles.tableCol10white}>{item.length}</Text>
        <Text style={styles.tableCol10white}>{item.width}</Text>
        <Text style={styles.tableCol10white}>{item.height}</Text>
      </View>
    ));

    return (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol10blue}>#</Text>
          <Text style={styles.tableCol25blue}>Item</Text>
          <Text style={styles.tableCol25blue}>Serial</Text>
          <Text style={styles.tableCol10blue}>Qty</Text>
          <Text style={styles.tableCol10blue}>L (cm)</Text>
          <Text style={styles.tableCol10blue}>W (cm)</Text>
          <Text style={styles.tableCol10blue}>H (cm)</Text>
        </View>
        {itemslist}
      </View>
    );
  }
}

export default observer(FixedPerTypePDF);
