import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";

const instance = axios.create({ baseURL: baseURL });

class SideJobStore {
  constructor() {
    this.type = "";
    this.sjs = [];
    this.sjsconv = [];
    this.sjscanc = [];
    this.loadsjs = false;
    this.loadcanc = false;
    this.loadconv = false;
    this.loadsingle = false;
    this.singlejob = { job: {}, lms: [], ims: [], cc: [] };
    this.filtuser = "All";
    this.filter = {
      text: "",
      lead: true,
      proposal: true,
      booked: true,
      converted: true,
      cancelled: true,
      lm: true,
      im: true,
      cc: true,
      st: true,
    };

    makeObservable(this, {
      sjs: observable,
      sjsconv: observable,
      sjscanc: observable,
      loadsjs: observable,
      loadcanc: observable,
      loadconv: observable,

      loadsingle: observable,
      singlejob: observable,

      filtuser: observable,
      filter: observable,
      filtsjs: computed,
      filtfocalpoints: computed,
      calclms: computed,
      calcims: computed,
      calcccs: computed,
      calcsts: computed,
    });
  }

  // FETCH
  getPending(jobtype) {
    this.type = jobtype;
    this.loadsjs = true;
    return instance
      .get(`/job/sidejobs/sjs/getpending/${jobtype}/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.sjs = data;
        this.loadsjs = false;
        this.loadconv = false;
        this.loadcanc = false;
        console.log(`Pending Jobs Fetched.`);
      })
      .catch((err) => {
        this.loadsjs = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  // FETCH
  getOther(jobtype, filter) {
    this.type = jobtype;
    this.loadsjs = true;

    return instance
      .get(`/job/sidejobs/sjs/getpending/${jobtype}/${filter}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        let oldData = [];
        oldData = [...this.sjs];
        for (let i = 0; i < data.length; i++) {
          oldData.push(data[i]);
        }
        if (filter === "Converted") {
          this.loadconv = true;
        } else if (filter === "Cancelled") {
          this.loadcanc = true;
        }
        this.sjs = [...oldData];
        this.loadsjs = false;
        console.log(`${filter} Jobs Fetched.`);
      })
      .catch((err) => {
        this.loadsjs = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  editJobFilt(type, val) {
    if (val) {
      this.filter.im = true;
      this.filter.lm = true;
      this.filter.st = true;
      this.filter.cc = true;
    } else {
      this.filter.im = type === "im";
      this.filter.lm = type === "lm";
      this.filter.st = type === "st";
      this.filter.cc = type === "cc";
    }
  }

  editFilt(type, val) {
    this.filter[type] = val;
  }
  editFiltUser(val) {
    this.filtuser = val;
  }

  resetFilt() {
    this.filter = {
      text: "",
      lead: true,
      proposal: true,
      booked: true,
      converted: true,
      cancelled: true,
      lm: true,
      im: true,
      cc: true,
      st: true,
    };
    this.filtuser = "All";
  }

  get filtsjs() {
    let sjs = [...this.sjs];
    if (!this.filter.lead) sjs = sjs.filter((or) => or.status !== "Lead");
    if (!this.filter.proposal)
      sjs = sjs.filter((or) => or.status !== "Proposal");
    if (!this.filter.booked) sjs = sjs.filter((or) => or.status !== "Booked");
    if (!this.filter.converted)
      sjs = sjs.filter((or) => or.status !== "Converted");
    if (!this.filter.cancelled)
      sjs = sjs.filter((or) => or.status !== "Cancelled");
    if (this.filter.text !== "") {
      let text = this.filter.text.toLowerCase();
      sjs = sjs.filter((or) =>
        `${or.sjNumId} ${or.status} ${or.subtype} ${or.jobtype} ${
          or.lastprice
        } ${or.cbm} ${or.remarks} ${
          or.job.focalpoint ? or.job.focalpoint.username : ""
        } ${or.job.client.name} ${or.job.jobNumId}`
          .toLowerCase()
          .includes(text)
      );
    }
    if (!this.filter.lm) sjs = sjs.filter((or) => or.jobtype !== "LM");
    if (!this.filter.im) sjs = sjs.filter((or) => or.jobtype !== "IM");
    if (!this.filter.cc) sjs = sjs.filter((or) => or.jobtype !== "CC");
    if (!this.filter.st) sjs = sjs.filter((or) => or.jobtype !== "ST");

    if (this.filtuser !== "All") {
      sjs = sjs.filter((or) => or.job.focalname === this.filtuser);
    }

    return sjs;
  }

  get calclms() {
    return this.filtsjs.filter((sj) => sj.jobtype === "LM");
  }
  get calcims() {
    return this.filtsjs.filter((sj) => sj.jobtype === "IM");
  }
  get calcccs() {
    return this.filtsjs.filter((sj) => sj.jobtype === "CC");
  }
  get calcsts() {
    return this.filtsjs.filter((sj) => sj.jobtype === "ST");
  }

  get filtfocalpoints() {
    let focalpointall = [];
    if (this.sjs.length <= 0) {
      return [];
    } else {
      focalpointall = this.sjs.map((sj) => {
        if (sj.job) {
          if (sj.job.focalpoint) {
            return sj.job.focalpoint.username;
          }
        }
        return "Not Assigned";
      });
      if (focalpointall.length <= 0) {
        return [];
      } else {
        return [...new Set(focalpointall)];
      }
    }
  }
}

const sideJobStore = new SideJobStore();
export default sideJobStore;
