import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import zoneStore from "../../../../Stores/Admin/Lists/ZoneStore";
import { Tooltip } from "@material-ui/core";
import { IconButton, Icon, FlexboxGrid, Notification } from "rsuite";
import MaterialTable from "material-table";
import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../Components/Columns/Col2";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import kuwaitzones from "../../../../Static/Lists/KuwaitZoneList";

const instance = axios.create({ baseURL: baseURL });

class ZoneTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "New",
      show: false,
      zone: { name: "", arabic: "", zone: 1, govt: "Al Asima", _id: "" },
      loading: false,
    };
    this.editZone = this.editZone.bind(this);
    this.postZone = this.postZone.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({ show: false });
  }
  editZone(data, label) {
    let zone = { ...this.state.zone };
    zone[label] = data;
    this.setState({ zone: zone });
  }

  uploadAll = async () => {
    let zones = kuwaitzones;
    this.setState({ loading: true });
    let data = {};
    for (let i = 0; i < zones.length; i++) {
      data = {
        name: zones[i].name,
        arabic: zones[i].arabic,
        govt: zones[i].govt,
        zone: zones[i].zone,
      };
      await instance
        .post(`/additional/zone/new`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          console.log(`${i + 1}) Zone Created #${res.name}`);
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
    Notification["success"]({
      title: `${zones.length} Zones Uploaded.`,
    });
    zoneStore.getZones(true);
  };

  postZone = async (data, type) => {
    this.setState({ loading: true });
    if (type === "New") {
      return instance
        .post(`/additional/zone/new`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          zoneStore.getZones(true);
          this.setState({ loading: false });
          Notification["success"]({
            title: `New Zone Created.`,
            description: <div>{data.amount} KD</div>,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else {
      return instance
        .put(`/additional/zone/edit/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          zoneStore.getZones(true);
          this.setState({ loading: false });
          Notification["success"]({
            title: `Updated Zone Details.`,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    }
  };

  newZone() {
    this.setState({
      type: "New",
      show: true,
      zone: { name: "", arabic: "", zone: 1, govt: "Al Asima", _id: "" },
    });
  }

  componentDidMount() {
    zoneStore.getZones();
  }
  render() {
    let data = zoneStore.zones;
    let columnnames = [
      { title: "ID", field: "zoneNumId", defaultSort: "desc" },
      { title: "Name", field: "name" },
      { title: "Arabic", field: "arabic" },
      { title: "Zone`", field: "zone" },
      { title: "Govt`", field: "govt" },
    ];

    let type = this.state.type;
    let zone = this.state.zone;
    let disable = false;
    if (zone.name === "") disable = true;
    if (zone.arabic === "") disable = true;
    if (zone.govt === "") disable = true;

    return (
      <div>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`${type === "New" ? "Add New " : "Edit Existing "} Zone`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.postZone(this.state.zone, this.state.type);
                  }}
                >
                  {type === "New" ? "Create New " : "Save Edited "} City + Zone
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid>
            <Col2>
              <TextInputSTD
                title="Zone Name"
                val={zone.name}
                label="name"
                size="large"
                editVal={this.editZone}
              />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Zone Arabic"
                val={zone.arabic}
                label="arabic"
                size="large"
                editVal={this.editZone}
              />
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Governate"
                val={zone.govt}
                label="govt"
                size="large"
                editVal={this.editZone}
                opts={[
                  "Ahmadi",
                  "Al Asima",
                  "Farwaniya",
                  "Hawalli",
                  "Jahra",
                  "Mubarak Al-Kabeer",
                ]}
              />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Zone Number"
                val={zone.zone}
                label="zone"
                size="large"
                editVal={this.editZone}
                opts={[1, 2, 3, 4, 5]}
              />
            </Col2>
          </FlexboxGrid>
        </UnifiedModal>

        <Div14>
          Zones:
          <span style={{ width: "10px" }} />{" "}
          <Tooltip title={"Refresh List"} arrow placement="top">
            <IconButton
              circle
              size="xs"
              icon={<Icon icon="refresh" />}
              onClick={() => zoneStore.getZones(true)}
            />
          </Tooltip>
        </Div14>

        <IconButton onClick={() => this.uploadAll()}>Upload All</IconButton>

        <div>
          <MaterialTable
            isLoading={zoneStore.loading}
            title={data.length + " Zones"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `Zone List as of ${moment(new Date()).format(
                "DD-MMM-YY"
              )}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Open Client",
                onClick: (event, rowData) =>
                  this.setState({ zone: rowData, show: true, type: "Edit" }),
              },
              {
                icon: "add",
                tooltip: "Open Client",
                isFreeAction: true,
                onClick: (event, rowData) => this.newZone(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default observer(ZoneTab);
