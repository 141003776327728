import React, { Component } from "react";
import { Loader, Placeholder } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";

const { Paragraph } = Placeholder;

class DeliveryTrackingLoading extends Component {
  render() {
    return (
      <div>
        <Loader center />
        <Div14>Delivery Tracking Page</Div14>
        <Paragraph active rows={2} />

        <Div14>Address</Div14>
        <Paragraph active rows={4} />

        <Div14>Items</Div14>
        <Placeholder.Grid active rows={4} columns={4} />
      </div>
    );
  }
}

export default DeliveryTrackingLoading;
