import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

import moment from "moment";
import LocationPDF from "./LocationPDF";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol100blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol70blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol100white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol70white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableCol30white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
});
class FixedPerTypePDF extends Component {
  render() {
    let del = this.props.del;
    let showall = this.props.showall;
    let to = this.props.to;

    let deliveryNumId = del.deliveryNumId;

    let itemslist = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableCol100white}>Services</Text>
      </View>
    );

    if (showall) {
      itemslist = del.toaddress.map((to, index) => (
        <View style={{ flexDirection: "row" }} key={index}>
          <Text style={styles.tableCol30white}>Service {index + 1}</Text>
          <Text style={styles.tableCol70white}>{to.service.type}</Text>
        </View>
      ));
    } else {
      itemslist = (
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30white}>Service {to.order}</Text>
          <Text style={styles.tableCol70white}>{to.service.type}</Text>
        </View>
      );
    }

    let location = (
      <LocationPDF to={to} title={`Dropoff Location #${to.order}`} />
    );

    return (
      <View>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Below is to confirm that the Below Services were{" "}
          {showall ? "Picked Up from" : "Delivered to"} the Client for Order ID#{" "}
          {deliveryNumId} on{" "}
          {moment(to.delSignature.date).format("DD-MMM-YYYY HH:mm A")}.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        {location}

        <Text style={{ lineHeight: 1 }}> </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>#</Text>
          <Text style={styles.tableCol70blue}>Type</Text>
        </View>
        {itemslist}
      </View>
    );
  }
}

export default observer(FixedPerTypePDF);
