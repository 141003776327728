import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import Col4 from "../../../../../../../Components/Columns/Col4";
import { Button, FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import { message, Statistic, Tabs } from "antd";
import Col2 from "../../../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import MaterialTable from "material-table";
import userStore from "../../../../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import ReactApexChart from "react-apexcharts";

import DataGrid, {
  Column,
  Grouping,
  GroupItem,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { Tooltip } from "@material-ui/core";
import ItemModal from "../../../../../StoragePages/InventoryPage/Modals/ItemModal";
import moment from "moment";
import modalItemStore from "../../../../../../../Stores/ModalStores/ModalItemStore";

const { TabPane } = Tabs;
const instance = axios.create({ baseURL: baseURL });

class ItemLocationsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "2",
      selected: "",
      show: false,
      itemtab: "Current",
      wh3tab: "A",
      loading: false,
      showitem: false,
    };
    this.onHide = this.onHide.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Current_Inventory_List_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  ActionButs(cellData) {
    return (
      <>
        <Tooltip title={`View Item`} arrow>
          <IconButton
            icon={<Icon icon="eye" />}
            color="green"
            circle
            size="xs"
            onClick={() => {
              modalItemStore.getSingleItem(cellData.data._id, true);
              this.setState({ showitem: true });
            }}
          />
        </Tooltip>
      </>
    );
  }
  addTag(item) {
    this.setState({ loading: true });
    let loctags = [...item.loctags, staffStorageStore.selectedloc];
    let data = { loctags: loctags };

    return instance
      .put(`/ac/basicedititem/edititem/${item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        let newitem = { ...item };
        newitem.loctags = loctags;
        staffStorageStore.addItemTag(newitem);
      })
      .then((res) => {
        this.setState({ loading: false });
        message.success("Location Tag Added");
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  }
  removeTag(item) {
    this.setState({ loading: true });
    let loctags = item.loctags;
    let newlocs = [];

    for (let i = 0; i < loctags.length; i++) {
      if (loctags[i] !== staffStorageStore.selectedloc) {
        newlocs = [...newlocs, loctags[i]];
      }
    }
    let data = { loctags: newlocs };

    return instance
      .put(`/ac/basicedititem/edititem/${item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        let newitem = { ...item };
        newitem.loctags = newlocs;
        staffStorageStore.removeItemTag(newitem);
      })
      .then((res) => {
        this.setState({ loading: false });
        message.success("Location Tag Removed");
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    staffStorageStore.getItems();
    staffStorageStore.getItemLocs();
  }
  onHide() {
    this.setState({ show: false, showitem: false });
  }
  onSelect(loc) {
    this.setState({ selected: loc, show: true });
    staffStorageStore.getSingleLocItems(loc);
  }

  render() {
    let buttons = staffStorageStore.filtitemlocs;

    let content;
    if (buttons) {
      if (buttons.length) {
        if (buttons.length > 0) {
          if (staffStorageStore.loctab === "3") {
            content = (
              <Tabs
                activeKey={this.state.wh3tab}
                defaultActiveKey={0}
                tabPosition={"top"}
                type="card"
                centered
                onChange={(e) => this.setState({ wh3tab: e })}
              >
                {buttons.map((but, index) => (
                  <TabPane
                    key={index}
                    tab={`${staffStorageStore.loctab}${but.title}`}
                  >
                    <FlexboxGrid>
                      {but.buts.map((b, ind) => (
                        <Col4 key={ind} justify="start">
                          <Button
                            block
                            color="green"
                            size="sm"
                            onClick={() => this.onSelect(b.title)}
                            appearance={
                              b.items.length > 0 ? "primary" : "ghost"
                            }
                          >
                            {b.title} ({b.items.length})
                          </Button>
                        </Col4>
                      ))}
                      <br />
                    </FlexboxGrid>
                  </TabPane>
                ))}
              </Tabs>
            );
          } else {
            content = buttons.map((but, index) => (
              <div key={index}>
                <Div14>
                  Warehouse {staffStorageStore.loctab} {but.title}
                </Div14>
                <FlexboxGrid>
                  {but.buts.map((b, ind) => (
                    <Col4 key={ind} justify="start">
                      <Button
                        block
                        color="green"
                        size="sm"
                        onClick={() => this.onSelect(b.title)}
                        appearance={b.items.length > 0 ? "primary" : "ghost"}
                      >
                        {b.title} ({b.items.length})
                      </Button>
                    </Col4>
                  ))}
                  <br />
                </FlexboxGrid>
              </div>
            ));
          }
        }
      }
    }

    let columnnames = [
      {
        title: "ID",
        field: "acItemNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Name", field: "name" },
      { title: "Serial", field: "serialnumber" },
      { title: "Prod ID", field: "productid" },
      { title: "Client", field: "client.name" },
      {
        title: "loctag",
        field: "loctag",
        render: (rowData) => {
          return rowData.loctags.join(", ");
        },
      },
    ];

    let totalmissing = staffStorageStore.missinglocs.length;
    let totalinv = staffStorageStore.inventory.length;
    let totalcomplete = Math.round(
      ((totalinv - totalmissing) * 100) / totalinv
    );

    let options = {
      chart: {
        height: 280,
        type: "radialBar",
      },
      colors: ["#20E647"],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#333",
            startAngle: -90,
            endAngle: 90,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          gradientToColors: ["#87D4F9"],
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Progress"],
    };

    return (
      <LoadBack loading={staffStorageStore.loading.itemlocs}>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title={`Items List in ${staffStorageStore.selectedloc}`}
          footer={
            <FlexboxGrid justify="center">
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Close
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <LoadBack
            loading={staffStorageStore.loading.itemlocs || this.state.loading}
          >
            <Tabs
              activeKey={this.state.itemtab}
              defaultActiveKey={0}
              tabPosition={"top"}
              type="card"
              centered
              onChange={(e) => this.setState({ itemtab: e })}
            >
              <TabPane tab={"Current"} key={"Current"}>
                <MaterialTable
                  isLoading={staffStorageStore.loading.itemlocs}
                  columns={columnnames}
                  title={`Items in ${staffStorageStore.selectedloc}`}
                  style={{ fontSize: 12 }}
                  data={staffStorageStore.singlelocitems}
                  options={{
                    actionsColumnIndex: 0,
                    exportAllData: true,
                    paging: false,
                    toolbarButtonAlignment: "left",
                    maxBodyHeight: 1000,
                    tableLayout: "auto",
                    exportButton: false,
                    padding: "dense",
                  }}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete Tag",
                      onClick: (event, rowData) => this.removeTag(rowData),
                    },
                  ]}
                />
              </TabPane>
              <TabPane tab={"Inventory"} key={"Inventory"}>
                <MaterialTable
                  isLoading={staffStorageStore.loading.itemlocs}
                  columns={columnnames}
                  style={{ fontSize: 12 }}
                  title={`All Inventory`}
                  data={staffStorageStore.otheritems}
                  options={{
                    actionsColumnIndex: 0,
                    exportAllData: true,
                    paging: true,
                    toolbarButtonAlignment: "left",
                    maxBodyHeight: 1000,
                    tableLayout: "auto",
                    exportButton: true,
                    padding: "dense",
                  }}
                  actions={[
                    {
                      icon: "add",
                      tooltip: "Add Tag",
                      onClick: (event, rowData) => this.addTag(rowData),
                    },
                  ]}
                />
              </TabPane>
            </Tabs>
          </LoadBack>
        </UnifiedModal>
        <Div14>Item Locations</Div14>
        <IconButton
          icon={<Icon icon="refresh" />}
          onClick={() => staffStorageStore.getItemLocs(true)}
        >
          Refresh
        </IconButton>
        <Tabs
          activeKey={staffStorageStore.loctab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => staffStorageStore.changeLocTab(e)}
        >
          {/* <TabPane tab={"Warehouse 1"} key={"1"}>
            {content}
          </TabPane> */}
          <TabPane tab={"Warehouse 2"} key={"2"}>
            {content}
          </TabPane>
          <TabPane tab={"Warehouse 3"} key={"3"}>
            {content}
          </TabPane>
          <TabPane tab={"Missing Locations"} key={"Missing Locations"}>
            <LoadBack loading={staffStorageStore.loading.inventory}>
              <ItemModal show={this.state.showitem} onHide={this.onHide} />
              <FlexboxGrid>
                <Col2>
                  <div
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px black",
                      textAlign: "center",
                      margin: "2%",
                      padding: "5px",
                    }}
                  >
                    <ReactApexChart
                      options={options}
                      series={[totalcomplete]}
                      type="radialBar"
                      height={315}
                    />
                  </div>
                </Col2>
                <Col2>
                  <FlexboxGrid>
                    <div
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px black",
                        textAlign: "center",
                        margin: "2%",
                        width: "100%",
                      }}
                    >
                      <Statistic title="Total" value={totalinv} />
                    </div>
                    <div
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px black",
                        textAlign: "center",
                        margin: "2%",
                        width: "100%",
                      }}
                    >
                      <Statistic
                        title="Complete"
                        value={totalinv - totalmissing}
                        valueStyle={{ color: "green" }}
                      />
                    </div>
                    <div
                      style={{
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px black",
                        textAlign: "center",
                        margin: "2%",
                        width: "100%",
                      }}
                    >
                      <Statistic
                        title="Remaining"
                        value={totalmissing}
                        valueStyle={{ color: "red" }}
                      />
                    </div>
                  </FlexboxGrid>
                </Col2>
              </FlexboxGrid>
              <div>
                <Div14>Current Inventory Lists</Div14>
                <DataGrid
                  dataSource={staffStorageStore.missinglocs}
                  keyExpr="acItemNumId"
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  showBorders={true}
                  rowAlternationEnabled
                  onExporting={this.onExporting}
                  style={{ fontSize: 10 }}
                  hoverStateEnabled={true}
                  onRowClick={(cellData) => {
                    if (cellData.data) {
                      if (cellData.data._id) {
                        modalItemStore.getSingleItem(cellData.data._id, true);
                        this.setState({ showitem: true });
                      }
                    }
                  }}
                >
                  <FilterRow visible={true} applyFilter />
                  <GroupPanel visible={true} />
                  <Grouping autoExpandAll={false} />
                  <SearchPanel visible={true} />

                  <Scrolling mode="standard" showScrollbar="always" />
                  <ColumnChooser enabled={true} mode="select" />
                  <ColumnFixing enabled={true} />
                  <Column
                    dataField="acItemNumId"
                    caption="ID"
                    alignment="center"
                    width={80}
                  />
                  <Column caption="Item Details" alignment={"center"}>
                    <Column
                      dataField="name"
                      caption="Item"
                      alignment="center"
                      minWidth={80}
                    />
                    <Column
                      dataField="serialnumber"
                      caption="Serial No."
                      alignment="center"
                      minWidth={80}
                    />
                    <Column
                      dataField="productid"
                      caption="Product No."
                      alignment="center"
                      minWidth={80}
                    />

                    <Column
                      dataField="client.name"
                      caption="Client"
                      alignment="center"
                      minWidth={80}
                      grouped={true}
                    />
                  </Column>
                  <Column caption="Item Dimensions" alignment={"center"}>
                    <Column
                      dataField="currentstock"
                      caption="QTY"
                      alignment="center"
                      minWidth={80}
                      format={{ type: "fixedPoint", precision: 0 }}
                      cellRender={this.CurrentStockCount}
                    />
                    <Column
                      dataField="onreserve"
                      caption="On Reserve"
                      alignment="center"
                      minWidth={80}
                      format={{ type: "fixedPoint", precision: 0 }}
                      cellRender={this.OnReserveCount}
                    />
                    <Column
                      dataField="dimensions"
                      caption="Dimensions"
                      alignment="center"
                      minWidth={100}
                    />
                    <Column
                      dataField="cbm"
                      caption="CBM per Item"
                      alignment="center"
                      minWidth={80}
                      format={{ type: "fixedPoint", precision: 2 }}
                    />
                    <Column
                      dataField="totalcbm"
                      caption="Total CBM"
                      alignment="center"
                      minWidth={80}
                      format={{ type: "fixedPoint", precision: 2 }}
                    />
                  </Column>
                  <Column caption="Location" alignment={"center"}>
                    <Column
                      dataField="loctags"
                      caption="Location"
                      alignment="center"
                      minWidth={80}
                    />
                  </Column>
                  <Column
                    caption="Actions"
                    alignment={"center"}
                    cellRender={this.ActionButs}
                    width={100}
                  />
                  <Summary>
                    <GroupItem column="client.name" summaryType="count" />
                    <TotalItem
                      column="name"
                      summaryType="count"
                      displayFormat={`{0} Items`}
                    />
                    <TotalItem
                      column="currentstock"
                      summaryType="sum"
                      displayFormat={`{0} Items`}
                    />
                    <TotalItem
                      column="totalcbm"
                      summaryType="sum"
                      valueFormat={"#,##0.000"}
                      displayFormat={"{0} CBM"}
                    />
                  </Summary>
                  <Export enabled={true} />
                </DataGrid>
              </div>
            </LoadBack>
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(ItemLocationsMain));
