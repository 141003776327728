import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  FlexboxGrid,
  IconButton,
  Icon,
  Col,
  InputGroup,
  Notification,
} from "rsuite";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { Tooltip } from "antd";
import moment from "moment";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../../../../Components/Columns/Col2";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import { message, DatePicker } from "antd";
import axios from "axios";
import baseURL from "../../../../../../../Static/baseURL/baseURL";

import locationtypelist from "../../../../../../../Static/Lists/LocationType";
import JDInput from "./JDInput";
import userStore from "../../../../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

const { RangePicker } = DatePicker;

class NewJobDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      date: moment().startOf("day").add(1, "day"),
      dateto: moment().startOf("day").add(1, "day"),
      type: "Packing",
      status: "Pending",
      remark: "",
      from: "",
      fromtype: "",
      to: "",
      totype: "",
      load: false,
    };
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
    this.editVal = this.editVal.bind(this);
  }

  postNew = async (newdata) => {
    this.props.changeLoad(true);
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let data = {
      date: moment(newdata.date),
      dateto: moment(newdata.dateto),
      status: newdata.status,
      type: newdata.type,
      from: newdata.from,
      fromtype: newdata.fromtype,
      to: newdata.to,
      totype: newdata.totype,
      remark: newdata.remark,
      sj: sj._id,
    };

    await instance
      .post(`additional/jobdates/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ load: false, show: false });
        sjStore.newList("jobdate", res.data, ind);
        message.success(`Date Added.`);
        this.props.changeLoad(false);
      })
      .catch((err) => {
        this.setState({ load: false });
        this.props.changeLoad(false);
        Notification["error"]({ title: `An error occured` });
      });
  };
  resetData() {
    this.setState({
      date: moment().startOf("day").add(1, "day"),
      dateto: moment().startOf("day").add(1, "day"),
      type: "Packing",
      newremark: "",
      from: "",
      fromtype: "",
      to: "",
      totype: "",
      load: false,
    });
  }

  onHide(val) {
    this.resetData();
    this.setState({ show: val });
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }

  render() {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let disabled = false;
    let datetypes = ["Packing", "Moving", "Delivery"];

    if (sj.jobtype === "ST") {
      datetypes = ["Storage In", "Storage Out"];
    }

    if (this.state.status === "") disabled = true;
    if (this.state.type === "") disabled = true;
    if (sj.jobtype !== "ST") {
      if (this.state.fromtype === "") disabled = true;
      if (this.state.from === "") disabled = true;

      if (this.state.type !== "Packing") {
        if (this.state.to === "") disabled = true;
        if (this.state.totype === "") disabled = true;
      }
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title="New Job Date"
          onHide={this.onHide}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide(false)}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  block
                  icon={<Icon icon="save" />}
                  color="green"
                  disabled={disabled}
                  onClick={() => {
                    let data = {
                      date: this.state.date,
                      dateto: this.state.dateto,
                      type: this.state.type,
                      status: this.state.status,
                      remark: this.state.remark,
                      from: this.state.from,
                      fromtype: this.state.fromtype,
                      to: this.state.to,
                      totype: this.state.totype,
                    };
                    this.postNew(data);
                  }}
                >
                  Create New Job Date
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid>
            <Col md={24} sm={24} xs={24}>
              <InputGroup>
                {sj.jobtype === "ST" ? (
                  <DatePicker
                    style={{ width: "100%" }}
                    //format={"DD-MMM-YY @ HA"}
                    format={"DD-MMM-YY"}
                    value={moment(this.state.date)}
                    allowClear={false}
                    showMinute={false}
                    onChange={(date) => {
                      this.editVal(date, "date");
                      this.editVal(date, "dateto");
                    }}
                  />
                ) : (
                  <RangePicker
                    style={{ width: "100%" }}
                    //format={"DD-MMM-YY @ HA"}
                    format={"DD-MMM-YY"}
                    value={[moment(this.state.date), moment(this.state.dateto)]}
                    allowClear={false}
                    showMinute={false}
                    onChange={(date) => {
                      this.editVal(date[0], "date");
                      this.editVal(date[1], "dateto");
                    }}
                  />
                )}
              </InputGroup>
            </Col>
            <Col2>
              <JDInput
                type="Select"
                label="type"
                title="Type"
                size="sm"
                editVal={this.editVal}
                opts={datetypes}
                val={this.state.type}
              />
            </Col2>
            <Col2>
              <JDInput
                type="Select"
                label="status"
                title="Status"
                size="sm"
                editVal={this.editVal}
                opts={["Pending", "Complete"]}
                val={this.state.status}
              />
            </Col2>
            <Col2>
              {sj.jobtype !== "ST" && (
                <JDInput
                  type="Select"
                  label="fromtype"
                  title="From"
                  size="sm"
                  editVal={this.editVal}
                  opts={locationtypelist}
                  val={this.state.fromtype}
                />
              )}
            </Col2>
            <Col2>
              {(this.state.type !== "Packing") & (sj.jobtype !== "ST") ? (
                <JDInput
                  type="Select"
                  label="totype"
                  title="To"
                  size="sm"
                  editVal={this.editVal}
                  opts={locationtypelist}
                  val={this.state.totype}
                />
              ) : (
                <div />
              )}
            </Col2>
            <Col2>
              {sj.jobtype !== "ST" && (
                <JDInput
                  type="TextArea"
                  label="from"
                  title="From"
                  size="sm"
                  editVal={this.editVal}
                  val={this.state.from}
                />
              )}
            </Col2>
            <Col2>
              {(this.state.type !== "Packing") & (sj.jobtype !== "ST") ? (
                <JDInput
                  type="TextArea"
                  label="to"
                  title="To"
                  size="sm"
                  editVal={this.editVal}
                  val={this.state.to}
                />
              ) : (
                <div />
              )}
            </Col2>
          </FlexboxGrid>
        </UnifiedModal>
        <Div14>
          <FlexboxGrid justify="center">
            <Tooltip title="Add New Date">
              <IconButton
                icon={<Icon icon="plus" />}
                size="sm"
                appearance={"ghost"}
                color="green"
                onClick={() => {
                  this.onHide(true);
                }}
              >
                Add New Date
              </IconButton>
            </Tooltip>
          </FlexboxGrid>
        </Div14>
      </div>
    );
  }
}

export default observer(NewJobDate);
