import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import emSignature from "../../Pictures/signature.png";
import moment from "moment";
import FixedPerTypePDF from "./CustomPDFs/FixedPerTypePDF";
import ItemsTable from "./CustomPDFs/ItemsTable";
import { Buffer } from "buffer";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableCol100blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableLeft: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol1blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableCol3blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol4blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol1white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableCol3white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol4white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  table10blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  table70white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
});
class DelHeaderSection extends Component {
  render() {
    let del = this.props.del;
    let to = this.props.to;
    let client = this.props.client;

    let src = to.delSignature.signature;

    const b64 = new Buffer(src).toString("base64");
    let imagesrc = `data:image/png;base64,${b64}`;

    let type = this.props.type;

    let deliveryNumId = del.deliveryNumId;
    let clientname = client.name;

    let proposaltitle = `${type} - Delivery Note for ${clientname} - Order #${deliveryNumId}`;

    let uniqueadd;
    if (del.maintype === "Fixed Per Type") {
      uniqueadd = (
        <FixedPerTypePDF
          del={del}
          to={to}
          showall={this.props.type === "Pickup"}
        />
      );
    } else {
      if (this.props.type === "Pickup") {
        uniqueadd = (
          <View>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "Times-Roman",
              }}
            >
              Below is to confirm that the Below Services were Picked Up from
              the Client for Order ID# {deliveryNumId} on{" "}
              {moment(to.delSignature.date).format("DD-MMM-YYYY HH:mm A")}.
            </Text>
            <Text> </Text>
            {del.toaddress.map((to, index) => (
              <View key={index}>
                <Text style={styles.tableCol100blue}>
                  Picked Up Items for Location #{index + 1} ({to.city})
                </Text>
                <ItemsTable del={del} to={to} />
                <Text> </Text>
              </View>
            ))}
          </View>
        );
      } else {
        uniqueadd = (
          <View>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "Times-Roman",
              }}
            >
              Below is to confirm that the Below Services were Delivered to the
              Client for Order ID# {deliveryNumId} on{" "}
              {moment(to.delSignature.date).format("DD-MMM-YYYY HH:mm A")}.
            </Text>
            <Text> </Text>
            <View>
              <Text style={styles.tableCol100blue}>
                Picked Up Items for Location #{to.order} ({to.city})
              </Text>
              <ItemsTable del={del} to={to} />
              <Text> </Text>
            </View>
          </View>
        );
      }
    }

    return (
      <View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Date: {moment(to.delSignature.date).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>Order ID: {deliveryNumId}</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>info@easymovekw.com</Text>
          <Text style={styles.tableRight}>
            Del ID: DEL-{deliveryNumId}-
            {type === "Pickup" ? "Pickup" : to.order}
          </Text>
        </View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text style={styles.header}>{proposaltitle}</Text>

        <Text style={{ lineHeight: 1 }}> </Text>
        {uniqueadd}
        <View style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
            Delivery Remarks:
          </Text>
          <View style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
              {to.delSignature.remark}
            </Text>
          </View>
        </View>
        <View style={{ height: 20 }} />
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          By signing both parties confirm the delivery of the above mentioned
          items with the conditions specified. Two copies should be distributed
          to the Delivery and Receiving party.
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Image src={emSignature} style={{ width: 100 }} />
          <Text style={{ width: "50%" }} />
          <Image src={imagesrc} style={{ width: 100 }} />
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Times-Roman",
              width: "70%",
            }}
          >
            Easy Move Logistics Co.
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Times-Roman",
              width: "30%",
            }}
          >
            {type === "Pickup" ? "Pickup" : "Receiving"} Party:{" "}
            {to.delSignature.name}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "70%",
              fontSize: 9,
              fontFamily: "Times-Roman",
            }}
          >
            Date: {moment(to.delSignature.date).format("DD-MMM-YYYY")}
          </Text>
          <Text
            style={{
              width: "30%",
              fontSize: 9,
              fontFamily: "Times-Roman",
            }}
          >
            Date: {moment(to.delSignature.date).format("DD-MMM-YYYY HH:mm A")}
          </Text>
        </View>
      </View>
    );
  }
}

export default observer(DelHeaderSection);
