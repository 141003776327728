import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import moment from "moment";
import axios from "axios";
import { message } from "antd";
import MaterialTable from "material-table";
import baseURL from "../../../Static/baseURL/baseURL";
import singleEquipmentStore from "../../../Stores/EquipmentStores/SingleEquipmentStore";
import userStore from "../../../Stores/UserStore";

import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";

import Col3 from "../../../Components/Columns/Col3";
import NumbInputSTD from "../../../Components/Inputs/STD/NumbInputSTD";
import DatePickerSTD from "../../../Components/Inputs/STD/DatePickerSTD";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import TextAreaInputSTD from "../../../Components/Inputs/STD/TextAreaInputSTD";

const instance = axios.create({ baseURL: baseURL });

class ServiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownew: false,
      showedit: false,
      showdelete: false,

      equipmentServiceNumId: "",
      serviceid: "",
      date: moment(),
      title: "",
      truckid: "",
      servicedetails: "",
      driver: "",
      quoteinvoicenumber: "",
      company: "",
      price: 0,
      kilometer: 0,
      nextservicekm: 0,
      nextservicedate: undefined,
      loading: false,
    };
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  componentDidMount() {
    singleEquipmentStore.getEquipmentService(this.props.match.params.equipid);
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  postDelete() {
    let data = {};
    let url = `/hr/equipmentservice/delete/${this.state.serviceid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleEquipmentStore.getEquipmentService(
          this.props.match.params.equipid,
          true
        );
        message.success("Deleted Service Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Delete Service Record");
      });
  }
  postEdit() {
    let data = {
      date: this.state.date,
      title: this.state.title,
      servicedetails: this.state.servicedetails,
      driver: this.state.driver,
      quoteinvoicenumber: this.state.quoteinvoicenumber,
      company: this.state.company,
      price: this.state.price,
      kilometer: this.state.kilometer,
      nextservicekm: this.state.nextservicekm,
      nextservicedate: this.state.nextservicedate,
    };
    let url = `/hr/equipmentservice/edit/${this.state.serviceid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleEquipmentStore.getEquipmentService(
          this.props.match.params.equipid,
          true
        );
        message.success("Updated Service Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Update Service Record");
      });
  }
  postNew() {
    let data = {
      equipment: singleEquipmentStore.equipment._id,
      date: this.state.date,
      title: this.state.title,
      servicedetails: this.state.servicedetails,
      driver: this.state.driver,
      quoteinvoicenumber: this.state.quoteinvoicenumber,
      company: this.state.company,
      price: this.state.price,
      kilometer: this.state.kilometer,
      nextservicekm: this.state.nextservicekm,
      nextservicedate: this.state.nextservicedate,
    };
    let url = "/hr/equipmentservice/new";
    this.setState({ loading: true });
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleEquipmentStore.getEquipmentService(
          this.props.match.params.equipid,
          true
        );
        message.success("Created New Service Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Create New Service Record");
      });
  }
  onHide() {
    this.setState({
      shownew: false,
      showedit: false,
      showdelete: false,
      equipmentServiceNumId: "",
      serviceid: "",
      date: moment(),
      title: "",
      truckid: "",
      servicedetails: "",
      driver: "",
      quoteinvoicenumber: "",
      company: "",
      price: 0,
      kilometer: 0,
      nextservicekm: 0,
      nextservicedate: undefined,
      loading: false,
    });
  }

  render() {
    let servicebuttons = (
      <IconButton
        icon={<Icon icon="plus" />}
        size="xs"
        onClick={() => this.setState({ shownew: true })}
      >
        Add New Service Record
      </IconButton>
    );

    let disabled = false;
    if (this.state.title === "") disabled = true;
    if (this.state.driver === "") disabled = true;
    if (this.state.servicedetails === "") disabled = true;
    if (this.state.company === "") disabled = true;

    let truck = singleEquipmentStore.equipment;

    let columnnames = [
      {
        title: "ID",
        field: "equipmentServiceNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Type", field: "type", removable: true },
      { title: "Plate", field: "license", removable: true },
      {
        title: "Date",
        field: "date",
        removable: true,
        render: (rowData) => (
          <div>{moment(rowData.date).format("DD-MMM-YY")}</div>
        ),
      },
      { title: "Title", field: "title", removable: true },
      { title: "KM", field: "kilometer", removable: true },
      { title: "Driver", field: "driver", removable: true },
      { title: "Details", field: "servicedetails", removable: true },
      { title: "Company", field: "company", removable: true },
      { title: "Price", field: "price", removable: true },
      { title: "Next Service (KM)", field: "nextservicekm", removable: true },
      {
        title: "Next Service (Date)",
        field: "nextservicedate",
        removable: true,
        render: (rowData) => (
          <div>{moment(rowData.nextservicedate).format("DD-MMM-YY")}</div>
        ),
      },
    ];
    let tableTitle = "Service Records";
    let data = singleEquipmentStore.service;
    let modalheader = (
      <FlexboxGrid>
        <Col3>
          <b>Type: </b> {truck.type}{" "}
        </Col3>
        <Col3>
          <b>Plate: </b> {truck.license}
        </Col3>
        <Col3>
          <b>Remarks: </b> {truck.remark}
        </Col3>
      </FlexboxGrid>
    );
    let modalcontent = (
      <>
        {modalheader}
        <br />
        <DatePickerSTD
          title="Date"
          val={this.state.date}
          label="date"
          editVal={this.editVal}
        />
        <br />
        <TextInputSTD
          title="Service Title"
          val={this.state.title}
          label="title"
          editVal={this.editVal}
        />
        <br />
        <TextInputSTD
          title="Driver"
          val={this.state.driver}
          label="driver"
          editVal={this.editVal}
        />
        <br />
        <NumbInputSTD
          title="Kilometer"
          val={this.state.kilometer}
          label="kilometer"
          editVal={this.editVal}
        />
        <br />
        <TextAreaInputSTD
          title="Service Details"
          val={this.state.servicedetails}
          label="servicedetails"
          editVal={this.editVal}
        />
        <br />
        <TextInputSTD
          title="Service Company"
          val={this.state.company}
          label="company"
          editVal={this.editVal}
        />
        <br />
        <TextInputSTD
          title="Quote / Invoice"
          val={this.state.quoteinvoicenumber}
          label="quoteinvoicenumber"
          editVal={this.editVal}
        />
        <br />
        <NumbInputSTD
          title="Price"
          val={this.state.price}
          label="price"
          editVal={this.editVal}
        />
        <br />
        {truck.type !== "Generator" && (
          <>
            <NumbInputSTD
              title="Next Service KM"
              val={this.state.nextservicekm}
              label="nextservicekm"
              editVal={this.editVal}
            />
            <br />
          </>
        )}
        <IconButton
          icon={
            <Icon
              icon={
                this.state.nextservicedate === undefined ? "eye-slash" : "eye"
              }
            />
          }
          size="xs"
          onClick={() => {
            if (this.state.nextservicedate === undefined) {
              this.setState({ nextservicedate: moment() });
            } else {
              this.setState({ nextservicedate: undefined });
            }
          }}
          color={this.state.nextservicedate === undefined ? "red" : "green"}
        >
          {this.state.nextservicedate === undefined ? "Hiding" : "Showiung"}{" "}
          Next Service Date
        </IconButton>
        <br />

        {this.state.nextservicedate !== undefined && (
          <DatePickerSTD
            title="Next Service Date"
            val={this.state.nextservicedate}
            label="nextservicedate"
            editVal={this.editVal}
          />
        )}
        <br />
      </>
    );

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          title={`Delete Service Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Delete Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postDelete()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <h3>Are you sure you want to delete this record?</h3>
          <h5>Service ID Number (#{this.state.equipmentServiceNumId}):</h5>
          <FlexboxGrid>
            <Col3>
              <b>Date: </b>
              {moment(this.state.date).format("DD-MMM-YY")}
            </Col3>
            <Col3>
              <b>Title: </b> {this.state.title}
            </Col3>
            <Col3>
              <b>Price: </b> {this.state.price}
            </Col3>
          </FlexboxGrid>
          <b>Details: </b> {this.state.servicedetails}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.shownew}
          title={`Add New Service Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New"]}
              oC1={() => this.onHide()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          {modalcontent}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showedit}
          title={`Edit Service Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Update Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postEdit()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          {modalcontent}
        </UnifiedModal>
        <FlexboxGrid justify="space-around">{servicebuttons}</FlexboxGrid>
        <hr />
        <div style={{ padding: "1%" }}>
          <MaterialTable
            isLoading={singleEquipmentStore.loading.service}
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Service Record",
                onClick: (event, rowData) => {
                  this.setState({
                    showedit: true,

                    date: rowData.date,
                    title: rowData.title,
                    servicedetails: rowData.servicedetails,
                    driver: rowData.driver,
                    quoteinvoicenumber: rowData.quoteinvoicenumber,
                    company: rowData.company,
                    price: rowData.price,
                    kilometer: rowData.kilometer,
                    nextservicekm: rowData.nextservicekm,
                    nextservicedate: rowData.nextservicedate,
                    truckid: rowData.equipment._id,
                    serviceid: rowData._id,
                    loading: false,
                  });
                },
              },
              {
                icon: "delete",
                tooltip: "Delete Service Record",
                onClick: (event, rowData) => {
                  this.setState({
                    showdelete: true,

                    date: rowData.date,
                    title: rowData.title,
                    servicedetails: rowData.servicedetails,
                    driver: rowData.driver,
                    quoteinvoicenumber: rowData.quoteinvoicenumber,
                    company: rowData.company,
                    price: rowData.price,
                    kilometer: rowData.kilometer,
                    nextservicekm: rowData.nextservicekm,
                    nextservicedate: rowData.nextservicedate,

                    serviceid: rowData._id,
                    truckid: rowData.equipment._id,
                    loading: false,
                  });
                },
              },
              {
                icon: "refresh",
                tooltip: "Refresh",
                isFreeAction: true,
                onClick: (event, rowData) => {
                  singleEquipmentStore.getEquipmentService(
                    this.props.match.params.equipid,
                    true
                  );
                },
              },
            ]}
          />
        </div>
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(ServiceTable));
