import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import AttachmentTemplate from "../../Attachments/Attachment/AttachmentTemplate";

class ApprovalDocs extends Component {
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let quality = this.props.quality;
    let initdocs = [
      "Bill of Lading",
      "Packing List",
      "Certificate of Origin",
      "Iqrar Report",
      "Commercial Invoice",
    ];
    let appdocs = [
      "Invoice Delivery",
      "Final Bayan",
      "Driver Paper",
      "Delivery Note",
    ];
    let optdocs = [
      "Certificate of Conformity",
      "Technical Inspection Report",
      "EPA / Other",
      "PAI",
    ];
    let initdocrows = initdocs.map((doc, index) => (
      <Col2 key={index}>
        <AttachmentTemplate
          ind={ind}
          attType={doc}
          quality={quality}
          editview={editview}
        />
      </Col2>
    ));
    let appdocrows = appdocs.map((doc, index) => (
      <Col2 key={index}>
        <AttachmentTemplate
          ind={ind}
          attType={doc}
          quality={quality}
          editview={editview}
        />
      </Col2>
    ));
    let optdocrows = optdocs.map((doc, index) => (
      <Col2 key={index}>
        <AttachmentTemplate ind={ind} attType={doc} editview={editview} />
      </Col2>
    ));
    return (
      <div>
        <Div14>Initial Documents</Div14>
        <FlexboxGrid>{initdocrows}</FlexboxGrid>
        <Div14>Approval Documents</Div14>
        <FlexboxGrid>{appdocrows}</FlexboxGrid>
        <Div14>Optional Documents</Div14>
        <FlexboxGrid>{optdocrows}</FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default observer(ApprovalDocs);
