import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../../../Components/Columns/Col2";
import Col3 from "../../../../../../../Components/Columns/Col3";
import NumbInputSTD from "../../../../../../../Components/Inputs/STD/NumbInputSTD";
import TextInputSTD from "../../../../../../../Components/Inputs/STD/TextInputSTD";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";

class AddChargeModal extends Component {
  render() {
    let showEdit = this.props.showEdit;
    let showDelete = this.props.showDelete;
    let type = this.props.type;
    let editedCharge = this.props.editedCharge;
    let loading = this.props.loading;
    let disabled = this.props.disabled;

    let title = "Add New Additional Charge";
    if (type === "Edit") title = "Edit Existing Add Charge";
    if (type === "Delete") title = "Delete Add Charge";

    let cancel = (
      <IconButton
        icon={<Icon icon="close" />}
        block
        onClick={() => this.props.showHideModal(false)}
        disabled={loading}
      >
        Cancel
      </IconButton>
    );
    let save = (
      <IconButton
        icon={<Icon icon="save" />}
        color="green"
        block
        onClick={() => {
          if (type === "Edit") {
            this.props.postEdit(editedCharge);
          } else if (type === "New") {
            this.props.postNew(editedCharge);
          }
        }}
        disabled={loading || disabled}
      >
        {type === "Edit" ? "Save Changes" : "Create New Add Charge"}
      </IconButton>
    );
    if (type === "Delete") {
      save = (
        <IconButton
          icon={<Icon icon="save" />}
          color="red"
          block
          onClick={() => this.props.deleteCharge(editedCharge)}
          disabled={loading}
        >
          Yes, Delete
        </IconButton>
      );
    }
    let body = (
      <FlexboxGrid>
        <Col2>
          <TextInputSTD
            title="Name"
            editVal={this.props.editVal}
            val={editedCharge.name}
            label="name"
            disabled={type === "Delete"}
          />
        </Col2>
        <Col2>
          <NumbInputSTD
            title="Price (KD)"
            editVal={this.props.editVal}
            val={editedCharge.price}
            min={-100000}
            label="price"
            disabled={type === "Delete"}
          />
          {editedCharge.price < 0 && (
            <HelpBlock style={{ color: "red", fontWeight: "bold" }}>
              This is a Discount: Since the value is less than 0.
            </HelpBlock>
          )}
        </Col2>
      </FlexboxGrid>
    );

    return (
      <div>
        <UnifiedModal
          title={title}
          show={showEdit || showDelete}
          onHide={this.props.showHideModal}
          footer={
            <FlexboxGrid>
              <Col3>{cancel}</Col3>
              <Col3>{save}</Col3>
            </FlexboxGrid>
          }
        >
          {body}
        </UnifiedModal>
      </div>
    );
  }
}

export default observer(AddChargeModal);
