import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";

import { SJProposals } from "../../Functions/JobFinances/SJProposals";
import singleJobStore from "../SingleJobStore";

const instance = axios.create({ baseURL: baseURL });

class SJStore {
  constructor() {
    this.sjs = [];
    this.esjs = [];
    this.loading = false;
    this.dateload = false;
    this.itemsloaded = false;
    this.jobid = "";

    makeObservable(this, {
      sjs: observable,
      esjs: observable,
      loading: observable,
      dateload: observable,
      itemsloaded: observable,
      activeSJs: computed,
      recProposals: computed,
    });
  }

  // FETCH
  getSJs(jobId, override) {
    if (!override) {
      if (jobId === this.jobId || this.loading || this.dataloaded) return null;
    }
    this.loading = true;
    return instance
      .get(`/job/sidejobs/sjs/getjob/${jobId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((sjs) => {
        this.jobid = jobId;
        this.sjs = sjs;

        this.esjs = [...sjs];
        this.loading = false;
        this.dataloaded = true;
        console.log("Side Jobs Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  // ----- List ----
  changeList(label, item, itemind, i, sj) {
    if (sj) {
      this.esjs[i][sj][label][itemind] = item;
      this.sjs[i][sj][label][itemind] = item;
    } else {
      this.esjs[i][label][itemind] = item;
      this.sjs[i][label][itemind] = item;
    }
  }
  editList(label, item, itemind, i, sj) {
    this.esjs[i][label][itemind] = item;
  }
  removeList(label, itemind, i, sj) {
    if (sj) {
      let oldList = [...this.sjs[i][sj][label]];
      oldList.splice(itemind, 1);
      this.sjs[i][sj][label] = [...oldList];
      this.esjs[i][sj][label] = [...oldList];
    } else {
      let oldList = [...this.sjs[i][label]];
      oldList.splice(itemind, 1);
      this.sjs[i][label] = [...oldList];
      this.esjs[i][label] = [...oldList];
    }
  }
  newList(label, item, i, sj) {
    let newlist;
    if (sj) {
      let newlist = [...this.sjs[i][sj][label]];
      newlist.push(item);
      this.sjs[i][sj][label] = [...newlist];
      this.esjs[i][sj][label] = [...newlist];
    } else {
      newlist = [...this.sjs[i][label]];
      newlist.push(item);
      this.sjs[i][label] = [...newlist];
      this.esjs[i][label] = [...newlist];
    }
  }

  bulkAddList(label, items, i) {
    let newlist = [...this.sjs[i][label]];
    for (let j = 0; j < items.length; j++) {
      newlist.push(items[j]);
    }
    this.sjs[i][label] = [...newlist];
    this.esjs[i][label] = [...newlist];
  }
  // ----- Item in List ----
  editItem(label, itemlabel, item, itemind, i) {
    this.esjs[i][label][itemind][itemlabel] = item;
  }
  changeItem(label, itemlabel, item, itemind, i) {
    this.esjs[i][label][itemind][itemlabel] = item;
    this.sjs[i][label][itemind][itemlabel] = item;
  }
  // ------- SERVICES ------
  selectService(type, action, ser, i) {
    let incsjs = [...this.esjs[i].incservices];
    let excsjs = [...this.esjs[i].excservices];

    let serIndex = -1;
    if (type === "Include") {
      if (action === "add") {
        incsjs.push(ser);
        let excIndex = excsjs.findIndex((exc) => exc === ser);
        if (excIndex > -1) {
          excsjs.splice(excIndex, 1);
        }
      } else {
        serIndex = incsjs.findIndex((inc) => inc === ser);
        incsjs.splice(serIndex, 1);
      }
    } else {
      if (action === "add") {
        excsjs.push(ser);
        let incIndex = incsjs.findIndex((inc) => inc === ser);
        if (incIndex > -1) {
          incsjs.splice(incIndex, 1);
        }
      } else {
        serIndex = excsjs.findIndex((inc) => inc === ser);
        excsjs.splice(serIndex, 1);
      }
    }

    this.esjs[i].excservices = [...excsjs];
    this.esjs[i].incservices = [...incsjs];
  }

  replaceServices(index) {
    this.sjs[index].incservices = [...this.esjs[index].incservices];
    this.sjs[index].excservices = [...this.esjs[index].excservices];
  }

  get activeSJs() {
    return this.sjs.filter((sj) => sj.status !== "Cancelled");
  }
  get recProposals() {
    return SJProposals(this.activeSJs, singleJobStore.job);
  }

  // ---- Main Job ---
  eEditJob(label, val, i) {
    this.esjs[i][label] = val;
  }
  esjEditJob(label, val, i, sj) {
    this.esjs[i][sj][label] = val;
  }
  eMainJob(label, val, i, sj) {
    if (sj) {
      this.sjs[i][sj][label] = val;
    } else {
      this.sjs[i][label] = val;
    }
  }

  startLoading() {
    this.loading = true;
  }
}

const sjStore = new SJStore();
export default sjStore;
