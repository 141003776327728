import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  FilterRow,
  LoadPanel,
  Toolbar,
  Item,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { Tooltip } from "antd";

import moment from "moment";
import singleAccountStore from "../../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import { Parsed } from "../../../../../../../../Functions/Pricing/PRKDCalc";
import acStorStatStore from "../../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";

class MonthlyUnmovedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemdetails: false,
      quantitychange: false,
      totals: true,
      permonth: true,
      lastinout: true,
    };
  }
  componentDidMount() {}

  LastIn(cellData) {
    let data = cellData.data;
    return `${moment(data.lastin).format("DD-MMM-YY")} (${moment(
      data.lastin
    ).fromNow(true)})`;
  }
  LastOut(cellData) {
    let data = cellData.data;
    if (data.lastout) {
      return `${moment(data.lastout).format("DD-MMM-YY")} (${moment(
        data.lastout
      ).fromNow(true)})`;
    } else {
      return `No Outs (${moment(data.lastin).fromNow(true)})`;
    }
  }
  StagnantPrice(cellData) {
    let data = cellData.data;
    let storcont = singleAccountStore.storCont;
    let output =
      Parsed(moment().diff(data.lastout, storcont.timecode)) *
      Parsed(storcont.pricepercbm);

    return output;
  }

  render() {
    let data = acStorStatStore.monthlyoverview.items;

    return (
      <div>
        <DataGrid
          dataSource={data}
          keyExpr="acItemNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <LoadPanel enabled={true} />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column caption="Item" alignment={"center"}>
            <Column
              dataField="acItemNumId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="name"
              dataType="text"
              caption="Item Name"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="currentplusreserve"
              dataType="number"
              caption="Current Stock"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              minWidth={100}
            />
          </Column>

          <Column caption="Item Details" alignment={"center"}>
            <Column
              dataField="serialnumber"
              caption="Serial"
              alignment="center"
              minWidth={80}
            />

            <Column
              dataField="productid"
              caption="Prod. ID"
              alignment="center"
              minWidth={80}
            />

            <Column
              dataField="cbm"
              caption="CBM"
              alignment="center"
              minWidth={80}
              format={{ type: "fixedPoint", precision: 3 }}
            />
          </Column>

          <Column caption="Last In / Out" alignment={"center"}>
            <Column
              dataField="lastin"
              dataType="date"
              caption="Last In"
              alignment="center"
              cellRender={this.LastIn}
              minWidth={100}
            />
            <Column
              dataField="lastout"
              dataType="date"
              caption="Last Out"
              alignment="center"
              cellRender={this.LastOut}
              minWidth={100}
            />
            <Column
              dataField="daysstagnant"
              dataType="number"
              caption="Days Stagnant"
              alignment="center"
              minWidth={100}
            />
            <Column
              dataField="stagnantpriceperitem"
              dataType="number"
              caption="Stagnant Cost Per Item"
              alignment="center"
              minWidth={100}
              format={{ type: "fixedPoint", precision: 3 }}
            />
            <Column
              dataField="stagnantprice"
              dataType="number"
              caption="Stagnant Cost"
              alignment="center"
              minWidth={100}
              format={{ type: "fixedPoint", precision: 3 }}
            />
          </Column>

          <Summary>
            <TotalItem
              column="name"
              summaryType="count"
              valueFormat={"#,##0"}
              displayFormat={"{0}"}
            />
            <TotalItem
              column="currentplusreserve"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0}"}
            />
            <TotalItem
              column="in"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="out"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="injob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="outjob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="totaljob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="totalqty"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="totalcbm"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="pertimejob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="pertimeqty"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="pertimecbm"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="stagnantprice"
              summaryType="sum"
              displayFormat={"{0} KD"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="cbm"
              summaryType="avg"
              displayFormat={"Avg: {0} CBM"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="priceperitem"
              summaryType="avg"
              displayFormat={"Avg: {0} KD"}
              valueFormat={"#,##0.000"}
            />
          </Summary>
          <Export enabled={true} />

          <Toolbar>
            <Tooltip title={`Choose Columns`} arrow placement="left">
              <Item name="columnChooserButton" />
            </Tooltip>
            <Item name="exportButton" />
          </Toolbar>
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(MonthlyUnmovedItems));
