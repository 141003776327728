import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import emSignature from "../../Pictures/signature.png";
import moment from "moment";
import { Buffer } from "buffer";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol1blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableCol3blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol4blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol1white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableCol3white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol4white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
});

class ToAddressItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let toadd = this.props.toadd;
    let toadds = this.props.sto.toaddress;
    let startindex = 0;
    let node = this.props.index;
    for (let i = 0; i < node; i++) {
      startindex += toadds[i].acitemmove.length;
    }

    return toadd.acitemmove.map((itemmove, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol1white}>{startindex + index + 1}</Text>
        <Text style={styles.tableCol2white}>{itemmove.acitem.name}</Text>
        <Text style={styles.tableCol3white}>
          {itemmove.acitem.serialnumber}
        </Text>
        <Text style={styles.tableCol4white}>{itemmove.actualquantity}</Text>
        <Text style={styles.tableCol5white}>{itemmove.acitem.acItemNumId}</Text>
      </View>
    ));
  }
}

class HeaderSection extends Component {
  render() {
    let sto = this.props.sto;
    let client = this.props.client;

    let src = sto.storSignature.signature;

    const b64 = new Buffer(src).toString("base64");
    let imagesrc = `data:image/png;base64,${b64}`;

    let type;
    if (sto.jobtype === "Storage In" || sto.jobtype === "Delivery In")
      type = "In";
    if (sto.jobtype === "Storage Out" || sto.jobtype === "Delivery Out")
      type = "Out";

    let acOrderId = sto.acOrderNumId;
    let clientname = client.name;

    let proposaltitle = `${type} - Storage Note for ${clientname} - Order #${acOrderId}`;

    let itemslist = sto.toaddress.map((to, index) => (
      <ToAddressItems toadd={to} key={index} sto={sto} index={index} />
    ));

    return (
      <View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Date: {moment(sto.jobdate).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>Order ID: {acOrderId}</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>info@easymovekw.com</Text>
        </View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text style={styles.header}>{proposaltitle}</Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Below is to confirm that the Items{" "}
          {type === "In" ? "Entered into" : "Removed from"} the{" "}
          {sto.emlocation.name} for Order ID#{acOrderId} on{" "}
          {moment(sto.jobdate).format("DD-MMM-YYYY")}.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol1blue}>#</Text>
          <Text style={styles.tableCol2blue}>Item</Text>
          <Text style={styles.tableCol3blue}>Serial No.</Text>
          <Text style={styles.tableCol4blue}>Quantity</Text>
          <Text style={styles.tableCol5blue}>Item ID</Text>
        </View>
        {itemslist}
        <View style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
            Storage Remarks:
          </Text>
          <View style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
              {sto.storSignature.remark}
            </Text>
          </View>
        </View>
        <View style={{ height: 20 }} />
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          By signing both parties confirm the items{" "}
          {type === "In" ? "entered" : "exited"} the storage facility. Two
          copies should be distributed to the Delivery and Receiving party.
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Image src={emSignature} style={{ width: 100 }} />
          <Text style={{ width: "50%" }} />
          <Image src={imagesrc} style={{ width: 100 }} />
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Times-Roman",
              width: "70%",
            }}
          >
            Easy Move Logistics Co.
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Times-Roman",
              width: "30%",
            }}
          >
            {type === "In" ? "Delivering" : "Receiving"} Party:{" "}
            {sto.storSignature.name}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "70%",
              fontSize: 9,
              fontFamily: "Times-Roman",
            }}
          >
            Date: {moment(sto.jobdate).format("DD-MMM-YYYY")}
          </Text>
          <Text
            style={{
              width: "30%",
              fontSize: 9,
              fontFamily: "Times-Roman",
            }}
          >
            Date:{" "}
            {moment(sto.storSignature.createdAt).format("DD-MMM-YYYY HH:mm A")}
          </Text>
        </View>
      </View>
    );
  }
}

export default observer(HeaderSection);
