import React, { Component } from "react";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import axios from "axios";

import clientStore from "../../../../Stores/ClientStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { FlexboxGrid, Icon, IconButton, Col } from "rsuite";
import statuslist from "../../../../Static/Lists/StatusList";
import jobTypeList from "../../../../Static/Lists/JobTypeList";
import SelectInputs from "../Inputs/SelectInputs";
import NumberInputAntd from "../Inputs/NumberInputAntd";
import lmtypes from "../../../../Static/Lists/jobsubtypes/lmtypes";
import imtypes from "../../../../Static/Lists/jobsubtypes/imtypes";
import sttypes from "../../../../Static/Lists/jobsubtypes/sttypes";
import cctypes from "../../../../Static/Lists/jobsubtypes/cctypes";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { Notification } from "rsuite";
import ParagraphLoader from "../../../../Components/Loaders/ParagraphLoader";
import commoditytypes from "../../../../Static/Lists/jobsubtypes/imlists/commoditytypes";
import { SJPriceCalc } from "../../../../Functions/Pricing/SJPriceCalc";
import { DarkColorCalc } from "../../../../Functions/Colors/DarkColorCalc";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import singleJobStore from "../../../../Stores/SingleJobStore";
import sjStore from "../../../../Stores/SideJobs/SJStore";

const instance = axios.create({ baseURL: baseURL });

class ClientsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobs: false,
      showNewJob: false,
      remarks: "",
      jobtype: "Local Move",
      jobsubtype: "Full Service",
      jobstatus: "Lead",
      jobcbm: 0,
      loading: false,
      direction: "Inbound",
      shippingmode: "Sea",
      commodity: "Personsal Effect",
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }

  changeVal(type, val) {
    if (val === "remarks") {
      this.setState({ [val]: type });
    } else {
      this.setState({ [type]: val });
      if (type === "jobtype") {
        if (val === "Local Move") this.setState({ jobsubtype: lmtypes[0] });
        if (val === "International Move")
          this.setState({ jobsubtype: imtypes[0] });
        if (val === "Storage") this.setState({ jobsubtype: sttypes[0] });
        if (val === "Custom Clearance")
          this.setState({ jobsubtype: cctypes[0] });
      }
    }
  }

  showHideModal(val) {
    if (val) {
      this.setState({ showJobs: val });
    } else {
      this.setState({
        showJobs: false,
        jobtype: "Local Move",
        jobsubtype: "Full Service",
        jobstatus: "Lead",
        jobcbm: 0,
        showNewJob: false,
      });
    }
  }

  postNewJob = async () => {
    let jobtype = "LM";
    if (this.state.jobtype === "International Move") {
      jobtype = "IM";
    } else if (this.state.jobtype === "Custom Clearance") {
      jobtype = "CC";
    } else if (this.state.jobtype === "Storage") {
      jobtype = "ST";
    }
    let newjobdata = {
      client: clientStore.selectedClient._id,
      remarks: this.state.jobsubtype,
      jobtype: jobtype,
      subtype: this.state.jobsubtype,
      status: this.state.jobstatus,
      cbm: this.state.jobcbm,
      direction: this.state.direction,
      shippingmode: this.state.shippingmode,
      commodity: this.state.commodity,
    };
    this.changeVal("loading", true);
    let url = "/job/sidejobs/sjs/newjob";

    return instance
      .post(url, newjobdata, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.changeVal("loading", false);
        this.showHideModal(false);
        Notification["success"]({
          title: `Successfully Added New Job.`,
          description: (
            <div>
              SubJob {res.data.jobtype} Added (ID# {res.data.sjNumId})
            </div>
          ),
        });
        this.props.history.push(`/singlejob/${res.data.job}`);
        singleJobStore.getJob(res.data.job, true);
        sjStore.getSJs(res.data.job, true);

        this.setState({ showNewJob: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    if (clientStore.loading) {
      return <ParagraphLoader />;
    }

    let data = clientStore.filtClients;
    let jobs = clientStore.clientJobs;

    let tableTitle = "Clients List";

    let columnnames = [
      {
        title: "ID",
        field: "clientNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Name", field: "name", removable: true },
      { title: "Type", field: "type", removable: true },
      { title: "Email", field: "email", removable: true },
      {
        title: "Phone",
        field: "phone",
        removable: true,
        render: (rowData) => {
          return (
            <p>
              {rowData.phone2 === ""
                ? rowData.phone
                : rowData.phone + " / " + rowData.phone2}
            </p>
          );
        },
      },
    ];
    let jobColumns = [
      { title: "ID", field: "jobNumId", defaultSort: "asc" },
      { title: "Focal Point", field: "focalpoint.username" },

      {
        title: "SJs",
        field: "sjs",
        render: (rowData) => {
          // let sjsfilt = rowData.sjs.filter((sj) => sj.status !== "Cancelled");
          let sjsfilt = rowData.sjs;
          let sjs = sjsfilt.map((sj) => (
            <div
              style={{ color: DarkColorCalc(sj.status), fontWeight: "bold" }}
            >
              * {sj.jobtype} ({sj.status})
            </div>
          ));
          return (
            <div>
              <b>{sjsfilt.length} Sub Jobs: </b>
              {sjs}
            </div>
          );
        },
      },
      {
        title: "Job Price",
        field: "sjs",
        render: (rowData) => {
          let sjs = rowData.sjs.filter((sj) => sj.status !== "Cancelled");
          let price = SJPriceCalc(sjs);
          return `${price} KD`;
        },
      },
      {
        title: "Invoices",
        field: "sjs",
        render: (rowData) => {
          let inv = rowData.invoices.filter(
            (inv) => inv.status !== "Cancelled"
          );
          let total = 0;
          for (let i = 0; i < inv.length; i++) {
            total += parseFloat(inv[i].total);
          }

          return `${total} KD`;
        },
      },
      {
        title: "Payments",
        field: "sjs",
        render: (rowData) => {
          let pay = rowData.payments.filter((pay) => pay.type === "Income");
          let total = 0;
          for (let i = 0; i < pay.length; i++) {
            total += parseFloat(pay[i].amount);
          }
          return `${total} KD`;
        },
      },
    ];

    let subtypelist = lmtypes;
    if (this.state.jobtype === "Local Move") subtypelist = lmtypes;
    if (this.state.jobtype === "International Move") subtypelist = imtypes;
    if (this.state.jobtype === "Storage") subtypelist = sttypes;
    if (this.state.jobtype === "Custom Clearance") subtypelist = cctypes;

    return (
      <div>
        <UnifiedModal
          show={this.state.showJobs}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  {this.state.showNewJob && (
                    <IconButton
                      icon={<Icon icon="close" />}
                      color="red"
                      block
                      onClick={() => this.setState({ showNewJob: false })}
                    >
                      Go Back to Job List
                    </IconButton>
                  )}
                </Col>
                <Col md={8} sm={12} xs={24}>
                  {this.state.showNewJob ? (
                    <IconButton
                      icon={<Icon icon="plus" />}
                      color="green"
                      block
                      disabled={
                        (this.state.jobtype !== "Custom Clearance") &
                        (this.state.jobcbm === null)
                      }
                      onClick={() => this.postNewJob()}
                    >
                      Create New Job
                    </IconButton>
                  ) : (
                    <IconButton
                      icon={<Icon icon="plus" />}
                      color="green"
                      block
                      onClick={() => this.setState({ showNewJob: true })}
                    >
                      Add a New Job
                    </IconButton>
                  )}
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h4>
            <b>
              <i> {clientStore.selectedClient.clientNumId}</i>
            </b>{" "}
            - {clientStore.selectedClient.name}
          </h4>

          {this.state.showNewJob ? (
            <div>
              <hr />
              <h5>Add a New Job</h5>
              <FlexboxGrid>
                <Col md={6} sm={12} xs={24}>
                  <SelectInputs
                    options={jobTypeList}
                    val={this.state.jobtype}
                    label="Job Type"
                    type={"jobtype"}
                    changeVal={this.changeVal}
                  />
                </Col>
                {this.state.jobtype === "International Move" && (
                  <Col md={6} sm={12} xs={24}>
                    <SelectInputs
                      options={["Air", "Land", "Sea"]}
                      val={this.state.shippingmode}
                      label="Shipping Method"
                      type={"shippingmode"}
                      changeVal={this.changeVal}
                    />
                  </Col>
                )}
                {this.state.jobtype === "International Move" && (
                  <Col md={6} sm={12} xs={24}>
                    <SelectInputs
                      options={commoditytypes}
                      val={this.state.commodity}
                      label="Commodity"
                      type={"commodity"}
                      changeVal={this.changeVal}
                    />
                  </Col>
                )}
                {this.state.jobtype === "International Move" && (
                  <Col md={6} sm={12} xs={24}>
                    <SelectInputs
                      options={["Inbound", "Outbound"]}
                      val={this.state.direction}
                      label="Direction"
                      type={"direction"}
                      changeVal={this.changeVal}
                    />
                  </Col>
                )}
                <Col md={6} sm={12} xs={24}>
                  <SelectInputs
                    options={subtypelist}
                    val={this.state.jobsubtype}
                    label="Sub Type"
                    type={"jobsubtype"}
                    changeVal={this.changeVal}
                  />
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <SelectInputs
                    options={statuslist}
                    val={this.state.jobstatus}
                    label="Status"
                    type={"jobstatus"}
                    changeVal={this.changeVal}
                  />
                </Col>
                <Col md={6} sm={12} xs={24}>
                  {this.state.jobtype !== "Custom Clearance" ? (
                    <NumberInputAntd
                      label="CBM"
                      type="jobcbm"
                      val={this.state.jobcbm}
                      changeVal={this.changeVal}
                    />
                  ) : (
                    <SelectInputs
                      options={["Air", "Land", "Sea"]}
                      val={this.state.shippingmode}
                      label="Shipping Method"
                      type={"shippingmode"}
                      changeVal={this.changeVal}
                    />
                  )}
                </Col>
              </FlexboxGrid>
              <br />
              <FlexboxGrid>
                <Col md={24} sm={24} xs={24}>
                  <TextAreaInputSTD
                    title="Remarks"
                    val={this.state.remarks}
                    label="remarks"
                    editVal={this.changeVal}
                  />
                </Col>
              </FlexboxGrid>
            </div>
          ) : (
            <MaterialTable
              title={`${clientStore.clientJobs.length} Jobs`}
              columns={jobColumns}
              style={{ fontSize: 12 }}
              data={jobs}
              onRowClick={async (event, rowData, togglePanel) => {
                this.props.history.push(`/singlejob/${rowData._id}`);
                singleJobStore.getJob(rowData._id, true);
                sjStore.getSJs(rowData._id, true);
              }}
              options={{
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: false,
                pageSizeOptions: [5, 10, 15, 20, 25],
                toolbarButtonAlignment: "left",
                maxBodyHeight: 1000,
                tableLayout: "auto",
                exportButton: true,
                exportFileName: `${
                  this.props.exportTitle ? this.props.exportTitle : tableTitle
                } as of ${moment(new Date()).format("DD-MMM-YY")}`,
                padding: "dense",
              }}
            />
          )}
        </UnifiedModal>

        {!clientStore.loading && (
          <MaterialTable
            isLoading={this.state.loading}
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            onRowClick={async (event, rowData, togglePanel) => {
              this.changeVal("loading", true);
              let successfulPull = await clientStore.getClientJobs(
                rowData,
                rowData.name,
                rowData.clientNumId
              );
              this.changeVal("loading", false);
              this.showHideModal(successfulPull);
            }}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100, 500],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
          />
        )}
        <br />
      </div>
    );
  }
}

export default withRouter(observer(ClientsTable));
