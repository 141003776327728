import React, { Component } from "react";
import moment from "moment";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import MaterialTable from "material-table";
import hrStore from "../../../../Stores/HR/HRStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";

import { DatePicker, Statistic } from "antd";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";

const instance = axios.create({ baseURL: baseURL });

const { RangePicker } = DatePicker;
class LeaveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      newhol: { staff: { remaining: 0 } },
      oldhol: { staff: { remaining: 0 } },
    };
    this.hideModal = this.hideModal.bind(this);
    this.updateHoliday = this.updateHoliday.bind(this);
    this.getHoliday = this.getHoliday.bind(this);
    this.editVal = this.editVal.bind(this);
  }

  editVal(val, label) {
    let newhol = { ...this.state.newhol };
    if (label === "date") {
      newhol.date = val[0];
      newhol.dateto = val[1];
    } else {
      newhol[label] = val;
    }
    this.setState({ newhol: newhol });
  }
  hideModal() {
    this.setState({ show: false, loading: false });
  }
  getHoliday = async (id) => {
    this.setState({ loading: true, show: true });
    let url = `/hr/holiday/holidaybyid/${id}`;

    return instance
      .get(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false, newhol: res.data, oldhol: res.data });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  updateHoliday = async (id) => {
    this.setState({ loading: true });
    let url = `/hr/holiday/edit/${id}`;

    let newhol = { ...this.state.newhol };
    newhol.days = moment(newhol.dateto).diff(newhol.date, "days") + 1;

    return instance
      .put(url, newhol, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHolidays(true);
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    let columnnames = [
      { title: "ID", field: "holidayNumId", defaultSort: "asc" },
      { title: "Status", field: "status" },
      { title: "Staff", field: "staff.name" },
      { title: "Role", field: "staff.type" },
      { title: "Type", field: "type" },
      {
        title: "From",
        field: "date",
        render: (row) => moment(row.date).format("DD-MMM-YY"),
      },
      {
        title: "To",
        field: "dateto",
        render: (row) => moment(row.dateto).format("DD-MMM-YY"),
      },
      { title: "Days", field: "days" },
      { title: "Remarks", field: "remark" },
    ];
    let data = this.props.data;
    let newhol = this.state.newhol;
    let holidaytypes = ["Planned Leave", "Unplanned Leave"];
    if (newhol.type === "Sick Leave") holidaytypes = ["Sick Leave"];

    let daysavailable = newhol.staff.remaining;
    let diff = moment(newhol.dateto).diff(newhol.date, "days") + 1;
    let remaining =
      parseFloat(daysavailable) - parseFloat(diff) + this.state.oldhol.days;

    if (newhol.status === "Reject") {
      remaining = parseFloat(daysavailable);
    }

    return (
      <div>
        <UnifiedModal
          title={"Edit Holiday"}
          show={this.state.show}
          onHide={this.hideModal}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["default", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Save Changes"]}
              oC1={() => this.hideModal()}
              oC2={() => this.updateHoliday(this.state.newhol._id)}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <LoadBack loading={this.state.loading}>
            <FlexboxGrid style={{ padding: "1%" }}>
              <Col2>
                <SelectInputSTD
                  title="Type"
                  val={newhol.type}
                  label="type"
                  size="large"
                  opts={holidaytypes}
                  editVal={this.editVal}
                />
              </Col2>
              <Col2>
                <TextInputSTD
                  title="Calc Days"
                  val={diff}
                  label="base"
                  size="large"
                  editVal={this.editVal}
                />
              </Col2>
            </FlexboxGrid>

            <FlexboxGrid style={{ padding: "1%" }}>
              <RangePicker
                style={{ width: "100%" }}
                //format={"DD-MMM-YY @ HA"}
                format={"DD-MMM-YY"}
                value={[moment(newhol.date), moment(newhol.dateto)]}
                allowClear={false}
                showMinute={false}
                onChange={(date) => {
                  this.editVal(date, "date");
                }}
              />
            </FlexboxGrid>
            <div style={{ padding: "1%" }}>
              <TextAreaInputSTD
                title="Remarks"
                val={newhol.remark}
                label="remark"
                size="large"
                editVal={this.editVal}
              />
            </div>
            {newhol.status === "Reject" ? (
              <h3 style={{ color: "red", textAlign: "center" }}>
                No Change in Employee Balance since the Holiday is Rejected
              </h3>
            ) : (
              <FlexboxGrid justify="space-around">
                <Statistic
                  title="Available Days"
                  value={daysavailable}
                  suffix="days"
                />
                <Statistic
                  title=" + "
                  value={"+"}
                  valueStyle={{ color: "green" }}
                />
                <Statistic
                  title="Old Holiday"
                  value={this.state.oldhol.days}
                  suffix="days"
                  valueStyle={{ color: "green" }}
                />
                <Statistic
                  title=" - "
                  value={"-"}
                  valueStyle={{ color: "red" }}
                />
                <Statistic
                  title="New Holiday"
                  value={diff}
                  suffix="days"
                  valueStyle={{ color: "red" }}
                />
                <Statistic
                  title=" = "
                  value={"="}
                  valueStyle={{ color: "red" }}
                />
                <Statistic
                  title="After Holiday"
                  value={remaining}
                  suffix="days"
                  valueStyle={{ color: remaining < 0 ? "red" : "green" }}
                />
              </FlexboxGrid>
            )}
          </LoadBack>
        </UnifiedModal>
        <MaterialTable
          title={data.length + " Holiday List"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: [10],
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : "Staff List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
            rowStyle: (rowData) => {
              let color = "#feffab";
              if (rowData.status === "Approve") color = "#c2ffbd";
              if (rowData.status === "Reject") color = "#ffaea8";
              return {
                backgroundColor: color,
              };
            },
          }}
          actions={[
            {
              icon: "add",
              isFreeAction: true,
              tooltip: "Add",
              onClick: (event, rowData) => {
                this.props.showHideModal(true, this.props.type);
              },
            },
            {
              icon: "refresh",
              isFreeAction: true,
              tooltip: "Refresh",
              onClick: (event, rowData) => {
                hrStore.getHolidays(true);
              },
            },
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.getHoliday(rowData._id);
              },
            }),
            (rowData) => ({
              icon: "close",
              tooltip: "Reject",
              hidden: rowData.status === "Reject" || !userStore.user.mgt,
              onClick: (event, rowData) => {
                this.props.showAppRej("Reject", rowData);
              },
            }),
            (rowData) => ({
              icon: "check",
              tooltip: "Approve",
              hidden: rowData.status === "Approve" || !userStore.user.mgt,
              onClick: (event, rowData) => {
                this.props.showAppRej("Approve", rowData);
              },
            }),
          ]}
        />
      </div>
    );
  }
}

export default withRouter(observer(LeaveList));
