import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import SinglePalletModal from "../SinglePalletModal/SinglePalletModal";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";

class WHMSingleCompanyPallet extends Component {
  constructor(props) {
    super(props);
    this.state = { pallet: { status: "", itempallet: [] }, show: false };
    this.onHide = this.onHide.bind(this);
    this.QTYColumn = this.QTYColumn.bind(this);
    this.CBMColumn = this.CBMColumn.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  onSelect(data) {
    if (data) {
      if (data.selectedRowsData) {
        if (data.selectedRowsData[0]) {
          whmSinglePalletStore.getPallet(data.selectedRowsData[0]._id);
          this.setState({ show: true, pallet: data.selectedRowsData[0] });
        }
      }
    }
  }
  onHide() {
    this.setState({ show: false, pallet: {} });
  }
  QTYColumn(cellData) {
    let data = cellData.data.qty;
    let qty = 0;
    if (data > 0) return PRKD(qty);
    return "-";
  }
  CBMColumn(cellData) {
    let data = cellData.data.cbm;
    let cbm = 0;
    if (data > 0) return PRKD(cbm);
    return "-";
  }
  render() {
    let data = this.props.data;

    return (
      <div>
        <SinglePalletModal
          show={this.state.show}
          onHide={this.onHide}
          actionbut={true}
          actionbuttext="Edit Pallet"
          onEdit={this.props.onEdit}
        />

        <DataGrid
          dataSource={data}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
          onSelectionChanged={this.onSelect}
        >
          <Selection mode="single" />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column caption="Actions" cellRender={this.ActionButs} width={100} />
          <Column
            dataField="whmPalletNumId"
            caption="ID"
            alignment="center"
            width={80}
          />
          <Column
            dataField="items"
            caption="Items"
            alignment="center"
            width={80}
          />
          <Column dataField="qty" caption="QTY" alignment="center" width={80} />
          <Column dataField="cbm" caption="CBM" alignment="center" width={80} />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="createdAt"
            caption="Created"
            alignment="center"
            width={100}
            dataType={"date"}
            format={"dd-MMM-yy HH:mm"}
          />
          <Column
            dataField="prepared"
            caption="Prepared"
            alignment="center"
            width={100}
            dataType={"date"}
            format={"dd-MMM-yy HH:mm"}
          />
          <Column
            dataField="loaded"
            caption="Loaded"
            alignment="center"
            width={100}
            dataType={"date"}
            format={"dd-MMM-yy HH:mm"}
          />
          <Column
            dataField="unloaded"
            caption="UnLoaded"
            alignment="center"
            width={100}
            dataType={"date"}
            format={"dd-MMM-yy HH:mm"}
          />
          <Column
            dataField="stacked"
            caption="Stacked"
            alignment="center"
            width={100}
            dataType={"date"}
            format={"dd-MMM-yy HH:mm"}
          />

          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(WHMSingleCompanyPallet));
