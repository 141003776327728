import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import moment from "moment";
import axios from "axios";
import { message } from "antd";
import MaterialTable from "material-table";
import baseURL from "../../../Static/baseURL/baseURL";
import singleEquipmentStore from "../../../Stores/EquipmentStores/SingleEquipmentStore";
import userStore from "../../../Stores/UserStore";
import { Parsed, PRKD } from "../../../Functions/Pricing/PRKDCalc";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import Col4 from "../../../Components/Columns/Col4";
import Col3 from "../../../Components/Columns/Col3";
import NumbInputSTD from "../../../Components/Inputs/STD/NumbInputSTD";
import DatePickerSTD from "../../../Components/Inputs/STD/DatePickerSTD";
import FuelGraph from "./Graphs/FuelGraph";

const instance = axios.create({ baseURL: baseURL });

class FuelTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownew: false,
      showedit: false,
      showdelete: false,

      fuelid: "",
      fuelNumId: "",
      kilometer: 0,
      liter: 0,
      price: 0,
      date: moment(),
      loading: false,
    };
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  componentDidMount() {
    singleEquipmentStore.getEquipmentFuel(this.props.match.params.equipid);
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  postDelete() {
    let data = {};
    let url = `/hr/equipmentfuel/delete/${this.state.fuelid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleEquipmentStore.getEquipmentFuel(
          this.props.match.params.equipid,
          true
        );
        message.success("Deleted Fuel Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Delete Fuel Record");
      });
  }
  postEdit() {
    let data = {
      kilometer: this.state.kilometer,
      liter: this.state.liter,
      price: this.state.price,
      date: this.state.date,
    };
    let url = `/hr/equipmentfuel/edit/${this.state.fuelid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleEquipmentStore.getEquipmentFuel(
          this.props.match.params.equipid,
          true
        );
        message.success("Updated Fuel Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Update Fuel Record");
      });
  }
  postNew() {
    let data = {
      equipment: singleEquipmentStore.equipment._id,
      kilometer: this.state.kilometer,
      liter: this.state.liter,
      price: this.state.price,
      date: this.state.date,
    };
    let url = "/hr/equipmentfuel/new";
    this.setState({ loading: true });
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleEquipmentStore.getEquipmentFuel(
          this.props.match.params.equipid,
          true
        );
        message.success("Created New Fuel Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Create New Fuel Record");
      });
  }
  onHide() {
    this.setState({
      shownew: false,
      showedit: false,
      showdelete: false,
      fuelid: "",
      kilometer: 0,
      liter: 0,
      price: 0,
      date: moment(),
      loading: false,
    });
  }

  render() {
    let fuelbuttons = (
      <IconButton
        icon={<Icon icon="plus" />}
        size="xs"
        onClick={() => this.setState({ shownew: true })}
      >
        Add New Fuel Record
      </IconButton>
    );

    let disabled = false;

    let truck = singleEquipmentStore.equipment;

    if (this.state.liter <= 0) disabled = true;
    if (this.state.price <= 0) disabled = true;

    let columnnames = [
      {
        title: "ID",
        field: "equipmentFuelNumId",
        removable: true,
      },
      {
        title: "Date",
        field: "date",
        removable: true,
        render: (rowData) => (
          <div>{moment(rowData.date).format("DD-MMM-YY")}</div>
        ),
        defaultSort: "desc",
      },
      { title: "KM", field: "kilometer", removable: true },
      { title: "Price", field: "price", removable: true },
      { title: "Liters", field: "liter", removable: true },
      {
        title: "Average KM/Lit",
        field: "avgkmperliter",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.avgkmperliter)}</div>,
      },
      {
        title: "KM/Lit",
        field: "kmperliter",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.kmperliter)}</div>,
      },
      {
        title: "KWD/Lit",
        field: "priceperliter",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.priceperliter)}</div>,
      },
      {
        title: "Distance",
        field: "distance",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.distance)}</div>,
      },
      {
        title: "Days Since",
        field: "duration",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.duration)}</div>,
      },
      {
        title: "Avg Days",
        field: "avgduration",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.avgduration)}</div>,
      },
      {
        title: "Total Price",
        field: "totalprice",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.totalprice)}</div>,
      },
      {
        title: "Cost Per Day",
        field: "priceperday",
        removable: true,
        render: (rowData) => <div>{PRKD(rowData.priceperday)}</div>,
      },
    ];
    let tableTitle = "Fuel Records";
    let data = singleEquipmentStore.fuel;

    let priceperliter = 0;
    if ((this.state.price > 0) & (this.state.liter > 0)) {
      priceperliter = PRKD(Parsed(this.state.price) / Parsed(this.state.liter));
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          title={`Delete Fuel Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Delete Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postDelete()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <h3>Are you sure you want to delete this record?</h3>
          <h5>Fuel ID Number (#{this.state.fuelNumId}):</h5>
          <FlexboxGrid>
            <Col4>
              <b>Date: </b>
              {moment(this.state.date).format("DD-MMM-YY")}
            </Col4>
            <Col4>
              <b>Kilometer: </b>
              {truck.kilometer}
            </Col4>
            <Col4>
              <b>Liters: </b>
              {truck.liter}
            </Col4>
            <Col4>
              <b>Price: </b>
              {truck.price}
            </Col4>
          </FlexboxGrid>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.shownew}
          title={`Add New Fuel Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New"]}
              oC1={() => this.onHide()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <FlexboxGrid>
            <Col3>
              <b>Type: </b>
              {truck.type}
            </Col3>
            <Col3>
              <b>Plate: </b>
              {truck.license}
            </Col3>
            <Col3>
              <b>Remarks: </b>
              {truck.remark}
            </Col3>
          </FlexboxGrid>
          <hr />
          {truck.type !== "Generator" && (
            <>
              <HelpBlock>Last Kilometer: {truck.lastfuel.kilometer}</HelpBlock>
              <NumbInputSTD
                title="Kilometers"
                val={this.state.kilometer}
                label="kilometer"
                editVal={this.editVal}
              />
              <HelpBlock>
                Difference:{" "}
                {PRKD(
                  Parsed(this.state.kilometer) -
                    Parsed(truck.lastfuel.kilometer)
                )}
              </HelpBlock>
              <br />
            </>
          )}
          <NumbInputSTD
            title="Liters"
            val={this.state.liter}
            label="liter"
            editVal={this.editVal}
          />
          <br />
          <HelpBlock>
            Last Price Per Liter:{" "}
            {PRKD(Parsed(truck.lastfuel.price) / Parsed(truck.lastfuel.liter))}
          </HelpBlock>
          <NumbInputSTD
            title="Price"
            val={this.state.price}
            label="price"
            editVal={this.editVal}
          />
          {priceperliter > 0 && (
            <HelpBlock style={{ fontStyle: "italic" }}>
              {priceperliter} KD / Liter
            </HelpBlock>
          )}
          <br />
          <DatePickerSTD
            title="Date"
            val={this.state.date}
            label="date"
            editVal={this.editVal}
          />
          <br />
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showedit}
          title={`Edit Fuel Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Update Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postEdit()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <FlexboxGrid>
            <Col3>
              <b>Type: </b>
              {truck.type}
            </Col3>
            <Col3>
              <b>Plate: </b>
              {truck.license}
            </Col3>
            <Col3>
              <b>Remarks: </b>
              {truck.remark}
            </Col3>
          </FlexboxGrid>
          <hr />
          {truck.type !== "Generator" && (
            <>
              <NumbInputSTD
                title="Kilometers"
                val={this.state.kilometer}
                label="kilometer"
                editVal={this.editVal}
              />
              <br />
            </>
          )}
          <NumbInputSTD
            title="Liters"
            val={this.state.liter}
            label="liter"
            editVal={this.editVal}
          />
          <br />
          <NumbInputSTD
            title="Price"
            val={this.state.price}
            label="price"
            editVal={this.editVal}
          />
          {priceperliter > 0 && (
            <HelpBlock style={{ fontStyle: "italic" }}>
              {priceperliter} KD / Liter
            </HelpBlock>
          )}
          <br />
          <DatePickerSTD
            title="Date"
            val={this.state.date}
            label="date"
            editVal={this.editVal}
          />
          <br />
        </UnifiedModal>
        <FlexboxGrid justify="space-around">{fuelbuttons}</FlexboxGrid>
        <hr />
        <div style={{ padding: "1%" }}>
          <MaterialTable
            isLoading={singleEquipmentStore.loading.fuel}
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Fuel Record",
                onClick: (event, rowData) => {
                  this.setState({
                    showedit: true,

                    fuelid: rowData._id,
                    truckid: rowData.equipment._id,
                    kilometer: rowData.kilometer,
                    liter: rowData.liter,
                    price: rowData.price,
                    date: rowData.date,
                    loading: false,
                  });
                },
              },
              {
                icon: "delete",
                tooltip: "Delete Fuel Record",
                onClick: (event, rowData) => {
                  this.setState({
                    showdelete: true,

                    fuelid: rowData._id,
                    fuelNumId: rowData.equipmentFuelNumId,
                    truckid: rowData.equipment._id,
                    kilometer: rowData.kilometer,
                    liter: rowData.liter,
                    price: rowData.price,
                    date: rowData.date,
                    loading: false,
                  });
                },
              },
              {
                icon: "refresh",
                tooltip: "Refresh",
                isFreeAction: true,
                onClick: (event, rowData) => {
                  singleEquipmentStore.getEquipmentFuel(
                    this.props.match.params.equipid,
                    true
                  );
                },
              },
            ]}
          />
        </div>
        <hr />
        <FuelGraph />
      </div>
    );
  }
}

export default withRouter(observer(FuelTable));
