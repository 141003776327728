import { message } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";

import userStore from "../../../../../../Stores/UserStore";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";

import Div14 from "../../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import Col2 from "../../../../../../Components/Columns/Col2";
import SelectInputSTD from "../../../../../../Components/Inputs/STD/SelectInputSTD";
import NumbInputSTD from "../../../../../../Components/Inputs/STD/NumbInputSTD";
import TextInputSTD from "../../../../../../Components/Inputs/STD/TextInputSTD";
import DatePickerSTD from "../../../../../../Components/Inputs/STD/DatePickerSTD";
import TextAreaInputSTD from "../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import PaymentTable from "./PaymentTable";
import accInvoiceStore from "../../../../../../Stores/Financial/ACCInvoiceStore";
import acClearanceStore from "../../../../../../Stores/AccountUpdated/ACC/ACClearanceStore";
import ACCChargeHeader from "../Charges/ACCChargeHeader";
import banklist from "../../../../../../Static/Lists/financial/banklist";
import paymethodlist from "../../../../../../Static/Lists/financial/paymethodlist";

const instance = axios.create({ baseURL: baseURL });

class PaymentMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,

      payment: {
        type: "Income",
        subtype: "Account Related",
        specific: "Customer Payment",
        cash: "Cash",
        date: moment(),
        method: "Bank Transfer",
        account: "KFH",
        paymentref: "",
        amount: 0,
        remarks: "",
        accounttype: "Custom Clearance Account",
        client: "",
      },
    };
    this.newPayment = this.newPayment.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
  }
  componentDidMount() {
    accInvoiceStore.getInvPayments(true);
  }
  postNew = async () => {
    this.setState({ loading: true });

    let data = { ...this.state.payment };

    let url = `/jf/payment/new`;

    await instance
      .post(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.onHide();
        message.success("New Payment Created");
        accInvoiceStore.getInvPayments(true);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  editVal(val, label) {
    let oldpayment = { ...this.state.payment };
    oldpayment[label] = val;
    this.setState({ payment: oldpayment });
  }
  onHide() {
    this.setState({ show: false, loading: false });
  }
  newPayment(type) {
    this.setState({
      show: true,

      payment: {
        type: "Income",
        subtype: "Account Related",
        specific: "Customer Payment",
        cash: "Cash",
        date: moment(),
        method: "Bank Transfer",
        account: "KFH",
        paymentref: "",
        amount: 0,
        remarks: "",
        accounttype: "Custom Clearance Account",
        client: acClearanceStore.client._id,
      },
    });
  }
  render() {
    let disabled = PRKD(this.state.payment.amount) <= 0;
    return (
      <LoadBack loading={accInvoiceStore.loading.invpayments}>
        <ACCChargeHeader hidedetails />
        <Div14>Payment Main</Div14>
        <UnifiedModal
          title="Create a New Custom Clearance Account Payment"
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New Payment"]}
              oC1={this.onHide}
              oC2={() => this.postNew()}
              disabled2={disabled}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Type"
                editVal={this.editVal}
                val={this.state.payment.type}
                label="type"
                size="large"
                opts={["Income"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Sub Type"
                editVal={this.editVal}
                val={this.state.payment.subtype}
                label="subtype"
                size="large"
                opts={["Account Related"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Specific"
                editVal={this.editVal}
                val={this.state.payment.specific}
                label="specific"
                size="large"
                opts={["Customer Payment"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Cash / Non-Cash"
                editVal={this.editVal}
                val={this.state.payment.cash}
                label="cash"
                size="large"
                opts={["Cash"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Account"
                editVal={this.editVal}
                val={this.state.payment.account}
                label="account"
                size="large"
                opts={banklist}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Method"
                editVal={this.editVal}
                val={this.state.payment.method}
                label="method"
                size="large"
                opts={paymethodlist}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Amount"
                editVal={this.editVal}
                val={this.state.payment.amount}
                label="amount"
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Payment Ref."
                editVal={this.editVal}
                val={this.state.payment.paymentref}
                label="paymentref"
              />
              <br />
            </Col2>
            <Col2>
              <DatePickerSTD
                title="Date"
                editVal={this.editVal}
                val={this.state.payment.date}
                label="date"
              />
              <br />
            </Col2>
            <TextAreaInputSTD
              title="Payment Remarks"
              editVal={this.editVal}
              val={this.state.payment.remarks}
              label="remarks"
            />
            <br />
          </FlexboxGrid>
        </UnifiedModal>
        <PaymentTable newPayment={this.newPayment} />
      </LoadBack>
    );
  }
}

export default withRouter(observer(PaymentMain));
