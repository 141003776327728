import { Paragraph } from "@rsuite/icons";
import React, { Component } from "react";
import { Modal } from "rsuite";
// import { Loader, Modal } from "rsuite";

class ParagraphLoader extends Component {
  render() {
    return (
      <Modal backdrop={"static"} open={true}>
        <Modal.Header>
          <Modal.Title>Loading... Please Wait</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Paragraph />
        </Modal.Body>
      </Modal>
    );

    // return (
    //   <Paragraph rows={8}>
    //     <Loader center size="lg" content="Loading..." />
    //   </Paragraph>
    // );
  }
}

export default ParagraphLoader;
