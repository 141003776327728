import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

import Accounts from "../Accordians/Accounts";

import StaffJobs from "../Accordians/StaffJobs";
import AdvStaffJobs from "../Accordians/AdvStaffJobs";
import userStore from "../../../../Stores/UserStore";
import TLTabs from "../Accordians/TLTabs";
import Warehouse from "../Accordians/Warehouse";

class EMstaffTabs extends Component {
  render() {
    return (
      <div>
        {userStore.user.jobs && (
          <StaffJobs open={this.props.open} hdc={this.props.hdc} />
        )}
        {userStore.user.jobs && (
          <AdvStaffJobs open={this.props.open} hdc={this.props.hdc} />
        )}
        {userStore.user.staffaccount && (
          <Accounts open={this.props.open} hdc={this.props.hdc} />
        )}
        {userStore.user.warehouse && (
          <Warehouse open={this.props.open} hdc={this.props.hdc} />
        )}
        {userStore.user.teamleader && (
          <TLTabs open={this.props.open} hdc={this.props.hdc} />
        )}
      </div>
    );
  }
}

export default withRouter(observer(EMstaffTabs));
