import React, { Component } from "react";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  MasterDetail,
  Item,
  Toolbar,
  LoadPanel,
} from "devextreme-react/data-grid";
import { observer } from "mobx-react";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import Button from "devextreme-react/button";
import { Tooltip } from "@material-ui/core";

import moment from "moment";
import axios from "axios";

import { Col, FlexboxGrid, Icon, IconButton, Notification } from "rsuite";

import ChargesTable from "./ChargesTable";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import userStore from "../../../../../../../Stores/UserStore";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import MainACInvoice from "../../../../../../../Components/PDFs/ACInvoices/MainACInvoice.js";
import ModalFooter from "../../../../../../../Components/Modal/ModalFooter";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../../../../Components/Inputs/STD/TextInputSTD";
import DatePickerSTD from "../../../../../../../Components/Inputs/STD/DatePickerSTD";
import TextAreaInputSTD from "../../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import Col3 from "../../../../../../../Components/Columns/Col3";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";

const instance = axios.create({ baseURL: baseURL });

class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoExpandAll: true,
      acinvoice: {},
      show: false,
      showedit: false,
      load: false,
    };
    this.onExporting = this.onExporting.bind(this);
    this.DownloadInvoice = this.DownloadInvoice.bind(this);
    this.StatusCell = this.StatusCell.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postUpdate = this.postUpdate.bind(this);
  }

  postUpdate = async () => {
    let data = {
      status: staffStorageStore.newacinvoice.status,
      title: staffStorageStore.newacinvoice.title,
      date: staffStorageStore.newacinvoice.date,
      remarks: staffStorageStore.newacinvoice.remarks,
    };

    this.setState({ load: true });
    let url = "";
    url = `jf/acinvoice/edit/${staffStorageStore.newacinvoice._id}`;

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.onHide();
        staffStorageStore.getAllInvoices();
        Notification["success"]({ title: `AC Invoice Updated` });
      })
      .catch((err) => {
        this.setState({ load: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  onHide() {
    this.setState({ show: false, showedit: false, acinvoice: {}, load: false });
  }
  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }

  DownloadInvoice(cellData) {
    return (
      <>
        <Tooltip title={`View Invoice`} arrow>
          <IconButton
            icon={<Icon icon="save" />}
            color="blue"
            circle
            size="xs"
            onClick={() =>
              this.setState({ show: true, acinvoice: cellData.data })
            }
          />
        </Tooltip>
        <span> </span>
        {userStore.user.jobs ? (
          <Tooltip title={`Edit Invoice`} arrow>
            <IconButton
              icon={<Icon icon="pencil" />}
              color="yellow"
              circle
              size="xs"
              onClick={() => {
                staffStorageStore.selectInvoice(cellData.data);
                this.setState({ showedit: true });
              }}
            />
          </Tooltip>
        ) : (
          <div />
        )}
      </>
    );
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `All_Client_Invoices_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  render() {
    let inv = staffStorageStore.newacinvoice;
    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
        }}
      >
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="AC Invoice PDF View"
          footer={null}
        >
          <MainACInvoice inv={this.state.acinvoice} />
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showedit}
          onHide={this.onHide}
          title={`Edit AC Invoice #${staffStorageStore.newacinvoice.acInvoiceNumId}`}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Save Changes"]}
              oC1={this.onHide}
              oC2={() => this.postUpdate()}
              loading1={this.state.load}
              loading2={this.state.load}
            />
          }
        >
          <Div14>Minor Changes:</Div14>
          <FlexboxGrid>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <SelectInputSTD
                title="Status"
                val={inv.status}
                label="status"
                size="large"
                opts={["Pending", "Paid", "Cancelled"]}
                editVal={staffStorageStore.editInvoice}
              />
            </Col>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <TextInputSTD
                title="Title"
                val={inv.title}
                label="title"
                editVal={staffStorageStore.editInvoice}
              />
            </Col>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <DatePickerSTD
                title="Invoice Date"
                editVal={staffStorageStore.editInvoice}
                val={inv.date}
                label="date"
              />
            </Col>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <TextAreaInputSTD
                title="Inv Remarks"
                val={inv.remarks}
                label="remarks"
                editVal={staffStorageStore.editInvoice}
              />
            </Col>
          </FlexboxGrid>
          <Div14>Major Changes:</Div14>
          <FlexboxGrid justify="center">
            <Col3>
              <IconButton
                icon={<Icon icon="edit" />}
                color="red"
                onClick={() => this.props.onEditClick(inv._id)}
                block
              >
                Make Major Changes
              </IconButton>
            </Col3>
          </FlexboxGrid>
          <hr />
        </UnifiedModal>
        <Div14>Storage Invoices</Div14>

        <LoadBack loading={staffStorageStore.loading.invoices}>
          <DataGrid
            dataSource={staffStorageStore.acinvoices}
            keyExpr="acInvoiceNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <LoadPanel enabled={true} />
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />
            <MasterDetail enabled={true} component={ChargesTable} />
            <Column
              dataField="acInvoiceNumId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="client.name"
              dataType="text"
              caption="Company"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="date"
              dataType="date"
              caption="Invoice Date"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
            />
            <Column
              dataField="title"
              dataType="text"
              caption="Invoice Title"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="status"
              caption="Status"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
              cellRender={this.StatusCell}
            />
            <Column
              dataField="totalstorage"
              dataType="number"
              caption="Storage Charges"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={80}
            />
            <Column
              dataField="totaldelivery"
              dataType="number"
              caption="Delivery Charges"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={80}
            />
            <Column
              dataField="totalcharges"
              dataType="number"
              caption="Charges"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={80}
            />
            <Column
              caption="Download Invoice"
              alignment="center"
              minWidth={80}
              cellRender={this.DownloadInvoice}
            />

            <Summary>
              <TotalItem
                column="acInvoiceNumId"
                summaryType="count"
                displayFormat={"{0}"}
              />
              <TotalItem
                column="totalstorage"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="totaldelivery"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="totalcharges"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Toolbar>
              <Item name="Refresh" location="before">
                <Button
                  icon="refresh"
                  onClick={() => staffStorageStore.getAllInvoices()}
                />
              </Item>

              <Tooltip title={`Choose Columns`} arrow placement="left">
                <Item name="columnChooserButton" />
              </Tooltip>
              <Item name="exportButton" />
              {userStore.user.jobs & !staffStorageStore.cancelledinvoices ? (
                <Item name="Show Cancelled" location="after">
                  <Tooltip title={`Show Cancelled`} arrow placement="left">
                    <IconButton
                      icon={<Icon icon="trash" />}
                      onClick={() => staffStorageStore.getCancelledInvoices()}
                      color="red"
                      circle
                    />
                  </Tooltip>
                </Item>
              ) : (
                <div />
              )}
            </Toolbar>
            <Export enabled={true} />
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default observer(InvoiceTable);
