function AttQualityCalc(data) {
  let errors = 0;
  let total = 1;
  let items = [];

  if (data.attachments.length > 0) {
    for (let i = 0; i < data.attachments.length; i++) {
      if (data.attachments[i].title === "Additional") {
        items.push(
          `Attachment #${data.attachments[i].attachmentNumId} Missing Title`
        );
      }
    }
  }
  total = data.attachments.length;
  errors = items.length;

  return [errors, total, items];
}

export { AttQualityCalc };
