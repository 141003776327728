import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import { FlexboxGrid, Icon, IconButton, Col, Button, InputGroup } from "rsuite";
import { Notification, Input } from "rsuite";
import { Select } from "antd";

import { Paper } from "@material-ui/core";
import imSupplierStore from "../../../Stores/AdditionalStores/IMSupplierStore";

import baseURL from "../../../Static/baseURL/baseURL";
import SupplierTable from "./SupplierTable";
import userStore from "../../../Stores/UserStore";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import allcountrieslist from "../../../Static/Lists/imlists/AllCountries";
import imsuppliertypes from "../../../Static/Lists/imlists/imsuppliertypes";
import LoadBack from "../../../Components/Loaders/LoadBack";
import Div14 from "../../../Components/Dividers/Div14";

const { Option } = Select;

const instance = axios.create({ baseURL: baseURL });

class IMSupplierMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      company: "",
      country: "Kuwait",
      city: "",
      type: "Freight Forwarder",
      contactname: "",
      contactnumber: "",
      contactemail: "",
      kuwaitoffice: "Yes",
      rating: "Good",
      organization: "None",
      remarks: "",
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }

  componentDidMount() {
    imSupplierStore.getSuppliers();
  }

  changeVal(type, val) {
    this.setState({ [type]: val });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        company: "",
        country: "Kuwait",
        city: "",
        type: "Freight Forwarder",
        contactname: "",
        contactnumber: "",
        contactemail: "",
        kuwaitoffice: "Yes",
        rating: "Good",
        organization: "None",
        remarks: "",
      });
    }
  }

  postNewSupplier = async () => {
    let newsupplier = {
      company: this.state.company,
      country: this.state.country,
      city: this.state.city,
      type: this.state.type,
      contactname: this.state.contactname,
      contactnumber: this.state.contactnumber,
      contactemail: this.state.contactemail,
      kuwaitoffice: this.state.kuwaitoffice,
      rating: this.state.rating,
      organization: this.state.organization,
      remarks: this.state.remarks,
    };
    this.changeVal("loading", true);
    let url = "/imadditional/supplier/new";

    return instance
      .post(url, newsupplier, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        imSupplierStore.getSuppliers();
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Added New Supplier ${newsupplier.company}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    let countryoptions = allcountrieslist.map((val, index) => (
      <Option value={val.name} key={index}>
        {val.name}
      </Option>
    ));
    let supplieroptions = imsuppliertypes.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));

    let disabled = false;
    if (this.state.company === "") disabled = true;
    if (this.state.city === "") disabled = true;
    if (this.state.contactname === "") disabled = true;
    if (this.state.contactnumber === "") disabled = true;
    if (this.state.contactemail === "") disabled = true;

    return (
      <LoadBack loading={imSupplierStore.loading}>
        <Div14>List of International Move Suppliers</Div14>

        <UnifiedModal
          show={this.state.show}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={disabled}
                    loading={this.state.loading}
                    onClick={() => this.postNewSupplier()}
                  >
                    Create Supplier
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h5>New Supplier Details</h5>
          <InputGroup>
            <InputGroup.Addon>Company</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.company}
              onChange={(e) => this.changeVal("company", e)}
            />
            {this.state.company === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Country</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.country}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("country", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countryoptions}
            </Select>
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>City</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.city}
              onChange={(e) => this.changeVal("city", e)}
            />
            {this.state.city === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Type</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.type}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("type", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {supplieroptions}
            </Select>
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Name</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.contactname}
              onChange={(e) => this.changeVal("contactname", e)}
            />
            {this.state.contactname === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Number</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.contactnumber}
              onChange={(e) => this.changeVal("contactnumber", e)}
            />
            {this.state.contactnumber === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Email</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.contactemail}
              onChange={(e) => this.changeVal("contactemail", e)}
            />
            {this.state.contactemail === "" && (
              <InputGroup.Addon>
                <Icon icon="close-circle" style={{ color: "red" }} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Kuwait Office</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.kuwaitoffice}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("kuwaitoffice", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"Yes"}> Yes </Option>
              <Option value={"No"}> No </Option>
            </Select>
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Rating</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.rating}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("rating", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"Good"}> Good </Option>
              <Option value={"Slow"}> Slow </Option>
              <Option value={"Expensive"}> Expensive </Option>
            </Select>
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Organization</InputGroup.Addon>
            <Select
              size="large"
              showSearch
              value={this.state.organization}
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={(e) => this.changeVal("organization", e)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"None"}> None </Option>
              <Option value={"ARA"}> ARA </Option>
              <Option value={"AMSA"}> AMSA </Option>
              <Option value={"FIATA"}> FIATA </Option>
              <Option value={"FIDI"}> FIDI </Option>
              <Option value={"IAM"}> IAM </Option>
              <Option value={"Other"}> Other </Option>
            </Select>
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>Remarks</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.remarks}
              onChange={(e) => this.changeVal("remarks", e)}
            />
          </InputGroup>
        </UnifiedModal>
        {imSupplierStore.suppliers.length > 0 && (
          <Paper style={{ margin: "1%" }}>
            <SupplierTable
              supplier={imSupplierStore.suppliers}
              exportTitle="IM Suppliers List"
            />
          </Paper>
        )}
        <FlexboxGrid justify="center">
          <Col md={6} sm={8} xs={24}>
            <Button
              color="green"
              onClick={() => this.showHideModal(true)}
              block
            >
              Add New Supplier
            </Button>
          </Col>
        </FlexboxGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(IMSupplierMain));
