import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Checkbox, CheckboxGroup, Col } from "rsuite";
import { FlexboxGrid, HelpBlock, Loader } from "rsuite";
import { InputGroup, InputNumber, Notification } from "rsuite";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import adUserStore from "../../../Stores/Admin/AdUserStore";
import { Empty, message, Select } from "antd";
import { IconButton, Icon } from "rsuite";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import Div14 from "../../../Components/Dividers/Div14";
import Col4 from "../../../Components/Columns/Col4";
import Col3 from "../../../Components/Columns/Col3";

import TextInputSTU from "../../../Components/Inputs/Updatable/TextInputSTU";
import { Tabs } from "antd";
import Col2 from "../../../Components/Columns/Col2";

const { TabPane } = Tabs;
const { Option } = Select;
const instance = axios.create({ baseURL: baseURL });

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "",
      show: false,
      loading: false,
      orguser: {
        group: "Client",
        company: null,
        jobs: false,
        mgt: false,
        hr: false,
        admin: false,
        finance: false,
        compadmin: false,
        comprequest: false,
        whmoveview: false,
        whmoveedit: false,
        companies: [],
      },
      user: {
        group: "Client",
        company: null,
        companies: [],
        jobs: false,
        mgt: false,
        hr: false,
        admin: false,
        finance: false,
        compadmin: false,
        comprequest: false,
        whmoveview: false,
        whmoveedit: false,
      },
      password: "",
      clientNumId: "",
      clientLoad: false,
      client: { name: "", _id: "" },
      updateLoad: false,
    };
    this.selectUser = this.selectUser.bind(this);
    this.editVal = this.editVal.bind(this);
    this.addremovecompany = this.addremovecompany.bind(this);
    this.editValFlip = this.editValFlip.bind(this);
    this.updateVal = this.updateVal.bind(this);
    this.updateSingleVal = this.updateSingleVal.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.sendResetPasswordLink = this.sendResetPasswordLink.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
  }
  componentDidMount() {
    adUserStore.getUsers();
  }

  sendResetPasswordLink = async () => {
    let data = { email: this.state.user.email };
    await instance
      .post(`auth/passreset/resetpassword`, data)
      .then((res) => {
        Notification["success"]({
          title: `Password Reset Link Sent`,
        });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `Please check your email is accurate.`,
        });
      });
  };

  selectUser(user) {
    this.setState({ user: user, orguser: user });
  }

  editClientId(type, val) {
    this.setState({ clientNumId: val });
  }

  checkClient() {
    let clientNumId = this.state.clientNumId;
    this.setState({ clientLoad: true, client: { name: "", _id: "" } });
    return instance
      .get(`/adstaff/user/client/byId/${clientNumId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((singleClient) => {
        if (singleClient) {
          this.setState({
            client: singleClient,
            clientLoad: false,
            clientNumId: "",
          });
        } else {
          this.setState({
            client: { name: "No Client Found", _id: "" },
            clientNumId: "",
            clientLoad: false,
          });
        }
        console.log("Client Fetched");
      })
      .catch((err) => {
        this.setState({ clientLoad: false });
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Client.`,
        });
      });
  }

  editVal(type, val) {
    let data = { ...this.state.user };
    data[type] = val;
    this.setState({ user: data });
  }
  addremovecompany(type, val) {
    let data = { ...this.state.user };
    if (type === "Add") {
      let replace = true;
      if (data.companies) {
        if (data.companies.length > 0) {
          replace = false;
        }
      }

      if (replace) {
        data.companies = [val];
      } else {
        data.companies = [...data.companies, val];
      }
    } else {
      data.companies.filter((dt) => dt.clientNumId !== val.clientNumId);
    }
    this.setState({ user: data });
  }
  editValFlip(val, type) {
    if (type === "password") {
      this.setState({ password: val });
    } else {
      let data = { ...this.state.user };
      data[type] = val;
      this.setState({ user: data });
    }
  }

  updatePassword = async () => {
    this.setState({ updateLoad: true });
    let data = { password: this.state.password };

    await instance
      .put(`auth/passreset/updatepassbyuserid/${this.state.user._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ password: "" });
        message.success(`Password Updated`);
      })
      .catch((err) => {
        this.setState({ loadmainjob: false, loadfocalpoint: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  updateSingleVal = async (label) => {
    this.setState({ updateLoad: true });
    let user = this.state.user;
    let data = { [label]: user[label] };

    await instance
      .put(`adstaff/user/edit/${this.state.user._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        let orguser = { ...this.state.orguser };
        orguser[label] = this.state.user[label];
        this.setState({ orguser: orguser });
        message.success(`User Details Updated. (${label})`);
      })
      .catch((err) => {
        this.setState({ loadmainjob: false, loadfocalpoint: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  updateVal = async () => {
    this.setState({ updateLoad: true });
    let user = this.state.user;
    let data = {
      group: user.group,
      company: user.company,
      admin: user.admin,
      jobs: user.jobs,
      mgt: user.mgt,
      hr: user.hr,
      operhead: user.operhead,
      finance: user.finance,
      compadmin: user.compadmin,
      comprequest: user.comprequest,
      warehouse: user.warehouse,
      customclearance: user.customclearance,
      teamleader: user.teamleader,
      staffaccount: user.staffaccount,
      delivery: user.delivery,
      whmoveview: user.whmoveview,
      whmoveedit: user.whmoveedit,
    };

    await instance
      .put(`adstaff/user/edit/${this.state.user._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        adUserStore.editMainUser(this.state.user);
        message.success(`User Details Updated.`);
        this.showHideModal(false);
      })
      .catch((err) => {
        this.setState({ loadmainjob: false, loadfocalpoint: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        loading: false,
        password: "",
        user: {
          group: "Client",
          jobs: false,
          mgt: false,
          admin: false,
          finance: false,
          companies: [],
        },
      });
    }
  }

  render() {
    if (adUserStore.loading) {
      return <Loader center size="lg" />;
    }

    let users = adUserStore.filtusers;

    let columnnames = [
      { title: "User ID", field: "userNumId" },
      { title: "Username", field: "username", defaultSort: "asc" },
      { title: "Group", field: "group" },
      { title: "Email", field: "email" },
      { title: "Phone", field: "phone" },
      { title: "Company", field: "company.name" },
      {
        title: "Last Sign In",
        field: "lastsignin",
        render: (rowData) => {
          return moment(rowData.lastsignin).fromNow();
        },
      },
      {
        title: "Created",
        field: "createdAt",
        render: (rowData) => {
          return moment(rowData.createdAt).format("DD-MMM-YY");
        },
      },
      {
        title: "Edit Jobs",
        field: "jobs",
        render: (rowData) => {
          let check = rowData.jobs;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Finance",
        field: "finance",
        render: (rowData) => {
          let check = rowData.finance;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Mgt",
        field: "mgt",
        render: (rowData) => {
          let check = rowData.mgt;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Staff HR",
        field: "hr",
        render: (rowData) => {
          let check = rowData.hr;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Admin",
        field: "admin",
        render: (rowData) => {
          let check = rowData.admin;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Company Admin",
        field: "compadmin",
        render: (rowData) => {
          let check = rowData.compadmin;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Company Request",
        field: "comprequest",
        render: (rowData) => {
          let check = rowData.comprequest;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Warehouse",
        field: "warehouse",
        render: (rowData) => {
          let check = rowData.warehouse;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Custom Clearance",
        field: "customclearance",
        render: (rowData) => {
          let check = rowData.customclearance;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Team Leader",
        field: "teamleader",
        render: (rowData) => {
          let check = rowData.teamleader;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Staff Account",
        field: "staffaccount",
        render: (rowData) => {
          let check = rowData.staffaccount;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Delivery",
        field: "delivery",
        render: (rowData) => {
          let check = rowData.delivery;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
    ];

    let grouptypes = ["Client", "Company", "EMStaff"];

    let options = grouptypes.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));

    let clientDetails = (
      <HelpBlock style={{ color: "red" }}>
        Please search with a Client ID above.
      </HelpBlock>
    );
    let addcompanydetails = (
      <HelpBlock style={{ color: "red" }}>
        Please search with a Client ID above.
      </HelpBlock>
    );

    let assignClientBut = (
      <IconButton
        block
        color="green"
        icon={<Icon icon="check" />}
        onClick={() => this.editVal("company", this.state.client)}
      >
        Assign Client
      </IconButton>
    );

    let compindex = false;
    if (this.state.user.companies) {
      if (this.state.user.companies.length > 0) {
        let findcomp = this.state.user.companies.findIndex(
          (comp) => comp.clientNumId === this.state.client.clientNumId
        );
        if (findcomp >= 0) compindex = true;
      }
    }

    let addClient = (
      <IconButton
        block
        color="green"
        icon={<Icon icon="check" />}
        onClick={() => this.addremovecompany("Add", this.state.client)}
      >
        Add Company
      </IconButton>
    );
    if (compindex) {
      addClient = (
        <IconButton block color="red" icon={<Icon icon="check" />}>
          Already Added
        </IconButton>
      );
    }

    let selectedClient;
    let allcompanies;

    let filts = adUserStore.filter;

    if (this.state.user.company) {
      selectedClient = (
        <div
          style={{
            borderRadius: 10,
            border: "1px solid black",
            boxShadow: "1px 1px 5px black",
            padding: "1%",
            margin: "1%",
          }}
        >
          <Div14>
            Selected Company (ID# {this.state.user.company.clientNumId})
          </Div14>
          <b>Name: </b>
          {this.state.user.company.name}
          <br />
          <b>Phone: </b>
          {this.state.user.company.phone}
          <br />
          <b>Phone 2: </b>
          {this.state.user.company.phone2}
          <br />
          <b>Email: </b>
          {this.state.user.company.email}
        </div>
      );
    }
    allcompanies = <Empty description="No Companies Selected" />;

    if (this.state.user.companies) {
      if (this.state.user.companies.length > 0) {
        allcompanies = (
          <FlexboxGrid>
            {this.state.user.companies.map((comp, index) => (
              <>
                <Col2 key={index}>
                  {comp.clientNumId} - {comp.name}
                </Col2>
                <Col2>
                  <IconButton icon={<Icon icon="trash" />} block color="red" />
                </Col2>
              </>
            ))}
          </FlexboxGrid>
        );
      }
    }

    if (this.state.user.company) {
      if (this.state.user.company._id) {
        if (this.state.user.company._id === this.state.client._id) {
          assignClientBut = (
            <IconButton block icon={<Icon icon="check-circle" />} disabled>
              Already Assigned
            </IconButton>
          );
        }
      }
    }

    if (this.state.client.name !== "") {
      if (this.state.client.name === "No Client Found") {
        clientDetails = (
          <HelpBlock style={{ color: "red" }}>
            No Client Found. Please make sure of the Client ID and try again.
          </HelpBlock>
        );
      } else {
        clientDetails = (
          <div
            style={{
              borderRadius: 10,
              border: "1px solid black",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <FlexboxGrid justify="start" align="middle">
              <Col md={4} sm={4} xs={4}>
                {assignClientBut}
              </Col>
              <Col md={20} sm={20} xs={20}>
                <b>Client ID: </b>
                {this.state.client.clientNumId}
                <br />
                <b>Name: </b>
                {this.state.client.name}
                <br />
                <b>Phone: </b>
                {this.state.client.phone}
                <br />
                <b>Phone 2: </b>
                {this.state.client.phone2}
                <br />
                <b>Email: </b>
                {this.state.client.email}
              </Col>
            </FlexboxGrid>
          </div>
        );
      }
    }

    if (this.state.client.name !== "") {
      if (this.state.client.name === "No Client Found") {
        addcompanydetails = (
          <HelpBlock style={{ color: "red" }}>
            No Client Found. Please make sure of the Client ID and try again.
          </HelpBlock>
        );
      } else {
        addcompanydetails = (
          <div
            style={{
              borderRadius: 10,
              border: "1px solid black",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <FlexboxGrid justify="start" align="middle">
              <Col md={4} sm={4} xs={4}>
                {addClient}
              </Col>
              <Col md={20} sm={20} xs={20}>
                <b>Client ID: </b>
                {this.state.client.clientNumId}
                <br />
                <b>Name: </b>
                {this.state.client.name}
                <br />
                <b>Phone: </b>
                {this.state.client.phone}
                <br />
                <b>Phone 2: </b>
                {this.state.client.phone2}
                <br />
                <b>Email: </b>
                {this.state.client.email}
              </Col>
            </FlexboxGrid>
          </div>
        );
      }
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title="Edit User Details"
          onHide={this.showHideModal}
          footer={
            <FlexboxGrid>
              <Col md={12} sm={12} xs={24}>
                <IconButton
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => {
                    this.showHideModal(false);
                  }}
                >
                  Cancel
                </IconButton>
              </Col>
              <Col md={12} sm={12} xs={24}>
                <IconButton
                  block
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.updateVal();
                  }}
                >
                  Save
                </IconButton>
              </Col>
            </FlexboxGrid>
          }
        >
          <h6>User Type:</h6>
          <Select
            size="large"
            showSearch
            value={this.state.user.group}
            style={{ width: "100%" }}
            placeholder={this.props.label}
            optionFilterProp="children"
            onChange={(val) => this.editVal("group", val)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options}
          </Select>
          <br />
          <TextInputSTU
            val={this.state.user.username}
            org={this.state.orguser.username}
            label="username"
            title="Username"
            size="sm"
            editVal={this.editValFlip}
            updateVal={this.updateSingleVal}
          />
          <TextInputSTU
            val={this.state.user.email}
            org={this.state.orguser.email}
            label="email"
            title="Email"
            size="sm"
            editVal={this.editValFlip}
            updateVal={this.updateSingleVal}
          />
          <TextInputSTU
            val={this.state.user.phone}
            org={this.state.orguser.phone}
            label="phone"
            title="Phone"
            size="sm"
            editVal={this.editValFlip}
            updateVal={this.updateSingleVal}
          />
          <CheckboxGroup>
            <FlexboxGrid>
              <Col4>
                <Div14>EM Staff</Div14>
                <Checkbox
                  checked={this.state.user.jobs}
                  onClick={() => this.editVal("jobs", !this.state.user.jobs)}
                >
                  Edit Jobs
                </Checkbox>
                <Checkbox
                  checked={this.state.user.staffaccount}
                  onClick={() =>
                    this.editVal("staffaccount", !this.state.user.staffaccount)
                  }
                >
                  Staff Accounts
                </Checkbox>
                <Checkbox
                  checked={this.state.user.finance}
                  onClick={() =>
                    this.editVal("finance", !this.state.user.finance)
                  }
                >
                  Edit Finance
                </Checkbox>
                <Checkbox
                  checked={this.state.user.whmoveview}
                  onClick={() =>
                    this.editVal("whmoveview", !this.state.user.whmoveview)
                  }
                >
                  View Warehouse Move
                </Checkbox>
                <Checkbox
                  checked={this.state.user.whmoveedit}
                  onClick={() =>
                    this.editVal("whmoveedit", !this.state.user.whmoveedit)
                  }
                >
                  Edit Warehouse Move
                </Checkbox>
              </Col4>
              <Col4>
                <Div14>Off Site Jobs</Div14>
                <Checkbox
                  checked={this.state.user.warehouse}
                  onClick={() =>
                    this.editVal("warehouse", !this.state.user.warehouse)
                  }
                >
                  Edit Warehouse
                </Checkbox>
                <Checkbox
                  checked={this.state.user.customclearance}
                  onClick={() =>
                    this.editVal(
                      "customclearance",
                      !this.state.user.customclearance
                    )
                  }
                >
                  Custom Clearance
                </Checkbox>
                <Checkbox
                  checked={this.state.user.teamleader}
                  onClick={() =>
                    this.editVal("teamleader", !this.state.user.teamleader)
                  }
                >
                  Team Leader
                </Checkbox>
                <Checkbox
                  checked={this.state.user.delivery}
                  onClick={() =>
                    this.editVal("delivery", !this.state.user.delivery)
                  }
                >
                  Delivery
                </Checkbox>
              </Col4>
              <Col4>
                <Div14>High Level</Div14>
                <Checkbox
                  checked={this.state.user.operhead}
                  onClick={() =>
                    this.editVal("operhead", !this.state.user.operhead)
                  }
                >
                  Operation Heads
                </Checkbox>
                <Checkbox
                  checked={this.state.user.hr}
                  onClick={() => this.editVal("hr", !this.state.user.hr)}
                >
                  Staff HR
                </Checkbox>
                <Checkbox
                  checked={this.state.user.mgt}
                  onClick={() => this.editVal("mgt", !this.state.user.mgt)}
                >
                  Management Views
                </Checkbox>
                <Checkbox
                  checked={this.state.user.admin}
                  onClick={() => this.editVal("admin", !this.state.user.admin)}
                >
                  Admin Views
                </Checkbox>
              </Col4>
              <Col4>
                <Div14>Company Options</Div14>
                <Checkbox
                  checked={this.state.user.compadmin}
                  onClick={() =>
                    this.editVal("compadmin", !this.state.user.compadmin)
                  }
                >
                  Company Admin
                </Checkbox>
                <Checkbox
                  checked={this.state.user.comprequest}
                  onClick={() =>
                    this.editVal("comprequest", !this.state.user.comprequest)
                  }
                >
                  Company Requester
                </Checkbox>
              </Col4>
            </FlexboxGrid>
          </CheckboxGroup>
          <Div14>Link to Client/Company:</Div14>
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            centered
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Single Company"} key={"Single Company"}>
              <>
                {this.state.user.company && (
                  <div>
                    {selectedClient}
                    <br />
                  </div>
                )}
                <InputGroup>
                  <InputGroup.Addon>Client ID</InputGroup.Addon>
                  <InputNumber
                    min={0}
                    value={this.state.clientNumId}
                    disabled={this.state.clientLoad}
                    onChange={(e) => this.setState({ clientNumId: e })}
                  />
                  <InputGroup.Button
                    disabled={this.state.clientNumId === ""}
                    color="green"
                    loading={this.state.clientLoad}
                    onClick={() => this.checkClient()}
                  >
                    <Icon icon="refresh" />
                  </InputGroup.Button>
                </InputGroup>
                {clientDetails}
              </>
            </TabPane>
            <TabPane tab={"Other Companies"} key={"Other Companies"}>
              <>
                {this.state.user.company && (
                  <div>
                    {allcompanies}
                    <br />
                  </div>
                )}
                <InputGroup>
                  <InputGroup.Addon>Client ID</InputGroup.Addon>
                  <InputNumber
                    min={0}
                    value={this.state.clientNumId}
                    disabled={this.state.clientLoad}
                    onChange={(e) => this.setState({ clientNumId: e })}
                  />
                  <InputGroup.Button
                    disabled={this.state.clientNumId === ""}
                    color="green"
                    loading={this.state.clientLoad}
                    onClick={() => this.checkClient()}
                  >
                    <Icon icon="refresh" />
                  </InputGroup.Button>
                </InputGroup>
                {addcompanydetails}
              </>
            </TabPane>
          </Tabs>

          <hr />
          <FlexboxGrid justify="center">
            <Col4>
              <IconButton
                icon={<Icon icon="key" />}
                block
                onClick={() => this.sendResetPasswordLink()}
              >
                Send Reset Password Link
              </IconButton>
            </Col4>
          </FlexboxGrid>
          <hr />

          <TextInputSTU
            val={this.state.password}
            org={""}
            label="password"
            size="xs"
            title="New Password"
            editVal={this.editValFlip}
            updateVal={this.updatePassword}
            disablesave={this.state.password.length > 6}
          />
        </UnifiedModal>

        <div
          style={{
            padding: "1%",
            margin: "1%",
            borderRadius: 10,
            boxShadow: "1px 1px 5px black",
          }}
        >
          <Div14>Admin User List</Div14>
          <FlexboxGrid>
            <Col3>
              <IconButton
                block
                appearance={
                  filts === "Client" || filts === "All" ? "primary" : "ghost"
                }
                icon={
                  <Icon
                    icon={
                      filts === "Client" || filts === "All"
                        ? "eye"
                        : "eye-slash"
                    }
                  />
                }
                onClick={() => adUserStore.changefilt("Client")}
              >
                Client
              </IconButton>
            </Col3>
            <Col3>
              <IconButton
                block
                appearance={
                  filts === "Company" || filts === "All" ? "primary" : "ghost"
                }
                icon={
                  <Icon
                    icon={
                      filts === "Company" || filts === "All"
                        ? "eye"
                        : "eye-slash"
                    }
                  />
                }
                onClick={() => adUserStore.changefilt("Company")}
              >
                Company
              </IconButton>
            </Col3>
            <Col3>
              <IconButton
                block
                appearance={
                  filts === "EMStaff" || filts === "All" ? "primary" : "ghost"
                }
                icon={
                  <Icon
                    icon={
                      filts === "EMStaff" || filts === "All"
                        ? "eye"
                        : "eye-slash"
                    }
                  />
                }
                onClick={() => adUserStore.changefilt("EMStaff")}
              >
                EMStaff
              </IconButton>
            </Col3>
          </FlexboxGrid>
          <MaterialTable
            isLoading={adUserStore.loading || this.state.loading}
            title={users.length + " Users"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={users}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : "Users List "
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  this.setState({
                    show: true,
                    loading: true,
                    user: rowData,
                    orguser: rowData,
                  });
                },
              },
              {
                icon: "refresh",
                isFreeAction: true,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  adUserStore.getUsers();
                },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default observer(UserList);
