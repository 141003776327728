import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Icon, IconButton, Notification } from "rsuite";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class DeleteDRModal extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, loading: false };
    this.onHide = this.onHide.bind(this);
    this.postDelete = this.postDelete.bind(this);
  }
  onHide() {
    this.setState({ show: false, loading: false });
  }

  postDelete = async () => {
    let id = newStorageStore.neworder.damagereport._id;

    let url = `ac/damagereport/delete/${id}`;

    this.setState({ loading: true });

    await instance
      .put(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
        message.success("Damage Report Deleted");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() => this.postDelete()}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>Are you sure you want to delete the Damage Report?</h3>
          <hr />
          <h6>
            Once deleted, you will not be able to recover the information.
          </h6>
        </UnifiedModal>

        <IconButton
          icon={<Icon icon="trash" />}
          color="red"
          size="xs"
          onClick={() => this.setState({ show: true })}
        >
          Delete Damage Report
        </IconButton>
      </div>
    );
  }
}

export default withRouter(observer(DeleteDRModal));
