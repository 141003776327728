import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../UserStore";
import moment from "moment";
import { Parsed } from "../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class StaffStorageStore {
  constructor() {
    this.inventory = [];
    this.acorders = [];
    this.cancelledorders = [];
    this.pastorders = [];
    this.storagehistory = [];
    this.singlelocitems = [];
    this.loctab = 1;
    this.selectedloc = "";
    this.startdate = moment().startOf("M");
    this.enddate = moment().endOf("M");
    this.selectedItemId = "";
    this.frequency = "Daily";
    this.newacinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: "",
      clientname: "",
      clientemail: "",
      clientphone: "",
      acInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
    this.cancelledinvoices = false;
    this.payments = [];
    this.calendar = [];
    this.itemlocs = [];

    this.loading = {
      inventory: false,
      acorders: false,
      storagehistory: false,
      invoices: false,
      payments: false,
      calendar: false,
      itemlocs: false,
    };
    this.loaded = {
      inventory: false,
      acorders: false,
      cancelledorders: false,
      pastorders: false,
      storagehistory: false,
      invoices: false,
      payments: false,
      calendar: false,
      itemlocs: false,
    };

    makeObservable(this, {
      singlelocitems: observable,
      inventory: observable,
      acorders: observable,
      cancelledorders: observable,
      pastorders: observable,
      storagehistory: observable,
      startdate: observable,
      enddate: observable,
      selectedItemId: observable,
      frequency: observable,
      newacinvoice: observable,
      cancelledinvoices: observable,
      payments: observable,
      calendar: observable,
      selectedloc: observable,
      itemlocs: observable,
      loctab: observable,
      loading: observable,
      loaded: observable,
      stockcalcs: computed,
      filtitemlocs: computed,
      otheritems: computed,
      missinglocs: computed,
    });
  }

  // FETCH
  getItemLocs(override) {
    let loaded = false;

    if (this.loaded.itemlocs) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.itemlocs = true;

      return instance
        .get(`/ac/storstats/itemlocs`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((itemlocs) => {
          this.itemlocs = itemlocs;
          this.loaded.itemlocs = true;
          this.loading.itemlocs = false;
          console.log("All Items Locs Fetched");
        })
        .catch((err) => {
          this.loaded.itemlocs = false;
          this.loading.itemlocs = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }
  getSingleLocItems(loc) {
    let loaded = false;

    if (this.loading.itemlocs) loaded = true;

    if (!loaded) {
      this.loading.itemlocs = true;
      this.selectedloc = loc;

      return instance
        .get(`/ac/storstats/itemloc/${loc}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((itemlocs) => {
          this.singlelocitems = itemlocs;
          this.loaded.itemlocs = true;
          this.loading.itemlocs = false;
          console.log("Single Loc Items Fetched");
        })
        .catch((err) => {
          this.loaded.itemlocs = false;
          this.loading.itemlocs = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Single Loc Items Fetched.`,
          });
        });
    }
  }

  addItemTag(item) {
    this.singlelocitems = [...this.singlelocitems, item];
    let indexVal = this.inventory.findIndex(
      (it) => it.acItemNumId === item.acItemNumId
    );

    this.inventory[indexVal].loctags = item.loctags;
  }
  removeItemTag(item) {
    let newitems = [];
    for (let i = 0; i < this.singlelocitems.length; i++) {
      if (this.singlelocitems[i].acItemNumId !== item.acItemNumId) {
        newitems = [...newitems, this.singlelocitems[i]];
      }
    }
    this.singlelocitems = [...newitems];
    let indexVal = this.inventory.findIndex(
      (it) => it.acItemNumId === item.acItemNumId
    );
    this.inventory[indexVal].loctags = item.loctags;
  }

  getItems(override) {
    let loaded = false;
    if (this.loaded.inventory) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.inventory = false;
      this.loading.inventory = true;

      return instance
        .get(`/ac/staff/currentstock`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((inventory) => {
          this.inventory = inventory;
          this.loaded.inventory = true;
          this.loading.inventory = false;
          console.log("Staff Items Fetched");
        })
        .catch((err) => {
          this.loading.inventory = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Current Stock.`,
          });
        });
    }
  }

  getACOrders(override) {
    let loaded = this.loaded.orders;

    if (override) loaded = false;
    if (!loaded) {
      this.loading.acorders = true;
      this.loaded.acorders = false;

      return instance
        .get(`/ac/staff/allorders`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          this.acorders = orders;
          this.loading.acorders = false;
          this.loaded.acorders = true;
          console.log("All AC Orders Fetched ");
        })
        .catch((err) => {
          this.loading.accounts = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching All AC Orders .`,
          });
        });
    }
  }

  getCustomOrderStatus(status, override) {
    let loaded = false;

    let urlcode;
    if (status === "Cancelled") {
      if (this.loaded.cancelledorders) loaded = true;
      urlcode = "allcancelled";
    } else if (status === "Completed") {
      if (this.loaded.pastorders) loaded = true;
      urlcode = "allcompleted";
    }
    if (override) loaded = false;
    if (!loaded) {
      if (status === "Cancelled") {
        this.loaded.cancelledorders = false;
      } else if (status === "Completed") {
        this.loaded.pastorders = false;
      }
      this.loading.acorders = true;

      return instance
        .put(
          `/ac/staff/${urlcode}`,
          { startdate: this.startdate, enddate: this.enddate },
          {
            headers: { authtoken: userStore.token },
          }
        )
        .then((res) => res.data)
        .then((orders) => {
          if (status === "Cancelled") {
            this.cancelledorders = orders;
            this.loaded.cancelledorders = true;
            console.log("Cancelled AC Orders Fetched for Client");
          } else if (status === "Completed") {
            this.pastorders = orders;
            this.loaded.pastorders = true;
            console.log("Past AC Orders Fetched for Client");
          }
          this.loading.acorders = false;
        })
        .catch((err) => {
          this.loading.accounts = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching ${status} AC Orders for Client.`,
          });
        });
    }
  }

  getStorageHistory(override) {
    let loaded = false;

    if (this.loading.storagehistory) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.storagehistory = true;
      this.loaded.storagehistory = false;
      let url = `/ac/staff/invhistory`;

      let data = { startdate: this.startdate, frequency: this.frequency };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.storagehistory = data;

          this.loading.storagehistory = false;
          this.loaded.storagehistory = true;

          console.log("Storage History Fetched");
        })
        .catch((err) => {
          this.loading.storagehistory = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage History.`,
          });
        });
    }
  }

  getAllInvoices(overide) {
    if (!overide) {
      if (this.loading.invoices) return null;
    }
    this.loading.invoices = true;
    return instance
      .put(
        `/ac/staff/getallinvoices`,
        { startdate: this.startdate, enddate: this.enddate },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        this.acinvoices = res.data.invoices;
        this.cancelledinvoices = false;
        this.loading.invoices = false;
        console.log("All AC Invoices Fetched");
      })
      .catch((err) => {
        this.loading.invoices = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getCancelledInvoices(overide) {
    if (!overide) {
      if (this.loading.invoices) return null;
    }
    this.loading.invoices = true;
    return instance
      .put(
        `/ac/staff/getallcancelled`,
        { startdate: this.startdate, enddate: this.enddate },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        this.cancelledinvoices = true;
        this.acinvoices = [...this.acinvoices, ...res.data];

        this.loading.invoices = false;
        console.log("All AC Invoices Fetched");
      })
      .catch((err) => {
        this.loading.invoices = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  getAllPayments() {
    this.loading.payments = true;
    return instance
      .put(
        `/ac/staff/getallpayments`,
        { startdate: this.startdate, enddate: this.enddate },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        this.payments = res.data;
        this.loading.payments = false;
        console.log("All AC Payments Fetched");
      })
      .catch((err) => {
        this.loading.payments = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  getCalendar() {
    this.loading.calendar = true;
    let data = { startdate: this.startdate };
    return instance
      .put(`/ac/staff/calendar`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((orders) => {
        this.calendar = orders;

        this.loading.calendar = false;

        console.log("AC Orders Calendar Fetched for Staff");
      })
      .catch((err) => {
        this.loading.calendar = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching AC Orders Calendar for Staff.`,
        });
      });
  }

  // ----- DATE ----
  // ----- Functions ----
  selectItemId(itemid) {
    staffStorageStore.selectedItemId = itemid;
  }
  changeDate(date) {
    staffStorageStore.startdate = moment(date).startOf("M");
  }
  changeDateRange(date) {
    staffStorageStore.startdate = moment(date[0]).startOf("M");
    staffStorageStore.enddate = moment(date[1]).endOf("M");
  }
  changeFrequency(freq) {
    staffStorageStore.frequency = freq;
  }

  selectInvoice(inv) {
    this.newacinvoice = { ...inv };
  }

  editInvoice(val, label) {
    staffStorageStore.newacinvoice[label] = val;
  }

  changeLocTab(val) {
    this.loctab = val;
  }

  get stockcalcs() {
    let items = 0;
    let instock = 0;
    let reserve = 0;
    for (let i = 0; i < this.inventory.length; i++) {
      items += 1;
      instock += Parsed(this.inventory[i].currentstock);
      reserve += Parsed(this.inventory[i].onreserve);
    }
    return { items: items, instock: instock, reserve: reserve };
  }

  get filtitemlocs() {
    let locs = this.itemlocs.filter(
      (loc) => Parsed(loc.type) === Parsed(this.loctab)
    );

    return locs;
  }
  get otheritems() {
    let locs = this.inventory.filter(
      (it) => !it.loctags.join(", ").includes(this.selectedloc)
    );
    return locs;
  }
  get missinglocs() {
    let locs = this.inventory.filter((it) => it.loctags.length === 0);
    return locs;
  }
}

const staffStorageStore = new StaffStorageStore();
export default staffStorageStore;
