import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import TimelineDate from "./TimelineDate";

class StorDelTimeline extends Component {
  render() {
    let del = this.props.del;
    let jobtype = del.jobtype;

    let allsteps = [
      {
        title: "Requested",
        desc: moment(del.createdAt).format("DD-MMM-YY"),
        status: "finish",
      },
      {
        title: "Booked",
        status: del.bookeddate === undefined ? "wait" : "finish",
        desc:
          del.bookeddate === undefined
            ? ""
            : moment(del.bookeddate).format("DD-MMM-YY"),
      },
    ];
    if (jobtype === "Storage In") {
      allsteps = [
        ...allsteps,
        {
          title: "In Storage",
          status: del.jobdate === undefined ? "wait" : "finish",
          desc:
            del.jobdate === undefined
              ? ""
              : moment(del.jobdate).format("DD-MMM-YY"),
        },
      ];
    } else if (jobtype === "Storage Out") {
      allsteps = [
        ...allsteps,
        {
          title: "Storage Out",
          status: del.jobdate === undefined ? "wait" : "finish",
          desc:
            del.jobdate === undefined
              ? ""
              : moment(del.jobdate).format("DD-MMM-YY"),
        },
      ];
    } else if (jobtype === "Delivery In") {
      allsteps = [
        ...allsteps,
        {
          title: "Pickup",
          status: del.deliverdate === undefined ? "wait" : "finish",
          desc:
            del.deliverdate === undefined
              ? ""
              : moment(del.deliverdate).format("DD-MMM-YY"),
        },
      ];
      if (del.status === "In Transit") {
        allsteps = [
          ...allsteps,
          {
            title: "In Transit",
            status: "finish",
            desc: "Currently In Progress",
          },
        ];
      }
      allsteps = [
        ...allsteps,
        {
          title: "In Storage",
          status: del.jobdate === undefined ? "wait" : "finish",
          desc:
            del.jobdate === undefined
              ? ""
              : moment(del.jobdate).format("DD-MMM-YY"),
        },
      ];
    } else if (jobtype === "Delivery Out") {
      allsteps = [
        ...allsteps,
        {
          title: "Storage Out",
          status: del.jobdate === undefined ? "wait" : "finish",
          desc:
            del.jobdate === undefined
              ? ""
              : moment(del.jobdate).format("DD-MMM-YY"),
        },
      ];
      if (del.status === "In Transit") {
        allsteps = [
          ...allsteps,
          {
            title: "In Transit",
            status: "finish",
            desc: "Currently In Progress",
          },
        ];
      }

      allsteps = [
        ...allsteps,
        {
          title: "Delivery",
          status: del.deliverdate === undefined ? "wait" : "finish",
          desc:
            del.deliverdate === undefined
              ? ""
              : moment(del.deliverdate).format("DD-MMM-YY"),
        },
      ];
    }

    if (del.status === "Cancelled") {
      allsteps = [
        ...allsteps,
        {
          title: "Cancelled",
          desc: moment(del.canceldate).format("DD-MMM-YY"),
          status: "error",
        },
      ];
    }
    let step = 0;
    if (del.status === "Booked") step = 1;
    if (del.status === "In Transit") step = 2;
    if (del.status === "Delivered") step = 2;
    if (del.status === "Cancelled") step = 3;

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <TimelineDate steps={allsteps} step={step} />
        <br />
      </div>
    );
  }
}

export default observer(StorDelTimeline);
