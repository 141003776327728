import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../Components/Loaders/LoadBack";

import Div14 from "../../../Components/Dividers/Div14";
import TabPane from "antd/lib/tabs/TabPane";
import { Tabs } from "antd";

import FlexboxButton from "../../../Components/Modal/FlexboxButton";
import whmNewWarehouseStore from "../WHMStores/WHMNewWarehouseStore";
import WHMNewUnloadingJob from "./WHMNewUnloadingJob";
import whmTruckTripStore from "../WHMStores/WHMTruckTripStore";
import WHMNewWarehouseUnloadTable from "./WHMNewWarehouseUnloadTable";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

class WHMNewWarehouseMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    whmNewWarehouseStore.getPallets();
    whmNewWarehouseStore.getReadyTruckTrip();
  }
  render() {
    return (
      <LoadBack
        loading={
          whmNewWarehouseStore.loading.pallets ||
          whmNewWarehouseStore.loading.trucktrip
        }
      >
        <Div14>New Warehouse</Div14>

        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={"top"}
          centered
          onChange={(e) => this.setState({ tab: e })}
        >
          <TabPane tab={`Truck Activity`} key="Truck Activity">
            <FlexboxGrid
              justify="start"
              style={{ marginRight: "5%", marginTop: "1%" }}
            >
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => whmNewWarehouseStore.getReadyTruckTrip(true)}
                color="orange"
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <Div14>Active Unloading Jobs</Div14>
            {!whmNewWarehouseStore.currentlyunloading.value ? (
              <WHMNewUnloadingJob />
            ) : (
              <FlexboxButton
                icon="truck"
                color="green"
                text={"Go To Unloading Page"}
                oC={() => {
                  whmTruckTripStore.getTruckTrip(
                    this.props.match.params.trucktrip,
                    true
                  );
                  this.props.history.push(
                    `/whm/unloading/${whmNewWarehouseStore.currentlyunloading.data[0]._id}`
                  );
                }}
              />
            )}
          </TabPane>
          <TabPane tab={`Unloaded Pallets`} key="Unloaded Pallets">
            <FlexboxGrid
              justify="start"
              style={{ marginRight: "5%", marginTop: "1%" }}
            >
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => whmNewWarehouseStore.getPallets(true)}
                color="orange"
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <WHMNewWarehouseUnloadTable
              data={whmNewWarehouseStore.pallets}
              prepared
              loaded
              unloaded
            />
          </TabPane>
          <TabPane tab={`Stacked Pallets`} key="Stacked Pallets">
            <FlexboxGrid
              justify="start"
              style={{ marginRight: "5%", marginTop: "1%" }}
            >
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => whmNewWarehouseStore.getStacked(true)}
                color="orange"
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <WHMNewWarehouseUnloadTable
              data={whmNewWarehouseStore.stacked}
              prepared
              loaded
              unloaded
              stacked
            />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMNewWarehouseMain));
