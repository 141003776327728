import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import { Parsed, PRKD } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class CompleteDeliveryStore {
  constructor() {
    this.loading = { order: false, current: false, oldstock: false };
    this.loaded = { order: false, current: false, oldstock: false };
    this.filter = "";
    this.client = {
      name: "",
      email: "",
      phone: "",
      phone2: "",
      clientNumId: "",
    };
    this.neworder = {
      _id: "New",
      jobtype: "Delivery In",
      location: {
        selected: "New",
        name: "",
        phone: "",
        type: "",
        city: "",
        floor: 0,
        address: "",
      },
      account: { client: { name: "", clientNumId: "" } },
      addcharge: [],
      emlocation: { name: "" },
      items: [],
      acitemmove: [],
      acOrderNumId: [],

      orderoptions: [],
    };
    this.oldstockitem = [];
    this.currentitem = [];

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      filter: observable,
      client: observable,
      neworder: observable,
      oldstockitem: observable,
      currentitem: observable,
      stordelinout: computed,
      itemMoveStats: computed,
      finalitemmoves: computed,
      currentitems: computed,
      oldstockitems: computed,
    });
  }

  // FETCH

  getSingleOrder(acorderid, override) {
    let loaded = false;
    if ((this.neworder._id === acorderid) & this.loaded.order) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.neworder._id = acorderid;
      this.loaded.order = false;
      this.loading.order = true;

      return instance
        .get(`/ac/warehouserequest/acorder/${acorderid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((sto) => {
          let order = { ...sto };
          let items = [];
          for (let i = 0; i < sto.acitemmove.length; i++) {
            items = [
              ...items,
              {
                itemmove: sto.acitemmove[i]._id,
                qty: sto.acitemmove[i].quantity,
                cbm: PRKD(
                  Parsed(sto.acitemmove[i].acitem.cbm) *
                    Parsed(sto.acitemmove[i].quantity)
                ),
                dimension: `${Parsed(sto.acitemmove[i].acitem.length)}x${Parsed(
                  sto.acitemmove[i].acitem.width
                )}x${Parsed(sto.acitemmove[i].acitem.height)}`,
                ...sto.acitemmove[i].acitem,
              },
            ];
            order.acitemmove[i].orgquant = order.acitemmove[i].actualquantity;
          }
          order.items = [...items];

          completeDeliveryStore.neworder = { ...order };
          this.loaded.order = true;
          this.loading.order = false;
          console.log("Order Fetched");
          this.getClientInfo(order.account._id);
        })
        .catch((err) => {
          this.order = {
            _id: "",
          };
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getClientInfo(accountid) {
    return instance
      .get(`/ac/account/getclientfromaccount/${accountid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((sto) => {
        completeDeliveryStore.client = { ...sto };
        console.log("Client Info Fetched");
      })
      .catch((err) => {
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Client.`,
        });
      });
  }

  changeFilt(type) {
    if (this.filter === type) {
      this.filter = "";
    } else {
      this.filter = type;
    }
  }

  addItemMove(itemmove) {
    completeDeliveryStore.neworder.acitemmove = [
      ...this.neworder.acitemmove,
      { ...itemmove, orgquant: itemmove.actualquantity },
    ];
    completeDeliveryStore.neworder.items = [
      ...completeDeliveryStore.neworder.items,
      {
        itemmove: itemmove._id,
        qty: itemmove.quantity,
        cbm: PRKD(Parsed(itemmove.acitem.cbm) * Parsed(itemmove.quantity)),
        dimension: `${Parsed(itemmove.acitem.length)}x${Parsed(
          itemmove.acitem.width
        )}x${Parsed(itemmove.acitem.height)}`,
        ...itemmove.acitem,
      },
    ];
  }

  removeItemMove(itemmove) {
    let cds = completeDeliveryStore;
    let cdsim = cds.neworder.acitemmove;
    let cdsitems = cds.neworder.items;

    let newitemmove = [];
    for (let i = 0; i < cdsim.length; i++) {
      if (cdsim[i]._id !== itemmove._id) {
        newitemmove = [...newitemmove, cdsim[i]];
      }
    }

    let newitems = [];
    for (let i = 0; i < cdsitems.length; i++) {
      if (cdsitems[i]._id !== itemmove.acitem._id) {
        newitems = [...newitems, cdsitems[i]];
      }
    }
    cds.neworder.acitemmove = [...newitemmove];
    cds.neworder.items = [...newitems];
  }
  getOldItems(override, accountid, locationid) {
    let loaded = false;
    if (this.loaded.oldstock) {
      loaded = true;
    }

    if (override) loaded = false;

    let acorderid = accountid;
    if (!loaded) {
      this.loaded.oldstock = false;
      this.loading.oldstock = true;
      let url = `/ac/itemsrequest/oldstock/${acorderid}`;
      if (locationid) {
        url = `/ac/itemsrequest/oldstockloc/${locationid}/${acorderid}`;
      }
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((sto) => {
          this.oldstockitem = [...sto];

          this.loaded.oldstock = true;
          this.loading.oldstock = false;

          console.log("Old Items Fetched");
        })
        .catch((err) => {
          this.oldstockitem = [];
          this.loading.oldstock = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Old Items.`,
          });
        });
    }
  }
  getCurrentItems(override, accountid, locationid) {
    let loaded = false;
    if (this.loaded.current) {
      loaded = true;
    }

    if (override) loaded = false;

    let acorderid = accountid;
    if (!loaded) {
      this.loaded.current = false;
      this.loading.current = true;
      let url = `/ac/itemsrequest/current/${acorderid}`;

      if (locationid) {
        url = `/ac/itemsrequest/currentloc/${locationid}/${acorderid}`;
      }
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((sto) => {
          this.currentitem = [...sto];

          this.loaded.current = true;
          this.loading.current = false;

          console.log("Existing Items Fetched");
        })
        .catch((err) => {
          this.currentitem = [];
          this.loading.current = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Existing Items.`,
          });
        });
    }
  }

  changeActQuantity(index, val) {
    this.neworder.acitemmove[index].actualquantity = val;
  }

  // POST

  // COMPUTATIONS:
  get stordelinout() {
    let stordel = "Delivery";
    let inout = "Out";
    let jobtype = this.neworder.jobtype;
    if (jobtype === "Storage In" || jobtype === "Storage Out") {
      stordel = "Storage";
    }
    if (jobtype === "Storage In" || jobtype === "Delivery In") {
      inout = "In";
    }
    return { stordel: stordel, inout: inout };
  }

  get finalitemmoves() {
    let itmoves = this.neworder.acitemmove;
    let confirmed = itmoves.filter(
      (it) => Parsed(it.quantity) === Parsed(it.actualquantity)
    );
    let missing = itmoves.filter((it) => Parsed(it.actualquantity) === 0);
    let modified = itmoves.filter(
      (it) =>
        (Parsed(it.actualquantity) > 0) &
        (Parsed(it.quantity) !== Parsed(it.actualquantity))
    );
    if (this.filter === "confirmed") {
      return confirmed;
    } else if (this.filter === "missing") {
      return missing;
    } else if (this.filter === "modified") {
      return modified;
    } else {
      return itmoves;
    }
  }

  get itemMoveStats() {
    let confirmed = 0;
    let modified = 0;
    let missing = 0;
    let itmoves = this.neworder.acitemmove;
    for (let i = 0; i < itmoves.length; i++) {
      if (Parsed(itmoves[i].quantity) === Parsed(itmoves[i].actualquantity)) {
        confirmed += 1;
      } else if (Parsed(itmoves[i].actualquantity) === 0) {
        missing += 1;
      } else {
        modified += 1;
      }
    }
    return { confirmed: confirmed, modified: modified, missing: missing };
  }

  get oldstockitems() {
    let items = this.oldstockitem;
    let final = [];
    let found = false;
    let oitems = this.neworder.items;
    for (let j = 0; j < items.length; j++) {
      for (let i = 0; i < oitems.length; i++) {
        if (oitems[i]._id === items[j]._id) found = true;
      }
      if (!found) final = [...final, items[j]];
      found = false;
    }
    return final;
  }
  get currentitems() {
    let items = this.currentitem;
    let final = [];
    let found = false;
    let oitems = this.neworder.items;
    for (let j = 0; j < items.length; j++) {
      for (let i = 0; i < oitems.length; i++) {
        if (oitems[i]._id === items[j]._id) found = true;
      }
      if (!found) final = [...final, items[j]];
      found = false;
    }
    return final;
  }

  // FUNCTIONS:
  resetOrder() {
    this.neworder = {
      jobtype: "Delivery In",
      location: {
        selected: "New",
        name: "",
        phone: "",
        type: "",
        city: "",
        floor: 0,
        address: "",
      },
      account: { client: { name: "", clientNumId: "" } },
      addcharge: [],
      emlocation: { name: "" },
      items: [],
      acitemmove: [],
      acOrderNumId: [],

      orderoptions: [],
    };
  }

  // ----- DATE ----
}

const completeDeliveryStore = new CompleteDeliveryStore();
export default completeDeliveryStore;
