import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Button, FlexboxGrid } from "rsuite";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";

class PackingList extends Component {
  render() {
    let sjs = this.props.inv.subJobs;
    let items = sjs.map((sj, index) => (
      <div key={index}>
        <Div14>
          {sj.jobtype} ({`${sj.jobtype}-${sj.numId}`}){" "}
          {sj.items.length === 0 && "No Items"}
        </Div14>
        {sj.items.length > 0 && (
          <FlexboxGrid justify="space-between">
            <Button onClick={() => invoiceStore.showHideAllItems(index, true)}>
              Select All
            </Button>
            <Button onClick={() => invoiceStore.showHideAllItems(index, false)}>
              Un-Select All
            </Button>
          </FlexboxGrid>
        )}
        <FlexboxGrid>
          {sj.items.map((item, itemInd) => (
            <Button
              appearance={item.show ? "primary" : "ghost"}
              key={itemInd}
              onClick={() => {
                invoiceStore.showhideItems(index, itemInd);
              }}
            >
              ({item.qty}) {item.itemname}
            </Button>
          ))}
        </FlexboxGrid>
      </div>
    ));

    return <div>{items}</div>;
  }
}

export default observer(PackingList);
