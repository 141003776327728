import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";

class PerTypeStepSupport extends Component {
  render() {
    let types = newDeliveryStore.delCont.types;
    let to = this.props.to;
    let toindex = this.props.index;
    let selected = to.service;
    let content = types.map((type, index) => (
      <FlexboxGrid
        key={index}
        style={{ paddingLeft: "2%", paddingRight: "2%", paddingBottom: "1%" }}
      >
        <IconButton
          icon={
            <Icon
              icon={type.type === selected.type ? "check-circle" : "circle"}
            />
          }
          appearance={type.type === selected.type ? "primary" : "ghost"}
          color="green"
          block
          style={{ height: "100%" }}
          onClick={() => newDeliveryStore.selectType(type, toindex)}
        >
          <div>
            {type.type} for {PRKD(type.price)} KD
            <br />
            <HelpBlock
              style={{
                fontStyle: "italic",
                color: type.type === selected.type ? "white" : "grey",
              }}
            >
              <b>Description: </b>
              {type.description}
            </HelpBlock>
          </div>
        </IconButton>
      </FlexboxGrid>
    ));
    return (
      <Col2>
        <Div14>
          Drop Off #{toindex + 1} {to.city}:
        </Div14>
        {content}
      </Col2>
    );
  }
}

export default observer(PerTypeStepSupport);
