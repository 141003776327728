import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../Components/Dividers/Div14";
import LoadBack from "../../../Components/Loaders/LoadBack";
import mgtOverviewStore from "../../../Stores/Management/MgtOverviewStore";

import { Empty, Tabs } from "antd";

import ClientData from "./Tabs/ClientData";

import LocalTabs from "./MainTabs/LocalTabs";
import FinancialTabs from "./MainTabs/FinancialTabs";
import SelectInputSTU from "../../../Components/Inputs/Updatable/SelectInputSTU";
import { FlexboxGrid, HelpBlock } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import moment from "moment";

const { TabPane } = Tabs;
class EMOverviewMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "" };
    this.updateMonths = this.updateMonths.bind(this);
  }

  updateMonths = async () => {
    mgtOverviewStore.updateMonths();
    if (this.state.tab === "Financial") {
      mgtOverviewStore.getIncome(true);
      mgtOverviewStore.getExpense(true);
    } else if (this.state.tab === "Clients") {
      mgtOverviewStore.getClients(true);
    } else if (this.state.tab === "Local") {
      mgtOverviewStore.getOverview(true);
    }
  };
  componentDidMount() {
    mgtOverviewStore.getOverview();
  }
  render() {
    return (
      <div>
        <Div14>Easy Move Operational Overview</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <SelectInputSTU
              val={mgtOverviewStore.months}
              org={mgtOverviewStore.orgmonths}
              label="months"
              load={mgtOverviewStore.checkLoading}
              title="Number of Months"
              size="large"
              editVal={mgtOverviewStore.changeMonths}
              updateVal={this.updateMonths}
              opts={[3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            />
          </Col2>
        </FlexboxGrid>
        <HelpBlock style={{ textAlign: "center" }}>
          <i>
            {moment()
              .startOf("month")
              .subtract(mgtOverviewStore.months, "month")
              .format("MMM-YYYY")}{" "}
            to {moment().startOf("month").format("MMM-YYYY")}
          </i>
        </HelpBlock>
        <LoadBack loading={mgtOverviewStore.loading.local}>
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            centered
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Local"} key={"Local"}>
              <LocalTabs />
            </TabPane>

            <TabPane tab={"Clients"} key={"Clients"}>
              <ClientData />
            </TabPane>
            <TabPane tab={"Financial"} key={"Financial"}>
              <FinancialTabs />
            </TabPane>
            <TabPane tab={"Warehouse"} key={"Warehouse"}>
              {/* <WarehouseData /> */}
              <Empty description="Coming Soon" />
            </TabPane>
            <TabPane tab={"Pending"} key={"Pending"}>
              <Div14>Job Data</Div14>
              <Div14>Supply Chain Data</Div14>
              <h1>Warehouse This Month</h1>
              <h1>Last Mile This Month</h1>
              <Div14>Costs This Month</Div14>
              <Div14>Income This Month</Div14>
            </TabPane>
          </Tabs>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(EMOverviewMain));
