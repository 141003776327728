import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import { Descriptions } from "antd";

class ToAddressTable extends Component {
  render() {
    let del = this.props.del;
    let to = this.props.to;
    let index = this.props.index;

    let items = this.props.to.items;

    let tcbm = 0;
    let tpackages = 0;
    for (let i = 0; i < items.length; i++) {
      tpackages += Parsed(items[i].quantity);
      tcbm +=
        (Parsed(items[i].length) *
          Parsed(items[i].width) *
          Parsed(items[i].height) *
          Parsed(items[i].quantity)) /
        1000000;
    }
    if (del.maintype === "Fixed Per Type") {
      return (
        <Descriptions
          title={`Dropoff Location # ${index + 1}`}
          bordered
          size="small"
          key={index}
        >
          <Descriptions.Item label={`Address`} span={3}>
            {to.address} {to.items.length}
          </Descriptions.Item>
          <Descriptions.Item label="Name">{to.name}</Descriptions.Item>
          <Descriptions.Item label="Phone">{to.phone}</Descriptions.Item>
          <Descriptions.Item label="Type">{to.type}</Descriptions.Item>
          <Descriptions.Item label="City">{to.city}</Descriptions.Item>
          <Descriptions.Item label="Floor">{to.floor}</Descriptions.Item>
          <Descriptions.Item label="Charges">
            {PRKD(Parsed(to.zonecost) + Parsed(to.ngfchg))} KD
          </Descriptions.Item>
          <Descriptions.Item label="Service Type">
            {to.service.type}: {PRKD(to.service.price)} KD
          </Descriptions.Item>
        </Descriptions>
      );
    }
    return (
      <Descriptions
        title={`Dropoff Location # ${index + 1}`}
        bordered
        size="small"
        key={index}
      >
        <Descriptions.Item label={`Address`} span={3}>
          {to.address} {to.items.length}
        </Descriptions.Item>
        <Descriptions.Item label="Name">{to.name}</Descriptions.Item>
        <Descriptions.Item label="Phone">{to.phone}</Descriptions.Item>
        <Descriptions.Item label="Type">{to.type}</Descriptions.Item>
        <Descriptions.Item label="City">{to.city}</Descriptions.Item>
        <Descriptions.Item label="Floor">{to.floor}</Descriptions.Item>
        <Descriptions.Item label="Charges">
          {PRKD(Parsed(to.zonecost) + Parsed(to.ngfchg))} KD
        </Descriptions.Item>
        <Descriptions.Item label="CBM">{PRKD(tcbm)} CBM</Descriptions.Item>
        <Descriptions.Item label="Items">
          {items.length} Item{items.length === 1 ? "" : "s"}
        </Descriptions.Item>
        <Descriptions.Item label="Packages">
          {PRKD(tpackages)} Package{tpackages === 1 ? "" : "s"}
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default withRouter(observer(ToAddressTable));
