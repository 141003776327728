import { computed, makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Parsed } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class ClientBalanceStore {
  constructor() {
    this.daterange = [
      moment().subtract(0, "days").startOf("month"),
      moment().endOf("month"),
    ];
    this.clients = [];
    this.stats = [];
    this.daily = [];
    this.caldate = moment().startOf("month");

    this.loading = { balance: false, sales: false };
    this.loaded = { balance: false, sales: false };

    makeObservable(this, {
      clients: observable,
      stats: observable,
      daily: observable,
      caldate: observable,
      loading: observable,
      loaded: observable,
      dailycal: computed,
      totalbreakdown: computed,
    });
  }

  // FETCH

  getMonthlySales(overide) {
    let loaded = this.loaded.sales;
    if (overide) loaded = false;
    if (this.loading.sales) loaded = true;
    if (!loaded) {
      let url = `/jf/financial/stats`;

      let data = {
        startdate: moment(this.daterange[0]),
        enddate: moment(this.daterange[1]),
      };
      this.loading.sales = true;
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.stats = data.jobs;
          this.daily = data.daily;

          this.loading.sales = false;
          this.loaded.sales = true;

          console.log("All Payments Fetched Monthly Sales");
        })
        .catch((err) => {
          this.loading.sales = false;

          console.log(err);

          Notification["error"]({
            title: `Error Fetching ACC Charges.`,
          });
        });
    }
  }
  getJobBalances(overide) {
    let loaded = this.loaded.balance;
    if (overide) loaded = false;
    if (this.loading.balance) loaded = true;
    if (!loaded) {
      let url = `/jf/financial/allclientbalances`;

      let data = { startdate: this.daterange[0], enddate: this.daterange[1] };
      this.loading.balance = true;
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.clients = data;

          this.loading.balance = false;
          this.loaded.balance = true;

          console.log("All Payments Fetched Job Balance");
        })
        .catch((err) => {
          this.loading.balance = false;

          console.log(err);

          Notification["error"]({
            title: `Error Fetching ACC Charges.`,
          });
        });
    }
  }

  // Functions
  changeDate(val) {
    clientBalanceStore.daterange[0] = moment(val[0]).startOf("month");
    clientBalanceStore.daterange[1] = moment(val[1]).endOf("month");
  }

  changeStartCal(val) {
    clientBalanceStore.daterange[0] = moment(val).startOf("month");
    clientBalanceStore.daterange[1] = moment(val).endOf("month");
    clientBalanceStore.getMonthlySales(true);
  }

  // Computations
  get dailycal() {
    let output = [];
    let price = 0;
    let cbm = 0;
    let jobs = 0;
    let title = "";
    if (this.daily) {
      for (let i = 0; i < this.daily.length; i++) {
        price =
          Parsed(this.daily[i].price.LM) +
          Parsed(this.daily[i].price.IM) +
          Parsed(this.daily[i].price.CC) +
          Parsed(this.daily[i].price.ST);
        cbm =
          Parsed(this.daily[i].cbm.LM) +
          Parsed(this.daily[i].cbm.IM) +
          Parsed(this.daily[i].cbm.CC) +
          Parsed(this.daily[i].cbm.ST);
        jobs =
          Parsed(this.daily[i].jobs.LM) +
          Parsed(this.daily[i].jobs.IM) +
          Parsed(this.daily[i].jobs.CC) +
          Parsed(this.daily[i].jobs.ST);
        if (jobs > 0) {
          title = `${jobs} Job${jobs !== 1 ? "s" : ""} | ${
            Math.round(price * 10) / 10
          } KD | ${Math.round(cbm * 10) / 10} CBM`;
        } else {
          title = " - ";
        }
        output = [
          ...output,
          {
            date: this.daily[i].date,
            title: title,
            jobs: jobs,
            type: this.daily[i].title,
          },
        ];
      }
    }

    return output;
  }
  get totalbreakdown() {
    let Booked = { LM: 0, IM: 0, CC: 0, ST: 0 };
    let Converted = { LM: 0, IM: 0, CC: 0, ST: 0 };

    if (this.daily) {
      for (let i = 0; i < this.daily.length; i++) {
        if (this.daily[i].title === "Booked") {
          Booked.LM += parseFloat(this.daily[i].price.LM);
          Booked.IM += parseFloat(this.daily[i].price.IM);
          Booked.CC += parseFloat(this.daily[i].price.CC);
          Booked.ST += parseFloat(this.daily[i].price.ST);
        } else {
          Converted.LM += parseFloat(this.daily[i].price.LM);
          Converted.IM += parseFloat(this.daily[i].price.IM);
          Converted.CC += parseFloat(this.daily[i].price.CC);
          Converted.ST += parseFloat(this.daily[i].price.ST);
        }
      }
    }

    return { Booked: Booked, Converted: Converted };
  }
}

const clientBalanceStore = new ClientBalanceStore();
export default clientBalanceStore;
