import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import hrStore from "../../../../Stores/HR/HRStore";
import NewStaff from "./NewStaff";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { Icon, IconButton, Notification } from "rsuite";
import { withRouter } from "react-router";
import singleStaffHRStore from "../../../../Stores/HR/SingleStaffHRStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import { Tooltip } from "@material-ui/core";
import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
  LoadPanel,
  Selection,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";

const instance = axios.create({ baseURL: baseURL });

class StaffMainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: {},
      show: false,
      single: false,
      ind: -1,
      type: "New",
      loading: true,
      staff: {
        type: "Office",
        base: "Mina Abdullah",
        title: "",
        jobdesc: "",
        name: "",
        email: "",
        phone: "",
        civilid: "",
        salary: 0,
        monthlyholiday: 0,
        joined: moment(),
      },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.ActiveCell = this.ActiveCell.bind(this);
    this.EditButton = this.EditButton.bind(this);
  }

  ActiveCell(cellData) {
    let active = cellData.data.active;
    let color = "green";
    let text = "Yes";
    if (!active) {
      color = "red";
      text = "No";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{text}</div>;
  }
  EditButton(cellData) {
    let rowData = cellData.data;
    return (
      <IconButton
        circle
        size="xs"
        icon={<Icon icon="pencil" />}
        color="blue"
        onClick={() => {
          this.props.history.push(`hrstaff/${rowData._id}`);
          singleStaffHRStore.getSingleHRStaff(rowData._id, true);
        }}
      />
    );
  }

  componentDidMount() {
    hrStore.getHRStaffs();
  }

  changeVal(val, type) {
    this.setState({ [type]: val });
  }
  editVal(val, type) {
    console.log("EDITTING IN PROGRESS");
    let staff = { ...this.state.staff };
    staff[type] = val;
    this.setState({ staff: staff });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        type: "New",
        loading: false,
        single: false,
        staff: {
          type: "Office",
          base: "Mina Abdullah",
          title: "",
          jobdesc: "",
          civilid: "",
          name: "",
          email: "",
          phone: "",
          salary: 0,
          monthlyholiday: 0,
          joined: moment(),
        },
      });
    }
  }

  postNew = async () => {
    let newservice = {
      type: this.state.staff.type,
      base: this.state.staff.base,
      title: this.state.staff.title,
      jobdesc: this.state.staff.jobdesc,
      name: this.state.staff.name,
      email: this.state.staff.email,
      phone: this.state.staff.phone,
      salary: this.state.staff.salary,
      monthlyholiday: this.state.staff.monthlyholiday,
      joined: moment(this.state.staff.joined),
    };
    this.changeVal("loading", true);
    let url = "/hr/staff/new";

    return instance
      .post(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHRStaffs(true);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Added New Staff: ${newservice.name}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    let data = hrStore.staff;

    return (
      <LoadBack loading={hrStore.loading}>
        <Div14>Staff List</Div14>
        <NewStaff
          hideModal={this.showHideModal}
          type={this.state.type}
          show={this.state.show}
          staff={this.state.staff}
          postNew={this.postNew}
          editVal={this.editVal}
        />

        <DataGrid
          dataSource={data}
          keyExpr="hrStaffNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <Selection mode="single" />
          <LoadPanel enabled={true} />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column
            dataField="hrStaffNumId"
            dataType="number"
            caption="Edit"
            alignment="center"
            width={40}
            cellRender={this.EditButton}
          />
          {hrStore.allstaffloaded && (
            <Column
              dataField="active"
              dataType="number"
              caption="Active"
              alignment="center"
              width={80}
              cellRender={this.ActiveCell}
            />
          )}
          <Column
            dataField="type"
            caption="Type"
            alignment="center"
            width={120}
          />
          <Column
            dataField="name"
            caption="Name"
            alignment="center"
            width={160}
          />
          <Column
            dataField="title"
            caption="Title"
            alignment="center"
            width={160}
          />
          <Column
            dataField="base"
            caption="Base"
            alignment="center"
            width={120}
          />
          <Column
            dataField="totalsalary"
            caption="Salary"
            type="number"
            alignment="center"
            width={80}
          />
          <Column
            dataField="monthlyholiday"
            caption="Monthly Days"
            type="number"
            alignment="center"
            width={80}
          />
          <Column
            dataField="remaining"
            caption="Days Left"
            type="number"
            alignment="center"
            width={80}
          />
          <Column
            dataField="sickremaining"
            caption="Sick Days Left"
            type="number"
            alignment="center"
            width={80}
          />
          <Column
            dataField="joined"
            dataType="date"
            caption="Joined"
            alignment="center"
            format={"dd-MMM-yy"}
            minWidth={80}
          />
          {hrStore.allstaffloaded && (
            <Column
              dataField="exitdate"
              dataType="date"
              caption="Exited"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
            />
          )}
          <Summary>
            <TotalItem
              column="hrStaffNumId"
              summaryType="count"
              displayFormat={"{0}"}
            />
            <TotalItem
              column="totalsalary"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="remaining"
              summaryType="sum"
              valueFormat={"#,##0.0"}
              displayFormat={"{0} Days"}
            />
          </Summary>
          <Toolbar>
            <Item name="Refresh" location="before">
              <Button
                icon="refresh"
                onClick={() => hrStore.getHRStaffs(true)}
              />
            </Item>
            <Item name="New" location="before">
              <Tooltip title={`New AC Invoice`} arrow placement="right">
                <IconButton
                  icon={<Icon icon="plus" />}
                  onClick={() => this.showHideModal(true)}
                  color="green"
                  circle
                />
              </Tooltip>
            </Item>
            <Item name="columnChooserButton" location="left" />

            <Item name="exportButton" />
            {!hrStore.allstaffloaded ? (
              <Item name="Show Old Staff" location="after">
                <Tooltip title={`Show Old Staff`} arrow placement="left">
                  <IconButton
                    color="yellow"
                    icon={<Icon icon="clock-o" />}
                    onClick={() => hrStore.getAllStaff(true)}
                    loading={hrStore.loading}
                  >
                    Load Old Staff
                  </IconButton>
                </Tooltip>
              </Item>
            ) : (
              <div />
            )}
          </Toolbar>
          <Export enabled={true} />
        </DataGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(StaffMainPage));
