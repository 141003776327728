import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

import { Button, Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import { Input } from "antd";
import { Tooltip } from "@material-ui/core";

import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import TextInput from "./Inputs/TextInput";
import SelectInputs from "./Inputs/SelectInputs";
import axios from "axios";

import heardofuslist from "../../../Static/Lists/HeardOfUs";
import leadtypeslist from "../../../Static/Lists/LeadTypes";
import baseURL from "../../../Static/baseURL/baseURL";
import { Notification } from "rsuite";
import userStore from "../../../Stores/UserStore";
import clienttypelist from "../../../Static/Lists/ClientTypeList";
import clientStore from "../../../Stores/ClientStore";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

const { TextArea } = Input;

class NewClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      name: "",
      email: "",
      type: "Customer",
      phone: "",
      phone2: "",
      heardofus: "",
      leadtype: "",
      remarks: "",
      status: "Pending",
      startdate: moment(),
      enddate: moment().add(1, "year"),
      aim: false,
      acc: false,
      ast: false,
      adel: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }

  changeVal(type, val) {
    this.setState({ [type]: val });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({ show: false });
    }
  }

  postNewClient = async () => {
    let type = this.state.type;
    if (this.props.account) type = "Account";
    let clientdata = {
      name: this.state.name,
      email: this.state.email,
      type: type,
      phone: this.state.phone,
      phone2: this.state.phone2,
      heardofus: this.state.heardofus,
      leadtype: this.state.leadtype,
      remarks: this.state.remarks,
    };

    this.changeVal("loading", true);

    return instance
      .post("/client/basic/new", clientdata, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.changeVal("loading", false);
        this.showHideModal(false);
        clientStore.getClients(true);
        Notification["success"]({
          title: `Successfully Added.`,
          description: (
            <div>
              Client {res.data.name} Added (ID# {res.data.clientNumId})
            </div>
          ),
        });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    let account = this.props.account;
    let title = "Add New Client";
    if (account) {
      title = "Add New Account Client";
    }

    let disableButton = { message: "Submit", val: false };
    let nameerr = this.state.name.length < 3;
    let hearderr = this.state.heardofus.length < 3;
    let leaderr = this.state.leadtype.length < 3;

    if (nameerr || hearderr || leaderr) disableButton.val = true;

    let contacterr =
      !disableButton.val &
      (this.state.phone === "") &
      (this.state.phone2 === "") &
      (this.state.email === "");

    if (contacterr) {
      disableButton = {
        message: "Please add either a PHONE or EMAIL.",
        val: false,
      };
    }

    return (
      <div>
        <Tooltip title={"Add New Client"} arrow placement="left">
          <IconButton
            color="green"
            circle
            icon={<Icon icon="plus" />}
            onClick={() =>
              this.setState({
                show: true,
                name: "",
                email: "",
                type: "Customer",
                phone: "",
                phone2: "",
                heardofus: "",
                leadtype: "",
                remarks: "",
                status: "Pending",
                startdate: moment(),
                enddate: moment().add(1, "year"),
                aim: false,
                acc: false,
                ast: false,
                adel: false,
              })
            }
          />
        </Tooltip>
        <UnifiedModal
          show={this.state.show}
          title={title}
          onHide={this.showHideModal}
          footer={
            <FlexboxGrid>
              <Col xs={12}>
                <Button
                  block
                  appearance="ghost"
                  color="red"
                  loading={this.state.loading}
                  onClick={() => this.showHideModal(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  block
                  color="green"
                  disabled={disableButton.val}
                  loading={this.state.loading}
                  onClick={() => {
                    this.postNewClient();
                  }}
                >
                  {disableButton.message}
                </Button>
              </Col>
            </FlexboxGrid>
          }
        >
          {!this.props.account ? (
            <SelectInputs
              options={clienttypelist}
              val={this.state.type}
              label="Client Type"
              type={"type"}
              changeVal={this.changeVal}
            />
          ) : (
            <SelectInputs
              options={["Account"]}
              val={"Account"}
              label="Client Type"
              type={"type"}
              changeVal={this.changeVal}
            />
          )}
          <TextInput
            val={this.state.name}
            label={`${this.state.type} Name`}
            changeVal={this.changeVal}
            err={nameerr}
            required
            content={"name"}
            type={"text"}
          />
          <FlexboxGrid>
            <Col md={8} sm={12} xs={24}>
              <TextInput
                val={this.state.phone}
                label={"Phone"}
                content={"phone"}
                changeVal={this.changeVal}
                type={"tel"}
                err={contacterr}
              />
            </Col>
            <Col md={8} sm={12} xs={24}>
              <TextInput
                val={this.state.phone2}
                label={"Phone 2"}
                content={"phone2"}
                changeVal={this.changeVal}
                type={"tel"}
                err={contacterr}
              />
            </Col>
            <Col md={8} sm={12} xs={24}>
              <TextInput
                val={this.state.email}
                content={"email"}
                label={"Email"}
                changeVal={this.changeVal}
                type={"email"}
                err={contacterr}
              />
            </Col>
            {contacterr ? <Col md={24}>{disableButton.message}</Col> : <div />}
            <br />
            <Col md={12} sm={12} xs={24}>
              <SelectInputs
                options={heardofuslist}
                val={this.state.heardofus}
                label="Heard of Us"
                type={"heardofus"}
                changeVal={this.changeVal}
              />
            </Col>
            <Col md={12} sm={12} xs={24}>
              <SelectInputs
                options={leadtypeslist}
                val={this.state.leadtype}
                label="Lead Type"
                type={"leadtype"}
                changeVal={this.changeVal}
              />
            </Col>
          </FlexboxGrid>
          <hr />
          <h6>Remarks:</h6>
          <TextArea
            style={{ width: "100%" }}
            showCount
            maxLength={200}
            value={this.state.remarks}
            onChange={(e) => this.changeVal("remarks", e.target.value)}
            allowClear
            autoSize={{ minRows: 2, maxRows: 20 }}
          />
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(NewClient));
