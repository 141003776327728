import { observer } from "mobx-react";
import React, { Component } from "react";
import BasicDesc from "../../../../../../Components/Descriptions/BasicDesc";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";

class CostSummary extends Component {
  render() {
    let sto = this.props.sto;

    let srv = sto.services;

    let main = [
      {
        label: srv.timetype,
        val: `${srv.timeprice} KD`,
        price: srv.timeprice,
        show: srv.timeprice > 0,
        span: 24,
      },
      {
        label: srv.weekday,
        val: `${srv.weekdayprice} KD`,
        price: srv.weekdayprice,
        show: srv.weekdayprice > 0,
        span: 24,
      },
      {
        label: srv.zonetype,
        val: `${srv.zonecost} KD`,
        price: srv.zonecost,
        show: srv.zonecost > 0,
        span: 24,
      },
      {
        label: "Non-Ground Floor Charge",
        val: `${srv.ngfchg} KD`,
        price: srv.ngfchg,
        show: srv.ngfchg > 0,
        span: 24,
      },
    ];
    let percbmitems = [
      {
        label: "Storage In Charge",
        val: `${PRKD(Parsed(srv.stincost) * Parsed(sto.totalcbm))} KD (${PRKD(
          srv.stincost
        )} KD Per CBM)`,
        price: srv.stincost,
        show: srv.stincost > 0,
        span: 24,
      },
      {
        label: "Storage Out Charge",
        val: `${PRKD(Parsed(srv.stoutcost) * Parsed(sto.totalcbm))} KD (${PRKD(
          srv.stoutcost
        )} KD Per CBM)`,
        price: srv.stoutcost,
        show: srv.stoutcost > 0,
        span: 24,
      },
    ];
    main = main.filter((item) => item.show);
    percbmitems = percbmitems.filter((item) => item.show);

    let options = sto.orderoptions;
    let opts = [];
    for (let i = 0; i < options.length; i++) {
      opts = [
        ...opts,
        {
          label: `${i + 1}) ${options[i].title} : ${options[i].desc}`,
          val:
            options[i].type.toLowerCase() === "per cbm"
              ? `${PRKD(
                  Parsed(options[i].price) * Parsed(sto.totalcbm)
                )} KD (${PRKD(options[i].price)} KD Per CBM)`
              : `${PRKD(options[i].price)} KD ${options[i].type}`,
          price: options[i].price,
          span: 24,
          ltype: options[i].type.toLowerCase(),
        },
      ];
    }

    let finalcost = 0;
    let percbm = 0;
    for (let i = 0; i < main.length; i++) {
      finalcost += Parsed(main[i].price);
    }
    for (let i = 0; i < percbmitems.length; i++) {
      percbm += Parsed(percbmitems[i].price);
    }

    for (let i = 0; i < opts.length; i++) {
      if (opts[i].ltype === "per cbm") {
        percbm += Parsed(opts[i].price);
      } else {
        finalcost += Parsed(opts[i].price);
      }
    }

    let totaladdchg = 0;
    let chgrows = [];
    if (sto.addcharge.length > 0) {
      for (let i = 0; i < sto.addcharge.length; i++) {
        chgrows = [
          ...chgrows,
          {
            label: `Add Charge: ${sto.addcharge[i].name}`,
            val: `${sto.addcharge[i].price} KD`,
            price: sto.addcharge[i].price,
            span: 24,
          },
        ];
        totaladdchg += parseFloat(sto.addcharge[i].price);
      }
    }

    let discount = sto.discount;
    let discs = [];
    if (discount > 0) {
      discs = [
        {
          label: `Discount`,
          val: `- ${sto.discount} KD`,
          price: -sto.discount,
          span: 24,
        },
      ];
    }

    return (
      <div>
        <Div14>Delivery Service</Div14>
        <BasicDesc
          items={[
            {
              label: "Delivery Charges",
              val: null,
              span: 24,
            },
          ]}
          dark
        />
        <BasicDesc items={main} />
        <BasicDesc items={chgrows} />
        <BasicDesc items={percbmitems} />
        <BasicDesc items={opts} />
        <BasicDesc items={discs} />
        <BasicDesc
          items={[
            {
              label: "Total Delivery Cost",
              val: `${PRKD(
                Parsed(finalcost) +
                  Parsed(totaladdchg) +
                  Parsed(percbm) * Parsed(sto.totalcbm) -
                  Parsed(discount)
              )} KD Total = ${PRKD(finalcost)} KD${
                totaladdchg > 0 ? ` + ${PRKD(totaladdchg)} KD Add` : ""
              } + ${PRKD(Parsed(percbm) * Parsed(sto.totalcbm))} (${PRKD(
                percbm
              )} KD Per CBM) ${discount > 0 ? `- ${PRKD(discount)} KD` : ""}`,
              span: 24,
            },
          ]}
          dark
        />
      </div>
    );
  }
}

export default observer(CostSummary);
