import { Statistic } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Button, FlexboxGrid } from "rsuite";
import Col4 from "../../../../../../../../Components/Columns/Col4";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import {
  Parsed,
  PRKD,
} from "../../../../../../../../Functions/Pricing/PRKDCalc";
import delInvoiceStore from "../../../../../../../../Stores/Financial/DelInvoiceStore";

class Step2 extends Component {
  render() {
    let recs = delInvoiceStore.newrecs;
    let shorttime = "Week ";
    let totalremaining = 0;
    let totalselected = 0;
    let selectedcount = 0;

    for (let i = 0; i < recs.length; i++) {
      if (recs[i].selected) totalselected += Parsed(recs[i].finalcost);
      if (recs[i].selected) selectedcount += 1;
      if (!recs[i].selected) totalremaining += Parsed(recs[i].finalcost);
    }

    let rows = recs.map((rec, index) => (
      <Button
        block
        key={index}
        onClick={() => delInvoiceStore.changeCharge(rec)}
        appearance={rec.selected ? "primary" : "ghost"}
        color="black"
      >
        <Col4>
          <b>
            {shorttime} {rec.freqtime}:
          </b>{" "}
          {moment(rec.startday).format("DD-MMM-YY")} to{" "}
          {moment(rec.endday).format("DD-MMM-YY")}
          <span> </span>
        </Col4>
        <Col4>
          <b>Delivery:</b> {PRKD(rec.totaljobchg)} KD
        </Col4>
        <Col4>
          <b>Min Charge:</b> {PRKD(rec.minchg)} KD
        </Col4>
        <Col4>
          <b>Total:</b> {PRKD(rec.finalcost)} KD
        </Col4>
      </Button>
    ));

    return (
      <div>
        <Div14>Step 2: Pending Charges</Div14>
        <FlexboxGrid>
          <Col4>
            <Statistic
              title="Unselected Charges"
              value={recs.length - selectedcount}
              style={{ textAlign: "center" }}
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Total Value Remaining"
              value={PRKD(totalremaining)}
              style={{ textAlign: "center" }}
              suffix="KD"
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Selected Charges"
              value={selectedcount}
              style={{ textAlign: "center" }}
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Total Value"
              value={PRKD(totalselected)}
              style={{ textAlign: "center" }}
              suffix="KD"
            />
            <hr />
          </Col4>
        </FlexboxGrid>
        <hr />
        {rows}
      </div>
    );
  }
}

export default observer(Step2);
