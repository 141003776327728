import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon } from "rsuite";

import Col3 from "../../../../../../../Components/Columns/Col3";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { CCQualityCalc } from "../../../../../../../Functions/Quality/CCQualityCalc";
import { CCQualityDocs } from "../../../../../../../Functions/Quality/CCQualityCalc";
import { CCQualityGeneral } from "../../../../../../../Functions/Quality/CCQualityCalc";
import { CCQualityKeyEvents } from "../../../../../../../Functions/Quality/CCQualityCalc";
import { IMQualityCalc } from "../../../../../../../Functions/Quality/IMQualityCalc";
import { IMQualityCharges } from "../../../../../../../Functions/Quality/IMQualityCalc";
import { IMQualityMain } from "../../../../../../../Functions/Quality/IMQualityCalc";
import { IMQualityTrack } from "../../../../../../../Functions/Quality/IMQualityCalc";
import { LMQualityCalc } from "../../../../../../../Functions/Quality/LMQualityCalc";
import { LMQualityMain } from "../../../../../../../Functions/Quality/LMQualityCalc";
import { STQualityCalc } from "../../../../../../../Functions/Quality/STQualityCalc";
import { STQualityMain } from "../../../../../../../Functions/Quality/STQualityCalc";
import { AttQualityCalc } from "../../../../../../../Functions/Quality/SupportingQuality/ATTQualityCalc";
import { JDQualityCalc } from "../../../../../../../Functions/Quality/SupportingQuality/JDQualityCalc";

class QualityMain extends Component {
  render() {
    let sj = this.props.sj;
    let qc = [0, 0];
    let tabs = [];
    if (sj.jobtype === "CC") {
      qc = CCQualityCalc(sj);
      tabs = [
        { title: "Job Details", qc: CCQualityGeneral(sj) },
        { title: "Events", qc: CCQualityKeyEvents(sj) },
        { title: "Docs", qc: CCQualityDocs(sj) },
        { title: "Job Dates", qc: JDQualityCalc(sj) },
        { title: "Attachments", qc: AttQualityCalc(sj) },
      ];
    } else if (sj.jobtype === "IM") {
      qc = IMQualityCalc(sj);
      tabs = [
        { title: "Job Details", qc: IMQualityMain(sj) },
        { title: "Charges", qc: IMQualityCharges(sj) },
        { title: "Job Dates", qc: JDQualityCalc(sj) },
        { title: "IM Tracks", qc: IMQualityTrack(sj) },
        { title: "Attachments", qc: AttQualityCalc(sj) },
      ];
    } else if (sj.jobtype === "LM") {
      qc = LMQualityCalc(sj);
      tabs = [
        { title: "Job Details", qc: LMQualityMain(sj) },
        { title: "Job Dates", qc: JDQualityCalc(sj) },
        { title: "Attachments", qc: AttQualityCalc(sj) },
      ];
    } else if (sj.jobtype === "ST") {
      qc = STQualityCalc(sj);
      tabs = [
        { title: "Job Details", qc: STQualityMain(sj) },
        { title: "Job Dates", qc: JDQualityCalc(sj) },
        { title: "Attachments", qc: AttQualityCalc(sj) },
      ];
    }

    tabs = tabs.filter((tab) => tab.qc[0] > 0);
    let percentage = Math.round((qc[0] / qc[1]) * 100);

    let finalTabs = tabs.map((main, index) => (
      <Col3 key={index}>
        <Div14>
          {main.title}: {main.qc[0]} Errors
        </Div14>
        {main.qc[2].map((qc, qind) => (
          <FlexboxGrid key={qind} justify="start" align="middle">
            <Icon style={{ color: "red" }} icon="close" /> {qc}
          </FlexboxGrid>
        ))}
      </Col3>
    ));
    return (
      <div>
        <h6 style={{ textAlign: "center" }}>
          {sj.jobtype}-{sj.sjNumId} Quality Tab ({percentage})% with {qc[0]}{" "}
          Issues:
        </h6>
        <FlexboxGrid>{finalTabs}</FlexboxGrid>
      </div>
    );
  }
}

export default observer(QualityMain);
