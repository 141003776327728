import React, { Component } from "react";
import modalItemStore from "../../../../Stores/ModalStores/ModalItemStore";
import MaterialTable from "material-table";
import moment from "moment";

import { FlexboxGrid, Icon, IconButton, Loader } from "rsuite";

import { Tabs } from "antd";
import Col2 from "../../../../Components/Columns/Col2";
import Col3 from "../../../../Components/Columns/Col3";
import Div14 from "../../../../Components/Dividers/Div14";
import ItemHistoryGraph from "../InventoryPage/Graphs/ItemHistoryGraph";

import AddLocationTags from "../InventoryPage/AddLocationTags/AddLocationTags";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import { observer } from "mobx-react";
import { withRouter } from "react-router";

const { TabPane } = Tabs;
class SingleItemPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      showtrack: false,
      order: { acOrderNumId: "", _id: "" },
    };
    this.handleModeChange = this.handleModeChange.bind(this);
    this.onHideAll = this.onHideAll.bind(this);
    this.onHideTrack = this.onHideTrack.bind(this);
    this.onShowTrack = this.onShowTrack.bind(this);
  }
  componentDidMount() {
    modalItemStore.getSingleItem(this.props.match.params.acitemid);
  }

  onShowTrack(acorder) {
    this.setState({ showtrack: true, order: acorder });
  }
  onHideTrack() {
    this.setState({ showtrack: false });
  }
  onHideAll() {
    this.onHideTrack();
  }
  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };
  render() {
    let content = <Loader center />;

    if (modalItemStore.loading.item) {
      return (
        <div style={{ padding: "2%" }}>
          <Div14>Item Details Page</Div14>
          {content}
        </div>
      );
    }

    let store = modalItemStore;
    let item = modalItemStore.item;
    let past = modalItemStore.pastmoves;
    let upco = modalItemStore.upcomoves;
    let data = past;
    if (this.state.tab !== "1") data = upco;

    let columns = [
      { title: "Order ID", field: "acOrderNumId" },
      {
        title: "Date",
        field: "jobdate",
        render: (row) => moment(row.jobdate).format("DD-MMM-YY"),
      },
      { title: "Type.", field: "fulltype" },
      {
        title: "Qty",
        field: "actualquantity",
        render: (row) => {
          let color = "green";
          if (row.type === "Out") color = "red";
          return (
            <b style={{ color: color }}>
              {color === "green" ? "+" : "-"} {row.actualquantity}
            </b>
          );
        },
      },
      { title: "Warehouse", field: "warehouseshort" },
      {
        title: "AfterStock",
        field: "afterstock",
      },
    ];

    content = (
      <div>
        <FlexboxGrid>
          <Col2>
            <b>Client: </b>
            {item.clientshort}
          </Col2>
          <Col2>
            <b>Warehouse: </b>
            {item.warehouseshort}
          </Col2>
        </FlexboxGrid>
        <Div14>Item Details:</Div14>

        <FlexboxGrid>
          <Col3>
            <b>Item name: </b>
            {item.name}
            <br />

            <b>Serial Number: </b>
            {item.serialnumber}
            <br />
            <b>Product Number: </b>
            {item.productid}
          </Col3>
          <Col3>
            <b>Current Stock: </b>
            {item.currentplusreserve} ({item.onreserve} On Reserve)
            <br />
            <i>
              <b>Damaged Stock: </b>
              {item.damaged}
              <br />
              <b>Entering Stock: </b>
              {item.enteringstock}
            </i>
          </Col3>
          <Col3>
            <b>CBM: </b>
            {item.cbm} CBM/peice
            <br />
            <b>Dimensions: </b>
            {item.dimensions}
            <br />
            <b>Total CBM: </b>
            {item.totalcbm} CBM
          </Col3>
        </FlexboxGrid>
        <Div14>Tags:</Div14>
        <FlexboxGrid>
          <Col2>
            <AddLocationTags />
          </Col2>
          <Col2></Col2>
        </FlexboxGrid>
        <Div14>Movement:</Div14>
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={"top"}
          centered
          onChange={(e) => this.setState({ tab: e })}
        >
          <TabPane tab={`${past.length} Past Movement`} key="1" />
          <TabPane
            tab={`${upco.length} Upcoming Movement`}
            key="2"
            disabled={upco.length === 0}
          />
        </Tabs>
        <MaterialTable
          columns={columns}
          style={{ fontSize: 12 }}
          data={data}
          onRowClick={(event, rowData) => {
            this.onShowTrack(rowData.acorder);
          }}
          isLoading={modalItemStore.loading.item}
          options={{
            actionsColumnIndex: 0,
            exportAllData: false,
            paging: false,
            toolbar: false,
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: false,
            padding: "dense",
            sorting: false,
          }}
        />
        <ItemHistoryGraph store={store} />
        <hr />
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              icon={<Icon icon="building" />}
              color="green"
              block
              onClick={() => {
                this.props.history.push(
                  `/ac/dashboard/${item.account.client._id}`
                );
              }}
            >
              Go to Company Page
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );

    return (
      <div style={{ padding: "2%" }}>
        <UnifiedModal
          show={this.state.showtrack}
          onHide={this.onHideTrack}
          title={`Order# ${this.state.order.acOrderNumId} - ${this.state.order.status}`}
          width="70%"
          footer={
            <ModalFooter
              icon={["close", "chevron-right"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={[
                "Cancel",
                `Yes, go to Order ${this.state.order.acOrderNumId}`,
              ]}
              oC1={() => this.onHideTrack()}
              oC2={() => {
                this.props.history.push(`/acorder/${this.state.order._id}`);
              }}
            />
          }
        >
          Go to Tracking Page for Order # {this.state.order.acOrderNumId}
          <hr />
          <h6>Type: {this.state.order.jobtype}</h6>
          <h6>
            Storage Date: {moment(this.state.order.jobdate).format("DD-MMM-YY")}
          </h6>
        </UnifiedModal>
        <Div14>Item Details Page</Div14>
        {content}
      </div>
    );
  }
}

export default withRouter(observer(SingleItemPage));
