import { makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import moment from "moment";
import sjStore from "./SJStore";
import { SJProposals } from "../../Functions/JobFinances/SJProposals";
import singleJobStore from "../SingleJobStore";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Notification } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class InvoiceStore {
  constructor() {
    this.newinvoice = {
      title: "Easy Move Invoice",
      date: moment(),
      dateto: moment().add(14, "days"),
      status: "Pending",
      remarks: "",
      total: 0,
      jobid: "",
      subJobs: [],
      invtype: "New",

      showBank: "KFH",
      invoiceNumId: "New",
      clientname: "",
      clientemail: "",
      clientphone: "",
      jobNumId: 0,
      _id: "",
    };
    this.recs = [];
    this.invoices = [];
    this.jobid = "";
    this.loading = false;

    makeObservable(this, {
      invoices: observable,
      newinvoice: observable,
      recs: observable,
      jobid: observable,
      loading: observable,
    });
  }

  // FETCH
  getInvoiceByJob(jobid, overide) {
    if (!overide) {
      if (this.loading || this.jobid === jobid) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/invoice/getbyjobid/${jobid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.invoices = res.data;
        this.jobid = jobid;
        this.loading = false;
        console.log("Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  createNewInvoice() {
    this.newinvoice = {
      title: "Easy Move Invoice",
      date: moment(),
      dateto: moment().add(14, "days"),
      status: "Pending",
      remarks: "",
      total: 0,
      jobid: singleJobStore.job._id,
      subJobs: [],
      invtype: "New",

      showBank: "KFH",
      invoiceNumId: "New",
      clientname: singleJobStore.client.name,
      clientemail: singleJobStore.client.email,
      clientphone: singleJobStore.client.phone2
        ? `${singleJobStore.client.phone} / ${singleJobStore.client.phone2}`
        : `${singleJobStore.client.phone}`,
      jobNumId: singleJobStore.job.jobNumId,
      _id: "",
    };
  }

  changeInvoice(inv) {
    this.newinvoice = inv;
  }

  getRecs() {
    let sjs = invoiceStore.newinvoice.subJobs;
    let newsjs = [];
    let ids = sjs.map((sj) => sj._id);
    for (let i = 0; i < ids.length; i++) {
      for (let j = 0; j < sjStore.activeSJs.length; j++) {
        if (ids[i] === `${sjStore.activeSJs[j]._id}`) {
          newsjs.push({ ...sjStore.activeSJs[j] });
        }
      }
    }

    let newlist = SJProposals(newsjs, singleJobStore.job);
    this.recs = newlist;
  }

  editInvoice(val, label) {
    invoiceStore.newinvoice[label] = val;
  }

  addSubJob(sj) {
    let incser = sj.incservices;
    let excser = sj.excservices;
    let items = [];
    let jobdates = [];
    for (let i = 0; i < sj.items.length; i++) {
      items.push({
        itemname: sj.items[i].item,
        qty: sj.items[i].quantity,
        show: true,
      });
    }
    for (let i = 0; i < sj.jobdate.length; i++) {
      jobdates.push({
        date: sj.jobdate[i].date,
        dateto: sj.jobdate[i].dateto,
        from: sj.jobdate[i].from,
        fromtype: sj.jobdate[i].fromtype,
        to: sj.jobdate[i].to,
        totype: sj.jobdate[i].totype,
        type: sj.jobdate[i].type,
        show: true,
      });
    }
    let newsj = {
      numId: sj.sjNumId,
      status: sj.status,
      jobtype: sj.jobtype,
      subtype: sj.subtype,
      _id: sj._id,
      cbm: sj.cbm,
      items: items,
      jobdates: jobdates,
      incservice: incser.join(", "),
      excservice: excser.join(", "),
      proposals: [],
      invnotes: sj.invnotes,
      showinvnotes: true,
    };
    if (newsj.invnotes === "") {
      newsj.showinvnotes = false;
    }
    if (sj.jobtype === "IM") {
      newsj.shippingmethod = sj.im.shippingmethod;
      newsj.direction = sj.im.direction;
      newsj.shipfrom = sj.im.shipfrom;
      newsj.shipto = sj.im.shipto;
      newsj.containertype = sj.im.containertype;
      newsj.commodity = sj.im.commodity;
    }
    if (sj.jobtype === "CC") {
      newsj.blnumber = sj.cc.blnumber;
      newsj.container = sj.cc.container;
      newsj.containercount = sj.cc.containercount;
      newsj.originlocation = sj.cc.originlocation;
      newsj.packages = sj.cc.packages;
      newsj.shippingline = sj.cc.shippingline;
      newsj.shippingmode = sj.cc.shippingmode;
      newsj.invoicetas = sj.cc.invoicetas.join(", ");
    }
    if (sj.jobtype === "ST") {
      newsj.facility = sj.st.facility;
      newsj.inprice = sj.st.inprice;
      newsj.outprice = sj.st.outprice;
      newsj.pricepertime = sj.st.pricepertime;
      newsj.time = sj.st.time;
      newsj.start = sj.st.start;
      newsj.proposals.push({
        added: true,
        ammount: parseFloat(sj.st.pricepertime) * parseFloat(sj.st.time),
        jobid: sj.job,
        jobtype: "ST",
        numId: sj.sjNumId,
        title: `${sj.subtype} Storage`,
        time: sj.st.time,
        type: "ST",
        typeid: "61ec24afd410cbbea88390fd",
      });
    }
    invoiceStore.newinvoice.subJobs.push(newsj);
  }

  removeSubJob(index) {
    invoiceStore.newinvoice.subJobs.splice(index, 1);
  }

  addProp(sjindex, rec) {
    let newrec = { ...rec };
    newrec.added = true;
    invoiceStore.newinvoice.subJobs[sjindex].proposals = [
      ...invoiceStore.newinvoice.subJobs[sjindex].proposals,
      newrec,
    ];
    console.log("Added");
    //invoiceStore.newinvoice.subJobs[sjindex].proposals.push(newrec);
  }
  removeProp(sjindex, index) {
    invoiceStore.newinvoice.subJobs[sjindex].proposals.splice(index, 1);
  }

  editNotes(sjindex) {
    invoiceStore.newinvoice.subJobs[sjindex].showinvnotes =
      !invoiceStore.newinvoice.subJobs[sjindex].showinvnotes;
  }
  editJobDate(sjindex, jdindex) {
    invoiceStore.newinvoice.subJobs[sjindex].jobdates[jdindex].show =
      !invoiceStore.newinvoice.subJobs[sjindex].jobdates[jdindex].show;
  }

  showhideItems(sjindex, itemindex) {
    invoiceStore.newinvoice.subJobs[sjindex].items[itemindex].show =
      !invoiceStore.newinvoice.subJobs[sjindex].items[itemindex].show;
  }
  showHideAllItems(sjindex, type) {
    let items = invoiceStore.newinvoice.subJobs[sjindex].items;
    for (let i = 0; i < items.length; i++) {
      items[i].show = type;
    }
  }

  editStorage(sjindex, val) {
    let oldsj = invoiceStore.newinvoice.subJobs[sjindex];
    oldsj.time = val;

    let newammount = parseFloat(val) * parseFloat(oldsj.pricepertime);
    oldsj.proposals[0].ammount = newammount;
    oldsj.proposals[0].time = val;
  }
}

const invoiceStore = new InvoiceStore();
export default invoiceStore;
