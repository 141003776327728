import { observer } from "mobx-react";
import React, { Component } from "react";
import acInventoryStore from "../../../../../../../Stores/AccountUpdated/Storage/ACInventoryStore";
import StorageCosts from "./StorageCosts";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import StorageChargeHeader from "./StorageChargeHeader";

class StorageCharges extends Component {
  render() {
    return (
      <LoadBack loading={acInventoryStore.loading.finance}>
        <StorageChargeHeader />
        {acInventoryStore.loaded.finance && <StorageCosts />}
      </LoadBack>
    );
  }
}

export default observer(StorageCharges);
