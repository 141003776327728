import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../../../Components/Columns/Col2";
import newStorageStore from "../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

class NextBackButton extends Component {
  render() {
    let step = this.props.step;
    let allsteps = this.props.allsteps;

    let nextBut = `Go to ${allsteps[step]} Details`;
    let backBut = `Back to ${allsteps[step - 2]} Details`;
    let disablenext = false;

    let order = newStorageStore.neworder;

    if ((step > 2) & (allsteps.length === 5)) {
      step = step + 1;
    }
    if (step === 1) {
      backBut = "Back to Account";
    } else if (step === 2) {
      if (order.jobdate === undefined) {
        disablenext = true;
      }
    } else if (step === 3) {
      if (order.toaddress.length <= 0) disablenext = true;
    } else if (step === 4) {
      for (let i = 0; i < newStorageStore.neworder.toaddress.length; i++) {
        if (newStorageStore.neworder.toaddress[i].acitemmove.length <= 0)
          disablenext = true;
      }
    } else if (step === 5) {
      for (let i = 0; i < order.addcharge.length; i++) {
        if (order.addcharge[i].name === "") disablenext = true;
        if (order.addcharge[i].price === "") disablenext = true;
        if (order.addcharge[i].price === 0) disablenext = true;
      }
    } else if (step === 6) {
      nextBut = "Create Order";
    }
    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              icon={<Icon icon="left" />}
              color="red"
              block
              onClick={() => this.props.changeStep(-1)}
            >
              {backBut}
            </IconButton>
            <br />
          </Col2>
          <Col2>
            <IconButton
              icon={<Icon icon="right" />}
              color="green"
              block
              disabled={disablenext}
              onClick={() => {
                if (step === 6) {
                  this.props.postOrder();
                } else {
                  this.props.changeStep(1);
                }
              }}
              placement="right"
            >
              {nextBut}
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(NextBackButton);
