let stafflisttype = [
  "Accountant",
  "Custom Clearance",
  "Driver",
  "Local Move",
  "Management",
  "Mover",
  "Office",
  "Operation",
  "Packer",
  "Team Leader",
  "Warehouse",
];
export default stafflisttype;
