import { observer } from "mobx-react";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class ItemHistoryGraph extends Component {
  render() {
    let options = {
      chart: {
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            zoom: false,
            selection: false,
            reset: false,
            pan: false,
          },
        },
      },
      markers: {
        size: 10,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      yaxis: {
        min: 0,
        tickAmount: 10,
        forceNiceScale: true,
        axisBorder: {
          show: true,
          color: "#78909C",
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: "Inventory CBM",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      colors: ["#96c9ff", "#96ffaa", "#E91E63"],
      dropShadow: { enabled: true, top: 0, left: 0, blur: 3, opacity: 0.5 },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: [undefined, "black", "black"],
        width: 0.5,
        dashArray: 0,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1, 2],
        formatter: function (val, opts) {
          if (val > 0) {
            if (opts.seriesIndex === 1) {
              return `+ ${val}`;
            } else {
              return `- ${val}`;
            }
          } else {
            return "";
          }
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    };

    let store = this.props.store;
    let seriesValue = [
      {
        data: store.itemInv,
        name: "Inventory",
        type: "area",
      },
      {
        data: store.itemIn,
        name: "In Quantity",
        type: "column",
      },
      {
        data: store.itemOut,
        name: "Out Quantity",
        type: "column",
      },
    ];
    return (
      <div>
        <ReactApexChart
          options={options}
          series={seriesValue}
          type="area"
          height={200}
        />
      </div>
    );
  }
}

export default observer(ItemHistoryGraph);
