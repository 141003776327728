import React, { Component } from "react";
import { Tabs } from "antd";
import { observer } from "mobx-react";
import Div14 from "../../../../../../Components/Dividers/Div14";

import CCPrice from "./CCPrice";
import { FlexboxGrid, Notification } from "rsuite";
import SJSelectInput from "../../../../../../Components/Inputs/SJ/SJSelectInput";
import Col2 from "../../../../../../Components/Columns/Col2";
import statuslist from "../../../../../../Static/Lists/StatusList";
import AddChargeTab from "../Others/AddCharge/AddChargeTab";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";

import ItemTab from "../Others/Items/ItemTab";
import JobDateTab from "../Others/JobDate/JobDateTab";
import AddAttTemplate from "../../Attachments/Attachment/Additional/AddAttTemplate";
import cctypes from "../../../../../../Static/Lists/jobsubtypes/cctypes";
import InvoiceTAs from "./InvoiceTAs";
import MainDates from "./MainDates";
import ApprovalDocs from "./ApprovalDocs";

import { CCPriceCalc } from "../../../../../../Functions/Pricing/CCPriceCalc";

import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import DraftEditor from "../../../../../../Components/Inputs/SJ/DraftEditor";
import userStore from "../../../../../../Stores/UserStore";
import {
  CCQualityDocs,
  CCQualityGeneral,
  CCQualityKeyEvents,
} from "../../../../../../Functions/Quality/CCQualityCalc";
import QualityMain from "../Others/Quality/QualityMain";
import QBadge from "../../../../../../Components/Quality/QBadge";
import { JDQualityCalc } from "../../../../../../Functions/Quality/SupportingQuality/JDQualityCalc";
import { AttQualityCalc } from "../../../../../../Functions/Quality/SupportingQuality/ATTQualityCalc";
import cancreasonlist from "../../../../../../Static/Lists/CancellationList";
import SJTextAreaInput from "../../../../../../Components/Inputs/SJ/SJTextAreaInput";

const { TabPane } = Tabs;

class SJCCTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "home", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let load = this.props.load;
    let tabOrientation = "left";
    if (window.innerWidth < 800) tabOrientation = "top";

    let sj = sjStore.sjs[ind];

    let price = CCPriceCalc(sj);

    let addcharges = price[2];

    let datelength = sj.jobdate.length;
    let itemlength = sj.items.length;

    let quality = userStore.user.quality;

    return (
      <div>
        <InvoiceTAs ind={ind} quality={quality} editview={editview} />
        <div style={{ textAlign: "center" }}>
          <b>{PRKD(price[0])} KD Total </b>
          {addcharges > 0
            ? `   = ${PRKD(price[1])} KD Job + ${PRKD(
                addcharges
              )} KD Additional.`
            : ""}
        </div>
        <FlexboxGrid>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="status"
              title="Status"
              load={load.status}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={statuslist}
              read={!editview}
            />
            <br />
            {sj.status === "Cancelled" && (
              <SJSelectInput
                ind={ind}
                label="cancreason"
                title="Canc. Reason"
                load={load.cancreason}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                opts={cancreasonlist}
                read={!editview}
              />
            )}
          </Col2>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="subtype"
              title="Sub Type"
              load={load.subtype}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={cctypes}
              read={!editview}
            />{" "}
            <br />
            {sj.status === "Cancelled" && (
              <SJTextAreaInput
                ind={ind}
                label="cancremarks"
                title="Canc. Remarks"
                load={load.cancremarks}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                read={!editview}
              />
            )}
          </Col2>
        </FlexboxGrid>

        <br />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={tabOrientation}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={
              <div>
                Job Details{" "}
                {quality && <QBadge value={CCQualityGeneral(sj)[0]} />}
              </div>
            }
            key={"home"}
            disabled={this.state.locked}
          >
            <CCPrice
              ind={ind}
              load={this.props.load}
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              editview={editview}
            />
            <Div14>Additional Charges (+ {PRKD(addcharges)} KD)</Div14>
            <AddChargeTab ind={ind} editview={editview} />
          </TabPane>

          <TabPane
            tab={
              <div>
                Events {quality && <QBadge value={CCQualityKeyEvents(sj)[0]} />}
              </div>
            }
            key={2}
            disabled={this.state.locked}
          >
            <MainDates ind={ind} quality={quality} editview={editview} />
          </TabPane>
          <TabPane
            tab={
              <div>
                Docs Events {quality && <QBadge value={CCQualityDocs(sj)[0]} />}
              </div>
            }
            key={3}
            disabled={this.state.locked}
          >
            <ApprovalDocs ind={ind} quality={quality} editview={editview} />
          </TabPane>

          <TabPane
            tab={
              <div>
                Add Docs {quality && <QBadge value={AttQualityCalc(sj)[0]} />}
              </div>
            }
            key={4}
            disabled={this.state.locked}
          >
            <AddAttTemplate
              ind={ind}
              attType={"Additional"}
              quality={quality}
              editview={editview}
            />
          </TabPane>

          <TabPane
            tab={
              <div>
                {datelength} Date{datelength !== 1 ? "s" : ""}{" "}
                {quality && <QBadge value={JDQualityCalc(sj)[0]} />}
              </div>
            }
            key={5}
            disabled={this.state.locked}
          >
            <JobDateTab ind={ind} quality={quality} editview={editview} />
          </TabPane>
          <TabPane
            tab={`${itemlength} Item${itemlength !== 1 ? "s" : ""}`}
            key={6}
            disabled={this.state.locked}
          >
            <ItemTab ind={ind} editview={editview} />
          </TabPane>

          {editview && (
            <TabPane tab={`Inv Notes`} key={8} disabled={this.state.locked}>
              <DraftEditor
                label="invnotes"
                title="Invoice Notes"
                load={load.invnotes}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                ind={ind}
              />
            </TabPane>
          )}
          {quality && (
            <TabPane tab={`Quality`} key={10} disabled={this.state.locked}>
              <QualityMain sj={sj} />
            </TabPane>
          )}
        </Tabs>
        <br />
      </div>
    );
  }
}

export default observer(SJCCTabs);
