import { observer } from "mobx-react";
import React, { Component } from "react";

import { Empty } from "antd";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  FlexboxGrid,
  Icon,
  IconButton,
} from "rsuite";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import Div14 from "../../../../../Components/Dividers/Div14";
import Col2 from "../../../../../Components/Columns/Col2";
import Col4 from "../../../../../Components/Columns/Col4";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import DACStep2Supporting from "./DACStep2Supporting";

class DACStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      newto: {
        type: "",
        city: "",
        floor: 0,
        address: "",
        name: "",
        phone: "",
        selected: "",
        zonecost: 0,
        zonetype: 0,
        ngfchg: 0,
      },
    };
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.selectLoc = this.selectLoc.bind(this);
    this.addToOrder = this.addToOrder.bind(this);
  }
  editVal(val, label) {
    let newto = { ...this.state.newto };
    newto[label] = val;
    this.setState({ newto: newto });
  }
  selectLoc(loc) {
    this.setState({ newto: loc });
  }
  onHide() {
    this.setState({
      show: false,
      newto: {
        type: "",
        city: "",
        floor: 0,
        address: "",
        name: "",
        phone: "",
        selected: "",
      },
    });
  }
  addToOrder(zchg, ztype, fchg) {
    let newto = { ...this.state.newto };
    newto.zonecost = zchg;
    newto.zonetype = ztype;
    newto.ngfchg = fchg;
    this.setState({ newto: newto });

    newDeliveryStore.addNewTo(newto);
    this.onHide();
  }

  render() {
    let tos = newDeliveryStore.newdel.toaddress;

    let header = (
      <FlexboxGrid justify="center">
        <Col2>
          <IconButton
            color="green"
            icon={<Icon icon="plus" />}
            loading={this.state.loading}
            onClick={() => this.setState({ show: true })}
            block
          >
            Add an Address
          </IconButton>
        </Col2>
      </FlexboxGrid>
    );

    let content = (
      <FlexboxGrid justify="center">
        <Empty description="Please add a Dropoff Address above." />
      </FlexboxGrid>
    );

    if (tos.length > 0) {
      content = tos.map((to, index) => (
        <FlexboxGrid
          key={index}
          justify="center"
          style={{
            textAlign: "center",
            border: "1px solid black",
            margin: "2px",
            padding: "2px",
            borderRadius: "5px",
          }}
        >
          <Col4>
            {to.name}: {to.city}
          </Col4>
          <Col2>{to.address}</Col2>
          <Col4>
            <ButtonToolbar>
              <ButtonGroup>
                <IconButton
                  icon={<Icon icon="down" />}
                  color="red"
                  appearance="ghost"
                  disabled={index === tos.length - 1}
                  onClick={() => newDeliveryStore.moveNewTo(index, "down")}
                />
                <Button
                  appearance="ghost"
                  style={{
                    backgroundColor: "white",
                    fontWeight: "bold",
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  {index + 1}
                </Button>
                <IconButton
                  icon={<Icon icon="up" />}
                  color="green"
                  appearance="ghost"
                  disabled={index === 0}
                  onClick={() => newDeliveryStore.moveNewTo(index, "up")}
                />
              </ButtonGroup>
              <IconButton
                icon={<Icon icon="trash" />}
                color="red"
                size="sm"
                circle
                appearance="primary"
                onClick={() => newDeliveryStore.deleteTo(index)}
              />
            </ButtonToolbar>
          </Col4>
        </FlexboxGrid>
      ));
    }
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={false}
          title={"New Dropoff Address"}
        >
          <DACStep2Supporting
            loc={this.state.newto}
            editVal={this.editVal}
            selectLoc={this.selectLoc}
            addToOrder={this.addToOrder}
            onHide={this.onHide}
          />
        </UnifiedModal>

        <Div14>Dropoff Locations</Div14>
        {header}
        <hr />
        <div>{content}</div>
      </div>
    );
  }
}

export default observer(DACStep2);
