import { observer } from "mobx-react";
import React, { Component } from "react";
import { Descriptions } from "antd";

class BasicDesc extends Component {
  render() {
    let lblst = { fontWeight: "bold", fontSize: 12 };
    let cntst = { fontSize: 12 };
    let items = this.props.items;
    if (this.props.dark) {
      lblst = { fontWeight: "bold", backgroundColor: "#d4d4d4", fontSize: 12 };
      cntst = lblst;
    }

    let rows = items.map((item, index) => (
      <Descriptions.Item
        labelStyle={lblst}
        label={item.label}
        span={item.span}
        key={index}
        contentStyle={cntst}
      >
        {item.val}
      </Descriptions.Item>
    ));
    return (
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        {rows}
      </Descriptions>
    );
  }
}

export default observer(BasicDesc);
