import { observer } from "mobx-react";
import React, { Component } from "react";

import { Descriptions } from "antd";

import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class OrderSummaryTable extends Component {
  render() {
    let del = this.props.del;

    let timechg = this.props.timechg;

    let greycolor = "#cccccc";
    let lightcolor = "#ebebeb";
    let options = del.orderoptions;
    let addchgs = del.addcharge;
    let discount = del.discount;
    let allcharges = timechg.allcharges;
    let loccharges = timechg.loccharges;

    let chgmap;
    let locmap;
    let optmap;
    let discmap;
    let addmap;

    if (allcharges.length > 0) {
      chgmap = allcharges.map((chg, index) => (
        <Descriptions.Item
          label={`${PRKD(chg.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title}
        </Descriptions.Item>
      ));
    }
    if (loccharges.length > 0) {
      locmap = loccharges.map((chg, index) => (
        <Descriptions.Item
          label={`${PRKD(chg.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title}
        </Descriptions.Item>
      ));
    }

    if (options.length > 0) {
      optmap = options.map((opt, index) => (
        <Descriptions.Item
          label={`${PRKD(opt.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {opt.title}
        </Descriptions.Item>
      ));
    }
    if (addchgs.length > 0) {
      addmap = addchgs.map((add, index) => (
        <Descriptions.Item
          label={`${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {add.name}
        </Descriptions.Item>
      ));
    }
    if (discount.length > 0) {
      discmap = discount.map((add, index) => (
        <Descriptions.Item
          label={`-${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          Discount: {add.name}
        </Descriptions.Item>
      ));
    }
    let allservices = [];

    if (newDeliveryStore.delCont.maintype === "Fixed Per Type") {
      for (let i = 0; i < del.toaddress.length; i++) {
        allservices = [
          ...allservices,
          {
            type: `Del ${i + 1}: ${del.toaddress[i].service.type}`,
            price: del.toaddress[i].service.price,
          },
        ];
      }
    }

    return (
      <div>
        <Descriptions title="Cost Breakdown" bordered size="small">
          <Descriptions.Item
            label={`${PRKD(timechg.finaljobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
          <Descriptions.Item
            label={`${PRKD(timechg.mainjobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: lightcolor,
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: lightcolor }}
          >
            Main Service
          </Descriptions.Item>
          {chgmap}
          {timechg.totalloccharge > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totalloccharge)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Delivery Location Charges
              </Descriptions.Item>
              {locmap}
            </>
          )}
          {timechg.totalopts > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totalopts)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Selected Options
              </Descriptions.Item>
              {optmap}
            </>
          )}
          {timechg.totaladdchg > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totaladdchg)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Additional Charges
              </Descriptions.Item>
              {addmap}
            </>
          )}
          {timechg.totaldiscount > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totaldiscount)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Discounts
              </Descriptions.Item>
              {discmap}
            </>
          )}
          <Descriptions.Item
            label={`${PRKD(timechg.finaljobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
        </Descriptions>
        <hr />
      </div>
    );
  }
}

export default observer(OrderSummaryTable);
