import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol15white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol10white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol15blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol10blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
});

class StorageCharges extends Component {
  render() {
    let inv = this.props.inv;

    let allcharges = inv.charges.filter((chg) => chg.storchg > 0);

    let charges = allcharges.map((chg, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol15white}>
          {moment(chg.startday).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol15white}>
          {moment(chg.endday).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.cbmstart) > 0 ? PRKD(chg.cbmstart) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.storin) > 0 ? PRKD(chg.storin) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.storout) > 0 ? PRKD(chg.storout) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.cbmend) > 0 ? PRKD(chg.cbmend) : "-"}
        </Text>
        <Text style={styles.tableCol15white}>
          {PRKD(chg.freqchg) > 0 ? PRKD(chg.freqchg) : "-"}
        </Text>
        <Text style={styles.tableCol15white}>{PRKD(chg.storchg)}</Text>
      </View>
    ));
    let totalstg = 0;
    let totalin = 0;
    let totalout = 0;
    let totalchgcbm = 0;

    for (let i = 0; i < inv.charges.length; i++) {
      totalstg += Parsed(inv.charges[i].storchg);
      totalin += Parsed(inv.charges[i].storin);
      totalout += Parsed(inv.charges[i].storout);
      totalchgcbm += Parsed(inv.charges[i].freqchg);
    }

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>Detailed Storage Charges</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol15blue}>Start</Text>
          <Text style={styles.tableCol15blue}>End</Text>
          <Text style={styles.tableCol10blue}>Start CBM</Text>
          <Text style={styles.tableCol10blue}>In CBM</Text>
          <Text style={styles.tableCol10blue}>Out CBM</Text>
          <Text style={styles.tableCol10blue}>End CBM</Text>
          <Text style={styles.tableCol15blue}>Chargeable CBM</Text>
          <Text style={styles.tableCol15blue}>Storage Charge KD</Text>
        </View>
        {charges}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>
            {inv.charges.length} Charges
          </Text>
          <Text style={styles.tableCol10blue}>-</Text>
          <Text style={styles.tableCol10blue}>
            {PRKD(totalin) > 0 ? PRKD(totalin) : "-"}
          </Text>
          <Text style={styles.tableCol10blue}>
            {PRKD(totalout) > 0 ? PRKD(totalout) : "-"}
          </Text>
          <Text style={styles.tableCol10blue}>-</Text>
          <Text style={styles.tableCol15blue}>{PRKD(totalchgcbm)}</Text>
          <Text style={styles.tableCol15blue}>{PRKD(totalstg)}</Text>
        </View>
      </View>
    );
  }
}

export default observer(StorageCharges);
