import React, { Component } from "react";

import { withRouter } from "react-router";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  MasterDetail,
  Item,
  Toolbar,
  LoadPanel,
} from "devextreme-react/data-grid";
import { observer } from "mobx-react";
import Button from "devextreme-react/button";

import ACOrderDetails from "./ACOrderDetails";
import { Icon, IconButton } from "rsuite";
import damageReportStore from "../../../Stores/Account/DamageReportStore";

class DamageReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.GoToOrder = this.GoToOrder.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
  }

  GoToOrder(cellData) {
    return (
      <IconButton
        onClick={() =>
          this.props.history.push(`/acorder/${cellData.data.acorder._id}`)
        }
        icon={<Icon icon={"plane"} />}
        size="xs"
        color="green"
        circle
      />
    );
  }

  refreshTable() {
    if (this.props.type === "closed") {
      damageReportStore.getClosedDamageReports(true);
    } else if (this.props.type === "pending") {
      damageReportStore.getPendingDamageReports(true);
    }
  }

  componentDidMount() {
    if (this.props.type === "closed") {
      damageReportStore.getPendingDamageReports();
    } else if (this.props.type === "pending") {
      damageReportStore.getPendingDamageReports();
    }
  }

  render() {
    return (
      <div>
        <DataGrid
          dataSource={this.props.data}
          keyExpr="damageReportNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <LoadPanel enabled={true} />
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <MasterDetail enabled={true} component={ACOrderDetails} />
          <Column
            dataField="damageReportNumId"
            dataType="number"
            caption="ID"
            alignment="center"
            width={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            width={80}
          />
          <Column
            dataField="acorder.jobdate"
            dataType="date"
            caption="Job Date"
            alignment="center"
            format={"dd-MMM-yy"}
            minWidth={80}
          />
          <Column
            dataField="createdAt"
            dataType="date"
            caption="Report Date"
            alignment="center"
            format={"dd-MMM-yy"}
            minWidth={80}
          />
          <Column
            dataField="requester.username"
            dataType="text"
            caption="User"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="item.length"
            caption="Items"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="acorder.acOrderNumId"
            dataType="number"
            caption="Order ID"
            alignment="center"
            minWidth={80}
          />

          <Column
            caption="Go To Order"
            alignment="center"
            minWidth={80}
            cellRender={this.GoToOrder}
          />

          <Summary>
            <TotalItem
              column="acInvoiceNumId"
              summaryType="count"
              displayFormat={"{0}"}
            />
            <TotalItem
              column="totalstorage"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="totaldelivery"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="totalcharges"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
          </Summary>
          <Toolbar>
            <Item name="Refresh" location="before">
              <Button icon="refresh" onClick={() => this.refreshTable()} />
            </Item>

            <Item name="columnChooserButton" />
            <Item name="exportButton" />
          </Toolbar>
          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(DamageReportTable));
