import React, { Component } from "react";

import { observer } from "mobx-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FlexboxGrid, HelpBlock } from "rsuite";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import Col2 from "../../../../../../../../Components/Columns/Col2";
import delInvoiceStore from "../../../../../../../../Stores/Financial/DelInvoiceStore";

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      modules: {
        toolbar: [
          ["italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      },
    };
  }
  setValue(e) {
    this.setState({ value: e });
  }

  render() {
    return (
      <div>
        <Div14>Step 3: Invoice Notes (Optional)</Div14>

        <FlexboxGrid justify="center">
          <Col2>
            <h4>Invoice Notes</h4>
            <HelpBlock>
              <div style={{ color: "black" }}>
                Notes to be added in the invoice section.
              </div>
              All font sizes will be reset, Bold and Colors will NOT show.
            </HelpBlock>
          </Col2>
        </FlexboxGrid>
        <br />
        <ReactQuill
          theme="snow"
          value={delInvoiceStore.newdelinvoice.invnotes}
          onChange={(e) => delInvoiceStore.editInvoice(e, "invnotes")}
          modules={this.state.modules}
        />
        <br />
      </div>
    );
  }
}

export default observer(Step3);
