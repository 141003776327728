import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../Static/baseURL/baseURL";
import userStore from "./UserStore";

const instance = axios.create({ baseURL: baseURL });

class NotificationStore {
  constructor() {
    this.read = [];
    this.unread = [];
    this.loading = { read: false };
    this.loaded = { read: false };

    makeObservable(this, {
      read: observable,
      unread: observable,
      loading: observable,
    });
  }

  // FETCH
  getNotifications(overide) {
    if (!overide) {
      if (this.loading.unread || this.loaded.unread) return null;
    }
    this.loading.unread = true;

    return instance
      .get(`/auth/notifications/unread`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((notifys) => {
        this.unread = notifys;

        this.loading.unread = false;
        this.loaded.unread = true;
        console.log("Notifications Fetched");
      })
      .catch((err) => {
        this.loading.unread = false;
        this.loaded.unread = false;

        console.log(err);

        Notification["error"]({
          title: `Error Fetching Notifications.`,
        });
      });
  }
  getAllNotifications(overide) {
    if (!overide) {
      if (this.loading.unread) return null;
    }
    this.loading.unread = true;

    return instance
      .get(`/auth/notifications/getall`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((notifys) => {
        this.unread = notifys.unread;
        this.read = notifys.read;
        this.loading.unread = false;
        this.loaded.unread = true;
        console.log("Notifications Fetched");
      })
      .catch((err) => {
        this.loading.unread = false;
        this.loaded.unread = false;

        console.log(err);

        Notification["error"]({
          title: `Error Fetching Notifications.`,
        });
      });
  }
  deleteNotification(id) {
    let oldread = [...notificationStore.read];
    let oldunread = [...notificationStore.unread];
    let newread = [];
    let newunread = [];
    let ind = oldread.findIndex((od) => od._id === id);
    if (ind >= 0) {
      for (let i = 0; i < oldread.length; i++) {
        if (i !== ind) {
          newread = [...newread, oldread[i]];
        }
      }
      notificationStore.read = [...newread];
    } else {
      ind = oldunread.findIndex((od) => od._id === id);
      if (ind >= 0) {
        for (let i = 0; i < oldunread.length; i++) {
          if (i !== ind) {
            newunread = [...newunread, oldunread[i]];
          }
        }
        notificationStore.unread = [...newunread];
      }
    }
    if (ind >= 0) {
      return instance
        .get(`/auth/notifications/deletenotification/${id}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((notifys) => {
          console.log("Notification Deleted");
        })
        .catch((err) => {
          console.log(err);

          Notification["error"]({
            title: `Error Deleting Notification.`,
          });
        });
    }
  }
  deleteAllNotifications(id) {
    notificationStore.read = [];
    notificationStore.unread = [];

    return instance
      .get(`/auth/notifications/deleteallnotifys`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((notifys) => {
        console.log("All Notification Deleted");
      })
      .catch((err) => {
        console.log(err);

        Notification["error"]({
          title: `Error Deleting All Notification.`,
        });
      });
  }

  markAsRead() {
    let nfts = notificationStore;
    nfts.read = [...nfts.read, ...nfts.unread];
    nfts.unread = [];
    return instance
      .get(`/auth/notifications/markasread`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((notifys) => {
        console.log("Notifications Read");
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

const notificationStore = new NotificationStore();
export default notificationStore;
