import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import AuthMain from "./AuthMain";
import PasswordInput from "../../../Components/Inputs/Auth/PasswordInput";
import UserNameInput from "../../../Components/Inputs/Auth/UserNameInput";
import userStore from "../../../Stores/UserStore";
import { Button } from "rsuite";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      username: "",
      password: "",
      passconf: "",
      email: "",
      checked: false,
    };
    this.changeView = this.changeView.bind(this);
  }

  changeView(type, val) {
    if (type === "username") {
      // val = val.replace(/[^a-zA-Z1-9]/g, "").toLowerCase();
      val = val.toLowerCase();
    }
    this.setState({ [type]: val });
  }

  render() {
    let emailerror = { val: false, message: "" };
    let passerror = { val: false, message: "" };
    let disabledButton = false;
    let user = {
      username: this.state.username,
      password: this.state.password,
    };

    if (user.username.length < 3) {
      disabledButton = true;
      emailerror = {
        val: true,
        message: "Please write a valid Username or Email.",
      };
    } else if (user.password.length < 6) {
      disabledButton = true;
      passerror = {
        val: true,
        message: "Password must be 6 Characters or more.",
      };
    }
    if (!this.state.checked) {
      emailerror = { val: false, message: "" };
      passerror = { val: false, message: "" };
    }

    return (
      <AuthMain type="login">
        <h4 style={{ textAlign: "center" }}> Login To Your Account </h4>
        <hr />
        <UserNameInput
          data={user.username}
          err={emailerror.val}
          errmessage={emailerror.message}
          type={"username"}
          label="Username / Email"
          disabled={userStore.loading}
          autoFocus={true}
          action={this.changeView}
        />
        <PasswordInput
          data={user.password}
          err={passerror.val}
          errmessage={passerror.message}
          type={"password"}
          label="Password"
          disabled={userStore.loading}
          loading={userStore.loading}
          autoFocus={false}
          autocomplete={"current-password"}
          action={this.changeView}
        />
        <br />
        <Button
          block
          color={"green"}
          disabled={disabledButton}
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => {
            this.setState({ checked: true });
            let userData = {
              username: user.username,
              email: user.username,
              password: user.password,
              validation: user.username.length >= 3,
            };
            userStore.loginUser(userData);
          }}
        >
          {userStore.loading ? "Loading ..." : "Login"}
        </Button>
      </AuthMain>
    );
  }
}

export default withRouter(observer(LoginPage));
