import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { ItemSummaryCalc } from "../../../../../../Functions/Other/ItemSummaryCalc";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";

class PackingListSummary extends Component {
  render() {
    let items = this.props.items;

    let itemcalc = ItemSummaryCalc(items);

    return (
      <div>
        <p>
          <Div14>Packing List: </Div14>
          {itemcalc[0]} Items with {itemcalc[1]} Packages
          <br />
          {PRKD(itemcalc[2])} CBM vs {PRKD(itemcalc[3])} CBM (from dimensions)
        </p>
      </div>
    );
  }
}

export default observer(PackingListSummary);
