import React, { Component } from "react";

import { Col } from "rsuite";

class Col4 extends Component {
  render() {
    let style;
    if (this.props.style) style = this.props.style;
    return (
      <Col md={6} sm={12} xs={24} style={style}>
        {this.props.children}
      </Col>
    );
  }
}

export default Col4;
