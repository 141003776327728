import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid } from "rsuite";
import Div14 from "../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import newACCOrderStore from "../../../../../Stores/AccountUpdated/ACC/NewACCStore";

class AIMStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let acc = newACCOrderStore.newacc;

    return (
      <div>
        <Div14>Step 1: Main Shipment Information</Div14>
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "5px",
            boxShadow: "1px 1px 5px black",
          }}
        >
          <FlexboxGrid justify="center">
            <Col md={24} sm={24} xs={24}>
              <SelectInputSTD
                title="Shipping Method"
                val={acc.shippingmode}
                label="shippingmode"
                size="large"
                opts={["Air", "Land", "Sea"]}
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
            <Col md={24} sm={24} xs={24}>
              <TextInputSTD
                title="Shipping Line"
                val={acc.shippingline}
                label="shippingline"
                size="large"
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
            <Col md={24} sm={24} xs={24}>
              <TextInputSTD
                title="Commodity"
                val={acc.commodity}
                label="commodity"
                size="large"
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
            <Col md={24} sm={24} xs={24}>
              <TextInputSTD
                title="Brand / Supplier"
                val={acc.brandsupplier}
                label="brandsupplier"
                size="large"
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
            <Col md={24} sm={24} xs={24}>
              <TextInputSTD
                title="Origin Location"
                val={acc.originlocation}
                label="originlocation"
                size="large"
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
            <Col md={24} sm={24} xs={24}>
              <TextInputSTD
                title="BL Number (Optional)"
                val={acc.blnumber}
                label="blnumber"
                size="large"
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
          </FlexboxGrid>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(AIMStep1));
