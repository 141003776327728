import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";

import { Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";

import { withRouter } from "react-router";

import { Statistic, Tabs } from "antd";
import warehouseAccountStore from "../../../../../Stores/AccountUpdated/Storage/WarehouseAccountStore";
import Col4 from "../../../../../Components/Columns/Col4";
import Div14 from "../../../../../Components/Dividers/Div14";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import WarehouseAccountsMap from "./WarehouseAccountsMap";

const { TabPane } = Tabs;

class WarehouseClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobs: null,
      loading: false,
      clientname: "",
      clientid: "",
      tab: "Active",
    };
    this.selectAccount = this.selectAccount.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }
  componentDidMount() {
    warehouseAccountStore.getAccounts();
  }
  changeVal(label, val) {
    this.setState({ [label]: val });
  }
  selectAccount(show, clientname, clientid) {
    this.setState({
      showJobs: show,
      clientname: clientname,
      clientid: clientid,
    });
  }

  render() {
    let data = warehouseAccountStore.accounts;

    if (this.state.tab === "Pending") data = warehouseAccountStore.pending;
    if (this.state.tab === "Expired") data = warehouseAccountStore.expired;

    let tableTitle = `${this.state.tab} Warehouse Account Clients`;
    let columnnames = [
      {
        title: "ID",
        field: "clientNumId",
        removable: true,
      },
      { title: "Name", field: "name", removable: true },
      { title: "Type", field: "type", removable: true },
      { title: "Email", field: "email", removable: true },
      {
        title: "Phone",
        field: "phone",
        removable: true,
        render: (rowData) => {
          return (
            <p>
              {rowData.phone2 === ""
                ? rowData.phone
                : rowData.phone + " / " + rowData.phone2}
            </p>
          );
        },
      },
      {
        title: "Status",
        field: "status",
        removable: true,
        render: (rowData) => {
          return (
            <div
              style={{
                fontWeight: "bold",
                color:
                  rowData.status === "Active"
                    ? "green"
                    : rowData.status === "Pending"
                    ? "orange"
                    : "red",
              }}
            >
              {rowData.status}
            </div>
          );
        },
      },
      {
        title: "CBM",
        field: "totalcbm",
        defaultSort: "desc",
        render: (rowData) => {
          return PRKD(rowData.totalcbm);
        },
      },
      { title: "QTY", field: "totalqty" },
      {
        title: "STO Upd.",
        field: "whupdatedate",
        render: (rowData) => moment(rowData.whupdatedate).fromNow(),
      },
      {
        title: "WH",
        field: "emloc",
        removable: true,
        render: (rowData) => {
          return rowData.emloc;
        },
      },
      {
        title: "STO",
        field: "ast",
        removable: true,
        render: (rowData) => {
          let check = rowData.ast;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Del",
        field: "adel",
        removable: true,
        render: (rowData) => {
          let check = rowData.adel;
          if (rowData.hidedel) check = false;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },

      {
        title: "AIM",
        field: "aim",
        removable: true,
        render: (rowData) => {
          let check = rowData.aim;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "ACC",
        field: "acc",
        removable: true,
        render: (rowData) => {
          let check = rowData.acc;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
    ];

    let tabledata = (
      <>
        <FlexboxGrid justify="end" style={{ paddingRight: "5%" }}>
          <Tooltip title={"Add New Client"} arrow placement="left">
            <IconButton
              color="green"
              circle
              icon={<Icon icon="plus" />}
              onClick={() => this.props.history.push("/accounts/new")}
            />
          </Tooltip>
          <span style={{ width: 10 }} />
          <Tooltip title={"Refresh List"} arrow placement="bottom">
            <IconButton
              loading={warehouseAccountStore.loading.accounts}
              onClick={() => {
                if (
                  this.state.tab === "Pending" ||
                  this.state.tab === "Expired"
                ) {
                  warehouseAccountStore.getCustom(this.state.tab, true);
                } else {
                  warehouseAccountStore.getAccounts(true);
                }
              }}
              icon={<Icon icon="refresh" />}
              circle
              block
            />
          </Tooltip>
        </FlexboxGrid>
        <MaterialTable
          isLoading={warehouseAccountStore.loading.accounts}
          title={data.length + " " + tableTitle}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 20,
            pageSizeOptions: [20, 50],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : tableTitle
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: "business",
              tooltip: "Open Client",
              onClick: (event, rowData) =>
                this.props.history.push(`/ac/dashboard/${rowData._id}`),
            },
          ]}
        />
      </>
    );
    let loaded = true;
    if (this.state.tab === "Pending")
      loaded = warehouseAccountStore.loaded.pending;
    if (this.state.tab === "Expired")
      loaded = warehouseAccountStore.loaded.expired;

    if (!loaded) {
      tabledata = (
        <FlexboxGrid justify="center">
          <Col4>
            <IconButton
              icon={<Icon icon="reload" />}
              loading={warehouseAccountStore.loading.accounts}
              onClick={() =>
                warehouseAccountStore.getCustom(this.state.tab, true)
              }
              block
              color="green"
            >
              Load {this.state.tab} Accounts
            </IconButton>
          </Col4>
        </FlexboxGrid>
      );
    }

    let whSummary = warehouseAccountStore.breakdownPerWH;

    let output = (
      <FlexboxGrid justify="center">
        {whSummary.map((wh, index) => (
          <Col4 key={index}>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title={`${wh.name} Summary`}
                value={wh.cbm}
                suffix={"CBM"}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
              <Statistic
                title={null}
                value={wh.qty}
                suffix={"QTY"}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
        ))}
      </FlexboxGrid>
    );

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <Div14>Warehouse Accounts:</Div14>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={"Active"}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Active"} key={"Active"}></TabPane>
          <TabPane tab={"Pending"} key={"Pending"}></TabPane>
          <TabPane tab={"Expired"} key={"Expired"}></TabPane>
        </Tabs>

        {this.state.tab === "Active" && output}

        {tabledata}

        <hr />
        {this.state.tab === "Active" && <WarehouseAccountsMap />}
      </div>
    );
  }
}

export default withRouter(observer(WarehouseClients));
