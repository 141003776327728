import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import singleJobStore from "../../../../Stores/SingleJobStore";
import ClientSection from "./Sections/ClientSection";

import PaymentSummary from "./Sections/PaymentSummary";
import SJSection from "./Sections/SJSection";

class SummaryMain extends Component {
  render() {
    let sjs = this.props.sjs.filter((sj) => sj.status !== "Cancelled");
    // let sjs = sjStore.sjs.filter(
    //   (sj) => (sj.status !== "Cancelled") & (sj.status !== "Converted")
    // );

    let sjscard;
    if (sjs.length > 0) {
      sjscard = sjs.map((sj, index) => <SJSection data={sj} key={index} />);
    }

    return (
      <div>
        <Div14>Job Summary</Div14>
        <ClientSection data={singleJobStore.client} />
        <PaymentSummary />
        <Div14>Sub Jobs:</Div14>
        {sjscard}
      </div>
    );
  }
}

export default observer(SummaryMain);
