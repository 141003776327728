import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { DatePicker } from "antd";
import PaymentTable from "./PaymentTable";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { Icon, InputGroup } from "rsuite";

const { RangePicker } = DatePicker;
class PaymentMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,

      payment: {
        type: "Income",
        subtype: "Account Related",
        cash: "Cash",
        date: moment(),
        method: "Bank Transfer",
        account: "KFH",
        paymentref: "",
        amount: 0,
        remarks: "",
        accounttype: "Storage Account",
        client: "",
      },
    };

    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  componentDidMount() {
    staffStorageStore.getAllPayments();
  }
  editVal(val, label) {
    let oldpayment = { ...this.state.payment };
    oldpayment[label] = val;
    this.setState({ payment: oldpayment });
  }
  onHide() {
    this.setState({ show: false, loading: false });
  }
  render() {
    return (
      <LoadBack loading={staffStorageStore.loading.payments}>
        <Div14>Payment Main</Div14>
        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <InputGroup>
            <InputGroup.Addon>Select Range</InputGroup.Addon>
            <RangePicker
              size="large"
              picker="month"
              format={"MMM-YY"}
              style={{ width: "100%" }}
              onChange={(e) => staffStorageStore.changeDateRange(e)}
              value={[staffStorageStore.startdate, staffStorageStore.enddate]}
            />
            <InputGroup.Button
              color="green"
              loading={staffStorageStore.loading.invoices}
              onClick={() => staffStorageStore.getAllPayments(true)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <PaymentTable newPayment={this.newPayment} />
      </LoadBack>
    );
  }
}

export default withRouter(observer(PaymentMain));
