import { observer } from "mobx-react";
import React, { Component } from "react";
import { Statistic } from "antd";
import { FlexboxGrid, HelpBlock } from "rsuite";
import moment from "moment";

import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import acDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/ACDeliveryStore";
import Col4 from "../../../../../../Components/Columns/Col4";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import FlexboxButton from "../../../../../../Components/Modal/FlexboxButton";
import delInvoiceStore from "../../../../../../Stores/Financial/DelInvoiceStore";

class DeliveryChargeHeader extends Component {
  render() {
    let data = acDeliveryStore.client;
    let daysactive = moment().diff(data.startdate, "days");
    if (data.status !== "Active") {
      daysactive = moment(data.enddate).diff(data.startdate, "days");
    }
    let delCont = data.deliveryContract;

    let custom1 = { title: "", val: "", suffix: "", hide: true };
    let custom2 = { title: "", val: "", suffix: "", hide: true };
    let custom3 = { title: "", val: "", suffix: "", hide: true };
    if (delCont.maintype === "Fixed Per Type") {
      let totalcost = 0;
      for (let i = 0; i < delCont.types.length; i++) {
        totalcost += Parsed(delCont.types[i].price);
      }

      custom1 = { title: "Type Available", val: delCont.types.length };
      custom2 = {
        title: "Avg Type Cost",
        val: PRKD(totalcost / delCont.types.length),
        suffix: "KD",
      };
    } else if (delCont.maintype === "Dedicated Staff") {
      custom1 = { title: "Weekly Rate", val: delCont.staffcount, suffix: "KD" };
      custom2 = { title: "Staff Count", val: delCont.staffcount };
      custom3 = { title: "Truck Count", val: delCont.carcount };
    } else if (delCont.maintype === "Dedicated Staff - Monthly") {
      custom1 = {
        title: "Monthly Rate",
        val: delCont.fixedweekly,
        suffix: "KD",
      };
      custom2 = { title: "Staff Count", val: delCont.staffcount };
      custom3 = { title: "Truck Count", val: delCont.carcount };
    } else if (delCont.maintype === "Daily Per Truck") {
      custom1 = {
        title: "Price Per Truck",
        val: delCont.pricepertruck,
        suffix: "KD",
      };
      custom2 = { title: "Min Daily Trucks", val: delCont.carcount };
    } else if (delCont.maintype === "Fixed Per Trip") {
      custom1 = {
        title: "Max CBM Per Trip",
        val: delCont.maxcbmpertrip,
        suffix: "CBM",
      };
    }

    let totalchg = 0;
    let totalinv = delInvoiceStore.totalinvandpays.totalinv;
    let totalpaid = delInvoiceStore.totalinvandpays.totalpaid;

    if (acDeliveryStore.loaded.delcharges) {
      let delchgs = acDeliveryStore.delcharges;
      for (let i = 0; i < delchgs.length; i++) {
        totalchg += Parsed(delchgs[i].finalcost);
      }
    }
    let totalremaining = totalchg - totalpaid;

    return (
      <LoadBack loading={acDeliveryStore.loading.order}>
        {!this.props.hidedetails && (
          <>
            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Contract Status"
                  value={data.status}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Start Date"
                  value={moment(data.startdate).format("DD-MMM-YYYY")}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="End Date"
                  value={moment(data.enddate).format("DD-MMM-YYYY")}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Days Active"
                  value={daysactive}
                  suffix="days"
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
            </FlexboxGrid>

            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Type"
                  value={delCont.maintype}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              {!custom1.hide && (
                <Col4>
                  <Statistic
                    title={custom1.title}
                    value={custom1.val}
                    style={{ textAlign: "center" }}
                    suffix={custom1.suffix}
                  />
                  <hr />
                </Col4>
              )}
              {!custom2.hide && (
                <Col4>
                  <Statistic
                    title={custom2.title}
                    value={custom2.val}
                    style={{ textAlign: "center" }}
                    suffix={custom2.suffix}
                  />
                  <hr />
                </Col4>
              )}
              {!custom3.hide && (
                <Col4>
                  <Statistic
                    title={custom3.title}
                    value={custom3.val}
                    style={{ textAlign: "center" }}
                    suffix={custom3.suffix}
                  />
                  <hr />
                </Col4>
              )}
            </FlexboxGrid>
          </>
        )}
        {acDeliveryStore.loaded.delcharges ? (
          <>
            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Total Charge"
                  value={PRKD(totalchg)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  As of {moment().format("DD-MMM-YY")}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Total Invoiced"
                  value={PRKD(totalinv)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalinv) === PRKD(totalchg) ? "green" : "red",
                  }}
                >
                  {PRKD(totalinv) === PRKD(totalchg)
                    ? "Charges Matched"
                    : PRKD(totalinv) > PRKD(totalchg)
                    ? "Over Invoiced"
                    : "Under Invoiced"}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Total Paid"
                  value={PRKD(totalpaid)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalpaid) === PRKD(totalinv) ? "green" : "red",
                  }}
                >
                  {PRKD(totalpaid) === PRKD(totalinv)
                    ? "Invoices Matched"
                    : PRKD(totalpaid) > PRKD(totalinv)
                    ? "Over Paid"
                    : "Under Paid"}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title={
                    totalremaining >= 0
                      ? "Remaining Charges"
                      : "Available Credit"
                  }
                  value={
                    PRKD(totalremaining) === 0
                      ? "-"
                      : Math.abs(PRKD(totalremaining))
                  }
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalremaining) === 0 ? "green" : "red",
                  }}
                >
                  {PRKD(totalremaining) === 0
                    ? "All Charges Met"
                    : PRKD(totalremaining) < 0
                    ? "Over Paid"
                    : "Overdue Balance"}
                </HelpBlock>
                <hr />
              </Col4>
            </FlexboxGrid>
          </>
        ) : (
          <FlexboxButton
            icon="reload"
            text="Load Delivery Charges"
            color="green"
            app="primary"
            loading={acDeliveryStore.loading.delcharges}
            oC={() => {
              acDeliveryStore.getDeliveryCharges();
              delInvoiceStore.getInvoiceAndPaymentByClient();
            }}
          />
        )}
      </LoadBack>
    );
  }
}

export default observer(DeliveryChargeHeader);
