import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";

const instance = axios.create({ baseURL: baseURL });

class PaymentOptionStore {
  constructor() {
    this.options = [];
    this.editoption = [];
    this.loading = false;
    this.dataloaded = false;

    makeObservable(this, {
      options: observable,
      editoption: observable,
      loading: observable,
      dataloaded: observable,
    });
  }

  // FETCH
  getOptions(overide) {
    if (!this.dataloaded || overide) {
      this.loading = true;
      return instance
        .get(`/jf/paymentoption/getall`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((options) => {
          this.options = options;
          this.dataloaded = true;
          this.editoption = [...options];
          this.loading = false;
          console.log("Payment Options Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  // ----- DATE ----
}

const paymentOptionStore = new PaymentOptionStore();
export default paymentOptionStore;
