function DarkColorCalc(status) {
  let color = "white";
  if (status === "Lead") color = "#949494";
  if (status === "Proposal") color = "#ebd700";
  if (status === "Booked") color = "#30cf00";
  if (status === "Converted") color = "#229400";
  if (status === "Cancelled") color = "#ad001d";
  return color;
}

export { DarkColorCalc };
