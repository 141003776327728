import { Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Loader } from "rsuite";
import MainCalendar from "../../../Components/Calendar/MainCalendar";
import Div14 from "../../../Components/Dividers/Div14";
import calendarStore from "../../../Stores/StaffStores/CalendarStore";
import ViewButtons from "./ViewButtons";
import { InputGroup } from "rsuite";
import { DatePicker } from "antd";
import moment from "moment";
import Col2 from "../../../Components/Columns/Col2";

class MainCalendarPage extends Component {
  componentDidMount() {
    calendarStore.getCalendarMain();
  }
  render() {
    return (
      <div>
        <br />
        <ViewButtons type={this.props.match.params.type} />
        <FlexboxGrid justify="center">
          <Col2>
            <InputGroup>
              <InputGroup.Addon>Start Date</InputGroup.Addon>
              <DatePicker
                style={{ width: "100%" }}
                //format={"DD-MMM-YY @ HA"}
                format={"MMM-YY"}
                value={moment(calendarStore.startdate)}
                allowClear={false}
                size={"large"}
                picker={"month"}
                showMinute={false}
                onChange={(date) => {
                  calendarStore.changeDate(date);
                }}
              />
              <InputGroup.Button
                color="green"
                loading={calendarStore.loading}
                onClick={() => calendarStore.getCalendarMain(true)}
              >
                <Icon icon="refresh" />
              </InputGroup.Button>
            </InputGroup>
          </Col2>
          {!calendarStore.cancloaded && (
            <Col2>
              <IconButton
                icon={<Icon icon="close" />}
                color="red"
                block
                loading={calendarStore.loading}
                onClick={() => calendarStore.getCancelledCalendarMain()}
              >
                Load Cancelled Clients
              </IconButton>
            </Col2>
          )}
        </FlexboxGrid>

        {calendarStore.loading ? (
          <Loader center size="lg" />
        ) : (
          <div>
            <Div14>
              <Tooltip title="Refresh" placement="left">
                <IconButton
                  icon={<Icon icon="refresh" />}
                  onClick={() => calendarStore.getCalendarMain()}
                  circle
                  size="xs"
                />
              </Tooltip>{" "}
              Calendar{" "}
            </Div14>
            <MainCalendar
              start={"start"}
              end={"end"}
              data={calendarStore.filtdates}
            />
          </div>
        )}
      </div>
    );
  }
}

export default observer(MainCalendarPage);
