import { observer } from "mobx-react";
import React, { Component } from "react";
import { Page, StyleSheet, Image, View } from "@react-pdf/renderer";

import emfooter from "../Pictures/easymovefooter.png";
import emheader from "../Pictures/easymoveheader.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 70,
    paddingBottom: 60,
    paddingHorizontal: 35,
  },
  unbreakable: { width: "100%", height: 400 },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
});

class HeaderFooterPDF extends Component {
  render() {
    return (
      <Page size="A4" style={styles.body}>
        <Image
          src={emheader}
          fixed
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        />
        <View>{this.props.children}</View>
        <Image
          fixed
          src={emfooter}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </Page>
    );
  }
}

export default observer(HeaderFooterPDF);
