import { observer } from "mobx-react";
import React, { Component } from "react";
import { Descriptions } from "antd";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import moment from "moment";
import { HelpBlock } from "rsuite";

class OrderSummaryTable extends Component {
  render() {
    let order = this.props.order;
    let account = this.props.account;

    let totalcbm = order.totalcbm;

    return (
      <div>
        <Descriptions
          title={this.props.hidetitle ? false : "Order Summary"}
          bordered
          size="small"
        >
          <Descriptions.Item label="Type"> {order.jobtype} </Descriptions.Item>
          <Descriptions.Item label="Status">{order.status}</Descriptions.Item>
          <Descriptions.Item label="Client">
            {account.clientNumId} - {account.name}
          </Descriptions.Item>
          <Descriptions.Item label="Total CBM">
            {PRKD(totalcbm)} CBM
          </Descriptions.Item>
          <Descriptions.Item label="Item Count">
            {PRKD(order.totalitems)} items
          </Descriptions.Item>
          <Descriptions.Item label="Package Count">
            {PRKD(order.totalqty)} packages
          </Descriptions.Item>
          <Descriptions.Item label="Job Date">
            {moment(order.jobdate).format("DD-MMM-YY HH:mm")}
            <HelpBlock>{moment(order.jobdate).fromNow()}</HelpBlock>
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Job Cost"
            span={24}
            labelStyle={{ fontWeight: "bold" }}
            contentStyle={{ fontWeight: "bold" }}
          >
            {PRKD(order.charges.totaljob)} KD
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default observer(OrderSummaryTable);
