import { CCPriceCalc } from "./CCPriceCalc";
import { IMPriceCalc } from "./IMPriceCalc";
import { LMPriceCalc } from "./LMPriceCalc";
import { Parsed } from "./PRKDCalc";
import { STPriceCalc } from "./STPriceCalc";

function SJPriceCalc(sjs) {
  let price = 0;
  let sjprice = [];
  for (let i = 0; i < sjs.length; i++) {
    if (sjs[i].jobtype === "LM") {
      sjprice = LMPriceCalc(sjs[i]);
    } else if (sjs[i].jobtype === "IM") {
      sjprice = IMPriceCalc(sjs[i]);
    } else if (sjs[i].jobtype === "CC") {
      sjprice = CCPriceCalc(sjs[i]);
    } else if (sjs[i].jobtype === "ST") {
      sjprice = STPriceCalc(sjs[i]);
    }
    price += Parsed(sjprice[0]);
  }
  return price;
}

function SingleSJPriceCalc(sj) {
  let sjprice = [];
  if (sj.jobtype === "LM") {
    sjprice = LMPriceCalc(sj);
  } else if (sj.jobtype === "IM") {
    sjprice = IMPriceCalc(sj);
  } else if (sj.jobtype === "CC") {
    sjprice = CCPriceCalc(sj);
  } else if (sj.jobtype === "ST") {
    sjprice = STPriceCalc(sj);
  }

  return sjprice;
}

export { SJPriceCalc, SingleSJPriceCalc };
