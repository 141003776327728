import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Collapse } from "antd";
import OrderSummaryTable from "../../../Modals/Supporting/OrderSummaryTable";
import LocationSummaryTable from "../../../Modals/Supporting/LocationSummaryTable";
import ServicesSummaryTable from "../../../Modals/Supporting/ServicesSummaryTable";

const { Panel } = Collapse;

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let del = this.props.del;
    let account = this.props.account;
    let timechg = this.props.timechg;
    return (
      <Collapse accordion>
        <Panel header="Order Summary">
          <Collapse accordion>
            <Panel header="Main Details">
              <OrderSummaryTable
                del={del}
                account={account}
                timechg={timechg}
                hidetitle
                hidecost
              />
            </Panel>
          </Collapse>
          <Collapse>
            <Panel header="Location Summary">
              <LocationSummaryTable del={del} hidetitle />
            </Panel>
          </Collapse>
          <Collapse>
            <Panel header="Services">
              <ServicesSummaryTable del={del} hidetitle />
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    );
  }
}

export default withRouter(observer(OrderSummary));
