import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, Notification } from "rsuite";
import baseURL from "../../../../Static/baseURL/baseURL";
import singleJobStore from "../../../../Stores/SingleJobStore";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import { message } from "antd";
import TextInput from "../Inputs/TextInput";
import SelectInpPicker from "../Inputs/SelectInpPicker";

import heardofuslist from "../../../../Static/Lists/HeardOfUs";
import leadtypeslist from "../../../../Static/Lists/LeadTypes";
import TextAreaInput from "../Inputs/TextAreaInput";
import Div14 from "../../../../Components/Dividers/Div14";
import clienttypelist from "../../../../Static/Lists/ClientTypeList";

const instance = axios.create({ baseURL: baseURL });

class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadname: false,
      loademail: false,
      loadtype: false,
      loadheard: false,
      loadlead: false,
      loadremark: false,
    };
    this.updateClient = this.updateClient.bind(this);
    this.editClient = this.editClient.bind(this);
  }

  editClient(type, val) {
    singleJobStore.editEditClient(type, val);
  }

  updateClient = async (label) => {
    if (label === "name") this.setState({ loadname: true });

    let data = {};
    data[label] = singleJobStore.editclient[label];

    await instance
      .put(`client/basic/edit/${singleJobStore.client._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleJobStore.editMainClient(label, singleJobStore.editclient[label]);
        this.setState({
          loadname: false,
          loademail: false,
          loadphone: false,
          loadphone2: false,
          loadtype: false,
          loadheard: false,
          loadlead: false,
          loadremark: false,
        });
        message.success(`${label} Updated.`);
      })
      .catch((err) => {
        this.setState({
          loadname: false,
          loademail: false,
          loadphone: false,
          loadphone2: false,
          loadtype: false,
          loadheard: false,
          loadlead: false,
          loadremark: false,
        });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let client = singleJobStore.editclient;
    let quality = this.props.quality;
    let editview = this.props.editview;

    return (
      <div style={{ margin: "1%" }}>
        <Div14>Client Details</Div14>

        <FlexboxGrid>
          <Col md={24} sm={24} xs={24}>
            <TextInput
              type="name"
              label="Name"
              val={client.name}
              load={this.state.loadname}
              org={singleJobStore.client.name}
              editVal={this.editClient}
              updateVal={this.updateClient}
              quality={quality}
              disabled={!editview}
            />
          </Col>

          <Col md={12} sm={24} xs={24}>
            <TextInput
              type="phone"
              label="Phone"
              val={client.phone}
              load={this.state.loadphone}
              org={singleJobStore.client.phone}
              editVal={this.editClient}
              updateVal={this.updateClient}
              quality={quality}
              disabled={!editview}
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <TextInput
              type="phone2"
              label="Phone 2"
              val={client.phone2}
              load={this.state.loadphone2}
              org={singleJobStore.client.phone2}
              editVal={this.editClient}
              updateVal={this.updateClient}
              disabled={!editview}
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <TextInput
              type="email"
              label="Email"
              val={client.email}
              load={this.state.loademail}
              org={singleJobStore.client.email}
              editVal={this.editClient}
              updateVal={this.updateClient}
              quality={quality}
              disabled={!editview}
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <SelectInpPicker
              type="type"
              label="Client Type"
              val={client.type}
              load={this.state.loadtype}
              org={singleJobStore.client.type}
              editVal={this.editClient}
              updateVal={this.updateClient}
              dataoptions={clienttypelist}
              disabled={!editview}
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <SelectInpPicker
              type="heardofus"
              label="Heard of Us"
              val={client.heardofus}
              load={this.state.loadheard}
              org={singleJobStore.client.heardofus}
              editVal={this.editClient}
              updateVal={this.updateClient}
              dataoptions={heardofuslist}
              disabled={!editview}
            />
          </Col>
          <Col md={12} sm={24} xs={24}>
            <SelectInpPicker
              type="leadtype"
              label="Lead Type"
              val={client.leadtype}
              load={this.state.loadtype}
              org={singleJobStore.client.leadtype}
              editVal={this.editClient}
              updateVal={this.updateClient}
              dataoptions={leadtypeslist}
              disabled={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <TextAreaInput
              type="remarks"
              label="Client Remarks"
              val={client.remarks}
              load={this.state.loadremark}
              org={singleJobStore.client.remarks}
              editVal={this.editClient}
              updateVal={this.updateClient}
              disabled={!editview}
            />
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(ClientDetails);
