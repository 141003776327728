import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../Components/Dividers/Div14";
import StorageNotePDF from "../../../../../Components/PDFs/Accounts/Storage/StorageNotePDF";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

class StorageNote extends Component {
  render() {
    let sto = newStorageStore.oldorder;
    let account = newStorageStore.account;
    let inout = newStorageStore.delstoinout.inout;

    return (
      <div>
        <Div14>Storage {inout} Note</Div14>
        <StorageNotePDF sto={sto} client={account} type={inout} />
      </div>
    );
  }
}

export default observer(StorageNote);
