import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../../../../Components/Dividers/Div14";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";

import acStorStatStore from "../../../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";
import ReactApexChart from "react-apexcharts";

class TopMovingItems extends Component {
  constructor(props) {
    super(props);
    this.state = { code: "", title: "" };
  }

  render() {
    let filtoptions = [
      { title: "Qty Moved", code: "totalqty" },
      { title: "CBM Moved", code: "totalcbm" },
      { title: "Qty per Month", code: "pertimeqty" },
      { title: "CBM per Month", code: "pertimecbm" },
      { title: "CBM Per Item", code: "cbm" },
      { title: "Stock", code: "currentplusreserve" },
      { title: "Price Per Item", code: "priceperitem" },
      { title: "Days Stagnant", code: "daysstagnant" },
    ];

    let series = [
      {
        data: acStorStatStore.topten.value,
        name: acStorStatStore.selectedcode.title,
      },
    ];
    let options = {
      chart: {
        type: "bar",
        height: 350,
      },
      tooltip: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: acStorStatStore.topten.name,
      },
    };

    let code = acStorStatStore.selectedcode.code;
    let title = acStorStatStore.selectedcode.title;

    return (
      <div>
        <Div14>Rank By Option</Div14>
        <FlexboxGrid justify="space-around">
          {filtoptions.map((op, index) => (
            <Col md={6} sm={8} xs={12} key={index}>
              <IconButton
                block
                onClick={() =>
                  acStorStatStore.changeSelectedCode(op.code, op.title)
                }
                color={op.code === code ? "green" : "default"}
                icon={<Icon icon={op.code === code ? "check" : "circle"} />}
              >
                {op.title}
              </IconButton>
            </Col>
          ))}
        </FlexboxGrid>
        <Div14>Top Items By: {title}</Div14>
        <br />
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={450}
        />
      </div>
    );
  }
}

export default withRouter(observer(TopMovingItems));
