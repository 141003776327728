import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import ItemsList from "./ItemsList";
import Div14 from "../../../../../Components/Dividers/Div14";

class ItemsListMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let inout = newStorageStore.delstoinout.inout;
    return (
      <div>
        {newStorageStore.neworder.toaddress.map((to, index) => (
          <div key={index}>
            <Div14>
              <b>
                {inout === "In" ? "Pickup" : "Delivery"} Location {to.order}:
              </b>
            </Div14>
            <ItemsList acitemmoves={to.acitemmove} to={to} />
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(observer(ItemsListMain));
