import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import Col2 from "../Columns/Col2";

class FlexboxButton extends Component {
  render() {
    let icon = this.props.icon;
    let text = this.props.text;
    let color = this.props.color;
    let app = this.props.app;
    let size = "md";
    let disabled = this.props.disabled;
    let loading = this.props.loading;
    if (this.props.size) size = this.props.size;

    return (
      <FlexboxGrid justify="center">
        <Col2>
          <IconButton
            icon={<Icon icon={icon} />}
            color={color}
            appearance={app}
            block
            size={size}
            onClick={this.props.oC}
            loading={loading}
            disabled={disabled}
          >
            {text}
          </IconButton>
        </Col2>
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(FlexboxButton));
