import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import singleAccountFinancialStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountFinancialStore";
import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import Div14 from "../../../../Components/Dividers/Div14";
import LoadBack from "../../../../Components/Loaders/LoadBack";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";

import { Tooltip } from "@material-ui/core";
import { Icon, IconButton } from "rsuite";
import userStore from "../../../../Stores/UserStore";
import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ACReceiptPDF from "../../../../Components/PDFs/Payments/ACReceiptPDF";

class AllAccountPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showreceipt: false,
      payment: { paymentNumId: "", _id: "" },
      loading: false,
      load: {},
    };
    this.onExporting = this.onExporting.bind(this);
    this.DownloadInvoice = this.DownloadInvoice.bind(this);
    this.StatusCell = this.StatusCell.bind(this);
    this.onHide = this.onHide.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }

  onHide() {
    this.setState({ showreceipt: false, loading: false });
  }
  componentDidMount() {
    singleAccountFinancialStore.getPayments(singleAccountStore.account._id);
  }

  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }

  DownloadInvoice(cellData) {
    return (
      <>
        <Tooltip title={`View Invoice`} arrow>
          <IconButton
            icon={<Icon icon="save" />}
            color="blue"
            circle
            size="xs"
            onClick={() => {
              let type = cellData.data.invId.slice(0, 3);

              this.setState({
                show: true,
                acinvoice: cellData.data,
                type: type,
              });
            }}
          />
        </Tooltip>
      </>
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Client_Invoice_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  ActionButs(cellData) {
    return (
      <Tooltip title={`View Receipt`} arrow>
        <IconButton
          icon={<Icon icon="eye" />}
          color="green"
          circle
          size="xs"
          onClick={() => {
            this.setState({ payment: cellData.data, showreceipt: true });
          }}
        />
      </Tooltip>
    );
  }

  render() {
    let pay = this.state.payment;

    return (
      <div>
        <UnifiedModal
          title={`Confirmation of Payment #${pay.paymentNumId}`}
          show={this.state.showreceipt}
          onHide={this.onHide}
          footer={false}
        >
          <ACReceiptPDF rct={pay} />
        </UnifiedModal>
        <Div14>Account Payments</Div14>
        <LoadBack loading={singleAccountFinancialStore.loading.payments}>
          <DataGrid
            dataSource={singleAccountFinancialStore.payments}
            keyExpr="paymentNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            hoverStateEnabled={true}
          >
            <FilterRow visible={true} applyFilter />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} />

            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />
            <Column
              caption="Actions"
              alignment={"center"}
              cellRender={this.ActionButs}
              width={100}
            />
            <Column
              dataField="paymentNumId"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column caption="Payment Details" alignment={"center"}>
              {userStore.user.group === "EMStaff" && (
                <Column
                  dataField="type"
                  caption="Main Type"
                  alignment="center"
                  minWidth={80}
                />
              )}
              <Column
                dataField="accounttype"
                caption="Type"
                alignment="center"
                minWidth={80}
              />
              <Column
                dataField="method"
                caption="Method"
                alignment="center"
                minWidth={80}
              />
              {userStore.user.group === "EMStaff" && (
                <Column
                  dataField="account"
                  caption="Account"
                  alignment="center"
                  minWidth={80}
                />
              )}
              <Column
                dataField="date"
                caption="Date"
                dataType={"date"}
                format="dd-MMM-yy"
                alignment="center"
                minWidth={80}
                sortOrder={"desc"}
              />
            </Column>

            <Column caption="Charges" alignment={"center"}>
              <Column
                dataField="amount"
                caption="Amount"
                alignment="center"
                minWidth={80}
                format={{ type: "fixedPoint", precision: 3 }}
              />
            </Column>
            <Summary>
              <TotalItem
                column="ID"
                summaryType="count"
                displayFormat={"{0} Payments"}
              />
              <TotalItem
                column="amount"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Export enabled={true} />
            <Toolbar>
              <Item name="Refresh" location="before">
                <div style={{ paddingLeft: "4px" }}>
                  <Tooltip title={`Refresh`} arrow placement="right">
                    <IconButton
                      icon={<Icon icon="refresh" />}
                      onClick={() => {
                        singleAccountFinancialStore.getPayments(false, true);
                      }}
                      appearance="default"
                      style={{ border: "1px solid black" }}
                    />
                  </Tooltip>
                </div>
              </Item>
              <Item name="columnChooserButton" location="after" />
              <Item name="exportButton" />
            </Toolbar>
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(AllAccountPayments));
