import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import { DatePicker, Statistic } from "antd";

import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import moment from "moment";

import UnifiedModal from "../../../../Components/Modal/UnifiedModal";

import hrStore from "../../../../Stores/HR/HRStore";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
const { RangePicker } = DatePicker;
class NewHoliday extends Component {
  render() {
    let holiday = this.props.holiday;
    let disabled = false;
    if (holiday.date === undefined) disabled = true;
    if (holiday.dateto === undefined) disabled = true;
    if (holiday.type === "") disabled = true;
    if (holiday.staff === "") disabled = true;

    let staff = hrStore.staff;

    if (this.props.staffhr) {
      staff = staff.filter((st) => st.type !== "Management");
    }
    let staffopts = staff.map((st, index) => (
      <IconButton
        key={index}
        icon={<Icon icon={holiday.staff === st._id ? "check" : "circle"} />}
        onClick={() => this.props.editVal(st._id, "staff")}
        color={holiday.staff === st._id && "green"}
        style={{ margin: "4px" }}
      >
        {st.name}
      </IconButton>
    ));

    let daysavailable = 0;
    if (holiday.staff) {
      let singlestaff = hrStore.staff.filter((st) => st._id === holiday.staff);
      if (holiday.type === "Sick Leave") {
        daysavailable = singlestaff[0].sickremaining;
      } else {
        daysavailable = singlestaff[0].remaining;
      }
    }

    let diff = moment(holiday.dateto).diff(holiday.date, "days") + 1;

    let remaining = parseFloat(daysavailable) - parseFloat(diff);

    let holidaytypes = ["Planned Leave", "Unplanned Leave"];
    if (holiday.type === "Sick Leave") holidaytypes = ["Sick Leave"];

    return (
      <UnifiedModal
        title={"Create a New Holiday"}
        show={this.props.show}
        onHide={this.props.hideModal}
        footer={
          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={<Icon icon="close" />}
                color="red"
                block
                onClick={() => this.props.hideModal(false)}
                disabled={this.props.loading}
              >
                Cancel
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="plus" />}
                color="green"
                block
                disabled={disabled}
                loading={this.props.loading}
                onClick={() => this.props.postNew(diff)}
              >
                Create {this.props.type}
              </IconButton>
            </Col2>
          </FlexboxGrid>
        }
      >
        <FlexboxGrid style={{ padding: "1%" }}>{staffopts}</FlexboxGrid>

        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <SelectInputSTD
              title="Type"
              val={holiday.type}
              label="type"
              size="large"
              opts={holidaytypes}
              editVal={this.props.editVal}
            />
          </Col2>
          <Col2>
            <TextInputSTD
              title="Calc Days"
              val={diff}
              label="base"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
        </FlexboxGrid>

        <FlexboxGrid style={{ padding: "1%" }}>
          <RangePicker
            style={{ width: "100%" }}
            //format={"DD-MMM-YY @ HA"}
            format={"DD-MMM-YY"}
            value={[moment(holiday.date), moment(holiday.dateto)]}
            allowClear={false}
            showMinute={false}
            onChange={(date) => {
              this.props.editVal(date, "date");
            }}
          />
        </FlexboxGrid>
        <FlexboxGrid justify="space-around">
          <Statistic
            title="Available Days"
            value={daysavailable}
            suffix="days"
          />
          <Statistic title=" - " value={"-"} valueStyle={{ color: "red" }} />
          <Statistic
            title="New Holiday"
            value={diff}
            suffix="days"
            valueStyle={{ color: "red" }}
          />
          <Statistic title=" = " value={"="} valueStyle={{ color: "red" }} />
          <Statistic
            title="After Holiday"
            value={remaining}
            suffix="days"
            valueStyle={{ color: remaining < 0 ? "red" : "green" }}
          />
        </FlexboxGrid>
        <div style={{ padding: "1%" }}>
          <TextAreaInputSTD
            title="Remarks"
            val={holiday.remark}
            label="remark"
            size="large"
            editVal={this.props.editVal}
          />
        </div>
      </UnifiedModal>
    );
  }
}

export default observer(NewHoliday);
