import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

import userStore from "../../../../Stores/UserStore";
import ClientTab from "./ClientTab";
import ClientAccount from "./ClientAccount";
import ClientAccountAdmin from "./ClientAccountAdmin";

class AllClientTabs extends Component {
  render() {
    let content = <div></div>;
    if (userStore.signedIn) {
      if (userStore.user) {
        if (userStore.user.group === "Client") {
          content = <ClientTab open={this.props.open} hdc={this.props.hdc} />;
        } else if (userStore.user.company) {
          content = (
            <div>
              {userStore.user.company && (
                <ClientAccount open={this.props.open} hdc={this.props.hdc} />
              )}
              {userStore.user.compadmin && (
                <ClientAccountAdmin
                  open={this.props.open}
                  hdc={this.props.hdc}
                />
              )}
            </div>
          );
        }
      }
    }

    return content;
  }
}

export default withRouter(observer(AllClientTabs));
