import MaterialTable from "material-table";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import "react-datasheet/lib/react-datasheet.css";
import { Empty, message } from "antd";
import Div14 from "../../../../Components/Dividers/Div14";
import axios from "axios";

import { textColumn, keyColumn } from "react-datasheet-grid";
import { DataSheetGrid, floatColumn } from "react-datasheet-grid";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class EditDelOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showone: false,
      type: "New",
      showbulk: false,
      loading: false,
      index: -1,
      singletype: { remarks: "", type: "", description: "", price: 0 },
      grid: [{ remarks: "", type: "", description: "", price: 0 }],
      changed: false,
    };
    this.addSingle = this.addSingle.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editType = this.editType.bind(this);
    this.updateDelCont = this.updateDelCont.bind(this);
  }
  updateDelCont = async () => {
    this.setState({ loading: true });
    let data = { types: newAccountStore.deltypes };
    return instance
      .put(`/ac/account/editdelcont/${newAccountStore.delCont.client}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAccountStore.updateDelTypes(data.types);

        message.success(`Successfully Updated Delivery Types`);
        this.setState({ loading: false, changed: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  addSingle() {
    this.setState({ changed: true });
    if (this.state.type === "New") {
      newAccountStore.addDelType(this.state.singletype);
    } else {
      newAccountStore.editDelType(this.state.singletype, this.state.index);
    }

    this.onHide();
  }
  editType(val, label) {
    let type = this.state.singletype;
    type[label] = val;
    this.setState({ singletype: { ...type } });
  }
  onHide() {
    this.setState({
      showone: false,
      showbulk: false,
      type: "New",
      singletype: { remarks: "", type: "", description: "", price: 0 },
      grid: [{ remarks: "", type: "", description: "", price: 0 }],
    });
  }
  render() {
    let columns = [
      { title: "Type", field: "type", defaultSort: "asc" },
      { title: "Price", field: "price" },
      { title: "Description", field: "description" },
      { title: "Remarks", field: "remarks" },
    ];
    const gridcolumns = [
      { ...keyColumn("type", textColumn), title: "Type" },
      { ...keyColumn("price", floatColumn), title: "Price (KD)" },
      { ...keyColumn("description", textColumn), title: "Description" },
      { ...keyColumn("remarks", textColumn), title: "Remarks (optional)" },
    ];
    let newtype = this.state.singletype;

    let loading = this.state.loading;
    let grid = this.state.grid;
    let disabled = false;

    if (this.state.type === "Edit" || this.state.type === "New") {
      if (newtype.type === "") disabled = true;
      if (newtype.price === "") disabled = true;
      if (newtype.price === 0) disabled = true;
      if (newtype.description === "") disabled = true;
    } else if (this.state.type === "Bulk") {
      for (let i = 0; i < grid.length; i++) {
        if (grid[i].type === null) disabled = true;
        if (grid[i].type === "") disabled = true;
        if (grid[i].price === null) disabled = true;
        if (grid[i].price === "") disabled = true;
        if (grid[i].description === null) disabled = true;
        if (grid[i].description === "") disabled = true;
      }
    }

    return (
      <LoadBack loading={loading}>
        <UnifiedModal
          show={this.state.showone}
          onHide={this.onHide}
          title={`Delivery Option - ${this.state.type}`}
          footer={
            <ModalFooter
              icon={["undo", "save"]}
              color={["red", "green"]}
              app={["ghost", "primary"]}
              text={[
                "Cancel",
                this.state.type === "New" ? "Add Option" : "Save Changes",
              ]}
              oC1={() => this.onHide()}
              oC2={() => this.addSingle()}
              loading1={loading}
              loading2={loading}
              disabled2={disabled}
            />
          }
        >
          <FlexboxGrid>
            <Col2>
              <TextInputSTD
                title="Type"
                val={newtype.type}
                label="type"
                size="large"
                editVal={this.editType}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Price (KD)"
                val={newtype.price}
                label="price"
                size="large"
                editVal={this.editType}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <TextAreaInputSTD
            title="Description"
            val={newtype.description}
            label="description"
            size="large"
            editVal={this.editType}
          />
          <br />
          <TextAreaInputSTD
            title="Additional Remarks"
            val={newtype.remarks}
            label="remarks"
            size="large"
            editVal={this.editType}
          />
          <br />
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showbulk}
          onHide={this.onHide}
          title={`Add Bulk Delivery Types`}
          footer={
            <ModalFooter
              icon={["undo", "save"]}
              color={["red", "green"]}
              app={["ghost", "primary"]}
              text={["Cancel", `Add ${grid.length} New Types`]}
              oC1={() => this.onHide()}
              oC2={() => {
                newAccountStore.addBulkDelType(this.state.grid);
                this.setState({ changed: true });
                this.onHide();
              }}
              loading1={loading}
              loading2={loading}
              disabled2={disabled}
            />
          }
        >
          <DataSheetGrid
            value={this.state.grid}
            onChange={(e) => {
              this.setState({ grid: e });
            }}
            columns={gridcolumns}
            autoAddRow
            style={{ backgroundColor: "red" }}
            createRow={() => ({
              type: "",
              price: 0,
              description: "",
              remarks: "",
            })}
          />
        </UnifiedModal>

        <Div14>Delivery Types</Div14>
        <ModalFooter
          icon={["plus", "cube"]}
          color={["green", "green"]}
          app={["ghost", "ghost"]}
          text={["Add Single Type", "Bulk Add"]}
          oC1={() => this.setState({ showone: true, type: "New" })}
          oC2={() => this.setState({ showbulk: true, type: "Bulk" })}
          loading1={loading}
          loading2={loading}
        />
        <div style={{ padding: "2%" }}>
          {newAccountStore.deltypes.length > 0 ? (
            <MaterialTable
              title={`Delivery Types List`}
              isLoading={this.state.showbulk || this.state.showone}
              columns={columns}
              style={{ fontSize: 12 }}
              data={newAccountStore.deltypes}
              options={{
                actionsColumnIndex: 0,
                paging: false,
                toolbarButtonAlignment: "left",
                maxBodyHeight: 500,
                tableLayout: "auto",
                padding: "dense",
              }}
              actions={[
                {
                  icon: "edit",

                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    this.setState({
                      type: "Edit",
                      singletype: rowData,
                      index: rowData.tableData.id,
                      showone: true,
                    });
                  },
                },
                {
                  icon: "delete",
                  style: { color: "red" },
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    newAccountStore.removeDelType(rowData.tableData.id);
                  },
                },
              ]}
            />
          ) : (
            <Empty description="No types found. Please Add using the above buttons." />
          )}
        </div>
        <ModalFooter
          icon={["undo", "save"]}
          color={["red", "green"]}
          app={["ghost", "primary"]}
          text={["Undo Changes", "Save Changes"]}
          oC1={() => {
            newAccountStore.resetDelTypes();
            this.setState({ changed: false });
            this.onHide();
          }}
          oC2={() => this.updateDelCont()}
          loading1={loading}
          loading2={loading}
          disabled1={!this.state.changed}
          disabled2={!this.state.changed}
        />
      </LoadBack>
    );
  }
}

export default observer(EditDelOptions);
