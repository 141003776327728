import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import { PRKD, Parsed } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class WHMCompanyStore {
  constructor() {
    this.company = { _id: "", name: "" };
    this.companies = [];
    this.pallets = [];
    this.unpalletized = [];
    this.palletized = [];
    this.oldpallet = {};
    this.palletitems = [];
    this.newpallet = 0;
    this.filter = "";
    this.loading = {
      companies: false,
      company: false,
      unpalletized: false,
      neweditpalletitem: false,
    };
    this.loaded = {
      companies: false,
      company: false,
      unpalletized: false,
      palletized: false,
    };

    makeObservable(this, {
      companies: observable,
      company: observable,
      pallets: observable,
      palletitems: observable,
      unpalletized: observable,
      palletized: observable,
      loading: observable,
      loaded: observable,
      filter: observable,
      oldpallet: observable,
      newpallet: observable,
      singlePallet: computed,
      palletstats: computed,
      remainingcomps: computed,
      companypalletstats: computed,
    });
  }

  // FETCH
  getCompanies(override) {
    let loaded;
    if (this.loading.companies || this.loaded.companies) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.companies = true;
      return instance
        .get(`/whm/companies/allcompanies`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.companies = [...data];
          this.loading.companies = false;
          this.loaded.companies = true;
          console.log("WHM Companies Fetched");
        })
        .catch((err) => {
          this.loading.companies = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getSingleCompany(compid, override) {
    let loaded;
    if (this.loading.company || this.loaded.company) loaded = true;
    if (override) loaded = false;
    if (compid) {
      if (this.company._id !== compid) {
        loaded = false;
      }
    }

    if (!loaded) {
      this.loading.company = true;
      this.pallets = [];
      return instance
        .get(`/whm/companies/single/${compid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.company = data.company;
          this.pallets = data.pallets;

          this.loading.company = false;
          this.loaded.company = true;
          this.loaded.unpalletized = false;
          console.log("WHM Single Company Fetched Frontend");
        })
        .catch((err) => {
          this.loading.company = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  postNewPalletItem(palletitems) {
    let loaded = this.loading.neweditpalletitem;

    if (!loaded) {
      this.loading.neweditpalletitem = true;
      let data = palletitems;
      let compid = this.company._id;
      return instance
        .put(`/whm/palletitem/new/${compid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.getSingleCompany(compid);

          this.loading.neweditpalletitem = false;

          console.log("New Pallet Created");
        })
        .catch((err) => {
          this.loading.neweditpalletitem = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  uploadBackdated() {
    return instance
      .put(`/whm/palletitem/newpalletlist`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {})
      .catch((err) => {
        this.setState({ loading: false });

        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getUnpalletized(compid, override) {
    let loaded;
    if (this.loading.unpalletized || this.loaded.unpalletized) loaded = true;
    if (override) loaded = false;
    if (compid) {
      if (this.company._id !== compid) {
        loaded = false;
      }
    } else {
      compid = this.company._id;
    }

    if (!loaded) {
      this.loading.unpalletized = true;

      return instance
        .get(`/whm/items/unpalletized/${compid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.unpalletized = data.data;
          this.newpallet = data.newpallet;
          console.log(this.newpallet);
          this.loading.unpalletized = false;
          this.loaded.unpalletized = true;
          console.log("WHM Unpalletized Items Fetched");
        })
        .catch((err) => {
          this.loading.unpalletized = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getPalletized(compid, override) {
    let loaded;
    if (this.loading.palletized || this.loaded.palletized) loaded = true;
    if (override) loaded = false;
    if (compid) {
      if (this.company._id !== compid) {
        loaded = false;
      }
    } else {
      compid = this.company._id;
    }

    if (!loaded) {
      this.loading.palletized = true;

      return instance
        .get(`/whm/items/palletized/${compid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let output = data.data;
          let palletnums = [];

          for (let i = 0; i < output.length; i++) {
            palletnums = [];
            if (output[i].itempallet) {
              if (output[i].itempallet.length > 0) {
                for (let j = 0; j < output[i].itempallet.length; j++) {
                  palletnums = [
                    ...palletnums,
                    output[i].itempallet[j].palletNumId,
                  ];
                }
              }
            }
            output[i].palletNumber = palletnums.join(", ");
          }
          this.palletized = [...output];

          console.log(this.newpallet);
          this.loading.palletized = false;
          this.loaded.palletized = true;
          console.log("WHM Palletized Items Fetched");
        })
        .catch((err) => {
          this.loading.palletized = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get singlePallet() {
    return this.unpalletized.filter((it) => it.newquant > 0);
  }
  get palletstats() {
    let totalcbm = 0;
    let totalqty = 0;
    for (let i = 0; i < this.singlePallet.length; i++) {
      totalcbm +=
        Parsed(this.singlePallet[i].cbm) *
        Parsed(this.singlePallet[i].newquant);
      totalqty += Parsed(this.singlePallet[i].newquant);
    }
    return { cbm: PRKD(totalcbm), qty: PRKD(totalqty) };
  }

  get remainingcomps() {
    return this.companies.filter((co) => co.remainingunpalletized > 0);
  }

  get companypalletstats() {
    let items = 0;
    let pallets = 0;
    let done = 0;
    for (let i = 0; i < this.remainingcomps.length; i++) {
      items += parseFloat(this.remainingcomps[i].remainingunpalletized);
    }
    for (let i = 0; i < this.companies.length; i++) {
      if (this.companies[i].pallets.length > 0) {
        pallets += this.companies[i].pallets.filter(
          (pl) => pl.status !== "Stacked"
        ).length;
        done += this.companies[i].pallets.filter(
          (pl) => pl.status === "Stacked"
        ).length;
      }
    }
    return {
      remainingitems: items,
      remainingpallets: pallets,
      completedpallets: done,
    };
  }

  // FUNCTIONS:
  editPalletItem(val, label, acItemNumId) {
    for (let i = 0; i < this.unpalletized.length; i++) {
      if (this.unpalletized[i].acItemNumId === acItemNumId) {
        this.unpalletized[i].newquant = val;
        i = 10000;
      }
    }
  }

  itemSearch(val) {
    this.filter = val;
  }

  resetPallet(type, pallet) {
    if (type === "New") {
      this.palletitems = [...this.unpalletized];
    } else {
      this.oldpallet = { ...pallet };
    }
  }
}

const whmCompanyStore = new WHMCompanyStore();
export default whmCompanyStore;
