import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  FilterRow,
  LoadPanel,
  Toolbar,
  Item,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { Tooltip } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import moment from "moment";
import { Parsed } from "../../../../../../../../Functions/Pricing/PRKDCalc";
import staffStorageStatStore from "../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import Col2 from "../../../../../../../../Components/Columns/Col2";

class FullItemListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemdetails: false,
      quantitychange: false,
      totals: true,
      permonth: true,
      lastinout: true,
    };
  }
  componentDidMount() {}

  LastIn(cellData) {
    let data = cellData.data;
    return `${moment(data.lastin).format("DD-MMM-YY")} (${moment(
      data.lastin
    ).fromNow(true)})`;
  }
  LastOut(cellData) {
    let data = cellData.data;
    if (data.lastout) {
      return `${moment(data.lastout).format("DD-MMM-YY")} (${moment(
        data.lastout
      ).fromNow(true)})`;
    } else {
      return `No Outs (${moment(data.lastin).fromNow(true)})`;
    }
  }
  StagnantPrice(cellData) {
    let data = cellData.data;

    let output = Parsed(moment().diff(data.lastout, "Month")) * Parsed(4);

    return output;
  }

  render() {
    let data = staffStorageStatStore.staticitemstat.items;
    let move = staffStorageStatStore.selectedcode.move;
    let nomove = staffStorageStatStore.selectedcode.nomove;
    let buttons = [
      { title: "Item Details", code: "itemdetails" },
      { title: "Qty Details", code: "quantitychange" },
      { title: "Totals", code: "totals" },
      { title: "Per Month", code: "permonth" },
      { title: "Last In/Out", code: "lastinout" },
    ];

    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              icon={<Icon icon={move ? "eye" : "eye-slash"} />}
              block
              appearance={move ? "default" : "ghost"}
              color={move ? "primary" : "default"}
              onClick={() => staffStorageStatStore.changeMoveFilt("move")}
              size="sm"
            >
              Movement
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              icon={<Icon icon={nomove ? "eye" : "eye-slash"} />}
              block
              appearance={nomove ? "default" : "ghost"}
              color={nomove ? "primary" : "default"}
              onClick={() => staffStorageStatStore.changeMoveFilt("nomove")}
              size="sm"
            >
              No Movement
            </IconButton>
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          {buttons.map((bt, index) => (
            <IconButton
              style={{ margin: "1%" }}
              key={index}
              icon={<Icon icon={this.state[bt.code] ? "eye" : "eye-slash"} />}
              onClick={() => this.setState({ [bt.code]: !this.state[bt.code] })}
              color={this.state[bt.code] ? "green" : "default"}
            >
              {bt.title}
            </IconButton>
          ))}
        </FlexboxGrid>
        <DataGrid
          dataSource={data}
          keyExpr="acItemNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <LoadPanel enabled={true} />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column caption="Item" alignment={"center"}>
            <Column
              dataField="acItemNumId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="name"
              dataType="text"
              caption="Item Name"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="currentplusreserve"
              dataType="number"
              caption="Current Stock"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              minWidth={100}
            />
          </Column>
          {this.state.itemdetails && (
            <Column caption="Item Details" alignment={"center"}>
              <Column
                dataField="serialnumber"
                caption="Serial"
                alignment="center"
                minWidth={80}
              />

              <Column
                dataField="productid"
                caption="Prod. ID"
                alignment="center"
                minWidth={80}
              />

              <Column
                dataField="cbm"
                caption="CBM"
                alignment="center"
                minWidth={80}
                format={{ type: "fixedPoint", precision: 3 }}
              />

              <Column
                dataField="priceperitem"
                caption="Price Per Item"
                alignment="center"
                minWidth={80}
                format={{ type: "fixedPoint", precision: 3 }}
              />
            </Column>
          )}
          {this.state.quantitychange && (
            <Column caption="Quantity Change" alignment={"center"}>
              <Column
                dataField="in"
                dataType="number"
                caption="Qty In"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={100}
              />
              <Column
                dataField="out"
                dataType="number"
                caption="Qty Out"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={100}
              />
              <Column
                dataField="injob"
                dataType="number"
                caption="In Jobs"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={100}
              />
              <Column
                dataField="outjob"
                dataType="number"
                caption="Out Jobs"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={100}
              />
            </Column>
          )}
          {this.state.totals && (
            <Column caption="Total" alignment={"center"}>
              <Column
                dataField="totaljob"
                dataType="number"
                caption="Total Jobs"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={100}
              />

              <Column
                dataField="totalqty"
                dataType="number"
                caption="Total Movement"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={100}
              />
              <Column
                dataField="totalcbm"
                dataType="number"
                caption="Total CBM Movement"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                minWidth={100}
              />
            </Column>
          )}
          {this.state.permonth && (
            <Column caption="Per Month" alignment={"center"}>
              <Column
                dataField="pertimejob"
                dataType="number"
                caption="Total Jobs"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                minWidth={100}
              />

              <Column
                dataField="pertimeqty"
                dataType="number"
                caption="Total Movement"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                minWidth={100}
              />
              <Column
                dataField="pertimecbm"
                dataType="number"
                caption="Total CBM Movement"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                minWidth={100}
              />
            </Column>
          )}
          {this.state.lastinout && (
            <Column caption="Last In / Out" alignment={"center"}>
              <Column
                dataField="lastin"
                dataType="date"
                caption="Last In"
                alignment="center"
                cellRender={this.LastIn}
                minWidth={100}
              />
              <Column
                dataField="lastout"
                dataType="date"
                caption="Last Out"
                alignment="center"
                cellRender={this.LastOut}
                minWidth={100}
              />
              <Column
                dataField="daysstagnant"
                dataType="number"
                caption="Days Stagnant"
                alignment="center"
                minWidth={100}
              />
            </Column>
          )}
          <Summary>
            <TotalItem
              column="name"
              summaryType="count"
              valueFormat={"#,##0"}
              displayFormat={"{0}"}
            />
            <TotalItem
              column="currentplusreserve"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0}"}
            />
            <TotalItem
              column="in"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="out"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="injob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="outjob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="totaljob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="totalqty"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0"}
            />
            <TotalItem
              column="totalcbm"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="pertimejob"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="pertimeqty"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
            <TotalItem
              column="pertimecbm"
              summaryType="sum"
              displayFormat={"{0}"}
              valueFormat={"#,##0.0"}
            />
          </Summary>
          <Export enabled={true} />

          <Toolbar>
            <Tooltip title={`Choose Columns`} arrow placement="left">
              <Item name="columnChooserButton" />
            </Tooltip>
            <Item name="exportButton" />
          </Toolbar>
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(FullItemListTable));
