import { observer } from "mobx-react";
import React, { Component } from "react";
import SJNumbInput from "../../../../../../Components/Inputs/SJ/SJNumbInput";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import { FlexboxGrid } from "rsuite";
import outboundlist from "../../../../../../Static/Lists/imlists/outboundlist";
import inboundlist from "../../../../../../Static/Lists/imlists/inboundlist";
import Col2 from "../../../../../../Components/Columns/Col2";

import imchargelist1 from "../../../../../../Static/Lists/imlists/imcharges/imchargelist1";
import Div14 from "../../../../../../Components/Dividers/Div14";
import ServicesTab from "../Others/Services/ServicesTab";

import AddChargeTab from "../Others/AddCharge/AddChargeTab";
import { IMPriceCalc } from "../../../../../../Functions/Pricing/IMPriceCalc";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import SJTextAreaInput from "../../../../../../Components/Inputs/SJ/SJTextAreaInput";
import SJSelectInput from "../../../../../../Components/Inputs/SJ/SJSelectInput";

class IMPrice extends Component {
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let quality = this.props.quality;

    let load = this.props.load;

    let sj = sjStore.sjs[ind];
    let list = [];
    if (sj.im.direction === "Inbound") list = inboundlist;
    if (sj.im.direction === "Outbound") list = outboundlist;

    let price = IMPriceCalc(sj);
    let addcharges = price[2];

    let pricerows = list.map((val, index) => (
      <Col2 key={index}>
        <SJNumbInput
          ind={ind}
          label={val.label}
          title={val.title}
          load={load[val.label]}
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.updateVal}
          quality={quality}
          sj="im"
          read={!editview}
        />
      </Col2>
    ));
    let dimensions = imchargelist1.map((val, index) => (
      <Col2 key={index}>
        <SJNumbInput
          ind={ind}
          label={val.label}
          title={val.title}
          load={load[val.label]}
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.updateVal}
          sj="im"
          read={!editview}
        />
      </Col2>
    ));

    return (
      <div>
        <FlexboxGrid justify="center">
          <Div14>Shipment Details</Div14>
          {dimensions}
          <Col2>
            <SJTextAreaInput
              ind={ind}
              label={"shipfrom"}
              title={"Ship From"}
              load={load.shipfrom}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="im"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJTextAreaInput
              ind={ind}
              label={"shipto"}
              title={"Ship To"}
              load={load.shipto}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="im"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="shippingmethod"
              title="Shipping Method"
              load={load.shippingmethod}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={["Air", "Land", "Sea"]}
              sj="im"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJTextAreaInput
              ind={ind}
              label={"containertype"}
              title={"Container"}
              load={load.containertype}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="im"
              read={!editview}
            />
          </Col2>
        </FlexboxGrid>
        <br />
        <FlexboxGrid justify="center">
          <Div14>Pricing ({PRKD(price[0])} KD)</Div14>
          {pricerows}
          <Col2>
            <h4 style={{ textAlign: "center" }}>
              {`${price[1]}`} KD{" "}
              {addcharges > 0 ? `+ ${PRKD(addcharges)} KD Additional` : ""}
            </h4>
          </Col2>
        </FlexboxGrid>
        <br />

        <Div14>Additional Charges (+ {PRKD(addcharges)} KD)</Div14>
        <AddChargeTab ind={ind} editview={editview} />

        <FlexboxGrid>
          <Div14>Services</Div14>
          <ServicesTab ind={ind} editview={editview} />
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(IMPrice);
