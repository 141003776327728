import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import Div14 from "../../../Components/Dividers/Div14";
import MaterialTable from "material-table";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import { Button, FlexboxGrid, Icon, IconButton } from "rsuite";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import whmNewWarehouseStore from "../WHMStores/WHMNewWarehouseStore";
import userStore from "../../../Stores/UserStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

const locsecs = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const locaisle = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
];
const locbin = [0, 1, 2, 3];

class StackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showitem: false,
      section: "",
      aisle: "",
      bin: "",
      loading: false,
    };
    this.onHide = this.onHide.bind(this);
    this.addLocationTag = this.addLocationTag.bind(this);
  }
  onHide() {
    this.setState({
      showitem: false,
      section: "",
      aisle: "",
      bin: "",
    });

    this.props.onHide();
  }
  addLocationTag = async (palletid) => {
    let data = {
      location: `3${this.state.section}${this.state.aisle}${this.state.bin}`,
    };
    this.setState({ loading: true });
    await instance
      .put(`whm/pallets/setpalletlocation/${palletid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Successfully Stacked Pallet and Added Item Locations`);
        whmNewWarehouseStore.getPallets(true);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };

  render() {
    let pallet = this.props.pallet;

    let columnnames = [
      { title: "ID", field: "acitem.acItemNumId", defaultSort: "desc" },
      { title: "Quantity", field: "quantity" },
      { title: "Name", field: "acitem.name" },
      { title: "Serial", field: "acitem.serialnumber" },
      {
        title: "CBM",
        field: "totalcbm",
        render: (rowData) => PRKD(rowData.totalcbm),
      },
    ];
    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={`Stack Pallet # ${pallet.whmPalletNumId} to Location`}
        footer={
          <ModalFooter
            icon={["close", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={["Cancel", "Complete Stack"]}
            oC1={() => this.onHide()}
            oC2={() => this.addLocationTag(pallet._id)}
            disabled2={
              this.state.aisle === "" ||
              this.state.section === "" ||
              this.state.bin === ""
            }
            loading1={this.state.loading}
            loading2={this.state.loading}
          />
        }
      >
        <Div14>Select a Section</Div14>
        <FlexboxGrid justify="center">
          {locsecs.map((sec, index) => (
            <Button
              color="green"
              key={index}
              size="xs"
              appearance={sec === this.state.section ? "default" : "ghost"}
              onClick={() => this.setState({ section: sec })}
            >
              {sec}
            </Button>
          ))}
        </FlexboxGrid>
        <Div14>Select an Aisle</Div14>
        <FlexboxGrid justify="center">
          {locaisle.map((aisle, index) => (
            <Button
              color="green"
              key={index}
              size="xs"
              appearance={aisle === this.state.aisle ? "default" : "ghost"}
              onClick={() => this.setState({ aisle: aisle })}
            >
              {aisle}
            </Button>
          ))}
        </FlexboxGrid>
        <Div14>Select a Bin (Level)</Div14>
        <FlexboxGrid justify="center">
          {locbin.map((bin, index) => (
            <Button
              color="green"
              key={index}
              size="xs"
              appearance={bin === this.state.bin ? "default" : "ghost"}
              onClick={() => this.setState({ bin: bin })}
            >
              {bin}
            </Button>
          ))}
        </FlexboxGrid>
        <hr />

        <IconButton
          block
          icon={
            <Icon icon={this.state.showitem ? "chevron-down" : "chevron-up"} />
          }
          onClick={() => this.setState({ showitem: !this.state.showitem })}
        >
          Item List
        </IconButton>
        {this.state.showitem && (
          <>
            <br />
            <MaterialTable
              columns={columnnames}
              style={{ fontSize: 12 }}
              data={pallet.itempallet}
              title={null}
              options={{
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: false,
                search: false,
                toolbar: false,
                toolbarButtonAlignment: "left",
                maxBodyHeight: 1000,
                tableLayout: "auto",
                exportButton: false,
                padding: "dense",
              }}
            />
          </>
        )}
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(StackModal));
