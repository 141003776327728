import { computed, makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import acClearanceStore from "../AccountUpdated/ACC/ACClearanceStore";
import { Parsed } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class ACCInvoiceStore {
  constructor() {
    this.newaccinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move International Account Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: "",
      clientname: "",
      clientemail: "",
      clientphone: "",
      accInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
    this.recs = [];
    this.accinvoices = [];
    this.cancelled = false;
    this.payments = [];
    this.loading = { charges: false, invpayments: false };
    this.loaded = { charges: false, invpayments: false };
    this.client = { storageContract: {}, _id: "" };
    this.payments = [];
    this.chargeoptions = [];
    this.finalinvoice = {
      accInvoiceNumId: "",
      clientname: "",
      clientemail: "",
      clientphone: "",
      title: "",
      date: "",
      status: "",
      remarks: "",
      client: "",
      showBank: "",
      invnotes: "",
      charges: [],
    };

    makeObservable(this, {
      accinvoices: observable,
      newaccinvoice: observable,
      recs: observable,
      payments: observable,
      finalinvoice: observable,
      cancelled: observable,
      client: observable,
      chargeoptions: observable,
      loading: observable,
      loaded: observable,
      newrecs: computed,
      totalinvandpays: computed,
    });
  }

  // FETCH

  getACCCharges(overide) {
    let newclient = acClearanceStore.client._id;
    let loaded = this.loaded.charges;
    if (overide) loaded = false;
    if (this.loading.charges) loaded = true;
    if (!loaded) {
      let url = `/jf/accinvoice/accchargesbyclient/${newclient}`;
      this.clientId = newclient;
      let data = {};
      this.loading.charges = true;
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.recs = data.charges;
          this.client = data.client;
          this.loading.charges = false;
          this.loaded.charges = true;

          console.log("ACC Charges Fetched");
        })
        .catch((err) => {
          this.loading.charges = false;

          console.log(err);

          Notification["error"]({
            title: `Error Fetching ACC Charges.`,
          });
        });
    }
  }
  getInvPayments(overide) {
    let newclient = acClearanceStore.client._id;
    let loaded = this.loaded.charges;
    if (overide) loaded = false;
    if (this.loading.charges) loaded = true;
    if (!loaded) {
      let url = `/jf/accinvoice/getinvoiceandpaymentbyclient/${newclient}`;
      this.clientId = newclient;
      let data = {};
      this.loading.invpayments = true;
      return instance
        .get(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.accinvoices = data.invoices;
          this.recs = data.charges;
          this.cancelled = false;
          this.payments = data.payments;
          this.client = data.client;
          this.loading.invpayments = false;
          this.loaded.invpayments = true;

          console.log("ACC Invoices and Payments Fetched");
        })
        .catch((err) => {
          this.loading.invpayments = false;

          console.log(err);

          Notification["error"]({
            title: `Error Fetching ACC Invoices and Payments.`,
          });
        });
    }
  }
  getCancelledByClient() {
    let newclient = acClearanceStore.client._id;

    let url = `/jf/accinvoice/getcancelledinvoicesbyclient/${newclient}`;
    this.clientId = newclient;
    let data = {};
    this.loading.invpayments = true;
    return instance
      .get(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        let accinv = [...this.accinvoices, ...data];
        this.accinvoices = accinv;
        this.cancelled = true;

        this.loading.invpayments = false;

        console.log("Cancelled ACC Invoices");
      })
      .catch((err) => {
        this.loading.invpayments = false;

        console.log(err);

        Notification["error"]({
          title: `Error Fetching Cancelled ACC Invoices.`,
        });
      });
  }
  createNewInvoice() {
    this.newaccinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move Custom Clearance Account Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: this.client._id,
      clientname: this.client.name,
      clientemail: this.client.email,
      clientphone: this.client.phone,
      accInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
    this.chargeoptions = [...this.newrecs];
  }

  selectInvoice(inv) {
    this.newaccinvoice = { ...inv };
    if (inv.status !== "Cancelled") {
      this.chargeoptions = [...this.newrecs, ...inv.charges];
    } else {
      this.newaccinvoice.charges = [];
      this.chargeoptions = [...this.newrecs];
    }
  }

  editInvoice(val, label) {
    accInvoiceStore.newaccinvoice[label] = val;
  }

  changeCharge(chg) {
    let newchg;
    if (chg.selected) {
      newchg = this.newaccinvoice.charges.filter(
        (oldchg) =>
          `${oldchg.title} ${oldchg.type} ${oldchg.price} ${oldchg.accorder}` !==
          `${chg.title} ${chg.type} ${chg.price} ${chg.accorder}`
      );
    } else {
      let newchgs = [...this.newaccinvoice.charges, chg];
      newchg = newchgs.sort((a, b) => moment(a.startday) - moment(b.startday));
    }
    this.newaccinvoice.charges = [...newchg];
  }

  editFinalInvoice(data) {
    let charges = [];
    let singlecharge = {};
    let deliveryids = [];
    let deliverys = [];
    let singledel = {};

    for (let i = 0; i < data.charges.length; i++) {
      deliverys = [];
      deliveryids = [];

      if (data.charges[i].deliverys) {
        if (data.charges[i].deliverys.length > 0) {
          for (let j = 0; j < data.charges[i].deliverys.length; j++) {
            singledel = {
              deliveryNumId: data.charges[i].deliverys[j].deliveryNumId,
              jobdate: data.charges[i].deliverys[j].jobdate,
              status: data.charges[i].deliverys[j].status,
              dropoffs: data.charges[i].deliverys[j].toaddress.length,
              additional: data.charges[i].deliverys[j].charges.additional,
              cancel: data.charges[i].deliverys[j].charges.cancel,
              discount: data.charges[i].deliverys[j].charges.discount,
              location: data.charges[i].deliverys[j].charges.location,
              main: data.charges[i].deliverys[j].charges.main,
              option: data.charges[i].deliverys[j].charges.option,
              totaljob: data.charges[i].deliverys[j].charges.totaljob,
            };
            deliveryids = [...deliveryids, data.charges[i].deliverys[j]._id];
            deliverys = [...deliverys, singledel];
          }
        }
      }

      singlecharge = {
        startday: data.charges[i].startday,
        endday: data.charges[i].end,
        additionalchg: data.charges[i].additionalchg,
        discountchg: data.charges[i].discountchg,
        locationchg: data.charges[i].locationchg,
        mainchg: data.charges[i].mainchg,
        missingtruckjob: data.charges[i].missingtruckjob,
        minchg: data.charges[i].minchg,
        finalcost: data.charges[i].finalcost,
        optionchg: data.charges[i].optionchg,
        totaljobchg: data.charges[i].totaljobchg,
        deliveryids: deliveryids,
        deliverys: deliverys,
        missing: data.charges[i].missing,
      };
      charges = [...charges, singlecharge];
    }

    let final = {
      delInvoiceNumId: data.delInvoiceNumId,
      clientname: data.clientname,
      clientemail: data.clientemail,
      clientphone: data.clientphone,
      title: data.title,
      date: data.date,
      status: data.status,
      remarks: data.remarks,
      client: data.client,
      showBank: data.showBank,
      invnotes: data.invnotes,
      charges: charges,
    };
    this.finalinvoice = final;
  }

  get newrecs() {
    let single = {};
    let final = [];
    let chgs = this.newaccinvoice.charges;
    for (let i = 0; i < this.recs.length; i++) {
      single = { ...this.recs[i], selected: false };
      for (let j = 0; j < chgs.length; j++) {
        if (
          (this.recs[i].title === chgs[j].title) &
          (this.recs[i].type === chgs[j].type) &
          (this.recs[i].price === chgs[j].price) &
          (this.recs[i].accorder === chgs[j].accorder)
        ) {
          single.selected = true;
        }
      }
      final = [...final, single];
    }
    return final;
  }

  get totalinvandpays() {
    let invs = this.accinvoices.filter((inv) => inv.status !== "Cancelled");

    let totalinvoiced = 0;
    for (let i = 0; i < invs.length; i++) {
      totalinvoiced += Parsed(invs[i].totalinvoicecost);
    }
    let totalpaid = 0;
    for (let i = 0; i < this.payments.length; i++) {
      totalpaid += Parsed(this.payments[i].amount);
    }
    return { totalinv: totalinvoiced, totalpaid: totalpaid };
  }
}

const accInvoiceStore = new ACCInvoiceStore();
export default accInvoiceStore;
