import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol40white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol60blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "60%",
  },
  tableCol40blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
});

class MissingCharges extends Component {
  render() {
    let inv = this.props.inv;
    let allcharges = inv.charges.filter((chg) => chg.missing.length > 0);

    let missing = [];
    if (allcharges.length > 0) {
      for (let i = 0; i < allcharges.length; i++) {
        for (let j = 0; j < allcharges[i].missing.length; j++) {
          missing = [...missing, allcharges[i].missing[j]];
        }
      }
    }

    let charges = missing.map((ms, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol20white}>{index + 1}</Text>
        <Text style={styles.tableCol40white}>
          {moment(ms.date).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol40white}>{PRKD(ms.price)} KD</Text>
      </View>
    ));

    let totalmissing = 0;

    for (let i = 0; i < missing.length; i++) {
      totalmissing += PRKD(missing[i].price);
    }

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>
          Contractual Charges on Missing Job Dates
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol20blue}>#</Text>
          <Text style={styles.tableCol40blue}>Date</Text>
          <Text style={styles.tableCol40blue}>Contract Charges KD</Text>
        </View>
        {charges}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol60blue}>{missing.length} Empty Days</Text>
          <Text style={styles.tableCol40blue}>{PRKD(totalmissing)} KD</Text>
        </View>
      </View>
    );
  }
}

export default observer(MissingCharges);
