import { observer } from "mobx-react";

import React, { Component } from "react";
import { Divider, Icon, IconButton } from "rsuite";

import { Tabs } from "antd";

import userStore from "../../../../../../../Stores/UserStore";

import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import OrderModal from "../../../../Modals/OrderModal";
import modalACOrderStore from "../../../../../../../Stores/ModalStores/ModalACOrderStore";
import { withRouter } from "react-router";

import OrderTable from "./OrderTable";
import FlexboxButton from "../../../../../../../Components/Modal/FlexboxButton";
import { Tooltip } from "@material-ui/core";
import DatePickerSTD from "../../../../../../../Components/Inputs/STD/DatePickerSTD";
import { InputGroup, Notification } from "rsuite";
const { TabPane } = Tabs;

class OrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, tab: "Current Orders" };
    this.onHide = this.onHide.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }
  componentDidMount() {
    singleAccountStore.getACOrders(this.props.clientid);
  }
  onHide() {
    this.setState({ show: false });
  }
  ActionButs(cellData) {
    return (
      <>
        <Tooltip title={`View Order`} arrow>
          <IconButton
            icon={<Icon icon={cellData.data.loading ? "eye-slash" : "eye"} />}
            color={cellData.data.loading ? "default" : "green"}
            circle
            size="xs"
            onClick={() => {
              if (cellData.data.loading) {
                Notification["warning"]({
                  title: `Order is being updated.`,
                  description:
                    "The order is being updated and may take time depending on the number of items available. Please refresh and try again. ",
                });
              } else {
                modalACOrderStore.getASTfromOrderId(cellData.data._id);
                this.setState({ show: true });
              }
            }}
          />
        </Tooltip>
        <span> </span>
        {userStore.user.group === "EMStaff" && (
          <Tooltip title={`Print QR Code`} arrow>
            <IconButton
              icon={<Icon icon="qrcode" />}
              color={cellData.data.loading ? "default" : "blue"}
              circle
              size="xs"
              onClick={() => {
                if (cellData.data.loading) {
                  Notification["warning"]({
                    title: `Order is being updated.`,
                    description:
                      "The order is being updated and may take time depending on the number of items available. Please refresh and try again. ",
                  });
                } else {
                  this.props.history.push(
                    `/qrprintorder/${cellData.data.acOrderNumId}`
                  );
                }
              }}
            />
          </Tooltip>
        )}
      </>
    );
  }
  render() {
    return (
      <div>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <DatePickerSTD
              title={"From Date"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={singleAccountStore.startdate}
              size="large"
              editVal={singleAccountStore.editDate}
            />
            <InputGroup.Button
              color="green"
              loading={singleAccountStore.loading.acorders}
              onClick={() => singleAccountStore.getACOrders(false, true)}
            >
              Reload <Divider vertical />
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
          centered
        >
          <TabPane tab={"Current Orders"} key={"Current Orders"}>
            <OrderTable
              data={singleAccountStore.acorders}
              type="Current"
              actionButs={this.ActionButs}
            />
          </TabPane>
          <TabPane tab={"Past Orders"} key={"Past Orders"}>
            {singleAccountStore.loaded.pastorders ? (
              <OrderTable
                data={singleAccountStore.pastorders}
                type="Past"
                actionButs={this.ActionButs}
              />
            ) : (
              <FlexboxButton
                icon="eye"
                color="green"
                text="Load Past Orders"
                app="primary"
                loading={singleAccountStore.loading.acorders}
                oC={() => singleAccountStore.getCustomOrderStatus("Completed")}
              />
            )}
          </TabPane>
          <TabPane tab={"Cancelled Orders"} key={"Cancelled Orders"}>
            {singleAccountStore.loaded.cancelledorders ? (
              <OrderTable
                data={singleAccountStore.cancelledorders}
                type="Cancel"
                actionButs={this.ActionButs}
              />
            ) : (
              <FlexboxButton
                icon="eye"
                color="green"
                text="Load Cancelled Orders"
                app="primary"
                loading={singleAccountStore.loading.acorders}
                oC={() => singleAccountStore.getCustomOrderStatus("Cancelled")}
              />
            )}
          </TabPane>
        </Tabs>

        <br />
      </div>
    );
  }
}

export default withRouter(observer(OrderMain));
