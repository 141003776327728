import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import PasswordInput from "../../../Components/Inputs/Basic/PasswordInput";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passconf: "",
    };
    this.changeSingle = this.changeSingle.bind(this);
  }

  changeSingle(type, val) {
    // if (type === "username") {
    //   val = val.replace(/[^a-zA-Z1-9]/g, "").toLowerCase();
    // }
    this.setState({ [type]: val });
  }

  render() {
    let passerror = { val: false, message: "" };
    let passconferr = { val: false, message: "" };

    if (this.state.password.length < 6) {
      passerror = {
        val: true,
        message: "Password must be 6 Characters or more.",
      };
    } else if (this.state.passconf !== this.state.password) {
      passconferr = {
        val: true,
        message: "Passwords do not match.",
      };
    }

    return (
      <PasswordInput
        type="username"
        pass={this.state.password}
        conf={this.state.passconf}
        changeSingle={this.changeSingle}
        passerror={passerror}
        passconferr={passconferr}
      />
    );
  }
}

export default withRouter(observer(PasswordReset));
