import { observer } from "mobx-react";
import React, { Component } from "react";
import { PageHeader, Tag } from "antd";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { StatusColorCalc } from "../../../../../../../Functions/Colors/StatusColorCalc";

class SJHeader extends Component {
  render() {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let numId = `sjNumId`;
    let subtitle;
    if (sj.jobtype === "LM") subtitle = "Local Move";
    if (sj.jobtype === "IM") subtitle = "International Move";
    if (sj.jobtype === "CC") subtitle = "Custom Clearance";
    if (sj.jobtype === "ST") subtitle = "Storage Job";

    let backColor = StatusColorCalc(sj.status);

    return (
      <Div14>
        <PageHeader
          //className="site-page-header"
          title={`${sj.jobtype}-${sj[numId]}`}
          subTitle={subtitle}
          tags={
            <Tag color={backColor} style={{ color: "black" }}>
              {sj.status}
            </Tag>
          }
        />
      </Div14>
    );
  }
}

export default observer(SJHeader);
