import { Tag, PageHeader, Alert, Badge } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, IconButton, Notification } from "rsuite";
import newStorageStore from "../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import userStore from "../../../../Stores/UserStore";
import OrderSummaryTable from "../Modals/Supporting/OrderSummaryTable";

class SingleACOrderHeader extends Component {
  render() {
    let sto = newStorageStore.oldorder;
    let account = newStorageStore.account;
    let tagcolor = "orange";
    if (sto.status === "Requested") tagcolor = "orange";
    if (sto.status === "Booked") tagcolor = "green";
    if (sto.status === "In Transit") tagcolor = "blue";
    if (sto.status === "Completed") tagcolor = "darkgreen";
    if (sto.status === "Cancelled") tagcolor = "darkred";

    let cancbut = (
      <IconButton
        icon={<Icon icon="trash" />}
        color="red"
        size="sm"
        disabled={newStorageStore.stopedit}
        onClick={() => {
          let disabled = false;
          let items = [];
          let toadd = newStorageStore.neworder.toaddress;

          if (newStorageStore.delstoinout.inout === "Out") {
            for (let k = 0; k < toadd.length; k++) {
              for (let i = 0; i < toadd[k].acitemmove.length; i++) {
                if (
                  parseFloat(toadd[k].acitemmove[i].acitem.currentstock) <
                  parseFloat(toadd[k].acitemmove[i].actualquantity)
                ) {
                  disabled = true;
                  items = [...items, toadd[k].acitemmove[i].acitem];
                }
              }
            }
          }
          if (disabled) {
            let itemmap = items.map((it, index) => (
              <span key={index}>
                <b>
                  {index + 1}) {it.name} (ID#{it.acItemNumId})
                </b>
                : {it.currentstock} Left
                <br />
              </span>
            ));

            Notification["error"]({
              title: `${items.length} item${
                items.length === 1 ? "" : "s"
              } Already Moved from Inventory.`,
              description: (
                <div>
                  <p>
                    <b>
                      The items have been moved out of inventory. Please modify
                      the below items to match the stock count before
                      Reactivating:
                    </b>
                    <br />
                    {itemmap}
                  </p>
                </div>
              ),
            });
          } else {
            this.props.changeST(true, "showCancel");
          }
        }}
      >
        Cancel Job
      </IconButton>
    );
    if (sto.status === "Cancelled") {
      cancbut = <div />;
      if (userStore.user.staffaccount) {
        cancbut = (
          <IconButton
            icon={<Icon icon="check" />}
            color="green"
            size="sm"
            appearance="ghost"
            disabled={newStorageStore.stopedit}
            onClick={() => {
              let disabled = false;
              let items = [];
              if (newStorageStore.delstoinout.inout === "Out") {
                for (let i = 0; i < newStorageStore.acitemmove.length; i++) {
                  if (
                    parseFloat(
                      newStorageStore.acitemmove[i].acitem.currentstock
                    ) < parseFloat(newStorageStore.acitemmove[i].actualquantity)
                  ) {
                    disabled = true;
                    items = [...items, newStorageStore.acitemmove[i].acitem];
                  }
                }
              }
              if (disabled) {
                let itemmap = items.map((it, index) => (
                  <>
                    <b key={index}>
                      {index + 1}) {it.name}
                    </b>
                    : {it.currentstock} Left
                    <br />
                  </>
                ));
                Notification["error"]({
                  title: `${items.length} item${
                    items.length === 1 ? "" : "s"
                  } NOT In Stock.`,
                  description: (
                    <div>
                      <p>
                        <b>
                          Please modify the below items to match the stock count
                          before Reactivating:
                        </b>
                        <br />
                        {itemmap}
                      </p>
                    </div>
                  ),
                });
              } else {
                this.props.changeST(true, "showCancel");
              }
            }}
          >
            Re-Activate Job
          </IconButton>
        );
      }
    }
    return (
      <div>
        <PageHeader
          ghost={true}
          onBack={() => this.props.history.push(`/ac/dashboard/${account._id}`)}
          title={`AC Order #${sto.acOrderNumId} for ${account.name}`}
          tags={
            <Tag color={tagcolor}>
              <Badge
                status={
                  sto.status === "Cancelled" || sto.status === "Completed"
                    ? "default"
                    : "processing"
                }
                style={{
                  color:
                    sto.status === "Cancelled" || sto.status === "Completed"
                      ? "white"
                      : "black",
                }}
                color={
                  sto.status === "Cancelled" || sto.status === "Completed"
                    ? "white"
                    : tagcolor
                }
                text={sto.status}
              />
            </Tag>
          }
          extra={[
            <IconButton
              size="sm"
              icon={<Icon icon="refresh" />}
              appearance="primary"
              onClick={() => newStorageStore.getSingleACOrder(false, true)}
            >
              Refresh Data
            </IconButton>,
            cancbut,
          ]}
        >
          <OrderSummaryTable order={sto} account={account} hideloc hidetitle />
        </PageHeader>
        {newStorageStore.stopedit && (
          <Alert
            message={
              sto.loading
                ? "The job details are currently being updated and may take time due to the large order side. For modifications, please refresh and try again later."
                : sto.status === "Booked" ||
                  sto.status === "In Transit" ||
                  sto.status === "Completed"
                ? `Job is booked or has already started with the status being ${sto.status}. For order modifications please contact our office.`
                : "Job is within 2 hours, for order modifications please contact our office."
            }
            type="warning"
            showIcon
            closable
            style={{ paddingLeft: "2%", paddingRight: "2%" }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(SingleACOrderHeader));
