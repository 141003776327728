import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import equipmentStore from "../../../../Stores/EquipmentStores/EquipmentStore";
import MaterialTable from "material-table";
import moment from "moment";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";
import ModalFooter from "../../../../Components/Modal/ModalFooter";

import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";

import NewEquipModal from "./NewEquipModal";
import singleEquipmentStore from "../../../../Stores/EquipmentStores/SingleEquipmentStore";

const instance = axios.create({ baseURL: baseURL });

class EquipmentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownew: false,
      showedit: false,
      loading: false,
      equipmentid: "",
    };
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
  }
  componentDidMount() {
    equipmentStore.getEquipments();
  }
  postNew = async () => {
    let data = equipmentStore.equipment;
    let url = "/hr/equipment/new";
    this.setState({ loading: true });
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        message.success("Created New Equipment");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Create New Equipment");
      });
  };

  postEdit = async () => {
    let data = equipmentStore.equipment;
    let url = `/hr/equipment/edit/${this.state.equipmentid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        message.success("Updated Equipment");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Update Equipment");
      });
  };
  onHide() {
    this.setState({
      shownew: false,
      showedit: false,
      equipmentid: "",
      loading: false,
    });
    equipmentStore.resetEquipment();
  }

  render() {
    let disabled = false;
    if (equipmentStore.equipment.license === "") disabled = true;
    if (equipmentStore.equipment.remark === "") disabled = true;
    let data = equipmentStore.equipments;
    let tableTitle = "Equipment";
    let columnnames = [
      {
        title: "ID",
        field: "equipmentNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Type", field: "type", removable: true },
      {
        title: "Date",
        field: "date",
        removable: true,
        render: (rowData) => (
          <div>{moment(rowData.date).format("DD-MMM-YY")}</div>
        ),
      },
      { title: "Status", field: "status", removable: true },
      { title: "Plate", field: "license", removable: true },
      { title: "Remarks", field: "remark", removable: true },
      {
        title: "Checklist",
        field: "checklist",
        removable: true,
        render: (rowData) => {
          let output = (
            <>
              <Icon icon="warning" style={{ color: "red" }} /> Missing Checklist
            </>
          );
          if (rowData.checklist) {
            if (rowData.checklist.problems) {
              if (rowData.checklist.problems.length > 0) {
                output = (
                  <>
                    <Icon
                      icon="exclamation-triangle"
                      style={{ color: "orange" }}
                    />{" "}
                    {rowData.checklist.problems.length} Issue
                    {rowData.checklist.problems.length !== 1 ? "s" : ""}
                  </>
                );
              } else {
                output = (
                  <>
                    <Icon icon="check" style={{ color: "green" }} /> No Issues
                  </>
                );
              }
            }
          }
          return output;
        },
      },
      {
        title: "Last Checklist",
        field: "checklist",
        removable: true,
        render: (rowData) => {
          let output = <></>;
          if (rowData.checklist) {
            if (rowData.checklist.problems) {
              output = (
                <>
                  {moment(rowData.checklist.date).format("DD-MMM-YY")} (
                  {moment(rowData.checklist.date).fromNow()})
                </>
              );
            }
          }
          return output;
        },
      },
      {
        title: "Last Fuel",
        field: "lastfuel.date",
        removable: true,
        render: (rowData) => {
          let output = <div />;
          if (rowData.lastfuel) {
            if (rowData.lastfuel.date) {
              output = (
                <div>
                  {moment(rowData.lastfuel.date).format("DD-MMM-YY")} (
                  {moment(rowData.lastfuel.date).fromNow()})
                </div>
              );
            }
          }
          return output;
        },
      },
      {
        title: "Last Service",
        field: "lastservice.date",
        removable: true,
        render: (rowData) => {
          let output = <div />;
          if (rowData.lastservice) {
            if (rowData.lastservice.date) {
              output = (
                <div>
                  {moment(rowData.lastservice.date).format("DD-MMM-YY")} (
                  {moment(rowData.lastservice.date).fromNow()})
                </div>
              );
            }
          }
          return output;
        },
      },
    ];
    return (
      <div>
        <UnifiedModal
          show={this.state.shownew}
          title={`Add New Equipment`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New"]}
              oC1={() => this.onHide()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <NewEquipModal />
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showedit}
          title={`Edit Equipment Details`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Update Equipment"]}
              oC1={() => this.onHide()}
              oC2={() => this.postEdit()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <NewEquipModal />
        </UnifiedModal>

        <div style={{ padding: "1%" }}>
          <MaterialTable
            isLoading={equipmentStore.loading.equipments}
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            onRowClick={async (event, rowData, togglePanel) => {
              singleEquipmentStore.getEquipment(rowData._id, true);
              this.props.history.push(`/equipment/${rowData._id}`);
            }}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Equipment",
                onClick: (event, rowData) => {
                  equipmentStore.resetEquipment(rowData);
                  this.setState({
                    equipmentid: rowData._id,
                    showedit: true,
                  });
                },
              },
              {
                icon: "refresh",
                tooltip: "Refresh",
                isFreeAction: true,
                onClick: (event, rowData) => {
                  equipmentStore.getEquipments(true);
                },
              },
            ]}
          />
        </div>
        <hr />
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              icon={<Icon icon="plus" />}
              block
              color="green"
              onClick={() => this.setState({ shownew: true })}
            >
              Create New Equipment
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(EquipmentTable));
