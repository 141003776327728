import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class StaticCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      modalShow: false,
      estCalendar: false,
      perfCalendar: false,
    };
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";

    if (event.status === "Complete") {
      backgroundColor = "green";
    } else if (event.status === "Pending") {
      backgroundColor = "#29ff62";
    }

    let textColor = "white";
    if (event.status === "Pending") {
      backgroundColor = "#fff700";
      textColor = "black";
    }

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 10,
      height: 15,
      color: textColor,
    };
    return {
      style: style,
    };
  };

  render() {
    let data = this.props.data;
    let start = this.props.start;
    let end = this.props.end;
    let title = this.props.title;
    let height = 500;
    if (this.props.height) height = this.props.height;

    return (
      <div>
        <Calendar
          localizer={localizer}
          events={data}
          eventPropGetter={this.eventStyleGetter}
          startAccessor={start}
          endAccessor={end}
          style={{ height: height }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor={title}
          popup
          onSelectEvent={(e) => this.props.onSelectEvent(e)}
          onNavigate={(e) => {}}
        />
        <hr />
      </div>
    );
  }
}

export default observer(StaticCalendar);
