import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import { message } from "antd";
import moment from "moment";
const instance = axios.create({ baseURL: baseURL });

class SubmitNewAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.postSignIn = this.postSignIn.bind(this);
    this.postSignOut = this.postSignOut.bind(this);
  }

  postSignIn() {
    let data = { date: moment() };
    let url = `/hr/attendanceinout/newsignin/${this.props.staffid}`;
    this.setState({ loading: true });
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.getStaff();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Add Attendance");
      });
  }
  postSignOut() {
    let data = { date: moment() };
    let url = `/hr/attendanceinout/signout/${this.props.staffid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.getStaff();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Add Attendance");
      });
  }

  render() {
    let lastin = "";
    let lastout = "";

    let disablein = false;
    console.log(this.props.lastdate);
    if (this.props.lastdate) {
      if (moment(this.props.lastdate).isSameOrAfter(moment().startOf("day"))) {
        lastin = this.props.lastin;
        lastout = this.props.lastout;
        disablein = true;
      }
    }

    return (
      <div>
        Last Attendance:
        <hr />
        <FlexboxGrid>
          <Col2>
            <h5 style={{ textAlign: "center" }}>{lastin}</h5>
          </Col2>
          <Col2>
            <h5 style={{ textAlign: "center" }}>{lastout}</h5>
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <IconButton
              loading={this.state.loading}
              icon={<Icon icon="sign-in" />}
              color="green"
              block
              onClick={this.postSignIn}
              disabled={disablein}
            >
              Sign In
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              loading={this.state.loading}
              icon={<Icon icon="sign-out" />}
              color="red"
              block
              onClick={this.postSignOut}
              disabled={!disablein}
            >
              Sign Out
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(SubmitNewAttendance));
