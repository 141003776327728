import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import { STPriceCalc } from "../../../../../../Functions/Pricing/STPriceCalc";

class STSummary extends Component {
  render() {
    let sj = this.props.sj;
    let price = STPriceCalc(sj);
    return (
      <div>
        <Div14>Pricing:</Div14>
        <p>
          <b>Total CBM: </b>
          {sj.cbm} CBM
          <br />
          <b>Type: {sj.subtype}</b>
          <br />
          <b>Price: </b>
          {PRKD(price[0])} KD
          <br />
          <b>Job Price: </b>
          {price[1]} KD Total ({PRKD(sj.pricepertime)} x {PRKD(sj.time)}{" "}
          {price[5]})
          <br />
          <b>Add Charges: </b>
          {price[2]} KD ({sj.addcharge.length})
          <br />
          <b>Total Price: </b>
          {Parsed(price[0])} KD
        </p>
      </div>
    );
  }
}

export default observer(STSummary);
