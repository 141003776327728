import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

import moment from "moment";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol1blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "35%",
  },
  tableCol3blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol4blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol1white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "35%",
  },
  tableCol3white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol4white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
});

class ToAddressItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let toadd = this.props.toadd;
    let toadds = this.props.sto.toaddress;
    let startindex = 0;
    let node = this.props.index;
    for (let i = 0; i < node; i++) {
      startindex += toadds[i].acitemmove.length;
    }
    let acitemmove = toadd.acitemmove.sort((a, b) =>
      a.acitem.serialnumber < b.acitem.serialnumber ? -1 : 1
    );

    return acitemmove.map((itemmove, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol1white}>{startindex + index + 1}</Text>
        <Text style={styles.tableCol2white}>{itemmove.acitem.name}</Text>
        <Text style={styles.tableCol3white}>
          {itemmove.acitem.serialnumber}
        </Text>
        <Text style={styles.tableCol4white}>{itemmove.actualquantity}</Text>
        <Text style={styles.tableCol5white}>{itemmove.acitem.acItemNumId}</Text>
        <Text style={styles.tableCol5white}>
          {itemmove.acitem.loctags.join(", ")}
        </Text>
        <Text style={styles.tableCol5white}></Text>
      </View>
    ));
  }
}

class HeaderSection extends Component {
  render() {
    let sto = this.props.sto;
    let client = this.props.client;

    // let type;
    // if (sto.jobtype === "Storage In" || sto.jobtype === "Delivery In")
    // type = "In";
    // if (sto.jobtype === "Storage Out" || sto.jobtype === "Delivery Out")
    // type = "Out";

    let acOrderId = sto.acOrderNumId;
    let clientname = client.name;

    let proposaltitle = `${sto.jobtype} - Items List for ${clientname} - Order #${acOrderId}`;

    let itemslist = sto.toaddress.map((to, index) => (
      <ToAddressItems toadd={to} key={index} sto={sto} index={index} />
    ));

    return (
      <View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Date: {moment(sto.jobdate).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>Order ID: {acOrderId}</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>info@easymovekw.com</Text>
        </View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text style={styles.header}>{proposaltitle}</Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Below is the mentioned Items List for Order ID#{acOrderId} on{" "}
          {moment(sto.jobdate).format("DD-MMM-YYYY")}.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol1blue}>#</Text>
          <Text style={styles.tableCol2blue}>Item</Text>
          <Text style={styles.tableCol3blue}>Serial No.</Text>
          <Text style={styles.tableCol4blue}>Quantity</Text>
          <Text style={styles.tableCol5blue}>Item ID</Text>
          <Text style={styles.tableCol5blue}>Loc</Text>
          <Text style={styles.tableCol5blue}>Check</Text>
        </View>
        {itemslist}
      </View>
    );
  }
}

export default observer(HeaderSection);
