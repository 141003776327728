import { observer } from "mobx-react";
import React, { Component } from "react";
import SJNumbInput from "../../../../../../Components/Inputs/SJ/SJNumbInput";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import { FlexboxGrid, Button } from "rsuite";
import { LMPriceRangeCalc } from "../../../../../../Functions/Pricing/LMPriceCalc";
import Col3 from "../../../../../../Components/Columns/Col3";
import { Parsed } from "../../../../../../Functions/Pricing/PRKDCalc";

class LMPrice extends Component {
  render() {
    let ind = this.props.ind;

    let load = this.props.load;
    let esj = sjStore.esjs[ind];
    let calcPrice = LMPriceRangeCalc(esj);

    let butdeets = ["green", "Within Recommended Price Range"];
    if (Parsed(sjStore.sjs[ind].lm.lastprice) < Parsed(calcPrice.finalmin)) {
      butdeets = ["red", "Below Recommended Price Range"];
    }
    if (Parsed(sjStore.sjs[ind].lm.lastprice) > Parsed(calcPrice.finalmax)) {
      butdeets = ["red", "Above Recommended Price Range"];
    }
    let quality = this.props.quality;
    let editview = this.props.editview;

    return (
      <div>
        <FlexboxGrid>
          <Col3>
            <SJNumbInput
              ind={ind}
              label="cbm"
              title="CBM"
              load={load.cbm}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              read={!editview}
            />
          </Col3>
          {editview && (
            <Col3>
              <SJNumbInput
                ind={ind}
                label="firstprice"
                title="1st Price"
                load={load.firstprice}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                quality={quality}
                sj="lm"
              />
            </Col3>
          )}

          <Col3>
            <SJNumbInput
              ind={ind}
              label="lastprice"
              title="Last Price"
              load={load.lastprice}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="lm"
              read={!editview}
            />
          </Col3>

          {editview && (
            <Col3>
              <SJNumbInput
                ind={ind}
                read={true}
                step={0}
                label="min"
                title={`Min KD (${calcPrice.minpcbm}/cbm)`}
                load={load.last}
                size="sm"
                value={calcPrice.finalmin}
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
              />
            </Col3>
          )}
          {editview && (
            <Col3>
              <SJNumbInput
                ind={ind}
                read={true}
                step={0}
                label="min"
                title={`Max KD (${calcPrice.maxpcbm}/cbm)`}
                value={calcPrice.finalmax}
                load={load.last}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
              />
            </Col3>
          )}
          {editview && (
            <Col3>
              <FlexboxGrid justify="center" align="middle">
                <Button block color={butdeets[0]}>
                  <b>{butdeets[1]}</b>
                </Button>
              </FlexboxGrid>
            </Col3>
          )}
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(LMPrice);
