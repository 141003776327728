import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import HoverImage from "../../../../Components/Advanced/HoverImage";
import VisiblityCode from "../../../../Components/Advanced/VisiblityCode";
class BannerImage extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    let imageleft = this.props.imageleft;
    let color;
    let textcolor = "black";
    let shadcolor = "black";
    if (this.props.shadcolor) shadcolor = this.props.shadcolor;
    if (this.props.color) color = this.props.color;
    if (this.props.textcolor) textcolor = this.props.textcolor;

    let imageblock = (
      <Col2>
        <HoverImage image={this.props.image} />
      </Col2>
    );
    let title = (
      <h1
        style={{
          fontFamily: "Work Sans",
          fontWeight: 900,
          color: textcolor,
          textShadow: `1px 1px ${shadcolor}`,
        }}
      >
        {this.props.title}
      </h1>
    );
    if (this.props.titlecomp) {
      title = this.props.titlecomp;
    }

    let textblock = (
      <Col2>
        {title}
        <hr />
        <h3 style={{ color: textcolor }}>{this.props.subtitle}</h3>
        <hr />
        <h5 style={{ color: textcolor }}>{this.props.details}</h5>
        <br />
        {this.props.button}
      </Col2>
    );
    return (
      <VisiblityCode alwaysvisible={this.props.alwaysvisible}>
        <div style={{ backgroundColor: color }}>
          <div>
            <FlexboxGrid justify="center" align="middle">
              {imageleft ? imageblock : textblock}
              {!imageleft ? imageblock : textblock}
            </FlexboxGrid>
          </div>
        </div>
      </VisiblityCode>
    );
  }
}

export default withRouter(observer(BannerImage));
