import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newDeliveryStore from "../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import moment from "moment";

import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { Badge, message, Tabs } from "antd";
import zoneStore from "../../../../Stores/Admin/Lists/ZoneStore";
import carStore from "../../../../Stores/AdditionalStores/CarStore";
import SingleDelOrderHeader from "./SingleDelOrderHeader";
import { FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import LiveTracking from "../../../../Components/CarTracking/LiveTracking";
import CostSummaryTable from "../Modals/Supporting/CostSummaryTable";
import Div14 from "../../../../Components/Dividers/Div14";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import Timing from "./Tabs/Timing";
import Options from "./Tabs/Options";
import AdditionalCharges from "./Tabs/AdditionalCharges";
import Discounts from "./Tabs/Discounts";
import FixedPerType from "./Tabs/Custom/FixedPerType";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import CancelJobModal from "./Tabs/CancelJobModal";
import LocationMain from "./Tabs/Location/LocationMain.";
import PickupDropoffButton from "./Tabs/PickupDropoff/PickupDropoffButton";
import PickupNote from "./Tabs/PickupNote";
import FromLocation from "./Tabs/Location/FromLocation";
import DeliveryNotes from "./Tabs/DeliveryNotes";
import ItemsList from "./Tabs/Custom/ItemsList";
import AddAttTemplate from "./Tabs/Attachment/AddAttTemplate";

import StartAndEndDate from "./Tabs/StartAndEndDate";

const instance = axios.create({ baseURL: baseURL });
const { TabPane } = Tabs;
class SingleDelOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      showApprove: false,
      showReject: false,
      load: { status: false },
      tab: "Summary",
    };

    this.postChanges = this.postChanges.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.changeST = this.changeST.bind(this);
    this.cancelJob = this.cancelJob.bind(this);
  }

  onHide() {
    this.setState({
      showApprove: false,
      showReject: false,
      showCancel: false,
      load: false,
    });
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  editVal(val, label) {
    if (label === "startdate" || label === "enddate") {
      if (userStore.user.staffaccount) {
        newDeliveryStore.editOrder(val, label);
      } else {
        Notification["error"]({
          title: `Please contact EM Staff to Modify`,
          description: "Please contact our office for support.",
        });
      }
    } else {
      if (!newDeliveryStore.stopedit) {
        newDeliveryStore.editOrder(val, label);
      } else {
        Notification["error"]({
          title: `Cannot edit as the job is Booked or within 2 hours.`,
          description: "Please contact our office for support.",
        });
      }
    }
  }
  cancelJob = async (free, type) => {
    let data = {};
    let prices = { ...newDeliveryStore.newdel.prices };
    let url = "/";
    if (type === "Cancelled") {
      prices.canctime = moment();
      prices.canccost = 0;
      if (!free) {
        prices.canccost = newDeliveryStore.delCont.cancelprice;
      }
      data = { status: "Cancelled", prices: prices, discount: [] };
      url = `ac/delivery/canceljob/${this.props.match.params.deliveryid}`;
    } else if (type === "Reactivated") {
      prices.canctime = undefined;
      prices.canccost = 0;
      data = { status: "Booked", prices: prices };
      url = `ac/delivery/activatejob/${this.props.match.params.deliveryid}`;
    }

    this.setState({ load: { status: true } });
    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.getSingleACOrder(false, true);
        message.success(`Order Status Changed (${data.status})`);
        this.setState({ load: { status: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { status: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  postChanges = async (label, title, index) => {
    let data = { [label]: newDeliveryStore.newdel[label] };

    let reloadpage;
    if (label === "jobdate") {
      let prices = { ...newDeliveryStore.newdel.prices };
      let ppt = newDeliveryStore.delCont.pricepertrip;
      let day = moment(newDeliveryStore.newdel.jobdate).day();

      let alldays = [
        { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
        { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
        { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
        { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
        { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
        { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
        { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
      ];
      if (alldays[day].price > 0) {
        prices.weekday = alldays[day].title;
        prices.weekdayprice = alldays[day].price;
      }
      data.prices = { ...prices };
      reloadpage = true;
    }

    this.setState({ load: { [label]: true } });
    await instance
      .put(`ac/delivery/edit/${this.props.match.params.deliveryid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.updateOrgOrder(label);

        message.success(`Order Updated (${title})`);
        if (reloadpage) {
          newDeliveryStore.getSingleACOrder(false, true);
        }
        this.setState({ load: { [label]: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  componentDidMount() {
    newDeliveryStore.getSingleACOrder(this.props.match.params.deliveryid);
    zoneStore.getZones();
    carStore.getCars();
  }
  render() {
    let del = newDeliveryStore.olddel;

    let delCont = newDeliveryStore.delCont;
    let timechg = newDeliveryStore.ctimechg;
    let account = newDeliveryStore.account;
    let load = this.state.load;
    let staffButs;
    let showdropoffnote = false;
    let toadd = del.toaddress;
    for (let i = 0; i < toadd.length; i++) {
      if (toadd[i].delSignature) {
        if (toadd[i].delSignature.name !== "") showdropoffnote = true;
      }
    }
    let showpickupnote = false;

    let showAttachment = userStore.user.staffaccount;
    if (del.attachments) {
      if (del.attachments.length > 0) {
        showAttachment = true;
      }
    }

    if (del.fromaddress.delSignature) {
      if (del.fromaddress.delSignature.name !== "") showpickupnote = true;
    }
    let showLive = false;
    if (del.trucktrack) {
      if ((del.trucktrack !== "") & (del.status === "In Transit"))
        showLive = true;
    }
    if (del.status === "Requested") {
      staffButs = (
        <div>
          <FlexboxGrid justify="center">
            <Col2>
              <Div14>Approve / Reject Request</Div14>
              <ModalFooter
                icon={["trash", "check-circle"]}
                color={["red", "green"]}
                app={["default", "default"]}
                text={["Cancel Request", "Approve Request"]}
                oC1={() => this.setState({ showCancel: true })}
                oC2={() => {
                  newDeliveryStore.editOrder("Booked", "status");
                  this.postChanges("status", "Job Status");
                }}
                loading1={load.status}
                loading2={load.status}
              />
            </Col2>
          </FlexboxGrid>
          <hr />
        </div>
      );
    } else if (del.status === "Booked" || del.status === "In Transit") {
      staffButs = <PickupDropoffButton />;
    }

    return (
      <LoadBack loading={newDeliveryStore.loading.newdel}>
        <CancelJobModal
          show={this.state.showCancel}
          cancelJob={this.cancelJob}
          onHide={this.onHide}
          delCont={delCont}
          account={account}
          del={del}
          timechg={newDeliveryStore.ctimechg}
          load={this.state.load.status}
        />
        <SingleDelOrderHeader changeST={this.changeST} />
        {userStore.user.group === "EMStaff" && staffButs}
        <StartAndEndDate />
        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            {showLive && (
              <TabPane
                tab={
                  <Badge
                    status="processing"
                    color={"green"}
                    text="Live Tracking"
                  />
                }
                key={"Live Tracking"}
              >
                <LiveTracking truckid={del.trucktrack._id} />
              </TabPane>
            )}
            <TabPane tab={"Summary"} key={"Summary"}>
              <CostSummaryTable del={del} timechg={timechg} />
            </TabPane>
            <TabPane tab={"Timing"} key={"Timing"}>
              <Timing
                load={this.state.load.jobdate}
                postChanges={this.postChanges}
              />
            </TabPane>

            <TabPane tab={"Locations"} key={"Locations"}>
              <FromLocation />
              <hr />
              <LocationMain editVal={this.editVal} />
            </TabPane>

            {del.maintype === "Fixed Per Type" ? (
              <TabPane tab={"Services"} key={"Services"}>
                <FixedPerType />
              </TabPane>
            ) : (
              <TabPane tab={"Items"} key={"Items"}>
                {del.toaddress.map((to, index) => (
                  <ItemsList key={index} to={to} />
                ))}
              </TabPane>
            )}
            <TabPane tab={"Options"} key={"Options"}>
              <Options />
            </TabPane>
            <TabPane tab={"Additional Charges"} key={"Additional Charges"}>
              <AdditionalCharges />
              <hr />
              <Discounts />
            </TabPane>
            {showAttachment && (
              <TabPane tab={"Attachments"} key={"Attachments"}>
                <AddAttTemplate attType={"Additional"} />
              </TabPane>
            )}
            {showpickupnote && (
              <TabPane tab={"Pickup Note"} key={"Pickup Note"}>
                <PickupNote />
              </TabPane>
            )}
            {showdropoffnote && (
              <TabPane tab={"Dropoff Note"} key={"Dropoff Note"}>
                <DeliveryNotes />
              </TabPane>
            )}
          </Tabs>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(SingleDelOrderPage));
