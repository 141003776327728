import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import moment from "moment";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { HelpBlock } from "rsuite";
import ChargesTable from "./Supporting/OrderSummarySupport/ChargesTable";

class SACStep6 extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Order Summary", locked: false, step: 1 };
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };
  render() {
    let order = newStorageStore.neworder;
    return (
      <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
        <Div14>Finalize Order</Div14>
        <h4 style={{ textAlign: "center" }}>
          <HelpBlock>Job Time</HelpBlock>
          {moment(order.jobdate).format("DD-MMM-YY HH:mm")}
          <HelpBlock>{moment(order.jobdate).fromNow()}</HelpBlock>
        </h4>
        <Div14>Charges Summary:</Div14>
        <ChargesTable />
      </div>
    );
  }
}

export default observer(SACStep6);
