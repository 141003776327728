import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import moment from "moment";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class EditAccountStore {
  constructor() {
    this.clientid = "";
    this.original = { status: "", accountNumId: "", client: { name: "" } };
    this.edited = { status: "", accountNumId: "", client: { name: "" } };
    this.loaded = false;
    this.loading = false;
    this.load = {};

    makeObservable(this, {
      clientid: observable,
      original: observable,
      edited: observable,
      loaded: observable,
      loading: observable,
      load: observable,
    });
  }

  // FUNCTIONS

  editmain(val, label) {
    if (label === "startdate" || label === "enddate") {
      val = moment(val).set({
        minutes: 0,
        seconds: 0,
        hours: 0,
        millisecond: 0,
      });
    }
    editAccountStore.edited[label] = val;
  }
  editstozone(val, zonelabel) {
    editAccountStore.edited.storageContract.zoneadd[zonelabel] = val;
  }
  editstodayrate(val, dayratelabel) {
    editAccountStore.edited.storageContract.pricepertrip[dayratelabel] = val;
  }

  resetzone() {
    editAccountStore.edited.storageContract.zoneadd = {
      ...editAccountStore.original.storageContract.zoneadd,
    };
  }
  resetdayrate() {
    editAccountStore.edited.storageContract.pricepertrip = {
      ...editAccountStore.original.storageContract.pricepertrip,
    };
  }

  editSto(val, stolabel) {
    editAccountStore.edited.storageContract[stolabel] = val;
  }
  editLoc(newloc) {
    let oldlocs = [...editAccountStore.edited.storageContract.emlocations];
    let newlocs = [...editAccountStore.edited.storageContract.emlocations];

    let indexVal = oldlocs.findIndex((loc) => loc._id === newloc._id);
    if (indexVal >= 0) {
      newlocs.splice(indexVal, 1);
    } else {
      newlocs = [...newlocs, newloc];
    }
    editAccountStore.edited.storageContract.emlocations = [...newlocs];
  }

  changeContract(label) {
    let edited = editAccountStore.edited;
    let original = editAccountStore.original;
    if (label === "storageContract") {
      if (edited.storageContract) {
        edited.storageContract = null;
      } else {
        if (original.storageContract) {
          edited.storageContract = original.storageContract;
        } else {
          edited.storageContract = true;
        }
      }
      edited.deliveryContract = null;
    } else if (label === "deliveryContract") {
      if (edited.deliveryContract) {
        edited.deliveryContract = null;
      } else {
        if (original.deliveryContract) {
          edited.deliveryContract = original.deliveryContract;
        } else {
          edited.deliveryContract = true;
        }
      }
      edited.storageContract = null;
    }
  }

  updateOriginal(label) {
    editAccountStore.original[label] = editAccountStore.edited[label];
  }

  resetContract(org) {
    if (org.storageContract) {
      editAccountStore.edited.storageContract = { ...org.storageContract };
    } else {
      editAccountStore.edited.storageContract = null;
    }
    if (org.deliveryContract) {
      editAccountStore.edited.deliveryContract = { ...org.deliveryContract };
    } else {
      editAccountStore.edited.deliveryContract = null;
    }
  }

  resetMain(labels) {
    let original = { ...editAccountStore.original };
    let org;
    if (Array.isArray(labels)) {
      for (let i = 0; i < labels.length; i++) {
        org = original[labels[i]];
        editAccountStore.edited[labels[i]] = org;
      }
    } else {
      org = editAccountStore.original[labels];
      editAccountStore.edited[labels] = org;
    }
  }
  editStoTiming(label, value) {
    editAccountStore.edited.storageContract.excludedhours[label] = value;
  }
  resettiming() {
    editAccountStore.edited.storageContract.excludedhours = {
      ...editAccountStore.original.storageContract.excludedhours,
    };
  }
  resetopts() {
    editAccountStore.edited.storageContract.options = [
      ...editAccountStore.original.storageContract.options,
    ];
  }
  resetSingleLabel(label) {
    editAccountStore.edited.storageContract.emlocations = [
      ...editAccountStore.original.storageContract.emlocations,
    ];
  }

  // Delivery Options:
  addDeliveryOption(title, desc, price, type, inout) {
    editAccountStore.edited.storageContract.options = [
      ...editAccountStore.edited.storageContract.options,
      { title: title, desc: desc, price: price, type: type, inout: inout },
    ];
  }
  removeDeliveryOption(index) {
    let opts = editAccountStore.edited.storageContract.options;
    let finalopts = [];
    for (let i = 0; i < opts.length; i++) {
      if (i !== index) finalopts = [...finalopts, opts[i]];
    }
    editAccountStore.edited.storageContract.options = finalopts;
  }
  editDeliveryOption(val, label, index) {
    editAccountStore.edited.storageContract.options[index][label] = val;
  }

  // FETCH
  getAccount(clientid, override) {
    let fetch = true;
    if (this.loaded & (clientid === this.clientid)) fetch = false;
    if (override) fetch = true;
    if (this.loading) fetch = false;
    if (fetch) {
      this.loading = true;
      this.clientid = clientid;
      return instance
        .get(`/ac/account/accountbyclient/${clientid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          if (accounts.account) {
            this.original = { ...accounts.account };
            this.edited = { ...accounts.account };
          } else if (!accounts.client) {
            console.log("Client Not Found");
          } else {
            console.log("Client Not Found");
          }
          this.loading = false;
          this.loaded = true;

          console.log("Account Fetched");
        })
        .catch((err) => {
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  // POST
  updateAccountMain(labels, loadlabel) {
    editAccountStore.load[loadlabel] = true;
    let load = editAccountStore.load;
    let edited = editAccountStore.edited;
    let original = editAccountStore.original;
    let data = {};

    if (Array.isArray(labels)) {
      for (let i = 0; i < labels.length; i++) {
        data = { ...data, [labels[i]]: edited[labels[i]] };
      }
    } else {
      editAccountStore.load[labels] = true;
      data = { [labels]: edited[labels] };
    }

    return instance
      .put(`/ac/account/edit/${edited._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((accounts) => {
        if (Array.isArray(labels)) {
          for (let i = 0; i < labels.length; i++) {
            original[labels[i]] = edited[labels[i]];
          }
        } else {
          original[labels] = edited[labels];
          editAccountStore.load[labels] = false;
        }

        load[loadlabel] = false;
        message.success("Account Updated");
      })
      .catch((err) => {
        console.log(err);

        Notification["error"]({
          title: `Error Updating.`,
        });
      });
  }
  updateSTO(labels, loadlabel) {
    editAccountStore.load[loadlabel] = true;
    let load = editAccountStore.load;
    let edited = editAccountStore.edited;
    let esto = edited.storageContract;
    let sto = editAccountStore.original.storageContract;
    let data = {};

    if (Array.isArray(labels)) {
      for (let i = 0; i < labels.length; i++) {
        data = { ...data, [labels[i]]: esto[labels[i]] };
      }
    } else {
      editAccountStore.load[labels] = true;
      data = { [labels]: esto[labels] };
    }

    return instance
      .put(`/ac/account/editsto/${edited._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((accounts) => {
        if (Array.isArray(labels)) {
          for (let i = 0; i < labels.length; i++) {
            sto[labels[i]] = esto[labels[i]];
          }
        } else {
          sto[labels] = esto[labels];
          editAccountStore.load[labels] = false;
        }

        load[loadlabel] = false;
        message.success("Storage Contract Updated");
      })
      .catch((err) => {
        console.log(err);
        load[loadlabel] = false;

        Notification["error"]({
          title: `Error Updating.`,
        });
      });
  }

  // ----- DATE ----
}

const editAccountStore = new EditAccountStore();
export default editAccountStore;
