import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

class TextInput extends Component {
  render() {
    return (
      <TextField
        autoFocus
        variant="outlined"
        margin="normal"
        fullWidth
        label={this.props.label}
        value={this.props.val}
        required={this.props.required}
        error={this.props.err}
        onChange={(e) =>
          this.props.changeVal(this.props.content, e.target.value)
        }
        size="small"
        type={this.props.type}
      />
    );
  }
}

export default withRouter(observer(TextInput));
