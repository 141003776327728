import { makeObservable, observable } from "mobx";
import axios from "axios";
import moment from "moment";

const instance = axios.create({
  baseURL: "https://api.thelightbug.com/api/",
});

class MgtMapStore {
  constructor() {
    this.user = [];
    this.devices = [];
    this.center = [29.175, 48.069];
    this.zoom = 10;
    this.markersize = 25;
    this.loading = true;
    this.trackID = 0;

    makeObservable(this, {
      user: observable,
      devices: observable,
      center: observable,
      markersize: observable,
      trackID: observable,
      zoom: observable,
      loading: observable,
    });
  }

  resolveAfter2Seconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 0);
    });
  }

  updateTrackCar(ID) {
    instance
      .get(`/users/${this.user.userId}/getDeviceSummary/`, {
        headers: { Authorization: this.user.id },
      })
      .then((res) => res.data)
      .then((devices) => {
        let newDevices = [];

        let trackedDevice = devices.filter((device) => device.id === ID);
        let drivername = "";
        drivername = trackedDevice.tags[0].substring(
          trackedDevice.tags[0].search(":") + 1,
          20
        );
        let carStat = "On";
        if (trackedDevice.currentMode === 1) carStat = "Off";
        if (trackedDevice.currentMode > 1) carStat = "On";
        newDevices.push({
          id: trackedDevice.id,
          name: trackedDevice.name,
          lat: trackedDevice.latestPoints[0].location.lat,
          lng: trackedDevice.latestPoints[0].location.lng,
          address: trackedDevice.latestPoints[0].address,
          speed: trackedDevice.latestPoints[0].speed,
          date: moment(trackedDevice.latestPoints[0].created).fromNow(),
          driver: drivername.charAt(0).toUpperCase() + drivername.slice(1),
          color: trackedDevice.color,
          status: trackedDevice.currentMode,
          carStat: carStat,
        });
        this.devices = newDevices;
        console.log("Devices Fetched");
      })
      .then((device) => (this.loading = false))
      .catch((err) => {
        alert("Not Able To Get Trucks - Update Track Car");
      });
  }

  trackCar(ID, lat, lng) {
    this.trackID = ID;
  }

  changeCenter = async (lat, lon, ID) => {
    this.loading = true;
    this.center = [lat, lon];
    this.zoom = 40;
    this.stopLoading();
  };

  resetMap() {
    this.loading = true;
    this.center = [29.175, 48.069];
    this.zoom = 10;
    this.stopLoading();
  }

  stopLoading = async () => {
    await this.resolveAfter2Seconds();
    this.loading = false;
  };

  fetchUser() {
    this.loading = true;
    let credentials = {
      username: "alfouzan@easymovekw.com",
      password: "pass123",
    };
    instance
      .post("/users/login", credentials)
      .then((res) => res.data)
      .then((user) => {
        this.user = user;
        console.log("API Logged In");
      })
      .then((user) => {
        this.fetchDevices();
      })
      .catch((err) => {
        this.loading = false;

        alert("Not Able To Sign In To API");
      });
  }

  fetchDevices() {
    instance
      .get(`/users/${this.user.userId}/getDeviceSummary `, {
        headers: { Authorization: this.user.id },
      })
      .then((res) => res.data)
      .then((devices) => {
        console.log(devices);
        let newDevices = [];

        for (let i = 0; i < devices.length; i++) {
          let drivername = "";
          if (devices[i].tags !== null) {
            if (devices[i].tags.length > 0) {
              drivername = devices[i].tags[0].substring(
                devices[i].tags[0].search(":") + 1,
                20
              );
            }
          }

          let carStat = "On";
          if (devices[i].currentMode === 1) carStat = "Off";
          if (devices[i].currentMode > 1) carStat = "On";

          let newdevice = {
            id: 0,
            name: "Missing",
            driver: drivername.charAt(0).toUpperCase() + drivername.slice(1),
            color: "",
            status: "",
            lastConnection: "No Data",
            carStat: carStat,
            lat: 0,
            lng: 0,
            address: "Missing Location",
            speed: "",
            date: moment(),
          };
          if (devices[i]) {
            if (devices[i].id) newdevice.id = devices[i].id;
            if (devices[i].name) newdevice.name = devices[i].name;
            if (devices[i].color) newdevice.color = devices[i].color;
            if (devices[i].currentMode)
              newdevice.status = devices[i].currentMode;
            if (devices[i].lastConnection)
              newdevice.lastConnection = devices[i].lastConnection;
            if (devices[i].latestPoints) {
              if (devices[i].latestPoints.length > 0) {
                newdevice.lat = devices[i].latestPoints[0].location.lat;
                newdevice.lng = devices[i].latestPoints[0].location.lng;
                newdevice.address = devices[i].latestPoints[0].address;
                newdevice.speed = devices[i].latestPoints[0].speed;
                newdevice.date = moment(
                  devices[i].latestPoints[0].created
                ).fromNow();
              }
            }
          }
          newDevices = [...newDevices, { ...newdevice }];
        }
        this.devices = newDevices;

        console.log("Devices Fetched");
      })
      .then((device) => (this.loading = false))
      .catch((err) => {
        this.loading = false;
        console.error(err);
        alert("Not Able To Get Trucks");
      });
  }
}

const mgtMapStore = new MgtMapStore();

export default mgtMapStore;
