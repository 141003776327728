function QualityTextCalc(good, label, type, min) {
  if (good) return null;
  if (type === "empty") return `${label} must be filled.`;
  let minval = 0;
  if (min) minval = min;

  if (type === "zero") return `${label} must be greater than ${minval}.`;

  return null;
}

export { QualityTextCalc };
