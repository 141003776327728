import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import hrStore from "../../../../Stores/HR/HRStore";
import { DatePicker, Tabs, Tooltip } from "antd";

import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { FlexboxGrid, Icon, IconButton, Col, Notification } from "rsuite";
import NewHoliday from "./NewHoliday";
import HolidayCalendar from "./AllHolidayCalendar";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import AddHolidayModal from "./AddHolidayModal";
import HolidayAllowance from "./HolidayAllowance";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import LeaveList from "./LeaveList";
import Col2 from "../../../../Components/Columns/Col2";
import LoadBack from "../../../../Components/Loaders/LoadBack";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const instance = axios.create({ baseURL: baseURL });

class HolidayMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apprej: "",
      load: {},
      show: false,
      single: false,
      ind: -1,
      tab: "Calendar",
      type: "New",
      loading: false,
      apprejhol: {
        type: "Planned Leave",
        staff: "",
        date: moment(),
        dateto: moment().add(1, "day"),
        remark: "",
        _id: "",
      },
      holiday: {
        type: "Planned Leave",
        staff: "",
        date: moment(),
        dateto: moment().add(1, "day"),
        remark: "",
      },
    };

    this.showHideModal = this.showHideModal.bind(this);
    this.showAppRej = this.showAppRej.bind(this);
    this.updateHoliday = this.updateHoliday.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
  }

  showAppRej(status, data) {
    this.setState({
      ind: data.tableData.id,
      apprej: status,
      apprejhol: data,
    });
  }
  componentDidMount() {
    hrStore.getHolidays();
  }

  changeVal(val, type) {
    this.setState({ [type]: val });
  }
  editVal(val, type) {
    let holiday = { ...this.state.holiday };
    if (type === "date") {
      holiday.date = val[0];
      holiday.dateto = val[1];
    } else {
      holiday[type] = val;
    }
    this.setState({ holiday: holiday });
  }

  showHideModal(val, type) {
    if (val) {
      let holiday = {
        type: type,
        staff: "",
        date: moment(),
        dateto: moment().add(0, "day"),
        remark: "",
      };
      this.setState({ show: val, holiday: holiday });
    } else {
      this.setState({
        apprej: "",
        show: false,
        type: "New",
        loading: false,
        single: false,
        holiday: {
          type: "Planned Leave",
          staff: "",
          date: moment(),
          dateto: moment().add(1, "day"),
          remark: "",
        },
        apprejhol: {
          type: "Planned Leave",
          staff: "",
          date: moment(),
          dateto: moment().add(1, "day"),
          remark: "",
          _id: "",
        },
      });
    }
  }

  updateHoliday = async (id) => {
    this.setState({ loading: true });
    let url = "";
    if (this.state.apprej === "Approve") {
      url = `/hr/holiday/approve/${id}`;
    } else if (this.state.apprej === "Reject") {
      url = `/hr/holiday/reject/${id}`;
    }

    return instance
      .put(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHolidays(true);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Holiday Updated (${this.state.apprej})`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  postNew = async (days) => {
    let newservice = {
      type: this.state.holiday.type,
      staff: this.state.holiday.staff,
      date: this.state.holiday.date,
      dateto: this.state.holiday.dateto,
      remark: this.state.holiday.remark,
      days: days,
    };
    this.setState({ loading: true });
    let url = "/hr/holiday/new";

    return instance
      .post(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHolidays(true);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Added New Holiday: ${newservice.type}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    let apprej = this.state.apprej;

    let showrej = hrStore.showrejected;
    let fd = hrStore.filtdates;

    return (
      <LoadBack loading={hrStore.loading}>
        <UnifiedModal
          title={`${apprej} Holiday`}
          show={apprej !== ""}
          onHide={this.showHideModal}
          footer={
            <ModalFooter
              icon={["close", apprej === "Approve" ? "check" : "trash"]}
              color={["default", apprej === "Approve" ? "green" : "red"]}
              app={["ghost", "default"]}
              text={["Cancel", apprej]}
              oC1={() => this.showHideModal()}
              oC2={() => this.updateHoliday(this.state.apprejhol._id)}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          Are you sure you want to {this.state.apprej} this holiday?
        </UnifiedModal>
        <NewHoliday
          hideModal={this.showHideModal}
          loading={this.state.loading}
          type={this.state.type}
          show={this.state.show}
          holiday={this.state.holiday}
          postNew={this.postNew}
          editVal={this.editVal}
        />
        <FlexboxGrid style={{ padding: "1%" }} justify="space-between">
          <Col md={20} sm={18} xs={16}>
            <RangePicker
              style={{ width: "100%" }}
              format={"MMM-YY"}
              picker={"month"}
              value={[moment(fd.startsalary), moment(fd.endsalary)]}
              allowClear={false}
              showMinute={false}
              onChange={(date) => {
                hrStore.changeFiltDate(
                  moment(date[0]).startOf("month"),
                  moment(date[1]).startOf("month"),
                  "Salary"
                );
              }}
            />
          </Col>
          <Col md={4} sm={6} xs={8}>
            <Tooltip title="Refresh">
              <IconButton
                circle
                color="green"
                icon={<Icon icon="refresh" />}
                onClick={() => hrStore.getHolidays(true)}
              />
            </Tooltip>
          </Col>
        </FlexboxGrid>
        {this.state.tab !== "Calendar" && (
          <>
            <hr />

            <FlexboxGrid justify="center">
              <Col2>
                <IconButton
                  icon={<Icon icon={showrej ? "eye" : "eye-slash"} />}
                  onClick={() => hrStore.changeShowRej()}
                  block
                  color={showrej ? "green" : "red"}
                >
                  {showrej ? "Showing" : "Hiding"} Rejected
                </IconButton>
              </Col2>
            </FlexboxGrid>
          </>
        )}
        <hr />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"Calendar"}
          tabPosition={"top"}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={"Calendar"}
            key={"Calendar"}
            disabled={this.state.locked}
          >
            <Div14>Leave Calendar</Div14>
            <HolidayCalendar />
          </TabPane>
          <TabPane
            tab={"Leave List"}
            key={"Leave List"}
            disabled={this.state.locked}
          >
            <Div14>Leave List</Div14>
            <LeaveList
              showAppRej={this.showAppRej}
              showHideModal={this.showHideModal}
              data={hrStore.allhols}
              type="Planned Leave"
            />
          </TabPane>
          <TabPane
            tab={"Sick Leaves"}
            key={"Sick Leaves"}
            disabled={this.state.locked}
          >
            <Div14>Sick Leaves</Div14>
            <LeaveList
              showAppRej={this.showAppRej}
              showHideModal={this.showHideModal}
              data={hrStore.sickhols}
              type="Sick Leave"
            />
          </TabPane>
          {userStore.user.mgt && (
            <TabPane
              tab={"Monthly Allowance"}
              key={"Monthly Allowance"}
              disabled={this.state.locked}
            >
              <Div14>Holiday Allowances</Div14>
              <AddHolidayModal />
              <HolidayAllowance />
            </TabPane>
          )}
        </Tabs>
      </LoadBack>
    );
  }
}

export default observer(HolidayMain);
