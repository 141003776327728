function StatusColorCalc(status) {
  let color = "white";
  if (status === "Lead") color = "#cfcfcf";
  if (status === "Proposal") color = "#fffcc7";
  if (status === "Booked") color = "#a3ffae";
  if (status === "Converted") color = "#7ca681";
  if (status === "In Transit") color = "#7ca681";
  if (status === "Completed") color = "#7ca681";
  if (status === "Cancelled") color = "#c79393";
  return color;
}

export { StatusColorCalc };
