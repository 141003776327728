import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";

import moment from "moment";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import frontendURL from "../../../../Static/baseURL/frontendURL";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 8,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol0blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol1blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableCol3blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol4blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol6blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol1white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol2white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  tableCol3white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol4white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol5white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol6white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol7white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 8,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "75%",
  },
});

class ToAddressItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let toadd = this.props.toadd;
    let showdimen = this.props.showdimen;
    let toadds = this.props.sto.toaddress;
    let startindex = 0;
    let node = this.props.index;
    for (let i = 0; i < node; i++) {
      startindex += toadds[i].acitemmove.length;
    }

    let totalqty = 0;
    let totalcbm = 0;
    if (toadd.acitemmove) {
      for (let i = 0; i < toadd.acitemmove.length; i++) {
        totalqty += Parsed(toadd.acitemmove[i].actualquantity);
        totalcbm += Parsed(toadd.acitemmove[i].cbm);
      }
    }
    console.log(toadd);

    return (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>Location #{toadd.order}</Text>
          <Text style={styles.tableCol7white}>{toadd.name}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>Phone</Text>
          <Text style={styles.tableCol6white}>{toadd.phone}</Text>
          <Text style={styles.tableCol6blue}>Type</Text>
          <Text style={styles.tableCol6white}>{toadd.type}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>City</Text>
          <Text style={styles.tableCol6white}>{toadd.city}</Text>
          <Text style={styles.tableCol6blue}>Floor</Text>
          <Text style={styles.tableCol6white}>{toadd.floor}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>Address</Text>
          <Text style={styles.tableCol7white}>{toadd.address}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>Total QTY</Text>
          <Text style={styles.tableCol6white}>{PRKD(totalqty)}</Text>
          <Text style={styles.tableCol6blue}>Total CBM</Text>
          <Text style={styles.tableCol6white}>{PRKD(totalcbm)}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol0blue}>
            Location {toadd.order} Items List:
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol1blue}>#</Text>
          <Text style={styles.tableCol5blue}>Item ID</Text>
          <Text style={styles.tableCol2blue}>Item</Text>
          <Text style={styles.tableCol3blue}>Serial No.</Text>
          <Text style={styles.tableCol4blue}>Quantity</Text>
          {showdimen && <Text style={styles.tableCol5blue}>Dimensions</Text>}
          <Text style={styles.tableCol5blue}>CBM</Text>
        </View>

        {toadd.acitemmove.map((itemmove, index) => (
          <View style={{ flexDirection: "row" }} key={index}>
            <Text style={styles.tableCol1white}>{startindex + index + 1}</Text>
            <Text style={styles.tableCol5white}>
              {itemmove.acitem.acItemNumId}
            </Text>
            <Text style={styles.tableCol2white}>{itemmove.acitem.name}</Text>
            <Text style={styles.tableCol3white}>
              {itemmove.acitem.serialnumber}
            </Text>
            <Text style={styles.tableCol4white}>{itemmove.actualquantity}</Text>
            {showdimen && (
              <Text style={styles.tableCol5white}>{`${PRKD(
                itemmove.itemlength
              )} x ${PRKD(itemmove.itemwidth)} x ${PRKD(
                itemmove.itemheight
              )} cm`}</Text>
            )}
            <Text style={styles.tableCol5white}>{PRKD(itemmove.cbm)}</Text>
          </View>
        ))}
        <Text> </Text>
      </View>
    );
  }
}

class HeaderSection extends Component {
  render() {
    let sto = this.props.sto;
    let client = this.props.client;
    let showdimen = this.props.showdimen;

    let type;
    if (sto.jobtype === "Storage In" || sto.jobtype === "Delivery In")
      type = "In";
    if (sto.jobtype === "Storage Out" || sto.jobtype === "Delivery Out")
      type = "Out";

    let acOrderId = sto.acOrderNumId;
    let clientname = client.name;

    let proposaltitle = `${type} - Items List for ${clientname} - Order #${acOrderId}`;

    let itemslist = sto.toaddress.map((to, index) => (
      <ToAddressItems
        toadd={to}
        key={index}
        sto={sto}
        index={index}
        showdimen={showdimen}
      />
    ));

    return (
      <View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Job Date: {moment(sto.jobdate).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>
            Request Date: {moment(sto.createdAt).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>info@easymovekw.com</Text>
          <Text style={styles.tableRight}>Order ID: {acOrderId}</Text>
        </View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text style={styles.header}>{proposaltitle}</Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>Job Time</Text>
          <Text style={styles.tableCol7white}>
            {moment(sto.jobdate).format("DD-MMM-YY HH:mm")}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol6blue}>Order Status</Text>
          <Text style={styles.tableCol7white}>{sto.status}</Text>
        </View>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Below is the current details as of{" "}
          {moment().format("DD-MMM-YY HH:mm")} for Order ID#{acOrderId}{" "}
          scheduled on {moment(sto.jobdate).format("DD-MMM-YYYY")}. To track the
          order you can use this link:
        </Text>
        <Link
          src={`${frontendURL}acorder/${sto._id}`}
          style={{
            fontSize: 10,
            textAlign: "center",
            fontFamily: "Times-Roman",
            fontStyle: "italic",
          }}
        >
          Order #{acOrderId} Tracking Page
        </Link>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        {itemslist}
      </View>
    );
  }
}

export default observer(HeaderSection);
