import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import StorageChargesSummary from "./WHSummarySupport/StorageChargesSummary";
import { Tabs } from "antd";
import moment from "moment";
import warehouseSummaryStore from "../../../Stores/Financial/WarehouseSummaryStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col4 from "../../../Components/Columns/Col4";
import Col2 from "../../../Components/Columns/Col2";
import InvoiceChargesSummary from "./WHSummarySupport/InvoiceChargesSummary";
import PendingInvoices from "./WHSummarySupport/PendingInvoices";
import Div14 from "../../../Components/Dividers/Div14";

const { TabPane } = Tabs;
class WarehouseSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "1" };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Div14>Warehouse Summary</Div14>

        <FlexboxGrid style={{ textAlign: "center" }}>
          <Col4>
            <FlexboxGrid justify="space-around">
              <IconButton
                size="xs"
                loading={
                  warehouseSummaryStore.loading.clients ||
                  warehouseSummaryStore.loading.invoices ||
                  warehouseSummaryStore.loading.pendinginvoices
                }
                icon={<Icon icon="chevron-left" />}
                color="orange"
                onClick={() =>
                  warehouseSummaryStore.changeMonth(
                    moment(warehouseSummaryStore.month)
                      .subtract(1, "month")
                      .format("MMM-YYYY"),
                    this.state.tab
                  )
                }
              >
                {moment(warehouseSummaryStore.month)
                  .subtract(1, "month")
                  .format("MMM-YYYY")}
              </IconButton>

              {moment(warehouseSummaryStore.month)
                .add(1, "month")
                .isBefore(moment()) && (
                <IconButton
                  loading={
                    warehouseSummaryStore.loading.clients ||
                    warehouseSummaryStore.loading.invoices ||
                    warehouseSummaryStore.loading.pendinginvoices
                  }
                  icon={<Icon icon="chevron-right" />}
                  size="xs"
                  placement="right"
                  color="green"
                  onClick={() =>
                    warehouseSummaryStore.changeMonth(
                      moment(warehouseSummaryStore.month)
                        .add(1, "month")
                        .format("MMM-YYYY"),
                      this.state.tab
                    )
                  }
                >
                  {moment(warehouseSummaryStore.month)
                    .add(1, "month")
                    .format("MMM-YYYY")}
                </IconButton>
              )}
            </FlexboxGrid>
            <br />
          </Col4>
          <Col2>
            <h4>
              {moment(warehouseSummaryStore.month).format("MMM-YYYY")} Summary
            </h4>
            <br />
          </Col2>
          <Col4 />
        </FlexboxGrid>

        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={"top"}
          centered
          onChange={(e) => this.setState({ tab: e })}
        >
          <TabPane tab={`Monthly Charges`} key="1">
            <StorageChargesSummary />
          </TabPane>
          <TabPane tab={`Monthly Invoices`} key="2">
            <InvoiceChargesSummary />
          </TabPane>
          <TabPane tab={`Pending Invoices`} key="3">
            <PendingInvoices />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(WarehouseSummary));
