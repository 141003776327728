import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import moment from "moment";
import { Parsed } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class SingleStaffHRStore {
  constructor() {
    this.singlestaff = {};
    this.singleedit = {};
    this.staffsalary = [];
    this.payments = [];
    this.staffless = [];
    this.holidays = [];
    this.showrejectedholidays = false;

    this.loading = {
      single: false,
      salary: false,
      payments: false,
      holidays: false,
    };
    this.loaded = {
      single: false,
      salary: false,
      payments: false,
      holidays: false,
    };

    makeObservable(this, {
      singlestaff: observable,
      singleedit: observable,
      holidays: observable,
      loading: observable,
      loaded: observable,

      staffless: observable,
      payments: observable,
      showrejectedholidays: observable,
      paymentGraph: computed,
      allhols: computed,
      holallowances: computed,
      plannedleave: computed,
      unplannedleave: computed,
      sickleave: computed,
    });
  }

  // FETCH
  getSingleHRStaff(id, override) {
    if (!override) {
      if (this.loading.single || this.loaded.single) return null;
    }
    this.loading.single = true;

    return instance
      .get(`/hr/staff/singlestaff/${id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staff) => {
        this.singlestaff = staff;
        this.singleedit = staff;
        this.loading.single = false;

        this.loaded = {
          single: true,
          salary: false,
          payments: false,
          holidays: false,
        };

        console.log("Single Staff Fetched");
      })
      .catch((err) => {
        this.loading.single = false;
        this.loaded.single = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getStaffSalary(override) {
    if (!override) {
      if (this.loading.salary || this.loaded.salary) return null;
    }
    this.loading.salary = true;

    return instance
      .get(`/hr/salary/singlestaff/${this.singlestaff._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staffsalary) => {
        this.staffsalary = staffsalary;
        this.loading.salary = false;
        this.loaded.salary = true;
        console.log("Single Staff Salary Fetched");
      })
      .catch((err) => {
        this.loading.salary = false;
        this.loaded.salary = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getPayments(override) {
    if (!override) {
      if (this.loading.payments || this.loaded.payments) return null;
    }
    this.loading.payments = true;

    return instance
      .get(`/hr/salary/singlestaffpayments/${this.singlestaff._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((payments) => {
        this.payments = payments;
        this.loading.payments = false;
        this.loaded.payments = true;
        console.log("Single Staff Payments Fetched");
      })
      .catch((err) => {
        this.loading.payments = false;
        this.loaded.payments = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getHolidays(override) {
    if (!override) {
      if (this.loading.holidays || this.loaded.holidays) return null;
    }
    this.loading.holidays = true;

    return instance
      .get(`/hr/holiday/bystaffid/${this.singlestaff._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((holidays) => {
        this.holidays = holidays;
        this.loading.holidays = false;
        this.loaded.holidays = true;
        console.log("Single Staff Holidays Fetched");
      })
      .catch((err) => {
        this.loading.holidays = false;
        this.loaded.holidays = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }

  // FETCH
  getStaffless() {
    return instance
      .get(`/auth/basic/staffless`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staff) => {
        this.staffless = [];
        for (let i = 0; i < staff.length; i++) {
          if (staff[i].staff) {
            if (this.singlestaff.user) {
              if (staff[i]._id === this.singlestaff.user._id) {
                this.staffless = [...this.staffless, staff[i]];
              }
            }
          } else {
            this.staffless = [...this.staffless, staff[i]];
          }
        }

        console.log("Staffless Users Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  get paymentGraph() {
    let salary = [];
    let bonus = [];
    let add = [];

    let months = [];

    let startmonth = moment().startOf("month");
    let endmonth = moment().add(1, "month").startOf("month");

    if (this.payments.length > 0) {
      for (let i = 0; i < this.payments.length; i++) {
        if (moment(this.payments[i].salarymonth) < moment(startmonth)) {
          startmonth = moment(this.payments[i].salarymonth).startOf("month");
        }
      }
      let diff = moment(endmonth).diff(startmonth, "month");
      let givenmonth = startmonth;
      let ssalary = 0;
      let sbonus = 0;
      let sadd = 0;
      for (let j = 0; j < diff; j++) {
        ssalary = 0;
        sbonus = 0;
        sadd = 0;
        for (let i = 0; i < this.payments.length; i++) {
          if (this.payments[i].paymentNumId === 3505) {
          }
          if (
            moment(this.payments[i].salarymonth).isSame(
              moment(givenmonth),
              "month"
            )
          ) {
            if (this.payments[i].specific === "Salary") {
              ssalary += Parsed(this.payments[i].amount);
            } else if (this.payments[i].specific === "Bonus") {
              sbonus += Parsed(this.payments[i].amount);
            } else if (this.payments[i].specific === "Additional") {
              sadd += Parsed(this.payments[i].amount);
            }
          }
        }
        salary = [...salary, ssalary];
        bonus = [...bonus, sbonus];
        add = [...add, sadd];
        months = [...months, moment(givenmonth).format("MMM-YYYY")];
        givenmonth = moment(givenmonth).add(1, "months");
      }
    }
    return { salary: salary, bonus: bonus, add: add, months: months };
  }

  get allhols() {
    let holidays = this.holidays.filter(
      (hol) => hol.type !== "Monthly Allowance"
    );
    if (!this.showrejectedholidays) {
      holidays = holidays.filter((hol) => hol.status !== "Reject");
    }
    return holidays;
  }
  get holallowances() {
    return this.holidays.filter((hol) => hol.type === "Monthly Allowance");
  }
  get sickleave() {
    let holidays = this.holidays.filter(
      (hol) => (hol.type === "Sick Leave") & (hol.status !== "Reject")
    );
    let days = 0;
    for (let i = 0; i < holidays.length; i++) {
      if (Parsed(holidays[i].days) > 0) days += Parsed(holidays[i].days);
    }
    return { holidays: holidays, days: days };
  }

  get plannedleave() {
    let holidays = this.holidays.filter(
      (hol) => (hol.type === "Planned Leave") & (hol.status !== "Reject")
    );
    let days = 0;
    for (let i = 0; i < holidays.length; i++) {
      if (Parsed(holidays[i].days) > 0) days += Parsed(holidays[i].days);
    }

    return { holidays: holidays, days: days };
  }
  get unplannedleave() {
    let hols = this.holidays.filter(
      (hol) => (hol.type === "Unplanned Leave") & (hol.status !== "Reject")
    );
    let days = 0;
    for (let i = 0; i < hols.length; i++) {
      if (Parsed(hols[i].days) > 0) days += Parsed(hols[i].days);
    }
    return { holidays: hols, days: days };
  }

  // FUNCTIONS:
  // ----- DATE ----

  changeFiltDate(start, end, type) {
    if (type === "Salary") {
      this.filtdates.startsalary = start;
      this.filtdates.endsalary = end;
    }
  }

  editSingle(val, label) {
    if (label === "hideatt") {
      if (val === "Show") {
        singleStaffHRStore.singleedit.hideatt = false;
      } else {
        singleStaffHRStore.singleedit.hideatt = true;
      }
    } else if (label === "active") {
      if (val === "Yes") {
        singleStaffHRStore.singleedit.active = true;
      } else {
        singleStaffHRStore.singleedit.active = false;
      }
    } else {
      singleStaffHRStore.singleedit[label] = val;
    }
  }
  editMainSingle(val, label) {
    singleStaffHRStore.singlestaff[label] = val;
  }

  startLoading() {
    this.loading = true;
  }

  changeRejectedHolidays() {
    this.showrejectedholidays = !this.showrejectedholidays;
  }
}

const singleStaffHRStore = new SingleStaffHRStore();
export default singleStaffHRStore;
