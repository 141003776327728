import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Loader } from "rsuite";

import DataGrid, {
  Column,
  GroupItem,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Item } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { Toolbar, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { withRouter } from "react-router";
import acStorStatStore from "../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";

class MonthlyDeliveryReport extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };

    this.onExporting = this.onExporting.bind(this);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Order_List_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  GroupData(cellData) {
    return "Hello There";
  }
  StorDate(cellData) {
    let data = cellData.data;
    if (data.status === "Completed") {
      return moment(data.jobdate).format("DD-MMM-YY");
    } else {
      return moment(data.jobdate).format("DD-MMM-YY");
    }
  }
  StatusCell(cellData) {
    let data = cellData.data.status;
    if (cellData.data.loading) {
      return (
        <div
          style={{
            fontWeight: "bold",
            color: "orange",
          }}
        >
          <Loader size="xs" /> Updating
        </div>
      );
    }
    return (
      <div
        style={{
          fontWeight: "bold",
          color:
            data === "Requested"
              ? "#f59f00"
              : data === "Cancelled"
              ? "red"
              : data === "Delivered"
              ? "black"
              : "green",
        }}
      >
        {data}
      </div>
    );
  }
  render() {
    let data = acStorStatStore.stats.dels;

    let content = (
      <DataGrid
        dataSource={data}
        keyExpr="acItemMoveNumId"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        rowAlternationEnabled
        onExporting={this.onExporting}
        style={{ fontSize: 10 }}
        hoverStateEnabled={true}
      >
        <FilterRow visible={true} applyFilter />
        <GroupPanel visible={true} />
        <SearchPanel visible={true} />
        <Paging defaultPageSize={25} defaultPageIndex={0} />
        <Pager
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={[25, 50, 100]}
          showNavigationButtons={true}
        />
        <Scrolling mode="standard" showScrollbar="always" />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={true} />

        <Column
          dataField="tabletitle"
          caption="Job"
          alignment="center"
          width={80}
          groupIndex={1}
          autoExpandGroup={false}
          cellRender={this.StatusCell}
        />
        <Column
          dataField="client"
          caption="Company"
          alignment="center"
          minWidth={80}
        />
        <Column caption="Order Details" alignment={"center"}>
          <Column
            dataField="acItemMoveNumId"
            caption="Order Details"
            alignment="center"
            minWidth={80}
          />
        </Column>
        <Column caption="Item Details" alignment={"center"}>
          <Column
            dataField="name"
            caption="Name"
            alignment="center"
            minWidth={80}
          />

          <Column
            dataField="serialnumber"
            caption="Serial No."
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="productid"
            caption="Product ID"
            alignment="center"
            minWidth={80}
          />

          <Column
            dataField="actualquantity"
            caption="QTY"
            alignment="center"
            minWidth={80}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="cbm"
            caption="CBM"
            alignment="center"
            minWidth={80}
            format={{ type: "fixedPoint", precision: 2 }}
          />
        </Column>

        <Summary>
          <GroupItem
            column="acItemMoveNumId"
            summaryType="count"
            displayFormat="{0} items"
          />
          <GroupItem
            column="cbm"
            summaryType="sum"
            displayFormat="{0} CBM"
            valueFormat={"#,##0.000"}
          />
          <TotalItem
            column="cbm"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} CBM"}
          />
        </Summary>

        <Export enabled={true} />
        <Toolbar>
          <Item name="columnChooserButton" location="after" />
          <Item name="exportButton" />
        </Toolbar>
      </DataGrid>
    );

    return <div>{content}</div>;
  }
}

export default withRouter(observer(MonthlyDeliveryReport));
