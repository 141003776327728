import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

import moment from "moment";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol70white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
});
class ACHeaderSection extends Component {
  render() {
    let rct = this.props.rct;

    let paymentId = rct.paymentNumId;
    let clientname = rct.client.name;
    let proposaltitle = `Confirmation of Payment (# ${paymentId})`;

    let invrct = `Receipt-${moment(rct.date).format("YYMMDDHH")}`;

    return (
      <View>
        <Text style={styles.header}>{proposaltitle}</Text>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Date: {moment(rct.date).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>Payment Number: {paymentId}</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Phone: +965-22060969</Text>
          <Text style={styles.tableRight}>
            Account ID: {rct.client.clientNumId}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Email: info@easymovekw.com</Text>
          <Text style={styles.tableRight}> </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}></View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
            lineHeight: 1.5,
          }}
        >
          To: {clientname},
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Below is the confirmation of payment by {rct.client.name} for
          professional services on {rct.accounttype} confirmed on{" "}
          {moment(rct.date).format("DD-MMM-YYYY")}.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Client</Text>
          <Text style={styles.tableCol70white}>{clientname}.</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Phone</Text>
          <Text style={styles.tableCol70white}>
            {rct.client.phone} / {rct.client.phone2}.
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Email</Text>
          <Text style={styles.tableCol70white}>{rct.client.email}.</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Amount Received</Text>
          <Text style={styles.tableCol70white}>{PRKD(rct.amount)} KD</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Date</Text>
          <Text style={styles.tableCol70white}>
            {moment(rct.date).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Method</Text>
          <Text style={styles.tableCol70white}>{rct.method}</Text>
        </View>
        {rct.method !== "Cheque" && (
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Paid to Account</Text>
            <Text style={styles.tableCol70white}>{rct.account}</Text>
          </View>
        )}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Payment Reference</Text>
          <Text style={styles.tableCol70white}>{rct.paymentref}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>
            Referenced to Account Number {rct.client.clientNumId}
          </Text>
          <Text style={styles.tableCol70white}>{invrct}.</Text>
        </View>
      </View>
    );
  }
}

export default observer(ACHeaderSection);
