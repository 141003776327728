import React, { Component } from "react";
import { InputGroup, Input } from "rsuite";

class TextInputSTD extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;
    let label = this.props.label;
    let val = this.props.val;
    let size = "lg";
    if (this.props.size) size = this.props.size;
    let index = this.props.index;
    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <Input
          size={size}
          value={val}
          disabled={disabled}
          onChange={(e) => this.props.editVal(e, label, index)}
        />
      </InputGroup>
    );
  }
}

export default TextInputSTD;
