import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../../../../Components/Dividers/Div14";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import ReactApexChart from "react-apexcharts";

import staffStorageStatStore from "../../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import { PRKD } from "../../../../../../../../../Functions/Pricing/PRKDCalc";

class ClientBreakdownBars extends Component {
  constructor(props) {
    super(props);
    this.state = { type: "totaljobs", title: "Total Jobs" };
  }

  render() {
    let opts = [
      { label: "cbmin", title: "In CBM" },
      { label: "cbmout", title: "Out CBM" },
      { label: "cbmtotal", title: "Total CBM" },
      { label: "qtyin", title: "In Quantity" },
      { label: "qtyout", title: "Out Quantity" },
      { label: "qtytotal", title: "Total Quantity" },
      { label: "stoin", title: "Storage In Jobs" },
      { label: "stoout", title: "Storage Out Jobs" },
      { label: "sto", title: "Storage Jobs" },
      { label: "delin", title: "Delivery In Jobs" },
      { label: "delout", title: "Delivery Out Jobs" },
      { label: "del", title: "Delivery Jobs" },

      { label: "totalin", title: "In Jobs" },
      { label: "totalout", title: "Out Jobs" },
      { label: "totaljobs", title: "Total Jobs" },
    ];

    let data = [...staffStorageStatStore.clientbreakdown.clients];

    let newdata = [];
    for (let i = 0; i < data.length; i++) {
      newdata = [
        ...newdata,
        { client: data[i].name, value: data[i][this.state.type] },
      ];
    }

    let newerdata = newdata.sort((a, b) => (a.value > b.value ? -1 : 1));

    let xaxis = [];
    let yaxis = [];
    for (let i = 0; i < newerdata.length; i++) {
      if (newerdata[i].value > 0) {
        xaxis = [...xaxis, newerdata[i].client];
        yaxis = [...yaxis, PRKD(newerdata[i].value)];
      }
    }

    let options = {
      chart: {
        type: "bar",
        height: 500,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          dataLabels: { position: "top" },
        },
      },

      xaxis: { categories: xaxis },
      dataLabels: {
        enabled: true,
        position: "end",
        offsetX: 20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      title: {
        text: `${this.state.title} Per Client`,
        floating: true,
        align: "center",
        style: {
          color: "#444",
        },
      },
    };
    let series = [
      {
        name: this.state.title,
        data: yaxis,
      },
    ];

    let pieopts = {
      chart: {
        type: "pie",
      },
      labels: xaxis,
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: { width: 500 },
            legend: { position: "bottom" },
          },
        },
      ],
    };

    return (
      <div>
        <Div14>Rank By:</Div14>
        <FlexboxGrid justify="space-around" align="middle">
          {opts.map((it, index) => (
            <div key={index} style={{ margin: "2px" }}>
              <IconButton
                size="sm"
                color={this.state.type === it.label ? "green" : "default"}
                icon={
                  <Icon
                    icon={
                      this.state.type === it.label ? "check-circle" : "circle"
                    }
                  />
                }
                onClick={() =>
                  this.setState({ type: it.label, title: it.title })
                }
              >
                {it.title}
              </IconButton>
            </div>
          ))}
        </FlexboxGrid>
        <hr />

        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={500}
        />
        <br />
        <div style={{ maxWidth: 800 }}>
          <ReactApexChart options={pieopts} series={yaxis} type="pie" />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(ClientBreakdownBars));
