import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { Tabs } from "antd";
import Div14 from "../../../../Components/Dividers/Div14";
import CombinedLocalGraphs from "../Tabs/CombinedLocalGraphs";
import { Icon, IconButton } from "rsuite";
import mgtOverviewStore from "../../../../Stores/Management/MgtOverviewStore";

const { TabPane } = Tabs;
class EMOverviewMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "All Local" };
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        <Div14>
          Local / International / Storage / CC Data{" "}
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() => mgtOverviewStore.getOverview(true)}
            loading={mgtOverviewStore.loading.local}
            size="xs"
          />
        </Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"All Local"} key={"All Local"}>
            <CombinedLocalGraphs jobtype="all" />
          </TabPane>
          <TabPane tab={"Local Move"} key={"LM"}>
            <CombinedLocalGraphs jobtype="LM" />
          </TabPane>
          <TabPane tab={"International"} key={"IM"}>
            <CombinedLocalGraphs jobtype="IM" />
          </TabPane>
          <TabPane tab={"Custom Clearance"} key={"CC"}>
            <CombinedLocalGraphs jobtype="CC" />
          </TabPane>
          <TabPane tab={"Storage"} key={"ST"}>
            <CombinedLocalGraphs jobtype="ST" />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(EMOverviewMain));
