import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Icon, IconButton } from "rsuite";
import googleURL from "../../../Static/baseURL/googleURL";
import { observer } from "mobx-react";
import { Tooltip } from "antd";

class GeneralMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: { lat: 29.17, lng: 48.039732 },
      marker: { lat: null, lng: null },
      zoom: 12,
    };
  }

  render() {
    let office = [
      {
        title: "Easy Move Office",
        lat: 29.36224047806684,
        lng: 47.96161277377282,
        icon: "building",
      },
      {
        title: "EM Warehouse - Mina Abdulla",
        lat: 29.003,
        lng: 48.086,
        icon: "home",
      },
    ];

    let officeMarks;
    if (!this.props.hideOffice) {
      officeMarks = office.map((off, index) => (
        <Tooltip key={index} title={off.title} lat={off.lat} lng={off.lng}>
          <IconButton
            icon={<Icon icon={off.icon} size="lg" />}
            circle
            color="black"
            appearance="ghost"
            size="lg"
            onClick={() => this.props.onChildClick(off.lat, off.lng)}
            style={{ border: "0px" }}
          />
        </Tooltip>
      ));
    }

    let markers;
    if (this.props.markers) {
      markers = this.props.markers.map((mark, index) => (
        <IconButton
          key={index}
          icon={<Icon icon="truck" />}
          circle
          appearance="ghost"
          style={{ border: "0px" }}
          color={mark.carStat === "On" ? "green" : "red"}
          size="lg"
          onClick={() => this.props.onChildClick(mark.lat, mark.lng)}
          lat={mark.lat}
          lng={mark.lng}
        />
      ));
    }

    let center = this.state.center;
    let zoom = this.state.zoom;
    if (this.props.center) center = this.props.center;
    if (this.props.zoom) zoom = this.props.zoom;

    return (
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={googleURL}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
          zoom={zoom}
          onClick={this.onClick}
        >
          {officeMarks}
          {markers}
        </GoogleMapReact>
      </div>
    );
  }
}

export default observer(GeneralMap);
