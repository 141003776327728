import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import MaterialTable from "material-table";
import moment from "moment";
import mgtAttendanceStore from "../../../Stores/Management/MgtAttendanceStore";

class AttendanceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let data = this.props.data;
    let title = this.props.title;

    let columnnames = [
      {
        title: "ID",
        field: "userNumId",
        removable: true,
      },
      {
        title: "Name",
        field: "username",
        removable: true,
        render: (row) => {
          return row.username.charAt(0).toUpperCase() + row.username.slice(1);
        },
      },
      { title: "Group", field: "group", removable: true },
      { title: "Email", field: "email", removable: true },
      {
        title: "Last Sign In",
        field: "attendance.createdAt",
        removable: true,
        defaultSort: "desc",
        render: (rowData) => {
          let data = "";
          let color = "black";

          if (rowData.attendance) {
            if (rowData.attendance.createdAt) {
              data = moment(rowData.attendance.createdAt).fromNow();
              if (
                moment(rowData.attendance.createdAt).isBefore(
                  moment().subtract(10, "hours")
                )
              ) {
                color = "red";
              }
            }
          }
          return <p style={{ color: color, fontWeight: "bold" }}>{data}</p>;
        },
      },
    ];

    let emstaff = this.props.emstaff;

    if (emstaff) {
      columnnames = [
        ...columnnames,
        { title: "Type", field: "staff.type", removable: true },
        { title: "Title", field: "staff.title", removable: true },
      ];
    }

    return (
      <div>
        <div style={{ padding: "2%" }}>
          <MaterialTable
            isLoading={this.props.loading}
            title={data.length + " " + title}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : title
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
              rowStyle: (rowData) => ({
                backgroundColor:
                  mgtAttendanceStore.selectedUser._id === rowData._id
                    ? "#b1fabc"
                    : "#FFF",
              }),
            }}
            onRowClick={(event, row) => {
              if (row._id !== mgtAttendanceStore.selectedUser._id) {
                mgtAttendanceStore.getAttendance(row, true);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(AttendanceTable));
