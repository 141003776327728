import { observer } from "mobx-react";

import React, { Component } from "react";
import { Icon, IconButton, InputGroup, Notification } from "rsuite";
import { Tooltip } from "@material-ui/core";
import { Empty, Tabs } from "antd";
import { withRouter } from "react-router";
import OrderTable from "./OrderTable";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import modalACOrderStore from "../../../../../../../Stores/ModalStores/ModalACOrderStore";
import { DatePicker } from "antd";

import OrderModal from "../../../../../StorageAccount/Modals/OrderModal";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class OrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, tab: "Current Orders" };
    this.onHide = this.onHide.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }
  componentDidMount() {
    staffStorageStore.getACOrders();
  }
  onHide() {
    this.setState({ show: false });
  }
  ActionButs(cellData) {
    return (
      <>
        <Tooltip title={`View Order`} arrow>
          <IconButton
            icon={<Icon icon={cellData.data.loading ? "eye-slash" : "eye"} />}
            color={cellData.data.loading ? "default" : "green"}
            circle
            size="xs"
            onClick={() => {
              if (cellData.data.loading) {
                Notification["warning"]({
                  title: `Order is being updated.`,
                  description:
                    "The order is being updated and may take time depending on the number of items available. Please refresh and try again. ",
                });
              } else {
                modalACOrderStore.getASTfromOrderId(cellData.data._id);
                this.setState({ show: true });
              }
            }}
          />
        </Tooltip>
        <span> </span>

        <Tooltip title={`Print QR Code`} arrow>
          <IconButton
            icon={<Icon icon="qrcode" />}
            color={cellData.data.loading ? "default" : "blue"}
            circle
            size="xs"
            onClick={() => {
              let loading = false;

              if (cellData) {
                if (cellData.data) {
                  if (cellData.data.loading) {
                    loading = true;
                    Notification["warning"]({
                      title: `Order is being updated.`,
                      description:
                        "The order is being updated and may take time depending on the number of items available. Please refresh and try again. ",
                    });
                  }
                }
              }

              if (!loading) {
                this.props.history.push(
                  `/qrprintorder/${cellData.data.acOrderNumId}`
                );
              }
            }}
          />
        </Tooltip>
      </>
    );
  }
  render() {
    return (
      <LoadBack loading={staffStorageStore.loading.acorders}>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
          centered
        >
          <TabPane tab={"Current Orders"} key={"Current Orders"}>
            <OrderTable
              data={staffStorageStore.acorders}
              type="Current"
              actionButs={this.ActionButs}
            />
          </TabPane>
          <TabPane tab={"Past Orders"} key={"Past Orders"}>
            <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
              <InputGroup>
                <InputGroup.Addon>Select Range</InputGroup.Addon>
                <RangePicker
                  size="large"
                  picker="month"
                  format={"MMM-YY"}
                  style={{ width: "100%" }}
                  onChange={(e) => staffStorageStore.changeDateRange(e)}
                  value={[
                    staffStorageStore.startdate,
                    staffStorageStore.enddate,
                  ]}
                />
                <InputGroup.Button
                  color="green"
                  loading={staffStorageStore.loading.invoices}
                  onClick={() =>
                    staffStorageStore.getCustomOrderStatus("Completed", true)
                  }
                >
                  <Icon icon="refresh" />
                </InputGroup.Button>
              </InputGroup>
            </div>
            {staffStorageStore.loaded.pastorders ? (
              <OrderTable
                data={staffStorageStore.pastorders}
                type="Past"
                actionButs={this.ActionButs}
              />
            ) : (
              <Empty description="Choose the date range and click Load" />
            )}
          </TabPane>
          <TabPane tab={"Cancelled Orders"} key={"Cancelled Orders"}>
            <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
              <InputGroup>
                <InputGroup.Addon>Select Range</InputGroup.Addon>
                <RangePicker
                  size="large"
                  picker="month"
                  format={"MMM-YY"}
                  style={{ width: "100%" }}
                  onChange={(e) => staffStorageStore.changeDateRange(e)}
                  value={[
                    staffStorageStore.startdate,
                    staffStorageStore.enddate,
                  ]}
                />
                <InputGroup.Button
                  color="green"
                  loading={staffStorageStore.loading.invoices}
                  onClick={() =>
                    staffStorageStore.getCustomOrderStatus("Cancelled", true)
                  }
                >
                  <Icon icon="refresh" />
                </InputGroup.Button>
              </InputGroup>
            </div>
            {staffStorageStore.loaded.cancelledorders ? (
              <OrderTable
                data={staffStorageStore.cancelledorders}
                type="Cancel"
                actionButs={this.ActionButs}
              />
            ) : (
              <Empty description="Choose the date range and click Load" />
            )}
          </TabPane>
        </Tabs>

        <br />
      </LoadBack>
    );
  }
}

export default withRouter(observer(OrderMain));
