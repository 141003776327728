import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class WHMOldWarehouseStore {
  constructor() {
    this.trucks = [];
    this.pallets = [];
    this.trucktrip = { _id: "" };
    this.loading = {
      trucks: false,
      pallets: false,
      trucktrip: false,
    };
    this.loaded = {
      trucks: false,
      trucktrip: false,
      pallets: false,
    };

    makeObservable(this, {
      pallets: observable,
      trucks: observable,
      trucktrip: observable,
      loading: observable,
      loaded: observable,
      truckloading: computed,
      freetruck: computed,
      currentlyloading: computed,
    });
  }

  // FETCH
  getTrucks(override) {
    let loaded;
    if (this.loading.trucks || this.loaded.trucks) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.trucks = true;
      return instance
        .get(`/whm/trucks/oldwarehousetrucks`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.trucks = data;
          this.loading.trucks = false;
          this.loaded.trucks = true;
          console.log("OLD WHM Trucks Fetched");
        })
        .catch((err) => {
          this.loading.trucks = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  getPallets(override) {
    let loaded;
    if (this.loading.pallets || this.loaded.pallets) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.pallets = true;
      return instance
        .get(`/whm/pallets/preparedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.pallets = data;
          this.loading.pallets = false;
          this.loaded.pallets = true;
          console.log("OLD WHM Pallets Fetched");
        })
        .catch((err) => {
          this.loading.pallets = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getTruckTrip(override) {
    let loaded;
    if (this.loading.trucktrip || this.loaded.trucktrip) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.trucktrip = true;
      this.trucktrip = { _id: "" };
      return instance
        .get(`/whm/trucktrip/loadingtrucktrip`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.trucktrip = data;
          this.loading.trucktrip = false;
          this.loaded.trucktrip = true;
          console.log("Loading Truck Trip Fetched");
        })
        .catch((err) => {
          this.loading.trucktrip = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get currentlyloading() {
    let data = this.trucks.filter((tr) => tr.status === "Loading");
    if (data.length > 0) {
      return { value: true, data: data };
    }
    return { value: false, data: data };
  }
  get truckloading() {
    return this.trucks.filter((tr) => tr.status === "Loading");
  }
  get freetruck() {
    return this.trucks.filter((tr) => tr.status === "Ready");
  }
}

const whmOldWarehouseStore = new WHMOldWarehouseStore();
export default whmOldWarehouseStore;
