import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { LMPriceCalc } from "../../../../../../Functions/Pricing/LMPriceCalc";
import { Parsed } from "../../../../../../Functions/Pricing/PRKDCalc";

class LMSummary extends Component {
  render() {
    let sj = this.props.sj;
    let price = LMPriceCalc(sj);
    return (
      <div>
        <Div14>Pricing:</Div14>

        <p>
          <b>Total CBM: </b>
          {sj.cbm} CBM
          <br />
          <b>Job Price: </b>
          {price[1]} KD (
          {price[1] === 0 || sj.cbm === 0
            ? "Missing"
            : Math.round((100 * Parsed(price[1])) / Parsed(sj.cbm)) / 100}{" "}
          KD / CBM)
          <br />
          <b>Add Charges: </b>
          {price[2]} KD ({sj.addcharge.length})
          <br />
          <b>Total Price: </b>
          {Parsed(price[0])} KD
        </p>
      </div>
    );
  }
}

export default observer(LMSummary);
