import React, { Component } from "react";

import DataGrid, { Column, Grouping } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton, Loader, Notification } from "rsuite";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import NewEditItemModal from "../../Dashboard/NewStorage/Steps/Supporting/ItemsSupport/NewEditItemModal";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import { message } from "antd";
import Col3 from "../../../../../Components/Columns/Col3";
import AddBulkItems from "./Supporting/AddBulkItems";
import AddExisting from "./Supporting/AddExisting";
import ItemsChecklist from "./Supporting/ItemsChecklist";

const instance = axios.create({ baseURL: baseURL });

class ItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      showbulk: false,
      showexisting: false,
      type: "New",
      showprint: false,
    };
    this.EditActionButton = this.EditActionButton.bind(this);
    this.DeleteActionButton = this.DeleteActionButton.bind(this);
    this.onHide = this.onHide.bind(this);
    this.submitAdd = this.submitAdd.bind(this);
    this.editItem = this.editItem.bind(this);
    this.selectExisting = this.selectExisting.bind(this);
  }
  selectExisting(item) {
    newStorageStore.selectItem(item);
    this.setState({ show: true, type: "Existing" });
  }
  editItem(val, label) {
    newStorageStore.editSingleItemMove(val, label);
  }
  submitAdd = async (type, items) => {
    let data = newStorageStore.singleitemmove;
    data.quantity = data.actualquantity;
    data.toaddressid = this.props.to._id;
    if (type === "Single") {
      if (data.acItemNumId !== "New") {
        this.setState({ loading: true });
        await instance
          .put(`ac/itemmove/editqty/${data._id}`, data, {
            headers: { authtoken: userStore.token },
          })
          .then((res) => {
            message.success(`Quantity Updated`);
            newStorageStore.getSingleACOrder(false, true);
            this.setState({ loading: false });
            this.onHide();
          })
          .catch((err) => {
            this.setState({ loading: false });
            Notification["error"]({ title: `An error occured` });
          });
      } else {
        data = {
          item: newStorageStore.singleitemmove,
          order: {
            _id: newStorageStore.oldorder._id,
            status: newStorageStore.oldorder.status,
            client: newStorageStore.oldorder.client,
            emlocation: newStorageStore.oldorder.emlocation._id,
          },
          toaddressid: this.props.to._id,
        };
        this.setState({ loading: true });
        await instance
          .put(`ac/itemmove/newitemexistingorder`, data, {
            headers: { authtoken: userStore.token },
          })
          .then((res) => {
            message.success(`New Item Created`);
            newStorageStore.getSingleACOrder(false, true);
            this.setState({ loading: false });
            this.onHide();
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
            Notification["error"]({ title: `An error occured` });
          });
      }
    } else if (type === "Existing") {
      this.setState({ loading: true });
      data = {
        item: newStorageStore.singleitemmove,
        orderid: newStorageStore.oldorder._id,
        orderstatus: newStorageStore.oldorder.status,
        inout: newStorageStore.delstoinout.inout,
        toaddressid: this.props.to._id,
      };
      data.item.quantity = data.item.actualquantity;
      this.setState({ loading: true });
      await instance
        .put(`ac/itemmove/existingitem`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          message.success(`Item Added to Order`);
          newStorageStore.getSingleACOrder(false, true);
          this.setState({ loading: false });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
          Notification["error"]({ title: `An error occured` });
        });
    } else if (type === "Bulk") {
      data = {
        items: items,
        order: {
          _id: newStorageStore.oldorder._id,
          status: newStorageStore.oldorder.status,
          client: newStorageStore.oldorder.client,
          emlocation: newStorageStore.oldorder.emlocation._id,
        },
        toaddressid: this.props.to._id,
      };

      this.setState({ loading: true });
      await instance
        .put(`ac/itemmove/newbulkexistingorder`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          message.success(`${items.length} Bulk Items Created`);
          this.setState({ loading: false });
          this.onHide();
          newStorageStore.getSingleACOrder(false, true);
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({ title: `An error occured` });
          this.setState({ loading: false });
          return false;
        });
    }
  };
  deleteItemMove = async (data) => {
    let itemmove = data.data;
    if (itemmove.acItemNumId !== "New") {
      this.setState({ loading: true });

      await instance
        .put(`ac/itemmove/deleteitemmove/${itemmove._id}`, itemmove, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          message.success(`Item Removed from Job`);
          newStorageStore.getSingleACOrder(false, true);
          this.setState({ loading: false });
          this.onHide();
        })
        .catch((err) => {
          Notification["error"]({ title: `An error occured` });
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      this.onHide();
    }
  };
  onHide() {
    this.setState({
      item: {},
      show: false,
      showbulk: false,
      showexisting: false,
    });
  }

  EditActionButton(cellData) {
    return (
      <IconButton
        icon={<Icon icon="edit" />}
        color="green"
        circle
        size="xs"
        onClick={() => {
          if (newStorageStore.stopedit) {
            Notification["error"]({
              title: `Cannot edit as the job has been Booked.`,
              description: "Please contact our office for support.",
            });
          } else {
            let rowInd = cellData.rowIndex;
            newStorageStore.selectItem(cellData.data, rowInd);
            this.setState({ show: true, type: "Edit" });
          }
        }}
      />
    );
  }

  DeleteActionButton(cellData) {
    let disabled = false;
    if (newStorageStore.delstoinout.inout === "In") {
      if (newStorageStore.oldorder.status === "Completed") {
        if (
          Parsed(cellData.data.acitem.currentstock) <
          Parsed(cellData.data.actualquantity)
        ) {
          disabled = true;
        }
      }
    }

    return (
      <IconButton
        icon={<Icon icon="trash" />}
        color="red"
        circle
        size="xs"
        disabled={disabled}
        onClick={() => this.deleteItemMove(cellData)}
      />
    );
  }
  DiffCell(cellData) {
    return <div>{cellData.data.acItemMoveNumId > 0 ? "Saved" : "New"}</div>;
  }
  Dimension(cellData) {
    return `${PRKD(cellData.data.itemlength / 100)} m x ${PRKD(
      cellData.data.itemwidth / 100
    )} m x ${PRKD(cellData.data.itemheight / 100)} m`;
  }
  LocTags(cellData) {
    return cellData.data.acitem.loctags.join(", ");
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.itemlength) *
        Parsed(cellData.data.itemwidth) *
        Parsed(cellData.data.itemheight)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.itemlength) *
        Parsed(cellData.data.itemwidth) *
        Parsed(cellData.data.actualquantity) *
        Parsed(cellData.data.itemheight)) /
      1000000;
    return PRKD(cbmperitem);
  }
  render() {
    let hideaction = this.props.hideaction;
    if (newStorageStore.stopedit) hideaction = true;

    let actions = <div />;
    if (!hideaction) {
      actions = (
        <FlexboxGrid>
          <Col3>
            {newStorageStore.delstoinout.inout === "In" && (
              <IconButton
                block
                loading={this.state.loading}
                color="green"
                icon={<Icon icon="plus" />}
                size="xs"
                appearance="ghost"
                onClick={() => {
                  let item = {
                    acItemNumId: "New",
                    account: newStorageStore.account._id,
                    acorder: newStorageStore.neworder._id,
                    quantity: 1,
                    actualquantity: 1,
                    itemheight: 0,
                    itemlength: 0,
                    itemwidth: 0,
                    itemproductid: "",
                    itemserialnumber: "",
                    acitem: { currentstock: 0 },
                    address: this.props.to._id,
                  };
                  newStorageStore.selectItem(item);
                  this.setState({ show: true, type: "New" });
                }}
              >
                Add New Item
              </IconButton>
            )}
          </Col3>
          <Col3>
            {newStorageStore.delstoinout.inout === "In" && (
              <IconButton
                block
                loading={this.state.loading}
                color="green"
                size="xs"
                appearance="ghost"
                icon={<Icon icon="multiple-lines-chart" />}
                onClick={() => this.setState({ showbulk: true })}
              >
                Add Bulk Items
              </IconButton>
            )}
          </Col3>
          <Col3>
            <IconButton
              block
              loading={this.state.loading}
              color="green"
              size="xs"
              appearance="ghost"
              icon={<Icon icon="cube" />}
              onClick={() => {
                this.setState({ showexisting: true, type: "Existing" });
                newStorageStore.getExistingItems(false, true);
              }}
            >
              Add Existing Item
            </IconButton>
          </Col3>
        </FlexboxGrid>
      );
    }
    let acitemmoves = this.props.acitemmoves;

    let content;
    if (this.state.loading) {
      content = <Loader center />;
    } else if (this.state.showexisting) {
      content = (
        <AddExisting
          onHide={this.onHide}
          selectExisting={this.selectExisting}
        />
      );
    } else if (!this.state.showbulk & !this.state.showexisting) {
      content = (
        <>
          {actions}
          <br />

          <DataGrid
            dataSource={acitemmoves}
            keyExpr="acItemNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            style={{ fontSize: 10 }}
            hoverStateEnabled={true}
            noDataText="No Items Added"
          >
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />

            <ColumnFixing enabled={true} />
            {!hideaction && (
              <Column
                caption="Edit"
                alignment={"center"}
                cellRender={this.EditActionButton}
                width={45}
              />
            )}

            <Column
              dataField="acItemNumId"
              dataType="number"
              caption="Item ID"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              width={60}
            />
            <Column caption="Item Details" alignment="center">
              <Column
                dataField="itemname"
                caption="Item"
                alignment="center"
                minWidth={220}
              />
              <Column
                dataField="itemserialnumber"
                caption="Ser. No."
                alignment="center"
                minWidth={160}
              />
              <Column
                dataField="itemproductid"
                caption="Prod. No."
                alignment="center"
                minWidth={80}
              />
            </Column>
            <Column
              dataField="dimensions"
              caption="Dimensions"
              alignment="center"
              minWidth={140}
              cellRender={this.Dimension}
            />
            <Column
              dataField="actualquantity"
              dataType="number"
              caption="Quantity"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              minWidth={80}
            />
            <Column caption="CBM Calcs" alignment="center">
              <Column
                dataField="cbmperitem"
                dataType="number"
                caption="CBM Per Item"
                alignment="center"
                minWidth={80}
                cellRender={this.CBMPerItem}
              />
              {userStore.user.group === "EMStaff" && (
                <Column
                  dataField="loctag"
                  dataType="number"
                  caption="WH Location"
                  alignment="center"
                  minWidth={80}
                  cellRender={this.LocTags}
                />
              )}
              <Column
                dataField="totalcbm"
                dataType="number"
                caption="Total CBM"
                alignment="center"
                minWidth={80}
                cellRender={this.TotalCBM}
              />
            </Column>
            {!hideaction && (
              <Column
                caption="Delete"
                alignment={"center"}
                cellRender={this.DeleteActionButton}
                width={45}
              />
            )}
          </DataGrid>
        </>
      );
    }

    let showitemchecklist = userStore.user.warehouse;

    if (hideaction) showitemchecklist = false;

    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
        }}
      >
        <NewEditItemModal
          item={newStorageStore.singleitemmove}
          onHide={this.onHide}
          show={this.state.show}
          type={this.state.type}
          submitAdd={this.submitAdd}
          editItem={this.editItem}
          loading={this.state.loading}
        />
        {this.state.showbulk && (
          <AddBulkItems
            onHide={this.onHide}
            submitAdd={this.submitAdd}
            to={this.props.to}
          />
        )}
        {content}
        <br />
        {showitemchecklist && <ItemsChecklist />}
      </div>
    );
  }
}

export default observer(ItemsList);
