import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../Components/Dividers/Div14";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import axios from "axios";
import userStore from "../../../Stores/UserStore";
import { Empty, Result, message, Tabs } from "antd";
import baseURL from "../../../Static/baseURL/baseURL";
import SubmitNewAttendance from "./SubmitNewAttendance";
import AttendanceHistory from "./AttendanceHistory";
import TextInputINPB from "../../../Components/Inputs/InputButton/TextInputINPB";
const { TabPane } = Tabs;

const instance = axios.create({ baseURL: baseURL });

class StaffDailyAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idval: "",
      staffname: "",
      staffid: "",
      lastsigninid: "",
      lastdate: "",
      lastin: "",
      lastout: "",
      loading: false,
      tab: "Today",
      loadedhistory: false,
    };
    this.editVal = this.editVal.bind(this);
    this.getStaff = this.getStaff.bind(this);
  }

  editVal(val, label) {
    this.setState({ [label]: val });
  }
  getStaff() {
    let url = `/hr/attendanceinout/checkstaffid/${this.state.idval}`;
    this.setState({ loading: true });
    return instance
      .get(`${url}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          staffname: res.data.name,
          staffid: res.data._id,
          lastdate: res.data.date,
          lastin: res.data.signInTime,
          lastout: res.data.signOutTime,
          lastsigninid: res.data.lastsigninid,
          loadedhistory: false,
        });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Get Staff");
      });
  }

  render() {
    let disabled = false;

    let content = <Empty description="Please search by Civil ID" />;
    if (this.state.staffname !== "") {
      if (this.state.staffname === "Staff Not Found") {
        content = (
          <Result
            status="warning"
            title="Civil ID Not Found. Please check your Civil ID number"
          />
        );
      } else {
        disabled = true;
        content = (
          <div>
            <hr />
            <Div14>
              {this.state.staffname}
              <Divider vertical />
              <IconButton
                size="xs"
                color="yellow"
                icon={<Icon icon="refresh" />}
                circle
                onClick={this.getStaff}
              />
            </Div14>
            <Tabs
              activeKey={this.state.tab}
              defaultActiveKey={0}
              tabPosition={"top"}
              type="card"
              centered
              onChange={(e) => {
                this.setState({ tab: e });
              }}
            >
              <TabPane tab={"Today"} key={"Today"}>
                <SubmitNewAttendance
                  staffid={this.state.staffid}
                  staffname={this.state.staffname}
                  lastdate={this.state.lastdate}
                  lastin={this.state.lastin}
                  lastout={this.state.lastout}
                  lastsigninid={this.state.lastsigninid}
                  getStaff={this.getStaff}
                />
              </TabPane>
              <TabPane tab={"History"} key={"History"}>
                <AttendanceHistory
                  editVal={this.editVal}
                  loadedhistory={this.state.loadedhistory}
                  staffname={this.state.staffname}
                  staffid={this.state.staffid}
                />
              </TabPane>
            </Tabs>
          </div>
        );
      }
    }

    return (
      <div>
        <Div14>Staff Sign In</Div14>
        <h5 style={{ textAlign: "center" }}>Enter Your Civil ID to Sign In</h5>
        <FlexboxGrid justify="center">
          <Col2>
            <TextInputINPB
              title="Civil ID"
              val={this.state.idval}
              label="idval"
              editVal={this.editVal}
              disabled={disabled}
              butdisable={!disabled & (this.state.idval.length < 3)}
              buticon={disabled ? "undo" : "check"}
              butcolor={disabled ? "orange" : "green"}
              buttext={disabled ? "New Civil ID" : "Check Civil ID"}
              load={this.state.loading}
              updateVal={() => {
                if (disabled) {
                  this.setState({
                    idval: "",
                    staffname: "",
                    staffid: "",
                    loadedhistory: false,
                    tab: "Today",
                  });
                } else {
                  this.getStaff();
                }
              }}
            />
          </Col2>
        </FlexboxGrid>
        {content}
      </div>
    );
  }
}

export default withRouter(observer(StaffDailyAttendance));
