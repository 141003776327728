import React, { Component } from "react";
import { Card, CardActionArea } from "@material-ui/core";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../Components/Dividers/Div14";
import moment from "moment";

class JobRows extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let job = this.props.job;

    let title = "";
    let urlcode = "";
    let jobtitle = "";
    if (job.actype === "AST") {
      urlcode = "acorder";
      title = "Storage";
      jobtitle = job.acOrderNumId;
    }
    if (job.actype === "DEL") {
      urlcode = "delorder";
      title = "Delivery";
      jobtitle = job.deliveryNumId;
    }

    return (
      <div style={{ margin: "1%", padding: "1%" }}>
        <Card style={{ minWidth: "300px" }}>
          <CardActionArea
            style={{
              padding: "1%",
              boxShadow: "0px 0px 2px black",
              backgroundColor: job.status === "In Transit" && "#e3ffe3",
            }}
            onClick={() => {
              this.props.history.push(`/${urlcode}/${job._id}`);
            }}
          >
            <div style={{ margin: "2%" }}>
              <Div14>
                {title} Job {jobtitle}
              </Div14>
              <b>Client: </b>
              {job.client.name} ({job.client.clientNumId})
              <br />
              <b>Job Type: </b>
              {job.jobtype}
              <br />
              <b>Status: </b>
              {job.status}
              <br />
              <b>Job Date: </b>
              {moment(job.jobdate).format("DD-MMM-YY")}
              {job.fromaddress && (
                <>
                  <br />
                  <b>From: </b>
                  {job.fromaddress.name}
                </>
              )}
              {job.toaddress && (
                <>
                  <br />
                  <b>To: </b>
                  {job.toaddress[0].name}
                </>
              )}
            </div>
          </CardActionArea>
        </Card>
      </div>
    );
  }
}

export default withRouter(observer(JobRows));
