import { observer } from "mobx-react";
import React, { Component } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import signature from "../../Pictures/signature.png";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol10white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol30white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol10blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol50blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "50%",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
});

class ChargeRows extends Component {
  render() {
    let inv = this.props.inv;

    let newchgs = inv.charges;

    let charges = newchgs.map((chg, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol10white}>{index + 1}</Text>
        <Text style={styles.tableCol10white}>{chg.aimOrderNumId}</Text>
        <Text style={styles.tableCol20white}>{chg.type}</Text>
        <Text style={styles.tableCol30white}>{chg.title}</Text>
        <Text style={styles.tableCol30white}>
          {PRKD(chg.price) !== 0 ? PRKD(chg.price) : "-"}
        </Text>
      </View>
    ));
    let totalfinalcost = 0;

    for (let i = 0; i < inv.charges.length; i++) {
      totalfinalcost += Parsed(inv.charges[i].price);
    }

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>
          International Account Invoice Charges Summary
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol10blue}>#</Text>
          <Text style={styles.tableCol10blue}>Order ID</Text>
          <Text style={styles.tableCol20blue}>Charge Type</Text>
          <Text style={styles.tableCol30blue}>Charge Title</Text>
          <Text style={styles.tableCol30blue}>Total (KD)</Text>
        </View>
        {charges}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol20blue}>
            {inv.charges.length} Charges
          </Text>
          <Text style={styles.tableCol50blue}></Text>
          <Text style={styles.tableCol30blue}>{PRKD(totalfinalcost)} KD</Text>
        </View>
        <Text style={{ lineHeight: 0.5 }}> </Text>
        <Text style={styles.text2}>
          Should you require any additional information please feel free to
          contact the undersigned at your earliest convenience. We thank you for
          the opportunity to quote for your business and assure you of our best
          service levels and prompt attention at all times.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.text2}>{"        "}Yours Sincerely,</Text>
        <Image src={signature} style={{ width: 150 }} />
        <Text style={styles.text2}>
          {"        "}On Behalf of Easy Move Logistics Co.
        </Text>
      </View>
    );
  }
}

export default observer(ChargeRows);
