import { DatePicker, Tooltip } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock } from "rsuite";

class MonthPickerSTU extends Component {
  render() {
    let format = "MMM-YYYY";
    if (this.props.format) format = this.props.format;
    let picker = "month";
    if (this.props.picker) picker = this.props.picker;

    let ind = this.props.ind;
    let label = this.props.label;
    let title = this.props.title;
    let load = this.props.load;

    let org = this.props.org;
    let val = this.props.val;

    let disabled = false;

    if (this.props.disabled) disabled = this.props.disabled;

    let changed = false;

    if (moment(org).format(format) !== moment(val).format(format)) {
      changed = true;
    }

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;
    if (quality) {
      if (val === undefined) {
        flag = true;
        qualitytext = `${title} must be added.`;
      }
    }

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {changed && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(org, label, ind)}
            >
              <Icon icon="undo" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: flag && "#ff968c" }}>
              {title}
            </InputGroup.Addon>
          </Tooltip>
          <DatePicker
            allowClear={false}
            format={format}
            style={{ width: "100%" }}
            disabled={disabled}
            value={moment(val)}
            picker={picker}
            onChange={(e) => {
              let newdate;
              if (e === null) {
                newdate = undefined;
              } else {
                newdate = moment(e).format(format);
              }
              this.props.editVal(newdate, label, ind);
            }}
          />

          {changed && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(label, title, ind)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {changed && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>"{org}"</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(MonthPickerSTU);
