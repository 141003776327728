import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Loader } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Col3 from "../../../../../Components/Columns/Col3";
import Div14 from "../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import axios from "axios";
import { withRouter } from "react-router";

import modalItemStore from "../../../../../Stores/ModalStores/ModalItemStore";
import AddLocationTags from "../AddLocationTags/AddLocationTags";
import TextInputSTU from "../../../../../Components/Inputs/Updatable/TextInputSTU";
import NumbInputSTU from "../../../../../Components/Inputs/Updatable/NumbInputSTU";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import { message } from "antd";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class EditItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      showtrack: false,
      order: { acOrderNumId: "", _id: "" },
      load: {},
    };
    this.handleModeChange = this.handleModeChange.bind(this);
    this.onHideAll = this.onHideAll.bind(this);
    this.onHideTrack = this.onHideTrack.bind(this);
    this.onShowTrack = this.onShowTrack.bind(this);
    this.updateData = this.updateData.bind(this);
  }
  updateData = async (label) => {
    let item = modalItemStore.item;
    let org = modalItemStore.original;
    let data = { [label]: item[label] };
    this.setState({ load: { [label]: false } });
    await instance
      .put(`/ac/basicedititem/edititem/${item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        let length = org.length;
        let width = org.width;
        let height = org.height;
        if (label === "length") length = item.length;
        if (label === "width") width = item.width;
        if (label === "height") height = item.width;

        if (label === "length" || label === "width" || label === "height") {
          let cbminitial =
            (Parsed(length) * Parsed(width) * Parsed(height)) / 1000000;
          let dimensions = `${PRKD(Parsed(length) / 100)}m x ${PRKD(
            Parsed(width) / 100
          )}m x ${PRKD(Parsed(height) / 100)}m`;
          let totalcbm = PRKD(cbminitial * Parsed(item.currentplusreserve));
          let cbm = PRKD(cbminitial);
          modalItemStore.editOrg(dimensions, "dimensions");
          modalItemStore.editOrg(totalcbm, "totalcbm");
          modalItemStore.editOrg(cbm, "cbm");
        }
        modalItemStore.editOrg(data[label], label);

        this.setState({ load: { [label]: false } });
        message.success(`Item Updated (${label})`);
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };
  onShowTrack(acorder) {
    this.setState({ showtrack: true, order: acorder });
  }
  onHideTrack() {
    this.setState({ showtrack: false });
  }
  onHideAll() {
    this.onHideTrack();
    this.props.onHide();
  }
  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };
  render() {
    let store = modalItemStore;
    let item = store.item;
    let org = store.original;

    let load = this.state.load;
    let content = <Loader center />;

    if (!store.loading.item) {
      content = (
        <div>
          <FlexboxGrid>
            <Col2>
              <b>Client: </b>
              {item.clientshort}
            </Col2>
            <Col2>
              <b>Warehouse: </b>
              {item.warehouseshort}
            </Col2>
          </FlexboxGrid>
          <Div14>Item Details:</Div14>

          <FlexboxGrid>
            <Col3>
              <TextInputSTU
                val={item.name}
                org={org.name}
                label="name"
                title="Item Name:"
                load={load.name}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
              <TextInputSTU
                val={item.serialnumber}
                org={org.serialnumber}
                label="serialnumber"
                title="Serial No.:"
                load={load.serialnumber}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
              <TextInputSTU
                val={item.productid}
                org={org.productid}
                label="productid"
                title="Product No.:"
                load={load.productid}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
            </Col3>
            <Col3 style={{ textAlign: "center" }}>
              <b>Current Stock: </b>
              {org.currentplusreserve} ({org.onreserve} On Reserve)
              <br />
              <i>
                <b>Damaged Stock: </b>
                {org.damaged}
                <br />
                <b>Entering Stock: </b>
                {org.enteringstock}
              </i>
              <br />
              <b>CBM: </b>
              {org.cbm} CBM/peice
              <br />
              <b>Dimensions: </b>
              {org.dimensions}
              <br />
              <b>Total CBM: </b>
              {org.totalcbm} CBM
            </Col3>
            <Col3>
              <NumbInputSTU
                val={item.length}
                org={org.length}
                label="length"
                title="Length  (cm)"
                load={load.length}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
              <NumbInputSTU
                val={item.width}
                org={org.width}
                label="width"
                title="Width  (cm)"
                load={load.width}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
              <NumbInputSTU
                val={item.height}
                org={org.height}
                label="height"
                title="Height (cm)"
                load={load.height}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
            </Col3>
          </FlexboxGrid>
          <hr />
          <Div14>Tags:</Div14>
          <FlexboxGrid>
            <Col2>
              <AddLocationTags />
            </Col2>
            <Col2></Col2>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          onHide={this.onHideAll}
          title={`EDIT ITEM: ID# ${item.acItemNumId} - ${item.name} - Item Details`}
          footer={false}
        >
          {content}
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(EditItemModal));
