import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Loader,
  Notification,
} from "rsuite";
import newAccountStore from "../../../Stores/AccountUpdated/General/NewAccountStore";
import NewAccountTemplate from "./NewSupport/NewAccountTemplate";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import { Spin } from "antd";
import Col2 from "../../../Components/Columns/Col2";
import moment from "moment";
import EditAccountTemplate from "./EditSupport/EditAccountTemplate";

const instance = axios.create({ baseURL: baseURL });

class NewEditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { type: "New", loading: false };
    this.postNew = this.postNew.bind(this);
  }
  componentDidMount() {
    newAccountStore.fetchEMLocations();
    newAccountStore.createNewAccount();
    newAccountStore.createNewStorCont();
    newAccountStore.createNewDelCont();
    newAccountStore.getServices();
    if (this.props.match.path === "/accounts/new") {
    } else {
      this.setState({ type: "Edit" });
      newAccountStore.getAccount(this.props.match.params.clientid);
    }
  }

  postNew = async () => {
    let data = {
      account: newAccountStore.account,
      storCont: newAccountStore.storCont,
      delCont: newAccountStore.delCont,
    };

    this.setState({ loading: true });

    return instance
      .post(`/ac/account/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.props.history.push(`/ac/dashboard/${res.data.account._id}`);

        this.setState({ loading: false });
        Notification["success"]({
          title: `New Account Created.`,
          description: <div></div>,
        });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  render() {
    let content = <Loader center />;
    if (!newAccountStore.loading & !newAccountStore.accloading) {
      if (this.state.type === "New") {
        content = <NewAccountTemplate />;
      } else {
        content = <EditAccountTemplate />;
      }
    } else {
      console.log("Catch!");
      return content;
    }
    let disabled = { val: false, msg: "" };
    let acc = newAccountStore.account;
    if (acc.name === "") {
      disabled = { val: true, msg: "Client Name Missing" };
    } else if ((acc.email === "") & (acc.phone === "") & (acc.phone2 === "")) {
      disabled = { val: true, msg: "Client Contact Missing" };
    } else if ((acc.email === "") & (acc.phone === "") & (acc.phone2 === "")) {
      disabled = { val: true, msg: "Client Contact Missing" };
    } else if (moment(acc.enddate).isSameOrBefore(acc.startdate)) {
      disabled = { val: true, msg: "Contract End Date is Before Start Date" };
    } else if (!acc.aim & !acc.acc & !acc.ast & !acc.adel) {
      disabled = { val: true, msg: "At Least 1 Job Type Must Be Selected" };
    } else if (acc.ast) {
      if (newAccountStore.storCont.emlocation) {
        if (newAccountStore.storCont.emlocation._id === "") {
          disabled = { val: true, msg: "Please Select a Storage Warehouse" };
        }
      } else {
        disabled = { val: true, msg: "Please Select a Storage Warehouse" };
      }
    }

    return (
      <div>
        <Spin
          spinning={this.state.loading}
          size="large"
          tip="Loading..."
          style={{ color: "black" }}
        >
          <PageHeader
            onBack={() => this.props.history.push("/accounts/clients")}
            title={`${this.state.type} Account`}
            subTitle={
              this.state.type === "New"
                ? `Create a New Client Account`
                : `Edit Account #${newAccountStore.account.clientNumId}`
            }
          />
          {content}
          <hr />
          {this.state.type === "New" && (
            <FlexboxGrid justify="center">
              <Col2>
                <IconButton
                  icon={<Icon icon="save" />}
                  onClick={() => this.postNew()}
                  color="green"
                  block
                  disabled={disabled.val}
                >
                  Save Data
                </IconButton>
                {disabled.val && (
                  <HelpBlock style={{ color: "red" }}>{disabled.msg}</HelpBlock>
                )}
              </Col2>
            </FlexboxGrid>
          )}
        </Spin>
      </div>
    );
  }
}

export default withRouter(observer(NewEditAccount));
