import { Tooltip } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { InputGroup, Input, Icon, HelpBlock } from "rsuite";
import { QualityTextCalc } from "../../../../Functions/Quality/QualityTextCalc";

class TextInput extends Component {
  render() {
    let type = this.props.type;
    let label = this.props.label;
    let val = this.props.val;
    let org = this.props.org;
    let load = this.props.load;
    let index = this.props.index;
    let disabled = this.props.disabled;
    let subjobtype = this.props.subjobtype;

    let size = "lg";
    if (this.props.size) size = this.props.size;

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;
    if (quality) {
      if (val === "") {
        flag = true;
      }
      qualitytext = QualityTextCalc(!flag, label, "empty");
    }

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {org !== val && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(type, org, index, subjobtype)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: flag && "#ff968c" }}>
              {label}
            </InputGroup.Addon>
          </Tooltip>
          <Input
            size={size}
            readOnly={disabled}
            value={val}
            onChange={(e) => this.props.editVal(type, e, index, subjobtype)}
          />
          {org !== val && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(type, index, subjobtype)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {org !== val && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>"{org}"</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(TextInput);
