import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Col4 from "../../../../Components/Columns/Col4";
import Div14 from "../../../../Components/Dividers/Div14";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";

class TimeButs extends Component {
  render() {
    let opts = this.props.opts;
    let content = opts.map((opt, index) => (
      <IconButton
        appearance="ghost"
        style={{
          color: "black",
          fontWeight: "bold",
          backgroundColor:
            opt.value === "No"
              ? "#ffbdbd"
              : opt.value === "Yes"
              ? "#8fffa3"
              : "#fdffb5",
        }}
        key={index}
        block
        color={
          opt.value === "No" ? "red" : opt.value === "Yes" ? "green" : "yellow"
        }
        icon={
          <Icon
            icon={
              opt.value === "No"
                ? "close"
                : opt.value === "Yes"
                ? "check"
                : "clock-o"
            }
          />
        }
        onClick={() => {
          let value = "Yes";
          if (opt.value === "No") value = "Yes";
          if (opt.value === "After") value = "No";
          if (opt.value === "Yes") value = "After";

          newAccountStore.editDelTime(value, opt.label);
        }}
      >
        <div style={{ textAlign: "left" }}>
          {opt.type === "hour" ? `${opt.label}:00` : opt.type} (
          {opt.value === "Yes"
            ? "Regular Delivery"
            : opt.value === "No"
            ? "No Delivery"
            : "After Hours"}
          )
        </div>
      </IconButton>
    ));
    return content;
  }
}

class EditTimingSection extends Component {
  render() {
    let newhrs = newAccountStore.delCont.excludedhours;
    let hrs = newAccountStore.orgdelCont.excludedhours;

    let opts1 = [
      { type: "hour", label: "0", value: newhrs["0"] },
      { type: "hour", label: "1", value: newhrs["1"] },
      { type: "hour", label: "2", value: newhrs["2"] },
      { type: "hour", label: "3", value: newhrs["3"] },
      { type: "hour", label: "4", value: newhrs["4"] },
      { type: "hour", label: "5", value: newhrs["5"] },
      { type: "hour", label: "6", value: newhrs["6"] },
      { type: "hour", label: "7", value: newhrs["7"] },
    ];
    let opts2 = [
      { type: "hour", label: "8", value: newhrs["8"] },
      { type: "hour", label: "9", value: newhrs["9"] },
      { type: "hour", label: "10", value: newhrs["10"] },
      { type: "hour", label: "11", value: newhrs["11"] },
      { type: "hour", label: "12", value: newhrs["12"] },
      { type: "hour", label: "13", value: newhrs["13"] },
      { type: "hour", label: "14", value: newhrs["14"] },
      { type: "hour", label: "15", value: newhrs["15"] },
    ];
    let opts3 = [
      { type: "hour", label: "16", value: newhrs["16"] },
      { type: "hour", label: "17", value: newhrs["17"] },
      { type: "hour", label: "18", value: newhrs["18"] },
      { type: "hour", label: "19", value: newhrs["19"] },
      { type: "hour", label: "20", value: newhrs["20"] },
      { type: "hour", label: "21", value: newhrs["21"] },
      { type: "hour", label: "22", value: newhrs["22"] },
      { type: "hour", label: "23", value: newhrs["23"] },
    ];
    let opts4 = [
      { type: "Sunday", label: "sun", value: newhrs.sun },
      { type: "Monday", label: "mon", value: newhrs.mon },
      { type: "Tuesday", label: "tue", value: newhrs.tue },
      { type: "Wednesday", label: "wed", value: newhrs.wed },
      { type: "Thursday", label: "thu", value: newhrs.thu },
      { type: "Friday", label: "fri", value: newhrs.fri },
      { type: "Saturday", label: "sat", value: newhrs.sat },
    ];

    let optchanged = false;
    let allops = [...opts1, ...opts2, ...opts3, ...opts4];
    for (let i = 0; i < allops.length; i++) {
      if (hrs[allops[i].label] !== newhrs[allops[i].label]) {
        optchanged = true;
      }
    }

    return (
      <div>
        <Div14>Timing Section:</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="undo" />}
                block
                disabled={!optchanged}
                onClick={() =>
                  newAccountStore.editDelCont({ ...hrs }, "excludedhours")
                }
              >
                Revert to Original
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="save" />}
                color="green"
                block
                disabled={!optchanged}
                onClick={() => this.props.updateVal()}
              >
                Save Timing Changes
              </IconButton>
            </Col2>
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col4>
            <Div14>Days of the Week</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts4} />
            </FlexboxGrid>
          </Col4>
          <Col4>
            <Div14>0:00 to 7:00</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts1} />
            </FlexboxGrid>
          </Col4>
          <Col4>
            <Div14>8:00 to 15:00</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts2} />
            </FlexboxGrid>
          </Col4>

          <Col4>
            <Div14>16:00 to 23:00</Div14>
            <FlexboxGrid>
              <TimeButs opts={opts3} />
            </FlexboxGrid>
          </Col4>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(EditTimingSection);
