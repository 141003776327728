import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import whmCompanyStore from "../WHMStores/WHMCompanyStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import { Icon, IconButton } from "rsuite";
import { Empty } from "antd";

class WHMSingleCompanyItemTablePalletized extends Component {
  constructor(props) {
    super(props);
    this.Remaining = this.Remaining.bind(this);
  }
  Remaining(cellData) {
    let data = cellData.data;
    return parseFloat(data.currentstock) - parseFloat(data.palletized);
  }
  componentDidMount() {
    whmCompanyStore.getPalletized(false, true);
  }
  render() {
    return (
      <LoadBack loading={whmCompanyStore.loading.palletized}>
        <IconButton
          icon={<Icon icon="refresh" />}
          onClick={() =>
            whmCompanyStore.getPalletized(
              this.props.match.params.companyid,
              true
            )
          }
          size="sm"
          color="orange"
        >
          Refresh
        </IconButton>

        {whmCompanyStore.palletized.length <= 0 ? (
          <Empty description="All Items are Palletized" />
        ) : (
          <DataGrid
            dataSource={whmCompanyStore.palletized}
            keyExpr="_id"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
            onSelectionChanged={this.onSelect}
          >
            <Selection mode="single" />
            <FilterRow visible={true} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />

            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />
            <Column
              dataField="acItemNumId"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="palletNumber"
              caption="Pallet #"
              alignment="center"
              width={80}
            />
            <Column
              dataField="name"
              caption="Client"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="serialnumber"
              caption="Status"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="productid"
              caption="Start"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="cbm"
              caption="CBM"
              alignment="center"
              width={80}
            />
            <Column
              dataField="currentstock"
              caption="Stock"
              alignment="center"
              width={80}
            />
            <Column
              dataField="palletized"
              caption="Palletized"
              alignment="center"
              width={80}
            />
            <Column
              dataField="remaining"
              caption="Remaining"
              alignment="center"
              width={80}
              cellRender={this.Remaining}
            />

            <Export enabled={true} />
            <Summary>
              <TotalItem column="acItemNumId" summaryType={"count"} />
            </Summary>
          </DataGrid>
        )}
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMSingleCompanyItemTablePalletized));
