import { observer } from "mobx-react";
import React, { Component } from "react";
import Col4 from "../../../../../../../Components/Columns/Col4";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { FlexboxGrid } from "rsuite";
import { Collapse } from "antd";

import moment from "moment";
import MaterialTable from "material-table";

const { Panel } = Collapse;

class DeliveryTimeRequestTable extends Component {
  render() {
    let delCont = this.props.delCont;

    let hrs = delCont.excludedhours;
    let alldays = [
      { data: "sun", title: "Sunday" },
      { data: "mon", title: "Monday" },
      { data: "tue", title: "Tuesday" },
      { data: "wed", title: "Wednesday" },
      { data: "thu", title: "Thursday" },
      { data: "fri", title: "Friday" },
      { data: "sat", title: "Saturday" },
    ];

    let allhrs1 = [];
    let allhrs2 = [];
    let allhrs3 = [];
    let daysdata = [];
    let time;
    let intype;
    let type;
    let fee;
    for (let i = 0; i < alldays.length; i++) {
      let fee = "";
      let ppt = delCont.pricepertrip[alldays[i].data];

      if (ppt > 0) fee = `${ppt} KD `;

      let data = alldays[i].data;
      let time = alldays[i].title;
      let intype = hrs[alldays[i].data];
      if (intype === "No") type = "No Delivery";
      if (intype === "After") type = "After Hours";
      if (intype === "After") fee = `${fee}+ ${delCont.afterhourfee} Add`;
      if (intype === "Yes") type = "Delivery";

      daysdata = [
        ...daysdata,
        { data: data, time: time, type: type, fee: fee },
      ];
    }

    for (let i = 0; i < 24; i++) {
      fee = "-";
      time = moment()
        .set({ hours: i, minute: 0, second: 0, millisecond: 0 })
        .format("H:mm A");
      intype = hrs[`${i}`];
      if (intype === "No") type = "No Delivery";
      if (intype === "After") type = "After Hours";
      if (intype === "After") fee = `+ ${delCont.afterhourfee} KD`;
      if (intype === "Yes") type = "Delivery";
      if (i < 8) {
        allhrs1 = [...allhrs1, { time: time, type: type, fee: fee }];
      } else if (i < 16) {
        allhrs2 = [...allhrs2, { time: time, type: type, fee: fee }];
      } else {
        allhrs3 = [...allhrs3, { time: time, type: type, fee: fee }];
      }
    }
    let columnshr = [
      { title: "Hour", field: "time" },
      { title: "Type", field: "type" },

      { title: "Fee", field: "fee" },
    ];
    let columnsday = [
      { title: "Day", field: "time" },
      { title: "Type", field: "type" },
      { title: "Fee", field: "fee" },
    ];
    let tbls = [
      { data: daysdata, title: "Delivery Week Days" },
      { data: allhrs1, title: "Delivery Hours 0:00 to 7:00" },
      { data: allhrs2, title: "Delivery Hours 8:00 to 15:00" },
      { data: allhrs3, title: "Delivery Hours 16:00 to 24:00" },
    ];
    let mattabs = tbls.map((item, index) => (
      <Col4 key={index}>
        <Div14 style={{ textAlign: "center" }}>{item.title}</Div14>
        <MaterialTable
          title={item.title}
          columns={item.title === "Delivery Days" ? columnsday : columnshr}
          style={{ fontSize: 10 }}
          data={item.data}
          options={{
            actionsColumnIndex: 0,
            sorting: false,
            exportAllData: false,
            paging: false,
            toolbar: false,
            maxBodyHeight: 1000,
            tableLayout: "fixed",
            padding: "dense",
            search: false,
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.type === "No Delivery"
                  ? "#ff7373"
                  : rowData.type === "After Hours"
                  ? "#f1ff99"
                  : "#d5ffc7",
            }),
          }}
        />
      </Col4>
    ));

    return (
      <div>
        {!this.props.hideTitle && <Div14>Delivery Times</Div14>}
        <Collapse>
          <Panel header="Delivery Contract Request Timings">
            <FlexboxGrid>{mattabs}</FlexboxGrid>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default observer(DeliveryTimeRequestTable);
