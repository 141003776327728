import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import { Col, FlexboxGrid } from "rsuite";
import Div14 from "../../../../../Components/Dividers/Div14";
import commoditytypes from "../../../../../Static/Lists/jobsubtypes/imlists/commoditytypes";
import imtypes from "../../../../../Static/Lists/jobsubtypes/imtypes";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";

class AIMStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let aim = newAimStore.newaim;

    //let imtypes = imtypes;
    let direction = ["Inbound", "Outbound"];
    return (
      <div>
        <Div14>Step 1: Main Shipment Information</Div14>
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "5px",
            boxShadow: "1px 1px 5px black",
          }}
        >
          <FlexboxGrid justify="center">
            <Col md={12} sm={20} xs={24}>
              <SelectInputSTD
                title="Shipping Method"
                val={aim.shippingmethod}
                label="shippingmethod"
                size="large"
                opts={["Air", "Land", "Sea"]}
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <SelectInputSTD
                title="Direction"
                val={aim.direction}
                label="direction"
                size="large"
                opts={direction}
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <SelectInputSTD
                title="Type"
                val={aim.subtype}
                label="subtype"
                size="large"
                opts={imtypes}
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <SelectInputSTD
                title="Commodity"
                val={aim.commodity}
                label="commodity"
                size="large"
                opts={commoditytypes}
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <TextAreaInputSTD
                title="From"
                val={aim.shipfrom}
                label="shipfrom"
                size="large"
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <TextAreaInputSTD
                title="To"
                val={aim.shipto}
                label="shipto"
                size="large"
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
          </FlexboxGrid>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(AIMStep1));
