import moment from "moment";
import { AddChargeCalc } from "./AddChargeCalc";
import { Parsed } from "./PRKDCalc";

function STPriceCalc(job) {
  let type = job.subtype;
  let time;
  if (job.st) {
    if (job.st.time) {
      time = job.st.time;
    }
  }

  let format = "Month";
  if (type === "Weekly") format = "Week";
  if (type === "Daily") format = "Day";
  let pricepertime = 0;
  let storageprice = 0;
  let addcharge = 0;
  let price = 0;
  if (job.st) {
    if (job.st.pricepertime) {
      pricepertime = Parsed(job.st.pricepertime);
      if (job.st.price) {
        storageprice = Parsed(pricepertime) * Parsed(time);
      }
    }
  }
  addcharge = AddChargeCalc(job.addcharge);
  price = Parsed(storageprice) + Parsed(addcharge);

  let start;
  if (job.st) {
    start = job.st.start;
  }
  let timediff = `${format.toLowerCase()}s`;
  let end = undefined;
  if (job.st) {
    if (job.st.time) {
      if (start) end = moment(start).add(time, timediff);
    }
  }

  return [price, storageprice, addcharge, pricepertime, time, format, end];
}

export { STPriceCalc };
