import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newStorageStore from "../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col4 from "../../../../../../../Components/Columns/Col4";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import TextAreaInputSTD from "../../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../../../Components/Inputs/STD/TextInputSTD";
import ClientSignature from "./ClientSignature";
import ModalFooter from "../../../../../../../Components/Modal/ModalFooter";
import axios from "axios";
import moment from "moment";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import { Image, message } from "antd";
import Col2 from "../../../../../../../Components/Columns/Col2";

import ACOrderDamageReport from "../../../../../../../Components/PDFs/Accounts/DamageReport/ACOrder/ACOrderDamageReport";
import { Buffer } from "buffer";

const instance = axios.create({ baseURL: baseURL });

class ClientSectionMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      clientInstruction: "",
      clientname: "",
      clientsignature: null,
      showPDF: false,
    };
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  editVal(val, label) {
    if (userStore.user.staffaccount) {
      if (label === "sig") label = "clientsignature";
      this.setState({ [label]: val });
    }
  }
  onHide() {
    this.setState({
      show: false,
      clientInstruction: "",
      clientname: "",
      clientsignature: null,
    });
  }
  postNew = async () => {
    let dr = newStorageStore.oldorder.damagereport;
    let data = {
      clientInstruction: this.state.clientInstruction,
      clientname: this.state.clientname,
      clientsignature: this.state.clientsignature,
      clientsigndate: moment(),
    };

    let url = `ac/damagereport/clientsignature/${dr._id}`;

    this.setState({ loading: true });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
        message.success("Damage Report Signed");
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.onHide();
        message.error("An Error Occured");
      });
  };

  render() {
    let dr = newStorageStore.oldorder.damagereport;
    let content;
    if (dr.clientsigndate) {
      let src = dr.clientsignature;

      const b64 = new Buffer(src).toString("base64");

      let imagesrc = `data:image/png;base64,${b64}`;

      content = (
        <FlexboxGrid>
          <Col2>
            <h3>
              <b>Instructions:</b> {dr.clientInstruction}
              <br />
              <b>Client:</b> {dr.clientname}
              <br />
              <b>Sign Date:</b> {moment(dr.clientsigndate).format("DD-MMMM-YY")}
            </h3>
          </Col2>
          <Col2>
            <Image src={imagesrc} preview={false} />
            <IconButton
              icon={<Icon icon="pencil" />}
              color="red"
              size="xs"
              onClick={() =>
                this.setState({
                  show: true,
                  clientInstruction: dr.clientInstruction,
                  clientname: dr.clientname,
                })
              }
            >
              Re-Sign Document
            </IconButton>
          </Col2>
        </FlexboxGrid>
      );
    } else {
      content = (
        <FlexboxGrid justify="center">
          <Col4>
            <IconButton
              icon={<Icon icon={"pencil"} />}
              block
              color="green"
              onClick={() => this.setState({ show: true })}
              disabled={!userStore.user.staffaccount}
            >
              Client Signature
            </IconButton>
          </Col4>
        </FlexboxGrid>
      );
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["left", "pencil"]}
              color={["red", "green"]}
              app={["ghost", "primary"]}
              text={["Cancel", `Sign Document`]}
              oC1={() => this.onHide()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={
                this.state.clientInstruction === "" ||
                this.state.clientname === "" ||
                this.state.clientsignature === null
              }
            />
          }
        >
          <h3>Client Acknowledgement</h3>
          <hr />
          <TextAreaInputSTD
            title="Client Instructions"
            label="clientInstruction"
            size="large"
            editVal={this.editVal}
            val={this.state.clientInstruction}
          />
          <br />
          <TextInputSTD
            title="Client Name"
            label="clientname"
            size="large"
            editVal={this.editVal}
            val={this.state.clientname}
          />
          <br />
          <ClientSignature
            changeST={this.editVal}
            sig={this.state.clientsignature}
            oldsig={false}
          />
        </UnifiedModal>
        {content}
        <hr />
        <FlexboxGrid justify="center">
          <IconButton
            color={this.state.showPDF ? "green" : "red"}
            appearance={this.state.showPDF ? "primary" : "ghost"}
            icon={<Icon icon={this.state.showPDF ? "eye" : "eye-slash"} />}
            onClick={() => this.setState({ showPDF: !this.state.showPDF })}
          >
            {this.state.showPDF ? "Showing" : "Hiding"} PDF
          </IconButton>
        </FlexboxGrid>

        {this.state.showPDF && (
          <ACOrderDamageReport
            dr={dr}
            sto={newStorageStore.oldorder}
            client={newStorageStore.account}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(ClientSectionMain));
