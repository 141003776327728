import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import acDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/ACDeliveryStore";
import delInvoiceStore from "../../../../../../Stores/Financial/DelInvoiceStore";
import DeliveryChargeHeader from "../DeliveryCharges/DeliveryChargeHeader";
import InvoiceTable from "./InvoiceTable";

class DelInvoiceMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goToNewInvoice = this.goToNewInvoice.bind(this);
    this.goToEditInvoice = this.goToEditInvoice.bind(this);
  }

  goToNewInvoice() {
    this.props.history.push(
      `/dacinvoice/newinvoice/${acDeliveryStore.client._id}`
    );
  }
  goToEditInvoice(acinvid) {
    delInvoiceStore.getDelInvoiceById(acinvid);
    this.props.history.push(`/dacinvoice/editinvoice/${acinvid}`);
  }
  componentDidMount() {
    delInvoiceStore.getInvoiceByClient(acDeliveryStore.client._id);
    delInvoiceStore.getPaymentsByClient(acDeliveryStore.client._id);
  }
  render() {
    return (
      <div>
        <DeliveryChargeHeader hidedetails />
        <InvoiceTable
          onNewClick={this.goToNewInvoice}
          onEditClick={this.goToEditInvoice}
        />
      </div>
    );
  }
}

export default withRouter(observer(DelInvoiceMain));
