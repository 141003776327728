import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import { Empty } from "antd";
import { InputGroup, Input, InputNumber } from "rsuite";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import Div14 from "../../../../../Components/Dividers/Div14";
import Col4 from "../../../../../Components/Columns/Col4";
import userStore from "../../../../../Stores/UserStore";

class OptionCheckBox extends Component {
  render() {
    let option = this.props.option;
    let desc = option.desc;
    let price = option.price;
    let type = option.type;
    let total = 0;

    let ind = newDeliveryStore.newdel.orderoptions.findIndex(
      (opt) => opt.desc === desc
    );
    let value = ind >= 0;

    total = PRKD(price);

    return (
      <IconButton
        block
        icon={<Icon icon={value ? "check-circle" : "circle"} />}
        value={value}
        onClick={() => newDeliveryStore.updateOption(option)}
        color={value && "green"}
        appearance="ghost"
      >
        <FlexboxGrid style={{ textAlign: "left" }}>
          {desc} ({price} {type}) = {total} KD{" "}
        </FlexboxGrid>
      </IconButton>
    );
  }
}

class DACStep5 extends Component {
  render() {
    let delCont = newDeliveryStore.delCont;

    let options = delCont.options;
    let optmap;

    if (options.length > 0) {
      options = options.filter(
        (opt) => opt.delstor === "Delivery" || opt.delstor === "Both"
      );
      options = options.filter((opt) => opt.type === "KD");

      optmap = options.map((opt, index) => (
        <OptionCheckBox key={index} option={opt} />
      ));
    } else {
      optmap = <Empty description="No Options Available" />;
    }

    let addchgs = newDeliveryStore.newdel.addcharge;
    return (
      <div>
        <Div14>Select Options</Div14>
        <hr />
        {optmap}
        <hr />
        <Div14>Additional Charges</Div14>
        {addchgs.length <= 0 && (
          <Empty description="No Additional Charges Available" />
        )}
        {userStore.user.staffaccount && (
          <FlexboxGrid justify="center">
            <Col4>
              <IconButton
                icon={<Icon icon="plus" />}
                color="green"
                block
                onClick={() => newDeliveryStore.newAddCharge()}
              >
                Add Additional Charge
              </IconButton>
              <br />
            </Col4>
          </FlexboxGrid>
        )}
        {addchgs.map((addchg, index) => (
          <FlexboxGrid key={index} index={index} addchg={addchg}>
            <Col md={2} sm={2} xs={8}>
              <FlexboxGrid justify="center">
                <IconButton
                  icon={<Icon icon="trash" />}
                  color="red"
                  circle
                  onClick={() => newDeliveryStore.removeAddCharge(index)}
                />
              </FlexboxGrid>
            </Col>
            <Col md={14} sm={14} xs={16}>
              <InputGroup>
                <InputGroup.Addon>Add Charge</InputGroup.Addon>
                <Input
                  value={addchg.name}
                  onChange={(e) =>
                    newDeliveryStore.editAddCharge(e, "name", index)
                  }
                />
              </InputGroup>
            </Col>
            <Col md={8} sm={8} xs={24}>
              <InputGroup>
                <InputGroup.Addon>Price (KD)</InputGroup.Addon>
                <InputNumber
                  min={0}
                  value={addchg.price}
                  onChange={(e) =>
                    newDeliveryStore.editAddCharge(e, "price", index)
                  }
                  scrollable={false}
                />
              </InputGroup>
              <br />
            </Col>
          </FlexboxGrid>
        ))}
      </div>
    );
  }
}

export default observer(DACStep5);
