import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import MaterialTable from "material-table";
import hrStore from "../../../../Stores/HR/HRStore";
import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import Div14 from "../../../../Components/Dividers/Div14";
import { FlexboxGrid, Notification } from "rsuite";

import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import Col3 from "../../../../Components/Columns/Col3";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import { Parsed } from "../../../../Functions/Pricing/PRKDCalc";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class HolidayAllowance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      holiday: { staff: { name: "" }, _id: "" },
      oldholdays: 0,
      type: "Edit",
    };
    this.hideModal = this.hideModal.bind(this);
    this.deleteHoliday = this.deleteHoliday.bind(this);
    this.updateHoliday = this.updateHoliday.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  hideModal() {
    this.setState({
      show: false,
      type: "Edit",
      holiday: { staff: { name: "" } },
      oldholdays: 0,
    });
  }
  deleteHoliday = async () => {
    this.setState({ loading: true });
    let url = `/hr/holiday/deleteallowancebyid/${this.state.holiday._id}`;

    return instance
      .put(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHolidays(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Allowance Deleted`,
          description: <div></div>,
        });
        this.hideModal();
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  updateHoliday = async () => {
    this.setState({ loading: true });
    let url = `/hr/holiday/editallowance/${this.state.holiday._id}`;

    return instance
      .put(url, this.state.holiday, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHolidays(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Allowance Updated`,
          description: <div></div>,
        });
        this.hideModal();
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  editVal(val, label) {
    let old = { ...this.state.holiday };
    if (label === "dateto") val = moment(val).startOf("month");
    old[label] = val;
    this.setState({ holiday: old });
  }

  render() {
    let data = hrStore.holallowances;

    let columnnames = [
      { title: "ID", field: "holidayNumId", defaultSort: "asc" },
      { title: "Staff", field: "staff.name" },
      { title: "Role", field: "staff.type" },
      { title: "Type", field: "type" },
      {
        title: "Added",
        field: "date",
        render: (row) => moment(row.dateto).format("DD-MMM-YY"),
      },
      {
        title: "Month",
        field: "dateto",
        render: (row) => moment(row.dateto).format("MMM-YY"),
      },
      { title: "Days", field: "days" },
      { title: "Remarks", field: "remark" },
    ];

    let hol = this.state.holiday;

    return (
      <div>
        <UnifiedModal
          title={`${this.state.type} Holiday Allowance`}
          show={this.state.show & (this.state.type === "Delete")}
          onHide={this.hideModal}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes, Delete"]}
              oC1={() => this.hideModal()}
              oC2={() => this.deleteHoliday(hol._id)}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          Are you sure you want to delete this allowance?
          <hr />
          <b>Staff: </b>
          {hol.staff.name}
          <br />
          <b>Days: </b>
          {hol.days}
          <br />
          <b>Month: </b>
          {moment(hol.dateto).format("MMM-YY")}
          <br />
          <b>Remarks: </b>
          {hol.remark}
          <br />
        </UnifiedModal>
        <UnifiedModal
          title={`${this.state.type} Holiday Allowance`}
          show={this.state.show & (this.state.type === "Edit")}
          onHide={this.hideModal}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel Changes", "Update Allowance"]}
              oC1={() => this.hideModal()}
              oC2={() => this.updateHoliday(hol._id)}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={hol.days <= 0}
            />
          }
        >
          <Div14>{hol.staff.name}</Div14>
          <FlexboxGrid>
            <Col3>
              <TextInputSTD
                title="Days"
                val={hol.days}
                label="days"
                size="large"
                editVal={this.editVal}
              />
            </Col3>
            <Col3>
              <DatePickerSTD
                title="Allowance Month"
                format={"MMM-YY"}
                picker="month"
                val={hol.dateto}
                label={"dateto"}
                size="large"
                editVal={this.editVal}
              />
            </Col3>
            <Col3>
              <DatePickerSTD
                title="Date Added"
                format={"DD-MMM-YY"}
                picker="date"
                val={hol.date}
                label={"date"}
                size="large"
                editVal={this.editVal}
              />
            </Col3>
          </FlexboxGrid>
          <Div14>Changes:</Div14>
          <FlexboxGrid style={{ textAlign: "center", fontWeight: "bold" }}>
            <Col3>
              <h6>Old Allowance</h6>
              <h1>{this.state.oldholdays}</h1>
            </Col3>
            <Col3>
              <h6>New Allowance</h6>
              <h1>{hol.days}</h1>
            </Col3>
            <Col3>
              <h6>Difference Allowance</h6>
              <h1>
                {Parsed(hol.days) - Parsed(this.state.oldholdays) > 0 && "+ "}
                {Parsed(hol.days) - Parsed(this.state.oldholdays)}
              </h1>
            </Col3>
          </FlexboxGrid>
        </UnifiedModal>

        <MaterialTable
          title={data.length + " Holiday Allowances"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: [20],
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : "Staff List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.setState({
                  show: true,
                  type: "Edit",
                  holiday: rowData,
                  oldholdays: rowData.days,
                });
              },
            },
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                this.setState({
                  show: true,
                  type: "Delete",
                  holiday: rowData,
                  oldholdays: rowData.days,
                });
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(observer(HolidayAllowance));
