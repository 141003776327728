import { observer } from "mobx-react";
import React, { Component } from "react";
import IntroPage from "./IntroPage";

class MainInvoicePDF extends Component {
  render() {
    let inv = this.props.inv;

    return (
      <div>
        <IntroPage inv={inv} />
      </div>
    );
  }
}

export default observer(MainInvoicePDF);
