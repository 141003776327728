import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Button, FlexboxGrid, Icon, IconButton } from "rsuite";
import userStore from "../../Stores/UserStore";
import { Tooltip } from "@material-ui/core";
import UnifiedModal from "../Modal/UnifiedModal";
import NotificationPage from "./Notification/NotificationPage";

class SignInOutButton extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.showHideModal = this.showHideModal.bind(this);
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({ show: false });
    }
  }
  render() {
    let accCirc;
    let notif;
    if (userStore.signedIn) {
      accCirc = (
        <Tooltip title={"Sign Out"} arrow placement="left">
          <AccountCircle
            color="primary"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            onClick={() => {
              this.setState({ show: true });
              // userStore.logoutUser()
            }}
          />
        </Tooltip>
      );
      notif = (
        <>
          <NotificationPage />
          <span style={{ width: "5px" }} />
        </>
      );
    } else {
      accCirc = (
        <Tooltip title="Login" arrow placement="left">
          <AccountCircle onClick={() => this.props.history.push("/login")} />
        </Tooltip>
      );
    }
    let user = "";

    let accounttabs = [
      "jobs",
      "finance",
      "mgt",
      "hr",
      "compadmin",
      "comprequest",
      "quality",
      "warehouse",
      "customclearance",
      "teamleader",
      "staffaccount",
      "delivery",
    ];

    let content = <div />;
    if (userStore.user) {
      if (userStore.user.username) {
        user = userStore.user.username;
        content = (
          <div>
            <h6>{user.charAt(0).toUpperCase() + user.slice(1)}</h6>
            <h6>
              {userStore.user.group.charAt(0).toUpperCase() +
                userStore.user.group.slice(1)}
            </h6>
            <hr />
            <FlexboxGrid>
              {userStore.user.admin &&
                accounttabs.map((acc, index) => (
                  <IconButton
                    color="green"
                    appearance={userStore.user[acc] ? "primary" : "ghost"}
                    onClick={() => userStore.changeTempRole(acc)}
                    key={index}
                    block
                    icon={
                      <Icon
                        icon={userStore.user[acc] ? "check-square" : "square"}
                      />
                    }
                  >
                    {acc}
                  </IconButton>
                ))}
            </FlexboxGrid>
            <hr />
            <Button
              onClick={() => {
                this.setState({ show: false });
                userStore.logoutUser();
              }}
            >
              Sign Out
            </Button>
          </div>
        );
      }
    }
    return (
      <div>
        {notif}

        <UnifiedModal
          show={this.state.show}
          width={"400px"}
          title="Account Information"
          onHide={this.showHideModal}
          footer={<div />}
        >
          {content}
        </UnifiedModal>

        {accCirc}
      </div>
    );
  }
}

export default withRouter(observer(SignInOutButton));
