import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import combinedCalendarStore from "../../Stores/StaffStores/CombinedCalendarStore";
import LoadBack from "../../Components/Loaders/LoadBack";
import {
  Button,
  Col,
  Divider,
  FlexboxGrid,
  Icon,
  IconButton,
  InputGroup,
} from "rsuite";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { StatusColorCalc } from "../../Functions/Colors/StatusColorCalc";
import Div14 from "../../Components/Dividers/Div14";
import Col2 from "../../Components/Columns/Col2";
import { DatePicker } from "antd";
import JobDetailsModal from "../Lists/SideJobLists/Template/JobDetailsModal";
import singleJobStore from "../../Stores/SingleJobStore";
import sjStore from "../../Stores/SideJobs/SJStore";
import modalACOrderStore from "../../Stores/ModalStores/ModalACOrderStore";
import ACCOrderModal from "../Accounts/ACCAccount/Modal/OrderModal";
import STOOrderModal from "../Accounts/StorageAccount/Modals/OrderModal";
import AIMOrderModal from "../Accounts/AIMAccount/Modals/OrderModal";
import DELOrderModal from "../Accounts/DeliveryAccount/Modals/OrderModal";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class CombinedCalendarMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showacc: false,
      showaim: false,
      showdel: false,
      showast: false,
      jobId: "",
      subjob: { type: "" },
      job: {},
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    combinedCalendarStore.getCalendarMain();
  }
  onHide() {
    this.setState({
      show: false,
      jobId: "",
      subjob: { type: "" },
      showacc: false,
      showaim: false,
      showdel: false,
      showast: false,
    });
  }

  showHideModal(val, subjob) {
    if (!val) {
      this.setState({
        show: false,
        jobId: "",
        subjob: { type: "" },
        showacc: false,
        showaim: false,
        showdel: false,
        showast: false,
      });
    } else if (val) {
      if (subjob.jobtype === "ACC") {
        modalACOrderStore.getACCFromJob(subjob._id);
        this.setState({ showacc: true });
      } else if (subjob.jobtype === "AST") {
        modalACOrderStore.getASTfromOrderId(subjob._id);
        this.setState({ showast: true });
      } else if (subjob.jobtype === "AIM") {
        modalACOrderStore.getAIMfromTrack(subjob.aimOrder._id);
        this.setState({ showaim: true });
      } else if (subjob.jobtype === "DEL") {
        modalACOrderStore.getDELfromOrderId(subjob._id);
        this.setState({ showdel: true });
      } else {
        this.setState({ show: val, jobId: subjob.job, subjob: subjob });

        this.fetchJobDetails(subjob.job);
      }
    }
  }
  fetchJobDetails(jobId) {
    singleJobStore.getJob(jobId, true);
    sjStore.getSJs(jobId, true);
  }

  selectJob(job) {
    if (job.actype === "ACC") {
      modalACOrderStore.getACCFromJob(job._id);
      this.setState({ showacc: true });
    } else if (job.actype === "AST") {
      modalACOrderStore.getASTfromOrderId(job._id);
      this.setState({ showast: true });
    } else if (job.actype === "AIM") {
      modalACOrderStore.getAIMfromTrack(job.aimOrder._id);
      this.setState({ showaim: true });
    } else if (job.actype === "DEL") {
      modalACOrderStore.getDELfromOrderId(job._id);
      this.setState({ showdel: true });
    }
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";

    backgroundColor = StatusColorCalc(event.status);

    let textColor = "black";

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "1px solid black",
      borderColor: "black",
      display: "block",
      fontSize: 8,
      height: 15,
      color: textColor,
    };
    return {
      style: style,
    };
  };

  calendarStyle = (day) => {
    if (!moment().isSame(day, "day")) {
      return {
        style: {
          backgroundColor:
            moment(day).day() === 1
              ? "#ebebeb"
              : moment(day).day() === 5 && "#ebebeb",
        },
      };
    }
  };

  render() {
    let buttons = [
      { type: "ast", label: "Warehouse" },
      { type: "adel", label: "Delivery" },
      { type: "aim", label: "Account IM" },
      { type: "acc", label: "Account CC" },
      { type: "lm", label: "Local Move" },
      { type: "im", label: "Intern. Move" },
      { type: "cc", label: "Custom Clear." },
      { type: "st", label: "Storage" },
    ];

    return (
      <LoadBack loading={combinedCalendarStore.loading}>
        <ACCOrderModal show={this.state.showacc} onHide={this.onHide} />
        <STOOrderModal show={this.state.showast} onHide={this.onHide} />
        <AIMOrderModal show={this.state.showaim} onHide={this.onHide} />
        <DELOrderModal show={this.state.showdel} onHide={this.onHide} />

        <JobDetailsModal
          show={this.state.show}
          showModal={this.showHideModal}
          loading={false}
          subjob={this.state.subjob}
          jobId={this.state.jobId}
          type={this.state.subjob.jobtype}
        />
        <Div14>
          Combined Calendar <Divider vertical />
          <IconButton
            color="yellow"
            size="xs"
            circle
            icon={<Icon icon="refresh" />}
            onClick={() => combinedCalendarStore.getCalendarMain(true)}
          />
        </Div14>
        <FlexboxGrid justify="space-around">
          {buttons.map((but, index) => (
            <Col md={6} sm={8} xs={12} key={index}>
              <Button
                block
                onClick={() => combinedCalendarStore.changeFilt(but.type)}
                color="green"
                size="xs"
                appearance={
                  combinedCalendarStore.filter[but.type] ? "default" : "ghost"
                }
              >
                {but.label}
              </Button>
              <br />
            </Col>
          ))}
        </FlexboxGrid>
        <FlexboxGrid justify="center">
          <Col2>
            <InputGroup>
              <InputGroup.Addon>Start Date</InputGroup.Addon>
              <DatePicker
                style={{ width: "100%" }}
                //format={"DD-MMM-YY @ HA"}
                format={"MMM-YY"}
                value={moment(combinedCalendarStore.startdate)}
                allowClear={false}
                size={"large"}
                picker={"month"}
                showMinute={false}
                onChange={(date) => {
                  combinedCalendarStore.changeDate(date);
                }}
              />
              <InputGroup.Button
                color="green"
                loading={combinedCalendarStore.loading}
                onClick={() => combinedCalendarStore.getCalendarMain(true)}
              >
                <Icon icon="refresh" />
              </InputGroup.Button>
            </InputGroup>
          </Col2>
        </FlexboxGrid>
        <Calendar
          localizer={localizer}
          events={combinedCalendarStore.filtdates}
          eventPropGetter={this.eventStyleGetter}
          startAccessor={"start"}
          endAccessor={"end"}
          style={{ height: 800 }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor={"title"}
          popup
          onSelectEvent={(e) => this.showHideModal(true, e)}
          onNavigate={(e) => {}}
          dayPropGetter={this.calendarStyle}
        />
        <hr />
      </LoadBack>
    );
  }
}

export default withRouter(observer(CombinedCalendarMain));
