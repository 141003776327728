import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import sideJobStore from "../../../../Stores/SideJobs/SideJobStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col4 from "../../../../Components/Columns/Col4";

class DistributionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!sideJobStore.loadsjs) {
      return (
        <div>
          <FlexboxGrid justify="space-around">
            {sideJobStore.filtfocalpoints.map((focal, index) => (
              <Col4 key={index}>
                <IconButton
                  icon={<Icon icon={"user"} />}
                  color="blue"
                  size="xs"
                  block
                  appearance={
                    sideJobStore.filtuser === "All"
                      ? "primary"
                      : sideJobStore.filtuser === focal
                      ? "primary"
                      : "ghost"
                  }
                  onClick={() => {
                    let val = focal;
                    if (sideJobStore.filtuser === focal) {
                      val = "All";
                    }
                    sideJobStore.editFiltUser(val);
                  }}
                >
                  {focal.charAt(0).toUpperCase() + focal.slice(1)}
                </IconButton>
              </Col4>
            ))}
          </FlexboxGrid>
        </div>
      );
    }
    return <div />;
  }
}

export default withRouter(observer(DistributionButtons));
