import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../Components/Loaders/LoadBack";
import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";

import { ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import moment from "moment";
import GanttTrial from "./GanttTrial";
import { Col, FlexboxGrid, Icon } from "rsuite";
import TruckTripStats from "./TruckTripStats";
import whmTruckStore from "../WHMStores/WHMTruckStore";

class TruckTripTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.DateColumn = this.DateColumn.bind(this);
    this.LoadTime = this.LoadTime.bind(this);
    this.UnLoadTime = this.UnLoadTime.bind(this);
  }
  DateColumn(cellData) {
    if (cellData.displayValue) {
      return moment(cellData.displayValue).format("DD-MMM-YY HH:mm");
    }
    return "-";
  }
  LoadTime(cellData) {
    let data = cellData.data;
    if (data.loadingend) {
      let output = data.timetoload;
      let color = "green";
      if (output >= whmTruckStore.targets.loading) color = "red";

      return (
        <FlexboxGrid style={{ color: color, fontWeight: "bold" }}>
          <Col xs={12}>
            <Icon icon={color === "red" ? "clock-o" : "check-circle"} />
          </Col>
          <Col xs={12}>{output} min</Col>
        </FlexboxGrid>
      );
    }
    return "-";
  }
  UnLoadTime(cellData) {
    let data = cellData.data;

    if (data.unloadingstart) {
      if (data.unloadingend) {
        let output = data.timetounload;
        let color = "green";
        if (output >= whmTruckStore.targets.unloading) color = "red";

        return (
          <FlexboxGrid style={{ color: color, fontWeight: "bold" }}>
            <Col xs={12}>
              <Icon icon={color === "red" ? "clock-o" : "check-circle"} />
            </Col>
            <Col xs={12}>{output} min</Col>
          </FlexboxGrid>
        );
      }
    }
    return "-";
  }

  render() {
    return (
      <LoadBack loading={this.props.loading}>
        <TruckTripStats trips={this.props.data} />
        <DataGrid
          dataSource={this.props.data}
          keyExpr="whmTruckTripNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
          onSelectionChanged={this.onSelect}
        >
          <Selection mode="single" />

          <GroupPanel visible={false} />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column
            dataField="whmTruckTripNumId"
            caption="ID"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="pallets.length"
            caption="Pallets"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="qty"
            caption="QTY"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="cbm"
            caption="CBM"
            alignment="center"
            minWidth={80}
            format={{ type: "fixedPoint", precision: 3 }}
          />
          <Column
            dataField="createdAt"
            caption="Loading Start"
            alignment="center"
            minWidth={80}
            cellRender={this.DateColumn}
            sortOrder={"asc"}
          />
          <Column
            dataField="loadingend"
            caption="Loading End"
            alignment="center"
            minWidth={80}
            cellRender={this.DateColumn}
          />
          <Column
            dataField="timetoload"
            caption="Time to Load"
            alignment="center"
            minWidth={80}
            cellRender={this.LoadTime}
          />
          <Column
            dataField="unloadingstart"
            caption="Unloading Start"
            alignment="center"
            minWidth={80}
            cellRender={this.DateColumn}
          />
          <Column
            dataField="unloadingend"
            caption="Unloading End"
            alignment="center"
            minWidth={80}
            cellRender={this.DateColumn}
          />

          <Column
            dataField="timetounload"
            caption="Time to Unload"
            alignment="center"
            minWidth={80}
            cellRender={this.UnLoadTime}
          />
          <Export enabled={true} />
          <Summary>
            <TotalItem
              column="whmTruckTripNumId"
              summaryType="count"
              valueFormat={"#,##0"}
              displayFormat={"{0} Trips"}
            />
            <TotalItem
              column="timetounload"
              summaryType="avg"
              valueFormat={"#,##0.0"}
              displayFormat={"{0} min"}
            />
            <TotalItem
              column="timetoload"
              summaryType="avg"
              valueFormat={"#,##0.0"}
              displayFormat={"{0} min"}
            />
            <TotalItem
              column="pallets.length"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0} Pallets"}
            />
            <TotalItem
              column="pallets.length"
              summaryType="avg"
              valueFormat={"#,##0"}
              displayFormat={"{0} Avg/trip"}
            />
            <TotalItem
              column="qty"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0} Items"}
            />
            <TotalItem
              column="qty"
              summaryType="avg"
              valueFormat={"#,##0"}
              displayFormat={"{0} Avg/trip"}
            />
            <TotalItem
              column="cbm"
              summaryType="sum"
              valueFormat={"#,##0.0"}
              displayFormat={"{0} CBM"}
            />
            <TotalItem
              column="cbm"
              summaryType="avg"
              valueFormat={"#,##0.0"}
              displayFormat={"{0} Avg/trip"}
            />
          </Summary>
        </DataGrid>
        {!this.props.loading && <GanttTrial data={this.props.data} />}
      </LoadBack>
    );
  }
}

export default withRouter(observer(TruckTripTable));
