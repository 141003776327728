import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, InputGroup } from "rsuite";
import { DatePicker } from "antd";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import acInvoiceStore from "../../../../../../../Stores/Financial/ACInvoiceStore";

import InvoiceTable from "./InvoiceTable";
const { RangePicker } = DatePicker;
class ACInvoiceMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goToEditInvoice = this.goToEditInvoice.bind(this);
  }

  goToEditInvoice(acinvid) {
    acInvoiceStore.getACInvoiceById(acinvid);
    this.props.history.push(`/sacinvoice/editinvoice/${acinvid}`);
  }
  componentDidMount() {
    staffStorageStore.getAllInvoices();
  }
  render() {
    return (
      <div>
        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <InputGroup>
            <InputGroup.Addon>Select Range</InputGroup.Addon>
            <RangePicker
              size="large"
              picker="month"
              format={"MMM-YY"}
              style={{ width: "100%" }}
              onChange={(e) => staffStorageStore.changeDateRange(e)}
              value={[staffStorageStore.startdate, staffStorageStore.enddate]}
            />
            <InputGroup.Button
              color="green"
              loading={staffStorageStore.loading.invoices}
              onClick={() => staffStorageStore.getAllInvoices(true)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <InvoiceTable onEditClick={this.goToEditInvoice} />
      </div>
    );
  }
}

export default withRouter(observer(ACInvoiceMain));
