import { message } from "antd";
import axios from "axios";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { IconButton, Icon, FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Col4 from "../../../../Components/Columns/Col4";
import Div14 from "../../../../Components/Dividers/Div14";
import baseURL from "../../../../Static/baseURL/baseURL";

import singleStaffHRStore from "../../../../Stores/HR/SingleStaffHRStore";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class AssignStaffToUser extends Component {
  constructor(props) {
    super(props);
    this.state = { userlist: [], show: false };
    this.updateVal = this.updateVal.bind(this);
  }

  updateVal = async (label, title) => {
    let staff = singleStaffHRStore.singleedit;
    let val = staff[label];
    let url = `hr/staff/edit/${staff._id}`;

    let data = { [label]: val };

    this.setState({ load: { [label]: true } });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.editMainSingle(val, label);
        this.setState({ load: { [label]: false } });
        message.success(`${title} Updated.`);
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let org = singleStaffHRStore.singlestaff;
    let edit = singleStaffHRStore.singleedit;

    let changed = false;
    let staffless;
    if (org.user) {
      staffless = singleStaffHRStore.staffless.map((st, index) => (
        <Col4 key={index}>
          <IconButton
            block
            color={st._id === edit.user._id && "green"}
            icon={<Icon icon={st._id === edit.user._id ? "check" : "circle"} />}
            onClick={() => singleStaffHRStore.editSingle(st, "user")}
          >
            {st.group}: {st.username} / {st.email}
          </IconButton>
          <br />
        </Col4>
      ));
      if (edit.user._id !== org.user._id) {
        changed = true;
      }
    } else {
      staffless = singleStaffHRStore.staffless.map((st, index) => (
        <Col4 key={index}>
          <IconButton
            block
            color={st._id === edit.user && "green"}
            icon={<Icon icon={st._id === edit.user ? "check" : "circle"} />}
            onClick={() => singleStaffHRStore.editSingle(st._id, "user")}
          >
            {st.group}: {st.username} / {st.email}
          </IconButton>
          <br />
        </Col4>
      ));
      if (edit.user !== org.user) {
        changed = true;
      }
    }

    return (
      <div>
        <Div14>Assign Staff to User:</Div14>
        <IconButton
          icon={<Icon icon="people-group" />}
          onClick={() => {
            this.setState({ show: true });
            singleStaffHRStore.getStaffless();
          }}
        >
          Staffless
        </IconButton>
        <br />
        <FlexboxGrid>{this.state.show && staffless}</FlexboxGrid>
        <br />
        <br />
        {changed && (
          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => singleStaffHRStore.editSingle(org.user, "user")}
                block
                appearance="ghost"
              >
                Reset
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="save" />}
                onClick={() => this.updateVal("user", "Staff")}
                block
                color="green"
                appearance="ghost"
              >
                Save
              </IconButton>
            </Col2>
          </FlexboxGrid>
        )}
      </div>
    );
  }
}

export default observer(AssignStaffToUser);
