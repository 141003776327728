import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import moment from "moment";
import singleStaffHRStore from "./SingleStaffHRStore";

const instance = axios.create({ baseURL: baseURL });

class HRStore {
  constructor() {
    this.staff = [];
    this.editstaff = [];
    this.staffless = [];
    this.holiday = [];
    this.showrejected = false;
    this.editholiday = [];
    this.hrdoc = [];
    this.salaries = [];
    this.staffsalary = [];
    this.filtdates = {
      startsalary: moment().startOf("year"),
      endsalary: moment().startOf("month"),
    };
    this.iloading = { single: false, salary: false };
    this.iloaded = { single: false, salary: false };
    this.loading = false;
    this.dataloaded = false;
    this.allstaffloaded = false;
    this.holidayloaded = false;
    this.hrdocloaded = false;
    this.hrdoccancloaded = false;
    this.salariesloaded = false;
    this.allpayments = [];
    this.singlestaff = {};
    this.singleedit = {};

    makeObservable(this, {
      staff: observable,
      editstaff: observable,
      filtdates: observable,
      salaries: observable,
      holiday: observable,
      showrejected: observable,
      editholiday: observable,
      hrdoc: observable,
      staffless: observable,
      iloading: observable,
      iloaded: observable,
      loading: observable,
      dataloaded: observable,
      allstaffloaded: observable,
      holidayloaded: observable,
      hrdocloaded: observable,
      hrdoccancloaded: observable,
      salariesloaded: observable,
      allpayments: observable,
      singlestaff: observable,
      singleedit: observable,
      staffsalary: observable,
      sickhols: computed,
      staffdocs: computed,
      eqdocs: computed,
      filtsalaries: computed,
      allhols: computed,
      holallowances: computed,
      allholidays: computed,
    });
  }

  // FETCH
  getSingleHRStaff(id, override) {
    if (!override) {
      if (this.iloading.single || this.iloaded.single) return null;
    }
    this.iloading.single = true;

    return instance
      .get(`/hr/staff/singlestaff/${id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staff) => {
        this.singlestaff = staff;
        this.singleedit = staff;
        this.iloading.single = false;
        this.iloaded.single = true;
        this.getStaffSalary(true);
        console.log("Single Staff Fetched");
      })
      .catch((err) => {
        this.iloading.single = false;
        this.iloaded.single = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getStaffSalary(override) {
    if (!override) {
      if (this.iloading.salary || this.iloaded.salary) return null;
    }
    this.iloading.salary = true;

    return instance
      .get(`/hr/salary/singlestaff/${this.singlestaff._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staffsalary) => {
        this.staffsalary = staffsalary;
        this.iloading.salary = false;
        this.iloaded.salary = true;
        console.log("Single Staff Salary Fetched");
      })
      .catch((err) => {
        this.iloading.salary = false;
        this.iloaded.salary = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getHRStaffs(overide) {
    if (!overide) {
      if (this.loading || this.dataloaded) return null;
    }
    this.loading = true;

    return instance
      .get(`/hr/staff/currentstaff`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staff) => {
        this.allstaffloaded = false;
        this.staff = staff;
        this.editstaff = [...staff];
        this.loading = false;
        this.dataloaded = true;
        console.log("All Staff Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getAllStaff(overide) {
    if (!overide) {
      if (this.loading || this.dataloaded) return null;
    }
    this.loading = true;

    return instance
      .get(`/hr/staff/allstaff`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staff) => {
        this.staff = staff;
        this.editstaff = [...staff];
        this.loading = false;
        this.dataloaded = true;
        this.allstaffloaded = true;
        console.log("All Staff Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getHolidays(overide) {
    if (!overide) {
      if (this.holidayloaded) return null;
    }
    this.loading = true;
    let data = {
      startdate: moment(this.filtdates.startsalary).startOf("month"),
      enddate: moment(this.filtdates.endsalary)
        .add(1, "month")
        .startOf("month"),
    };

    return instance
      .put(`/hr/holiday/all`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((holiday) => {
        let hol = [...holiday];
        this.showrejected = false;
        this.holiday = hol;
        this.editholiday = [...hol];
        this.loading = false;
        this.holidayloaded = true;
        console.log("All Holidays Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }
  getHRDocs(overide) {
    if (!overide) {
      if (this.hrdocloaded) return null;
    }
    this.loading = true;
    this.hrdoccancloaded = false;
    return instance
      .get(`/hr/hrdoc/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((hrdoc) => {
        let hol = [...hrdoc];
        for (let i = 0; i < hol.length; i++) {
          if (hol[i].type === "Staff") {
            hol[i].title = `${hol[i].staff.name}: ${hol[i].name}`;
          } else {
            hol[i].title = `${hol[i].type}: ${hol[i].name}`;
          }
        }
        this.hrdoc = hol;
        this.edithrdoc = [...hol];
        this.loading = false;
        this.hrdocloaded = true;
        console.log("All HR Docs Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching HR Docs.`,
        });
      });
  }
  getHRDocsCanc(overide) {
    if (!overide) {
      if (this.hrdoccancloaded) return null;
    }
    this.loading = true;
    return instance
      .get(`/hr/hrdoc/allinactive`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((hrdoc) => {
        let hol = [...hrdoc];
        for (let i = 0; i < hol.length; i++) {
          if (hol[i].type === "Staff") {
            hol[i].title = `${hol[i].staff.name}: ${hol[i].name}`;
          } else {
            hol[i].title = `${hol[i].type}: ${hol[i].name}`;
          }
          this.hrdoc.push(hol[i]);
        }
        this.loading = false;
        this.hrdoccancloaded = true;
        console.log("Inactive HR Docs Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching HR Docs.`,
        });
      });
  }
  getSalaries(overide) {
    if (!overide) {
      if (this.salariesloaded) return null;
    }
    this.loading = true;
    let data = {
      start: this.filtdates.startsalary,
      end: moment(this.filtdates.startsalary).subtract(11, "months"),
    };
    return instance
      .get(`/hr/staff/salaries`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((salary) => {
        this.salaries = salary[0];
        this.allpayments = salary[1];

        this.loading = false;
        this.salariesloaded = true;
        console.log("Salaries Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Salaries.`,
        });
      });
  }
  // FETCH
  getStaffless() {
    return instance
      .get(`/auth/basic/staffless`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((staff) => {
        this.staffless = [];
        for (let i = 0; i < staff.length; i++) {
          if (staff[i].staff) {
            if (staff[i].staff.user === singleStaffHRStore.singlestaff._id) {
              this.staffless = [...this.staffless, staff[i]];
            }
          } else {
            this.staffless = [...this.staffless, staff[i]];
          }
        }

        console.log("Staffless Users Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  get staffdocs() {
    return this.hrdoc.filter((hr) => hr.type === "Staff");
  }
  get eqdocs() {
    return this.hrdoc.filter((hr) => hr.type === "Equipment");
  }

  get filtsalaries() {
    return this.allpayments.filter(
      (pay) =>
        (moment(pay.salarymonth) <= moment(this.filtdates.endsalary)) &
        (moment(pay.salarymonth) >= moment(this.filtdates.startsalary))
    );
  }

  get allholidays() {
    let holidays = this.holiday;
    if (!this.showrejected) {
      holidays = holidays.filter((hol) => hol.status !== "Reject");
    }
    return holidays;
  }
  get allhols() {
    return this.allholidays.filter(
      (hol) => (hol.type !== "Monthly Allowance") & (hol.type !== "Sick Leave")
    );
  }
  get sickhols() {
    return this.allholidays.filter((hol) => hol.type === "Sick Leave");
  }
  get holallowances() {
    return this.allholidays.filter((hol) => hol.type === "Monthly Allowance");
  }

  // FUNCTIONS:
  // ----- DATE ----

  changeShowRej() {
    this.showrejected = !this.showrejected;
  }

  changeFiltDate(start, end, type) {
    if (type === "Salary") {
      this.filtdates.startsalary = start;
      this.filtdates.endsalary = end;
    }
  }

  eEditStaff(val, label, i) {
    hrStore.editstaff[i][label] = val;
  }
  eMainStaff(val, label, i) {
    hrStore.staff[i][label] = val;
  }
  eEditSingle(val, label) {
    hrStore.singleedit[label] = val;
  }
  eMainSingle(val, label) {
    hrStore.singlestaff[label] = val;
  }

  startLoading() {
    this.loading = true;
  }
}

const hrStore = new HRStore();
export default hrStore;
