import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../../Components/Dividers/Div14.js";
import MainACCInvoice from "../../../../../../../Components/PDFs/ACCInvoice/MainACCInvoice.js";

import accInvoiceStore from "../../../../../../../Stores/Financial/ACCInvoiceStore.js";

class Step4 extends Component {
  render() {
    return (
      <div>
        <Div14>Step 4: Finalize Invoice</Div14>
        <MainACCInvoice inv={accInvoiceStore.newaccinvoice} />
      </div>
    );
  }
}

export default observer(Step4);
