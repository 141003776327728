import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import carStore from "../AdditionalStores/CarStore";

const instance = axios.create({ baseURL: baseURL });

class DeliveryTrackingStore {
  constructor() {
    this.delid = 1;
    this.del = { _id: null };
    this.account = {};
    this.delCont = {};
    this.loading = false;
    this.dataloaded = false;

    makeObservable(this, {
      delid: observable,
      del: observable,
      account: observable,
      delCont: observable,
      loading: observable,
      dataloaded: observable,
    });
  }

  // FETCH
  getDelivery(delid, override) {
    if (!this.loading) {
      if (delid !== this.delid || override) {
        this.delid = delid;
        this.loading = true;
        return instance
          .get(`/track/delivery/get/byid/${delid}`)
          .then((res) => res.data)
          .then((del) => {
            this.del = del;
            if (del.status === "In Transit") {
              if (del.trucktrack) {
                if (del.trucktrack.name !== "No Tracking") {
                  carStore.selectCar(del.trucktrack.truckapi);
                }
              }
            }
            this.loading = false;
            this.dataloaded = true;
            console.log("Delivery Found");
          })
          .catch((err) => {
            this.del = { _id: null };
            this.loading = false;
            console.log(err);
            console.error(err);
            Notification["error"]({
              title: `Error Fetching Delivery.`,
            });
          });
      }
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  startLoading() {
    this.loading = true;
  }
  endLoading() {
    this.loading = false;
  }
}

const deliveryTrackingStore = new DeliveryTrackingStore();
export default deliveryTrackingStore;
