import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import DatePickerSTD from "../../../../../../../Components/Inputs/STD/DatePickerSTD";
import SelectInputSTD from "../../../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../../../Components/Inputs/STD/TextInputSTD";
import aimInvoiceStore from "../../../../../../../Stores/Financial/AIMInvoiceStore";

class Step1 extends Component {
  render() {
    let inv = aimInvoiceStore.newaiminvoice;

    return (
      <div>
        <Div14>Step 1: Main Info</Div14>
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <SelectInputSTD
              title="Status"
              val={inv.status}
              label="status"
              size="large"
              opts={["Pending", "Paid", "Cancelled"]}
              editVal={aimInvoiceStore.editInvoice}
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <TextInputSTD
              title="Title"
              val={inv.title}
              label="title"
              editVal={aimInvoiceStore.editInvoice}
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <DatePickerSTD
              title="Invoice Date"
              editVal={aimInvoiceStore.editInvoice}
              val={inv.date}
              label="date"
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <TextAreaInputSTD
              title="Inv Remarks"
              val={inv.remarks}
              label="remarks"
              editVal={aimInvoiceStore.editInvoice}
            />
          </Col>
        </FlexboxGrid>
        <Div14>Bank Information:</Div14>
        <FlexboxGrid>
          <Col2>
            <IconButton
              icon={
                <Icon
                  icon={inv.showBank === "KFH" ? "check-circle" : "circle"}
                />
              }
              appearance={inv.showBank === "KFH" ? "primary" : "ghost"}
              block
              onClick={() => aimInvoiceStore.editInvoice("KFH", "showBank")}
            >
              KFH Information
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              icon={
                <Icon
                  icon={inv.showBank === "NBK" ? "check-circle" : "circle"}
                />
              }
              appearance={inv.showBank === "NBK" ? "primary" : "ghost"}
              block
              onClick={() => aimInvoiceStore.editInvoice("NBK", "showBank")}
            >
              NBK Information
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(Step1);
