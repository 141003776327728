import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import Div14 from "../../../Components/Dividers/Div14";
import { Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";

import { withRouter } from "react-router";
import accountStore from "../../../Stores/AccountUpdated/General/AccountStore";
import { Tabs } from "antd";
import Col4 from "../../../Components/Columns/Col4";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";

const { TabPane } = Tabs;

class AccountClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobs: null,
      loading: false,
      clientname: "",
      clientid: "",
      tab: "Active",
    };
    this.selectAccount = this.selectAccount.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }
  componentDidMount() {
    accountStore.getAccounts();
  }
  changeVal(label, val) {
    this.setState({ [label]: val });
  }
  selectAccount(show, clientname, clientid) {
    this.setState({
      showJobs: show,
      clientname: clientname,
      clientid: clientid,
    });
  }

  render() {
    let data = accountStore.accounts;
    if (this.state.tab === "Pending") data = accountStore.pending;
    if (this.state.tab === "Expired") data = accountStore.expired;

    let tableTitle = "Account Clients";
    let columnnames = [
      {
        title: "ID",
        field: "clientNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Name", field: "name", removable: true },
      { title: "Type", field: "type", removable: true },
      { title: "Email", field: "email", removable: true },
      {
        title: "Phone",
        field: "phone",
        removable: true,
        render: (rowData) => {
          return (
            <p>
              {rowData.phone2 === ""
                ? rowData.phone
                : rowData.phone + " / " + rowData.phone2}
            </p>
          );
        },
      },
      { title: "Status", field: "status", removable: true },
      {
        title: "Del",
        field: "adel",
        removable: true,
        render: (rowData) => {
          let check = rowData.adel;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "STO",
        field: "ast",
        removable: true,
        render: (rowData) => {
          let check = rowData.ast;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "CBM",
        field: "totalcbm",
        defaultSort: "desc",
        render: (rowData) => {
          return PRKD(rowData.totalcbm);
        },
      },
      { title: "QTY", field: "totalqty" },
      {
        title: "Last Upd.",
        field: "whupdatedate",
        render: (rowData) => moment(rowData.whupdatedate).fromNow(),
      },
      {
        title: "AIM",
        field: "aim",
        removable: true,
        render: (rowData) => {
          let check = rowData.aim;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "ACC",
        field: "acc",
        removable: true,
        render: (rowData) => {
          let check = rowData.acc;
          return (
            <Icon
              icon={check ? "check" : "dash"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
    ];
    let tabledata = (
      <>
        <FlexboxGrid justify="end" style={{ paddingRight: "5%" }}>
          <Tooltip title={"Add New Client"} arrow placement="left">
            <IconButton
              color="green"
              circle
              icon={<Icon icon="plus" />}
              onClick={() => this.props.history.push("/accounts/new")}
            />
          </Tooltip>
          <span style={{ width: 10 }} />
          <Tooltip title={"Refresh List"} arrow placement="bottom">
            <IconButton
              loading={accountStore.loading.accounts}
              onClick={() => {
                if (
                  this.state.tab === "Pending" ||
                  this.state.tab === "Expired"
                ) {
                  accountStore.getCustom(this.state.tab, true);
                } else {
                  accountStore.getAccounts(true);
                }
              }}
              icon={<Icon icon="refresh" />}
              circle
              block
            />
          </Tooltip>
        </FlexboxGrid>
        <MaterialTable
          isLoading={accountStore.loading.accounts}
          title={data.length + " " + tableTitle}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : tableTitle
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: "business",
              tooltip: "Open Client",
              onClick: (event, rowData) =>
                this.props.history.push(`/ac/dashboard/${rowData._id}`),
            },
          ]}
        />
      </>
    );
    let loaded = true;
    if (this.state.tab === "Pending") loaded = accountStore.loaded.pending;
    if (this.state.tab === "Expired") loaded = accountStore.loaded.expired;

    if (!loaded) {
      tabledata = (
        <FlexboxGrid justify="center">
          <Col4>
            <IconButton
              icon={<Icon icon="reload" />}
              loading={accountStore.loading.accounts}
              onClick={() => accountStore.getCustom(this.state.tab, true)}
              block
              color="green"
            >
              Load {this.state.tab} Accounts
            </IconButton>
          </Col4>
        </FlexboxGrid>
      );
    }

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <Div14>Account Clients:</Div14>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={"Active"}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Active"} key={"Active"}></TabPane>
          <TabPane tab={"Pending"} key={"Pending"}></TabPane>
          <TabPane tab={"Expired"} key={"Expired"}></TabPane>
        </Tabs>

        {tabledata}
      </div>
    );
  }
}

export default withRouter(observer(AccountClients));
