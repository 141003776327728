import React, { Component } from "react";
import { Chip } from "@material-ui/core";
import { observer } from "mobx-react";
import axios from "axios";

import AddIcon from "@material-ui/icons/Add";
import {
  FlexboxGrid,
  Col,
  Button,
  HelpBlock,
  Input,
  Notification,
} from "rsuite";
import { message, Modal } from "antd";
import baseURL from "../../../../../Static/baseURL/baseURL";
import newACCOrderStore from "../../../../../Stores/AccountUpdated/ACC/NewACCStore";
import userStore from "../../../../../Stores/UserStore";
const instance = axios.create({ baseURL: baseURL });

class InvoiceTAs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modaldelete: false,
      modaladd: false,
      loadinvta: false,
      newinvta: "",
    };
    this.hideModal = this.hideModal.bind(this);
    this.editnewinvta = this.editnewinvta.bind(this);
    this.deleteINVTA = this.deleteINVTA.bind(this);
    this.postNewINVTA = this.postNewINVTA.bind(this);
    this.updateShipment = this.updateShipment.bind(this);
  }
  hideModal() {
    this.setState({ modaldelete: false, modaladd: false, newinvta: "" });
  }

  editnewinvta(val) {
    this.setState({ newinvta: val });
  }

  postNewINVTA = async (invoicetas) => {
    let data = [...invoicetas, this.state.newinvta];
    this.updateShipment(data);
  };
  deleteINVTA(invoicetas) {
    this.updateShipment("invoicetas");
  }

  updateShipment = async (finalinvtas) => {
    let data = { invoicetas: finalinvtas };
    this.setState({ loading: true });
    await instance
      .put(`ac/accorder/edit/${this.props.accorderid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Updated Invoice TAs)`);

        newACCOrderStore.getSingleACOrder(false, true);

        this.setState({ loading: false });
        this.hideModal();
      })
      .catch((err) => {
        this.setState({ loading: false });

        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = !newACCOrderStore.stopedit;
    let load = this.state.loading;
    let invoicetas = newACCOrderStore.newacc.invoicetas;

    let errinvta = { val: false, message: "" };
    let indexval = invoicetas.findIndex((inv) => inv === this.state.newinvta);

    let qualitytext;

    if (this.state.newinvta === "") {
      errinvta = {
        val: true,
        message: "Please write in an Invoice TA number.",
      };
    } else if (indexval >= 0) {
      errinvta = {
        val: true,
        message: `${this.state.newinvta} Already Exists.`,
      };
    }
    if (load) {
      errinvta = { val: false, message: "" };
    }

    let invrows;
    if (editview) {
      invrows = invoicetas.map((inv, index) => (
        <Chip
          label={inv}
          key={index}
          onDelete={() => this.setState({ modaldelete: true, newinvta: inv })}
          variant="outlined"
        />
      ));
    } else {
      invrows = invoicetas.map((inv, index) => (
        <Chip label={inv} key={index} variant="outlined" />
      ));
    }

    return (
      <div>
        <Modal
          title="Delete Invoice TA Number"
          open={this.state.modaldelete}
          footer={null}
          onCancel={this.hideModal}
        >
          <p>
            Are you sure you want to delete Invoice TA #{this.state.newinvta}?
          </p>
          <hr />
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                disabled={load}
                appearance="ghost"
                color="green"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="red"
                loading={load}
                onClick={() => this.deleteINVTA(invoicetas)}
              >
                Yes, Delete
              </Button>
            </Col>
          </FlexboxGrid>
        </Modal>
        <Modal
          title="Add Invoice TA"
          open={this.state.modaladd}
          footer={null}
          onCancel={this.hideModal}
        >
          <HelpBlock>New Invoice TA Number:</HelpBlock>
          <Input
            value={this.state.newinvta}
            onChange={(e) => this.editnewinvta(e)}
            style={{ border: errinvta.val && "1px dashed red" }}
          />
          <p style={{ fontSize: 10 }}>
            Existing Invoice TAs: <i>{invoicetas.join(", ")}</i>
          </p>
          {errinvta.val && (
            <HelpBlock style={{ color: "red" }}>{errinvta.message}</HelpBlock>
          )}
          <hr />
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                disabled={load}
                appearance="ghost"
                color="red"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                disabled={errinvta.val}
                color="green"
                loading={load}
                onClick={() => this.postNewINVTA(invoicetas)}
              >
                Create
              </Button>
            </Col>
          </FlexboxGrid>
        </Modal>

        <FlexboxGrid
          align="middle"
          justify="center"
          style={{ paddingLeft: "1%", paddingRight: "1%" }}
        >
          {editview && (
            <Chip
              label={"New"}
              icon={<AddIcon style={{ color: "white" }} />}
              size="small"
              style={{
                backgroundColor: "green",
                color: "white",
                boxShadow: "0px 0px 3px black",
              }}
              onClick={() => this.setState({ modaladd: true, newinta: "" })}
            />
          )}
          <span style={{ width: 5 }} />
          <h6>
            <strong>
              {invoicetas.length > 0 ? "Invoice TAs: " : "No Invoice TAs."}
            </strong>
          </h6>
          {qualitytext}
          <span style={{ width: 5 }} />
          {invrows}
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(InvoiceTAs);
