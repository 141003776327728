let cancreasonlist = [
  "",
  "Price Too High",
  "Client Unreachable",
  "Date NA",
  "Not Interested",
  "Service Not Available",
  "Too Long to Price",
  "Other",
];
export default cancreasonlist;
