import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import AuthMain from "./AuthMain";
import { Button } from "rsuite";
import PasswordInput from "../../../Components/Inputs/Auth/PasswordInput";
import UserNameInput from "../../../Components/Inputs/Auth/UserNameInput";
import userStore from "../../../Stores/UserStore";

import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      username: "",
      password: "",
      passconf: "",
      email: "",
      try: false,
      client: { name: "", clientNumId: "" },
      comprequest: false,
      compadmin: false,
      expired: false,
      missing: false,
      loading: false,
      linkused: false,
      token: "",
    };
    this.changeView = this.changeView.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    return instance
      .get(`/auth/referral/singlerefferal/${this.props.match.params.tokenid}`)
      .then((res) => res.data)
      .then((data) => {
        this.setState({
          token: data.token,
          linkused: data.linkused,
          expired: data.expired,
          client: data.client,
          loading: false,
        });
        console.log("Referral Link Fetched");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  changeView(type, val) {
    if (type === "username") {
      // val = val.replace(/[^a-zA-Z1-9]/g, "").toLowerCase();
      val = val.toLowerCase();
    }
    this.setState({ [type]: val });
  }

  render() {
    let emailerror = { val: false, message: "" };
    let passerror = { val: false, message: "" };
    let passconferr = { val: false, message: "" };
    let disabledButton = false;

    emailerror.val = !validateEmail(this.state.email);
    if (emailerror.val) emailerror.message = "Please enter a Valid Email.";

    if (this.state.password.length < 8) passerror.val = true;
    if (passerror.val) passerror.message = "Password must +8 Characters.";

    if (this.state.password !== this.state.passconf) passconferr.val = true;
    if (passconferr.val) passconferr.message = "Passwords do not match.";

    let user = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      passconf: this.state.passconf,
    };

    return (
      <AuthMain
        type="signup"
        token={this.props.match.params.tokenid}
        client={this.state.client}
        linkused={this.state.linkused}
        expired={this.state.expired}
      >
        <h4 style={{ textAlign: "center" }}> Create a New Account </h4>
        <hr />
        <UserNameInput
          data={user.email}
          err={this.state.try && emailerror.val}
          errmessage={this.state.try && emailerror.message}
          type={"email"}
          label="Email"
          disabled={userStore.loading}
          autoFocus={false}
          action={this.changeView}
        />
        <PasswordInput
          data={user.password}
          err={this.state.try && passerror.val}
          errmessage={this.state.try && passerror.message}
          type={"password"}
          label="Password"
          disabled={userStore.loading}
          loading={userStore.loading}
          autoFocus={false}
          action={this.changeView}
        />
        <PasswordInput
          data={user.passconf}
          err={this.state.try && passconferr.val}
          errmessage={this.state.try && passconferr.message}
          type={"passconf"}
          label="Confirm Password"
          disabled={userStore.loading}
          loading={userStore.loading}
          autoFocus={false}
          action={this.changeView}
        />
        <br />
        <Button
          block
          color="green"
          disabled={disabledButton}
          style={{ boxShadow: "0px 0px 3px black" }}
          onClick={() => {
            if (!this.state.try) this.setState({ try: true });
            let validation = true;
            if (this.emailerror || this.passerror || this.passconferr) {
              validation = false;
            }

            let userData = {
              username: user.email,
              email: user.email,
              password: user.password,
              validation: validation,
              group: "Company",
            };

            userStore.registerNewUser(userData);
          }}
        >
          {userStore.loading ? "Loading ..." : "Sign Up"}
        </Button>
      </AuthMain>
    );
  }
}

export default withRouter(observer(SignUpPage));
