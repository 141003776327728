import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid } from "rsuite";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import Col4 from "../../../../../../../../Components/Columns/Col4";
import { Statistic } from "antd";
import acStorStatStore from "../../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";

class StatisticsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let output = acStorStatStore.stats;
    return (
      <div>
        <Div14>Statistics Main</Div14>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Total CBM"
                value={output.cbm}
                precision={3}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Total Items"
                value={output.items}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Total Quantity"
                value={output.currentplusreserve}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="On Reserve"
                value={output.onreserve}
                precision={0}
                valueStyle={{ color: "red" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(StatisticsHeader));
