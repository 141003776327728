import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Div14 from "../../../Components/Dividers/Div14";
import whmCompanyStore from "../WHMStores/WHMCompanyStore";
import Col2 from "../../../Components/Columns/Col2";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import WHMSingleCompanyPallet from "./WHMSingleCompanyPallet";
import LoadBack from "../../../Components/Loaders/LoadBack";
import WHMSingleCompanyItemTable from "./WHMSingleCompanyItemTable";
import WHMSingleCompanyItemTablePalletized from "./WHMSingleCompanyItemTablePalletized";

class WHMSingleCompanyMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Pallets", lock: false };
    this.LockTabs = this.LockTabs.bind(this);
  }
  LockTabs(val) {
    this.setState({ lock: val });
  }

  componentDidMount() {
    whmCompanyStore.getSingleCompany(this.props.match.params.companyid);
  }

  render() {
    let comp = whmCompanyStore.company;
    return (
      <LoadBack loading={whmCompanyStore.loading.company}>
        <FlexboxGrid justify="space-between" style={{ margin: "1%" }}>
          <IconButton
            icon={<Icon icon="back-arrow" />}
            onClick={() => this.props.history.push("/whm/companies")}
            size="xs"
          >
            Full Company List
          </IconButton>

          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() =>
              whmCompanyStore.getSingleCompany(
                this.props.match.params.companyid,
                true
              )
            }
            size="sm"
            color="orange"
          >
            Refresh
          </IconButton>
        </FlexboxGrid>
        <br />
        <Div14>{comp.name}</Div14>
        <FlexboxGrid>
          <Col2>
            <h6>Total Items: {comp.totalqty}</h6>
          </Col2>
        </FlexboxGrid>
        <hr />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          centered
          type="card"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Pallets"} key={"Pallets"} disabled={this.state.lock}>
            <WHMSingleCompanyPallet LockTab={this.LockTabs} />
          </TabPane>
          <TabPane
            tab={"Unpalletized Items"}
            key={"Unpalletized Items"}
            disabled={this.state.lock}
          >
            <WHMSingleCompanyItemTable />
          </TabPane>
          <TabPane
            tab={"Palletized Items"}
            key={"Palletized Items"}
            disabled={this.state.lock}
          >
            <WHMSingleCompanyItemTablePalletized />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMSingleCompanyMain));
