import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class TLStore {
  constructor() {
    this.storage = [];
    this.delivery = [];
    this.filter = "delivery";
    this.loading = { jobs: false };
    this.loaded = { jobs: false };

    makeObservable(this, {
      storage: observable,
      delivery: observable,
      loading: observable,
      loaded: observable,
      filter: observable,
      jobs: computed,
      jobtype: computed,
    });
  }

  // FETCH
  getTLJobs(override) {
    let loaded = false;
    if (this.loading.jobs || this.loaded.jobs) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.jobs = true;
      this.loaded.jobs = false;

      return instance
        .get(`/tl/accounts/delsandsto`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.delivery = data.delivery;
          this.loading.jobs = false;
          this.loaded.jobs = true;

          console.log("TL Jobs Fetched");
        })
        .catch((err) => {
          this.loading.jobs = false;
          this.loaded.jobs = false;

          console.log(err);

          Notification["error"]({
            title: `Error Services.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get jobs() {
    return this.delivery;
  }
  get jobtype() {
    if (this.filter === "delivery") return "Delivery";
    return "";
  }
  // FUNCTIONS:

  changeFilter(type) {
    this.filter = type;
  }
}

const tlStore = new TLStore();
export default tlStore;
