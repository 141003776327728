import { observer } from "mobx-react";
import React, { Component } from "react";
import { Tag, Badge } from "antd";
import { Descriptions } from "antd";

import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import SingleLocationTable from "./SingleLocationTable";

class LocationSummaryTable extends Component {
  render() {
    let del = this.props.del;

    let from = del.fromaddress;
    let tos = del.toaddress;

    return (
      <div>
        <Descriptions title={"Pickup Address"} bordered size="small">
          <Descriptions.Item label="Status">
            <Tag
              color={from.status === "Completed" ? "green" : "orange"}
              style={{ fontWeight: "bold" }}
            >
              <Badge
                status={from.status === "Completed" ? "default" : "processing"}
                style={{
                  color: from.status === "Completed" ? "green" : "orange",
                }}
                color={from.status === "Completed" ? "green" : "orange"}
                text={from.status}
              />
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Name">{from.name}</Descriptions.Item>
          <Descriptions.Item label="Phone">{from.phone}</Descriptions.Item>
          <Descriptions.Item label="City">{from.city}</Descriptions.Item>
          <Descriptions.Item label="Type">{from.type}</Descriptions.Item>
          <Descriptions.Item label="Floor">{from.floor}</Descriptions.Item>
          <Descriptions.Item label="Address" span={24}>
            {from.address}
          </Descriptions.Item>
          {from.ngfchg > 0 && (
            <Descriptions.Item label="Non-Ground Floor Charge">
              {PRKD(from.ngfchg)} KD
            </Descriptions.Item>
          )}
          {from.zonecost > 0 && (
            <Descriptions.Item label={`Zone ${from.zonetype}`}>
              {PRKD(from.zonecost)} KD
            </Descriptions.Item>
          )}
        </Descriptions>
        <hr />

        {tos.length > 0 && (
          <>
            {tos.map((to, index) => (
              <SingleLocationTable to={to} key={index} del={del} />
            ))}
          </>
        )}

        <hr />
      </div>
    );
  }
}

export default observer(LocationSummaryTable);
