import { Col, message, Tooltip } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Notification, FlexboxGrid, IconButton, ButtonGroup } from "rsuite";
import moment from "moment";
import { InputGroup, Input, Icon, HelpBlock } from "rsuite";

import AttachmentModal from "../AttachmentModal";

import axios from "axios";

import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";

const instance = axios.create({ baseURL: baseURL });

class AddAttTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      src: null,
      newtitle: "",
      index: -1,
    };
    this.hideModal = this.hideModal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.resetPage = this.resetPage.bind(this);
    this.changeDelete = this.changeDelete.bind(this);
  }

  resetPage() {
    this.setState({ loading: true });
    this.setState({ loading: false });
  }

  editVal(val) {
    this.setState({ newtitle: val });
  }

  hideModal() {
    this.setState({ show: false, src: null });
  }

  changeDelete(val) {
    this.setState({ delete: val });
  }

  deleteDocument = async () => {
    let ind = this.props.ind;
    let attInd = this.props.attInd;
    let sj = sjStore.sjs[ind];
    let attachment = sj.attachments[attInd];

    this.setState({ loading: true });
    Notification["info"]({ title: "Deleting.. Please Wait" });

    let data = {
      attId: attachment._id,
      type: sj.jobtype,
      subJobId: sj._id,
    };

    await instance
      .put(`media/sidejob/delete`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.removeList("attachments", attInd, ind);
        Notification.close();
        message.success("Document Deleted");
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({ title: `An error occured` });
      });
  };

  updateTitle = async (title) => {
    let attInd = this.props.attInd;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let attachment = sj.attachments[attInd];

    this.setState({ loading: true });

    let data = { title: title };

    await instance
      .put(`media/attachment/edit/${attachment._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.changeItem("attachments", "title", title, attInd, ind);

        message.success("Title Updated");
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let attInd = this.props.attInd;
    let sj = sjStore.sjs[ind];
    let esj = sjStore.esjs[ind];

    let att = esj.attachments[attInd];

    if (!att) {
      return <div />;
    }

    let changed = false;
    if (esj.attachments[attInd].title) {
      if (sj.attachments[attInd].title !== esj.attachments[attInd].title) {
        changed = true;
      }
    }

    if (att.type !== "Additional") {
      return <div />;
    }

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;

    if (quality) {
      if (esj.attachments[attInd].title === "Additional") {
        flag = true;
        qualitytext = `Must add a Title to the document.`;
      }
    }

    return (
      <div>
        <AttachmentModal
          hideModal={this.hideModal}
          changeDelete={this.changeDelete}
          deleteDocument={this.deleteDocument}
          show={this.state.show}
          attachment={att}
          deleteactive={this.state.delete}
          editview={editview}
        />

        <FlexboxGrid
          justify="space-between"
          align="top"
          style={{
            borderRadius: 5,
            boxShadow: "0px 0px 1px black",
            border: "1px solid black",
          }}
        >
          <Col md={6} sm={8} xs={24}>
            <ButtonGroup block>
              <IconButton
                icon={<Icon icon="file-text-o" />}
                size="md"
                onClick={() => {
                  this.setState({ show: true });
                }}
              >
                <HelpBlock style={{ fontSize: 10 }}>
                  Att #{att.attachmentNumId} - {att.title}
                  <br />(<i>{moment(att.createdAt).fromNow()}</i>)
                </HelpBlock>
              </IconButton>
            </ButtonGroup>
          </Col>
          <Col md={18} sm={16} xs={24}>
            <InputGroup>
              {changed && (
                <InputGroup.Button
                  loading={this.state.loading}
                  onClick={() => {
                    sjStore.editItem(
                      "attachments",
                      "title",
                      sj.attachments[attInd].title,
                      attInd,
                      ind
                    );
                  }}
                >
                  <Icon icon="refresh" />
                </InputGroup.Button>
              )}
              <Tooltip title={qualitytext}>
                <InputGroup.Addon
                  style={{ backgroundColor: flag && "#ff968c" }}
                >
                  Title
                </InputGroup.Addon>
              </Tooltip>
              <Input
                size="sm"
                disabled={false}
                value={esj.attachments[attInd].title}
                onChange={(e) => {
                  if (editview) {
                    sjStore.editItem("attachments", "title", e, attInd, ind);
                  }
                }}
              />
              {changed && (
                <InputGroup.Button
                  color="green"
                  loading={this.state.loading}
                  onClick={() =>
                    this.updateTitle(esj.attachments[attInd].title)
                  }
                >
                  <Icon icon="check" />
                </InputGroup.Button>
              )}
            </InputGroup>
            {changed && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from <i>"{sj.attachments[attInd].title}"</i>
              </HelpBlock>
            )}
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(AddAttTemplate));
