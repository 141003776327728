import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol15white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol10white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol15blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol10blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol45blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
});

class DeliveryCharges extends Component {
  render() {
    let inv = this.props.inv;

    let allcharges = inv.charges.filter((chg) => chg.acorders.length > 0);

    let orders = [];
    if (allcharges.length > 0) {
      for (let i = 0; i < allcharges.length; i++) {
        for (let j = 0; j < allcharges[i].acorders.length; j++) {
          orders = [...orders, allcharges[i].acorders[j]];
        }
      }
    }

    let charges = orders.map((chg, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol10white}>{chg.acOrderNumId}</Text>
        <Text style={styles.tableCol15white}>
          {moment(chg.jobdate).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol10white}>{chg.jobtype}</Text>
        <Text style={styles.tableCol10white}>{PRKD(chg.cbm)}</Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.delcharge) > 0 ? PRKD(chg.delcharge) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.percbmchg) > 0 ? PRKD(chg.percbmchg) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.addchg) > 0 ? PRKD(chg.addchg) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(chg.discount) > 0 ? -1 * PRKD(chg.discount) : "-"}
        </Text>
        <Text style={styles.tableCol15white}>
          {PRKD(chg.totaldelcharge) > 0 ? PRKD(chg.totaldelcharge) : "-"}
        </Text>
      </View>
    ));

    let totaldel = 0;
    let totalpcbm = 0;
    let totaladd = 0;
    let totaldisc = 0;
    let totalfinal = 0;

    for (let i = 0; i < orders.length; i++) {
      totaldel += Parsed(orders[i].delcharge);
      totalpcbm += Parsed(orders[i].percbmchg);
      totaladd += Parsed(orders[i].addchg);
      totaldisc += Parsed(orders[i].discount);
      totalfinal += Parsed(orders[i].totaldelcharge);
    }

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>Detailed Delivery Charges</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol10blue}>Order ID</Text>
          <Text style={styles.tableCol15blue}>Date</Text>
          <Text style={styles.tableCol10blue}>Type</Text>
          <Text style={styles.tableCol10blue}>CBM</Text>
          <Text style={styles.tableCol10blue}>Delivery Charge KD</Text>
          <Text style={styles.tableCol10blue}>Per CBM Charge KD</Text>
          <Text style={styles.tableCol10blue}>Addit. Charges KD</Text>
          <Text style={styles.tableCol10blue}>Discounts KD</Text>
          <Text style={styles.tableCol15blue}>Total Delivery Charge KD</Text>
        </View>
        {charges}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol45blue}></Text>
          <Text style={styles.tableCol10blue}>{PRKD(totaldel)}</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totalpcbm)}</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totaladd)}</Text>
          <Text style={styles.tableCol10blue}>{-1 * PRKD(totaldisc)}</Text>
          <Text style={styles.tableCol15blue}>{PRKD(totalfinal)}</Text>
        </View>
      </View>
    );
  }
}

export default observer(DeliveryCharges);
