import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

class HRWhite extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <hr style={{ border: "1px solid white" }} />;
  }
}

export default withRouter(observer(HRWhite));
