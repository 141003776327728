import React, { Component } from "react";
import axios from "axios";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import DatePickerSTD from "../../../../../Components/Inputs/STD/DatePickerSTD";
import moment from "moment";
import userStore from "../../../../../Stores/UserStore";

import baseURL from "../../../../../Static/baseURL/baseURL";
import { Empty, message } from "antd";
import LoadBack from "../../../../../Components/Loaders/LoadBack";
import MaterialTable from "material-table";
import Div14 from "../../../../../Components/Dividers/Div14";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import singleStaffHRStore from "../../../../../Stores/HR/SingleStaffHRStore";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";

const instance = axios.create({ baseURL: baseURL });

class StaffSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "New",
      loading: false,
      showdelete: false,
      show: false,
      basic: 0,
      _id: "",
      food: 0,
      accomodation: 0,
      transporation: 0,
      startdate: new Date(),
    };
    this.hideModal = this.hideModal.bind(this);
    this.changeST = this.changeST.bind(this);
    this.newSalary = this.newSalary.bind(this);
    this.deleteSalary = this.deleteSalary.bind(this);
  }
  componentDidMount() {
    singleStaffHRStore.getStaffSalary();
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  deleteSalary = async () => {
    this.setState({ loading: true });

    let url = `hr/salary/delete/${this.state._id}`;
    await instance
      .put(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.getSingleHRStaff(
          singleStaffHRStore.singlestaff._id,
          true
        );
        this.hideModal();

        message.success(`Salary Deleted`);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  newSalary = async () => {
    let salary = {
      basic: this.state.basic,
      food: this.state.food,
      accomodation: this.state.accomodation,
      transporation: this.state.transporation,
      startdate: moment(this.state.startdate).format("DD-MMM-YYYY"),
      staff: singleStaffHRStore.singlestaff._id,
    };

    this.setState({ loading: true });

    let url = `hr/salary/new`;
    if (this.state.type === "New") {
      await instance
        .post(url, salary, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          singleStaffHRStore.getSingleHRStaff(
            singleStaffHRStore.singlestaff._id,
            true
          );
          this.hideModal();

          message.success(`New Salary Created`);
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({ title: `An error occured` });
        });
    } else {
      url = `hr/salary/edit/${this.state._id}`;
      await instance
        .put(url, salary, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          singleStaffHRStore.getSingleHRStaff(
            singleStaffHRStore.singlestaff._id,
            true
          );
          this.hideModal();

          message.success(`Salary Updated`);
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]({ title: `An error occured` });
        });
    }
  };
  hideModal() {
    this.setState({
      type: "New",
      loading: false,
      showdelete: false,
      show: false,
      basic: 0,
      food: 0,
      accomodation: 0,
      transporation: 0,
      startdate: new Date(),
      _id: "",
    });
  }

  render() {
    let columnnames = [
      {
        title: "Start Date",
        field: "startdate",
        defaultSort: "desc",
        render: (row) => moment(row.startdate).format("DD-MMM-YY"),
      },
      { title: "Basic", field: "basic" },
      { title: "Food", field: "food" },
      { title: "Accomodation", field: "accomodation" },
      { title: "Transporation", field: "transporation" },
      { title: "Total", field: "total" },
    ];

    let currentsalary = null;
    if (singleStaffHRStore.staffsalary.length > 0)
      currentsalary = singleStaffHRStore.staffsalary[0];

    return (
      <LoadBack loading={singleStaffHRStore.loading.salary}>
        <UnifiedModal
          show={this.state.showdelete}
          title={`Delete Salary`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.hideModal()}
              oC2={() => this.deleteSalary()}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          Are you sure you want to delete this Salary?
          <Div14>Salary Details</Div14>
          <p>
            <b>Basic: </b>
            {this.state.basic} KD
          </p>
          <p>
            <b>Food: </b>
            {this.state.food} KD
          </p>
          <p>
            <b>Accomodation: </b>
            {this.state.accomodation} KD
          </p>
          <p>
            <b>Transporation: </b>
            {this.state.transporation} KD
          </p>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.show}
          title={
            this.state.type === "New"
              ? `Create a New Salary`
              : `Update Salary Details`
          }
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Save"]}
              oC1={() => this.hideModal()}
              oC2={() => this.newSalary()}
              disabled2={this.state.basic === 0}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <FlexboxGrid>
            <Col2>
              <NumbInputSTD
                val={this.state.basic}
                label="basic"
                title="Basic Salary"
                disabled={this.state.loading}
                editVal={this.changeST}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                val={this.state.food}
                label="food"
                title="Food"
                disabled={this.state.loading}
                editVal={this.changeST}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                val={this.state.accomodation}
                label="accomodation"
                title="Accomodation"
                disabled={this.state.loading}
                editVal={this.changeST}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                val={this.state.transporation}
                label="transporation"
                title="Transportation"
                disabled={this.state.loading}
                editVal={this.changeST}
              />
              <br />
            </Col2>
            <br />
            <DatePickerSTD
              title={"Active From"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={this.state.startdate}
              size="large"
              editVal={this.changeST}
            />
          </FlexboxGrid>
        </UnifiedModal>
        <div
          style={{
            boxShadow: "0px 0px 5px black",
            margin: "2%",
            padding: "2%",
            borderRadius: "5px",
          }}
        >
          <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
            Current Salary
          </h3>
          {currentsalary ? (
            <FlexboxGrid justify="center">
              <Col2>
                <Div14>Summary:</Div14>
                <p style={{ fontSize: 22 }}>
                  <b>Since: </b>
                  {moment(currentsalary.startdate).format("DD-MMM-YY")} (
                  {moment(currentsalary.startdate).fromNow()})
                  <br />
                  <b>Total: </b>
                  {PRKD(currentsalary.total)} KD
                </p>
              </Col2>
              <Col2>
                <Div14>Breakdown:</Div14>
                <p style={{ fontSize: 14 }}>
                  <b>Basic: </b>
                  {PRKD(currentsalary.basic)} KD
                  <br />
                  <b>Food: </b>
                  {PRKD(currentsalary.food)} KD
                  <br />
                  <b>Accomodation: </b>
                  {PRKD(currentsalary.accomodation)} KD
                  <br />
                  <b>Transporation: </b>
                  {PRKD(currentsalary.transporation)} KD
                </p>
              </Col2>
            </FlexboxGrid>
          ) : (
            <Empty description="No Salary Record" />
          )}
        </div>
        <Div14>Past Salaries</Div14>
        <MaterialTable
          title={singleStaffHRStore.staffsalary.length + " Salary"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={singleStaffHRStore.staffsalary}
          onRowClick={(event, rowData) => {
            this.setState({ ind: rowData.tableData.id, single: true });
          }}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle
                ? this.props.exportTitle
                : "Staff Salary List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: "add",
              isFreeAction: true,
              tooltip: "Add",
              onClick: (event, rowData) => {
                this.setState({ show: true });
              },
            },
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.setState({
                  show: true,
                  type: "Edit",
                  basic: rowData.basic,
                  food: rowData.food,
                  accomodation: rowData.accomodation,
                  transporation: rowData.transporation,
                  startdate: rowData.startdate,
                  _id: rowData._id,
                });
              },
            },
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                this.setState({
                  showdelete: true,
                  basic: rowData.basic,
                  _id: rowData._id,
                  food: rowData.food,
                  accomodation: rowData.accomodation,
                  transporation: rowData.transporation,
                });
              },
            },

            {
              icon: "refresh",
              isFreeAction: true,
              tooltip: "Refresh",
              onClick: (event, rowData) => {
                singleStaffHRStore.getStaffSalary(true);
              },
            },
          ]}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(StaffSalary));
