import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class NewACCOrderStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.newacc = {
      status: "Requested",
      shippingmode: "Sea",
      shippingline: "",
      blnumber: "",
      brandsupplier: "",
      commodity: "",
      originlocation: "",
      container: "Other",
      containercount: 0,
      weight: 0,
      packages: 0,
      vesselarrival: moment().add(7, "days"),
      epa: false,
      epadate: undefined,
      pai: false,
      paidate: undefined,
      invoicetas: [],
      doccol: false,
      doccoldate: undefined,
      bayanissue: false,
      bayanissuedate: undefined,
      cleardone: false,
      cleardonedate: undefined,
      remarks: "",
      incservices: [],
      excservices: [],
      attachments: [],
      items: [],
      scac: "",
      invnotes: "",
      cbm: 0,
      lastprice: 0,
    };
    this.oldacc = {
      status: "Requested",
      shippingmode: "",
      shippingline: "",
      blnumber: "",
      brandsupplier: "",
      commodity: "",
      originlocation: "",
      container: "Other",
      containercount: 0,
      weight: 0,
      packages: 0,
      vesselarrival: undefined,
      epa: false,
      epadate: undefined,
      pai: false,
      paidate: undefined,
      invoicetas: [],
      doccol: false,
      doccoldate: undefined,
      bayanissue: false,
      bayanissuedate: undefined,
      cleardone: false,
      cleardonedate: undefined,
      remarks: "",
      incservices: [],
      excservices: [],
      attachments: [],
      items: [],
      scac: "",
      invnotes: "",
      cbm: 0,
      lastprice: 0,
    };

    this.loading = {
      account: false,
      newacc: false,
    };
    this.loaded = { items: false };

    makeObservable(this, {
      account: observable,
      newacc: observable,
      oldacc: observable,
      loading: observable,
      loaded: observable,
      stopedit: computed,
    });
  }

  // FETCH
  getAccount(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.account = true;

      return instance
        .get(`/ac/account/getaccount/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((account) => {
          this.account = account;
          this.loading.account = false;
          console.log("Account Fetched");
        })
        .catch((err) => {
          this.loading.account = false;
          this.account = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getSingleACOrder(aimorderid, override) {
    let loaded = false;
    let aimId = this.newacc._id;
    if (aimorderid) aimId = aimorderid;
    if (aimId === this.newacc._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.newacc = true;

      return instance
        .get(`/ac/accorder/get/byid/${aimId}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.client;
          this.newacc = { ...order };

          this.oldacc = { ...order };

          this.loading.newacc = false;
          console.log("Single ACC Order Fetched");
        })
        .catch((err) => {
          this.loading.newdel = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching ACC Order.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get stopedit() {
    if (!userStore.user.staffaccount) {
      return true;
    }
    return false;
  }

  removeList(label, index) {
    let oldList = [...newACCOrderStore.oldacc[label]];
    oldList.splice(index, 1);
    newACCOrderStore.newacc[label] = [...oldList];
    newACCOrderStore.oldacc[label] = [...oldList];
  }
  newList(label, item) {
    newACCOrderStore.newacc[label] = [
      ...newACCOrderStore.newacc[label],
      { ...item },
    ];
    newACCOrderStore.oldacc[label] = [
      ...newACCOrderStore.oldacc[label],
      { ...item },
    ];
    newACCOrderStore.getSingleACOrder(false, true);
  }
  editList(label, index, item) {
    newACCOrderStore.newacc[label][index] = { ...item };
    newACCOrderStore.oldacc[label][index] = { ...item };
  }
  editListItem(label, index, itemlabel, itemval) {
    newACCOrderStore.newacc[label][index][itemlabel] = itemval;
    newACCOrderStore.oldacc[label][index][itemlabel] = itemval;
  }
  editAttachmentTitle(index, val) {
    newACCOrderStore.newacc.attachments[index].title = val;
  }
  bulkAddList(label, items) {
    let oldList = [...newACCOrderStore.oldacc[label]];
    for (let j = 0; j < items.length; j++) {
      oldList = [...oldList, { ...items[j] }];
    }
    newACCOrderStore.newacc[label] = [...oldList];
    newACCOrderStore.oldacc[label] = [...oldList];
  }

  // ------- SERVICES ------
  selectService(type, action, ser) {
    let incsjs = [...this.newacc.incservices];
    let excsjs = [...this.newacc.excservices];

    let serIndex = -1;
    if (type === "Include") {
      if (action === "add") {
        incsjs.push(ser);
        let excIndex = excsjs.findIndex((exc) => exc === ser);
        if (excIndex > -1) {
          excsjs.splice(excIndex, 1);
        }
      } else {
        serIndex = incsjs.findIndex((inc) => inc === ser);
        incsjs.splice(serIndex, 1);
      }
    } else {
      if (action === "add") {
        excsjs.push(ser);
        let incIndex = incsjs.findIndex((inc) => inc === ser);
        if (incIndex > -1) {
          incsjs.splice(incIndex, 1);
        }
      } else {
        serIndex = excsjs.findIndex((inc) => inc === ser);
        excsjs.splice(serIndex, 1);
      }
    }

    this.newacc.excservices = [...excsjs];
    this.newacc.incservices = [...incsjs];
  }

  replaceServices() {
    this.oldacc.incservices = [...this.newacc.incservices];
    this.oldacc.excservices = [...this.newacc.excservices];
  }
  resetServices() {
    this.newacc.incservices = [...this.oldacc.incservices];
    this.newacc.excservices = [...this.oldacc.excservices];
  }

  editDiscount(val, label, index) {
    newACCOrderStore.newacc.discount[index][label] = val;
  }
  editAddCharge(val, label, index) {
    newACCOrderStore.newacc.addcharge[index][label] = val;
  }

  removeAddCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newACCOrderStore.newacc[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newACCOrderStore.newacc[label][i]];
      }
    }
    newACCOrderStore.newacc[label] = [...newchgs];
  }
  removeOrgCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newACCOrderStore.oldacc[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newACCOrderStore.oldacc[label][i]];
      }
    }
    newACCOrderStore.oldacc[label] = [...newchgs];
  }
  updateOrgAddChgNew(charge, label) {
    newACCOrderStore.oldacc[label] = [
      ...newACCOrderStore.oldacc[label],
      charge,
    ];
    newACCOrderStore.newacc[label] = [
      ...newACCOrderStore.newacc[label],
      charge,
    ];
  }
  updateOrgAddChg(val, label) {
    newACCOrderStore.oldacc[label] = val;
  }

  editOrder(val, label) {
    newACCOrderStore.newacc[label] = val;
  }

  updateOrgOrder(label) {
    let newacc = { ...newACCOrderStore.newacc };
    newACCOrderStore.oldacc[label] = newacc[label];
  }

  // ------- Items ------
  addItemToOrder(item) {
    newACCOrderStore.newacc.items = [...newACCOrderStore.newacc.items, item];
  }
  editItemToOrder(item, itemindex) {
    newACCOrderStore.newacc.items[itemindex] = { ...item };
  }
  removeItemFromOrder(itemindex) {
    let finalitems = [];
    let items = newACCOrderStore.newacc.items;

    for (let i = 0; i < items.length; i++) {
      if (i !== itemindex) {
        finalitems = [...finalitems, { ...items[i] }];
      }
    }
    newACCOrderStore.newacc.items = [...finalitems];
  }

  createNewOrder() {
    this.loaded.items = false;

    this.newacc = {
      status: "Requested",
      shippingmode: "Sea",
      shippingline: "",
      blnumber: "",
      brandsupplier: "",
      commodity: "",
      originlocation: "",
      container: "Other",
      containercount: 0,
      weight: 0,
      packages: 0,
      vesselarrival: moment().add(7, "days"),
      epa: false,
      epadate: undefined,
      pai: false,
      paidate: undefined,
      invoicetas: [],
      doccol: false,
      doccoldate: undefined,
      bayanissue: false,
      bayanissuedate: undefined,
      cleardone: false,
      cleardonedate: undefined,
      remarks: "",
      incservices: [],
      excservices: [],
      items: [],
      scac: "",
      invnotes: "",
      cbm: 0,
      lastprice: 0,
    };
  }

  startLoading() {
    this.loading = true;
  }
}

const newACCOrderStore = new NewACCOrderStore();
export default newACCOrderStore;
