import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class HRAttendanceStore {
  constructor() {
    this.attendance = [];
    this.singleattendance = {
      signInTime: "",
      signOutTime: "",
      status: "",
      remarks: "",
      staffid: "",
      staffname: "",
      date: "",
      _id: "",
    };
    this.esingleatt = {
      signInTime: "",
      signOutTime: "",
      status: "",
      remarks: "",
      staffid: "",
      staffname: "",
      date: "",
      _id: "",
    };
    this.date = moment().format("MMM-YYYY");
    this.edate = moment().format("MMM-YYYY");
    this.systemmonths = [];
    this.loading = { attendance: false };
    this.loaded = { attendance: false };

    makeObservable(this, {
      date: observable,
      edate: observable,
      attendance: observable,
      singleattendance: observable,
      esingleatt: observable,
      loading: observable,
      loaded: observable,
      systemmonths: observable,
      dateList: computed,
      worktime: computed,
      lockedMonth: computed,
      lockedApprovalMonth: computed,
      lockstatus: computed,
    });
  }

  // FETCH
  getStaffAttendance(override) {
    if (!override) {
      if (this.loading.attendance || this.loaded.attendance) return null;
    }
    this.loading.attendance = true;

    return instance
      .put(
        `/hr/attendanceinout/staffmonthlyattendance`,
        { date: this.date },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => res.data)
      .then((staff) => {
        this.attendance = staff.attendance;
        this.systemmonths = staff.lockedmonths;

        this.loading.attendance = false;
        this.loaded.attendance = true;

        console.log("Staff Attendance Fetched");
      })
      .catch((err) => {
        this.loading.attendance = false;
        this.loaded.attendance = false;

        console.log(err);

        Notification["error"]({
          title: `Error Fetching Staff.`,
        });
      });
  }

  /// Computations
  get dateList() {
    let start = moment.utc(this.date).startOf("month");
    let end = moment.utc(this.date).endOf("month");
    let daysdiff = moment(end).diff(start, "days") + 1;
    let output = [];

    for (let i = 0; i < daysdiff; i++) {
      output = [...output, moment.utc(this.date).add(i, "days").format("DD")];
    }
    return output.sort((a, b) => a - b);
  }
  get worktime() {
    let att = hrAttendanceStore.esingleatt;

    let output = "";
    if (att.signOutTime) {
      if (att.signInTime) {
        if (`${moment(att.signInTime).format("H:mm")}` === "0:00") {
          output = "No Sign In";
        } else if (`${moment(att.signOutTime).format("H:mm")}` === "0:00") {
          output = "No Sign Out";
        } else {
          output = moment
            .utc(
              moment
                .duration(att.signOutTime.diff(moment.utc(att.signInTime)))
                .asMilliseconds()
            )
            .format("HH:mm");
        }
      }
    }
    return output;
  }
  get lockedMonth() {
    let locked = false;
    if (hrAttendanceStore.systemmonths.length) {
      for (let i = 0; i < hrAttendanceStore.systemmonths.length; i++) {
        if (
          moment(hrAttendanceStore.systemmonths[i].month).format("MMM-YY") ===
          moment(hrAttendanceStore.date).format("MMM-YY")
        ) {
          if (hrAttendanceStore.systemmonths[i].status !== "Open") {
            locked = true;
          }
        }
      }
    }

    return locked;
  }
  get lockedApprovalMonth() {
    let locked = false;
    if (!hrAttendanceStore.lockedMonth) locked = true;
    if (hrAttendanceStore.systemmonths.length) {
      for (let i = 0; i < hrAttendanceStore.systemmonths.length; i++) {
        if (
          moment(hrAttendanceStore.systemmonths[i].month).format("MMM-YY") ===
          moment(hrAttendanceStore.date).format("MMM-YY")
        ) {
          if (hrAttendanceStore.systemmonths[i].status !== "Pending Approval") {
            locked = true;
          }
        }
      }
    }

    return locked;
  }
  get lockstatus() {
    let status = "Open";
    if (hrAttendanceStore.systemmonths.length) {
      for (let i = 0; i < hrAttendanceStore.systemmonths.length; i++) {
        if (
          moment(hrAttendanceStore.systemmonths[i].month).format("MMM-YY") ===
          moment(hrAttendanceStore.date).format("MMM-YY")
        ) {
          status = hrAttendanceStore.systemmonths[i].status;
        }
      }
    }

    return status;
  }

  // Functions:
  selectAttendance(data, staffname, colindex, staffid) {
    hrAttendanceStore.singleattendance = {
      _id: data._id,
      status: data.status,
      date: data.date,
      staffname: staffname,
      staffid: staffid,
      // date: moment(hrAttendanceStore.date).add(colindex, "day"),
    };

    if (hrAttendanceStore.singleattendance.status === "Empty")
      hrAttendanceStore.singleattendance.status = "";
    if (data.signInTime.length > 0) {
      hrAttendanceStore.singleattendance.signInTime = moment
        .utc(hrAttendanceStore.singleattendance.date)
        .startOf("day")
        .add(data.signInTime.split(":")[0], "hours")
        .add(data.signInTime.slice(-2), "minutes");
    } else {
      hrAttendanceStore.singleattendance.signInTime = moment(
        hrAttendanceStore.date
      ).startOf("day");
    }
    if (data.signOutTime.length > 0) {
      hrAttendanceStore.singleattendance.signOutTime = moment
        .utc(hrAttendanceStore.singleattendance.date)
        .startOf("day")
        .add(data.signOutTime.split(":")[0], "hours")
        .add(data.signOutTime.slice(-2), "minutes");
    } else {
      hrAttendanceStore.singleattendance.signOutTime = moment(
        hrAttendanceStore.date
      ).startOf("day");
    }
    hrAttendanceStore.esingleatt = { ...hrAttendanceStore.singleattendance };
  }
  editSingleAttendance(val, label) {
    //if (!hrAttendanceStore.lockedMonth) {
    hrAttendanceStore.esingleatt[label] = val;
    //}
  }

  changeDate(val) {
    hrAttendanceStore.edate = val;
  }
  changeOrgDate(val) {
    hrAttendanceStore.date = val;
    this.getStaffAttendance(true);
  }
}

const hrAttendanceStore = new HRAttendanceStore();
export default hrAttendanceStore;
