import React, { Component } from "react";
import { InputGroup, Input, Icon, Divider } from "rsuite";

class TextInputINPB extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;
    let label = this.props.label;
    let val = this.props.val;
    let size = "lg";
    if (this.props.size) size = this.props.size;
    let index = this.props.index;
    let butdisable = this.props.butdisable;
    let butcolor = "green";
    if (this.props.butcolor) butcolor = this.props.butcolor;
    let buticon = "check";
    if (this.props.buticon) buticon = this.props.buticon;
    let ind = this.props.ind;
    let load = this.props.load;
    let buttext;
    if (this.props.buttext)
      buttext = (
        <>
          <Divider vertical />
          {this.props.buttext}
        </>
      );

    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <Input
          size={size}
          value={val}
          disabled={disabled}
          onChange={(e) => this.props.editVal(e, label, index)}
        />
        <InputGroup.Button
          color={butcolor}
          loading={load}
          disabled={butdisable}
          onClick={() => this.props.updateVal(label, title, ind)}
        >
          <Icon icon={buticon} />
          {buttext}
        </InputGroup.Button>
      </InputGroup>
    );
  }
}

export default TextInputINPB;
