import React, { Component } from "react";
import axios from "axios";

import {
  DataSheetGrid,
  floatColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";
import { Notification } from "rsuite";

import "react-datasheet/lib/react-datasheet.css";
import { IconButton, Icon, FlexboxGrid, Col } from "rsuite";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import userStore from "../../../../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class ItemSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      grid: [
        {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
      ],
    };
  }

  postNew = async (grid) => {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    this.setState({ loading: true });

    grid.map((item) => (item.sj = sj._id));

    let data = { grid: grid };

    return instance
      .put(`/additional/item/bulknew`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.bulkAddList("items", res.data, ind);

        this.setState({ loading: false });

        Notification["success"]({
          title: `New Items Created.`,
          description: <div>{grid.length} Items</div>,
        });
        this.cancelChanges();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  cancelChanges() {
    this.setState({
      show: false,
      loading: false,
      grid: [
        {
          item: "",
          cbm: 0,
          quantity: 1,
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          remarks: "",
        },
      ],
    });
  }

  render() {
    const columns = [
      { ...keyColumn("item", textColumn), title: "Item" },
      { ...keyColumn("cbm", floatColumn), title: "CBM" },
      { ...keyColumn("quantity", floatColumn), title: "Qty" },
      { ...keyColumn("length", floatColumn), title: "L (cm)" },
      { ...keyColumn("width", floatColumn), title: "W (cm)" },
      { ...keyColumn("height", floatColumn), title: "H (cm)" },
      { ...keyColumn("weight", floatColumn), title: "KG" },
      { ...keyColumn("remarks", textColumn), title: "Remarks" },
    ];

    let disabled = false;
    let grid = this.state.grid;
    for (let i = 0; i < grid.length; i++) {
      if (grid[i].cbm === null) disabled = true;
      if (grid[i].quantity === null) disabled = true;
      if (grid[i].length === null) disabled = true;
      if (grid[i].width === null) disabled = true;
      if (grid[i].height === null) disabled = true;
      if (grid[i].weight === null) disabled = true;
      if (grid[i].item === null) disabled = true;
      if (grid[i].item === "") disabled = true;
    }

    return (
      <div>
        <FlexboxGrid>
          <Col md={12} sm={12} xs={24}>
            <IconButton
              size="lg"
              block
              loading={this.state.loading}
              color={!this.state.show && "green"}
              icon={
                <Icon icon={this.state.show ? "close" : "plus"} size="lg" />
              }
              onClick={() => {
                if (this.state.show) {
                  this.cancelChanges();
                } else {
                  this.setState({ show: true });
                }
              }}
            >
              {this.state.show ? "Cancel" : "Add New Items"}
            </IconButton>
          </Col>
          <Col md={12} sm={12} xs={24}>
            {this.state.show && (
              <IconButton
                size="lg"
                block
                color="green"
                icon={<Icon icon="save" size="lg" />}
                disabled={disabled}
                loading={this.state.loading}
                onClick={() => this.postNew(this.state.grid)}
              >
                Save and Add
              </IconButton>
            )}
          </Col>
        </FlexboxGrid>
        {this.state.show && (
          <DataSheetGrid
            value={this.state.grid}
            onChange={(e) => {
              this.setState({ grid: e });
            }}
            columns={columns}
            autoAddRow
            style={{ backgroundColor: "red" }}
            createRow={() => ({
              item: "",
              cbm: 0,
              quantity: 1,
              length: 0,
              width: 0,
              height: 0,
              weight: 0,
              remarks: "",
            })}
          />
        )}
      </div>
    );
  }
}

export default ItemSheet;
