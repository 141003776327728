import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Button, FlexboxGrid, Col } from "rsuite";
import HolidayCalendar from "../../../../Components/Calendar/HolidayCalendar";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import hrStore from "../../../../Stores/HR/HRStore";

class AllHolidayCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      holiday: {
        type: "",
        from: moment(),
        to: moment(),
        days: 0,
        staff: { name: "", type: "" },
      },
    };
    this.hideModal = this.hideModal.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
  }
  onSelectEvent(e) {
    this.setState({ show: true, holiday: e });
  }
  hideModal() {
    this.setState({ show: false });
  }

  render() {
    let hol = this.state.holiday;
    let staff = this.state.holiday.staff;
    let data = [
      { title: "Staff", data: staff.name },
      { title: "Role", data: staff.type },
      { title: "Type", data: hol.type },
      { title: "Days", data: hol.days },
      { title: "From", data: moment(hol.from).format("DD-MMM-YY") },
      { title: "To", data: moment(hol.to).format("DD-MMM-YY") },
      { title: "Remarks", data: hol.remarks },
    ];

    let datarows = data.map((dt, index) => (
      <FlexboxGrid key={index}>
        <Col md={6} sm={8} xs={12}>
          <b>{dt.title}: </b>
        </Col>
        <Col md={18} sm={16} xs={12}>
          {dt.data}
        </Col>
      </FlexboxGrid>
    ));
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title={`${hol.type}: ${staff.name}`}
          onHide={this.hideModal}
          footer={
            <Button color="red" onClick={() => this.hideModal()}>
              Close
            </Button>
          }
        >
          {datarows}
        </UnifiedModal>

        <HolidayCalendar
          start={"date"}
          end={"dateto"}
          title={"title"}
          data={hrStore.holiday.filter((hol) => hol.status !== "Reject")}
          onSelectEvent={this.onSelectEvent}
        />
      </div>
    );
  }
}

export default observer(AllHolidayCalendar);
