import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Div14 from "../../../../Components/Dividers/Div14";
import { Descriptions } from "antd";

import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import { Collapse } from "antd";

const { Panel } = Collapse;
class StorageContractInfo extends Component {
  render() {
    let storCont = this.props.storCont;
    return (
      <div>
        <Div14>Storage Contract Info</Div14>
        <Collapse>
          <Panel header="Storage Contract Info">
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Storage Facility" span={3}>
                {storCont && storCont.emlocation.name}
              </Descriptions.Item>
              <Descriptions.Item label="Type">
                {storCont && storCont.maintype}
              </Descriptions.Item>
              <Descriptions.Item label="Method">
                {storCont && storCont.subtype}
              </Descriptions.Item>
              <Descriptions.Item label="Price Per CBM">
                {storCont && PRKD(storCont.pricepercbm)} KD / CBM
              </Descriptions.Item>
              <Descriptions.Item label="Minimum CBM">
                {storCont && PRKD(storCont.mincbm)} CBM
              </Descriptions.Item>
              <Descriptions.Item label="Storage In Charge">
                {storCont && PRKD(storCont.incharge)} KD / CBM
              </Descriptions.Item>
              <Descriptions.Item label="Storage Out Charge">
                {storCont && PRKD(storCont.outcharge)} KD / CBM
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default observer(withRouter(StorageContractInfo));
