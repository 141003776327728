import { message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Notification } from "rsuite";

import axios from "axios";
import AddRow from "./AddRow";
import NewAdd from "./NewAdd";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import userStore from "../../../../../../Stores/UserStore";
import Div14 from "../../../../../../Components/Dividers/Div14";

const instance = axios.create({ baseURL: baseURL });

class AddAttTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      src: null,
      newtitle: "",
    };
    this.hideModal = this.hideModal.bind(this);
    this.changeDelete = this.changeDelete.bind(this);
  }

  hideModal() {
    this.setState({ show: false, src: null });
  }

  changeDelete(val) {
    this.setState({ delete: val });
  }

  deleteDocument = async (attInd) => {
    let attType = this.props.attType;
    let acc = newDeliveryStore.olddel;
    let attachment = acc.attachments[attInd];

    this.setState({ loading: true });
    Notification["info"]({ title: "Deleting.. Please Wait" });

    let data = {
      attId: attachment._id,
    };

    await instance
      .put(`media/sidejob/delete`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.removeList("attachments", attInd);
        Notification.close();
        message.success(`Attachment (${attType}) Deleted`);
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  checkFileType = async (file) => {
    if (file.blobFile.type.substring(0, 3) === "ima") {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(file.blobFile);
    } else {
      this.uploadDocument(file.blobFile);
    }
  };

  uploadDocument = async (file) => {
    let attType = this.props.attType;
    let acorder = newDeliveryStore.olddel;

    this.setState({ loading: true });
    let fd = new FormData();
    fd.append(
      "file",
      file,
      `${attType}_${Math.round(Math.random() * 100)}_${file.name}`
    );
    fd.append("delivery", acorder._id);
    fd.append("type", attType);
    fd.append("title", attType);

    await instance
      .post("media/sidejob/upload/single", fd, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.newList("attachments", res.data);
        this.setState({ loading: false, show: false, src: null });
        message.success(`${this.props.type} Uploaded.`);
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = !newDeliveryStore.stopedit;
    let attType = this.props.attType;
    let acc = newDeliveryStore.olddel;

    let initialatts = acc.attachments;
    let atts = [];
    let allatts = [];

    if (initialatts) {
      if (initialatts.length > 0) {
        allatts = initialatts.filter((att) => att.type === attType);
      }
    }
    if (allatts) {
      if (allatts.length > 0) {
        atts = allatts;
      }
    }

    let uploaded;
    uploaded = acc.attachments.map((att, i) => (
      <AddRow key={i} attInd={i} editview={editview} />
    ));

    return (
      <div>
        <Div14>
          {attType}
          {atts.length > 0 && ` (${atts.length})`}:
        </Div14>
        {editview && <NewAdd attType={attType} />}

        {uploaded}
      </div>
    );
  }
}

export default withRouter(observer(AddAttTemplate));
