import { Alert } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock } from "rsuite";
import Col2 from "../../../../../../../../Components/Columns/Col2";
import Col3 from "../../../../../../../../Components/Columns/Col3";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import NumbInputSTD from "../../../../../../../../Components/Inputs/STD/NumbInputSTD";
import TextInputSTD from "../../../../../../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../../../../../../Components/Modal/ModalFooter";
import UnifiedModal from "../../../../../../../../Components/Modal/UnifiedModal";
import {
  Parsed,
  PRKD,
} from "../../../../../../../../Functions/Pricing/PRKDCalc";
import newStorageStore from "../../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

class NewEditItemModal extends Component {
  render() {
    let item = this.props.item;

    let inout = newStorageStore.delstoinout.inout;

    let status = newStorageStore.oldorder.status;

    let finalqty = 0;
    let currentstock = 0;
    let olddeliverycomment;

    let min = 1;
    let max = 100000;

    if (status === "Completed") {
      if (item.acItemNumId !== "New") {
        if (item.acitem) {
          currentstock = item.acitem.currentplusreserve;
        }
      }

      if (inout === "In") {
        finalqty =
          parseFloat(currentstock) +
          parseFloat(item.actualquantity) -
          parseFloat(item.orgquant);
        min = parseFloat(item.orgquant);
        if (item.acitem) {
          if (item.acitem.currentstock) {
            min =
              parseFloat(item.orgquant) - parseFloat(item.acitem.currentstock);
          }
        }
        if (min < 1) min = 1;
      } else {
        finalqty =
          parseFloat(currentstock) -
          parseFloat(item.actualquantity) +
          parseFloat(item.orgquant);
        max = parseFloat(currentstock) + parseFloat(item.orgquant);
      }
      olddeliverycomment = (
        <>
          <b>Old Delivery Quantity: {item.orgquant}</b>
          <br />
        </>
      );
    } else {
      if (item.acItemNumId !== "New") {
        let toadd = newStorageStore.neworder.toaddress;
        currentstock = item.acitem.currentplusreserve;
        if (inout === "Out") {
          for (let j = 0; j < toadd.length; j++) {
            if (j !== this.props.index) {
              for (let k = 0; k < toadd[j].acitemmove.length; k++) {
                if (
                  toadd[j].acitemmove[k].acItemNumId === item.acitem.acItemNumId
                ) {
                  currentstock -= Parsed(toadd[j].acitemmove[k].actualquantity);
                }
              }
            }
          }
        }
      }

      if (inout === "In") {
        finalqty = parseFloat(currentstock) + parseFloat(item.actualquantity);
      } else {
        max = currentstock;
        finalqty = parseFloat(currentstock) - parseFloat(item.actualquantity);
      }
    }
    if (status === "Cancelled") max = 100000;

    let esSingleCBM =
      (parseFloat(item.itemlength) *
        parseFloat(item.itemwidth) *
        parseFloat(item.itemheight)) /
      1000000;
    let esFinalCBM = parseFloat(esSingleCBM) * parseFloat(item.actualquantity);

    let disablesingle = false;
    if (item.acItemNumId === "New") {
      if (item.itemname === "") disablesingle = true;
      if (item.itemserialnumber === "") disablesingle = true;
      if (item.itemlength === "") disablesingle = true;
      if (item.itemwidth === "") disablesingle = true;
      if (item.itemheight === "") disablesingle = true;
      if (item.actualquantity <= 0) disablesingle = true;
    }

    let disableEdit = item.acItemNumId !== "New";

    return (
      <UnifiedModal
        onHide={this.props.onHide}
        show={this.props.show}
        title={
          this.props.type === "New"
            ? "Add a New Item"
            : `Edit Item ${
                item.acItemNumId === "New"
                  ? "(New)"
                  : ` - Item ID#${item.acItemNumId} (${status})`
              }`
        }
        footer={
          <ModalFooter
            icon={["close", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={[
              "Cancel",
              this.props.type === "New" ? "Add Item" : "Save Changes",
            ]}
            oC1={() => this.props.onHide()}
            oC2={() =>
              this.props.submitAdd(
                this.props.type === "Existing" ? "Existing" : "Single"
              )
            }
            disabled2={disablesingle}
            loading1={this.props.loading}
            loading2={this.props.loading}
          />
        }
      >
        <h5>{item.acItemNumId === "New" ? "New Item" : "Existing Item"}</h5>

        <FlexboxGrid>
          <Col2 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="Quantity"
              val={item.actualquantity}
              label="actualquantity"
              size="large"
              min={min}
              max={max}
              editVal={this.props.editItem}
            />
            <HelpBlock
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Estimated CBM = {PRKD(esFinalCBM)} CBM{" "}
            </HelpBlock>
            <HelpBlock style={{ fontStyle: "italic" }}>
              {PRKD(parseFloat(item.itemlength / 100))} (m) x{" "}
              {PRKD(parseFloat(item.itemwidth / 100))} (m) x{" "}
              {PRKD(parseFloat(item.itemheight / 100))} (m) x{" "}
              {PRKD(parseFloat(item.actualquantity))} quantity
            </HelpBlock>
          </Col2>
          <Col2 style={{ textAlign: "center" }}>
            <p>
              <b>Current Stock: {currentstock}</b>
              <br />
              {olddeliverycomment}
              <b style={{ color: inout === "In" ? "green" : "red" }}>
                {status === "Completed" && "New "}Delivery:{" "}
              </b>
              {inout === "In" ? "+" : "-"} {item.actualquantity}
              <br />
              <b>After Delivery Quantity = {finalqty}</b>
            </p>
          </Col2>
        </FlexboxGrid>
        <div style={{ paddingBottom: "5px" }} />
        <Div14>Item Details:</Div14>
        {disableEdit && (
          <>
            <Alert
              message="Item already exists, Only Quantity can be modified. All other properties can be updated after Job Completion or on the Inventory Page."
              type="warning"
              showIcon
              closable
              style={{ paddingLeft: "2%", paddingRight: "2%" }}
            />
            <br />
          </>
        )}
        <TextInputSTD
          title="Item Name"
          val={item.itemname}
          label="itemname"
          size="large"
          editVal={this.props.editItem}
          disabled={disableEdit}
        />
        <div style={{ paddingBottom: "5px" }} />
        <FlexboxGrid>
          <Col2 style={{ paddingBottom: "5px" }}>
            <TextInputSTD
              title="Serial No."
              val={item.itemserialnumber}
              label="itemserialnumber"
              size="large"
              editVal={this.props.editItem}
              disabled={disableEdit}
            />
          </Col2>
          <Col2 style={{ paddingBottom: "5px" }}>
            <TextInputSTD
              title="Product ID"
              val={item.itemproductid}
              label="itemproductid"
              size="large"
              editVal={this.props.editItem}
              disabled={disableEdit}
            />
            <HelpBlock style={{ textAlign: "center" }}>Optional</HelpBlock>
          </Col2>
        </FlexboxGrid>
        <br />
        <Div14>Item Dimensions:</Div14>

        <FlexboxGrid>
          <Col3 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="L (cm)"
              val={item.itemlength}
              label="itemlength"
              size="lg"
              editVal={this.props.editItem}
              disabled={disableEdit}
            />
            <HelpBlock style={{ textAlign: "center" }}>
              If not known keep at 0 cm
            </HelpBlock>
          </Col3>
          <Col3 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="W (cm)"
              val={item.itemwidth}
              label="itemwidth"
              size="lg"
              editVal={this.props.editItem}
              disabled={disableEdit}
            />
            <HelpBlock style={{ textAlign: "center" }}>
              If not known keep at 0 cm
            </HelpBlock>
          </Col3>
          <Col3 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="H (cm)"
              val={item.itemheight}
              label="itemheight"
              size="lg"
              editVal={this.props.editItem}
              disabled={disableEdit}
            />
            <HelpBlock style={{ textAlign: "center" }}>
              If not known keep at 0 cm
            </HelpBlock>
          </Col3>
        </FlexboxGrid>
      </UnifiedModal>
    );
  }
}

export default observer(NewEditItemModal);
