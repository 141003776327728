import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Loader } from "rsuite";
import completeDeliveryStore from "../../../../../Stores/Account/CompleteDeliveryStore";
import MaterialTable from "material-table";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class Page0 extends Component {
  render() {
    let data = this.props.data;
    let title = this.props.title;
    let oldstock = false;
    if (title === "Old Stock") {
      oldstock = true;
    }
    let content;
    let cds = completeDeliveryStore;
    if (cds.loading.current || cds.loading.oldstock) {
      return <Loader center />;
    }
    if (data.length === 0) {
      content = <Empty description="No Items Available" />;
    } else {
      content = (
        <MaterialTable
          title="Items List"
          columns={[
            { title: "ID", field: "acItemNumId" },
            { title: "Item", field: "name" },
            { title: "Serial No.", field: "serialnumber" },
            { title: "Product ID", field: "productid" },
            { title: "Stock", field: "currentstock", hidden: oldstock },
            {
              title: "LxWxH (cm)",
              field: "length",
              render: (rowData) => {
                return `${parseFloat(rowData.length)} x
                  ${parseFloat(rowData.width)} x
                  ${parseFloat(rowData.height)}
                  `;
              },
            },
            {
              title: "CBM",
              field: "height",
              render: (rowData) => {
                let dimensions =
                  parseFloat(rowData.length) *
                  parseFloat(rowData.width) *
                  parseFloat(rowData.height);
                let final = 0;
                if (dimensions > 0) {
                  final = PRKD(dimensions / 1000000);
                }
                return final;
              },
            },
          ]}
          data={data}
          isLoading={this.props.loading}
          onRowClick={async (event, rowData, togglePanel) =>
            this.props.selectItem(rowData)
          }
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
            tableLayout: "auto",
            exportButton: false,
            padding: "dense",
            loadingType: "overlay",
            maxBodyHeight: 500,
          }}
        />
      );
    }
    return <div>{content}</div>;
  }
}

export default observer(Page0);
