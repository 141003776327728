import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Col, InputGroup } from "rsuite";
import { Input } from "rsuite";
import { InputNumber, Select } from "antd";

import Col3 from "../../../../../../../Components/Columns/Col3";
import packinglist from "../../../../../../../Static/Lists/packinglist";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../../../../Components/Columns/Col2";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";

const { Option } = Select;

class NewItemModal extends Component {
  render() {
    let disabled = this.props.disabled;
    let type = this.props.type;
    let edited = this.props.edited;
    let selected = this.props.selected;
    let loading = this.props.loading;

    let fastfillOptions = packinglist.map((item, index) => (
      <Option value={index} key={index}>
        {item.name}
      </Option>
    ));

    let footer = (
      <div>
        <FlexboxGrid>
          <Col3>
            <IconButton
              icon={<Icon icon="close" />}
              color="red"
              block
              onClick={() => this.props.showHideModal(false)}
              disabled={loading}
            >
              Cancel
            </IconButton>
          </Col3>
          <Col3>
            <IconButton
              icon={<Icon icon="plus" />}
              color="green"
              block
              disabled={disabled}
              loading={loading}
              onClick={() => {
                if (type === "New") {
                  this.props.postNew(edited);
                } else if (type === "Edit") {
                  this.props.postEdit(edited);
                }
              }}
            >
              {type === "Edit" ? "Save Changes" : "Create Item"}
            </IconButton>
          </Col3>
        </FlexboxGrid>
      </div>
    );

    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.showHideModal}
        footer={footer}
      >
        <h5>{type} Item Details</h5>
        <InputGroup>
          <InputGroup.Addon>Fast Fill</InputGroup.Addon>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select an Option"
            optionFilterProp="children"
            onChange={(e) => this.props.fastFill(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fastfillOptions}
          </Select>
        </InputGroup>
        <hr />
        <InputGroup>
          <InputGroup.Addon>Item</InputGroup.Addon>
          <Input
            size="lg"
            disabled={loading}
            value={edited.item}
            onChange={(e) => this.props.changeVal("item", e)}
          />
          {edited.item !== selected.item && (
            <InputGroup.Addon>
              <Icon icon="check-circle" style={{ color: "green" }} />
            </InputGroup.Addon>
          )}
        </InputGroup>
        <br />
        <FlexboxGrid>
          <Col md={6} sm={12} xs={24}>
            <InputGroup>
              <InputGroup.Addon>L (cm)</InputGroup.Addon>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={0}
                disabled={loading}
                value={edited.length}
                onChange={(e) => this.props.changeVal("length", e)}
              />
              {edited.length !== selected.length && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <InputGroup>
              <InputGroup.Addon>W (cm)</InputGroup.Addon>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={0}
                disabled={loading}
                value={edited.width}
                onChange={(e) => this.props.changeVal("width", e)}
              />
              {edited.width !== selected.width && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <InputGroup>
              <InputGroup.Addon>H (cm)</InputGroup.Addon>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={0}
                disabled={loading}
                value={edited.height}
                onChange={(e) => this.props.changeVal("height", e)}
              />
              {edited.height !== selected.height && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <InputGroup>
              <InputGroup.Addon>KG</InputGroup.Addon>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={0}
                disabled={loading}
                value={edited.weight}
                onChange={(e) => this.props.changeVal("weight", e)}
              />
              {edited.weight !== selected.weight && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </Col>
        </FlexboxGrid>
        <br />
        <h6>
          Calculated CBM ={" "}
          {PRKD(
            (edited.length / 100) * (edited.width / 100) * (edited.height / 100)
          )}
        </h6>
        <FlexboxGrid>
          <Col2>
            <InputGroup>
              <InputGroup.Addon>CBM</InputGroup.Addon>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={0}
                disabled={loading}
                value={edited.cbm}
                onChange={(e) => this.props.changeVal("cbm", e)}
              />
              {edited.cbm !== selected.cbm && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </Col2>
          <Col2>
            <InputGroup>
              <InputGroup.Addon>Quantity</InputGroup.Addon>
              <InputNumber
                size="large"
                style={{ width: "100%" }}
                min={1}
                disabled={loading}
                value={edited.quantity}
                onChange={(e) => this.props.changeVal("quantity", e)}
              />
              {edited.quantity !== selected.quantity && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </Col2>
        </FlexboxGrid>
        <br />
        <InputGroup>
          <InputGroup.Addon>Remarks</InputGroup.Addon>
          <Input
            size="lg"
            disabled={loading}
            value={edited.remarks}
            onChange={(e) => this.props.changeVal("remarks", e)}
          />
          {edited.remarks !== selected.remarks && (
            <InputGroup.Addon>
              <Icon icon="check-circle" style={{ color: "green" }} />
            </InputGroup.Addon>
          )}
        </InputGroup>
      </UnifiedModal>
    );
  }
}

export default observer(NewItemModal);
