import { message, Statistic } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, IconButton, Notification } from "rsuite";
import Div14 from "../../../../../Components/Dividers/Div14";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import userStore from "../../../../../Stores/UserStore";
import DeliveryTimeRequestTable from "../../Dashboard/NewStorage/Steps/Supporting/DeliveryTimeRequestTable";
import { DatePicker } from "antd";
import { TRC } from "../../../../../Functions/Timing/TimingRulesCalc";
import { InputGroup, Icon } from "rsuite";
import { TimingCheck } from "../../../../../Functions/Timing/TimingCheck";
import Col2 from "../../../../../Components/Columns/Col2";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import { Parsed } from "../../../../../Functions/Pricing/PRKDCalc";
import NumbInputSTU from "../../../../../Components/Inputs/Updatable/NumbInputSTU";

const instance = axios.create({ baseURL: baseURL });

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

class Timing extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, load: { stincost: false } };
    this.postJobDate = this.postJobDate.bind(this);
    this.changeFee = this.changeFee.bind(this);
  }
  changeFee = async (fee) => {
    let delCont = newStorageStore.delCont;
    let expfee = delCont.expressfee;
    let affee = delCont.afterhourfee;
    let bothfee = Parsed(affee) + Parsed(expfee);
    let services = { ...newStorageStore.neworder.services };
    let timetype = services.timetype;
    let expact =
      timetype === "Express + After Hour Delivery" ||
      timetype === "Express Delivery";
    let afact =
      timetype === "Express + After Hour Delivery" ||
      timetype === "After Hour Delivery";

    services.timetype = "";
    services.timeprice = 0;

    if (fee === "Express") {
      if (expact) {
        if (afact) {
          services.timetype = "After Hour Delivery";
          services.timeprice = affee;
        }
      } else {
        if (afact) {
          services.timetype = "Express + After Hour Delivery";
          services.timeprice = bothfee;
        } else {
          services.timetype = "Express Delivery";
          services.timeprice = expfee;
        }
      }
    } else {
      if (afact) {
        if (expact) {
          services.timetype = "Express Delivery";
          services.timeprice = expfee;
        }
      } else {
        if (expact) {
          services.timetype = "Express + After Hour Delivery";
          services.timeprice = bothfee;
        } else {
          services.timetype = "After Hour Delivery";
          services.timeprice = affee;
        }
      }
    }
    let data = { services: services };

    this.setState({ loading: true });
    await instance
      .put(`ac/acorder/edit/${newStorageStore.oldorder._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Time Fees Updated`);

        newStorageStore.editOrder(services, "services");
        newStorageStore.updateOrgOrder("services");

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  updateService = async (label) => {
    let data = {
      label: label,
      val: parseFloat(newStorageStore.neworder.services[label]),
    };

    this.setState({ load: { [label]: true } });

    await instance
      .put(`ac/acorder/editservice/${newStorageStore.oldorder._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Updated`);

        newStorageStore.editService(data.val, data.label);
        newStorageStore.updateOrgService(data.label);

        this.setState({ load: { [label]: false } });
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };
  postJobDate = async () => {
    let data = { jobdate: newStorageStore.neworder.jobdate };
    let services = { ...newStorageStore.neworder.services };
    let ppt = newStorageStore.delCont.pricepertrip;
    let day = moment(newStorageStore.neworder.jobdate).day();

    let alldays = [
      { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
      { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
      { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
      { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
      { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
      { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
      { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
    ];
    if (
      newStorageStore.neworder.jobtype === "Delivery In" ||
      newStorageStore.neworder.jobtype === "Delivery Out"
    ) {
      if (alldays[day].price > 0) {
        services.weekday = alldays[day].title;
        services.weekdayprice = alldays[day].price;
      }
    }
    data.services = { ...services };

    this.setState({ loading: true });
    await instance
      .put(`ac/acorder/edit/${newStorageStore.oldorder._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Job Date Updated`);
        newStorageStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  disabledDate(current) {
    let delCont = newStorageStore.delCont;
    let hrs = delCont.excludedhours;

    let exdays = [];
    let afdays = [];
    let range = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    for (let i = 0; i < range.length; i++) {
      if (hrs[range[i]] === "No") exdays = [...exdays, i];
      if (hrs[range[i]] === "After") afdays = [...afdays, i];
    }

    let excluded = false;

    for (let i = 0; i < exdays.length; i++) {
      if (moment(current).day() === exdays[i]) excluded = true;
    }

    let earliest = TRC(delCont, true, true, newStorageStore.neworder.createdAt);

    return current < earliest || excluded;
  }
  disabledTime(current) {
    let min1 = range(1, 15);
    let min2 = range(16, 30);
    let min3 = range(31, 45);
    let min4 = range(46, 60);
    let minutes = [...min1, ...min2, ...min3, ...min4];

    let delCont = newStorageStore.delCont;
    let hrs = delCont.excludedhours;
    let exhrs = [];
    let afhrs = [];
    for (let i = 0; i < 24; i++) {
      if (hrs[`${i}`] === "No") exhrs = [...exhrs, i];
      if (hrs[`${i}`] === "After") afhrs = [...afhrs, i];
    }

    return {
      disabledHours: () => exhrs,
      disabledMinutes: () => minutes,
    };
  }
  render() {
    let newsto = newStorageStore.neworder;
    let oldsto = newStorageStore.oldorder;
    let delCont = newStorageStore.delCont;

    let load = this.props.load;

    let staffActions;
    if (userStore.user.staffaccount) {
      let changed =
        moment(newsto.jobdate).format("DD-MMM-YY HH:mm") !==
        moment(oldsto.jobdate).format("DD-MMM-YY HH:mm");

      let timecheck = TimingCheck(delCont, oldsto.jobdate, oldsto.createdAt);
      let expact =
        newsto.services.timetype === "Express + After Hour Delivery" ||
        newsto.services.timetype === "Express Delivery";
      let afact =
        newsto.services.timetype === "Express + After Hour Delivery" ||
        newsto.services.timetype === "After Hour Delivery";

      let addFeeButton = (
        <FlexboxGrid>
          <Col2>
            <IconButton
              loading={this.state.loading}
              onClick={() => this.changeFee("Express")}
              block
              icon={<Icon icon="usd" />}
              color="green"
              appearance={expact ? "primary" : "ghost"}
            >
              {expact ? "Express Fee Charged" : "No Express Fee"}
            </IconButton>
            <HelpBlock style={{ textAlign: "center" }}>
              Express fee should {timecheck[0] ? "" : "NOT "}be applied
            </HelpBlock>
          </Col2>
          <Col2>
            <IconButton
              loading={this.state.loading}
              onClick={() => this.changeFee("After Hour")}
              block
              icon={<Icon icon="usd" />}
              color="green"
              appearance={afact ? "primary" : "ghost"}
            >
              {afact ? "After Hour Fee Charged" : "No After Hour Fee"}
            </IconButton>
            <HelpBlock style={{ textAlign: "center" }}>
              After Hour fee should {timecheck[1] ? "" : "NOT "}be applied
            </HelpBlock>
          </Col2>
        </FlexboxGrid>
      );

      staffActions = (
        <div>
          <Div14>Staff Actions:</Div14>
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                {changed && (
                  <InputGroup.Button
                    loading={load}
                    onClick={() =>
                      newStorageStore.editOrder(
                        moment(oldsto.jobdate),
                        "jobdate"
                      )
                    }
                  >
                    <Icon icon="undo" />
                  </InputGroup.Button>
                )}
                <InputGroup.Addon>Modify Job Date</InputGroup.Addon>
                <DatePicker
                  style={{ width: "100%" }}
                  showTime={{ format: "HH:mm" }}
                  format="DD-MMM-YY HH:mm"
                  minuteStep={15}
                  showSecond={false}
                  allowClear={false}
                  size="large"
                  picker="date"
                  showNow={false}
                  showToday={false}
                  inputReadOnly
                  value={moment(newsto.jobdate)}
                  onChange={(date) =>
                    newStorageStore.editOrder(date, "jobdate")
                  }
                  onOk={(date) => newStorageStore.editOrder(date, "jobdate")}
                />
                {changed && (
                  <InputGroup.Button
                    color="green"
                    loading={load}
                    onClick={() => this.postJobDate("jobdate", "Job Date")}
                  >
                    <Icon icon="check" />
                  </InputGroup.Button>
                )}
              </InputGroup>
              {changed && (
                <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                  Changed from{" "}
                  <i>"{moment(oldsto.jobddate).format("DD-MMM-YY HH:mm")}"</i>
                </HelpBlock>
              )}
            </Col2>
            <Col2>{addFeeButton}</Col2>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <div>
        <Div14>Job Time</Div14>
        <FlexboxGrid justify="center">
          <Statistic
            title={oldsto.services.timetype}
            value={moment(oldsto.jobdate).format("DD-MMM-YY HH:mm")}
            style={{
              margin: "0 32px",
              textAlign: "center",
            }}
          />
        </FlexboxGrid>
        <HelpBlock style={{ textAlign: "center" }}>
          {moment(oldsto.jobdate).fromNow()}
        </HelpBlock>
        {staffActions}
        <DeliveryTimeRequestTable delCont={delCont} />
        {userStore.user.staffaccount ? (
          <div>
            <Div14>Charges Manual Override</Div14>
            <FlexboxGrid justify="center">
              <Col2>
                {newsto.jobtype === "Delivery In" ||
                newsto.jobtype === "Storage In" ? (
                  <NumbInputSTU
                    val={newsto.services.stincost}
                    title="Stor In Chg / CBM"
                    editVal={newStorageStore.editService}
                    org={oldsto.services.stincost}
                    updateVal={this.updateService}
                    label="stincost"
                    load={this.state.load.stincost}
                  />
                ) : (
                  <NumbInputSTU
                    val={newsto.services.stoutcost}
                    title="Stor Out Chg / CBM"
                    editVal={newStorageStore.editService}
                    org={oldsto.services.stoutcost}
                    updateVal={this.updateService}
                    label="stoutcost"
                    load={this.state.load.stoutcost}
                  />
                )}
              </Col2>
              {newsto.jobtype === "Delivery In" ||
              newsto.jobtype === "Delivery Out" ? (
                <>
                  <Col2>
                    <NumbInputSTU
                      val={newsto.services.weekdayprice}
                      title="Delivery Charge (KD)"
                      editVal={newStorageStore.editService}
                      org={oldsto.services.weekdayprice}
                      updateVal={this.updateService}
                      label="weekdayprice"
                      load={this.state.load.weekdayprice}
                    />
                  </Col2>
                </>
              ) : (
                <></>
              )}
              {newsto.status === "Cancelled" ? (
                <Col2>
                  <NumbInputSTU
                    val={newsto.services.canccost}
                    title="Cancellation Charge (KD)"
                    editVal={newStorageStore.editService}
                    org={oldsto.services.canccost}
                    updateVal={this.updateService}
                    label="canccost"
                    load={this.state.load.canccost}
                  />
                </Col2>
              ) : (
                <></>
              )}
            </FlexboxGrid>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default observer(Timing);
