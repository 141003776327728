import { message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../Components/Columns/Col2";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import completeDeliveryStore from "../../../../Stores/Account/CompleteDeliveryStore";
import Page0 from "./ItemModalPages/Page0";
import Page1 from "./ItemModalPages/Page1";

const instance = axios.create({ baseURL: baseURL });

class ExistingItemIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      page: 0,
      title: "Existing Stock",
      item: {
        name: "",
        productid: "",
        serialnumber: "",
        length: 0,
        width: 0,
        height: 0,
        quantity: 1,
        remarks: "",
      },
      olditem: {
        name: "",
        productid: "",
        serialnumber: "",
        length: 0,
        width: 0,
        height: 0,
        quantity: 0,
        remarks: "",
      },
    };
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
  }
  selectItem(item) {
    let newitem = { ...item };
    newitem.quantity = 1;
    let olditem = { ...item };
    olditem.quantity = 0;

    this.setState({ item: newitem, olditem: olditem, page: 1 });
  }
  showHideModal() {
    this.setState({
      show: false,
      page: 0,
      item: {
        name: "",
        productid: "",
        serialnumber: "",
        length: 0,
        width: 0,
        height: 0,
        quantity: 1,
        remarks: "",
      },
      olditem: {
        name: "",
        productid: "",
        serialnumber: "",
        length: 0,
        width: 0,
        height: 0,
        quantity: 1,
        remarks: "",
      },
    });
  }
  editVal(val, label) {
    let item = { ...this.state.item };
    item[label] = val;
    this.setState({ item: item });
  }
  postNew = async (item) => {
    let orderid = this.props.orderid;

    let data = {
      orderid: orderid,
      item: this.state.item,
      quantity: this.state.item.quantity,
    };

    let url = "/ac/advitemmove/existingitem";
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        completeDeliveryStore.addItemMove(res.data);
        message.success("Created New Item");
        this.setState({ loading: false });
        this.showHideModal();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Count Not Create New Item");
      });
  };
  render() {
    let cds = completeDeliveryStore;
    let data = [];
    if (this.state.title === "Existing Stock") {
      data = cds.currentitems;
    } else {
      data = cds.oldstockitems;
    }

    let content;
    if (this.state.page === 0) {
      content = <Page0 data={data} selectItem={this.selectItem} />;
    } else if (this.state.page === 1) {
      content = (
        <Page1
          item={this.state.item}
          olditem={this.state.olditem}
          editVal={this.editVal}
          onHide={this.showHideModal}
          postNew={this.postNew}
          loading={this.state.loading}
        />
      );
    }
    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="plus" />}
              onClick={() => {
                this.setState({ show: true, title: "Existing Stock" });
                cds.getCurrentItems(
                  true,
                  this.props.accountid,
                  this.props.warehouseid
                );
              }}
              color="blue"
            >
              Add Existing Item
            </IconButton>
          </Col2>
          <Col2>
            {cds.stordelinout.inout === "In" && (
              <IconButton
                block
                icon={<Icon icon="plus" />}
                onClick={() => {
                  this.setState({ show: true, title: "Old Stock" });
                  cds.getOldItems(
                    true,
                    this.props.accountid,
                    this.props.warehouseid
                  );
                }}
                color="blue"
              >
                Add Old Item
              </IconButton>
            )}
          </Col2>
        </FlexboxGrid>
        <UnifiedModal
          show={this.state.show}
          onHide={this.showHideModal}
          footer={null}
          title={this.state.title}
        >
          {content}
        </UnifiedModal>
      </div>
    );
  }
}

export default observer(ExistingItemIn);
