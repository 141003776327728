import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import SJNumbInput from "../../../../../../Components/Inputs/SJ/SJNumbInput";
import { IMPriceCalc } from "../../../../../../Functions/Pricing/IMPriceCalc";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import imchargelist2 from "../../../../../../Static/Lists/imlists/imcharges/imchargelist2";
import imchargelist3 from "../../../../../../Static/Lists/imlists/imcharges/imchargelist3";
import imchargelist4 from "../../../../../../Static/Lists/imlists/imcharges/imchargelist4";
import imchargelist5 from "../../../../../../Static/Lists/imlists/imcharges/imchargelist5";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";

class IMCharges extends Component {
  render() {
    let ind = this.props.ind;
    let load = this.props.load;
    let quality = this.props.quality;

    let sj = sjStore.sjs[ind];

    let imprice = IMPriceCalc(sj);

    let imtype = sj.im.direction;

    let imcharge2 = imchargelist2.filter((val) => val.out);
    let imcharge3 = imchargelist3.filter((val) => val.out);
    let imcharge4 = imchargelist4.filter((val) => val.out);
    let imcharge5 = imchargelist5.filter((val) => val.out);
    if (imtype === "Inbound") {
      imcharge2 = imchargelist2.filter((val) => val.in);
      imcharge3 = imchargelist3.filter((val) => val.in);
      imcharge4 = imchargelist4.filter((val) => val.in);
      imcharge5 = imchargelist5.filter((val) => val.in);
    }

    let section2 = imcharge2.map((val, index) => (
      <Col2>
        <SJNumbInput
          ind={ind}
          label={val.label}
          title={val.title}
          load={load[val.label]}
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.updateVal}
          quality={val.title !== "Other" && quality}
          sj="im"
        />
      </Col2>
    ));
    let section3 = imcharge3.map((val, index) => (
      <Col2>
        <SJNumbInput
          ind={ind}
          label={val.label}
          title={val.title}
          load={load[val.label]}
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.updateVal}
          quality={quality}
          sj="im"
        />
      </Col2>
    ));
    let section4 = imcharge4.map((val, index) => (
      <Col2>
        <SJNumbInput
          ind={ind}
          label={val.label}
          title={val.title}
          load={load[val.label]}
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.updateVal}
          quality={quality}
          sj="im"
        />
      </Col2>
    ));
    let section5 = imcharge5.map((val, index) => (
      <Col2>
        <SJNumbInput
          ind={ind}
          label={val.label}
          title={val.title}
          load={load[val.label]}
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.updateVal}
          quality={quality}
          sj="im"
        />
      </Col2>
    ));

    return (
      <div>
        <Div14>Section 2 - Origin Charges {PRKD(imprice[3])} KD</Div14>
        <FlexboxGrid>{section2}</FlexboxGrid>
        <br />
        <Div14>Section 3 - Shipping Charges {PRKD(imprice[4])} KD</Div14>
        <FlexboxGrid>{section3}</FlexboxGrid>
        <br />
        <Div14>Section 4 - Destination Charges {PRKD(imprice[5])} KD</Div14>
        <FlexboxGrid>{section4}</FlexboxGrid>
        <br />
        {sj.commodity === "Dangerous Goods" && (
          <div>
            <Div14>Section 5 - Dangerous Goods {PRKD(imprice[6])} KD</Div14>
            <FlexboxGrid>{section5}</FlexboxGrid>
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default IMCharges;
