import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { Button, FlexboxGrid } from "rsuite";
import Col4 from "../../../../../../../Components/Columns/Col4";
import { Parsed, PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";

import Div14 from "../../../../../../../Components/Dividers/Div14";
import { Statistic } from "antd";
import accInvoiceStore from "../../../../../../../Stores/Financial/ACCInvoiceStore";

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let recs = accInvoiceStore.newrecs.filter((inv) => !inv.invoiced);

    let totalremaining = 0;
    let totalselected = 0;
    let selectedcount = 0;

    for (let i = 0; i < recs.length; i++) {
      if (recs[i].selected) totalselected += Parsed(recs[i].price);
      if (recs[i].selected) selectedcount += 1;
      if (!recs[i].selected) totalremaining += Parsed(recs[i].price);
    }
    let content = recs.map((acc, index) => (
      <Button
        block
        key={index}
        onClick={() => accInvoiceStore.changeCharge(acc)}
        appearance={acc.selected ? "primary" : "ghost"}
        color="black"
      >
        <Col4>
          <b>AIM Order {acc.accOrderNumId}:</b> {acc.direction} by{" "}
          {acc.shippingmethod} {acc.selected}
          <span> </span>
        </Col4>
        <Col4>
          <b>{acc.type}</b>
        </Col4>
        <Col4>{acc.title}</Col4>
        <Col4>
          <b>Price:</b> {PRKD(acc.price)} KD
        </Col4>
      </Button>
    ));
    return (
      <div>
        <Div14>Step 2: Pending Charges</Div14>
        <FlexboxGrid>
          <Col4>
            <Statistic
              title="Unselected Charges"
              value={recs.length - selectedcount}
              style={{ textAlign: "center" }}
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Total Value Remaining"
              value={PRKD(totalremaining)}
              style={{ textAlign: "center" }}
              suffix="KD"
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Selected Charges"
              value={selectedcount}
              style={{ textAlign: "center" }}
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Total Value"
              value={PRKD(totalselected)}
              style={{ textAlign: "center" }}
              suffix="KD"
            />
            <hr />
          </Col4>
        </FlexboxGrid>
        <hr />
        {content}
      </div>
    );
  }
}

export default withRouter(observer(Step2));
