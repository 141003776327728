import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  List,
  Col,
  InputGroup,
  InputNumber,
} from "rsuite";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";

class InvoiceProps extends Component {
  render() {
    let inv = this.props.inv;
    let sjindex = this.props.sjindex;
    let proposals = inv.proposals;
    let rec = invoiceStore.recs.filter((rec) => rec.sjid === inv._id);
    let singleRec;
    let allrecs = [];
    let foundprop = false;
    let proposalindex = -1;
    for (let i = 0; i < rec.length; i++) {
      foundprop = false;
      proposalindex = -1;
      for (let j = 0; j < proposals.length; j++) {
        if (
          (proposals[j].title === rec[i].title) &
          (parseFloat(proposals[j].ammount) === parseFloat(rec[i].ammount))
        ) {
          foundprop = true;
          proposalindex = j;
        }
      }
      singleRec = { ...rec[i] };
      singleRec.added = foundprop;
      singleRec.proposalindex = proposalindex;
      allrecs.push({ ...singleRec });
    }

    let recs = allrecs.map((rec, index) => (
      <List.Item key={index} index={index + 1}>
        <FlexboxGrid justify="space-between">
          <Col md={12} sm={12} xs={24}>
            {rec.title}
          </Col>
          <Col md={6} sm={6} xs={24}>
            {PRKD(rec.ammount)} KD
          </Col>
          <Col md={6} sm={6} xs={24}>
            <IconButton
              size="xs"
              icon={<Icon icon={rec.added ? "check" : "close"} />}
              color={rec.added && "green"}
              block
              onClick={() => {
                if (rec.added) {
                  invoiceStore.removeProp(sjindex, rec.proposalindex);
                } else {
                  invoiceStore.addProp(sjindex, rec);
                }
              }}
            >
              {rec.added ? "Included" : "Not Included"}
            </IconButton>
          </Col>
        </FlexboxGrid>
      </List.Item>
    ));

    let selected = proposals.length;
    let total = allrecs.length;
    let ammount = 0;
    for (let i = 0; i < proposals.length; i++) {
      ammount += Parsed(proposals[i].ammount);
    }
    let storagerow;
    if (inv.jobtype === "ST") {
      total += 1;
      let dateformat = "Month";
      if (inv.subtype === "Weekly") dateformat = "Week";
      if (inv.subtype === "Daily") dateformat = "Day";

      storagerow = (
        <FlexboxGrid justify="space-between">
          <Col md={12} sm={12} xs={24}>
            {inv.subtype} for {inv.pricepertime} per {dateformat}
          </Col>
          <Col md={6} sm={6} xs={24}>
            {PRKD(Parsed(inv.pricepertime) * Parsed(inv.time))}
          </Col>
          <Col md={6} sm={6} xs={24}>
            <InputGroup>
              <InputGroup.Addon
                style={{
                  backgroundColor: inv.time > 0 ? "#daf7d7" : "#e6e6e6",
                }}
              >{`${dateformat}s`}</InputGroup.Addon>
              <InputNumber
                min={0}
                size="sm"
                value={inv.proposals[0].time}
                onChange={(e) => invoiceStore.editStorage(sjindex, e)}
              />
            </InputGroup>
          </Col>
        </FlexboxGrid>
      );
    }

    return (
      <div>
        <Div14>
          Available Charges for {inv.jobtype}-{inv.numId}: {PRKD(ammount)} KD (
          {selected} of {total} Selected)
        </Div14>
        {storagerow}
        <List hover>{recs}</List>
      </div>
    );
  }
}

export default observer(InvoiceProps);
