import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Grow } from "@material-ui/core";
import VisibilitySensor from "react-visibility-sensor";

class VisiblityCode extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    let show = this.state.show;
    if (this.props.alwaysvisible) {
      show = true;
    }
    return (
      <VisibilitySensor
        onChange={(val) => this.setState({ show: val })}
        partialVisibility={true}
      >
        <Grow in={show} timeout={1000}>
          {this.props.children}
        </Grow>
      </VisibilitySensor>
    );
  }
}

export default withRouter(observer(VisiblityCode));
