import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import allPaymentsStore from "../../../Stores/Financial/AllPaymentsStore";

class AddCustomPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FlexboxGrid justify="center">
        <IconButton
          icon={<Icon icon="plus" />}
          size="xs"
          color="green"
          onClick={() => {
            allPaymentsStore.resetNewPayment();
            this.props.showNew();
          }}
        >
          New Payment
        </IconButton>
        <span style={{ width: "5px" }} />

        <IconButton
          icon={<Icon icon="cube" />}
          size="xs"
          color="green"
          onClick={() => {
            allPaymentsStore.newCustomPayment(
              "Expense Variable",
              "Job Materials",
              "Boxes",
              "Cash",
              "General"
            );
            this.props.showNew();
          }}
        >
          Boxes
        </IconButton>
        <span style={{ width: "5px" }} />
        <IconButton
          icon={<Icon icon="wrench" />}
          size="xs"
          color="green"
          onClick={() => {
            allPaymentsStore.newCustomPayment(
              "Expense Variable",
              "Job Materials",
              "Boxes",
              "Cash",
              "General"
            );
            this.props.showNew();
          }}
        >
          Gas
        </IconButton>
        <span style={{ width: "5px" }} />
        <IconButton
          icon={<Icon icon="cc-visa" />}
          size="xs"
          color="green"
          onClick={() => {
            allPaymentsStore.newCustomPayment(
              "Expense Fixed",
              "Bank Related",
              "POS Rental",
              "Cash",
              "General"
            );
            this.props.showNew();
          }}
        >
          POS Rental
        </IconButton>
        <span style={{ width: "5px" }} />
        <IconButton
          icon={<Icon icon="money" />}
          size="xs"
          color="green"
          onClick={() => {
            allPaymentsStore.newCustomPayment(
              "Expense Variable",
              "Office Related",
              "Petty Cash",
              "Cheque",
              "KFH"
            );
            this.props.showNew();
          }}
        >
          Petty Cash
        </IconButton>
        <span style={{ width: "5px" }} />
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(AddCustomPayment));
