import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";

import { Tooltip } from "@material-ui/core";
import { IconButton, Icon, FlexboxGrid, Notification } from "rsuite";
import MaterialTable from "material-table";
import moment from "moment";
import axios from "axios";
import { Col } from "rsuite";
import baseURL from "../../../../Static/baseURL/baseURL";
import zoneStore from "../../../../Stores/Admin/Lists/ZoneStore";
import userStore from "../../../../Stores/UserStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../Components/Columns/Col2";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import locationtypelist from "../../../../Static/Lists/LocationType";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";

const instance = axios.create({ baseURL: baseURL });
class AdminAccountLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locs: [],
      type: "New",
      show: false,
      accountId: "",
      loc: {
        name: "",
        city: "",
        type: "Warehouse",
        address: "",
        phone: null,
        easymove: false,
        floor: 0,
        _id: "",
      },
      loading: false,
    };
    this.editLocation = this.editLocation.bind(this);
  }
  componentDidMount = async () => {
    this.setState({ loading: true });
    await instance
      .get(`/ac/accountadmin/locationsbyclient/${this.props.clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({
          loading: false,
          locs: res.data.locations,
          accountId: res.data.account,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
    zoneStore.getZones();
  };

  fetchLocations = async () => {
    this.setState({ loading: true });
    await instance
      .get(`/ac/accountadmin/locationsbyclient/${this.props.clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({
          loading: false,
          locs: res.data.locations,
          accountId: res.data.account,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  onHide = async () => {
    this.setState({ show: false });
  };
  editLocation(data, label) {
    let loc = { ...this.state.loc };
    loc[label] = data;
    this.setState({ loc: loc });
  }

  postLocation = async (data, type) => {
    this.setState({ loading: true });
    let newdata = { ...data };
    newdata.client = this.props.clientid;
    newdata.account = this.state.accountId;

    if (type === "New") {
      return instance
        .post(`/additional/location/new`, newdata, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchLocations();
          Notification["success"]({
            title: `New Location Created.`,
            description: <div>In {newdata.city}</div>,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else if (type === "Delete") {
      return instance
        .put(`/additional/location/delete/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchLocations();

          Notification["success"]({
            title: `Deleted Location Details.`,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else {
      return instance
        .put(`/additional/location/edit/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchLocations();
          Notification["success"]({
            title: `Updated Location Details.`,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    }
  };

  deleteLocation() {}

  newLocation() {
    this.setState({
      type: "New",
      show: true,
      loc: {
        name: "",
        city: "",
        type: "Warehouse",
        address: "",
        phone: null,
        easymove: false,
        floor: 0,
        _id: "",
      },
    });
  }

  render() {
    let data = this.state.locs;
    let columnnames = [
      { title: "ID", field: "locationNumId", defaultSort: "desc" },
      { title: "Name", field: "name" },
      { title: "City", field: "city" },
      { title: "Address", field: "address" },
      { title: "Floor", field: "floor" },
      { title: "Phone", field: "phone" },
    ];

    let type = this.state.type;
    let loc = this.state.loc;
    let disable = false;
    if (loc.name === "") disable = true;
    if (loc.id === "") disable = true;

    let modal = (
      <UnifiedModal
        onHide={this.onHide}
        show={this.state.show}
        title={`${type === "New" ? "Add New " : "Edit Existing "} Location`}
        footer={
          <FlexboxGrid>
            <Col2>
              <IconButton
                block
                loading={this.state.loading}
                color="red"
                icon={<Icon icon="close" />}
                onClick={() => this.onHide()}
              >
                Cancel
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                disabled={disable}
                block
                loading={this.state.loading}
                color="green"
                icon={<Icon icon="save" />}
                onClick={() => {
                  this.postLocation(this.state.loc, this.state.type);
                }}
              >
                {type === "New" ? "Create New " : "Save Edited "} Location
              </IconButton>
            </Col2>
          </FlexboxGrid>
        }
      >
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24}>
            <TextInputSTD
              title="Location Name"
              val={loc.name}
              label="name"
              size="large"
              editVal={this.editLocation}
            />
            <br />
          </Col>
          <Col2>
            <TextInputSTD
              title="Phone"
              val={loc.phone}
              label="phone"
              size="large"
              editVal={this.editLocation}
            />
            <br />
          </Col2>
          <Col2>
            <SelectInputSTD
              title="Type"
              val={loc.type}
              label="type"
              size="large"
              opts={locationtypelist}
              editVal={this.editLocation}
            />
            <br />
          </Col2>
          <Col2>
            <SelectInputSTD
              title="City"
              val={loc.city}
              label="city"
              size="large"
              opts={zoneStore.zones.map((zone) => zone.name)}
              editVal={this.editLocation}
            />
            <br />
          </Col2>
          <Col2>
            <NumbInputSTD
              title="Floor"
              val={loc.floor}
              label="floor"
              size="large"
              editVal={this.editLocation}
            />
            <br />
          </Col2>
          <Col md={24} sm={24} xs={24}>
            <TextAreaInputSTD
              title="Address"
              val={loc.address}
              label="address"
              size="large"
              editVal={this.editLocation}
            />
            <br />
          </Col>
        </FlexboxGrid>
      </UnifiedModal>
    );

    if (type === "Delete") {
      modal = (
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`Delete Location`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  No, Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.postLocation(this.state.loc, this.state.type);
                  }}
                >
                  Yes, Delete Location
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <h6>Are you sure you want to delete this location?</h6>
          <p>
            <b>Name: </b>
            {loc.name} <br />
            <b>Phone: </b>
            {loc.phone} <br />
            <b>Type: </b>
            {loc.type} <br />
            <b>City: </b>
            {loc.city} <br />
            <b>Address: </b>
            {loc.address} <br />
          </p>
        </UnifiedModal>
      );
    }

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        {modal}

        <Div14>
          Saved Locations:
          <span style={{ width: "10px" }} />{" "}
          <Tooltip title={"Refresh List"} arrow placement="top">
            <IconButton
              circle
              size="xs"
              icon={<Icon icon="refresh" />}
              onClick={() => this.fetchLocations()}
            />
          </Tooltip>
        </Div14>

        <div>
          <MaterialTable
            isLoading={this.state.loading}
            title={data.length + " Locations"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `Location List as of ${moment(new Date()).format(
                "DD-MMM-YY"
              )}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Location",
                onClick: (event, rowData) =>
                  this.setState({ loc: rowData, show: true, type: "Edit" }),
              },
              {
                icon: "add",
                tooltip: "Add New Location",
                isFreeAction: true,
                onClick: (event, rowData) => this.newLocation(),
              },
              {
                icon: "delete",
                tooltip: "Delete Location",

                onClick: (event, rowData) =>
                  this.setState({ loc: rowData, show: true, type: "Delete" }),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default observer(AdminAccountLocations);
