import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";
import singleEquipmentStore from "../../../../Stores/EquipmentStores/SingleEquipmentStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col3 from "../../../../Components/Columns/Col3";

class FuelGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let series = singleEquipmentStore.fuelgraphdata.series;

    let title = "Fuel Consumption";

    let buttontext = ["Fuel Consumption", "Price Graph", "Fuel Duration"];

    let options = {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: { text: title },
      dataLabels: { enabled: true },
      labels: singleEquipmentStore.fuelgraphdata.label,
      xaxis: { type: "datetime" },
      yaxis: singleEquipmentStore.fuelgraphdata.yaxis,
    };

    let fuelgraph = singleEquipmentStore.fuelgraph;

    let graphbuttons = buttontext.map((but, index) => (
      <Col3 key={index}>
        <IconButton
          color={fuelgraph === but ? "green" : "default"}
          icon={<Icon icon={fuelgraph === but ? "check-circle" : "circle"} />}
          onClick={() => singleEquipmentStore.changeFuelgraph(but)}
          block
        >
          {but}
        </IconButton>
      </Col3>
    ));

    return (
      <div>
        <FlexboxGrid justify="space-around">{graphbuttons}</FlexboxGrid>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
    );
  }
}

export default withRouter(observer(FuelGraph));
