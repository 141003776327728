import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

class UnderConstructionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        <h1>
          The System is currently updating, should be done within a few hours.
        </h1>
        <br />
        <h1>
          Please let me know if you need any information and I will do my best
          to support.
        </h1>
      </div>
    );
  }
}

export default withRouter(observer(UnderConstructionPage));
