import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import KFHPage from "./KFHPage";
import NBKPage from "./NBKPage";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  body: {
    paddingTop: 10,
    paddingBottom: 5,
    paddingHorizontal: 35,
  },
  header: {
    fontSize: 16,
    marginBottom: 5,
    textAlign: "center",
    color: "black",
    fontWeight: "extrabold",
    textDecoration: "underline",
  },
  subheader: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
  bodytext: {
    fontSize: 10,
    fontFamily: "Times-Roman",
    textAlign: "left",
  },
});

class PaymentNotes extends Component {
  render() {
    let inv = this.props.inv;
    let banktransfer;
    if (inv.showBank === "KFH") banktransfer = <KFHPage />;
    if (inv.showBank === "NBK") banktransfer = <NBKPage />;
    return (
      <View>
        <Text style={styles.header}>Payment Options</Text>
        <Text style={styles.subheader}>Payment Notes: </Text>
        <View style={styles.bodytext}>
          <Text>
            * Payment can be accepted by Cash, K-Net, Wire Transfer, Online
            Payment, or Cheque.
          </Text>
          <Text>
            * Cheque should be made payable to "Easy Move Logistics Co. W.L.L"
            and crossed A/C Payee.
          </Text>
          <Text>
            * Any Bank related charges or fees related to the transfer must be
            paid by the sender. The invoice amount is the amount that should be
            received by Easy Move.
          </Text>
        </View>
        <Text style={{ lineHeight: 0.5 }}> </Text>
        <Text style={styles.subheader}>1) Online Link: </Text>
        <View style={styles.bodytext}>
          <Text>
            If you wish to pay by Online link, please let our office know and we
            will send you a link right away.
          </Text>
        </View>
        <Text style={styles.subheader}>2) Bank Transfer Details: </Text>
        <View style={styles.bodytext}>
          <Text>
            If you wish to pay by Bank Transfer, kindly remit to the following
            Bank Account details:
          </Text>
          <Text style={[styles.bodytext, { fontSize: 10 }]}>
            * Any Bank related charges or fees related to the transfer must be
            paid by the sender. The invoice amount is the amount that should be
            received by Easy Move.
          </Text>
        </View>
        <Text style={{ lineHeight: 0.5 }}> </Text>
        {banktransfer}
        <Text style={[styles.tableHeaderStyle, { fontSize: 10 }]}>
          Please Quote the Invoice Number (Invoice Number Here)
        </Text>

        <Text style={styles.subheader}>3) Cash / Cheque: </Text>
        <View style={styles.bodytext}>
          <Text>
            If you wish to pay in other methods such as Cash or by Cheque,
            please let our office know and we will arrange accordingly.
          </Text>
          <Text style={{ lineHeight: 0.5 }}> </Text>
          <Text style={[styles.bodytext, { fontSize: 10 }]}>
            * Cheque should be made payable to "Easy Move Logistics Co. W.L.L"
            and crossed A/C Payee.
          </Text>
        </View>
      </View>
    );
  }
}

export default observer(PaymentNotes);
