import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Result } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";

class TruckTripNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <br />
        <h3 style={{ textAlign: "center" }}>Error Finding Truck Trip</h3>
        <hr />
        <Result
          status="warning"
          title="The Truck Trip was either Completed or Not Found"
          subTitle="You can visit the below links to check in the New or Old Warehouse for Active jobs:"
        />
        <FlexboxGrid style={{ margin: "3%" }}>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="building" />}
              color="green"
              onClick={() => this.props.history.push("/whm/oldwarehouse")}
            >
              Go to Old Warehouse
            </IconButton>
            <br />
          </Col2>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="star" />}
              color="green"
              onClick={() => this.props.history.push("/whm/newwarehouse")}
            >
              Go to New Warehouse
            </IconButton>
            <br />
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(TruckTripNotFound));
