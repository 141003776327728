import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import moment from "moment";
import { PRKD, Parsed } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

class EquipmentStore {
  constructor() {
    this.equipments = [];
    this.fuel = [];
    this.fuelbytruck = [];
    this.fuelbytrucklist = [];
    this.service = [];
    this.checklist = [];
    this.latestchecklist = true;
    this.filteredfuel = [];
    this.selectedfuel = "";
    this.equipment = {
      type: "Truck",
      date: moment(),
      dateto: undefined,
      license: "",
      remark: "",
      status: "Active",
    };

    this.loading = {
      equipments: false,
      fuel: false,
      service: false,
      checklist: false,
    };
    this.loaded = {
      equipments: false,
      fuel: false,
      service: false,
      checklist: false,
    };

    makeObservable(this, {
      equipments: observable,
      equipment: observable,
      fuel: observable,
      service: observable,
      checklist: observable,
      selectedfuel: observable,
      latestchecklist: observable,
      fuelbytruck: observable,
      fuelbytrucklist: observable,
      filteredfuel: observable,
      loading: observable,
      loaded: observable,
      fuelconsumption: computed,
    });
  }

  // FETCH
  getEquipments(override) {
    let loaded = false;
    if (this.loaded.equipments) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.equipments = false;
      this.loading.equipments = true;

      return instance
        .get(`/hr/equipment/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((equipments) => {
          this.equipments = equipments;

          this.loaded.equipments = true;
          this.loading.equipments = false;
          console.log("Equipments Fetched");
        })
        .catch((err) => {
          this.loading.equipments = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Equipments.`,
          });
        });
    }
  }
  getEquipmentFuels(override) {
    let loaded = false;
    if (this.loaded.fuel) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.fuel = false;
      this.loading.fuel = true;

      return instance
        .get(`/hr/equipmentfuel/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((fuel) => {
          this.fuel = fuel;

          this.loaded.fuel = true;
          this.loading.fuel = false;
          this.fuelbytruck = groupBy(fuel, "license");
          let trucklist = Object.keys(this.fuelbytruck);
          let finaltrucklist = [];

          for (let i = 0; i < trucklist.length; i++) {
            finaltrucklist = [
              ...finaltrucklist,
              {
                name: trucklist[i],
                type: this.fuelbytruck[trucklist[i]][0].equipment.type,
              },
            ];
            console.log(this.fuelbytruck[trucklist[i]][0].equipment.type);
          }

          this.fuelbytrucklist = finaltrucklist;
          console.log("Equipment Fuels Fetched");
        })
        .catch((err) => {
          this.loading.fuel = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Equipment Fuels.`,
          });
        });
    }
  }

  getEquipmentService(override) {
    let loaded = false;
    if (this.loaded.service) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.service = false;
      this.loading.service = true;

      return instance
        .get(`/hr/equipmentservice/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((service) => {
          this.service = service;

          this.loaded.service = true;
          this.loading.service = false;
          console.log("Equipment Services Fetched");
        })
        .catch((err) => {
          this.loading.service = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Equipment Services.`,
          });
        });
    }
  }
  getEquipmentChecklist(override) {
    let loaded = false;
    if (this.loaded.checklist) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.checklist = false;
      this.loading.checklist = true;

      let url = "/hr/equipmentchecklist/all";
      if (this.latestchecklist) url = "/hr/equipmentchecklist/latest";
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((checklist) => {
          this.checklist = checklist;
          this.loaded.checklist = true;
          this.loading.checklist = false;
          console.log("Equipment Checklist Fetched");
        })
        .catch((err) => {
          this.loading.checklist = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Equipment Checklist.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get fuelconsumption() {
    if (this.selectedfuel === "") return "";
    return this.fuelbytrucklist[this.selectedfuel];
  }

  // FUNCTIONS:

  changeChecklist() {
    equipmentStore.latestchecklist = !equipmentStore.latestchecklist;
    equipmentStore.getEquipmentChecklist(true);
  }

  resetEquipment(equipment) {
    if (equipment) {
      equipmentStore.equipment = {
        type: equipment.type,
        date: equipment.date,
        dateto: equipment.dateto,
        license: equipment.license,
        remark: equipment.remark,
        status: equipment.status,
      };
    } else {
      equipmentStore.equipment = {
        type: "Truck",
        date: moment(),
        dateto: undefined,
        license: "",
        remark: "",
        status: "Active",
      };
    }
  }

  changeEquipment(val, label) {
    equipmentStore.equipment[label] = val;
  }

  selectSingleFuel(val) {
    if (equipmentStore.selectedfuel === val) {
      equipmentStore.selectedfuel = "";
      equipmentStore.filteredfuel = [];
    } else {
      equipmentStore.selectedfuel = val;
      let newfuel = equipmentStore.fuelbytruck[val];
      for (let i = 0; i < newfuel.length; i++) {
        if (i > 0) {
          newfuel[i].oldkm = newfuel[i - 1].kilometer;
          newfuel[i].kmdiff = PRKD(
            Parsed(newfuel[i].kilometer) - Parsed(newfuel[i - 1].kilometer)
          );
          newfuel[i].oldliter = newfuel[i - 1].liter;
          newfuel[i].kmperliter = PRKD(
            Parsed(newfuel[i].kmdiff) / Parsed(newfuel[i - 1].liter)
          );
        } else {
          newfuel[i].oldkm = 0;
          newfuel[i].kmdiff = 0;
          newfuel[i].oldliter = 0;
          newfuel[i].kmperliter = 0;
        }
      }
      equipmentStore.filteredfuel = newfuel;
    }
  }

  startLoading() {
    this.loading = true;
  }
}

const equipmentStore = new EquipmentStore();
export default equipmentStore;
