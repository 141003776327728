import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { Button, FlexboxGrid } from "rsuite";
import Col4 from "../../../../../../../Components/Columns/Col4";
import { Parsed, PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";

import aimInvoiceStore from "../../../../../../../Stores/Financial/AIMInvoiceStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { Statistic } from "antd";

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let recs = aimInvoiceStore.newrecs.filter((inv) => !inv.invoiced);

    let totalremaining = 0;
    let totalselected = 0;
    let selectedcount = 0;

    for (let i = 0; i < recs.length; i++) {
      if (recs[i].selected) totalselected += Parsed(recs[i].price);
      if (recs[i].selected) selectedcount += 1;
      if (!recs[i].selected) totalremaining += Parsed(recs[i].price);
    }
    let content = recs.map((aim, index) => (
      <Button
        block
        key={index}
        onClick={() => aimInvoiceStore.changeCharge(aim)}
        appearance={aim.selected ? "primary" : "ghost"}
        color="black"
      >
        <Col4>
          <b>AIM Order {aim.aimOrderNumId}:</b> {aim.direction} by{" "}
          {aim.shippingmethod} {aim.selected}
          <span> </span>
        </Col4>
        <Col4>
          <b>{aim.type}</b>
        </Col4>
        <Col4>{aim.title}</Col4>
        <Col4>
          <b>Price:</b> {PRKD(aim.price)} KD
        </Col4>
      </Button>
    ));
    return (
      <div>
        <Div14>Step 2: Pending Charges</Div14>
        <FlexboxGrid>
          <Col4>
            <Statistic
              title="Unselected Charges"
              value={recs.length - selectedcount}
              style={{ textAlign: "center" }}
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Total Value Remaining"
              value={PRKD(totalremaining)}
              style={{ textAlign: "center" }}
              suffix="KD"
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Selected Charges"
              value={selectedcount}
              style={{ textAlign: "center" }}
            />
            <hr />
          </Col4>
          <Col4>
            <Statistic
              title="Total Value"
              value={PRKD(totalselected)}
              style={{ textAlign: "center" }}
              suffix="KD"
            />
            <hr />
          </Col4>
        </FlexboxGrid>
        <hr />
        {content}
      </div>
    );
  }
}

export default withRouter(observer(Step2));
