import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Col, List, IconButton, Icon } from "rsuite";
import Div14 from "../../../../../../Components/Dividers/Div14";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";

class InvoiceNotes extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, data: "", jobtitle: "" };
  }

  render() {
    let inv = this.props.inv;
    let sjs = inv.subJobs;

    let rows = sjs.map((sj, sjindex) => (
      <div key={sjindex}>
        <Div14>
          {sj.jobtype}-{sj.numId} Available Job Dates
        </Div14>
        <List hover>
          {sj.jobdates.map((jd, jdindex) => (
            <List.Item
              key={jdindex}
              onClick={() => invoiceStore.editJobDate(sjindex, jdindex)}
            >
              <FlexboxGrid justify="space-between">
                <Col md={4} sm={4} xs={4}>
                  <IconButton
                    color={jd.show && "green"}
                    icon={<Icon icon={jd.show ? "check" : "close"} />}
                  />
                </Col>
                <Col md={20} sm={20} xs={20}>
                  {jd.type}: {moment(jd.date).format("DD-MMM-YY")} for
                  {1 + moment(jd.dateto).diff(jd.date, "days")} days. (From:{" "}
                  {jd.fromtype} - {jd.from}) (To: {jd.totype} - {jd.to})
                </Col>
              </FlexboxGrid>
            </List.Item>
          ))}
        </List>
      </div>
    ));

    return (
      <div>
        {rows}
        <br />
      </div>
    );
  }
}

export default observer(InvoiceNotes);
