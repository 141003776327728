import { observer } from "mobx-react";
import React, { Component } from "react";
import QRCode from "react-qr-code";
import { withRouter } from "react-router";
import Barcode from "react-barcode";
import { Row } from "antd";
import easymoveblack from "../../Static/Images/easymoveblack.png";
import frontendURL from "../../Static/baseURL/frontendURL";

class QRPrint extends Component {
  render() {
    let company = this.props.clientshort;
    let item = this.props.item;

    let itemName = item.name;
    let itemID = item._id;
    let itemNumID = item.acItemNumId;

    let serialnumber = item.serialnumber;
    let productid = item.productid;

    if (company) {
      if (company.length) {
        if (company.length > 17) {
          company = company.substring(0, 17) + "..";
        }
      }
    }

    if (itemName) {
      if (itemName.length) {
        if (itemName.length > 17) {
          itemName = itemName.substring(0, 17) + "..";
        }
      }
    }
    if (serialnumber) {
      if (serialnumber.length) {
        if (serialnumber.length > 17) {
          serialnumber = serialnumber.substring(0, 17) + "..";
        }
      }
    }
    if (productid) {
      if (productid.length) {
        if (productid.length > 17) {
          productid = productid.substring(0, 17) + "..";
        }
      }
    }

    let size = 200;

    return (
      <div
        style={{ width: size, paddingLeft: "3px", pageBreakAfter: "always" }}
      >
        <Row justify="start">
          <div>
            <div
              style={{
                fontSize: 30,
                fontWeight: "bold",
                textAlign: "start",
                marginBottom: 0,
                lineHeight: 0.9,
              }}
            >
              {itemNumID}
            </div>

            <p
              style={{
                fontSize: 8,
                textAlign: "start",
                marginBottom: 0,
                lineHeight: 0.9,
                textOverflow: "clip",
              }}
            >
              <b>{company}</b>
              <br />
              <b>SN:</b>
              {serialnumber}
              <br />
              <b>PN:</b>
              {productid}
              <br />
              {itemName}
            </p>
            <img src={easymoveblack} style={{ maxWidth: 90 }} alt="QR Code" />
          </div>
        </Row>

        <div style={{ paddingTop: "3px" }}>
          <QRCode
            value={`${frontendURL}itemtrack/${itemID}`}
            size={size - 110}
          />
        </div>

        <Barcode
          value={item.serialnumber}
          width={0.6}
          height={20}
          format="CODE128"
          marginTop={0}
          marginBottom={0}
          marginLeft={-0.0000001}
          displayValue={false}
        />
        <br />
      </div>
    );
  }
}

export default withRouter(observer(QRPrint));
