import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import jobStatsMainStore from "../../Stores/SideJobs/JobStatsMainStore";
import LoadBack from "../../Components/Loaders/LoadBack";
import JobsTable from "./JobsTable";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import Col4 from "../../Components/Columns/Col4";
import Div14 from "../../Components/Dividers/Div14";
import moment from "moment";

import Col2 from "../../Components/Columns/Col2";
import { InputGroup } from "rsuite";
import { DatePicker } from "antd";
import OverviewBarGraph from "../Management/EMOverview/Graph/OverviewBarGraph";
const { RangePicker } = DatePicker;

class JobStatMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    jobStatsMainStore.getCalendarJobs();
  }

  render() {
    let filters = ["LM", "IM", "CC", "ST"];

    return (
      <LoadBack loading={jobStatsMainStore.loading.alljobs}>
        <div>
          <Div14>
            Job Statistics from{" "}
            {moment(jobStatsMainStore.newstart).format("MMM-yy")} to{" "}
            {moment(jobStatsMainStore.newend).format("MMM-yy")}
          </Div14>
          <FlexboxGrid justify="center">
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Start Date</InputGroup.Addon>
                <RangePicker
                  allowClear={false}
                  format={"MMM-yyyy"}
                  picker="month"
                  style={{ width: "100%" }}
                  value={[
                    moment(jobStatsMainStore.startdate),
                    moment(jobStatsMainStore.enddate),
                  ]}
                  onChange={(e) => {
                    jobStatsMainStore.changeDate(e);
                  }}
                />

                <InputGroup.Button
                  color="green"
                  loading={jobStatsMainStore.loading.alljobs}
                  onClick={() => jobStatsMainStore.getCalendarJobs(true)}
                >
                  <Icon icon="check" />
                </InputGroup.Button>
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid>
            {filters.map((filt, index) => (
              <Col4 key={index}>
                <IconButton
                  block
                  color={jobStatsMainStore.filter[filt] ? "green" : "grey"}
                  icon={
                    <Icon
                      icon={
                        jobStatsMainStore.filter[filt] ? "eye" : "eye-slash"
                      }
                    />
                  }
                  onClick={() => jobStatsMainStore.changeFilter(filt)}
                >
                  {jobStatsMainStore.filter[filt]
                    ? `Showing ${filt}`
                    : `Hiding ${filt}`}
                </IconButton>
              </Col4>
            ))}
          </FlexboxGrid>
          <hr />
          <FlexboxGrid>
            <Col2>
              <OverviewBarGraph
                data={jobStatsMainStore.jobgraphs.price}
                xaxis={jobStatsMainStore.jobgraphs.x}
                label="Revenue"
                title={`Monthly Revenue`}
              />
            </Col2>
            <Col2>
              <OverviewBarGraph
                data={jobStatsMainStore.jobgraphs.days}
                xaxis={jobStatsMainStore.jobgraphs.x}
                label="Working Days"
                title={`Busy Days`}
              />
            </Col2>
          </FlexboxGrid>

          <hr />
          <JobsTable data={jobStatsMainStore.joblist} />
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(JobStatMain));
