import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol15white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol35white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "35%",
  },
  tableCol35grey: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "grey",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "35%",
  },
  tableCol35blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "35%",
  },
  tableCol15blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
});

class SJDates extends Component {
  render() {
    let sj = this.props.sj;
    let jds = sj.jobdates.filter((jd) => jd.show);

    if (jds.length <= 0) return null;

    // type, fromtype, from, totype, to, date, dateto
    // let diff = 1 + moment(jd.dateto).diff(jd.date, "days")
    let subjds = jds.map((jd, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol15white}>
          {moment(jd.date).format("DD-MMM-YY") ===
          moment(jd.dateto).format("DD-MMM-YY")
            ? moment(jd.date).format("DD-MMM-YY")
            : `${moment(jd.date).format("DD-MMM-YY")} to ${moment(
                jd.dateto
              ).format("DD-MMM-YY")}`}
        </Text>
        <Text style={styles.tableCol15white}>
          {jd.type} ({1 + moment(jd.dateto).diff(jd.date, "days")} day
          {1 + moment(jd.dateto).diff(jd.date, "days") !== 1 && "s"})
        </Text>
        <Text style={styles.tableCol35white}>
          {jd.fromtype}: {jd.from}
        </Text>
        {jd.type !== "Packing" ? (
          <Text style={styles.tableCol35white}>
            {jd.totype}: {jd.to}
          </Text>
        ) : (
          <Text style={styles.tableCol35grey}></Text>
        )}
      </View>
    ));

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>
          {jds.length} Job Dates for {sj.jobtype}-{sj.numId}
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol15blue}>Dates</Text>
          <Text style={styles.tableCol15blue}>Job</Text>
          <Text style={styles.tableCol35blue}>From</Text>
          <Text style={styles.tableCol35blue}>To</Text>
        </View>
        {subjds}
      </View>
    );
  }
}

export default observer(SJDates);
