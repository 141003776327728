import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import whmCompanyStore from "../WHMStores/WHMCompanyStore";
import Div14 from "../../../Components/Dividers/Div14";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col3 from "../../../Components/Columns/Col3";
import WHMItemsList from "./ItemSelection/WHMItemsList";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import { message } from "antd";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import FlexboxButton from "../../../Components/Modal/FlexboxButton";

const instance = axios.create({ baseURL: baseURL });

class WHMNewPalletModal extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, loading: false, newpalletid: "" };
    this.onHide = this.onHide.bind(this);
    this.onHideConf = this.onHideConf.bind(this);
    this.postNewPalletItem = this.postNewPalletItem.bind(this);
  }
  onHide() {
    this.setState({ show: false });
  }
  postNewPalletItem = async (palletitems, compid) => {
    let data = palletitems;

    this.setState({ loading: true });
    return instance
      .put(`/whm/palletitem/new/${compid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.setState({ loading: false });
        this.onHide();

        this.setState({ newpalletid: data.whmPalletNumId });
        message.success("New Pallet Created");
      })
      .catch((err) => {
        this.setState({ loading: false });

        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };
  onHideConf = async (compid) => {
    this.setState({ newpalletid: "" });
    whmCompanyStore.getSingleCompany(compid, true);
    this.props.onHide();
  };

  render() {
    let company = whmCompanyStore.company;
    return (
      <div>
        <UnifiedModal
          title="Pallet Confirmation"
          footer={
            <FlexboxButton
              icon="close"
              color="green"
              text="Close Page"
              oC={() => this.onHideConf(whmCompanyStore.company._id)}
            />
          }
          show={this.state.newpalletid !== ""}
          onHide={this.onHideConf}
        >
          <h2 style={{ textAlign: "center" }}>New Pallet Created</h2>
          <h1 style={{ textAlign: "center", fontSize: 50, fontWeight: "bold" }}>
            {this.state.newpalletid}
          </h1>
        </UnifiedModal>
        <UnifiedModal
          title="Confirm Items"
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New Pallet"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.postNewPalletItem(
                  whmCompanyStore.singlePallet,
                  whmCompanyStore.company._id
                )
              }
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          show={this.state.show}
          onHide={this.onHide}
        >
          {whmCompanyStore.singlePallet.map((it, index) => (
            <div key={index}>
              {it.newquant} x Item # {it.acItemNumId}: {it.name}
            </div>
          ))}
          <br />
          ________________
          <br />
          <b>
            {whmCompanyStore.palletstats.qty} Items
            <br />
            {whmCompanyStore.palletstats.cbm} CBM
          </b>
        </UnifiedModal>
        <h3 style={{ textAlign: "center" }}>
          Pallet #{whmCompanyStore.newpallet}
        </h3>
        <Div14>New Pallet for {company.name}</Div14>
        <FlexboxGrid>
          <Col3>
            <IconButton
              icon={<Icon icon={"close"} />}
              color="red"
              block
              onClick={() => {
                this.props.onHide();
                whmCompanyStore.getUnpalletized(false, true);
              }}
            >
              Close
            </IconButton>
          </Col3>
          <Col3></Col3>
          <Col3>
            <IconButton
              icon={<Icon icon={"save"} />}
              color="green"
              block
              onClick={() => this.setState({ show: true })}
              disabled={whmCompanyStore.singlePallet.length <= 0}
            >
              Save
            </IconButton>
          </Col3>
        </FlexboxGrid>
        <Div14>Select Items</Div14>
        <WHMItemsList data={whmCompanyStore.unpalletized} />
      </div>
    );
  }
}

export default withRouter(observer(WHMNewPalletModal));
