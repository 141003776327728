import { observer } from "mobx-react";
import React, { Component } from "react";
import MaterialTable from "material-table";

import Div14 from "../../../../../../Components/Dividers/Div14";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";

class ItemSummary extends Component {
  render() {
    let sto = this.props.sto;
    let items = [];

    for (let i = 0; i < sto.acitemmove.length; i++) {
      if (sto.acitemmove[i].acitem) {
        items = [
          ...items,
          { ...sto.acitemmove[i].acitem, qty: sto.acitemmove[i].quantity },
        ];
      }
    }

    let orderdata = [];
    for (let i = 0; i < items.length; i++) {
      orderdata = [
        ...orderdata,
        {
          name: items[i].name,
          acItemNumId: items[i].acItemNumId,
          serialnumber: items[i].serialnumber,
          productid: items[i].productid,
          qty: items[i].qty,
          dim: `${items[i].length} x ${items[i].width} x ${items[i].height}`,
        },
      ];
    }

    return (
      <div>
        <Div14>Items Summary</Div14>
        <MaterialTable
          title="Items List"
          columns={[
            { title: "ID", field: "acItemNumId" },
            { title: "Item", field: "name" },
            { title: "Serial No.", field: "serialnumber" },
            { title: "Product ID", field: "productid" },
            { title: "Qty", field: "qty" },
            { title: "Stock", field: "currentstock" },
            {
              title: "CBM",
              field: "height",
              render: (rowData) => {
                return PRKD(
                  (parseFloat(rowData.length) *
                    parseFloat(rowData.width) *
                    parseFloat(rowData.height) *
                    parseFloat(rowData.qty)) /
                    1000000
                );
              },
            },
            {
              title: "L x W x H (cm)",
              field: "length",
              render: (rowData) => {
                return `${PRKD(rowData.length)} x ${PRKD(
                  rowData.width
                )} x ${PRKD(rowData.height)}`;
              },
            },
          ]}
          data={items}
          isLoading={this.props.loading}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: false,
            padding: "dense",
            loadingType: "overlay",
          }}
        />
      </div>
    );
  }
}

export default observer(ItemSummary);
