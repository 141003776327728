import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
  LoadPanel,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { Tooltip } from "@material-ui/core";
import warehouseSummaryStore from "../../../../Stores/Financial/WarehouseSummaryStore";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import Col2 from "../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import MainACInvoice from "../../../../Components/PDFs/ACInvoices/MainACInvoice.js";
import Col4 from "../../../../Components/Columns/Col4";
import { Statistic } from "antd";

class InvoiceChargesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, acinvoice: { charges: [] } };
    this.StatusCell = this.StatusCell.bind(this);
    this.DelChg = this.DelChg.bind(this);
    this.StoChg = this.StoChg.bind(this);
    this.TotalChg = this.TotalChg.bind(this);
    this.TotalInv = this.TotalInv.bind(this);
    this.GoToClient = this.GoToClient.bind(this);
    this.GoToInvoice = this.GoToInvoice.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {}

  onHide() {
    this.setState({ show: false, acinvoice: { charges: [] } });
  }

  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending") {
      color = "orange";
    } else if (status === "Expired" || status === "Cancelled") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }
  DelChg(cellData) {
    if (cellData.data.totaldelivery === 0) return "-";
    return PRKD(cellData.data.totaldelivery);
  }
  StoChg(cellData) {
    if (cellData.data.totalstorage === 0) return "-";
    return PRKD(cellData.data.totalstorage);
  }
  TotalChg(cellData) {
    if (cellData.data.totalcharges === 0) return "-";
    return PRKD(cellData.data.totalcharges);
  }
  TotalInv(cellData) {
    if (cellData.data.totalinvoicecost === 0) return "-";
    return PRKD(cellData.data.totalinvoicecost);
  }
  GoToClient(cellData) {
    return (
      <a href={`/ac/dashboard/${cellData.data.client._id}`}>
        {cellData.data.client.name}
      </a>
    );
  }
  GoToInvoice(cellData) {
    return (
      <IconButton
        icon={<Icon icon="save" />}
        onClick={() => this.setState({ show: true, acinvoice: cellData.data })}
        circle
        color="green"
        size="xs"
      />
    );
  }

  render() {
    if (!warehouseSummaryStore.loaded.invoices) {
      return (
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              loading={warehouseSummaryStore.loading.invoices}
              icon={<Icon icon="refresh" />}
              color="green"
              block
              onClick={() => warehouseSummaryStore.getInvoiceData(true)}
            >
              Load Data
            </IconButton>
          </Col2>
        </FlexboxGrid>
      );
    }
    let stats = warehouseSummaryStore.stats;
    let invoices = warehouseSummaryStore.invoices;
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="AC Invoice PDF View"
          footer={null}
        >
          <MainACInvoice inv={this.state.acinvoice} />
        </UnifiedModal>
        <LoadBack loading={warehouseSummaryStore.loading.invoices}>
          <FlexboxGrid justify="space-around">
            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Clients"
                  value={invoices.length}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>
            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Storage Charges"
                  value={PRKD(stats.invoices.storage)}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>
            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Delivery Charges"
                  value={PRKD(stats.invoices.delivery)}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>

            <Col4>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Total Charges"
                  value={PRKD(stats.invoices.total)}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </Col4>
          </FlexboxGrid>
          <DataGrid
            dataSource={warehouseSummaryStore.invoices}
            keyExpr="client.clientNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <LoadPanel enabled={true} />
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />

            <Column
              dataField="client.clientNumId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />

            <Column
              dataField="client.name"
              dataType="date"
              caption="Client"
              alignment="center"
              minWidth={120}
              cellRender={this.GoToClient}
            />
            <Column
              dataField="status"
              caption="Status"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
              cellRender={this.StatusCell}
            />
            <Column
              dataField="date"
              dataType="date"
              caption="Date"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
            />
            <Column caption="Charges Details" alignment={"center"}>
              <Column
                dataField="totalstorage"
                dataType="text"
                caption="Storage Charges"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={120}
                cellRender={this.StoChg}
              />
              <Column
                dataField="totaldelivery"
                dataType="text"
                caption="Del Charges"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={120}
                cellRender={this.DelChg}
              />
              <Column
                dataField="totalcharges"
                dataType="text"
                caption="All Charges"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={120}
                cellRender={this.TotalChg}
              />
            </Column>
            <Column
              dataField="totalinvoicecost"
              dataType="text"
              caption="Invoiced"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={120}
              cellRender={this.TotalInv}
              sortOrder="desc"
            />
            <Column
              caption="View Invoice"
              alignment="center"
              width={80}
              cellRender={this.GoToInvoice}
            />

            <Summary>
              <TotalItem
                column="client.name"
                summaryType="count"
                displayFormat={"{0} Clients"}
              />
              <TotalItem
                column="totalstorage"
                summaryType="sum"
                valueFormat={"#,##0"}
                displayFormat={"{0}"}
              />
              <TotalItem
                column="totaldelivery"
                summaryType="sum"
                valueFormat={"#,##0"}
                displayFormat={"{0}"}
              />
              <TotalItem
                column="totalcharges"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="totalinvoicecost"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Toolbar>
              <Item name="Refresh" location="before">
                <Button
                  icon="refresh"
                  onClick={() => warehouseSummaryStore.getInvoiceData(true)}
                />
              </Item>

              <Tooltip title={`Choose Columns`} arrow placement="left">
                <Item name="columnChooserButton" />
              </Tooltip>
              <Item name="exportButton" />
            </Toolbar>
            <Export enabled={true} />
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(InvoiceChargesSummary));
