import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid } from "rsuite";
import Col4 from "../../Columns/Col4";
import { Statistic } from "antd";
import { Parsed } from "../../../Functions/Pricing/PRKDCalc";

function itemCount(items) {
  let output = { items: 0, qty: 0, cbm: 0 };

  let qty = 0;
  let cbm = 0;
  let onreserve = 0;

  if (items) {
    if (items.length) {
      for (let i = 0; i < items.length; i++) {
        qty += Parsed(items[i].currentplusreserve);
        onreserve += Parsed(items[i].onreserve);
        cbm += Parsed(items[i].totalcbm);
      }
      // qty = items.map((item) => +item.currentplusreserve);
      output = { qty: qty, cbm: cbm, onreserve: onreserve };
    }
  }

  return output;
}

class ItemCountHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let items = this.props.items;
    let output = itemCount(items);

    let totalitems = items.length;
    let totalqty = output.qty;
    let totalcbm = output.cbm;
    let totalonreserve = output.onreserve;

    return (
      <FlexboxGrid justify="space-around">
        <Col4>
          <div
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
              textAlign: "center",
              margin: "2%",
            }}
          >
            <Statistic
              title="Total CBM"
              value={totalcbm}
              precision={3}
              valueStyle={{ color: "#3f8600" }}
            />
          </div>
        </Col4>
        <Col4>
          <div
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
              textAlign: "center",
              margin: "2%",
            }}
          >
            <Statistic
              title="Total Items"
              value={totalitems}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
            />
          </div>
        </Col4>
        <Col4>
          <div
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
              textAlign: "center",
              margin: "2%",
            }}
          >
            <Statistic
              title="Total Quantity"
              value={totalqty}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
            />
          </div>
        </Col4>
        <Col4>
          <div
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
              textAlign: "center",
              margin: "2%",
            }}
          >
            <Statistic
              title="On Reserve"
              value={totalonreserve}
              precision={0}
              valueStyle={{ color: "red" }}
            />
          </div>
        </Col4>
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(ItemCountHeader));
