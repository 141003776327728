import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Tabs } from "antd";
import Div14 from "../../../Components/Dividers/Div14";
import userStore from "../../../Stores/UserStore";
import PasswordReset from "./PasswordReset";
import ProfileHoliday from "./Holiday/ProfileHoliday";
import { Icon, IconButton, Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import CompanyInformation from "./CompanyInformation";

const instance = axios.create({ baseURL: baseURL });

const { TabPane } = Tabs;
class ProfileMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      password: "",
      passconf: "",
      tab: "home",
      locked: false,
      step: 1,
      verificationsent: false,
      loading: false,
    };
    this.sendEmailVerification = this.sendEmailVerification.bind(this);
    this.changeSingle = this.changeSingle.bind(this);
    this.resetSingle = this.resetSingle.bind(this);
    this.lockstate = this.lockstate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
  }
  sendEmailVerification = async () => {
    this.setState({ loading: true });
    await instance
      .get(`auth/basic/sendemailverification/${userStore.user._id}`)
      .then((res) => {
        this.setState({ loading: false, verificationsent: true });

        Notification["success"]({ title: `Email Verification Sent.` });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({ title: `An error occured` });
      });
  };

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }
  resetSingle(type) {
    this.setState({ [type]: userStore.user[type] });
  }

  changeSingle(type, val) {
    if (type === "username") {
      val = val.replace(/[^a-zA-Z1-9]/g, "").toLowerCase();
    }
    this.setState({ [type]: val });
  }
  componentDidMount() {
    this.setState({ username: userStore.user.username });
    userStore.fetchProfile();
  }

  render() {
    let user = userStore.user;
    let tabOrientation = "top";
    return (
      <div style={{ padding: "1%", margin: "1%" }}>
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={tabOrientation}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={<div>User Information</div>}
            key={"home"}
            disabled={this.state.locked}
          >
            <h6>Edit your Profile Information</h6>
            <h6>
              <b>Username: </b> {user.username}
              <br />
              <b>Email: </b> {user.email}
              <br />
              <br />
              <IconButton
                icon={
                  <Icon icon={user.accountVerified ? "check" : "envelope"} />
                }
                size="xs"
                loading={this.state.loading}
                disabled={user.accountVerified || this.state.verificationsent}
                color={user.accountVerified ? "green" : "red"}
                onClick={() => this.sendEmailVerification()}
              >
                {user.accountVerified
                  ? "Email Verified"
                  : this.state.verificationsent
                  ? "Check your Email"
                  : "Send Verification Email"}
              </IconButton>
            </h6>
            <br />
            <PasswordReset />
            <br />
          </TabPane>
          <TabPane
            tab={<div>Access Levels</div>}
            key={"access"}
            disabled={this.state.locked}
          >
            <div>
              <h6>
                <Div14>Access:</Div14>
                {user.jobs && (
                  <div>
                    <b>Edit Jobs</b>
                    <br />
                  </div>
                )}
                {user.finance && (
                  <div>
                    <b>Financial Tabs</b>
                    <br />
                  </div>
                )}
                {user.mgt && (
                  <div>
                    <b>Management Tabs</b>
                    <br />
                  </div>
                )}
                {user.hr && (
                  <div>
                    <b>Human Resources Tabs</b>
                    <br />
                  </div>
                )}
                {user.compadmin && (
                  <div>
                    <b>Company Admin Tab</b>
                    <br />
                  </div>
                )}
                {user.comprequest && (
                  <div>
                    <b>Company Request Tab</b>
                    <br />
                  </div>
                )}
                {user.comprequest && (
                  <div>
                    <b>Warehouse Tabs</b>
                    <br />
                  </div>
                )}
                {user.customclearance && (
                  <div>
                    <b>Custom Clearance Tabs</b>
                    <br />
                  </div>
                )}
                {user.teamleader && (
                  <div>
                    <b>Team Leader Tabs</b>
                    <br />
                  </div>
                )}
                {user.delivery && (
                  <div>
                    <b>Delivery Tabs</b>
                    <br />
                  </div>
                )}
              </h6>
              <hr />
              <h6>Our Admin Staff Will Be Updating Your Access Levels</h6>
            </div>
          </TabPane>
          {user.group === "EMStaff" && (
            <TabPane
              tab={<div>Holidays</div>}
              key={"holidays"}
              disabled={this.state.locked}
            >
              <div>
                <Div14>Holidays:</Div14>
                <ProfileHoliday />
              </div>
            </TabPane>
          )}
          {user.company && (
            <TabPane
              tab={<div>Company Information</div>}
              key={"Company Information"}
              disabled={this.state.locked}
            >
              <CompanyInformation />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(ProfileMain));
