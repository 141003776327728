import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Divider, FlexboxGrid, Icon, IconButton, Loader } from "rsuite";
import Div14 from "../../../Components/Dividers/Div14";
import singleAccountStore from "../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import userStore from "../../../Stores/UserStore";
import { withRouter } from "react-router";
import { Tabs } from "antd";
import AccountInfo from "./SubTabs/AccountInfo";
import StorageACCDash from "../StorageAccount/Dashboard/StorageACCDash/StorageACCDash";
import DeliveryACCDash from "../DeliveryAccount/Dashboard/DeliveryACCDash";

import AIMDashboard from "../AIMAccount/Dashboard/AIMDashboard";
import ACCDashboard from "../ACCAccount/Dashboard/ACCDashboard";
import CombinedCalendar from "./Combined/CombinedCalendar";
import AllAccountInvoices from "./SubTabs/AllAccountInvoices";
import AllAccountPayments from "./SubTabs/AllAccountPayments";

const { TabPane } = Tabs;

class AccountDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Account Info", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
  }

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }

  componentDidMount() {
    singleAccountStore.getAccount(this.props.match.params.clientid);
  }
  componentDidUpdate() {
    if (!singleAccountStore.loading.account) {
      if (singleAccountStore.account._id !== this.props.match.params.clientid) {
        singleAccountStore.getAccount(this.props.match.params.clientid, true);
      }
    }
  }

  render() {
    if (singleAccountStore.loading.account) {
      return <Loader center />;
    } else if (!singleAccountStore.account) {
      return (
        <div>
          <br />
          <Empty description="Account Not Found" />
        </div>
      );
    }

    let account = singleAccountStore.account;

    let hidedel = !account.adel;

    if (account.hidedel & account.ast) {
      hidedel = true;
    }

    return (
      <div>
        <Div14>
          {userStore.user.staffaccount && (
            <>
              <IconButton
                icon={<Icon icon="pencil" />}
                size="xs"
                color="red"
                onClick={() => {
                  singleAccountStore.getAccount(
                    this.props.match.params.clientid,
                    true
                  );
                  this.props.history.push(
                    `/editaccount/${this.props.match.params.clientid}`
                  );
                }}
                circle
              />
              <Divider vertical />
            </>
          )}
          {singleAccountStore.account.name}
          <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            size="xs"
            onClick={() =>
              singleAccountStore.getAccount(
                this.props.match.params.clientid,
                true
              )
            }
            circle
          />
          <br />
          <i style={{ fontWeight: "normal", fontSize: 12 }}>
            Account {singleAccountStore.account.clientNumId} -{" "}
            {singleAccountStore.account.name}
          </i>
        </Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane
            tab={"Calendar"}
            key={"Calendar"}
            disabled={this.state.locked}
          >
            <CombinedCalendar />
          </TabPane>

          {!hidedel && (
            <TabPane
              tab={"Delivery"}
              key={"Delivery"}
              disabled={this.state.locked}
            >
              <FlexboxGrid justify="center">
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="xs"
                  color="blue"
                  onClick={() =>
                    this.props.history.push(
                      `/dac/dash/${singleAccountStore.account._id}`
                    )
                  }
                >
                  Go to Delivery Page
                </IconButton>
              </FlexboxGrid>
              <DeliveryACCDash hidecomp />
            </TabPane>
          )}
          {account.ast && (
            <TabPane
              tab={"Storage"}
              key={"Storage"}
              disabled={this.state.locked}
            >
              <FlexboxGrid justify="center">
                <IconButton
                  icon={<Icon icon="cube" />}
                  size="xs"
                  color="blue"
                  onClick={() =>
                    this.props.history.push(
                      `/sac/dash/${singleAccountStore.account._id}`
                    )
                  }
                >
                  Go to Storage Page
                </IconButton>
              </FlexboxGrid>
              <StorageACCDash hidecomp />
            </TabPane>
          )}
          {account.aim && (
            <TabPane
              tab={"Int. Jobs"}
              key={"Int. Jobs"}
              disabled={this.state.locked}
            >
              <FlexboxGrid justify="center">
                <IconButton
                  icon={<Icon icon="ship" />}
                  size="xs"
                  color="blue"
                  onClick={() =>
                    this.props.history.push(
                      `/aim/dash/${singleAccountStore.account._id}`
                    )
                  }
                >
                  Go to International Move Page
                </IconButton>
              </FlexboxGrid>
              <AIMDashboard hidecomp />
            </TabPane>
          )}
          {account.acc && (
            <TabPane
              tab={"CC. Jobs"}
              key={"CC. Jobs"}
              disabled={this.state.locked}
            >
              <FlexboxGrid justify="center">
                <IconButton
                  icon={<Icon icon="plane" />}
                  size="xs"
                  color="blue"
                  onClick={() =>
                    this.props.history.push(
                      `/acc/dash/${singleAccountStore.account._id}`
                    )
                  }
                >
                  Go to Custom Clearance Page
                </IconButton>
              </FlexboxGrid>
              <ACCDashboard hidecomp />
            </TabPane>
          )}
          <TabPane
            tab={"Invoices"}
            key={"Invoices"}
            disabled={this.state.locked}
          >
            <AllAccountInvoices />
          </TabPane>
          <TabPane
            tab={"Payments"}
            key={"Payments"}
            disabled={this.state.locked}
          >
            <AllAccountPayments />
          </TabPane>
          <TabPane
            tab={"Account Info"}
            key={"Account Info"}
            disabled={this.state.locked}
          >
            <AccountInfo />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(AccountDashboard));
