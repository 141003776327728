import { observer } from "mobx-react";
import moment from "moment";
import axios from "axios";

import React, { Component } from "react";
import {
  IconButton,
  Icon,
  FlexboxGrid,
  Button,
  Notification,
  Col,
} from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Div14 from "../../../../Components/Dividers/Div14";

import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import hrStore from "../../../../Stores/HR/HRStore";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import { Parsed } from "../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class AddHolidayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      staff: [],
      month: moment(),
      date: moment(),
    };
    this.onHide = this.onHide.bind(this);
    this.addRemoveStaff = this.addRemoveStaff.bind(this);
    this.postMultiple = this.postMultiple.bind(this);
    this.editVal = this.editVal.bind(this);
    this.editDays = this.editDays.bind(this);
  }
  editVal(val, label) {
    if (label === "month") val = moment(val).startOf("month");
    if (label === "date") val = moment(val).startOf("day");
    this.setState({ [label]: val });
  }

  editDays(val, label, index) {
    let staff = [...this.state.staff];
    staff[index].monthlyholiday = val;
    this.setState({ staff: staff });
  }

  postMultiple() {
    let staff = this.state.staff.map((st) => {
      return { _id: st._id, days: Parsed(st.monthlyholiday), name: st.name };
    });
    let data = {
      staff: staff,
      date: this.state.date,
      month: this.state.month,
    };

    this.setState({ loading: true });

    return instance
      .post(`/hr/holiday/monthlyallowance`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((hrdoc) => {
        hrStore.getHolidays(true);
        this.onHide();
        this.setState({ loading: false });

        message.success(`Added ${data.staff.length} Staff Holidays`);
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);

        Notification["error"]({
          title: `Error Saving Holidays.`,
        });
      });
  }

  addRemoveStaff(staff, type) {
    let oldst = [...this.state.staff];
    let newst = [];

    if (type === "Add") {
      newst = [...oldst, staff];
    } else if (type === "Remove") {
      newst = oldst.filter((st) => st !== staff);
    }
    this.setState({ staff: newst });
  }
  onHide() {
    this.setState({ show: false, loading: false, staff: [] });
  }
  render() {
    let staff = hrStore.staff;

    let selected = [];
    let unselected = [];
    let indexval = -1;
    for (let i = 0; i < staff.length; i++) {
      indexval = this.state.staff.findIndex((st) => st._id === staff[i]._id);
      if (indexval >= 0) {
        selected.push(staff[i]);
      } else {
        unselected.push(staff[i]);
      }
    }

    let checks = selected.map((st, index) => (
      <FlexboxGrid key={index} style={{ margin: "1%" }}>
        <Col md={16} sm={24} xs={24}>
          <Button
            block
            key={index}
            onClick={() => this.addRemoveStaff(st, "Remove")}
          >
            {st.name} ({st.remaining} + {st.monthlyholiday} ={" "}
            {parseFloat(st.remaining) + parseFloat(st.monthlyholiday)})
          </Button>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <TextInputSTD
            title="Days"
            val={st.monthlyholiday}
            label="monthlyholiday"
            size="large"
            editVal={this.editDays}
            index={index}
          />
        </Col>
      </FlexboxGrid>
    ));
    let unchecks = unselected.map((st, index) => (
      <Button block key={index} onClick={() => this.addRemoveStaff(st, "Add")}>
        {st.name} ({st.remaining} remaining)
      </Button>
    ));

    let disabled = this.state.staff.length <= 0;

    return (
      <div>
        <UnifiedModal
          title="Add Monthly Holidays"
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  loading={this.state.loading}
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  loading={this.state.loading}
                  block
                  disabled={disabled}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => this.postMultiple()}
                >
                  Add Additional Days
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          Add Monthly Holidays to the following staff?
          <FlexboxGrid>
            <Col2>
              <Div14>Remaining:</Div14>
              {unchecks}
            </Col2>
            <Col2>
              <Div14>Included:</Div14>
              {checks}
            </Col2>
          </FlexboxGrid>
          <hr />
          <Col2>
            <DatePickerSTD
              title="Date Added"
              format={"DD-MMM-YY"}
              picker="date"
              val={this.state.date}
              label={"date"}
              size="large"
              editVal={this.editVal}
            />
          </Col2>
          <Col2>
            <DatePickerSTD
              title="Allowance Month"
              format={"MMM-YY"}
              picker="month"
              val={this.state.month}
              label={"month"}
              size="large"
              editVal={this.editVal}
            />
          </Col2>
          <hr />
          <br />
        </UnifiedModal>
        <div style={{ marginLeft: "5%", marginRight: "5%" }}>
          <hr />
          <IconButton
            block
            icon={<Icon icon="plus" />}
            color="green"
            onClick={() => this.setState({ show: true })}
          >
            Add Monthly Holidays
          </IconButton>
        </div>
      </div>
    );
  }
}

export default observer(AddHolidayModal);
