import { observer } from "mobx-react";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock } from "rsuite";
import { Input, Tooltip } from "antd";

import sjStore from "../../../Stores/SideJobs/SJStore";
const { TextArea } = Input;

class SJTextAreaInput extends Component {
  render() {
    let ind = this.props.ind;
    let label = this.props.label;
    let title = this.props.title;
    let load = this.props.load;
    let org;
    let val;

    if (this.props.value) {
      org = this.props.org;
      val = this.props.value;
    } else {
      org = sjStore.sjs[ind][label];
      val = sjStore.esjs[ind][label];
    }

    let sj = this.props.sj;
    if (sj) {
      org = sjStore.sjs[ind][sj][label];
      val = sjStore.esjs[ind][sj][label];
    }

    let read = false;

    let disabled = false;

    if (this.props.read) read = this.props.read;

    if (this.props.disabled) disabled = this.props.disabled;

    let change = false;

    if (org !== val) change = true;

    if (read || disabled) change = false;

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;

    if (quality) {
      if (val === "") {
        flag = true;
        qualitytext = `${title} must be added.`;
      }
    }
    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {change && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(org, label, sj)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: flag && "#ff968c" }}>
              {title}
            </InputGroup.Addon>
          </Tooltip>
          <TextArea
            autoSize={{ minRows: this.props.showlabel ? 1 : 2 }}
            style={{ width: "100%" }}
            value={val}
            readOnly={read}
            onChange={(e) => this.props.editVal(e.target.value, label, sj)}
          />
          {change && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(label, title, sj)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {change && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>"{org}"</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(SJTextAreaInput);
