import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Empty } from "antd";

class DeliveryNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <br />
        <Empty description="Page Not Found" />;
      </div>
    );
  }
}

export default withRouter(observer(DeliveryNotFound));
