import { observer } from "mobx-react";
import React, { Component } from "react";
import { InputGroup } from "rsuite";
import { Select } from "antd";
import { Input } from "antd";

const { Option } = Select;
const { TextArea } = Input;

class JDInput extends Component {
  render() {
    let label = this.props.label;
    let title = this.props.title;
    let opts = this.props.opts;
    let val = this.props.val;
    let type = this.props.type;

    if (type === "Select") {
      let options = opts.map((val, index) => (
        <Option value={val} key={index}>
          {val}
        </Option>
      ));

      return (
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <InputGroup.Addon>{title}</InputGroup.Addon>
            <Select
              showSearch
              value={val}
              style={{ width: "100%" }}
              placeholder={this.props.title}
              optionFilterProp="children"
              onChange={(e) => this.props.editVal(e, label)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options}
            </Select>
          </InputGroup>
        </div>
      );
    } else if (type === "TextArea") {
      return (
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <InputGroup.Addon>{title}</InputGroup.Addon>
            <TextArea
              autoSize={{ minRows: this.props.showlabel ? 1 : 2 }}
              style={{ width: "100%" }}
              value={val}
              onChange={(e) => this.props.editVal(e.target.value, label)}
            />
          </InputGroup>
        </div>
      );
    }
  }
}

export default observer(JDInput);
