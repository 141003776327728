import { computed, makeObservable, observable } from "mobx";
import { Parsed, PRKD } from "../../Functions/Pricing/PRKDCalc";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import axios from "axios";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Notification } from "rsuite";
const instance = axios.create({ baseURL: baseURL });

class ModalACOrderStore {
  constructor() {
    this.sto = {
      client: {},
      location: {},
    };
    this.del = {
      client: { name: "", clientNumId: "" },
      location: {},
      prices: { weekdayprice: 0, weekday: "" },
      toaddress: [],
      addcharge: [],
      discount: [],
      fromaddress: {},
    };
    this.aim = { client: { name: "", clientNumId: "" } };
    this.acc = { client: { name: "", clientNumId: "" } };
    this.loading = { sto: false, aim: false, acc: false, del: false };
    this.loaded = { sto: false };

    makeObservable(this, {
      sto: observable,
      del: observable,
      aim: observable,
      acc: observable,
      loading: observable,
      loaded: observable,
      timechgs: computed,
    });
  }

  // FETCH

  // ----- Computed ----
  get timechgs() {
    let del = this.del;
    let prices = del.prices;

    let charges = [
      {
        title: prices.weekday,
        value: prices.weekdayprice,
        show: PRKD(prices.weekdayprice) > 0,
        unit: "KD",
      },
    ];

    let toadd = del.toaddress;

    for (let i = 0; i < toadd.length; i++) {
      if (i > 0) {
        charges = [
          ...charges,
          {
            title: `Multiple Location Charge (${
              PRKD(1000 * Parsed(del.prices.tripmultiplier)) / 10
            }%): Location ${i + 1}`,
            value:
              Parsed(del.prices.weekdayprice) *
              Parsed(del.prices.tripmultiplier),
            show:
              PRKD(
                Parsed(del.prices.weekdayprice) *
                  Parsed(del.prices.tripmultiplier)
              ) > 0,
            unit: "KD",
          },
        ];
      }
    }

    if (del.prices.timeprice > 0) {
      charges = [
        ...charges,
        {
          title: `${del.prices.timetype} Charge`,
          value: del.prices.timeprice,
          show: true,
          unit: "KD",
        },
      ];
    }
    charges = charges.filter((ch) => ch.show);

    let allcharges = [...charges];

    let loccharges = [];
    if (del.fromaddress.zonecost > 0) {
      loccharges = [
        ...loccharges,
        {
          title: `Pickup: Zone ${del.fromaddress.zonetype} (${del.fromaddress.city}) Delivery Charge`,
          value: del.fromaddress.zonecost,
          show: true,
          unit: "KD",
        },
      ];
    }
    if (del.fromaddress.ngfchg > 0) {
      loccharges = [
        ...loccharges,
        {
          title: `Pickup: Non-Ground Floor Charge`,
          value: del.fromaddress.ngfchg,
          show: true,
          unit: "KD",
        },
      ];
    }

    for (let i = 0; i < toadd.length; i++) {
      if (Parsed(toadd[i].zonecost) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Dropoff ${i + 1}: Zone ${toadd[i].zonetype} (${
              toadd[i].city
            }) Delivery Charge`,
            value: toadd[i].zonecost,
            show: true,
            unit: "KD",
          },
        ];
      }
      if (Parsed(toadd[i].ngfchg) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Dropoff ${i + 1}: Non-Ground Floor Charge`,
            value: toadd[i].ngfchg,
            show: true,
            unit: "KD",
          },
        ];
      }

      if (del.maintype === "Fixed Per Type") {
        if (toadd[i].service) {
          if (toadd[i].service.type) {
            allcharges = [
              ...allcharges,
              {
                title: `Dropoff ${i + 1} Service: ${toadd[i].service.type}`,
                value: toadd[i].service.price,
                show: true,
                unit: "KD",
              },
            ];
          }
        }
      }
    }

    let mainjobcost = 0;

    let totaladdchg = 0;
    if (del.addcharge) {
      for (let i = 0; i < del.addcharge.length; i++) {
        totaladdchg += Parsed(del.addcharge[i].price);
      }
    }

    let totalopts = 0;

    if (del.orderoptions) {
      for (let i = 0; i < del.orderoptions.length; i++) {
        totalopts += Parsed(del.orderoptions[i].price);
      }
    }

    for (let i = 0; i < allcharges.length; i++) {
      mainjobcost += Parsed(allcharges[i].value);
    }

    let totalloccharge = 0;
    if (loccharges) {
      for (let i = 0; i < loccharges.length; i++) {
        totalloccharge += Parsed(loccharges[i].value);
      }
    }
    let totaldiscount = 0;
    if (del.discount) {
      for (let i = 0; i < del.discount.length; i++) {
        totaldiscount += Parsed(del.discount[i].price);
      }
    }

    let finaljobcost =
      Parsed(mainjobcost) +
      Parsed(totalloccharge) +
      Parsed(totaladdchg) +
      Parsed(totalopts) -
      Parsed(totaldiscount);

    if (del.status === "Cancelled") {
      finaljobcost = del.prices.canccost;
      totalopts = 0;
      totaladdchg = 0;
      mainjobcost = 0;
      totalloccharge = 0;
      allcharges = [
        {
          title: `Cancellation Charge`,
          value: finaljobcost,
          show: true,
          unit: "KD",
        },
      ];
      charges = [];
    }

    return {
      finaljobcost: finaljobcost,
      totalopts: totalopts,
      totaladdchg: totaladdchg,
      totalloccharge: totalloccharge,
      totaldiscount: totaldiscount,
      mainjobcost: mainjobcost,
      allcharges: allcharges,
      charges: charges,
      loccharges: loccharges,
    };
  }
  // ----- Functions ----

  selectACC(acc) {
    this.acc = { ...acc };
  }
  selectAIM(aim) {
    this.aim = { ...aim };
  }
  selectDelivery(del) {
    this.del = { ...del };
  }
  selectOrder(acorder) {
    this.sto = { ...acorder };
  }

  getACCFromJob(accorderid) {
    this.loading.acc = true;
    return instance
      .get(`/ac/accorder/get/byid/${accorderid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((order) => {
        this.acc = { ...order };
        this.loading.acc = false;

        console.log("Single ACC Order Fetched");
      })
      .catch((err) => {
        this.loading.acc = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Account.`,
        });
      });
  }
  getAIMfromTrack(aimorderid) {
    this.loading.aim = true;

    return instance
      .get(`/ac/aimorder/get/byid/${aimorderid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((order) => {
        this.aim = { ...order };
        this.loading.aim = false;

        console.log("Single AIM Order Fetched");
      })
      .catch((err) => {
        this.loading.aim = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Account.`,
        });
      });
  }
  getASTfromOrderId(stoorderid) {
    this.loading.sto = true;
    return instance
      .get(`/ac/warehouse/singleacorder/${stoorderid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((order) => {
        this.sto = { ...order.order };
        this.loading.sto = false;

        console.log("Single STO Order Fetched");
      })
      .catch((err) => {
        this.loading.sto = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching STO.`,
        });
      });
  }
  getDELfromOrderId(delorderid) {
    this.loading.del = true;
    return instance
      .get(`/ac/delivery/get/byid/${delorderid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((order) => {
        this.del = { ...order.order };
        this.loading.del = false;

        console.log("Single DEL Order Fetched");
      })
      .catch((err) => {
        this.loading.del = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching DEL.`,
        });
      });
  }
}

const modalACOrderStore = new ModalACOrderStore();
export default modalACOrderStore;
