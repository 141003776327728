import { observer } from "mobx-react";
import React, { Component } from "react";

import newStorageStore from "../../../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

import { Tabs, Spin, Pagination } from "antd";
import { FlexboxGrid, Icon, IconButton, Row } from "rsuite";

import { Parsed } from "../../../../../../../../../Functions/Pricing/PRKDCalc";

import ExistingRow from "./ExistingRow";

import TextInputSTD from "../../../../../../../../../Components/Inputs/STD/TextInputSTD";
import Col2 from "../../../../../../../../../Components/Columns/Col2";
import ModalFooter from "../../../../../../../../../Components/Modal/ModalFooter";
import UnifiedModal from "../../../../../../../../../Components/Modal/UnifiedModal";

const { TabPane } = Tabs;

class ExistingRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      items: [],
      tab: "Existing Items",
      page: 1,
      pageSize: 10,
    };
    this.cancelChanges = this.cancelChanges.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editItem = this.editItem.bind(this);
    this.submitAdd = this.submitAdd.bind(this);
  }
  componentDidMount() {
    newStorageStore.getExistingItems(false, false, this.props.index);
  }
  onHide() {
    this.setState({
      filter: "",
      item: [],
    });
    newStorageStore.changefilter("");
  }
  createNewItem(item) {
    let newitem = { ...item };
    newitem.quantity = 1;
    let items = [...this.state.items, { newitem }];
    this.setState({ item: items });
  }
  cancelChanges() {
    this.onHide();
    this.props.onHide();
  }
  editItem(val, label, index) {
    let newitems = [...this.state.item];
    newitems[index][label] = val;
    this.setState({ item: newitems });
  }
  submitAdd() {
    let oitm = newStorageStore.existsingles.filter((it) => it.newquant > 0);
    let past = newStorageStore.pastsingles.filter((it) => it.newquant > 0);
    oitm = [...oitm, ...past];
    for (let i = 0; i < oitm.length; i++) {
      oitm[i].acItemMoveNumId = "New";
      oitm[i].itemheight = oitm[i].height;
      oitm[i].itemlength = oitm[i].length;
      oitm[i].itemwidth = oitm[i].width;
      oitm[i].itemname = oitm[i].name;
      oitm[i].itemproductid = oitm[i].productid;
      oitm[i].itemserialnumber = oitm[i].serialnumber;
      oitm[i].itemid = oitm[i]._id;
      oitm[i].acitem = { ...oitm[i] };
      oitm[i].quantity = Parsed(oitm[i].newquant);
      oitm[i].actualquantity = Parsed(oitm[i].newquant);
    }

    newStorageStore.addExistingItems(oitm, this.props.index);
    this.cancelChanges();
  }
  render() {
    let data = newStorageStore.existsingles;

    if (newStorageStore.delstoinout.inout === "Out") {
      data = newStorageStore.existsingles.filter((it) => it.currentstock > 0);
    }

    if (this.state.tab === "Past Items") {
      data = newStorageStore.pastsingles;
    }
    if (newStorageStore.filter !== "") {
      data = data.filter((item) =>
        `${item.name} ${item.acItemNumId} ${item.cbm} ${item.serialnumber} ${item.productid}  ${item.dimensions}`
          .toLowerCase()
          .includes(newStorageStore.filter.toLowerCase())
      );
    }

    let showdata = data.slice(
      (this.state.page - 1) * this.state.pageSize,
      this.state.page * this.state.pageSize
    );

    let content = showdata.map((it, index) => (
      <ExistingRow item={it} key={index} index={index} />
    ));

    let selected =
      newStorageStore.existsingles.filter((it) => Parsed(it.newquant) > 0)
        .length +
      newStorageStore.pastsingles.filter((it) => Parsed(it.newquant) > 0)
        .length;

    return (
      <UnifiedModal
        title={`Add Existing Items to Location #${this.props.index + 1}`}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <ModalFooter
            icon={["close", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={[
              "Cancel",
              `Add ${selected} Item${selected !== 1 ? "s" : ""}`,
            ]}
            oC1={() => this.cancelChanges()}
            oC2={() => this.submitAdd()}
            disabled2={selected <= 0}
          />
        }
      >
        <Spin
          spinning={newStorageStore.loading.items}
          size="large"
          tip="Loading..."
          style={{ color: "black" }}
        >
          <div style={{ padding: "1%" }}>
            <Row justify="center" style={{ width: "100%" }}>
              <Tabs
                activeKey={this.state.tab}
                defaultActiveKey={0}
                tabPosition={"top"}
                type="line"
                centered
                tabBarStyle={{ alignText: "center" }}
                onChange={(e) => {
                  newStorageStore.getPastItems(false, false, this.props.index);
                  this.setState({ tab: e });
                }}
              >
                <TabPane
                  tab={`Existing Items`}
                  key={"Existing Items"}
                ></TabPane>
                {newStorageStore.delstoinout.inout === "In" && (
                  <TabPane tab={`Past Items`} key={"Past Items"}></TabPane>
                )}
              </Tabs>
            </Row>
            <FlexboxGrid justify="end">
              <Col2>
                <IconButton
                  icon={<Icon icon="plus" />}
                  disabled={selected <= 0}
                  color="green"
                  block
                  onClick={() => this.submitAdd()}
                >
                  Add {selected} Item{selected !== 1 ? "s" : ""}
                </IconButton>
              </Col2>
              <Col2>
                <TextInputSTD
                  title="Search"
                  label="filter"
                  size="sm"
                  val={newStorageStore.filter}
                  editVal={(val) => newStorageStore.changefilter(val)}
                />
              </Col2>
            </FlexboxGrid>
            <br />
            {data.length > this.state.pageSize && (
              <Pagination
                pageSize={this.state.pageSize}
                total={data.length}
                showQuickJumper
                onChange={(e) => this.setState({ page: e })}
                onShowSizeChange={(current, size) =>
                  this.setState({ pageSize: size })
                }
                pageSizeOptions={[10, 20, 50]}
              />
            )}
            {content}
          </div>
        </Spin>
      </UnifiedModal>
    );
  }
}

export default observer(ExistingRows);
