import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock } from "rsuite";
import { PRKD, Parsed } from "../../../../Functions/Pricing/PRKDCalc";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import Col4 from "../../../../Components/Columns/Col4";
import whmCompanyStore from "../../WHMStores/WHMCompanyStore";

class ExistingRows extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.editVal = this.editVal.bind(this);
  }

  editVal(val, label, index) {
    whmCompanyStore.editPalletItem(val, label, index);
  }
  render() {
    let item = this.props.item;

    let backcolor = "white";
    if (item.newquant > 0) {
      backcolor = "#a3ffab";
    }

    let quant = item.newquant;
    if (quant === "") quant = 0;
    let afterjobqty =
      Parsed(item.currentstock) -
      parseFloat(item.palletized) -
      parseFloat(quant);

    return (
      <div
        style={{
          margin: "2px",
          padding: "1px",
          border: "1px solid black",
          borderRadius: "5px",
          boxShadow: "0px 0px 3px black",
          backgroundColor: backcolor,
        }}
      >
        <FlexboxGrid>
          <Col4>
            Item ID {item.acItemNumId}: {item.name}
            <HelpBlock>Serial# {item.serialnumber}</HelpBlock>
            <HelpBlock>Product ID {item.productid}</HelpBlock>
          </Col4>
          <Col4>
            CBM Per Item: {item.cbm}
            <HelpBlock>Dimensions: {item.dimensions}</HelpBlock>
          </Col4>
          <Col4>
            <h6>Required Quantity:</h6>

            <NumbInputSTD
              size="sm"
              title="Qty:"
              editVal={this.editVal}
              label="newquant"
              index={item.acItemNumId}
              val={item.newquant}
              max={parseFloat(item.currentstock) - parseFloat(item.palletized)}
              min={0}
            />
          </Col4>
          <Col4>
            Stock - Palletized: {item.currentstock - item.palletized}
            <HelpBlock>Stock: {item.currentstock}</HelpBlock>
            <HelpBlock>Palletized: {item.palletized}</HelpBlock>
            <HelpBlock>After New Palletized: {PRKD(afterjobqty)}</HelpBlock>
          </Col4>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(ExistingRows);
