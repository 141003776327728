import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../Components/Loaders/LoadBack";
import whmOldWarehouseStore from "../WHMStores/WHMOldWarehouseStore";
import { Empty, Pagination, message } from "antd";
import SinglePalletAddRow from "./SinglePalletAddRow";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import baseURL from "../../../Static/baseURL/baseURL";
import axios from "axios";
import userStore from "../../../Stores/UserStore";
import whmTruckTripStore from "../WHMStores/WHMTruckTripStore";

const instance = axios.create({ baseURL: baseURL });

class SelectAvailablePallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      page: 1,
      pageSize: 10,
      selected: [],
      loading: false,
    };
    this.editfilt = this.editfilt.bind(this);
    this.changeSelected = this.changeSelected.bind(this);
    this.postAdditionalPallets = this.postAdditionalPallets.bind(this);
  }
  postAdditionalPallets = async (pallets) => {
    let data = { pallets: pallets };

    this.setState({ loading: true });
    await instance
      .put(
        `whm/trucktrip/addpalletstotrip/${this.props.match.params.trucktrip}`,
        data,
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        whmTruckTripStore.getTruckTrip(this.props.match.params.trucktrip, true);

        message.success(`New Pallets Added to Truck Trips`);
        this.props.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };
  changeSelected(pallet) {
    let newpallets = [];
    let selected = this.state.selected;
    if (selected.length <= 0) {
      newpallets = [...newpallets, { ...pallet }];
    } else {
      let found = false;
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].whmPalletNumId === pallet.whmPalletNumId) {
          found = true;
        } else {
          newpallets = [...newpallets, selected[i]];
        }
      }
      if (!found) {
        newpallets = [...newpallets, { ...pallet }];
      }
    }
    this.setState({ selected: newpallets });
  }
  editfilt(val) {
    this.setState({ filter: val });
  }

  render() {
    let data = whmOldWarehouseStore.pallets;

    if (this.state.filter !== "") {
      data = data.filter((item) =>
        `${item.whmPalletNumId}`
          .toLowerCase()
          .includes(this.state.filter.toLowerCase())
      );
    }

    let showdata = data.slice(
      (this.state.page - 1) * this.state.pageSize,
      this.state.page * this.state.pageSize
    );

    let list = showdata.map((it, index) => (
      <SinglePalletAddRow
        pallet={it}
        key={index}
        index={index}
        newpallets={this.state.selected}
        changeSelected={this.changeSelected}
      />
    ));

    if (data.length <= 0) {
      list = <Empty description="No Pallets Remaining" />;
    }
    return (
      <LoadBack loading={whmOldWarehouseStore.loading.pallets}>
        <b>SelectAvailablePallet</b>
        <FlexboxGrid>
          <Col2>
            <TextInputSTD
              title="Search"
              label="filter"
              size="sm"
              val={this.state.filter}
              editVal={(val) => this.editfilt(val)}
            />
          </Col2>
          <Col2>
            {data.length > this.state.pageSize && (
              <Pagination
                pageSize={this.state.pageSize}
                total={data.length}
                showQuickJumper
                onChange={(e) => this.setState({ page: e })}
                onShowSizeChange={(current, size) =>
                  this.setState({ pageSize: size })
                }
                pageSizeOptions={[10, 20, 50]}
              />
            )}
          </Col2>
        </FlexboxGrid>
        <br />
        {list}
        <hr />
        <ModalFooter
          icon={["close", "save"]}
          color={["red", "green"]}
          app={["ghost", "default"]}
          text={["Cancel", `Add ${this.state.selected.length} Pallets`]}
          oC1={() => this.props.onHide()}
          oC2={() => this.postAdditionalPallets(this.state.selected)}
          disabled2={this.state.selected.length <= 0}
          loading1={this.state.loading}
          loading2={this.state.loading}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(SelectAvailablePallet));
