import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { Collapse } from "antd";
import DropoffDeliveryNotePDF from "../../../../../Components/PDFs/Accounts/Deliveries/DropoffDeliveryNotePDF";

const { Panel } = Collapse;

class DeliveryNotes extends Component {
  constructor(props) {
    super(props);
    this.state = { key: "0" };
    this.onChange = this.onChange.bind(this);
  }
  onChange(key) {
    this.setState({ key: key });
  }
  render() {
    let del = newDeliveryStore.newdel;
    let account = newDeliveryStore.account;

    return (
      <div>
        <Div14>
          Dropoff Delivery Note{del.toaddress.length > 1 ? "s" : ""}:
        </Div14>

        <Collapse defaultActiveKey={[""]} onChange={this.onChange} accordion>
          {del.toaddress.map((to, index) => (
            <Panel
              key={index}
              header={`Dropoff Delivery Note (${to.status})`}
              style={{
                backgroundColor:
                  to.status === "Completed" ? "#d3ffcc" : "#fccaca",
              }}
            >
              <DropoffDeliveryNotePDF del={del} client={account} to={to} />
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export default withRouter(observer(DeliveryNotes));
