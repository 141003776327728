import { makeObservable, observable } from "mobx";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../Static/baseURL/baseURL";
import notificationStore from "./NotificationStore";

const instance = axios.create({ baseURL: baseURL });

class UserStore {
  constructor() {
    this.user = {
      group: "", // Guest, Client
      admin: false,
      username: "",
      jobs: false,
      finance: false,
      mgt: false,
      hr: false,
      compadmin: false,
      comprequest: false,
      quality: false,
      warehouse: false,
      customclearance: false,
      teamleader: false,
      delivery: false,
      staffaccount: false,
      whmoveview: false,
      whmoveedit: false,
      company: { aim: false, acc: false, ast: false, adel: false },
      companies: [],
    };
    this.token = "";
    this.signedIn = false;
    this.loading = false;
    this.profileloaded = false;

    makeObservable(this, {
      user: observable,
      token: observable,
      signedIn: observable,
      loading: observable,
      profileloaded: observable,
    });
  }

  // FUNCTIONS:

  setUser(token, user) {
    localStorage.setItem("myToken", token);
    this.token = token;
    console.log("Token Set");
  }

  signIn = () => {
    let duration = moment
      .duration(moment().diff(this.user.lastsignin))
      .asHours();

    if (duration > 1) {
      Notification["success"]({
        title: `Welcome ${this.user.username}.`,
        description: (
          <div>
            Your last Sign In was {moment(this.user.lastsignin).fromNow()}.
          </div>
        ),
      });
    }
    this.signedIn = true;

    this.loading = false;

    console.log("User Signed In");
  };

  registerNewUser = async (userData) => {
    try {
      if (userData.validation) {
        this.loading = true;
        console.log("User Sign In Attempt.");
        const res = await instance.post("/auth/basic/emailregister", userData);
        this.token = res.data.token;
        this.user = res.data.user;

        this.signIn();
        this.setUser(this.token, this.user);
        this.profileloaded = true;
        Notification["success"]({
          title: `Welcome to Easy Move!`,
          description: (
            <div>
              Thank you for creating an account. Our IT staff will get back and
              assign your account to your business.
            </div>
          ),
        });
      } else {
        console.log("NOT VALID");
      }
    } catch (err) {
      console.log(err);
      this.loading = false;
      Notification["error"]({
        title: `Registration Failed!`,
      });
    }
  };

  fetchProfile = async (overide) => {
    if (!overide) {
      if (this.loading || this.profileloaded) {
        return null;
      }
    }
    this.loading = true;

    return instance
      .get(`/auth/basic/profile`, {
        headers: { authtoken: this.token },
      })
      .then((res) => res.data)
      .then((res) => {
        this.user = res;

        console.log("Profile Fetched");
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  };

  loginUser = async (userData) => {
    try {
      if (userData.validation) {
        this.loading = true;
        console.log("User Sign In Attempt.");
        const res = await instance.post("/auth/basic/login", userData);

        this.token = res.data.token;
        this.user = res.data.user;

        notificationStore.getNotifications(true);

        this.profileloaded = true;
        this.signIn();
        this.setUser(this.token, this.user);
      } else {
        console.log("NOT VALID");
      }
    } catch (err) {
      console.log(err);

      this.loading = false;
      Notification["error"]({
        title: `Login Failed!`,
      });
    }
  };

  logoutUser() {
    delete axios.defaults.headers.common.Authorization;
    this.token = "";
    this.user = { group: "Visitor", username: "" };
    this.signedIn = false;
    localStorage.removeItem("myToken");
    console.log("User Logged Out");
    Notification["info"]({ title: "User Logged Out." });
  }

  checkForToken(url) {
    this.loading = true;
    console.log("Checking Tokens");
    const token = localStorage.getItem("myToken");

    if (token) {
      console.log("Found Token");
      const user = jwt_decode(token);
      this.setUser(token, user);
      return instance
        .get(`/auth/basic/profile`, {
          headers: { authtoken: token, urlcode: url },
        })
        .then((res) => res.data)
        .then((res) => {
          this.user = res;

          notificationStore.getNotifications(true);
          this.profileloaded = true;
          this.signIn();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.logoutUser();
        });
    } else {
      console.log("No Token");
      this.loading = false;
    }
  }

  changeQuality() {
    this.user.quality = !this.user.quality;
  }

  changeTempRole(label) {
    this.user[label] = !this.user[label];
  }
}

const userStore = new UserStore();
export default userStore;
