import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class CompleteDelStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.address = { _id: "", items: [], service: {}, delSignature: {} };
    this.delCont = {
      remarks: "",
      maintype: "Fixed Per Trip",
      fixedweekly: 0,
      freeweeklytrips: 0,

      pricepertruck: 35,
      dropoffmult: 1.2,
      staffcount: 0,
      carcount: 1,
      maxcbmpertrip: 0,

      nongroundfloorprice: 10,

      cancelhours: 8,
      cancelprice: 40,
      timetoreq: 24,
      afterhourfee: 20,
      expresstime: 8,
      expressfee: 20,

      pricepertrip: {
        sun: 20,
        mon: 20,
        tue: 20,
        wed: 20,
        thu: 20,
        fri: 20,
        sat: 20,
      }, // Price Per Job
      zoneadd: {
        z1: 0,
        z2: 0,
        z3: 0,
        z4: 0,
        z5: 0,
      }, // Price Per Job

      excludedhours: {
        0: "No",
        1: "No",
        2: "No",
        3: "No",
        4: "No",
        5: "No",
        6: "After",
        7: "After",
        8: "Yes",
        9: "Yes",
        10: "Yes",
        11: "Yes",
        12: "Yes",
        13: "Yes",
        14: "Yes",
        15: "Yes",
        16: "Yes",
        17: "Yes",
        18: "After",
        19: "After",
        20: "After",
        21: "After",
        22: "No",
        23: "No",
        sun: "Yes",
        mon: "Yes",
        tue: "Yes",
        wed: "Yes",
        thu: "Yes",
        fri: "No",
        sat: "After",
      },
    };
    this.newdel = {
      remarks: "",
      status: "Requested",
      jobdate: moment().set({ second: 0 }),
      createdAt: moment().set({ second: 0 }),
      maintype: "",
      fromaddress: {
        selected: "New",
        type: "",
        city: "",
        floor: 0,
        address: "",
        name: "",
        phone: "",
        zonecost: 0,
        zonetype: "",
        ngfchg: 0,
      },
      toaddress: [
        {
          selected: "New",
          type: "",
          city: "",
          floor: 0,
          address: "",
          name: "",
          phone: "",
          service: {},
          orderoptions: [],
          items: [],
          price: {
            zonecost: 0,
            zonetype: "",
            ngfchg: 0,
          },
        },
      ],
      prices: {
        tripmultiplier: 0,
        tripprice: 0,
        ppt: 0,
        typecost: 0,
        delday: 0,
      },
      addcharge: [],
      discount: [],
      orderoptions: [],
    };

    this.toaddress = { _id: "", name: "" };
    this.loading = false;

    makeObservable(this, {
      account: observable,
      delCont: observable,
      loading: observable,
      address: observable,
      toaddress: observable,
    });
  }

  // FETCH

  getSingleACOrder(deliveryid, override) {
    let loaded = false;
    let oID = this.newdel._id;
    if (deliveryid) oID = deliveryid;
    if (oID === this.newdel._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading = true;

      return instance
        .get(`/ac/delivery/get/byid/${oID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.account;
          this.newdel = { ...order.order };
          this.delCont = order.account.deliveryContract;
          this.address = { ...order.order.fromaddress };

          this.loading = false;
          console.log("Pickup AC Order Data Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getSingleACOrderFromTo(toaddressid, override) {
    let loaded = false;
    let oID = this.toaddress._id;
    if (toaddressid) oID = toaddressid;
    if (oID === this.toaddress._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      if (toaddressid) this.toaddress._id = oID;
      this.loading = true;

      return instance
        .get(`/ac/delivery/get/fromtoid/${oID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.delivery.account;
          this.newdel = { ...order.delivery.order };
          this.delCont = order.delivery.account.deliveryContract;
          this.toaddress = order.toaddress;

          this.toaddress = order.toaddress;
          this.loading = false;
          console.log("Dropoff Delivery from To Address Data Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Dropoff from To Address.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  startLoading() {
    this.loading = true;
  }
}

const completeDelStore = new CompleteDelStore();
export default completeDelStore;
