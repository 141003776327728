import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import acStorStatStore from "../../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";
import LoadBack from "../../../../../../../../Components/Loaders/LoadBack";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import FullItemListTable from "./MovementDetails/FullItemListTable";

import { FlexboxGrid } from "rsuite";
import Col4 from "../../../../../../../../Components/Columns/Col4";
import { Statistic } from "antd";

import { PRKD } from "../../../../../../../../Functions/Pricing/PRKDCalc";
import moment from "moment";

class UnmovedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    acStorStatStore.getStaticItems();
  }

  render() {
    let startdate = acStorStatStore.startdate;
    let enddate = acStorStatStore.enddate;
    let monthdiff = moment(enddate).diff(startdate, "month") + 1;
    let daysdiff = moment(enddate).diff(startdate, "days") + 1;
    return (
      <LoadBack loading={acStorStatStore.loading.staticitems}>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title={`Months (${daysdiff} days)`}
                precision={0}
                value={PRKD(monthdiff)}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Static Items"
                value={PRKD(acStorStatStore.addmovestats.staticitems.length)}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Static CBM"
                value={PRKD(acStorStatStore.addmovestats.staticcbm)}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Stagnant Monthly Price (KD)"
                value={PRKD(acStorStatStore.addmovestats.monthlyprice)}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <Div14>Full Item Movement List</Div14>
        <FullItemListTable />
      </LoadBack>
    );
  }
}

export default withRouter(observer(UnmovedItems));
