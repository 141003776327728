import { Box } from "@material-ui/core";
import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { IconButton, Icon, FlexboxGrid } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Col4 from "../../../../Components/Columns/Col4";
import Div14 from "../../../../Components/Dividers/Div14";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import QRPrint from "../../../../Components/QRPages/QRPrint";
import itemQRStore from "../../../../Stores/Account/ItemQRStore";

class MultipleQRs extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, newId: "" };
    this.changeVal = this.changeVal.bind(this);
  }

  componentDidMount() {
    itemQRStore.singleItemFetch(this.props.match.params.acordernumid, "Order");
  }

  changeVal(val) {
    this.setState({ newId: val });
  }
  render() {
    let content;
    let allitems = itemQRStore.items;
    let empty = (
      <div>
        <Empty description="No Items Selected">
          <IconButton
            icon={<Icon icon="left" />}
            onClick={() => this.props.history.push("/ac")}
          >
            Go Back To Company Page
          </IconButton>
        </Empty>
      </div>
    );
    let searchwell = (
      <Col2>
        <Col2>
          <NumbInputSTD
            title="Order ID"
            val={this.state.newId}
            label="actualquantity"
            size="large"
            width="100%"
            min={0}
            max={100000}
            editVal={this.changeVal}
          />
          <br />
        </Col2>
        <Col2>
          <IconButton
            icon={<Icon icon="cube" />}
            color="green"
            block
            disabled={this.state.newId === "" || this.state.newId === 0}
            onClick={() =>
              itemQRStore.singleItemFetch(this.state.newId, "Order")
            }
          >
            QR for Item ID# {this.state.newId}
          </IconButton>
          <br />
        </Col2>
      </Col2>
    );
    let header = (
      <FlexboxGrid>
        <Col4>
          <IconButton
            icon={<Icon icon="left" />}
            block
            color="red"
            onClick={() => this.setState({ step: 0 })}
          >
            Go Back
          </IconButton>
          <br />
        </Col4>
        <Col4>
          <IconButton
            icon={<Icon icon="print" />}
            color="green"
            onClick={() => window.print()}
          >
            Print QR Codes
          </IconButton>
          <br />
        </Col4>

        {searchwell}
      </FlexboxGrid>
    );
    if (this.state.step === 0) {
      header = (
        <FlexboxGrid>
          <Col4>
            <IconButton
              icon={<Icon icon="left" />}
              block
              color="red"
              onClick={() => this.props.history.push("/sacstaff/dash")}
            >
              Go Back
            </IconButton>
            <br />
          </Col4>
          <Col4>
            <IconButton
              icon={<Icon icon="print" />}
              block
              color="green"
              loading={this.state.step === 1}
              onClick={() => this.setState({ step: 1 })}
            >
              Print QR Codes
            </IconButton>

            <br />
          </Col4>

          {searchwell}
        </FlexboxGrid>
      );
    }
    if (allitems.length > 0) {
      content = (
        <div>
          <Div14>Select Items to Print</Div14>
          {allitems.map((item, index) => (
            <FlexboxGrid
              key={index}
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                boxShadw: "1px 1px 5px black",
                marginBottom: "5px",
              }}
            >
              <Col4>
                {item.acItemNumId}: {item.name}
              </Col4>
              <Col4>{item.serialnumber}</Col4>
              <Col4>{item.productid}</Col4>
              <Col4>
                <NumbInputSTD
                  title="Quantity"
                  val={item.actualquantity}
                  label={index}
                  size="large"
                  min={0}
                  editVal={itemQRStore.editAllItem}
                />
              </Col4>
            </FlexboxGrid>
          ))}
          <hr />
        </div>
      );
      if (this.state.step === 1) {
        let printitems = [];
        for (let i = 0; i < allitems.length; i++) {
          if (allitems[i].actualquantity > 0) {
            for (let j = 0; j < allitems[i].actualquantity; j++) {
              printitems = [...printitems, allitems[i]];
            }
          }
        }
        if (printitems.length > 0) {
          let qrs = printitems.map((item, index) => (
            <QRPrint
              item={item}
              companyname={itemQRStore.company}
              key={index}
            />
          ));
          content = qrs;
        } else {
          content = empty;
        }
      }
    } else {
      content = empty;
    }

    return (
      <LoadBack loading={itemQRStore.loading}>
        <Box displayPrint="none">
          <br />
          {header}
        </Box>
        {content}
      </LoadBack>
    );
  }
}

export default withRouter(observer(MultipleQRs));
