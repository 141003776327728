import { DatePicker } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { InputGroup, Icon, Divider } from "rsuite";

class DatePickerINPB extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;
    let label = this.props.label;
    let val = this.props.val;

    let size = "large";
    if (this.props.size) size = this.props.size;

    let picker = "date";
    if (this.props.picker) picker = this.props.picker;
    let format = "DD-MMM-YY";
    if (this.props.format) format = this.props.format;

    let butdisable = this.props.butdisable;
    let butcolor = "green";
    if (this.props.butcolor) butcolor = this.props.butcolor;
    let buticon = "check";
    if (this.props.buticon) buticon = this.props.buticon;
    let ind = this.props.ind;
    let load = this.props.load;
    let buttext;
    if (this.props.buttext)
      buttext = (
        <>
          <Divider vertical />
          {this.props.buttext}
        </>
      );

    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <DatePicker
          allowClear={false}
          style={{ width: "100%" }}
          //format={"DD-MMM-YY @ HA"}
          format={format}
          value={moment(val)}
          disabled={disabled}
          size={size}
          picker={picker}
          showMinute={false}
          onChange={(date) => {
            this.props.editVal(date, label);
          }}
        />
        <InputGroup.Button
          color={butcolor}
          loading={load}
          disabled={butdisable}
          onClick={() => this.props.updateVal(label, title, ind)}
        >
          <Icon icon={buticon} />
          {buttext}
        </InputGroup.Button>
      </InputGroup>
    );
  }
}

export default DatePickerINPB;
