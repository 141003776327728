import React, { Component } from "react";
import { message, Tabs } from "antd";
import { observer } from "mobx-react";
import Div14 from "../../../../../../Components/Dividers/Div14";

import STPrice from "./STPrice";
import { FlexboxGrid, Notification } from "rsuite";
import SJSelectInput from "../../../../../../Components/Inputs/SJ/SJSelectInput";
import Col2 from "../../../../../../Components/Columns/Col2";
import statuslist from "../../../../../../Static/Lists/StatusList";
import ServicesTab from "../Others/Services/ServicesTab";
import AddChargeTab from "../Others/AddCharge/AddChargeTab";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import ItemTab from "../Others/Items/ItemTab";
import JobDateTab from "../Others/JobDate/JobDateTab";
import AddAttTemplate from "../../Attachments/Attachment/Additional/AddAttTemplate";
import { STPriceCalc } from "../../../../../../Functions/Pricing/STPriceCalc";

import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import DraftEditor from "../../../../../../Components/Inputs/SJ/DraftEditor";
import userStore from "../../../../../../Stores/UserStore";
import QBadge from "../../../../../../Components/Quality/QBadge";
import { STQualityMain } from "../../../../../../Functions/Quality/STQualityCalc";
import { JDQualityCalc } from "../../../../../../Functions/Quality/SupportingQuality/JDQualityCalc";
import { AttQualityCalc } from "../../../../../../Functions/Quality/SupportingQuality/ATTQualityCalc";
import QualityMain from "../Others/Quality/QualityMain";
import SJTextAreaInput from "../../../../../../Components/Inputs/SJ/SJTextAreaInput";
import cancreasonlist from "../../../../../../Static/Lists/CancellationList";
import FlexboxButton from "../../../../../../Components/Modal/FlexboxButton";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import singleJobStore from "../../../../../../Stores/SingleJobStore";

const { TabPane } = Tabs;
const instance = axios.create({ baseURL: baseURL });

class SJSTTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "home", locked: false, step: 1, loading: false };
    this.lockstate = this.lockstate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.postStorageAdd = this.postStorageAdd.bind(this);
  }

  postStorageAdd = async () => {
    console.log("Add Storage");

    let ind = this.props.ind;
    let esj = sjStore.esjs[ind];

    let url = `job/sidejobs/sjs/newsttoexistingsj/${esj._id}`;
    let data = {};
    this.setState({ loading: true });
    await instance
      .post(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.getSJs(singleJobStore.job._id, true);
        this.setState({ loading: false });
        message.success(`Storage Added to Job.`);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let load = this.props.load;
    let tabOrientation = "left";
    if (window.innerWidth < 800) tabOrientation = "top";

    let sj = sjStore.sjs[ind];

    let price = STPriceCalc(sj);

    let addcharges = price[2];

    let datelength = sj.jobdate.length;
    let itemlength = sj.items.length;

    let quality = userStore.user.quality;

    let storagejobsec;
    if (sj.st === null) {
      storagejobsec = (
        <FlexboxButton
          icon="cube"
          text="Create Storage Details"
          color="green"
          size="large"
          loading={false}
          oC={() => {
            this.postStorageAdd();
          }}
        />
      );
    } else {
      storagejobsec = (
        <>
          <STPrice
            ind={ind}
            load={this.props.load}
            editVal={this.props.editVal}
            updateVal={this.props.updateVal}
            quality={quality}
            editview={editview}
          />
        </>
      );
    }

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <b>{PRKD(price[0])} KD Total </b>
          {addcharges > 0
            ? `   = ${PRKD(price[1])} KD Job + ${PRKD(price[2])} KD Additional`
            : ""}
          {" / "}(
          {`${PRKD(price[3])} KD per ${price[5]} for ${PRKD(price[4])} ${
            price[5]
          }s`}
          )
        </div>
        <FlexboxGrid>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="status"
              title="Status"
              load={load.status}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={statuslist}
              read={!editview}
            />
            <br />
            {sj.status === "Cancelled" && (
              <SJSelectInput
                ind={ind}
                label="cancreason"
                title="Canc. Reason"
                load={load.cancreason}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                opts={cancreasonlist}
                read={!editview}
              />
            )}
          </Col2>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="storagestatus"
              title="Storage Status"
              load={load.storagestatus}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={["Planned", "In Storage", "Items Removed"]}
              sj="st"
              read={!editview}
            />
            <br />
            {sj.status === "Cancelled" && (
              <SJTextAreaInput
                ind={ind}
                label="cancremarks"
                title="Canc. Remarks"
                load={load.cancremarks}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                read={!editview}
              />
            )}
          </Col2>
        </FlexboxGrid>

        <br />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={tabOrientation}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={
              <div>
                Job Details {quality && <QBadge value={STQualityMain(sj)[0]} />}
              </div>
            }
            key={"home"}
            disabled={this.state.locked}
          >
            <Div14>
              Pricing ({PRKD(price[1])} KD
              {addcharges > 0 ? ` + ${addcharges} KD Additional)` : ")"}
            </Div14>
            {storagejobsec}
            <br />
            <Div14>Services</Div14>
            <ServicesTab ind={ind} editview={editview} />

            <Div14>Additional Charges (+ {PRKD(addcharges)} KD)</Div14>
            <AddChargeTab ind={ind} editview={editview} />
          </TabPane>
          <TabPane
            tab={
              <div>
                {datelength} In/Out{datelength !== 1 ? "s" : ""}{" "}
                {quality && <QBadge value={JDQualityCalc(sj)[0]} />}
              </div>
            }
            key={1}
            disabled={this.state.locked}
          >
            <JobDateTab ind={ind} quality={quality} editview={editview} />
          </TabPane>
          <TabPane
            tab={`${itemlength} Item${itemlength !== 1 ? "s" : ""}`}
            key={3}
            disabled={this.state.locked}
          >
            <ItemTab ind={ind} editview={editview} />
          </TabPane>
          <TabPane
            tab={
              <div>
                Documents {quality && <QBadge value={AttQualityCalc(sj)[0]} />}
              </div>
            }
            key={4}
            disabled={this.state.locked}
          >
            <AddAttTemplate
              ind={ind}
              attType={"Additional"}
              quality={quality}
              editview={editview}
            />
          </TabPane>

          {editview && (
            <TabPane tab={`Inv Notes`} key={6} disabled={this.state.locked}>
              <DraftEditor
                label="invnotes"
                title="Invoice Notes"
                load={load.invnotes}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                ind={ind}
              />
            </TabPane>
          )}
          {quality && (
            <TabPane tab={`Quality`} key={10} disabled={this.state.locked}>
              <QualityMain sj={sj} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default observer(SJSTTabs);
