import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import SinglePalletModal from "../SinglePalletModal/SinglePalletModal";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";
import { Divider, Icon, IconButton } from "rsuite";
import TruckTripView from "../TruckTrip/TruckTripView/TruckTripView";
import whmTruckTripStore from "../WHMStores/WHMTruckTripStore";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import StackModal from "./StackModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import MaterialTable from "material-table";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import { message } from "antd";
import whmNewWarehouseStore from "../WHMStores/WHMNewWarehouseStore";

const instance = axios.create({ baseURL: baseURL });

class WHMNewWarehouseUnloadTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showtt: false,
      showstack: false,
      showunstack: false,
      pallet: { _id: "", whmPalletNumId: "" },
    };
    this.onHide = this.onHide.bind(this);
    this.onEdit = this.onEdit.bind(this);

    this.QTYColumn = this.QTYColumn.bind(this);
    this.CBMColumn = this.CBMColumn.bind(this);

    this.ShowPalletModal = this.ShowPalletModal.bind(this);
    this.TruckTripColumn = this.TruckTripColumn.bind(this);
    this.StackOption = this.StackOption.bind(this);
    this.UnStackOption = this.UnStackOption.bind(this);
  }
  removeLocationTag = async (palletid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/pallets/removepalletlocation/${palletid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(
          `Successfully Unstacked Pallet and Removed Item Locations`
        );
        whmNewWarehouseStore.getStacked(true);
        whmNewWarehouseStore.getPallets(true);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };
  StackOption(cellData) {
    return (
      <IconButton
        icon={<Icon icon="plus" />}
        size="xs"
        color="green"
        onClick={() =>
          this.setState({ pallet: cellData.data, showstack: true })
        }
      >
        Stack
      </IconButton>
    );
  }
  UnStackOption(cellData) {
    return (
      <IconButton
        icon={<Icon icon="close-circle" />}
        size="xs"
        color="red"
        onClick={() =>
          this.setState({ pallet: cellData.data, showunstack: true })
        }
      >
        Un-Stack
      </IconButton>
    );
  }
  ShowPalletModal(cellData) {
    let data = cellData.data;
    if (data._id) {
      return (
        <IconButton
          icon={<Icon icon="eye" />}
          circle
          color="green"
          size="xs"
          onClick={() => {
            whmSinglePalletStore.getPallet(data._id);
            this.setState({ show: true, pallet: data });
          }}
        />
      );
    }
    return <></>;
  }
  TruckTripColumn(cellData) {
    if (cellData.data.whmtrucktrip) {
      if (cellData.data.whmtrucktrip._id) {
        let tt = cellData.data.whmtrucktrip;
        return (
          <>
            # {tt.whmTruckTripNumId}
            <Divider vertical />
            <IconButton
              color="green"
              icon={<Icon icon="truck" />}
              size="xs"
              circle
              onClick={() => {
                whmTruckTripStore.getTruckTrip(cellData.data.whmtrucktrip._id);
                this.setState({ showtt: true });
              }}
            />
          </>
        );
      }
    }
  }
  QTYColumn(cellData) {
    let data = cellData.data.qty;
    let qty = 0;
    if (data > 0) return PRKD(qty);
    return "-";
  }
  CBMColumn(cellData) {
    let data = cellData.data.cbm;
    let cbm = 0;
    if (data > 0) return PRKD(cbm);
    return "-";
  }

  onHide() {
    this.setState({
      show: false,
      showtt: false,
      showstack: false,
      showunstack: false,
      pallet: { _id: "", whmPalletNumId: "" },
    });
  }
  onEdit(pallet) {
    this.props.history.push(`/whm/company/${pallet.client._id}`);
    this.onHide();
  }

  render() {
    let data = this.props.data;

    let columnnames = [
      { title: "ID", field: "acitem.acItemNumId", defaultSort: "desc" },
      { title: "Quantity", field: "quantity" },
      { title: "Name", field: "acitem.name" },
      {
        title: "Loc",
        field: "acitem.loctags",
        render: (rowData) => rowData.acitem.loctags.join(", "),
      },
      { title: "Serial", field: "acitem.serialnumber" },
      {
        title: "CBM",
        field: "totalcbm",
        render: (rowData) => PRKD(rowData.totalcbm),
      },
    ];
    return (
      <div>
        <UnifiedModal
          title={`Unstack Pallet #${this.state.pallet.whmPalletNumId}`}
          show={this.state.showunstack}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "undo"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Unstack Pallet"]}
              oC1={() => this.onHide()}
              oC2={() => this.removeLocationTag(this.state.pallet._id)}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>Are you sure you want to unstack this Pallet?</h3>
          <hr />
          <h5>
            The full item list within this pallet will have their location tags
            modified.
          </h5>

          <br />
          <MaterialTable
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={this.state.pallet.itempallet}
            title={null}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              search: false,
              toolbar: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: false,
              padding: "dense",
            }}
          />
        </UnifiedModal>

        <StackModal
          show={this.state.showstack}
          onHide={this.onHide}
          pallet={this.state.pallet}
        />
        <TruckTripView show={this.state.showtt} onHide={this.onHide} />
        <SinglePalletModal
          show={this.state.show}
          onHide={this.onHide}
          actionbut={true}
          actionbuttext="Go To Company"
          onEdit={this.onEdit}
        />
        <DataGrid
          dataSource={data}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <Selection mode="single" />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column
            caption="View"
            alignment="center"
            width={40}
            cellRender={this.ShowPalletModal}
          />
          <Column
            dataField="whmPalletNumId"
            caption="ID"
            alignment="center"
            width={80}
          />
          <Column
            dataField="clientname"
            caption="Client"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            width={80}
          />
          <Column
            dataField="items"
            caption="Items"
            alignment="center"
            width={80}
          />
          <Column dataField="qty" caption="QTY" alignment="center" width={80} />
          <Column
            dataField="cbm"
            caption="CBM"
            alignment="center"
            width={80}
            format={"0.000"}
          />
          {this.props.prepared && (
            <Column
              dataField="createdAt"
              caption="Prepared"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.loaded && (
            <Column
              dataField="loadeddate"
              caption="Loaded"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.loaded && (
            <Column
              dataField="whmtrucktrip.whmTruckTripNumId"
              caption="Truck Trip #"
              alignment="center"
              width={100}
              cellRender={this.TruckTripColumn}
            />
          )}
          {this.props.unloaded && (
            <Column
              dataField="unloadeddate"
              caption="UnLoaded"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.stacked && (
            <Column
              dataField="stackeddate"
              caption="Stacked"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.stacked && (
            <Column
              dataField="locationtag"
              caption="Location"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {!this.props.stacked && (
            <Column
              caption="UnStack"
              alignment="center"
              width={100}
              cellRender={this.StackOption}
            />
          )}
          {this.props.stacked && (
            <Column
              caption="Stack"
              alignment="center"
              width={100}
              cellRender={this.UnStackOption}
            />
          )}
          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(WHMNewWarehouseUnloadTable));
