import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmTruckTripStore from "../../WHMStores/WHMTruckTripStore";
import TruckTripSummary from "../TruckTripSummary";
import Div14 from "../../../../Components/Dividers/Div14";
import TruckTripPalletTable from "../TruckTripPalletTable";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";

import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";

import Col3 from "../../../../Components/Columns/Col3";

class TruckTripView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showdelete: false,
      showcomplete: false,
      showcancel: false,
    };
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    this.setState({
      show: false,
      showdelete: false,
      showcomplete: false,
      showcancel: false,
    });
  }

  render() {
    let data = whmTruckTripStore.trucktrip;
    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.onHide}
        title={`Truck Trip # ${data.whmTruckTripNumId} Summary`}
        footer={
          <FlexboxGrid justify="end">
            <Col3>
              <IconButton
                icon={<Icon icon="close" />}
                onClick={() => this.props.onHide()}
                color="red"
                appearance="ghost"
                block
              >
                Close
              </IconButton>
            </Col3>
          </FlexboxGrid>
        }
      >
        <TruckTripSummary
          hideTitle
          truck={data.whmtruck}
          trucktrip={data}
          loading={whmTruckTripStore.loading.trucktrip}
        />
        <Div14>
          Truck Trip Pallet List <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() =>
              whmTruckTripStore.getTruckTrip(
                this.props.match.params.trucktrip,
                true
              )
            }
            size="xs"
            circle
          />
        </Div14>

        <TruckTripPalletTable data={data.pallets} hideActions />
        <br />
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(TruckTripView));
