import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Descriptions, Empty } from "antd";
import Div14 from "../../../../../../Components/Dividers/Div14";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { Col, Divider, FlexboxGrid, HelpBlock, Icon } from "rsuite";
import { IconButton, Input, InputGroup } from "rsuite";
import Col3 from "../../../../../../Components/Columns/Col3";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import { Tabs } from "antd";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../../../Components/Columns/Col2";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import SearchIcon from "@rsuite/icons/Search";
import TextInputSTD from "../../../../../../Components/Inputs/STD/TextInputSTD";
import NumbInputSTD from "../../../../../../Components/Inputs/STD/NumbInputSTD";
import {
  DataSheetGrid,
  floatColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";

import "react-datasheet/lib/react-datasheet.css";

const { TabPane } = Tabs;

class ItemsListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoExpandAll: true,
      type: "New",
      filter: "",
      tab: 0,
      itemind: -1,
      grid: [
        {
          name: "",
          serialnumber: "",
          length: 1,
          width: 1,
          height: 1,
          quantity: 1,
        },
      ],
      item: {
        name: "",
        serialnumber: "",
        length: 1,
        width: 1,
        height: 1,
        quantity: 1,
      },
      show: false,
      showbulk: false,
      loading: false,
    };
    this.EditActionButton = this.EditActionButton.bind(this);
    this.DeleteActionButton = this.DeleteActionButton.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editItem = this.editItem.bind(this);
    this.editGrid = this.editGrid.bind(this);
    this.newItem = this.newItem.bind(this);
  }

  editGrid(e) {
    this.setState({ grid: e });
  }

  newItem(tab) {
    if (tab === "Bulk") {
      this.setState({
        showbulk: true,
        grid: [
          {
            name: "",
            serialnumber: "",
            length: 1,
            width: 1,
            height: 1,
            quantity: 1,
          },
        ],
      });
    } else {
      this.setState({
        show: true,
        type: "New",
        tab: tab,
        itemind: -1,
        item: {
          name: "",
          serialnumber: "",
          length: 1,
          width: 1,
          height: 1,
          quantity: 1,
        },
      });
    }
  }
  editItem(data, label) {
    let item = { ...this.state.item };
    item[label] = data;
    this.setState({ item: item });
  }
  onHide() {
    this.setState({
      show: false,
      type: "New",
      item: {
        name: "",
        serialnumber: "",
        length: 1,
        width: 1,
        height: 1,
        quantity: 1,
      },
      showbulk: false,
      grid: [
        {
          name: "",
          serialnumber: "",
          length: 1,
          width: 1,
          height: 1,
          quantity: 1,
        },
      ],
    });
  }

  EditActionButton(cellData) {
    return (
      <IconButton
        icon={<Icon icon="edit" />}
        color="green"
        circle
        size="xs"
        onClick={() => {
          this.setState({
            item: cellData.data,
            show: true,
            type: "Edit",
            tab: "New",
            itemind: cellData.rowIndex,
          });
        }}
      />
    );
  }

  DeleteActionButton(cellData) {
    return (
      <IconButton
        icon={<Icon icon="trash" />}
        color="red"
        circle
        size="xs"
        onClick={() => {
          this.setState({ show: true });
          this.props.removeItem(cellData.rowIndex);
          this.setState({ show: false });
          console.log("Remove Item");
        }}
      />
    );
  }
  Dimension(cellData) {
    return `${PRKD(cellData.data.length / 100)} m x ${PRKD(
      cellData.data.width / 100
    )} m x ${PRKD(cellData.data.height / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.quantity) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  render() {
    const columns = [
      { ...keyColumn("name", textColumn), title: "Item Name*" },
      { ...keyColumn("serialnumber", textColumn), title: "Serial No.*" },
      { ...keyColumn("length", floatColumn), title: "L (cm)" },
      { ...keyColumn("width", floatColumn), title: "W (cm)" },
      { ...keyColumn("height", floatColumn), title: "H (cm)" },
      { ...keyColumn("quantity", floatColumn), title: "Qty*" },
    ];
    let to = this.props.to;
    let item = this.state.item;
    let disable = false;
    if (item.name === "") disable = true;
    if (item.serialnumber === "") disable = true;
    let griddisabled = false;
    let grid = this.state.grid;
    for (let i = 0; i < grid.length; i++) {
      if (grid[i].quantity === null) griddisabled = true;
      if (grid[i].length === null) griddisabled = true;
      if (grid[i].length === "") griddisabled = true;
      if (grid[i].width === null) griddisabled = true;
      if (grid[i].width === "") griddisabled = true;
      if (grid[i].height === null) griddisabled = true;
      if (grid[i].height === "") griddisabled = true;
      if (grid[i].name === null) griddisabled = true;
      if (grid[i].name === "") griddisabled = true;
      if (grid[i].serialnumber === null) griddisabled = true;
      if (grid[i].serialnumber === "") griddisabled = true;
    }

    let stditems = newDeliveryStore.standarditems;
    let filtitems = stditems.filter((item) =>
      `${item.name} ${item.serialnumber}`
        .toLowerCase()
        .includes(this.state.filter.toLowerCase())
    );
    let showfilt = false;
    if (filtitems.length > 0) showfilt = true;

    return (
      <div>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`${
            this.state.type === "New" ? "Add New " : "Edit Existing "
          } Item`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    if (this.state.type === "New") {
                      this.props.addItem(this.state.item);

                      this.onHide();
                    } else {
                      this.props.editItem(this.state.item, this.state.itemind);

                      this.onHide();
                    }
                  }}
                >
                  {this.state.type === "New" ? "Create New " : "Save Edited "}{" "}
                  Item
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="line"
            centered
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Standard Item"} key={"Standard"}>
              <LoadBack loading={newDeliveryStore.loading.items}>
                <FlexboxGrid justify="center">
                  <Col2>
                    <InputGroup inside>
                      <Input
                        placeholder={"Search Items"}
                        onChange={(e) => this.setState({ filter: e })}
                      />
                      <InputGroup.Button>
                        <SearchIcon />
                      </InputGroup.Button>
                    </InputGroup>
                  </Col2>
                </FlexboxGrid>
                <hr />
                {showfilt ? (
                  filtitems.map((item, index) => (
                    <IconButton
                      key={index}
                      icon={<Icon icon="plus" />}
                      appearance="ghost"
                      color="green"
                      block
                      onClick={() =>
                        this.setState({
                          tab: "New",
                          filter: "",
                          item: {
                            name: item.name,
                            serialnumber: item.serialnumber,
                            length: item.length,
                            width: item.width,
                            height: item.height,
                            quantity: 1,
                          },
                        })
                      }
                    >
                      {item.name} (SN# {item.serialnumber})
                    </IconButton>
                  ))
                ) : (
                  <Empty description="No Items Found" />
                )}
              </LoadBack>
            </TabPane>
            <TabPane tab={"New Item"} key={"New"}>
              <FlexboxGrid>
                <Col md={24} sm={24} xs={24}>
                  <TextInputSTD
                    title="Item Name"
                    val={item.name}
                    label="name"
                    size="large"
                    editVal={this.editItem}
                  />
                  <br />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <TextInputSTD
                    title="Serial Number"
                    val={item.serialnumber}
                    label="serialnumber"
                    size="large"
                    editVal={this.editItem}
                  />
                  <br />
                </Col>
                <Col3>
                  <NumbInputSTD
                    min={1}
                    title="Length (cm)"
                    val={item.length}
                    label="length"
                    size="large"
                    editVal={this.editItem}
                  />
                  <br />
                </Col3>
                <Col3>
                  <NumbInputSTD
                    min={1}
                    title="Width (cm)"
                    val={item.width}
                    label="width"
                    size="large"
                    editVal={this.editItem}
                  />
                  <br />
                </Col3>
                <Col3>
                  <NumbInputSTD
                    min={1}
                    title="Height (cm)"
                    val={item.height}
                    label="height"
                    size="large"
                    editVal={this.editItem}
                  />
                  <br />
                </Col3>
                <Col md={24} sm={24} xs={24}>
                  <NumbInputSTD
                    min={1}
                    title="Quantity"
                    val={item.quantity}
                    label="quantity"
                    size="large"
                    editVal={this.editItem}
                  />
                  <br />
                </Col>
              </FlexboxGrid>
              <HelpBlock>
                <b>Item Dimensions </b>= {PRKD(item.length)} cm x{" "}
                {PRKD(item.width)} cm x {PRKD(item.height)} cm{" "}
              </HelpBlock>
              <HelpBlock>
                <b>Single Item CBM </b>={" "}
                {PRKD(
                  (Parsed(item.length) *
                    Parsed(item.width) *
                    Parsed(item.height)) /
                    1000000
                )}{" "}
                CBM
              </HelpBlock>
              <HelpBlock>
                <b>Total Item CBM </b>={" "}
                {PRKD(
                  (Parsed(item.length) *
                    Parsed(item.width) *
                    Parsed(item.height) *
                    Parsed(item.quantity)) /
                    1000000
                )}{" "}
                CBM
              </HelpBlock>
            </TabPane>
          </Tabs>
        </UnifiedModal>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.showbulk}
          title={"Add Bulk Items"}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={griddisabled}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    for (let i = 0; i < this.state.grid.length; i++) {
                      if (this.state.grid[i].quantity > 0) {
                        this.props.addItem(this.state.grid[i]);
                      }
                    }

                    this.onHide();
                  }}
                >
                  Add {this.state.grid.length} Item
                  {this.state.grid.length === 1 ? "" : "s"}
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <DataSheetGrid
            value={this.state.grid}
            onChange={(e) => this.editGrid(e)}
            columns={columns}
            autoAddRow
            style={{ backgroundColor: "red" }}
            createRow={() => ({
              name: "",
              serialnumber: "",
              length: 1,
              width: 1,
              height: 1,
              quantity: 1,
            })}
          />
        </UnifiedModal>
        <Descriptions
          bordered
          size="small"
          style={{ textAlign: "center" }}
          title={false}
        >
          <Descriptions.Item label="Name" span={2}>
            {to.name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone" span={1}>
            {to.phone}
          </Descriptions.Item>
          <Descriptions.Item label="City">{to.city}</Descriptions.Item>
          <Descriptions.Item label="Type" span={1}>
            {to.type}
          </Descriptions.Item>
          <Descriptions.Item label="Floor" span={1}>
            {to.floor}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={3}>
            {to.address}
          </Descriptions.Item>
        </Descriptions>
        <hr />
        <FlexboxGrid justify="center">
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            size="xs"
            onClick={() => this.newItem("New")}
          >
            Add New Item
          </IconButton>
          <Divider vertical />
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            size="xs"
            onClick={() => this.newItem("Standard")}
          >
            Add Standard Item
          </IconButton>
          <Divider vertical />
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            size="xs"
            onClick={() => this.newItem("Bulk")}
          >
            Add Bulk Items
          </IconButton>
        </FlexboxGrid>
        <Div14>Items List:</Div14>
        {this.state.show ? (
          <div style={{ height: "250px" }} />
        ) : (
          <DataGrid
            dataSource={to.items}
            keyExpr="name"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            style={{ fontSize: 10 }}
            hoverStateEnabled={true}
            noDataText="No Items Added"
          >
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />

            <Scrolling mode="standard" showScrollbar="always" />

            <ColumnFixing enabled={true} />
            <Column
              caption="Edit"
              alignment={"center"}
              cellRender={this.EditActionButton}
              width={45}
            />
            <Column caption="Item Details" alignment="center">
              <Column
                dataField="name"
                caption="Item"
                alignment="center"
                minWidth={80}
              />
              <Column
                dataField="serialnumber"
                caption="Ser. No."
                alignment="center"
                minWidth={80}
              />
            </Column>
            <Column
              dataField="dimensions"
              caption="Dimensions"
              alignment="center"
              minWidth={140}
              cellRender={this.Dimension}
            />
            <Column
              dataField="quantity"
              dataType="number"
              caption="Quantity"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              minWidth={80}
            />
            <Column caption="CBM Calcs" alignment="center">
              <Column
                dataField="cbmperitem"
                dataType="number"
                caption="CBM Per Item"
                alignment="center"
                minWidth={80}
                cellRender={this.CBMPerItem}
              />
              <Column
                dataField="totalcbm"
                dataType="number"
                caption="Total CBM"
                alignment="center"
                minWidth={80}
                cellRender={this.TotalCBM}
              />
              <Column
                caption="Delete"
                alignment={"center"}
                cellRender={this.DeleteActionButton}
                width={45}
              />
            </Column>
          </DataGrid>
        )}
      </div>
    );
  }
}

export default withRouter(observer(ItemsListModal));
