import moment from "moment";
import { AttQualityCalc } from "./SupportingQuality/ATTQualityCalc";
import { JDQualityCalc } from "./SupportingQuality/JDQualityCalc";

function IMQualityCalc(data) {
  let finalerror = [0, 0];
  let general = IMQualityMain(data);
  let charges = IMQualityCharges(data);
  let tracks = IMQualityTrack(data);
  let dates = JDQualityCalc(data);
  let atts = AttQualityCalc(data);

  finalerror[0] = general[0] + dates[0] + charges[0] + tracks[0] + atts[0];
  finalerror[1] = general[1] + dates[1] + charges[1] + tracks[1] + atts[1];
  finalerror[2] = [
    ...general[2],
    ...charges[2],
    ...tracks[2],
    ...dates[2],
    ...atts[2],
  ];
  return finalerror;
}

function IMQualityMain(data) {
  let errors = 0;
  let total = 8;
  let items = [];
  if (data.im.shipfrom === "") items.push("Ship From Address");
  if (data.im.shipto === "") items.push("Ship To Address");
  if (data.im.containertype === "") items.push("Container Info");
  if (data.im.direction === "Inbound") {
    if (data.im.cpunpack === 0) items.push("Unloading and Unpacking Charges");
    if (data.im.cpintrans === 0) items.push("Transportation Charges");
    if (data.im.cpimport === 0) items.push("Import Custom Clearance Charges");
    if (data.im.cpministry === 0) items.push("Ministry Approval Charges");
    if (data.im.cpinspect === 0) items.push("Inspection Charges");
  } else {
    if (data.im.cppack === 0) items.push("Packing and Loading Charges");
    if (data.im.cpouttrans === 0) items.push("Transporation Charges");
    if (data.im.cpexport === 0) items.push("Export Clearance Charges");
    if (data.im.cpship === 0) items.push("Shipping Charges");
    if (data.im.cpdest === 0) items.push("Destination Charges");
  }
  errors = items.length;
  return [errors, total, items];
}

function IMQualityCharges(data) {
  let errors = 0;
  let total = 15;
  let items = [];
  if (data.im.direction === "Inbound") {
    if (data.im.choriginship === 0) items.push("Origin + Shipping Charges");
    if (data.im.chinsurance === 0) items.push("Insurance Charges");

    if (data.im.chship === 0) items.push("Shipping Line Charges");

    if (data.im.chimport === 0) items.push("Import Customs Charges");
    if (data.im.chtransport === 0) items.push("Transportation Charges");
    if (data.im.chcustom === 0) items.push("Customs Duty Charges");
    if (data.im.chdthc === 0) items.push("DO/DTHC Charges");
    if (data.im.chpai === 0) items.push("PAI Charges");
    if (data.im.chepa === 0) items.push("EPA Charges");
    if (data.im.chbayan === 0) items.push("Bayan Charges");
    if (data.im.chclearance === 0) items.push("Global Clearance Charges");
    if (data.im.chrelease === 0) items.push("Container Release Charges");
    if (data.im.chdemurrage === 0) items.push("Demurrage Charges");
    if (data.im.chdetention === 0) items.push("Detention Charges");
    if (data.im.chport === 0) items.push("Port Fees Charges");
  } else {
    if (data.im.chtransport === 0) items.push("Transportation Charges");
    if (data.im.chclearance === 0) items.push("Global Clearance Charges");
    if (data.im.chbayan === 0) items.push("Bayan Charges");
    if (data.im.chinsurance === 0) items.push("Insurance Charges");

    if (data.im.chship === 0) items.push("Shipping Line Charges");
    if (data.im.chdthc === 0) items.push("DTHC Charges");

    if (data.im.chdelord === 0) items.push("Delivery Order Charges");
    if (data.im.chterhand === 0) items.push("Terminal Handeling Charges");
    if (data.im.chdestclear === 0) items.push("Destiantion Clearance Charges");
    if (data.im.chshuttle === 0) items.push("Shuttle Charges");
    if (data.im.chdemurrage === 0) items.push("Demurrage Charges");
    if (data.im.chdetention === 0) items.push("Detention Charges");
    if (data.im.chdeststore === 0) items.push("Destination Storage Charges");
    if (data.im.chassembly === 0) items.push("Assembling and Unloading Charges");
    if (data.im.chcontreturn === 0) items.push("Container Return Charges");
  }
  if (data.im.commodity === "Dangerous Goods") {
    total = 17;
    if (data.im.danggoodch === 0) items.push("Dangerous Goods Charges");
    if (data.im.danghandch === 0) items.push("Dangerous Goods Handeling Charges");
  }
  errors = items.length;
  return [errors, total, items];
}

function IMQualityTrack(data) {
  let errors = 0;
  let total = 0;
  let items = [];

  let tracks = data.im.imtracks;
  let pastOpen = tracks.filter(
    (track) => (moment(track.date) < moment()) & (track.status === "Pending")
  );
  for (let i = 0; i < pastOpen.length; i++) {
    items.push(
      `Pending ${pastOpen[i].title}: Past Due ${moment(
        pastOpen[i].date
      ).fromNow()}`
    );
  }

  errors = items.length;
  total = tracks.length;
  if (errors > total) total = errors;

  return [errors, total, items];
}

export { IMQualityCalc, IMQualityMain, IMQualityCharges, IMQualityTrack };
