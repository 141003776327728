import { DatePicker, Tooltip } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock } from "rsuite";
import sjStore from "../../../Stores/SideJobs/SJStore";

class SJDateInput extends Component {
  render() {
    let ind = this.props.ind;
    let label = this.props.label;
    let title = this.props.title;
    let load = this.props.load;

    let org = sjStore.sjs[ind][label];
    let val = sjStore.esjs[ind][label];

    let sj = this.props.sj;
    if (sj) {
      if (sjStore.sjs[ind][sj]) {
        if (sjStore.sjs[ind][sj][label]) {
          org = sjStore.sjs[ind][sj][label];
          val = sjStore.esjs[ind][sj][label];
        }
      }
    }

    let disabled = false;

    if (this.props.disabled) disabled = this.props.disabled;

    let changed = false;

    if (moment(org).format("YYYY-MM-DD") !== moment(val).format("YYYY-MM-DD")) {
      changed = true;
    }

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;
    if (quality) {
      if (val === undefined) {
        flag = true;
        qualitytext = `${title} must be added.`;
      }
    }
    let read = this.props.read;
    if (read) changed = false;

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {changed && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(org, label, sj)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: flag && "#ff968c" }}>
              {title}
            </InputGroup.Addon>
          </Tooltip>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-yyyy"}
            style={{ width: "100%" }}
            disabled={disabled}
            value={moment(val)}
            onChange={(e) => {
              if (!read) {
                let newdate;
                if (e === null) {
                  newdate = undefined;
                } else {
                  newdate = moment(e).format("YYYY-MM-DD");
                }
                this.props.editVal(newdate, label, sj);
              }
            }}
          />

          {changed && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(label, title, sj)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {changed && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>"{org}"</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(SJDateInput);
