import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class WHMNewWarehouseStore {
  constructor() {
    this.trucks = [];
    this.pallets = [];
    this.stacked = [];
    this.trucktrip = [];
    this.loading = {
      trucks: false,
      pallets: false,
      stacked: false,
      trucktrip: false,
    };
    this.loaded = {
      trucks: false,
      pallets: false,
      stacked: false,
      trucktrip: false,
    };

    makeObservable(this, {
      pallets: observable,
      stacked: observable,
      trucks: observable,
      trucktrip: observable,
      loading: observable,
      loaded: observable,
      readytrucks: computed,
      currentlyunloading: computed,
    });
  }

  // FETCH
  getPallets(override) {
    let loaded;
    if (this.loading.pallets || this.loaded.pallets) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.pallets = true;
      return instance
        .get(`/whm/pallets/unloadedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.pallets = data;
          this.loading.pallets = false;
          this.loaded.pallets = true;
          console.log("NEW WHM Unloaded Pallets Fetched");
        })
        .catch((err) => {
          this.loading.pallets = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getStacked(override) {
    let loaded;
    if (this.loading.stacked || this.loaded.stacked) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.stacked = true;
      return instance
        .get(`/whm/pallets/stackedpallets`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.stacked = data;
          this.loading.stacked = false;
          this.loaded.stacked = true;
          console.log("NEW WHM Stacked Pallets Fetched");
        })
        .catch((err) => {
          this.loading.stacked = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getReadyTruckTrip(override) {
    let loaded;
    if (this.loading.trucktrip || this.loaded.trucktrip) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.trucktrip = true;
      this.trucktrip = [];
      return instance
        .get(`/whm/trucktrip/readytounloadtrips`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.trucktrip = data;
          this.loading.trucktrip = false;
          this.loaded.trucktrip = true;
          console.log("Unloading Truck Trip Fetched");
        })
        .catch((err) => {
          this.loading.trucktrip = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get readytrucks() {
    return this.trucktrip.filter((tr) => tr.status === "Loading End");
  }
  get currentlyunloading() {
    if (this.trucktrip.length > 0) {
      let data = this.trucktrip.filter((tr) => tr.status === "Unloading Start");
      if (data.length > 0) {
        return { value: true, data: data };
      }
    }
    return { value: false, data: { _id: "" } };
  }
}

const whmNewWarehouseStore = new WHMNewWarehouseStore();
export default whmNewWarehouseStore;
