import { observer } from "mobx-react";
import React, { Component } from "react";

import { FlexboxGrid, Col, Button } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import DatePickerSTD from "../../../../../../Components/Inputs/STD/DatePickerSTD";
import SelectInputSTD from "../../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../../Components/Inputs/STD/TextInputSTD";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";

class InvoiceMainInfo extends Component {
  render() {
    let inv = this.props.inv;

    return (
      <div>
        <Div14>Main Invoice Info:</Div14>
        <FlexboxGrid>
          <Col2>
            <FlexboxGrid>
              <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
                <SelectInputSTD
                  title="Status"
                  val={inv.status}
                  label="status"
                  size="large"
                  opts={["Pending", "Partial", "Paid", "Cancelled"]}
                  editVal={invoiceStore.editInvoice}
                />
              </Col>
              <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
                <TextInputSTD
                  title="Title"
                  editVal={invoiceStore.editInvoice}
                  val={inv.title}
                  label="title"
                />
              </Col>
            </FlexboxGrid>
          </Col2>
          <Col2>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <DatePickerSTD
                title="Invoice Date"
                editVal={invoiceStore.editInvoice}
                val={inv.date}
                label="date"
              />
            </Col>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <DatePickerSTD
                title="Valid Until"
                editVal={invoiceStore.editInvoice}
                val={inv.dateto}
                label="dateto"
              />
            </Col>
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
            <Col md={24} sm={24} xs={24} style={{ marginBottom: "5px" }}>
              <TextAreaInputSTD
                title="Remarks"
                editVal={invoiceStore.editInvoice}
                val={inv.remarks}
                label="remarks"
              />
            </Col>
          </Col>
        </FlexboxGrid>
        <FlexboxGrid style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <Col md={12} sm={12} xs={24}>
            <Button
              block
              style={{
                color: inv.showBank === "NBK" ? "white" : "black",
                backgroundColor: inv.showBank === "NBK" ? "black" : "white",
                border: "1px solid black",
              }}
              appearance={inv.showBank === "NBK" ? "primary" : "ghost"}
              onClick={() => invoiceStore.editInvoice("NBK", "showBank")}
            >
              Show NBK
            </Button>
          </Col>
          <Col md={12} sm={12} xs={24}>
            <Button
              block
              style={{
                color: inv.showBank === "KFH" ? "white" : "black",
                backgroundColor: inv.showBank === "KFH" ? "black" : "white",
                border: "1px solid black",
              }}
              appearance={inv.showBank === "KFH" ? "primary" : "ghost"}
              onClick={() => invoiceStore.editInvoice("KFH", "showBank")}
            >
              Show KFH
            </Button>
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(InvoiceMainInfo);
