import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@material-ui/core";

import SingleLink from "../SingleLink";
import { FlexboxGrid, Avatar } from "rsuite";

class WarehouseMoveTab extends Component {
  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={
            <Tooltip title="WH Move 2023" arrow placement="right">
              <Avatar circle size="sm" style={{ background: "#5eb500" }}>
                WM
              </Avatar>
            </Tooltip>
          }
        >
          {this.props.open && <Typography>WH Move 2023</Typography>}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <FlexboxGrid>
            <SingleLink
              page="/whm/companies"
              icon="people-group"
              title="Clients"
              hdc={this.props.hdc}
            />

            <SingleLink
              page="/whm/oldwarehouse"
              icon="building"
              title="Old WH"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/whm/newwarehouse"
              icon="star"
              title="New WH"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/whm/overview"
              icon="globe"
              title="Overview"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/whm/planning"
              icon="map"
              title="Plans"
              hdc={this.props.hdc}
            />
            <SingleLink
              page="/whm/statistics"
              icon="realtime"
              title="Statistics"
              hdc={this.props.hdc}
            />
          </FlexboxGrid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withRouter(observer(WarehouseMoveTab));
