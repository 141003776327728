import { Statistic } from "antd";
import MaterialTable from "material-table";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import axios from "axios";

import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import carStore from "../../../../../Stores/AdditionalStores/CarStore";
import SignatureWrapper from "../SignatureWrapper/SignatureWrapper";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import completeDeliveryStore from "../../../../../Stores/Account/CompleteDeliveryStore";

const instance = axios.create({ baseURL: baseURL });

class StorageInOutModal extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, loading: false };
    this.onHide = this.onHide.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.postStorage = this.postStorage.bind(this);
  }
  postStorage = async () => {
    let sig = this.props.sig;
    let trucktrack = null;
    this.setState({ loading: true });
    if (sig.trucktrack !== "No Tracking") {
      let indexval = carStore.cars.findIndex(
        (car) => car.name === sig.trucktrack
      );
      if (indexval >= 0) {
        trucktrack = carStore.cars[indexval]._id;
      }
    }
    let data = { ...sig };
    if (
      this.props.fulltype === "Storage In" ||
      this.props.fulltype === "Delivery" ||
      this.props.fulltype === "Storage Out"
    ) {
      data.status = "Completed";
    } else {
      data.status = "In Transit";
    }
    data.fulltype = this.props.fulltype;
    data.trucktrack = trucktrack;

    return instance
      .put(
        `/ac/advwarehouserequest/signstorage/${completeDeliveryStore.neworder._id}`,
        data,
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        if (data.status === "Completed") {
          Notification["success"]({
            title: `Order Completed`,
          });
        } else {
          Notification["success"]({
            title: `Delivery Currently In Transit`,
          });
        }
        this.setState({ loading: false });
        completeDeliveryStore.getSingleOrder(
          completeDeliveryStore.neworder._id,
          true
        );
        this.props.onHide();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  nextStep(finalstep) {
    if (finalstep) {
      this.postStorage();
    } else {
      this.setState({ step: this.state.step + 1 });
    }
  }
  backStep() {
    this.props.changeST(null, "sig");
    if (this.state.step <= 0) {
      this.onHide();
    } else {
      this.setState({ step: this.state.step - 1 });
    }
  }
  onHide() {
    this.setState({ step: 0 });
    this.props.onHide();
  }
  render() {
    let sig = this.props.sig;
    let data = this.props.data;
    let itemmove = data.acitemmove;
    let emailNote = this.props.emailNote;

    let finalstep = false;
    if (this.props.fulltype === "Pickup") {
      if (this.state.step === 2) finalstep = true;
    } else if (
      (data.jobtype === "Delivery Out") &
      (this.props.fulltype === "Storage Out")
    ) {
      if (this.state.step === 2) finalstep = true;
    } else {
      if (this.state.step === 1) finalstep = true;
    }
    let disabled = false;
    if (finalstep) {
      if (sig.name === "" || sig.sig === null) disabled = true;
      if ((sig.email !== "") & (emailNote.color === "red")) disabled = true;
    }

    let content;
    let footer = (
      <ModalFooter
        icon={["chevron-left", "chevron-right"]}
        color={["red", "green"]}
        app={["default", "default"]}
        text={[
          this.state.step === 0 ? "Cancel" : "Back",
          finalstep ? `Confirm ${this.props.fulltype}` : "Next",
        ]}
        oC1={() => this.backStep()}
        oC2={() => this.nextStep(finalstep)}
        loading1={this.state.loading}
        loading2={this.state.loading}
        disabled2={disabled}
      />
    );
    let itemqty = 0;
    let packages = 0;
    let cbm = 0;
    for (let i = 0; i < itemmove.length; i++) {
      itemqty += 1;
      packages += Parsed(itemmove[i].actualquantity);
      cbm += Parsed(itemmove[i].acitem.cbm * itemmove[i].actualquantity);
    }
    cbm = PRKD(cbm);
    let title = "";
    if (this.state.step === 0) {
      title = "Confirmed Items";
      content = (
        <div>
          <MaterialTable
            title="Items List"
            columns={[
              { title: "ID", field: "acitem.acItemNumId" },
              { title: "Item", field: "acitem.name" },
              { title: "Serial No.", field: "acitem.serialnumber" },
              { title: "Product ID", field: "acitem.productid" },
              { title: "Qty", field: "quantity" },
              {
                title: "LxWxH (cm)",
                field: "acitem.length",
                render: (rowData) => {
                  return `${parseFloat(rowData.acitem.length)} x
                ${parseFloat(rowData.acitem.width)} x
                ${parseFloat(rowData.acitem.height)}
                `;
                },
              },
              { title: "CBM/item", field: "acitem.cbm" },
              {
                title: "CBM",
                field: "acitem.cbm",
                render: (rowData) => {
                  return PRKD(
                    Parsed(rowData.acitem.cbm) * Parsed(rowData.actualquantity)
                  );
                },
              },
            ]}
            data={itemmove}
            isLoading={this.props.loading}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              exportButton: false,
              padding: "dense",
              loadingType: "overlay",
              maxBodyHeight: 500,
            }}
          />
        </div>
      );
    } else {
      if (finalstep) {
        title = "Receiving Party Details";
        content = (
          <div>
            <FlexboxGrid>
              <Col2>
                <TextInputSTD
                  title="Name"
                  val={sig.name}
                  label="name"
                  size="large"
                  editVal={this.props.changeST}
                />
                <HelpBlock style={{ color: "red", textAlign: "center" }}>
                  {sig.name === "" ? "Name Required" : " "}
                </HelpBlock>
              </Col2>
              <Col2>
                <TextInputSTD
                  title="Email (Optional)"
                  val={sig.email}
                  label="email"
                  size="large"
                  editVal={this.props.changeST}
                />

                <HelpBlock
                  style={{
                    color: emailNote.color,
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  {emailNote.message}
                </HelpBlock>
              </Col2>
            </FlexboxGrid>
            <TextAreaInputSTD
              title="Additional Remarks"
              label="remark"
              val={sig.remark}
              editVal={this.props.changeST}
            />
            <Div14>Signature:</Div14>
            <SignatureWrapper
              changeST={this.props.changeST}
              sig={sig}
              oldsig={data.storSignature}
            />
          </div>
        );
      } else {
        title = "Tracking Services";
        content = (
          <div>
            <SelectInputSTD
              title="Select a Truck"
              val={sig.trucktrack}
              label="trucktrack"
              size="medium"
              opts={carStore.trucklist}
              editVal={this.props.changeST}
            />
            <hr />
          </div>
        );
      }
    }

    return (
      <div>
        <FlexboxGrid justify="space-around">
          <Statistic
            title={`Item${itemqty !== 1 ? "s" : ""}`}
            value={itemqty}
            precision={0}
            valueStyle={{ color: "#3f8600" }}
          />
          <Statistic
            title={`Package${packages !== 1 ? "s" : ""}`}
            value={packages}
            precision={0}
            valueStyle={{ color: "#3f8600" }}
          />
          <Statistic
            title="CBM"
            value={cbm}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
          />
        </FlexboxGrid>
        <hr />
        <Div14>
          Step {this.state.step + 1}: {title}:
        </Div14>
        {content}
        <hr />
        {footer}
      </div>
    );
  }
}

export default observer(StorageInOutModal);
