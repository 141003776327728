import { observer } from "mobx-react";
import React, { Component } from "react";

import { Badge, Descriptions, Tag } from "antd";

import moment from "moment";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import userStore from "../../../../../Stores/UserStore";

class OrderSummaryTable extends Component {
  render() {
    let acc = this.props.acc;

    let account = this.props.account;

    let statuscolor = "green";
    let statustype = "default";
    if (acc.status === "Requested") statuscolor = "orange";
    if (acc.status === "Booked") statuscolor = "blue";
    if (acc.status === "In Transit") {
      statuscolor = "blue";
      statustype = "processing";
    }
    if (acc.status === "Completed") statuscolor = "green";
    if (acc.status === "Cancelled") statuscolor = "red";
    let data1 = [
      { title: "Client", val: `${account.clientNumId} - ${account.name}` },
      {
        title: "Vessel Arrival Date",
        val: moment(acc.vesselarrival).format("DD-MMM-YY"),
      },
      { title: "Shipping Method", val: acc.shippingmode },
      { title: "Shipping Line", val: acc.shippingline },
      { title: "Commodity", val: acc.commodity },
      { title: "Brand / Supplier", val: acc.brandsupplier },
      { title: "Origin Location", val: acc.originlocation },
      { title: "Bill of Lading Number", val: acc.blnumber },
      { title: "Total Job Price", val: `${PRKD(acc.totalcost)} KD` },
    ];
    if (userStore.user.staffaccount) {
      data1 = [
        ...data1,
        { title: "Total Receipts", val: PRKD(acc.totalreceipts) },
        { title: "Profit", val: `${PRKD(acc.profit)} KD` },
      ];
    }

    return (
      <div>
        <Descriptions bordered size="small">
          <Descriptions.Item
            span={2}
            labelStyle={{
              textAlign: "right",
              fontWeight: "bold",
              width: "30%",
            }}
            label="Status"
          >
            <Tag color={statuscolor} span={2} style={{ fontWeight: "bold" }}>
              <Badge
                status={statustype}
                style={{ color: statuscolor }}
                color={statuscolor}
                text={acc.status}
              />
            </Tag>
          </Descriptions.Item>
          {data1.map((val, index) => (
            <Descriptions.Item
              key={index}
              label={val.title}
              span={2}
              labelStyle={{
                textAlign: "right",
                fontWeight: "bold",
                width: "30%",
              }}
            >
              {val.val}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    );
  }
}

export default observer(OrderSummaryTable);
