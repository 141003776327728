import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import baseURL from "../../../../Static/baseURL/baseURL";
import axios from "axios";
import equipmentStore from "../../../../Stores/EquipmentStores/EquipmentStore";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";
import moment from "moment";
import Col3 from "../../../../Components/Columns/Col3";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";

import MaterialTable from "material-table";
import ChecklistTextInputSTD from "../../../../Components/Inputs/STD/ChecklistTextInputSTD";
import Col2 from "../../../../Components/Columns/Col2";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class ChecklistTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownew: false,
      showedit: false,
      showdelete: false,
      loading: false,

      checklist: {
        equipmentChecklistNumId: "",
        checklistid: "",
        date: moment(),
        truckid: "",
        kilometer: "",
        driver: "",
        checkallfluids: "OK",
        engineoil: "OK",
        radiatorwater: "OK",
        wiper: "OK",
        checktires: "OK",
        fuellevel: "OK",
        oil: "OK",
        battery: "OK",
        temp: "OK",
        horn: "OK",
        steeringwheel: "OK",
        handbrake: "OK",
        footbrake: "OK",
        registration: "OK",
        acheater: "OK",
        interiorlights: "OK",
        upholstery: "OK",
        seatbelt: "OK",
        firstaidkit: "OK",
        fireextinguisher: "OK",
        toolkit: "OK",
        vehiclecontact: "OK",
        headlightslow: "OK",
        headlightshigh: "OK",
        leftsignal: "OK",
        rightsignal: "OK",
        taillight: "OK",
        parklight: "OK",
        brakelight: "OK",
        hazardlight: "OK",
        tirewear: "OK",
        tirepsi: "OK",
        sparetire: "OK",
        exhaust: "OK",
        dentscratch: "OK",
        windows: "OK",
        windowshield: "OK",
        sticker: "OK",
        remarks: "",
      },
      truck: {
        type: "",
        license: "",
        remark: "",
      },
      equipmentChecklistNumId: "",
      checklistid: "",
      date: moment(),
      truckid: "",
      kilometer: "",
      driver: "",
      checkallfluids: "OK",
      engineoil: "OK",
      radiatorwater: "OK",
      wiper: "OK",
      checktires: "OK",
      fuellevel: "OK",
      oil: "OK",
      battery: "OK",
      temp: "OK",
      horn: "OK",
      steeringwheel: "OK",
      handbrake: "OK",
      footbrake: "OK",
      registration: "OK",
      acheater: "OK",
      interiorlights: "OK",
      upholstery: "OK",
      seatbelt: "OK",
      firstaidkit: "OK",
      fireextinguisher: "OK",
      toolkit: "OK",
      vehiclecontact: "OK",
      headlightslow: "OK",
      headlightshigh: "OK",
      leftsignal: "OK",
      rightsignal: "OK",
      taillight: "OK",
      parklight: "OK",
      brakelight: "OK",
      hazardlight: "OK",
      tirewear: "OK",
      tirepsi: "OK",
      sparetire: "OK",
      exhaust: "OK",
      dentscratch: "OK",
      windows: "OK",
      windowshield: "OK",
      sticker: "OK",
      remarks: "",
    };
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  componentDidMount() {
    equipmentStore.getEquipmentChecklist();
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  postEdit() {
    let data = {
      date: this.state.date,
      kilometer: this.state.kilometer,
      driver: this.state.driver,
      checkallfluids: this.state.checkallfluids,
      engineoil: this.state.engineoil,
      radiatorwater: this.state.radiatorwater,
      wiper: this.state.wiper,
      checktires: this.state.checktires,
      fuellevel: this.state.fuellevel,
      oil: this.state.oil,
      battery: this.state.battery,
      temp: this.state.temp,
      horn: this.state.horn,
      steeringwheel: this.state.steeringwheel,
      handbrake: this.state.handbrake,
      footbrake: this.state.footbrake,
      registration: this.state.registration,
      acheater: this.state.acheater,
      interiorlights: this.state.interiorlights,
      upholstery: this.state.upholstery,
      seatbelt: this.state.seatbelt,
      firstaidkit: this.state.firstaidkit,
      fireextinguisher: this.state.fireextinguisher,
      toolkit: this.state.toolkit,
      vehiclecontact: this.state.vehiclecontact,
      headlightslow: this.state.headlightslow,
      headlightshigh: this.state.headlightshigh,
      leftsignal: this.state.leftsignal,
      rightsignal: this.state.rightsignal,
      taillight: this.state.taillight,
      parklight: this.state.parklight,
      brakelight: this.state.brakelight,
      hazardlight: this.state.hazardlight,
      tirewear: this.state.tirewear,
      tirepsi: this.state.tirepsi,
      sparetire: this.state.sparetire,
      exhaust: this.state.exhaust,
      dentscratch: this.state.dentscratch,
      windows: this.state.windows,
      windowshield: this.state.windowshield,
      sticker: this.state.sticker,
      remarks: this.state.remarks,
    };
    let url = `/hr/equipmentchecklist/edit/${this.state.checklistid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        equipmentStore.getEquipmentChecklist(true);
        message.success("Updated Checklist Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Update Checklist Record");
      });
  }
  postDelete() {
    let data = {};
    let url = `/hr/equipmentchecklist/delete/${this.state.checklistid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        equipmentStore.getEquipmentChecklist(true);
        message.success("Deleted Checklist Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Delete Checklist Record");
      });
  }
  postNew() {
    let data = {
      date: this.state.date,
      equipment: this.state.truckid,

      kilometer: this.state.kilometer,
      driver: this.state.driver,
      checkallfluids: this.state.checkallfluids,
      engineoil: this.state.engineoil,
      radiatorwater: this.state.radiatorwater,
      wiper: this.state.wiper,
      checktires: this.state.checktires,
      fuellevel: this.state.fuellevel,
      oil: this.state.oil,
      battery: this.state.battery,
      temp: this.state.temp,
      horn: this.state.horn,
      steeringwheel: this.state.steeringwheel,
      handbrake: this.state.handbrake,
      footbrake: this.state.footbrake,
      registration: this.state.registration,
      acheater: this.state.acheater,
      interiorlights: this.state.interiorlights,
      upholstery: this.state.upholstery,
      seatbelt: this.state.seatbelt,
      firstaidkit: this.state.firstaidkit,
      fireextinguisher: this.state.fireextinguisher,
      toolkit: this.state.toolkit,
      vehiclecontact: this.state.vehiclecontact,
      headlightslow: this.state.headlightslow,
      headlightshigh: this.state.headlightshigh,
      leftsignal: this.state.leftsignal,
      rightsignal: this.state.rightsignal,
      taillight: this.state.taillight,
      parklight: this.state.parklight,
      brakelight: this.state.brakelight,
      hazardlight: this.state.hazardlight,
      tirewear: this.state.tirewear,
      tirepsi: this.state.tirepsi,
      sparetire: this.state.sparetire,
      exhaust: this.state.exhaust,
      dentscratch: this.state.dentscratch,
      windows: this.state.windows,
      windowshield: this.state.windowshield,
      sticker: this.state.sticker,
      remarks: this.state.remarks,
    };
    let url = "/hr/equipmentchecklist/new";
    this.setState({ loading: true });
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        equipmentStore.getEquipmentChecklist(true);
        message.success("Created New Checklist Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Create New Checklist Record");
      });
  }

  onHide() {
    this.setState({
      shownew: false,
      showedit: false,
      showdelete: false,
      loading: false,
      truck: {
        type: "",
        license: "",
        remark: "",
      },
      checklist: {
        equipmentChecklistNumId: "",
        checklistid: "",
        date: moment(),
        truckid: "",
        kilometer: "",
        driver: "",
        checkallfluids: "OK",
        engineoil: "OK",
        radiatorwater: "OK",
        wiper: "OK",
        checktires: "OK",
        fuellevel: "OK",
        oil: "OK",
        battery: "OK",
        temp: "OK",
        horn: "OK",
        steeringwheel: "OK",
        handbrake: "OK",
        footbrake: "OK",
        registration: "OK",
        acheater: "OK",
        interiorlights: "OK",
        upholstery: "OK",
        seatbelt: "OK",
        firstaidkit: "OK",
        fireextinguisher: "OK",
        toolkit: "OK",
        vehiclecontact: "OK",
        headlightslow: "OK",
        headlightshigh: "OK",
        leftsignal: "OK",
        rightsignal: "OK",
        taillight: "OK",
        parklight: "OK",
        brakelight: "OK",
        hazardlight: "OK",
        tirewear: "OK",
        tirepsi: "OK",
        sparetire: "OK",
        exhaust: "OK",
        dentscratch: "OK",
        windows: "OK",
        windowshield: "OK",
        sticker: "OK",
        remarks: "",
      },
      equipmentChecklistNumId: "",
      checklistid: "",
      date: moment(),
      truckid: "",
      kilometer: "",
      driver: "",
      checkallfluids: "OK",
      engineoil: "OK",
      radiatorwater: "OK",
      wiper: "OK",
      checktires: "OK",
      fuellevel: "OK",
      oil: "OK",
      battery: "OK",
      temp: "OK",
      horn: "OK",
      steeringwheel: "OK",
      handbrake: "OK",
      footbrake: "OK",
      registration: "OK",
      acheater: "OK",
      interiorlights: "OK",
      upholstery: "OK",
      seatbelt: "OK",
      firstaidkit: "OK",
      fireextinguisher: "OK",
      toolkit: "OK",
      vehiclecontact: "OK",
      headlightslow: "OK",
      headlightshigh: "OK",
      leftsignal: "OK",
      rightsignal: "OK",
      taillight: "OK",
      parklight: "OK",
      brakelight: "OK",
      hazardlight: "OK",
      tirewear: "OK",
      tirepsi: "OK",
      sparetire: "OK",
      exhaust: "OK",
      dentscratch: "OK",
      windows: "OK",
      windowshield: "OK",
      sticker: "OK",
      remarks: "",
    });
  }
  render() {
    let columnnames = [
      {
        title: "ID",
        field: "equipmentChecklistNumId",
        removable: true,
      },
      { title: "Type", field: "type", removable: true },
      { title: "Plate", field: "license", removable: true },
      {
        title: "Date",
        field: "date",
        removable: true,
        defaultSort: "desc",
        render: (rowData) => (
          <div>{moment(rowData.date).format("DD-MMM-YY")}</div>
        ),
      },
      { title: "KM", field: "kilometer", removable: true },
      { title: "Driver", field: "driver", removable: true },
    ];

    let tableTitle = "Checklist Records";
    let data = equipmentStore.checklist;
    let checklistbuttons = equipmentStore.equipments.map((eq, index) => (
      <IconButton
        icon={
          <Icon
            icon={
              eq.type === "Generator"
                ? "bolt"
                : eq.type === "Pickup"
                ? "car"
                : "truck"
            }
          />
        }
        size="xs"
        key={index}
        onClick={() => {
          let checklist = {
            equipmentChecklistNumId: "",
            checklistid: "",
            date: moment(),
            truckid: "",
            kilometer: "",
            driver: "",
            checkallfluids: "OK",
            engineoil: "OK",
            radiatorwater: "OK",
            wiper: "OK",
            checktires: "OK",
            fuellevel: "OK",
            oil: "OK",
            battery: "OK",
            temp: "OK",
            horn: "OK",
            steeringwheel: "OK",
            handbrake: "OK",
            footbrake: "OK",
            registration: "OK",
            acheater: "OK",
            interiorlights: "OK",
            upholstery: "OK",
            seatbelt: "OK",
            firstaidkit: "OK",
            fireextinguisher: "OK",
            toolkit: "OK",
            vehiclecontact: "OK",
            headlightslow: "OK",
            headlightshigh: "OK",
            leftsignal: "OK",
            rightsignal: "OK",
            taillight: "OK",
            parklight: "OK",
            brakelight: "OK",
            hazardlight: "OK",
            tirewear: "OK",
            tirepsi: "OK",
            sparetire: "OK",
            exhaust: "OK",
            dentscratch: "OK",
            windows: "OK",
            windowshield: "OK",
            sticker: "OK",
            remarks: "",
          };
          if (eq.checklist) checklist = eq.checklist;
          for (let i = 0; i < checklistitems.length; i++) {
            this.setState({
              [checklistitems[i].data]: checklist[checklistitems[i].data],
            });
          }
          this.setState({
            truck: eq,
            checklist: checklist,
            truckid: eq._id,
            shownew: true,
          });
        }}
      >
        {eq.license}
      </IconButton>
    ));
    let eqp = this.state.truck;
    let st = this.state;

    let modalheader = (
      <FlexboxGrid>
        <Col3>
          <b>Type: </b> {eqp.type}{" "}
        </Col3>
        <Col3>
          <b>Plate: </b> {eqp.license}
        </Col3>
        <Col3>
          <b>Remarks: </b> {eqp.remark}
        </Col3>
      </FlexboxGrid>
    );

    let checklistitems = [
      { title: "Check All Fluids", data: "checkallfluids", okval: "OK" },
      { title: "Engine Oil", data: "engineoil", okval: "OK" },
      { title: "Radiator Water", data: "radiatorwater", okval: "OK" },
      { title: "Wiper", data: "wiper", okval: "OK" },
      { title: "Check Tires", data: "checktires", okval: "OK" },
      { title: "Fuel Level", data: "fuellevel", okval: "OK" },
      { title: "Oil Level", data: "oil", okval: "OK" },
      { title: "Battery Level", data: "battery", okval: "OK" },
      { title: "Temperature Level", data: "temp", okval: "OK" },
      { title: "Horn", data: "horn", okval: "OK" },
      { title: "Steering Wheel", data: "steeringwheel", okval: "OK" },
      { title: "Hand Brake", data: "handbrake", okval: "OK" },
      { title: "Foot Brake", data: "footbrake", okval: "OK" },
      { title: "Registration Card", data: "registration", okval: "OK" },
      { title: "AC / Heater", data: "acheater", okval: "OK" },
      { title: "Interior Lights", data: "interiorlights", okval: "OK" },
      { title: "Upholstery", data: "upholstery", okval: "OK" },
      { title: "Seatbelts", data: "seatbelt", okval: "OK" },
      { title: "First Aid Kit", data: "firstaidkit", okval: "OK" },
      { title: "Fire Extinguisher", data: "fireextinguisher", okval: "OK" },
      { title: "Tool Kit", data: "toolkit", okval: "OK" },
      { title: "Vehicle Contact No.", data: "vehiclecontact", okval: "OK" },
      { title: "Low Headlights", data: "headlightslow", okval: "OK" },
      { title: "High Headlights", data: "headlightshigh", okval: "OK" },
      { title: "Left Signal Light", data: "leftsignal", okval: "OK" },
      { title: "Right Signal Light", data: "rightsignal", okval: "OK" },
      { title: "Tail Light", data: "taillight", okval: "OK" },
      { title: "Park Light", data: "parklight", okval: "OK" },
      { title: "Brake Light", data: "brakelight", okval: "OK" },
      { title: "Hazard Light", data: "hazardlight", okval: "OK" },
      { title: "Tire Wear", data: "tirewear", okval: "OK" },
      { title: "Tire Psi", data: "tirepsi", okval: "OK" },
      { title: "Spare Tire", data: "sparetire", okval: "OK" },
      { title: "Exhaust (Sound/Emission)", data: "exhaust", okval: "OK" },
      { title: "Dents / Scratches", data: "dentscratch", okval: "OK" },
      { title: "Wind Shield Glass", data: "windowshield", okval: "OK" },
      { title: "Windows", data: "windows", okval: "OK" },
      { title: "Side Sticker", data: "sticker", okval: "OK" },
    ];

    let oldcheck = this.state.checklist;
    let modalcontent = (
      <>
        {modalheader}
        <br />
        <FlexboxGrid>
          <Col2>
            <DatePickerSTD
              title="Date"
              val={this.state.date}
              label="date"
              editVal={this.editVal}
            />
            <HelpBlock>
              Last Service = {moment(oldcheck.date).format("DD-MMM-YY")} (
              {moment(oldcheck.date).fromNow()})
            </HelpBlock>
            <br />
          </Col2>
          <Col2>
            <NumbInputSTD
              title="Kilometer"
              val={this.state.kilometer}
              label="kilometer"
              editVal={this.editVal}
            />
            <HelpBlock>
              Last KM = {oldcheck.kilometer} (
              {PRKD(st.kilometer) - PRKD(oldcheck.kilometer)})
            </HelpBlock>
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          {checklistitems.map((ch, index) => (
            <Col2>
              <ChecklistTextInputSTD
                key={index}
                title={ch.title}
                val={st[ch.data]}
                label={ch.data}
                editVal={this.editVal}
                okvalue={ch.okval}
              />
              <HelpBlock>
                Last Value = {oldcheck[ch.data]}{" "}
                {oldcheck[ch.data] === "OK"
                  ? st[ch.data] === "OK"
                    ? ""
                    : "( New Issue )"
                  : st[ch.data] === "OK"
                  ? ""
                  : "( Still Pending )"}
              </HelpBlock>
              <br />
            </Col2>
          ))}
        </FlexboxGrid>
        <br />
        <TextAreaInputSTD
          title="Additional Remarks"
          val={this.state.remarks}
          label="remarks"
          editVal={this.editVal}
        />
        <br />
        <TextInputSTD
          title="Driver"
          val={this.state.driver}
          label="driver"
          editVal={this.editVal}
        />
        <br />
      </>
    );
    let disabled = false;
    if (st.title === "") disabled = true;
    if (st.driver === "") disabled = true;
    if (st.kilometer === "") disabled = true;
    for (let i = 0; i < checklistitems.length; i++) {
      if (st[checklistitems[i].data] === "") disabled = true;
      columnnames = [
        ...columnnames,
        {
          title: checklistitems[i].title,
          field: checklistitems[i].data,
          removable: true,
          render: (rowData) => {
            if (rowData[checklistitems[i].data] === "OK") {
              return <Icon icon="check" style={{ color: "green" }} />;
            } else {
              return (
                <>
                  <Icon
                    icon="exclamation-triangle"
                    style={{ color: "orange" }}
                  />{" "}
                  {rowData[checklistitems[i].data]}
                </>
              );
            }
          },
        },
      ];
    }
    columnnames = [
      ...columnnames,
      { title: "Remarks", field: "remarks", removable: true },
    ];

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          title={`Delete Checklist Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Delete Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postDelete()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <h3>Are you sure you want to delete this record?</h3>
          <h5>Checklist ID Number (#{this.state.equipmentChecklistNumId}):</h5>
          <FlexboxGrid>
            <Col3>
              <b>Date: </b>
              {moment(this.state.date).format("DD-MMM-YY")}
            </Col3>
            <Col3>
              <b>Title: </b> {this.state.title}
            </Col3>
            <Col3>
              <b>Driver: </b> {this.state.driver}
            </Col3>
          </FlexboxGrid>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.shownew}
          title={`Add New Checklist Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New"]}
              oC1={() => this.onHide()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          {modalcontent}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showedit}
          title={`Edit Checklist Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Update Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postEdit()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          {modalcontent}
        </UnifiedModal>
        <FlexboxGrid justify="space-around">{checklistbuttons}</FlexboxGrid>
        <hr />
        <FlexboxGrid justify="center">
          <Col3>
            <IconButton
              icon={
                <Icon
                  icon={equipmentStore.latestchecklist ? "eye-slash" : "eye"}
                />
              }
              block
              color="green"
              appearance={equipmentStore.latestchecklist ? "ghost" : "default"}
              size="xs"
              onClick={() => equipmentStore.changeChecklist()}
            >
              {equipmentStore.latestchecklist
                ? "Showing Latest Checklist"
                : "Showing All Checklist History"}
            </IconButton>
          </Col3>
        </FlexboxGrid>
        <hr />
        <div style={{ padding: "1%" }}>
          <MaterialTable
            isLoading={equipmentStore.loading.checklist}
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 20,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Checklist Record",
                onClick: (event, rowData) => {
                  this.setState({
                    showedit: true,
                    loading: false,
                    truckid: rowData.equipment._id,
                    checklistid: rowData._id,
                    checklist: rowData.equipment.checklist,
                    date: rowData.date,
                    equipment: rowData.equipment,

                    kilometer: rowData.kilometer,
                    driver: rowData.driver,
                    checkallfluids: rowData.checkallfluids,
                    engineoil: rowData.engineoil,
                    radiatorwater: rowData.radiatorwater,
                    wiper: rowData.wiper,
                    checktires: rowData.checktires,
                    fuellevel: rowData.fuellevel,
                    oil: rowData.oil,
                    battery: rowData.battery,
                    temp: rowData.temp,
                    horn: rowData.horn,
                    steeringwheel: rowData.steeringwheel,
                    handbrake: rowData.handbrake,
                    footbrake: rowData.footbrake,
                    registration: rowData.registration,
                    acheater: rowData.acheater,
                    interiorlights: rowData.interiorlights,
                    upholstery: rowData.upholstery,
                    seatbelt: rowData.seatbelt,
                    firstaidkit: rowData.firstaidkit,
                    fireextinguisher: rowData.fireextinguisher,
                    toolkit: rowData.toolkit,
                    vehiclecontact: rowData.vehiclecontact,
                    headlightslow: rowData.headlightslow,
                    headlightshigh: rowData.headlightshigh,
                    leftsignal: rowData.leftsignal,
                    rightsignal: rowData.rightsignal,
                    taillight: rowData.taillight,
                    parklight: rowData.parklight,
                    brakelight: rowData.brakelight,
                    hazardlight: rowData.hazardlight,
                    tirewear: rowData.tirewear,
                    tirepsi: rowData.tirepsi,
                    sparetire: rowData.sparetire,
                    exhaust: rowData.exhaust,
                    dentscratch: rowData.dentscratch,
                    windows: rowData.windows,
                    windowshield: rowData.windowshield,
                    sticker: rowData.sticker,
                    remarks: rowData.remarks,
                  });
                },
              },
              {
                icon: "delete",
                tooltip: "Delete Checklist Record",
                onClick: (event, rowData) => {
                  this.setState({
                    showdelete: true,
                    loading: false,
                    truckid: rowData.equipment._id,
                    checklistid: rowData._id,
                    date: rowData.date,
                    equipment: rowData.truckid,
                    checklist: rowData.equipment.checklist,
                    kilometer: rowData.kilometer,
                    driver: rowData.driver,
                    checkallfluids: rowData.checkallfluids,
                    engineoil: rowData.engineoil,
                    radiatorwater: rowData.radiatorwater,
                    wiper: rowData.wiper,
                    checktires: rowData.checktires,
                    fuellevel: rowData.fuellevel,
                    oil: rowData.oil,
                    battery: rowData.battery,
                    temp: rowData.temp,
                    horn: rowData.horn,
                    steeringwheel: rowData.steeringwheel,
                    handbrake: rowData.handbrake,
                    footbrake: rowData.footbrake,
                    registration: rowData.registration,
                    acheater: rowData.acheater,
                    interiorlights: rowData.interiorlights,
                    upholstery: rowData.upholstery,
                    seatbelt: rowData.seatbelt,
                    firstaidkit: rowData.firstaidkit,
                    fireextinguisher: rowData.fireextinguisher,
                    toolkit: rowData.toolkit,
                    vehiclecontact: rowData.vehiclecontact,
                    headlightslow: rowData.headlightslow,
                    headlightshigh: rowData.headlightshigh,
                    leftsignal: rowData.leftsignal,
                    rightsignal: rowData.rightsignal,
                    taillight: rowData.taillight,
                    parklight: rowData.parklight,
                    brakelight: rowData.brakelight,
                    hazardlight: rowData.hazardlight,
                    tirewear: rowData.tirewear,
                    tirepsi: rowData.tirepsi,
                    sparetire: rowData.sparetire,
                    exhaust: rowData.exhaust,
                    dentscratch: rowData.dentscratch,
                    windows: rowData.windows,
                    windowshield: rowData.windowshield,
                    sticker: rowData.sticker,
                    remarks: rowData.remarks,
                  });
                },
              },
              {
                icon: "refresh",
                tooltip: "Refresh",
                isFreeAction: true,
                onClick: (event, rowData) => {
                  equipmentStore.getEquipmentChecklist(true);
                },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(ChecklistTable));
