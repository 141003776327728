import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";

import HeaderSection from "./HeaderSection";

import { Icon, FlexboxGrid, IconButton } from "rsuite";
import HeaderFooterPDF from "../../../Template/HeaderFooterPDF";

class ACOrderDamageReport extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", loading: false, show: false };
    this.editVal = this.editVal.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val, loading: false });
  }

  render() {
    let dr = this.props.dr;
    let sto = this.props.sto;
    let client = this.props.client;

    return (
      <div>
        <FlexboxGrid justify="center">
          <IconButton
            icon={<Icon icon={this.state.show ? "eye-slash" : "eye"} />}
            onClick={() => this.setState({ show: !this.state.show })}
          >
            {this.state.show ? "Showing" : "Hiding"} PDF
          </IconButton>
        </FlexboxGrid>
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Damage Report ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Storage Note ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection sto={sto} client={client} dr={dr} />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(ACOrderDamageReport);
