import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Button, FlexboxGrid, Col, Input, InputGroup } from "rsuite";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = { edit: false };
  }
  render() {
    return (
      <div>
        <FlexboxGrid>
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
            <Button
              block
              onClick={() => this.setState({ edit: !this.state.edit })}
              color={this.state.edit ? "red" : "yellow"}
            >
              {this.state.edit ? "Cancel Changes" : "Edit Password"}
            </Button>
          </FlexboxGrid.Item>
          {this.state.edit && (
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
              <Button
                block
                onClick={() => this.setState({ edit: !this.state.edit })}
                color="green"
              >
                Submit Changes
              </Button>
            </FlexboxGrid.Item>
          )}
        </FlexboxGrid>

        <br />
        {this.state.edit && (
          <FlexboxGrid>
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
              <InputGroup>
                <InputGroup.Addon>Password:</InputGroup.Addon>
                <Input
                  disabled={!this.state.edit}
                  placeholder={this.props.placeholder}
                  size="lg"
                  value={this.props.pass}
                  onChange={(e) => this.props.changeSingle("password", e)}
                  autocomplete={false}
                  type="password"
                />
              </InputGroup>
            </FlexboxGrid.Item>
            <br />
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
              <InputGroup>
                <InputGroup.Addon> Confirm:</InputGroup.Addon>
                <Input
                  disabled={!this.state.edit}
                  placeholder={this.props.placeholder}
                  size="lg"
                  value={this.props.conf}
                  onChange={(e) => this.props.changeSingle("passconf", e)}
                  type="password"
                />
              </InputGroup>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        )}
      </div>
    );
  }
}

export default withRouter(observer(PasswordInput));
