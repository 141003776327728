import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import pic4 from "../../../../Static/Images/CompanyPics/pic4.png";
import pic5 from "../../../../Static/Images/CompanyPics/pic5.png";
import pic8 from "../../../../Static/Images/CompanyPics/pic8.png";
import pic6 from "../../../../Static/Images/CompanyPics/pic6.png";
import pic10 from "../../../../Static/Images/CompanyPics/pic10.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BannerImage from "../Banners/BannerImage";

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          swipeable
          useKeyboardArrows
        >
          <BannerImage
            title={"Outsource Your Logistics"}
            subtitle={"One Stop Shop for your Business!"}
            details={
              "Our experience in International Freight, Custom Clearance, and Warehousing ensures your products will be transported with quality."
            }
            image={pic10}
            imageleft
          />
          <BannerImage
            imageleft
            title={"Dedicated Staff"}
            subtitle={"Utilizing Easy Move's Local Move reputation in 3PL."}
            details={
              "Using the 4 pillars of our company, our staff work across Local Moving for Households and Businesses, International Logistics for Corporate and Customers, Utilizing our own Custom Clearance License in Kuwait, and our Warehouse facility and staff to make sure all your logistic needs are met."
            }
            image={pic8}
          />
          <BannerImage
            imageleft
            title={"Fully Insured Warehouse Facility"}
            subtitle={"Our Warehouse is fully insured for all items."}
            details={
              "The Easy Move Warehouse is up to the Fire and Safety standards set, along with CCTV cameras throughtout the warehouse 24/7. All items that enter the warehouse are insured."
            }
            image={pic4}
          />
          <BannerImage
            imageleft
            title={"In-House Inventory System"}
            subtitle={"Track your inventory in real time."}
            details={
              "Our in-house built inventory system is tailored for our scope of work. It is a complimentary service we provide to all our customers with unlimited users and items. Submit orders online, track requests, and restock when your items are running low."
            }
            image={pic6}
          />
          <BannerImage
            imageleft
            title={"First to Last Mile Delivery"}
            subtitle={"From International Shipping to Local Delivery"}
            details={
              "With Easy Move, you can rely on our services to ship the items into/out of the country and for any deliveries directly to the customer's doorstep."
            }
            image={pic5}
          />
        </Carousel>
      </div>
    );
  }
}

export default withRouter(observer(ImageCarousel));
