import React, { Component } from "react";
import { Chip } from "@material-ui/core";
import { observer } from "mobx-react";
import axios from "axios";

import AddIcon from "@material-ui/icons/Add";
import {
  FlexboxGrid,
  Col,
  Button,
  HelpBlock,
  Input,
  Notification,
} from "rsuite";
import { message, Modal } from "antd";
import userStore from "../../../../../../Stores/UserStore";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import baseURL from "../../../../../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class InvoiceTAs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modaldelete: false,
      modaladd: false,
      loadinvta: false,
      newinvta: "",
    };
    this.hideModal = this.hideModal.bind(this);
    this.editnewinvta = this.editnewinvta.bind(this);
    this.deleteINVTA = this.deleteINVTA.bind(this);
    this.postNewINVTA = this.postNewINVTA.bind(this);
    this.updateShipment = this.updateShipment.bind(this);
  }
  hideModal() {
    this.setState({ modaldelete: false, modaladd: false, newinvta: "" });
  }

  editnewinvta(val) {
    this.setState({ newinvta: val });
  }

  postNewINVTA(invoicetas, index) {
    let ind = this.props.ind;
    this.setState({ loadinvta: true });

    let invtas = [];
    invtas = [...invoicetas];
    invtas.push(this.state.newinvta);

    let finalinvta = [...invtas];

    sjStore.esjEditJob("invoicetas", finalinvta, ind, "cc");
    this.updateShipment("invoicetas", index);
  }
  deleteINVTA(invoicetas, index) {
    let ind = this.props.ind;
    this.setState({ loadinvta: true });
    let invtas = invoicetas.filter((inv) => inv !== this.state.newinvta);

    let finalinvta = [...invtas];
    sjStore.esjEditJob("invoicetas", finalinvta, ind, "cc");
    this.updateShipment("invoicetas", index);
  }

  updateShipment = async (label) => {
    this.setState({ loadinvta: true });
    let ind = this.props.ind;
    let data = {};
    let url = "";

    data[label] = sjStore.esjs[ind].cc[label];
    url = `job/sidejobs/sjs/editunique/${sjStore.sjs[ind]._id}`;

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.eMainJob(label, res.data, ind, "cc");

        this.setState({ loadinvta: false });
        this.hideModal();
        message.success(`${label} Updated.`);
      })
      .catch((err) => {
        this.setState({ loadinvta: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let esj = sjStore.esjs[ind];
    let invoicetas = esj.cc.invoicetas;

    let errinvta = { val: false, message: "" };
    let indexval = invoicetas.findIndex((inv) => inv === this.state.newinvta);

    let quality = this.props.quality;
    let qualitytext;
    if (quality) {
      if (invoicetas.length <= 0) {
        qualitytext = (
          <HelpBlock style={{ color: "red", fontWeight: "bold" }}>
            Must add at least 1 Invoice TA
          </HelpBlock>
        );
      }
    }

    if (this.state.newinvta === "") {
      errinvta = {
        val: true,
        message: "Please write in an Invoice TA number.",
      };
    } else if (indexval >= 0) {
      errinvta = {
        val: true,
        message: `${this.state.newinvta} Already Exists.`,
      };
    }
    if (this.state.loadinvta) {
      errinvta = { val: false, message: "" };
    }

    let invrows;
    if (editview) {
      invrows = invoicetas.map((inv, index) => (
        <Chip
          label={inv}
          key={index}
          onDelete={() => this.setState({ modaldelete: true, newinvta: inv })}
          variant="outlined"
        />
      ));
    } else {
      invrows = invoicetas.map((inv, index) => (
        <Chip label={inv} key={index} variant="outlined" />
      ));
    }

    return (
      <div>
        <Modal
          title="Delete Invoice TA Number"
          open={this.state.modaldelete}
          footer={null}
          onCancel={this.hideModal}
        >
          <p>
            Are you sure you want to delete Invoice TA #{this.state.newinvta}?
          </p>
          <hr />
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                disabled={this.state.loadinvta}
                appearance="ghost"
                color="green"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="red"
                loading={this.state.loadinvta}
                onClick={() => this.deleteINVTA(invoicetas, ind)}
              >
                Yes, Delete
              </Button>
            </Col>
          </FlexboxGrid>
        </Modal>
        <Modal
          title="Add Invoice TA"
          open={this.state.modaladd}
          footer={null}
          onCancel={this.hideModal}
        >
          <HelpBlock>New Invoice TA Number:</HelpBlock>
          <Input
            value={this.state.newinvta}
            onChange={(e) => this.editnewinvta(e)}
            style={{ border: errinvta.val && "1px dashed red" }}
          />
          <p style={{ fontSize: 10 }}>
            Existing Invoice TAs: <i>{invoicetas.join(", ")}</i>
          </p>
          {errinvta.val && (
            <HelpBlock style={{ color: "red" }}>{errinvta.message}</HelpBlock>
          )}
          <hr />
          <FlexboxGrid>
            <Col xs={12}>
              <Button
                block
                disabled={this.state.loadinvta}
                appearance="ghost"
                color="red"
                onClick={this.hideModal}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                block
                disabled={errinvta.val}
                color="green"
                loading={this.state.loadinvta}
                onClick={() => this.postNewINVTA(invoicetas, ind)}
              >
                Create
              </Button>
            </Col>
          </FlexboxGrid>
        </Modal>

        <FlexboxGrid
          align="middle"
          justify="center"
          style={{ paddingLeft: "1%", paddingRight: "1%" }}
        >
          {editview && (
            <Chip
              label={"New"}
              icon={<AddIcon style={{ color: "white" }} />}
              size="small"
              style={{
                backgroundColor: "green",
                color: "white",
                boxShadow: "0px 0px 3px black",
              }}
              onClick={() => this.setState({ modaladd: true, newinta: "" })}
            />
          )}
          <span style={{ width: 5 }} />
          <h6>
            <strong>
              {invoicetas.length > 0 ? "Invoice TAs: " : "No Invoice TAs."}
            </strong>
          </h6>
          {qualitytext}
          <span style={{ width: 5 }} />
          {invrows}
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(InvoiceTAs);
