import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import NumbInputSTU from "../../../../../Components/Inputs/Updatable/NumbInputSTU";
import inboundlist from "../../../../../Static/Lists/imlists/inboundlist";
import outboundlist from "../../../../../Static/Lists/imlists/outboundlist";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import imchargelist2 from "../../../../../Static/Lists/imlists/imcharges/imchargelist2";
import imchargelist3 from "../../../../../Static/Lists/imlists/imcharges/imchargelist3";
import imchargelist4 from "../../../../../Static/Lists/imlists/imcharges/imchargelist4";
import imchargelist5 from "../../../../../Static/Lists/imlists/imcharges/imchargelist5";
import { Statistic } from "antd";

class IMCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let newaim = newAimStore.newaim;
    let oldaim = newAimStore.oldaim;
    let load = this.props.load;
    let inout = "in";
    if (oldaim.direction === "Outbound") inout = "out";
    let list = inboundlist;
    if (oldaim.direction === "Outbound") {
      list = outboundlist;
    }

    let list2 = imchargelist2.filter((im) => im[inout] === true);
    let list3 = imchargelist3.filter((im) => im[inout] === true);
    let list4 = imchargelist4.filter((im) => im[inout] === true);
    let list5 = imchargelist5.filter((im) => im[inout] === true);

    let charges = {
      sec1: 0,
      sec2: 0,
      sec3: 0,
      sec4: 0,
      total: 0,
      customer: 0,
      addchg: 0,
      discount: 0,
      profit: 0,
    };
    for (let i = 0; i < list.length; i++) {
      charges.customer += Parsed(oldaim[list[i].label]);
    }
    if (oldaim.addcharge) {
      for (let i = 0; i < oldaim.addcharge.length; i++) {
        charges.addchg += Parsed(oldaim.addcharge[i].price);
      }
    }
    if (oldaim.discount) {
      for (let i = 0; i < oldaim.discount.length; i++) {
        charges.discount += Parsed(oldaim.discount[i].price);
      }
    }
    for (let i = 0; i < list2.length; i++) {
      charges.sec1 += parseFloat(oldaim[list2[i].label]);
    }
    for (let i = 0; i < list3.length; i++) {
      charges.sec2 += parseFloat(oldaim[list3[i].label]);
    }
    for (let i = 0; i < list4.length; i++) {
      charges.sec3 += parseFloat(oldaim[list4[i].label]);
    }
    if (oldaim.commodity === "Dangerous Goods") {
      for (let i = 0; i < list5.length; i++) {
        charges.sec4 += parseFloat(oldaim[list5[i].label]);
      }
    }
    charges.total += Parsed(charges.sec1) + Parsed(charges.sec2);
    charges.total += Parsed(charges.sec3) + Parsed(charges.sec4);
    charges.profit =
      Parsed(charges.customer) +
      Parsed(charges.addchg) -
      Parsed(charges.discount) -
      Parsed(charges.total);

    let chg2 = list2.map((ch, index) => (
      <Col2 key={index}>
        <NumbInputSTU
          val={newaim[ch.label]}
          org={oldaim[ch.label]}
          label={ch.label}
          load={load[ch.label]}
          title={ch.title}
          size="sm"
          min={0}
          editVal={this.props.editVal}
          updateVal={this.props.postChanges}
        />
      </Col2>
    ));

    let chg3 = list3.map((ch, index) => (
      <Col2 key={index}>
        <NumbInputSTU
          val={newaim[ch.label]}
          org={oldaim[ch.label]}
          label={ch.label}
          load={load[ch.label]}
          title={ch.title}
          size="sm"
          min={0}
          editVal={this.props.editVal}
          updateVal={this.props.postChanges}
        />
      </Col2>
    ));

    let chg4 = list4.map((ch, index) => (
      <Col2 key={index}>
        <NumbInputSTU
          val={newaim[ch.label]}
          org={oldaim[ch.label]}
          label={ch.label}
          load={load[ch.label]}
          title={ch.title}
          size="sm"
          min={0}
          editVal={this.props.editVal}
          updateVal={this.props.postChanges}
        />
      </Col2>
    ));
    let chg5 = list5.map((ch, index) => (
      <Col2 key={index}>
        <NumbInputSTU
          val={newaim[ch.label]}
          org={oldaim[ch.label]}
          label={ch.label}
          load={load[ch.label]}
          title={ch.title}
          size="sm"
          min={0}
          editVal={this.props.editVal}
          updateVal={this.props.postChanges}
        />
      </Col2>
    ));

    return (
      <div>
        <FlexboxGrid justify="space-around">
          <Statistic
            title={`Customer Charges`}
            value={PRKD(charges.customer)}
            style={{
              border: `3px solid green`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
          <Statistic
            title={`Additional + Discount`}
            value={PRKD(Parsed(charges.addchg) - Parsed(charges.discount))}
            style={{
              border: `3px solid green`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
          <Statistic
            title={`IM Costs`}
            value={PRKD(charges.total)}
            style={{
              border: `3px solid red`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
          <Statistic
            title={`Profit`}
            value={PRKD(charges.profit)}
            style={{
              border: `3px solid ${charges.profit > 0 ? "green" : "red"}`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
        </FlexboxGrid>
        <Div14>Section 1: Origin Charges ({PRKD(charges.sec1)} KD)</Div14>
        <FlexboxGrid>{chg2}</FlexboxGrid>
        <Div14>Section 2: Shipping Charges ({PRKD(charges.sec2)} KD)</Div14>
        <FlexboxGrid>{chg3}</FlexboxGrid>
        <Div14>Section 3: Destination Charges ({PRKD(charges.sec3)} KD)</Div14>
        <FlexboxGrid>{chg4}</FlexboxGrid>
        {oldaim.commodity === "Dangerous Goods" && (
          <>
            <Div14>
              Section 4: Dangerous Goods Charges ({PRKD(charges.sec4)} KD)
            </Div14>
            <FlexboxGrid>{chg5}</FlexboxGrid>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(observer(IMCharges));
