import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import moment from "moment";
import singleAccountStore from "./SingleAccountStore";

const instance = axios.create({ baseURL: baseURL });

class SingleAccountCalendarStore {
  constructor() {
    this.account = { _id: "" };
    this.calendar = [];
    this.startdate = moment().subtract(180, "days");
    this.filters = { aim: false, ast: false, adel: false, acc: false };

    this.loading = {
      calendar: false,
    };
    this.loaded = {
      calendar: false,
    };

    makeObservable(this, {
      account: observable,
      calendar: observable,
      startdate: observable,
      filters: observable,

      loading: observable,
      loaded: observable,
    });
  }

  // FETCH
  getCalendarJobs() {
    this.loading.calendar = true;
    let data = { startdate: this.startdate };
    return instance
      .put(
        `/ac/advaccount/calendarjobs/${singleAccountStore.account._id}`,
        data,
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        this.account = data.client;
        this.calendar = data.calendar;

        this.loading.calendar = false;
        this.loaded.calendar = true;
        console.log("Single Account Dashboard Fetched");
      })
      .catch((err) => {
        this.loading.calendar = false;
        this.loaded.calendar = false;

        console.log(err);

        Notification["error"]({
          title: `Error Fetching Calendar.`,
        });
      });
  }
  getAllCalendarJobs() {
    this.loading.calendar = true;
    let data = { startdate: this.startdate };
    return instance
      .put(`/ac/advaccount/allcalendarjobs`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.account = data.client;
        this.calendar = data.calendar;

        this.loading.calendar = false;
        this.loaded.calendar = true;
        console.log("Single Account Dashboard Fetched");
      })
      .catch((err) => {
        this.loading.calendar = false;
        this.loaded.calendar = false;

        console.log(err);

        Notification["error"]({
          title: `Error Fetching Calendar.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  editDate(val) {
    singleAccountCalendarStore.startdate = val;
  }

  startLoading() {
    this.loading = true;
  }
}

const singleAccountCalendarStore = new SingleAccountCalendarStore();
export default singleAccountCalendarStore;
