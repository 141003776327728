import { observer } from "mobx-react";
import React, { Component } from "react";
import { Tag, Badge } from "antd";
import { Descriptions } from "antd";

import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class SingleLocationTable extends Component {
  render() {
    let to = this.props.to;
    let del = this.props.del;

    if (del.maintype === "Fixed Per Type") {
      return (
        <Descriptions
          title={`Dropoff Location # ${to.order}`}
          bordered
          size="small"
        >
          <Descriptions.Item label="Status">
            <Tag
              color={to.status === "Completed" ? "green" : "orange"}
              style={{ fontWeight: "bold" }}
            >
              <Badge
                status={to.status === "Completed" ? "default" : "processing"}
                style={{
                  color: to.status === "Completed" ? "green" : "orange",
                }}
                color={to.status === "Completed" ? "green" : "orange"}
                text={to.status}
              />
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Name">{to.name}</Descriptions.Item>
          <Descriptions.Item label="Phone">{to.phone}</Descriptions.Item>
          <Descriptions.Item label="City">{to.city}</Descriptions.Item>
          <Descriptions.Item label="Type">{to.type}</Descriptions.Item>
          <Descriptions.Item label="Floor">{to.floor}</Descriptions.Item>
          <Descriptions.Item label={`Address`} span={3}>
            {to.address}
          </Descriptions.Item>
          {to.ngfchg > 0 && (
            <Descriptions.Item label="Non-Ground Floor Charge">
              {PRKD(to.ngfchg)} KD
            </Descriptions.Item>
          )}
          {to.zonecost > 0 && (
            <Descriptions.Item label={`Zone ${to.zonetype}`}>
              {PRKD(to.zonecost)} KD
            </Descriptions.Item>
          )}
          {to.service && (
            <Descriptions.Item label="Service Type">
              {to.service.type}: {PRKD(to.service.price)} KD
            </Descriptions.Item>
          )}
        </Descriptions>
      );
    }

    let tcbm = 0;
    let tpackages = 0;
    let items = to.items;
    if (items) {
      for (let k = 0; k < items.length; k++) {
        tpackages += Parsed(items[k].quantity);
        tcbm +=
          (Parsed(items[k].length) *
            Parsed(items[k].width) *
            Parsed(items[k].height) *
            Parsed(items[k].quantity)) /
          1000000;
      }
    }

    return (
      <Descriptions
        title={`Dropoff Location # ${to.order}`}
        bordered
        size="small"
      >
        <Descriptions.Item label="Status">
          <Tag
            color={to.status === "Completed" ? "green" : "orange"}
            style={{ fontWeight: "bold" }}
          >
            <Badge
              status={to.status === "Completed" ? "default" : "processing"}
              style={{
                color: to.status === "Completed" ? "green" : "orange",
              }}
              color={to.status === "Completed" ? "green" : "orange"}
              text={to.status}
            />
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Name">{to.name}</Descriptions.Item>
        <Descriptions.Item label="Phone">{to.phone}</Descriptions.Item>
        <Descriptions.Item label="City">{to.city}</Descriptions.Item>
        <Descriptions.Item label="Type">{to.type}</Descriptions.Item>
        <Descriptions.Item label="Floor">{to.floor}</Descriptions.Item>
        <Descriptions.Item label={`Address`} span={3}>
          {to.address}
        </Descriptions.Item>
        <Descriptions.Item label="CBM">{PRKD(tcbm)} CBM</Descriptions.Item>
        <Descriptions.Item label="Items">
          {items.length} Item{items.length === 1 ? "" : "s"}
        </Descriptions.Item>
        <Descriptions.Item label="Packages">
          {PRKD(tpackages)} Package{tpackages === 1 ? "" : "s"}
        </Descriptions.Item>
        {to.ngfchg > 0 && (
          <Descriptions.Item label="Non-Ground Floor Charge">
            {PRKD(to.ngfchg)} KD
          </Descriptions.Item>
        )}
        {to.zonecost > 0 && (
          <Descriptions.Item label={`Zone ${to.zonetype}`}>
            {PRKD(to.zonecost)} KD
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  }
}

export default observer(SingleLocationTable);
