import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import hrStore from "../../../../Stores/HR/HRStore";

class NewRenewal extends Component {
  render() {
    let hrdoc = this.props.hrdoc;
    let staff = hrStore.staff;

    let staffopts = staff.map((st, index) => (
      <IconButton
        key={index}
        icon={<Icon icon={hrdoc.staff === st._id ? "check" : "circle"} />}
        onClick={() => this.props.editVal(st._id, "staff")}
        color={hrdoc.staff === st._id && "green"}
      >
        {st.name}
      </IconButton>
    ));
    let disabled = false;

    if (this.props.page === "Staff") {
      if (hrdoc.staff === "") disabled = true;
    } else {
      if (hrdoc.type === "Staff") disabled = true;
    }
    if (hrdoc.name === "") disabled = true;

    let type = "New";
    if (hrdoc._id !== "New") type = "Edit";

    let content = staffopts;

    if (this.props.page !== "Staff") {
      content = (
        <SelectInputSTD
          title="Type"
          val={hrdoc.type}
          label="type"
          size="large"
          opts={[
            "Certificate",
            "Company",
            "Equipment",
            "Marketing",
            "Property",
            "Other",
          ]}
          editVal={this.props.editVal}
        />
      );
    }

    return (
      <UnifiedModal
        show={this.props.show}
        title={`${type} HR Document:`}
        onHide={this.props.hideModal}
        footer={
          <FlexboxGrid>
            <Col2>
              <IconButton
                block
                loading={this.props.loading}
                color="red"
                icon={<Icon icon="close" />}
                onClick={() => this.props.hideModal()}
              >
                Cancel
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                block
                disabled={disabled}
                loading={this.props.loading}
                color="green"
                icon={<Icon icon="save" />}
                onClick={() => {
                  if (type === "New") {
                    this.props.postNew();
                  } else {
                    this.props.postEdit();
                  }
                }}
              >
                {type === "New" ? "Create New Document" : "Save Changes"}
              </IconButton>
            </Col2>
          </FlexboxGrid>
        }
      >
        <FlexboxGrid style={{ padding: "1%" }}>{content}</FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <TextInputSTD
              title="Doc Name"
              val={hrdoc.name}
              label="name"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
          <Col2>
            <SelectInputSTD
              title="Status"
              val={hrdoc.status}
              label="status"
              size="large"
              opts={["Active", "Cancelled"]}
              editVal={this.props.editVal}
            />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <TextAreaInputSTD
            title="Remarks"
            val={hrdoc.remark}
            label="remark"
            size="large"
            editVal={this.props.editVal}
          />
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <DatePickerSTD
            title="Valid Since"
            val={hrdoc.validsince}
            label="validsince"
            size="large"
            editVal={this.props.editVal}
          />
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <DatePickerSTD
            title="Expires On"
            val={hrdoc.expiry}
            label="expiry"
            size="large"
            editVal={this.props.editVal}
          />
        </FlexboxGrid>
        <h3 style={{ textAlign: "center" }}>
          <i>
            Valid for{" "}
            <b>
              {PRKD(
                moment(hrdoc.expiry).diff(
                  moment(hrdoc.validsince),
                  "year",
                  true
                )
              )}{" "}
              Year
            </b>{" "}
            and Expires <b>{moment(hrdoc.expiry).fromNow()}</b>.
          </i>
        </h3>
      </UnifiedModal>
    );
  }
}

export default observer(NewRenewal);
