import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Div14 from "../../../../Components/Dividers/Div14";
import { Descriptions } from "antd";

import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import DeliveryTimeRequestTable from "../Dashboard/NewStorage/Steps/Supporting/DeliveryTimeRequestTable";
import { Collapse } from "antd";

const { Panel } = Collapse;

class DeliveryContractInfo extends Component {
  render() {
    let delCont = this.props.delCont;

    let dailydel;
    let delpricing;
    let dellOptions;

    if (delCont) {
      dailydel = (
        <FlexboxGrid>
          <Col2>
            <Div14>Daily Delivery Fees</Div14>
            {delCont.pricepertrip && (
              <Descriptions title={false} bordered size="small">
                <Descriptions.Item label="Sun Del. Fee" span={3}>
                  {delCont.pricepertrip.sun} KD
                </Descriptions.Item>
                <Descriptions.Item label="Mon Del. Fee" span={3}>
                  {delCont.pricepertrip.mon} KD
                </Descriptions.Item>
                <Descriptions.Item label="Tue Del. Fee" span={3}>
                  {delCont.pricepertrip.tue} KD
                </Descriptions.Item>
                <Descriptions.Item label="Wed Del. Fee" span={3}>
                  {delCont.pricepertrip.wed} KD
                </Descriptions.Item>
                <Descriptions.Item label="Thu Del. Fee" span={3}>
                  {delCont.pricepertrip.thu} KD
                </Descriptions.Item>
                <Descriptions.Item label="Fri Del. Fee" span={3}>
                  {delCont.pricepertrip.fri} KD
                </Descriptions.Item>
                <Descriptions.Item label="Sat Del. Fee" span={3}>
                  {delCont.pricepertrip.sat} KD
                </Descriptions.Item>
              </Descriptions>
            )}
          </Col2>

          <Col2>
            <Div14>Zone Delivery Fees</Div14>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Zone 1 Del. Fee" span={3}>
                {delCont.zoneadd.z1} KD
              </Descriptions.Item>
              <Descriptions.Item label="Zone 2 Del. Fee" span={3}>
                {delCont.zoneadd.z2} KD
              </Descriptions.Item>
              <Descriptions.Item label="Zone 3 Del. Fee" span={3}>
                {delCont.zoneadd.z3} KD
              </Descriptions.Item>
              <Descriptions.Item label="Zone 4 Del. Fee" span={3}>
                {delCont.zoneadd.z4} KD
              </Descriptions.Item>
              <Descriptions.Item label="Zone 5 Del. Fee" span={3}>
                {delCont.zoneadd.z5} KD
              </Descriptions.Item>
              <Descriptions.Item label="Multi-Drop Off Del. Fee" span={3}>
                {delCont.dropoffmult} KD
              </Descriptions.Item>
              <Descriptions.Item label="Non-Ground Floor Charge" span={3}>
                {delCont.nongroundfloorprice} KD
              </Descriptions.Item>
            </Descriptions>
          </Col2>
        </FlexboxGrid>
      );

      if (delCont.maintype === "Dedicated Staff") {
        delpricing = (
          <>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Weekly Rate">
                {PRKD(delCont.fixedweekly)} KD
              </Descriptions.Item>
              <Descriptions.Item label="Staff Count">
                {delCont.staffcount}
              </Descriptions.Item>
              <Descriptions.Item label="Truck Count">
                {delCont.carcount}
              </Descriptions.Item>
            </Descriptions>
          </>
        );
      } else if (delCont.maintype === "Dedicated Staff - Monthly") {
        delpricing = (
          <>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Monthly Rate">
                {PRKD(delCont.fixedweekly)} KD
              </Descriptions.Item>
              <Descriptions.Item label="Staff Count">
                {delCont.staffcount}
              </Descriptions.Item>
              <Descriptions.Item label="Truck Count">
                {delCont.carcount}
              </Descriptions.Item>
            </Descriptions>
          </>
        );
      } else if (delCont.maintype === "Daily Per Truck") {
        delpricing = (
          <>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Price Per Truck">
                {PRKD(delCont.pricepertruck)} KD
              </Descriptions.Item>
              <Descriptions.Item label="Minimum Daily Truck">
                {delCont.carcount}
              </Descriptions.Item>
            </Descriptions>
          </>
        );
      } else if (delCont.maintype === "Fixed Per Type") {
        delpricing = (
          <>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Weekly Rate">
                {PRKD(delCont.fixedweekly)} KD
              </Descriptions.Item>
            </Descriptions>
            {dailydel}
          </>
        );
      } else if (delCont.maintype === "Fixed Per Trip") {
        delpricing = (
          <>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Max CBM Per Trip">
                {PRKD(delCont.maxcbmpertrip)} KD
              </Descriptions.Item>
            </Descriptions>
            {dailydel}
          </>
        );
      }

      dellOptions = delCont.options.map((del, index) => (
        <Descriptions.Item
          span={3}
          label={`Option ${index + 1}: ${del.title}`}
          key={index}
        >
          {del.delstor} {del.inout}: {del.price} {del.type}
        </Descriptions.Item>
      ));
    }

    return (
      <div>
        <Div14>Delivery Contract Info</Div14>
        <Collapse>
          <Panel header="Main Delivery Pricing">
            <Div14>Main Delivery Pricing</Div14>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Main Type" span={3}>
                {delCont && delCont.maintype}
              </Descriptions.Item>
            </Descriptions>
            {delpricing}
          </Panel>
        </Collapse>
        <Collapse>
          <Panel header="Request Timing and Fees">
            <Div14>Request Timing and Fees</Div14>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Time to Request">
                {delCont && delCont.timetoreq} Hrs
              </Descriptions.Item>
              <Descriptions.Item label="Express Time">
                {delCont && delCont.expresstime} Hrs
              </Descriptions.Item>
              <Descriptions.Item label="Cancellation Time">
                {delCont && delCont.cancelhours} Hrs
              </Descriptions.Item>
              <Descriptions.Item label="After Hour Fee">
                {delCont && delCont.afterhourfee} KD
              </Descriptions.Item>
              <Descriptions.Item label="Express Fee">
                {delCont && delCont.expressfee} KD
              </Descriptions.Item>
              <Descriptions.Item label="Cancellation Fee">
                {delCont && delCont.cancelprice} KD
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>

        <Collapse>
          <Panel header="Delivery Options">
            <Div14>Available Delivery Options</Div14>
            <Descriptions title={false} bordered size="small">
              {dellOptions}
            </Descriptions>
          </Panel>
        </Collapse>

        {delCont && <DeliveryTimeRequestTable delCont={delCont} hideTitle />}
      </div>
    );
  }
}

export default observer(withRouter(DeliveryContractInfo));
