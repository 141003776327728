import React, { Component } from "react";
import { InputGroup } from "rsuite";
import { Select } from "antd";
const { Option } = Select;

class SelectInputSTD extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;
    let label = this.props.label;
    let opts = this.props.opts;
    let index = this.props.index;
    let val = this.props.val;
    let size = "small";
    if (this.props.size) size = this.props.size;
    let options = opts.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));

    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <Select
          disabled={disabled}
          showSearch
          size={size}
          value={val}
          style={{ width: "100%" }}
          placeholder={this.props.label}
          optionFilterProp="children"
          onChange={(e) => this.props.editVal(e, label, index)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options}
        </Select>
      </InputGroup>
    );
  }
}

export default SelectInputSTD;
