import { makeObservable, observable, computed } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";

const instance = axios.create({ baseURL: baseURL });

class ServiceStore {
  constructor() {
    this.services = [];
    this.editservices = [];
    this.allaccounts = [];
    this.loading = false;
    this.dataloaded = false;

    makeObservable(this, {
      services: observable,
      editservices: observable,
      loading: observable,
      dataloaded: observable,
      allaccounts: observable,
      allservices: computed,
      allreceipts: computed,
      lmservice: computed,
      imservice: computed,
      ccservice: computed,
      stservice: computed,
      storagein: computed,
      storageout: computed,
      deliveryin: computed,
      deliveryout: computed,
    });
  }

  // FETCH
  getServices(overide) {
    let loaded = false;
    if (this.loading || this.dataloaded) {
      loaded = true;
    }
    if (overide) loaded = false;

    if (loaded) return null;

    this.loading = true;
    return instance
      .get(`/additional/services/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((services) => {
        this.services = services;
        this.editservices = [...services];
        this.allaccounts = services.filter((ser) => ser.type === "AC Order");
        this.loading = false;
        this.dataloaded = true;
        console.log("Services Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  // ----- DATE ----
  editService(type, val, i) {
    this.editservices[i][type] = val;
  }

  editMainDateLms(type, val, i) {
    this.services[i][type] = val;
  }

  addNewService(service) {
    let newService = [...this.services];
    newService.push(service);
    this.services = [...newService];
    this.editservices = [...newService];
  }

  startLoading() {
    this.loading = true;
  }

  get allservices() {
    return this.services.filter((ser) => ser.type === "Service");
  }

  get storagein() {
    return this.allaccounts.filter((ser) => ser.showSTin);
  }
  get storageout() {
    return this.allaccounts.filter((ser) => ser.showSTout);
  }
  get deliveryin() {
    return this.allaccounts.filter((ser) => ser.showDELin);
  }
  get deliveryout() {
    return this.allaccounts.filter((ser) => ser.showDELout);
  }

  get allreceipts() {
    return this.services.filter((ser) => ser.type === "Receipt");
  }

  get lmservice() {
    return this.services.filter(
      (ser) => (ser.showLM === true) & (ser.type === "Service")
    );
  }
  get imservice() {
    return this.services.filter(
      (ser) => (ser.showIM === true) & (ser.type === "Service")
    );
  }
  get ccservice() {
    return this.services.filter(
      (ser) => (ser.showCC === true) & (ser.type === "Service")
    );
  }
  get stservice() {
    return this.services.filter(
      (ser) => (ser.showST === true) & (ser.type === "Service")
    );
  }
}

const serviceStore = new ServiceStore();
export default serviceStore;
