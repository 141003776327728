import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Icon, IconButton } from "rsuite";
import clientBalanceStore from "../../../Stores/Financial/ClientBalanceStore";
import LoadBack from "../../../Components/Loaders/LoadBack";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
  LoadPanel,
  Button,
} from "devextreme-react/data-grid";
import { Tooltip } from "@material-ui/core";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import moment from "moment";
import singleJobStore from "../../../Stores/SingleJobStore";
import sjStore from "../../../Stores/SideJobs/SJStore";
import Div14 from "../../../Components/Dividers/Div14";

class ClientBalancesMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoExpandAll: true,
      acinvoice: {},
      show: false,
      showedit: false,
      load: false,
    };
    this.onExporting = this.onExporting.bind(this);
    this.DownloadInvoice = this.DownloadInvoice.bind(this);
    this.StatusCell = this.StatusCell.bind(this);
    this.SJSData = this.SJSData.bind(this);

    this.onHide = this.onHide.bind(this);
  }
  componentDidMount() {
    clientBalanceStore.getJobBalances();
    clientBalanceStore.getMonthlySales();
  }
  onHide() {
    this.setState({ show: false, showedit: false, acinvoice: {}, load: false });
  }
  SJSData(cellData) {
    let sjs = cellData.data.sjs;
    let sjsids = [];
    for (let i = 0; i < sjs.length; i++) {
      if (sjs[i].status === "Booked" || sjs[i].status === "Converted") {
        sjsids = [
          ...sjsids,
          `${sjs[i].jobtype}-${sjs[i].sjNumId} (${sjs[i].status})`,
        ];
      }
    }
    return sjsids.join(", ");
  }

  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending" || status === "Cancelled") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }

  DownloadInvoice(cellData) {
    return (
      <>
        <Tooltip title={`Go to Job Page`} arrow>
          <IconButton
            icon={<Icon icon="truck" />}
            color="green"
            circle
            size="xs"
            onClick={() => {
              this.props.history.push(`/singlejob/${cellData.data._id}`);
              singleJobStore.getJob(cellData.data._id, true);
              sjStore.getSJs(cellData.data._id, true);
            }}
          />
        </Tooltip>
        <span> </span>
      </>
    );
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ClientBalances_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  render() {
    return (
      <div>
        <br />
        <LoadBack loading={clientBalanceStore.loading.balance}>
          <Div14>Job Balances</Div14>
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() => clientBalanceStore.getJobBalances(true)}
          >
            Reload Data
          </IconButton>
          <DataGrid
            dataSource={clientBalanceStore.clients}
            keyExpr="jobNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <LoadPanel enabled={true} />
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />

            <Column
              dataField="jobNumId"
              dataType="number"
              caption="Job ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="clientname"
              caption="Client"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="status"
              caption="SubJobs"
              alignment="center"
              minWidth={80}
              cellRender={this.SJSData}
            />
            <Column
              dataField="jobdaterange"
              caption="Job Dates"
              alignment="center"
              minWidth={120}
            />

            <Column
              dataField="lastprice"
              dataType="number"
              caption="Job Price (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={100}
            />
            <Column
              dataField="invprice"
              dataType="number"
              caption="Invoice Price (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={100}
            />
            <Column
              dataField="payprice"
              dataType="number"
              caption="Payments (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={100}
            />
            <Column
              dataField="balance"
              dataType="number"
              caption="Balance (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={100}
            />

            <Column
              caption="Go To Job"
              alignment="center"
              minWidth={80}
              cellRender={this.DownloadInvoice}
            />

            <Summary>
              <TotalItem
                column="jobNumId"
                summaryType="count"
                displayFormat={"{0}"}
              />
              <TotalItem
                column="lastprice"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="invprice"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="payprice"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="balance"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Toolbar>
              <Item name="Refresh" location="before">
                <Button
                  icon="refresh"
                  onClick={() => clientBalanceStore.getJobBalances(true)}
                />
              </Item>

              <Tooltip title={`Choose Columns`} arrow placement="left">
                <Item name="columnChooserButton" />
              </Tooltip>
              <Item name="exportButton" />
            </Toolbar>
            <Export enabled={true} />
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(ClientBalancesMain));
