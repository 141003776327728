import React, { Component } from "react";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton, Col, HelpBlock } from "rsuite";
import { Notification } from "rsuite";
import axios from "axios";

import moment from "moment";
import serviceStore from "../../../Stores/AdditionalStores/ServiceStore";
import ParagraphLoader from "../../../Components/Loaders/ParagraphLoader";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import paymentOptionStore from "../../../Stores/AdditionalStores/PaymentOptionStore";
import SelectInputSTD from "../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";

const instance = axios.create({ baseURL: baseURL });

class PaymentOptionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      selectedOption: { title: "", type: "", subtype: "", cash: "" },
      editedOption: { title: "", type: "", subtype: "", cash: "" },
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNewService = this.postNewService.bind(this);
  }

  changeVal(val, label) {
    let option = { ...this.state.editedOption };
    option[label] = val;
    this.setState({ editedOption: option });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ showEdit: val });
    } else {
      this.setState({
        selectedOption: { title: "", type: "", subtype: "", cash: "", _id: "" },
        editedOption: { title: "", type: "", subtype: "", cash: "", _id: "" },
        showEdit: false,
      });
    }
  }

  postNewService = async (option) => {
    this.changeVal("loading", true);

    return instance
      .put(`/jf/paymentoption/edit/${option._id}`, option, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        paymentOptionStore.getOptions();
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Updated Payment Option ${option.title}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    if (serviceStore.loading) {
      return <ParagraphLoader />;
    }

    let data = this.props.options;
    let tableTitle = "Payment Option List";

    let columnnames = [
      { title: "Type", field: "type", defaultSort: "asc" },
      { title: "Subtype", field: "subtype" },
      { title: "Cash", field: "cash" },
      { title: "Title", field: "title" },
    ];

    let disabled = false;
    if (this.state.selectedOption === this.state.editedOption) {
      disabled = true;
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showEdit}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={disabled}
                    loading={this.state.loading}
                    onClick={() => this.postNewService(this.state.editedOption)}
                  >
                    Save Changes
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h5>Edit {this.state.editedOption.title} Details</h5>
          <h5>New Payment Option Details</h5>
          <SelectInputSTD
            title="Type"
            val={this.state.editedOption.type}
            label="type"
            size="large"
            opts={["Income", "Expense Fixed", "Expense Variable", "Transfer"]}
            editVal={this.changeVal}
          />
          <br />
          <SelectInputSTD
            title="Sub Type"
            val={this.state.editedOption.cash}
            label="cash"
            size="large"
            opts={["Cash", "Non-Cash"]}
            editVal={this.changeVal}
          />
          <br />
          <SelectInputSTD
            title="Sub Type"
            val={this.state.editedOption.subtype}
            label="subtype"
            size="large"
            opts={[
              "Job Related",
              "Staff Related",
              "Office Related",
              "Bank Related",
              "Equipment Related",
              "Marketing",
              "Other",
            ]}
            editVal={this.changeVal}
          />
          <HelpBlock>Such as Job Related, Refund, Damage...</HelpBlock>
          <br />
          <TextInputSTD
            title="Title"
            editVal={this.changeVal}
            val={this.state.editedOption.title}
            label="title"
          />
          <HelpBlock>Name of the Payment</HelpBlock>
        </UnifiedModal>

        {!serviceStore.loading && (
          <MaterialTable
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  this.setState({ selectedOption: rowData });
                  this.setState({ editedOption: rowData });
                  this.showHideModal(true);
                },
              },
              {
                icon: "refresh",
                isFreeAction: true,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  serviceStore.getServices();
                },
              },
            ]}
          />
        )}
        <br />
      </div>
    );
  }
}

export default withRouter(observer(PaymentOptionTable));
