import React, { Component } from "react";

import { observer } from "mobx-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { FlexboxGrid, IconButton, Icon, HelpBlock } from "rsuite";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import Col2 from "../../../../../Components/Columns/Col2";
import userStore from "../../../../../Stores/UserStore";

class InvNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      modules: {
        toolbar: [
          ["italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      },
    };
  }
  setValue(e) {
    this.setState({ value: e });
  }

  render() {
    let label = "invnotes";
    let title = "International Move Notes";
    let load = this.props.load;

    let val = newAimStore.newaim.invnotes;
    let org = newAimStore.oldaim.invnotes;

    let change = false;
    if (org !== val) change = true;

    if (!userStore.user.staffaccount) {
      return (
        <div>
          <FlexboxGrid>
            <Col2>
              <h4>International Move Notes</h4>
              <HelpBlock>
                <div style={{ color: "black" }}>
                  Notes to be added related to the job, such as International
                  Move Restrictions or Others.
                </div>
              </HelpBlock>
            </Col2>
          </FlexboxGrid>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: val }} />
          <br />
        </div>
      );
    }

    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <h4>International Move Notes</h4>
            <HelpBlock>
              <div style={{ color: "black" }}>
                Notes to be added related to the job, such as International Move
                Restrictions or Others.
              </div>
              All font sizes will be reset, Bold and Colors will not show.
            </HelpBlock>
          </Col2>
          {change && (
            <Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="save" />}
                  block
                  color="green"
                  onClick={() => this.props.updateVal(label, title)}
                  loading={load}
                >
                  Submit
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="refresh" />}
                  block
                  onClick={() => this.props.editVal(org, label)}
                  loading={load}
                >
                  Reset
                </IconButton>
              </Col2>
            </Col2>
          )}
        </FlexboxGrid>

        <ReactQuill
          theme="snow"
          value={val}
          onChange={(e) => this.props.editVal(e, label)}
          modules={this.state.modules}
        />
        <br />
      </div>
    );
  }
}

export default observer(InvNotes);
