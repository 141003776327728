let imchargelist4 = [
  { title: "Import Customs", label: "chimport", in: true, out: false },
  { title: "Transportation", label: "chtransport", in: true, out: false },
  { title: "Customs Duty", label: "chcustom", in: true, out: false },
  { title: "DO/DTHC Charges", label: "chdthc", in: true, out: false },
  { title: "PAI Charges", label: "chpai", in: true, out: false },
  { title: "EPA Charges", label: "chepa", in: true, out: false },
  { title: "Bayan Charges", label: "chbayan", in: true, out: false },
  { title: "Global Clearance", label: "chclearance", in: true, out: false },
  { title: "Container Release", label: "chrelease", in: true, out: false },
  { title: "Demurrage", label: "chdemurrage", in: true, out: false },
  { title: "Detention", label: "chdetention", in: true, out: false },
  { title: "Port Fees", label: "chport", in: true, out: false },
  { title: "Other", label: "chothersec4", in: true, out: false },

  { title: "Delivery Order", label: "chdelord", in: false, out: true },
  { title: "Terminal Handeling", label: "chterhand", in: false, out: true },
  {
    title: "Destination Clearance",
    label: "chdestclear",
    in: false,
    out: true,
  },
  { title: "Shuttle Charges", label: "chshuttle", in: false, out: true },
  { title: "Demurrage", label: "chdemurrage", in: false, out: true },
  { title: "Detention", label: "chdetention", in: false, out: true },
  { title: "Destination Storage", label: "chdeststore", in: false, out: true },
  {
    title: "Assembling and Unloading",
    label: "chassembly",
    in: false,
    out: true,
  },
  { title: "Container Return", label: "chcontreturn", in: false, out: true },
  { title: "Other", label: "chothersec4", in: false, out: true },
];
export default imchargelist4;
