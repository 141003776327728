import { observer } from "mobx-react";
import React, { Component } from "react";

import { Descriptions } from "antd";
import outboundlist from "../../../../../Static/Lists/imlists/outboundlist";
import inboundlist from "../../../../../Static/Lists/imlists/inboundlist";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class CostSummaryTable extends Component {
  render() {
    let aim = this.props.aim;

    let greycolor = "#cccccc";
    let lightcolor = "#ebebeb";

    let addchgs = aim.addcharge;
    let discount = aim.discount;

    let allcharges = [];
    let list = outboundlist;
    if (aim.direction === "Inbound") list = inboundlist;

    for (let i = 0; i < list.length; i++) {
      allcharges = [
        ...allcharges,
        { title: list[i].title, value: aim[list[i].label] },
      ];
    }

    let finalchgs = allcharges.filter((ch) => parseFloat(ch.value) !== 0);

    let chgmap;

    if (finalchgs.length > 0) {
      chgmap = finalchgs.map((chg, index) => (
        <Descriptions.Item
          label={`${PRKD(chg.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title}
        </Descriptions.Item>
      ));
    }
    let addmap;
    let discmap;
    if (addchgs.length > 0) {
      addmap = addchgs.map((add, index) => (
        <Descriptions.Item
          label={`${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {add.name}
        </Descriptions.Item>
      ));
    }
    if (discount.length > 0) {
      discmap = discount.map((add, index) => (
        <Descriptions.Item
          label={`-${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          Discount: {add.name}
        </Descriptions.Item>
      ));
    }

    return (
      <div>
        <Descriptions title="Cost Breakdown" bordered size="small">
          <Descriptions.Item
            label={`${PRKD(aim.charges.finalcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
          {aim.charges.totalchg !== 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(aim.charges.totalchg)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Main Service
              </Descriptions.Item>
              {chgmap}
            </>
          )}
          {aim.charges.addchg > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(aim.charges.addchg)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Additional Charges
              </Descriptions.Item>
              {addmap}
            </>
          )}
          {aim.charges.dischg > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(aim.charges.dischg)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Discounts
              </Descriptions.Item>
              {discmap}
            </>
          )}
          <Descriptions.Item
            label={`${PRKD(aim.charges.finalcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
        </Descriptions>
        <hr />
      </div>
    );
  }
}

export default observer(CostSummaryTable);
