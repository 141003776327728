import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Divider,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import { message } from "antd";

import DataGrid, { Column } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import ItemsListModal from "./ItemsListModal";

const instance = axios.create({ baseURL: baseURL });

class ItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      changed: false,
      to: { order: "", items: [] },
    };
    this.addItem = this.addItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.onHide = this.onHide.bind(this);
    this.updateItemsList = this.updateItemsList.bind(this);
  }
  updateItemsList = async () => {
    this.setState({ loading: true });

    let data = this.state.to;

    await instance
      .put(`ac/location/editlocation/${data._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.getSingleACOrder(false, true);
        this.onHide();
        message.success(`Items List Updated`);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        Notification["error"]({ title: `An error occured` });
      });
  };

  editItem(item, itemindex) {
    let newto = this.state.to;
    newto.items[itemindex] = { ...item };
    this.setState({ to: newto, changed: true });
  }
  addItem(item) {
    let newto = this.state.to;
    newto.items = [...newto.items, { ...item }];
    this.setState({ to: newto, changed: true });
  }
  removeItem(itemindex) {
    let newitems = [];
    for (let i = 0; i < this.state.to.items.length; i++) {
      if (i !== itemindex) {
        newitems = [...newitems, { ...this.state.to.items[i] }];
      } else {
        console.log("Catch!");
      }
    }
    let newto = this.state.to;
    newto.items = [...newitems];
    this.setState({ to: newto, changed: true });
  }
  onHide() {
    this.setState({ show: false, to: { items: [] } });
  }

  Dimension(cellData) {
    return `${PRKD(cellData.data.length / 100)} m x ${PRKD(
      cellData.data.width / 100
    )} m x ${PRKD(cellData.data.height / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.quantity) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  render() {
    let to = this.props.to;

    let itms = this.props.to.items;
    let tcbm = 0;
    let titems = itms.length;
    let tpackages = 0;
    for (let i = 0; i < itms.length; i++) {
      tpackages += Parsed(itms[i].quantity);
      tcbm +=
        (Parsed(itms[i].length) *
          Parsed(itms[i].width) *
          Parsed(itms[i].height) *
          Parsed(itms[i].quantity)) /
        1000000;
    }

    return (
      <div>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`Edit Items List for Dropoff #${to.order}`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  appearance="ghost"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel Changes
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="green"
                  disabled={!this.state.changed}
                  icon={<Icon icon="close" />}
                  onClick={() => this.updateItemsList()}
                >
                  Save Changes
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <ItemsListModal
            to={this.state.to}
            editItem={this.editItem}
            addItem={this.addItem}
            removeItem={this.removeItem}
            onHide={this.onHide}
          />
        </UnifiedModal>

        <Div14>
          Drop Off #{to.order} {to.city}:
          {!newDeliveryStore.stopedit && (
            <>
              <Divider vertical />
              <IconButton
                icon={<Icon icon="pencil" />}
                circle
                size="xs"
                color="yellow"
                onClick={() =>
                  this.setState({ to: { ...to }, show: true, changed: false })
                }
              />
            </>
          )}
        </Div14>

        <DataGrid
          dataSource={to.items}
          keyExpr="name"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          hoverStateEnabled={true}
          noDataText="No Items Added"
        >
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />

          <Scrolling mode="standard" showScrollbar="always" />

          <ColumnFixing enabled={true} />
          <Column caption="Item Details" alignment="center">
            <Column
              dataField="name"
              caption="Item"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="serialnumber"
              caption="Ser. No."
              alignment="center"
              minWidth={80}
            />
          </Column>
          <Column
            dataField="dimensions"
            caption="Dimensions"
            alignment="center"
            minWidth={140}
            cellRender={this.Dimension}
          />
          <Column
            dataField="quantity"
            dataType="number"
            caption="Quantity"
            alignment="center"
            format={{ type: "fixedPoint", precision: 0 }}
            minWidth={80}
          />
          <Column caption="CBM Calcs" alignment="center">
            <Column
              dataField="cbmperitem"
              dataType="number"
              caption="CBM Per Item"
              alignment="center"
              minWidth={80}
              cellRender={this.CBMPerItem}
            />
            <Column
              dataField="totalcbm"
              dataType="number"
              caption="Total CBM"
              alignment="center"
              minWidth={80}
              cellRender={this.TotalCBM}
            />
          </Column>
        </DataGrid>
        <HelpBlock
          style={{
            fontStyle: "italic",
            textAlign: "right",
            fontSize: 12,
            paddingRight: "5%",
          }}
        >
          {PRKD(tcbm)} CBM | {PRKD(titems)} Items | {PRKD(tpackages)} Pacakges
        </HelpBlock>
      </div>
    );
  }
}

export default observer(ItemsList);
