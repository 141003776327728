import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import mgtOverviewStore from "../../../../Stores/Management/MgtOverviewStore";
import { Icon, IconButton, Loader } from "rsuite";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import HeardOfUsGraph from "../Graph/HeardOfUsGraph";
import { Tabs } from "antd";
import Div14 from "../../../../Components/Dividers/Div14";

const { TabPane } = Tabs;

class IncomeData extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "By Sub Type" };
  }
  componentDidMount() {
    mgtOverviewStore.getIncome();
  }

  render() {
    if (mgtOverviewStore.loading.income) {
      return <Loader />;
    }
    return (
      <LoadBack loading={mgtOverviewStore.loading.income}>
        <Div14>
          Financial Details - Income{" "}
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() => mgtOverviewStore.getIncome(true)}
            loading={mgtOverviewStore.loading.income}
            size="xs"
          />
        </Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"By Sub Type"} key={"By Sub Type"}>
            <HeardOfUsGraph
              data={mgtOverviewStore.income.subtype}
              xaxis={mgtOverviewStore.income.cats}
              label="Income (KD)"
              title="Monthly Income - By Sub Type"
            />
          </TabPane>
          <TabPane tab={"By Specific Reason"} key={"By Specific Reason"}>
            <HeardOfUsGraph
              data={mgtOverviewStore.income.specific}
              xaxis={mgtOverviewStore.income.cats}
              label="Income (KD)"
              title="Monthly Income - By Specific Reason"
            />
          </TabPane>
          <TabPane tab={"By Department"} key={"By Department"}>
            <HeardOfUsGraph
              data={mgtOverviewStore.income.accounttype}
              xaxis={mgtOverviewStore.income.cats}
              label="Income (KD)"
              title="Monthly Income - By Department"
            />
          </TabPane>
        </Tabs>
      </LoadBack>
    );
  }
}

export default withRouter(observer(IncomeData));
