import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import allPaymentsStore from "../../../Stores/Financial/AllPaymentsStore";
import { Divider, InputGroup } from "rsuite";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

import { Tooltip } from "@material-ui/core";
import moment from "moment";

import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";
import Div14 from "../../../Components/Dividers/Div14";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import Col2 from "../../../Components/Columns/Col2";
import SelectInputSTD from "../../../Components/Inputs/STD/SelectInputSTD";
import NumbInputSTD from "../../../Components/Inputs/STD/NumbInputSTD";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import DatePickerSTD from "../../../Components/Inputs/STD/DatePickerSTD";
import TextAreaInputSTD from "../../../Components/Inputs/STD/TextAreaInputSTD";
import banklist from "../../../Static/Lists/financial/banklist";

import paymethodlist from "../../../Static/Lists/financial/paymethodlist";
import { Parsed } from "../../../Functions/Pricing/PRKDCalc";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import { message, Statistic } from "antd";
import { DatePicker } from "antd";
import PaymentTable from "./PaymentTable";
import AddCustomPayment from "./AddCustomPayment";

import Col4 from "../../../Components/Columns/Col4";
import { Tabs } from "antd";
import PaymentStats from "./PaymentStats";
import Col3 from "../../../Components/Columns/Col3";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const instance = axios.create({ baseURL: baseURL });

class AllPaymentsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showdelete: false,
      loading: false,
      edit: false,
      tab: "All Payments",
    };
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.showNew = this.showNew.bind(this);
    this.postNewPayment = this.postNewPayment.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
    this.selectPay = this.selectPay.bind(this);
    this.selectDelete = this.selectDelete.bind(this);

    this.editButton = this.editButton.bind(this);
  }

  selectPay(data) {
    allPaymentsStore.selectPayment(data);

    this.setState({ show: true, edit: true });
  }

  selectDelete(data) {
    allPaymentsStore.selectPayment(data);
    this.setState({ showdelete: true, show: false });
  }

  editButton(cellData) {
    return (
      <>
        {userStore.user.admin && (
          <Tooltip title={`Delete Payment`} arrow>
            <IconButton
              icon={<Icon icon="trash" />}
              color="red"
              circle
              size="xs"
              onClick={() => this.selectDelete(cellData.data)}
            />
          </Tooltip>
        )}
        <span> </span>
        <Tooltip title={`Edit Payment`} arrow>
          <IconButton
            icon={<Icon icon="pencil" />}
            color="red"
            circle
            size="xs"
            onClick={() => this.selectPay(cellData.data)}
          />
        </Tooltip>
      </>
    );
  }
  postDelete = async () => {
    let data = { ...allPaymentsStore.newpayment };
    let url = `/jf/payment/deletebyid/${data._id}`;
    this.setState({ loading: true });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.onHide();
        this.setState({ loading: false });
        allPaymentsStore.getAllPayments(true);
        message.success("Payment Deleted");
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  updatePayment = async () => {
    let data = { ...allPaymentsStore.newpayment };

    let url = `/jf/payment/edit/${data._id}`;

    this.setState({ loading: true });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });

        allPaymentsStore.getAllPayments(true);
        this.onHide();
        message.success("Payment Updated");
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  postNewPayment = async () => {
    let data = { ...allPaymentsStore.newpayment };

    let url = `/jf/payment/new`;

    this.setState({ loading: true });

    await instance
      .post(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });

        allPaymentsStore.addNewPayment(res.data);
        this.onHide();
        message.success("New Payment Created");
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  showNew() {
    this.setState({ show: true });
  }
  onHide() {
    allPaymentsStore.resetNewPayment();
    this.setState({ show: false, edit: false, showdelete: false });
  }
  editVal(val, label) {
    allPaymentsStore.editNewPayment(val, label);
  }
  componentDidMount() {
    if (userStore.user.mgt) {
      allPaymentsStore.getAllPayments(false, true);
    }
    allPaymentsStore.getAllPayments();
  }

  StatusCell(cellData) {
    let type = cellData.data.type;
    let color = "red";
    if (type === "Income") {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{type}</div>;
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `EasyMovePayments_as_of_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  render() {
    let disabled = false;
    let newpay = allPaymentsStore.newpayment;
    let showall = userStore.user.mgt;
    if (newpay.type === "") disabled = true;
    if (newpay.subtype === "") disabled = true;
    if (newpay.specific === "") disabled = true;
    if (newpay.method === "") disabled = true;
    if (newpay.cash === "") disabled = true;
    if (Parsed(newpay.amount) <= 0) disabled = true;

    let data = allPaymentsStore.basicpaymentstats;
    data.ratio = Math.round((100 * Parsed(data.expense)) / Parsed(data.income));

    let selectedborder = "2px solid black";

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <UnifiedModal
          title={
            this.state.edit
              ? `Edit Payment #${allPaymentsStore.newpayment.paymentNumId}`
              : "Create a New Payment"
          }
          show={this.state.show & !this.state.showdelete}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={[
                "Cancel",
                this.state.edit ? "Update Payment" : "Create New Payment",
              ]}
              oC1={this.onHide}
              oC2={() => {
                this.state.edit ? this.updatePayment() : this.postNewPayment();
              }}
              disabled2={disabled}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <FlexboxGrid>
            <Col3>
              {newpay.jobnumid && (
                <>
                  <b>Job ID: </b> {newpay.jobnumid}{" "}
                  <IconButton
                    icon={<Icon icon="send" />}
                    circle
                    size={"xs"}
                    color="green"
                    onClick={() =>
                      this.props.history.push(`/singlejob/${newpay.jobid._id}`)
                    }
                  />
                </>
              )}
              <hr />
            </Col3>
            <Col3>
              {newpay.clientshort && (
                <>
                  <b>Client: </b> {newpay.clientshort}{" "}
                </>
              )}
              <hr />
            </Col3>
            <Col3>
              {newpay.invoicenumber && (
                <>
                  <b>Invoice: </b> {newpay.invoicenumber}
                </>
              )}
              <hr />
            </Col3>

            <Col2>
              <SelectInputSTD
                title="Type"
                editVal={this.editVal}
                val={newpay.type}
                label="type"
                size="large"
                opts={["Income", "Expense Variable", "Expense Fixed"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Sub Type"
                editVal={this.editVal}
                val={newpay.subtype}
                label="subtype"
                size="large"
                opts={allPaymentsStore.paysubtypes}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Specific"
                editVal={this.editVal}
                val={newpay.specific}
                label="specific"
                size="large"
                opts={allPaymentsStore.payspecific}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Department"
                editVal={this.editVal}
                val={newpay.accounttype}
                label="accounttype"
                size="large"
                opts={[
                  "General",
                  "Local Move",
                  "International Move",
                  "Custom Clearance",
                  "Delivery Account",
                  "Storage Account",
                ]}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Amount"
                editVal={this.editVal}
                val={newpay.amount}
                label="amount"
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Cash / Non-Cash"
                editVal={this.editVal}
                val={newpay.cash}
                label="cash"
                size="large"
                opts={["Cash", "Non-Cash"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Method"
                editVal={this.editVal}
                val={newpay.method}
                label="method"
                size="large"
                opts={paymethodlist}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Account"
                editVal={this.editVal}
                val={newpay.account}
                label="account"
                size="large"
                opts={banklist}
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Payment Ref."
                editVal={this.editVal}
                val={newpay.paymentref}
                label="paymentref"
              />
              <br />
            </Col2>
            <Col2>
              <DatePickerSTD
                title="Date"
                editVal={this.editVal}
                val={newpay.date}
                label="date"
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <TextInputSTD
            title="Party Name"
            editVal={this.editVal}
            val={newpay.partyname}
            label="partyname"
          />

          <br />
          <TextAreaInputSTD
            title="Payment Remarks"
            editVal={this.editVal}
            val={newpay.remarks}
            label="remarks"
          />
          <br />
        </UnifiedModal>
        <UnifiedModal
          title={"Delete Payment"}
          show={this.state.showdelete}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={this.onHide}
              oC2={() => this.postDelete()}
              disabled2={disabled}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <FlexboxGrid>
            <Col3>
              {newpay.jobnumid && (
                <>
                  <b>Job ID: </b> {newpay.jobnumid}{" "}
                  <IconButton
                    icon={<Icon icon="send" />}
                    circle
                    size={"xs"}
                    color="green"
                    onClick={() =>
                      this.props.history.push(`/singlejob/${newpay.jobid._id}`)
                    }
                  />
                </>
              )}
              <hr />
            </Col3>
            <Col3>
              {newpay.clientshort && (
                <>
                  <b>Client: </b> {newpay.clientshort}{" "}
                </>
              )}
              <hr />
            </Col3>
            <Col3>
              {newpay.invoicenumber && (
                <>
                  <b>Invoice: </b> {newpay.invoicenumber}
                </>
              )}
              <hr />
            </Col3>

            <Col2>
              <b>Type:</b> {newpay.type}
            </Col2>
            <Col2>
              <b>SubType:</b> {newpay.subtype}
            </Col2>
            <Col2>
              <b>Specific:</b> {newpay.specific}
            </Col2>
            <Col2>
              <b>Department:</b> {newpay.accounttype}
            </Col2>
            <Col2>
              <b>Amount:</b> {newpay.amount}
            </Col2>
            <Col2>
              <b>Cash:</b> {newpay.cash}
            </Col2>
            <Col2>
              <b>Method:</b> {newpay.method}
            </Col2>
            <Col2>
              <b>Account:</b> {newpay.account}
            </Col2>
            <Col2>
              <b>Payment Ref.:</b> {newpay.paymentref}
            </Col2>
            <Col2>
              <b>Date:</b> {newpay.date}
            </Col2>
            <Col2>
              <b>Party:</b> {newpay.partyname}
            </Col2>
            <Col2>
              <b>Remarks:</b> {newpay.remarks}
            </Col2>
          </FlexboxGrid>
        </UnifiedModal>

        <Div14>
          All Payments <Divider vertical />{" "}
          <IconButton
            icon={<Icon icon="plus" />}
            size="xs"
            onClick={() => {
              this.setState({ show: true });
              allPaymentsStore.resetNewPayment();
            }}
            circle
            color="green"
          />
        </Div14>
        <AddCustomPayment showNew={this.showNew} />
        <br />
        <LoadBack loading={allPaymentsStore.loading.payment}>
          <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
            <InputGroup>
              <InputGroup.Addon>Select Range</InputGroup.Addon>
              <RangePicker
                size="large"
                picker="month"
                format={"MMM-YY"}
                style={{ width: "100%" }}
                onChange={(e) => allPaymentsStore.changeDate(e)}
                value={allPaymentsStore.daterange}
              />
              <InputGroup.Button
                color="green"
                loading={allPaymentsStore.loading.payment}
                onClick={() => allPaymentsStore.getAllPayments(true, showall)}
              >
                <Icon icon="refresh" />
              </InputGroup.Button>
            </InputGroup>
          </div>
          <hr />
          <Div14>All Payments</Div14>
          <FlexboxGrid justify="space-around">
            <Col4>
              <div
                onClick={() => allPaymentsStore.changeFilter("Income")}
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                  border:
                    allPaymentsStore.filtertype === "All"
                      ? selectedborder
                      : allPaymentsStore.filtertype === "Income"
                      ? selectedborder
                      : "",
                }}
              >
                <Statistic
                  title="Income"
                  value={data.income}
                  precision={3}
                  valueStyle={{ color: "#3f8600" }}
                  suffix="KD"
                />
              </div>
            </Col4>
            <Col4>
              <div
                onClick={() =>
                  allPaymentsStore.changeFilter("Expense Variable")
                }
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                  border:
                    allPaymentsStore.filtertype === "All"
                      ? selectedborder
                      : allPaymentsStore.filtertype === "Expense Variable"
                      ? selectedborder
                      : "",
                }}
              >
                <Statistic
                  title="Expense Variable"
                  value={data.expensevariable}
                  precision={0}
                  valueStyle={{ color: "red" }}
                  suffix="KD"
                />
              </div>
            </Col4>
            <Col4>
              <div
                onClick={() => allPaymentsStore.changeFilter("Expense Fixed")}
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                  border:
                    allPaymentsStore.filtertype === "All"
                      ? selectedborder
                      : allPaymentsStore.filtertype === "Expense Fixed"
                      ? selectedborder
                      : "",
                }}
              >
                <Statistic
                  title="Expense Fixed"
                  value={data.expensefixed}
                  precision={0}
                  valueStyle={{ color: "red" }}
                  suffix="KD"
                />
              </div>
            </Col4>
            <Col4>
              <div
                onClick={() => allPaymentsStore.changeFilter("All")}
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "2%",
                }}
              >
                <Statistic
                  title="Income Ratio"
                  value={data.expenseratio}
                  precision={0}
                  suffix="%"
                  valueStyle={{
                    color: data.diff > 0 ? "#3f8600" : "red",
                  }}
                />
              </div>
            </Col4>
          </FlexboxGrid>

          <br />
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="line"
            centered
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"All Payments"} key={"All Payments"}>
              <PaymentTable
                payments={allPaymentsStore.filteredpayments}
                editButton={this.editButton}
                selectPay={this.selectPay}
                selectDelete={this.selectDelete}
              />
            </TabPane>
            <TabPane tab={"Statistics"} key={"Statistics"}>
              <PaymentStats />
            </TabPane>
          </Tabs>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(AllPaymentsMain));
