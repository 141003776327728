import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid } from "rsuite";
import { Statistic } from "antd";
import staffStorageStatStore from "../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import Col4 from "../../../../../../../../Components/Columns/Col4";
import { Parsed } from "../../../../../../../../Functions/Pricing/PRKDCalc";
import StorStatBarChart from "./StorStatBarChart";

class DeliveryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let output = staffStorageStatStore.stats;
    return (
      <div>
        <Div14>Delivery Details</Div14>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Storage In Jobs"
                value={Parsed(output.stoin)}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Delivery In Jobs"
                value={Parsed(output.delin)}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Storage Out Jobs"
                value={Parsed(output.stoout)}
                valueStyle={{ color: "red" }}
              />
            </div>
          </Col4>

          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Delivery Out Jobs"
                value={Parsed(output.delout)}
                valueStyle={{ color: "red" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <Div14>Item Movement Details</Div14>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Quantity In"
                value={Parsed(output.qtyin)}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="CBM In"
                precision={3}
                value={Parsed(output.cbmin)}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Quantity Out"
                value={Parsed(output.qtyout)}
                valueStyle={{ color: "red" }}
              />
            </div>
          </Col4>

          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="CBM Out"
                precision={3}
                value={Parsed(output.cbmout)}
                valueStyle={{ color: "red" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <StorStatBarChart data={staffStorageStatStore.stats.barchart} />
      </div>
    );
  }
}

export default withRouter(observer(DeliveryDetails));
