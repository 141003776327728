import { observer } from "mobx-react";
import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { TRC } from "../../../../../Functions/Timing/TimingRulesCalc";
import Col2 from "../../../../../Components/Columns/Col2";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

class DeliveryTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = { afterHours: false, express: false };
    this.disabledDate = this.disabledDate.bind(this);
    this.disabledTime = this.disabledTime.bind(this);
  }

  disabledDate(current) {
    let delCont = newDeliveryStore.delCont;
    let hrs = delCont.excludedhours;

    let exdays = [];
    let afdays = [];
    let range = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    for (let i = 0; i < range.length; i++) {
      if (hrs[range[i]] === "No") exdays = [...exdays, i];
      if (hrs[range[i]] === "After") afdays = [...afdays, i];
    }

    let excluded = false;

    for (let i = 0; i < exdays.length; i++) {
      if (moment(current).day() === exdays[i]) excluded = true;
    }
    if (!this.state.afterHours) {
      for (let i = 0; i < afdays.length; i++) {
        if (moment(current).day() === afdays[i]) excluded = true;
      }
    }

    let earliest = TRC(delCont, this.state.express, this.state.afterHours);

    return current < earliest || excluded;
  }
  disabledTime(current) {
    let min1 = range(1, 15);
    let min2 = range(16, 30);
    let min3 = range(31, 45);
    let min4 = range(46, 60);
    let minutes = [...min1, ...min2, ...min3, ...min4];

    let delCont = newDeliveryStore.delCont;
    let hrs = delCont.excludedhours;
    let exhrs = [];
    let afhrs = [];
    for (let i = 0; i < 24; i++) {
      if (hrs[`${i}`] === "No") exhrs = [...exhrs, i];
      if (hrs[`${i}`] === "After") afhrs = [...afhrs, i];
    }
    if (!this.state.afterHours) {
      exhrs = [...exhrs, ...afhrs];
    }

    return {
      disabledHours: () => exhrs,
      disabledMinutes: () => minutes,
    };
  }

  render() {
    let delCont = newDeliveryStore.delCont;

    let express = TRC(delCont, true, false, moment().add(10, "minute"));
    let earliest = TRC(delCont, false, false, moment().add(10, "minute"));
    let expressaf = TRC(delCont, true, true, moment().add(10, "minute"));
    let earliestaf = TRC(delCont, false, true, moment().add(10, "minute"));

    let greenstyle = {
      border: "1px solid green",
      backgroundColor: "#d8ffcc",
      borderRadius: "5%",
    };

    let redstyle = {
      border: "1px solid red",
      backgroundColor: "#ffcccc",
      borderRadius: "5%",
    };

    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              block
              color="blue"
              onClick={() =>
                this.setState({ afterHours: !this.state.afterHours })
              }
              icon={
                <Icon icon={this.state.afterHours ? "clock-o" : "clock-o"} />
              }
              appearance={this.state.afterHours ? "default" : "ghost"}
            >
              {this.state.afterHours ? "Showing" : "Hiding"} After Hours Timing
              ({PRKD(delCont.afterhourfee)} KD)
            </IconButton>
          </Col2>

          <Col2>
            <IconButton
              block
              color="blue"
              onClick={() => this.setState({ express: !this.state.express })}
              icon={<Icon icon={this.state.express ? "truck" : "truck"} />}
              appearance={this.state.express ? "default" : "ghost"}
            >
              {this.state.express ? "Showing" : "Hiding"} Express Timing (
              {PRKD(delCont.expressfee)} KD)
            </IconButton>
          </Col2>
        </FlexboxGrid>
        <br />
        <DatePicker
          dateRender={(current) => {
            const style = {};
            let hrs = delCont.excludedhours;

            let afdays = [];
            let range = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
            for (let i = 0; i < range.length; i++) {
              if (hrs[range[i]] === "After") afdays = [...afdays, i];
            }
            if (!this.state.afterHours) {
              for (let i = 0; i < afdays.length; i++) {
                if (moment(current).day() === afdays[i]) {
                  style.border = "1px solid red";
                  style.borderRadius = "15%";
                  style.backgroundColor = "#ffcccc";
                }
              }
            }

            return (
              <div className="ant-picker-cell-inner" style={style}>
                {current.date()}
              </div>
            );
          }}
          style={{ width: "100%" }}
          showTime={{ format: "HH:mm" }}
          format="DD-MMM-YY HH:mm"
          minuteStep={15}
          showSecond={false}
          allowClear={false}
          size="large"
          picker="date"
          showNow={false}
          showToday={false}
          inputReadOnly
          value={newDeliveryStore.newdel.jobdate}
          disabledDate={this.disabledDate}
          disabledTime={this.disabledTime}
          onChange={(date) => newDeliveryStore.editOrder(date, "jobdate")}
          onOk={(date) => newDeliveryStore.editOrder(date, "jobdate")}
          renderExtraFooter={() => (
            <div>
              <h6 style={greenstyle}>
                <b>Earliest Available Date: </b>
                {moment(earliest).format("DD-MMM-YY")} at{" "}
                {moment(earliest).format("HH:mm A")}
              </h6>
              {moment(express) < moment(earliest) && (
                <h6 style={this.state.express ? greenstyle : redstyle}>
                  <b>Express Date: </b>
                  {moment(express).format("DD-MMM-YY")} at{" "}
                  {moment(express).format("HH:mm A")} ( +
                  {PRKD(parseFloat(delCont.expressfee))} KD)
                </h6>
              )}
              {moment(earliestaf) < moment(earliest) && (
                <h6 style={this.state.afterHours ? greenstyle : redstyle}>
                  <b>After Hour Date: </b>
                  {moment(earliestaf).format("DD-MMM-YY")} at{" "}
                  {moment(earliestaf).format("HH:mm A")} ( +
                  {PRKD(delCont.afterhourfee)} KD)
                </h6>
              )}
              {moment(expressaf) < moment(earliestaf) && (
                <h6
                  style={
                    this.state.afterHours & this.state.express
                      ? greenstyle
                      : redstyle
                  }
                >
                  <b>Express After Hour Date: </b>
                  {moment(expressaf).format("DD-MMM-YY")} at{" "}
                  {moment(expressaf).format("HH:mm A")} ( +
                  {PRKD(
                    parseFloat(delCont.afterhourfee) +
                      parseFloat(delCont.expressfee)
                  )}{" "}
                  KD)
                </h6>
              )}
            </div>
          )}
        />
      </div>
    );
  }
}

export default observer(DeliveryTimePicker);
