import { observer } from "mobx-react";
import React, { Component } from "react";
import editAccountStore from "../../../../../Stores/Account/EditAccountStore";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import Col4 from "../../../../../Components/Columns/Col4";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";

class SelectedOptions extends Component {
  constructor(props) {
    super(props);
    this.editVal = this.editVal.bind(this);
  }

  editVal = async (val, label) => {
    let index = this.props.index;

    editAccountStore.editDeliveryOption(val, label, index);
  };

  render() {
    let opt = this.props.opt;
    let title = opt.title;
    let price = opt.price;
    let type = opt.type;
    let desc = opt.desc;
    let inout = opt.inout;
    return (
      <div>
        <FlexboxGrid justify="space-between">
          <Col4>
            <TextInputSTD
              title="Title"
              val={title}
              label="title"
              editVal={this.editVal}
              size="sm"
            />
          </Col4>
          <Col4>
            <NumbInputSTD
              title="Price"
              editVal={this.editVal}
              val={price}
              label="price"
              min={0}
              size="sm"
            />
          </Col4>
          <Col4>
            <SelectInputSTD
              title="Type"
              val={type}
              label="type"
              opts={["Per CBM", "Per Shipment"]}
              editVal={this.editVal}
              min={0}
            />
          </Col4>
          <Col4>
            <SelectInputSTD
              title="In/Out"
              val={inout}
              label="inout"
              opts={["In", "Out"]}
              editVal={this.editVal}
              min={0}
            />
          </Col4>
          <Col md={20} sm={20} xs={20}>
            <TextInputSTD
              title="Details"
              val={desc}
              label="desc"
              size="sm"
              editVal={this.editVal}
            />
          </Col>
          <Col md={4} sm={4} xs={4}>
            <IconButton
              icon={<Icon icon="trash" />}
              color="red"
              circle
              onClick={() =>
                editAccountStore.removeDeliveryOption(this.props.index)
              }
            />
          </Col>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default observer(SelectedOptions);
