import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class FeedbackStore {
  constructor() {
    this.allfeedback = [];
    this.delsinglefeedback = {
      _id: "",
      remarks: "",
      type: "",
      rating: 0,
    };
    this.jobsinglefeedback = {
      _id: "",
      remarks: "",
      type: "",
      rating: 0,
    };
    this.loading = false;
    this.dataloaded = false;

    makeObservable(this, {
      allfeedback: observable,
      delsinglefeedback: observable,
      jobsinglefeedback: observable,
      loading: observable,
      dataloaded: observable,
    });
  }

  // FETCH Delivery Feedback
  getDelSingleFeedback(id, override) {
    let loaded = false;
    if (this.delsinglefeedback._id === id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.delsinglefeedback._id = id;
      this.loading = true;
      return instance
        .get(`/track/feedback/feedbackbydelid/${id}`)
        .then((res) => res.data)
        .then((feedback) => {
          this.delsinglefeedback = feedback;
          this.loading = false;
          console.log("Delivery Feedback Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Feedback.`,
          });
        });
    }
  }

  // FETCH Job Feedback
  getJobSingleFeedback(id, override) {
    let loaded = false;
    if (this.jobsinglefeedback._id === id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.jobsinglefeedback._id = id;
      this.loading = true;
      return instance
        .get(`/track/feedback/feedbackbyjobid/${id}`)
        .then((res) => res.data)
        .then((feedback) => {
          this.jobsinglefeedback = feedback;
          this.loading = false;
          console.log("Job Feedback Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Feedback.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  // ----- DATE ----
}

const feedbackStore = new FeedbackStore();
export default feedbackStore;
