import { observer } from "mobx-react";
import React, { Component } from "react";

import { Descriptions } from "antd";

import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

import ToAddressTable from "./ToAddressTable";

class OrderSummary extends Component {
  render() {
    let del = newDeliveryStore.newdel;

    let account = newDeliveryStore.account;
    let timechg = newDeliveryStore.timechgs;
    let from = del.fromaddress;
    let tos = del.toaddress;

    let greycolor = "#cccccc";
    let lightcolor = "#ebebeb";
    let options = del.orderoptions;
    let addchgs = del.addcharge;
    let allcharges = timechg.allcharges;
    let loccharges = timechg.loccharges;
    let chgmap;
    let locmap;
    let optmap;
    let addmap;

    let titems = 0;
    let tcbm = 0;
    let tpackages = 0;
    for (let i = 0; i < tos.length; i++) {
      if (tos[i].items) {
        titems += tos[i].items.length;
        for (let k = 0; k < tos[i].items.length; k++) {
          tpackages += Parsed(tos[i].items[k].quantity);
          tcbm +=
            (Parsed(tos[i].items[k].length) *
              Parsed(tos[i].items[k].width) *
              Parsed(tos[i].items[k].height) *
              Parsed(tos[i].items[k].quantity)) /
            1000000;
        }
      }
    }

    if (allcharges.length > 0) {
      chgmap = allcharges.map((chg, index) => (
        <Descriptions.Item
          label={`${PRKD(chg.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title}
        </Descriptions.Item>
      ));
    }
    if (loccharges.length > 0) {
      locmap = loccharges.map((chg, index) => (
        <Descriptions.Item
          label={`${PRKD(chg.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title}
        </Descriptions.Item>
      ));
    }

    if (options.length > 0) {
      optmap = options.map((opt, index) => (
        <Descriptions.Item
          label={`${PRKD(opt.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {opt.title}
        </Descriptions.Item>
      ));
    }
    if (addchgs.length > 0) {
      addmap = addchgs.map((add, index) => (
        <Descriptions.Item
          label={`${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {add.name}
        </Descriptions.Item>
      ));
    }
    let allservices = [];
    let uniqueAdds;

    if (newDeliveryStore.delCont.maintype === "Fixed Per Type") {
      for (let i = 0; i < del.toaddress.length; i++) {
        allservices = [
          ...allservices,
          {
            type: `Del ${i + 1}: ${del.toaddress[i].service.type}`,
            price: del.toaddress[i].service.price,
          },
        ];
      }
    }

    if (del.maintype === "Fixed Per Type") {
      uniqueAdds = allservices.map((ser, index) => (
        <Descriptions.Item label={`Selected Type:`}>
          {ser.type} ({PRKD(ser.price)} KD)
        </Descriptions.Item>
      ));
    } else {
      uniqueAdds = (
        <>
          <Descriptions.Item label={`CBM:`}>{PRKD(tcbm)} CBM</Descriptions.Item>
          <Descriptions.Item label={`Items:`}>
            {titems} Item{titems === 1 ? "" : "s"}
          </Descriptions.Item>
          <Descriptions.Item label={`Packages:`}>
            {PRKD(tpackages)} Pacakge{tpackages === 1 ? "" : "s"}
          </Descriptions.Item>
        </>
      );
    }

    return (
      <div>
        <Descriptions title="Order Summary" bordered size="small">
          <Descriptions.Item label="Type">{del.maintype}</Descriptions.Item>
          <Descriptions.Item label="Status">{del.status}</Descriptions.Item>
          <Descriptions.Item label="Client">
            {account.clientNumId} - {account.name}
          </Descriptions.Item>
          {uniqueAdds}
          <Descriptions.Item
            label="Total Job Cost"
            span={24}
            labelStyle={{ fontWeight: "bold" }}
            contentStyle={{ fontWeight: "bold" }}
          >
            {PRKD(timechg.finaljobcost)} KD
          </Descriptions.Item>
        </Descriptions>
        <hr />
        {from.name !== "" && (
          <>
            <Descriptions title={"Pickup Address"} bordered size="small">
              <Descriptions.Item label="Address" span={24}>
                {from.address}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{from.name}</Descriptions.Item>
              <Descriptions.Item label="Phone">{from.phone}</Descriptions.Item>
              <Descriptions.Item label="Type">{from.type}</Descriptions.Item>
              <Descriptions.Item label="City">{from.city}</Descriptions.Item>
              <Descriptions.Item label="Floor">{from.floor}</Descriptions.Item>
              <Descriptions.Item label="Charges">
                {PRKD(Parsed(from.zonecost) + Parsed(from.ngfchg))} KD
              </Descriptions.Item>
            </Descriptions>
            <hr />
          </>
        )}
        {tos.length > 0 && (
          <>
            {tos.map((to, index) => (
              <ToAddressTable del={del} to={to} key={index} index={index} />
            ))}
          </>
        )}

        <Descriptions title="Cost Breakdown" bordered size="small">
          <Descriptions.Item
            label={`${PRKD(timechg.finaljobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
          <Descriptions.Item
            label={`${PRKD(timechg.mainjobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: lightcolor,
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: lightcolor }}
          >
            Main Service
          </Descriptions.Item>
          {chgmap}
          {timechg.totalloccharge > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totalloccharge)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Delivery Location Charges
              </Descriptions.Item>
              {locmap}
            </>
          )}
          {timechg.totalopts > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totalopts)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Selected Options
              </Descriptions.Item>
              {optmap}
            </>
          )}
          {timechg.totaladdchg > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totaladdchg)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Additional Charges
              </Descriptions.Item>
              {addmap}
            </>
          )}
          <Descriptions.Item
            label={`${PRKD(timechg.finaljobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
        </Descriptions>
        <hr />
      </div>
    );
  }
}

export default observer(OrderSummary);
