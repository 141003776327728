import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import SinglePalletModal from "../SinglePalletModal/SinglePalletModal";
import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";
import { Divider, Icon, IconButton } from "rsuite";
import TruckTripView from "../TruckTrip/TruckTripView/TruckTripView";
import whmTruckTripStore from "../WHMStores/WHMTruckTripStore";

class WHMPalletListTable extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, showtt: false };
    this.onHide = this.onHide.bind(this);
    this.onEdit = this.onEdit.bind(this);

    this.QTYColumn = this.QTYColumn.bind(this);
    this.CBMColumn = this.CBMColumn.bind(this);

    this.ShowPalletModal = this.ShowPalletModal.bind(this);
    this.TruckTripColumn = this.TruckTripColumn.bind(this);
  }
  ShowPalletModal(cellData) {
    let data = cellData.data;
    if (data._id) {
      return (
        <IconButton
          icon={<Icon icon="eye" />}
          circle
          color="green"
          size="xs"
          onClick={() => {
            whmSinglePalletStore.getPallet(data._id);
            this.setState({ show: true, pallet: data });
          }}
        />
      );
    }
    return <></>;
  }
  TruckTripColumn(cellData) {
    if (cellData.data.whmtrucktrip) {
      if (cellData.data.whmtrucktrip._id) {
        let tt = cellData.data.whmtrucktrip;
        return (
          <>
            # {tt.whmTruckTripNumId}
            <Divider vertical />
            <IconButton
              color="green"
              icon={<Icon icon="truck" />}
              size="xs"
              circle
              onClick={() => {
                whmTruckTripStore.getTruckTrip(cellData.data.whmtrucktrip._id);
                this.setState({ showtt: true });
              }}
            />
          </>
        );
      }
    }
  }
  QTYColumn(cellData) {
    let data = cellData.data.qty;
    let qty = 0;
    if (data > 0) return PRKD(qty);
    return "-";
  }
  CBMColumn(cellData) {
    let data = cellData.data.cbm;
    let cbm = 0;
    if (data > 0) return PRKD(cbm);
    return "-";
  }

  onHide() {
    this.setState({ show: false, showtt: false });
  }
  onEdit(pallet) {
    this.props.history.push(`/whm/company/${pallet.client._id}`);
    this.onHide();
  }

  render() {
    let data = this.props.data;

    return (
      <div>
        <TruckTripView show={this.state.showtt} onHide={this.onHide} />
        <SinglePalletModal
          show={this.state.show}
          onHide={this.onHide}
          actionbut={true}
          actionbuttext="Go To Company"
          onEdit={this.onEdit}
        />
        <DataGrid
          dataSource={data}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <Selection mode="single" />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column
            caption="View"
            alignment="center"
            width={40}
            cellRender={this.ShowPalletModal}
          />
          <Column
            dataField="whmPalletNumId"
            caption="ID"
            sortOrder={"desc"}
            alignment="center"
            width={80}
          />
          <Column
            dataField="clientname"
            caption="Client"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            width={80}
          />
          <Column
            dataField="items"
            caption="Items"
            alignment="center"
            width={80}
          />
          <Column dataField="qty" caption="QTY" alignment="center" width={80} />
          <Column
            dataField="cbm"
            caption="CBM"
            alignment="center"
            width={80}
            format={"0.000"}
          />

          {this.props.prepared && (
            <Column
              dataField="createdAt"
              caption="Prepared"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.loaded && (
            <Column
              dataField="loadeddate"
              caption="Loaded"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.loaded && (
            <Column
              dataField="whmtrucktrip.whmTruckTripNumId"
              caption="Truck Trip #"
              alignment="center"
              width={100}
              cellRender={this.TruckTripColumn}
            />
          )}
          {this.props.unloaded && (
            <Column
              dataField="unloadeddate"
              caption="UnLoaded"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          {this.props.stacked && (
            <Column
              dataField="stacked"
              caption="Stacked"
              alignment="center"
              width={100}
              dataType={"date"}
              format={"dd-MMM-yy HH:mm"}
            />
          )}
          <Export enabled={true} />
          <Summary>
            <TotalItem
              column="whmPalletNumId"
              summaryType="count"
              valueFormat={"#,##0"}
              displayFormat={"{0} Pallets"}
            />
            <TotalItem
              column="items"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0} Items"}
            />
            <TotalItem
              column="qty"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0} Qty"}
            />
            <TotalItem
              column="cbm"
              summaryType="sum"
              valueFormat={"#,##0.0"}
              displayFormat={"{0} CBM"}
            />
          </Summary>
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(WHMPalletListTable));
