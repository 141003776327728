import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Badge, Descriptions, message, Tag } from "antd";
import {
  Divider,
  FlexboxGrid,
  Icon,
  IconButton,
  HelpBlock,
  Notification,
} from "rsuite";
import axios from "axios";

import LocationModal from "./LocationModal";
import LocationOrder from "./LocationOrder";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import Col2 from "../../../../../../Components/Columns/Col2";
import NumbInputSTU from "../../../../../../Components/Inputs/Updatable/NumbInputSTU";

const instance = axios.create({ baseURL: baseURL });

class LocationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      showreorder: false,
      locs: [],
      toaddress: {
        address: "",
        type: "",
        name: "",
        phone: "",
        city: "",
        floor: 0,
        _id: "New",
      },
      org: {
        address: "",
        type: "",
        name: "",
        phone: "",
        city: "",
        floor: 0,
        _id: "New",
      },
    };
    this.showModal = this.showModal.bind(this);
    this.showReorder = this.showReorder.bind(this);
    this.moveNewTo = this.moveNewTo.bind(this);
    this.newModal = this.newModal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);

    this.updateMult = this.updateMult.bind(this);
  }

  updateMult = async (label, title) => {
    let reloadpage;

    let prices = { ...newDeliveryStore.newdel.prices };
    let data = {};
    data.prices = { ...prices };
    reloadpage = true;

    this.setState({ loading: true });
    await instance
      .put(`ac/delivery/edit/${newDeliveryStore.newdel._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.updatePrices(label);
        message.success(`Order Updated (${title})`);
        if (reloadpage) {
          newDeliveryStore.getSingleACOrder(false, true);
        }
        this.setState({ loading: false });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);

        Notification["error"]({ title: `An error occured` });
      });
  };

  showReorder(locs) {
    this.setState({ showreorder: true, locs: locs });
  }

  moveNewTo(index, type) {
    let oldlocs = [...this.state.locs];
    let removedArray = oldlocs.splice(index, 1)[0];
    let newindex = index;
    if (type === "up") newindex -= 1;
    if (type === "down") newindex += 1;
    oldlocs.splice(newindex, 0, removedArray);
    this.setState({ locs: [...oldlocs] });
  }

  showModal(toaddress) {
    this.setState({
      show: true,
      toaddress: {
        address: toaddress.address,
        type: toaddress.type,
        name: toaddress.name,
        phone: toaddress.phone,
        city: toaddress.city,
        floor: toaddress.floor,
        _id: toaddress._id,
      },
      org: {
        address: toaddress.address,
        type: toaddress.type,
        name: toaddress.name,
        phone: toaddress.phone,
        city: toaddress.city,
        floor: toaddress.floor,
        _id: toaddress._id,
      },
    });
  }
  newModal() {
    this.setState({
      show: true,
      toaddress: {
        address: "",
        type: "",
        name: "",
        phone: "",
        city: "",
        floor: 0,
        _id: "New",

        order: newDeliveryStore.newdel.toaddress.length + 1,
      },
      org: {
        address: "",
        type: "",
        name: "",
        phone: "",
        city: "",
        floor: 0,
        _id: "New",
      },
    });
  }
  onHide() {
    this.setState({
      show: false,
      showreorder: false,
      locs: [],
      toaddress: {
        address: "",
        type: "",
        name: "",
        phone: "",
        city: "",
        floor: 0,
        _id: "New",
      },
      org: {
        address: "",
        type: "",
        name: "",
        phone: "",
        city: "",
        floor: 0,
        _id: "New",
      },
    });
  }
  editVal(val, label) {
    let oldloc = { ...this.state.toaddress };
    oldloc[label] = val;
    this.setState({ toaddress: { ...oldloc } });
  }

  render() {
    let locs = newDeliveryStore.newdel.toaddress;

    let stopedit = newDeliveryStore.stopedit;

    let showtripmultiplier = false;
    if (userStore.user.staffaccount) {
      if (locs.length > 1) showtripmultiplier = true;
    }

    return (
      <div>
        <UnifiedModal
          title={"Re-Order Locations"}
          show={this.state.showreorder}
          onHide={this.onHide}
          footer={false}
        >
          <LocationOrder
            locs={this.state.locs}
            onHide={this.onHide}
            moveNewTo={this.moveNewTo}
          />
        </UnifiedModal>
        <UnifiedModal
          title={
            this.state.toaddress._id === "New"
              ? "Add New Location"
              : "Edit Location"
          }
          show={this.state.show}
          onHide={this.onHide}
          footer={false}
        >
          <LocationModal
            loc={this.state.toaddress}
            org={this.state.org}
            order={newDeliveryStore.newdel}
            editVal={this.editVal}
            onHide={this.onHide}
          />
        </UnifiedModal>
        {!stopedit && (
          <ModalFooter
            icon={["arrows", "plus"]}
            color={["blue", "green"]}
            app={["ghost", "ghost"]}
            text={["Re-Order", "New Location"]}
            oC1={() => this.showReorder([...locs])}
            oC2={() => this.newModal()}
            loading1={this.state.loading}
            loading2={this.state.loading}
          />
        )}
        {locs.map((loc, index) => (
          <div
            key={index}
            style={{
              borderRadius: "5px",
              margin: "1%",
              padding: "1%",
              boxShadow: "1px 1px 8px #a3a3a3",
            }}
          >
            <Div14>
              <Tag
                color={loc.status === "Completed" ? "green" : "orange"}
                key={1}
              >
                <Badge
                  status={loc.status === "Completed" ? "default" : "processing"}
                  style={{
                    color: loc.status === "Completed" ? "green" : "orange",
                  }}
                  color={loc.status === "Completed" ? "green" : "orange"}
                  text={loc.status}
                />
              </Tag>
              <Divider vertical />
              <b>Dropoff Location {loc.order}:</b>
              {!stopedit && (
                <>
                  <Divider vertical />
                  <IconButton
                    icon={<Icon icon="edit" />}
                    color="yellow"
                    onClick={() => this.showModal(loc)}
                    circle
                    size="xs"
                  />
                </>
              )}
            </Div14>
            <Descriptions
              bordered
              key={index}
              size="small"
              style={{ textAlign: "center" }}
              title={false}
            >
              <Descriptions.Item label="Status" span={1}>
                {loc.status}
              </Descriptions.Item>
              <Descriptions.Item label="Name" span={1}>
                {loc.name}
              </Descriptions.Item>
              <Descriptions.Item label="Phone" span={1}>
                {loc.phone}
              </Descriptions.Item>
              <Descriptions.Item label="City">{loc.city}</Descriptions.Item>
              <Descriptions.Item label="Type" span={1}>
                {loc.type}
              </Descriptions.Item>
              <Descriptions.Item label="Floor" span={1}>
                {loc.floor}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {loc.address}
              </Descriptions.Item>
              {loc.ngfchg > 0 && (
                <Descriptions.Item label="Non-Ground Floor Charge">
                  {PRKD(loc.ngfchg)} KD
                </Descriptions.Item>
              )}
              {loc.zonecost > 0 && (
                <Descriptions.Item label={`Zone ${loc.zonetype} Charge`}>
                  {PRKD(loc.zonecost)} KD
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
        ))}
        <hr />
        {showtripmultiplier && (
          <FlexboxGrid justify="center" align="middle">
            <Col2>
              <NumbInputSTU
                val={newDeliveryStore.newdel.prices.tripmultiplier}
                org={newDeliveryStore.olddel.prices.tripmultiplier}
                label="tripmultiplier"
                title="Trip Cost Multiplier"
                size="sm"
                editVal={newDeliveryStore.editPrices}
                updateVal={this.updateMult}
              />
            </Col2>
            <Col2>
              <HelpBlock style={{ textAlign: "center", color: "red" }}>
                <b>
                  Trip Multiplier as per Delivery Contract ={" "}
                  {newDeliveryStore.delCont.dropoffmult}
                </b>
              </HelpBlock>
              <HelpBlock style={{ textAlign: "center", color: "red" }}>
                <b>
                  {PRKD(
                    Parsed(newDeliveryStore.newdel.prices.tripmultiplier) *
                      Parsed(newDeliveryStore.newdel.prices.weekdayprice)
                  )}{" "}
                  KD additional per Trip{" "}
                </b>
                <i>
                  = Trip Multiplier (
                  {newDeliveryStore.newdel.prices.tripmultiplier}) x{" "}
                  {PRKD(newDeliveryStore.newdel.prices.weekdayprice)} KD
                </i>
              </HelpBlock>
            </Col2>
          </FlexboxGrid>
        )}
      </div>
    );
  }
}

export default withRouter(observer(LocationMain));
