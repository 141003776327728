import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Col, Button } from "rsuite";
import { DarkColorCalc } from "../../../Functions/Colors/DarkColorCalc";
import calendarStore from "../../../Stores/StaffStores/CalendarStore";

class ViewButtons extends Component {
  render() {
    let buttons = [
      { label: "lead", title: "Lead" },
      { label: "prop", title: "Proposal" },
      { label: "book", title: "Booked" },
      { label: "conv", title: "Converted" },
    ];

    if (calendarStore.cancloaded) {
      buttons = [...buttons, { label: "canc", title: "Cancelled" }];
    }

    let jobbuts = [
      { label: "lm", title: "LM" },
      { label: "im", title: "IM" },
      { label: "cc", title: "CC" },
      { label: "st", title: "ST" },
    ];

    let md = 6;
    if (calendarStore.cancloaded) md = 4;

    let buttonrow = buttons.map((but, index) => (
      <Col md={md} sm={6} xs={12} key={index}>
        <Button
          size="xs"
          block
          onClick={() => calendarStore.changeFilt(but.label)}
          style={{
            backgroundColor: calendarStore.filter[but.label]
              ? DarkColorCalc(but.title)
              : "white",
            color: calendarStore.filter[but.label]
              ? "white"
              : DarkColorCalc(but.title),
            fontWeight: "bold",
            border: `1px solid ${DarkColorCalc(but.title)}`,
            boxShadow: "0px 0px 5px black",
          }}
        >
          {but.title}
        </Button>
      </Col>
    ));
    let jobbutrows = jobbuts.map((but, index) => (
      <Col md={6} sm={6} xs={12} key={index}>
        <Button
          block
          size="xs"
          onClick={() => calendarStore.changeFilt(but.label)}
          style={{
            fontWeight: "bold",
            backgroundColor: calendarStore.filter[but.label]
              ? "black"
              : "white",
            color: calendarStore.filter[but.label] ? "white" : "grey",
            border: "1px solid black",
            boxShadow: "0px 0px 5px black",
          }}
        >
          {but.title}
        </Button>
      </Col>
    ));

    return (
      <div>
        {this.props.type === "all" && (
          <FlexboxGrid style={{ paddingBottom: "2px" }}>
            {jobbutrows}
          </FlexboxGrid>
        )}
        <FlexboxGrid>{buttonrow}</FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default observer(ViewButtons);
