import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Icon, IconButton } from "rsuite";

import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Item } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { Toolbar, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";

import { Tooltip } from "@material-ui/core";
import userStore from "../../../../../../../Stores/UserStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import modalItemStore from "../../../../../../../Stores/ModalStores/ModalItemStore";
import ItemModal from "../../../../../StoragePages/InventoryPage/Modals/ItemModal";
import EditItemModal from "../../../../../StoragePages/InventoryPage/Modals/EditItemModal";

import { withRouter } from "react-router";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import ItemCountHeader from "../../../../../../../Components/Stats/ItemCount/ItemCountHeader";

class ItemInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showedit: false,
      item: {},
    };
    this.onHide = this.onHide.bind(this);
    this.onExporting = this.onExporting.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }
  onSelect(data) {
    if (data) {
      if (data.selectedRowsData) {
        if (data.selectedRowsData[0]) {
          modalItemStore.getSingleItem(data.selectedRowsData[0]._id, true);
          this.setState({ show: true });
        }
      }
    }
  }
  componentDidMount() {
    singleAccountStore.getACItems(this.props.clientid);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Current_Inventory_List_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  onHide() {
    this.setState({ show: false, showedit: false });
  }

  ActionButs(cellData) {
    let useraccess = false;
    if (
      userStore.user.jobs ||
      userStore.user.warehouse ||
      userStore.user.staffaccount
    ) {
      useraccess = true;
    }
    return (
      <>
        <Tooltip title={`View Item`} arrow>
          <IconButton
            icon={<Icon icon="eye" />}
            color="green"
            circle
            size="xs"
            onClick={() => {
              modalItemStore.getSingleItem(cellData.data._id, true);
              this.setState({ show: true });
            }}
          />
        </Tooltip>
        <span> </span>
        {useraccess ? (
          <Tooltip title={`Print QR Code`} arrow>
            <IconButton
              icon={<Icon icon="qrcode" />}
              color="blue"
              circle
              size="xs"
              onClick={() => {
                this.props.history.push(`/qrprintitem/${cellData.acItemNumId}`);
              }}
            />
          </Tooltip>
        ) : (
          <div />
        )}
        <span> </span>
        {useraccess ? (
          <Tooltip title={`Edit Item`} arrow>
            <IconButton
              icon={<Icon icon="pencil" />}
              color="yellow"
              circle
              size="xs"
              onClick={() => {
                modalItemStore.getEditItem(cellData.data._id);
                this.setState({ showedit: true });
              }}
            />
          </Tooltip>
        ) : (
          <div />
        )}
      </>
    );
  }

  CurrentStockCount(cellData) {
    if (cellData.data.currentstock === 0) return "-";
    return <b style={{ color: "green" }}>{PRKD(cellData.data.currentstock)}</b>;
  }
  OnReserveCount(cellData) {
    if (cellData.data.onreserve === 0) return "-";
    return <b style={{ color: "red" }}>{PRKD(cellData.data.onreserve)}</b>;
  }

  render() {
    let data = singleAccountStore.inventory;

    let stockcalcs = singleAccountStore.stockcalcs;

    return (
      <div>
        <LoadBack loading={singleAccountStore.loading.inventory}>
          <ItemModal show={this.state.show} onHide={this.onHide} />
          <EditItemModal show={this.state.showedit} onHide={this.onHide} />
          <ItemCountHeader items={data} />
          <Div14>Current Inventory List</Div14>
          <div>
            <DataGrid
              dataSource={data}
              keyExpr="acItemNumId"
              allowColumnReordering={true}
              allowColumnResizing={true}
              showBorders={true}
              rowAlternationEnabled
              onExporting={this.onExporting}
              style={{ fontSize: 10 }}
              hoverStateEnabled={true}
              onSelectionChanged={this.onSelect}
              wordWrapEnabled={true}
            >
              <Selection mode="single" />

              <FilterRow visible={true} applyFilter />
              <GroupPanel visible={true} />
              <SearchPanel visible={true} />

              <Scrolling mode="standard" showScrollbar="always" />
              <ColumnChooser enabled={true} mode="select" />
              <ColumnFixing enabled={true} />
              <Column
                dataField="acItemNumId"
                caption="ID"
                alignment="center"
                width={80}
              />
              <Column caption="Item Details" alignment={"center"}>
                <Column
                  dataField="name"
                  caption="Item"
                  alignment="center"
                  minWidth={80}
                />
                <Column
                  dataField="serialnumber"
                  caption="Serial No."
                  alignment="center"
                  minWidth={80}
                />
                <Column
                  dataField="productid"
                  caption="Product No."
                  alignment="center"
                  minWidth={80}
                />
              </Column>
              <Column caption="Item Dimensions" alignment={"center"}>
                <Column
                  dataField="currentstock"
                  caption="QTY"
                  alignment="center"
                  minWidth={80}
                  format={{ type: "fixedPoint", precision: 0 }}
                  cellRender={this.CurrentStockCount}
                />
                <Column
                  dataField="onreserve"
                  caption="On Reserve"
                  alignment="center"
                  minWidth={80}
                  format={{ type: "fixedPoint", precision: 0 }}
                  cellRender={this.OnReserveCount}
                />
                <Column
                  dataField="dimensions"
                  caption="Dimensions"
                  alignment="center"
                  minWidth={100}
                />
                <Column
                  dataField="cbm"
                  caption="CBM per Item"
                  alignment="center"
                  minWidth={80}
                  format={{ type: "fixedPoint", precision: 2 }}
                />
                <Column
                  dataField="totalcbm"
                  caption="Total CBM"
                  alignment="center"
                  minWidth={80}
                  format={{ type: "fixedPoint", precision: 2 }}
                />
              </Column>
              <Column caption="Location" alignment={"center"}>
                <Column
                  dataField="loctags"
                  caption="Location"
                  alignment="center"
                  minWidth={80}
                />
              </Column>
              <Column
                caption="Actions"
                alignment={"center"}
                cellRender={this.ActionButs}
                width={100}
              />
              <Summary>
                <TotalItem
                  column="name"
                  summaryType="count"
                  displayFormat={`${PRKD(stockcalcs.items)} Items`}
                />
                <TotalItem
                  column="currentstock"
                  summaryType="sum"
                  displayFormat={`${PRKD(stockcalcs.instock)} In Stock`}
                />
                <TotalItem
                  column="onreserve"
                  summaryType="sum"
                  displayFormat={`${PRKD(stockcalcs.reserve)} On Reserve`}
                />
                <TotalItem
                  column="totalcbm"
                  summaryType="sum"
                  valueFormat={"#,##0.000"}
                  displayFormat={"{0} CBM"}
                />
              </Summary>
              <Export enabled={true} />
              <Toolbar>
                <Item name="Refresh" location="before">
                  <div style={{ paddingLeft: "4px" }}>
                    <Tooltip title={`Refresh`} arrow placement="right">
                      <IconButton
                        icon={<Icon icon="refresh" />}
                        onClick={() =>
                          singleAccountStore.getACItems(false, true)
                        }
                        appearance="default"
                        style={{ border: "1px solid black" }}
                      />
                    </Tooltip>
                  </div>
                </Item>

                <Item name="columnChooserButton" location="after" />
                <Item name="exportButton" location="after" />
              </Toolbar>
            </DataGrid>
          </div>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(ItemInventory));
