import { Parsed } from "../Pricing/PRKDCalc";

function ItemSummaryCalc(items) {
  let count = 0;
  let cbm = 0;
  let packages = 0;
  let calccbm = 0;

  if (items.length > 0) {
    count = items.length;
    for (let i = 0; i < items.length; i++) {
      cbm += Parsed(items[i].cbm);
      packages += Parsed(items[i].quantity);
      calccbm +=
        (Parsed(items[i].length) *
          Parsed(items[i].width) *
          Parsed(items[i].height)) /
        1000000;
    }
  }

  return [count, packages, cbm, calccbm];
}

export { ItemSummaryCalc };
