import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import accountStore from "../../../../../Stores/Account/AccountStore";

import Col3 from "../../../../../Components/Columns/Col3";
import userStore from "../../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import DeliverySummary from "./DeliverySummary";

const instance = axios.create({ baseURL: baseURL });

class DeliveryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  approvereject = async (type) => {
    let del = this.props.del;

    this.setState({ loading: true });
    let data = { status: type };

    return instance
      .put(`/ac/delivery/edit/${del._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        Notification["success"]({
          title: `${type} Delivery.`,
        });
        this.setState({ loading: false });
        accountStore.getDeliveries(true);
        this.props.onHide();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    if (!accountStore.accountloaded) {
      return <div />;
    } else if (!accountStore.delloaded) {
      return <div />;
    }

    let del = this.props.del;

    let account = accountStore.selectedaccount;
    let delCont = accountStore.selectedaccount.deliveryContract;

    let actions = (
      <div>
        <Div14>Staff Actions</Div14>
        <FlexboxGrid>
          <Col3>
            <IconButton
              loading={this.state.loading}
              size="sm"
              block
              icon={<Icon icon="check" />}
              color="green"
              onClick={() => this.approvereject("Booked")}
            >
              Approve Request
            </IconButton>
          </Col3>
          <Col3></Col3>
          <Col3>
            <IconButton
              loading={this.state.loading}
              size="sm"
              block
              icon={<Icon icon="trash" />}
              color="red"
              onClick={() => this.approvereject("Cancelled")}
            >
              Cancel Request
            </IconButton>
          </Col3>
        </FlexboxGrid>
        <hr />
      </div>
    );
    if ((del.status !== "Requested") & !this.props.edit) {
      actions = (
        <div>
          <Div14>Staff Actions</Div14>
          <FlexboxGrid justify="center">
            <Col2>
              <IconButton
                loading={this.state.loading}
                size="sm"
                block
                icon={<Icon icon="pencil" />}
                color="yellow"
                onClick={() => this.props.changeEditDelivery()}
              >
                Edit Delivery
              </IconButton>
            </Col2>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.onHide}
        footer={false}
        title={`${del.status} Delivery #${del.deliveryNumId} for ${moment(
          del.date
        ).format("DD-MMM-YY")}`}
      >
        {!userStore.user.clientview && actions}
        <DeliverySummary del={del} account={account} delCont={delCont} />
        <hr />
        <IconButton
          color="cyan"
          appearance="ghost"
          block
          onClick={() => this.props.history.push(`/trackdelivery/${del._id}`)}
          icon={<Icon icon="map" />}
        >
          Track Delivery
        </IconButton>
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(DeliveryModal));
