import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

import { Select } from "antd";
const { Option } = Select;

class SelectInput extends Component {
  render() {
    let options = this.props.options.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));
    return (
      <div>
        <br />
        <h6 style={{ color: this.props.val === "" && "red" }}>
          {this.props.label}:
        </h6>
        <Select
          size="large"
          showSearch
          value={this.props.val}
          style={{ width: "100%" }}
          placeholder={this.props.label}
          optionFilterProp="children"
          onChange={(val) => this.props.changeVal(this.props.type, val)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options}
        </Select>
      </div>
    );
  }
}

export default withRouter(observer(SelectInput));
