import { DatePicker } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock, Toggle } from "rsuite";
import sjStore from "../../../Stores/SideJobs/SJStore";
import { Tooltip } from "antd";

class SJDateToggle extends Component {
  render() {
    let ind = this.props.ind;
    let label = this.props.label;
    let labeldate = `${label}date`;
    let title = this.props.title;
    let load = this.props.load;

    let orgtoggle = sjStore.sjs[ind][label];
    let valtoggle = sjStore.esjs[ind][label];
    let org = sjStore.sjs[ind][labeldate];
    let val = sjStore.esjs[ind][labeldate];

    let disabled = false;
    if (this.props.disabled) disabled = this.props.disabled;

    let sj = this.props.sj;
    if (sj) {
      org = sjStore.sjs[ind][sj][labeldate];
      val = sjStore.esjs[ind][sj][labeldate];
      orgtoggle = sjStore.sjs[ind][sj][label];
      valtoggle = sjStore.esjs[ind][sj][label];
    }

    let changed = false;

    if (moment(org).format("YYYY-MM-DD") !== moment(val).format("YYYY-MM-DD")) {
      changed = true;
    }
    if (orgtoggle !== valtoggle) {
      changed = true;
    }

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;
    if (quality) {
      if (!valtoggle || val === undefined) {
        flag = true;
        qualitytext = `${title} must be added.`;
      }
    }
    let read = this.props.read;
    if (read) changed = false;

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          <Toggle
            size="lg"
            onChange={(e) => {
              if (!read) {
                this.props.editVal(e, label, sj);
                if (e === false) {
                  this.props.editVal(undefined, labeldate, sj);
                } else {
                  this.props.editVal(
                    moment().format("YYYY-MM-DD"),
                    labeldate,
                    sj
                  );
                }
              }
            }}
            checked={valtoggle}
          />
          {changed && (
            <InputGroup.Button
              loading={load}
              onClick={() => {
                this.props.editVal(org, labeldate, sj);
                this.props.editVal(orgtoggle, label, sj);
              }}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: flag && "#ff968c" }}>
              {title}
            </InputGroup.Addon>
          </Tooltip>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-yyyy"}
            style={{ width: "100%" }}
            disabled={disabled || !valtoggle}
            placeholder={!valtoggle ? "Pending" : "Select a Date"}
            value={valtoggle ? moment(val) : undefined}
            onChange={(e) => {
              if (!read) {
                let newdate;
                if (e === null) {
                  newdate = undefined;
                } else {
                  newdate = moment(e).format("YYYY-MM-DD");
                }
                this.props.editVal(newdate, labeldate, sj);
              }
            }}
          />

          {changed && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(label, title, sj)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {changed && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from{" "}
            <i>
              "{orgtoggle ? "Complete: " : "Pending: "}
              {org}"
            </i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(SJDateToggle);
