let imchargelist2 = [
  { title: "Origin + Shipping", label: "choriginship", in: true, out: false },
  { title: "Insurance Charges", label: "chinsurance", in: true, out: false },
  { title: "Other", label: "chothersec2", in: true, out: false },

  {
    title: "Transportation",
    label: "chtransport",
    in: false,
    out: true,
  },
  { title: "Global Clearance", label: "chclearance", in: false, out: true },
  { title: "Bayan Charges", label: "chbayan", in: false, out: true },
  { title: "Insurance Charges", label: "chinsurance", in: false, out: true },
  { title: "Other", label: "chothersec2", in: false, out: true },
];
export default imchargelist2;
