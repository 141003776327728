import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../../../../Components/Dividers/Div14";
import MainACInvoice from "../../../../../../../../../Components/PDFs/ACInvoices/MainACInvoice.js";
import acInvoiceStore from "../../../../../../../../../Stores/Financial/ACInvoiceStore";

class Step4 extends Component {
  render() {
    return (
      <div>
        <Div14>Step 4: Finalize Invoice</Div14>
        <MainACInvoice inv={acInvoiceStore.newacinvoice} />
      </div>
    );
  }
}

export default observer(Step4);
