import { Empty, message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Col,
  FlexboxGrid,
  Icon,
  IconButton,
  Loader,
  Notification,
} from "rsuite";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import TextInputSTU from "../../../../../Components/Inputs/Updatable/TextInputSTU";
import NumbInputSTU from "../../../../../Components/Inputs/Updatable/NumbInputSTU";
import { Parsed } from "../../../../../Functions/Pricing/PRKDCalc";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import Col2 from "../../../../../Components/Columns/Col2";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import Div14 from "../../../../../Components/Dividers/Div14";
import Col4 from "../../../../../Components/Columns/Col4";

const instance = axios.create({ baseURL: baseURL });

class AdditionalCharges extends Component {
  constructor(props) {
    super(props);
    this.state = { load: -1, show: false, loading: false, name: "", price: 0 };
    this.updateVal = this.updateVal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.changeST = this.changeST.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  editVal(val, label, index) {
    if (userStore.user.staffaccount) {
      newDeliveryStore.editAddCharge(val, label, index);
    }
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  onHide() {
    this.setState({ show: false, loading: false });
  }
  updateVal = async (label, title, index) => {
    let acorderid = newDeliveryStore.olddel._id;
    let data = {};
    if (label === "Delete") {
      let adds = [...newDeliveryStore.olddel.addcharge];
      let newadds = [];
      for (let i = 0; i < adds.length; i++) {
        if (i !== index) newadds = [...newadds, adds[i]];
      }
      data = { addcharge: newadds };

      this.setState({ load: index });

      await instance
        .put(`ac/delivery/edit/${acorderid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          newDeliveryStore.removeAddCharge(index, "addcharge");
          newDeliveryStore.removeOrgCharge(index, "addcharge");
          message.success(`Additional Charge Deleted`);
          this.setState({ load: -1 });
        })
        .catch((err) => {
          this.setState({ load: -1 });

          Notification["error"]({ title: `An error occured` });
        });
    } else {
      let adds = [...newDeliveryStore.olddel.addcharge];
      let newadds;

      if (label === "New") {
        newadds = [...adds, { name: this.state.name, price: this.state.price }];
        this.setState({ loading: true });
      } else {
        newadds = [...adds];
        newadds[index][label] = newDeliveryStore.newdel.addcharge[index][label];
      }

      data = { addcharge: newadds };
      await instance
        .put(`ac/delivery/edit/${acorderid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          let oldchgs = [...newDeliveryStore.newdel.addcharge];
          if (label === "New") {
            newDeliveryStore.updateOrgAddChgNew(
              {
                name: this.state.name,
                price: this.state.price,
              },
              "addcharge"
            );
          } else {
            newDeliveryStore.updateOrgAddChg(
              oldchgs[index][label],
              label,
              index
            );
          }
          message.success(`Additional Charge Deleted`);
          this.setState({
            load: -1,
            loading: false,
            name: "",
            price: 0,
            show: false,
          });
        })
        .catch((err) => {
          this.setState({ load: -1, loading: false });

          Notification["error"]({ title: `An error occured` });
        });
    }
  };
  render() {
    let adds = newDeliveryStore.newdel.addcharge;
    let orgadds = newDeliveryStore.olddel.addcharge;
    let load = this.state.load;
    let content = <Empty description="No Additional Charges" />;
    if (this.state.loading) {
      content = <Loader center />;
    } else {
      if (adds.length > 0) {
        content = adds.map((add, index) => (
          <FlexboxGrid key={index} justify="center" align="middle">
            <Col md={14} sm={14} xs={24}>
              <TextInputSTU
                val={add.name}
                org={orgadds[index].name}
                label="name"
                title="Name:"
                size="sm"
                load={load === index}
                editVal={this.editVal}
                updateVal={this.updateVal}
                ind={index}
              />
            </Col>
            <Col md={8} sm={8} xs={16}>
              <NumbInputSTU
                val={add.price}
                org={orgadds[index].price}
                label="price"
                title="Price:"
                size="sm"
                load={load === index}
                editVal={this.editVal}
                updateVal={this.updateVal}
                ind={index}
              />
            </Col>
            <Col md={2} sm={2} xs={8}>
              {userStore.user.staffaccount && (
                <FlexboxGrid justify="center">
                  <IconButton
                    icon={<Icon icon="trash" />}
                    color="red"
                    block
                    loading={load === index}
                    onClick={() => this.updateVal("Delete", "Delete", index)}
                    disabled={!userStore.user.staffaccount}
                  >
                    Delete
                  </IconButton>
                </FlexboxGrid>
              )}
            </Col>
          </FlexboxGrid>
        ));
      }
    }

    let totaladdchgs = 0;
    for (let i = 0; i < orgadds.length; i++) {
      totaladdchgs += Parsed(orgadds[i].price);
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="New Additional Charge"
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Save"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateVal("New")}
              disabled2={
                this.state.price === 0 ||
                this.state.name === "" ||
                this.state.price === ""
              }
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <FlexboxGrid>
            <Col2>
              <TextInputSTD
                val={this.state.name}
                label="name"
                title="Name:"
                size="sm"
                disabled={this.state.loading}
                editVal={this.changeST}
              />
            </Col2>
            <Col2>
              <NumbInputSTD
                val={this.state.price}
                label="price"
                title="Price:"
                size="sm"
                disabled={this.state.loading}
                editVal={this.changeST}
              />
            </Col2>
          </FlexboxGrid>
        </UnifiedModal>

        <Div14>Additional Charges ({totaladdchgs} KD)</Div14>
        {content}
        <br />
        {userStore.user.staffaccount && (
          <FlexboxGrid justify="center">
            <Col4>
              <IconButton
                icon={<Icon icon="plus" />}
                color="green"
                block
                onClick={() =>
                  this.setState({ show: true, name: "", price: 0 })
                }
              >
                New Additional Charge
              </IconButton>
            </Col4>
          </FlexboxGrid>
        )}
      </div>
    );
  }
}

export default observer(AdditionalCharges);
