import { observer } from "mobx-react";
import React, { Component } from "react";
import { Tabs } from "antd";
import { withRouter } from "react-router";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import TimelineComp from "../../../../Components/Timeline/TimelineComp";

import OrderSummaryTable from "./Supporting/OrderSummaryTable";
import ApprovalSummary from "./Supporting/ApprovalSummary";
import Div14 from "../../../../Components/Dividers/Div14";
import ContainerInfo from "./Supporting/ContainerInfo";
import LoadBack from "../../../../Components/Loaders/LoadBack";

const { TabPane } = Tabs;

class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Summary" };
  }

  render() {
    let acc = modalACOrderStore.acc;

    let allsteps = [];
    let step = 2;
    allsteps = ["Requested", "Booked"];
    if (acc.status === "Requested") step = 0;
    if (acc.status === "Booked") step = 1;

    if (acc.status === "In Transit") {
      allsteps = [...allsteps, "In Transit", "Delivered"];
    } else {
      allsteps = [...allsteps, "Delivered"];
    }

    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          onHide={() => {
            this.setState({ tab: "Summary" });
            this.props.onHide();
          }}
          title={`Delivery Request #${acc.accOrderNumId} for ${acc.client.name}`}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Go To Order"]}
              oC1={() => {
                this.setState({ tab: "Summary" });
                this.props.onHide();
              }}
              oC2={() => {
                this.props.history.push(`/accorder/${acc._id}`);
              }}
            />
          }
        >
          <LoadBack loading={modalACOrderStore.loading.acc}>
            <TimelineComp steps={allsteps} step={step} />
            <Tabs
              activeKey={this.state.tab}
              defaultActiveKey={"home"}
              tabPosition={"top"}
              centered
              type="card"
              onChange={(e) => this.setState({ tab: e })}
            >
              <TabPane tab={`Summary`} key="Summary">
                <Div14>Order Summary</Div14>
                <OrderSummaryTable acc={acc} account={acc.client} />
                <Div14>Shipment Details</Div14>
                <ContainerInfo acc={acc} account={acc.client} />
              </TabPane>

              <TabPane tab={`Approval Summary`} key="Approval Summary">
                <ApprovalSummary acc={acc} />
              </TabPane>
            </Tabs>
          </LoadBack>
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(OrderModal));
