import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Col, IconButton, Icon } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";

import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import moment from "moment";

import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import stafflisttype from "../../../../Static/Lists/hr/stafftypelist";

class NewStaff extends Component {
  render() {
    let staff = this.props.staff;
    let disabled = false;
    if (staff.type === "") disabled = true;
    if (staff.base === "") disabled = true;
    if (staff.name === "") disabled = true;
    if (staff.title === "") disabled = true;

    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.hideModal}
        footer={
          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={<Icon icon="close" />}
                color="red"
                block
                onClick={() => this.props.hideModal(false)}
                disabled={this.props.loading}
              >
                Cancel
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="plus" />}
                color="green"
                block
                disabled={disabled}
                loading={this.props.loading}
                onClick={() => this.props.postNew()}
              >
                Create {this.props.type}
              </IconButton>
            </Col2>
          </FlexboxGrid>
        }
      >
        <br />
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <SelectInputSTD
              title="Type"
              val={staff.type}
              label="type"
              size="large"
              opts={stafflisttype}
              editVal={this.props.editVal}
            />
          </Col2>
          <Col2>
            <TextInputSTD
              title="Location"
              val={staff.base}
              label="base"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <TextInputSTD
              title="Name"
              val={staff.name}
              label="name"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
          <Col2>
            <TextInputSTD
              title="Title"
              val={staff.title}
              label="title"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col md={24} sm={24} xs={24}>
            <TextAreaInputSTD
              title="Job Description"
              val={staff.jobdesc}
              label="jobdesc"
              size="large"
              editVal={this.props.editVal}
            />
          </Col>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <TextInputSTD
              title="Email"
              val={staff.email}
              label="email"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
          <Col2>
            <TextInputSTD
              title="Phone"
              val={staff.phone}
              label="phone"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <NumbInputSTD
              title="Salary (KD)"
              val={staff.salary}
              label="salary"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
          <Col2>
            <NumbInputSTD
              title="Days Off"
              val={staff.monthlyholiday}
              label="monthlyholiday"
              size="large"
              editVal={this.props.editVal}
            />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <DatePickerSTD
              title="Join Date"
              editVal={this.props.editVal}
              val={staff.joined}
              label="joined"
            />
            <i>{moment(staff.joined).fromNow()}</i>
          </Col2>
          <Col2>
            <TextInputSTD
              title="Civil ID"
              editVal={this.props.editVal}
              val={staff.civilid}
              label="civilid"
            />
          </Col2>
        </FlexboxGrid>
      </UnifiedModal>
    );
  }
}

export default observer(NewStaff);
