import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";

class ServicesSection extends Component {
  render() {
    let inc = this.props.data.incservices;
    let exc = this.props.data.excservices;

    let incTitle = "";
    let excTitle = "";

    if (inc.length > 0) {
      incTitle = inc.join(", ");
    }
    if (exc.length > 0) {
      excTitle = exc.join(", ");
    }
    return (
      <div>
        <Div14>Services:</Div14>
        <b style={{ color: "green" }}>Included Services: </b>
        {inc.length > 0 && incTitle + "."}
        <br />
        <b style={{ color: "red" }}>Excluded Services: </b>
        {exc.length > 0 && excTitle + "."}
      </div>
    );
  }
}

export default observer(ServicesSection);
