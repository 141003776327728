import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  LoadPanel,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

import moment from "moment";
import clientBalanceStore from "../../../Stores/Financial/ClientBalanceStore";
import Div14 from "../../../Components/Dividers/Div14";

class PaymentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  StatusCell(cellData) {
    let dates = cellData.data.dates;
    let output = [];
    for (let i = 0; i < dates.length; i++) {
      output = [...output, moment(dates[i]).format("DD-MMM-YY")];
    }

    return <div>{output.join(", ")}</div>;
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `EasyMovePayments_as_of_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  render() {
    let jobs = clientBalanceStore.stats;

    return (
      <>
        <Div14>Monthly Sales</Div14>
        <DataGrid
          dataSource={jobs}
          keyExpr="jobNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          hoverStateEnabled={true}
        >
          <LoadPanel enabled={true} />
          <FilterRow visible={true} applyFilter />

          <GroupPanel visible={true} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column
            dataField="jobNumId"
            dataType="number"
            caption="ID"
            alignment="center"
            width={80}
            sortOrder={"desc"}
          />
          <Column
            dataField="dates.length"
            caption="Days"
            alignment="center"
            minWidth={60}
          />
          <Column
            dataField="dates"
            caption="Dates"
            alignment="center"
            minWidth={160}
            cellRender={this.StatusCell}
          />
          <Column
            dataField="jobtype"
            dataType="text"
            caption="Sub Type"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="lastprice"
            dataType="text"
            caption="Total Price"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="cbm"
            dataType="text"
            caption="Total CBM"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="statperday.price"
            dataType="number"
            format={{ type: "fixedPoint", precision: 3 }}
            caption="Daily Price"
            alignment="center"
            minWidth={80}
          />

          <Column
            dataField="focalpoint"
            caption="Focal Point"
            alignment="center"
            minWidth={80}
          />

          <Summary>
            <TotalItem
              column="date"
              summaryType="count"
              displayFormat={"{0}"}
            />
            <TotalItem
              column="amount"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
          </Summary>
          <Export enabled={true} />
        </DataGrid>
      </>
    );
  }
}

export default withRouter(observer(PaymentTable));
