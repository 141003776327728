import { AddChargeCalc } from "./AddChargeCalc";
import { Parsed } from "./PRKDCalc";

function LMPriceCalc(job) {
  let lastprice = Parsed(job.lm.lastprice);
  let addcharge = AddChargeCalc(job.addcharge);
  let price = Parsed(lastprice) + Parsed(addcharge);
  return [price, lastprice, addcharge];
}

function LMPriceRangeCalc(job) {
  let jobtype = job.subtype;
  let cbm = Parsed(job.cbm);

  let min = 65;
  let max = 65;

  let minpcbm = 7;
  let maxpcbm = 9;

  let finalmin = 0;
  let finalmax = 0;

  if (jobtype === "Full Service") {
    minpcbm = 7;
    maxpcbm = 9;
  } else if (jobtype === "Regular Service") {
    minpcbm = 4;
    maxpcbm = 6;
  } else if (jobtype === "Coporate Full Service") {
    minpcbm = 8;
    maxpcbm = 10;
  } else if (jobtype === "Coporate Regular Service") {
    minpcbm = 5;
    maxpcbm = 7;
  }

  if (cbm <= 0) {
    finalmin = min;
    finalmax = max;
  } else {
    finalmin = minpcbm * cbm;
    finalmax = maxpcbm * cbm;
  }
  if (finalmin < min) finalmin = min;
  if (finalmax < max) finalmax = max;
  return {
    finalmin: finalmin,
    finalmax: finalmax,
    minpcbm: minpcbm,
    maxpcbm: maxpcbm,
  };
}

export { LMPriceRangeCalc, LMPriceCalc };
