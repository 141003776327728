import { Divider } from "antd";
import React, { Component } from "react";

class Div14 extends Component {
  render() {
    let size = 14;
    if (this.props.size) size = this.props.size;

    let marginTop = 0;
    if (this.props.marginTop) marginTop = this.props.marginTop;

    let align = "center";
    if (this.props.align) align = this.props.align;
    return (
      <Divider
        style={{
          margin: marginTop,
          padding: "10px",
          fontSize: size,
        }}
        orientation={align}
      >
        {this.props.children}
      </Divider>
    );
  }
}

export default Div14;
