import { observer } from "mobx-react";
import React, { Component } from "react";
import moment from "moment";
import InvoiceMainInfo from "./InvoiceMainInfo";
import InvoiceProps from "./InvoiceProps";
import axios from "axios";

import { Paper } from "@material-ui/core";
import Col2 from "../../../../../../Components/Columns/Col2";
import { Button, FlexboxGrid, IconButton, Icon, Steps } from "rsuite";
import { Notification } from "rsuite";
import InvoiceJobs from "./InvoiceJobs";
import PackingList from "./PackingList";
import MainInvoicePDF from "../../../../../../Components/PDFs/Invoices/MainInvoicePDF";

import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";
import InvoiceNotes from "./InvoiceNotes";
import JobDatesSelect from "./JobDatesSelect";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import singleJobStore from "../../../../../../Stores/SingleJobStore";

const instance = axios.create({ baseURL: baseURL });

class InvoiceEditMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      load: false,
      show: false,
      recprops: [],
      newinvoice: {
        title: "Easy Move Invoice",
        date: moment(),
        dateto: moment().add(14, "days"),
        status: "Pending",
        remarks: "",
        total: 0,
        jobid: "",
        subJobs: [],
        invtype: "New",
        invNumber: "New",
        showBank: "KFH",
        invoiceNumId: "New",
      },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.postNewInvoice = this.postNewInvoice.bind(this);
    this.editProp = this.editProp.bind(this);
    this.reorder = this.reorder.bind(this);
    this.addremoveitem = this.addremoveitem.bind(this);
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({ show: false });
    }
  }

  postNewInvoice = async () => {
    this.setState({ load: true });
    let total = 0;
    let data = {};

    data = invoiceStore.newinvoice;
    for (let i = 0; i < data.subJobs.length; i++) {
      for (let j = 0; j < data.subJobs[i].proposals.length; j++) {
        if (data.subJobs[i].proposals[j].added) {
          total += parseFloat(data.subJobs[i].proposals[j].ammount);
        }
      }
    }
    data.total = total;

    let url = "";
    url = `jf/invoice/new`;
    let type = "New";
    if (data.invoiceNumId === "New") {
      url = `jf/invoice/new`;
      type = "New";
    } else {
      url = `jf/invoice/edit/${data._id}`;
      type = "Update";
    }
    delete data.invoiceNumId;
    delete data._id;

    if (type === "New") {
      await instance
        .post(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ load: false });
          this.showHideModal(false);
          invoiceStore.getInvoiceByJob(singleJobStore.job._id);
          this.props.changeST(false, "new");
          Notification["success"]({ title: `New Invoice Created` });
        })
        .catch((err) => {
          this.setState({ load: false });
          Notification["error"]({ title: `An error occured` });
        });
    } else {
      await instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ load: false });
          this.showHideModal(false);
          invoiceStore.getInvoiceByJob(singleJobStore.job._id);
          this.props.changeST(false, "new");
          Notification["success"]({ title: `Invoice Updated` });
        })
        .catch((err) => {
          this.setState({ load: false });
          Notification["error"]({ title: `An error occured` });
        });
    }
  };

  addremoveitem(sjIndex, itemIndex, option) {
    let old = { ...this.state.newinvoice };
    if (option) {
      let newval = true;
      if (option === "None") newval = false;
      for (let i = 0; i < old.subJobs[sjIndex].items.length; i++) {
        old.subJobs[sjIndex].items[i].hide = newval;
      }
    } else {
      old.subJobs[sjIndex].items[itemIndex].hide =
        !old.subJobs[sjIndex].items[itemIndex].hide;
    }
    this.setState({ newinvoice: { ...old } });
  }

  editProp(type, prop, index, propindex) {
    let old = { ...this.state.newinvoice };
    if (type === "Add") {
      old.subJobs[index].proposals = [...old.subJobs[index].proposals, prop];
    } else if (type === "Remove") {
      old.subJobs[index].proposals.splice(propindex, 1);
    }
    this.setState({ newinvoice: old });
  }
  reorder(oldInd, newInd) {
    let old = { ...this.state.newinvoice };
    old.proposals[oldInd].order = newInd;
    old.proposals[newInd].order = oldInd;
    this.setState({ newinvoice: old });
  }

  render() {
    let inv = invoiceStore.newinvoice;

    let disabled;
    if (this.state.step === 0) {
      if (invoiceStore.newinvoice.title === "") disabled = true;
    } else if (this.state.step === 1) {
      if (invoiceStore.newinvoice.subJobs.length <= 0) disabled = true;
    } else if (this.state.step === 2) {
      let proposals = 0;
      for (let i = 0; i < invoiceStore.newinvoice.subJobs.length; i++) {
        proposals += invoiceStore.newinvoice.subJobs[i].proposals.length;
      }
      if (proposals <= 0) disabled = true;
    }

    let proposalrows;
    if (invoiceStore.newinvoice.subJobs.length > 0) {
      proposalrows = invoiceStore.newinvoice.subJobs.map((inv, index) => (
        <InvoiceProps
          sjindex={index}
          inv={inv}
          key={index}
          editProp={this.editProp}
          reorder={this.reorder}
          recprops={this.state.recprops}
        />
      ));
    }

    return (
      <div style={{ padding: "2%" }}>
        <UnifiedModal
          show={this.state.show}
          title={
            inv.invoiceNumId === "New" ? "Create a New Invoice" : "Save Changes"
          }
          onHide={this.showHideModal}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  loading={this.state.load}
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => this.showHideModal(false)}
                >
                  No, Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  loading={this.state.load}
                  block
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => this.postNewInvoice()}
                >
                  Yes,{" "}
                  {inv.invoiceNumId === "New"
                    ? "Create a New Invoice"
                    : "Save Changes"}
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          Are you sure you want to{" "}
          {inv.invoiceNumId === "New" ? "Create" : "Update"} the Invoice?
        </UnifiedModal>
        <Paper>
          <Steps current={this.state.step}>
            <Steps.Item title="Invoice Main Info" />
            <Steps.Item title="Sub Jobs" />
            <Steps.Item title="Costs" />
            <Steps.Item title="Packing List" />
            <Steps.Item title="Job Dates" />
            <Steps.Item title="Invoice Notes" />
            <Steps.Item title="Save" />
          </Steps>
          <hr />
          <FlexboxGrid>
            <Col2>
              <Button
                block
                appearance={this.state.step === 0 ? "primary" : "ghost"}
                color="red"
                onClick={() => {
                  if (this.state.step === 0) {
                    this.props.changeST(false, "new");
                  } else {
                    this.setState({ step: this.state.step - 1 });
                  }
                }}
              >
                {this.state.step === 0 ? "Cancel" : "Back"}
              </Button>
            </Col2>
            <Col2>
              <Button
                color="green"
                block
                loading={this.state.show}
                onClick={() => {
                  if (this.state.step === 1) invoiceStore.getRecs();
                  if (this.state.step === 6) {
                    this.setState({ show: true });
                  } else {
                    this.setState({ step: this.state.step + 1 });
                  }
                }}
                disabled={disabled}
              >
                {this.state.step !== 6
                  ? "Next"
                  : inv.invoiceNumId === "New"
                  ? "Create Invoice"
                  : "Save Invoice"}
              </Button>
            </Col2>
          </FlexboxGrid>
          {this.state.step === 0 && <InvoiceMainInfo inv={inv} />}
          {this.state.step === 1 && <InvoiceJobs inv={inv} />}
          {this.state.step === 2 && proposalrows}
          {this.state.step === 3 && (
            <PackingList
              inv={inv}
              editProp={this.editProp}
              reorder={this.reorder}
              recprops={this.state.recprops}
              addremoveitem={this.addremoveitem}
            />
          )}

          {this.state.step === 4 && <JobDatesSelect inv={inv} />}
          {this.state.step === 5 && <InvoiceNotes inv={inv} />}
          {this.state.step === 6 && <MainInvoicePDF inv={inv} />}
        </Paper>
      </div>
    );
  }
}

export default observer(InvoiceEditMain);
