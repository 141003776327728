import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });
const trackinst = axios.create({
  baseURL: "https://api.thelightbug.com/api/",
});

class CarStore {
  constructor() {
    this.cars = [];
    this.user = [];
    this.devices = [];
    this.selectedcar = 0;

    this.loading = false;
    this.dataloaded = false;

    makeObservable(this, {
      cars: observable,
      user: observable,
      devices: observable,
      selectedcar: observable,
      loading: observable,
      dataloaded: observable,
      trucklist: computed,
      singlecar: computed,
    });
  }

  // FETCH
  getCars() {
    let loaded = false;
    if (this.loading || this.dataloaded) loaded = true;
    if (!loaded) {
      this.loading = true;
      return instance
        .get(`/additional/truck/all`)
        .then((res) => res.data)
        .then((cars) => {
          this.cars = cars;
          this.loading = false;
          this.dataloaded = true;
          console.log("Car Track Loaded");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // FETCH Tracking
  fetchUser = async () => {
    this.loading = true;
    let credentials = {
      username: "alfouzan@easymovekw.com",
      password: "pass123",
    };
    trackinst
      .post("/users/login", credentials)
      .then((res) => res.data)
      .then((user) => {
        this.user = user;
        console.log("API Logged In");
      })
      .then((user) => {
        this.fetchDevices();
      })
      .catch((err) => {
        this.loading = false;

        Notification["error"]({
          title: `Not Able to Fetch Device.`,
        });
      });
  };

  fetchDevices = async () => {
    trackinst
      .get(`/users/${this.user.userId}/getDeviceSummary `, {
        headers: { Authorization: this.user.id },
      })
      .then((res) => res.data)
      .then((devices) => {
        let newDevices = [];
        for (let i = 0; i < devices.length; i++) {
          let drivername = "";

          drivername = devices[i].tags[0].substring(
            devices[i].tags[0].search(":") + 1,
            20
          );

          let carStat = "On";
          if (devices[i].currentMode === 1) carStat = "Off";
          if (devices[i].currentMode > 1) carStat = "On";

          newDevices.push({
            id: devices[i].id,
            name: devices[i].name,
            lat: devices[i].latestPoints[0].location.lat,
            lng: devices[i].latestPoints[0].location.lng,
            address: devices[i].latestPoints[0].address,
            speed: devices[i].latestPoints[0].speed,
            date: moment(devices[i].latestPoints[0].created).fromNow(),
            driver: drivername.charAt(0).toUpperCase() + drivername.slice(1),
            color: devices[i].color,
            status: devices[i].currentMode,
            carStat: carStat,
          });
        }
        this.devices = newDevices;

        console.log("Devices Fetched");
        this.loading = false;
      })
      .then((device) => (this.loading = false))
      .catch((err) => {
        this.loading = false;
        console.error(err);
        Notification["error"]({
          title: `Not Able to Fetch Device.`,
        });
      });
  };
  // POST

  // COMPUTATIONS:
  get trucklist() {
    return this.cars.map((truck) => truck.name);
  }

  get singlecar() {
    return this.devices.filter(
      (car) => parseFloat(car.id) === parseFloat(this.selectedcar)
    );
  }

  // FUNCTIONS:

  selectCar(truckapi) {
    this.selectedcar = truckapi;
    this.fetchUser();
  }

  selectCarId(truckid) {
    let truckapi = "";
    for (let i = 0; i < this.cars.length; i++) {
      if (this.cars[i]._id === truckid) {
        truckapi = this.cars[i].truckapi;
      }
    }

    this.selectCar(truckapi);
  }

  startLoading() {
    this.loading = true;
  }
  endLoading() {
    this.loading = false;
  }
}

const carStore = new CarStore();
export default carStore;
