import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import { Tooltip } from "@material-ui/core";
import { IconButton, Icon, FlexboxGrid, Notification } from "rsuite";
import MaterialTable from "material-table";
import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../Components/Columns/Col2";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class TruckTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trucks: [],
      type: "New",
      show: false,
      truck: { name: "", truckapi: "", active: true, _id: "" },
      loading: false,
    };
    this.editTruck = this.editTruck.bind(this);
  }
  componentDidMount() {
    this.fetchTrucks();
  }

  fetchTrucks = async () => {
    this.setState({ loading: true });
    await instance
      .get(`/additional/truck/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false, trucks: res.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  onHide() {
    this.setState({ show: false });
  }
  editTruck(data, label) {
    let truck = { ...this.state.truck };
    truck[label] = data;
    this.setState({ truck: truck });
  }

  postTruck = async (data, type) => {
    this.setState({ loading: true });
    if (type === "New") {
      return instance
        .post(`/additional/truck/new`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchTrucks();
          this.setState({ loading: false });
          Notification["success"]({
            title: `New Truck Created.`,
            description: <div>{data.amount} KD</div>,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else {
      return instance
        .put(`/additional/truck/edit/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchTrucks();
          this.setState({ loading: false });
          Notification["success"]({
            title: `Updated Truck Details.`,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    }
  };

  newTruck() {
    this.setState({
      type: "New",
      show: true,
      truck: { name: "", truckapi: "", active: true, _id: "" },
    });
  }

  render() {
    let data = this.state.trucks;
    let columnnames = [
      { title: "ID", field: "truckNumId", defaultSort: "desc" },
      { title: "Name", field: "name" },
      { title: "API Code", field: "truckapi" },
      { title: "Active", field: "active" },
    ];

    let type = this.state.type;
    let truck = this.state.truck;
    let disable = false;
    if (truck.name === "") disable = true;
    if (truck.id === "") disable = true;

    return (
      <div>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`${type === "New" ? "Add New " : "Edit Existing "} Truck`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.postTruck(this.state.truck, this.state.type);
                  }}
                >
                  {type === "New" ? "Create New " : "Save Edited "} Truck
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid>
            <Col2>
              <TextInputSTD
                title="Truck Name"
                val={truck.name}
                label="name"
                size="large"
                editVal={this.editTruck}
              />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Truck API Code"
                val={truck.truckapi}
                label="truckapi"
                size="large"
                editVal={this.editTruck}
              />
            </Col2>
          </FlexboxGrid>
        </UnifiedModal>

        <Div14>
          Truck:
          <span style={{ width: "10px" }} />{" "}
          <Tooltip title={"Refresh List"} arrow placement="top">
            <IconButton
              circle
              size="xs"
              icon={<Icon icon="refresh" />}
              onClick={() => this.fetchTrucks()}
            />
          </Tooltip>
        </Div14>

        <div>
          <MaterialTable
            isLoading={this.state.loading}
            title={data.length + " Trucks"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `Truck List as of ${moment(new Date()).format(
                "DD-MMM-YY"
              )}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Truck",
                onClick: (event, rowData) =>
                  this.setState({ truck: rowData, show: true, type: "Edit" }),
              },
              {
                icon: "add",
                tooltip: "Open New Truck",
                isFreeAction: true,
                onClick: (event, rowData) => this.newTruck(),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default observer(TruckTab);
