import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import newStorageStore from "../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import Col4 from "../../../../Components/Columns/Col4";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import completeStorStore from "../../../../Stores/AccountUpdated/Storage/CompleteStorStore";
import Col2 from "../../../../Components/Columns/Col2";

class PickupDropoffButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let sto = newStorageStore.neworder;
    let toadd = sto.toaddress;
    let inout = newStorageStore.delstoinout.inout;
    let delsto = newStorageStore.delstoinout.delsto;
    let deliverybuts;

    let countpending = 0;
    for (let i = 0; i < toadd.length; i++) {
      if (toadd[i].status === "Pending") countpending += 1;
    }
    if (
      (sto.status !== "Cancelled") &
      (sto.status !== "Requested") &
      (sto.status !== "Completed")
    ) {
      if (delsto === "Delivery") {
        if (inout === "In") {
          if (countpending > 0) {
            deliverybuts = toadd.map((to, index) => (
              <Col4 key={index}>
                <IconButton
                  icon={<Icon icon="truck" />}
                  block
                  color="green"
                  loading={sto.loading}
                  disabled={to.status === "Completed"}
                  onClick={() => {
                    completeStorStore.getSingleACOrderFromTo(to._id, true);
                    this.props.history.push(`/acorderconfdelin/${to._id}`);
                  }}
                >
                  Complete{to.status === "Completed" ? "d" : ""} Pickup{" "}
                  {to.order} ({to.city})
                </IconButton>
              </Col4>
            ));
          } else {
            deliverybuts = (
              <Col2>
                <IconButton
                  icon={<Icon icon="cube" />}
                  block
                  color="green"
                  loading={sto.loading}
                  onClick={() => {
                    completeStorStore.getSingleACOrder(sto._id, true);
                    this.props.history.push(`/acorderconfstodelin/${sto._id}`);
                  }}
                >
                  Complete Storage In
                </IconButton>
              </Col2>
            );
          }
        } else {
          if (sto.storSignature.name === "") {
            deliverybuts = (
              <Col2>
                <IconButton
                  icon={<Icon icon="cube" />}
                  block
                  color="green"
                  loading={sto.loading}
                  onClick={() => {
                    completeStorStore.getSingleACOrder(sto._id, true);
                    this.props.history.push(`/acorderconfstodelout/${sto._id}`);
                  }}
                >
                  Complete Storage Out
                </IconButton>
              </Col2>
            );
          } else {
            deliverybuts = toadd.map((to, index) => (
              <Col4 key={index}>
                <IconButton
                  icon={<Icon icon="truck" />}
                  block
                  color="green"
                  loading={sto.loading}
                  disabled={to.status === "Completed"}
                  onClick={() => {
                    completeStorStore.getSingleACOrderFromTo(to._id, true);
                    this.props.history.push(`/acorderconfdelout/${to._id}`);
                  }}
                >
                  Complete{to.status === "Completed" ? "d" : ""} Delivery{" "}
                  {to.order} ({to.city})
                </IconButton>
              </Col4>
            ));
          }
        }
      } else {
        if (sto.storSignature.name === "") {
          if (inout === "In") {
            deliverybuts = (
              <Col2>
                <IconButton
                  icon={<Icon icon="cube" />}
                  block
                  color="green"
                  loading={sto.loading}
                  onClick={() => {
                    completeStorStore.getSingleACOrder(sto._id, true);
                    this.props.history.push(`/acorderconfstoin/${sto._id}`);
                  }}
                >
                  Complete Storage In
                </IconButton>
              </Col2>
            );
          } else {
            deliverybuts = (
              <Col2>
                <IconButton
                  icon={<Icon icon="cube" />}
                  block
                  color="green"
                  loading={sto.loading}
                  onClick={() => {
                    completeStorStore.getSingleACOrder(sto._id, true);
                    this.props.history.push(`/acorderconfstoout/${sto._id}`);
                  }}
                >
                  Complete Storage Out
                </IconButton>
              </Col2>
            );
          }
        }
      }
    }
    return (
      <div>
        <FlexboxGrid justify="center">{deliverybuts}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(PickupDropoffButtons));
