import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Div14 from "../../../../Components/Dividers/Div14";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Divider, Icon, IconButton } from "rsuite";
import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import OrderModal from "../Modals/OrderModal";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class SACCalendarPage extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.changeST = this.changeST.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    singleAccountStore.getCalendar(this.props.match.params.clientid, true);
  }

  onHide() {
    this.setState({ show: false });
  }
  changeST(label, val) {
    this.setState({ [label]: val });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let evt = event;

    let incomp = { backgroundColor: "#006103", textColor: "white" };
    let outcomp = { backgroundColor: "#614301", textColor: "white" };
    let intrans = { backgroundColor: "#129903", textColor: "white" };
    let outtrans = { backgroundColor: "#b37b00", textColor: "white" };
    let inbook = { backgroundColor: "#acf2a5", textColor: "black" };
    let outbook = { backgroundColor: "#e6cd95", textColor: "black" };
    let final;
    if (evt.jobtype === "Delivery In" || evt.jobtype === "Storage In") {
      final = inbook;
      if (evt.status === "Completed") final = incomp;
      if (evt.status === "In Transit") final = intrans;
    } else if (
      evt.jobtype === "Delivery Out" ||
      evt.jobtype === "Storage Out"
    ) {
      final = outbook;
      if (evt.status === "Completed") final = outcomp;
      if (evt.status === "In Transit") final = outtrans;
    } else {
      final = outcomp;
    }
    if (evt.status === "Cancelled") {
      final = { backgroundColor: "#730006", textColor: "white" };
    }

    var style = {
      backgroundColor: final.backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 10,
      height: 15,
      color: final.textColor,
    };
    return {
      style: style,
    };
  };
  render() {
    let data = singleAccountStore.calendar;

    return (
      <div>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <Div14>
          Storage Calendar Page
          <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            circle
            size="sm"
            loading={singleAccountStore.loading.calendar}
            onClick={() =>
              singleAccountStore.getCalendar(
                this.props.match.params.clientid,
                true
              )
            }
          />
        </Div14>
        <LoadBack loading={singleAccountStore.loading.calendar}>
          <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
            <Calendar
              localizer={localizer}
              events={data}
              eventPropGetter={this.eventStyleGetter}
              startAccessor={"start"}
              endAccessor={"start"}
              style={{ height: 500 }}
              defaultDate={new Date()}
              views={["month"]}
              titleAccessor={"title"}
              popup
              onSelectEvent={(e) => {
                modalACOrderStore.selectOrder(e);
                this.setState({ show: true });
              }}
              onNavigate={(e) => console.log(e)}
            />
          </div>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(SACCalendarPage));
