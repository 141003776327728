import React, { Component } from "react";

import moment from "moment";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import singleStaffHRStore from "../../../../../Stores/HR/SingleStaffHRStore";
import {
  Button,
  Col,
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import Div14 from "../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import MaterialTable from "material-table";
import Col2 from "../../../../../Components/Columns/Col2";

import NewStaffHoliday from "./NewStaffHoliday";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import Col4 from "../../../../../Components/Columns/Col4";
import LoadBack from "../../../../../Components/Loaders/LoadBack";
import HolidayCalendar from "../../../../../Components/Calendar/HolidayCalendar";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import { Parsed } from "../../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class SingleStaffHoliday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showcal: false,
      showdel: false,
      loading: false,
      type: "New",
      apprej: "",
      holiday: {
        type: "",
        date: moment(),
        dateto: moment(),
        days: 0,
        staff: { name: "", type: "" },
      },
    };
    this.hideModal = this.hideModal.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.editVal = this.editVal.bind(this);
    this.updateHoliday = this.updateHoliday.bind(this);
    this.postNew = this.postNew.bind(this);
    this.deleteHoliday = this.deleteHoliday.bind(this);
  }
  deleteHoliday = async (id) => {
    this.setState({ loading: true });
    let url = `/hr/holiday/deletebyid/${id}`;

    return instance
      .put(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.getHolidays(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Holiday Deleted`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  updateHoliday = async (status, id) => {
    let newservice = { status: status };
    this.setState({ loading: true });
    let url = `/hr/holiday/edit/${id}`;

    return instance
      .put(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.getHolidays(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `${status} Holiday`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  postNew = async (days) => {
    let newservice = {
      type: this.state.holiday.type,
      staff: singleStaffHRStore.singlestaff._id,
      date: this.state.holiday.date,
      dateto: this.state.holiday.dateto,
      remark: this.state.holiday.remark,
      days: days,
    };
    this.setState({ loading: true });
    let url = "/hr/holiday/new";

    return instance
      .post(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.getHolidays(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Added New Holiday: ${newservice.type}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  onSelectEvent(e) {
    this.setState({ showcal: true, holiday: e });
  }
  editVal(val, type) {
    let holiday = { ...this.state.holiday };
    if (type === "date") {
      holiday.date = val[0];
      holiday.dateto = val[1];
    } else {
      holiday[type] = val;
    }
    this.setState({ holiday: holiday });
  }
  hideModal() {
    this.setState({ showcal: false });
  }
  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        apprej: "",
        show: false,
        showdel: false,
        type: "New",
        loading: false,
        single: false,
        holiday: {
          type: "Planned Leave",
          staff: "",
          date: moment(),
          dateto: moment().add(1, "day"),
          remark: "",
        },
      });
    }
  }
  componentDidMount() {
    singleStaffHRStore.getHolidays();
  }

  render() {
    let hol = this.state.holiday;
    let staff = this.state.holiday.staff;

    let data = [
      { title: "Staff", data: staff.name },
      { title: "Role", data: staff.type },
      { title: "Type", data: hol.type },
      { title: "Days", data: hol.days },
      { title: "From", data: moment(hol.date).format("DD-MMM-YY") },
      { title: "To", data: moment(hol.dateto).format("DD-MMM-YY") },
      { title: "Remarks", data: hol.remarks },
    ];

    let datarows = data.map((dt, index) => (
      <FlexboxGrid key={index}>
        <Col md={6} sm={8} xs={12}>
          <b>{dt.title}: </b>
        </Col>
        <Col md={18} sm={16} xs={12}>
          {dt.data}
        </Col>
      </FlexboxGrid>
    ));
    let columnnames = [
      { title: "ID", field: "holidayNumId", defaultSort: "asc" },
      { title: "Status", field: "status" },
      { title: "Staff", field: "staff.name" },
      { title: "Role", field: "staff.type" },
      { title: "Type", field: "type" },
      {
        title: "From",
        field: "date",
        render: (row) => moment(row.date).format("DD-MMM-YY"),
      },
      {
        title: "To",
        field: "dateto",
        render: (row) => moment(row.dateto).format("DD-MMM-YY"),
      },
      { title: "Days", field: "days" },
      { title: "Remarks", field: "remark" },
    ];
    let columnnames2 = [
      { title: "ID", field: "holidayNumId", defaultSort: "asc" },
      { title: "Staff", field: "staff.name" },
      { title: "Role", field: "staff.type" },
      { title: "Type", field: "type" },
      { title: "Days", field: "days" },
      { title: "Remarks", field: "remark" },
    ];

    let headerdata = [
      {
        label: "Leaves Remaining",
        val: singleStaffHRStore.singlestaff.remaining,
        color: "green",
      },
      {
        label: "Leaves Taken",
        val:
          Parsed(singleStaffHRStore.plannedleave.days) +
          Parsed(singleStaffHRStore.unplannedleave.days),
        color: "red",
      },
      {
        label: "Sick Days Remaining",
        val: singleStaffHRStore.singlestaff.sickremaining,
        color: "green",
      },
      {
        label: "Sick Days Taken",
        val: singleStaffHRStore.sickleave.days,
        color: "red",
      },
    ];

    return (
      <LoadBack loading={singleStaffHRStore.loading.holidays}>
        <UnifiedModal
          show={this.state.showdel}
          title={`${hol.type}: ${staff.name}`}
          onHide={this.showHideModal}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes, Delete"]}
              oC1={() => this.showHideModal()}
              oC2={() => this.deleteHoliday(hol._id)}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h1>Are you sure you want to delete this leave?</h1>
          <h3>
            <b>Status: </b>
            {hol.status}
          </h3>
          {datarows}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showcal}
          title={`${hol.type}: ${staff.name}`}
          onHide={this.hideModal}
          footer={
            <Button color="red" onClick={() => this.hideModal()}>
              Close
            </Button>
          }
        >
          <h3>
            <b>Status: </b>
            {hol.status}
          </h3>
          {datarows}
        </UnifiedModal>
        <NewStaffHoliday
          hideModal={this.showHideModal}
          loading={this.state.loading}
          type={this.state.type}
          show={this.state.show}
          holiday={this.state.holiday}
          postNew={this.postNew}
          editVal={this.editVal}
        />
        <FlexboxGrid>
          {headerdata.map((h, index) => (
            <Col4 key={index}>
              <div
                style={{
                  boxShadow: "0px 0px 5px black",
                  borderRadius: "5px",
                  margin: "2%",
                  padding: "2%",
                  textAlign: "center",
                }}
              >
                <h6>{h.label}</h6>
                <h1 style={{ fontWeight: "bold", color: h.color }}>{h.val}</h1>
              </div>
            </Col4>
          ))}
        </FlexboxGrid>
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              block
              onClick={() => singleStaffHRStore.changeRejectedHolidays()}
              color={singleStaffHRStore.showrejectedholidays ? "red" : "green"}
              appearance="ghost"
              icon={
                <Icon
                  icon={
                    singleStaffHRStore.showrejectedholidays
                      ? "eye"
                      : "eye-slash"
                  }
                />
              }
            >
              {singleStaffHRStore.showrejectedholidays ? "Hide" : "Show"}{" "}
              Rejected Holidays
            </IconButton>
          </Col2>
        </FlexboxGrid>
        <Div14>Holiday Calendar</Div14>
        <HolidayCalendar
          start={"date"}
          end={"dateto"}
          title={"title"}
          data={singleStaffHRStore.allhols}
          onSelectEvent={this.onSelectEvent}
        />
        <MaterialTable
          title={singleStaffHRStore.allhols.length + " Holiday List"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={singleStaffHRStore.allhols}
          options={{
            rowStyle: (rowData) => {
              let color = "#feffab";
              if (rowData.status === "Approve") color = "#c2ffbd";
              if (rowData.status === "Reject") color = "#ffaea8";
              return {
                backgroundColor: color,
              };
            },
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: [10],
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : "Staff List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
        />
        <br />
        <Div14>Holiday Allowances</Div14>
        <MaterialTable
          title={
            singleStaffHRStore.holallowances.length + " Holiday Allowances"
          }
          columns={columnnames2}
          style={{ fontSize: 12 }}
          data={singleStaffHRStore.holallowances}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: [20],
            pageSizeOptions: [5, 10, 15, 20, 25, 100],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : "Staff List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(SingleStaffHoliday));
