import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol15white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol10white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol15blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol10blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableCol35blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "35%",
  },
  tableCol40blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
});

class DeliveryCharges extends Component {
  render() {
    let inv = this.props.inv;
    let allcharges = inv.charges.filter((chg) => chg.deliverys.length > 0);
    let dels = [];
    if (allcharges.length > 0) {
      for (let i = 0; i < allcharges.length; i++) {
        for (let j = 0; j < allcharges[i].deliverys.length; j++) {
          dels = [...dels, allcharges[i].deliverys[j]];
        }
      }
    }

    let charges = dels.map((del, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol10white}>{del.deliveryNumId}</Text>
        <Text style={styles.tableCol10white}>
          {moment(del.jobdate).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol15white}>{del.status}</Text>
        <Text style={styles.tableCol10white}>
          {PRKD(del.toaddress.length > 0 ? del.toaddress.length : del.dropoffs)}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(Parsed(del.charges.main) + Parsed(del.charges.cancel)) > 0
            ? PRKD(Parsed(del.charges.main) + Parsed(del.charges.cancel))
            : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(del.charges.location) > 0 ? PRKD(del.charges.location) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(del.charges.option) > 0 ? PRKD(del.charges.option) : "-"}
        </Text>
        <Text style={styles.tableCol10white}>
          {PRKD(Parsed(del.charges.additional) + Parsed(del.charges.discount)) >
          0
            ? PRKD(
                Parsed(del.charges.additional) - Parsed(del.charges.discount)
              )
            : "-"}
        </Text>
        <Text style={styles.tableCol15white}>
          {PRKD(del.charges.totaljob) > 0 ? PRKD(del.charges.totaljob) : "-"}
        </Text>
      </View>
    ));

    let totaldel = 0;
    let totaldrops = 0;
    let totalfinal = 0;
    let totalmain = 0;
    let totalloc = 0;
    let totalopt = 0;
    let totaladd = 0;

    for (let i = 0; i < dels.length; i++) {
      totaldel += 1;
      if (dels[i].toaddress.length > 0) {
        totaldrops += dels[i].toaddress.length;
      } else {
        totaldrops += Parsed(dels[i].dropoffs);
      }
      totalfinal += Parsed(dels[i].charges.totaljob);
      totalmain += Parsed(dels[i].charges.main);
      if (Parsed(dels[i].charges.cancel)) {
        totalmain += Parsed(dels[i].charges.cancel);
      }
      totalloc += Parsed(dels[i].charges.location);
      totalopt += Parsed(dels[i].charges.option);
      totaladd += Parsed(dels[i].charges.additional);
      if (Parsed(dels[i].charges.discount)) {
        totaladd -= Parsed(dels[i].charges.discount);
      }
    }

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>Detailed Delivery Charges</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol10blue}>Order ID</Text>
          <Text style={styles.tableCol10blue}>Date</Text>
          <Text style={styles.tableCol15blue}>Status</Text>
          <Text style={styles.tableCol10blue}>Dropoffs</Text>
          <Text style={styles.tableCol10blue}>Main Charge KD</Text>
          <Text style={styles.tableCol10blue}>Location Charge KD</Text>
          <Text style={styles.tableCol10blue}>Option Charge KD</Text>
          <Text style={styles.tableCol10blue}>Additional Charge KD</Text>
          <Text style={styles.tableCol15blue}>Job Charge KD</Text>
        </View>
        {charges}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol35blue}>{PRKD(totaldel)} Jobs</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totaldrops)}</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totalmain)}</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totalloc)}</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totalopt)}</Text>
          <Text style={styles.tableCol10blue}>{PRKD(totaladd)}</Text>
          <Text style={styles.tableCol15blue}>{PRKD(totalfinal)}</Text>
        </View>
      </View>
    );
  }
}

export default observer(DeliveryCharges);
