import { observer } from "mobx-react";
import React, { Component } from "react";
import SJNumbInput from "../../../../../../Components/Inputs/SJ/SJNumbInput";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import { FlexboxGrid, Col } from "rsuite";

import Col3 from "../../../../../../Components/Columns/Col3";
import SJTextInput from "../../../../../../Components/Inputs/SJ/SJTextInput";

import Col2 from "../../../../../../Components/Columns/Col2";
import SJSelectInput from "../../../../../../Components/Inputs/SJ/SJSelectInput";
import shippingmodetypes from "../../../../../../Static/Lists/jobsubtypes/cclists/shippingmodetypes";
import seatypes from "../../../../../../Static/Lists/jobsubtypes/imlists/seatypes";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { CCPriceCalc } from "../../../../../../Functions/Pricing/CCPriceCalc";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";

class CCPrice extends Component {
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;

    let load = this.props.load;
    let sj = sjStore.sjs[ind];
    let price = CCPriceCalc(sj);
    let addcharges = price[2];
    let quality = this.props.quality;

    return (
      <div>
        <Div14>
          Price ({PRKD(price[1])} KD
          {addcharges > 0 ? ` + ${addcharges} KD Additional` : ""})
        </Div14>
        <FlexboxGrid>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="lastprice"
              title="Price"
              load={load.lastprice}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="cbm"
              title="CBM"
              load={load.cbm}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              read={!editview}
            />
          </Col2>
        </FlexboxGrid>
        <br />

        <Div14>Shipment Details</Div14>
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24}>
            <SJSelectInput
              ind={ind}
              label="shippingmode"
              title="Shipping Mode"
              load={load.shippingmode}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={shippingmodetypes}
              sj="cc"
              read={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <SJSelectInput
              ind={ind}
              label="container"
              title="Container Type"
              load={load.container}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={seatypes}
              sj="cc"
              read={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <SJTextInput
              ind={ind}
              label="shippingline"
              title="Shipping Line"
              load={load.shippingline}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <SJTextInput
              ind={ind}
              label="blnumber"
              title="BL Number"
              load={load.blnumber}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <SJTextInput
              ind={ind}
              label="commodity"
              title="Commodity"
              load={load.commodity}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <SJTextInput
              ind={ind}
              label="brandsupplier"
              title="Brand / Supplier"
              load={load.brandsupplier}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <SJTextInput
              ind={ind}
              label="originlocation"
              title="Origin Location"
              load={load.originlocation}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col>
        </FlexboxGrid>
        <br />
        <Div14>Container Info</Div14>
        <FlexboxGrid>
          <Col3>
            <SJNumbInput
              ind={ind}
              label="containercount"
              title="# of Containers"
              load={load.containercount}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col3>
          <Col3>
            <SJNumbInput
              ind={ind}
              label="packages"
              title="Packages"
              load={load.packages}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="cc"
              read={!editview}
            />
          </Col3>
          <Col3>
            <SJNumbInput
              ind={ind}
              label="weight"
              title="Weight (KG)"
              load={load.weight}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              sj="cc"
              read={!editview}
            />
          </Col3>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default observer(CCPrice);
