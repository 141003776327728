import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";
import HeaderFooterPDF from "../../Template/HeaderFooterPDF";
import HeaderSection from "./HeaderSection";

class StorageOrderPDF extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", loading: false };
    this.editVal = this.editVal.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val, loading: false });
  }

  render() {
    let sto = this.props.sto;
    let client = this.props.client;
    let type = this.props.type;
    let showdimen = this.props.showdimen;
    console.log(showdimen);
    return (
      <div>
        <hr />
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Items Checklist ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Items Checklist ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection
                sto={sto}
                client={client}
                type={type}
                showdimen={showdimen}
              />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(StorageOrderPDF);
