import { AddChargeCalc } from "./AddChargeCalc";
import { Parsed } from "./PRKDCalc";

function IMPriceCalc(sj) {
  let final = 0;

  let addcharge = AddChargeCalc(sj.addcharge);

  let section2 = 0;
  let section3 = 0;
  let section4 = 0;
  let section5 = 0;

  if (sj.im.direction === "Inbound") {
    final += Parsed(sj.im.cpunpack);

    final += Parsed(sj.im.cpintrans);
    final += Parsed(sj.im.cpimport);
    final += Parsed(sj.im.cpministry);
    final += Parsed(sj.im.cpinspect);

    section2 += Parsed(sj.im.choriginship);
    section2 += Parsed(sj.im.chinsurance);
    section2 += Parsed(sj.im.chothersec2);

    section3 += Parsed(sj.im.chship);

    section4 += Parsed(sj.im.chimport);
    section4 += Parsed(sj.im.chtransport);
    section4 += Parsed(sj.im.chcustom);
    section4 += Parsed(sj.im.chdthc);
    section4 += Parsed(sj.im.chpai);
    section4 += Parsed(sj.im.chepa);
    section4 += Parsed(sj.im.chbayan);
    section4 += Parsed(sj.im.chclearance);
    section4 += Parsed(sj.im.chrelease);
    section4 += Parsed(sj.im.chdemurrage);
    section4 += Parsed(sj.im.chdetention);
    section4 += Parsed(sj.im.chport);
    section4 += Parsed(sj.im.chothersec4);
  } else if (sj.im.direction === "Outbound") {
    final += Parsed(sj.im.cppack);
    final += Parsed(sj.im.cpouttrans);
    final += Parsed(sj.im.cpexport);
    final += Parsed(sj.im.cpship);
    final += Parsed(sj.im.cpdest);

    section2 += Parsed(sj.im.chtransport);
    section2 += Parsed(sj.im.chclearance);
    section2 += Parsed(sj.im.chbayan);
    section2 += Parsed(sj.im.chinsurance);
    section2 += Parsed(sj.im.chothersec2);

    section3 += Parsed(sj.im.chship);
    section3 += Parsed(sj.im.chdthc);

    section4 += Parsed(sj.im.chdelord);
    section4 += Parsed(sj.im.chterhand);
    section4 += Parsed(sj.im.chdestclear);
    section4 += Parsed(sj.im.chshuttle);
    section4 += Parsed(sj.im.chdemurrage);
    section4 += Parsed(sj.im.chdetention);
    section4 += Parsed(sj.im.chdeststore);
    section4 += Parsed(sj.im.chassembly);
    section4 += Parsed(sj.im.chcontreturn);
    section4 += Parsed(sj.im.chothersec4);
  }

  let profit = Parsed(final);
  profit += Parsed(addcharge);
  profit -= Parsed(section2);
  profit -= Parsed(section3);
  profit -= Parsed(section4);
  if (sj.im.commodity === "Dangerous Goods") {
    section5 += Parsed(sj.im.danggoodch);
    section5 += Parsed(sj.im.danghandch);
    profit -= Parsed(section5);
  }

  let withAddChg = final + addcharge;
  return [
    withAddChg, // 0
    final, // 1
    addcharge, // 2
    section2, // 3
    section3, // 4
    section4, // 5
    section5, // 6
    profit, // 7
  ];
}

export { IMPriceCalc };
