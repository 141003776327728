import { observer } from "mobx-react";
import React, { Component } from "react";
import { Icon, IconButton, HelpBlock, FlexboxGrid, Notification } from "rsuite";
import { PageHeader, message } from "antd";
import singleJobStore from "../../../Stores/SingleJobStore";
import userStore from "../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import sjStore from "../../../Stores/SideJobs/SJStore";
import invoiceStore from "../../../Stores/SideJobs/InvoiceStore";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import paymentStore from "../../../Stores/SideJobs/PaymentStore";
import { SJPriceCalc } from "../../../Functions/Pricing/SJPriceCalc";
import Col2 from "../../../Components/Columns/Col2";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class JobHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadmainjob: false,
      loadfocalpoint: false,
      show: false,
      shareemail: false,
      loading: false,
      email: "",
    };
    this.updateJob = this.updateJob.bind(this);
    this.editVal = this.editVal.bind(this);
    this.editJob = this.editJob.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
  }

  editVal(val, label) {
    this.setState({ email: val });
  }
  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({ show: false, shareemail: false });
    }
  }

  editJob(type, val) {
    singleJobStore.editEditJob(type, val);
  }

  emailClient = async () => {
    this.setState({ loading: true });
    let data = { email: this.state.email };

    await instance
      .put(`job/basic/emailjobtoclient/${singleJobStore.job._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ shareemail: false, email: "", loading: false });
        Notification["success"]({ title: `Email Sent` });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  updateJob = async (label) => {
    if (label === "focalpoint") {
      this.setState({ loadfocalpoint: true, loading: true });
    }

    let data = {};
    data[label] = singleJobStore.editjob[label];

    await instance
      .put(`job/basic/edit/${singleJobStore.job._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleJobStore.editMainJob(label, singleJobStore.editjob[label]);
        this.setState({
          loadmainjob: false,
          loadfocalpoint: false,
          show: false,
          loading: false,
        });
        message.success(`${label} Updated.`);
      })
      .catch((err) => {
        this.setState({ loadmainjob: false, loadfocalpoint: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let disablecontrol = false;
    let quality = this.props.quality;
    let job = singleJobStore.job;
    let focalpoint = "";

    if (!singleJobStore.loading) {
      if (job.focalpoint) {
        if (userStore.user._id === job.focalpoint._id) disablecontrol = true;

        if (job.focalpoint.username.length > 0) {
          focalpoint =
            job.focalpoint.username.charAt(0).toUpperCase() +
            job.focalpoint.username.slice(1);
        }
      }
    }

    let headerButtons = [
      <IconButton
        size="sm"
        appearance="ghost"
        icon={<Icon icon="refresh" />}
        onClick={() => {
          singleJobStore.getJob(singleJobStore.job._id);
          sjStore.getSJs(singleJobStore.job._id, true);
        }}
      >
        Refresh Data
      </IconButton>,
    ];

    if (userStore.user.jobs) {
      headerButtons = [
        ...headerButtons,
        <IconButton
          size="sm"
          appearance={quality ? "primary" : "ghost"}
          icon={<Icon icon="check" />}
          color="red"
          onClick={() => userStore.changeQuality()}
        >
          Quality
        </IconButton>,
      ];
    }

    if (userStore.user.group === "EMStaff") {
      if (!disablecontrol) {
        headerButtons.push(
          <IconButton
            size="sm"
            appearance="ghost"
            icon={<Icon icon="user" />}
            onClick={() => {
              this.setState({ show: true });
            }}
          >
            {disablecontrol ? "Assigned to You" : "Take Control"}
          </IconButton>
        );
      }
    }
    if (userStore.user.jobs) {
      headerButtons = [
        ...headerButtons,
        <IconButton
          size="sm"
          appearance="ghost"
          icon={<Icon icon="envelope" />}
          color="green"
          onClick={() => this.setState({ shareemail: true, email: "" })}
        >
          Email Job to Client
        </IconButton>,
      ];
    }

    let totalprice = SJPriceCalc(
      sjStore.sjs.filter((sj) => sj.status !== "Cancelled")
    );
    let invoices = invoiceStore.invoices.filter(
      (inv) => inv.status !== "Cancelled"
    );
    let totalinv = 0;
    for (let i = 0; i < invoices.length; i++) {
      totalinv += parseFloat(invoices[i].total);
    }
    let payments = paymentStore.payments.filter((pay) => pay.type === "Income");
    let totalpay = 0;
    for (let i = 0; i < payments.length; i++) {
      totalpay += parseFloat(payments[i].amount);
    }
    let diff1 = PRKD(parseFloat(totalprice) - parseFloat(totalinv));
    let diff2 = PRKD(parseFloat(totalinv) - parseFloat(totalpay));

    let emailValidation = validateEmail(this.state.email);

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          width={"250px"}
          title="Assign Job"
          onHide={this.showHideModal}
          footer={
            <div>
              <IconButton
                icon={<Icon icon="close" />}
                block
                onClick={() => this.setState({ show: false })}
                loading={this.state.loading}
              >
                No, Cancel
              </IconButton>
              <IconButton
                icon={<Icon icon="check" />}
                color="green"
                loading={this.state.loading}
                block
                onClick={() => {
                  this.editJob("focalpoint", userStore.user);
                  this.updateJob("focalpoint", userStore.user);
                }}
              >
                Take Control
              </IconButton>
            </div>
          }
        >
          Are you sure you want to take the Job?
        </UnifiedModal>
        <UnifiedModal
          show={this.state.shareemail}
          title="Share Job By Email"
          onHide={this.showHideModal}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  icon={<Icon icon="close" />}
                  block
                  onClick={() => this.setState({ show: false })}
                  loading={this.state.loading}
                >
                  No, Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="check" />}
                  color="green"
                  loading={this.state.loading}
                  block
                  onClick={() => this.emailClient()}
                >
                  Email Client
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          Email the job link to a Client:
          <TextInputSTD
            title="Client Email"
            editVal={this.editVal}
            val={this.state.email}
            label="email"
          />
          {!emailValidation && (
            <HelpBlock style={{ color: "red", fontStyle: "italic" }}>
              Please enter a valid email.
            </HelpBlock>
          )}
        </UnifiedModal>
        <PageHeader
          className="site-page-header"
          title={
            <div>
              <FlexboxGrid align="middle">
                Job {singleJobStore.job.jobNumId}
                <span style={{ width: "5px" }} />
              </FlexboxGrid>
              <HelpBlock>
                Client {singleJobStore.client.clientNumId}{" "}
                {singleJobStore.client.name} - Focal Point = {focalpoint}
              </HelpBlock>
              <HelpBlock>
                Total Job ({PRKD(totalprice)} KD) - Invoiced ({PRKD(totalinv)}{" "}
                KD) - Total Payments ({PRKD(totalpay)} KD)
              </HelpBlock>
              <HelpBlock>
                {userStore.user.jobs && (
                  <FlexboxGrid>
                    <div style={{ color: diff1 !== 0 && "red" }}>
                      {diff1 > 0
                        ? `${diff1} KD Not Invoiced`
                        : diff1 < 0
                        ? `${diff1} KD Over Invoiced`
                        : "Invoiced Correctly"}{" "}
                    </div>
                    <div style={{ width: "10px" }}> </div>
                    <div style={{ width: "10px" }}> / </div>
                    <div style={{ width: "10px" }}> </div>
                    <div style={{ color: diff2 !== 0 && "red" }}>
                      {diff2 > 0
                        ? `${diff2} KD Remaining`
                        : diff2 < 0
                        ? `${diff2} KD Overpaid`
                        : "Balance Settled"}
                    </div>
                  </FlexboxGrid>
                )}
              </HelpBlock>
            </div>
          }
          extra={headerButtons}
        />
      </div>
    );
  }
}

export default observer(JobHeader);
