import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, Icon, Col, IconButton, Button } from "rsuite";
import { Image, Modal } from "antd";
import userStore from "../../../../../Stores/UserStore";

class AttachmentModal extends Component {
  render() {
    //PROPS
    // hideModal
    // changeDelete
    // deleteDocument
    let editview = this.props.editview;
    let show = this.props.show;
    let attachment = this.props.attachment;
    let deleteactive = this.props.deleteactive;

    let attlink;
    let documenttype;
    let viewtype = "iframe";
    let finalview;
    if (attachment !== null) {
      attlink = attachment.attachment;
      documenttype = attlink.substring(attlink.length - 3, attlink.length);

      if (documenttype === "jpg" || documenttype === "png") {
        viewtype = "picture";
        finalview = (
          <Image src={`${attlink}?alt=media`} width={"auto"} height={"auto"} />
        );
      }
    }

    let allowEdit = userStore.user.jobs & editview;

    return (
      <div>
        <Modal
          width={"90%"}
          visible={show}
          onCancel={this.props.hideModal}
          title={
            attachment !== null &&
            `Attachment ${attachment.attachmentNumId}: ${attachment.title}`
          }
          footer={
            deleteactive ? (
              <FlexboxGrid>
                <div>
                  <h6>Are you sure you want to Delete this document?</h6>
                  <Col xs={12}>
                    <Button
                      block
                      color="green"
                      appearance="ghost"
                      onClick={() => {
                        this.props.changeDelete(false);
                      }}
                    >
                      No Cancel
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Button
                      block
                      color="red"
                      onClick={() => {
                        this.props.deleteDocument();
                        this.props.changeDelete(false);
                      }}
                    >
                      Yes Delete
                    </Button>
                  </Col>
                </div>
              </FlexboxGrid>
            ) : (
              false
            )
          }
        >
          {attachment !== null && (
            <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
              {viewtype === "picture" ? (
                finalview
              ) : (
                <iframe
                  src={`${attachment.attachment}?alt=media`}
                  title={`${attachment.attachment}`}
                  height={500}
                  width={"100%"}
                  style={{ zoom: 0.5 }}
                ></iframe>
              )}
            </div>
          )}
          {allowEdit ? (
            <IconButton
              icon={<Icon icon="trash" />}
              size="sm"
              onClick={() => this.props.changeDelete(true)}
              circle
              color="red"
              disabled={deleteactive}
              style={{ position: "absolute", bottom: 0, right: 0 }}
            />
          ) : (
            <div />
          )}
        </Modal>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(AttachmentModal));
