import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmCompanyStore from "../WHMStores/WHMCompanyStore";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";
import Div14 from "../../../Components/Dividers/Div14";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import moment from "moment";
import MaterialTable from "material-table";
import { Statistic, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import Col4 from "../../../Components/Columns/Col4";

class WHMCompanyMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Pallet Summary" };
  }
  componentDidMount() {
    whmCompanyStore.getCompanies();
  }

  render() {
    let data = whmCompanyStore.remainingcomps;
    if (this.state.tab === "All Companies") {
      data = whmCompanyStore.companies;
    }
    let tableTitle = `${this.state.tab} - WH Accounts`;
    let columnnames = [
      {
        title: "ID",
        field: "clientNumId",
        removable: true,
      },
      { title: "Name", field: "name", removable: true },
      {
        title: "CBM",
        field: "totalreqpalletizedcbm",
        hidden: this.state.tab === "Pallet Summary",
        render: (rowData) => {
          return PRKD(rowData.totalreqpalletizedcbm);
        },
      },
      {
        title: "QTY",
        field: "totalreqpalletized",
        hidden: this.state.tab === "Pallet Summary",
      },
      {
        title: "Palletized",
        field: "totalpalletized",
        hidden: this.state.tab === "Pallet Summary",
      },
      {
        title: "Remaining",
        field: "remainingunpalletized",
        defaultSort: "desc",
        hidden: this.state.tab === "Pallet Summary",
      },
      {
        title: "All Pallets",
        field: "pallets.length",
        hidden: this.state.tab === "Remaining QTY",
      },
      {
        title: "Prepared",
        field: "pallets",
        hidden: this.state.tab === "Remaining QTY",
        removable: true,
        render: (rowData) => {
          return rowData.pallets.filter((pl) => pl.status === "Prepared")
            .length;
        },
      },
      {
        title: "Loaded",
        field: "pallets",
        hidden: this.state.tab === "Remaining QTY",
        removable: true,
        render: (rowData) => {
          return rowData.pallets.filter((pl) => pl.status === "Loaded").length;
        },
      },
      {
        title: "Unloaded",
        field: "pallets",
        hidden: this.state.tab === "Remaining QTY",
        removable: true,
        render: (rowData) => {
          return rowData.pallets.filter((pl) => pl.status === "Unloaded")
            .length;
        },
      },
      {
        title: "Stacked",
        field: "pallets",
        hidden: this.state.tab === "Remaining QTY",
        removable: true,
        render: (rowData) => {
          return rowData.pallets.filter((pl) => pl.status === "Stacked").length;
        },
      },
      {
        title: "STO Upd.",
        field: "palletizationupdate",

        render: (rowData) =>
          rowData.palletizationupdate
            ? moment(rowData.palletizationupdate).fromNow()
            : "",
      },
    ];

    return (
      <LoadBack loading={whmCompanyStore.loading.companies}>
        <br />
        <Div14>
          Company List <Divider vertical />{" "}
          <IconButton
            color="orange"
            icon={<Icon icon="refresh" />}
            onClick={() => whmCompanyStore.getCompanies(true)}
            circle
            size="xs"
          />
        </Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Pallet Summary"} key={"Pallet Summary"} />
          <TabPane tab={"Remaining QTY"} key={"Remaining QTY"} />
          <TabPane tab={"All Companies"} key={"All Companies"} />
        </Tabs>
        <FlexboxGrid>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Companies Remaining"
                value={whmCompanyStore.remainingcomps.length}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Items Not Palletized"
                value={whmCompanyStore.companypalletstats.remainingitems}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Pallets Remaining"
                value={whmCompanyStore.companypalletstats.remainingpallets}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Pallets Complete"
                value={whmCompanyStore.companypalletstats.completedpallets}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <MaterialTable
          isLoading={whmCompanyStore.loading.companies}
          title={data.length + " " + tableTitle}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 20,
            pageSizeOptions: [20, 50],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : tableTitle
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          onRowClick={async (event, rowData, togglePanel) =>
            this.props.history.push(`/whm/company/${rowData._id}`)
          }
          actions={[
            {
              icon: "business",
              tooltip: "Open Client",
              onClick: (event, rowData) =>
                this.props.history.push(`/whm/company/${rowData._id}`),
            },
          ]}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMCompanyMain));
