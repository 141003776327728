import React, { Component } from "react";

import { observer } from "mobx-react";
import { withRouter } from "react-router";
import {
  IconButton,
  Icon,
  ButtonToolbar,
  FlexboxGrid,
  Notification,
} from "rsuite";
import colors from "../../Static/Brand/colors";
import logo from "../../Static/Images/easymoveicon.png";
import userStore from "../../Stores/UserStore";
import TextInputSTD from "../Inputs/STD/TextInputSTD";
import Col2 from "../Columns/Col2";
import Col4 from "../Columns/Col4";
import TextAreaInputSTD from "../Inputs/STD/TextAreaInputSTD";
import technology from "../../Static/Images/banners/technology.png";
import axios from "axios";
import baseURL from "../../Static/baseURL/baseURL";
import LoadBack from "../Loaders/LoadBack";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class FooterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      loading: false,
      sent: false,
      show: false,
    };
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  updateVal = async () => {
    this.setState({ loading: true });
    let data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      type: "Guest Support",
    };
    this.setState({ loading: false });
    return instance
      .post(`/track/feedback/customersupport`, data)
      .then((res) => {
        this.setState({ sent: true, loading: false });
        Notification["success"]({
          title: `Message Sent to Easy Move Staff.`,
        });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  render() {
    let signup;
    let disabled = !validateEmail(this.state.email);
    if (this.state.name === "") disabled = true;
    if (this.state.email === "") disabled = true;
    if (this.state.message === "") disabled = true;

    signup = (
      <LoadBack loading={this.state.loading}>
        <br />

        <div style={{ background: `linear-gradient(45deg, #0F2027, #203A43)` }}>
          <br />
          <h4>Contact Us</h4>
          <FlexboxGrid justify="center">
            <Col2>
              <TextInputSTD
                title="Name"
                val={this.state.name}
                label="name"
                size="large"
                editVal={this.editVal}
              />
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid justify="center">
            <Col2>
              <TextInputSTD
                title="Email"
                val={this.state.email}
                label="email"
                size="large"
                editVal={this.editVal}
              />
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid justify="center">
            <Col2>
              <TextInputSTD
                title="Phone (optional)"
                val={this.state.phone}
                label="phone"
                size="large"
                editVal={this.editVal}
              />
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid justify="center">
            <Col2>
              <TextAreaInputSTD
                title="Message"
                val={this.state.message}
                label="message"
                size="large"
                editVal={this.editVal}
              />
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid justify="center">
            <Col4>
              <IconButton
                icon={<Icon icon="send" />}
                block
                color="green"
                onClick={this.updateVal}
                disabled={disabled}
                loading={this.state.loading}
              >
                Submit Details
              </IconButton>
            </Col4>
            <br />
          </FlexboxGrid>
          <br />
          <div>
            <br />
            <img
              src={technology}
              style={{ maxHeight: 200, maxWidth: "100%" }}
              alt="tehnology graphics"
            />
            <br />
          </div>
        </div>
      </LoadBack>
    );
    if (this.state.sent) {
      signup = (
        <div style={{ background: `linear-gradient(45deg, #0F2027, #203A43)` }}>
          <br />
          <h1>Thank you for contacting Easy Move</h1>
          <h3>Our team will reach out to you shortly.</h3>
          <img
            src={logo}
            height={300}
            width={300}
            alt="Easy Move Logistics Kuwait Logo"
          />
          <br />
          <div>
            <br />
            <img
              src={technology}
              style={{ maxHeight: 200, maxWidth: "100%" }}
              alt="tehnology graphics"
            />
            <br />
          </div>
        </div>
      );
    }
    return (
      <div>
        <br />
        <div
          style={{
            backgroundColor: colors.primcol,
            minHeight: 300,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            bottom: "-50px",
          }}
        >
          <br />
          <h3 style={{ color: "whitesmoke" }}>Get In Touch</h3>
          <br />
          <ButtonToolbar>
            <IconButton
              size="lg"
              circle
              color="green"
              icon={<Icon icon="whatsapp" size="lg" />}
              onClick={() =>
                (window.location.href = `https://wa.me/96522060969`)
              }
            />
            <IconButton
              size="lg"
              circle
              color="red"
              icon={<Icon icon="phone" size="lg" />}
              onClick={() => (window.location.href = `tel:+965-22060969`)}
            />
            <IconButton
              size="lg"
              circle
              color="red"
              icon={<Icon icon="instagram" size="lg" />}
              onClick={() =>
                window.open(`https://www.instagram.com/${"emsupplychain"}`)
              }
            />
            <IconButton
              size="lg"
              circle
              color="red"
              icon={<Icon size="lg" icon="envelope" />}
              onClick={() =>
                (window.location.href = `mailto:info@emsupplychain.com`)
              }
            />
            <IconButton
              size="lg"
              color="red"
              circle
              icon={<Icon size="lg" icon="map" />}
              onClick={() =>
                window.open(`https://goo.gl/maps/4iPVyES59AxT3TTc7`)
              }
            />
          </ButtonToolbar>
          <br />

          {!userStore.signedIn && signup}
          <br />
          <p style={{ fontSize: 20, fontStyle: "italic" }}>
            {`Easy Move Logistics © ${new Date().getFullYear()}.`}{" "}
          </p>

          <div style={{ backgroundColor: "white", height: "100px" }}>
            <img src={logo} style={{ height: "100px" }} alt="Easy Move Logo" />
          </div>
          <p style={{ fontSize: 11, color: colors.greycol }}>
            <i onClick={() => this.props.history.push("/policy/privacypolicy")}>
              Privacy Policy
            </i>{" "}
            -{" "}
            <i onClick={() => this.props.history.push("/policy/cookiespolicy")}>
              Cookie Policy
            </i>{" "}
            -{" "}
            <i
              onClick={() =>
                this.props.history.push("/policy/termsandservices")
              }
            >
              Terms and Conditions
            </i>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(FooterPage));
