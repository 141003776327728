import React, { Component } from "react";
import { FlexboxGrid, Col } from "rsuite";

import { Collapse } from "antd";
import { observer } from "mobx-react";
import paymentStore from "../../../../../Stores/SideJobs/PaymentStore";
import invoiceStore from "../../../../../Stores/SideJobs/InvoiceStore";
import sjStore from "../../../../../Stores/SideJobs/SJStore";
import { SJPriceCalc } from "../../../../../Functions/Pricing/SJPriceCalc";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import moment from "moment";
const { Panel } = Collapse;

class PaymentSummary extends Component {
  render() {
    let payments = paymentStore.payments;
    let invoices = invoiceStore.invoices.filter(
      (inv) => inv.status !== "Cancelled"
    );

    let sjs = sjStore.sjs.filter((sj) => sj.status !== "Cancelled");

    let totalprice = SJPriceCalc(sjs);

    let totalinv = 0;
    for (let i = 0; i < invoices.length; i++) {
      totalinv += parseFloat(invoices[i].total);
    }

    let totalpay = 0;
    for (let i = 0; i < payments.length; i++) {
      totalpay += parseFloat(payments[i].amount);
    }
    let diff1 = PRKD(parseFloat(totalprice) - parseFloat(totalinv));
    let diff2 = PRKD(parseFloat(totalinv) - parseFloat(totalpay));

    let paymentrows = payments.map((pay, index) => (
      <div key={index}>
        ID# {pay.paymentNumId}) {pay.amount} by {pay.method} on{" "}
        {moment(pay.date).format("DD-MMM-YY")}
      </div>
    ));

    let invoicerows = invoices.map((inv, index) => (
      <div key={index}>
        ID# {inv.invoiceNumId}-{moment(inv.createdAt).format("YYMMDDHH")}){" "}
        {inv.total} for{" "}
        {inv.subJobs.map(
          (sj, sjindex) =>
            `${sj.jobtype}-${sj.numId}${
              inv.subJobs.length === sjindex + 2
                ? " and "
                : inv.subJobs.length === sjindex + 1
                ? ""
                : ", "
            }`
        )}{" "}
        on {moment(inv.date).format("DD-MMM-YY")}
      </div>
    ));

    let sjpricerows = sjs.map((sj, index) => (
      <div key={index}>
        ID# {sj.jobtype}-{sj.sjNumId} for {SJPriceCalc([sj])} KD
      </div>
    ));

    return (
      <div>
        <Collapse>
          <Panel
            header={
              <>
                <b>
                  Payment Summary: Job ({totalprice} KD) - Invoice (
                  {PRKD(totalinv)} KD
                  {diff1 > 0
                    ? " Under Invoiced"
                    : diff1 < 0
                    ? " Over Invoiced"
                    : ""}
                  ) - Paid ({PRKD(totalpay)} KD
                  {diff2 > 0 ? " Under Paid" : diff1 < 0 ? " Over Paid" : ""})
                </b>
                <br />
              </>
            }
            key="1"
          >
            <div style={{ fontSize: 12 }}>
              <FlexboxGrid justify="space-around" align="top">
                <Col md={8} sm={12} xs={24}>
                  <p>
                    {" "}
                    <b>
                      {sjs.length} Sub Jobs ({PRKD(totalprice)} KD)
                    </b>
                  </p>
                  {sjpricerows}
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <p>
                    {" "}
                    <b>
                      {invoices.length} Invoices ({PRKD(totalinv)} KD)
                    </b>
                  </p>
                  {invoicerows}
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <p>
                    <b>
                      {payments.length} Payments ({PRKD(totalpay)} KD)
                    </b>
                  </p>
                  {paymentrows}
                </Col>
              </FlexboxGrid>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default observer(PaymentSummary);
