import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import { Descriptions } from "antd";
import newStorageStore from "../../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import {
  Parsed,
  PRKD,
} from "../../../../../../../../Functions/Pricing/PRKDCalc";
import GroupedItemList from "./GroupedItemList";

class ChargesTable extends Component {
  render() {
    let order = newStorageStore.neworder;

    let account = newStorageStore.account;
    let timechg = newStorageStore.timechgs;
    let orderstat = newStorageStore.orderstat;
    let totalcbm = orderstat.totalcbm;

    let greycolor = "#cccccc";
    let lightcolor = "#ebebeb";
    let options = order.orderoptions;
    let addchgs = order.addcharge;
    let allcharges = timechg.allcharges;
    let loccharges = timechg.loccharges;
    let chgmap;
    let locmap;
    let optmap;
    let addmap;

    if (allcharges.length > 0) {
      chgmap = allcharges.map((chg, index) => (
        <Descriptions.Item
          label={`${
            chg.unit === "KD / CBM"
              ? PRKD(Parsed(chg.value) * Parsed(totalcbm))
              : PRKD(chg.value)
          } KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title} {chg.unit === "KD" ? "" : `(${PRKD(chg.value)} KD/CBM)`}
        </Descriptions.Item>
      ));
    }
    if (loccharges.length > 0) {
      locmap = loccharges.map((chg, index) => (
        <Descriptions.Item
          label={`${PRKD(chg.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title}
        </Descriptions.Item>
      ));
    }

    if (options.length > 0) {
      optmap = options.map((opt, index) => (
        <Descriptions.Item
          label={
            opt.type === "KD"
              ? `${PRKD(opt.price)} KD`
              : `${PRKD(Parsed(opt.price) * Parsed(totalcbm))} KD`
          }
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {opt.title} {opt.type === "KD" ? "" : `(${PRKD(opt.price)} KD/CBM)`}
        </Descriptions.Item>
      ));
    }
    if (addchgs.length > 0) {
      addmap = addchgs.map((add, index) => (
        <Descriptions.Item
          label={`${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {add.name}
        </Descriptions.Item>
      ));
    }

    return (
      <div>
        <Descriptions title="Order Summary" bordered size="small">
          <Descriptions.Item label="Type"> {order.jobtype} </Descriptions.Item>
          <Descriptions.Item label="Status">{order.status}</Descriptions.Item>
          <Descriptions.Item label="Client">
            {account.clientNumId} - {account.name}
          </Descriptions.Item>
          <Descriptions.Item label="Total CBM">
            {PRKD(totalcbm)} CBM
          </Descriptions.Item>
          <Descriptions.Item label="Item Count">
            {PRKD(orderstat.itemcount)} items
          </Descriptions.Item>
          <Descriptions.Item label="Package Count">
            {PRKD(orderstat.packagecount)} packages
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Job Cost"
            span={24}
            labelStyle={{ fontWeight: "bold" }}
            contentStyle={{ fontWeight: "bold" }}
          >
            {PRKD(timechg.finaljobcost)} KD
          </Descriptions.Item>
        </Descriptions>
        <hr />
        {newStorageStore.delstoinout.delsto === "Delivery" ? (
          order.toaddress.map((loc, index) => (
            <>
              <Descriptions
                key={index}
                title={`${
                  newStorageStore.delstoinout.inout === "In"
                    ? "Pickup"
                    : "Delivery"
                } Address`}
                bordered
                size="small"
              >
                <Descriptions.Item label="Address" span={24}>
                  {loc.address}
                </Descriptions.Item>
                <Descriptions.Item label="Name">{loc.name}</Descriptions.Item>
                <Descriptions.Item label="Phone">{loc.phone}</Descriptions.Item>
                <Descriptions.Item label="Type">{loc.type}</Descriptions.Item>
                <Descriptions.Item label="City">{loc.city}</Descriptions.Item>
                <Descriptions.Item label="Floor">{loc.floor}</Descriptions.Item>
              </Descriptions>
              <hr />
            </>
          ))
        ) : (
          <></>
        )}
        <Descriptions title="Cost Breakdown" bordered size="small">
          <Descriptions.Item
            label={`${PRKD(timechg.finaljobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
          <Descriptions.Item
            label={`${PRKD(timechg.mainjobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: lightcolor,
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: lightcolor }}
          >
            Main Service
          </Descriptions.Item>
          {chgmap}
          {timechg.totalloccharge > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totalloccharge)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Delivery Location Charges
              </Descriptions.Item>
              {locmap}
            </>
          )}
          {timechg.totalopts > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totalopts)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Selected Options
              </Descriptions.Item>
              {optmap}
            </>
          )}
          {timechg.totaladdchg > 0 && (
            <>
              <Descriptions.Item
                label={`${PRKD(timechg.totaladdchg)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: lightcolor,
                }}
              >
                Additional Charges
              </Descriptions.Item>
              {addmap}
            </>
          )}
          <Descriptions.Item
            label={`${PRKD(timechg.finaljobcost)} KD`}
            span={24}
            labelStyle={{
              fontWeight: "bold",
              backgroundColor: greycolor,
              width: "30%",
              textAlign: "right",
            }}
            contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
          >
            Total Job Cost
          </Descriptions.Item>
        </Descriptions>
        <hr />
        <Div14>Items List</Div14>
        <GroupedItemList
          hideaction
          acitemmove={newStorageStore.selecteditems}
        />
      </div>
    );
  }
}

export default observer(ChargesTable);
