import { observer } from "mobx-react";
import React, { Component } from "react";
import { Tabs } from "antd";
import { withRouter } from "react-router";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";

import TimelineComp from "../../../../Components/Timeline/TimelineComp";
import Div14 from "../../../../Components/Dividers/Div14";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";
import OrderSummaryTable from "./Supporting/OrderSummaryTable";
import LocationSummaryTable from "./Supporting/LocationSummaryTable";
import CostSummaryTable from "./Supporting/CostSummaryTable";
import ServicesSummaryTable from "./Supporting/ServicesSummaryTable";
import ItemsSummaryTable from "./Supporting/ItemsSummaryTable";
import { Loader } from "rsuite";

const { TabPane } = Tabs;

class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Summary" };
  }

  render() {
    let del = modalACOrderStore.del;
    let timechg = modalACOrderStore.timechgs;

    let allsteps = [];
    let step = 2;
    allsteps = ["Requested", "Booked"];
    if (del.status === "Requested") step = 0;
    if (del.status === "Booked") step = 1;

    if (del.status === "In Transit") {
      allsteps = [...allsteps, "In Transit", "Delivered"];
    } else {
      allsteps = [...allsteps, "Delivered"];
    }
    let content = <Loader center />;
    if (!modalACOrderStore.loading.del) {
      content = (
        <>
          <TimelineComp steps={allsteps} step={step} />
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={"home"}
            tabPosition={"top"}
            centered
            type="card"
            onChange={(e) => this.setState({ tab: e })}
          >
            <TabPane tab={`Summary`} key="Summary">
              <Div14>Order Summary Table</Div14>
              <OrderSummaryTable
                del={del}
                timechg={timechg}
                account={del.client}
              />
            </TabPane>

            <TabPane tab={`Locations`} key="Locations">
              <LocationSummaryTable del={del} />
            </TabPane>

            {del.maintype === "Fixed Per Type" ? (
              <TabPane tab={`Service`} key="Service">
                <ServicesSummaryTable del={del} />
              </TabPane>
            ) : (
              <TabPane tab={`Items`} key="Items">
                {del.toaddress.map((to, index) => (
                  <ItemsSummaryTable to={to} key={index} />
                ))}
              </TabPane>
            )}
            <TabPane tab={`Costs`} key="Costs">
              <CostSummaryTable
                del={del}
                timechg={timechg}
                account={del.client}
              />
            </TabPane>
          </Tabs>
        </>
      );
    }
    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          onHide={() => {
            this.setState({ tab: "Summary" });
            this.props.onHide();
          }}
          title={`Delivery Request #${del.deliveryNumId} for ${del.client.name}`}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Go To Orders"]}
              oC1={() => {
                this.setState({ tab: "Summary" });
                this.props.onHide();
              }}
              oC2={() => {
                this.props.history.push(`/delorder/${del._id}`);
              }}
            />
          }
        >
          {content}
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(OrderModal));
