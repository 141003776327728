import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import completeStorStore from "../../../../../Stores/AccountUpdated/Storage/CompleteStorStore";
import carStore from "../../../../../Stores/AdditionalStores/CarStore";
import SignatureWrapperStor from "./SignatureWrapperStor";
import { withRouter } from "react-router";

import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class CloseStorInOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signature: {
        sig: null,
        type: "",
        name: "",
        email: "",
        remark: "",
        _id: "",
        trucktrack: "No Tracking",
        loading: false,
        changed: false,
        deliverdate: undefined,
      },
      showsig: false,
    };
    this.editContact = this.editContact.bind(this);
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
  }
  editContact(val, label) {
    let newdata = { ...this.state.signature };
    newdata[label] = val;
    this.setState({ signature: newdata });
  }
  editVal(val) {
    let newdata = { ...this.state.signature };
    newdata.sig = val;
    if (val) {
      this.setState({ signature: newdata });
    }
  }
  componentDidMount() {
    carStore.getCars();
  }
  onHide() {
    this.setState({ showsig: false });
  }

  postNew = async () => {
    let inout = completeStorStore.delstoinout.inout;
    let type = `Storage ${inout}`;
    let orderid = completeStorStore.neworder._id;

    let url = `/ac/pickupdropoff/confirmsto/${orderid}`;

    let trucktrack = null;
    if (this.state.signature.trucktrack !== "No Tracking") {
      let indexval = carStore.cars.findIndex(
        (car) => car.name === this.state.signature.trucktrack
      );
      if (indexval >= 0) {
        trucktrack = carStore.cars[indexval]._id;
      }
    }

    let data = {
      signature: this.state.signature,
      type: type,
    };
    if (completeStorStore.delstoinout.delsto === "Delivery") {
      if (completeStorStore.delstoinout.inout === "Out") {
        if (trucktrack) data.trucktrack = trucktrack;
      }
    }
    this.setState({ loading: true });

    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });

        Notification["success"]({
          title: `New Storage ${completeStorStore.delstoinout.inout} Completed.`,
        });
        newStorageStore.getSingleACOrder(orderid, true);
        this.props.history.push(`/acorder/${orderid}`);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });

        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  render() {
    let type = completeStorStore.delstoinout.type;
    let inout = completeStorStore.delstoinout.inout;

    let sto = completeStorStore.neworder;

    let oldsig = false;
    if (sto.storSignature.signature) {
      oldsig = sto.storSignature;
    }

    let emailNote = { message: "", color: "black" };
    let emailVal = true;

    let sig = this.state.signature;

    if (sig.email !== "") {
      emailVal = validateEmail(sig.email);
      if (emailVal) {
        emailNote = {
          color: "green",
          message: `The Storage ${inout} Note will be sent to ${sig.email}.`,
        };
      } else {
        emailNote = {
          color: "red",
          message: "Please Write a Valid Email Address.",
        };
      }
    }

    let selectTracking;
    if (inout === "Out") {
      selectTracking = (
        <>
          <Div14>Truck Tracking</Div14>
          <FlexboxGrid justify="center">
            <Col2>
              <SelectInputSTD
                title="Select a Truck"
                val={sig.trucktrack}
                label="trucktrack"
                size="large"
                opts={carStore.trucklist}
                editVal={this.editContact}
              />
            </Col2>
          </FlexboxGrid>
        </>
      );
    }

    let signature = (
      <SignatureWrapperStor
        changeST={this.editVal}
        sig={sig.sig}
        oldsig={oldsig}
      />
    );

    return (
      <div>
        <FlexboxGrid justify="center">
          <Col2>
            <ModalFooter
              icon={["left", "right"]}
              color={["red", "green"]}
              app={["default", "default"]}
              text={["Back", `Complete ${type}`]}
              oC1={() => this.props.changeStep()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={
                this.state.signature.name === "" ||
                !emailVal ||
                this.state.signature.sig === null
              }
            />
          </Col2>
        </FlexboxGrid>
        <Div14>Complete Storage {inout}</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <TextInputSTD
              val={sig.name}
              editVal={this.editContact}
              title="Client Name"
              label="name"
            />
            <HelpBlock
              style={{
                color: "red",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              {this.state.signature.name === "" && "Client Name is Required"}
            </HelpBlock>
          </Col2>
          <Col2>
            <TextInputSTD
              title="Email (Optional)"
              val={sig.email}
              label="email"
              editVal={this.editContact}
            />
            <HelpBlock
              style={{
                color: emailNote.color,
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              {emailNote.message}
            </HelpBlock>
          </Col2>
        </FlexboxGrid>
        <div>{signature}</div>
        <TextAreaInputSTD
          title="Remarks (Optional)"
          val={sig.remark}
          label="remark"
          editVal={this.editContact}
        />
        {selectTracking}
      </div>
    );
  }
}

export default withRouter(observer(CloseStorInOut));
