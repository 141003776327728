import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize("G-ZFJ6S08QH8");
  console.log("GA INITIALIZED");
};

export default InitializeGoogleAnalytics;

export { InitializeGoogleAnalytics };
