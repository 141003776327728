import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Item } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { Toolbar, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { Tooltip } from "@material-ui/core";
import { withRouter } from "react-router";
import axios from "axios";
import { message } from "antd";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import aimInvoiceStore from "../../../../../../Stores/Financial/AIMInvoiceStore";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import ACReceiptPDF from "../../../../../../Components/PDFs/Payments/ACReceiptPDF";
import Col2 from "../../../../../../Components/Columns/Col2";
import SelectInputSTD from "../../../../../../Components/Inputs/STD/SelectInputSTD";
import SelectInputSTU from "../../../../../../Components/Inputs/Updatable/SelectInputSTU";
import NumbInputSTU from "../../../../../../Components/Inputs/Updatable/NumbInputSTU";
import TextInputSTU from "../../../../../../Components/Inputs/Updatable/TextInputSTU";
import DateInputSTU from "../../../../../../Components/Inputs/Updatable/DateInputSTU";
import TextAreaInputSTU from "../../../../../../Components/Inputs/Updatable/TextAreaInputSTU";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import Col3 from "../../../../../../Components/Columns/Col3";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import banklist from "../../../../../../Static/Lists/financial/banklist";
import paymethodlist from "../../../../../../Static/Lists/financial/paymethodlist";

const instance = axios.create({ baseURL: baseURL });

class PaymentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showreceipt: false,
      payment: { paymentNumId: "", _id: "" },
      orgpayment: { paymentNumId: "", _id: "" },
      showedit: false,
      showdelete: false,
      loading: false,
      load: {},
    };

    this.onHide = this.onHide.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.updateVal = this.updateVal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.onExporting = this.onExporting.bind(this);
    this.selectPayment = this.selectPayment.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }
  postDelete = async () => {
    this.setState({ loading: true });

    let url = `/jf/payment/deletebyid/${this.state.payment._id}`;
    let data = {};
    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Payment #${this.state.payment.paymentNumId} Deleted`);
        this.onHide();
        aimInvoiceStore.getInvPayments(true);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  updateVal = async (label) => {
    this.setState({ load: { [label]: true } });

    let url = `/jf/payment/edit/${this.state.payment._id}`;
    let data = { [label]: this.state.payment[label] };
    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.editOrg(this.state.payment[label], label);
        this.setState({ load: { [label]: false } });
        message.success(
          `Payment #${this.state.payment.paymentNumId} Updated ${label}`
        );
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  editVal(val, label) {
    let oldpayment = { ...this.state.payment };
    oldpayment[label] = val;
    this.setState({ payment: oldpayment });
  }
  editOrg(val, label) {
    let oldpayment = { ...this.state.orgpayment };
    oldpayment[label] = val;
    this.setState({ orgpayment: oldpayment });
  }
  selectPayment(payment, type) {
    this.setState({ payment: payment, orgpayment: payment, [type]: true });
  }
  onHide() {
    this.setState({
      showreceipt: false,
      showedit: false,
      showdelete: false,
      loading: false,
    });
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Payment_List_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  ActionButs(cellData) {
    return (
      <>
        <Tooltip title={`View Receipt`} arrow>
          <IconButton
            icon={<Icon icon="eye" />}
            color="green"
            circle
            size="xs"
            onClick={() => {
              this.selectPayment(cellData.data, "showreceipt");
            }}
          />
        </Tooltip>
        <span> </span>
        {userStore.user.group === "EMStaff" && (
          <Tooltip title={`Edit Payment`} arrow>
            <IconButton
              icon={<Icon icon="pencil" />}
              color="blue"
              circle
              size="xs"
              onClick={() => {
                this.selectPayment(cellData.data, "showedit");
              }}
            />
          </Tooltip>
        )}
        <span> </span>
        {userStore.user.group === "EMStaff" && (
          <Tooltip title={`Delete Payment`} arrow>
            <IconButton
              icon={<Icon icon="trash" />}
              color="red"
              circle
              size="xs"
              onClick={() => {
                this.selectPayment(cellData.data, "showdelete");
              }}
            />
          </Tooltip>
        )}
      </>
    );
  }
  render() {
    let data = aimInvoiceStore.payments;

    let content = (
      <DataGrid
        dataSource={data}
        keyExpr="paymentNumId"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        rowAlternationEnabled
        onExporting={this.onExporting}
        style={{ fontSize: 10 }}
        hoverStateEnabled={true}
      >
        <FilterRow visible={true} applyFilter />
        <GroupPanel visible={true} />
        <SearchPanel visible={true} />
        <Paging defaultPageSize={10} defaultPageIndex={0} />
        <Pager
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showNavigationButtons={true}
        />
        <Scrolling mode="standard" showScrollbar="always" />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={true} />
        <Column
          caption="Actions"
          alignment={"center"}
          cellRender={this.ActionButs}
          width={100}
        />
        <Column
          dataField="paymentNumId"
          caption="ID"
          alignment="center"
          width={80}
        />
        <Column caption="Payment Details" alignment={"center"}>
          {userStore.user.group === "EMStaff" && (
            <Column
              dataField="type"
              caption="Main Type"
              alignment="center"
              minWidth={80}
            />
          )}
          <Column
            dataField="accounttype"
            caption="Type"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="method"
            caption="Method"
            alignment="center"
            minWidth={80}
          />
          {userStore.user.group === "EMStaff" && (
            <Column
              dataField="account"
              caption="Account"
              alignment="center"
              minWidth={80}
            />
          )}
          <Column
            dataField="date"
            caption="Date"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
            sortOrder={"desc"}
          />
        </Column>

        <Column caption="Charges" alignment={"center"}>
          <Column
            dataField="amount"
            caption="Amount"
            alignment="center"
            minWidth={80}
            format={{ type: "fixedPoint", precision: 3 }}
          />
        </Column>
        <Summary>
          <TotalItem
            column="ID"
            summaryType="count"
            displayFormat={"{0} Payments"}
          />
          <TotalItem
            column="amount"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
        </Summary>
        <Export enabled={true} />
        <Toolbar>
          <Item name="Refresh" location="before">
            <div style={{ paddingLeft: "4px" }}>
              <Tooltip title={`Refresh`} arrow placement="right">
                <IconButton
                  icon={<Icon icon="refresh" />}
                  onClick={() => {
                    aimInvoiceStore.getInvPayments(true);
                  }}
                  appearance="default"
                  style={{ border: "1px solid black" }}
                />
              </Tooltip>
            </div>
          </Item>
          {userStore.user.group === "EMStaff" && (
            <Item name="New Payment" location="after">
              <div style={{ paddingLeft: "4px" }}>
                <Tooltip title={`New Payment`} arrow placement="right">
                  <IconButton
                    color="green"
                    icon={<Icon icon="plus" />}
                    onClick={() => {
                      this.props.newPayment();
                    }}
                    appearance="default"
                    style={{ border: "1px solid grey" }}
                  />
                </Tooltip>
              </div>
            </Item>
          )}
          <Item name="columnChooserButton" location="after" />
          <Item name="exportButton" />
        </Toolbar>
      </DataGrid>
    );

    let pay = this.state.payment;

    return (
      <div>
        <UnifiedModal
          title={`Confirmation of Payment #${this.state.payment.paymentNumId}`}
          show={this.state.showreceipt}
          onHide={this.onHide}
          footer={false}
        >
          <ACReceiptPDF rct={pay} />
        </UnifiedModal>
        <UnifiedModal
          title={`Edit Payment #${pay.paymentNumId}`}
          show={this.state.showedit}
          onHide={this.onHide}
          footer={false}
        >
          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Type"
                editVal={this.editVal}
                val={pay.type}
                label="type"
                size="large"
                opts={["Income"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Sub Type"
                editVal={this.editVal}
                val={pay.subtype}
                label="subtype"
                size="large"
                opts={["Account Related"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Specific"
                editVal={this.editVal}
                val={pay.specific}
                label="specific"
                size="large"
                opts={["Customer Payment"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Cash / Non-Cash"
                editVal={this.editVal}
                val={pay.cash}
                label="cash"
                size="large"
                opts={["Cash"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTU
                val={pay.account}
                org={this.state.orgpayment.account}
                label="account"
                load={this.state.load.account}
                title="Account"
                size="large"
                editVal={this.editVal}
                updateVal={this.updateVal}
                opts={banklist}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTU
                val={pay.method}
                org={this.state.orgpayment.method}
                label="method"
                load={this.state.load.method}
                title="Method"
                size="large"
                editVal={this.editVal}
                updateVal={this.updateVal}
                opts={paymethodlist}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTU
                val={pay.amount}
                title="Amount"
                editVal={this.editVal}
                org={this.state.orgpayment.amount}
                updateVal={this.updateVal}
                label="amount"
                load={this.state.load.amount}
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTU
                title="Payment Ref."
                editVal={this.editVal}
                val={pay.paymentref}
                label="paymentref"
                org={this.state.orgpayment.paymentref}
                updateVal={this.updateVal}
                load={this.state.load.paymentref}
              />
              <br />
            </Col2>
            <Col2>
              <DateInputSTU
                title="Date"
                editVal={this.editVal}
                val={pay.date}
                label="date"
                org={this.state.orgpayment.date}
                load={this.state.load.date}
                size="large"
                format={"DD-MMM-YY"}
                picker="date"
                updateVal={this.updateVal}
              />
              <br />
            </Col2>
            <TextAreaInputSTU
              title="Payment Remarks"
              editVal={this.editVal}
              val={pay.remarks}
              label="remarks"
              org={this.state.orgpayment.remarks}
              updateVal={this.updateVal}
              load={this.state.load.remarks}
            />
            <br />
          </FlexboxGrid>
        </UnifiedModal>
        <UnifiedModal
          title={`Delete Payment #${pay.paymentNumId}`}
          show={this.state.showdelete}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() => this.postDelete()}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>Are you sure you want to delete Payment # {pay.paymentNumId}?</h3>
          <br />
          <FlexboxGrid>
            <Col3>
              <p>
                <b>Type: </b>
                {pay.type}
                <br />
                <b>Sub Type: </b>
                {pay.subtype}
                <br />
                <b>Account Type: </b>
                {pay.accounttype}
                <br />
              </p>
            </Col3>
            <Col3>
              <p>
                <b>Cash/Non-Cash: </b>
                {pay.cash}
                <br />
                <b>Date: </b>
                {moment(pay.type).format("DD-MMM-YY")}
                <br />
                <b>Method: </b>
                {pay.method}
                <br />
              </p>
            </Col3>
            <Col3>
              <p>
                <b>Account: </b>
                {pay.account}
                <br />
                <b>Payment Reference: </b>
                {pay.paymentref}
                <br />
                <b>Amount: </b>
                {PRKD(pay.amount)} KD
                <br />
              </p>
            </Col3>
          </FlexboxGrid>
          <p>
            <b>Remarks: </b>
            {pay.remarks}
            <br />
          </p>
          <hr />
        </UnifiedModal>

        {content}
      </div>
    );
  }
}

export default withRouter(observer(PaymentTable));
