import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ItemCountHeader from "../../../../../../../Components/Stats/ItemCount/ItemCountHeader";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { Button, FlexboxGrid, Icon, InputGroup } from "rsuite";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import moment from "moment";
import staffStorageStatStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import { DatePicker, Tabs } from "antd";
import DeliveryDetails from "./Subpages/DeliveryDetails";
import MonthlyDeliveryReport from "./Subpages/MonthlyDeliveryReport";
import StaticItemsList from "./Subpages/StaticItemsList";
import UnmovedItems from "./Subpages/UnmovedItems";
import ClientBreakdown from "./Subpages/ClientBreakdown";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class StatisticsMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Delivery Details" };
    this.changeDate = this.changeDate.bind(this);
  }

  changeDate(val) {
    let startdate = moment()
      .subtract(val - 1, "month")
      .startOf("M");
    let enddate = moment().endOf("month");
    staffStorageStatStore.changeDateRange([startdate, enddate]);

    staffStorageStatStore.getStaticItems(true);
    staffStorageStatStore.getStats(true);
  }

  componentDidMount() {
    staffStorageStatStore.getStats(true);
  }

  render() {
    let timeopts = [
      { title: "1 Month", value: 1 },
      { title: "2 Month", value: 2 },
      { title: "3 Month", value: 3 },
      { title: "6 Month", value: 6 },
      { title: "9 Month", value: 9 },
      { title: "12 Month", value: 12 },
    ];
    return (
      <LoadBack loading={staffStorageStatStore.loading.stats}>
        <Div14>Statistics Main</Div14>
        <ItemCountHeader items={staffStorageStore.inventory} />
        <hr />
        <FlexboxGrid justify="space-between">
          {timeopts.map((it, index) => (
            <Button
              color="green"
              appearance="ghost"
              size="xs"
              key={index}
              onClick={() => this.changeDate(it.value)}
            >
              {it.title}
            </Button>
          ))}
        </FlexboxGrid>
        <br />
        <InputGroup>
          <InputGroup.Addon>Date Range</InputGroup.Addon>
          <RangePicker
            style={{ width: "100%" }}
            //format={"DD-MMM-YY @ HA"}
            format={"MMM-YY"}
            value={[
              staffStorageStatStore.startdate,
              staffStorageStatStore.enddate,
            ]}
            allowClear={false}
            size={"large"}
            picker="month"
            showMinute={false}
            onChange={(date) => {
              staffStorageStatStore.changeDateRange(date);
            }}
          />
          <InputGroup.Button
            color="green"
            loading={staffStorageStatStore.loading.stats}
            onClick={() => {
              if (this.state.tab === "Static Items") {
                staffStorageStatStore.getStaticItems(true);
              } else if (this.state.tab === "Client Breakdown") {
                staffStorageStatStore.getClientBreakdown(true);
              } else {
                staffStorageStatStore.getStats(true);
              }
            }}
          >
            <Icon icon="refresh" />
          </InputGroup.Button>
        </InputGroup>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Delivery Details"} key={"Delivery Details"}>
            <DeliveryDetails />
          </TabPane>
          <TabPane tab={"Delivery Report"} key={"Delivery Report"}>
            <MonthlyDeliveryReport />
          </TabPane>
          <TabPane tab={"Monthly Movement"} key={"Monthly Movement"}>
            <StaticItemsList />
          </TabPane>
          <TabPane tab={"Unmoved Items"} key={"Unmoved Items"}>
            <UnmovedItems />
          </TabPane>
          <TabPane tab={"Client Breakdown"} key={"Client Breakdown"}>
            <ClientBreakdown />
          </TabPane>
        </Tabs>

        <hr />
      </LoadBack>
    );
  }
}

export default withRouter(observer(StatisticsMain));
