import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import allPaymentsStore from "../../../Stores/Financial/AllPaymentsStore";
import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  LoadPanel,
  GroupItem,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

import moment from "moment";

class PaymentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      payment: {
        type: "",
        subtype: "",
        specific: "",
        cash: "",
        date: null,
        method: "",
        account: "",
        paymentref: "",
        amount: "",
        accounttype: "",
        remarks: "",
        paymentNumId: "",
      },
    };
  }

  onHide() {
    this.setState({
      show: false,
      payment: {
        type: "",
        subtype: "",
        specific: "",
        cash: "",
        date: null,
        method: "",
        account: "",
        paymentref: "",
        amount: "",
        accounttype: "",
        remarks: "",
        paymentNumId: "",
      },
    });
  }

  componentDidMount() {
    allPaymentsStore.getAllPayments();
  }

  StatusCell(cellData) {
    let type = cellData.data.type;
    let color = "red";
    if (type === "Income") {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{type}</div>;
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `EasyMovePayments_as_of_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  render() {
    let payments = this.props.payments;

    return (
      <div>
        <DataGrid
          dataSource={payments}
          keyExpr="paymentNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          hoverStateEnabled={true}
          onRowClick={(e) => this.props.selectPay(e.data)}
        >
          <LoadPanel enabled={true} />
          <FilterRow visible={true} applyFilter />

          <GroupPanel visible={true} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column
            dataField="paymentNumId"
            dataType="number"
            caption="ID"
            alignment="center"
            width={60}
          />
          <Column
            dataField="date"
            dataType="date"
            caption="Date"
            alignment="center"
            format={"dd-MMM-yy"}
            minWidth={80}
            sortOrder={"desc"}
          />
          <Column
            dataField="type"
            caption="Type"
            alignment="center"
            minWidth={100}
            cellRender={this.StatusCell}
          />
          <Column
            dataField="accounttype"
            caption="Department"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="subtype"
            dataType="text"
            caption="Sub Type"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="specific"
            dataType="text"
            caption="Specific"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="method"
            dataType="text"
            caption="Method"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="account"
            dataType="text"
            caption="Account"
            alignment="center"
            minWidth={120}
          />

          <Column
            dataField="amount"
            dataType="number"
            caption="Amount (KD)"
            alignment="center"
            format={{ type: "fixedPoint", precision: 3 }}
            minWidth={100}
          />
          <Column
            dataField="clientshort"
            caption="Client"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="partyname"
            caption="Party Name"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="invoicenumber"
            caption="Invoice IDs"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="paymentref"
            caption="Payment Ref."
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="remarks"
            caption="Remarks"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="user.username"
            caption="Modified By"
            alignment="center"
            minWidth={80}
          />
          <Column
            caption="Payment Actions"
            alignment="center"
            minWidth={80}
            cellRender={this.props.editButton}
          />

          <Summary>
            <GroupItem
              column="amount"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="date"
              summaryType="count"
              displayFormat={"{0}"}
            />
            <TotalItem
              column="amount"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
          </Summary>

          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(PaymentTable));
