import { Pagination } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Loader } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import sideJobStore from "../../../../Stores/SideJobs/SideJobStore";
import sjStore from "../../../../Stores/SideJobs/SJStore";
import singleJobStore from "../../../../Stores/SingleJobStore";
import DistributionButtons from "./DistributionButtons";
import FilterButtons from "./FilterButtons";
import JobDetailsModal from "./JobDetailsModal";
import SideJobTable from "./SideJobTable";
import SingleCard from "./SingleCard";
import ViewButtons from "./ViewButtons";

class SJListTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "card",
      show: false,
      jobId: "",
      subjob: { type: "" },
      job: {},
      page: 1,
      pageSize: 50,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeView = this.changeView.bind(this);
  }
  componentDidMount() {
    if (sideJobStore.type !== this.props.match.params.type) {
      sideJobStore.getPending(this.props.match.params.type);
    }
  }
  componentDidUpdate() {
    if (sideJobStore.type !== this.props.match.params.type) {
      sideJobStore.getPending(this.props.match.params.type);
    }
  }
  changeView(val) {
    this.setState({ type: val });
  }
  showHideModal(val, subjob) {
    if (val) {
      this.setState({ show: val, jobId: subjob.job._id, subjob: subjob });
      this.fetchJobDetails(subjob.job._id);
    } else {
      this.setState({ show: false, jobId: "", subjob: { type: "" } });
    }
  }
  fetchJobDetails(jobId) {
    singleJobStore.getJob(jobId, true);
    sjStore.getSJs(jobId, true);
  }

  render() {
    let type = this.props.match.params.type;

    let showdata = sideJobStore.filtsjs.slice(
      (this.state.page - 1) * this.state.pageSize,
      this.state.page * this.state.pageSize
    );

    let finalouput = (
      <div>
        <JobDetailsModal
          show={this.state.show}
          showModal={this.showHideModal}
          loading={false}
          subjob={this.state.subjob}
          jobId={this.state.jobId}
          type={this.state.subjob.jobtype}
        />

        {this.state.type === "card" ? (
          sideJobStore.filtsjs.length > 0 ? (
            <FlexboxGrid justify="center">
              {showdata.map((item, index) => (
                <SingleCard
                  key={index}
                  item={item}
                  showModal={this.showHideModal}
                />
              ))}
            </FlexboxGrid>
          ) : (
            <div />
          )
        ) : (
          <SideJobTable
            data={sideJobStore.filtsjs}
            type="All"
            showModal={this.showHideModal}
          />
        )}
      </div>
    );

    if (sideJobStore.loadsjs) {
      finalouput = <Loader center size="lg" />;
    }

    let showpaging = false;
    if (this.state.type === "card") showpaging = true;
    // if (sideJobStore.filtsjs.length <= this.state.pageSize) showpaging = false;

    return (
      <div>
        <br />
        <ViewButtons
          type={type}
          changeView={this.changeView}
          view={this.state.type}
        />

        <Div14>{type.toUpperCase()} Job List </Div14>
        <DistributionButtons />
        <br />
        <FilterButtons type={type} />
        {finalouput}
        {showpaging && (
          <FlexboxGrid justify="center">
            <Pagination
              pageSize={this.state.pageSize}
              total={sideJobStore.filtsjs.length}
              showQuickJumper
              onChange={(e) => this.setState({ page: e })}
              onShowSizeChange={(current, size) =>
                this.setState({ pageSize: size })
              }
              pageSizeOptions={[50, 100, 200]}
            />
          </FlexboxGrid>
        )}
      </div>
    );
  }
}

export default observer(SJListTemplate);
