import Modal from "antd/lib/modal/Modal";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

class UnifiedModal extends Component {
  render() {
    let width = "90%";
    if (this.props.width) {
      width = this.props.width;
    }

    let closable = true;
    if (this.props.noclose) {
      closable = false;
    }

    return (
      <Modal
        open={this.props.show}
        title={this.props.title}
        destroyOnClose
        footer={this.props.footer}
        onCancel={() => this.props.onHide(false)}
        width={width}
        maskClosable={false}
        style={{ top: 20 }}
        closable={closable}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default withRouter(observer(UnifiedModal));
