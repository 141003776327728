import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { IMQuoteCalc } from "../../../../../../Functions/Other/IMQuoteCalc";
import { IMPriceCalc } from "../../../../../../Functions/Pricing/IMPriceCalc";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import userStore from "../../../../../../Stores/UserStore";

class IMSummary extends Component {
  render() {
    let subjob = this.props.sj;
    let sj = subjob.im;
    let price = IMPriceCalc(subjob);
    let section = this.props.section;
    if (section === "Pricing") {
      return (
        <div>
          <Div14>Pricing:</Div14>
          <p>
            <b>Total CBM: </b>
            {subjob.cbm} CBM
            <br />
            <b>Job Price: </b>
            {PRKD(price[0])} KD (
            {price[1] === 0 || subjob.cbm === 0
              ? "0"
              : Math.round((100 * Parsed(price[1])) / Parsed(subjob.cbm)) /
                100}{" "}
            KD / CBM)
            <br />
            {sj.direction === "Outbound" ? (
              <>
                <b> {PRKD(sj.cppack)} KD </b> Packing and Loading
                <br />
                <b> {PRKD(sj.cpouttrans)} KD </b> Transportation
                <br />
                <b> {PRKD(sj.cpexport)} KD </b> Export Clearance
                <br />
                <b> {PRKD(sj.cpship)} KD </b> Shipping
                <br />
                <b> {PRKD(sj.cpdest)} KD </b> Destination
              </>
            ) : (
              <>
                <b> {PRKD(sj.cpunpack)} KD </b> Unloading and Unpacking
                <br />
                <b> {PRKD(sj.cpintrans)} KD </b> Transportation
                <br />
                <b> {PRKD(sj.cpimport)} KD </b> Import Clearance
                <br />
                <b> {PRKD(sj.cpministry)} KD </b> Ministry Approval
                <br />
                <b> {PRKD(sj.cpinspect)} KD </b> Inspection
              </>
            )}
            <br />
            <b>{price[2]} KD </b> ({subjob.addcharge.length}) Additional
            <br />
            {userStore.user.jobs && (
              <>
                <div style={{ color: "red" }}>
                  <b> {PRKD(price[3])} KD </b> Origin Cost
                  <br />
                  <b> {PRKD(price[4])} KD </b> Shipping Cost
                  <br />
                  <b> {PRKD(price[5])} KD </b> Destination Cost
                  <br />
                  {sj.commodity === "Dangerous Goods" && (
                    <div>
                      <b> {PRKD(price[6])} KD </b> Destination Cost
                      <br />
                    </div>
                  )}
                </div>
                <div style={{ color: price[7] > 0 ? "green" : "red" }}>
                  <b>{PRKD(price[7])} KD </b>
                  Profit
                </div>
              </>
            )}
          </p>
        </div>
      );
    } else if (section === "Tracking") {
      let trackrows;
      if (sj.imtracks.length > 0) {
        trackrows = sj.imtracks.map((track, index) => (
          <div
            key={index}
            style={{ color: track.status === "Complete" ? "green" : "black" }}
          >
            <b>
              {index + 1}) {track.title}{" "}
            </b>
            {moment(track.date).format("DD-MMM-YY")} - {track.status}
            <br />
          </div>
        ));
      }
      return (
        <div>
          <Div14>{sj.imtracks.length} IM Tracking:</Div14>
          <p>{trackrows}</p>
        </div>
      );
    } else if (section === "Quotes") {
      let quoterows;
      if (sj.imquotes.length > 0) {
        quoterows = sj.imquotes.map((quote, index) => (
          <div key={index}>
            <b>
              {index + 1}) {quote.companytype}
            </b>{" "}
            {quote.imsupplier.company} for {PRKD(quote.price)} KD
            <br />
          </div>
        ));
      }
      let qprice = IMQuoteCalc(sj.imquotes);
      return (
        <div>
          <Div14>
            {qprice[0]} IM Quotes ({PRKD(qprice[1])} KD):
          </Div14>
          <p>{quoterows}</p>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default observer(IMSummary);
