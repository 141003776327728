import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import acStorStatStore from "../../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";
import LoadBack from "../../../../../../../../Components/Loaders/LoadBack";
import Col2 from "../../../../../../../../Components/Columns/Col2";
import { FlexboxGrid } from "rsuite";
import OverviewBarGraph from "../../../../../../../Management/EMOverview/Graph/OverviewBarGraph";
import { Collapse, Empty } from "antd";
import moment from "moment";
import GaugeGraph from "../../../../../../../../Components/Graphs/GaugeGraph";
import Div14 from "../../../../../../../../Components/Dividers/Div14";

import Col3 from "../../../../../../../../Components/Columns/Col3";
import MonthlyUnmovedItems from "./MonthlyUnmovedItems";

const { Panel } = Collapse;

class MonthlyOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    acStorStatStore.getMonthlyOverview();
  }

  render() {
    let monthly = acStorStatStore.monthlyoverview;

    let summary = acStorStatStore.monthlyoverviewsummary;

    return (
      <LoadBack loading={acStorStatStore.loading.monthly}>
        <Collapse accordion defaultActiveKey={"1"}>
          <Panel
            header={`Monthly CBM In (${summary.cbmin} CBM) vs CBM Out (${
              summary.cbmout
            } CBM) = ${summary.cbmin - summary.cbmout > 0 ? "+" : ""}${
              summary.cbmin - summary.cbmout
            } CBM`}
            key="1"
          >
            <FlexboxGrid>
              <Col2>
                <OverviewBarGraph
                  data={monthly.cbmin}
                  xaxis={monthly.months}
                  title={`Monthly CBM In (${summary.cbmin} CBM)`}
                  label="CBM"
                  colors={["#04b341"]}
                />
              </Col2>
              <Col2>
                <OverviewBarGraph
                  data={monthly.cbmout}
                  xaxis={monthly.months}
                  title={`Monthly CBM Out (${summary.cbmout} CBM)`}
                  label="CBM"
                  colors={["#b30404"]}
                />
              </Col2>
            </FlexboxGrid>
          </Panel>
          <Panel
            header={`Monthly Quantity In (${summary.qtyin}) vs Quantity Out (${
              summary.qtyout
            }) = ${summary.qtyin - summary.qtyout > 0 ? "+" : ""}${
              summary.qtyin - summary.qtyout
            } Items`}
            key="2"
          >
            <FlexboxGrid>
              <Col2>
                <OverviewBarGraph
                  data={monthly.qtyin}
                  xaxis={monthly.months}
                  title={`Monthly Items In (${summary.qtyin})`}
                  label="Items Moved"
                  colors={["#04b341"]}
                />
              </Col2>
              <Col2>
                <OverviewBarGraph
                  data={monthly.qtyout}
                  xaxis={monthly.months}
                  title={`Monthly Items Out (${summary.qtyout})`}
                  label="Items Moved"
                  colors={["#b30404"]}
                />
              </Col2>
            </FlexboxGrid>
          </Panel>
          <Panel
            header={`Monthly Jobs In (${summary.orderin}) vs Jobs Out (${
              summary.orderout
            }) = ${summary.orderin - summary.orderout > 0 ? "+" : ""}${
              summary.orderin - summary.orderout
            }`}
            key="3"
          >
            <FlexboxGrid>
              <Col2>
                <OverviewBarGraph
                  data={monthly.orderin}
                  xaxis={monthly.months}
                  title={`Monthly Jobs In (${summary.orderin})`}
                  label="Jobs"
                  colors={["#04b341"]}
                />
              </Col2>
              <Col2>
                <OverviewBarGraph
                  data={monthly.orderout}
                  xaxis={monthly.months}
                  title={`Monthly Jobs Out (${summary.orderout})`}
                  label="Jobs"
                  colors={["#b30404"]}
                />
              </Col2>
            </FlexboxGrid>
          </Panel>
          <Panel
            header={`Monthly Client Jobs (${
              summary.totalsto
            }) vs Easy Move Jobs (${summary.totaldel}) = ${
              summary.totaldel + summary.totalsto
            } Jobs`}
            key="4"
          >
            <FlexboxGrid>
              <Col2>
                <OverviewBarGraph
                  data={monthly.totalsto}
                  xaxis={monthly.months}
                  title={`Easy Move Jobs (${summary.totaldel})`}
                  label="Orders"
                  colors={["#04b341"]}
                />
              </Col2>
              <Col2>
                <OverviewBarGraph
                  data={monthly.totaldel}
                  xaxis={monthly.months}
                  title={`Client Jobs (${summary.totalsto})`}
                  label="Orders"
                  colors={["#b30404"]}
                />
              </Col2>
            </FlexboxGrid>
          </Panel>
          <Panel
            header={`Monthly Damage Reports (${summary.damagereports} Reports)`}
            key="5"
          >
            {summary.damagereports <= 0 ? (
              <Empty description="No Damage Reports in the selected period" />
            ) : (
              <OverviewBarGraph
                data={monthly.damagereports}
                xaxis={monthly.months}
                title={`Damage Reports (${summary.damagereports})`}
                label="Orders"
                colors={["#04b341"]}
              />
            )}
          </Panel>
          <Panel
            header={`${monthly.items.length} Stagnant Items Since ${moment(
              acStorStatStore.startdate
            ).format("MMM-YY")} (${summary.stagnantcost} KD Monthly)`}
            key="6"
          >
            {monthly.items.length <= 0 ? (
              <Empty
                description={`All Items have had movement since ${moment(
                  acStorStatStore.startdate
                ).format("MMM-YY")}`}
              />
            ) : (
              <div>
                <FlexboxGrid>
                  <Col3>
                    <Div14>
                      {monthly.items.length} Stagnant Items since{" "}
                      {moment(acStorStatStore.startdate).format("MMM-YY")}
                    </Div14>
                    <GaugeGraph
                      color="#fc0000"
                      value={Math.round(
                        (100 * monthly.items.length) /
                          acStorStatStore.stats.items
                      )}
                    />
                  </Col3>
                  <Col3>
                    <Div14>
                      {summary.stagnantcbm} CBM Stagnant since{" "}
                      {moment(acStorStatStore.startdate).format("MMM-YY")}
                    </Div14>
                    <GaugeGraph
                      color="#fc0000"
                      value={Math.round(
                        (100 * summary.stagnantcbm) / acStorStatStore.stats.cbm
                      )}
                    />
                  </Col3>
                  <Col3>
                    <Div14>
                      {summary.stagnantcost} KD per month on Stagnant Items
                    </Div14>
                    <GaugeGraph
                      color="#fc0000"
                      value={Math.round(
                        (100 * summary.stagnantcbm) / acStorStatStore.stats.cbm
                      )}
                    />
                  </Col3>
                </FlexboxGrid>
                <hr />
                <MonthlyUnmovedItems />
              </div>
            )}
          </Panel>
        </Collapse>
      </LoadBack>
    );
  }
}

export default withRouter(observer(MonthlyOverview));
