import { observer } from "mobx-react";
import React, { Component } from "react";

import DataGrid, { Column } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import Div14 from "../../../../../Components/Dividers/Div14";
import { HelpBlock } from "rsuite";

class ItemsSummaryTable extends Component {
  Dimension(cellData) {
    return `${PRKD(cellData.data.length / 100)} m x ${PRKD(
      cellData.data.width / 100
    )} m x ${PRKD(cellData.data.height / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.quantity) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  render() {
    let to = this.props.to;

    let itms = this.props.to.items;
    let tcbm = 0;
    let titems = itms.length;
    let tpackages = 0;
    for (let i = 0; i < itms.length; i++) {
      tpackages += Parsed(itms[i].quantity);
      tcbm +=
        (Parsed(itms[i].length) *
          Parsed(itms[i].width) *
          Parsed(itms[i].height) *
          Parsed(itms[i].quantity)) /
        1000000;
    }

    return (
      <div>
        <Div14>
          Drop Off #{to.order} {to.city}:
        </Div14>

        <DataGrid
          dataSource={to.items}
          keyExpr="name"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          hoverStateEnabled={true}
          noDataText="No Items Added"
        >
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />

          <Scrolling mode="standard" showScrollbar="always" />

          <ColumnFixing enabled={true} />
          <Column caption="Item Details" alignment="center">
            <Column
              dataField="name"
              caption="Item"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="serialnumber"
              caption="Ser. No."
              alignment="center"
              minWidth={80}
            />
          </Column>
          <Column
            dataField="dimensions"
            caption="Dimensions"
            alignment="center"
            minWidth={140}
            cellRender={this.Dimension}
          />
          <Column
            dataField="quantity"
            dataType="number"
            caption="Quantity"
            alignment="center"
            format={{ type: "fixedPoint", precision: 0 }}
            minWidth={80}
          />
          <Column caption="CBM Calcs" alignment="center">
            <Column
              dataField="cbmperitem"
              dataType="number"
              caption="CBM Per Item"
              alignment="center"
              minWidth={80}
              cellRender={this.CBMPerItem}
            />
            <Column
              dataField="totalcbm"
              dataType="number"
              caption="Total CBM"
              alignment="center"
              minWidth={80}
              cellRender={this.TotalCBM}
            />
          </Column>
        </DataGrid>
        <HelpBlock
          style={{
            fontStyle: "italic",
            textAlign: "right",
            fontSize: 12,
            paddingRight: "5%",
          }}
        >
          {PRKD(tcbm)} CBM | {PRKD(titems)} Items | {PRKD(tpackages)} Pacakges
        </HelpBlock>
      </div>
    );
  }
}

export default observer(ItemsSummaryTable);
