import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";

class SinglePalletAddRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let pallet = this.props.pallet;

    let newpallets = this.props.newpallets;
    let selected = false;
    if (newpallets) {
      if (newpallets.length > 0) {
        for (let i = 0; i < newpallets.length; i++) {
          if (newpallets[i].whmPalletNumId === pallet.whmPalletNumId) {
            selected = true;
          }
        }
      }
    }

    let backcolor = "white";
    if (selected) {
      backcolor = "#a3ffab";
    }

    return (
      <div
        style={{
          margin: "2px",
          padding: "1px",
          border: "1px solid black",
          borderRadius: "5px",
          boxShadow: "0px 0px 3px black",
          backgroundColor: backcolor,
        }}
      >
        <FlexboxGrid>
          <Col2>
            <h3 style={{ textAlign: "center" }}>
              Pallet ID {pallet.whmPalletNumId}
            </h3>
          </Col2>
          <Col2>
            <IconButton
              icon={<Icon icon={selected ? "check-circle" : "circle"} />}
              color={selected && "green"}
              block
              onClick={() => this.props.changeSelected(pallet)}
            >
              {selected ? "Selected" : "Not Selected"}
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(SinglePalletAddRow);
