import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Col, Button, List, IconButton, Icon } from "rsuite";
import Div14 from "../../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";

class InvoiceNotes extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, data: "", jobtitle: "" };
    this.showHideModal = this.showHideModal.bind(this);
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({ show: false });
    }
  }
  render() {
    let inv = this.props.inv;
    let sjs = inv.subJobs.filter((sj) => sj.invnotes !== "");

    let rows = sjs.map((sj, index) => (
      <List.Item key={index} onClick={() => invoiceStore.editNotes(index)}>
        <FlexboxGrid justify="space-between">
          <Col md={8} sm={8} xs={8}>
            <IconButton
              color={sj.showinvnotes && "green"}
              icon={<Icon icon={sj.showinvnotes ? "check" : "close"} />}
            />
          </Col>
          <Col md={8} sm={8} xs={8}>
            {sj.jobtype}-{sj.numId}
          </Col>
          <Col md={8} sm={8} xs={8}>
            <Button
              appearance="ghost"
              onClick={() => {
                this.setState({
                  show: true,
                  data: sj.invnotes,
                  jobtitle: `${sj.jobtype}-${sj.numId}`,
                });
                invoiceStore.editNotes(index);
              }}
            >
              See
            </Button>
          </Col>
        </FlexboxGrid>
      </List.Item>
    ));

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title={`Invoice Notes for ${this.state.jobtitle}`}
          onHide={this.showHideModal}
          footer={<div />}
        >
          <div dangerouslySetInnerHTML={{ __html: this.state.data }} />
        </UnifiedModal>
        <Div14>Available Invoice Notes to Add:</Div14>
        <List hover>{rows}</List>
        <br />
      </div>
    );
  }
}

export default observer(InvoiceNotes);
