import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import Col3 from "../../../../../../../Components/Columns/Col3";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";

class DeleteItemModal extends Component {
  render() {
    let selected = this.props.selected;
    let loading = this.props.loading;

    let footer = (
      <div>
        <FlexboxGrid>
          <Col3>
            <IconButton
              icon={<Icon icon="close" />}
              block
              onClick={() => this.props.showHideModal(false)}
              disabled={loading}
            >
              Cancel
            </IconButton>
          </Col3>
          <Col3>
            <IconButton
              icon={<Icon icon="trash" />}
              color="red"
              block
              loading={loading}
              onClick={() => this.props.removeItem(selected)}
            >
              Delete Item
            </IconButton>
          </Col3>
        </FlexboxGrid>
      </div>
    );

    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.props.showHideModal}
        footer={footer}
      >
        <h5>Are you sure you want to delete the quote?</h5>
        <hr />
        <p>
          <b>Item:</b> {selected.item} <br />
          <b>CBM:</b> {selected.cbm} <br />
          <b>Quantity:</b> {selected.quantity} <br />
          <b>Remarks:</b> {selected.remarks} <br />
          <br />
        </p>
      </UnifiedModal>
    );
  }
}

export default observer(DeleteItemModal);
