import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import { Parsed, PRKD } from "../../../Functions/Pricing/PRKDCalc";
import { TRC } from "../../../Functions/Timing/TimingRulesCalc";
import { TimingCheck } from "../../../Functions/Timing/TimingCheck";
import zoneStore from "../../Admin/Lists/ZoneStore";

const instance = axios.create({ baseURL: baseURL });

class NewDeliveryStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.delCont = {
      remarks: "",
      maintype: "Fixed Per Trip",
      fixedweekly: 0,
      freeweeklytrips: 0,

      pricepertruck: 35,
      dropoffmult: 1.2,
      staffcount: 0,
      carcount: 1,
      maxcbmpertrip: 0,

      nongroundfloorprice: 10,

      cancelhours: 8,
      cancelprice: 40,
      timetoreq: 24,
      afterhourfee: 20,
      expresstime: 8,
      expressfee: 20,

      pricepertrip: {
        sun: 20,
        mon: 20,
        tue: 20,
        wed: 20,
        thu: 20,
        fri: 20,
        sat: 20,
      }, // Price Per Job
      zoneadd: {
        z1: 0,
        z2: 0,
        z3: 0,
        z4: 0,
        z5: 0,
      }, // Price Per Job

      excludedhours: {
        0: "No",
        1: "No",
        2: "No",
        3: "No",
        4: "No",
        5: "No",
        6: "After",
        7: "After",
        8: "Yes",
        9: "Yes",
        10: "Yes",
        11: "Yes",
        12: "Yes",
        13: "Yes",
        14: "Yes",
        15: "Yes",
        16: "Yes",
        17: "Yes",
        18: "After",
        19: "After",
        20: "After",
        21: "After",
        22: "No",
        23: "No",
        sun: "Yes",
        mon: "Yes",
        tue: "Yes",
        wed: "Yes",
        thu: "Yes",
        fri: "No",
        sat: "After",
      },
    };
    this.newdel = {
      remarks: "",
      status: "Requested",
      jobdate: moment().set({ second: 0 }),
      createdAt: moment().set({ second: 0 }),
      maintype: "",
      fromaddress: {
        selected: "New",
        type: "",
        city: "",
        floor: 0,
        address: "",
        name: "",
        phone: "",
        zonecost: 0,
        zonetype: "",
        ngfchg: 0,
      },
      toaddress: [
        {
          selected: "New",
          type: "",
          city: "",
          floor: 0,
          address: "",
          name: "",
          phone: "",
          service: {},
          orderoptions: [],
          items: [],
          price: {
            zonecost: 0,
            zonetype: "",
            ngfchg: 0,
          },
        },
      ],
      prices: {
        tripmultiplier: 0,
        tripprice: 0,
        ppt: 0,
        typecost: 0,
        delday: 0,
      },
      addcharge: [],
      discount: [],
      orderoptions: [],
      attachemnts: [],
    };
    this.olddel = {
      remarks: "",
      jobdate: moment().set({ second: 0 }),
      status: "Requested",
      createdAt: moment().set({ second: 0 }),
      fromaddress: {
        type: "",
        city: "",
        floor: 0,
        address: "",
        name: "",
        phone: "",
      },
      toaddress: [],
      type: {},
      prices: {
        tripprice: 0,
        ppt: 0,
        typecost: 0,
        delday: 0,
        zonecost: 0,
        ngfchg: 0,
      },
      addcharge: [],
      discount: [],
      orderoptions: [],
      attachemnts: [],
    };
    this.locations = [];
    this.filter = "";
    this.standarditems = [];
    this.loading = {
      account: false,
      newdel: false,
      items: false,
    };
    this.loaded = { items: false };

    makeObservable(this, {
      account: observable,
      delCont: observable,
      newdel: observable,
      olddel: observable,
      locations: observable,
      filter: observable,
      standarditems: observable,
      loading: observable,
      loaded: observable,
      timechgs: computed,
      ctimechg: computed,
      stopedit: computed,
      delopts: computed,
    });
  }

  // FETCH
  getAccount(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.account = true;

      return instance
        .get(`/ac/account/getaccount/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((account) => {
          this.account = account;
          this.storCont = account.storageContract;
          this.delCont = account.deliveryContract;
          this.locations = account.locations;
          this.loading.account = false;
          console.log("Accounts Fetched from Delivery Store");
        })
        .catch((err) => {
          this.loading.account = false;
          this.account = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getSingleACOrder(deliveryid, override) {
    let loaded = false;
    let dID = this.newdel._id;
    if (deliveryid) dID = deliveryid;
    if (dID === this.newdel._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.newdel = true;

      return instance
        .get(`/ac/delivery/get/byid/${dID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.account;
          this.newdel = { ...order.order };

          this.olddel = { ...order.order };
          this.delCont = order.account.deliveryContract;
          this.locations = order.account.locations;

          this.loading.newdel = false;
          console.log("Single AC Order Fetched");
        })
        .catch((err) => {
          this.loading.newdel = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getStandardAccountItems(override) {
    let loaded = this.loaded.items;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.items = true;

      return instance
        .get(`/ac/adminaccountitem/getbyclient/${this.account._id}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.standarditems = order;

          this.loading.items = false;
          this.loaded.items = true;
          console.log("Standard Account Items Fetched");
        })
        .catch((err) => {
          this.loading.items = false;
          this.loaded.items = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Standard Account Items.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get stopedit() {
    if (!userStore.user.staffaccount) {
      if (!userStore.user.comprequest) return true;
      if (
        moment().isAfter(
          moment(newDeliveryStore.newdel.jobdate).subtract(2, "hours")
        )
      ) {
        return true;
      }
      if (
        newDeliveryStore.newdel.status === "Booked" ||
        newDeliveryStore.newdel.status === "In Transit" ||
        newDeliveryStore.newdel.status === "Completed"
      ) {
        return true;
      }
    }
    return false;
  }

  get delopts() {
    let allopts = this.delCont.options.filter(
      (opt) => opt.delstor === "Delivery" || opt.delstor === "Both"
    );

    for (let i = 0; i < allopts.length; i++) {
      allopts[i].selected = false;

      for (let j = 0; j < this.newdel.orderoptions.length; j++) {
        if (this.newdel.orderoptions[j]._id === allopts[i]._id) {
          allopts[i].selected = true;
        }
      }
    }
    return allopts;
  }

  get timechgs() {
    let delCont = this.delCont;
    let del = this.newdel;

    let express = TRC(delCont, true, false, del.createdAt);
    let earliest = TRC(delCont, false, false, del.createdAt);
    let expressaf = TRC(delCont, true, true, del.createdAt);
    let earliestaf = TRC(delCont, false, true, del.createdAt);

    let hideexpress = false;
    let hideaf = false;
    let hideexpressaf = false;

    if (
      moment(earliest).format("DD-MMM-YY HH:mm A") ===
      moment(express).format("DD-MMM-YY HH:mm A")
    ) {
      hideexpress = true;
    }
    if (
      moment(earliest).format("DD-MMM-YY HH:mm A") ===
      moment(earliestaf).format("DD-MMM-YY HH:mm A")
    ) {
      hideaf = true;
    }

    if (
      moment(earliestaf).format("DD-MMM-YY HH:mm A") ===
        moment(expressaf).format("DD-MMM-YY HH:mm A") ||
      moment(express).format("DD-MMM-YY HH:mm A") ===
        moment(expressaf).format("DD-MMM-YY HH:mm A")
    ) {
      hideexpressaf = true;
    }

    let ppt = delCont.pricepertrip;

    let alldays = [
      { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
      { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
      { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
      { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
      { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
      { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
      { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
    ];

    let day = moment(del.jobdate).day();

    let charges = [
      {
        title: alldays[day].title,
        value: alldays[day].price,
        show: PRKD(alldays[day].price) > 0,
        unit: "KD",
      },
    ];
    if (delCont.maintype === "Daily Per Truck") {
      charges = [
        {
          title: "Daily Truck Rate",
          value: delCont.pricepertruck,
          show: PRKD(delCont.pricepertruck) > 0,
          unit: "KD",
        },
      ];
    }

    let toadd = del.toaddress;

    for (let i = 0; i < toadd.length; i++) {
      if (i > 0) {
        charges = [
          ...charges,
          {
            title: `Multiple Location Charge (${
              PRKD(1000 * Parsed(del.prices.tripmultiplier)) / 10
            }%): Location ${i + 1}`,
            value:
              Parsed(alldays[day].price) * Parsed(del.prices.tripmultiplier),
            show:
              PRKD(
                Parsed(alldays[day].price) * Parsed(del.prices.tripmultiplier)
              ) > 0,
            unit: "KD",
          },
        ];
      }
    }

    let timcost2 = TimingCheck(delCont, del.jobdate, del.createdAt);

    let fasttime = [];
    if (timcost2[0] & timcost2[1] & !hideexpressaf) {
      fasttime = [
        {
          price: PRKD(
            Parsed(delCont.expressfee) + Parsed(delCont.afterhourfee)
          ),
          title: "Express + After Hour Delivery",
        },
      ];
    } else if (timcost2[1] & !hideaf) {
      fasttime = [
        {
          price: PRKD(delCont.afterhourfee),
          title: "After Hour Delivery",
        },
      ];
    } else if (timcost2[0] & !hideexpress) {
      fasttime = [
        {
          price: PRKD(delCont.expressfee),
          title: "Express Delivery",
        },
      ];
    }
    if (fasttime.length > 0) {
      charges = [
        ...charges,
        {
          title: `${fasttime[0].title} Charge`,
          value: fasttime[0].price,
          show: true,
          unit: "KD",
        },
      ];
    }
    charges = charges.filter((ch) => ch.show);

    let allcharges = [...charges];

    let loccharges = [];
    if (del.fromaddress.zonecost > 0) {
      loccharges = [
        ...loccharges,
        {
          title: `Pickup: Zone ${del.fromaddress.zonetype} (${del.fromaddress.city}) Delivery Charge`,
          value: del.fromaddress.zonecost,
          show: true,
          unit: "KD",
        },
      ];
    }
    if (del.fromaddress.ngfchg > 0) {
      loccharges = [
        ...loccharges,
        {
          title: `Pickup: Non-Ground Floor Charge`,
          value: del.fromaddress.ngfchg,
          show: true,
          unit: "KD",
        },
      ];
    }

    for (let i = 0; i < toadd.length; i++) {
      if (Parsed(toadd[i].zonecost) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Dropoff ${i + 1}: Zone ${toadd[i].zonetype} (${
              toadd[i].city
            }) Delivery Charge`,
            value: toadd[i].zonecost,
            show: true,
            unit: "KD",
          },
        ];
      }
      if (Parsed(toadd[i].ngfchg) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Dropoff ${i + 1}: Non-Ground Floor Charge`,
            value: toadd[i].ngfchg,
            show: true,
            unit: "KD",
          },
        ];
      }

      if (delCont.maintype === "Fixed Per Type") {
        if (toadd[i].service) {
          if (toadd[i].service.type) {
            allcharges = [
              ...allcharges,
              {
                title: `Dropoff ${i + 1} Service: ${toadd[i].service.type}`,
                value: toadd[i].service.price,
                show: true,
                unit: "KD",
              },
            ];
          }
        }
      }
    }

    let mainjobcost = 0;

    let totaladdchg = 0;
    if (del.addcharge) {
      for (let i = 0; i < del.addcharge.length; i++) {
        totaladdchg += Parsed(del.addcharge[i].price);
      }
    }
    let totaldiscount = 0;
    if (del.discount) {
      for (let i = 0; i < del.discount.length; i++) {
        totaldiscount += Parsed(del.discount[i].price);
      }
    }

    let totalopts = 0;

    if (del.orderoptions) {
      for (let i = 0; i < del.orderoptions.length; i++) {
        totalopts += Parsed(del.orderoptions[i].price);
      }
    }

    for (let i = 0; i < allcharges.length; i++) {
      mainjobcost += Parsed(allcharges[i].value);
    }

    let totalloccharge = 0;
    if (loccharges) {
      for (let i = 0; i < loccharges.length; i++) {
        totalloccharge += Parsed(loccharges[i].value);
      }
    }

    let finaljobcost =
      Parsed(mainjobcost) +
      Parsed(totalloccharge) +
      Parsed(totaladdchg) +
      Parsed(totalopts) -
      Parsed(totaldiscount);

    if (del.status === "Cancelled") {
      finaljobcost = del.prices.canccost;
      totalopts = 0;
      totaladdchg = 0;
      mainjobcost = 0;
      totalloccharge = 0;
      allcharges = [
        {
          title: `Cancellation Charge`,
          value: finaljobcost,
          show: true,
          unit: "KD",
        },
      ];
      charges = [];
    }

    return {
      finaljobcost: finaljobcost,
      totalopts: totalopts,
      totaladdchg: totaladdchg,
      totalloccharge: totalloccharge,
      totaldiscount: totaldiscount,
      mainjobcost: mainjobcost,
      allcharges: allcharges,
      charges: charges,
      loccharges: loccharges,
      earliest: earliest,
      express: express,
      earliestaf: earliestaf,
      expressaf: expressaf,
    };
  }
  get ctimechg() {
    let delCont = this.delCont;
    let del = this.olddel;

    let charges = [
      {
        title: del.prices.weekday,
        value: del.prices.weekdayprice,
        show: PRKD(del.prices.weekdayprice) > 0,
        unit: "KD",
      },
    ];
    let dayprice;
    if (del.prices) {
      if (del.prices.weekdayprice) {
        dayprice = del.prices.weekdayprice;
      }
    }

    let toadd = del.toaddress;

    if (toadd.length) {
      for (let i = 0; i < toadd.length; i++) {
        if (i > 0) {
          charges = [
            ...charges,
            {
              title: `Multiple Location Charge (${
                PRKD(1000 * Parsed(del.prices.tripmultiplier)) / 10
              }%): Location ${i + 1}`,
              value: Parsed(dayprice) * Parsed(del.prices.tripmultiplier),
              show:
                PRKD(Parsed(dayprice) * Parsed(del.prices.tripmultiplier)) > 0,
              unit: "KD",
            },
          ];
        }
      }
    }

    if (del.prices.timeprice > 0) {
      charges = [
        ...charges,
        {
          title: `${del.prices.timetype} Charge`,
          value: del.prices.timeprice,
          show: true,
          unit: "KD",
        },
      ];
    }
    charges = charges.filter((ch) => ch.show);

    let allcharges = [...charges];

    let loccharges = [];
    if (del.fromaddress.zonecost > 0) {
      loccharges = [
        ...loccharges,
        {
          title: `Pickup: Zone ${del.fromaddress.zonetype} (${del.fromaddress.city}) Delivery Charge`,
          value: del.fromaddress.zonecost,
          show: true,
          unit: "KD",
        },
      ];
    }
    if (del.fromaddress.ngfchg > 0) {
      loccharges = [
        ...loccharges,
        {
          title: `Pickup: Non-Ground Floor Charge`,
          value: del.fromaddress.ngfchg,
          show: true,
          unit: "KD",
        },
      ];
    }

    for (let i = 0; i < toadd.length; i++) {
      if (Parsed(toadd[i].zonecost) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Dropoff ${i + 1}: Zone ${toadd[i].zonetype} (${
              toadd[i].city
            }) Delivery Charge`,
            value: toadd[i].zonecost,
            show: true,
            unit: "KD",
          },
        ];
      }
      if (Parsed(toadd[i].ngfchg) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Dropoff ${i + 1}: Non-Ground Floor Charge`,
            value: toadd[i].ngfchg,
            show: true,
            unit: "KD",
          },
        ];
      }

      if (delCont.maintype === "Fixed Per Type") {
        if (toadd[i].service) {
          if (toadd[i].service.type) {
            allcharges = [
              ...allcharges,
              {
                title: `Dropoff ${i + 1} Service: ${toadd[i].service.type}`,
                value: toadd[i].service.price,
                show: true,
                unit: "KD",
              },
            ];
          }
        }
      }
    }

    let mainjobcost = 0;

    let totaladdchg = 0;
    if (del.addcharge) {
      for (let i = 0; i < del.addcharge.length; i++) {
        totaladdchg += Parsed(del.addcharge[i].price);
      }
    }
    let totaldiscount = 0;
    if (del.discount) {
      for (let i = 0; i < del.discount.length; i++) {
        totaldiscount += Parsed(del.discount[i].price);
      }
    }

    let totalopts = 0;

    if (del.orderoptions) {
      for (let i = 0; i < del.orderoptions.length; i++) {
        totalopts += Parsed(del.orderoptions[i].price);
      }
    }

    for (let i = 0; i < allcharges.length; i++) {
      mainjobcost += Parsed(allcharges[i].value);
    }

    let totalloccharge = 0;
    if (loccharges) {
      for (let i = 0; i < loccharges.length; i++) {
        totalloccharge += Parsed(loccharges[i].value);
      }
    }

    let finaljobcost =
      Parsed(mainjobcost) +
      Parsed(totalloccharge) +
      Parsed(totaladdchg) +
      Parsed(totalopts) -
      Parsed(totaldiscount);

    if (del.status === "Cancelled") {
      finaljobcost = del.prices.canccost;
      totalopts = 0;
      totaladdchg = 0;
      mainjobcost = 0;
      totalloccharge = 0;
      allcharges = [
        {
          title: `Cancellation Charge`,
          value: finaljobcost,
          show: true,
          unit: "KD",
        },
      ];
      charges = [];
    }

    return {
      finaljobcost: finaljobcost,
      totalopts: totalopts,
      totaladdchg: totaladdchg,
      totalloccharge: totalloccharge,
      totaldiscount: totaldiscount,
      mainjobcost: mainjobcost,
      allcharges: allcharges,
      charges: charges,
      loccharges: loccharges,
    };
  }

  // FUNCTIONS:
  removeList(label, index) {
    let oldList = [...newDeliveryStore.olddel[label]];
    oldList.splice(index, 1);
    newDeliveryStore.newdel[label] = [...oldList];
    newDeliveryStore.olddel[label] = [...oldList];
  }
  newList(label, item) {
    newDeliveryStore.newdel[label] = [
      ...newDeliveryStore.newdel[label],
      { ...item },
    ];
    newDeliveryStore.olddel[label] = [
      ...newDeliveryStore.olddel[label],
      { ...item },
    ];
    newDeliveryStore.getSingleACOrder(false, true);
  }
  editListItem(label, index, itemlabel, itemval) {
    newDeliveryStore.newdel[label][index][itemlabel] = itemval;
    newDeliveryStore.olddel[label][index][itemlabel] = itemval;
  }
  editAttachmentTitle(index, val) {
    newDeliveryStore.newdel.attachments[index].title = val;
  }

  addItemToOrder(item, toindex) {
    newDeliveryStore.newdel.toaddress[toindex].items = [
      ...newDeliveryStore.newdel.toaddress[toindex].items,
      item,
    ];
  }
  editItemToOrder(item, toindex, itemindex) {
    newDeliveryStore.newdel.toaddress[toindex].items[itemindex] = { ...item };
  }
  removeItemToOrder(toindex, itemindex) {
    let finalitems = [];
    let items = newDeliveryStore.newdel.toaddress[toindex].items;

    for (let i = 0; i < items.length; i++) {
      if (i !== itemindex) {
        finalitems = [...finalitems, { ...items[i] }];
      }
    }
    newDeliveryStore.newdel.toaddress[toindex].items = [...finalitems];
  }

  editOrder(val, label) {
    newDeliveryStore.newdel[label] = val;

    if (label === "jobdate") {
      let del = newDeliveryStore.newdel;
      let timcost2 = TimingCheck(newDeliveryStore.delCont, val, del.createdAt);

      let expfee = parseFloat(newDeliveryStore.delCont.expressfee);
      let affee = parseFloat(newDeliveryStore.delCont.afterhourfee);
      if (timcost2[0] & timcost2[1]) {
        del.prices.timeprice = expfee + affee;
        del.prices.timetype = "Express + After Hour Delivery";
      } else if (timcost2[1]) {
        del.prices.timeprice = affee;
        del.prices.timetype = "After Hour Delivery";
      } else if (timcost2[0]) {
        del.prices.timeprice = expfee;
        del.prices.timetype = "Express Delivery";
      } else {
        del.prices.timeprice = 0;
        del.prices.timetype = "";
      }
      let day = moment(del.jobdate).day();
      let ppt = newDeliveryStore.delCont.pricepertrip;
      let alldays = [
        { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
        { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
        { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
        { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
        { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
        { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
        { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
      ];
      if (alldays[day].price > 0) {
        del.prices.weekday = alldays[day].title;
        del.prices.weekdayprice = alldays[day].price;
      }
    }
  }

  addNewLoc(newloc, type) {
    newDeliveryStore.locations = [...newDeliveryStore.locations, newloc];
    if (type === "From") {
      newDeliveryStore.newdel.fromaddress.selected = newloc.name;
    } else {
      newDeliveryStore.newdel.toaddress.selected = newloc.name;
    }
  }

  selectLoc(newloc, type) {
    let code = "fromaddress";
    let code2 = "f";
    if (type === "To") {
      code = "toaddress";
      code2 = "t";
    }
    newDeliveryStore.newdel[code] = { ...newloc };
    newDeliveryStore.updateZone(newloc.city, type);
    if (code2 === "f") {
      if (newloc.floor > 0 || newloc.floor > 0) {
        newDeliveryStore.newdel[code].ngfchg =
          newDeliveryStore.delCont.nongroundfloorprice;
      } else {
        newDeliveryStore.newdel[code].ngfchg = 0;
      }
    }
  }

  editLoc(val, label, type) {
    let code = "fromaddress";
    if (type === "To") {
      code = "toaddress";
    }
    newDeliveryStore.newdel[code][label] = val;
    if (label === "city") {
      newDeliveryStore.updateZone(val, type);
    } else if (label === "floor") {
      if (val > 0 || val < 0) {
        newDeliveryStore.newdel[code].ngfchg =
          newDeliveryStore.delCont.nongroundfloorprice;
      } else {
        newDeliveryStore.newdel[code].ngfchg = 0;
      }
    }
  }
  updateOrgLoc(fromaddress, label) {
    newDeliveryStore.olddel.fromaddress[label] = fromaddress[label];
    if (label === "floor") {
      newDeliveryStore.olddel.fromaddress.ngfchg = fromaddress.ngfchg;
    }
    if (label === "city") {
      newDeliveryStore.olddel.fromaddress.zonecost = fromaddress.zonecost;
      newDeliveryStore.olddel.fromaddress.zonetype = fromaddress.zonetype;
    }
  }

  addNewTo(to) {
    let newto = { ...to, service: {}, orderoptions: [], items: [] };
    newDeliveryStore.newdel.toaddress = [
      ...newDeliveryStore.newdel.toaddress,
      newto,
    ];
  }
  moveNewTo(index, type) {
    let olddel = [...newDeliveryStore.newdel.toaddress];
    let removedArray = olddel.splice(index, 1)[0];
    let newindex = index;
    if (type === "up") newindex -= 1;
    if (type === "down") newindex += 1;
    olddel.splice(newindex, 0, removedArray);
    newDeliveryStore.newdel.toaddress = [...olddel];
  }
  deleteTo(index) {
    let olddel = [...newDeliveryStore.newdel.toaddress];
    let newdel = [];
    for (let i = 0; i < olddel.length; i++) {
      if (i !== index) newdel = [...newdel, olddel[i]];
    }
    newDeliveryStore.newdel.toaddress = [...newdel];
  }

  selectType(type, index) {
    let found = false;
    if (newDeliveryStore.newdel.toaddress[index].service) {
      if (newDeliveryStore.newdel.toaddress[index].service.type) {
        if (
          newDeliveryStore.newdel.toaddress[index].service.type === type.type
        ) {
          newDeliveryStore.newdel.toaddress[index].service = {};

          found = true;
        }
      }
    }
    if (!found) {
      newDeliveryStore.newdel.toaddress[index].service = type;
    }
  }
  updateOrgType(type) {
    let tos = [...newDeliveryStore.newdel.toaddress];
    let oldtos = [...newDeliveryStore.olddel.toaddress];

    if (type === "reset") {
      for (let i = 0; i < tos.length; i++) {
        newDeliveryStore.newdel.toaddress[i].service = { ...oldtos[i].service };
      }
    } else {
      for (let i = 0; i < tos.length; i++) {
        newDeliveryStore.olddel.toaddress[i].service = { ...tos[i].service };
      }
    }
  }

  updateOption(option) {
    let order = { ...newDeliveryStore.newdel };

    let ind = order.orderoptions.findIndex((opt) => opt.desc === option.desc);
    if (ind < 0) {
      order.orderoptions = [...order.orderoptions, option];
    } else {
      let newopts = [];
      for (let i = 0; i < order.orderoptions.length; i++) {
        if (i !== ind) {
          newopts = [...newopts, order.orderoptions[i]];
        }
      }
      order.orderoptions = [...newopts];
    }
    newDeliveryStore.newdel = { ...order };
  }

  resetorderopts(oldopts) {
    newDeliveryStore.newdel.orderoptions = [...oldopts];
  }
  editPrices(val, label) {
    newDeliveryStore.newdel.prices[label] = val;
  }
  updatePrices(label) {
    newDeliveryStore.olddel.prices[label] =
      newDeliveryStore.newdel.prices[label];
  }

  editAddCharge(val, label, index) {
    newDeliveryStore.newdel.addcharge[index][label] = val;
  }
  editDiscount(val, label, index) {
    newDeliveryStore.newdel.discount[index][label] = val;
  }

  newAddCharge() {
    newDeliveryStore.newdel.addcharge = [
      ...newDeliveryStore.newdel.addcharge,
      { name: "", price: 0 },
    ];
  }
  removeAddCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newDeliveryStore.newdel[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newDeliveryStore.newdel[label][i]];
      }
    }
    newDeliveryStore.newdel[label] = [...newchgs];
  }
  removeOrgCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newDeliveryStore.olddel[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newDeliveryStore.olddel[label][i]];
      }
    }
    newDeliveryStore.olddel[label] = [...newchgs];
  }

  updateOrgOrdOptions(opts) {
    newDeliveryStore.olddel.orderoptions = [...opts];
  }
  updateOrgAddChg(val, label, index) {
    newDeliveryStore.olddel[label] = val;
  }
  updateOrgAddChgNew(charge, label) {
    newDeliveryStore.olddel[label] = [
      ...newDeliveryStore.olddel[label],
      charge,
    ];
    newDeliveryStore.newdel[label] = [
      ...newDeliveryStore.newdel[label],
      charge,
    ];
  }
  updateOrgOrder(label) {
    let newdel = { ...newDeliveryStore.newdel };
    newDeliveryStore.olddel[label] = newdel[label];
  }

  updateZone(city, type) {
    let zoneind = zoneStore.zones.findIndex((zone) => zone.name === city);
    let zonecost = 0;
    let zonenum;
    if (zoneind >= 0) {
      zonenum = zoneStore.zones[zoneind].zone;
      zonecost = this.delCont.zoneadd[`z${zonenum}`];
    }
    let code = "f";
    if (type === "To") code = "t";
    if (code !== "t") {
      this.newdel.fromaddress.zonecost = zonecost;
      this.newdel.fromaddress.zonetype = zonenum;
    }
  }

  createNewOrder() {
    let fromaddress = { ...this.newdel.fromaddress };
    this.loaded.items = false;
    this.acitemmove = [];
    this.filter = "";
    this.newdel = {
      remarks: "",
      status: "Requested",
      jobdate: moment().set({ second: 0 }),
      createdAt: moment().set({ second: 0 }),
      maintype: this.delCont.maintype,
      fromaddress: { ...fromaddress },
      toaddress: [],

      prices: {
        tripmultiplier: this.delCont.dropoffmult,
        tripprice: 0,
        ppt: 0,
        typecost: 0,
        delday: 0,
      },
      addcharge: [],
      discount: [],
      orderoptions: [],
    };
    if (this.delCont.maintype === "Daily Per Truck") {
      this.newdel.prices.weekday = "Daily Truck Rate";
      this.newdel.prices.weekdayprice = this.delCont.pricepertruck;
      this.newdel.prices.tripmultiplier = 0;
    }
  }

  startLoading() {
    this.loading = true;
  }
}

const newDeliveryStore = new NewDeliveryStore();
export default newDeliveryStore;
