import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";

import Col4 from "../../../../Components/Columns/Col4";
import Div14 from "../../../../Components/Dividers/Div14";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import NewStorWarehouse from "./NewStorWarehouse";

class NewStorCont extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.editLoc = this.editLoc.bind(this);
  }
  editLoc(loc) {
    newAccountStore.editStorContEMLOC(loc);
  }
  render() {
    let data = newAccountStore.account;
    let storCont = newAccountStore.storCont;

    let storinputs;
    if (data.ast) {
      storinputs = (
        <div>
          <Div14>Warehouse Access:</Div14>
          <FlexboxGrid>
            {newAccountStore.emlocs.map((loc, index) => (
              <NewStorWarehouse loc={loc} key={index} editLoc={this.editLoc} />
            ))}
          </FlexboxGrid>
          <Div14>Pricing Method:</Div14>
          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Main Type"
                val={storCont.maintype}
                label="maintype"
                size="large"
                editVal={newAccountStore.editStorCont}
                opts={["Daily Rate", "Weekly Rate", "Monthly Rate"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Sub Type"
                val={storCont.subtype}
                label="subtype"
                size="large"
                editVal={newAccountStore.editStorCont}
                opts={["Average", "Maximum"]}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <Div14>Charges and Quantities:</Div14>
          <FlexboxGrid>
            <Col4>
              <NumbInputSTD
                title="Price Per CBM"
                val={storCont.pricepercbm}
                label="pricepercbm"
                size="large"
                editVal={newAccountStore.editStorCont}
              />
              <br />
            </Col4>
            <Col4>
              <NumbInputSTD
                title="Min CBM"
                val={storCont.mincbm}
                label="mincbm"
                size="large"
                editVal={newAccountStore.editStorCont}
              />
              <br />
            </Col4>
            <Col4>
              <NumbInputSTD
                title="In Charge (KD/CBM)"
                val={storCont.incharge}
                label="incharge"
                size="large"
                editVal={newAccountStore.editStorCont}
              />
              <br />
            </Col4>
            <Col4>
              <NumbInputSTD
                title="Out Charge (KD/CBM)"
                val={storCont.outcharge}
                label="outcharge"
                size="large"
                editVal={newAccountStore.editStorCont}
              />
              <br />
            </Col4>
          </FlexboxGrid>
          <Div14>Additional Details:</Div14>
          <FlexboxGrid>
            <Col md={24} sm={24} xs={24}>
              <TextAreaInputSTD
                title="Storage Contract Remarks"
                val={storCont.remarks}
                label="remarks"
                size="large"
                editVal={newAccountStore.editStorCont}
              />
            </Col>
          </FlexboxGrid>
        </div>
      );
    }

    let content = (
      <div>
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              icon={<Icon icon="cube" />}
              appearance={data.ast ? "primary" : "ghost"}
              color="green"
              onClick={() => newAccountStore.editAccount(!data.ast, "ast")}
              block
            >
              {data.ast ? "Remove" : "Add"} Storage Contract
            </IconButton>
          </Col2>
          {data.ast && (
            <Col2>
              <IconButton
                icon={<Icon icon={data.hidedel ? "eye-slash" : "eye"} />}
                appearance={data.hidedel ? "primary" : "ghost"}
                color="green"
                onClick={() => {
                  newAccountStore.editAccount(!data.hidedel, "hidedel");
                }}
                block
              >
                {data.hidedel ? "Hiding" : "Showing"} Delivery Jobs
              </IconButton>
            </Col2>
          )}
        </FlexboxGrid>
        {data.ast && storinputs}
      </div>
    );

    return (
      <div
        style={{
          padding: "2%",
          border: "1px solid black",
          borderRadius: "4px",
        }}
      >
        <Div14>Storage Contract</Div14>
        {data.adel ? (
          content
        ) : (
          <HelpBlock
            style={{ textAlign: "center", fontWeight: "bold", color: "red" }}
          >
            Activate Delivery Contract to show Storage Contract
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(NewStorCont);
