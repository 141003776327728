import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmTruckTripStore from "../../WHMStores/WHMTruckTripStore";
import TruckTripSummary from "../TruckTripSummary";
import Div14 from "../../../../Components/Dividers/Div14";
import TruckTripPalletTable from "../TruckTripPalletTable";
import FlexboxButton from "../../../../Components/Modal/FlexboxButton";
import whmOldWarehouseStore from "../../WHMStores/WHMOldWarehouseStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import SelectAvailablePallet from "../SelectAvailablePallet";
import { Divider, Icon, IconButton } from "rsuite";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import { message } from "antd";

import TruckTripNotFound from "../TruckTripNotFound";

import CountDownFunc from "../../../../Components/Counters/CountDownFunc";

const instance = axios.create({ baseURL: baseURL });

class LoadingTruckTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showdelete: false,
      showcomplete: false,
    };
    this.onHide = this.onHide.bind(this);
    this.deleteTruckTrip = this.deleteTruckTrip.bind(this);
    this.completeLoading = this.completeLoading.bind(this);
  }

  deleteTruckTrip = async (trucktripid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/deletetrucktrip/${trucktripid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.props.history.push("/whm/oldwarehouse");
        whmOldWarehouseStore.getPallets(true);
        whmOldWarehouseStore.getTrucks(true);
        whmOldWarehouseStore.getTruckTrip(true);
        message.success(`Truck Trip Deleted`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };
  completeLoading = async (trucktripid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/completeloading/${trucktripid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.props.history.push("/whm/oldwarehouse");
        whmOldWarehouseStore.getPallets(true);
        whmOldWarehouseStore.getTrucks(true);
        whmOldWarehouseStore.getTruckTrip(true);
        message.success(`Truck Trip Deleted`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };

  onHide() {
    this.setState({ show: false, showdelete: false, showcomplete: false });
  }
  componentDidMount() {
    whmTruckTripStore.getTruckTrip(this.props.match.params.trucktrip);
  }

  render() {
    let data = whmTruckTripStore.trucktrip;
    let showdelete = true;
    if (data.pallets) {
      if (data.pallets.length > 0) showdelete = false;
    }

    if (
      (data.status !== "Loading Start") &
      !whmTruckTripStore.loading.trucktrip
    ) {
      return <TruckTripNotFound />;
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          onHide={this.onHide}
          title="Delete Truck Trip"
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.deleteTruckTrip(this.props.match.params.trucktrip)
              }
            />
          }
        >
          <h4>Are you sure you want to delete this Truck Trip?</h4>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showcomplete}
          onHide={this.onHide}
          title="Complete Loading Job"
          footer={
            <ModalFooter
              icon={["close", "check"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes, Complete Loading"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.completeLoading(this.props.match.params.trucktrip)
              }
            />
          }
        >
          <h4>Are you sure you want to complete the Loading?</h4>
        </UnifiedModal>
        <UnifiedModal show={this.state.show} onHide={this.onHide} footer={null}>
          <SelectAvailablePallet onHide={this.onHide} />
        </UnifiedModal>
        <TruckTripSummary
          truck={data.whmtruck}
          trucktrip={data}
          loading={whmTruckTripStore.loading.trucktrip}
        />
        <Div14>
          Truck Trip Pallet List <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() =>
              whmTruckTripStore.getTruckTrip(
                this.props.match.params.trucktrip,
                true
              )
            }
            size="xs"
            circle
          />
        </Div14>
        <FlexboxButton
          icon="plus"
          text="Add a Pallet"
          color="green"
          app="ghost"
          oC={() => {
            this.setState({ show: true });
            whmOldWarehouseStore.getPallets(true);
          }}
        />

        <TruckTripPalletTable data={data.pallets} />
        <br />
        <div style={{ textAlign: "center" }}>
          <b>Loading Time: </b>
          <CountDownFunc starttime={data.createdAt} />
        </div>

        {showdelete ? (
          <FlexboxButton
            icon="trash"
            text="Delete Truck Trip"
            color="red"
            app="primary"
            oC={() => {
              this.setState({ showdelete: true });
            }}
          />
        ) : (
          <FlexboxButton
            icon="check-circle"
            text="Complete Loading"
            color="green"
            app="primary"
            oC={() => {
              this.setState({ showcomplete: true });
            }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(LoadingTruckTrip));
