import { observer } from "mobx-react";
import React, { Component } from "react";
import { Loader, Notification } from "rsuite";
import sjStore from "../../../../Stores/SideJobs/SJStore";

import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";
import SJLMTabs from "./SJTabs/LM/SJLMTabs";
import SJHeader from "./SJTabs/Others/SJHeader/SJHeader";
import SJIMTabs from "./SJTabs/IM/SJIMTabs";
import SJCCTabs from "./SJTabs/CC/SJCCTabs";
import SJSTTabs from "./SJTabs/ST/SJSTTabs";

const instance = axios.create({ baseURL: baseURL });

class SJAll extends Component {
  constructor(props) {
    super(props);
    this.state = { load: {} };
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
  }

  editVal(val, label, sj) {
    if (sj) {
      sjStore.esjEditJob(label, val, this.props.ind, sj);
    } else {
      sjStore.eEditJob(label, val, this.props.ind);
    }
  }

  updateVal = async (label, title, sj) => {
    let ind = this.props.ind;
    let esj = sjStore.esjs[ind];
    let val = esj[label];
    let url = `job/sidejobs/sjs/edit/${esj._id}`;

    if (sj) {
      url = `job/sidejobs/sjs/editunique/${esj._id}`;
      val = esj[sj][label];
    }
    let data = { [label]: val };

    this.setState({ load: { [label]: true } });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.eMainJob(label, val, ind, sj);
        this.setState({ load: { [label]: false } });
        message.success(`${title} Updated.`);
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };
  render() {
    if (sjStore.loading) {
      return <Loader center size="lg" />;
    }

    let editview = this.props.editview;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];

    let sjtab;
    if (sj.jobtype === "LM") {
      sjtab = (
        <SJLMTabs
          editview={editview}
          ind={ind}
          load={this.state.load}
          editVal={this.editVal}
          updateVal={this.updateVal}
        />
      );
    } else if (sj.jobtype === "IM") {
      sjtab = (
        <SJIMTabs
          editview={editview}
          ind={ind}
          load={this.state.load}
          editVal={this.editVal}
          updateVal={this.updateVal}
        />
      );
    } else if (sj.jobtype === "CC") {
      sjtab = (
        <SJCCTabs
          editview={editview}
          ind={ind}
          load={this.state.load}
          editVal={this.editVal}
          updateVal={this.updateVal}
        />
      );
    } else if (sj.jobtype === "ST") {
      sjtab = (
        <SJSTTabs
          editview={editview}
          ind={ind}
          load={this.state.load}
          editVal={this.editVal}
          updateVal={this.updateVal}
        />
      );
    }

    return (
      <div>
        <SJHeader ind={ind} />
        {sjtab}
      </div>
    );
  }
}

export default observer(SJAll);
