import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import completeDelStore from "../../../../../../Stores/AccountUpdated/Delivery/CompleteDelStore";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import DeliveryNotFound from "../../../../StorageAccount/SingleACOrderPage/PickupDropoff/DeliveryNotFound";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Descriptions } from "antd";
import { Parsed, PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import OrderSummaryTable from "../../../Modals/Supporting/OrderSummaryTable";
import OrderSummary from "./OrderSummary";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import SignDropoff from "./SignDropoff";

import DataGrid, { Column } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

class CompleteDropoff extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  Dimension(cellData) {
    return `${PRKD(cellData.data.length / 100)} m x ${PRKD(
      cellData.data.width / 100
    )} m x ${PRKD(cellData.data.height / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.length) *
        Parsed(cellData.data.width) *
        Parsed(cellData.data.quantity) *
        Parsed(cellData.data.height)) /
      1000000;
    return PRKD(cbmperitem);
  }
  componentDidMount() {
    completeDelStore.getSingleACOrderFromTo(
      this.props.match.params.toaddressid,
      true
    );
    this.setState({
      step: 1,
      showdelete: false,
      loading: false,
    });
  }
  render() {
    let content;
    let rows;
    let del = completeDelStore.newdel;
    let account = completeDelStore.account;
    let timechg = newDeliveryStore.ctimechg;

    if (
      completeDelStore.newdel.status !== "In Transit" ||
      completeDelStore.loading
    ) {
      content = <DeliveryNotFound />;
    } else {
      let to = completeDelStore.toaddress;
      if (del.maintype === "Fixed Per Type") {
        rows = (
          <div>
            <div
              style={{
                borderRadius: "5px",
                boxShadow: "1px 1px 5px black",
                padding: "1%",
                margin: "1%",
              }}
            >
              <Div14>Services:</Div14>
              <Descriptions
                labelStyle={{ width: "30%" }}
                title={false}
                bordered
                size="small"
                style={{ paddingBottom: "5px" }}
              >
                <Descriptions.Item
                  label={`Service Dropoff #${to.order}: ${to.service.type}`}
                  span={3}
                >
                  {PRKD(to.service.price)} KD
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        );
      } else {
        rows = (
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <Div14>Delivered Items for Location {to.order}:</Div14>

            <DataGrid
              dataSource={to.items}
              keyExpr="name"
              allowColumnReordering={true}
              allowColumnResizing={true}
              showBorders={true}
              rowAlternationEnabled
              style={{ fontSize: 10 }}
              hoverStateEnabled={true}
              noDataText="No Items Added"
            >
              <FilterRow visible={false} applyFilter />
              <GroupPanel visible={false} />

              <Scrolling mode="standard" showScrollbar="always" />

              <ColumnFixing enabled={true} />
              <Column caption="Item Details" alignment="center">
                <Column
                  dataField="name"
                  caption="Item"
                  alignment="center"
                  minWidth={80}
                />
                <Column
                  dataField="serialnumber"
                  caption="Ser. No."
                  alignment="center"
                  minWidth={80}
                />
              </Column>
              <Column
                dataField="dimensions"
                caption="Dimensions"
                alignment="center"
                minWidth={140}
                cellRender={this.Dimension}
              />
              <Column
                dataField="quantity"
                dataType="number"
                caption="Quantity"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={80}
              />
              <Column caption="CBM Calcs" alignment="center">
                <Column
                  dataField="cbmperitem"
                  dataType="number"
                  caption="CBM Per Item"
                  alignment="center"
                  minWidth={80}
                  cellRender={this.CBMPerItem}
                />
                <Column
                  dataField="totalcbm"
                  dataType="number"
                  caption="Total CBM"
                  alignment="center"
                  minWidth={80}
                  cellRender={this.TotalCBM}
                />
              </Column>
            </DataGrid>
          </div>
        );
      }
      content = (
        <div>
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <Div14>Order Summary:</Div14>
            <OrderSummaryTable
              del={del}
              hidetitle
              hidecost
              account={completeDelStore.account}
              timechg={newDeliveryStore.ctimechg}
            />
            <Div14>Dropoff Location #{to.order}:</Div14>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Address" span={24}>
                {to.address}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{to.name}</Descriptions.Item>
              <Descriptions.Item label="Phone">{to.phone}</Descriptions.Item>
              <Descriptions.Item label="Type">{to.type}</Descriptions.Item>
              <Descriptions.Item label="City">{to.city}</Descriptions.Item>
              <Descriptions.Item label="Floor">{to.floor}</Descriptions.Item>
            </Descriptions>
          </div>
          {rows}
          <div
            style={{
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              padding: "1%",
              margin: "1%",
            }}
          >
            <Div14>Signature:</Div14>
            <SignDropoff type="Dropoff" />
          </div>
          <hr />
          <OrderSummary del={del} account={account} timechg={timechg} />
        </div>
      );
    }
    return <LoadBack loading={completeDelStore.loading}>{content}</LoadBack>;
  }
}

export default withRouter(observer(CompleteDropoff));
