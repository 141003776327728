import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton, Steps } from "rsuite";
import { Notification } from "rsuite";
import Step1 from "./Steps/Step1";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import accInvoiceStore from "../../../../../../Stores/Financial/ACCInvoiceStore";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import Col4 from "../../../../../../Components/Columns/Col4";
import Col2 from "../../../../../../Components/Columns/Col2";

const instance = axios.create({ baseURL: baseURL });

class EditACCInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1, loading: false };
    this.changeStep = this.changeStep.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postNewInvoice = this.postNewInvoice.bind(this);
  }
  onHide() {
    if (!this.state.loading) {
      this.setState({ step: 1 });
      this.props.onHide();
    }
  }
  changeStep(val) {
    this.setState({ step: this.state.step + val });
  }

  postNewInvoice = async () => {
    let data = accInvoiceStore.newaccinvoice;

    let final = {
      title: data.title,
      date: data.date,
      status: data.status,
      remarks: data.remarks,
      showBank: data.showBank,
      invnotes: data.invnotes,
    };

    let url = "";
    url = `jf/accinvoice/edit/${data._id}`;

    this.setState({ loading: true });
    await instance
      .put(url, final, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        this.onHide();
        Notification["success"]({ title: `ACC Invoice Updated` });
        accInvoiceStore.getInvPayments(true);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  render() {
    let disabled = false;
    let content;
    let inv = accInvoiceStore.newaccinvoice;
    let step = this.state.step;
    if (step === 1) {
      content = <Step1 />;
      if (inv.title === "") disabled = true;
    } else if (step === 2) {
      content = <Step3 />;
    } else if (step === 3) {
      content = <Step4 />;
    }

    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          title={`Edit Existing Invoice`}
          onHide={this.onHide}
          footer={<div />}
        >
          <Steps current={step - 1}>
            <Steps.Item title="Invoice Main Info" />
            <Steps.Item title="Invoice Notes" />
            <Steps.Item title="Save" />
          </Steps>
          <FlexboxGrid>
            <Col4>
              {step !== 1 && (
                <IconButton
                  loading={this.state.loading}
                  icon={<Icon icon="left" />}
                  color="orange"
                  onClick={() => this.setState({ step: step - 1 })}
                  block
                >
                  Back
                </IconButton>
              )}
            </Col4>
            <Col2 />
            {step < 3 ? (
              <Col4>
                <IconButton
                  loading={this.state.loading}
                  icon={<Icon icon="right" />}
                  color="green"
                  onClick={() => {
                    this.setState({ step: step + 1 });
                  }}
                  block
                  placement="right"
                  disabled={disabled}
                >
                  Next
                </IconButton>
              </Col4>
            ) : (
              <Col4>
                <IconButton
                  loading={this.state.loading}
                  icon={<Icon icon="save" />}
                  color="green"
                  onClick={() => {
                    this.postNewInvoice();
                  }}
                  block
                  placement="right"
                >
                  Save Updated Invoice
                </IconButton>
              </Col4>
            )}
          </FlexboxGrid>
          {content}
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(EditACCInvoiceModal));
