import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Col,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";

import Col4 from "../../../../Components/Columns/Col4";
import Div14 from "../../../../Components/Dividers/Div14";
import SelectInputSTU from "../../../../Components/Inputs/Updatable/SelectInputSTU";
import NumbInputSTU from "../../../../Components/Inputs/Updatable/NumbInputSTU";
import TextAreaInputSTU from "../../../../Components/Inputs/Updatable/TextAreaInputSTU";

import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import EditStorWarehouse from "./EditStorWarehouse";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class EditStorCont extends Component {
  constructor(props) {
    super(props);
    this.state = { load: {} };
    this.editLoc = this.editLoc.bind(this);
    this.updateStorCont = this.updateStorCont.bind(this);
  }
  updateStorCont = async (label) => {
    let stoCont = newAccountStore.storCont;
    let data = { [label]: stoCont[label] };

    this.setState({ load: { [label]: true } });
    return instance
      .put(`/ac/account/editstocont/${stoCont.client}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAccountStore.updateStorCont(stoCont[label], label);
        message.success(
          `Successfully Updated Storage Contract Info (${label})`
        );
        this.setState({ load: { [label]: false } });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ load: { [label]: false } });
      });
  };
  editLoc(loc) {
    newAccountStore.editStorContEMLOC(loc);
  }
  render() {
    let data = newAccountStore.orgaccount;
    let newdata = newAccountStore.account;
    let newstorCont = newAccountStore.storCont;
    let storCont = newAccountStore.orgstorCont;
    let load = this.state.load;
    let storinputs;
    if (data.ast) {
      storinputs = (
        <div>
          <Div14>Warehouse Access:</Div14>
          <div>
            <FlexboxGrid justify="center">
              {newstorCont.emlocation !== storCont.emlocation && (
                <Col md={24} sm={24} xs={24}>
                  <HelpBlock
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Warehouse Changed
                  </HelpBlock>
                </Col>
              )}
              <Col4>
                <IconButton
                  icon={<Icon icon="undo" />}
                  onClick={() =>
                    newAccountStore.editStorCont(
                      storCont.emlocation,
                      "emlocation"
                    )
                  }
                  block
                  color="red"
                  disabled={newstorCont.emlocation === storCont.emlocation}
                >
                  Undo Changes
                </IconButton>
                <br />
              </Col4>
              <Col4>
                <IconButton
                  icon={<Icon icon="save" />}
                  onClick={() => this.updateStorCont("emlocation")}
                  block
                  disabled={newstorCont.emlocation === storCont.emlocation}
                  color="green"
                >
                  Save Changes
                </IconButton>
              </Col4>
              <br />
            </FlexboxGrid>
          </div>
          <FlexboxGrid>
            {newAccountStore.emlocs.map((loc, index) => (
              <EditStorWarehouse loc={loc} key={index} editLoc={this.editLoc} />
            ))}
          </FlexboxGrid>
          <Div14>Pricing Method:</Div14>
          <FlexboxGrid>
            <Col2>
              <SelectInputSTU
                val={newstorCont.maintype}
                org={storCont.maintype}
                label="maintype"
                title="Main Type"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                opts={["Daily Rate", "Weekly Rate", "Monthly Rate"]}
                load={load.maintype}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTU
                val={newstorCont.subtype}
                org={storCont.subtype}
                label="subtype"
                title="Sub Type"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                opts={["Average", "Maximum"]}
                load={load.subtype}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <Div14>Charges and Quantities:</Div14>
          <FlexboxGrid>
            <Col4>
              <NumbInputSTU
                val={newstorCont.pricepercbm}
                org={storCont.pricepercbm}
                label="pricepercbm"
                title="Price Per CBM"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                load={load.pricepercbm}
              />
              <br />
            </Col4>
            <Col4>
              <NumbInputSTU
                val={newstorCont.mincbm}
                org={storCont.mincbm}
                label="mincbm"
                title="Min CBM"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                load={load.mincbm}
              />
              <br />
            </Col4>
            <Col4>
              <NumbInputSTU
                val={newstorCont.incharge}
                org={storCont.incharge}
                label="incharge"
                title="In Charge (KD/CBM)"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                load={load.incharge}
              />
              <br />
            </Col4>
            <Col4>
              <NumbInputSTU
                val={newstorCont.outcharge}
                org={storCont.outcharge}
                label="outcharge"
                title="Out Charge (KD/CBM)"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                load={load.outcharge}
              />
              <br />
            </Col4>
          </FlexboxGrid>
          <Div14>Additional Details:</Div14>
          <FlexboxGrid>
            <Col md={24} sm={24} xs={24}>
              <TextAreaInputSTU
                val={newstorCont.remarks}
                org={storCont.remarks}
                label="remarks"
                title="Storage Contract Remarks"
                size="large"
                editVal={newAccountStore.editStorCont}
                updateVal={this.updateStorCont}
                load={load.remarks}
              />
            </Col>
          </FlexboxGrid>
        </div>
      );
    }

    let content = (
      <div>
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              icon={<Icon icon="cube" />}
              appearance={newdata.ast ? "primary" : "ghost"}
              color="green"
              onClick={() => newAccountStore.editAccount(!newdata.ast, "ast")}
              block
            >
              {newdata.ast ? "Remove" : "Add"} Storage Contract
            </IconButton>
            {newdata.ast !== data.ast && (
              <div>
                <FlexboxGrid justify="center">
                  <Col md={24} sm={24} xs={24}>
                    <HelpBlock
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >{`${
                      data.ast ? "De-Activated" : "Activated"
                    } Storage Contract`}</HelpBlock>
                  </Col>
                  <Col2>
                    <IconButton
                      icon={<Icon icon="undo" />}
                      onClick={() =>
                        newAccountStore.editAccount(data.ast, "ast")
                      }
                      block
                      color="red"
                    >
                      Undo Changes
                    </IconButton>
                  </Col2>
                  <Col2>
                    <IconButton
                      icon={<Icon icon="save" />}
                      onClick={() => this.props.updateAcc("ast")}
                      block
                      color="green"
                    >
                      Save Changes
                    </IconButton>
                  </Col2>
                </FlexboxGrid>
              </div>
            )}
          </Col2>
          {data.ast && (
            <Col2>
              <IconButton
                icon={<Icon icon={newdata.hidedel ? "eye-slash" : "eye"} />}
                appearance={newdata.hidedel ? "primary" : "ghost"}
                color="green"
                onClick={() => {
                  newAccountStore.editAccount(!newdata.hidedel, "hidedel");
                }}
                block
              >
                {newdata.hidedel ? "Hiding" : "Showing"} Delivery Jobs
              </IconButton>
              {newdata.hidedel !== data.hidedel && (
                <div>
                  <FlexboxGrid justify="center">
                    <Col md={24} sm={24} xs={24}>
                      <HelpBlock
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >{`${
                        data.hidedel ? "Showing" : "Hiding"
                      } Delivery Jobs`}</HelpBlock>
                    </Col>
                    <Col2>
                      <IconButton
                        icon={<Icon icon="undo" />}
                        onClick={() =>
                          newAccountStore.editAccount(data.hidedel, "hidedel")
                        }
                        block
                        color="red"
                      >
                        Undo Changes
                      </IconButton>
                    </Col2>
                    <Col2>
                      <IconButton
                        icon={<Icon icon="save" />}
                        onClick={() => this.props.updateAcc("hidedel")}
                        block
                        color="green"
                      >
                        Save Changes
                      </IconButton>
                    </Col2>
                  </FlexboxGrid>
                </div>
              )}
            </Col2>
          )}
        </FlexboxGrid>
        {data.ast && storinputs}
      </div>
    );

    return (
      <div
        style={{
          padding: "2%",
          border: "1px solid black",
          borderRadius: "4px",
        }}
      >
        <Div14>Storage Contract</Div14>
        {data.adel ? (
          content
        ) : (
          <HelpBlock
            style={{ textAlign: "center", fontWeight: "bold", color: "red" }}
          >
            Activate Delivery Contract to show Storage Contract
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(EditStorCont);
