import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 11,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 3,
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "20%",
  },
  tableCol60white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "60%",
  },
  tableCol80blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "80%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "20%",
  },
});

class WhitePaymentRows extends Component {
  render() {
    let proposals = this.props.proposals;
    let title = this.props.title;

    let proposalrows = proposals.map((prop, index) => (
      <View key={index}>
        {(prop.type === "ST") & (prop.jobtype === "ST") ? (
          <View style={{ flexDirection: "row" }} key={index}>
            <Text style={styles.tableCol20white}>
              {prop.jobtype}-{prop.numId}
            </Text>
            <Text style={styles.tableCol60white}>
              {prop.time} x {prop.title}
            </Text>
            <Text style={styles.tableCol20white}>{PRKD(prop.ammount)} KD</Text>
          </View>
        ) : (
          <View style={{ flexDirection: "row" }} key={index}>
            <Text style={styles.tableCol20white}>
              {prop.jobtype}-{prop.numId}
            </Text>
            <Text style={styles.tableCol60white}>{prop.title}</Text>
            <Text style={styles.tableCol20white}>{PRKD(prop.ammount)} KD</Text>
          </View>
        )}
      </View>
    ));

    let total = 0;
    for (let i = 0; i < proposals.length; i++) {
      total += parseFloat(proposals[i].ammount);
    }

    return (
      <View>
        <Text style={styles.tableHeaderStyle}>
          {title}: ({proposals.length} Items for {PRKD(total)} KD)
        </Text>
        <View>{proposalrows}</View>
      </View>
    );
  }
}

export default observer(WhitePaymentRows);
