import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import { SingleSJPriceCalc } from "../../Functions/Pricing/SJPriceCalc";
import { PRKD } from "../../Functions/Pricing/PRKDCalc";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class CalendarStore {
  constructor() {
    this.dates = [];
    this.cancelled = [];
    this.converted = [];
    this.loading = false;
    this.dataloaded = false;
    this.cancloaded = false;
    this.startdate = moment().subtract(180, "days").startOf("month");
    this.filter = {
      lead: false,
      prop: false,
      book: true,
      conv: true,
      canc: false,
      im: true,
      lm: true,
      cc: true,
      st: true,
    };

    makeObservable(this, {
      dates: observable,
      cancelled: observable,
      converted: observable,
      loading: observable,
      dataloaded: observable,
      filter: observable,
      startdate: observable,
      cancloaded: observable,
      filtdates: computed,
    });
  }

  // FETCH
  getResetOverride() {
    this.dataloaded = false;
    this.cancelled = [];
    this.converted = [];
    this.getCalendarMain(true);
  }

  getCalendarMain(override) {
    let loaded;
    if (this.loading || this.dataloaded) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.dataloaded = false;
      this.loading = true;
      let data = { startdate: this.startdate };
      return instance
        .put(`/job/sidejobs/sjs/getcalendar/all/all`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let dates = [...data];

          for (let i = 0; i < dates.length; i++) {
            dates[i].title = `${dates[i].jobtype}: ${dates[i].type} - ${
              dates[i].clientname
            } ${PRKD(dates[i].cbm)} CBM for ${PRKD(dates[i].lastprice)} KD`;
          }

          this.cancloaded = false;
          this.dataloaded = true;
          this.loading = false;
          this.dates = dates;
          console.log("Calendar Jobs Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getCancelledCalendarMain(override) {
    let loaded;
    if (this.loading || this.cancloaded) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.cancloaded = false;
      this.loading = true;
      let data = { startdate: this.startdate };
      return instance
        .put(`/job/sidejobs/sjs/getcalendar/all/Cancelled`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let dates = [...data];
          let price = 0;
          for (let i = 0; i < dates.length; i++) {
            price = SingleSJPriceCalc(dates[i]);
            dates[i].title = `${dates[i].jobtype}: ${dates[i].type} - ${
              dates[i].clientname
            } ${PRKD(dates[i].cbm)} CBM for ${PRKD(price[0])} KD`;
          }

          this.cancloaded = true;
          this.loading = false;
          this.dates = [...this.dates, ...dates];
          console.log("Cancelled Calendar Jobs Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get filtdates() {
    let dates = [...this.dates];
    if (!this.filter.lead) {
      dates = dates.filter((date) => date.status !== "Lead");
    }
    if (!this.filter.prop) {
      dates = dates.filter((date) => date.status !== "Proposal");
    }
    if (!this.filter.book) {
      dates = dates.filter((date) => date.status !== "Booked");
    }
    if (!this.filter.conv) {
      dates = dates.filter((date) => date.status !== "Converted");
    }
    if (!this.filter.canc) {
      dates = dates.filter((date) => date.status !== "Cancelled");
    }
    if (!this.filter.lm) {
      dates = dates.filter((date) => date.jobtype !== "LM");
    }
    if (!this.filter.im) {
      dates = dates.filter((date) => date.jobtype !== "IM");
    }
    if (!this.filter.cc) {
      dates = dates.filter((date) => date.jobtype !== "CC");
    }
    if (!this.filter.st) {
      dates = dates.filter((date) => date.jobtype !== "ST");
    }
    return dates;
  }

  // FUNCTIONS:
  changeFilt(label) {
    this.filter[label] = !this.filter[label];
  }
  changeDate(val) {
    calendarStore.startdate = moment(val).startOf("month");
  }
}

const calendarStore = new CalendarStore();
export default calendarStore;
