import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

import { InputNumber } from "antd";

class NumberInputAntd extends Component {
  render() {
    return (
      <div>
        <br />
        <h6 style={{ color: this.props.val === "" && "red" }}>
          {this.props.label}:
        </h6>
        <InputNumber
          size="large"
          value={this.props.val}
          style={{ width: "100%" }}
          placeholder={this.props.label}
          onChange={(val) => this.props.changeVal(this.props.type, val)}
        />
      </div>
    );
  }
}

export default withRouter(observer(NumberInputAntd));
