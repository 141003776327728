import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { CCPriceCalc } from "../../../../Functions/Pricing/CCPriceCalc";
import { IMPriceCalc } from "../../../../Functions/Pricing/IMPriceCalc";
import { LMPriceCalc } from "../../../../Functions/Pricing/LMPriceCalc";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import { STPriceCalc } from "../../../../Functions/Pricing/STPriceCalc";

function getTotal(sj) {
  let price;
  if (sj.jobtype === "LM") price = LMPriceCalc(sj);
  if (sj.jobtype === "IM") price = IMPriceCalc(sj);
  if (sj.jobtype === "CC") price = CCPriceCalc(sj);
  if (sj.jobtype === "ST") price = STPriceCalc(sj);
  let total = PRKD(price[0]);
  let job = PRKD(price[1]);
  let add = PRKD(price[2]);

  let value = [total, job, add];
  return value;
}

class SideJobTable extends Component {
  render() {
    let data = this.props.data;
    let type = this.props.type;
    let numId = type.toLowerCase() + "NumId";

    let columnnames = [
      {
        title: "Job ID",
        field: "job.jobNumId",
      },
      {
        title: type + "-ID",
        field: numId,
        defaultSort: "asc",
        render: (rowData) => {
          return rowData.jobtype + "-" + rowData.sjNumId;
        },
      },
      { title: "Status", field: "status" },
      { title: "Client", field: "job.client.name" },
      {
        title: "SubType",
        field: "subtype",
        render: (rowData) => {
          let value = rowData.subtype;
          if (rowData.jobtype === "ST") {
            value = `${rowData.subtype} (${rowData.storagestatus})`;
          } else if (rowData.jobtype === "IM") {
            value = `${rowData.direction} (${rowData.shippingmethod})`;
          }
          return value;
        },
      },
      { title: "CBM", field: "cbm" },
      {
        title: "Total",
        field: "lastprice",
        render: (rowData) => {
          let value = getTotal(rowData);
          return value[0];
        },
      },
      {
        title: "Price",
        field: "lastprice",
        render: (rowData) => {
          let value = getTotal(rowData);
          return value[1];
        },
      },
      {
        title: "Add",
        field: "addcharge",
        render: (rowData) => {
          let value = getTotal(rowData);
          return value[2];
        },
      },
      { title: "Focal", field: "job.focalpoint.username" },
      {
        title: "Dates",
        field: "jobdate",
        render: (rowData) => {
          let minJobDate = "No Dates Yet";
          if (rowData.jobtype === "CC") {
            minJobDate = `Arrives ${moment(rowData.vesselarrival).format(
              "DD-MMM-YY"
            )}`;
          } else {
            if (rowData.jobdate.length === 1) {
              minJobDate = `(${moment(rowData.jobdate[0].date).format(
                "DD-MMM"
              )})`;
            } else if (rowData.jobdate.length > 1) {
              minJobDate = `(${moment(rowData.jobdate[0].date).format(
                "DD-MMM"
              )} to ${moment(
                rowData.jobdate[rowData.jobdate.length - 1].date
              ).format("DD-MMM")})`;
            }
          }
          return minJobDate;
        },
      },
    ];

    let tableTitle = type + " Jobs";
    return (
      <div style={{ padding: "1%" }}>
        <MaterialTable
          title={data.length + " " + tableTitle}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100, 200],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : tableTitle
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.props.showModal(true, rowData);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default observer(SideJobTable);
