import { observer } from "mobx-react";

import React, { Component } from "react";
import { Steps } from "rsuite";

class TimelineDate extends Component {
  render() {
    let hidden = this.props.hidden;
    let active = this.props.step;

    let steps = this.props.steps;

    if (active < 0) {
      active = 0;
    }

    let finalsteps = steps.map((step, index) => (
      <Steps.Item
        key={index}
        title={hidden ? "" : step.title}
        description={step.desc}
        status={step.status}
      />
    ));

    return (
      <div>
        <Steps
          current={active}
          style={{ color: "red" }}
          small
          currentStatus={active === steps.length ? "finish" : "process"}
        >
          {finalsteps}
        </Steps>
      </div>
    );
  }
}

export default observer(TimelineDate);
