import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import { Tabs } from "antd";
import AdminAccountLocations from "../Locations/AdminAccountLocations";

import { observer } from "mobx-react";
import AdminAccountStandardItems from "../Items/AdminAccountStandardItems";

const { TabPane } = Tabs;

class AccAdminSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "Location",
    };
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };
  render() {
    return (
      <div>
        <Div14>Admin Settings Page</Div14>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane
            tab={`Saved Locations`}
            key={"Location"}
            disabled={this.state.locked}
          >
            <AdminAccountLocations
              clientid={this.props.match.params.clientid}
            />
          </TabPane>
          <TabPane
            tab={`Standard Items`}
            key={"Items"}
            disabled={this.state.locked}
          >
            <AdminAccountStandardItems
              clientid={this.props.match.params.clientid}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default observer(AccAdminSettings);
