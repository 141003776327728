let pagetitles = [
  { path: "clientslist", title: "Clients List" },
  { path: "supplier", title: "IM Supplier List" },
  { path: "calendar", title: "Calendar" },
  { path: "accounts", title: "Accounts" },
  { path: "editaccount", title: "Edit Account" },
  { path: "ac", title: "Account Page" },
  { path: "acorder", title: "Storage Account Order" },
  { path: "delorder", title: "Delivery Account Order" },
  { path: "acorderconfdelin", title: "Pickup Confirmation" },
  { path: "acorderconfdelout", title: "Dropoff Confirmation" },
  { path: "acorderconfstodelin", title: "Storage In Confirmation" },
  { path: "acorderconfstoin", title: "Storage In Confirmation" },
  { path: "acorderconfstodelout", title: "Storage Out Confirmation" },
  { path: "acorderconfstoout", title: "Storage Out Confirmation" },
  { path: "delconfpickup", title: "Pickup Confirmation" },
  { path: "delconfdropoff", title: "Dropoff Confirmation" },
  { path: "sacstaff", title: "Staff Storage Dashboard" },
  { path: "sac", title: "Storage Account Page" },
  { path: "sacinvoice", title: "Storage Invoice Page" },
  { path: "dac", title: "Delivery Account" },
  { path: "dacinvoice", title: "Delivery Account Invoice" },
  { path: "acc", title: "Custom Clearance Account" },
  { path: "aim", title: "International Move Account" },
  { path: "acconfirmdel0", title: "Account Order Actions" },
  { path: "acf", title: "Account Finance Page" },
  { path: "admin", title: "Admin" },
  { path: "staffhr", title: "Staff HR" },
  { path: "truckmap", title: "Truck Map" },
  { path: "mgt", title: "Management" },
  { path: "acedit", title: "Edit Account" },
  { path: "profile", title: "Profile" },
  { path: "singlejob", title: "EM Job" },
  { path: "trackdelivery", title: "Delivery Tracking" },
  { path: "feedback", title: "Feedback" },
  { path: "itemtrack", title: "Item Track" },
  { path: "confirmemail", title: "Email Confirmation" },
  { path: "login", title: "Login" },
  { path: "signup", title: "Sign Up" },
  { path: "forgotpass", title: "Forgot Password" },
  { path: "resetpassword", title: "Reset Password" },
  { path: "acitem", title: "Account Item" },
  { path: "hr", title: "Human Resources" },
  { path: "clientbalances", title: "Client Balances" },
  { path: "combinedcalendar", title: "Combined Calendar" },
  { path: "", title: "Easy Move Supply Chain" },
  { path: "em-home", title: "Easy Move Supply Chain" },
  { path: "em-aboutus", title: "About Us" },
  { path: "em-storage", title: "Storage Solutions" },
  { path: "em-localmove", title: "Local Move" },
  { path: "em-internationalmove", title: "Inter. Move" },
  { path: "em-lastmile", title: "Last Mile" },
  { path: "em-customclearance", title: "Custom Clearance" },
  { path: "em-contactus", title: "Contact Us" },
  { path: "em-warehousing", title: "Warehousing" },
  { path: "em-freightforwarding", title: "Freight Forwarding" },
  { path: "em-fullfillment", title: "Fulfillment Services" },
  { path: "damagereports", title: "Damage Reports" },
  { path: "tlaccounts", title: "Crew Leader Jobs" },
  { path: "whaccounts", title: "Warehouse Accounts" },
  { path: "staffdailyattendance", title: "Staff Attendance" },
];
export default pagetitles;
