import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ParallaxImage from "../../../Components/Parallax/ParallaxImage";
import userStore from "../../../Stores/UserStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import pic from "../../../Static/Images/CompanyPics/pic4.png";
import Col2 from "../../../Components/Columns/Col2";
import IconItem from "../../../Components/Lists/IconItem";
import SingleBenefit from "../Sections/Benefits/SingleBenefit";

class WarehousingSupplyChain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ParallaxImage image={pic} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              Warehousing
            </p>
            <p style={{ fontFamily: "Work Sans", fontSize: 20 }}>
              With Easy Move
            </p>
            <hr style={{ border: "1px solid white" }} />
            <p style={{ fontFamily: "sans-serif", fontSize: 24 }}>
              + 5,000 m2 Warehousing Space
            </p>

            <div style={{ textShadow: "1px 1px black" }}>
              <p style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                Maximize your storage potential with our comprehensive
                warehousing solution. Our state-of-the-art facilities,
                cutting-edge technology, and experienced team provide real-time
                inventory management, flexible and cost-effective storage
                options, and efficient order fulfillment. Trust us to streamline
                your operations and provide peace of mind for your valuable
                assets.
              </p>
            </div>
            <FlexboxGrid>
              <Col2>
                <IconItem icon="cube" text="Fulfillment Service" />
                <br />
                <IconItem icon="barcode" text="Stock Control" />
                <br />
              </Col2>
              <Col2>
                <IconItem icon="pc" text="Fully Digitalized" />
                <br />
                <IconItem icon="thermometer" text="Temperature Controlled" />
                <br />
              </Col2>
            </FlexboxGrid>
            {!userStore.signedIn && (
              <>
                <hr style={{ border: "1px solid white" }} />
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="lg"
                  color="blue"
                  onClick={() => this.props.history.push("/login")}
                >
                  Join Easy Move Today!
                </IconButton>
              </>
            )}
          </div>
        </ParallaxImage>
        <SingleBenefit />
      </div>
    );
  }
}

export default withRouter(observer(WarehousingSupplyChain));
