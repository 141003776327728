import { makeObservable, observable } from "mobx";
import userStore from "../UserStore";
import axios from "axios";
import baseURL from "../../Static/baseURL/baseURL";
import { Notification } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class ItemQRStore {
  constructor() {
    this.items = [];
    this.singleitem = {};
    this.company = "";
    this.loading = false;

    makeObservable(this, {
      items: observable,
      loading: observable,
      singleitem: observable,
      company: observable,
    });
  }
  //

  singleItemFetch(typeid, type) {
    this.loading = true;
    let url = `/ac/itemsrequest/qrsingleitem/${typeid}`;
    if (type === "Order") {
      url = `/ac/itemsrequest/qrbyorder/${typeid}`;
    }
    return instance
      .get(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((sto) => {
        this.items = sto;
        if (sto.length <= 0) {
          Notification["error"]({
            title: "No Items Found",
            description: "Please check the Item Number ID and try again.",
          });
        }

        this.loading = false;

        console.log("Item QR Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Existing Items.`,
        });
      });
  }

  editAllItem(val, index) {
    itemQRStore.items[index].actualquantity = val;
  }
}

const itemQRStore = new ItemQRStore();
export default itemQRStore;
