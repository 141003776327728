import { DatePicker } from "antd";
import { observer } from "mobx-react";

import React, { Component } from "react";
import { InputGroup } from "rsuite";
const { RangePicker } = DatePicker;

class RangePickerSTD extends Component {
  render() {
    let title = this.props.title;
    let disabled = this.props.disabled;
    let label = this.props.label;
    let val = this.props.val;
    let size = "large";
    if (this.props.size) size = this.props.size;

    let picker = "date";
    if (this.props.picker) picker = this.props.picker;
    let format = "DD-MMM-YY";
    if (this.props.format) format = this.props.format;

    return (
      <InputGroup>
        <InputGroup.Addon>{title}</InputGroup.Addon>
        <RangePicker
          style={{ width: "100%" }}
          //format={"DD-MMM-YY @ HA"}
          format={format}
          value={val}
          allowClear={false}
          disabled={disabled}
          size={size}
          picker={picker}
          showMinute={false}
          onChange={(date) => {
            this.props.editVal(date, label);
          }}
        />
      </InputGroup>
    );
  }
}

export default observer(RangePickerSTD);
