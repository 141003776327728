import { Image } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import moment from "moment";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import Col2 from "../../../../../../Components/Columns/Col2";
import { Buffer } from "buffer";

class SignatureWrapperDel extends Component {
  constructor(props) {
    super(props);
    this.state = { new: false, changed: false };
    this.sigPad = {};
  }

  clearSign() {
    this.sigPad.clear();
  }

  trimAndUpload = async () => {
    let signatureimage = this.sigPad.toDataURL("image/png");
    this.props.changeST(signatureimage, "sig");
    this.setState({ new: false });
  };
  render() {
    let src = null;

    let oldsig = this.props.oldsig;

    let imagesrc;

    if (this.props.sig) {
      src = this.props.sig;
      imagesrc = src;
    } else if (oldsig) {
      src = oldsig.signature.data;
      let b64 = new Buffer(src).toString("base64");
      imagesrc = `data:image/png;base64,${b64}`;
    }

    let content = (
      <div>
        <ModalFooter
          icon={["close", "save"]}
          color={["red", "green"]}
          app={["ghost", "default"]}
          text={["Cancel", "Save Signature"]}
          oC1={() => this.setState({ new: false })}
          oC2={() => this.trimAndUpload()}
          disabled2={!this.state.changed}
        />

        <h6 style={{ textAlign: "center" }}>
          Date: {moment().format("DD-MMM-YY HH:mm")}
        </h6>
        <div
          style={{
            border: "1px solid black",
            maxWidth: "300px",
            height: "150px",
          }}
          onClick={() => this.setState({ changed: true })}
        >
          <SignaturePad
            removeBlanks
            options={{
              penColor: "blue",
              minWidth: 2,
              maxWidth: 3,
            }}
            redrawOnResize={true}
            disabled={true}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </div>
        <IconButton
          icon={<Icon icon="refresh" />}
          color="orange"
          size="xs"
          block
          onClick={() => this.clearSign()}
          disabled={!this.state.changed}
        >
          Clear Signature
        </IconButton>
      </div>
    );
    if (!this.state.new) {
      content = (
        <FlexboxGrid style={{ height: "235px" }}>
          <Col2>
            <Image src={imagesrc} preview={false} width={300} />
          </Col2>
          <Col2>
            <IconButton
              onClick={() => this.setState({ new: true })}
              color="green"
              icon={<Icon icon="edit" />}
              appearance={this.props.sig === null ? "primary" : "ghost"}
            >
              {this.props.sig === null ? "Add" : "Update"} Signature
            </IconButton>
          </Col2>
        </FlexboxGrid>
      );
    }

    return <FlexboxGrid justify="center">{content}</FlexboxGrid>;
  }
}

export default observer(SignatureWrapperDel);
