import { Container, Paper } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";

/// BASIC PAGE No Title

class StdPage2 extends Component {
  render() {
    return (
      <Container>
        <br />
        <Paper elevation={3} style={{ padding: "1%" }}>
          {this.props.children}
        </Paper>
      </Container>
    );
  }
}

export default withRouter(observer(StdPage2));
