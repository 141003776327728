import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, HelpBlock, Loader, Notification } from "rsuite";
import newStorageStore from "../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import SelectInputSTU from "../../../../Components/Inputs/Updatable/SelectInputSTU";
import { Badge, message, Statistic, Tabs } from "antd";
import CostSummaryTable from "../Modals/Supporting/CostSummaryTable";

import SingleACOrderHeader from "./SingleACOrderHeader";
import CancelJobModal from "../Modals/CancelJobModal";
import moment from "moment";
import Timing from "./Tabs/Timing";
import zoneStore from "../../../../Stores/Admin/Lists/ZoneStore";
import AdditionalCharges from "./Tabs/AdditionalCharges";
import Discounts from "./Tabs/Discounts";
import Options from "./Tabs/Options";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import Col2 from "../../../../Components/Columns/Col2";

import carStore from "../../../../Stores/AdditionalStores/CarStore";
import DeliveryNote from "./Tabs/DeliveryNote";

import StorageNote from "./Tabs/StorageNote";
import LocationMain from "./Tabs/LocationMain";
import ItemsListMain from "./Tabs/ItemsListMain";
import PickupDropoffButtons from "./PickupDropoffButtons";
import LiveTracking from "../../../../Components/CarTracking/LiveTracking";
import Div14 from "../../../../Components/Dividers/Div14";
import PrintOrderTab from "./Tabs/PrintOrderTab";
import AddAttTemplate from "./Tabs/Attachment/AddAttTemplate";
import DamageReportMain from "./Tabs/DamageReport/DamageReportMain";

const { TabPane } = Tabs;

const instance = axios.create({ baseURL: baseURL });

class SingleACOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      showApprove: false,
      showReject: false,
      load: { status: false },
      tab: "Summary",
    };
    this.postChanges = this.postChanges.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.changeST = this.changeST.bind(this);
    this.cancelJob = this.cancelJob.bind(this);
  }
  onHide() {
    this.setState({
      showApprove: false,
      showReject: false,
      showCancel: false,
      load: false,
    });
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  editVal(val, label) {
    if (!newStorageStore.stopedit) {
      newStorageStore.editOrder(val, label);
    } else {
      if (newStorageStore.oldorder.loading) {
        Notification["error"]({
          title: `Job Details are Being Updated.`,
          description:
            "Due to the large item count, the job details are currently being updated in the database. Please allow a few minutes and refresh.",
        });
      } else if (newStorageStore.oldorder.status === "Booked") {
        Notification["error"]({
          title: `Cannot edit as the job is Booked by our staff.`,
          description: "Please contact our office for support.",
        });
      } else {
        Notification["error"]({
          title: `Cannot edit as the job is within 2 hours / in progress.`,
          description: "Please contact our office for support.",
        });
      }
    }
  }
  cancelJob = async (free, type) => {
    let data = {};
    let services = { ...newStorageStore.neworder.services };
    let url = "/";
    if (type === "Cancelled") {
      services.canctime = moment();
      services.canccost = 0;
      if (!free) {
        services.canccost = newStorageStore.delCont.cancelprice;
      }
      data = { status: "Cancelled", services: services };
      url = `ac/acorder/canceljob/${this.props.match.params.acorderid}`;
    } else if (type === "Reactivated") {
      services.canctime = undefined;
      services.canccost = 0;
      data = { status: "Booked", services: services };
      url = `ac/acorder/activatejob/${this.props.match.params.acorderid}`;
    }

    this.setState({ load: { status: true } });
    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        message.success(`Order Status Changed (${data.status})`);
        this.setState({ load: { status: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { status: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  postChanges = async (label, title, index) => {
    let data = { [label]: newStorageStore.neworder[label] };
    let reloadpage;
    if (label === "jobdate") {
      let services = { ...newStorageStore.neworder.services };
      let ppt = newStorageStore.delCont.pricepertrip;
      let day = moment(newStorageStore.neworder.jobdate).day();

      let alldays = [
        { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
        { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
        { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
        { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
        { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
        { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
        { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
      ];
      if (alldays[day].price > 0) {
        services.weekday = alldays[day].title;
        services.weekdayprice = alldays[day].price;
      }
      data.services = { ...services };
      reloadpage = true;
    }

    this.setState({ load: { [label]: true } });
    await instance
      .put(`ac/acorder/edit/${this.props.match.params.acorderid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.updateOrgOrder(label);
        message.success(`Order Updated (${title})`);
        if (reloadpage) {
          newStorageStore.getSingleACOrder(false, true);
        }
        this.setState({ load: { [label]: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  componentDidMount() {
    newStorageStore.getSingleACOrder(this.props.match.params.acorderid);
    zoneStore.getZones();
    carStore.getCars();
  }
  render() {
    let sto = newStorageStore.oldorder;
    let newsto = newStorageStore.neworder;
    let account = newStorageStore.account;
    let delCont = newStorageStore.delCont;

    let load = this.state.load;
    if (newStorageStore.loading.neworder) {
      return <Loader center />;
    }

    let staffButs;

    if (sto.status === "Requested") {
      staffButs = (
        <ModalFooter
          icon={["trash", "check-circle"]}
          color={["red", "green"]}
          app={["default", "default"]}
          text={["Cancel Request", "Approve Request"]}
          oC1={() => this.setState({ showCancel: true })}
          oC2={() => {
            newStorageStore.editOrder("Booked", "status");
            this.postChanges("status", "Job Status");
          }}
          loading1={load.status}
          loading2={load.status}
        />
      );
    }

    let showdelnote = false;
    let toadd = sto.toaddress;
    for (let i = 0; i < toadd.length; i++) {
      if (toadd[i].delSignature) {
        if (toadd[i].delSignature.name !== "") showdelnote = true;
      }
    }
    let showstonote = false;
    if (sto.storSignature) {
      if (sto.storSignature.name !== "") showstonote = true;
    }
    let showLive = false;
    if (sto.trucktrack) {
      if ((sto.trucktrack !== "") & (sto.status === "In Transit"))
        showLive = true;
    }

    let showAttachment = userStore.user.staffaccount;
    if (sto.attachments.length > 0) showAttachment = true;

    let showDamage = userStore.user.staffaccount;
    if (sto.damagereport) {
      if (sto.damagereport.status) {
        if (sto.damagereport.status !== "Pending") {
          showDamage = true;
        }
      }
    }

    return (
      <div>
        <CancelJobModal
          show={this.state.showCancel}
          cancelJob={this.cancelJob}
          onHide={this.onHide}
          delCont={delCont}
          account={account}
          sto={sto}
          load={this.state.load.status}
        />
        <SingleACOrderHeader changeST={this.changeST} />
        {userStore.user.staffaccount && (
          <FlexboxGrid justify="center">
            <Col2>{staffButs}</Col2>
          </FlexboxGrid>
        )}
        {userStore.user.staffaccount ? (
          <PickupDropoffButtons />
        ) : userStore.user.teamleader ? (
          <PickupDropoffButtons />
        ) : (
          <div />
        )}{" "}
        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <SelectInputSTU
            val={newsto.jobtype}
            org={sto.jobtype}
            label="jobtype"
            load={load.jobtype}
            title="Job Type"
            size="sm"
            editVal={this.editVal}
            updateVal={this.postChanges}
            opts={
              newStorageStore.delstoinout.inout === "In"
                ? ["Delivery In", "Storage In"]
                : ["Delivery Out", "Storage Out"]
            }
          />

          <hr />
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            {showLive && (
              <TabPane
                tab={
                  <Badge
                    status="processing"
                    color={"green"}
                    text="Live Tracking"
                  />
                }
                key={"Live Tracking"}
              >
                <LiveTracking truckid={sto.trucktrack} />
              </TabPane>
            )}
            <TabPane tab={"Summary"} key={"Summary"}>
              <CostSummaryTable order={sto} account={account} />
              <Div14>Job Time</Div14>
              <FlexboxGrid justify="center">
                <Statistic
                  title={sto.services.timetype}
                  value={moment(sto.jobdate).format("DD-MMM-YY HH:mm")}
                  style={{
                    margin: "0 32px",
                    textAlign: "center",
                  }}
                />
              </FlexboxGrid>
              <HelpBlock style={{ textAlign: "center" }}>
                {moment(sto.jobdate).fromNow()}
              </HelpBlock>

              <LocationMain simple />
            </TabPane>
            <TabPane tab={"Timing"} key={"Timing"}>
              <Timing
                load={this.state.load.jobdate}
                postChanges={this.postChanges}
              />
            </TabPane>
            {newStorageStore.delstoinout.delsto === "Delivery" && (
              <TabPane
                tab={`${
                  newStorageStore.delstoinout.inout === "In"
                    ? "Pickup"
                    : "Delivery"
                } Locations (${sto.toaddress.length})`}
                key={"Location"}
              >
                <LocationMain />
              </TabPane>
            )}
            <TabPane tab={"Items List"} key={"Items List"}>
              <ItemsListMain />
            </TabPane>
            <TabPane tab={"Options"} key={"Options"}>
              <Options />
            </TabPane>
            <TabPane tab={"Additional Charges"} key={"Additional Charges"}>
              <AdditionalCharges />
              <hr />
              <Discounts />
            </TabPane>
            {showdelnote && (
              <TabPane tab={"Delivery Note"} key={"Delivery Note"}>
                <DeliveryNote />
              </TabPane>
            )}
            {showstonote && (
              <TabPane tab={"Storage Note"} key={"Storage Note"}>
                <StorageNote />
              </TabPane>
            )}
            {showAttachment && (
              <TabPane tab={"Attachments"} key={"Attachments"}>
                <AddAttTemplate attType={"Additional"} />
              </TabPane>
            )}
            <TabPane tab={"Print Order"} key={"Print Order"}>
              <PrintOrderTab />
            </TabPane>
            {showDamage && (
              <TabPane tab={"Damage Report"} key={"Damage Report"}>
                <DamageReportMain />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(SingleACOrderPage));
