import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class MgtAttendanceStore {
  constructor() {
    this.staff = [];
    this.clients = [];
    this.startdate = moment().subtract(31, "days");
    this.attendancedata = { attendance: [], dates: [], count: [] };
    this.selectedUser = { username: "", _id: "" };
    this.loading = { staff: false, clients: false, singleuser: false };
    this.loaded = { staff: false, clients: false, singleuser: false };

    makeObservable(this, {
      staff: observable,
      clients: observable,
      startdate: observable,
      attendancedata: observable,
      selectedUser: observable,
      loading: observable,
      loaded: observable,
    });
  }

  // FETCH
  getAttendance(user, override) {
    let loaded = false;
    if (this.loading.singleuser || this.loaded.singleuser) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.singleuser = true;
      this.loaded.singleuser = false;
      let data = { startdate: this.startdate };
      return instance
        .put(`/mgt/attendance/byuser/${user._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.selectedUser = {
            _id: user._id,
            username:
              user.username.charAt(0).toUpperCase() + user.username.slice(1),
          };
          this.attendancedata = res.data;
          this.loading.singleuser = false;
          this.loaded.singleuser = true;

          console.log("User Attendance Fetched");
        })
        .catch((err) => {
          this.loading.singleuser = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getStaff(override) {
    let loaded = false;
    if (this.loading.staff || this.loaded.staff) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.staff = true;
      this.loaded.staff = false;

      return instance
        .get(`/mgt/attendance/staff`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.staff = res.data;
          this.loading.staff = false;
          this.loaded.staff = true;

          console.log("Staff Accounts Fetched");
        })
        .catch((err) => {
          this.loading.staff = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getClient(override) {
    let loaded = false;
    if (this.loading.clients || this.loaded.clients) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.clients = true;
      this.loaded.clients = false;

      return instance
        .get(`/mgt/attendance/clients`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.clients = res.data;
          this.loading.clients = false;
          this.loaded.clients = true;

          console.log("Staff Accounts Fetched");
        })
        .catch((err) => {
          this.loading.clients = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  resetAttLoad() {
    this.loaded.singleuser = false;
    this.selectedUser = { username: "", _id: "" };
  }
  changeDate(val) {
    mgtAttendanceStore.startdate = val;
  }
}

const mgtAttendanceStore = new MgtAttendanceStore();
export default mgtAttendanceStore;
