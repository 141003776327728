import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import singleStaffHRStore from "../../../../../Stores/HR/SingleStaffHRStore";
import Div14 from "../../../../../Components/Dividers/Div14";
import AssignStaffToUser from "../AssignStaffToUser";

class EMAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let staff = singleStaffHRStore.singlestaff;
    return (
      <div>
        <b>EMAccount</b>
        <Div14>Assign Staff</Div14>
        {staff.user ? (
          <div>
            <b>Assigned User: </b> {staff.user.group}: {staff.user.username} /{" "}
            {staff.user.email}
          </div>
        ) : (
          <b> No User Assigned </b>
        )}
        <AssignStaffToUser
          editVal={singleStaffHRStore.editSingle}
          updateVal={this.updateVal}
        />
      </div>
    );
  }
}

export default withRouter(observer(EMAccount));
