let storagejoblist = [
  {
    type: "Storage In",
    code: (
      <div>
        <b>Dropoff By Client: </b>(
        <i>
          Client is responsible to bring the items to the Easy Move Warehouse.
        </i>
        )
        <br />
        <b>Storage By Easy Move: </b>(
        <i>Easy Move will follow up on Palletization and Organizing.</i>)
      </div>
    ),
  },
  {
    type: "Storage Out",
    code: (
      <div>
        <b>Pickup By Client: </b>(
        <i>
          Client is responsible to arrange transport to pickup the items at the
          Easy Move Warehouse.
        </i>
        )
        <br />
        <b>Storage By Easy Move: </b>(
        <i>Easy Move will prepare all the items to leave the Warehouse.</i>)
      </div>
    ),
  },
  {
    type: "Delivery In",
    code: (
      <div>
        <b>Dropoff By Easy Move: </b>(
        <i>
          Easy Move will arrange the Delivery from the Client's location to the
          Easy Move Warehouse.
        </i>
        )
        <br />
        <b>Storage By Easy Move: </b>(
        <i>Easy Move will follow up on Palletization and Organizing.</i>)
      </div>
    ),
  },
  {
    type: "Delivery Out",
    code: (
      <div>
        <b>Pickup By Easy Move: </b>(
        <i>
          Easy Move will arrange the Delivery from the Easy Move Warehouse to
          the Client's desired location.
        </i>
        )
        <br />
        <b>Storage By Easy Move: </b>(
        <i>Easy Move will follow up on Palletization and Organizing.</i>)
      </div>
    ),
  },
];

export default storagejoblist;
