import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Icon, IconButton } from "rsuite";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Item } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { Toolbar, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";

import { Tooltip } from "@material-ui/core";

import { withRouter } from "react-router";
import singleAccountStore from "../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";

import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import acDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/ACDeliveryStore";
import userStore from "../../../../../../Stores/UserStore";

class OrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { cost: false };

    this.onExporting = this.onExporting.bind(this);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Order_List_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  StorDate(cellData) {
    let data = cellData.data;
    if (data.status === "Completed") {
      return moment(data.jobdate).format("DD-MMM-YY");
    } else {
      return moment(data.jobdate).format("DD-MMM-YY");
    }
  }
  StatusCell(cellData) {
    let data = cellData.data.status;
    return (
      <div
        style={{
          fontWeight: "bold",
          color:
            data === "Requested"
              ? "#f59f00"
              : data === "Cancelled"
              ? "red"
              : data === "Delivered"
              ? "black"
              : "green",
        }}
      >
        {data}
      </div>
    );
  }
  render() {
    let data = this.props.data;
    let type = this.props.type;

    let showadd = false;
    if (userStore.user.staffaccount) showadd = true;
    if (userStore.user.comprequest) showadd = true;
    if (type !== "Current") showadd = false;

    let content = (
      <DataGrid
        dataSource={data}
        keyExpr="deliveryNumId"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        rowAlternationEnabled
        onExporting={this.onExporting}
        style={{ fontSize: 10 }}
        hoverStateEnabled={true}
      >
        <FilterRow visible={true} applyFilter />
        <GroupPanel visible={true} />
        <SearchPanel visible={true} />
        <Paging defaultPageSize={10} defaultPageIndex={0} />
        <Pager
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showNavigationButtons={true}
        />
        <Scrolling mode="standard" showScrollbar="always" />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={true} />
        <Column
          caption="Actions"
          alignment={"center"}
          cellRender={this.props.actionButs}
          width={100}
        />
        <Column
          dataField="deliveryNumId"
          caption="ID"
          alignment="center"
          width={80}
        />
        <Column caption="Delivery Details" alignment={"center"}>
          <Column
            dataField="jobtype"
            caption="Job"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            minWidth={80}
            cellRender={this.StatusCell}
          />
          <Column
            dataField="jobdate"
            caption="Job Date"
            dataType={"date"}
            format="dd-MMM-yy HH:mm"
            alignment="center"
            minWidth={100}
            sortOrder={"asc"}
            sortIndex={2}
          />
          <Column
            dataField="startdate"
            caption="Start"
            dataType={"date"}
            format="dd-MMM-yy HH:mm"
            alignment="center"
            minWidth={100}
          />
          <Column
            dataField="enddate"
            caption="End"
            dataType={"date"}
            format="dd-MMM-yy HH:mm"
            alignment="center"
            minWidth={100}
          />
          <Column
            dataField="fromaddress.city"
            caption="From"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="toaddress.length"
            caption="Drop offs"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="maintype"
            caption="Job Type"
            alignment="center"
            minWidth={100}
          />
        </Column>
        {this.state.cost && (
          <Column caption="Charges" alignment={"center"}>
            <Column
              dataField="charges.main"
              caption="Main Cost"
              alignment="center"
              minWidth={80}
              format={{ type: "fixedPoint", precision: 3 }}
            />
            <Column
              dataField="charges.location"
              caption="Location"
              alignment="center"
              minWidth={80}
              format={{ type: "fixedPoint", precision: 3 }}
            />
            <Column
              dataField="charges.option"
              caption="Options Cost"
              alignment="center"
              minWidth={80}
              format={{ type: "fixedPoint", precision: 3 }}
            />
            <Column
              dataField="charges.additional"
              caption="Additional Cost"
              alignment="center"
              minWidth={80}
              format={{ type: "fixedPoint", precision: 3 }}
            />
            <Column
              dataField="charges.discount"
              caption="Discounts"
              alignment="center"
              minWidth={80}
              format={{ type: "fixedPoint", precision: 3 }}
              hidingPriority={true}
            />
          </Column>
        )}
        <Column
          dataField="charges.totaljob"
          caption="Total Job"
          alignment="center"
          minWidth={80}
          format={{ type: "fixedPoint", precision: 3 }}
        />

        <Summary>
          <TotalItem
            column="jobtype"
            summaryType="count"
            displayFormat={"{0} jobs"}
          />
          <TotalItem
            column="totalcbm"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} CBM"}
          />
          <TotalItem
            column="totalitems"
            summaryType="sum"
            displayFormat={"{0} items"}
          />
          <TotalItem
            column="totalqty"
            summaryType="sum"
            displayFormat={"{0} Qty"}
          />
          <TotalItem
            column="charges.main"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
          <TotalItem
            column="charges.option"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
          <TotalItem
            column="charges.additional"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
          <TotalItem
            column="charges.discount"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
          <TotalItem
            column="charges.totaljob"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
        </Summary>
        <Export enabled={true} />
        <Toolbar>
          <Item name="Refresh" location="before">
            <div style={{ paddingLeft: "4px" }}>
              <Tooltip title={`Refresh`} arrow placement="right">
                <IconButton
                  icon={<Icon icon="refresh" />}
                  onClick={() => {
                    if (type === "Current") {
                      acDeliveryStore.getOrders(false, true);
                    } else if (type === "Past") {
                      acDeliveryStore.getCustomOrderStatus("Completed", true);
                    } else if (type === "Cancel") {
                      acDeliveryStore.getCustomOrderStatus("Cancelled", true);
                    }
                  }}
                  appearance="default"
                  style={{ border: "1px solid black" }}
                />
              </Tooltip>
            </div>
          </Item>
          <Item name="Refresh" location="before">
            <div style={{ paddingLeft: "4px" }}>
              <Tooltip
                title={`${this.state.cost ? "Hide" : "Show"} Cost Breakdown`}
                arrow
                placement="right"
              >
                <IconButton
                  icon={<Icon icon={this.state.cost ? "eye" : "eye-slash"} />}
                  color={this.state.cost ? "green" : "red"}
                  appearance="ghost"
                  onClick={() => this.setState({ cost: !this.state.cost })}
                  style={{ border: "1px solid black" }}
                />
              </Tooltip>
            </div>
          </Item>
          {showadd && (
            <Item name="New Order" location="after">
              <div style={{ paddingLeft: "4px" }}>
                <Tooltip title={`New Order`} arrow placement="right">
                  <IconButton
                    color="green"
                    icon={<Icon icon="plus" />}
                    onClick={() => {
                      newDeliveryStore.getAccount(
                        singleAccountStore.account._id,
                        true
                      );
                      newDeliveryStore.createNewOrder();

                      this.props.history.push(
                        `/dac/new/${singleAccountStore.account._id}`
                      );
                    }}
                    appearance="default"
                    style={{ border: "1px solid grey" }}
                  />
                </Tooltip>
              </div>
            </Item>
          )}
          <Item name="columnChooserButton" location="after" />
        </Toolbar>
      </DataGrid>
    );

    return <div>{content}</div>;
  }
}

export default withRouter(observer(OrderMain));
