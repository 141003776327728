import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ParallaxImage from "../../../Components/Parallax/ParallaxImage";
import userStore from "../../../Stores/UserStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import pic from "../../../Static/Images/CompanyPics/pic3.png";
import Col2 from "../../../Components/Columns/Col2";
import IconItem from "../../../Components/Lists/IconItem";
import SingleBenefit from "../Sections/Benefits/SingleBenefit";

class CustomClearSolution extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ParallaxImage image={pic} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              Custom Clearance
            </p>
            <p style={{ fontFamily: "Work Sans", fontSize: 20 }}>
              With Easy Move
            </p>
            <hr style={{ border: "1px solid white" }} />
            <p style={{ fontFamily: "sans-serif", fontSize: 24 }}>
              Our Own In-House Custom Clearance License
            </p>

            <div style={{ textShadow: "1px 1px black" }}>
              <p style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                We have our own Custom Clearance License and staff dedicated to
                following up on your shipments. Streamline and simplify your
                customs clearance process with our innovative solution. Our team
                of experts and cutting-edge technology ensure compliance with
                international regulations and provide real-time visibility into
                the status of your shipment. Trust us to handle the complexities
                of customs clearance, freeing you to focus on growing your
                business.
              </p>
            </div>
            <FlexboxGrid>
              <Col2>
                <IconItem icon="user" text="In House License" />
                <br />
                <IconItem icon="clock-o" text="Digitalized Process" />
                <br />
              </Col2>
              <Col2>
                <IconItem icon="upload" text="All Documents Uploaded" />
                <br />
                <IconItem icon="charts-line" text="Step By Step Tracking" />
                <br />
              </Col2>
            </FlexboxGrid>
            {!userStore.signedIn && (
              <>
                <hr style={{ border: "1px solid white" }} />
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="lg"
                  color="blue"
                  onClick={() => this.props.history.push("/login")}
                >
                  Join Easy Move Today!
                </IconButton>
              </>
            )}
          </div>
        </ParallaxImage>
        <SingleBenefit />
      </div>
    );
  }
}

export default withRouter(observer(CustomClearSolution));
