import { observer } from "mobx-react";
import React, { Component } from "react";
import AddChargeModal from "./AddChargeModal";
import AddChargeTable from "./AddChargeTable";
import axios from "axios";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import { Notification } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class AddChargeTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "New",
      showEdit: false,
      showDelete: false,
      selectedCharge: { price: 0, name: "" },
      editedCharge: { price: 0, name: "" },
      loading: false,
    };
    this.changeVal = this.changeVal.bind(this);
    this.changeState = this.changeState.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.deleteCharge = this.deleteCharge.bind(this);
  }

  changeState(val, label) {
    this.setState({ [label]: val });
  }
  changeVal(val, label) {
    let addch = { ...this.state.editedCharge };
    addch[label] = val;
    this.setState({ editedCharge: addch });
  }

  showHideModal(val) {
    if (val === "Delete") {
      this.setState({ showDelete: true, type: "Delete" });
    } else if (val === "New") {
      this.setState({
        showEdit: true,
        selectedCharge: { price: 0, name: "" },
        editedCharge: { price: 0, name: "" },
      });
    } else if (val) {
      this.setState({ showEdit: true, type: "Edit" });
    } else {
      this.setState({
        selectedCharge: { price: 0, name: "" },
        editedCharge: { price: 0, name: "" },
        showEdit: false,
        showDelete: false,
        showNew: false,
      });
    }
  }

  postEdit = async (charge) => {
    let ind = this.props.ind;

    let chargeindex = charge.tableData.id;
    this.changeState(true, "loading");

    return instance
      .put(`/additional/addcharge/edit/${charge._id}`, charge, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.changeList("addcharge", charge, chargeindex, ind);

        this.changeState(false, "loading");
        Notification["success"]({
          title: `Updated Add Charge Details.`,
          description: <div>{charge.name}</div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeState(false, "loading");
      });
  };

  postNew = async (charge) => {
    let sj = sjStore.sjs[this.props.ind];

    this.changeState(true, "loading");
    let data = {
      name: charge.name,
      price: charge.price,
      sj: sj._id,
    };

    return instance
      .post(`/additional/addcharge/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.newList("addcharge", res.data, this.props.ind);

        this.changeState(false, "loading");
        Notification["success"]({
          title: `New Add Charge Created.`,
          description: (
            <div>
              {charge.name} for {charge.price} KD
            </div>
          ),
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeState(false, "loading");
      });
  };

  deleteCharge = async (charge) => {
    let chargeindex = charge.tableData.id;
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];

    this.changeState(true, "loading");

    let data = {
      addchargeid: charge._id,
      type: sj.jobtype,
      subJobId: sj._id,
    };

    return instance
      .put(`/additional/addcharge/delete/${charge._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.changeState(false, "loading");
        Notification["success"]({
          title: `Deleted Add Charge.`,
          description: <div>{charge.title}</div>,
        });
        this.showHideModal(false);
        sjStore.removeList("addcharge", chargeindex, ind);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeState(false, "loading");
      });
  };

  render() {
    let ind = this.props.ind;
    let editview = this.props.editview;

    let disabled = false;
    if (this.state.selectedCharge === this.state.editedCharge) {
      disabled = true;
    }
    if (this.state.editedCharge.name === "") disabled = true;
    if (this.state.editedCharge.price === null) disabled = true;
    if (this.state.editedCharge.price === "") disabled = true;

    return (
      <div>
        <AddChargeModal
          disabled={disabled}
          showEdit={this.state.showEdit}
          showDelete={this.state.showDelete}
          selectedCharge={this.state.selectedCharge}
          editedCharge={this.state.editedCharge}
          loading={this.state.loading}
          showHideModal={this.showHideModal}
          editVal={this.changeVal}
          type={this.state.type}
          postNew={this.postNew}
          postEdit={this.postEdit}
          deleteCharge={this.deleteCharge}
        />
        {this.state.loading ? (
          <div style={{ minHeight: "400px" }} />
        ) : (
          <AddChargeTable
            editview={editview}
            ind={ind}
            showHideModal={this.showHideModal}
            changeState={this.changeState}
            loading={
              this.state.loading || this.state.showDelete || this.state.showEdit
            }
          />
        )}
      </div>
    );
  }
}

export default observer(AddChargeTab);
