import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Empty, Statistic, message } from "antd";

import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";

import Col3 from "../../../Components/Columns/Col3";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import axios from "axios";
import Div14 from "../../../Components/Dividers/Div14";
import whmNewWarehouseStore from "../WHMStores/WHMNewWarehouseStore";
import moment from "moment";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class WHMNewUnloadingJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      trucktrip: {
        _id: "",
        whmTruckTripNumId: 0,
        pallets: [],
        cbm: 0,
        qty: 0,
        updatedAt: moment(),
      },
    };
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.startUnloading = this.startUnloading.bind(this);
    this.selectTruck = this.selectTruck.bind(this);
  }
  onHide() {
    this.setState({
      show: false,
      trucktrip: {
        _id: "",
        whmTruckTripNumId: 0,
        pallets: [],
        cbm: 0,
        qty: 0,
        updatedAt: moment(),
      },
    });
  }
  onShow() {
    this.setState({
      show: true,
      trucktrip: {
        _id: "",
        whmTruckTripNumId: 0,
        pallets: [],
        cbm: 0,
        qty: 0,
        updatedAt: moment(),
      },
    });
  }
  selectTruck(trucktrip) {
    this.setState({ show: true, trucktrip: trucktrip });
  }
  startUnloading = async (truckid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/startunloading/${truckid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        whmNewWarehouseStore.getPallets(true);
        whmNewWarehouseStore.getReadyTruckTrip(true);
        message.success(`Unloading Job Started`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };

  render() {
    let readytrucks;
    if (whmNewWarehouseStore.readytrucks.length > 0) {
      readytrucks = whmNewWarehouseStore.readytrucks.map((tr, index) => (
        <Col3 key={index}>
          <div
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
              textAlign: "center",
              margin: "2%",
            }}
          >
            <IconButton
              icon={<Icon icon="check" />}
              color="green"
              onClick={() => this.selectTruck(tr)}
              block
              appearance="ghost"
            >
              Unload Trip #{tr.whmTruckTripNumId}
            </IconButton>
            <Statistic
              title={`${tr.whmtruck.name}`}
              value={moment(tr.updatedAt).fromNow()}
              valueStyle={{ color: "#3f8600" }}
            />
            {tr.pallets.length} Pallets <br />
            {PRKD(tr.cbm)} CBM
            <br />
            {PRKD(tr.qty)} QTY
          </div>
        </Col3>
      ));
    }
    let stt = this.state.trucktrip;
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="Start Unloading"
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Start Unloading"]}
              oC1={() => this.onHide()}
              oC2={() => this.startUnloading(this.state.trucktrip._id)}
              disabled2={this.state.whmTruckNumId === ""}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>Start Unloading Truck Trip #{stt.whmTruckTripNumId}:</h3>
          <h5>{stt.pallets.length} Pallets</h5>
          <h5>{PRKD(stt.cbm)} CBM</h5>
          <h5>{stt.qty} QTY</h5>
        </UnifiedModal>
        <Empty description="No Active Unloading Jobs" />
        <Div14>Available Trucks to Unload:</Div14>
        <FlexboxGrid>{readytrucks}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(WHMNewUnloadingJob));
