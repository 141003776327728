import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import Col3 from "../../../../Components/Columns/Col3";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import Col2 from "../../../../Components/Columns/Col2";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";

class EditOptions extends Component {
  render() {
    let basicOpts = newAccountStore.services;

    let delCont = newAccountStore.delCont;
    let options = delCont.options;
    let unselected = [];
    let found = false;

    for (let i = 0; i < basicOpts.length; i++) {
      found = false;
      for (let j = 0; j < options.length; j++) {
        if (basicOpts[i].title === options[j].title) {
          found = true;
        }
      }
      if (!found) unselected = [...unselected, basicOpts[i]];
    }

    let stdbuts = unselected.map((un, index) => (
      <IconButton
        style={{ marginLeft: "3px", marginBottom: "3px" }}
        icon={<Icon icon="plus" />}
        color="green"
        onClick={() => newAccountStore.addOption(un, "Add")}
        key={index}
        size="xs"
      >
        {un.title}
      </IconButton>
    ));

    let showinopts = ["Both", "Delivery", "Storage"];
    let inooutopts = ["Both", "In", "Out"];
    if (!newAccountStore.account.ast) {
      showinopts = ["Delivery"];
      inooutopts = ["Both"];
    } else if (newAccountStore.account.hidedel) {
      showinopts = ["Storage"];
    }

    let selected = newAccountStore.delCont.options.map((opt, index) => (
      <div key={index}>
        <FlexboxGrid justify="space-around">
          <h6>Option {index + 1}: </h6>
          <IconButton
            icon={<Icon icon="trash" />}
            color="red"
            size="xs"
            onClick={() => newAccountStore.addOption(index, "Remove")}
          >
            Delete
          </IconButton>
        </FlexboxGrid>
        <FlexboxGrid style={{ marginBottom: "6px" }}>
          <Col3>
            <TextInputSTD
              title="Title"
              val={opt.title}
              label="title"
              size="sm"
              editVal={newAccountStore.editOption}
              index={index}
            />
          </Col3>
          <Col3>
            <SelectInputSTD
              title="Show In"
              val={opt.delstor}
              label="delstor"
              opts={showinopts}
              editVal={newAccountStore.editOption}
              index={index}
            />
          </Col3>
          <Col3>
            <SelectInputSTD
              title="Direction"
              val={opt.inout}
              label="inout"
              opts={inooutopts}
              editVal={newAccountStore.editOption}
              index={index}
            />
          </Col3>
          <Col md={24} sm={24} xs={24}>
            <TextInputSTD
              title="Description"
              val={opt.desc}
              label="desc"
              size="sm"
              editVal={newAccountStore.editOption}
              index={index}
            />
          </Col>
          <Col2>
            <NumbInputSTD
              title="Price"
              val={opt.price}
              label="price"
              size="sm"
              editVal={newAccountStore.editOption}
              index={index}
            />
          </Col2>
          <Col2>
            <SelectInputSTD
              title="Price Unit"
              val={opt.type}
              label="type"
              opts={["KD", "KD / CBM"]}
              editVal={newAccountStore.editOption}
              index={index}
            />
          </Col2>
        </FlexboxGrid>
      </div>
    ));

    let disabled = false;
    for (let i = 0; i < options.length; i++) {
      if (options[i].title === "") disabled = true;
      if (options[i].price === "") disabled = true;
      if (options[i].price <= 0) disabled = true;
      if (options[i].price === "") disabled = true;
    }

    if (
      newAccountStore.delCont.options === newAccountStore.orgdelCont.options
    ) {
      disabled = true;
    }

    return (
      <div>
        <Div14>Edit Delivery / Storage Options</Div14>
        <FlexboxGrid>
          <b>Standard Options: </b> {stdbuts}
        </FlexboxGrid>
        <hr />
        {selected}
        <hr />
        <FlexboxGrid justify="center">
          <Col2>
            <Col2>
              <IconButton
                block
                disabled={disabled}
                icon={<Icon icon="undo" />}
                color="red"
                onClick={() =>
                  newAccountStore.editDelCont(
                    newAccountStore.orgdelCont.options,
                    "options"
                  )
                }
              >
                Undo Changes
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                block
                disabled={disabled}
                icon={<Icon icon="save" />}
                color="green"
                onClick={() => this.props.updateVal()}
              >
                Save Changes
              </IconButton>
            </Col2>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(EditOptions);
