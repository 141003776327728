import React, { Component } from "react";

import DataGrid, { Column, Grouping } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Summary, TotalItem } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { MasterDetail, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";

import { observer } from "mobx-react";

import moment from "moment";

import { Icon, IconButton } from "rsuite";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";
import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import acInventoryStore from "../../../../../../../Stores/AccountUpdated/Storage/ACInventoryStore";
import OrderDetailView from "./OrderDetailView";
import acInvoiceStore from "../../../../../../../Stores/Financial/ACInvoiceStore";

function DiffCell(cellData) {
  return <div>{PRKD(cellData.data.quantin)}</div>;
}

class StorageCosts extends Component {
  constructor(props) {
    super(props);
    this.state = { autoExpandAll: true };
    this.onExporting = this.onExporting.bind(this);
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      let storCont = singleAccountStore.account.storageContract;
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${storCont.maintype}_Charges_${storCont.subtype}_${moment().format(
            "DD_MMM_YYYY"
          )}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  render() {
    let storCont = singleAccountStore.storCont;

    let finaltime = "Months";
    if (storCont.maintype === "Weekly Rate") finaltime = "Weeks";
    if (storCont.maintype === "Daily Rate") finaltime = "Days";

    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
        }}
      >
        <Div14>
          {storCont.maintype} Charges ({storCont.subtype}){" "}
          <IconButton
            icon={<Icon icon="refresh" />}
            size="xs"
            onClick={() => {
              acInventoryStore.getStorageFinanceHistory(
                singleAccountStore.account._id,
                true
              );
              acInvoiceStore.getInvoiceAndPaymentByClient(
                acInventoryStore.clientId
              );
            }}
            circle
          />
        </Div14>

        <DataGrid
          dataSource={acInventoryStore.finance.weekly}
          keyExpr="startday"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column caption="Dates">
            <Column
              dataField="startday"
              dataType="date"
              caption="Start"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
            />
            <Column
              dataField="endday"
              dataType="date"
              caption="End"
              alignment="center"
              format={"dd-MMM-yy"}
              width={80}
            />
          </Column>
          <Column caption="CBM Details" alignment="center">
            <Column
              dataField="cbmstart"
              dataType="number"
              caption="Start CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              width={80}
            />
            <Column
              dataField="quantin"
              caption="QTY In"
              width={80}
              alignment="center"
              cellRender={DiffCell}
            />
            <Column
              dataField="storin"
              dataType="number"
              caption="Storage In"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              width={80}
            />
            <Column
              dataField="quantout"
              dataType="number"
              caption="QTY Out."
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              width={80}
            />
            <Column
              dataField="storout"
              dataType="number"
              caption="Storage Out"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              width={80}
            />
            <Column
              dataField="cbmend"
              dataType="number"
              caption="End CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              width={80}
            />
          </Column>
          <Column caption="Storage Charges" alignment="center">
            <Column
              dataField="freqchg"
              dataType="number"
              caption={`${storCont.maintype} CBM (${storCont.subtype})`}
              alignment="center"
              displayFormat={"{0} CBM"}
              width={150}
              format={{ type: "fixedPoint", precision: 3 }}
            />
            <Column
              dataField="storchg"
              dataType="number"
              caption="Charges KD"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={120}
            />
          </Column>
          <Column caption="Delivery Charges" alignment="center">
            <Column
              dataField="delinchg"
              dataType="number"
              caption="In Del Cost"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={80}
            />
            <Column
              dataField="deloutchg"
              dataType="number"
              caption="Out Del Cost"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={80}
            />
            <Column
              dataField="deltotalchg"
              dataType="number"
              caption="Total Del Cost"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={100}
            />
          </Column>
          <Column caption="Total Charges" alignment="center">
            <Column
              dataField="totalchg"
              dataType="number"
              caption="Charges KD"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={150}
            />
          </Column>
          <MasterDetail enabled={true} component={OrderDetailView} />
          <Summary>
            <TotalItem
              column="startday"
              summaryType="count"
              displayFormat={`${finaltime}: `}
            />
            <TotalItem
              column="endday"
              summaryType="count"
              displayFormat={`${acInventoryStore.finance.weekly.length}`}
            />

            <TotalItem
              column="storchg"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="delinchg"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="deloutchg"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="deltotalchg"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
            <TotalItem
              column="totalchg"
              summaryType="sum"
              valueFormat={"#,##0.000"}
              displayFormat={"{0} KD"}
            />
          </Summary>
          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default observer(StorageCosts);
