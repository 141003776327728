import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import moment from "moment";
import { PRKD } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class SingleEquipmentStore {
  constructor() {
    this.equipmentid = "";
    this.equipment = {
      type: "Truck",
      date: moment(),
      dateto: undefined,
      license: "",
      remark: "",
      status: "Active",
      _id: "",
      lastfuel: {
        kilometer: 0,
        liter: 0,
        price: 0,
        date: null,
      },
      lastservice: {
        price: 0,
        nextservicekm: 0,
        nextservicedate: 0,
        date: null,
      },
    };
    this.fuelgraph = "Fuel Consumption";
    this.fuel = [];
    this.service = [];
    this.checklist = [];

    this.loading = {
      equipment: false,
      fuel: false,
      service: false,
      checklist: false,
    };
    this.loaded = {
      equipment: false,
      fuel: false,
      service: false,
      checklist: false,
    };

    makeObservable(this, {
      equipmentid: observable,
      equipment: observable,
      fuel: observable,
      fuelgraph: observable,
      service: observable,
      checklist: observable,

      loading: observable,
      loaded: observable,

      fuelgraphdata: computed,
    });
  }

  // FETCH
  getEquipment(equipid, override) {
    let loaded = false;

    if (this.loaded.equipment) loaded = true;
    if (override) loaded = false;
    if (equipid !== this.equipmentid) loaded = false;
    if (!loaded) {
      this.equipmentid = equipid;
      this.loaded.equipment = false;
      this.loading.equipment = true;
      this.loaded.fuel = false;
      this.loaded.service = false;
      this.loaded.checklist = false;

      return instance
        .get(`/hr/equipment/single/${this.equipmentid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((equipment) => {
          this.equipment = equipment;
          this.loading.equipment = false;
          this.loaded.equipment = true;
          console.log("Single Equipment Fetched");
        })
        .catch((err) => {
          this.loading.equipment = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Single Equipment.`,
          });
        });
    }
  }
  getEquipmentFuel(equipid, override) {
    let loaded = false;

    if (this.loaded.fuel) loaded = true;
    if (override) loaded = false;
    if (equipid !== this.equipid) loaded = false;
    if (!loaded) {
      this.equipmentid = equipid;
      this.loaded.fuel = false;
      this.loading.fuel = true;

      return instance
        .get(`/hr/equipmentfuel/singleeq/${this.equipmentid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((fuel) => {
          this.fuel = fuel;

          this.loaded.fuel = true;
          this.loading.fuel = false;
          console.log("Single Equipment Fuel Fetched");
        })
        .catch((err) => {
          this.loaded.fuel = false;
          this.loading.fuel = false;

          console.log(err);
          Notification["error"]({
            title: `Error Fetching Single Equipment Fuel.`,
          });
        });
    }
  }
  getEquipmentService(equipid, override) {
    let loaded = false;

    if (this.loaded.service) loaded = true;
    if (override) loaded = false;
    if (equipid !== this.equipid) loaded = false;
    if (!loaded) {
      this.equipmentid = equipid;
      this.loaded.service = false;
      this.loading.service = true;

      return instance
        .get(`/hr/equipmentservice/singleeq/${this.equipmentid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((service) => {
          this.service = service;

          this.loaded.service = true;
          this.loading.service = false;
          console.log("Single Equipment Service Fetched");
        })
        .catch((err) => {
          this.loaded.service = false;
          this.loading.service = false;

          console.log(err);
          Notification["error"]({
            title: `Error Fetching Single Equipment Service.`,
          });
        });
    }
  }
  getEquipmentChecklist(equipid, override) {
    let loaded = false;

    if (this.loaded.checklist) loaded = true;
    if (override) loaded = false;
    if (equipid !== this.equipid) loaded = false;
    if (!loaded) {
      this.equipmentid = equipid;
      this.loaded.checklist = false;
      this.loading.checklist = true;

      return instance
        .get(`/hr/equipmentchecklist/singleeq/${this.equipmentid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((checklist) => {
          this.checklist = checklist;

          this.loaded.checklist = true;
          this.loading.checklist = false;
          console.log("Single Equipment Service Fetched");
        })
        .catch((err) => {
          this.loaded.checklist = false;
          this.loading.checklist = false;

          console.log(err);
          Notification["error"]({
            title: `Error Fetching Single Equipment Service.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get fuelgraphdata() {
    let series1 = [];
    let series2 = [];
    let series = [];
    let label = [];
    let yaxis = [];
    if (this.fuelgraph === "Fuel Consumption") {
      for (let i = 0; i < this.fuel.length; i++) {
        series1 = [...series1, PRKD(this.fuel[i].kmperliter)];
        series2 = [...series2, PRKD(this.fuel[i].avgkmperliter)];
        label = [...label, moment(this.fuel[i].date).format("DD-MMM-YY")];
      }
      series = [
        { name: "KM / Liter", type: "column", data: series1 },
        { name: "Average KM / Liter", type: "line", data: series2 },
      ];
      yaxis = [{ title: { text: "Fuel Efficiency (KM / Liter)" } }];
    } else if (this.fuelgraph === "Price Graph") {
      for (let i = 0; i < this.fuel.length; i++) {
        series1 = [...series1, PRKD(this.fuel[i].price)];
        series2 = [...series2, PRKD(this.fuel[i].priceperday)];
        label = [...label, moment(this.fuel[i].date).format("DD-MMM-YY")];
      }
      series = [
        { name: "KM / Liter", type: "column", data: series1 },
        { name: "Average KM / Liter", type: "line", data: series2 },
      ];
      yaxis = [
        { title: { text: "Price (KD)" } },
        { opposite: true, title: { text: "Avg Price Per Day (KD/day)" } },
      ];
    } else if (this.fuelgraph === "Fuel Duration") {
      for (let i = 0; i < this.fuel.length; i++) {
        series1 = [...series1, PRKD(this.fuel[i].duration)];
        series2 = [...series2, PRKD(this.fuel[i].avgduration)];
        label = [...label, moment(this.fuel[i].date).format("DD-MMM-YY")];
      }
      series = [
        { name: "Duration (days)", type: "column", data: series1 },
        { name: "Average Duration (days)", type: "line", data: series2 },
      ];
      yaxis = [
        { title: { text: "Duration (days)" } },
        { opposite: true, title: { text: "Avg Duration (days)" } },
      ];
    }
    return { series: series, label: label, yaxis };
  }

  // FUNCTIONS:

  changeFuelgraph(val) {
    this.fuelgraph = val;
  }

  startLoading() {
    this.loading = true;
  }
}

const singleEquipmentStore = new SingleEquipmentStore();
export default singleEquipmentStore;
