import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Badge, Tag } from "antd";
import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

class IMTrackTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  StatusCell(cellData) {
    let data = cellData.data.status;
    let statuscolor = "orange";
    let statustype = "processing";
    if (data === "Complete") {
      statuscolor = "green";
      statustype = "default";
    }
    return (
      <Tag color={statuscolor} span={2} style={{ fontWeight: "bold" }}>
        <Badge
          status={statustype}
          style={{ color: statuscolor }}
          color={statuscolor}
          text={data}
        />
      </Tag>
    );
  }
  render() {
    let aim = this.props.aim;
    return (
      <div>
        <DataGrid
          id="gridContainer"
          dataSource={aim.imtracks}
          showBorders={true}
          style={{ fontSize: 12 }}
          showRowLines
          showColumnLines
          rowAlternationEnabled
          wordWrapEnabled
        >
          <Selection mode="single" />
          <Column dataField="title" caption="Title" />
          <Column
            dataField="status"
            caption="Status"
            cellRender={this.StatusCell}
          />
          <Column
            dataField="date"
            dataType="date"
            caption="Date"
            alignment="center"
            format={"dd-MMM-yy"}
            minwidth={80}
            sortOrder={"desc"}
          />
          <Column dataField="remarks" caption="Remarks" />
          <Summary>
            <TotalItem
              column="title"
              summaryType="count"
              displayFormat="{0} Tracks"
            />
          </Summary>
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(IMTrackTable));
