import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmPlanningStore from "../WHMStores/WHMPlanningStore";
import Div14 from "../../../Components/Dividers/Div14";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";
import TextInputSTU from "../../../Components/Inputs/Updatable/TextInputSTU";
import userStore from "../../../Stores/UserStore";
import { message } from "antd";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import Col2 from "../../../Components/Columns/Col2";
import NumbInputSTU from "../../../Components/Inputs/Updatable/NumbInputSTU";
import Col3 from "../../../Components/Columns/Col3";
import DateInputSTU from "../../../Components/Inputs/Updatable/DateInputSTU";
import TimeInputSTU from "../../../Components/Inputs/Updatable/TimeInputSTU";

const instance = axios.create({ baseURL: baseURL });

class WHMSinglePlan extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: {} };
    this.postChanges = this.postChanges.bind(this);
  }
  postChanges = async (label) => {
    let data = { [label]: whmPlanningStore.singleplan[label] };

    this.setState({ loading: { [label]: true } });
    return instance
      .put(`/whm/plan/updateplan/${whmPlanningStore.originalplan._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`updated (${label})`);
        whmPlanningStore.updatePlan(label);
        this.setState({ loading: { [label]: false } });
      })
      .catch((err) => {
        console.log(err);
        message.error("An Error Occured");
        this.setState({ loading: { [label]: false } });
      });
  };
  componentDidMount() {
    whmPlanningStore.getSinglePlan(this.props.match.params.planid);
  }

  render() {
    let plan = whmPlanningStore.singleplan;
    let org = whmPlanningStore.originalplan;
    let load = this.state.loading;
    return (
      <LoadBack loading={whmPlanningStore.loading.singleplan}>
        <Div14>
          Single Plan <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            color="orange"
            circle
            size="xs"
            onClick={() => whmPlanningStore.getSinglePlan(false, true)}
          />
        </Div14>
        <FlexboxGrid>
          <Col2>
            <TextInputSTU
              val={plan.name}
              org={org.name}
              label="name"
              title="Plan Name"
              load={load.name}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col2>
          <Col2>
            <DateInputSTU
              val={plan.startday}
              org={org.startday}
              label="startday"
              title="Start Day"
              load={load.startday}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <NumbInputSTU
              val={plan.trucks}
              org={org.trucks}
              label="trucks"
              title="Total Trucks"
              load={load.trucks}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={plan.pallets}
              org={org.pallets}
              label="pallets"
              title="Total Pallets"
              load={load.pallets}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={plan.palletspertruck}
              org={org.palletspertruck}
              label="palletspertruck"
              title="Pallets Per Truck"
              load={load.palletspertruck}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={plan.dailytrips}
              org={org.dailytrips}
              label="dailytrips"
              title="Daily Trips"
              load={load.dailytrips}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col3>
            <NumbInputSTU
              val={plan.minutestoload}
              org={org.minutestoload}
              label="minutestoload"
              title="Loading Time (min)"
              load={load.minutestoload}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col3>
          <Col3>
            <NumbInputSTU
              val={plan.minutestotransit}
              org={org.minutestotransit}
              label="minutestotransit"
              title="Transit Time (min)"
              load={load.minutestotransit}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col3>
          <Col3>
            <NumbInputSTU
              val={plan.minutestounload}
              org={org.minutestounload}
              label="minutestounload"
              title="Unloading Time (min)"
              load={load.minutestounload}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
            <br />
          </Col3>
          <Col2>
            <TimeInputSTU
              val={plan.starttime}
              org={org.starttime}
              label="starttime"
              title="Start Time"
              load={load.starttime}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
          </Col2>
          <Col2>
            <TimeInputSTU
              val={plan.endtime}
              org={org.endtime}
              label="endtime"
              title="End Time"
              load={load.endtime}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
          </Col2>
          <Col2>
            <TimeInputSTU
              val={plan.startbreak}
              org={org.startbreak}
              label="startbreak"
              title="Start Break"
              load={load.startbreak}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
          </Col2>
          <Col2>
            <TimeInputSTU
              val={plan.endbreak}
              org={org.endbreak}
              label="endbreak"
              title="End Break"
              load={load.endbreak}
              editVal={whmPlanningStore.editPlan}
              updateVal={this.postChanges}
            />
          </Col2>
        </FlexboxGrid>
        <hr />
        Total Time = {whmPlanningStore.singlePlanTimeline}
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMSinglePlan));
