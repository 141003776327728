import { observer } from "mobx-react";
import React, { Component } from "react";

import { Badge, Tabs } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";

import MainAccountInfo from "../../StorageAccount/SubContractSummary/MainAccountInfo";
import DeliveryContractInfo from "../../StorageAccount/SubContractSummary/DeliveryContractInfo";
import OrderMain from "./Tabs/OrderTable/OrderMain";
import acDeliveryStore from "../../../../Stores/AccountUpdated/Delivery/ACDeliveryStore";
import LiveTracking from "../../../../Components/CarTracking/LiveTracking";
import { Collapse } from "antd";
import Col4 from "../../../../Components/Columns/Col4";
import { withRouter } from "react-router";
import DACCalendarPage from "../Calendar/DACCalendarPage";
import DeliveryCharges from "./Tabs/DeliveryCharges/DeliveryCharges";
import DeliveryDailyGraph from "./Tabs/DailyGraph/DeliveryDailyGraph";
import DelInvoiceMain from "./Tabs/DelInvoice/DelInvoiceMain";
import PaymentMain from "./Tabs/Payments/PaymentMain";

const { Panel } = Collapse;
const { TabPane } = Tabs;

class DeliveryACCTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Deliveries" };
  }

  componentDidMount() {
    singleAccountStore.getAccount(this.props.clientid);
  }
  render() {
    let showlive = false;
    let trucktracks = [];
    let orders = acDeliveryStore.orders;
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].status === "In Transit") {
        if (orders[i].trucktrack) {
          showlive = true;
          trucktracks = [
            ...trucktracks,
            { truckid: orders[i].trucktrack._id, del: orders[i] },
          ];
        }
      }
    }

    return (
      <div>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          {showlive && (
            <TabPane
              tab={
                <Badge
                  status="processing"
                  color={"green"}
                  text="Live Tracking"
                />
              }
              key={"Live Tracking"}
            >
              <Collapse accordian>
                {trucktracks.map((truck, index) => (
                  <Panel
                    header={`Live Tracking of Delivery #${truck.del.deliveryNumId}`}
                    key={index}
                    style={{ backgroundColor: "#d3ffcc" }}
                  >
                    <FlexboxGrid justify="center">
                      <Col4>
                        <IconButton
                          icon={<Icon icon="truck" />}
                          block
                          color="green"
                          onClick={() =>
                            this.props.history.push(
                              `/delorder/${truck.del._id}`
                            )
                          }
                        >
                          Go to Delivery {truck.del.deliveryNumId} Page
                        </IconButton>
                      </Col4>
                    </FlexboxGrid>
                    <LiveTracking truckid={truck.truckid} />
                  </Panel>
                ))}
              </Collapse>
            </TabPane>
          )}
          <TabPane tab={"Deliveries"} key={"Deliveries"}>
            <OrderMain clientid={this.props.clientid} />
          </TabPane>
          <TabPane tab={"Calendar"} key={"Calendar"}>
            <DACCalendarPage />
          </TabPane>
          <TabPane tab={"Daily Activities"} key={"Daily Activities"}>
            <DeliveryDailyGraph />
          </TabPane>
          <TabPane tab={"Delivery Charges"} key={"Delivery Charges"}>
            <DeliveryCharges />
          </TabPane>
          <TabPane tab={"Invoices"} key={"Invoices"}>
            <DelInvoiceMain />
          </TabPane>
          <TabPane tab={"Payments"} key={"Payments"}>
            <PaymentMain />
          </TabPane>
          <TabPane tab={"Delivery Contract"} key={"Delivery Contract"}>
            <FlexboxGrid justify="end">
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => singleAccountStore.getAccount(false, true)}
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <MainAccountInfo client={singleAccountStore.account} />
            <DeliveryContractInfo
              delCont={singleAccountStore.account.deliveryContract}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(DeliveryACCTabs));
