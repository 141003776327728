import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { StatusColorCalc } from "../../Functions/Colors/StatusColorCalc";
import JobDetailsModal from "../../Pages/Lists/SideJobLists/Template/JobDetailsModal";

import singleJobStore from "../../Stores/SingleJobStore";
import sjStore from "../../Stores/SideJobs/SJStore";
import { withRouter } from "react-router";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class MainCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      jobId: "",
      subjob: { type: "" },
      job: {},
    };
    this.showHideModal = this.showHideModal.bind(this);
  }

  showHideModal(val, subjob) {
    if (val) {
      this.setState({ show: val, jobId: subjob.job, subjob: subjob });

      this.fetchJobDetails(subjob.job);
    } else {
      this.setState({ show: false, jobId: "", subjob: { type: "" } });
    }
  }
  fetchJobDetails(jobId) {
    singleJobStore.getJob(jobId, true);
    sjStore.getSJs(jobId, true);
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";

    backgroundColor = StatusColorCalc(event.status);

    let textColor = "black";

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "1px solid black",
      borderColor: "black",
      display: "block",
      fontSize: 8,
      height: 15,
      color: textColor,
    };
    return {
      style: style,
    };
  };

  calendarStyle = (day) => {
    return {
      style: {
        backgroundColor: moment(day).day() === 1 && "#ebebeb",
      },
    };
  };

  render() {
    let data = this.props.data;
    let start = this.props.start;
    let end = this.props.end;

    return (
      <div>
        <JobDetailsModal
          show={this.state.show}
          showModal={this.showHideModal}
          loading={false}
          subjob={this.state.subjob}
          jobId={this.state.jobId}
          type={this.state.subjob.jobtype}
        />
        <Calendar
          localizer={localizer}
          events={data}
          eventPropGetter={this.eventStyleGetter}
          startAccessor={start}
          endAccessor={end}
          style={{ height: 800 }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor={"title"}
          popup
          onSelectEvent={(e) => this.showHideModal(true, e)}
          onNavigate={(e) => {}}
          dayPropGetter={this.calendarStyle}
        />
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(MainCalendar));
