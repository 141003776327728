import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../Components/Dividers/Div14";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import { Tabs } from "antd";
import Col2 from "../../../../Components/Columns/Col2";
import SingleStaff from "./SingleStaff";
import StaffSalary from "./StaffSubPages/StaffSalary";
import singleStaffHRStore from "../../../../Stores/HR/SingleStaffHRStore";
import StaffPayments from "./StaffSubPages/StaffPayments";
import EMAccount from "./StaffSubPages/EMAccount";
import SingleStaffHoliday from "./StaffSubPages/SingleStaffHoliday";

const { TabPane } = Tabs;

class SingleStaffMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Employment" };
  }

  componentDidMount() {
    singleStaffHRStore.getSingleHRStaff(this.props.match.params.staffid);
  }

  render() {
    let staff = singleStaffHRStore.singlestaff;

    return (
      <LoadBack loading={singleStaffHRStore.loading.single}>
        <div style={{ minHeight: "80vh" }}>
          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={<Icon icon="chevron-left" />}
                onClick={() => this.props.history.push("/mgt/hr")}
                block
              >
                Back to All Staff
              </IconButton>
            </Col2>
          </FlexboxGrid>
          <br />
          <Div14>
            HR Details for {staff.name}
            <Divider vertical />
            <IconButton
              circle
              size="xs"
              icon={<Icon icon="refresh" />}
              onClick={() =>
                singleStaffHRStore.getSingleHRStaff(
                  this.props.match.params.staffid,
                  true
                )
              }
            />
          </Div14>

          <Tabs
            size="large"
            activeKey={this.state.tab}
            defaultActiveKey={"Employment"}
            tabPosition={"top"}
            type="card"
            onChange={(e) => this.setState({ tab: e })}
          >
            <TabPane tab={<div>Employment</div>} key={"Employment"}>
              <SingleStaff />
            </TabPane>
            <TabPane tab={<div>Basic Salary</div>} key={"Basic Salary"}>
              <StaffSalary />
            </TabPane>
            <TabPane tab={<div>Monthly Payments</div>} key={"Monthly Payments"}>
              <StaffPayments />
            </TabPane>
            <TabPane tab={<div>Holidays</div>} key={"Holidays"}>
              <SingleStaffHoliday />
            </TabPane>
            <TabPane tab={<div>EM Account</div>} key={"EM Account"}>
              <EMAccount />
            </TabPane>
          </Tabs>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(SingleStaffMain));
