import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Empty } from "antd";
import acInventoryStore from "../../../../../../../Stores/AccountUpdated/Storage/ACInventoryStore";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { FlexboxGrid } from "rsuite";
import Col3 from "../../../../../../../Components/Columns/Col3";
import Col2 from "../../../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../../Components/Modal/ModalFooter";
import { withRouter } from "react-router";

class SingleItemInvGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      showtrack: false,
      order: { acOrderNumId: "", _id: "" },
    };
    this.handleModeChange = this.handleModeChange.bind(this);
    this.onHideAll = this.onHideAll.bind(this);
    this.onHideTrack = this.onHideTrack.bind(this);
    this.onShowTrack = this.onShowTrack.bind(this);
  }
  onShowTrack(acorder) {
    this.setState({ showtrack: true, order: acorder });
  }
  onHideTrack() {
    this.setState({ showtrack: false });
  }
  onHideAll() {
    this.setState({ tab: "1" });
    this.onHideTrack();
    this.props.onHide();
  }
  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };
  render() {
    let options = {
      chart: {
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            zoom: false,
            selection: false,
            reset: false,
            pan: false,
          },
        },
      },
      markers: {
        size: 10,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      yaxis: {
        min: 0,
        tickAmount: 10,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return PRKD(val);
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: "Item Quantity",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      colors: ["#96c9ff", "#96ffaa", "#E91E63"],
      dropShadow: { enabled: true, top: 0, left: 0, blur: 3, opacity: 0.5 },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: [undefined, "black", "black"],
        width: 0.5,
        dashArray: 0,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1, 2],
        formatter: function (val, opts) {
          if (val > 0) {
            if (opts.seriesIndex === 1) {
              return `+ ${PRKD(val)}`;
            } else {
              return `- ${PRKD(val)}`;
            }
          } else {
            return "";
          }
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    };
    let content;

    let seriesValue = [
      {
        data: acInventoryStore.itemInv,
        name: "Inventory",
        type: "area",
      },
      {
        data: acInventoryStore.itemIn,
        name: "In Quantity",
        type: "column",
      },
      {
        data: acInventoryStore.itemOut,
        name: "Out Quantity",
        type: "column",
      },
    ];
    let columns = [
      { title: "Order ID", field: "acOrderNumId" },
      {
        title: "Date",
        field: "jobdate",
        defaultSort: "desc",
        render: (row) => moment(row.jobdate).format("DD-MMM-YY"),
      },
      { title: "Type.", field: "fulltype" },
      {
        title: "Qty",
        field: "actualquantity",
        render: (row) => {
          let color = "green";
          if (row.type === "Out") color = "red";
          return (
            <b style={{ color: color }}>
              {color === "green" ? "+" : "-"} {row.actualquantity}
            </b>
          );
        },
      },
      { title: "Warehouse", field: "warehouseshort" },
      {
        title: "AfterStock",
        field: "afterstock",
      },
    ];

    let item = acInventoryStore.selecteditem;

    if (
      acInventoryStore.selecteditem._id !== acInventoryStore.selectedItemId ||
      acInventoryStore.loading.item
    ) {
      content = <Empty description="Please select an item to view history." />;
    } else {
      content = (
        <>
          <ReactApexChart
            options={options}
            series={seriesValue}
            type="area"
            height={200}
          />
          <MaterialTable
            isLoading={acInventoryStore.loading.item}
            title={`Inventory History of Item #${acInventoryStore.selecteditem.acItemNumId} (${acInventoryStore.selecteditem.name})`}
            columns={columns}
            style={{ fontSize: 12 }}
            data={acInventoryStore.moves}
            onRowClick={(event, rowData) => {
              this.onShowTrack(rowData.acorder);
            }}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 500,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `Inventory History`,
              padding: "dense",

              search: false,
            }}
          />
          <Div14>Item Details:</Div14>
          <FlexboxGrid>
            <Col3>
              <b>Item Name: </b> {item.name}
              <br />
              <b>Serial Number: </b> {item.serialnumber}
              <br />
              <b>ProductID: </b> {item.productid}
              <br />
            </Col3>
            <Col3>
              <b>Current Stock: </b> {item.currentstock}
              <br />
              <b>On Reserve: </b> {item.onreserve}
              <br />
              <b>Entering Stock: </b> {item.enteringstock}
              <br />
            </Col3>
            <Col3>
              <b>CBM: </b> {PRKD(item.cbm)} CBM
              <br />
              <b>Dimensions: </b> {item.dimensions}
              <br />
              <b>Total CBM: </b> {PRKD(item.totalcbm)} CBM
              <br />
            </Col3>
            <Col2>
              <b>Location Tags: </b>
              {item.loctags.join(", ")}
              <br />
            </Col2>
            <Col2></Col2>
          </FlexboxGrid>
        </>
      );
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showtrack}
          onHide={this.onHideTrack}
          title={`Order# ${this.state.order.acOrderNumId} - ${this.state.order.status}`}
          width="70%"
          footer={
            <ModalFooter
              icon={["close", "chevron-right"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={[
                "Cancel",
                `Yes, go to Order ${this.state.order.acOrderNumId}`,
              ]}
              oC1={() => this.onHideTrack()}
              oC2={() => {
                this.props.history.push(`/acorder/${this.state.order._id}`);
              }}
            />
          }
        >
          Go to Tracking Page for Order # {this.state.order.acOrderNumId}
          <hr />
          <h6>Type: {this.state.order.jobtype}</h6>
          <h6>
            Storage Date: {moment(this.state.order.jobdate).format("DD-MMM-YY")}
          </h6>
        </UnifiedModal>
        {content}
      </div>
    );
  }
}

export default withRouter(observer(SingleItemInvGraph));
