import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";

class MatTableSTD extends Component {
  render() {
    let loading = this.props.loading;
    let title = this.props.title;
    let data = this.props.data;
    let columns = this.props.columns;
    let actions = this.props.actions;

    return (
      <MaterialTable
        isLoading={loading}
        title={data.length + " " + title}
        columns={columns}
        style={{ fontSize: 12 }}
        data={data}
        options={{
          actionsColumnIndex: 0,
          exportAllData: true,
          paging: false,
          pageSizeOptions: [5, 10, 15, 20, 25],
          toolbarButtonAlignment: "left",
          maxBodyHeight: 1000,
          tableLayout: "auto",
          exportButton: true,
          exportFileName: `${
            this.props.exportTitle ? this.props.exportTitle : title
          } as of ${moment(new Date()).format("DD-MMM-YY")}`,
          padding: "dense",
        }}
        actions={actions}
      />
    );
  }
}

export default observer(MatTableSTD);
