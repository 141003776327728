import { observer } from "mobx-react";
import React, { Component } from "react";
import { Empty } from "antd";

import MaterialTable from "material-table";
import { withRouter } from "react-router";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";

class OrderDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      stomodal: false,
      stodata: {},
      delcharge: true,
      addchg: true,
      percbmchg: true,
      discount: true,
    };
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({
      stomodal: false,
      stodata: {},
    });
  }
  render() {
    let content = (
      <Empty
        description="No Deliveries during this period"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: "50px" }}
      />
    );

    let columns = [
      { title: "OrderID", field: "acOrderNumId" },
      {
        title: "Type",
        field: "jobtype",
        render: (row) => {
          let color = "green";
          if (row.jobtype === "Delivery Out" || row.jobtype === "Storage Out")
            color = "red";
          return <b style={{ color: color }}>{row.jobtype}</b>;
        },
      },
      {
        title: "QTY",
        field: "quant",
        render: (row) => {
          let color = "green";
          if (row.jobtype === "Delivery Out" || row.jobtype === "Storage Out")
            color = "red";

          return (
            <b style={{ color: color }}>
              {color === "green" ? "+" : "-"} {row.quant}
            </b>
          );
        },
      },
      {
        title: "CBM",
        field: "cbm",
        render: (row) => {
          let color = "green";
          if (row.jobtype === "Delivery Out" || row.jobtype === "Storage Out")
            color = "red";

          return (
            <b style={{ color: color }}>
              {color === "green" ? "+" : "-"} {PRKD(row.cbm)}
            </b>
          );
        },
      },
      { title: "Status", field: "status" },
      {
        title: "Total Chg. KD",
        field: "totaldelcharge",
        render: (row) => <b>{PRKD(row.totaldelcharge)}</b>,
      },
      { title: "Warehouse", field: "emlocation.name" },
    ];

    let data = this.props.data.data.acorders;

    if (data.length) {
      if (data.length > 0) {
        content = (
          <div>
            <MaterialTable
              columns={columns}
              style={{ fontSize: 12 }}
              data={data}
              options={{
                search: false,
                actionsColumnIndex: 0,
                exportAllData: false,
                paging: false,
                toolbarButtonAlignment: "left",
                maxBodyHeight: 500,
                tableLayout: "auto",
                exportButton: false,
                padding: "dense",
                toolbar: false,
              }}
            />
          </div>
        );
      }
    }
    return <div>{content}</div>;
  }
}

export default withRouter(observer(OrderDetailView));
