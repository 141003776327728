import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import accountAdminStore from "../../../../Stores/AccountUpdated/Admin/AccountAdminStore";

import MaterialTable from "material-table";

import moment from "moment";

import { Checkbox, CheckboxGroup, Col, HelpBlock } from "rsuite";
import { FlexboxGrid, Notification } from "rsuite";
import { message } from "antd";
import { IconButton, Icon } from "rsuite";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import Div14 from "../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import InviteNewUser from "./InviteNewUser";

const instance = axios.create({ baseURL: baseURL });

class AdminAccountUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      user: {
        group: "Client",
        company: null,

        compadmin: false,
        comprequest: false,
      },
      clientNumId: "",
      clientLoad: false,
      client: { name: "", _id: "" },
      updateLoad: false,
    };
    this.selectUser = this.selectUser.bind(this);
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
  }
  componentDidMount() {
    accountAdminStore.getUsers(this.props.match.params.clientid, true);
  }

  selectUser(user) {
    this.setState({ user: user });
  }

  editClientId(type, val) {
    this.setState({ clientNumId: val });
  }

  editVal(type, val) {
    let data = { ...this.state.user };
    data[type] = val;
    this.setState({ user: data });
  }

  updateVal = async () => {
    this.setState({ updateLoad: true });
    let user = this.state.user;
    let data = {
      compadmin: user.compadmin,
      comprequest: user.comprequest,
    };

    await instance
      .put(`ac/accountadmin/edituseraccess/${this.state.user._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        accountAdminStore.editMainUser(this.state.user);
        message.success(`User Details Updated.`);
        this.showHideModal(false);
      })
      .catch((err) => {
        this.setState({ loadmainjob: false, loadfocalpoint: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        loading: false,
        user: {
          group: "Client",
          jobs: false,
          mgt: false,
          admin: false,
          finance: false,
        },
      });
    }
  }

  render() {
    let users = accountAdminStore.users;

    let columnnames = [
      { title: "User ID", field: "userNumId" },
      { title: "Username", field: "username", defaultSort: "asc" },
      { title: "Email", field: "email" },
      { title: "Phone", field: "phone" },
      {
        title: "Last Sign In",
        field: "lastsignin",
        render: (rowData) => {
          return moment(rowData.lastsignin).fromNow();
        },
      },
      {
        title: "Created",
        field: "createdAt",
        render: (rowData) => {
          return moment(rowData.createdAt).format("DD-MMM-YY");
        },
      },
      {
        title: "Company Admin",
        field: "compadmin",
        render: (rowData) => {
          let check = rowData.compadmin;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
      {
        title: "Company Request",
        field: "comprequest",
        render: (rowData) => {
          let check = rowData.comprequest;
          return (
            <Icon
              icon={check ? "check" : "close"}
              style={{ color: check ? "green" : "red" }}
            />
          );
        },
      },
    ];

    return (
      <LoadBack loading={accountAdminStore.loading.users}>
        <UnifiedModal
          show={this.state.show}
          title="Edit User Details"
          onHide={this.showHideModal}
          footer={
            <FlexboxGrid>
              <Col md={12} sm={12} xs={24}>
                <IconButton
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => {
                    this.showHideModal(false);
                  }}
                >
                  Cancel
                </IconButton>
              </Col>
              <Col md={12} sm={12} xs={24}>
                <IconButton
                  block
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.updateVal();
                  }}
                >
                  Save
                </IconButton>
              </Col>
            </FlexboxGrid>
          }
        >
          <CheckboxGroup>
            <FlexboxGrid>
              <Col md={24} sm={24} xs={24}>
                <Div14>Company Options</Div14>
                <Checkbox
                  checked={this.state.user.compadmin}
                  onClick={() =>
                    this.editVal("compadmin", !this.state.user.compadmin)
                  }
                >
                  Company Admin (Can Invite Users)
                </Checkbox>
                <Checkbox
                  checked={this.state.user.comprequest}
                  onClick={() =>
                    this.editVal("comprequest", !this.state.user.comprequest)
                  }
                >
                  Company Requester (Can Request Jobs)
                </Checkbox>
                <HelpBlock
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  Please contact our office to remove a user from this account.
                </HelpBlock>
              </Col>
            </FlexboxGrid>
          </CheckboxGroup>
        </UnifiedModal>
        <Div14> Account Admin User List</Div14>
        <div
          style={{
            padding: "1%",
            margin: "1%",
            borderRadius: 10,
            boxShadow: "1px 1px 5px black",
          }}
        >
          <MaterialTable
            isLoading={accountAdminStore.loading.users || this.state.loading}
            title={users.length + " Users"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={users}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : "Users List "
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  this.setState({
                    show: true,
                    loading: true,
                    user: rowData,
                  });
                },
              },
              {
                icon: "refresh",
                isFreeAction: true,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  accountAdminStore.getUsers(false, true);
                },
              },
            ]}
          />
        </div>
        <hr />
        <InviteNewUser />
      </LoadBack>
    );
  }
}

export default withRouter(observer(AdminAccountUsers));
