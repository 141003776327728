import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";
import HeaderFooterPDF from "../Template/HeaderFooterPDF";
import ACHeaderSection from "./ACHeaderSection";

class ACReceiptPDF extends Component {
  render() {
    let rct = this.props.rct;

    return (
      <div>
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Payment Receipt ${rct.paymentNumId}-
                ${moment().format("YYMMDDHH")} for ${rct.client.name}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`AC Invoice ${rct.paymentNumId}-
                ${moment().format("YYMMDDHH")} for ${rct.client.name}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <ACHeaderSection rct={rct} />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(ACReceiptPDF);
