import { observer } from "mobx-react";
import React, { Component } from "react";
import MatTableSTD from "../../../../../../../Components/Tables/MatTableSTD";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";

class AddChargeTable extends Component {
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let data = sjStore.sjs[ind].addcharge;

    let columns = [
      { title: "Name", field: "name", defaultSort: "desc" },
      { title: "Price", field: "price" },
    ];

    let actions = [
      {
        icon: "add",
        tooltip: "Add New Charge",
        isFreeAction: true,
        hidden: !editview,
        onClick: (event, rowData) => {
          this.props.showHideModal("New");
        },
      },
      {
        icon: "edit",
        tooltip: "Edit",
        hidden: !editview,
        onClick: (event, rowData) => {
          this.props.changeState(rowData, "selectedCharge");
          this.props.changeState(rowData, "editedCharge");

          this.props.showHideModal("Edit");
        },
      },
      {
        icon: "delete",
        tooltip: "Delete",
        hidden: !editview,
        onClick: (event, rowData) => {
          this.props.changeState(rowData, "selectedCharge");
          this.props.changeState(rowData, "editedCharge");
          this.props.showHideModal("Delete");
        },
      },
    ];

    return (
      <MatTableSTD
        data={data}
        title="Additional Charges"
        columns={columns}
        actions={actions}
        loading={this.props.loading}
      />
    );
  }
}

export default observer(AddChargeTable);
