import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import PickupNotePDF from "../../../../../Components/PDFs/Accounts/Deliveries/PickupNotePDF";
import { Collapse } from "antd";

const { Panel } = Collapse;

class PickupNote extends Component {
  constructor(props) {
    super(props);
    this.state = { key: "0" };
    this.onChange = this.onChange.bind(this);
  }
  onChange(key) {
    this.setState({ key: key });
  }
  render() {
    let del = newDeliveryStore.newdel;
    let to = del.fromaddress;
    let account = newDeliveryStore.account;
    return (
      <div>
        <Div14>Pickup Delivery Note:</Div14>

        <Collapse defaultActiveKey={[""]} onChange={this.onChange} accordion>
          <Panel
            header={`Pickup Delivery Note (${to.status})`}
            style={{
              backgroundColor:
                to.status === "Completed" ? "#d3ffcc" : "#fccaca",
            }}
          >
            <PickupNotePDF del={del} client={account} to={del.fromaddress} />
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default withRouter(observer(PickupNote));
