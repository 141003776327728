import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Loader, Notification, Icon, IconButton, FlexboxGrid } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import hrStore from "../../../../Stores/HR/HRStore";
import NewRenewal from "./NewRenewal";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import AttachmentTemplate from "./AttachmentTemplate";
import { message } from "antd";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../Components/Columns/Col2";
import StaticCalendar from "../../../../Components/Calendar/StaticCalendar";
const instance = axios.create({ baseURL: baseURL });

class RenewalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: false,
      loading: false,
      show: false,
      showatt: false,
      ind: -1,
      hrdoc: {
        name: "",
        type: "Staff",
        validsince: moment(),
        expiry: moment().add(1, "year"),
        remark: "",
        status: "Active",
        staff: "",
        _id: "New",
      },
    };
    this.hideModal = this.hideModal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.changeDelete = this.changeDelete.bind(this);
  }

  onSelectEvent(event) {
    let hrdoc = {
      name: event.name,
      type: event.type,
      validsince: event.validsince,
      expiry: event.expiry,
      remark: event.remark,
      status: event.status,
      _id: event._id,
    };
    if (this.props.page === "Staff") {
      hrdoc.staff = event.staff._id;
    }
    this.setState({ hrdoc: hrdoc, show: true });
  }

  deleteDocument = async (attId) => {
    this.setState({ loading: true });
    Notification["info"]({ title: "Deleting.. Please Wait" });

    let data = { attId: attId };

    await instance
      .put(`media/sidejob/delete`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHRDocs();
        this.hideModal();

        Notification.close();
        message.success("Document Deleted");
        this.setState({ loading: false, show: false });
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  changeDelete(val) {
    this.setState({ delete: val });
  }

  editVal(val, label) {
    let newDoc = { ...this.state.hrdoc };
    newDoc[label] = val;
    this.setState({ hrdoc: newDoc });
  }

  postNew = async () => {
    let newservice = {
      name: this.state.hrdoc.name,
      type: this.state.hrdoc.type,
      validsince: this.state.hrdoc.validsince,
      expiry: this.state.hrdoc.expiry,
      remark: this.state.hrdoc.remark,
      status: this.state.hrdoc.status,
    };
    if (this.props.page === "Staff") {
      newservice.staff = this.state.hrdoc.staff;
    }

    this.setState({ loading: true });
    let url = "/hr/hrdoc/new";

    return instance
      .post(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHRDocs(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Added New HR Doc: ${newservice.name}.`,
          description: <div></div>,
        });
        this.hideModal();
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  postEdit = async () => {
    let newservice = {
      name: this.state.hrdoc.name,
      type: this.state.hrdoc.type,
      validsince: this.state.hrdoc.validsince,
      expiry: this.state.hrdoc.expiry,
      remark: this.state.hrdoc.remark,
      status: this.state.hrdoc.status,
    };

    if (this.props.page === "Staff") {
      newservice.staff = this.state.hrdoc.staff;
    }

    this.setState({ loading: true });
    let url = `/hr/hrdoc/edit/${this.state.hrdoc._id}`;

    return instance
      .put(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getHRDocs(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Updated HR Doc: ${newservice.name}.`,
          description: <div></div>,
        });
        this.hideModal();
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  hideModal() {
    this.setState({ show: false, showatt: false, delete: false });
    let hrdoc = {
      name: "",
      type: this.props.page,
      staff: "",
      validsince: moment(),
      expiry: moment().add(1, "year"),
      remark: "",
      status: "Active",
      _id: "New",
    };
    this.setState({ hrdoc: hrdoc, ind: -1 });
  }
  componentDidMount() {
    hrStore.getHRDocs(true);
  }
  render() {
    let columnnames = [
      { title: "ID", field: "hrDocNumId" },
      { title: "Status", field: "status" },
      { title: "Name", field: "name" },
      { title: "Type", field: "type" },
      { title: this.props.page, field: "staff.name" },
      { title: "Remarks", field: "remark" },
      {
        title: "Since",
        field: "validsince",
        render: (row) => moment(row.validsince).format("DD-MMM-YY"),
      },
      {
        title: "Expiry",
        field: "expiry",
        render: (row) => moment(row.expiry).format("DD-MMM-YY"),
      },
      {
        title: "Days Left",
        field: "expiry",
        render: (row) => moment(row.expiry).diff(moment(), "days"),
        defaultSort: "asc",
      },
    ];

    let page = this.props.page;
    let data = hrStore.hrdoc.filter((hr) => hr.type !== "Staff");
    if (page === "Staff") {
      data = hrStore.hrdoc.filter((hr) => hr.type === "Staff");
    }
    let name = "";
    let type = "";
    if (this.state.ind >= 0) {
      name = data[this.state.ind].name;
      type = data[this.state.ind].type;
    }

    return (
      <div>
        <Div14>{page} Renewal Page</Div14>
        <UnifiedModal
          show={this.state.showatt}
          onHide={this.hideModal}
          title={`${type}: ${name} Attachment`}
          footer={
            this.state.delete ? (
              <FlexboxGrid>
                <Col2>
                  <IconButton
                    block
                    color="red"
                    icon={<Icon icon="trash" />}
                    onClick={() =>
                      this.deleteDocument(data[this.state.ind].attachment._id)
                    }
                  >
                    Yes Delete
                  </IconButton>
                </Col2>
                <Col2>
                  <IconButton
                    block
                    icon={<Icon icon="save" />}
                    onClick={() => this.changeDelete(false)}
                  >
                    No, Keep
                  </IconButton>
                </Col2>
              </FlexboxGrid>
            ) : (
              <FlexboxGrid>
                <Col2>
                  <IconButton
                    block
                    icon={<Icon icon="close" />}
                    onClick={() => this.hideModal()}
                  >
                    Close
                  </IconButton>
                </Col2>
                <Col2>
                  <IconButton
                    icon={<Icon icon="trash" />}
                    onClick={() => this.changeDelete(true)}
                    color="red"
                  >
                    Delete
                  </IconButton>
                </Col2>
              </FlexboxGrid>
            )
          }
        >
          <AttachmentTemplate hrdoc={data[this.state.ind]} />
        </UnifiedModal>
        <NewRenewal
          show={this.state.show}
          hideModal={this.hideModal}
          hrdoc={this.state.hrdoc}
          loading={this.state.loading}
          editVal={this.editVal}
          postNew={this.postNew}
          postEdit={this.postEdit}
          page={page}
        />

        <br />
        {hrStore.loading ? (
          <Loader center />
        ) : (
          <MaterialTable
            title={data.length + " " + page + " Renewals"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle
                  ? this.props.exportTitle
                  : page + " Renewals"
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
              rowStyle: (rowData) => ({
                backgroundColor: rowData.status === "Cancelled" && "#ffafa3",
              }),
            }}
            actions={[
              {
                icon: "add",
                isFreeAction: true,
                tooltip: "Add",
                onClick: (event, rowData) => {
                  let hrdoc = {
                    name: "",
                    type: page,
                    validsince: moment(),
                    expiry: moment().add(1, "year"),
                    remark: "",
                    status: "Active",
                    staff: "",
                    _id: "New",
                  };
                  this.setState({ show: true, hrdoc: hrdoc });
                },
              },
              {
                icon: "refresh",
                isFreeAction: true,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  hrStore.getHRDocs(true);
                },
              },
              {
                icon: "close",
                isFreeAction: true,
                tooltip: "Load Cancelled",
                hidden: hrStore.hrdoccancloaded,
                onClick: (event, rowData) => {
                  hrStore.getHRDocsCanc(true);
                },
              },
              {
                icon: "edit",
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  let hrdoc = {
                    name: rowData.name,
                    type: rowData.type,
                    validsince: rowData.validsince,
                    expiry: rowData.expiry,
                    remark: rowData.remark,
                    status: rowData.status,
                    _id: rowData._id,
                  };
                  if (page === "Staff") hrdoc.staff = rowData.staff._id;
                  this.setState({ hrdoc: hrdoc, show: true });
                },
              },
              (rowdata) => ({
                icon: rowdata.attachment ? "save" : "add",
                tooltip: rowdata.attachment
                  ? "View Attachment"
                  : "Add Attachment",
                onClick: (event, rowData) => {
                  let hrdoc = {
                    name: rowData.name,
                    type: rowData.type,
                    validsince: rowData.validsince,
                    expiry: rowData.expiry,
                    remark: rowData.remark,
                    status: rowData.status,
                    _id: rowData._id,
                  };
                  if (page === "Staff") hrdoc.staff = rowData.staff._id;
                  this.setState({
                    hrdoc: hrdoc,
                    showatt: true,
                    ind: rowData.tableData.id,
                  });
                },
              }),
            ]}
          />
        )}
        <br />
        <Div14>Expiry Calendar</Div14>
        <StaticCalendar
          start={"expiry"}
          end={"expiry"}
          title={"title"}
          height={300}
          data={data}
          onSelectEvent={this.onSelectEvent}
        />
        <br />
      </div>
    );
  }
}

export default observer(RenewalPage);
