import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ParallaxImage from "../../../Components/Parallax/ParallaxImage";
import userStore from "../../../Stores/UserStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import pic from "../../../Static/Images/CompanyPics/pic1.png";
import Col2 from "../../../Components/Columns/Col2";
import IconItem from "../../../Components/Lists/IconItem";
import SingleBenefit from "../Sections/Benefits/SingleBenefit";
import HRWhite from "../../../Components/Dividers/HRWhite";

class LastMile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ParallaxImage image={pic} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              Last Mile Solution
            </p>
            <p style={{ fontFamily: "Work Sans", fontSize: 20 }}>
              With Easy Move
            </p>
            <HRWhite />
            <p style={{ fontFamily: "sans-serif", fontSize: 24 }}>
              Our own Packers, Movers, Drivers, and GPS Tracked Trucks
            </p>

            <div style={{ textShadow: "1px 1px black" }}>
              <p style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                levate your customer experience with our cutting-edge last mile
                delivery solution. Our technology and experienced team provide
                reliable and efficient delivery directly to your customers,
                offering real-time tracking, flexible delivery options, and an
                end-to-end seamless experience. Trust us to revolutionize your
                last mile delivery and delight your customers.
              </p>
            </div>
            <FlexboxGrid justify="center">
              <Col2>
                <IconItem icon="people-group" text="In-House Staff" />
                <br />
                <IconItem icon="truck" text="GPS Trucks" />
                <br />
              </Col2>
              <Col2>
                <IconItem icon="lock" text="Safety and Security" />
                <br />
                <IconItem icon="pc" text="Fully Digitalized" />
                <br />
              </Col2>
            </FlexboxGrid>
            {!userStore.signedIn && (
              <>
                <HRWhite />
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="lg"
                  color="blue"
                  onClick={() => this.props.history.push("/login")}
                >
                  Join Easy Move Today!
                </IconButton>
              </>
            )}
          </div>
        </ParallaxImage>
        <SingleBenefit />
      </div>
    );
  }
}

export default withRouter(observer(LastMile));
