import { observer } from "mobx-react";

import React, { Component } from "react";
import { Divider, Icon, IconButton } from "rsuite";

import { Tabs } from "antd";

import { withRouter } from "react-router";

import OrderTable from "./OrderTable";
import { Tooltip } from "@material-ui/core";
import { InputGroup } from "rsuite";
import singleAccountStore from "../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import acInternationalStore from "../../../../../../Stores/AccountUpdated/AIM/ACInternationalStore";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import DatePickerSTD from "../../../../../../Components/Inputs/STD/DatePickerSTD";
import FlexboxButton from "../../../../../../Components/Modal/FlexboxButton";
import modalACOrderStore from "../../../../../../Stores/ModalStores/ModalACOrderStore";
import OrderModal from "../../../Modals/OrderModal";
const { TabPane } = Tabs;

class OrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, tab: "Pending Deliveries" };
    this.onHide = this.onHide.bind(this);
    this.ActionButs = this.ActionButs.bind(this);
  }
  componentDidMount() {
    singleAccountStore.getACOrders(this.props.clientid);
    acInternationalStore.getOrders(this.props.clientid, true);
  }
  onHide() {
    this.setState({ show: false });
  }
  ActionButs(cellData) {
    return (
      <>
        <Tooltip title={`View Order`} arrow>
          <IconButton
            icon={<Icon icon="eye" />}
            color="green"
            circle
            size="xs"
            onClick={() => {
              modalACOrderStore.selectAIM(cellData.data);
              this.setState({ show: true });
            }}
          />
        </Tooltip>
      </>
    );
  }
  render() {
    return (
      <LoadBack loading={acInternationalStore.loading.order}>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <DatePickerSTD
              title={"From Date"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={acInternationalStore.startdate}
              size="large"
              editVal={acInternationalStore.editDate}
            />
            <InputGroup.Button
              color="green"
              loading={acInternationalStore.loading.order}
              onClick={() => {
                acInternationalStore.getOrders(false, true);
              }}
            >
              Reload <Divider vertical />
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
          centered
        >
          <TabPane tab={"Pending Deliveries"} key={"Pending Deliveries"}>
            <OrderTable
              data={acInternationalStore.orders}
              type="Current"
              actionButs={this.ActionButs}
            />
          </TabPane>
          <TabPane tab={"Past Deliveries"} key={"Past Deliveries"}>
            {acInternationalStore.loaded.pastorders ? (
              <OrderTable
                data={acInternationalStore.pastorders}
                type="Past"
                actionButs={this.ActionButs}
              />
            ) : (
              <FlexboxButton
                icon="eye"
                color="green"
                text="Load Past Deliveries"
                app="primary"
                oC={() =>
                  acInternationalStore.getCustomOrderStatus("Completed")
                }
              />
            )}
          </TabPane>
          <TabPane tab={"Cancelled Deliveries"} key={"Cancelled Deliveries"}>
            {acInternationalStore.loaded.cancelledorders ? (
              <OrderTable
                data={acInternationalStore.cancelledorders}
                type="Cancel"
                actionButs={this.ActionButs}
              />
            ) : (
              <FlexboxButton
                icon="eye"
                color="green"
                text="Load Cancelled Deliveries"
                app="primary"
                oC={() =>
                  acInternationalStore.getCustomOrderStatus("Cancelled")
                }
              />
            )}
          </TabPane>
        </Tabs>

        <br />
      </LoadBack>
    );
  }
}

export default withRouter(observer(OrderMain));
