import { observer } from "mobx-react";
import React, { Component } from "react";

import { Col, FlexboxGrid, List } from "rsuite";
import { Tooltip } from "@material-ui/core";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";

class InvoiceDetails extends Component {
  render() {
    let data = this.props.data.data;
    let subJobs = data.subJobs;
    let sjs = [];
    let sj = {};
    let jobtype = "Local Move";

    for (let i = 0; i < subJobs.length; i++) {
      if (subJobs[i].jobtype === "LM") jobtype = "Local Move";
      if (subJobs[i].jobtype === "IM") jobtype = "International Move";
      if (subJobs[i].jobtype === "CC") jobtype = "Custom Clearance";
      if (subJobs[i].jobtype === "ST") jobtype = "Storage Job";
      sj = {
        jobNumId: `${subJobs[i].jobtype}-${subJobs[i].numId}`,
        total: 0,
        proposals: [],
        jobtype: jobtype,
      };

      for (let j = 0; j < subJobs[i].proposals.length; j++) {
        if (subJobs[i].proposals[j].added) {
          if (parseFloat(subJobs[i].proposals[j].ammount) !== 0) {
            sj.proposals.push({
              title: subJobs[i].proposals[j].title,
              type: subJobs[i].proposals[j].type,
              ammount: parseFloat(subJobs[i].proposals[j].ammount),
            });
            sj.total += parseFloat(subJobs[i].proposals[j].ammount);
          }
        }
      }
      if (sj.proposals.length > 0) sjs.push({ ...sj });
    }

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        {sjs.map((sj, index) => (
          <List size="xs" hover key={index}>
            <List.Item>
              <Tooltip
                title={`${sj.jobtype} (${sj.jobNumId}) for ${PRKD(
                  sj.total
                )} KD`}
                arrow
                placement="top"
              >
                <FlexboxGrid
                  justify="space-between"
                  style={{ fontWeight: "bold" }}
                >
                  <Col>
                    {sj.jobtype} ({sj.jobNumId})
                  </Col>
                  <Col>{PRKD(sj.total)} KD</Col>
                </FlexboxGrid>
              </Tooltip>
            </List.Item>
            {sj.proposals.map((pr, ind) => (
              <List.Item style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <Tooltip
                  title={`${pr.type}: ${pr.title} for ${PRKD(pr.ammount)} KD`}
                  arrow
                  placement="top"
                >
                  <FlexboxGrid justify="space-between">
                    <Col>
                      {pr.title} ({pr.type})
                    </Col>
                    <Col>{PRKD(pr.ammount)} KD</Col>
                  </FlexboxGrid>
                </Tooltip>
              </List.Item>
            ))}
          </List>
        ))}
      </div>
    );
  }
}

export default observer(InvoiceDetails);
