import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import signature from "../../../Pictures/signature.png";
import moment from "moment";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 3,
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "20%",
  },
  tableCol80white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "80%",
  },
  tableCol80blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "80%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "20%",
  },
  subheader: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
    textAlign: "left",
    color: "black",
    fontWeight: "extrabold",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
});

class TotalTable extends Component {
  render() {
    let inv = this.props.inv;

    let groups = [];
    let ammount = 0;
    let total = 0;
    let jobdesc;

    for (let i = 0; i < inv.subJobs.length; i++) {
      ammount = 0;
      for (let j = 0; j < inv.subJobs[i].proposals.length; j++) {
        ammount += Parsed(inv.subJobs[i].proposals[j].ammount);
      }
      if (inv.subJobs[i].jobtype === "LM") jobdesc = "Local Move Job";
      if (inv.subJobs[i].jobtype === "IM") jobdesc = "International Move Job";
      if (inv.subJobs[i].jobtype === "CC") jobdesc = "Custom Clearance Job";
      if (inv.subJobs[i].jobtype === "ST") jobdesc = "Storage Job";
      let summary = {
        title: `${jobdesc} (${inv.subJobs[i].jobtype}-${inv.subJobs[i].numId}) Total`,
        ammount: PRKD(ammount),
        proposals: inv.subJobs[i].proposals,
      };
      total += ammount;
      groups.push(summary);
    }

    // groups = groups.filter((gr) => parseFloat(gr.ammount) > 0);

    let subitems = groups.map((item, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol80white}>{item.title}</Text>
        <Text style={styles.tableCol20white}>{PRKD(item.ammount)} KWD</Text>
      </View>
    ));

    let imNotes = null;
    let imjob = false;
    for (let i = 0; i < inv.subJobs.length; i++) {
      if (inv.subJobs[i].jobtype === "IM") imjob = true;
    }
    if (imjob) {
      imNotes = (
        <View>
          <Text style={{ lineHeight: 0.5 }}> </Text>
          <Text style={styles.text2}>IM Job Notes:</Text>
          <Text style={styles.text2}>
            • Easy Move Logistics will charge the customer a cancellation fee of
            25% if a booking is cancelled, please note refund process will take
            maximum 10 Business days.
          </Text>
          <Text style={styles.text2}>
            • Easy Move will not be responsible for any claim/port storage,
            Detention to containers due to vessel delay by berth congestion /
            weather condition/operation delays.
          </Text>
          <Text style={styles.text2}>
            • Vessel Schedules are subject to Change without Prior Notice.
          </Text>
          <Text style={styles.text2}>
            • Rate offer is subject to space and equipment availability at the
            actual time of shipment.
          </Text>
          <Text style={styles.text2}>
            • Easy Move will not be responsible for Any damage to cargo and/or
            equipment due to improper stuffing.
          </Text>
          <Text style={styles.text2}>
            • Arrival, berthing, departure, and transit times are estimated and
            given without guarantee and subject to change without prior notice.
          </Text>
          <Text style={styles.text2}>
            • Consignee are advised to insure their cargo.
          </Text>
          <Text style={styles.text2}>
            • Shipping prices are subject to change without notice as per the
            Shipping line policy and are not guaranteed.
          </Text>
        </View>
      );
    }

    return (
      <View>
        <Text style={{ lineHeight: 0.5 }}> </Text>
        <Text style={styles.tableHeaderStyle}>
          Total Invoiced Cost of Services
        </Text>
        <View>{subitems}</View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol80blue}>Total Invoice Cost</Text>
          <Text style={styles.tableCol20blue}>{PRKD(total)} KD</Text>
        </View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.text2}>General Proposal Notes:</Text>
        <Text style={styles.text2}>
          • Any cancellations less than 48 hours of the start of the job under
          any circumstance will result in a penalty equivalent to 65 Kuwaiti
          Dinars.{" "}
          {imjob &&
            " (Not applicable for International Move Jobs. Please see the below section for International Job cancellation policy and fees.)"}
        </Text>
        <Text style={styles.text2}>
          • This quotation is valid until{" "}
          {moment(inv.dateto).format("DD-MMM-YY")}.
        </Text>
        <Text style={styles.text2}>
          • The job dates specified in this proposal are subject to
          availability.
        </Text>
        <Text style={styles.text2}>
          • Additioal unplanned services that occur on the job site (such as
          drilling and wall fixing) is charged separately as an additional cost.
        </Text>
        {imNotes}
        <Text style={{ lineHeight: 0.5 }}> </Text>
        <Text style={styles.text2}>Working Hours Notes:</Text>
        <Text style={styles.text2}>
          • Crew Working Hours: Sunday to Saturday 08:00HRS to 17:30HRS.
        </Text>
        <Text style={styles.text2}>
          • Administration Office Working Hours: Saturday to Thursday 09:00HRS
          to 18:00HRS. (Excluding Public Holidays).
        </Text>
        <Text style={styles.text2}>
          • 100% Advance payment agreed amount are strictly required prior to
          starting of the job, either by Cash / K-net.{" "}
        </Text>
        <Text style={{ lineHeight: 0.5 }}> </Text>
        <Text style={styles.text2}>
          Should you require any additional information please feel free to
          contact the undersigned at your earliest convenience. We thank you for
          the opportunity to quote for your business and assure you of our best
          service levels and prompt attention at all times.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.text2}>{"        "}Yours Sincerely,</Text>
        <Image src={signature} style={{ width: 150 }} />
        <Text style={styles.text2}>
          {"        "}On Behalf of Easy Move Logistics Co.
        </Text>
      </View>
    );
  }
}

export default observer(TotalTable);
