import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Card, CardActionArea, CardMedia, Grow } from "@material-ui/core";

import TruckPic from "../../../Static/Images/blocks/TruckBlock.png";
import FreightPic from "../../../Static/Images/blocks/FreightBlock.png";
import PlanePic from "../../../Static/Images/blocks/AirplaneBlock.png";
import WarehousePic from "../../../Static/Images/blocks/ForkliftBlock.png";
import { Col, FlexboxGrid } from "rsuite";
import VisibilitySensor from "react-visibility-sensor";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = { mouseOver: 0, show: false };
  }

  render() {
    let data = [
      {
        title: "International Freight",
        subtitle:
          "As a member of International Associations such as FIATA, IAM, and ARA. Easy Move has high quality partners worldwide with competitive rates for First Mile Delivery.",
      },
      {
        title: "Custom Clearance",
        subtitle:
          "Easy Move has their own Custom Clearance License and Staff follow up on all Local guidelines, documentation, and processes.",
      },
      {
        title: "Warehousing Solutions",
        subtitle:
          "Climate controlled warehouses in Kuwait. Your items are tagged, scanned, and added to our real time inventory system and prepared for dispatch at your request.",
      },
      {
        title: "Dispatch and Distribution",
        subtitle:
          "Our fleet of GPS monitored Trucks are available to distribute your goods to local businesses or directly to customers across Kuwait.",
      },
    ];
    return (
      <div style={{ background: `linear-gradient(45deg, #ADA996, #EAEAEA)` }}>
        <br />
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          {data[this.state.mouseOver].title}
        </h3>
        <h6 style={{ textAlign: "center", margin: "2%" }}>
          {data[this.state.mouseOver].subtitle}
        </h6>
        <br />
        <VisibilitySensor
          onChange={(val) => this.setState({ show: val })}
          partialVisibility={true}
        >
          <FlexboxGrid style={{ margin: "2%" }} justify="space-around">
            <Grow in={this.state.show} timeout={500}>
              <Col md={6} sm={6} xs={6}>
                <Card
                  style={{
                    boxShadow: "0px 0px 10px black",
                    transform:
                      this.state.mouseOver === 0
                        ? "scale(1.1)"
                        : "scale(0.9) skew(-2deg)",
                  }}
                  onMouseOver={() => this.setState({ mouseOver: 0 })}
                >
                  <CardActionArea onClick={() => console.log("test")}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={FreightPic}
                      alt="freightpic"
                    />
                  </CardActionArea>
                </Card>
              </Col>
            </Grow>
            <Grow in={this.state.show} timeout={1000}>
              <Col md={6} sm={6} xs={6}>
                <Card
                  style={{
                    boxShadow: "0px 0px 10px black",
                    transform:
                      this.state.mouseOver === 1
                        ? "scale(1.1)"
                        : "scale(0.9) skew(-2deg)",
                  }}
                  onMouseOver={() => this.setState({ mouseOver: 1 })}
                >
                  <CardActionArea onClick={() => console.log("test")}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={PlanePic}
                      alt="airplanepic"
                    />
                  </CardActionArea>
                </Card>
              </Col>
            </Grow>
            <Grow in={this.state.show} timeout={1500}>
              <Col md={6} sm={6} xs={6}>
                <Card
                  style={{
                    boxShadow: "0px 0px 10px black",
                    transform:
                      this.state.mouseOver === 2
                        ? "scale(1.1)"
                        : "scale(0.9) skew(-2deg)",
                  }}
                  onMouseOver={() => this.setState({ mouseOver: 2 })}
                >
                  <CardActionArea onClick={() => console.log("test")}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={WarehousePic}
                      alt="Fork Lift"
                    />
                  </CardActionArea>
                </Card>
              </Col>
            </Grow>
            <Grow in={this.state.show} timeout={2000}>
              <Col md={6} sm={6} xs={6}>
                <Card
                  style={{
                    boxShadow: "0px 0px 10px black",
                    transform:
                      this.state.mouseOver === 3
                        ? "scale(1.1)"
                        : "scale(0.9) skew(-2deg)",
                  }}
                  onMouseOver={() => this.setState({ mouseOver: 3 })}
                >
                  <CardActionArea onClick={() => console.log("test")}>
                    <CardMedia
                      component="img"
                      height="250"
                      image={TruckPic}
                      alt="truckpic"
                    />
                  </CardActionArea>
                </Card>
              </Col>
            </Grow>
          </FlexboxGrid>
        </VisibilitySensor>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(Services));
