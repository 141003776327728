import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import acInvoiceStore from "../../../../../../../Stores/Financial/ACInvoiceStore";
import StorageChargeHeader from "../StorageCharges/StorageChargeHeader";
import InvoiceTable from "./InvoiceTable";

class ACInvoiceMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goToNewInvoice = this.goToNewInvoice.bind(this);
    this.goToEditInvoice = this.goToEditInvoice.bind(this);
  }

  goToNewInvoice() {
    this.props.history.push(
      `/sacinvoice/newinvoice/${singleAccountStore.account._id}`
    );
  }
  goToEditInvoice(acinvid) {
    acInvoiceStore.getACInvoiceById(acinvid);
    this.props.history.push(`/sacinvoice/editinvoice/${acinvid}`);
  }
  componentDidMount() {
    acInvoiceStore.getInvoiceByClient(singleAccountStore.account._id);
    acInvoiceStore.getPaymentsByClient(singleAccountStore.account._id);
  }
  render() {
    return (
      <div>
        <StorageChargeHeader hidedetails />
        <InvoiceTable
          onNewClick={this.goToNewInvoice}
          onEditClick={this.goToEditInvoice}
        />
      </div>
    );
  }
}

export default withRouter(observer(ACInvoiceMain));
