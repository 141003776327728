import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  FilterRow,
  LoadPanel,
  Toolbar,
  Item,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import { Tooltip } from "antd";

import staffStorageStatStore from "../../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";

class ClientBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = { table: true };
  }

  render() {
    let data = staffStorageStatStore.clientbreakdown.clients;

    return (
      <DataGrid
        dataSource={data}
        keyExpr="clientNumId"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        rowAlternationEnabled
        onExporting={this.onExporting}
        style={{ fontSize: 10 }}
        searchPanel={false}
        hoverStateEnabled={true}
      >
        <LoadPanel enabled={true} />
        <FilterRow visible={true} applyFilter />
        <GroupPanel visible={false} />
        <SearchPanel visible={true} />
        <Grouping autoExpandAll={this.state.autoExpandAll} />
        <Scrolling mode="standard" showScrollbar="always" />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={true} />

        <Column caption="Item" alignment={"center"}>
          <Column
            dataField="clientNumId"
            dataType="number"
            caption="ID"
            alignment="center"
            width={60}
          />
          <Column
            dataField="name"
            dataType="text"
            caption="Client"
            alignment="center"
            minWidth={120}
          />
        </Column>

        <Column caption="CBM" alignment={"center"}>
          <Column
            dataField="cbmin"
            caption="CBM In"
            alignment="center"
            minWidth={60}
            format={{ type: "fixedPoint", precision: 3 }}
          />
          <Column
            dataField="cbmout"
            caption="CBM Out"
            alignment="center"
            minWidth={60}
            format={{ type: "fixedPoint", precision: 3 }}
          />
          <Column
            dataField="cbmtotal"
            caption="CBM Total"
            alignment="center"
            minWidth={60}
            format={{ type: "fixedPoint", precision: 3 }}
          />
        </Column>
        <Column caption="QTY" alignment={"center"}>
          <Column
            dataField="qtyin"
            caption="QTY In"
            alignment="center"
            minWidth={60}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="qtyout"
            caption="QTY Out"
            alignment="center"
            minWidth={60}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="qtytotal"
            caption="QTY Out"
            alignment="center"
            minWidth={60}
            format={{ type: "fixedPoint", precision: 0 }}
          />
        </Column>
        <Column caption="In Jobs" alignment={"center"}>
          <Column
            dataField="stoin"
            caption="Storage"
            alignment="center"
            minWidth={40}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="delin"
            caption="Deliveries"
            alignment="center"
            minWidth={40}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="totalin"
            caption="In Jobs"
            alignment="center"
            minWidth={80}
            format={{ type: "fixedPoint", precision: 0 }}
          />
        </Column>
        <Column caption="Out Jobs" alignment={"center"}>
          <Column
            dataField="stoout"
            caption="Storage"
            alignment="center"
            minWidth={40}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="delout"
            caption="Deliveries"
            alignment="center"
            minWidth={40}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="totalout"
            caption="In Jobs"
            alignment="center"
            minWidth={80}
            format={{ type: "fixedPoint", precision: 0 }}
          />
        </Column>
        <Column caption="Totals" alignment={"center"}>
          <Column
            dataField="sto"
            caption="Storage Jobs"
            alignment="center"
            minWidth={100}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="del"
            caption="Delivery Jobs"
            alignment="center"
            minWidth={100}
            format={{ type: "fixedPoint", precision: 0 }}
          />
          <Column
            dataField="totaljobs"
            caption="Total Jobs"
            alignment="center"
            minWidth={100}
            format={{ type: "fixedPoint", precision: 0 }}
          />
        </Column>
        <Summary>
          <TotalItem
            column="name"
            summaryType="count"
            valueFormat={"#,##0"}
            displayFormat={"{0}"}
          />
          <TotalItem
            column="cbmin"
            summaryType="sum"
            valueFormat={"#,##0.0"}
            displayFormat={"{0} CBM"}
          />
          <TotalItem
            column="cbmout"
            summaryType="sum"
            valueFormat={"#,##0.0"}
            displayFormat={"{0} CBM"}
          />
          <TotalItem
            column="cbmtotal"
            summaryType="sum"
            valueFormat={"#,##0.0"}
            displayFormat={"{0} CBM"}
          />
          <TotalItem
            column="qtyin"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Items"}
          />
          <TotalItem
            column="qtyout"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Items"}
          />
          <TotalItem
            column="qtytotal"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Items"}
          />
          <TotalItem
            column="delin"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="delout"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="del"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="stoin"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="stoout"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="sto"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="totalout"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="totalin"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
          <TotalItem
            column="totaljobs"
            summaryType="sum"
            valueFormat={"#,##0"}
            displayFormat={"{0} Jobs"}
          />
        </Summary>
        <Export enabled={true} />

        <Toolbar>
          <Tooltip title={`Choose Columns`} arrow placement="left">
            <Item name="columnChooserButton" />
          </Tooltip>
          <Item name="exportButton" />
        </Toolbar>
      </DataGrid>
    );
  }
}

export default withRouter(observer(ClientBreakdown));
