import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, InputGroup } from "rsuite";
import { Notification, Input } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import MatTableSTD from "../../../../../../Components/Tables/MatTableSTD";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";

import { Select } from "antd";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import imsuppliertypes from "../../../../../../Static/Lists/imlists/imsuppliertypes";
import imSupplierStore from "../../../../../../Stores/AdditionalStores/IMSupplierStore";

const instance = axios.create({ baseURL: baseURL });

const { Option } = Select;

class IMQuotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      type: "New",
      new: {
        imsupplier: { company: "", id: "" },
        companytype: "Freight Forwarder",
        price: 0,
        rating: "Good",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
      old: {
        imsupplier: { company: "", id: "" },
        companytype: "Freight Forwarder",
        price: 0,
        rating: "Good",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.postDelete = this.postDelete.bind(this);
  }

  componentDidMount() {
    imSupplierStore.getSuppliers();
  }
  changeVal(label, val) {
    let track = { ...this.state.new };

    if (label === "imsupplier") {
      track.imsupplier._id = val;
      let filtcomp = imSupplierStore.suppliers.filter((im) => im._id === val);
      track.imsupplier.company = filtcomp[0].company;
    } else {
      track[label] = val;
    }
    this.setState({ new: track });

    if (label === "companytype") {
      imSupplierStore.changeSupplierType(val);
    }
  }

  resetState() {
    this.setState({
      type: "New",
      new: {
        imsupplier: { company: "" },
        companytype: "Freight Forwarder",
        price: 0,
        rating: "Good",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
      old: {
        imsupplier: { company: "" },
        companytype: "Freight Forwarder",
        price: 0,
        rating: "Good",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
    });
  }

  showHideModal(val, item) {
    if (val === "Edit" || val === "Delete") {
      this.setState({ show: true, type: val, new: item, old: item });
    } else if (val === "New") {
      this.setState({ show: true });
      this.resetState();
    } else {
      this.setState({ loading: false, show: false });
      this.resetState();
    }
  }

  postNew = async (quote) => {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    this.changeVal("loading", true);

    let data = {
      imsupplier: quote.imsupplier,
      companytype: quote.companytype,
      price: quote.price,
      rating: quote.rating,
      remarks: quote.remarks,
      imsj: sj.im._id,
    };
    let newdata = {
      companytype: quote.companytype,
      imsj: quote.imsj,
      price: quote.price,
      rating: quote.rating,
      remarks: quote.remarks,
      _id: quote._id,
      imsupplier: {
        company: quote.imsupplier.company,
        _id: quote.imsupplier._id,
      },
    };
    let url = "/imadditional/quote/new";

    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newdata._id = res.data._id;
        sjStore.newList("imquotes", newdata, ind, "im");

        this.changeVal("loading", false);
        Notification["success"]({
          title: `New IM Track Created.`,
          description: <div>{data.title}</div>,
        });
        this.showHideModal(false);
      })
      .catch((err) => {
        console.log(err);
        this.changeVal("loading", false);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };
  postEdit = async (quote) => {
    let ind = this.props.ind;

    this.changeVal("loading", true);
    let data = {
      imsupplier: quote.imsupplier,
      companytype: quote.companytype,
      price: quote.price,
      rating: quote.rating,
      remarks: quote.remarks,
    };
    let newdata = {
      companytype: quote.companytype,
      imsj: quote.imsj,
      price: quote.price,
      rating: quote.rating,
      remarks: quote.remarks,
      _id: quote._id,
      imsupplier: {
        company: quote.imsupplier.company,
        _id: quote.imsupplier._id,
      },
    };
    let url = `/imadditional/quote/edit/${quote._id}`;

    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.changeList("imquotes", newdata, quote.tableData.id, ind, "im");
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Edited IM Track.`,
          description: <div>{data.title}</div>,
        });
        this.showHideModal(false);
      })
      .catch((err) => {
        console.log(err);
        this.changeVal("loading", false);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  postDelete = async (quote) => {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    this.changeVal("loading", true);
    let data = { imquoteid: quote._id, subJobId: sj._id };
    let url = `/imadditional/quote/deletebyid`;

    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.removeList("imquotes", quote.tableData.id, ind, "im");

        this.changeVal("loading", false);
        Notification["success"]({
          title: `Deleted IM Track.`,
          description: <div>{quote.title}</div>,
        });
        this.showHideModal(false);
      })
      .catch((err) => {
        console.log(err);
        this.changeVal("loading", false);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  render() {
    let ind = this.props.ind;
    let sj = sjStore.sjs[ind];
    let quotes = sj.im.imquotes;

    let disabled = false;
    if (this.state.type !== "Delete") {
      if (this.state.new.title === "") disabled = true;
      if (this.state.new.imsupplier.company === "") disabled = true;
      if (this.state.new.price <= 0) disabled = true;
    }

    let columns = [
      { title: "Supplier", field: "imsupplier.company" },
      { title: "Company", field: "companytype" },
      { title: "Price", field: "price" },
      { title: "Rating", field: "rating" },
      { title: "Remarks", field: "remarks" },
    ];

    let actions = [
      {
        icon: "add",
        tooltip: "Add New Charge",
        isFreeAction: true,
        onClick: (event, rowData) => this.showHideModal("New"),
      },
      {
        icon: "edit",
        tooltip: "Edit",
        onClick: (event, rowData) => this.showHideModal("Edit", rowData),
      },
      {
        icon: "delete",
        tooltip: "Delete",
        onClick: (event, rowData) => this.showHideModal("Delete", rowData),
      },
    ];

    let supplieroptions = imsuppliertypes.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));

    let availSups = imSupplierStore.suppliers.filter(
      (sup) => sup.type === this.state.new.companytype
    );

    let suppliernames = availSups.map((val, index) => (
      <Option value={val._id} key={index}>
        {val.company}
      </Option>
    ));

    let footer = (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="close" />}
              onClick={() => this.showHideModal(false)}
            >
              Cancel
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              block
              disabled={disabled}
              loading={this.state.loading}
              color={this.state.type === "Delete" ? "red" : "green"}
              icon={<Icon icon="close" />}
              onClick={() => {
                this.setState({ loading: true });
                if (this.state.type === "New") {
                  this.postNew(this.state.new);
                } else if (this.state.type === "Edit") {
                  this.postEdit(this.state.new);
                } else if (this.state.type === "Delete") {
                  this.postDelete(this.state.new);
                }
              }}
            >
              {this.state.type === "Edit"
                ? "Save Changes"
                : this.state.type === "New"
                ? "Create New Track"
                : "Yes Delete"}
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );

    let modalbody;
    if (this.state.type === "Delete") {
      modalbody = (
        <div>
          <h5>Are you sure you want to Delete this Track?</h5>
          <hr />
          <div style={{ marginLeft: "5%" }}>
            <h6>
              <b>Type: </b>
              {this.state.new.imsupplier.company}
            </h6>
            <h6>
              <b>Status: </b>
              {this.state.new.companytype}
            </h6>
            <h6>
              <b>Price: </b>
              {this.state.new.price}
            </h6>
            <h6>
              <b>Rating: </b>
              {this.state.new.rating}
            </h6>
            <h6>
              <b>Remarks: </b>
              {this.state.new.remarks}
            </h6>
          </div>
        </div>
      );
    } else {
      modalbody = (
        <div>
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Type</InputGroup.Addon>
                <Select
                  size="large"
                  showSearch
                  value={this.state.new.companytype}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={(e) => this.changeVal("companytype", e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {supplieroptions}
                </Select>
              </InputGroup>
            </Col2>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Supplier</InputGroup.Addon>
                <Select
                  size="large"
                  showSearch
                  value={this.state.new.imsupplier._id}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={(e) => {
                    console.log(e);
                    this.changeVal("imsupplier", e);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {suppliernames}
                </Select>
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Price</InputGroup.Addon>
                <Input
                  type="number"
                  size="lg"
                  disabled={this.state.loading}
                  value={this.state.new.price}
                  onChange={(e) => this.changeVal("price", e)}
                />
              </InputGroup>
            </Col2>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Rating</InputGroup.Addon>
                <Select
                  size="large"
                  showSearch
                  value={this.state.new.rating}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={(e) => this.changeVal("rating", e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Good"}> Good </Option>
                  <Option value={"Slow"}> Slow </Option>
                  <Option value={"Expensive"}> Expensive </Option>
                </Select>
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <InputGroup>
              <InputGroup.Addon>Remarks</InputGroup.Addon>
              <Input
                size="lg"
                disabled={this.state.loading}
                value={this.state.new.remarks}
                onChange={(e) => this.changeVal("remarks", e)}
              />
              {this.state.new.remarks !== this.state.old.remarks && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <div>
        <UnifiedModal
          title={`${this.state.type} IM Quote`}
          show={this.state.show}
          onHide={this.showHideModal}
          footer={footer}
        >
          {modalbody}
        </UnifiedModal>

        <MatTableSTD
          data={quotes}
          title="IM Quotes"
          columns={columns}
          actions={actions}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default observer(IMQuotes);
