import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid } from "rsuite";
import { Statistic } from "antd";
import whmTruckStore from "../WHMStores/WHMTruckStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import Div14 from "../../../Components/Dividers/Div14";

class LatestMoveStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let ms = whmTruckStore.movestats;
    let main = [
      { title: "Total Pallets", val: ms.palletlength },
      { title: "Total Moved", val: ms.unloaded + ms.stacked + ms.loaded },
      { title: "Pallets Remaining", val: ms.prepared },
      {
        title: "% Complete",
        val: `${Math.round(
          (100 * (ms.unloaded + ms.stacked + ms.loaded)) / ms.palletlength
        )}%`,
      },
    ];
    let sub = [
      { title: "All Items", val: ms.itemreq },
      { title: "Palletized", val: ms.itempal },
      { title: "Remaining", val: ms.itemunpal },
      {
        title: "Percent Palletized",
        val: `${Math.round((100 * ms.itempal) / ms.itemreq)}%`,
      },
    ];
    return (
      <LoadBack loading={whmTruckStore.loading.movestats}>
        <Div14>Item Movement Summary</Div14>
        <FlexboxGrid justify="center">
          {sub.map((stat, index) => (
            <Col key={index} md={6} sm={6} xs={12}>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "1%",
                }}
              >
                <Statistic
                  title={stat.title}
                  value={stat.val}
                  precision={0}
                  valueStyle={{ color: stat.red ? "red" : "#3f8600" }}
                  suffix={stat.suffix}
                />
              </div>
            </Col>
          ))}
        </FlexboxGrid>
        <Div14>Pallet Movement Summary</Div14>
        <FlexboxGrid justify="center">
          {main.map((stat, index) => (
            <Col key={index} md={6} sm={6} xs={12}>
              <div
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px black",
                  textAlign: "center",
                  margin: "1%",
                }}
              >
                <Statistic
                  title={stat.title}
                  value={stat.val}
                  precision={0}
                  valueStyle={{ color: stat.red ? "red" : "#3f8600" }}
                  suffix={stat.suffix}
                />
              </div>
            </Col>
          ))}
        </FlexboxGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(LatestMoveStats));
