import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../../Components/Dividers/Div14.js";

import MainAIMInvoice from "../../../../../../../Components/PDFs/AIMInvoice/MainAIMInvoice.js";
import aimInvoiceStore from "../../../../../../../Stores/Financial/AIMInvoiceStore.js";

class Step4 extends Component {
  render() {
    return (
      <div>
        <Div14>Step 4: Finalize Invoice</Div14>
        <MainAIMInvoice inv={aimInvoiceStore.newaiminvoice} />
      </div>
    );
  }
}

export default observer(Step4);
