import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import staffStorageStatStore from "../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import LoadBack from "../../../../../../../../Components/Loaders/LoadBack";

import { Tooltip } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col3 from "../../../../../../../../Components/Columns/Col3";
import ClientBreakdownTable from "./ClientBreakdown/ClientBreakdownTable";
import ClientBreakdownBars from "./ClientBreakdown/ClientBreakdownBars";

class ClientBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = { table: true };
  }
  componentDidMount() {
    staffStorageStatStore.getClientBreakdown();
  }

  render() {
    return (
      <LoadBack loading={staffStorageStatStore.loading.clientbreakdown}>
        <FlexboxGrid style={{ marginLeft: "5%", marginRight: "5%" }}>
          <Col3>
            <Tooltip title="Refresh Data">
              <IconButton
                circle
                icon={<Icon icon="refresh" />}
                appearance={"primary"}
                color="yellow"
                onClick={() => staffStorageStatStore.getClientBreakdown(true)}
                loading={staffStorageStatStore.loading.clientbreakdown}
              />
            </Tooltip>
          </Col3>

          <Col3>
            <IconButton
              block
              icon={<Icon icon="table" />}
              appearance={this.state.table ? "primary" : "ghost"}
              color="green"
              onClick={() => this.setState({ table: true })}
            >
              Table View
            </IconButton>
          </Col3>

          <Col3>
            <IconButton
              block
              icon={<Icon icon="trend" />}
              appearance={!this.state.table ? "primary" : "ghost"}
              color="green"
              onClick={() => this.setState({ table: false })}
            >
              Graph View
            </IconButton>
          </Col3>
        </FlexboxGrid>
        {this.state.table ? <ClientBreakdownTable /> : <ClientBreakdownBars />}
      </LoadBack>
    );
  }
}

export default withRouter(observer(ClientBreakdown));
