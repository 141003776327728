import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Divider,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Loader,
} from "rsuite";
import Col4 from "../../../../../Components/Columns/Col4";
import Div14 from "../../../../../Components/Dividers/Div14";
import editAccountStore from "../../../../../Stores/Account/EditAccountStore";

class TimeButs extends Component {
  render() {
    let opts = this.props.opts;
    let content = opts.map((opt, index) => (
      <IconButton
        appearance="ghost"
        style={{
          color: "black",
          fontWeight: "bold",
          backgroundColor:
            opt.value === "No"
              ? "#ffbdbd"
              : opt.value === "Yes"
              ? "#8fffa3"
              : "#fdffb5",
        }}
        key={index}
        block
        color={
          opt.value === "No" ? "red" : opt.value === "Yes" ? "green" : "yellow"
        }
        icon={
          <Icon
            icon={
              opt.value === "No"
                ? "close"
                : opt.value === "Yes"
                ? "check"
                : "clock-o"
            }
          />
        }
        onClick={() => {
          let value = "Yes";
          if (opt.value === "No") value = "Yes";
          if (opt.value === "After") value = "No";
          if (opt.value === "Yes") value = "After";
          editAccountStore.editStoTiming(opt.label, value);
        }}
      >
        <div style={{ textAlign: "left" }}>
          {opt.type === "hour" ? `${opt.label}:00` : opt.type} (
          {opt.value === "Yes"
            ? "Regular Delivery"
            : opt.value === "No"
            ? "No Delivery"
            : "After Hours"}
          )
        </div>
      </IconButton>
    ));
    return content;
  }
}

class TimingSection extends Component {
  render() {
    let hrs = editAccountStore.edited.storageContract.excludedhours;
    let ohrs = editAccountStore.original.storageContract.excludedhours;
    let opts1 = [
      { type: "hour", label: "0", value: hrs["0"] },
      { type: "hour", label: "1", value: hrs["1"] },
      { type: "hour", label: "2", value: hrs["2"] },
      { type: "hour", label: "3", value: hrs["3"] },
      { type: "hour", label: "4", value: hrs["4"] },
      { type: "hour", label: "5", value: hrs["5"] },
      { type: "hour", label: "6", value: hrs["6"] },
      { type: "hour", label: "7", value: hrs["7"] },
    ];
    let opts2 = [
      { type: "hour", label: "8", value: hrs["8"] },
      { type: "hour", label: "9", value: hrs["9"] },
      { type: "hour", label: "10", value: hrs["10"] },
      { type: "hour", label: "11", value: hrs["11"] },
      { type: "hour", label: "12", value: hrs["12"] },
      { type: "hour", label: "13", value: hrs["13"] },
      { type: "hour", label: "14", value: hrs["14"] },
      { type: "hour", label: "15", value: hrs["15"] },
    ];
    let opts3 = [
      { type: "hour", label: "16", value: hrs["16"] },
      { type: "hour", label: "17", value: hrs["17"] },
      { type: "hour", label: "18", value: hrs["18"] },
      { type: "hour", label: "19", value: hrs["19"] },
      { type: "hour", label: "20", value: hrs["20"] },
      { type: "hour", label: "21", value: hrs["21"] },
      { type: "hour", label: "22", value: hrs["22"] },
      { type: "hour", label: "23", value: hrs["23"] },
    ];
    let opts4 = [
      { type: "Sunday", label: "sun", value: hrs.sun },
      { type: "Monday", label: "mon", value: hrs.mon },
      { type: "Tuesday", label: "tue", value: hrs.tue },
      { type: "Wednesday", label: "wed", value: hrs.wed },
      { type: "Thursday", label: "thu", value: hrs.thu },
      { type: "Friday", label: "fri", value: hrs.fri },
      { type: "Saturday", label: "sat", value: hrs.sat },
    ];

    if (!hrs) {
      return <Loader />;
    }
    let cat1 = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let cat2 = ["0", "1", "2", "3", "4", "5", "6", "7"];
    let cat3 = ["8", "9", "10", "11", "12", "13", "14", "15"];
    let cat4 = ["16", "17", "18", "19", "20", "21", "22", "23"];

    let title1 = false;
    let title2 = false;
    let title3 = false;
    let title4 = false;
    let timingchanged = false;
    for (let i = 0; i < cat1.length; i++) {
      if (hrs[cat1[i]] !== ohrs[cat1[i]]) title1 = true;
    }
    for (let i = 0; i < cat2.length; i++) {
      if (hrs[cat2[i]] !== ohrs[cat2[i]]) title2 = true;
    }
    for (let i = 0; i < cat3.length; i++) {
      if (hrs[cat3[i]] !== ohrs[cat3[i]]) title3 = true;
    }
    for (let i = 0; i < cat4.length; i++) {
      if (hrs[cat4[i]] !== ohrs[cat4[i]]) title4 = true;
    }
    if (title1 || title2 || title3 || title4) timingchanged = true;

    return (
      <div>
        <Div14>
          {timingchanged && (
            <IconButton
              icon={<Icon icon="undo" />}
              circle
              onClick={() => editAccountStore.resettiming()}
            />
          )}
          <Divider vertical />
          Delivery Hours:
          <Divider vertical />
          {timingchanged && (
            <IconButton
              icon={<Icon icon="save" />}
              color="green"
              circle
              onClick={() =>
                editAccountStore.updateSTO("excludedhours", "excludedhours")
              }
            />
          )}
        </Div14>

        <FlexboxGrid>
          <Col4>
            <Div14>Days of the Week</Div14>
            {title1 && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from{" "}
                <i>
                  Sun ({ohrs.sun}); Mon ({ohrs.mon}); Tue ({ohrs.tue}); Wed (
                  {ohrs.wed}); Thu ({ohrs.thu}); Fri ({ohrs.fri}); Sat (
                  {ohrs.sat});
                </i>
              </HelpBlock>
            )}
            <FlexboxGrid>
              <TimeButs opts={opts4} />
            </FlexboxGrid>
          </Col4>
          <Col4>
            <Div14>0:00 to 7:00</Div14>
            {title2 && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from{" "}
                <i>
                  0:00 ({ohrs["0"]}); 1:00 ({ohrs["1"]}); 2:00 ({ohrs["2"]});
                  3:00 ({ohrs["3"]}); 4:00 ({ohrs["4"]}); 5:00 ({ohrs["5"]});
                  6:00 ({ohrs["6"]}); 7:00 ({ohrs["7"]})
                </i>
              </HelpBlock>
            )}
            <FlexboxGrid>
              <TimeButs opts={opts1} />
            </FlexboxGrid>
          </Col4>
          <Col4>
            <Div14>8:00 to 15:00</Div14>
            {title3 && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from{" "}
                <i>
                  8:00 ({ohrs["8"]}); 9:00 ({ohrs["9"]}); 10:00 ({ohrs["10"]});
                  11:00 ({ohrs["11"]}); 12:00 ({ohrs["12"]}); 13:00 (
                  {ohrs["13"]}); 14:00 ({ohrs["14"]}); 15:00 ({ohrs["15"]})
                </i>
              </HelpBlock>
            )}
            <FlexboxGrid>
              <TimeButs opts={opts2} />
            </FlexboxGrid>
          </Col4>

          <Col4>
            <Div14>16:00 to 23:00</Div14>
            {title4 && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from{" "}
                <i>
                  16:00 ({ohrs["16"]}); 17:00 ({ohrs["17"]}); 18:00 (
                  {ohrs["18"]}); 19:00 ({ohrs["19"]}); 20:00 ({ohrs["20"]});
                  21:00 ({ohrs["21"]}); 22:00 ({ohrs["22"]}); 23:00 (
                  {ohrs["23"]})
                </i>
              </HelpBlock>
            )}
            <FlexboxGrid>
              <TimeButs opts={opts3} />
            </FlexboxGrid>
          </Col4>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default observer(TimingSection);
