import { message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  Icon,
  IconButton,
  Notification,
  TagGroup,
  Tag,
  Button,
  FlexboxGrid,
} from "rsuite";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import modalItemStore from "../../../../../Stores/ModalStores/ModalItemStore";
import Div14 from "../../../../../Components/Dividers/Div14";

const instance = axios.create({ baseURL: baseURL });

const typelist = [3];
const locbin = [0, 1, 2, 3];
const locsecs = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
];
const locaisle = [1, 2, 3, 4, 5, 6, 7];
const locsecs2 = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const locaisle2 = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
];

class AddLocationTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      newtype: 3,
      newsec: "",
      newaisle: "",
      newbin: "",
      loading: false,
      new: false,
    };
    this.onHide = this.onHide.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.editNew = this.editNew.bind(this);
    this.uploadChanges = this.uploadChanges.bind(this);
  }
  editNew(val, label) {
    this.setState({ [label]: val });
  }
  onDelete(tag) {
    if (!this.state.loading) {
      let oldtags = modalItemStore.item.loctags;
      let newtags = [];
      for (let i = 0; i < oldtags.length; i++) {
        if (tag.toLowerCase() !== oldtags[i].toLowerCase()) {
          newtags = [...newtags, oldtags[i]];
        }
      }
      this.uploadChanges(newtags);
    }
  }
  onSave() {
    let tags = [...modalItemStore.item.loctags];
    tags.push(
      `${this.state.newtype}${this.state.newsec}${this.state.newaisle}${this.state.newbin}`
    );

    this.uploadChanges(tags);
  }
  onHide() {
    this.setState({ show: false, newtag: "", loading: false });
  }
  uploadChanges(tags) {
    this.setState({ loading: true });
    let data = { loctags: tags };

    return instance
      .put(`/ac/basicedititem/edititem/${modalItemStore.item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false, newtag: "" });
        message.success("Location Tags Updated");
        this.setState({ newbin: "" });
        modalItemStore.updateLocTags(tags);
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  }
  render() {
    let item = modalItemStore.item;
    let disabled = false;
    if (this.state.newtype === "") disabled = true;
    if (this.state.newsec === "") disabled = true;
    if (this.state.newaisle === "") disabled = true;
    if (this.state.newbin === "") disabled = true;
    let indexVal = item.loctags.findIndex(
      (tag) =>
        tag ===
        `${this.state.newtype}${this.state.newsec}${this.state.newaisle}${this.state.newbin}`
    );
    if (indexVal >= 0) disabled = true;
    let locsects = locsecs;
    let locaisles = locaisle;
    if (this.state.newtype === 3) {
      locsects = locsecs2;
      locaisles = locaisle2;
    }
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <IconButton
              icon={<Icon icon="close" />}
              color="red"
              onClick={this.onHide}
              block
            >
              Close
            </IconButton>
          }
          title="Modify Location Tags"
          width="300px"
        >
          <TagGroup>
            {item.loctags.map((tag, index) => (
              <Tag
                size="sm"
                key={index}
                closable
                onClose={() => this.onDelete(tag)}
                color="blue"
              >
                {tag}
              </Tag>
            ))}
          </TagGroup>
          <hr />
          {this.state.new ? (
            <div>
              <Div14>Warehouse</Div14>
              <FlexboxGrid justify="center">
                {typelist.map((type, index) => (
                  <Button
                    color="green"
                    key={index}
                    size="xs"
                    appearance={
                      type === this.state.newtype ? "default" : "ghost"
                    }
                    onClick={() => {
                      if (type !== this.state.newtype) {
                        this.setState({ newaisle: "", newbin: "", newsec: "" });
                      }
                      this.setState({ newtype: type });
                    }}
                  >
                    {type}
                  </Button>
                ))}
              </FlexboxGrid>
              <Div14>Section</Div14>
              <FlexboxGrid justify="center">
                {locsects.map((sec, index) => (
                  <Button
                    color="green"
                    key={index}
                    size="xs"
                    appearance={sec === this.state.newsec ? "default" : "ghost"}
                    onClick={() => this.setState({ newsec: sec })}
                  >
                    {sec}
                  </Button>
                ))}
              </FlexboxGrid>
              <Div14>Aisle</Div14>
              <FlexboxGrid justify="center">
                {locaisles.map((aisle, index) => (
                  <Button
                    color="green"
                    key={index}
                    size="xs"
                    appearance={
                      aisle === this.state.newaisle ? "default" : "ghost"
                    }
                    onClick={() => this.setState({ newaisle: aisle })}
                  >
                    {aisle}
                  </Button>
                ))}
              </FlexboxGrid>
              <Div14>Bin</Div14>
              <FlexboxGrid justify="center">
                {locbin.map((bin, index) => (
                  <Button
                    color="green"
                    key={index}
                    size="xs"
                    appearance={bin === this.state.newbin ? "default" : "ghost"}
                    onClick={() => this.setState({ newbin: bin })}
                  >
                    {bin}
                  </Button>
                ))}
              </FlexboxGrid>
              <hr />
              <IconButton
                icon={<Icon icon="save" />}
                color="green"
                onClick={this.onSave}
                block
                size="xs"
                disabled={disabled}
                loading={this.state.loading}
              >
                Add {this.state.newtype}
                {this.state.newsec}
                {this.state.newaisle}
                {this.state.newbin} Location
              </IconButton>
            </div>
          ) : (
            <IconButton
              icon={<Icon icon="plus" />}
              color="green"
              block
              size="sm"
              onClick={() =>
                this.setState({
                  new: true,
                  newsec: "",
                  newaisle: "",
                  newbin: "",
                  newtype: 3,
                })
              }
            >
              New Loc
            </IconButton>
          )}
        </UnifiedModal>
        {userStore.user.jobs || userStore.user.company === item.account ? (
          <IconButton
            icon={<Icon icon="plus" />}
            size="xs"
            color="green"
            circle
            onClick={() => this.setState({ show: true })}
          />
        ) : (
          <div />
        )}

        <b> Location Tags: </b>
        {item.loctags.join(", ")}
      </div>
    );
  }
}
export default observer(AddLocationTags);
