import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Toolbar,
  Item,
  LoadPanel,
} from "devextreme-react/data-grid";
import axios from "axios";
import Button from "devextreme-react/button";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import Col2 from "../../../../../../Components/Columns/Col2";
import SelectInputSTD from "../../../../../../Components/Inputs/STD/SelectInputSTD";
import damagereportlist from "../../../../../../Static/Lists/damagereportlist";
import NumbInputSTD from "../../../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import userStore from "../../../../../../Stores/UserStore";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import { message } from "antd";
import { Tooltip } from "@material-ui/core";
import Div14 from "../../../../../../Components/Dividers/Div14";
import AddSingleItemRow from "./AddSingleItemRow";
import DRAttachmentMain from "./DRAttachment/DRAttachmentMain";
import DRAttachmentImage from "./DRAttachment/DRAttachmentImage";

const instance = axios.create({ baseURL: baseURL });

class DamageReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acitem: { acItemNumId: 0, name: "" },
      qty: 0,
      remarks: "",
      type: "",
      attachment: null,
      show: false,
      showdelete: false,
      showadd: false,
      showatt: false,
    };
    this.EditItem = this.EditItem.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editSt = this.editSt.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.AddAttachment = this.AddAttachment.bind(this);
  }
  selectItem(item) {
    if (item) {
      this.setState({
        acitem: item.acitem,
        qty: 0,
        remarks: "",
        type: "",
        attachment: item.attachment,
      });
    } else {
      this.setState({
        acitem: { acItemNumId: 0, name: "" },
        qty: 0,
        remarks: "",
        type: "",
        attachment: null,
      });
    }
  }

  updateItem = async (type) => {
    let runapi = false;
    let successmsg = "Updated Item";
    let errormsg = "Updated Item";

    let items = newStorageStore.neworder.damagereport.item;
    let finalitem = [];
    let url = `ac/damagereport/edit/${newStorageStore.neworder.damagereport._id}`;
    if (type === "Edit") {
      successmsg = "Updated Item Successfully";
      errormsg = "Could Not Update Item";
      runapi = true;

      for (let i = 0; i < items.length; i++) {
        if (items[i].acitem.acItemNumId !== this.state.acitem.acItemNumId) {
          finalitem = [...finalitem, { ...items[i] }];
        } else {
          finalitem = [
            ...finalitem,
            {
              acitem: this.state.acitem,
              qty: this.state.qty,
              type: this.state.type,
              remarks: this.state.remarks,
              attachment: this.state.attachment,
            },
          ];
        }
      }
    } else if (type === "Delete") {
      successmsg = "Deleted Item";
      errormsg = "Could Not Delete Item";
      runapi = true;
      for (let i = 0; i < items.length; i++) {
        if (items[i].acitem.acItemNumId !== this.state.acitem.acItemNumId) {
          finalitem = [...finalitem, { ...items[i] }];
        }
      }
    } else if (type === "Add") {
      successmsg = "Added Item Successfully";
      errormsg = "Could Not Add Item";
      runapi = true;
      for (let i = 0; i < items.length; i++) {
        if (items[i].acitem.acItemNumId !== this.state.acitem.acItemNumId) {
          finalitem = [...finalitem, { ...items[i] }];
        }
      }
      finalitem = [
        ...finalitem,
        {
          acitem: this.state.acitem,
          qty: this.state.qty,
          type: this.state.type,
          remarks: this.state.remarks,
          attachment: this.state.attachment,
        },
      ];
    }

    if (runapi) {
      let data = { item: finalitem };

      this.setState({ loading: true });
      await instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          newStorageStore.getSingleACOrder(false, true);
          this.setState({ loading: false });
          message.success(successmsg);
          this.onHide();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(errormsg);
        });
    }
  };

  onHide() {
    this.setState({
      acitem: { acItemNumId: 0, name: "" },
      qty: 0,
      remarks: "",
      type: "",
      show: false,
      showdelete: false,
      showadd: false,
      showatt: false,
    });
  }

  editSt(val, label) {
    this.setState({ [label]: val });
  }

  EditItem(cellData) {
    let it = cellData.data;
    return (
      <>
        {userStore.user.staffaccount && (
          <IconButton
            icon={<Icon icon="pencil" />}
            size="xs"
            circle
            onClick={() =>
              this.setState({
                acitem: it.acitem,
                qty: it.qty,
                remarks: it.remarks,
                type: it.type,
                show: true,
                attachment: it.attachment,
              })
            }
          />
        )}
        {userStore.user.staffaccount && (
          <IconButton
            icon={<Icon icon="trash" />}
            size="xs"
            circle
            color="red"
            appearance="ghost"
            onClick={() =>
              this.setState({
                acitem: it.acitem,
                qty: it.qty,
                remarks: it.remarks,
                type: it.type,
                attachment: it.attachment,
                showdelete: true,
              })
            }
          />
        )}
      </>
    );
  }
  AddAttachment(cellData) {
    let it = cellData.data;
    if (it.attachment) {
      return (
        <IconButton
          icon={<Icon icon="image" />}
          size="xs"
          color="green"
          appearance="ghost"
          circle
          onClick={() =>
            this.setState({
              acitem: it.acitem,
              qty: it.qty,
              remarks: it.remarks,
              type: it.type,
              attachment: it.attachment,
              showatt: true,
            })
          }
        />
      );
    } else {
      if (userStore.user.staffaccount) {
        return (
          <IconButton
            icon={<Icon icon="plus" />}
            size="xs"
            color="green"
            circle
            onClick={() =>
              this.setState({
                acitem: it.acitem,
                qty: it.qty,
                remarks: it.remarks,
                type: it.type,
                attachment: it.attachment,
                showatt: true,
              })
            }
          />
        );
      }
    }
  }
  render() {
    let dr = newStorageStore.oldorder.damagereport;
    let disabled = false;
    if (this.state.type === "") disabled = true;
    if (this.state.qty <= 0) disabled = true;

    return (
      <div>
        <UnifiedModal
          show={this.state.showatt}
          onHide={this.onHide}
          footer={false}
        >
          {this.state.attachment ? (
            <>
              <DRAttachmentImage
                attachment={this.state.attachment}
                acItemNumId={this.state.acitem.acItemNumId}
              />
            </>
          ) : (
            <DRAttachmentMain
              item={this.state.acitem}
              onHide={this.onHide}
              dr={dr}
            />
          )}
        </UnifiedModal>

        <UnifiedModal
          show={this.state.showadd}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "plus"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Add Item"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateItem("Add")}
              disabled2={disabled}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          {this.state.acitem.acItemNumId === 0 ? (
            <>
              <Div14>Select an Item from the Order to Add:</Div14>
              <AddSingleItemRow
                toadds={newStorageStore.oldorder.toaddress}
                dritems={dr.item}
                selectItem={this.selectItem}
              />
            </>
          ) : (
            <>
              <IconButton
                icon={<Icon icon="chevron-left" />}
                color="orange"
                size="xs"
                onClick={() => this.selectItem()}
              >
                Change Item
              </IconButton>
              <h3>
                Add Item: {this.state.acitem.acItemNumId}:{" "}
                {this.state.acitem.name}
              </h3>
              <FlexboxGrid>
                <Col2>
                  <SelectInputSTD
                    title="Damage Type"
                    label="type"
                    opts={damagereportlist}
                    val={this.state.type}
                    editVal={this.editSt}
                    size="large"
                  />
                </Col2>
                <Col2>
                  <NumbInputSTD
                    title="QTY"
                    label="qty"
                    min={0}
                    val={this.state.qty}
                    editVal={this.editSt}
                  />
                </Col2>
              </FlexboxGrid>
              <br />
              <TextAreaInputSTD
                title="Item Remarks"
                label="remarks"
                size="large"
                editVal={this.editSt}
                val={this.state.remarks}
              />
            </>
          )}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showdelete}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateItem("Delete")}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>
            Delete Item: {this.state.acitem.acItemNumId}:{" "}
            {this.state.acitem.name}
          </h3>
          <h5>
            Are you sure you want to Delete this item from the Damage Report?
          </h5>
          <hr />
          <p>
            <b>Item ID: </b>
            {this.state.acitem.acItemNumId}
          </p>
          <p>
            <b>Item Name: </b>
            {this.state.acitem.name}
          </p>
          <p>
            <b>Serial Number: </b>
            {this.state.acitem.serialnumber}
          </p>
          <p>
            <b>Damage Type: </b>
            {this.state.type}
          </p>
          <p>
            <b>Quantity Damaged: </b>
            {this.state.qty}
          </p>
          <p>
            <b>Remarks: </b>
            {this.state.remarks}
          </p>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel Changes", "Save Changes"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateItem("Edit")}
              disabled2={disabled}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>
            Edit Item: {this.state.acitem.acItemNumId}: {this.state.acitem.name}
          </h3>
          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Damage Type"
                label="type"
                opts={damagereportlist}
                val={this.state.type}
                editVal={this.editSt}
                size="large"
              />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="QTY"
                label="qty"
                min={0}
                val={this.state.qty}
                editVal={this.editSt}
              />
            </Col2>
          </FlexboxGrid>
          <br />
          <TextAreaInputSTD
            title="Item Remarks"
            label="remarks"
            size="large"
            editVal={this.editSt}
            val={this.state.remarks}
          />
        </UnifiedModal>

        <DataGrid
          dataSource={dr.item}
          keyExpr="acitem"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          hoverStateEnabled={true}
        >
          <LoadPanel enabled={true} />
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />

          <Column
            dataField="acitem.acItemNumId"
            dataType="number"
            caption="ID"
            alignment="center"
            width={80}
          />
          <Column
            dataField="acitem.name"
            dataType="text"
            caption="Item"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="acitem.serialnumber"
            dataType="text"
            caption="SN"
            alignment="center"
            minWidth={120}
          />
          <Column
            dataField="type"
            caption="Damage"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="qty"
            caption="QTY"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="remarks"
            caption="Remarks"
            alignment="center"
            minWidth={160}
          />

          <Column
            dataField="attachment"
            caption="Attachment"
            alignment="center"
            width={80}
            cellRender={this.AddAttachment}
          />

          {userStore.user.staffaccount && (
            <Column
              dataField="status"
              caption="Edit"
              alignment="center"
              width={80}
              cellRender={this.EditItem}
            />
          )}

          <Summary>
            <TotalItem
              column="acitem.acItemNumId"
              summaryType="count"
              displayFormat={"{0}"}
            />
            <TotalItem
              column="qty"
              summaryType="sum"
              valueFormat={"#,##0"}
              displayFormat={"{0}"}
            />
          </Summary>
          <Toolbar>
            <Item name="Refresh" location="before">
              <Button
                icon="refresh"
                onClick={() => newStorageStore.getSingleACOrder(false, true)}
              />
            </Item>
            {userStore.user.staffaccount && (
              <Item name="New" location="after">
                <Tooltip title={`Add New Item`} arrow placement="left">
                  <IconButton
                    icon={<Icon icon="plus" />}
                    onClick={() => this.setState({ showadd: true })}
                    color="green"
                    circle
                  />
                </Tooltip>
              </Item>
            )}
          </Toolbar>
          <Export enabled={true} />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(DamageReportTable));
