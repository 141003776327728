import { observer } from "mobx-react";
import React, { Component } from "react";

import { PDFViewer } from "@react-pdf/renderer";

import { Document } from "@react-pdf/renderer";
import moment from "moment";

import HeaderSection from "./StandardOnAll/HeaderSection";
import TermsAndConditions from "./StandardOnAll/TermsAndConditions";
import PaymentNotes from "../Invoices/StandardOnAll/PaymentPages/PaymentNotes";

import HeaderFooterPDF from "../Template/HeaderFooterPDF";
import ChargesTableSummary from "./Tables/ChargesTableSummary";

import DeliveryCharges from "./Tables/DeliveryCharges";
import { Parsed } from "../../../Functions/Pricing/PRKDCalc";
import MissingCharges from "./Tables/MissingCharges";
import InvoiceNotes from "./Tables/InvoiceNotes";

class IntroPage extends Component {
  render() {
    let inv = this.props.inv;

    let invoiceID = "New";
    let clientname = inv.clientname;

    let totaldel = 0;
    let totalmissing = 0;

    if (inv.charges) {
      if (inv.charges.length > 0) {
        for (let i = 0; i < inv.charges.length; i++) {
          if (Parsed(inv.charges[i].totaljobchg) !== 0) {
            totaldel += Parsed(inv.charges[i].totaljobchg);
          }

          if (Parsed(inv.charges[i].minchg) !== 0) {
            totalmissing += Parsed(inv.charges[i].minchg);
          }
        }
      }
    }

    return (
      <div>
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Del Invoice Del${invoiceID}-
                ${moment().format("YYMMDDHH")} for ${clientname}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Del Invoice Del${invoiceID}-
                ${moment().format("YYMMDDHH")} for ${clientname}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection inv={inv} />
              <ChargesTableSummary inv={inv} />
            </HeaderFooterPDF>

            {inv.invnotes.length > 12 && (
              <HeaderFooterPDF>
                <InvoiceNotes inv={inv} />
              </HeaderFooterPDF>
            )}
            {totaldel > 0 && (
              <HeaderFooterPDF>
                <DeliveryCharges inv={inv} />
              </HeaderFooterPDF>
            )}
            {totalmissing > 0 && (
              <HeaderFooterPDF>
                <MissingCharges inv={inv} />
              </HeaderFooterPDF>
            )}
            <HeaderFooterPDF>
              <PaymentNotes inv={inv} />
            </HeaderFooterPDF>
            <HeaderFooterPDF>
              <TermsAndConditions />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(IntroPage);
