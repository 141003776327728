import { AttQualityCalc } from "./SupportingQuality/ATTQualityCalc";
import { JDQualityCalc } from "./SupportingQuality/JDQualityCalc";

function LMQualityCalc(data) {
  let finalerror = [0, 0];
  let general = LMQualityMain(data);
  let dates = JDQualityCalc(data);
  let atts = AttQualityCalc(data);

  finalerror[0] = general[0] + dates[0] + atts[0];
  finalerror[1] = general[1] + dates[1] + atts[1];
  finalerror[2] = [...general[2], ...dates[2], ...atts[2]];
  return finalerror;
}

function LMQualityMain(data) {
  let errors = 0;
  let total = 3;
  let items = [];
  if (data.cbm === 0) items.push("CBM");
  if (data.lm.firstprice === 0) items.push("First Price");
  if (data.lm.lastprice === 0) items.push("Last Price");

  errors = items.length;
  return [errors, total, items];
}

export { LMQualityCalc, LMQualityMain };
