import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, IconButton } from "rsuite";

import staffimage from "../../Static/Images/background2.jpg";

import SingleBenefit from "./Sections/Benefits/SingleBenefit";
import Services from "./Sections/Services";
import clearance from "../../Static/Images/banners/clearance.png";
import warehousing from "../../Static/Images/banners/warehousing.png";
import VisiblityCode from "../../Components/Advanced/VisiblityCode";
import userStore from "../../Stores/UserStore";
import ImageCarousel from "./Sections/Carousel/ImageCarousel";
import ParallaxImage from "../../Components/Parallax/ParallaxImage";

import CountUp from "react-countup";

class MainHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      loading: false,
      sent: false,
    };
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
  }

  formatter(value) {
    return <CountUp end={value} separator="," duration={3} />;
  }

  editVal(val, label) {
    this.setState({ [label]: val });
  }
  updateVal() {
    console.log("Submit");
  }
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <ParallaxImage image={staffimage} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <p style={{ fontFamily: "Work Sans", fontSize: 20 }}>Welcome To</p>
            <p
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              Easy Move Supply Chain
            </p>
            <hr style={{ border: "1px solid white" }} />
            <p style={{ fontFamily: "sans-serif", fontSize: 20 }}>
              One Source for All Your Logistic Needs!
            </p>
            <hr style={{ border: "1px solid white" }} />
            <div style={{ textShadow: "1px 1px black" }}>
              <p style={{ fontFamily: "sans-serif", fontSize: 16 }}>
                Transform your logistics operations with our Supply Chain
                services. Our cutting-edge technology, experienced team, and
                vast network of partners provide a comprehensive solution that
                streamlines and simplifies your supply chain. From warehousing
                and fulfillment to transportation and delivery, we handle all
                aspects of your logistics, freeing you to focus on your core
                business.
              </p>
            </div>

            <br />
            {!userStore.signedIn && (
              <>
                <hr style={{ border: "1px solid white" }} />
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="lg"
                  color="blue"
                  onClick={() => this.props.history.push("/login")}
                >
                  Join Easy Move Today!
                </IconButton>
              </>
            )}
          </div>
        </ParallaxImage>
        {/* <BannerImage
          title={"Welcome to Easy Move Supply Chain"}
          subtitle={"One Source for All Your Logistic Needs!"}
          details={
            "We provide the full Supply Chain process to ensure your products are shipped, cleared, stored, and distributed to your businesses and customers."
          }
          button={
            userStore.signedIn ? (
              <div />
            ) : (
              <IconButton
                icon={<Icon icon="truck" />}
                size="lg"
                color="blue"
                onClick={() => this.props.history.push("/login")}
              >
                Join Easy Move Today!
              </IconButton>
            )
          }
          image={staffimage}
          alwaysvisible
          titlecomp={
            <h1
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                color: "#00a2da",
                textShadow: "1px 1px black",
              }}
            >
              Welcome to Easy Move
              <h1 style={{ color: "#8a959a" }}>Supply Chain</h1>
            </h1>
          }
        /> */}
        <VisiblityCode>
          <div>
            <br />
            <img
              src={clearance}
              style={{ maxHeight: 200, maxWidth: "100%" }}
              alt="tehnology graphics"
            />
            <br />
          </div>
        </VisiblityCode>
        <Services />
        <SingleBenefit />

        <ImageCarousel />
        <VisiblityCode>
          <div>
            <br />
            <img
              src={warehousing}
              style={{ maxHeight: 200, maxWidth: "100%" }}
              alt="tehnology graphics"
            />
            <br />
          </div>
        </VisiblityCode>
      </div>
    );
  }
}

export default withRouter(observer(MainHomePage));
