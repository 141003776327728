import { observer } from "mobx-react";
import React, { Component } from "react";
import { HelpBlock, Steps } from "rsuite";

class TimelineComp extends Component {
  render() {
    let hidden = this.props.hidden;
    if (window.innerWidth < 800) hidden = true;

    let active = this.props.step;

    let steps = this.props.steps;

    if (active < 0) {
      active = 0;
    }

    let finalsteps = steps.map((step, index) => (
      <Steps.Item key={index} title={hidden ? "" : step} />
    ));

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        {hidden && (
          <div>
            <HelpBlock style={{ textAlign: "center" }}>
              {steps[active]}
            </HelpBlock>
            <br />
          </div>
        )}
        <Steps
          current={active}
          small
          currentStatus={active === steps.length ? "finish" : "process"}
        >
          {finalsteps}
        </Steps>
      </div>
    );
  }
}

export default observer(TimelineComp);
