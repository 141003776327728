import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col3 from "../../../Components/Columns/Col3";
import WHMSingleCompanyPalletTable from "./WHMSingleCompanyPalletTable";
import whmCompanyStore from "../WHMStores/WHMCompanyStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import WHMNewPalletModal from "./WHMNewPalletModal";
import WHMEditPalletModal from "./WHMEditPalletModal";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";

class WHMSingleCompanyPallet extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, type: "New" };
    this.onHide = this.onHide.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }
  onEdit(pallet) {
    whmSinglePalletStore.getPallet(pallet._id, true);
    this.setState({ show: true, type: "Edit" });
    this.props.LockTab(true);
  }
  onHide() {
    this.setState({ show: false, type: "New" });
    this.props.LockTab(false);
  }

  render() {
    let content = (
      <div>
        <FlexboxGrid justify="center">
          <Col3>
            <IconButton
              block
              color="green"
              icon={<Icon icon="plus" />}
              onClick={() => {
                this.setState({ show: true, type: "New" });
                whmCompanyStore.getUnpalletized(false, true);
                this.props.LockTab(true);
              }}
            >
              New Pallet
            </IconButton>
          </Col3>
          <Col3>
            <IconButton
              block
              color="green"
              icon={<Icon icon="plus" />}
              onClick={() => {
                whmCompanyStore.uploadBackdated();
              }}
            >
              Upload Backdated Pallets
            </IconButton>
          </Col3>
        </FlexboxGrid>
        <WHMSingleCompanyPalletTable
          data={whmCompanyStore.pallets}
          onEdit={this.onEdit}
        />
      </div>
    );
    if (this.state.show) {
      if (this.state.type === "New") {
        content = <WHMNewPalletModal onHide={this.onHide} />;
      } else {
        content = <WHMEditPalletModal onHide={this.onHide} />;
      }
    }
    return (
      <LoadBack loading={whmCompanyStore.loading.company}>{content}</LoadBack>
    );
  }
}

export default withRouter(observer(WHMSingleCompanyPallet));
