import moment from "moment";
import { AttQualityCalc } from "./SupportingQuality/ATTQualityCalc";
import { JDQualityCalc } from "./SupportingQuality/JDQualityCalc";

function STQualityCalc(data) {
  let finalerror = [0, 0];
  let general = STQualityMain(data);
  let dates = JDQualityCalc(data);
  let atts = AttQualityCalc(data);

  finalerror[0] = general[0] + dates[0] + atts[0];
  finalerror[1] = general[1] + dates[1] + atts[1];
  finalerror[2] = [...general[2], ...dates[2], ...atts[2]];

  return finalerror;
}

function STQualityMain(data) {
  let errors = 0;
  let total = 4;
  let items = [];

  if (data.cbm === 0) items.push("CBM");
  if (data.st) {
    if (data.st.pricepertime) {
      if (data.st.pricepertime === 0) items.push("Storage Rate");
    }
    if (data.st.time) {
      if (data.st.time === 0) items.push("Storage Time");
    }
    if (data.st.inprice) {
      if (data.st.inprice === 0) items.push("Storage In Price");
    }
    if (data.st.outprice) {
      if (data.st.outprice === 0) items.push("Storage Out Price");
    }
    if (data.st.storagestatus) {
      if (data.st.storagestatus === "Planned") {
        if (moment(data.st.start) < moment()) items.push("Storage Start Date");
      } else {
        if (moment(data.st.start) > moment()) items.push("Storage Start Date");
      }
    }
  }

  errors = items.length;
  return [errors, total, items];
}

export { STQualityCalc, STQualityMain };
