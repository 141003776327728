import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Loader } from "rsuite";
import { withRouter } from "react-router-dom";

import Div14 from "../../../Components/Dividers/Div14";
import singleAccountStore from "../../../Stores/Account/SingleAccountStore";
import AccountNotFound from "../AccountMain/AccountNotFound";
import Col3 from "../../../Components/Columns/Col3";

class NewRequestMain extends Component {
  componentDidMount() {
    singleAccountStore.getAccount(this.props.match.params.clientid);
  }

  render() {
    if (singleAccountStore.loading.account) {
      return <Loader center />;
    } else if (singleAccountStore.account._id === "") {
      return <AccountNotFound />;
    }
    let account = singleAccountStore.account;
    let buttons = [
      {
        title: "Warehouse",
        url: `/ac/new/storage/${this.props.match.params.clientid}`,
        show: account.ast,
        icon: "home",
      },
      { title: "Delivery", url: "/", show: account.alm, icon: "truck" },
      {
        title: "International Move",
        url: "/",
        show: account.aim,
        icon: "plane",
      },
      {
        title: "Custom Clearance",
        url: "/",
        show: account.acc,
        icon: "id-badge",
      },
    ];
    let newbut = buttons.filter((but) => but.show);

    let colbuts = newbut.map((but, index) => (
      <Col3 key={index} style={{ paddingBottom: "5px" }}>
        <IconButton
          block
          icon={<Icon icon={but.icon} />}
          color="green"
          appearance="ghost"
          onClick={() => this.props.history.push(but.url)}
        >
          New {but.title} Request
        </IconButton>
      </Col3>
    ));

    return (
      <div>
        <Div14>New Account Request</Div14>
        <h6>Please select a Type:</h6>
        <FlexboxGrid justify="center">{colbuts}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(NewRequestMain));
