import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import clientStore from "../../../Stores/ClientStore";
import StdPage2 from "../../../Components/PageLayout/StdPage2";
import { Col, FlexboxGrid, Icon, IconButton, Button } from "rsuite";
import { Tooltip } from "@material-ui/core";

import NewClient from "./NewClient";
import ClientsTable from "./Table/ClientsTable";
import LoadBack from "../../../Components/Loaders/LoadBack";

class ClientList extends Component {
  componentDidMount() {
    if (!clientStore.clientsloaded) {
      clientStore.getClients();
    }
  }

  render() {
    let types = ["Company", "Customer"];
    let typerows = types.map((type, index) => (
      <Col md={8} sm={8} xs={24} key={index}>
        <Button
          block
          onClick={() => {
            if (clientStore.filter.type === type) {
              clientStore.setFilter("type", "");
            } else {
              clientStore.setFilter("type", type);
            }
          }}
          appearance={
            clientStore.filter.type === ""
              ? "primary"
              : clientStore.filter.type === type
              ? "primary"
              : "ghost"
          }
        >
          {type}
        </Button>
      </Col>
    ));

    return (
      <LoadBack loading={clientStore.loading}>
        <StdPage2
          title={"Clients List"}
          loading={clientStore.loading}
          refresh={clientStore.getClients}
        >
          <FlexboxGrid>
            <Col md={20} sm={16} xs={24}>
              <h3 style={{ fontWeight: "bold" }}>
                {" "}
                Clients List:{" "}
                <i style={{ fontSize: 16 }}>
                  ({clientStore.filtClients.length}){" "}
                </i>
              </h3>
            </Col>
            <Col md={4} sm={8} xs={24}>
              <FlexboxGrid>
                <NewClient />
                <span style={{ width: 10 }} />
                <Tooltip title={"Refresh List"} arrow placement="bottom">
                  <IconButton
                    loading={clientStore.loading}
                    onClick={() => clientStore.getClients(true)}
                    icon={<Icon icon="refresh" />}
                    active
                    circle
                    block
                  />
                </Tooltip>
              </FlexboxGrid>
            </Col>
          </FlexboxGrid>
          <hr />
          <FlexboxGrid>{typerows}</FlexboxGrid>
          <ClientsTable />
        </StdPage2>
      </LoadBack>
    );
  }
}

export default withRouter(observer(ClientList));
