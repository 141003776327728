import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@material-ui/core";
import logo from "../../../../Static/Images/easymoveicon.png";

import SingleLink from "../SingleLink";
import { Avatar, FlexboxGrid, Icon } from "rsuite";
import userStore from "../../../../Stores/UserStore";
import { ListItem, ListItemIcon } from "@material-ui/core";

import { ListItemText } from "@material-ui/core";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";

class AllUsers extends Component {
  render() {
    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <Tooltip title="Easy Move" arrow placement="right">
                <Avatar circle size="sm" style={{ background: "white" }}>
                  <img
                    src={logo}
                    height="30"
                    className="d-inline-block align-top"
                    alt="Easy Move Logo"
                  />
                </Avatar>
              </Tooltip>
            }
          >
            {this.props.open && <Typography>Easy Move</Typography>}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, margin: 0 }}>
            <FlexboxGrid>
              <SingleLink
                page="/em-home"
                icon="home"
                title="Home Page"
                hdc={this.props.hdc}
              />
              <SingleLink
                page="/em-aboutus"
                icon="calendar-check-o"
                title="About Us"
                hdc={this.props.hdc}
              />
              <SingleLink
                page="/em-storage"
                icon="cube"
                title="Solutions"
                hdc={this.props.hdc}
              />
              <SingleLink
                page="/em-fullfillment"
                icon="trophy"
                title="Supply Chain"
                hdc={this.props.hdc}
              />
            </FlexboxGrid>
          </AccordionDetails>
        </Accordion>
        <br />

        <ListItem
          onClick={() => {
            if (userStore.signedIn) {
              userStore.logoutUser();
            } else {
              this.props.history.push("/login");
            }
            this.props.hdc();
          }}
          style={{
            color: "black",
            padding: 0,
            margin: 0,
            paddingLeft: 25,
          }}
        >
          <ListItemIcon>
            <Tooltip
              title={userStore.signedIn ? "Sign Out" : "Login"}
              arrow
              placement="right"
            >
              {userStore.signedIn ? (
                <ExitToAppTwoToneIcon style={{ color: "#e06565" }} />
              ) : (
                <Icon icon={"user-info"} style={{ color: "green" }} size="lg" />
              )}
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary={userStore.signedIn ? "Sign Out" : "Login"} />
        </ListItem>

        <hr />
        {!userStore.signedIn && (
          <SingleLink
            page="/signup"
            icon="user-plus"
            title="Register"
            hdc={this.props.hdc}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(AllUsers));
