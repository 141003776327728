import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import acInventoryStore from "../../../../../../../Stores/AccountUpdated/Storage/ACInventoryStore";
import { Tabs, Spin, Select } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../../../Components/Columns/Col2";
import { InputGroup } from "rsuite";
import SingleItemInvGraph from "./SingleItemInvGraph";
import { withRouter } from "react-router";

const { TabPane } = Tabs;
const { Option } = Select;

class ItemMoveHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Existing Items" };
  }
  componentDidMount() {
    acInventoryStore.getExistingItems(singleAccountStore.account._id);
  }
  render() {
    let data = acInventoryStore.curritems;
    if (this.state.tab === "Past Items") data = acInventoryStore.pastitems;

    let options = data.map((item, index) => (
      <Option value={item._id} key={index}>
        ID {item.acItemNumId}) {item.name}
      </Option>
    ));

    return (
      <Spin
        spinning={acInventoryStore.loading.item}
        size="large"
        tip="Loading..."
        style={{ color: "black" }}
      >
        <Div14>Item Movement History</Div14>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="line"
          centered
          tabBarStyle={{ alignText: "center" }}
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={`Existing Items`} key={"Existing Items"}></TabPane>

          <TabPane tab={`Past Items`} key={"Past Items"}>
            <div />
          </TabPane>
        </Tabs>
        <FlexboxGrid justify="center">
          <Col2>
            <InputGroup>
              <InputGroup.Addon>Select an Item</InputGroup.Addon>
              <Select
                showSearch
                value={acInventoryStore.selectedItemId}
                style={{ width: "100%" }}
                placeholder={this.props.label}
                onChange={(e) => acInventoryStore.selectItemId(e)}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  let newopt = option.children.join(" ");
                  return newopt.includes(input);
                }}
              >
                {options}
              </Select>
            </InputGroup>
          </Col2>
          <Col2>
            <IconButton
              icon={<Icon icon="reload" />}
              color="green"
              block
              appearance="primary"
              loading={acInventoryStore.loading.itemmovement}
              disabled={acInventoryStore.selectedItemId === ""}
              onClick={() => acInventoryStore.getSingleItem(false, true)}
            >
              Load Item Movement History
            </IconButton>
          </Col2>
        </FlexboxGrid>
        <hr />
        <SingleItemInvGraph />
      </Spin>
    );
  }
}

export default withRouter(observer(ItemMoveHistory));
