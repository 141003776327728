import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { DatePicker, Spin } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";
import staffStorageStore from "../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import Col2 from "../../../../../../../Components/Columns/Col2";
import Col4 from "../../../../../../../Components/Columns/Col4";
import SelectInputSTD from "../../../../../../../Components/Inputs/STD/SelectInputSTD";
import FlexboxButton from "../../../../../../../Components/Modal/FlexboxButton";

class InvGraph extends Component {
  render() {
    let options = {
      chart: {
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            zoom: false,
            selection: false,
            reset: false,
            pan: false,
          },
        },
      },
      markers: {
        size: 10,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      yaxis: {
        min: 0,
        tickAmount: 10,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return PRKD(val);
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: "Inventory CBM",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      colors: ["#96c9ff", "#96ffaa", "#E91E63"],
      dropShadow: { enabled: true, top: 0, left: 0, blur: 3, opacity: 0.5 },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: [undefined, "black", "black"],
        width: 0.5,
        dashArray: 0,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1, 2],
        formatter: function (val, opts) {
          if (val > 0) {
            if (opts.seriesIndex === 1) {
              return `+ ${PRKD(val)}`;
            } else {
              return `- ${PRKD(val)}`;
            }
          } else {
            return "";
          }
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    };

    let history = staffStorageStore.storagehistory;
    let seriesValue = [
      {
        data: history.eodcbm,
        name: "Inventory",
        type: "area",
      },
      {
        data: history.eodin,
        name: "In Quantity",
        type: "column",
      },
      {
        data: history.eodout,
        name: "Out Quantity",
        type: "column",
      },
    ];

    let columnnames = [
      {
        title: "Start",
        field: "startday",
        defaultSort: "desc",
        render: (row) => moment(row.startday).format("DD-MMM-YY"),
      },
      {
        title: "End",
        field: "endday",
        render: (row) => moment(row.endday).format("DD-MMM-YY"),
      },
      {
        title: "Start CBM",
        field: "cbmstart",
        render: (row) => PRKD(row.cbmstart),
      },
      {
        title: "Storage In",
        field: "storin",
        render: (row) => {
          if (row.storin === 0) return "-";
          return PRKD(row.storin);
        },
      },
      {
        title: "Storage Out",
        field: "storout",
        render: (row) => {
          if (row.storout === 0) return "-";
          return PRKD(row.storout);
        },
      },
      { title: "End CBM", field: "cbmend", render: (row) => PRKD(row.cbmend) },
      { title: "Start QTY", field: "quantstart" },
      {
        title: "Qty In",
        field: "quantin",
        render: (row) => {
          if (row.quantin === 0) return "-";
          return row.quantin;
        },
      },
      {
        title: "Qty Out",
        field: "quantout",
        render: (row) => {
          if (row.quantout === 0) return "-";
          return row.quantout;
        },
      },
      { title: "End QTY", field: "quantend" },
    ];
    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <DatePicker
              onChange={staffStorageStore.changeDate}
              picker="month"
              format={"MMM-YY"}
              value={staffStorageStore.startdate}
              style={{ width: "100%" }}
              allowClear={false}
            />
          </Col2>
          <Col4>
            <SelectInputSTD
              title="Type"
              val={staffStorageStore.frequency}
              label="type"
              size="medium"
              opts={["Daily", "Weekly", "Monthly"]}
              editVal={staffStorageStore.changeFrequency}
            />
          </Col4>
          <Col4>
            <IconButton
              block
              size="sm"
              color="green"
              icon={<Icon icon="reload" />}
              onClick={() => staffStorageStore.getStorageHistory(true)}
            >
              Reload
            </IconButton>
          </Col4>
        </FlexboxGrid>
        <Spin
          spinning={staffStorageStore.loading.storagehistory}
          size="large"
          tip="Loading..."
          style={{ color: "black" }}
        >
          {staffStorageStore.loaded.storagehistory ? (
            <>
              <ReactApexChart
                options={options}
                series={seriesValue}
                type="area"
                height={200}
              />
              <MaterialTable
                isLoading={staffStorageStore.loading.storagehistory}
                title={"Inventory History"}
                columns={columnnames}
                style={{ fontSize: 12 }}
                data={staffStorageStore.storagehistory.dailyinv}
                options={{
                  actionsColumnIndex: 0,
                  exportAllData: true,
                  paging: false,
                  toolbarButtonAlignment: "left",
                  maxBodyHeight: 500,
                  tableLayout: "auto",
                  exportButton: true,
                  exportFileName: `Inventory History`,
                  padding: "dense",
                  search: false,
                }}
              />
            </>
          ) : (
            <>
              <br />

              <FlexboxButton
                icon="reload"
                text="Load Inventory Graph"
                color="green"
                app="primary"
                loading={staffStorageStore.loading.storagehistory}
                oC={() => staffStorageStore.getStorageHistory(true)}
              />
            </>
          )}
        </Spin>
      </div>
    );
  }
}

export default observer(InvGraph);
