import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import DataGrid, { Column } from "devextreme-react/data-grid";
import Div14 from "../../../Components/Dividers/Div14";

class ACOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let item = this.props.data.data.item;

    return (
      <div>
        <Div14>Items List</Div14>
        <DataGrid
          dataSource={item}
          keyExpr="acitem.acItemNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <Column
            dataField="acitem.acItemNumId"
            dataType="number"
            caption="ID"
            alignment="center"
            width={80}
          />
          <Column
            dataField="acitem.name"
            caption="Item"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="acitem.serialnumber"
            caption="SN"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="qty"
            caption="Quantity"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="type"
            caption="Type"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="remarks"
            dataType="text"
            caption="Remarks"
            alignment="center"
            minWidth={160}
          />
        </DataGrid>
      </div>
    );
  }
}

export default withRouter(observer(ACOrderDetails));
