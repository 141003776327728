import { observer } from "mobx-react";
import React, { Component } from "react";
import SJNumbInput from "../../../../../../Components/Inputs/SJ/SJNumbInput";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import { FlexboxGrid, HelpBlock } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import SJSelectInput from "../../../../../../Components/Inputs/SJ/SJSelectInput";
import sttypes from "../../../../../../Static/Lists/jobsubtypes/sttypes";
import SJDateInput from "../../../../../../Components/Inputs/SJ/SJDateInput";
import moment from "moment";
import { STPriceCalc } from "../../../../../../Functions/Pricing/STPriceCalc";

class STPrice extends Component {
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;

    let load = this.props.load;
    let sj = sjStore.sjs[ind];
    let price = STPriceCalc(sj);

    let timetitle = `Number of ${price[5]}s`;

    let enddate = price[6];
    let quality = this.props.quality;
    let storageStatusNote;
    if (quality) {
      let flag = 0;
      if (sj.storagestatus === "Planned") {
        if (moment(sj.start) < moment()) flag = 1;
      } else {
        if (moment(sj.start) > moment()) flag = 2;
      }
      if (flag > 0) {
        storageStatusNote = (
          <HelpBlock style={{ color: "red", textAlign: "center" }}>
            Storage Start Date needs to be corrected.
          </HelpBlock>
        );
      }
    }
    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="subtype"
              title="Payment"
              load={load.subtype}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={sttypes}
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="pricepertime"
              title={`${sj.subtype} Rate (KD)`}
              load={load.pricepertime}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="st"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="time"
              title={timetitle}
              load={load.time}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="st"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="cbm"
              title="CBM"
              load={load.cbm}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              sj="st"
              quality={quality}
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="inprice"
              title="In Price"
              load={load.inprice}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="st"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              label="outprice"
              title="Out Price"
              load={load.outprice}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              sj="st"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJDateInput
              ind={ind}
              label="start"
              title="Start Date"
              load={load.start}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              sj="st"
              read={!editview}
            />
          </Col2>
          <Col2>
            <SJSelectInput
              ind={ind}
              label="facility"
              title="Facility"
              load={load.facility}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={["Easy Move Facility"]}
              sj="st"
              read={!editview}
            />
          </Col2>
        </FlexboxGrid>
        <br />
        {storageStatusNote}
        <h6 style={{ textAlign: "center" }}>
          Estimated End Date is {moment(enddate).format("DD-MMM-YY")}{" "}
        </h6>
        <p style={{ textAlign: "center" }}>
          Starting on {moment(sj.start).format("DD-MMM-YY")} x {sj.time}{" "}
          {price[5]}s
        </p>
      </div>
    );
  }
}

export default observer(STPrice);
