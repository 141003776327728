import { observer } from "mobx-react";
import React, { Component } from "react";
import sjStore from "../../../../../../../Stores/SideJobs/SJStore";
import { Empty } from "antd";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import JobDateRow from "./JobDateRow";
import { Loader } from "rsuite";
import NewJobDate from "./NewJobDate";
import StaticCalendar from "../../../../../../../Components/Calendar/StaticCalendar";

class JobDateTab extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.changeLoad = this.changeLoad.bind(this);
  }
  changeLoad(val) {
    this.setState({ loading: val });
  }
  render() {
    let ind = this.props.ind;
    let editview = this.props.editview;
    let sj = sjStore.sjs[ind];
    let jds = sj.jobdate;
    let quality = this.props.quality;
    let daterows = (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={"No Dates Added"}
      />
    );
    if (this.state.loading) {
      daterows = <Loader center size="lg" />;
    } else if (jds.length > 0) {
      daterows = jds.map((jd, index) => (
        <JobDateRow
          key={index}
          index={index}
          ind={ind}
          changeLoad={this.changeLoad}
          quality={quality}
          editview={editview}
        />
      ));
    }

    return (
      <div>
        <Div14>Job Dates</Div14>
        {daterows}
        <br />
        {editview && <NewJobDate ind={ind} changeLoad={this.changeLoad} />}
        <StaticCalendar
          start={"date"}
          end={"dateto"}
          title={"type"}
          data={jds}
          onSelectEvent={() => console.log(" ")}
        />
      </div>
    );
  }
}

export default observer(JobDateTab);
