import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../Components/Dividers/Div14";
import whmPlanningStore from "../WHMStores/WHMPlanningStore";
import { Divider, Icon, IconButton } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";
import FlexboxButton from "../../../Components/Modal/FlexboxButton";
import WHMNewPlan from "./WHMNewPlan";

import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

class WHMPlanningPageMain extends Component {
  constructor(props) {
    super(props);
    this.state = { shownew: false };
    this.onHide = this.onHide.bind(this);
    this.ViewPlan = this.ViewPlan.bind(this);
  }
  ViewPlan(cellData) {
    let data = cellData.data;
    if (data._id) {
      return (
        <IconButton
          icon={<Icon icon="eye" />}
          circle
          color="green"
          size="xs"
          onClick={() => {
            this.props.history.push(`/whm/singleplan/${cellData.data._id}`);
          }}
        />
      );
    }
    return <></>;
  }

  onHide() {
    this.setState({ shownew: false });
  }
  componentDidMount() {
    whmPlanningStore.getPlans();
  }

  render() {
    let data = whmPlanningStore.plans;
    return (
      <LoadBack loading={whmPlanningStore.loading.plans}>
        <WHMNewPlan show={this.state.shownew} onHide={this.onHide} />
        <Div14>
          Planning Page <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            color="orange"
            circle
            size="xs"
            onClick={() => whmPlanningStore.getPlans(true)}
          />
        </Div14>
        <FlexboxButton
          oC={() => this.setState({ shownew: true })}
          text="Create a New Plan"
          color="green"
          icon="plus"
        />
        <DataGrid
          dataSource={data}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <Selection mode="single" />
          <FilterRow visible={true} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column caption="General">
            <Column caption="View Plan" cellRender={this.ViewPlan} width={50} />
            <Column
              dataField="whmPlanNumId"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="name"
              caption="Name"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="user.username"
              caption="User"
              alignment="center"
              width={80}
            />
          </Column>
          <Column caption="Details">
            <Column
              dataField="trucks"
              caption="Trucks"
              alignment="center"
              width={80}
            />
            <Column
              dataField="pallets"
              caption="Pallets"
              alignment="center"
              width={80}
            />
            <Column
              dataField="dailytrips"
              caption="Trips/Day"
              alignment="center"
              width={80}
            />
            <Column
              dataField="palletspertruck"
              caption="Pallets/Truck"
              alignment="center"
              width={80}
            />
            <Column
              dataField="minutestoload"
              caption="Loading Time"
              alignment="center"
              width={80}
            />
            <Column
              dataField="minutestotransit"
              caption="Transit Time"
              alignment="center"
              width={80}
            />
            <Column
              dataField="minutestounload"
              caption="Unloading Time"
              alignment="center"
              width={80}
            />
          </Column>
          <Column caption="Working Hours">
            <Column
              dataField="starttime"
              caption="Start Time"
              alignment="center"
              width={80}
              dataType={"date"}
              format={"HH:mm"}
            />
            <Column
              dataField="endtime"
              caption="End Time"
              alignment="center"
              width={80}
              dataType={"date"}
              format={"HH:mm"}
            />
            <Column
              dataField="startbreak"
              caption="Break Start"
              alignment="center"
              width={80}
              dataType={"date"}
              format={"HH:mm"}
            />
            <Column
              dataField="endbreak"
              caption="Break End"
              alignment="center"
              width={80}
              dataType={"date"}
              format={"HH:mm"}
            />
          </Column>
          <Export enabled={true} />
        </DataGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMPlanningPageMain));
