import { observer } from "mobx-react";
import React, { Component } from "react";
import AddSubJob from "./AddSubJob";
import EmptyForm from "../../../../Components/Empty/EmptyForm";
import ScrollingCard from "./ScrollingMenu/ScrollingCard";
import { Col, FlexboxGrid, Loader } from "rsuite";
import sjStore from "../../../../Stores/SideJobs/SJStore";
import SJAll from "./SJAll";

class SubJobMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: false,
      index: 0,
      numId: 0,
    };
    this.selectCard = this.selectCard.bind(this);
  }

  selectCard(index) {
    this.setState({ index: index });
  }

  render() {
    let sjs = sjStore.sjs;
    let editview = this.props.editview;

    let hideCancCard;
    if (editview) hideCancCard = <AddSubJob />;

    let sjscard;

    if (sjs.length > 0) {
      sjscard = sjs.map((sj, index) => (
        <ScrollingCard
          index={index}
          key={index}
          job={sj}
          selected={index === this.state.index}
          selectCard={this.selectCard}
        />
      ));
    }

    let showNewJob = (
      <EmptyForm text="Click on a Sub Job or create a new one" />
    );
    if (sjStore.loading) {
      showNewJob = <Loader center />;
    } else if (this.state.index >= 0) {
      showNewJob = <SJAll ind={this.state.index} editview={editview} />;
    }
    return (
      <div>
        <FlexboxGrid>
          <Col md={20} sm={18} xs={16}>
            <FlexboxGrid>{sjscard}</FlexboxGrid>
          </Col>
          <Col md={4} sm={6} xs={8}>
            {hideCancCard}
          </Col>
        </FlexboxGrid>
        {showNewJob}
      </div>
    );
  }
}

export default observer(SubJobMain);
