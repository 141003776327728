import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ParallaxImage from "../../../Components/Parallax/ParallaxImage";
import userStore from "../../../Stores/UserStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import pic from "../../../Static/Images/CompanyPics/pic2.png";
import Col2 from "../../../Components/Columns/Col2";
import IconItem from "../../../Components/Lists/IconItem";
import SingleBenefit from "../Sections/Benefits/SingleBenefit";

class LocalMove extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ParallaxImage image={pic} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              Local Moves
            </p>
            <p style={{ fontFamily: "Work Sans", fontSize: 20 }}>
              With Easy Move
            </p>
            <hr style={{ border: "1px solid white" }} />
            <p style={{ fontFamily: "sans-serif", fontSize: 24 }}>
              Highly Trained Packers and Movers for Quality Moves
            </p>

            <div style={{ textShadow: "1px 1px black" }}>
              <p style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                Move with ease and peace of mind with our local moving services.
                Our experienced and professional team takes care of every aspect
                of your move, from packing to unpacking, ensuring a seamless
                transition to your new home or business. Trust us to handle your
                move with the utmost care and attention to detail.
              </p>
            </div>
            <FlexboxGrid>
              <Col2>
                <IconItem icon="peoples" text="Trained Staff" />
                <br />
                <IconItem icon="cube" text="High Quality Packing" />
                <br />
              </Col2>
              <Col2>
                <IconItem icon="cart-arrow-down" text="Heavy Equipment" />
                <br />
                <IconItem icon="pc" text="Fully Digitalized" />
                <br />
              </Col2>
            </FlexboxGrid>
            {!userStore.signedIn && (
              <>
                <hr style={{ border: "1px solid white" }} />
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="lg"
                  color="blue"
                  onClick={() => this.props.history.push("/login")}
                >
                  Join Easy Move Today!
                </IconButton>
              </>
            )}
          </div>
        </ParallaxImage>
        <SingleBenefit />
      </div>
    );
  }
}

export default withRouter(observer(LocalMove));
