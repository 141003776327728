import { observer } from "mobx-react";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import LoadBack from "../../../../../../../../Components/Loaders/LoadBack";
import acInventoryStore from "../../../../../../../../Stores/AccountUpdated/Storage/ACInventoryStore";

class StorStatBarChart extends Component {
  render() {
    let options = {
      chart: {
        type: "bar",
        height: 450,
        stacked: true,
      },
      colors: ["#03821e", "#FF4560"],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: ["Orders In vs Out", "Quantity In vs Out", "CBM In vs Out"],
        title: {
          text: "Orders / Quantity / CBM Count",
        },
        labels: {
          formatter: function (val) {
            return Math.round(val);
          },
        },
      },
      legend: { show: false },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        title: {
          text: "Type",
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return Math.abs(val);
          },
        },
      },

      title: {
        text: "In vs Out Details",
        align: "center",
        marginTop: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
    };

    let seriesValue = this.props.data;

    return (
      <div>
        <LoadBack loading={acInventoryStore.loading.stats}>
          <ReactApexChart
            options={options}
            series={seriesValue}
            type="bar"
            height={200}
          />
        </LoadBack>
      </div>
    );
  }
}

export default observer(StorStatBarChart);
