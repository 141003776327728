import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@material-ui/core";

import SingleLink from "../SingleLink";
import { Avatar, FlexboxGrid } from "rsuite";
import userStore from "../../../../Stores/UserStore";

class Client extends Component {
  render() {
    let user = userStore.user;
    let comp = user.company;

    let showdel = false;
    if (comp.adel) {
      if (!comp.hidedel) showdel = true;
    }

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <Tooltip title="Account" arrow placement="right">
              <Avatar circle size="sm" style={{ background: "#303f5e" }}>
                CA
              </Avatar>
            </Tooltip>
          }
        >
          {this.props.open && <Typography>Client Account</Typography>}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <FlexboxGrid>
            <SingleLink
              page={`/ac/dashboard/${comp._id}`}
              icon="dashboard"
              title="Account Dash"
              hdc={this.props.hdc}
            />
            {comp.ast && (
              <SingleLink
                page={`/sac/dash/${userStore.user.company._id}`}
                icon="cube"
                title="Storage"
                hdc={this.props.hdc}
              />
            )}
            {showdel && (
              <SingleLink
                page={`/dac/dash/${userStore.user.company._id}`}
                icon="truck"
                title="Last Mile"
                hdc={this.props.hdc}
              />
            )}
            {comp.aim && (
              <SingleLink
                page={`/aim/dash/${userStore.user.company._id}`}
                icon="ship"
                title="International"
                hdc={this.props.hdc}
              />
            )}
            {comp.acc && (
              <SingleLink
                page={`/acc/dash/${userStore.user.company._id}`}
                icon="plane"
                title="Clearance"
                hdc={this.props.hdc}
              />
            )}
          </FlexboxGrid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withRouter(observer(Client));
