import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Loader } from "rsuite";
import Div14 from "../../../Components/Dividers/Div14";
import GeneralMap from "../../../Components/Maps/SelectLocationMap/GeneralMap";
import mgtMapStore from "../../../Stores/Management/MgtMapStore";

class AllTruckMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      user: {},
      height: 400,
      refresh: true,
      center: { lat: 29.17, lng: 48.039732 },
      zoom: 10,
    };
    this.changeCenter = this.changeCenter.bind(this);
  }
  componentDidMount() {
    mgtMapStore.fetchUser();
  }

  changeCenter(lat, lng, zoom) {
    let center = { lat: lat, lng: lng };
    this.setState({ center: center });
    if (zoom) this.setState({ zoom: 15 });
  }
  render() {
    let center = this.state.center;
    let zoom = this.state.zoom;
    let devices = mgtMapStore.devices;

    let map = (
      <GeneralMap
        zoom={zoom}
        center={center}
        markers={devices}
        onChildClick={this.changeCenter}
      />
    );

    if (mgtMapStore.loading) {
      map = <Loader center />;
    }

    let columns = [
      { title: "ID", field: "id" },
      { title: "Name", field: "name" },

      {
        title: "Time",
        field: "lastConnection",
        render: (rowData) => {
          return moment(rowData.lastConnection).fromNow();
        },
      },
      { title: "Status", field: "carStat" },
      { title: "Speed", field: "speed" },
      { title: "Location", field: "address" },
    ];

    return (
      <div>
        <Div14>All Trucks</Div14>
        {map}
        <MaterialTable
          isLoading={mgtMapStore.loading}
          columns={columns}
          style={{ fontSize: 11 }}
          data={devices}
          options={{
            actionsColumnIndex: 0,
            exportAllData: false,
            paging: false,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbar: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "fixed",
            padding: "dense",
            search: false,
          }}
          onRowClick={async (event, rowData, togglePanel) =>
            this.changeCenter(rowData.lat, rowData.lng, true)
          }
        />
      </div>
    );
  }
}

export default observer(AllTruckMap);
