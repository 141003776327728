import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";

class JobDateSummary extends Component {
  render() {
    let jds = this.props.jds;
    let jobdates;
    if (jds.length > 0) {
      jobdates = jds.map((date, index) => (
        <div key={index}>
          <p>
            <b>
              Date {index + 1}: {date.type}{" "}
            </b>{" "}
            {moment(date.date).format("DD-MMM-YY") ===
            moment(date.dateto).format("DD-MMM-YY")
              ? moment(date.date).format("DD-MMM-YY")
              : `${moment(date.date).format("DD-MMM-YY")} to ${moment(
                  date.dateto
                ).format("DD-MMM-YY")}`}
            <br />
            <b>From: </b> ({date.fromtype}) {date.from}
            {date.type !== "Packing" && (
              <div>
                <b>To: </b> ({date.totype}) {date.to}
              </div>
            )}
          </p>
        </div>
      ));
    }

    return (
      <div>
        <Div14>{jds.length} Job Dates: </Div14>
        <p>{jobdates}</p>
      </div>
    );
  }
}

export default observer(JobDateSummary);
