import React, { Component } from "react";
import PoorFeedback from "./Content/PoorFeedback";
import ExcellentFeedback from "./Content/ExcellentFeedback";
import StarRatingSTD from "../../../../Components/Inputs/STD/StarRatingSTD";
import easymovelogo from "../../../../Static/Images/easymoveicon.png";
import { Image } from "antd";
class FeedbackModal extends Component {
  render() {
    let rating = this.props.rating;
    let content;
    if (rating < 5) {
      content = <PoorFeedback />;
    } else {
      content = <ExcellentFeedback />;
    }

    let remarks = "";
    if (this.props.remarks) remarks = this.props.remarks;
    if (remarks !== "") remarks = `"${remarks}"`;

    return (
      <div style={{ textAlign: "center" }}>
        <h3 style={{ fontStyle: "italic", fontWeight: "bold" }}>
          Thank you for your feedback.
        </h3>
        <StarRatingSTD
          disabled
          val={rating}
          editVal={() => console.log("Test")}
        />
        <br />
        <h6 style={{ fontStyle: "italic" }}>{remarks}</h6>
        <br />

        {content}

        <h5 style={{ fontStyle: "italic" }}>
          Our office staff is available at any time if you have any additional
          feedback.
        </h5>
        <h4 style={{ fontStyle: "italic" }}>
          We look forward to working with you again soon.
        </h4>
        <Image src={easymovelogo} alt="Easy Move Logo" preview={false} />
      </div>
    );
  }
}

export default FeedbackModal;
