import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import TabPane from "antd/lib/tabs/TabPane";
import { Tabs } from "antd";
import WHMFullPalletList from "../FullPalletList/WHMFullPalletList";
import WHMTruckPage from "../Trucks/WHMTruckPage";
import whmTruckStore from "../WHMStores/WHMTruckStore";
import { Divider, Icon, IconButton } from "rsuite";
import Div14 from "../../../Components/Dividers/Div14";
import LatestMoveStats from "./LatestMoveStats";

class WHMOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Truck Summary" };
  }
  componentDidMount() {
    whmTruckStore.getMoveStats();
  }

  render() {
    return (
      <div>
        <br />
        <Div14>
          Warehouse Move Overview <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            loading={whmTruckStore.loading.movestats}
            onClick={() => whmTruckStore.getMoveStats(true)}
            size="xs"
            color="orange"
            circle
          />
        </Div14>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Move Summary"} key={"Move Summary"}>
            <LatestMoveStats />
          </TabPane>
          <TabPane tab={"Trucks Summary"} key={"Trucks Summary"}>
            <WHMTruckPage />
          </TabPane>
          <TabPane tab={"Pallet Summary"} key={"Pallet Summary"}>
            <WHMFullPalletList />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(WHMOverview));
