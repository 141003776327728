import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Button, Col } from "rsuite";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import hrStore from "../../../../../Stores/HR/HRStore";

class StaffRow extends Component {
  render() {
    let st = this.props.st;
    let start = hrStore.filtdates.startsalary;
    let end = hrStore.filtdates.endsalary;

    let diff = moment(end).diff(start, "month") + 1;

    let data = [];
    let single = {};
    let sas = [];
    let newdate;
    for (let i = 0; i < diff; i++) {
      newdate = moment(start).add(i, "months");
      sas = st.salaries.filter(
        (st) =>
          moment(st.salarymonth).format("MMM-YY") ===
          moment(start).add(i, "months").format("MMM-YY")
      );
      single = { date: newdate, sa: sas };
      if (sas.length > 1) {
        for (let j = 0; j < sas.length; j++) {
          single = { date: newdate, sa: [sas[j]] };
          data.push(single);
        }
      } else {
        data.push(single);
      }
    }
    let totalsalary = 0;
    if (st.currentsalary) {
      if (st.currentsalary.total) {
        totalsalary = st.currentsalary.total;
      }
    }

    return (
      <FlexboxGrid align="middle">
        <Col md={4} sm={6} xs={12}>
          <h6>
            {st.name} ({PRKD(totalsalary)} KD):
          </h6>
        </Col>
        <Col md={20} sm={18} xs={12}>
          {data.map((date, index) => (
            <Button
              appearance={date.sa.length > 0 ? "primary" : "ghost"}
              key={index}
              onClick={() => {
                if (date.sa.length > 0) {
                  this.props.startNewPayment(
                    "Edit",
                    moment(date.date),
                    st,
                    date.sa[0]
                  );
                } else {
                  this.props.startNewPayment("New", moment(date.date), st);
                }
              }}
            >
              {moment(date.date).format("MMM-YY")}
            </Button>
          ))}
        </Col>
      </FlexboxGrid>
    );
  }
}

export default observer(StaffRow);
