import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { CCPriceCalc } from "../../../../../../Functions/Pricing/CCPriceCalc";
import { Parsed } from "../../../../../../Functions/Pricing/PRKDCalc";

class CCSummary extends Component {
  render() {
    let data = this.props.data;
    data = data.cc;
    let section = this.props.section;
    let price = CCPriceCalc(this.props.data);
    if (section === "General") {
      return (
        <div>
          <Div14>General Info:</Div14>
          <p>
            <b>Shipping Mode: </b>
            {data.shippingmode} with {data.shippingline}
            <br />
            <b>Container: </b>({data.containercount}) {data.container} -{" "}
            {data.weight} KG with {data.packages} Packages
            <br />
            <b>Vessal Arrival: </b>
            {moment(data.vesselarrival).format("DD-MMM-YY")}
            <br />
            <b>Docs Collected: </b>
            {data.doccol
              ? moment(data.doccoldate).format("DD-MMM-YY")
              : "Pending"}
            <br />
            <b>Bayan Issued: </b>
            {data.bayanissue
              ? moment(data.bayanissuedate).format("DD-MMM-YY")
              : "Pending"}
            <br />
            <b>Clearance Done: </b>
            {data.cleardone
              ? moment(data.cleardonedate).format("DD-MMM-YY")
              : "Pending"}
            <br />
            <b>Invoice TAs: </b>
            {data.invoicetas.join(", ")}
            <br />
            <b>Bill of Lading: </b>
            {data.blnumber}
            <br />
            <b>Commodity: </b>
            {data.commodity}
            <br />
            <b>EPA/Other Done: </b>
            {data.epa ? moment(data.epadate).format("DD-MMM-YY") : "Pending"}
            <br />
            <b>PAI Done: </b>
            {data.pai ? moment(data.paidate).format("DD-MMM-YY") : "Pending"}
            <br />
          </p>
        </div>
      );
    } else if (section === "Attachment") {
      let atts = this.props.data.attachments;
      let blatt = atts.filter((att) => att.title === "Bill of Lading");
      let coatt = atts.filter((att) => att.title === "Certificate of Origin");
      let ciatt = atts.filter((att) => att.title === "Commercial Invoice");
      let platt = atts.filter((att) => att.title === "Packing List");
      let iqatt = atts.filter((att) => att.title === "Iqrar Report");

      let ccatt = atts.filter(
        (att) => att.title === "Certificate of Conformity"
      );
      let tiatt = atts.filter(
        (att) => att.title === "Technical Inspection Report"
      );

      let idatt = atts.filter((att) => att.title === "Invoice Delivery");
      let dpatt = atts.filter((att) => att.title === "Driver Paper");
      let fbatt = atts.filter((att) => att.title === "Final Bayan");
      let dnatt = atts.filter((att) => att.title === "Delivery Note");

      let epaatt = atts.filter((att) => att.title === "EPA/Other");
      let paiatt = atts.filter((att) => att.title === "PAI");
      return (
        <div>
          <Div14>Attachments: </Div14>
          <b>Initial Documents: </b>
          <b style={{ color: blatt.length > 0 ? "green" : "red" }}>
            Bill of Lading,{" "}
          </b>
          <b style={{ color: coatt.length > 0 ? "green" : "red" }}>
            Certificate of Origin,{" "}
          </b>
          <b style={{ color: ciatt.length > 0 ? "green" : "red" }}>
            Commercial Invoice,{" "}
          </b>
          <b style={{ color: platt.length > 0 ? "green" : "red" }}>
            Packing List,{" "}
          </b>
          <b style={{ color: iqatt.length > 0 ? "green" : "red" }}>
            Iqrar Report,{" "}
          </b>
          <br />
          <b>Approval Documents: </b>
          <b style={{ color: idatt.length > 0 ? "green" : "red" }}>
            Invoice Delivery,{" "}
          </b>
          <b style={{ color: dpatt.length > 0 ? "green" : "red" }}>
            Driver Paper,{" "}
          </b>
          <b style={{ color: fbatt.length > 0 ? "green" : "red" }}>
            Final Bayan,{" "}
          </b>
          <b style={{ color: dnatt.length > 0 ? "green" : "red" }}>
            Delivery Note.
          </b>
          <br />
          <b>Optional Documents: </b>
          <b style={{ color: ccatt.length > 0 ? "green" : "red" }}>
            Certificate of Conformity.
          </b>
          <b style={{ color: tiatt.length > 0 ? "green" : "red" }}>
            Technical Inspection Report,{" "}
          </b>
          <b style={{ color: epaatt.length > 0 ? "green" : "red" }}>
            EPA / Other,{" "}
          </b>
          <b style={{ color: paiatt.length > 0 ? "green" : "red" }}>PAI.</b>
          <br />
        </div>
      );
    } else if (section === "Pricing") {
      return (
        <div>
          <Div14>Pricing:</Div14>
          <p>
            <b>Total CBM: </b>
            {this.props.data.cbm} CBM
            <br />
            <b>Job Price: </b>
            {price[1]} KD (
            {price[1] === 0 || this.props.data.cbm === 0
              ? "Missing"
              : Math.round(
                  (100 * Parsed(price[1])) / Parsed(this.props.data.cbm)
                ) / 100}{" "}
            KD / CBM)
            <br />
            <b>Add Charges: </b>
            {price[2]} KD ({this.props.data.addcharge.length})
            <br />
            <b>Total Price: </b>
            {Parsed(price[0])} KD
          </p>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default observer(CCSummary);
