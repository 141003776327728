import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { FlexboxGrid, Icon, IconButton } from "rsuite";
import whmTruckStore from "../WHMStores/WHMTruckStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";

import { ColumnChooser } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import { message } from "antd";
import userStore from "../../../Stores/UserStore";
import Col2 from "../../../Components/Columns/Col2";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class WHMTruckTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      name: "",
      loading: false,
      active: true,
      truckid: "",
    };
    this.ActionButs = this.ActionButs.bind(this);
    this.ActiveColumn = this.ActiveColumn.bind(this);
    this.DelayColumnLoad = this.DelayColumnLoad.bind(this);
    this.DelayColumnUnLoad = this.DelayColumnUnLoad.bind(this);
    this.TimeToLoad = this.TimeToLoad.bind(this);
    this.TimeToUnLoad = this.TimeToUnLoad.bind(this);
    this.updatedAt = this.updatedAt.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.updateTruckName = this.updateTruckName.bind(this);
  }
  updateTruckName = async (name, active, truckid) => {
    let data = { name: name, active: active };

    this.setState({ loading: true });
    await instance
      .put(`whm/trucks/update/${truckid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        whmTruckStore.getTrucks(true);

        message.success(`Truck Updated Added`);
        this.onHide();
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("An Error Occured");
      });
  };
  onHide() {
    this.setState({ show: false, name: "", truckid: "" });
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  updatedAt(cellData) {
    return moment(cellData.data.updatedAt).fromNow(false);
  }
  ActionButs(cellData) {
    return (
      <>
        <IconButton
          icon={<Icon icon="pencil" />}
          color="orange"
          circle
          size="xs"
          onClick={() => {
            this.setState({
              show: true,
              name: cellData.data.name,
              active: cellData.data.active,
              truckid: cellData.data._id,
            });
          }}
        />
      </>
    );
  }
  ActiveColumn(cellData) {
    if (cellData.data.active) {
      return <h6 style={{ color: "green" }}>Active</h6>;
    } else {
      return <h6 style={{ color: "red" }}>Hidden</h6>;
    }
  }
  TimeToLoad(cellData) {
    return (
      <h6
        style={{
          color:
            cellData.value > whmTruckStore.targets.loading ? "red" : "green",
          fontWeight: "bold",
        }}
      >
        {cellData.value}
      </h6>
    );
  }
  TimeToUnLoad(cellData) {
    return (
      <h6
        style={{
          color:
            cellData.value > whmTruckStore.targets.unloading ? "red" : "green",
          fontWeight: "bold",
        }}
      >
        {cellData.value}
      </h6>
    );
  }
  DelayColumnLoad(cellData) {
    if (cellData.data.lateloading === 0) {
      return "-";
    } else {
      return (
        <h6>
          {cellData.data.lateloading} / {cellData.data.loading}
        </h6>
      );
    }
  }
  DelayColumnUnLoad(cellData) {
    if (cellData.data.lateunloading === 0) {
      return "-";
    } else {
      return (
        <h6>
          {cellData.data.lateunloading} / {cellData.data.unloading}
        </h6>
      );
    }
  }
  render() {
    return (
      <LoadBack loading={whmTruckStore.loading.trucks}>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Update Truck Name"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.updateTruckName(
                  this.state.name,
                  this.state.active,
                  this.state.truckid
                )
              }
              disabled2={this.state.name === ""}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          title="Add New Truck"
        >
          <TextInputSTD
            title="Truck Name"
            val={this.state.name}
            label="name"
            editVal={this.editVal}
          />
          <br />
          <FlexboxGrid justify="center">
            <Col2>
              <IconButton
                icon={
                  <Icon
                    icon={this.state.active ? "check-circle" : "close-circle"}
                  />
                }
                onClick={() => this.setState({ active: !this.state.active })}
                color={this.state.active ? "green" : "red"}
                block
              >
                {this.state.active ? "Active" : "NOT Active"}
              </IconButton>
            </Col2>
          </FlexboxGrid>
        </UnifiedModal>
        <DataGrid
          dataSource={whmTruckStore.trucks}
          keyExpr="_id"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          columnAutoWidth={true}
          hoverStateEnabled={true}
          onSelectionChanged={this.onSelect}
        >
          <Selection mode="single" />

          <GroupPanel visible={false} />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column
            dataField="active"
            caption="Active"
            alignment="center"
            minWidth={80}
            cellRender={this.ActiveColumn}
          />
          <Column
            caption="Actions"
            alignment={"center"}
            cellRender={this.ActionButs}
            width={60}
          />
          <Column
            dataField="whmTruckNumId"
            caption="ID"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="name"
            caption="Truck"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="updatedAt"
            caption="Last Status"
            alignment="center"
            minWidth={80}
            cellRender={this.updatedAt}
          />
          <Column
            dataField="loading"
            caption="Load Trips"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="avgloading"
            caption="Avg Load (min)"
            alignment="center"
            minWidth={80}
            cellRender={this.TimeToLoad}
          />
          <Column
            dataField="unloading"
            caption="Unload Trips"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="avgunloading"
            caption="Avg Unload (min)"
            alignment="center"
            minWidth={80}
            cellRender={this.TimeToLoad}
          />
          <Column
            dataField="lateloading"
            caption="Late Loading"
            alignment="center"
            minWidth={80}
            cellRender={this.DelayColumnLoad}
          />
          <Column
            dataField="lateunloading"
            caption="Late Unloading"
            alignment="center"
            minWidth={80}
            cellRender={this.DelayColumnUnLoad}
          />
          <Export enabled={true} />
        </DataGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMTruckTable));
