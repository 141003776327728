import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { message, Tabs } from "antd";
import { Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import newACCOrderStore from "../../../../Stores/AccountUpdated/ACC/NewACCStore";
import userStore from "../../../../Stores/UserStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import SingleACCOrderHeader from "./SingleACCOrderHeader";
import JobDetailTab from "./Tabs/JobDetailTab";
import Services from "./Tabs/Services";
import AdditionalCharge from "./Tabs/AdditionalCharge";
import Discount from "./Tabs/Discount";
import Events from "./Tabs/Events";
import EventDocs from "./Tabs/EventDocs";
import AddAttTemplate from "./Tabs/AddAttTemplate";
import InvNotes from "./Tabs/InvNotes";
import InvoiceTAs from "./Tabs/InvoiceTAs";

const instance = axios.create({ baseURL: baseURL });

const { TabPane } = Tabs;
class SingleAIMOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      showApprove: false,
      showReject: false,
      load: { direction: false },
      tab: "Job Details",
      errmsgopen: false,
    };

    this.postChanges = this.postChanges.bind(this);
    this.postDate = this.postDate.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.clientEditVal = this.clientEditVal.bind(this);
    this.changeST = this.changeST.bind(this);
  }
  onHide() {
    this.setState({
      showApprove: false,
      showReject: false,
      showCancel: false,
      load: false,
    });
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  clientEditVal(val, label) {
    if (!newACCOrderStore.stopedit) {
      newACCOrderStore.editOrder(val, label);
    } else {
      if (!this.state.errmsgopen) {
        this.setState({ errmsgopen: true });
        message
          .warning("Please contact our office to make modifications.")
          .then(() => this.setState({ errmsgopen: false }));
      }
    }
  }
  editVal(val, label) {
    if (userStore.user.staffaccount) {
      newACCOrderStore.editOrder(val, label);
    } else {
      if (!this.state.errmsgopen) {
        this.setState({ errmsgopen: true });
        message
          .warning("Please contact our office to make modifications.")
          .then(() => this.setState({ errmsgopen: false }));
      }
    }
  }

  postDate = async (label, title, index) => {
    let labeldate = `${label}date`;
    let data = {
      [label]: newACCOrderStore.newacc[label],
      [labeldate]: newACCOrderStore.newacc[labeldate],
    };
    let reloadpage = false;

    this.setState({ load: { [label]: true } });
    await instance
      .put(`ac/accorder/edit/${this.props.match.params.accorderid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newACCOrderStore.updateOrgOrder(label);
        newACCOrderStore.updateOrgOrder(labeldate);

        message.success(`Order Updated (${title})`);
        if (reloadpage) {
          newACCOrderStore.getSingleACOrder(false, true);
        }
        this.setState({ load: { [label]: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  postChanges = async (label, title, index) => {
    let data = { [label]: newACCOrderStore.newacc[label] };
    
    let reloadpage = false;

    this.setState({ load: { [label]: true } });
    await instance
      .put(`ac/accorder/edit/${this.props.match.params.accorderid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newACCOrderStore.updateOrgOrder(label);

        message.success(`Order Updated (${title})`);
        if (reloadpage) {
          newACCOrderStore.getSingleACOrder(false, true);
        }
        this.setState({ load: { [label]: false } });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });

        Notification["error"]({ title: `An error occured` });
      });
  };
  componentDidMount() {
    newACCOrderStore.getSingleACOrder(this.props.match.params.accorderid);
  }

  render() {
    let staffButs;

    let load = this.state.load;

    return (
      <LoadBack loading={newACCOrderStore.loading.newacc}>
        <SingleACCOrderHeader changeST={this.changeST} />
        {userStore.user.staffaccount && staffButs}

        <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Job Details"} key={"Job Details"}>
              <InvoiceTAs
                editVal={this.editVal}
                postChanges={this.postChanges}
                load={load.invoicetas}
                accorderid={this.props.match.params.accorderid}
              />
              <JobDetailTab
                editVal={this.editVal}
                postChanges={this.postChanges}
                load={load}
              />
            </TabPane>
            <TabPane tab={"Services"} key={"Services"}>
              <div style={{ padding: "1%" }}>
                <Services />
                <AdditionalCharge />
                <Discount />
              </div>
            </TabPane>
            <TabPane tab={"Events"} key={"Events"}>
              <Events
                editVal={this.editVal}
                postChanges={this.postChanges}
                postDate={this.postDate}
                load={load}
              />
            </TabPane>
            <TabPane tab={"Doc Events"} key={"Doc Events"}>
              <EventDocs />
            </TabPane>
            <TabPane tab={"Add Docs"} key={"Add Docs"}>
              <AddAttTemplate attType={"Additional"} />
            </TabPane>
            <TabPane tab={"Inv Notes"} key={"Inv Notes"}>
              <InvNotes
                editVal={this.editVal}
                updateVal={this.postChanges}
                load={load.invnotes}
              />
            </TabPane>
          </Tabs>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(SingleAIMOrderPage));
