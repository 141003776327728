import { Parsed } from "./PRKDCalc";

function AddChargeCalc(chgs) {
  let final = 0;
  for (let i = 0; i < chgs.length; i++) {
    final += Parsed(chgs[i].price);
  }
  return final;
}

export { AddChargeCalc };
