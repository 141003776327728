import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class SingleAccountFinancialStore {
  constructor() {
    this.accountid = "";
    this.invoices = [];
    this.payments = [];
    this.loading = { invoices: false, payments: false };
    this.loaded = { invoices: false, payments: false };

    makeObservable(this, {
      accountid: observable,
      invoices: observable,
      loading: observable,
      loaded: observable,
      payments: observable,
    });
  }

  // FETCH
  getInvoices(clientid, override) {
    let loaded = false;
    let clID = this.accountid;
    if (clientid) clID = clientid;

    if (this.loaded.invoices) loaded = true;
    if (clID !== this.accountid) loaded = false;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.invoices = true;

      return instance
        .get(`/jf/invoice/getbyclient/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((invoices) => {
          this.accountid = clID;
          this.invoices = invoices;

          this.loading.invoices = false;
          this.loaded.invoices = true;
          console.log("Single Account Invoices Fetched");
        })
        .catch((err) => {
          this.loading.invoices = false;
          this.loaded.invoices = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Single Account Invoices.`,
          });
        });
    }
  }
  getPayments(clientid, override) {
    let loaded = false;
    let clID = this.accountid;
    if (clientid) clID = clientid;
    if (this.loaded.payments) loaded = true;
    if (clID !== this.accountid) loaded = false;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.payments = true;

      return instance
        .get(`/jf/payment/getbyclient/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((payments) => {
          this.accountid = clID;
          this.payments = payments;

          this.loading.payments = false;
          this.loaded.payments = true;
          console.log("Single Account Payments Fetched");
        })
        .catch((err) => {
          this.loading.payments = false;
          this.loaded.payments = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Single Account Payments.`,
          });
        });
    }
  }

  // FUNCTIONS:

  startLoading() {
    this.loading = true;
  }
}

const singleAccountFinancialStore = new SingleAccountFinancialStore();
export default singleAccountFinancialStore;
