import React, { Component } from "react";
import { FlexboxGrid, HelpBlock } from "rsuite";
import Col3 from "../../../../Components/Columns/Col3";
import StorDelTimeline from "../../../../Components/Timeline/StorDelTimeline";

class HeaderInfo extends Component {
  render() {
    let cds = this.props.cds;
    let inout = cds.stordelinout.inout;
    let stordel = cds.stordelinout.stordel;

    let fromadd = {};
    let toadd = {};
    if (inout === "In") {
      fromadd = { ...cds.neworder.location };
      toadd = { ...cds.neworder.emlocation };
    } else {
      fromadd = { ...cds.neworder.emlocation };
      if (stordel === "Storage") {
        toadd = { ...cds.neworder.emlocation };
      } else {
        toadd = { ...cds.neworder.location };
      }
    }

    return (
      <div>
        <div style={{ paddingLeft: "3%" }}>
          <FlexboxGrid>
            <Col3>
              <h6>Client Details</h6>
              <HelpBlock style={{ color: "black" }}>
                <b>Client Name: </b>
                {cds.client.name} #{cds.client.clientNumId}
              </HelpBlock>
              <HelpBlock style={{ color: "black" }}>
                <b>Email: </b>
                {cds.client.email === "" ? <i>No Email</i> : cds.client.email}
                <br />
                <b>Phone:</b> {cds.client.phone}
                {cds.client.phone2 !== "" && ` / ${cds.client.phone2}`}
              </HelpBlock>
            </Col3>
            <Col3>
              <h6>From: {fromadd.type}</h6>
              <HelpBlock style={{ color: "black" }}>
                <b>Name: </b>
                {fromadd.name}
              </HelpBlock>
              <HelpBlock style={{ color: "black" }}>
                <b>City: </b>
                {fromadd.city}
              </HelpBlock>
              <HelpBlock style={{ color: "black" }}>
                <b>Address: </b>
                {fromadd.address}
              </HelpBlock>
            </Col3>
            <Col3>
              <h6>To: {toadd.type}</h6>
              <HelpBlock style={{ color: "black" }}>
                <b>Name: </b>
                {toadd.name}
              </HelpBlock>
              <HelpBlock style={{ color: "black" }}>
                <b>City: </b>
                {toadd.city}
              </HelpBlock>
              <HelpBlock style={{ color: "black" }}>
                <b>Address: </b>
                {toadd.address}
              </HelpBlock>
            </Col3>
          </FlexboxGrid>
        </div>
        <br />
        <StorDelTimeline del={cds.neworder} />
      </div>
    );
  }
}

export default HeaderInfo;
