import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import moment from "moment";
import { Parsed } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class SingleAccountStore {
  constructor() {
    this.account = { _id: "" };
    this.storCont = {};
    this.delCont = {};
    this.acorders = [];
    this.pastorders = [];
    this.cancelledorders = [];
    this.inventory = [];
    this.calendar = [];
    this.startdate = moment().subtract(1, "month").startOf("month");

    this.loading = {
      account: false,
      acorders: false,
      inventory: false,
      calendar: false,
    };
    this.loaded = {
      acorders: false,
      inventory: false,
      currorders: false,
      pastorders: false,
      cancelledorders: false,
    };

    makeObservable(this, {
      account: observable,
      acorders: observable,
      pastorders: observable,
      cancelledorders: observable,
      inventory: observable,
      storCont: observable,
      delCont: observable,
      loading: observable,
      loaded: observable,
      calendar: observable,
      startdate: observable,
      stockcalcs: computed,
    });
  }

  // FETCH
  getAccount(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.account = true;

      return instance
        .get(`/ac/account/getaccount/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((account) => {
          this.account = account;
          this.storCont = account.storageContract;
          this.delCont = account.deliveryContract;
          this.loaded.acorders = false;
          this.loaded.inventory = false;

          this.loading.account = false;
          console.log("Single Account Dashboard Fetched");
        })
        .catch((err) => {
          this.loading.account = false;
          this.account = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  getACOrders(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) {
      if (this.loaded.acorders) loaded = true;
    }
    if (override) loaded = false;
    if (!loaded) {
      this.loading.acorders = true;
      this.loaded.acorders = false;

      let data = { startdate: this.startdate };
      return instance
        .put(`/ac/warehouse/ordersbyclient/${clID}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          this.acorders = orders;
          this.loaded.acorders = true;

          this.loading.acorders = false;

          console.log("AC Orders Fetched for Client");
        })
        .catch((err) => {
          this.loading.acorders = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching AC Orders for Client.`,
          });
        });
    }
  }
  getCustomOrderStatus(status, override) {
    let loaded = false;
    let clID = this.account._id;
    let urlcode;
    if (status === "Cancelled") {
      if (this.loaded.cancelledorders) loaded = true;
      urlcode = "cancelledbyclient";
    } else if (status === "Completed") {
      if (this.loaded.pastorders) loaded = true;
      urlcode = "completedbyclient";
    }
    if (override) loaded = false;
    if (!loaded) {
      this.loading.acorders = true;
      this.loaded.acorders = false;
      let data = { startdate: this.startdate };
      return instance
        .put(`/ac/warehouse/${urlcode}/${clID}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          if (status === "Cancelled") {
            this.cancelledorders = orders;
            this.loaded.cancelledorders = true;
            console.log("Cancelled AC Orders Fetched for Client");
          } else if (status === "Completed") {
            this.pastorders = orders;
            this.loaded.pastorders = true;

            console.log("Past AC Orders Fetched for Client");
          }
          this.loading.acorders = false;
        })
        .catch((err) => {
          this.loading.acorders = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching ${status} AC Orders for Client.`,
          });
        });
    }
  }

  editDate(val) {
    singleAccountStore.startdate = val;
  }

  getACItems(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) {
      if (this.loaded.inventory) loaded = true;
    }
    if (override) loaded = false;
    if (!loaded) {
      this.loading.inventory = true;
      this.loaded.inventory = false;

      return instance
        .get(`/ac/itemsrequest/currentbyclient/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          this.inventory = orders;
          this.loading.inventory = false;
          this.loaded.inventory = true;
          console.log("Current Inventory Fetched for Client");
        })
        .catch((err) => {
          this.loading.inventory = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Current Inventory.`,
          });
        });
    }
  }

  getCalendar(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.calendar = true;

      return instance
        .get(`/ac/warehouse/calendar/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          this.calendar = orders;

          this.loading.calendar = false;

          console.log("AC Orders Calendar Fetched for Client");
        })
        .catch((err) => {
          this.loading.calendar = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching AC Orders Calendar for Client.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get stockcalcs() {
    let items = 0;
    let instock = 0;
    let reserve = 0;
    for (let i = 0; i < this.inventory.length; i++) {
      items += 1;
      instock += Parsed(this.inventory[i].currentstock);
      reserve += Parsed(this.inventory[i].onreserve);
    }
    return { items: items, instock: instock, reserve: reserve };
  }
  // FUNCTIONS:

  startLoading() {
    this.loading = true;
  }
}

const singleAccountStore = new SingleAccountStore();
export default singleAccountStore;
