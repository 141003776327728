import React, { Component } from "react";

import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
  LoadPanel,
} from "devextreme-react/data-grid";
import { observer } from "mobx-react";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import Button from "devextreme-react/button";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

import { Icon, IconButton } from "rsuite";

import userStore from "../../../../../../Stores/UserStore";
import Div14 from "../../../../../../Components/Dividers/Div14";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import UnifiedModal from "../../../../../../Components/Modal/UnifiedModal";

import accInvoiceStore from "../../../../../../Stores/Financial/ACCInvoiceStore";
import MainACCInvoice from "../../../../../../Components/PDFs/ACCInvoice/MainACCInvoice";

class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoExpandAll: true,
      acinvoice: {},
      show: false,
      showedit: false,
      load: false,
    };
    this.onExporting = this.onExporting.bind(this);
    this.DownloadInvoice = this.DownloadInvoice.bind(this);
    this.StatusCell = this.StatusCell.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    this.setState({ show: false, showedit: false, acinvoice: {}, load: false });
  }
  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending" || status === "Cancelled") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }

  DownloadInvoice(cellData) {
    return (
      <>
        <Tooltip title={`View Invoice`} arrow>
          <IconButton
            icon={<Icon icon="save" />}
            color="blue"
            circle
            size="xs"
            onClick={() =>
              this.setState({ show: true, acinvoice: cellData.data })
            }
          />
        </Tooltip>
        <span> </span>
        {userStore.user.jobs ? (
          <Tooltip title={`Edit Invoice`} arrow>
            <IconButton
              icon={<Icon icon="pencil" />}
              color="yellow"
              circle
              size="xs"
              onClick={() => {
                accInvoiceStore.selectInvoice(cellData.data);

                this.props.onEditClick();
              }}
            />
          </Tooltip>
        ) : (
          <div />
        )}
      </>
    );
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `International_Account_Invoice_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  render() {
    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
        }}
      >
        <Div14>Custom Clearance Account Invoices</Div14>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="ACC Invoice View"
          footer={null}
        >
          <MainACCInvoice inv={this.state.acinvoice} />
        </UnifiedModal>
        <LoadBack loading={accInvoiceStore.loading.invpayments}>
          <DataGrid
            dataSource={accInvoiceStore.accinvoices}
            keyExpr="accInvoiceNumId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <LoadPanel enabled={true} />
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />

            <Column
              dataField="accInvoiceNumId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="date"
              dataType="date"
              caption="Invoice Date"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
            />
            <Column
              dataField="title"
              dataType="text"
              caption="Invoice Title"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="status"
              caption="Status"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
              cellRender={this.StatusCell}
            />

            <Column
              dataField="totalinvoicecost"
              dataType="number"
              caption="Invoice Cost (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={100}
            />
            <Column
              caption="Download Invoice"
              alignment="center"
              minWidth={80}
              cellRender={this.DownloadInvoice}
            />

            <Summary>
              <TotalItem
                column="accInvoiceNumId"
                summaryType="count"
                displayFormat={"{0}"}
              />
              <TotalItem
                column="totalinvoicecost"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Toolbar>
              <Item name="Refresh" location="before">
                <Button
                  icon="refresh"
                  onClick={() => accInvoiceStore.getInvPayments(true)}
                />
              </Item>
              {userStore.user.jobs && (
                <Item name="New" location="after">
                  <Tooltip title={`New AC Invoice`} arrow placement="left">
                    <IconButton
                      icon={<Icon icon="plus" />}
                      onClick={() => this.props.onNewClick()}
                      color="green"
                      circle
                    />
                  </Tooltip>
                </Item>
              )}
              <Tooltip title={`Choose Columns`} arrow placement="left">
                <Item name="columnChooserButton" />
              </Tooltip>
              <Item name="exportButton" />
              {userStore.user.staffaccount & !accInvoiceStore.cancelled ? (
                <Item name="Show Cancelled" location="after">
                  <Tooltip title={`Show Cancelled`} arrow placement="left">
                    <IconButton
                      icon={<Icon icon="trash" />}
                      onClick={() => accInvoiceStore.getCancelledByClient()}
                      color="red"
                      circle
                    />
                  </Tooltip>
                </Item>
              ) : (
                <div />
              )}
            </Toolbar>
            <Export enabled={true} />
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default observer(InvoiceTable);
