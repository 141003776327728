import { observer } from "mobx-react";
import React, { Component } from "react";

import axios from "axios";

import { message } from "antd";

import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";

import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import baseURL from "../../../../../Static/baseURL/baseURL";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import userStore from "../../../../../Stores/UserStore";

import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import Col4 from "../../../../../Components/Columns/Col4";
import Col2 from "../../../../../Components/Columns/Col2";

const instance = axios.create({ baseURL: baseURL });

class LocationOrder extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, show: false };
    this.updateOrder = this.updateOrder.bind(this);
  }
  onHide() {
    this.setState({ show: false });
  }
  updateOrder = async () => {
    this.setState({ loading: true });
    let locs = [...this.props.locs];
    let newlocs = [];
    for (let i = 0; i < locs.length; i++) {
      newlocs = [...newlocs, { _id: locs[i]._id, order: i + 1 }];
    }

    await instance
      .put(`ac/location/reorderlocations`, newlocs, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.props.onHide();
        message.success(`Location Order Updated`);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let locs = this.props.locs;

    return (
      <div>
        {locs.map((to, index) => (
          <FlexboxGrid
            key={index}
            justify="center"
            style={{
              textAlign: "center",
              border: "1px solid black",
              margin: "2px",
              padding: "2px",
              borderRadius: "5px",
            }}
          >
            <Col4>
              <b>Old Order {to.order}:</b> {to.name}: {to.city}
            </Col4>
            <Col2>{to.address}</Col2>
            <Col4>
              <ButtonToolbar>
                <ButtonGroup>
                  <IconButton
                    icon={<Icon icon="down" />}
                    color="red"
                    appearance="ghost"
                    disabled={index === locs.length - 1}
                    onClick={() => this.props.moveNewTo(index, "down")}
                  />
                  <Button
                    appearance="ghost"
                    style={{
                      backgroundColor: "white",
                      fontWeight: "bold",
                      color: "black",
                      borderColor: "black",
                    }}
                  >
                    {index + 1}
                  </Button>
                  <IconButton
                    icon={<Icon icon="up" />}
                    color="green"
                    appearance="ghost"
                    disabled={index === 0}
                    onClick={() => this.props.moveNewTo(index, "up")}
                  />
                </ButtonGroup>
              </ButtonToolbar>
            </Col4>
          </FlexboxGrid>
        ))}
        <hr />
        <ModalFooter
          icon={["close", "save"]}
          color={["red", "green"]}
          app={["ghost", "default"]}
          text={["Cancel Changes", "Save Changes"]}
          oC1={() => this.props.onHide()}
          oC2={() => this.updateOrder()}
          disabled2={false}
          loading1={this.state.loading}
          loading2={this.state.loading}
        />
      </div>
    );
  }
}

export default observer(LocationOrder);
