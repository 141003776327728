import { observer } from "mobx-react";
import React, { Component } from "react";
import { Tabs } from "antd";
import { withRouter } from "react-router";
import modalACOrderStore from "../../../../Stores/ModalStores/ModalACOrderStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import TimelineComp from "../../../../Components/Timeline/TimelineComp";
import Div14 from "../../../../Components/Dividers/Div14";
import OrderSummaryTable from "./Supporting/OrderSummaryTable";
import CostSummaryTable from "./Supporting/CostSummaryTable";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import IMTrackTable from "./Supporting/IMTrackTable";

const { TabPane } = Tabs;

class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Summary" };
  }

  render() {
    let aim = modalACOrderStore.aim;

    let allsteps = [];
    let step = 2;
    allsteps = ["Requested", "Booked"];
    if (aim.status === "Requested") step = 0;
    if (aim.status === "Booked") step = 1;

    if (aim.status === "In Transit") {
      allsteps = [...allsteps, "In Transit", "Delivered"];
    } else {
      allsteps = [...allsteps, "Delivered"];
    }

    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          onHide={() => {
            this.setState({ tab: "Summary" });
            this.props.onHide();
          }}
          title={`Delivery Request #${aim.aimOrderNumId} for ${aim.client.name}`}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Go To Order"]}
              oC1={() => {
                this.setState({ tab: "Summary" });
                this.props.onHide();
              }}
              oC2={() => {
                this.props.history.push(`/aimorder/${aim._id}`);
              }}
            />
          }
        >
          <LoadBack loading={modalACOrderStore.loading.aim}>
            <TimelineComp steps={allsteps} step={step} />
            <Tabs
              activeKey={this.state.tab}
              defaultActiveKey={"home"}
              tabPosition={"top"}
              centered
              type="card"
              onChange={(e) => this.setState({ tab: e })}
            >
              <TabPane tab={`Summary`} key="Summary">
                <Div14>Order Summary Table</Div14>
                <OrderSummaryTable aim={aim} account={aim.client} />
              </TabPane>

              <TabPane tab={`Costs`} key="Costs">
                <CostSummaryTable aim={aim} account={aim.client} />
              </TabPane>
              <TabPane tab={`Tracking`} key="Tracking">
                <IMTrackTable aim={aim} account={aim.client} />
              </TabPane>
            </Tabs>
          </LoadBack>
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(OrderModal));
