import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import emSignature from "../../../Pictures/signature.png";
import moment from "moment";
import { Buffer } from "buffer";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol5blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol25blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol15blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol5white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "5%",
  },
  tableCol25white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "25%",
  },
  tableCol15white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
});

class SingleItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let item = this.props.item;

    return (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableCol5white}>{item.acitem.acItemNumId}</Text>
        <Text style={styles.tableCol25white}>{item.acitem.name}</Text>
        <Text style={styles.tableCol25white}>{item.acitem.serialnumber}</Text>
        <Text style={styles.tableCol5white}>{item.qty}</Text>
        <Text style={styles.tableCol15white}>{item.type}</Text>
        <Text style={styles.tableCol25white}>{item.remarks}</Text>
      </View>
    );
  }
}

class HeaderSection extends Component {
  render() {
    let dr = this.props.dr;
    let sto = this.props.sto;
    let client = this.props.client;

    let src = dr.clientsignature;
    let imagesrc;
    let showimage = false;

    if (src) {
      showimage = true;
      const b64 = new Buffer(src).toString("base64");
      imagesrc = `data:image/png;base64,${b64}`;
    }

    let acOrderId = sto.acOrderNumId;
    let clientname = client.name;

    let proposaltitle = `Damage Report for ${clientname} - Order #${acOrderId}`;

    let itemslist = dr.item.map((item, index) => (
      <SingleItems item={item} key={index} sto={sto} index={index} />
    ));

    let allimages = [];
    for (let i = 0; i < dr.item.length; i++) {
      if (dr.item[i].attachment) {
        allimages = [...allimages, dr.item[i].attachment];
      }
    }
    let showimages = allimages.map((im, index) => (
      <Image
        style={{ width: 100 }}
        key={index}
        src={{
          im,
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
      />
    ));

    return (
      <View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Date: {moment(dr.notifiedClient).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>Order ID: {acOrderId}</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>info@easymovekw.com</Text>
          <Text style={styles.tableRight}>
            Damage Report ID: {sto.damagereport.damageReportNumId}
          </Text>
        </View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text style={styles.header}>{proposaltitle}</Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Kindly note the below items were flagged by the Easy Move Team for
          Order ID#{acOrderId} on {moment(sto.jobdate).format("DD-MMM-YYYY")};
          we would require the Client to review and further Easy Move on how to
          proceed.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol5blue}>ID</Text>
          <Text style={styles.tableCol25blue}>Item</Text>
          <Text style={styles.tableCol25blue}>Serial No.</Text>
          <Text style={styles.tableCol5blue}>Qty</Text>
          <Text style={styles.tableCol15blue}>Damage</Text>
          <Text style={styles.tableCol25blue}>Remarks</Text>
        </View>
        {itemslist}
        {dr.clientsignature ? (
          <View style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
              Client Instructions:
            </Text>
            <View style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
                {dr.clientInstructions}
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Text style={{ fontSize: 9, fontFamily: "Times-Roman" }}>
              Pending Client Review and Instruction
            </Text>
          </View>
        )}
        <View style={{ height: 20 }} />
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          By signing both parties have reviewed and agreed on the attached
          document including the Client's Instructions to Proceed.
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Image src={emSignature} style={{ width: 100 }} />
          <Text style={{ width: "50%" }} />
          {showimage && <Image src={imagesrc} style={{ width: 100 }} />}
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Times-Roman",
              width: "70%",
            }}
          >
            Easy Move Logistics Co.
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Times-Roman",
              width: "30%",
            }}
          >
            Client Signature: {dr.clientname}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "70%",
              fontSize: 9,
              fontFamily: "Times-Roman",
            }}
          >
            Date: {moment(dr.createdAt).format("DD-MMM-YYYY")}
          </Text>
          <Text
            style={{
              width: "30%",
              fontSize: 9,
              fontFamily: "Times-Roman",
            }}
          >
            Date:{" "}
            {showimage
              ? moment(dr.clientsigndate).format("DD-MMM-YYYY HH:mm A")
              : "Pending Client Signature"}
          </Text>
        </View>
        <View>{showimages}</View>
      </View>
    );
  }
}

export default observer(HeaderSection);
