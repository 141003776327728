import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import { Parsed } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class CompleteStorStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.storCont = {};
    this.toaddress = { _id: "", acitemmove: [] };

    this.delCont = {
      remarks: "",
      maintype: "Fixed Per Trip",
      fixedweekly: 0,
      freeweeklytrips: 0,

      pricepertruck: 35,
      dropoffmult: 1.2,
      staffcount: 0,
      carcount: 1,
      maxcbmpertrip: 0,

      nongroundfloorprice: 10,

      cancelhours: 8,
      cancelprice: 40,
      timetoreq: 24,
      afterhourfee: 20,
      expresstime: 8,
      expressfee: 20,

      pricepertrip: {
        sun: 20,
        mon: 20,
        tue: 20,
        wed: 20,
        thu: 20,
        fri: 20,
        sat: 20,
      }, // Price Per Job
      zoneadd: {
        z1: 0,
        z2: 0,
        z3: 0,
        z4: 0,
        z5: 0,
      }, // Price Per Job

      excludedhours: {
        0: "No",
        1: "No",
        2: "No",
        3: "No",
        4: "No",
        5: "No",
        6: "After",
        7: "After",
        8: "Yes",
        9: "Yes",
        10: "Yes",
        11: "Yes",
        12: "Yes",
        13: "Yes",
        14: "Yes",
        15: "Yes",
        16: "Yes",
        17: "Yes",
        18: "After",
        19: "After",
        20: "After",
        21: "After",
        22: "No",
        23: "No",
        sun: "Yes",
        mon: "Yes",
        tue: "Yes",
        wed: "Yes",
        thu: "Yes",
        fri: "No",
        sat: "After",
      },
    };
    this.neworder = {
      status: "Requested",
      createdAt: moment().set({ second: 0 }),
      jobdate: moment().set({ second: 0 }),
      bookeddate: undefined,

      deliverdate: undefined,
      canceldate: undefined,
      jobtype: "Delivery In",
      emlocation: { ...this.storCont.emlocation },
      toaddress: [],
      orderoptions: [],
      services: {
        timetype: "",
        timeprice: 0,
        weekday: "",
        weekdayprice: 0,
        zonetype: "",
        zonecost: 0,
        ngfchg: 0,
        stincost: 0,
        stoutcost: 0,
        canctime: 0,
        canccost: 0,
      },
      addcharge: [],
      discount: [],
      trucktrack: null,
      charges: { totaljob: 0 },
    };
    this.acitemmove = [];
    this.filter = "";

    this.loading = false;

    makeObservable(this, {
      account: observable,
      storCont: observable,
      delCont: observable,
      neworder: observable,
      acitemmove: observable,
      filter: observable,
      loading: observable,
      toaddress: observable,

      allitemmoves: computed,
      finalitemmoves: computed,
      delstoinout: computed,
      itemMoveStats: computed,
      allItemMoveStats: computed,
    });
  }

  // FETCH

  getSingleACOrder(acorderid, override) {
    let loaded = false;
    let oID = this.neworder._id;
    if (acorderid) oID = acorderid;
    if (oID === this.neworder._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading = true;

      return instance
        .get(`/ac/warehouse/singleacorder/${oID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.account;
          this.neworder = { ...order.order };
          this.delCont = order.account.deliveryContract;
          this.stoCont = order.account.storageContract;

          this.loading = false;
          console.log("Pickup Dropoff AC Order Data Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getSingleACOrderFromTo(toaddressid, override) {
    let loaded = false;
    let oID = this.toaddress._id;
    if (toaddressid) oID = toaddressid;
    if (oID === this.toaddress._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading = true;

      return instance
        .get(`/ac/warehouse/singleacorderfromto/${oID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.order.account;
          this.neworder = { ...order.order.order };
          this.delCont = order.order.account.deliveryContract;
          this.stoCont = order.order.account.storageContract;
          this.toaddress = order.toaddress;
          this.loading = false;
          console.log("Pickup Dropoff AC Order Data Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get delstoinout() {
    let delsto = "Delivery";
    let inout = "Out";
    if (
      this.neworder.jobtype === "Storage In" ||
      this.neworder.jobtype === "Storage Out"
    ) {
      delsto = "Storage";
    }
    if (
      this.neworder.jobtype === "Storage In" ||
      this.neworder.jobtype === "Delivery In"
    ) {
      inout = "In";
    }
    let type = "Pickup";

    if (delsto === "Storage") {
      if (inout === "In") type = "Storage In";
      if (inout === "Out") type = "Storage Out";
    } else if (delsto === "Delivery") {
      if (inout === "In") {
        type = "Pickup";
      } else if (inout === "Out") {
        type = "Dropoff";
      }
    }

    return { delsto: delsto, inout: inout, type: type };
  }

  get finalitemmoves() {
    let itmoves = this.toaddress.acitemmove;
    let confirmed = itmoves.filter(
      (it) => Parsed(it.quantity) === Parsed(it.actualquantity)
    );
    let missing = itmoves.filter((it) => Parsed(it.actualquantity) === 0);
    let modified = itmoves.filter(
      (it) =>
        (Parsed(it.actualquantity) > 0) &
        (Parsed(it.quantity) !== Parsed(it.actualquantity))
    );
    if (this.filter === "confirmed") {
      return confirmed;
    } else if (this.filter === "missing") {
      return missing;
    } else if (this.filter === "modified") {
      return modified;
    } else {
      return itmoves;
    }
  }
  get allitemmoves() {
    let itmoves = [];
    for (let i = 0; i < this.neworder.toaddress.length; i++) {
      for (let k = 0; k < this.neworder.toaddress[i].acitemmove.length; k++) {
        itmoves = [
          ...itmoves,
          {
            ...this.neworder.toaddress[i].acitemmove[k],
            toaddressind: i,
            itemind: k,
          },
        ];
      }
    }
    let confirmed = itmoves.filter(
      (it) => Parsed(it.quantity) === Parsed(it.actualquantity)
    );
    let missing = itmoves.filter((it) => Parsed(it.actualquantity) === 0);
    let modified = itmoves.filter(
      (it) =>
        (Parsed(it.actualquantity) > 0) &
        (Parsed(it.quantity) !== Parsed(it.actualquantity))
    );
    if (this.filter === "confirmed") {
      return confirmed;
    } else if (this.filter === "missing") {
      return missing;
    } else if (this.filter === "modified") {
      return modified;
    } else {
      return itmoves;
    }
  }

  get itemMoveStats() {
    let selected = { items: 0, packages: 0, cbm: 0 };
    let confirmed = 0;
    let modified = 0;
    let missing = 0;
    let itmoves = this.toaddress.acitemmove;

    for (let i = 0; i < itmoves.length; i++) {
      if (itmoves[i].actualquantity > 0) {
        selected.items += 1;
        selected.packages += Parsed(itmoves[i].actualquantity);
        selected.cbm += Parsed(
          (Parsed(itmoves[i].itemlength) *
            Parsed(itmoves[i].itemwidth) *
            Parsed(itmoves[i].itemheight) *
            Parsed(itmoves[i].actualquantity)) /
            1000000
        );
      }

      if (Parsed(itmoves[i].quantity) === Parsed(itmoves[i].actualquantity)) {
        confirmed += 1;
      } else if (Parsed(itmoves[i].actualquantity) === 0) {
        missing += 1;
      } else {
        modified += 1;
      }
    }
    return {
      confirmed: confirmed,
      modified: modified,
      missing: missing,
      selected: selected,
    };
  }
  get allItemMoveStats() {
    let selected = { items: 0, packages: 0, cbm: 0 };
    let confirmed = 0;
    let modified = 0;
    let missing = 0;
    let toadd = this.neworder.toaddress;
    for (let k = 0; k < toadd.length; k++) {
      for (let i = 0; i < toadd[k].acitemmove.length; i++) {
        if (toadd[k].acitemmove[i].actualquantity > 0) {
          selected.items += 1;
          selected.packages += Parsed(toadd[k].acitemmove[i].actualquantity);
          selected.cbm += Parsed(
            (Parsed(toadd[k].acitemmove[i].itemlength) *
              Parsed(toadd[k].acitemmove[i].itemwidth) *
              Parsed(toadd[k].acitemmove[i].itemheight) *
              Parsed(toadd[k].acitemmove[i].actualquantity)) /
              1000000
          );
        }

        if (
          Parsed(toadd[k].acitemmove[i].quantity) ===
          Parsed(toadd[k].acitemmove[i].actualquantity)
        ) {
          confirmed += 1;
        } else if (Parsed(toadd[k].acitemmove[i].actualquantity) === 0) {
          missing += 1;
        } else {
          modified += 1;
        }
      }
    }
    return {
      confirmed: confirmed,
      modified: modified,
      missing: missing,
      selected: selected,
    };
  }

  // FUNCTIONS:

  changeStoActQuantity(index, val, toaddind) {
    this.neworder.toaddress[toaddind].acitemmove[index].actualquantity = val;
  }

  changeActQuantity(index, val) {
    this.toaddress.acitemmove[index].actualquantity = val;
  }

  changeSTOOnlyActQuantity(itemmoveid, val) {
    let toindex = -1;
    let itemmoveindex = -1;
    for (let i = 0; i < this.neworder.toaddress.length; i++) {
      for (let j = 0; j < this.neworder.toaddress[i].acitemmove.length; j++) {
        if (
          `${itemmoveid}` === `${this.neworder.toaddress[i].acitemmove[j]._id}`
        ) {
          toindex = i;
          itemmoveindex = j;
        }
      }
    }
    if (toindex >= 0) this.changeStoActQuantity(itemmoveindex, val, toindex);
  }

  changeFilt(type) {
    if (this.filter === type) {
      this.filter = "";
    } else {
      this.filter = type;
    }
  }
  startLoading() {
    this.loading = true;
  }
}

const completeStorStore = new CompleteStorStore();
export default completeStorStore;
