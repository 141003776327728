import { PageHeader } from "antd";
import MaterialTable from "material-table";
import { observer } from "mobx-react";
import React, { Component } from "react";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { Tabs } from "antd";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";

const { TabPane } = Tabs;

class AddExisting extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Existing Items" };
  }
  componentDidMount() {
    newStorageStore.getExistingItems(false, false);
  }
  render() {
    let available = newStorageStore.unselecteditems;

    if (this.state.tab === "Past Items") {
      available = newStorageStore.unselectpast;
    }

    let columnnames = [
      {
        title: "ID",
        field: "acItemNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Name", field: "name", removable: true },
      { title: "Ser. No.", field: "serialnumber", removable: true },
      { title: "Prod. ID", field: "productid", removable: true },
      { title: "Length", field: "length", removable: true },
      { title: "Width", field: "width", removable: true },
      { title: "Height", field: "height", removable: true },
      { title: "CBM", field: "cbm", removable: true },
      { title: "Stock", field: "currentplusreserve", removable: true },
    ];
    return (
      <LoadBack loading={newStorageStore.loading.items}>
        <PageHeader
          title="Go Back to Items List"
          onBack={() => this.props.onHide()}
        />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={"top"}
          centered
          type="line"
          onChange={(e) => {
            newStorageStore.getPastItems(false, false);
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={`Existing Items`} key="Existing Items" />
          {newStorageStore.delstoinout.inout === "In" && (
            <TabPane tab={`Past Items`} key="Past Items" />
          )}
        </Tabs>
        <MaterialTable
          title={`${available.length} Items`}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={available}
          options={{
            actionsColumnIndex: 0,
            paging: true,
            pageSize: 15,
            pageSizeOptions: [15, 25, 50, 100],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            padding: "dense",
          }}
          actions={[
            {
              icon: "add",
              tooltip: "Add",
              onClick: (event, rowData) => {
                this.props.selectExisting({
                  acItemNumId: rowData.acItemNumId,
                  actualquantity: 1,
                  quantity: 1,
                  itemheight: rowData.height,
                  itemname: rowData.name,
                  itemlength: rowData.length,
                  itemwidth: rowData.width,
                  itemproductid: rowData.productid,
                  itemserialnumber: rowData.serialnumber,
                  acitem: {
                    name: rowData.name,
                    currentstock: rowData.currentstock,
                    currentplusreserve: rowData.currentplusreserve,
                    onreserve: rowData.onreserve,
                    enteringstock: rowData.enteringstock,
                    _id: rowData._id,
                  },
                });
              },
            },
          ]}
        />
      </LoadBack>
    );
  }
}

export default observer(AddExisting);
