import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class MgtOverviewStore {
  constructor() {
    this.local = {};
    this.clients = {};
    this.income = {};
    this.expense = {};
    this.warehouse = [];
    this.months = 4;
    this.orgmonths = 4;
    this.startdate = moment().startOf("month");
    this.loading = {
      local: false,
      clients: false,
      income: false,
      expense: false,
      warehouse: false,
    };
    this.loaded = {
      local: false,
      clients: false,
      income: false,
      expense: false,
      warehouse: false,
    };

    makeObservable(this, {
      local: observable,
      clients: observable,
      income: observable,
      expense: observable,
      warehouse: observable,
      startdate: observable,
      months: observable,
      orgmonths: observable,
      loading: observable,
      loaded: observable,
      checkLoading: computed,
    });
  }

  // FETCH
  getOverview(override) {
    let loaded = this.loaded.local;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.local = true;
      this.loaded.local = false;
      let data = { startdate: this.startdate, months: this.months };
      return instance
        .put(`/mgt/overview/local`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.local = res.data;

          this.loading.local = false;
          this.loaded.local = true;

          console.log("Local Management Overview Fetched");
        })
        .catch((err) => {
          this.loading.local = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getClients(override) {
    let loaded = this.loaded.clients;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.clients = true;
      this.loaded.clients = false;
      let data = { startdate: this.startdate, months: this.months };
      return instance
        .put(`/mgt/overview/clients`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.clients = res.data;

          this.loading.clients = false;
          this.loaded.clients = true;

          console.log("Client Management Overview Fetched");
        })
        .catch((err) => {
          this.loading.clients = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getIncome(override) {
    let loaded = this.loaded.income;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.income = true;
      this.loaded.income = false;
      let data = { startdate: this.startdate, months: this.months };
      return instance
        .put(`/mgt/overview/income`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.income = res.data;

          this.loading.income = false;
          this.loaded.income = true;

          console.log("Client Management Overview Fetched");
        })
        .catch((err) => {
          this.loading.income = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getExpense(override) {
    let loaded = this.loaded.expense;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.expense = true;
      this.loaded.expense = false;
      let data = { startdate: this.startdate, months: this.months };
      return instance
        .put(`/mgt/overview/expense`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.expense = res.data;

          this.loading.expense = false;
          this.loaded.expense = true;

          console.log("Client Management Overview Fetched");
        })
        .catch((err) => {
          this.loading.expense = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getWarehouse(override) {
    let loaded = this.loaded.warehouse;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.warehouse = true;
      this.loaded.warehouse = false;
      let data = {
        startdate: moment(this.startdate)
          .subtract(this.months - 1, "month")
          .startOf("month"),
      };
      return instance
        .put(`/mgt/overview/warehouse`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.warehouse = res.data;

          this.loading.warehouse = false;
          this.loaded.warehouse = true;

          console.log("Client Management Overview Fetched");
        })
        .catch((err) => {
          this.loading.warehouse = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get checkLoading() {
    if (this.loading.clients) return true;
    if (this.loading.expense) return true;
    if (this.loading.income) return true;
    if (this.loading.local) return true;

    return false;
  }
  // FUNCTIONS:
  changeDate(val) {
    mgtOverviewStore.startdate = val;
  }
  changeMonths(val) {
    mgtOverviewStore.months = val;
  }
  updateMonths() {
    mgtOverviewStore.orgmonths = mgtOverviewStore.months;
    mgtOverviewStore.loaded = {
      local: false,
      clients: false,
      income: false,
      expense: false,
    };
  }
}

const mgtOverviewStore = new MgtOverviewStore();
export default mgtOverviewStore;
