import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Descriptions } from "antd";
import newACCOrderStore from "../../../../../Stores/AccountUpdated/ACC/NewACCStore";
import moment from "moment";
import Div14 from "../../../../../Components/Dividers/Div14";
import ItemsList from "../ItemsList/ItemsList";

class ACCStep4 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let acc = newACCOrderStore.newacc;
    let data1 = [
      { title: "Shipping Method", val: acc.shippingmode },
      { title: "Shipping Line", val: acc.shippingline },
      { title: "Commodity", val: acc.commodity },
      { title: "Brand / Supplier", val: acc.brandsupplier },
      { title: "Origin Location", val: acc.originlocation },
      { title: "Bill of Lading Number", val: acc.blnumber },
    ];
    let data2 = [
      {
        title: "Vessel Arrival Date",
        val: moment(acc.vesselarrival).format("DD-MMM-YY"),
      },
      { title: "Container Type", val: acc.container },
      { title: "# of Containers", val: acc.containercount },
      { title: "Packages", val: acc.packages },
      { title: "Weight (KG)", val: acc.weight },
    ];

    return (
      <div>
        <Div14>Order Confirmation:</Div14>
        <div
          style={{
            marginLeft: "5%",
            marginRight: "5%",
            boxShadow: "0px 0px 5px black",
            borderRadius: "5px",
          }}
        >
          <Div14>Initial Details</Div14>
          <Descriptions bordered size="small">
            {data1.map((val, index) => (
              <Descriptions.Item
                key={index}
                label={val.title}
                span={3}
                labelStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  width: "30%",
                }}
              >
                {val.val}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Div14>Shipping Specifications</Div14>
          <Descriptions bordered size="small">
            {data2.map((val, index) => (
              <Descriptions.Item
                key={index}
                label={val.title}
                span={3}
                labelStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  width: "30%",
                }}
              >
                {val.val}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Div14>Items List</Div14>

          <ItemsList hideaction />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(ACCStep4));
