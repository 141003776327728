import { observer } from "mobx-react";
import React, { Component } from "react";
import { InputGroup, Input, Icon, HelpBlock } from "rsuite";
class TextInput extends Component {
  render() {
    let type = this.props.type;
    let label = this.props.label;
    let val = this.props.val;
    let org = this.props.org;
    let load = this.props.load;
    let index = this.props.index;
    let disabled = this.props.disabled;

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {org !== val && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(type, org, index)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <InputGroup.Addon>{label}</InputGroup.Addon>
          <Input
            as="textarea"
            rows={3}
            value={val}
            onChange={(e) => this.props.editVal(type, e, index)}
            readOnly={disabled}
          />
          {org !== val && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(type, index)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {org !== val && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>"{org}"</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(TextInput);
