import { Image } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import moment from "moment";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import { Buffer } from "buffer";

class SignatureWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { new: false };
    this.sigPad = {};
  }

  postSignature = async () => {
    console.log("Test");
  };

  clearSign() {
    this.sigPad.clear();

    this.props.changeST(null, "sig");
    this.setState({ new: false });
  }

  trimAndUpload = async () => {
    let signatureimage = this.sigPad.toDataURL("image/png");
    this.props.changeST(signatureimage, "sig");
    this.setState({ new: false });
  };
  render() {
    let src = null;
    let oldsig = null;
    let oldsigdata = this.props.oldsig;
    if (oldsigdata) {
      if (oldsigdata.signature) {
        oldsig = oldsigdata.signature;
      }
    }

    let imagesrc;
    let b64;
    if (this.props.sig) {
      src = this.props.sig;
      imagesrc = src;
    } else if (oldsig) {
      src = oldsig;

      b64 = new Buffer(src).toString("base64");
      imagesrc = `data:image/png;base64,${b64}`;
    }

    let content = (
      <div>
        <ModalFooter
          icon={["close", "save"]}
          color={["red", "green"]}
          app={["ghost", "default"]}
          text={["Cancel", "Save Signature"]}
          oC1={() => this.setState({ new: false })}
          oC2={() => this.trimAndUpload()}
          disabled2={!this.props.sig.changed & !this.state.new}
        />

        <h6 style={{ textAlign: "center" }}>
          Date: {moment().format("DD-MMM-YY HH:mm")}
        </h6>
        <div
          style={{ border: "1px solid black", maxWidth: "300px" }}
          onClick={() => this.props.changeST(true, "changed")}
        >
          <SignaturePad
            beginStroke={() => console.log("Test")}
            removeBlanks
            options={{
              penColor: "blue",
              minWidth: 2,
              maxWidth: 3,
            }}
            redrawOnResize={true}
            disabled={true}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </div>
        <IconButton
          icon={<Icon icon="refresh" />}
          color="orange"
          size="xs"
          block
          onClick={() => this.clearSign()}
          disabled={!this.props.sig.changed}
        >
          Clear Signature
        </IconButton>
      </div>
    );
    if (this.props.statesig || oldsig) {
      if (!this.state.new) {
        content = (
          <div>
            <Image src={imagesrc} preview={false} width={300} />
            <IconButton onClick={() => this.setState({ new: true })}>
              Update Signature
            </IconButton>
          </div>
        );
      }
    }

    return <FlexboxGrid justify="center">{content}</FlexboxGrid>;
  }
}

export default observer(SignatureWrapper);
