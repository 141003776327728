import React, { Component } from "react";

import {
  DataSheetGrid,
  floatColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";

import "react-datasheet/lib/react-datasheet.css";
import { IconButton, Icon, FlexboxGrid, Col } from "rsuite";
import { observer } from "mobx-react";
import { PageHeader } from "antd";

class AddBulkItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      grid: [
        {
          itemname: "",
          itemserialnumber: "",
          itemproductid: "",
          cbm: 0,
          quantity: 1,
          itemlength: 0,
          itemwidth: 0,
          itemheight: 0,
          address: this.props.to._id,
        },
      ],
    };
  }
  componentDidMount() {
    this.setState({
      grid: [
        {
          itemname: "",
          itemserialnumber: "",
          itemproductid: "",
          cbm: 0,
          quantity: 1,
          itemlength: 0,
          itemwidth: 0,
          itemheight: 0,
          address: this.props.to._id,
          remarks: "",
        },
      ],
    });
  }

  postNew = async (grid) => {
    await this.props.submitAdd("Bulk", grid);
  };

  cancelChanges() {
    this.setState({
      grid: [
        {
          itemname: "",
          itemserialnumber: "",
          itemproductid: "",
          cbm: 0,
          quantity: 1,
          itemlength: 0,
          itemwidth: 0,
          itemheight: 0,

          remarks: "",
        },
      ],
    });
    this.props.onHide();
  }

  render() {
    const columns = [
      { ...keyColumn("itemname", textColumn), title: "Item Name*" },
      { ...keyColumn("itemserialnumber", textColumn), title: "Serial No.*" },
      {
        ...keyColumn("itemproductid", textColumn),
        title: "Product ID (optional)",
      },
      { ...keyColumn("itemlength", floatColumn), title: "L (cm)" },
      { ...keyColumn("itemwidth", floatColumn), title: "W (cm)" },
      { ...keyColumn("itemheight", floatColumn), title: "H (cm)" },
      { ...keyColumn("quantity", floatColumn), title: "Qty*" },
    ];

    let disabled = false;
    let grid = this.state.grid;
    for (let i = 0; i < grid.length; i++) {
      if (grid[i].quantity === null) disabled = true;
      if (grid[i].itemlength === null) disabled = true;
      if (grid[i].itemwidth === null) disabled = true;
      if (grid[i].itemwidth === "null") disabled = true;
      if (grid[i].itemheight === null) disabled = true;
      if (grid[i].itemheight === "") disabled = true;
      if (grid[i].itemname === null) disabled = true;
      if (grid[i].itemname === "") disabled = true;
      if (grid[i].itemserialnumber === null) disabled = true;
      if (grid[i].itemserialnumber === "") disabled = true;
    }

    return (
      <div>
        <PageHeader
          title="Go Back to Items List"
          onBack={() => this.cancelChanges()}
        />
        <FlexboxGrid>
          <Col md={12} sm={12} xs={24}></Col>
          <Col md={12} sm={12} xs={24}>
            <IconButton
              size="lg"
              block
              color="green"
              icon={<Icon icon="save" size="lg" />}
              disabled={disabled}
              loading={this.state.loading}
              onClick={() => this.postNew(this.state.grid)}
            >
              Save and Add
            </IconButton>
          </Col>
        </FlexboxGrid>
        <br />

        <DataSheetGrid
          value={this.state.grid}
          onChange={(e) => {
            this.setState({ grid: e });
          }}
          columns={columns}
          autoAddRow
          style={{ backgroundColor: "red" }}
          createRow={() => ({
            itemname: "",
            itemserialnumber: "",
            productid: "",
            itemlength: 0,
            itemwidth: 0,
            itemheight: 0,
            quantity: 1,
          })}
        />
      </div>
    );
  }
}

export default observer(AddBulkItems);
