import { observer } from "mobx-react";
import React, { Component } from "react";
import { Badge } from "rsuite";

class QBadge extends Component {
  render() {
    let value = this.props.value;
    if (value === 0) {
      return <div />;
    } else {
      return <Badge content={value} />;
    }
  }
}

export default observer(QBadge);
