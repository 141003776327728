import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid } from "rsuite";

import Div14 from "../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import Col2 from "../../../../../Components/Columns/Col2";
import seatypes from "../../../../../Static/Lists/jobsubtypes/imlists/seatypes";
import DatePickerSTD from "../../../../../Components/Inputs/STD/DatePickerSTD";
import newACCOrderStore from "../../../../../Stores/AccountUpdated/ACC/NewACCStore";

class AIMStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let acc = newACCOrderStore.newacc;

    //let imtypes = imtypes;

    return (
      <div>
        <Div14>Step 2: Shipment Specifications</Div14>
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "5px",
            boxShadow: "1px 1px 5px black",
          }}
        >
          <FlexboxGrid justify="center">
            <Col md={24} sm={24} xs={24}>
              <DatePickerSTD
                title="Vessel Arrival Date"
                val={acc.vesselarrival}
                label="vesselarrival"
                size="large"
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col>
            <Col2>
              <SelectInputSTD
                title="Container Type"
                val={acc.container}
                label="container"
                size="large"
                opts={seatypes}
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="# of Containers"
                val={acc.containercount}
                label="containercount"
                size="large"
                min={0}
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Packages"
                val={acc.packages}
                label="packages"
                size="large"
                min={0}
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Weight (KG)"
                val={acc.weight}
                label="weight"
                size="large"
                min={0}
                editVal={newACCOrderStore.editOrder}
              />
              <br />
            </Col2>
          </FlexboxGrid>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(AIMStep1));
