import React, { Component } from "react";

class PoorFeedback extends Component {
  render() {
    return (
      <div>
        <h5>
          It is unfortunate our service was not excellent and we do rely on our
          customer's feedback to improve our operations. Your feedback is
          apprecaited and is very valuable to us. It has been shared with our
          Management and will be reviewed to help us improve going forward.
        </h5>
        <br />
      </div>
    );
  }
}

export default PoorFeedback;
