import { Tag, PageHeader, Alert, Badge } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, IconButton } from "rsuite";
import newACCOrderStore from "../../../../Stores/AccountUpdated/ACC/NewACCStore";
import userStore from "../../../../Stores/UserStore";
import OrderSummaryTable from "../Modal/Supporting/OrderSummaryTable";

class SingleACCOrderHeader extends Component {
  render() {
    let acc = newACCOrderStore.oldacc;
    let account = newACCOrderStore.account;

    let tagcolor = "orange";
    if (acc.status === "Requested") tagcolor = "orange";
    if (acc.status === "Booked") tagcolor = "green";
    if (acc.status === "In Transit") tagcolor = "blue";
    if (acc.status === "Completed") tagcolor = "darkgreen";
    if (acc.status === "Cancelled") tagcolor = "darkred";

    return (
      <div>
        <PageHeader
          ghost={true}
          onBack={() => this.props.history.push(`/ac/dashboard/${account._id}`)}
          title={`Custom Clearance Account Order #${acc.accOrderNumId} for ${account.name}`}
          tags={
            <Tag color={tagcolor} key={1}>
              <Badge
                status={
                  acc.status === "Cancelled" || acc.status === "Completed"
                    ? "default"
                    : "processing"
                }
                style={{
                  color:
                    acc.status === "Cancelled" || acc.status === "Completed"
                      ? "white"
                      : "black",
                }}
                color={
                  acc.status === "Cancelled" || acc.status === "Completed"
                    ? "white"
                    : tagcolor
                }
                text={acc.status}
              />
            </Tag>
          }
          extra={[
            <IconButton
              size="sm"
              icon={<Icon icon="web" />}
              appearance="ghost"
              color="green"
              onClick={() =>
                this.props.history.push(`/ac/dashboard/${account._id}`)
              }
            >
              Company Dash
            </IconButton>,
            <IconButton
              size="sm"
              icon={<Icon icon="refresh" />}
              appearance="primary"
              onClick={() => newACCOrderStore.getSingleACOrder(false, true)}
            >
              Refresh Data
            </IconButton>,
          ]}
        >
          <OrderSummaryTable acc={acc} account={account} hidetitle />
        </PageHeader>
        {newACCOrderStore.stopedit && (
          <Alert
            message={
              acc.status === "Booked" ||
              acc.status === "In Transit" ||
              acc.status === "Completed"
                ? `The Order is already ${acc.status}. For order modifications please contact our office.`
                : !userStore.user.staffaccount & !userStore.user.comprequest
                ? "Kindly note editing access has not been provided, please contact our team to provide request editing access."
                : `For order modifications please contact our office.`
            }
            type="warning"
            showIcon
            closable
            style={{ paddingLeft: "2%", paddingRight: "2%" }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(SingleACCOrderHeader));
