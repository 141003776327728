import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { InputAdornment } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

class UserNameInput extends Component {
  render() {
    let data = this.props.data;
    let err = this.props.err;
    let errmessage = this.props.errmessage;
    let type = this.props.type;
    let label = this.props.label;
    let disabled = this.props.disabled;

    let autofocus = this.props.autofocus;

    return (
      <div>
        <TextField
          error={err}
          helperText={err && errmessage}
          variant="standard"
          margin="normal"
          fullWidth
          id={type}
          label={label}
          name={type}
          autoComplete={type}
          disabled={disabled}
          autoFocus={autofocus}
          value={data}
          onChange={(e) => this.props.action(type, e.target.value)}
          style={{ background: "rgb(255,255,255,0)" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon
                  style={{
                    color: data.length >= 3 ? "black" : "#a3a3a3",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

export default withRouter(observer(UserNameInput));
