import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import { Descriptions } from "antd";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";

class AIMStep4 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let aim = newAimStore.newaim;
    let data1 = [
      { title: "Shipping Method", val: aim.shippingmethod },
      { title: "Direction", val: aim.direction },
      { title: "Type", val: aim.subtype },
      { title: "Commodity", val: aim.commodity },
      { title: "From", val: aim.shipfrom },
      { title: "To", val: aim.shipto },
    ];
    let data2 = [
      { title: "Length (M)", val: aim.length },
      { title: "Width (M)", val: aim.width },
      { title: "Height (M)", val: aim.height },
      { title: "Chargeable Weight (KG)", val: aim.weight },
    ];
    let data3 = [
      { title: "Shipping Line", val: aim.scac },
      { title: "Bill of Lading", val: aim.blnumber },
      { title: "Container Info", val: aim.subtype },
    ];
    return (
      <div>
        <Div14>Order Confirmation:</Div14>
        <div
          style={{
            marginLeft: "5%",
            marginRight: "5%",
            boxShadow: "0px 0px 5px black",
            borderRadius: "5px",
          }}
        >
          <Div14>Initial Details</Div14>
          <Descriptions bordered size="small">
            {data1.map((val, index) => (
              <Descriptions.Item
                key={index}
                label={val.title}
                span={3}
                labelStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  width: "30%",
                }}
              >
                {val.val}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Div14>Shipping Specifications</Div14>
          <Descriptions bordered size="small">
            {data2.map((val, index) => (
              <Descriptions.Item
                key={index}
                label={val.title}
                span={3}
                labelStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  width: "30%",
                }}
              >
                {val.val}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Div14>Tracking Information</Div14>
          <Descriptions bordered size="small">
            {data3.map((val, index) => (
              <Descriptions.Item
                key={index}
                label={val.title}
                span={3}
                labelStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  width: "30%",
                }}
              >
                {val.val}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(AIMStep4));
