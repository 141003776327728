import { observer } from "mobx-react";
import React, { Component } from "react";
import { CardActionArea, Card } from "@material-ui/core";
import moment from "moment";
import singleJobStore from "../../../../../Stores/SingleJobStore";
import { StatusColorCalc } from "../../../../../Functions/Colors/StatusColorCalc";

class ScrollingCard extends Component {
  render() {
    let selected = this.props.selected;
    let index = this.props.index;
    let job = this.props.job;
    let type = job.jobtype;

    if (singleJobStore.hideCancelled && job.status === "Cancelled") {
      return <div />;
    }

    let shortNumId = `sjNumId`;
    let numId = job[shortNumId];

    let jobColor = StatusColorCalc(job.status);

    let minJobDate = null;
    if (job.jobdate.length === 1) {
      minJobDate = `(${moment(job.jobdate[0].date).format("DD-MMM")})`;
    } else if (job.jobdate.length > 1) {
      minJobDate = `(${moment(job.jobdate[0].date).format(
        "DD-MMM"
      )} to ${moment(job.jobdate[job.jobdate.length - 1].date).format(
        "DD-MMM"
      )})`;
    }

    return (
      <Card
        style={{
          margin: "5px",
          width: "130px",
          borderRadius: 8,
          border: "3px",
          borderStyle: selected ? "dashed" : "",
          textAlign: "center",
          boxShadow: "1px 1px 5px black ",
        }}
      >
        <CardActionArea onClick={() => this.props.selectCard(index)}>
          <div
            style={{
              padding: "2%",
              border: "1px",
              borderStyle: selected ? "solid" : "",
              backgroundColor: jobColor,
            }}
          >
            <h6>
              {type}-{numId}
            </h6>
            <p style={{ fontSize: 12 }}>
              <i>{job.status}</i>
              <b>
                <br />
                {job.subtype}
              </b>
              <br />
              {type !== "CC" && `${job.cbm} CBM / `}
              {job.lastprice} KD
              <br />
              {minJobDate && minJobDate}
            </p>
          </div>
        </CardActionArea>
      </Card>
    );
  }
}

export default observer(ScrollingCard);
