import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Button, FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import hrAttendanceStore from "../../../../Stores/HR/HRAttendanceStore";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
} from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";

import { ColumnChooser, Item, Toolbar } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import Div14 from "../../../../Components/Dividers/Div14";

import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";

import MonthPickerSTU from "../../../../Components/Inputs/Updatable/MonthPickerSTU";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import { Alert } from "antd";

const instance = axios.create({ baseURL: baseURL });

class StaffAttendanceMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showlock: false,
      loading: false,
      statusloading: false,
    };
    this.SingleDate = this.SingleDate.bind(this);
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.updateAttTime = this.updateAttTime.bind(this);
    this.updateMonthLock = this.updateMonthLock.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  onHide() {
    this.setState({ show: false, showlock: false });
  }
  componentDidMount() {
    hrAttendanceStore.getStaffAttendance();
  }
  updateAttTime = async () => {
    let eatt = hrAttendanceStore.esingleatt;
    this.setState({ loading: true });
    let data = {
      signInTime: moment(eatt.signInTime).format("HH:mm"),
      signOutTime: moment(eatt.signOutTime).format("HH:mm"),
      status: eatt.status,
    };

    if (data.signInTime === "0:00") data.signInTime = "";
    if (data.signOutTime === "0:00") data.signOutTime = "";

    let url = `/hr/attendanceinout/editsignin/${eatt._id}`;
    if (eatt._id === "Missing") {
      url = `/hr/attendanceinout/newmanualattendance/${eatt.staffid}`;
      data.date = moment.utc(eatt.date).format("DD-MMM-YY");
    }

    return instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((orders) => {
        this.setState({ loading: false });
        hrAttendanceStore.getStaffAttendance(true);
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `Error Updating Attendance.`,
        });
      });
  };
  updateMonthLock = async (newstat) => {
    let eatt = hrAttendanceStore.esingleatt;
    this.setState({ loading: true });
    let status = "Pending Approval";
    if (newstat) status = newstat;
    let data = { date: moment.utc(hrAttendanceStore.date), status: status };

    let url = `/hr/attendanceinout/newlockedmonth/${eatt._id}`;

    return instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((orders) => {
        this.setState({ loading: false });
        hrAttendanceStore.getStaffAttendance(true);
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `Error Updating Attendance.`,
        });
      });
  };

  SingleDate(cellData) {
    let colind = cellData.columnIndex - 1;
    if (colind < 10) {
      colind = `0${colind}`;
    }

    colind = `${colind}`;
    let color = "red";
    let status = "";
    let worktime = "";

    if (cellData.data) {
      if (cellData.data[colind]) {
        if (cellData.data[colind].status) {
          status = cellData.data[colind].status;
          if (status === "Present") color = "green";
          if (status === "Holiday") color = "yellow";
          if (status === "Empty") color = "grey";
          if (status === "Absent") color = "red";
          if (status === "Medical") color = "red";
          if (status === "Late") color = "orange";
          if (status === "No Out") color = "orange";
          worktime = cellData.data[colind].worktime;
        }
      }
    }

    return (
      <>
        <Button
          color={color}
          size="xs"
          onClick={() => {
            hrAttendanceStore.selectAttendance(
              cellData.data[colind],
              cellData.data.name,
              cellData.columnIndex,
              cellData.data.staff._id
            );
            this.setState({ show: true });
          }}
        >
          {status === "Present" ? worktime : status}
        </Button>
      </>
    );
  }
  render() {
    let singleatt = hrAttendanceStore.singleattendance;
    let esingleatt = hrAttendanceStore.esingleatt;
    let disableinput = hrAttendanceStore.lockedMonth;
    let disabled = disableinput;

    if (esingleatt.status === "") {
      disabled = true;
    }
    let datetext = moment.utc(hrAttendanceStore.date).format("MMM-YY");

    let disableapproval = false;
    if (moment().isBefore(moment(hrAttendanceStore.date).endOf("month"))) {
      disableapproval = true;
    }
    let adminoveride = false;
    if (hrAttendanceStore.lockedMonth) {
      if (userStore.user.admin) adminoveride = true;
    }

    return (
      <LoadBack loading={hrAttendanceStore.loading.attendance}>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="Edit Attendance"
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Save"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateAttTime()}
              disabled2={disabled}
              loading2={this.state.loading}
              loading1={this.state.loading}
            />
          }
        >
          <h5 style={{ textAlign: "center" }}>
            {moment.utc(esingleatt.date).format("DD-MMM-YY")}
          </h5>
          <Div14>Status:</Div14>
          <FlexboxGrid justify="center">
            <Col2>
              <TextInputSTD
                title="Staff Name"
                val={singleatt.staffname}
                label={"staffname"}
                size="lg"
                editVal={hrAttendanceStore.editSingleAttendance}
              />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Status"
                val={esingleatt.status}
                label={"status"}
                size="large"
                editVal={hrAttendanceStore.editSingleAttendance}
                opts={[
                  "Present",
                  "Late",
                  "Off",
                  "Absent",
                  "Holiday",
                  "Medical",
                ]}
              />
            </Col2>
          </FlexboxGrid>
          <Div14>Sign In and Sign Out:</Div14>

          <FlexboxGrid>
            <Col2>
              {!disableinput && (
                <FlexboxGrid justify="space-around">
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(7, "hours"),
                        "signInTime"
                      )
                    }
                  >
                    7 AM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(8, "hours"),
                        "signInTime"
                      )
                    }
                  >
                    8 AM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(9, "hours"),
                        "signInTime"
                      )
                    }
                  >
                    9 AM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(10, "hours"),
                        "signInTime"
                      )
                    }
                  >
                    10 AM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(11, "hours"),
                        "signInTime"
                      )
                    }
                  >
                    11 AM
                  </Button>
                </FlexboxGrid>
              )}
              <DatePickerSTD
                title="Sign In"
                format={"HH:mm"}
                picker="time"
                val={esingleatt.signInTime}
                label={"signInTime"}
                size="large"
                showMinute={true}
                editVal={hrAttendanceStore.editSingleAttendance}
              />
            </Col2>
            <Col2>
              {!disableinput && (
                <FlexboxGrid justify="space-around">
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(14, "hours"),
                        "signOutTime"
                      )
                    }
                  >
                    2 PM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(15, "hours"),
                        "signOutTime"
                      )
                    }
                  >
                    3 PM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(16, "hours"),
                        "signOutTime"
                      )
                    }
                  >
                    4 PM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(17, "hours"),
                        "signOutTime"
                      )
                    }
                  >
                    5 PM
                  </Button>
                  <Button
                    onClick={() =>
                      hrAttendanceStore.editSingleAttendance(
                        moment
                          .utc(esingleatt.date)
                          .startOf("day")
                          .add(18, "hours"),
                        "signOutTime"
                      )
                    }
                  >
                    6 PM
                  </Button>
                </FlexboxGrid>
              )}
              <DatePickerSTD
                title="Sign Out"
                format={"HH:mm"}
                picker="time"
                val={esingleatt.signOutTime}
                label={"signOutTime"}
                size="large"
                showMinute={true}
                editVal={hrAttendanceStore.editSingleAttendance}
              />
            </Col2>
          </FlexboxGrid>
          <hr />
          <h4 style={{ textAlign: "center" }}>
            Calculated Worktime: {hrAttendanceStore.worktime}{" "}
            {hrAttendanceStore.worktime === "No Sign Out" ||
            hrAttendanceStore.worktime === "No Sign In"
              ? ""
              : "hours"}
          </h4>
          {hrAttendanceStore.lockedMonth && (
            <Alert
              message="This month is Locked. Please contact IT to reopen."
              type="warning"
              showIcon
            />
          )}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showlock}
          onHide={this.onHide}
          title="Send Month For Approval"
          footer={
            <ModalFooter
              icon={["close", "lock"]}
              color={["red", "yellow"]}
              app={["ghost", "default"]}
              text={["Cancel", "Send for Approval"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateMonthLock()}
              loading2={this.state.loading}
              loading1={this.state.loading}
            />
          }
        >
          <h3>
            Are you sure you want to Send the Attendance Month of{" "}
            {moment(hrAttendanceStore.date).format("MMMM YYYY")} for Approval?
            You will no longer be able to edit the attendance for this month.
          </h3>
        </UnifiedModal>
        <FlexboxGrid justify="center">
          <Col2>
            <MonthPickerSTU
              val={hrAttendanceStore.edate}
              org={hrAttendanceStore.date}
              label="startday"
              format={"MMM-YYYY"}
              title="Select Month"
              load={hrAttendanceStore.loading.attendance}
              editVal={hrAttendanceStore.changeDate}
              updateVal={() =>
                hrAttendanceStore.changeOrgDate(hrAttendanceStore.edate)
              }
            />
          </Col2>
        </FlexboxGrid>
        <Div14>
          {moment.utc(hrAttendanceStore.date).format("MMMM YYYY")} Attendance
        </Div14>
        {hrAttendanceStore.lockedMonth && (
          <Alert
            message="This month is Locked. Please contact IT to reopen."
            type="warning"
            showIcon
          />
        )}
        <DataGrid
          dataSource={hrAttendanceStore.attendance}
          keyExpr="name"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          paging={false}
          hoverStateEnabled={true}
          groupPanel={true}
          onSelectionChanged={this.onSelect}
        >
          <Selection mode="single" />

          <GroupPanel visible={true} />
          <FilterRow visible={true} applyFilter />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column
            dataField="name"
            caption="Name"
            alignment="center"
            minWidth={80}
            fixed={true}
          />
          <Column
            dataField="staff.title"
            caption="Title"
            alignment="center"
            minWidth={80}
            fixed={true}
            groupIndex={0}
          />
          {hrAttendanceStore.dateList.map((date, index) => (
            <Column
              key={index}
              dataField={`${[date]}.statusshort`}
              caption={`${moment(date + "-" + datetext, "DD-MMM-YY").format(
                "ddd"
              )} ${date}`}
              alignment="center"
              minWidth={60}
              cellRender={this.SingleDate}
            />
          ))}
          <Column
            dataField="empty"
            caption="Empty"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="present"
            caption="Present"
            alignment="center"
            minWidth={80}
          />
          <Toolbar>
            <Item name="Refresh" location="after">
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => hrAttendanceStore.getStaffAttendance(true)}
              >
                Refresh
              </IconButton>
            </Item>
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
          </Toolbar>
        </DataGrid>
        <hr />
        <FlexboxGrid justify="center">
          {!hrAttendanceStore.lockedMonth && (
            <Col2>
              <IconButton
                block
                color="yellow"
                icon={<Icon icon="lock" />}
                disabled={disableapproval}
                onClick={() => this.setState({ showlock: true })}
              >
                Send Month for Approval
              </IconButton>
            </Col2>
          )}
          {adminoveride && (
            <Col2>
              <IconButton
                block
                color="red"
                icon={<Icon icon="lock" />}
                disabled={disableapproval}
                loading={this.state.loading}
                onClick={() => this.updateMonthLock("Open")}
              >
                Change Status to OPEN
              </IconButton>
            </Col2>
          )}
        </FlexboxGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(StaffAttendanceMain));
