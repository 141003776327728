import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import React, { Component } from "react";
import Div14 from "../../../Components/Dividers/Div14";
import StarRatingSTD from "../../../Components/Inputs/STD/StarRatingSTD";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
  Placeholder,
} from "rsuite";
import TextAreaInputSTD from "../../../Components/Inputs/STD/TextAreaInputSTD";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";

import FeedbackModal from "./FeedbackModal/FeedbackModal";
import feedbackStore from "../../../Stores/AdditionalStores/FeedbackStore";
import { Empty } from "antd";

const { Paragraph } = Placeholder;

const instance = axios.create({ baseURL: baseURL });

class DeliveryFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      remarks: "",
      type: "Delivery",
      loadig: true,
      show: false,
    };
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    feedbackStore.getDelSingleFeedback(this.props.match.params.delid);
  }

  onHide() {
    this.setState({ rating: 0, remarks: "", loading: false, show: false });
    this.props.history.push("/");
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }

  postFeedback = async () => {
    this.setState({ loading: true });
    let data = {
      rating: this.state.rating,
      remarks: this.state.remarks,
      type: this.state.type,
      del: this.props.match.params.delid,
    };

    return instance
      .post(`/track/feedback/new`, data)
      .then((res) => {
        this.setState({ rating: 0, remarks: "", loading: false, show: false });
        Notification["success"]({
          title: `Feedback Created.`,
        });
        feedbackStore.getDelSingleFeedback(this.props.match.params.delid, true);
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    if (feedbackStore.loading) {
      return (
        <div>
          <Div14>Delivery Feedback</Div14>

          <Paragraph active rows={7} />
        </div>
      );
    } else if (feedbackStore.delsinglefeedback.type === "NoDelivery") {
      return (
        <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <Div14>Delivery Feedback</Div14>
          <br />
          <Empty description="No Delivery Found" />
          <br />
          <FlexboxGrid justify="center">
            <IconButton
              block
              style={{ maxWidth: "300px" }}
              icon={<Icon icon="home" />}
              color="blue"
              onClick={() => this.props.history.push("/")}
            >
              Back to Home Page
            </IconButton>
          </FlexboxGrid>
        </div>
      );
    } else if (feedbackStore.delsinglefeedback._id !== "") {
      return (
        <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <Div14>Delivery Feedback Submitted</Div14>
          <FeedbackModal
            rating={feedbackStore.delsinglefeedback.rating}
            remarks={feedbackStore.delsinglefeedback.remarks}
          />
        </div>
      );
    }

    return (
      <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <Div14>Delivery Feedback</Div14>
        <h4 style={{ textAlign: "center", fontStyle: "italic" }}>
          How was the delivery?
        </h4>
        <br />
        <FlexboxGrid justify="center">
          <StarRatingSTD
            val={this.state.rating}
            editVal={this.editVal}
            label="rating"
          />
        </FlexboxGrid>
        <br />
        <div style={{ minHeight: 200 }}>
          {this.state.rating > 0 && (
            <FlexboxGrid
              justify="center"
              style={{ paddingLeft: "1%", paddingRight: "1%" }}
            >
              <TextAreaInputSTD
                title="Feedback (Optional)"
                val={this.state.remarks}
                label="remarks"
                size="large"
                editVal={this.editVal}
              />
            </FlexboxGrid>
          )}
        </div>
        <br />
        <FlexboxGrid justify="center">
          <IconButton
            block
            style={{ maxWidth: "600px" }}
            icon={<Icon icon="save" />}
            disabled={this.state.rating === 0}
            color="green"
            loading={this.state.loading}
            onClick={() => this.postFeedback()}
          >
            Save Feedback
          </IconButton>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(DeliveryFeedback));
