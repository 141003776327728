import { observer } from "mobx-react";
import React, { Component } from "react";
import { Descriptions } from "antd";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import moment from "moment";

class CostSummaryTable extends Component {
  render() {
    let order = this.props.order;

    

    let greycolor = "#cccccc";
    let lightcolor = "#ebebeb";
    let options = order.orderoptions;
    let addchgs = order.addcharge;

    let totalcbm = order.totalcbm;

    let chgmap;
    let optmap;
    let addmap;
    let locmap;

    let allcharges = [];
    let loccharges = [];
    let srv = order.services;

    if (srv.stincost > 0) {
      allcharges = [
        ...allcharges,
        {
          title: "Storage In Cost Per CBM",
          value: srv.stincost,
          unit: "KD / CBM",
        },
      ];
    }
    if (srv.stoutcost > 0) {
      allcharges = [
        ...allcharges,
        {
          title: "Storage Out Cost Per CBM",
          value: srv.stoutcost,
          unit: "KD / CBM",
        },
      ];
    }
    if (srv.timeprice > 0) {
      allcharges = [
        ...allcharges,
        {
          title: srv.timetype,
          value: srv.timeprice,
          unit: "KD",
        },
      ];
    }
    if (srv.weekdayprice > 0) {
      allcharges = [
        ...allcharges,
        {
          title: srv.weekday,
          value: srv.weekdayprice,
          unit: "KD",
        },
      ];
    }

    let toadd = order.toaddress;
    if (toadd.length > 0) {
      for (let i = 0; i < toadd.length; i++) {
        if (toadd[i].zonecost > 0) {
          loccharges = [
            ...loccharges,
            {
              title: `Location ${i + 1}: Zone ${toadd[i].zonetype} (${
                toadd[i].city
              }) Delivery Charge`,
              value: toadd[i].zonecost,
              unit: "KD",
            },
          ];
        }
        if (toadd[i].ngfchg > 0) {
          loccharges = [
            ...loccharges,
            {
              title: `Location ${i + 1}: Non-Ground Floor Charge`,
              value: toadd[i].ngfchg,
              unit: "KD",
            },
          ];
        }
        if (i > 0) {
          if (srv.tripmultiplier > 0) {
            allcharges = [
              ...allcharges,
              {
                title: `Multiple Location Charge (${
                  PRKD(1000 * Parsed(srv.tripmultiplier)) / 10
                }%): Location ${i + 1}`,
                value: Parsed(srv.weekdayprice) * Parsed(srv.tripmultiplier),
                unit: "KD",
              },
            ];
          }
        }
      }

      locmap = loccharges.map((loc, index) => (
        <Descriptions.Item
          label={`${PRKD(loc.value)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {loc.title}
        </Descriptions.Item>
      ));
    }

    if (allcharges.length > 0) {
      chgmap = allcharges.map((chg, index) => (
        <Descriptions.Item
          label={`${
            chg.unit === "KD"
              ? PRKD(chg.value)
              : PRKD(Parsed(chg.value) * Parsed(totalcbm))
          } KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {chg.title} {chg.unit === "KD" ? "" : `(${PRKD(chg.value)} KD/CBM)`}
        </Descriptions.Item>
      ));
    }

    if (options.length > 0) {
      optmap = options.map((opt, index) => (
        <Descriptions.Item
          label={`${
            opt.type === "KD"
              ? PRKD(opt.price)
              : PRKD(Parsed(opt.price) * Parsed(totalcbm))
          } 
          KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {opt.title} {opt.type === "KD" ? "" : `(${PRKD(opt.price)} KD/CBM)`}
        </Descriptions.Item>
      ));
    }
    if (addchgs.length > 0) {
      addmap = addchgs.map((add, index) => (
        <Descriptions.Item
          label={`${PRKD(add.price)} KD`}
          key={index}
          span={24}
          labelStyle={{
            fontSize: 12,
            textAlign: "right",
            fontStyle: "italic",
            padding: 3,
          }}
          contentStyle={{ fontSize: 12, fontStyle: "italic", padding: 3 }}
        >
          {add.name}
        </Descriptions.Item>
      ));
    }

    return (
      <div>
        {order.status === "Cancelled" ? (
          <>
            <Descriptions title="Cost Breakdown" bordered size="small">
              <Descriptions.Item
                label={`${PRKD(order.services.canccost)} KD`}
                span={24}
                labelStyle={{
                  fontWeight: "bold",
                  backgroundColor: greycolor,
                  textAlign: "right",
                }}
                contentStyle={{
                  fontWeight: "bold",
                  backgroundColor: greycolor,
                }}
              >
                {`Cancellation Cost ${moment(order.services.canctime).format(
                  "DD-MMM-YY HH:mm A"
                )}`}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : (
          <Descriptions title={false} bordered size="small">
            <Descriptions.Item
              label={`${PRKD(order.charges.totaljob)} KD`}
              span={24}
              labelStyle={{
                fontWeight: "bold",
                backgroundColor: greycolor,
                width: "30%",
                textAlign: "right",
              }}
              contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
            >
              Total Job Cost
            </Descriptions.Item>
            <Descriptions.Item
              label={`${PRKD(order.charges.main)} KD`}
              span={24}
              labelStyle={{
                fontWeight: "bold",
                backgroundColor: lightcolor,
                textAlign: "right",
              }}
              contentStyle={{ fontWeight: "bold", backgroundColor: lightcolor }}
            >
              Main Service
            </Descriptions.Item>
            {chgmap}
            {toadd.length > 0 && (
              <>
                <Descriptions.Item
                  label={`${PRKD(order.charges.location)} KD`}
                  span={24}
                  labelStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                    textAlign: "right",
                  }}
                  contentStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                  }}
                >
                  Location Charges
                </Descriptions.Item>
                {locmap}
              </>
            )}
            {order.charges.option > 0 && (
              <>
                <Descriptions.Item
                  label={`${PRKD(order.charges.option)} KD`}
                  span={24}
                  labelStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                    textAlign: "right",
                  }}
                  contentStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                  }}
                >
                  Selected Options
                </Descriptions.Item>
                {optmap}
              </>
            )}
            {order.charges.additional > 0 && (
              <>
                <Descriptions.Item
                  label={`${PRKD(order.charges.additional)} KD`}
                  span={24}
                  labelStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                    textAlign: "right",
                  }}
                  contentStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                  }}
                >
                  Additional Charges
                </Descriptions.Item>
                {addmap}
              </>
            )}
            {order.charges.discount > 0 && (
              <>
                <Descriptions.Item
                  label={`${-1 * PRKD(order.charges.discount)} KD`}
                  span={24}
                  labelStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                    textAlign: "right",
                  }}
                  contentStyle={{
                    fontWeight: "bold",
                    backgroundColor: lightcolor,
                  }}
                >
                  Discount
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item
              label={`${PRKD(order.charges.totaljob)} KD`}
              span={24}
              labelStyle={{
                fontWeight: "bold",
                backgroundColor: greycolor,
                textAlign: "right",
              }}
              contentStyle={{ fontWeight: "bold", backgroundColor: greycolor }}
            >
              Total Job Cost
            </Descriptions.Item>
          </Descriptions>
        )}
        <hr />
      </div>
    );
  }
}

export default observer(CostSummaryTable);
