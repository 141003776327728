import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Col3 from "../../../../Components/Columns/Col3";
import Div14 from "../../../../Components/Dividers/Div14";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import NewDelOptions from "./DelOptions/NewDelOptions";

import TimingSection from "./TimingSection";

class NewDelCont extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.editLoc = this.editLoc.bind(this);
  }
  editLoc(loc) {
    newAccountStore.editStorContEMLOC(loc);
  }
  render() {
    let data = newAccountStore.account;
    let delCont = newAccountStore.delCont;

    let delpricing;
    let deladditional;
    let delmain;
    let dailydelsandzones;

    if (data.adel) {
      dailydelsandzones = (
        <div>
          <FlexboxGrid>
            <Col2>
              <Div14>Day Delivery Rate (KD):</Div14>
              <NumbInputSTD
                title="Sunday"
                val={delCont.pricepertrip.sun}
                label="sun"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
              <NumbInputSTD
                title="Monday"
                val={delCont.pricepertrip.mon}
                label="mon"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
              <NumbInputSTD
                title="Tuesday"
                val={delCont.pricepertrip.tue}
                label="tue"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
              <NumbInputSTD
                title="Wednesday"
                val={delCont.pricepertrip.wed}
                label="wed"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
              <NumbInputSTD
                title="Thursday"
                val={delCont.pricepertrip.thu}
                label="thu"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
              <NumbInputSTD
                title="Friday"
                val={delCont.pricepertrip.fri}
                label="fri"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
              <NumbInputSTD
                title="Saturday"
                val={delCont.pricepertrip.sat}
                label="sat"
                size="large"
                editVal={newAccountStore.editDelDay}
              />
            </Col2>
            <Col2>
              <Div14>Zone Additional (KD):</Div14>
              <NumbInputSTD
                title="Zone 1"
                val={delCont.zoneadd.z1}
                label="z1"
                size="large"
                editVal={newAccountStore.editDelZone}
              />
              <NumbInputSTD
                title="Zone 2"
                val={delCont.zoneadd.z2}
                label="z2"
                size="large"
                editVal={newAccountStore.editDelZone}
              />
              <NumbInputSTD
                title="Zone 3"
                val={delCont.zoneadd.z3}
                label="z3"
                size="large"
                editVal={newAccountStore.editDelZone}
              />
              <NumbInputSTD
                title="Zone 4"
                val={delCont.zoneadd.z4}
                label="z4"
                size="large"
                editVal={newAccountStore.editDelZone}
              />
              <NumbInputSTD
                title="Zone 5"
                val={delCont.zoneadd.z5}
                label="z5"
                size="large"
                editVal={newAccountStore.editDelZone}
              />
              <NumbInputSTD
                title="Multi-Dropoff Multiplier"
                val={delCont.dropoffmult}
                label="dropoffmult"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <NumbInputSTD
                title="Non-Ground Floor Charge (KD)"
                val={delCont.nongroundfloorprice}
                label="nongroundfloorprice"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
            </Col2>
          </FlexboxGrid>
        </div>
      );

      if (delCont.maintype === "Dedicated Staff") {
        delpricing = (
          <FlexboxGrid justify="center">
            <Col3>
              <NumbInputSTD
                title="Weeekly Rate (KD)"
                val={delCont.fixedweekly}
                label="fixedweekly"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTD
                title="Staff Count"
                val={delCont.staffcount}
                label="staffcount"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTD
                title="Truck Count"
                val={delCont.carcount}
                label="carcount"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
          </FlexboxGrid>
        );
      } else if (delCont.maintype === "Dedicated Staff - Monthly") {
        delpricing = (
          <FlexboxGrid justify="center">
            <Col3>
              <NumbInputSTD
                title="Monthly Rate (KD)"
                val={delCont.fixedweekly}
                label="fixedweekly"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTD
                title="Staff Count"
                val={delCont.staffcount}
                label="staffcount"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTD
                title="Truck Count"
                val={delCont.carcount}
                label="carcount"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
          </FlexboxGrid>
        );
      } else if (delCont.maintype === "Daily Per Truck") {
        delpricing = (
          <div>
            <FlexboxGrid justify="center">
              <Col2>
                <NumbInputSTD
                  title="Price Per Truck"
                  val={delCont.pricepertruck}
                  label="pricepertruck"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                />
                <br />
              </Col2>
              <Col2>
                <NumbInputSTD
                  title="Min Daily Truck"
                  val={delCont.carcount}
                  label="carcount"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                />
                <br />
              </Col2>
            </FlexboxGrid>
          </div>
        );
      } else if (delCont.maintype === "Fixed Per Type") {
        delpricing = (
          <div>
            <NewDelOptions />
            {dailydelsandzones}
          </div>
        );
      } else if (delCont.maintype === "Fixed Per Trip") {
        delpricing = (
          <div>
            <FlexboxGrid justify="center">
              <Col2>
                <NumbInputSTD
                  title="Free Weekly Trips"
                  val={delCont.freeweeklytrips}
                  label="freeweeklytrips"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                />
                <br />
              </Col2>
              <Col2>
                <NumbInputSTD
                  title="Max CBM Per Trip"
                  val={delCont.maxcbmpertrip}
                  label="maxcbmpertrip"
                  size="large"
                  editVal={newAccountStore.editDelCont}
                />
                <br />
              </Col2>
            </FlexboxGrid>
            {dailydelsandzones}
          </div>
        );
      }

      deladditional = (
        <div>
          <Div14>Additional Details:</Div14>
          <FlexboxGrid justify="center">
            <Col3>
              <NumbInputSTD
                title="Time to Request (hrs)"
                val={delCont.timetoreq}
                label="timetoreq"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <NumbInputSTD
                title="After Hour Fees (KD)"
                val={delCont.afterhourfee}
                label="afterhourfee"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
            </Col3>
            <Col3>
              <NumbInputSTD
                title="Express Request (hrs)"
                val={delCont.expresstime}
                label="expresstime"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <NumbInputSTD
                title="Express Fees (KD)"
                val={delCont.expressfee}
                label="expressfee"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>
            <Col3>
              <NumbInputSTD
                title="Hours to Cancel (hrs)"
                val={delCont.cancelhours}
                label="cancelhours"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <NumbInputSTD
                title="Cancellation Fees (KD)"
                val={delCont.cancelprice}
                label="cancelprice"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
              <br />
            </Col3>

            <Col md={24} sm={24} xs={24}>
              <TextAreaInputSTD
                title="Delivery Contract Remarks"
                val={delCont.remarks}
                label="remarks"
                size="large"
                editVal={newAccountStore.editDelCont}
              />
            </Col>
          </FlexboxGrid>
        </div>
      );

      delmain = (
        <div>
          <Div14>Main Delivery Contract Type:</Div14>
          <SelectInputSTD
            title="Main Type"
            val={delCont.maintype}
            label="maintype"
            size="large"
            editVal={newAccountStore.editDelCont}
            opts={[
              "Dedicated Staff",
              "Dedicated Staff - Monthly",
              "Daily Per Truck",
              "Fixed Per Type",
              "Fixed Per Trip",
            ]}
          />
          <Div14>Pricing Method:</Div14>
          {delpricing}
          {deladditional}
          <TimingSection />
        </div>
      );
    }

    return (
      <div
        style={{
          padding: "2%",
          border: "1px solid black",
          borderRadius: "4px",
        }}
      >
        <Div14>Delivery Contract</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <IconButton
              icon={<Icon icon="cube" />}
              appearance={data.adel ? "primary" : "ghost"}
              color="green"
              onClick={() => {
                if (data.adel) {
                  newAccountStore.editAccount(false, "ast");
                }
                newAccountStore.editAccount(!data.adel, "adel");
              }}
              block
            >
              {data.adel ? "Remove" : "Add"} Delivery Contract
            </IconButton>
          </Col2>
        </FlexboxGrid>
        {data.adel && delmain}
      </div>
    );
  }
}

export default observer(NewDelCont);
