import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../Components/Dividers/Div14";
import hrStore from "../../../../../Stores/HR/HRStore";
import StaffRow from "./StaffRow";

class SalaryGrid extends Component {
  render() {
    let finalgroups = hrStore.salaries.map((st) => st.type);
    let groups = [...new Set(finalgroups)];

    let staffdata = [];
    let singlegroup = {};
    for (let i = 0; i < groups.length; i++) {
      singlegroup = {
        type: groups[i],
        staff: hrStore.salaries.filter(
          (st) => (st.type === groups[i]) & st.active
        ),
      };
      staffdata.push(singlegroup);
    }
    singlegroup = {
      type: "Old Staff",
      staff: hrStore.salaries.filter((st) => !st.active),
    };
    staffdata.push(singlegroup);

    let content = staffdata.map((group, index) => (
      <div key={index}>
        <h6>{group.type}: </h6>
        {group.staff.map((st, stind) => (
          <StaffRow
            st={st}
            key={stind}
            startNewPayment={this.props.startNewPayment}
          />
        ))}
        <br />
      </div>
    ));

    return (
      <div>
        <Div14>Staff Salary Summaries</Div14>
        {content}
      </div>
    );
  }
}

export default observer(SalaryGrid);
