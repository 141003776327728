import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import signature from "../../Pictures/signature.png";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol15white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol30white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableCol15blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "15%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
});

class ChargeRows extends Component {
  render() {
    let inv = this.props.inv;

    let newchgs = inv.charges;

    let charges = newchgs.map((chg, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableCol15white}>
          {moment(chg.startday).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol15white}>
          {moment(chg.endday).format("DD-MMM-YY")}
        </Text>
        <Text style={styles.tableCol20white}>
          {PRKD(chg.storchg) > 0 ? PRKD(chg.storchg) : "-"}
        </Text>
        <Text style={styles.tableCol20white}>
          {PRKD(chg.deltotalchg) > 0 ? PRKD(chg.deltotalchg) : "-"}
        </Text>
        <Text style={styles.tableCol30white}>
          {PRKD(Parsed(chg.storchg) + Parsed(chg.deltotalchg))}
        </Text>
      </View>
    ));
    let totalstg = 0;
    let totaldel = 0;
    let totalall = 0;

    for (let i = 0; i < inv.charges.length; i++) {
      totalstg += Parsed(inv.charges[i].storchg);
      totaldel += Parsed(inv.charges[i].deltotalchg);
      totalall +=
        Parsed(inv.charges[i].storchg) + Parsed(inv.charges[i].deltotalchg);
    }

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>AC Invoice Charges Summary</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol15blue}>Start</Text>
          <Text style={styles.tableCol15blue}>End</Text>
          <Text style={styles.tableCol20blue}>Storage (KD)</Text>
          <Text style={styles.tableCol20blue}>In/Out/Del. (KD)</Text>
          <Text style={styles.tableCol30blue}>Total (KD)</Text>
        </View>
        {charges}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>
            {inv.charges.length} Charges
          </Text>
          <Text style={styles.tableCol20blue}>
            {PRKD(totalstg) > 0 ? PRKD(totalstg) : "-"}
          </Text>
          <Text style={styles.tableCol20blue}>
            {PRKD(totaldel) > 0 ? PRKD(totaldel) : "-"}
          </Text>
          <Text style={styles.tableCol30blue}>{PRKD(totalall)}</Text>
        </View>
        <Text style={{ lineHeight: 0.5 }}> </Text>
        <Text style={styles.text2}>
          Should you require any additional information please feel free to
          contact the undersigned at your earliest convenience. We thank you for
          the opportunity to quote for your business and assure you of our best
          service levels and prompt attention at all times.
        </Text>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.text2}>{"        "}Yours Sincerely,</Text>
        <Image src={signature} style={{ width: 150 }} />
        <Text style={styles.text2}>
          {"        "}On Behalf of Easy Move Logistics Co.
        </Text>
      </View>
    );
  }
}

export default observer(ChargeRows);
