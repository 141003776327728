import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Steps } from "antd";
import { Col, FlexboxGrid } from "rsuite";

import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import Col4 from "../../Components/Columns/Col4";
import backgroundpic from "../../Static/Images/background2.jpg";
import pic1 from "../../Static/Images/CompanyPics/pic1.png";
import pic2 from "../../Static/Images/CompanyPics/pic2.png";
import pic3 from "../../Static/Images/CompanyPics/pic3.png";
import pic8 from "../../Static/Images/CompanyPics/pic8.png";
import invimage from "../../Static/Images/SmartSolutions/InventoryImage.png";
import warehouse from "../../Static/Images/WarehouseBackgroundEmpty.png";
import technologypic from "../../Static/Images/banners/technology.png";

import Services from "./Sections/Services";
import SingleBenefit from "./Sections/Benefits/SingleBenefit";
import ParallaxImage from "../../Components/Parallax/ParallaxImage";
import IconItem from "../../Components/Lists/IconItem";

const { Step } = Steps;

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 5 };
    this.handleStep = this.handleStep.bind(this);
  }
  handleStep(step) {
    this.setState({ step: step });
  }

  render() {
    let data = [
      {
        image: backgroundpic,
        step: 0,
        year: "2017",
        title: "Easy Move Initiation",
        point1: "Started with Local Move for Businesses and Households.",
        point2: "Provided Machinery to help with Heavy Lifting.",
        point3: "Incorporated the Highes Quality Packaging Materials.",
        point4:
          "Become a local market leader in Moving and a household name in Kuwait.",
      },
      {
        image: warehouse,
        step: 1,
        year: "2018",
        title: "Easy Move's First Warehouse",
        point1: "Obtained a warehouse and started household storage services.",
        point2: "Focused on Long Term Storage solutions.",
        point3: "Initiated the processes of Warehousing and Storage.",
        point4: "Developed our inhouse storage solution for Easy Move Staff.",
      },
      {
        image: pic1,
        step: 2,
        year: "2019",
        title: "Custom Clearance and International Moves",
        point1: "Obtained our in-house Custom Clearance License.",
        point2: "Provided Door to Door International Move Jobs",
        point3:
          "Joined international organizations (IAM / FIATA) giving us partners worldwide for international moves.",
        point4:
          "Provided full international move from Kuwait's Port to the Customers door all inhouse.",
      },
      {
        image: pic2,
        step: 3,
        year: "2020",
        title: "Supply Chain Management Solution",
        point1: "First implementation of Supply Chain Management.",
        point2: "Signed contracts with local businesses for full Supply Chain",
        point3:
          "Linked the existing Local, International, Clearance, and Warehousing departments into one syncronized process.",
        point4: "",
      },
      {
        image: invimage,
        step: 4,
        year: "2021",
        title: "Introduced Digitalization",
        point1: "Introduced our first Customer Based System.",
        point2:
          "Every Supply Chain Client had access to their business from their device.",
        point3: "Offered as a free of charge service with any new client.",
        point4: "Around the clock support for all clients.",
      },
      {
        image: pic3,
        step: 5,
        year: "2022",
        title: "Expanded Warehousing Capability",
        point1: "Doubled the size of our Warehouse",
        point2: "Expanded the business to cover Archiving.",
        point3: "Trained our staff with the Customer for on site assembly",
        point4:
          "Offered both our own delivery service or customer delivery based on their preference.",
      },
    ];
    let step = this.state.step;
    return (
      <div style={{ textAlign: "center", fontFamily: "sans-serif" }}>
        <ParallaxImage image={pic8} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <h1
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              About Easy Move
            </h1>
            <hr style={{ border: "1px solid white" }} />

            <h4>
              Starting back in 2016, we have expanded to cover the full Supply
              Chain cycle to service our customers.
            </h4>
            <br />
            <IconItem icon="clock-o" text="Established in 2017" />
            <br />
            <IconItem icon="location-arrow" text="Local Kuwaiti Company" />
            <br />
            <IconItem icon="plane" text="Inhouse Custom Clearance Licenses" />
            <br />
            <IconItem icon="pc" text="In-House Digitalized Solutions" />
          </div>
        </ParallaxImage>
        <hr />
        <FlexboxGrid justify="center" align="middle" style={{ margin: "2%" }}>
          <Col4>
            <Steps
              progressDot
              current={step}
              onChange={this.handleStep}
              direction="vertical"
            >
              <Step title={data[0].year} description={data[0].title} />
              <Step title={data[1].year} description={data[1].title} />
              <Step title={data[2].year} description={data[2].title} />
              <Step title={data[3].year} description={data[3].title} />
              <Step title={data[4].year} description={data[4].title} />
              <Step title={data[5].year} description={data[5].title} />
            </Steps>
          </Col4>
          <Col md={18} sm={12} xs={24}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="300"
                  image={data[step].image}
                  alt="freightpic"
                />
                <h3>
                  <b>{data[step].year}: </b>
                  {data[step].title}
                </h3>
                <div style={{ textAlign: "left", padding: "3%" }}>
                  <h4>• {data[step].point1}</h4>
                  <h4>• {data[step].point2}</h4>
                  <h4>• {data[step].point3}</h4>
                  <h4>• {data[step].point4}</h4>
                </div>
              </CardActionArea>
            </Card>
          </Col>
        </FlexboxGrid>
        <br />
        <Services />
        <SingleBenefit />
        <img
          src={technologypic}
          style={{ maxHeight: 200, maxWidth: "100%" }}
          alt="tehnology graphics"
        />
      </div>
    );
  }
}

export default withRouter(observer(AboutUs));
