import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ParallaxImage from "../../../Components/Parallax/ParallaxImage";
import userStore from "../../../Stores/UserStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import pic from "../../../Static/Images/CompanyPics/pic5.png";
import Col2 from "../../../Components/Columns/Col2";
import IconItem from "../../../Components/Lists/IconItem";
import SingleBenefit from "../Sections/Benefits/SingleBenefit";

class InterMoveSolution extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ParallaxImage image={pic} alt={"Inventory"}>
          <div
            style={{
              color: "white",
              textShadow: "3px 3px black",
              fontWeight: "bold",
            }}
          >
            <p
              style={{
                fontFamily: "Work Sans",
                fontWeight: 900,
                fontSize: 40,
              }}
            >
              International Moves
            </p>
            <p style={{ fontFamily: "Work Sans", fontSize: 20 }}>
              With Easy Move
            </p>
            <hr style={{ border: "1px solid white" }} />
            <p style={{ fontFamily: "sans-serif", fontSize: 24 }}>
              Network of High Quality Service Partners around the World
            </p>

            <div style={{ textShadow: "1px 1px black" }}>
              <p style={{ fontFamily: "sans-serif", fontSize: 14 }}>
                Easy Move is a part of multiple International Moving
                organizations where we ensure your items whether inbound or
                outbound are handled by professionals. Relocate with confidence
                with our comprehensive international moving services. Our
                experienced and knowledgeable team handles every aspect of your
                move, from customs clearance to final delivery, ensuring a
                smooth transition to your new home abroad. Let us help you
                navigate the complexities of an international move and make your
                transition as seamless as possible.
              </p>
            </div>
            <FlexboxGrid>
              <Col2>
                <IconItem icon="plane" text="Air Shipments" />
                <br />
                <IconItem icon="truck" text="Land Transporation" />
                <br />
                <IconItem icon="ship" text="Sea Freights" />
                <br />
              </Col2>
              <Col2>
                <IconItem icon="globe" text="International Network" />
                <br />
                <IconItem icon="list" text="Custom Clearance" />
                <br />
                <IconItem icon="pc" text="Fully Digitalized" />
                <br />
              </Col2>
            </FlexboxGrid>
            {!userStore.signedIn && (
              <>
                <hr style={{ border: "1px solid white" }} />
                <IconButton
                  icon={<Icon icon="truck" />}
                  size="lg"
                  color="blue"
                  onClick={() => this.props.history.push("/login")}
                >
                  Join Easy Move Today!
                </IconButton>
              </>
            )}
          </div>
        </ParallaxImage>
        <SingleBenefit />
      </div>
    );
  }
}

export default withRouter(observer(InterMoveSolution));
