import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { IconButton, Icon, FlexboxGrid } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import itemQRStore from "../../../../Stores/Account/ItemQRStore";
import QRPrint from "../../../../Components/QRPages/QRPrint";
import { Box } from "@material-ui/core";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import Col4 from "../../../../Components/Columns/Col4";
import Col2 from "../../../../Components/Columns/Col2";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";

class ItemQRPrint extends Component {
  constructor(props) {
    super(props);
    this.state = { newId: "" };
    this.changeVal = this.changeVal.bind(this);
  }
  changeVal(val) {
    this.setState({ newId: val });
  }
  componentDidMount() {
    itemQRStore.singleItemFetch(this.props.match.params.itemid);
  }
  render() {
    let printitems = itemQRStore.items;
    let content;
    let qrs = printitems.map((item, index) => (
      <QRPrint key={index} item={item} companyname={item.clientshort} />
    ));
    content = (
      <div style={{ paddingLeft: "2px" }}>
        <Box display="block" displayPrint="none">
          <FlexboxGrid>
            <Col4>
              <IconButton
                icon={<Icon icon="left" />}
                block
                color="red"
                onClick={() => this.props.history.push("/sacstaff/dash")}
              >
                Go Back
              </IconButton>
            </Col4>
            <Col2>
              <Col2>
                <NumbInputSTD
                  title="Item ID"
                  val={this.state.newId}
                  label="actualquantity"
                  size="large"
                  width="100%"
                  min={0}
                  max={100000}
                  editVal={this.changeVal}
                />
              </Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="cube" />}
                  color="green"
                  block
                  disabled={this.state.newId === "" || this.state.newId === 0}
                  onClick={() => itemQRStore.singleItemFetch(this.state.newId)}
                >
                  QR for Item ID# {this.state.newId}
                </IconButton>
              </Col2>
            </Col2>
          </FlexboxGrid>
          <hr />
          <IconButton
            icon={<Icon icon="print" />}
            color="green"
            onClick={() => window.print()}
          >
            Print QR Codes
          </IconButton>
          <br />
        </Box>
        {qrs}
      </div>
    );

    return (
      <LoadBack loading={itemQRStore.loading}>
        <Box display="block" displayPrint="none">
          <Div14>Single Item QR Print</Div14>
        </Box>
        {content}
      </LoadBack>
    );
  }
}

export default withRouter(observer(ItemQRPrint));
