import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Empty, message } from "antd";
import { FlexboxGrid } from "rsuite";
import moment from "moment";
import Col2 from "../../../Components/Columns/Col2";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";

import MaterialTable from "material-table";
import DatePickerINPB from "../../../Components/Inputs/InputButton/DatePickerINPB";

const instance = axios.create({ baseURL: baseURL });

class AttendanceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      attendance: [],
      date: moment().format("DD-MMM-YY"),
    };
    this.getHistory = this.getHistory.bind(this);
    this.editMonth = this.editMonth.bind(this);
  }
  editMonth(val, label) {
    this.setState({ date: moment(val).format("MMM-YYYY") });
  }
  getHistory() {
    let url = `/hr/attendanceinout/attendancehistory/${this.props.staffid}`;
    let data = { date: this.state.date };
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ attendance: res.data });
        this.setState({ loading: false });
        this.props.editVal(true, "loadedhistory");
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Get Staff");
      });
  }

  render() {
    let columnnames = [
      {
        title: "#",
        field: "index",
        removable: true,
        defaultSort: "asc",
      },

      {
        title: "Date",
        field: "date",
        removable: true,
        render: (rowData) =>
          `${moment(rowData.date).format("ddd").substring(0, 3)}, ${moment(
            rowData.date
          ).format("DD-MMM-YY")}`,
      },
      { title: "Sign In", field: "signInTime", removable: true },
      { title: "Sign Out", field: "signOutTime", removable: true },
      { title: "Status", field: "status", removable: true },
      {
        title: "Work Time",
        field: "worktime",
        removable: true,
        render: (rowData) => {
          if (rowData.worktimehrs < 6)
            return (
              <div style={{ color: "red", fontWeight: "bold" }}>
                {rowData.worktime}
              </div>
            );
          return rowData.worktime;
        },
      },
    ];
    let content;
    if (!this.props.loadedhistory) {
      content = <Empty description="History Not Loaded"></Empty>;
    } else {
      content = (
        <div style={{ padding: "1%" }}>
          <MaterialTable
            isLoading={this.state.loading}
            title={this.state.attendance.length + " Days"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={this.state.attendance}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              pageSize: 31,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: false,
              padding: "dense",
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.status === "Present"
                    ? "#e3ffe7"
                    : rowData.status === "Late"
                    ? "yellow"
                    : rowData.status === "Absent"
                    ? "red"
                    : rowData.status === "Medical"
                    ? "blue"
                    : moment(rowData.date).format("ddd") === "Fri" && "#ababab",
              }),
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <FlexboxGrid justify="center">
          <Col2>
            <DatePickerINPB
              title="Select Month"
              label="date"
              val={this.state.date}
              picker="month"
              format="MMM-YYYY"
              editVal={this.editMonth}
              updateVal={this.getHistory}
              buttext="Get Attendance"
              load={this.state.loading}
            />
          </Col2>
        </FlexboxGrid>

        {content}
      </div>
    );
  }
}

export default withRouter(observer(AttendanceHistory));
