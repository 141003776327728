import { message } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import DatePickerSTD from "../../../../../../../Components/Inputs/STD/DatePickerSTD";
import NumbInputSTD from "../../../../../../../Components/Inputs/STD/NumbInputSTD";
import SelectInputSTD from "../../../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../../../../../Components/Modal/ModalFooter";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import { PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";
import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import acInvoiceStore from "../../../../../../../Stores/Financial/ACInvoiceStore";
import PaymentTable from "./PaymentTable";
import axios from "axios";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";
import StorageChargeHeader from "../StorageCharges/StorageChargeHeader";
import banklist from "../../../../../../../Static/Lists/financial/banklist";
import paymethodlist from "../../../../../../../Static/Lists/financial/paymethodlist";
import departmentcustomer from "../../../../../../../Static/Lists/financial/departmentcustomer";
import Col4 from "../../../../../../../Components/Columns/Col4";

const instance = axios.create({ baseURL: baseURL });

class PaymentMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,

      payment: {
        type: "Income",
        subtype: "Account Related",
        specific: "Customer Payment",
        cash: "Cash",
        date: moment(),
        method: "Bank Transfer",
        account: "KFH",
        paymentref: "",
        amount: 0,
        remarks: "",
        accounttype: "Storage Account",
        client: "",
        acinvoice: "",
        partyname: "",
      },
    };
    this.newPayment = this.newPayment.bind(this);
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
  }
  componentDidMount() {
    acInvoiceStore.getPaymentsByClient(singleAccountStore.account._id);
    acInvoiceStore.getInvoiceByClient(singleAccountStore.account._id);
  }
  postNew = async () => {
    this.setState({ loading: true });

    let data = { ...this.state.payment };

    let url = `/jf/payment/new`;

    await instance
      .post(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.onHide();
        message.success("New Payment Created");
        acInvoiceStore.getPaymentsByClient(singleAccountStore.account._id);
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An Error Occured.` });
      });
  };
  editVal(val, label) {
    let oldpayment = { ...this.state.payment };
    oldpayment[label] = val;
    this.setState({ payment: oldpayment });
  }
  onHide() {
    this.setState({ show: false, loading: false });
  }
  newPayment(type) {
    this.setState({
      show: true,

      payment: {
        type: "Income",
        subtype: "Account Related",
        specific: "Customer Payment",
        cash: "Cash",
        date: moment(),
        method: "Bank Transfer",
        account: "KFH",
        paymentref: "",
        amount: 0,
        remarks: "",
        accounttype: "Storage Account",
        acinvoice: "",
        partyname: "",

        client: singleAccountStore.account._id,
      },
    });
  }
  render() {
    let disabled = PRKD(this.state.payment.amount) <= 0;

    return (
      <LoadBack loading={acInvoiceStore.loading}>
        <StorageChargeHeader hidedetails />
        <Div14>Payment Main</Div14>
        <UnifiedModal
          title="Create a New Storage Payment"
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New Payment"]}
              oC1={this.onHide}
              oC2={() => this.postNew()}
              disabled2={disabled}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <Div14>Payment Details</Div14>
          <FlexboxGrid>
            <Col2>
              <NumbInputSTD
                title="Amount"
                editVal={this.editVal}
                val={this.state.payment.amount}
                label="amount"
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Payment Ref."
                editVal={this.editVal}
                val={this.state.payment.paymentref}
                label="paymentref"
              />
              <br />
            </Col2>
            <Col2>
              <DatePickerSTD
                title="Date"
                editVal={this.editVal}
                val={this.state.payment.date}
                label="date"
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Department"
                editVal={this.editVal}
                val={this.state.payment.accounttype}
                label="accounttype"
                size="large"
                opts={departmentcustomer}
              />
              <br />
            </Col2>
          </FlexboxGrid>

          <TextInputSTD
            title="Party Name."
            editVal={this.editVal}
            val={this.state.payment.partyname}
            label="partyname"
          />
          <br />

          <TextAreaInputSTD
            title="Payment Remarks"
            editVal={this.editVal}
            val={this.state.payment.remarks}
            label="remarks"
          />
          <Div14>Payment Options</Div14>

          <FlexboxGrid>
            <Col2>
              <SelectInputSTD
                title="Type"
                editVal={this.editVal}
                val={this.state.payment.type}
                label="type"
                size="large"
                opts={["Income"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Sub Type"
                editVal={this.editVal}
                val={this.state.payment.subtype}
                label="subtype"
                size="large"
                opts={["Account Related"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Specific"
                editVal={this.editVal}
                val={this.state.payment.specific}
                label="specific"
                size="large"
                opts={["Customer Payment"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Cash / Non-Cash"
                editVal={this.editVal}
                val={this.state.payment.cash}
                label="cash"
                size="large"
                opts={["Cash"]}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Method"
                editVal={this.editVal}
                val={this.state.payment.method}
                label="method"
                size="large"
                opts={paymethodlist}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Account"
                editVal={this.editVal}
                val={this.state.payment.account}
                label="account"
                size="large"
                opts={banklist}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <Div14>Invoices</Div14>
          <FlexboxGrid>
            {acInvoiceStore.invoiceopts.map((inv, index) => (
              <Col4 key={index}>
                <Button
                  block
                  style={{
                    color: this.state.payment.acinvoice
                      ? this.state.payment.acinvoice === inv._id
                        ? "white"
                        : "black"
                      : "black",
                    backgroundColor: this.state.payment.acinvoice
                      ? this.state.payment.acinvoice === inv._id
                        ? "black"
                        : "white"
                      : "white",
                    border: "1px solid black",
                  }}
                  onClick={() => {
                    let newid = inv._id;
                    if (this.state.payment.acinvoice === inv._id) {
                      newid = "";
                    }
                    this.editVal(newid, "acinvoice");
                  }}
                >
                  Inv ID {inv.invId}: {PRKD(inv.totalcharges)} KD
                </Button>
              </Col4>
            ))}
          </FlexboxGrid>
        </UnifiedModal>
        <PaymentTable newPayment={this.newPayment} />
      </LoadBack>
    );
  }
}

export default withRouter(observer(PaymentMain));
