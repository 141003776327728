import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";
import HeaderFooterPDF from "../Template/HeaderFooterPDF";
import HeaderSection from "./HeaderSection";

class ReceiptPDF extends Component {
  render() {
    let rct = this.props.rct;

    return (
      <div>
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Receipt ${rct.receiptNumId}-
                ${moment().format("YYMMDDHH")} for ${rct.jobid.client.name}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Receipt ${rct.receiptNumId}-
                ${moment().format("YYMMDDHH")} for ${rct.jobid.client.name}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection rct={rct} />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(ReceiptPDF);
