import { observer } from "mobx-react";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock } from "rsuite";
import { Select, Tooltip } from "antd";

const { Option } = Select;

class SelectInputSTU extends Component {
  render() {
    let ind = this.props.ind;
    let label = this.props.label;
    let title = this.props.title;
    let load = this.props.load;
    let opts = this.props.opts;
    let org = this.props.org;
    let val = this.props.val;

    let read = false;
    let size = "small";
    let disabled = false;

    if (this.props.read) read = this.props.read;
    if (this.props.size) size = this.props.size;
    if (this.props.disabled) disabled = this.props.disabled;

    let change = false;
    if (org !== val) change = true;
    if (read || disabled) change = false;

    let options = opts.map((val, index) => (
      <Option value={val} key={index}>
        {val}
      </Option>
    ));
    let quality = this.props.quality;
    let qualitytext = this.props.qualitytext;

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {change && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(org, label, ind)}
            >
              <Icon icon="undo" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: quality && "#ff968c" }}>
              {title}
            </InputGroup.Addon>
          </Tooltip>
          <Select
            showSearch
            size={size}
            value={val}
            style={{ width: "100%" }}
            placeholder={this.props.title}
            optionFilterProp="children"
            onChange={(e) => this.props.editVal(e, label, ind)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options}
          </Select>
          {change && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(label, title, ind)}
              disabled={val === ""}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {change && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>{org}</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(SelectInputSTU);
