import { computed, makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Notification } from "rsuite";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class WarehouseSummaryStore {
  constructor() {
    this.month = moment().startOf("month").add(2, "days");
    this.clients = [];
    this.invoices = [];
    this.pendinginvoices = [];
    this.loading = { clients: false, invoices: false, pendinginvoices: false };
    this.loaded = { clients: false, invoices: false, pendinginvoices: false };

    makeObservable(this, {
      month: observable,
      clients: observable,
      invoices: observable,
      pendinginvoices: observable,
      loading: observable,
      loaded: observable,
      stats: computed,
    });
  }

  // FETCH
  getWarehouseData(overide) {
    if (!overide) {
      if (this.loaded.clients) return null;
    }
    this.loading.clients = true;
    return instance
      .put(
        `/jf/whsummary/clientdata`,
        { startmonth: this.month },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        this.clients = res.data;

        this.loading.clients = false;
        this.loaded.clients = true;

        console.log("Warehouse Summary Fetched with Client  Data");
      })
      .catch((err) => {
        this.loading.clients = false;
        this.loaded.clients = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  getInvoiceData(overide) {
    if (!overide) {
      if (this.loaded.invoices) return null;
    }
    this.loading.invoices = true;

    return instance
      .put(
        `/jf/whsummary/invoicedata`,
        { startmonth: this.month },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        this.invoices = res.data;
        this.loading.invoices = false;
        this.loaded.invoices = true;

        console.log("Invoice Data Fetched");
      })
      .catch((err) => {
        this.loading.invoices = false;
        this.loaded.invoices = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getPendingInvoiceData(overide) {
    if (!overide) {
      if (this.loaded.pendinginvoices) return null;
    }
    this.loading.pendinginvoices = true;

    return instance
      .put(
        `/jf/whsummary/pendinginvoices`,
        { startmonth: this.month },
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        this.pendinginvoices = res.data;
        this.loading.pendinginvoices = false;
        this.loaded.pendinginvoices = true;

        console.log("Pending Invoice Data Fetched");
      })
      .catch((err) => {
        this.loading.pendinginvoices = false;
        this.loaded.pendinginvoices = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  changeMonth(newdate, type) {
    warehouseSummaryStore.month = moment(newdate)
      .startOf("month")
      .add(2, "days");
    if (type === "1") this.getWarehouseData(true);
    if (type === "2") this.getInvoiceData(true);
  }

  get stats() {
    let output = {
      clients: { storage: 0, delivery: 0, total: 0 },
      invoices: { storage: 0, delivery: 0, total: 0 },
      pendinginvoices: { storage: 0, delivery: 0, total: 0 },
    };
    if (this.loaded.clients) {
      if (this.clients.length > 0) {
        for (let i = 0; i < this.clients.length; i++) {
          output.clients.storage += this.clients[i].storchg;
          output.clients.delivery += this.clients[i].deltotalchg;
          output.clients.total += this.clients[i].totalchg;
        }
      }
    }
    if (this.loaded.invoices) {
      if (this.invoices.length > 0) {
        for (let i = 0; i < this.invoices.length; i++) {
          output.invoices.storage += this.invoices[i].totalstorage;
          output.invoices.delivery += this.invoices[i].totaldelivery;
          output.invoices.total += this.invoices[i].totalinvoicecost;
        }
      }
    }
    if (this.loaded.pendinginvoices) {
      if (this.pendinginvoices.length > 0) {
        for (let i = 0; i < this.pendinginvoices.length; i++) {
          output.pendinginvoices.storage +=
            this.pendinginvoices[i].totalstorage;
          output.pendinginvoices.delivery +=
            this.pendinginvoices[i].totaldelivery;
          output.pendinginvoices.total +=
            this.pendinginvoices[i].totalinvoicecost;
        }
      }
    }

    return output;
  }
}

const warehouseSummaryStore = new WarehouseSummaryStore();
export default warehouseSummaryStore;
