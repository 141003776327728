import { Empty, message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.postUpdate = this.postUpdate.bind(this);
  }
  postUpdate = async () => {
    let newopts = [...newStorageStore.neworder.orderoptions];

    let data = { orderoptions: newopts };
    this.setState({ loading: true });
    await instance
      .put(`ac/acorder/edit/${newStorageStore.oldorder._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.updateOrgOrdOptions(newopts);
        message.success(`Order Options Updated`);

        this.setState({ loading: true });
      })
      .catch((err) => {
        this.setState({ loading: true });
        Notification["error"]({ title: `An error occured` });
      });
  };
  render() {
    let content = <Empty description="No Options Available" />;
    let allopts = newStorageStore.delopts;

    if (allopts) {
      if (allopts.length > 0) {
        content = allopts.map((opt, index) => (
          <IconButton
            icon={<Icon icon={opt.selected ? "check-circle" : "circle"} />}
            color="blue"
            block
            appearance={opt.selected ? "primary" : "ghost"}
            key={index}
            onClick={() => {
              if (!newStorageStore.stopedit) {
                newStorageStore.updateOption(opt);
              }
            }}
          >
            {opt.title} {PRKD(opt.price)} {opt.type}
          </IconButton>
        ));
      }
    }
    let oldopts = newStorageStore.oldorder.orderoptions;

    return (
      <div>
        {!newStorageStore.stopedit && (
          <ModalFooter
            icon={["undo", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={["Cancel Changes", "Save Changes"]}
            oC1={() => newStorageStore.resetorderopts([...oldopts])}
            oC2={() => this.postUpdate()}
          />
        )}
        <Div14>Selected Options:</Div14>
        <FlexboxGrid justify="center">
          <Col2>{content}</Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(Options);
