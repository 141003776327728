import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock, Icon, IconButton, Notification } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import ModalFooter from "../../../../../../Components/Modal/ModalFooter";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class PerTypeStepSupport extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.updateVal = this.updateVal.bind(this);
  }
  updateVal = async () => {
    let toadd = [...newDeliveryStore.newdel.toaddress];
    let finaldata = [];
    for (let i = 0; i < toadd.length; i++) {
      finaldata = [
        ...finaldata,
        { _id: toadd[i]._id, service: { ...toadd[i].service } },
      ];
    }

    this.setState({ loading: true });
    await instance
      .put(`ac/location/reorderlocations`, finaldata, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.updateOrgType("update");
        message.success(`Service Types Updated`);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  render() {
    let types = newDeliveryStore.delCont.types;
    let del = newDeliveryStore.newdel;
    let tos = del.toaddress;
    let oldtos = newDeliveryStore.olddel.toaddress;

    let disabled = false;
    let same = true;
    for (let i = 0; i < tos.length; i++) {
      if (tos[i].service.type !== oldtos[i].service.type) {
        same = false;
      }
      if (!tos[i].service.type) {
        disabled = true;
      }
    }
    if (same) {
      disabled = true;
    }
    let stopedit = newDeliveryStore.stopedit;

    return (
      <div>
        {!stopedit && (
          <ModalFooter
            icon={["undo", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={["Reset", "Save Changes"]}
            oC1={() => newDeliveryStore.updateOrgType("reset")}
            oC2={() => this.updateVal()}
            disabled1={same}
            disabled2={disabled}
            loading1={this.state.loading}
            loading2={this.state.loading}
          />
        )}
        <Div14>Single Type Per Delivery:</Div14>
        <FlexboxGrid justify="center">
          {tos.map((to, index) => (
            <Col2 key={index}>
              <Div14>
                Drop Off #{index + 1} {to.city}:
              </Div14>
              {types.map((type, typeind) => (
                <FlexboxGrid
                  key={typeind}
                  style={{
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    paddingBottom: "1%",
                  }}
                >
                  <IconButton
                    icon={
                      <Icon
                        icon={
                          type.type === tos[index].service.type
                            ? "check-circle"
                            : "circle"
                        }
                      />
                    }
                    appearance={
                      type.type === tos[index].service.type
                        ? "primary"
                        : "ghost"
                    }
                    color="green"
                    block
                    style={{ height: "100%" }}
                    onClick={() => {
                      if (!stopedit) {
                        newDeliveryStore.selectType(type, index);
                      }
                    }}
                  >
                    <div>
                      {type.type} for {PRKD(type.price)} KD
                      <br />
                      <HelpBlock
                        style={{
                          fontStyle: "italic",
                          color:
                            type.type === tos[index].service.type
                              ? "white"
                              : "grey",
                        }}
                      >
                        <b>Description: </b>
                        {type.description}
                      </HelpBlock>
                    </div>
                  </IconButton>
                </FlexboxGrid>
              ))}
            </Col2>
          ))}
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(PerTypeStepSupport);
