import { computed, makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import acClearanceStore from "../AccountUpdated/ACC/ACClearanceStore";
import { Parsed, PRKD } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class AllPaymentsStore {
  constructor() {
    this.newpayment = {
      type: "Expense Variable",
      subtype: "",
      specific: "",
      cash: "Cash",
      date: moment(),
      method: "",
      account: "KFH",
      paymentref: "",
      amount: 0,
      remarks: "",
      accounttype: "General",
    };
    this.oldpayment = {
      type: "Expense Variable",
      subtype: "",
      specific: "",
      cash: "Cash",
      date: moment(),
      method: "",
      account: "KFH",
      paymentref: "",
      amount: 0,
      remarks: "",
      accounttype: "General",
    };
    this.daterange = [
      moment().subtract(180, "days").startOf("month"),
      moment().endOf("month"),
    ];
    this.payments = [];
    this.paymentopts = [];
    this.loading = { payment: false };
    this.loaded = { payment: false };
    this.filtertype = "All";
    this.graphfactor = "subtype";

    makeObservable(this, {
      newpayment: observable,
      oldpayment: observable,
      payments: observable,
      paymentopts: observable,
      loading: observable,
      loaded: observable,
      daterange: observable,
      filtertype: observable,
      graphfactor: observable,
      paysubtypes: computed,
      payspecific: computed,
      income: computed,
      expenses: computed,
      filteredpayments: computed,
      basicpaymentstats: computed,
      paymentheatmap: computed,
    });
  }

  // FETCH

  getAllPayments(overide, showall) {
    let newclient = acClearanceStore.client._id;
    let loaded = this.loaded.payment;
    if (overide) loaded = false;
    if (this.loading.payment) loaded = true;
    if (!loaded) {
      let url = `/jf/financial/staffpayments`;
      if (showall) url = `/jf/financial/allpayments`;
      this.clientId = newclient;
      let data = { startdate: this.daterange[0], enddate: this.daterange[1] };
      this.loading.payment = true;
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.payments = data.payment;
          this.paymentopts = data.paymentopts;
          this.loading.payment = false;
          this.loaded.payment = true;

          console.log("All Payments Fetched");
        })
        .catch((err) => {
          this.loading.payment = false;

          console.log(err);

          Notification["error"]({
            title: `Error Fetching ACC Charges.`,
          });
        });
    }
  }

  // Functions
  editNewPayment(val, label) {
    this.newpayment[label] = val;
    if (label === "type") {
      this.newpayment.subtype = "";
      this.newpayment.specific = "";
    } else if (label === "subtype") {
      this.newpayment.specific = "";
    }
  }
  resetNewPayment() {
    this.newpayment = {
      type: "Expense Variable",
      subtype: "",
      specific: "",
      cash: "Cash",
      date: moment(),
      method: "",
      account: "KFH",
      paymentref: "",
      amount: 0,
      remarks: "",
      accounttype: "General",
    };
  }
  changeDate(val) {
    allPaymentsStore.daterange[0] = moment(val[0]).startOf("month");
    allPaymentsStore.daterange[1] = moment(val[1]).endOf("month");
  }

  addNewPayment(payment) {
    let newpayment = { ...payment };
    newpayment.user = { username: userStore.user.username };
    allPaymentsStore.payments = [...allPaymentsStore.payments, newpayment];
  }
  newCustomPayment(type, subtype, specific, method, account) {
    this.newpayment = {
      type: type,
      subtype: subtype,
      specific: specific,
      method: method,
      account: account,

      cash: "Cash",
      date: moment(),
      paymentref: "",
      amount: 0,
      remarks: "",
      accounttype: "General",
    };
  }
  selectPayment(payment) {
    this.newpayment = { ...payment };
  }
  changeFilter(type) {
    if (type === "All") {
      this.filtertype = "All";
    } else {
      if (this.filtertype === type) {
        this.filtertype = "All";
      } else {
        this.filtertype = type;
      }
    }
  }

  changeGraphFactor(val) {
    this.graphfactor = val;
  }

  // Computations
  get filteredpayments() {
    if (this.filtertype === "All") {
      return this.payments;
    } else {
      return this.payments.filter((pay) => pay.type === this.filtertype);
    }
  }

  get paysubtypes() {
    let newopts = this.paymentopts.filter(
      (pay) => pay.type === this.newpayment.type
    );
    let finalopts = newopts.map((pay) => pay.subtype);
    finalopts = finalopts.sort();
    return [...new Set(finalopts)];
  }
  get payspecific() {
    let newopts = this.paymentopts.filter(
      (pay) => pay.subtype === this.newpayment.subtype
    );
    let finalopts = newopts.map((pay) => pay.title);
    finalopts = finalopts.sort();
    return [...new Set(finalopts)];
  }
  get income() {
    return allPaymentsStore.payments.filter((pay) => pay.type === "Income");
  }
  get expenses() {
    return allPaymentsStore.payments.filter((pay) => pay.type !== "Income");
  }

  get basicpaymentstats() {
    let data = {
      income: 0,
      expensevariable: 0,
      expensefixed: 0,
      expenseratio: 0,
      diff: 0,
    };
    for (let i = 0; i < this.payments.length; i++) {
      if (this.payments[i].type === "Income") {
        data.income += Parsed(this.payments[i].amount);
      } else if (this.payments[i].type === "Expense Variable") {
        data.expensevariable += Parsed(this.payments[i].amount);
      } else if (this.payments[i].type === "Expense Fixed") {
        data.expensefixed += Parsed(this.payments[i].amount);
      }
    }

    data.diff =
      Parsed(data.income) -
      (Parsed(data.expensefixed) + Parsed(data.expensevariable));

    data.expenseratio = Math.round(
      (100 * Parsed(data.diff)) / Parsed(data.income)
    );

    return data;
  }

  get paymentheatmap() {
    let output = [];
    let single = 0;

    let data = this.filteredpayments.map((pay) => pay[this.graphfactor]);
    let newdata = [...new Set(data)];
    let filtdata = [];

    for (let i = 0; i < newdata.length; i++) {
      single = 0;

      filtdata = this.filteredpayments.filter(
        (pay) => pay[this.graphfactor] === newdata[i]
      );

      for (let p = 0; p < filtdata.length; p++) {
        if (filtdata[p].type === "Income") {
          single += Parsed(filtdata[p].amount);
        } else {
          single -= Parsed(filtdata[p].amount);
        }
      }
      output = [...output, { x: newdata[i], y: PRKD(single) }];
    }
    return { output: output };
  }

  get dailypayments() {
    let start = moment(this.daterange[0]);
    let newstart = moment(this.daterange[0]);
    let newend = moment(this.daterange[0]);
    let end = moment(this.daterange[1]);
    let diff = moment(end).diff(start, "days");

    let filtpay = this.filteredpayments;

    let income = 0;
    let expense = 0;
    let finaldiff = 0;
    let finalin = [];
    let finalex = [];
    let final = [];
    let labels = [];

    for (let i = 0; i < diff; i++) {
      newstart = moment(start).add(i, "days").startOf("day");
      newend = moment(start)
        .add(i + 1, "days")
        .startOf("day");

      for (let p = 0; p < filtpay.length; p++) {
        if (
          moment(filtpay[p].date).isSameOrAfter(newstart) &
          moment(filtpay[p].date).isBefore(newend)
        ) {
          if (filtpay[p].type === "Income") {
            income += Parsed(filtpay[p].amount);
          } else {
            expense -= Parsed(filtpay[p].amount);
          }
        }
      }
      finaldiff = income + expense;

      labels = [...labels, moment(newstart).startOf("day").format("DD-MMM-YY")];
      finalin = [...finalin, PRKD(income)];
      finalex = [...finalex, PRKD(expense)];
      final = [...final, PRKD(finaldiff)];
    }

    return {
      labels: labels,
      finalin: finalin,
      finalex: finalex,
      final: final,
    };
  }
}

const allPaymentsStore = new AllPaymentsStore();
export default allPaymentsStore;
