import { observer } from "mobx-react";
import React, { Component } from "react";
import { IconButton, Icon } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";

class EditStorWarehouse extends Component {
  render() {
    let loc = this.props.loc;
    let sto = newAccountStore.storCont;

    let selected = loc._id === sto.emlocation._id;

    return (
      <Col2>
        <IconButton
          block
          onClick={() => newAccountStore.editStorCont(loc, "emlocation")}
          appearance={selected ? "default" : "ghost"}
          color="green"
          icon={<Icon icon={selected ? "check-circle" : "circle"} />}
        >
          {loc.name}
        </IconButton>
      </Col2>
    );
  }
}

export default observer(EditStorWarehouse);
