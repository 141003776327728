import { observer } from "mobx-react";
import React, { Component } from "react";

import PackingListRow from "./PackListRow";

class PackingList extends Component {
  render() {
    let sj = this.props.sj;
    let jobs = [];
    let items = [];
    let item = { title: "", qty: "" };
    let total = 0;

    for (let j = 0; j < sj.items.length; j++) {
      item = {
        itemname: sj.items[j].itemname,
        qty: sj.items[j].qty,
      };
      if (sj.items[j].show) {
        items.push(item);
        total += parseFloat(item.qty);
      }
    }
    if (items.length > 0) {
      jobs.push({
        jobtype: sj.jobtype,
        numId: sj.numId,
        items: items,
        total: total,
      });
    }

    let output;

    if (jobs.length <= 0) {
      return null;
    } else {
      output = jobs.map((job, index) => (
        <PackingListRow
          job={job}
          key={index}
          joblength={jobs.length}
          index={index}
        />
      ));
    }

    return output;
  }
}

export default observer(PackingList);
