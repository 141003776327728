import React, { Component } from "react";
import { Tabs } from "antd";

import staffStorageStore from "../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStore";
import ItemInventory from "./Tabs/ItemInventoryTab/ItemInventory";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import OrderMain from "./Tabs/Orders/OrderMain";
import InvGraph from "./Tabs/InvGraph/InvGraph";
import InvoiceMain from "./Tabs/Invoices/InvoiceMain";
import PaymentMain from "./Tabs/Payments/PaymentMain";
import SACCalendarPage from "./Tabs/Calendar/SACCalendarPage";
import StatisticsMain from "./Tabs/Statistics/StatisticsMain";

const { TabPane } = Tabs;

class StaffStorageMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Orders" };
  }
  componentDidMount() {
    staffStorageStore.getItems();
  }
  render() {
    return (
      <div style={{ margin: "1%" }}>
        <br />
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          centered
          type="card"
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Calendar"} key={"Calendar"}>
            <SACCalendarPage />
          </TabPane>
          <TabPane tab={"Inventory"} key={"Inventory"}>
            <ItemInventory />
          </TabPane>
          <TabPane tab={"Orders"} key={"Orders"}>
            <OrderMain />
          </TabPane>
          <TabPane tab={"Inv Graph"} key={"Inv Graph"}>
            <InvGraph />
          </TabPane>
          <TabPane tab={"Invoices"} key={"Invoices"}>
            <InvoiceMain />
          </TabPane>
          <TabPane tab={"Payments"} key={"Payments"}>
            <PaymentMain />
          </TabPane>
          <TabPane tab={"Statistics"} key={"Statistics"}>
            <StatisticsMain />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(StaffStorageMain));
