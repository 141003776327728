import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import singleEquipmentStore from "../../../Stores/EquipmentStores/SingleEquipmentStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import Div14 from "../../../Components/Dividers/Div14";
import moment from "moment";
import { Divider, FlexboxGrid, Icon, IconButton, Col } from "rsuite";
import Col3 from "../../../Components/Columns/Col3";
import { Tabs } from "antd";
import FuelTable from "./FuelTable";
import ServiceTable from "./ServiceTable";
import ChecklistTable from "./ChecklistTable";

const { TabPane } = Tabs;

class SingleEquipmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Fuel" };
  }
  componentDidMount() {
    singleEquipmentStore.getEquipment(this.props.match.params.equipid);
  }
  render() {
    let eq = singleEquipmentStore.equipment;

    let problems = "No Checklist Created";
    let count = "";
    if (eq.checklist) {
      if (eq.checklist.problems) {
        if (eq.checklist.problems.length > 0) {
          count = `(${eq.checklist.problems.length} Issue${
            eq.checklist.problems.length !== 1 ? "s" : ""
          })`;
          problems = eq.checklist.problems.map((pr, index) => (
            <>
              <Icon
                key={index}
                icon={"exclamation-triangle"}
                style={{ color: "orange" }}
              />{" "}
              {pr}
              <br />
            </>
          ));
        }
      }
    }

    return (
      <LoadBack loading={singleEquipmentStore.loading.equipment}>
        <div style={{ margin: "1%" }}>
          <br />
          <IconButton
            style={{ margin: "1%" }}
            icon={<Icon icon="chevron-left" />}
            onClick={() => this.props.history.push("/equipment")}
          >
            Back to Equipment List
          </IconButton>
          <Div14>
            Equipment #{eq.equipmentNumId} Details
            <Divider vertical />
            <IconButton
              icon={<Icon icon="refresh" />}
              circle
              size="xs"
              onClick={() =>
                singleEquipmentStore.getEquipment(
                  this.props.match.params.equipid,
                  true
                )
              }
            />
          </Div14>
          <FlexboxGrid justify="center">
            <Col md={16} sm={12} xs={24}>
              <Col3>
                <h4>
                  <b>ID: </b> {eq.equipmentNumId}
                </h4>
              </Col3>
              <Col3>
                <h4>
                  <b>Type: </b> {eq.type}
                </h4>
              </Col3>
              <Col3>
                <h4>
                  <b>From Date: </b> {moment(eq.date).format("DD-MMM-YYYY")}
                </h4>
              </Col3>
              <Col3>
                <h4>
                  <b>Status: </b> {eq.status}
                </h4>
              </Col3>
              <Col3>
                <h4>
                  <b>Plane #: </b> {eq.license}
                </h4>
              </Col3>
              <Col3>
                <h4>
                  <b>Remarks: </b> {eq.remark}
                </h4>
              </Col3>
            </Col>
            <Col3>
              <h4>Checklist Problems: {count}</h4>
              <b>{problems}</b>
            </Col3>
          </FlexboxGrid>
          <hr />
          <hr />
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            centered
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Checklist"} key={"Checklist"}>
              <ChecklistTable />
            </TabPane>
            <TabPane tab={"Fuel"} key={"Fuel"}>
              <FuelTable />
            </TabPane>
            <TabPane tab={"Service"} key={"Service"}>
              <ServiceTable />
            </TabPane>
          </Tabs>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(SingleEquipmentPage));
