import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, HelpBlock } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Col3 from "../../../../../Components/Columns/Col3";
import Div14 from "../../../../../Components/Dividers/Div14";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import completeDeliveryStore from "../../../../../Stores/Account/CompleteDeliveryStore";

class Page1 extends Component {
  render() {
    let item = this.props.item;
    let olditem = this.props.olditem;
    let loading = this.props.loading;
    let cds = completeDeliveryStore;
    let inout = cds.stordelinout.inout;
    let currentstock = Parsed(item.currentstock);
    if (inout === "Out") {
      currentstock += Parsed(olditem.quantity);
    }
    let cbm = PRKD(Parsed(item.cbm) * Parsed(item.quantity));

    let max = 100000;
    let finalqty = 0;
    let deliverysummary;
    if (inout === "In") {
      finalqty = parseFloat(currentstock) + parseFloat(item.quantity);
      deliverysummary = (
        <p>
          <b>Current Stock: {currentstock} </b>
          <br />
          <b>
            <b style={{ color: "red" }}>Old Quantity: </b>
            {inout === "In" ? "+" : "-"} {olditem.quantity} vs{" "}
            <b style={{ color: "green" }}>New Quantity: </b>
            {inout === "In" ? "+" : "-"} {item.quantity}
          </b>
          <br />
          <b>After Delivery Quantity = {finalqty}</b>
        </p>
      );
    } else {
      finalqty = parseFloat(currentstock) - parseFloat(item.quantity);
      deliverysummary = (
        <p>
          <b>
            Current Stock: {Parsed(currentstock)} + ({olditem.onreserve} on
            reserve)
          </b>
          <br />
          <b>
            <b style={{ color: "red" }}>Old Quantity: </b>
            {inout === "In" ? "+" : "-"} {olditem.quantity} vs{" "}
            <b style={{ color: "green" }}>New Quantity: </b>
            {inout === "In" ? "+" : "-"} {item.quantity}
          </b>
          <br />
          <b>
            After Delivery Quantity = {finalqty} + (
            {Parsed(item.onreserve) +
              Parsed(item.quantity) -
              Parsed(olditem.quantity)}{" "}
            on reserve)
          </b>
        </p>
      );
    }
    if (inout === "Out") {
      max = currentstock;
    }
    let disabled = item.quantity <= 0 || item.quantity === "";

    let finaldata = (
      <>
        <FlexboxGrid>
          <Col2 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="Quantity"
              val={item.quantity}
              label="quantity"
              size="large"
              min={1}
              max={max}
              editVal={this.props.editVal}
            />
            <HelpBlock
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              Estimated CBM = {PRKD(cbm)} CBM{" "}
            </HelpBlock>
            <HelpBlock style={{ fontStyle: "italic" }}>
              {PRKD(parseFloat(item.length / 100))} (m) x{" "}
              {PRKD(parseFloat(item.width / 100))} (m) x{" "}
              {PRKD(parseFloat(item.height / 100))} (m) x{" "}
              {PRKD(parseFloat(item.quantity))} qty
            </HelpBlock>
          </Col2>
          <Col2 style={{ textAlign: "center" }}>{deliverysummary}</Col2>
        </FlexboxGrid>

        <div style={{ paddingBottom: "5px" }} />
        <hr />
        <HelpBlock style={{ textAlign: "center" }}>
          Cannot Modify Item Details Here
        </HelpBlock>
        <Div14>Item Details:</Div14>
        <TextInputSTD
          title="Item Name"
          val={item.name}
          label="name"
          size="large"
          editVal={() => console.log()}
        />
        <div style={{ paddingBottom: "5px" }} />
        <FlexboxGrid>
          <Col2 style={{ paddingBottom: "5px" }}>
            <TextInputSTD
              title="Serial No."
              val={item.serialnumber}
              label="serialnumber"
              size="large"
              editVal={() => console.log()}
            />
          </Col2>
          <Col2 style={{ paddingBottom: "5px" }}>
            <TextInputSTD
              title="Product ID"
              val={item.productid}
              label="productid"
              size="large"
              editVal={() => console.log()}
            />
          </Col2>
        </FlexboxGrid>
        <br />
        <Div14>Item Dimensions:</Div14>

        <FlexboxGrid>
          <Col3 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="L (cm)"
              val={item.length}
              label="length"
              size="large"
              editVal={() => console.log()}
            />
          </Col3>
          <Col3 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="W (cm)"
              val={item.width}
              label="width"
              size="large"
              editVal={() => console.log()}
            />
          </Col3>
          <Col3 style={{ paddingBottom: "5px" }}>
            <NumbInputSTD
              title="H (cm)"
              val={item.height}
              label="height"
              size="large"
              editVal={() => console.log()}
            />
          </Col3>
        </FlexboxGrid>
      </>
    );
    let modfoot = (
      <ModalFooter
        icon={["save", "close"]}
        color={["green", "red"]}
        app={["default", "default"]}
        text={["Add to Order", "Cancel Changes"]}
        oC1={() => this.props.postNew(item)}
        oC2={this.props.onHide}
        loading1={loading}
        loading2={loading}
        disabled1={disabled}
      />
    );
    return (
      <div>
        <Div14>Update Quantity:</Div14>
        {finaldata}
        {modfoot}
      </div>
    );
  }
}

export default observer(Page1);
