import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { ListItem, ListItemIcon, Tooltip } from "@material-ui/core";

import { Icon } from "rsuite";
import { ListItemText } from "@material-ui/core";
import colors from "../../../Static/Brand/colors";

class SingleLink extends Component {
  render() {
    let page = this.props.page;
    let icon = this.props.icon;
    let title = this.props.title;
    return (
      <ListItem
        component={Link}
        to={page}
        onClick={() => this.props.hdc()}
        style={{ color: "black", padding: 0, margin: 0, paddingLeft: 25 }}
      >
        <ListItemIcon>
          <Tooltip title={title} arrow placement="right">
            <Icon icon={icon} style={{ color: colors.primcol }} size="lg" />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  }
}

export default withRouter(observer(SingleLink));
