import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { CheckboxGroup, Checkbox, FlexboxGrid } from "rsuite";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import Col2 from "../../../../../../Components/Columns/Col2";
import invoiceStore from "../../../../../../Stores/SideJobs/InvoiceStore";
class InvoiceJobs extends Component {
  render() {
    let inv = this.props.inv;
    let sjs = sjStore.activeSJs;
    let selectedjobs = inv.subJobs;
    let finaljobs = [];
    let indexVal = -1;
    for (let i = 0; i < sjs.length; i++) {
      indexVal = -1;
      indexVal = selectedjobs.findIndex(
        (sj) => ` ${sj._id}` === ` ${sjs[i]._id}`
      );
      if (indexVal < 0) {
        finaljobs.push(sjs[i]);
      }
    }

    let pendingjobs = finaljobs.map((sj, index) => (
      <Checkbox
        key={index}
        checked={false}
        onClick={() => invoiceStore.addSubJob(sj)}
      >
        {sj.jobtype}-{sj.sjNumId}: {sj.subtype} ({sj.status})
      </Checkbox>
    ));
    let showjobs = selectedjobs.map((sj, index) => (
      <Checkbox
        key={index}
        checked={true}
        onClick={() => invoiceStore.removeSubJob(index)}
      >
        {sj.jobtype}-{sj.numId}: {sj.subtype} ({sj.status})
      </Checkbox>
    ));

    return (
      <div>
        <Div14>Packing List</Div14>
        <Div14>Services</Div14>
        <FlexboxGrid>
          <Col2>
            <Div14>Remaining Jobs</Div14>
            <CheckboxGroup name="checkboxList">{pendingjobs}</CheckboxGroup>
          </Col2>
          <Col2>
            <Div14>Selected Jobs</Div14>
            <CheckboxGroup name="checkboxList">{showjobs}</CheckboxGroup>
          </Col2>
        </FlexboxGrid>
        <Div14>Bank Info</Div14>
      </div>
    );
  }
}

export default observer(InvoiceJobs);
