import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon, HelpBlock } from "rsuite";
import axios from "axios";
import { message } from "antd";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import Col2 from "../../../../../Components/Columns/Col2";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import completeStorStore from "../../../../../Stores/AccountUpdated/Storage/CompleteStorStore";
import { Parsed } from "../../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class ActualQuantityRow extends Component {
  constructor(props) {
    super(props);
    this.state = { showsave: false };
    this.editVal = this.editVal.bind(this);
    this.postChange = this.postChange.bind(this);
  }
  editVal(val, label) {
    let jobtype = completeStorStore.neworder.jobtype;
    if (jobtype === "Delivery Out" || jobtype === "Storage Out") {
      let max = 100000;
      max =
        Parsed(this.props.itemmove.acitem.currentstock) +
        Parsed(this.props.itemmove.quantity);
      if (val > max) {
        message.warning(
          `Kindly note there are only ${max} Items available in stock`
        );
      }
    }
    completeStorStore.changeSTOOnlyActQuantity(this.props.itemmove._id, val);
    this.setState({ showsave: true });
  }
  postChange(quantity) {
    this.setState({ loading: true });
    let data = { actualquantity: quantity };

    return instance
      .put(`/ac/itemmove/updateactquant/${this.props.itemmove._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        message.success("Item Updated");
      })
      .catch((err) => {
        console.log(err);
        message.error("Update Failed");

        this.setState({ loading: false });
      });
  }

  render() {
    let disabled = this.props.disabled;

    let itemmove = this.props.itemmove;
    let item = itemmove.acitem;
    let color = "#ffe4a6";
    let text;
    let diff =
      parseFloat(itemmove.quantity) - parseFloat(itemmove.actualquantity);
    if (diff === 0) {
      text = "All Available";
    } else if (diff < 0) {
      text = `${-1 * diff} Extra`;
    } else {
      text = `${diff} Missing`;
    }
    if (parseFloat(itemmove.quantity) === parseFloat(itemmove.actualquantity)) {
      color = "#bcffb3";
    } else if (parseFloat(itemmove.actualquantity) === 0) {
      color = "#ffb3b3";
    }
    let max = 100000;
    let jobtype = completeStorStore.neworder.jobtype;
    if (jobtype === "Delivery Out" || jobtype === "Storage Out") {
      max = Parsed(itemmove.acitem.currentstock) + Parsed(itemmove.quantity);
    }
    let saveButs = (
      <div>
        <IconButton
          block
          size="xs"
          icon={<Icon icon="check" />}
          color="green"
          onClick={() => {
            completeStorStore.changeSTOOnlyActQuantity(
              this.props.itemmove._id,
              itemmove.quantity
            );
            this.setState({ showsave: false });
            this.postChange(itemmove.quantity);
          }}
          disabled={disabled}
        >
          Available
        </IconButton>
        <IconButton
          block
          size="xs"
          icon={<Icon icon="close" />}
          color="red"
          onClick={() => {
            completeStorStore.changeSTOOnlyActQuantity(
              this.props.itemmove._id,
              0
            );
            this.setState({ showsave: false });
            this.postChange(0);
          }}
          disabled={disabled}
        >
          Missing
        </IconButton>
      </div>
    );

    if (this.state.showsave) {
      saveButs = (
        <IconButton
          block
          icon={<Icon icon="save" />}
          color="blue"
          onClick={() => {
            this.setState({ showsave: false });
            this.postChange(itemmove.actualquantity);
          }}
          disabled={disabled}
        >
          Save
        </IconButton>
      );
    }

    return (
      <FlexboxGrid
        style={{
          border: "1px solid black",
          borderRadius: "5px",
          padding: "3px",
          backgroundColor: color,
        }}
        justify="center"
        align="middle"
      >
        <Col2>
          <p>
            <IconButton
              icon={<Icon icon="trash" />}
              circle
              color="red"
              size="xs"
              onClick={() => this.props.delItem(itemmove)}
              disabled={disabled}
            />{" "}
            <b>
              {" "}
              ID #{item.acItemNumId}) {item.name}
            </b>{" "}
            <i>
              ({item.length} x {item.width} x {item.height})
            </i>
            <br />
            <b>Product ID:</b> <i>{item.productid}</i> / <b>Serial No:</b>{" "}
            <i>{item.serialnumber}</i>
          </p>
        </Col2>
        <Col2>
          <FlexboxGrid justify="center" align="middle">
            <Col2>
              <NumbInputSTD
                title={`Quant: ${itemmove.quantity}`}
                min={0}
                max={max}
                val={itemmove.actualquantity}
                size="sm"
                editVal={this.editVal}
                disabled={disabled}
              />{" "}
              <HelpBlock style={{ textAlign: "center", fontWeight: "bold" }}>
                <i>{text}</i>
              </HelpBlock>
            </Col2>
            <Col2>{saveButs}</Col2>
          </FlexboxGrid>
        </Col2>
      </FlexboxGrid>
    );
  }
}

export default observer(ActualQuantityRow);
