import { observer } from "mobx-react";
import React, { Component } from "react";

import { Descriptions } from "antd";

import Div14 from "../../../../../Components/Dividers/Div14";
import StatusIndicator from "./StatusIndicator";

class ApprovalSummary extends Component {
  render() {
    let acc = this.props.acc;

    let data = [
      { label: "Documents Collected", val: acc.doccol, date: acc.doccoldate },
      { label: "Bayan Issued", val: acc.bayanissue, date: acc.bayanissuedate },
      { label: "EPA Approval", val: acc.epa, date: acc.epadate },
      { label: "PAI Approval", val: acc.pai, date: acc.paidate },
      {
        label: "Clearance Complete",
        val: acc.cleardone,
        date: acc.cleardonedate,
      },
    ];

    return (
      <div>
        <Div14>Approval Summary</Div14>
        <Descriptions bordered size="small">
          {data.map((val, index) => (
            <Descriptions.Item
              key={index}
              label={val.label}
              span={2}
              labelStyle={{
                textAlign: "right",
                fontWeight: "bold",
                width: "30%",
              }}
            >
              <StatusIndicator data={val} key={index} />
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    );
  }
}

export default observer(ApprovalSummary);
