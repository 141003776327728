import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class CombinedCalendarStore {
  constructor() {
    this.dates = [];
    this.converted = [];
    this.loading = false;
    this.dataloaded = false;

    this.startdate = moment().subtract(30, "days").startOf("month");
    this.filter = {
      book: true,
      conv: true,
      ast: true,
      adel: true,
      acc: true,
      aim: true,
      im: true,
      lm: true,
      cc: true,
      st: true,
    };

    makeObservable(this, {
      dates: observable,
      converted: observable,
      loading: observable,
      dataloaded: observable,
      filter: observable,
      startdate: observable,
      filtdates: computed,
    });
  }

  // FETCH
  getResetOverride() {
    this.dataloaded = false;
    this.cancelled = [];
    this.converted = [];
    this.getCalendarMain(true);
  }

  getCalendarMain(override) {
    let loaded;
    if (this.loading || this.dataloaded) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.dataloaded = false;
      this.loading = true;
      let data = { startdate: this.startdate };
      return instance
        .put(`/job/sidejobs/sjs/getcombinedcalendar`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let dates = [...data];

          this.dataloaded = true;
          this.loading = false;
          this.dates = dates;
          console.log("Calendar Jobs Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get filtdates() {
    let dates = [...this.dates];
    if (!this.filter.lead) {
      dates = dates.filter((date) => date.status !== "Lead");
    }
    if (!this.filter.prop) {
      dates = dates.filter((date) => date.status !== "Proposal");
    }
    if (!this.filter.book) {
      dates = dates.filter((date) => date.status !== "Booked");
    }
    if (!this.filter.conv) {
      dates = dates.filter(
        (date) => date.status !== "Converted" || date.status !== "Completed"
      );
    }
    if (!this.filter.lm) {
      dates = dates.filter((date) => date.jobtype !== "LM");
    }
    if (!this.filter.im) {
      dates = dates.filter((date) => date.jobtype !== "IM");
    }
    if (!this.filter.cc) {
      dates = dates.filter((date) => date.jobtype !== "CC");
    }
    if (!this.filter.st) {
      dates = dates.filter((date) => date.jobtype !== "ST");
    }
    if (!this.filter.ast) {
      dates = dates.filter((date) => date.jobtype !== "AST");
    }
    if (!this.filter.adel) {
      dates = dates.filter((date) => date.jobtype !== "DEL");
    }
    if (!this.filter.aim) {
      dates = dates.filter((date) => date.jobtype !== "AIM");
    }
    if (!this.filter.acc) {
      dates = dates.filter((date) => date.jobtype !== "ACC");
    }
    return dates;
  }

  // FUNCTIONS:
  changeFilt(label) {
    this.filter[label] = !this.filter[label];
  }
  changeDate(val) {
    combinedCalendarStore.startdate = moment(val).startOf("month");
  }
}

const combinedCalendarStore = new CombinedCalendarStore();
export default combinedCalendarStore;
