import { Spin } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Divider, Icon, IconButton } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";

import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import ACCTabs from "./ACCTabs";

class ACCDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Orders" };
  }
  componentDidMount() {
    singleAccountStore.getAccount(this.props.match.params.clientid);
  }
  render() {
    let loading =
      singleAccountStore.loading.acorders || singleAccountStore.loading.account;
    return (
      <div>
        <Spin
          spinning={loading}
          size="large"
          tip="Loading..."
          style={{
            position: "fixed",
            right: "50%",
            bottom: "50%",
          }}
        >
          <Div14>
            Custom Clearance Account Page
            <br />
            <i style={{ fontWeight: "normal", fontSize: 12 }}>
              Account {singleAccountStore.account.clientNumId} -{" "}
              {singleAccountStore.account.name}
            </i>
            {!this.props.hidecomp && (
              <>
                <Divider vertical />
                <IconButton
                  size="xs"
                  icon={<Icon icon="web" />}
                  appearance="ghost"
                  color="green"
                  onClick={() =>
                    this.props.history.push(
                      `/ac/dashboard/${this.props.match.params.clientid}`
                    )
                  }
                >
                  Account Dashboard
                </IconButton>
              </>
            )}
          </Div14>
          <div style={{ padding: "1%" }}>
            <ACCTabs clientid={this.props.match.params.clientid} />
          </div>
        </Spin>
      </div>
    );
  }
}

export default withRouter(observer(ACCDashboard));
