import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Icon, IconButton } from "rsuite";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Item } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { Toolbar, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";

import { Tooltip } from "@material-ui/core";

import { withRouter } from "react-router";
import userStore from "../../../../../../Stores/UserStore";
import acClearanceStore from "../../../../../../Stores/AccountUpdated/ACC/ACClearanceStore";
import newACCOrderStore from "../../../../../../Stores/AccountUpdated/ACC/NewACCStore";

class OrderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };

    this.onExporting = this.onExporting.bind(this);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Order_List_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  StorDate(cellData) {
    let data = cellData.data;
    if (data.status === "Completed") {
      return moment(data.jobdate).format("DD-MMM-YY");
    } else {
      return moment(data.jobdate).format("DD-MMM-YY");
    }
  }
  StatusCell(cellData) {
    let data = cellData.data.status;
    return (
      <div
        style={{
          fontWeight: "bold",
          color:
            data === "Requested"
              ? "#f59f00"
              : data === "Cancelled"
              ? "red"
              : data === "Delivered"
              ? "black"
              : "green",
        }}
      >
        {data}
      </div>
    );
  }
  ShippingMethodCell(cellData) {
    let data = cellData.data.shippingmode;
    let icon = "ship";
    if (data === "Air") icon = "plane";
    if (data === "Land") icon = "truck";
    return (
      <div>
        <Icon icon={icon} size="xs" /> {data}
      </div>
    );
  }
  render() {
    let data = this.props.data;
    let type = this.props.type;

    let showadd = false;
    if (userStore.user.staffaccount) showadd = true;
    if (userStore.user.comprequest) showadd = true;
    if (type !== "Current") showadd = false;

    let content = (
      <DataGrid
        dataSource={data}
        keyExpr="accOrderNumId"
        allowColumnReordering={true}
        allowColumnResizing={true}
        showBorders={true}
        rowAlternationEnabled
        onExporting={this.onExporting}
        style={{ fontSize: 10 }}
        hoverStateEnabled={true}
      >
        <FilterRow visible={true} applyFilter />
        <GroupPanel visible={true} />
        <SearchPanel visible={true} />
        <Paging defaultPageSize={10} defaultPageIndex={0} />
        <Pager
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={[10, 20, 50, 100]}
          showNavigationButtons={true}
        />
        <Scrolling mode="standard" showScrollbar="always" />
        <ColumnChooser enabled={true} mode="select" />
        <ColumnFixing enabled={true} />
        <Column
          caption="Actions"
          alignment={"center"}
          cellRender={this.props.actionButs}
          width={100}
        />
        <Column
          dataField="accOrderNumId"
          caption="ID"
          alignment="center"
          width={80}
        />
        <Column caption="Order Details" alignment={"center"}>
          <Column
            dataField="shippingmode"
            caption="Method"
            alignment="center"
            minWidth={80}
            cellRender={this.ShippingMethodCell}
          />
          <Column
            dataField="shippingline"
            caption="Shipped By"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="blnumber"
            caption="BL Number"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="originlocation"
            caption="Origin"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="status"
            caption="Status"
            alignment="center"
            minWidth={80}
            cellRender={this.StatusCell}
          />
          <Column
            dataField="vesselarrival"
            caption="Vessel Arrival"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
            sortOrder={"asc"}
            sortIndex={2}
          />
          <Column
            dataField="container"
            caption="Container"
            alignment="center"
            minWidth={100}
          />
          <Column
            dataField="containercount"
            caption="# Containers"
            alignment="center"
            minWidth={100}
          />
        </Column>

        <Column
          dataField="lastprice"
          caption="Job Cost"
          alignment="center"
          minWidth={80}
          format={{ type: "fixedPoint", precision: 3 }}
        />
        <Column caption="Activity Dates">
          <Column
            dataField="doccoldate"
            caption="Doc Collected"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="bayanissuedate"
            caption="Bayan Issued"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="epadate"
            caption="EPA Date"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="paidate"
            caption="PAI Date"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="cleardonedate"
            caption="Clearance Date"
            dataType={"date"}
            format="dd-MMM-yy"
            alignment="center"
            minWidth={80}
          />
        </Column>
        <Summary>
          <TotalItem
            column="shippingmode"
            summaryType="count"
            displayFormat={"{0} jobs"}
          />
          <TotalItem
            column="lastprice"
            summaryType="sum"
            valueFormat={"#,##0.000"}
            displayFormat={"{0} KD"}
          />
        </Summary>
        <Export enabled={true} />
        <Toolbar>
          <Item name="Refresh" location="before">
            <div style={{ paddingLeft: "4px" }}>
              <Tooltip title={`Refresh`} arrow placement="right">
                <IconButton
                  icon={<Icon icon="refresh" />}
                  onClick={() => {
                    if (type === "Current") {
                      acClearanceStore.getOrders(false, true);
                    } else if (type === "Past") {
                      acClearanceStore.getCustomOrderStatus("Completed", true);
                    } else if (type === "Cancel") {
                      acClearanceStore.getCustomOrderStatus("Cancelled", true);
                    }
                  }}
                  appearance="default"
                  style={{ border: "1px solid black" }}
                />
              </Tooltip>
            </div>
          </Item>
          {showadd && (
            <Item name="New Order" location="after">
              <div style={{ paddingLeft: "4px" }}>
                <Tooltip title={`New Order`} arrow placement="right">
                  <IconButton
                    color="green"
                    icon={<Icon icon="plus" />}
                    onClick={() => {
                      newACCOrderStore.getAccount(
                        acClearanceStore.client._id,
                        true
                      );
                      newACCOrderStore.createNewOrder();

                      this.props.history.push(
                        `/acc/new/${acClearanceStore.client._id}`
                      );
                    }}
                    appearance="default"
                    style={{ border: "1px solid grey" }}
                  />
                </Tooltip>
              </div>
            </Item>
          )}
          <Item name="columnChooserButton" location="after" />
        </Toolbar>
      </DataGrid>
    );

    return <div>{content}</div>;
  }
}

export default withRouter(observer(OrderMain));
