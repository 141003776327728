import { observer } from "mobx-react";
import React, { Component } from "react";
import { Descriptions } from "antd";
import Div14 from "../../../../../Components/Dividers/Div14";

class LocationsSummaryList extends Component {
  render() {
    let order = this.props.order;

    let locmap = order.toaddress.map((loc, index) => (
      <div key={index}>
        <Div14>
          <b>Location {index + 1}: </b>
          {order.jobtype === "Delivery In" ? "Pickup" : "Delivery"}
        </Div14>
        <Descriptions
          bordered
          key={index}
          size="small"
          style={{ textAlign: "center" }}
          title={false}
        >
          <Descriptions.Item label="Address" span={3}>
            {loc.address}
          </Descriptions.Item>
          <Descriptions.Item label="Type">{loc.type}</Descriptions.Item>
          <Descriptions.Item label="Floor">{loc.floor}</Descriptions.Item>
          <Descriptions.Item label="Phone">{loc.phone}</Descriptions.Item>
          <Descriptions.Item label="Name">{loc.name}</Descriptions.Item>
          <Descriptions.Item label="City">{loc.city}</Descriptions.Item>
        </Descriptions>
      </div>
    ));

    return <div>{locmap}</div>;
  }
}

export default observer(LocationsSummaryList);
