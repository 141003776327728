import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../Static/baseURL/baseURL";
import userStore from "./UserStore";
import { SJProposals } from "../Functions/JobFinances/SJProposals";
import sjStore from "./SideJobs/SJStore";
import invoiceStore from "./SideJobs/InvoiceStore";
import paymentStore from "./SideJobs/PaymentStore";

const instance = axios.create({ baseURL: baseURL });

class SingleJobStore {
  constructor() {
    this.client = {};
    this.editclient = {};
    this.job = { focalpoint: { _id: "", username: "" } };
    this.recprops = [];
    this.proposals = [];
    this.invoices = [];

    this.editjob = {};
    this.hideCancelled = true;
    this.loading = false;
    this.sideLoad = { invoice: false, payment: false };

    makeObservable(this, {
      editclient: observable,
      editjob: observable,
      recprops: observable,

      client: observable,
      job: observable,
      hideCancelled: observable,
      loading: observable,
      sideLoad: observable,
    });
  }

  // FETCH
  getJob(jobId, overide) {
    if (!overide) {
      if (this.loading || this.job._id === jobId) return null;
    }
    this.loading = true;
    return instance
      .get(`/job/basic/getjob/${jobId}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((job) => {
        this.job = job.job;
        this.editjob = { ...job.job };
        this.client = job.job.client;
        this.editclient = { ...job.job.client };
        this.loading = false;
        console.log("Job Fetched");
        sjStore.getSJs(jobId, overide);
        invoiceStore.getInvoiceByJob(jobId, overide);
        paymentStore.getPaymentById(jobId, overide);
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  updateProposals(sjs) {
    let newlist = SJProposals(sjs, this.job);
    this.recprops = [...newlist];
  }

  // ---- Edit Client ---
  editEditClient(type, val) {
    this.editclient[type] = val;
  }

  editMainClient(type, val) {
    this.client[type] = val;
  }

  // ---- Edit Job ---
  editEditJob(type, val) {
    this.editjob[type] = val;
  }

  editMainJob(type, val) {
    this.job[type] = val;
  }

  startLoading() {
    this.loading = true;
  }
  changeCancelled() {
    this.hideCancelled = !this.hideCancelled;
  }
}

const singleJobStore = new SingleJobStore();
export default singleJobStore;
