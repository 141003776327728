import React, { Component } from "react";

import { Steps } from "rsuite";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import MaterialTable from "material-table";
import LoadBack from "../../../Components/Loaders/LoadBack";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";
import Col2 from "../../../Components/Columns/Col2";
import moment from "moment";
import { Descriptions } from "antd";
import Div14 from "../../../Components/Dividers/Div14";

class SinglePalletModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let show = this.props.show;

    let pallet = whmSinglePalletStore.pallet;
    let palletitem = whmSinglePalletStore.palletitems;

    let columnnames = [
      { title: "ID", field: "acitem.acItemNumId", defaultSort: "desc" },
      { title: "Quantity", field: "quantity" },
      { title: "Name", field: "acitem.name" },
      { title: "Serial", field: "acitem.serialnumber" },
      {
        title: "CBM",
        field: "totalcbm",
        render: (rowData) => PRKD(rowData.totalcbm),
      },
    ];
    let footer = (
      <FlexboxGrid>
        <Col2>
          <IconButton
            icon={<Icon icon="close" />}
            color="red"
            appearance="ghost"
            onClick={() => this.props.onHide()}
            block
          >
            Close
          </IconButton>
        </Col2>
      </FlexboxGrid>
    );
    if (this.props.actionbut) {
      footer = (
        <ModalFooter
          icon={["close", "pencil"]}
          color={["red", "orange"]}
          app={["ghost", "default"]}
          text={["Cancel", this.props.actionbuttext]}
          oC1={() => this.props.onHide()}
          oC2={() => {
            this.props.onEdit(pallet);
          }}
        />
      );
    }

    let step = 1;
    if (pallet.status === "Prepared") step = 1;
    if (pallet.status === "Loaded") step = 2;
    if (pallet.status === "Unloaded") step = 3;
    if (pallet.status === "Stacked") step = 4;

    return (
      <UnifiedModal
        show={show}
        onHide={this.props.onHide}
        title={`Pallet #${pallet.whmPalletNumId} Details`}
        footer={footer}
      >
        {whmSinglePalletStore.loaded.pallet ? (
          <div>
            <Descriptions title={false} bordered size="small">
              <Descriptions.Item label="Status">
                {pallet.status}{" "}
              </Descriptions.Item>
              <Descriptions.Item label="Items">
                {PRKD(palletitem.length)} Items
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                {PRKD(pallet.qty)} QTY
              </Descriptions.Item>
              <Descriptions.Item label="CBM">
                {PRKD(pallet.totalcbm)} CBM
              </Descriptions.Item>
            </Descriptions>
            <Div14>Items List</Div14>
            <MaterialTable
              columns={columnnames}
              style={{ fontSize: 12 }}
              data={palletitem}
              title={null}
              options={{
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: false,
                search: false,
                toolbar: false,
                toolbarButtonAlignment: "left",
                maxBodyHeight: 1000,
                tableLayout: "auto",
                exportButton: false,
                padding: "dense",
              }}
            />
            <hr />
            <Steps current={step} vertical={window.innerWidth < 600}>
              <Steps.Item
                title={"Prepared"}
                description={moment(pallet.createdAt).format("DD-MMM-YY HH:mm")}
              />

              <Steps.Item
                title={"Loaded"}
                description={
                  pallet.loadeddate
                    ? moment(pallet.loadeddate).format("DD-MMM-YY HH:mm")
                    : "Pending"
                }
              />
              <Steps.Item
                title={"Unloaded"}
                description={
                  pallet.unloadeddate
                    ? moment(pallet.unloadeddate).format("DD-MMM-YY HH:mm")
                    : "Pending"
                }
              />
              <Steps.Item
                title={"Stacked"}
                description={
                  pallet.stackeddate
                    ? moment(pallet.stackeddate).format("DD-MMM-YY HH:mm")
                    : "Pending"
                }
              />
            </Steps>
          </div>
        ) : (
          <LoadBack loading={true} />
        )}
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(SinglePalletModal));
