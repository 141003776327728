import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon, Col, HelpBlock } from "rsuite";
import invoiceStore from "../../../../../Stores/SideJobs/InvoiceStore";
import singleJobStore from "../../../../../Stores/SingleJobStore";
import InvoiceEditMain from "./InvoiceEdit/InvoiceEditMain";
import InvoiceTable from "./InvoiceTable/InvoiceTable";
import { Tooltip } from "@material-ui/core";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import { SJPriceCalc } from "../../../../../Functions/Pricing/SJPriceCalc";
import sjStore from "../../../../../Stores/SideJobs/SJStore";
class InvoiceTab extends Component {
  constructor(props) {
    super(props);
    this.state = { new: false };
    this.changeST = this.changeST.bind(this);
  }

  componentDidMount() {
    invoiceStore.getInvoiceByJob(singleJobStore.job._id);
  }
  changeST(val, label) {
    this.setState({ [label]: val });
  }
  render() {
    let editview = this.props.editview;

    let total = 0;
    for (let i = 0; i < invoiceStore.invoices.length; i++) {
      if (invoiceStore.invoices[i].status !== "Cancelled") {
        total += parseFloat(invoiceStore.invoices[i].total);
      }
    }

    let totalprice = SJPriceCalc(
      sjStore.sjs.filter((sj) => sj.status !== "Cancelled")
    );
    let invoices = invoiceStore.invoices.filter(
      (inv) => inv.status !== "Cancelled"
    );
    let totalinv = 0;
    for (let i = 0; i < invoices.length; i++) {
      totalinv += parseFloat(invoices[i].total);
    }
    let diff = PRKD(parseFloat(totalprice) - parseFloat(totalinv));
    let qualitytext;
    if (this.props.quality) {
      if (diff < 0) {
        qualitytext = (
          <HelpBlock style={{ color: "red", fontWeight: "bold" }}>
            {diff} KD Over Invoiced.
          </HelpBlock>
        );
      } else if (diff > 0) {
        qualitytext = (
          <HelpBlock style={{ color: "red", fontWeight: "bold" }}>
            {diff} KD Remaining to Invoice.
          </HelpBlock>
        );
      } else {
        qualitytext = (
          <HelpBlock style={{ color: "green", fontWeight: "bold" }}>
            Invoice and Job Price Match.
          </HelpBlock>
        );
      }
    }

    return (
      <div>
        {!this.state.new && (
          <FlexboxGrid
            justify="space-between"
            style={{ paddingRight: "10%", paddingLeft: "10%" }}
          >
            <Col>
              {editview && (
                <Tooltip title={"Add New Invoice"} arrow placement="top">
                  <IconButton
                    color="green"
                    appearance="ghost"
                    circle
                    icon={<Icon icon="plus" />}
                    onClick={() => {
                      invoiceStore.createNewInvoice();
                      this.setState({ new: true });
                    }}
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <h4>Invoices (Total {PRKD(total)} KWD)</h4>
              {qualitytext}
            </Col>
            <Col>
              <Tooltip title={"Refresh Invoices"} arrow placement="top">
                <IconButton
                  circle
                  appearance="ghost"
                  icon={<Icon icon="refresh" />}
                  onClick={() => {
                    invoiceStore.getInvoiceByJob(singleJobStore.job._id, true);
                  }}
                />
              </Tooltip>
            </Col>
          </FlexboxGrid>
        )}
        <br />
        {this.state.new ? (
          <InvoiceEditMain changeST={this.changeST} />
        ) : (
          <InvoiceTable editview={editview} changeST={this.changeST} />
        )}
      </div>
    );
  }
}

export default observer(InvoiceTab);
