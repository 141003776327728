import React, { Component } from "react";

import {
  DataSheetGrid,
  floatColumn,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";

import "react-datasheet/lib/react-datasheet.css";
import { observer } from "mobx-react";
import { message } from "antd";
import newStorageStore from "../../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import UnifiedModal from "../../../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../../../Components/Modal/ModalFooter";

class AddBulkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      grid: [
        {
          itemname: "",
          itemserialnumber: "",
          itemproductid: "",
          cbm: 0,
          quantity: 1,
          itemlength: 0,
          itemwidth: 0,
          itemheight: 0,
        },
      ],
    };
  }

  postNew = (grid) => {
    let data;
    for (let i = 0; i < grid.length; i++) {
      data = grid[i];
      data.actualquantity = grid[i].quantity;
      newStorageStore.addEditSingleItem("New", data, true, this.props.index);
    }
    message.success(`${grid.length} Items Added`);
    this.cancelChanges();
  };

  cancelChanges() {
    this.setState({
      show: false,
      loading: false,
      grid: [
        {
          itemname: "",
          itemserialnumber: "",
          itemproductid: "",
          cbm: 0,
          quantity: 1,
          itemlength: 0,
          itemwidth: 0,
          itemheight: 0,

          remarks: "",
        },
      ],
    });
    this.props.onHide();
  }

  render() {
    const columns = [
      { ...keyColumn("itemname", textColumn), title: "Item Name*" },
      { ...keyColumn("itemserialnumber", textColumn), title: "Serial No.*" },
      {
        ...keyColumn("itemproductid", textColumn),
        title: "Product ID (optional)",
      },
      { ...keyColumn("itemlength", floatColumn), title: "L (cm)" },
      { ...keyColumn("itemwidth", floatColumn), title: "W (cm)" },
      { ...keyColumn("itemheight", floatColumn), title: "H (cm)" },
      { ...keyColumn("quantity", floatColumn), title: "Qty*" },
    ];

    let disabled = false;
    let grid = this.state.grid;
    for (let i = 0; i < grid.length; i++) {
      if (grid[i].quantity === null) disabled = true;
      if (grid[i].itemlength === null) disabled = true;
      if (grid[i].itemwidth === null) disabled = true;
      if (grid[i].itemwidth === "null") disabled = true;
      if (grid[i].itemheight === null) disabled = true;
      if (grid[i].itemheight === "") disabled = true;
      if (grid[i].itemname === null) disabled = true;
      if (grid[i].itemname === "") disabled = true;
      if (grid[i].itemserialnumber === null) disabled = true;
      if (grid[i].itemserialnumber === "") disabled = true;
    }

    return (
      <UnifiedModal
        title={"Add Bulk Items"}
        show={this.props.show}
        onHide={this.props.onHide}
        footer={
          <ModalFooter
            icon={["close", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={["Cancel", "Save and Add"]}
            oC1={() => this.cancelChanges()}
            oC2={() => this.postNew(this.state.grid)}
            disabled2={disabled}
          />
        }
      >
        <DataSheetGrid
          value={this.state.grid}
          onChange={(e) => {
            this.setState({ grid: e });
          }}
          columns={columns}
          autoAddRow
          style={{ backgroundColor: "red" }}
          createRow={() => ({
            itemname: "",
            itemserialnumber: "",
            productid: "",
            itemlength: 0,
            itemwidth: 0,
            itemheight: 0,
            quantity: 1,
          })}
        />
      </UnifiedModal>
    );
  }
}

export default observer(AddBulkList);
