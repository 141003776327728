import React from "react";
import { useLocation } from "react-router-dom";
import pagetitles from "./PageTitles";

const LocationHeader = () => {
  const location = useLocation();
  let title = location.pathname.slice(1);
  let cutoff = title.indexOf("/");
  if (cutoff > 0) title = title.substring(0, cutoff);

  let titlevalue = pagetitles.findIndex((item) => item.path === title);
  if (titlevalue < 0) {
    title = title.charAt(0).toUpperCase() + title.slice(1);
  } else {
    title = pagetitles[titlevalue].title;
  }

  return <div>{title}</div>;
};

export default LocationHeader;
