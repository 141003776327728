import { withRouter } from "react-router-dom";
import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Divider, Icon, IconButton, Loader } from "rsuite";
import carStore from "../../Stores/AdditionalStores/CarStore";
import Div14 from "../Dividers/Div14";
import DeliveryMap from "../../Pages/Management/CarMaps/DeliveryMap";

class LiveTracking extends Component {
  componentDidMount() {
    carStore.selectCarId(this.props.truckid);
  }
  render() {
    if (carStore.loading) {
      return (
        <div>
          <Loader center />
        </div>
      );
    }

    let cars = carStore.singlecar;

    if (cars.length === 0) {
      return <Empty description="Truck Not Found" />;
    }

    let carTrack = (
      <div>
        <Div14>
          Live Tracking <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            circle
            small
            onClick={() => carStore.selectCarId(this.props.truckid)}
          />
        </Div14>
        <DeliveryMap markers={cars} />
      </div>
    );
    return (
      <div>
        {carTrack}
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(LiveTracking));
