import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableRow8a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "20%",
    paddingLeft: 2,
  },
  tableRow8b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
    paddingLeft: 2,
  },
});

class KFHPage extends Component {
  render() {
    return (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>Beneficiary Name</Text>
          <Text style={styles.tableRow8b}>Easy move Logistics Co.</Text>
          <Text style={styles.tableRow8a}>Bank Name</Text>
          <Text style={styles.tableRow8b}>Kuwait Finance House</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>Account Number</Text>
          <Text style={styles.tableRow8b}>0910 1019 7695</Text>
          <Text style={styles.tableRow8a}>Bank Address</Text>
          <Text style={styles.tableRow8b}>Al Faiha Branch</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>IBAN Number:</Text>
          <Text style={styles.tableRow8b}>
            KW21 KFHO 0000 0000 0009 1010 1976 95
          </Text>
          <Text style={styles.tableRow8a}>SWIFT Code:</Text>
          <Text style={styles.tableRow8b}>KFHOKWKW</Text>
        </View>
      </View>
    );
  }
}

export default observer(KFHPage);
