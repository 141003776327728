import { observer } from "mobx-react";
import React, { Component } from "react";
import IntroPage from "./IntroPage";

class MainACCInvoice extends Component {
  render() {
    let inv = this.props.inv;
    let content;

    content = <IntroPage inv={inv} />;

    return <div>{content}</div>;
  }
}

export default observer(MainACCInvoice);
