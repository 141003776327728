import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Descriptions } from "antd";
import moment from "moment";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import { Steps } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";

class TruckTripSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let truck = this.props.truck;
    let trucktrip = this.props.trucktrip;
    let step = 0;
    if (!truck) {
      return (
        <div style={{ margin: "1%" }}>
          <LoadBack loading={this.props.loading}>
            <Descriptions title={`Truck Trip # `} bordered size="small">
              <Descriptions.Item label="Status"></Descriptions.Item>
              <Descriptions.Item label="Truck"></Descriptions.Item>
              <Descriptions.Item label="Pallet Count"></Descriptions.Item>
              <Descriptions.Item label="Total CBM"></Descriptions.Item>
              <Descriptions.Item label="QTY Count"></Descriptions.Item>
              <Descriptions.Item label="Loading Started"></Descriptions.Item>
              <Descriptions.Item label="Loaded Complete"></Descriptions.Item>
              <Descriptions.Item label="Unloading Started"></Descriptions.Item>
              <Descriptions.Item label="Unloading Complete"></Descriptions.Item>
            </Descriptions>
            <br />
            <Steps current={step}>
              <Steps.Item
                title={window.innerWidth > 600 ? "Loading Start" : false}
              />
              <Steps.Item
                title={window.innerWidth > 600 ? "Loading End" : false}
              />
              <Steps.Item
                title={window.innerWidth > 600 ? "Unloading Start" : false}
              />
              <Steps.Item
                title={window.innerWidth > 600 ? "Unloading End" : false}
              />
            </Steps>
          </LoadBack>
        </div>
      );
    }

    if (trucktrip.status === "Loading Start") step = 0;
    if (trucktrip.status === "Loading End") step = 1;
    if (trucktrip.status === "Unloading Start") step = 2;
    if (trucktrip.status === "Unloading End") step = 3;

    return (
      <LoadBack loading={this.props.loading}>
        <div style={{ margin: "1%" }}>
          <Descriptions
            title={
              !this.props.hideTitle &&
              `Truck Trip # ${trucktrip.whmTruckTripNumId}`
            }
            bordered
            size="small"
          >
            <Descriptions.Item label="Status">
              {" "}
              {trucktrip.status}{" "}
            </Descriptions.Item>
            <Descriptions.Item label="Truck">
              {truck.whmTruckNumId} - {truck.name}
            </Descriptions.Item>
            <Descriptions.Item label="Pallet Count">
              {PRKD(trucktrip.pallets.length)} Pallets
            </Descriptions.Item>
            <Descriptions.Item label="Total CBM">
              {trucktrip.pallets.length <= 0 ? 0 : PRKD(trucktrip.cbm)} CBM
            </Descriptions.Item>
            <Descriptions.Item label="QTY Count">
              {trucktrip.pallets.length <= 0 ? 0 : PRKD(trucktrip.qty)} QTY
            </Descriptions.Item>
            <Descriptions.Item label="Loading Started">
              {moment(trucktrip.createdAt).format("DD-MMM-YY HH:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="Loaded Complete">
              {trucktrip.loadingend
                ? moment(trucktrip.loadingend).format("DD-MMM-YY HH:mm")
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Unloading Started">
              {trucktrip.unloadingstart
                ? moment(trucktrip.unloadingstart).format("DD-MMM-YY HH:mm")
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Unloading Complete">
              {trucktrip.unloadingend
                ? moment(trucktrip.unloadingend).format("DD-MMM-YY HH:mm")
                : ""}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Steps current={step}>
            <Steps.Item
              title={window.innerWidth > 600 ? "Loading Start" : false}
            />
            <Steps.Item
              title={window.innerWidth > 600 ? "Loading End" : false}
            />
            <Steps.Item
              title={window.innerWidth > 600 ? "Unloading Start" : false}
            />
            <Steps.Item
              title={window.innerWidth > 600 ? "Unloading End" : false}
            />
          </Steps>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(TruckTripSummary));
