import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Div14 from "../../../../../Components/Dividers/Div14";
import { Card, CardActionArea } from "@material-ui/core";
import { Collapse, Empty, Statistic } from "antd";
import {
  Divider,
  FlexboxGrid,
  HelpBlock,
  Icon,
  IconButton,
  Loader,
} from "rsuite";
import OrderSummaryTable from "../../Modals/Supporting/OrderSummaryTable";

import TimelineComp from "../../../../../Components/Timeline/TimelineComp";
import StoActualQuantityRow from "./StoActualQuantityRow";
import completeStorStore from "../../../../../Stores/AccountUpdated/Storage/CompleteStorStore";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import Col4 from "../../../../../Components/Columns/Col4";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import Col2 from "../../../../../Components/Columns/Col2";
import ItemsList from "../../Modals/Supporting/ItemsList";

import DeliveryNotFound from "./DeliveryNotFound";
import CloseStorInOut from "./CloseStorInOut";

const { Panel } = Collapse;

class StorageDelOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      showdelete: false,
      signed: false,
      itemmove: { acitem: {} },
      loading: false,
    };
    this.onHide = this.onHide.bind(this);
    this.showDelModal = this.showDelModal.bind(this);
    this.changeStep = this.changeStep.bind(this);
  }
  componentDidMount() {
    completeStorStore.getSingleACOrder(this.props.match.params.acorderid, true);
    this.setState({
      step: 1,
      showdelete: false,

      itemmove: { acitem: {} },
      loading: false,
    });
  }

  showDelModal(itemmove) {
    this.setState({ showdelete: true, itemmove: itemmove });
  }
  onHide() {
    this.setState({
      showdelete: false,
      itemmove: { acitem: {} },
      loading: false,
    });
  }
  changeStep() {
    this.setState({ step: this.state.step - 1 });
  }

  render() {
    let css = completeStorStore;
    let sto = css.neworder;

    let account = css.account;
    let type = css.delstoinout.type;

    let allsteps = ["Confirm Items", `Sign ${type} Note`, `${type} Completed`];

    let step = this.state.step;

    let allitemmoves = css.allitemmoves;

    if (completeStorStore.loading) {
      return <Loader center />;
    }

    let quantrows = <Empty description="No Items Available" />;
    if (allitemmoves.length > 0) {
      quantrows = allitemmoves.map((item, index) => (
        <StoActualQuantityRow
          itemmove={item}
          key={index}
          index={index}
          disabled={false}
          delItem={this.showDelModal}
        />
      ));
    }

    let actionButs = (
      <FlexboxGrid justify="center">
        <Col2>
          <ModalFooter
            icon={[
              this.state.step === 1 ? "trash" : "left",
              this.state.step === 3 ? "save" : "right",
            ]}
            color={["red", "green"]}
            app={["default", "default"]}
            text={[
              this.state.step === 1 ? "Cancel" : "Back",
              this.state.step === 3 ? `Complete ${type}` : "Next",
            ]}
            oC1={() => {
              if (this.state.step === 1) {
                this.props.history.push(`/ac/dashboard/${css.account._id}`);
              } else {
                this.setState({ step: this.state.step - 1 });
              }
            }}
            oC2={() => {
              if (this.state.step < 3) {
                if (this.state.step === 1) {
                  completeStorStore.changeFilt("");
                }
                this.setState({ step: this.state.step + 1 });
              }
            }}
            loading1={this.state.loading || completeStorStore.loading}
            loading2={this.state.loading || completeStorStore.loading}
          />
        </Col2>
      </FlexboxGrid>
    );
    if (this.state.step === 3) actionButs = <div />;

    let content;
    if (completeStorStore.loading) {
      content = <Loader center />;
    } else {
      if (this.state.step === 1) {
        content = (
          <div>
            <Div14>
              Select Items <Divider vertical />{" "}
              <IconButton
                icon={<Icon icon="undo" />}
                circle
                size="xs"
                onClick={() => {
                  completeStorStore.getSingleACOrderFromTo(false, true);
                }}
              />
            </Div14>
            {quantrows}
          </div>
        );
      } else if (this.state.step === 2) {
        content = (
          <div>
            <Div14>
              Confirm Items <Divider vertical />{" "}
              <IconButton
                icon={<Icon icon="undo" />}
                circle
                size="xs"
                onClick={() => {
                  completeStorStore.getSingleACOrderFromTo(false, true);
                }}
              />
            </Div14>
            <ItemsList toadd={sto.toaddress} hideaction showmissing />
          </div>
        );
      } else if (this.state.step === 3) {
        content = <CloseStorInOut type={type} changeStep={this.changeStep} />;
      }
    }

    let orderstatus = sto.status;
    if ((orderstatus !== "Booked") & (orderstatus !== "In Transit")) {
      return <DeliveryNotFound />;
    } else if (sto.storSignature.name !== "") {
      return <DeliveryNotFound />;
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["trash", "close"]}
              color={["red", "green"]}
              app={["default", "default"]}
              text={["Delete from Order", "Cancel"]}
              oC1={() => this.postDelete(this.state.itemmove)}
              oC2={this.onHide}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          title={"Remove Item from Delivery"}
        >
          <h6>Are you sure you want to Delete this item from the delivery?</h6>
          <p>
            <b>Item Name: </b> {this.state.itemmove.acitem.name} <br />
            <b>Product ID: </b> {this.state.itemmove.acitem.productid} <br />
            <b>Serial Number: </b> {this.state.itemmove.acitem.serialnumber}{" "}
            <br />
            <b>Quantity: </b> {this.state.itemmove.quantity} <br />
          </p>
        </UnifiedModal>
        <Div14>Storage Out Confirmation</Div14>

        <Div14>Order Stats:</Div14>
        <FlexboxGrid style={{ textAlign: "center", fontWeight: "bold" }}>
          <Col4>
            <Card style={{ border: "4px solid black" }}>
              <CardActionArea>
                <h6>{type} Stats:</h6>
                <HelpBlock>
                  {PRKD(css.allItemMoveStats.selected.items)} Items
                </HelpBlock>
                <HelpBlock>
                  {PRKD(css.allItemMoveStats.selected.packages)} Packages
                </HelpBlock>
                <HelpBlock>
                  {PRKD(css.allItemMoveStats.selected.cbm)} CBM
                </HelpBlock>
              </CardActionArea>
            </Card>
          </Col4>
          <Col4>
            <Card
              style={{
                border:
                  css.filter === "confirmed" || css.filter === ""
                    ? "4px solid #3f8600"
                    : "",
              }}
            >
              <CardActionArea
                onClick={() => {
                  if (this.state.step === 1) {
                    completeStorStore.changeFilt("confirmed");
                  }
                }}
              >
                <Statistic
                  title="Confirmed"
                  value={
                    css.allItemMoveStats.confirmed === 0
                      ? "-"
                      : css.allItemMoveStats.confirmed
                  }
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </CardActionArea>
            </Card>
          </Col4>
          <Col4>
            <Card
              style={{
                border:
                  css.filter === "modified" || css.filter === ""
                    ? "4px solid orange"
                    : "",
              }}
            >
              <CardActionArea
                onClick={() => {
                  if (this.state.step === 1) {
                    completeStorStore.changeFilt("modified");
                  }
                }}
              >
                <Statistic
                  title="Modified"
                  value={
                    css.allItemMoveStats.modified === 0
                      ? "-"
                      : css.allItemMoveStats.modified
                  }
                  precision={0}
                />
              </CardActionArea>
            </Card>
          </Col4>
          <Col4>
            <Card
              style={{
                border:
                  css.filter === "missing" || css.filter === ""
                    ? "4px solid #cf1322"
                    : "",
              }}
            >
              <CardActionArea
                onClick={() => {
                  if (this.state.step === 1) {
                    completeStorStore.changeFilt("missing");
                  }
                }}
              >
                <Statistic
                  title="Missing"
                  value={
                    css.allItemMoveStats.missing === 0
                      ? "-"
                      : css.allItemMoveStats.missing
                  }
                  precision={0}
                  valueStyle={{ color: "#cf1322" }}
                />
              </CardActionArea>
            </Card>
          </Col4>
        </FlexboxGrid>
        <br />
        <TimelineComp steps={allsteps} step={step - 1} />
        <br />
        {actionButs}
        {content}
        <br />
        <Collapse>
          <Panel header="Order Summary">
            <FlexboxGrid>
              <OrderSummaryTable order={sto} account={account} hidetitle />
              <ItemsList toadd={sto.toaddress} hideaction showmissing />
            </FlexboxGrid>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default withRouter(observer(StorageDelOut));
