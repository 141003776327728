import { Alert } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../Components/Dividers/Div14";

class NewDelOptions extends Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <Div14>Delivery Types</Div14>
        <Alert
          message="Delivery Types can be added after creating account."
          type="warning"
          showIcon
          style={{ paddingLeft: "2%", paddingRight: "2%" }}
        />
      </div>
    );
  }
}

export default observer(NewDelOptions);
