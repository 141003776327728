import { observer } from "mobx-react";
import React, { Component } from "react";
import { Rate } from "rsuite";

class StarRatingSTD extends Component {
  render() {
    let max = 5;
    if (this.props.max) max = this.props.max;
    let size = "lg";
    if (this.props.size) size = this.props.size;

    let small = this.props.small;

    let options = {
      1: "Poor Service",
      2: "Below Average",
      3: "Average",
      4: "Good Service",
      5: "Excellent Service",
    };
    if (this.props.options) options = this.props.options;

    let val = this.props.val;
    let color = "default";
    if (val < 3) {
      color = "red";
    } else if (val < 5) {
      color = "blue";
    }

    if (small) {
      return (
        <Rate
          size={"xs"}
          color={color}
          defaultValue={val}
          max={max}
          value={val}
          readOnly
        />
      );
    }

    return (
      <div>
        <Rate
          size={size}
          color={color}
          defaultValue={val}
          onHo
          max={max}
          value={val}
          readOnly={this.props.disabled}
          onChangeActive={(e) => {
            if (e === "") e = 0;
            //this.props.editVal(e, this.props.label);
            //this.setState({ text: e });
          }}
          onChange={(e) => {
            if (e === "") e = 0;
            this.props.editVal(e, this.props.label);
            this.setState({ text: e });
          }}
        />
        <h6 style={{ textAlign: "center" }}>{options[val]}</h6>
      </div>
    );
  }
}

export default observer(StarRatingSTD);
