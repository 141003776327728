import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

import moment from "moment";

const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  tableLeft: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRight: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableCol70white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
});
class HeaderSection extends Component {
  render() {
    let inv = this.props.inv;
    let createdAt = moment().format("YYMMDDHH");
    if (inv.createdAt) createdAt = moment(inv.createdAt).format("YYMMDDHH");

    let invoiceID = inv.aimInvoiceNumId;
    if (invoiceID === "New") invoiceID = "???";
    let clientname = inv.clientname;
    let proposaltitle = inv.title;

    return (
      <View>
        <Text style={styles.header}>{proposaltitle}</Text>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>From:</Text>
          <Text style={styles.tableRight}>
            Date: {moment(inv.date).format("DD-MMM-YY")}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Easy Move Logistics Co.</Text>
          <Text style={styles.tableRight}>
            Invoice Number: ACC{invoiceID}-{createdAt}
          </Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Hawally, Kuwait</Text>
          <Text style={styles.tableRight}>Service: Supply Chain</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Phone: +965-22060969</Text>
        </View>
        <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
          <Text style={styles.tableLeft}>Email: info@easymovekw.com</Text>
        </View>
        <Text style={{ lineHeight: 2 }}> </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
            lineHeight: 1.5,
          }}
        >
          To: {clientname},
        </Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "Times-Roman",
          }}
        >
          Please find below the details of our fees for professional services:
        </Text>

        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Client</Text>
          <Text style={styles.tableCol70white}>{clientname}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Phone</Text>
          <Text style={styles.tableCol70white}>{inv.clientphone}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Email</Text>
          <Text style={styles.tableCol70white}>{inv.clientemail}</Text>
        </View>
      </View>
    );
  }
}

export default observer(HeaderSection);
