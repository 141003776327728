import { observer } from "mobx-react";
import React, { Component } from "react";

import { Badge, Descriptions, Tag } from "antd";

import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class OrderSummaryTable extends Component {
  render() {
    let del = this.props.del;

    let account = this.props.account;

    let timechg = this.props.timechg;

    let allservices = [];
    let uniqueAdds;

    if (newDeliveryStore.delCont.maintype === "Fixed Per Type") {
      for (let i = 0; i < del.toaddress.length; i++) {
        allservices = [
          ...allservices,
          {
            type: `${del.toaddress[i].service.type}`,
            price: del.toaddress[i].service.price,
          },
        ];
      }
    }

    let titems = 0;
    let tcbm = 0;
    let tpackages = 0;
    let tos = del.toaddress;
    for (let i = 0; i < tos.length; i++) {
      if (tos[i].items) {
        titems += tos[i].items.length;
        for (let k = 0; k < tos[i].items.length; k++) {
          tpackages += Parsed(tos[i].items[k].quantity);
          tcbm +=
            (Parsed(tos[i].items[k].length) *
              Parsed(tos[i].items[k].width) *
              Parsed(tos[i].items[k].height) *
              Parsed(tos[i].items[k].quantity)) /
            1000000;
        }
      }
    }

    if (del.maintype === "Fixed Per Type") {
      uniqueAdds = allservices.map((ser, index) => (
        <Descriptions.Item label={`Dropoff ${index + 1} Service:`} key={index}>
          {ser.type} ({PRKD(ser.price)} KD)
        </Descriptions.Item>
      ));
    } else {
      uniqueAdds = (
        <>
          <Descriptions.Item label={`CBM:`}>{PRKD(tcbm)} CBM</Descriptions.Item>
          <Descriptions.Item label={`Items:`}>
            {titems} Item{titems === 1 ? "" : "s"}
          </Descriptions.Item>
          <Descriptions.Item label={`Packages:`}>
            {PRKD(tpackages)} Pacakge{tpackages === 1 ? "" : "s"}
          </Descriptions.Item>
        </>
      );
    }

    let statuscolor = "green";
    let statustype = "default";
    if (del.status === "Requested") statuscolor = "orange";
    if (del.status === "Booked") statuscolor = "blue";
    if (del.status === "In Transit") {
      statuscolor = "blue";
      statustype = "processing";
    }
    if (del.status === "Completed") statuscolor = "green";
    if (del.status === "Cancelled") statuscolor = "red";

    return (
      <div>
        <Descriptions
          title={this.props.hidetitle ? false : "Order Summary"}
          bordered
          size="small"
        >
          <Descriptions.Item label="Delivery ID">
            {del.deliveryNumId}
          </Descriptions.Item>
          <Descriptions.Item label="Type">{del.maintype}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color={statuscolor} style={{ fontWeight: "bold" }}>
              <Badge
                status={statustype}
                style={{ color: statuscolor }}
                color={statuscolor}
                text={del.status}
              />
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Client">
            {account.clientNumId} - {account.name}
          </Descriptions.Item>
          <Descriptions.Item label="Pickup">
            {del.fromaddress.city}
          </Descriptions.Item>
          <Descriptions.Item label="Dropoffs">
            {del.toaddress.length}
          </Descriptions.Item>
          {uniqueAdds}
          {!this.props.hidecost && (
            <Descriptions.Item
              label="Total Job Cost"
              span={24}
              labelStyle={{ fontWeight: "bold" }}
              contentStyle={{ fontWeight: "bold" }}
            >
              {PRKD(timechg.finaljobcost)} KD
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  }
}

export default observer(OrderSummaryTable);
