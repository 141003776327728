import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { InputGroup, Icon, HelpBlock } from "rsuite";
import { Select } from "antd";
const { Option } = Select;
class SelectInPicker extends Component {
  render() {
    let type = this.props.type;
    let label = this.props.label;
    let val = this.props.val;
    let org = this.props.org;
    let load = this.props.load;
    let options;

    let index = this.props.index;
    let disabled = this.props.disabled;
    if (!disabled) {
      options = this.props.dataoptions.map((val, index) => (
        <Option value={val} key={index}>
          {val}
        </Option>
      ));
    } else {
      val = org;
    }

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {org !== val && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(type, org, index)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <InputGroup.Addon>{label}</InputGroup.Addon>

          <Select
            showSearch
            value={val}
            style={{ width: "100%" }}
            placeholder={this.props.label}
            optionFilterProp="children"
            onChange={(e) => {
              this.props.editVal(type, e, index);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options}
          </Select>
          {org !== val && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(type, index)}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {org !== val && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>"{org}"</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default withRouter(observer(SelectInPicker));
