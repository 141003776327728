import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import { Col, FlexboxGrid, InputGroup, Notification } from "rsuite";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import { Select } from "antd";
import shippinglines from "../../../../../Static/Lists/jobsubtypes/imlists/shippinglinelistterminal49";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import terminal49tracking from "../../../../../Static/baseURL/terminal49tracking";
import LoadBack from "../../../../../Components/Loaders/LoadBack";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";

const { Option } = Select;
const instance = axios.create({ baseURL: baseURL });

class AIMStep3 extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.addNewTrackRequest = this.addNewTrackRequest.bind(this);
  }

  addNewTrackRequest = async () => {
    this.setState({ loading: true });
    let data = {
      data: {
        attributes: {
          request_type: "bill_of_lading",
          request_number: newAimStore.newaim.blnumber,
          scac: newAimStore.newaim.scac,
        },
      },
    };

    return instance
      .post(`https://api.terminal49.com/v2/tracking_requests`, data, {
        headers: { terminal49tracking },
      })
      .then((res) => {
        this.setState({ loading: false, saved: true });

        Notification["success"]({
          title: `Tracking Added ().`,
        });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  render() {
    let aim = newAimStore.newaim;
    let options = shippinglines.map((val, index) => (
      <Option value={val.scac} key={index}>
        {val.scac}: {val.name}
      </Option>
    ));
    return (
      <div>
        <LoadBack loading={this.state.loading}>
          <Div14>Step 3: Enable Shipment Tracking</Div14>
          <div
            style={{
              padding: "2%",
              border: "1px solid black",
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
            }}
          >
            <FlexboxGrid justify="center">
              <Col md={12} sm={20} xs={24}>
                <InputGroup>
                  <InputGroup.Addon>Shipping Line</InputGroup.Addon>
                  <Select
                    disabled={aim.shippingmethod !== "Sea"}
                    showSearch
                    value={aim.scac}
                    style={{ width: "100%" }}
                    placeholder={this.props.label}
                    optionFilterProp="children"
                    onChange={(e) => newAimStore.editOrder(e, "scac")}
                  >
                    {options}
                  </Select>
                </InputGroup>
                <br />
              </Col>
              <Col md={12} sm={20} xs={24}>
                <TextInputSTD
                  title="Bill of Lading Number"
                  val={aim.blnumber}
                  min={0}
                  label="blnumber"
                  size="large"
                  editVal={newAimStore.editOrder}
                />
                <br />
              </Col>
              <Col md={24} sm={20} xs={24}>
                <TextAreaInputSTD
                  title="Container Info:"
                  val={aim.containertype}
                  min={0}
                  label="containertype"
                  size="large"
                  editVal={newAimStore.editOrder}
                />
                <br />
              </Col>
            </FlexboxGrid>
          </div>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(AIMStep3));
