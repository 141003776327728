import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import equipmentStore from "../../../../Stores/EquipmentStores/EquipmentStore";
import { FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import Col3 from "../../../../Components/Columns/Col3";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import { message } from "antd";
import MaterialTable from "material-table";
import Col4 from "../../../../Components/Columns/Col4";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import { Tabs } from "antd";
import FuelGraphs from "./Graphs/FuelGraphs";

const { TabPane } = Tabs;
const instance = axios.create({ baseURL: baseURL });

class FuelTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownew: false,
      showedit: false,
      showdelete: false,
      truck: {
        type: "",
        license: "",
        remark: "",
        lastfuel: {
          kilometer: 0,
          liter: 0,
          price: 0,
        },
      },
      fuelid: "",
      fuelNumId: "",
      truckid: "",
      kilometer: 0,
      liter: 0,
      price: 0,
      date: moment(),
      loading: false,
      tab: "Records",
    };
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.editVal = this.editVal.bind(this);
  }
  componentDidMount() {
    equipmentStore.getEquipmentFuels();
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  postEdit() {
    let data = {
      kilometer: this.state.kilometer,
      liter: this.state.liter,
      price: this.state.price,
      date: this.state.date,
    };
    let url = `/hr/equipmentfuel/edit/${this.state.fuelid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        equipmentStore.getEquipmentFuels(true);
        message.success("Updated Fuel Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Update Fuel Record");
      });
  }
  postDelete() {
    let data = {};
    let url = `/hr/equipmentfuel/delete/${this.state.fuelid}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        equipmentStore.getEquipmentFuels(true);
        message.success("Deleted Fuel Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Delete Fuel Record");
      });
  }
  postNew() {
    let data = {
      equipment: this.state.truckid,
      kilometer: this.state.kilometer,
      liter: this.state.liter,
      price: this.state.price,
      date: this.state.date,
    };
    let url = "/hr/equipmentfuel/new";
    this.setState({ loading: true });
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        equipmentStore.getEquipments(true);
        equipmentStore.getEquipmentFuels(true);
        message.success("Created New Fuel Record");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Could Not Create New Fuel Record");
      });
  }
  onHide() {
    this.setState({
      truck: {
        type: "",
        license: "",
        remark: "",
        lastfuel: {
          kilometer: 0,
          liter: 0,
          price: 0,
        },
        lastkm: 0,
      },
      shownew: false,
      showedit: false,
      showdelete: false,
      fuelid: "",
      truckid: "",
      kilometer: 0,
      liter: 0,
      price: 0,
      date: moment(),
      loading: false,
    });
  }

  render() {
    let fuelbuttons = equipmentStore.equipments.map((eq, index) => (
      <IconButton
        icon={
          <Icon
            icon={
              eq.type === "Generator"
                ? "bolt"
                : eq.type === "Pickup"
                ? "car"
                : "truck"
            }
          />
        }
        size="xs"
        key={index}
        onClick={() =>
          this.setState({ truck: eq, truckid: eq._id, shownew: true })
        }
      >
        {eq.license}
      </IconButton>
    ));
    let disabled = false;
    let eqp = this.state.truck;

    if (this.state.liter <= 0) disabled = true;
    if (this.state.price <= 0) disabled = true;

    let columnnames = [
      {
        title: "ID",
        field: "equipmentFuelNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Type", field: "type", removable: true },
      { title: "Plate", field: "license", removable: true },
      {
        title: "Date",
        field: "date",
        removable: true,
        render: (rowData) => (
          <div>{moment(rowData.date).format("DD-MMM-YY")}</div>
        ),
      },
      { title: "KM", field: "kilometer", removable: true },
      { title: "Price", field: "price", removable: true },
      { title: "Liters", field: "liter", removable: true },
      {
        title: "KD/Liter",
        field: "priceperliter",
        removable: true,
        render: (rowData) => {
          return PRKD(rowData.priceperliter);
        },
      },
    ];
    let tableTitle = "Fuel Records";
    let data = equipmentStore.fuel;

    let priceperliter = 0;
    if ((this.state.price > 0) & (this.state.liter > 0)) {
      priceperliter = PRKD(Parsed(this.state.price) / Parsed(this.state.liter));
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          title={`Delete Fuel Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Delete Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postDelete()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <h3>Are you sure you want to delete this record?</h3>
          <h5>Fuel ID Number (#{this.state.fuelNumId}):</h5>
          <FlexboxGrid>
            <Col4>
              <b>Date: </b>
              {moment(this.state.date).format("DD-MMM-YY")}
            </Col4>
            <Col4>
              <b>Kilometer: </b> {this.state.kilometer}
            </Col4>
            <Col4>
              <b>Liters: </b> {this.state.liter}
            </Col4>
            <Col4>
              <b>Price: </b> {this.state.price}
            </Col4>
          </FlexboxGrid>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.shownew}
          title={`Add New Fuel Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Create New"]}
              oC1={() => this.onHide()}
              oC2={() => this.postNew()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <FlexboxGrid>
            <Col3>
              <b>Type: </b>
              {eqp.type}
            </Col3>
            <Col3>
              <b>Plate: </b>
              {eqp.license}
            </Col3>
            <Col3>
              <b>Remarks: </b>
              {eqp.remark}
            </Col3>
          </FlexboxGrid>
          <hr />
          {this.state.truck.type !== "Generator" && (
            <>
              <HelpBlock>
                Last Kilometer: {this.state.truck.lastfuel.kilometer}
              </HelpBlock>
              <NumbInputSTD
                title="Kilometers"
                val={this.state.kilometer}
                label="kilometer"
                editVal={this.editVal}
              />
              <HelpBlock>
                Difference:{" "}
                {PRKD(
                  Parsed(this.state.kilometer) -
                    Parsed(this.state.truck.lastfuel.kilometer)
                )}
              </HelpBlock>
              <br />
            </>
          )}
          <NumbInputSTD
            title="Liters"
            val={this.state.liter}
            label="liter"
            editVal={this.editVal}
          />
          <br />
          <HelpBlock>
            Last Price Per Liter:{" "}
            {PRKD(
              Parsed(this.state.truck.lastfuel.price) /
                Parsed(this.state.truck.lastfuel.liter)
            )}
          </HelpBlock>
          <NumbInputSTD
            title="Price"
            val={this.state.price}
            label="price"
            editVal={this.editVal}
          />
          {priceperliter > 0 && (
            <HelpBlock style={{ fontStyle: "italic" }}>
              {priceperliter} KD / Liter
            </HelpBlock>
          )}
          <br />
          <DatePickerSTD
            title="Date"
            val={this.state.date}
            label="date"
            editVal={this.editVal}
          />
          <br />
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showedit}
          title={`Edit Fuel Record`}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Update Record"]}
              oC1={() => this.onHide()}
              oC2={() => this.postEdit()}
              loading1={this.state.loading}
              loading2={this.state.loading}
              disabled2={disabled}
            />
          }
        >
          <FlexboxGrid>
            <Col3>
              <b>Type: </b>
              {eqp.type}
            </Col3>
            <Col3>
              <b>Plate: </b>
              {eqp.license}
            </Col3>
            <Col3>
              <b>Remarks: </b>
              {eqp.remark}
            </Col3>
          </FlexboxGrid>
          <hr />
          {this.state.truck.type !== "Generator" && (
            <>
              <NumbInputSTD
                title="Kilometers"
                val={this.state.kilometer}
                label="kilometer"
                editVal={this.editVal}
              />
              <br />
            </>
          )}
          <NumbInputSTD
            title="Liters"
            val={this.state.liter}
            label="liter"
            editVal={this.editVal}
          />
          <br />
          <NumbInputSTD
            title="Price"
            val={this.state.price}
            label="price"
            editVal={this.editVal}
          />
          {priceperliter > 0 && (
            <HelpBlock style={{ fontStyle: "italic" }}>
              {priceperliter} KD / Liter
            </HelpBlock>
          )}
          <br />
          <DatePickerSTD
            title="Date"
            val={this.state.date}
            label="date"
            editVal={this.editVal}
          />
          <br />
        </UnifiedModal>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Records"} key={"Records"}>
            <FlexboxGrid justify="space-around">{fuelbuttons}</FlexboxGrid>
            <hr />
            <div style={{ padding: "1%" }}>
              <MaterialTable
                isLoading={equipmentStore.loading.fuel}
                title={data.length + " " + tableTitle}
                columns={columnnames}
                style={{ fontSize: 12 }}
                data={data}
                options={{
                  actionsColumnIndex: 0,
                  exportAllData: true,
                  paging: true,
                  pageSize: 20,
                  toolbarButtonAlignment: "left",
                  maxBodyHeight: 1000,
                  tableLayout: "auto",
                  exportButton: true,
                  exportFileName: `${
                    this.props.exportTitle ? this.props.exportTitle : tableTitle
                  } as of ${moment(new Date()).format("DD-MMM-YY")}`,
                  padding: "dense",
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit Fuel Record",
                    onClick: (event, rowData) => {
                      this.setState({
                        showedit: true,

                        fuelid: rowData._id,
                        truckid: rowData.equipment._id,
                        kilometer: rowData.kilometer,
                        liter: rowData.liter,
                        price: rowData.price,
                        date: rowData.date,
                        loading: false,
                      });
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete Fuel Record",
                    onClick: (event, rowData) => {
                      this.setState({
                        showdelete: true,

                        fuelid: rowData._id,
                        fuelNumId: rowData.equipmentFuelNumId,
                        truckid: rowData.equipment._id,
                        kilometer: rowData.kilometer,
                        liter: rowData.liter,
                        price: rowData.price,
                        date: rowData.date,
                        loading: false,
                      });
                    },
                  },
                  {
                    icon: "refresh",
                    tooltip: "Refresh",
                    isFreeAction: true,
                    onClick: (event, rowData) => {
                      equipmentStore.getEquipmentFuels(true);
                    },
                  },
                ]}
              />
            </div>
          </TabPane>
          <TabPane tab={"Graphs"} key={"Graphs"}>
            <FuelGraphs />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(FuelTable));
