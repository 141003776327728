import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Loader,
  Steps,
  Notification,
} from "rsuite";
import axios from "axios";
import baseURL from "../../../../../../../../Static/baseURL/baseURL";
import acInvoiceStore from "../../../../../../../../Stores/Financial/ACInvoiceStore";
import userStore from "../../../../../../../../Stores/UserStore";
import Col4 from "../../../../../../../../Components/Columns/Col4";
import Col2 from "../../../../../../../../Components/Columns/Col2";
import Col3 from "../../../../../../../../Components/Columns/Col3";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import Step1 from "./Steps/Step1";
import { withRouter } from "react-router";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";

const instance = axios.create({ baseURL: baseURL });

class NewACInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, load: false };
    this.postNewInvoice = this.postNewInvoice.bind(this);
  }

  componentDidMount() {
    acInvoiceStore.getRemainingInvoiceItems(
      this.props.match.params.clientid,
      true
    );
  }

  postNewInvoice = async () => {
    let data = acInvoiceStore.newacinvoice;

    let charges = [];
    let singlecharge = {};
    let acorderids = [];
    let acorders = [];
    let singleacorder = {};

    for (let i = 0; i < data.charges.length; i++) {
      acorders = [];
      if (data.charges[i].acorders) {
        if (data.charges[i].acorders.length > 0) {
          for (let j = 0; j < data.charges[i].acorders.length; j++) {
            singleacorder = {
              acOrderNumId: data.charges[i].acorders[j].acOrderNumId,
              jobdate: data.charges[i].acorders[j].jobdate,
              jobtype: data.charges[i].acorders[j].jobtype,
              cbm: data.charges[i].acorders[j].cbm,
              delcharge: data.charges[i].acorders[j].delcharge,
              percbmchg: data.charges[i].acorders[j].percbmchg,
              addchg: data.charges[i].acorders[j].addchg,
              discount: data.charges[i].acorders[j].discount,
              totaldelcharge: data.charges[i].acorders[j].totaldelcharge,
            };
            acorderids = [...acorderids, data.charges[i].acorders[j]._id];
            acorders = [...acorders, singleacorder];
          }
        }
      }
      singlecharge = {
        startday: data.charges[i].startday,
        endday: data.charges[i].endday,
        cbmend: data.charges[i].cbmend,
        cbmstart: data.charges[i].cbmstart,
        deltotalchg: data.charges[i].deltotalchg,
        freqchg: data.charges[i].freqchg,
        freqtime: data.charges[i].freqtime,
        storchg: data.charges[i].storchg,
        storin: data.charges[i].storin,
        storout: data.charges[i].storout,
        totaldelchg: data.charges[i].totaldelchg,
        acorderids: acorderids,
        acorders: acorders,
      };
      charges = [...charges, singlecharge];
    }

    let final = {
      acInvoiceNumId: data.acInvoiceNumId,
      clientname: data.clientname,
      clientemail: data.clientemail,
      clientphone: data.clientphone,
      title: data.title,
      date: data.date,
      status: data.status,
      remarks: data.remarks,
      client: data.client,
      showBank: data.showBank,
      invnotes: data.invnotes,
      charges: charges,
    };

    let url = "";
    url = `jf/acinvoice/new`;

    if (final.acInvoiceNumId === "New") {
      delete final.acInvoiceNumId;

      url = `jf/acinvoice/new`;

      this.setState({ load: true });
      await instance
        .post(url, final, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ load: false });
          this.props.history.push(
            `/sac/dash/${this.props.match.params.clientid}`
          );
          Notification["success"]({ title: `New AC Invoice Created` });
        })
        .catch((err) => {
          this.setState({ load: false });
          Notification["error"]({ title: `An error occured` });
        });
    } else {
      url = `jf/acinvoice/edit/${data._id}`;
    }
  };

  render() {
    if ((this.state.step === 0) & acInvoiceStore.loading) {
      return <Loader center />;
    }

    let inv = acInvoiceStore.newacinvoice;
    let content;
    let footerButs;
    if (this.state.step === 1) content = <Step1 />;
    if (this.state.step === 2) content = <Step2 />;
    if (this.state.step === 3) content = <Step3 />;
    if (this.state.step === 4) content = <Step4 />;
    let disabled = false;
    if (this.state.step === 1) {
      if (inv.title === "") disabled = true;
      if (inv.date === undefined) disabled = true;
    } else if (this.state.step === 2) {
      if (inv.charges.length === 0) disabled = true;
    }

    if (this.state.step === 0) {
      footerButs = (
        <>
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="close" />}
              color="red"
              onClick={() =>
                this.props.history.push(
                  `/ac/dashboard/${acInvoiceStore.client._id}`
                )
              }
              block
            >
              Cancel and Go Back
            </IconButton>
          </Col4>
        </>
      );
    } else if (this.state.step < 4) {
      footerButs = (
        <>
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="left" />}
              color="orange"
              onClick={() => this.setState({ step: this.state.step - 1 })}
              block
            >
              Back
            </IconButton>
          </Col4>
          <Col2 />
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="right" />}
              color="green"
              onClick={() => {
                this.setState({ step: this.state.step + 1 });
              }}
              block
              placement="right"
              disabled={disabled}
            >
              Next
            </IconButton>
          </Col4>
        </>
      );
    } else {
      footerButs = (
        <>
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="left" />}
              color="orange"
              onClick={() => this.setState({ step: this.state.step - 1 })}
              block
            >
              Back
            </IconButton>
          </Col4>
          <Col2 />
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="save" />}
              color="green"
              onClick={() => this.postNewInvoice()}
              block
              placement="right"
            >
              Save
            </IconButton>
          </Col4>
        </>
      );
    }

    let step0content;

    step0content = (
      <FlexboxGrid justify="center">
        <Col3>
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            block
            onClick={() => {
              acInvoiceStore.createNewInvoice();
              this.setState({ step: 1 });
            }}
          >
            Create a New Invoice
          </IconButton>
        </Col3>
      </FlexboxGrid>
    );

    return (
      <div
        style={{
          margin: "1%",
          padding: "2%",
          border: "1px solid black",
          borderRadius: "5px",
          boxShadow: "1px 1px 5px black",
        }}
      >
        <FlexboxGrid>{footerButs}</FlexboxGrid>
        <Div14>New AC Invoice</Div14>
        {this.state.step === 0 ? (
          step0content
        ) : (
          <Steps current={this.state.step - 1}>
            <Steps.Item title="Invoice Main Info" />
            <Steps.Item title="Select Charges" />
            <Steps.Item title="Invoice Notes" />
            <Steps.Item title="Save" />
          </Steps>
        )}
        {content}
      </div>
    );
  }
}

export default withRouter(observer(NewACInvoice));
