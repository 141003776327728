import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, InputGroup } from "rsuite";
import { Notification, Input, Col } from "rsuite";
import { DatePicker } from "antd";
import { Select } from "antd";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import Col2 from "../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import MatTableSTD from "../../../../../Components/Tables/MatTableSTD";
import StaticCalendar from "../../../../../Components/Calendar/StaticCalendar";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";

const instance = axios.create({ baseURL: baseURL });

const { Option } = Select;

class IMTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      type: "New",
      new: {
        date: moment(),
        title: "",
        status: "Pending",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
      old: {
        date: moment(),
        title: "",
        status: "Pending",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.postDelete = this.postDelete.bind(this);
  }

  changeVal(label, val) {
    let track = { ...this.state.new };
    track[label] = val;
    this.setState({ new: track });
  }

  resetState() {
    this.setState({
      type: "New",
      new: {
        date: moment(),
        title: "",
        status: "Pending",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
      old: {
        date: moment(),
        title: "",
        status: "Pending",
        remarks: "",
        _id: "",
        tableData: { id: -1 },
      },
    });
  }

  showHideModal(val, item) {
    if (val === "Edit" || val === "Delete") {
      this.setState({ show: true, type: val, new: item, old: item });
    } else if (val === "New") {
      this.setState({ show: true });
      this.resetState();
    } else {
      this.setState({ loading: false, show: false });
      this.resetState();
    }
  }

  postNew = async (track) => {
    let aimOrder = newAimStore.oldaim;
    this.changeVal("loading", true);
    let data = {
      title: track.title,
      status: track.status,
      date: track.date,
      remarks: track.remarks,
      aimOrder: aimOrder._id,
      client: newAimStore.account._id,
    };
    let url = "/imadditional/track/new";

    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.newList("imtracks", data);
        this.changeVal("loading", false);

        Notification["success"]({
          title: `New IM Track Created.`,
          description: <div>{data.title}</div>,
        });
        this.showHideModal(false);
      })
      .catch((err) => {
        console.log(err);
        this.changeVal("loading", false);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };
  postEdit = async (track) => {
    let aimOrder = newAimStore.oldaim;
    this.changeVal("loading", true);
    let data = {
      title: track.title,
      status: track.status,
      date: track.date,
      remarks: track.remarks,
      aimOrder: aimOrder._id,
    };
    let url = `/imadditional/track/edit/${track._id}`;

    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.editList("imtracks", track.tableData.id, track);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Edited IM Track.`,
          description: <div>{data.title}</div>,
        });
        this.showHideModal(false);
      })
      .catch((err) => {
        console.log(err);
        this.changeVal("loading", false);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  postDelete = async (track) => {
    let aimOrder = newAimStore.oldaim;
    this.changeVal("loading", true);
    let data = { imtrackid: track._id, aimOrder: aimOrder._id };
    let url = `/imadditional/track/deletebyid`;

    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAimStore.removeList("imtracks", track.tableData.id);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Deleted IM Track.`,
          description: <div>{track.title}</div>,
        });
        this.showHideModal(false);
      })
      .catch((err) => {
        console.log(err);
        this.changeVal("loading", false);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  render() {
    let editview = !newAimStore.stopedit;

    let tracks = newAimStore.oldaim.imtracks;

    let disabled = false;
    if (this.state.type !== "Delete") {
      if (this.state.new.title === "") disabled = true;
    }

    let columns = [
      { title: "Title", field: "title" },
      { title: "Status", field: "status" },
      {
        title: "Date",
        field: "date",

        render: (row) => moment(row.date).format("DD-MMM-YY"),
      },
      { title: "Remark", field: "remarks" },
    ];

    let actions = [
      {
        icon: "add",
        tooltip: "Add New Charge",
        hidden: !editview,
        isFreeAction: true,
        onClick: (event, rowData) => this.showHideModal("New"),
      },
      {
        icon: "edit",
        hidden: !editview,
        tooltip: "Edit",
        onClick: (event, rowData) => this.showHideModal("Edit", rowData),
      },
      {
        icon: "delete",
        hidden: !editview,
        tooltip: "Delete",
        onClick: (event, rowData) => this.showHideModal("Delete", rowData),
      },
    ];

    let footer = (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="close" />}
              onClick={() => this.showHideModal(false)}
            >
              Cancel
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              block
              disabled={disabled}
              loading={this.state.loading}
              color={this.state.type === "Delete" ? "red" : "green"}
              icon={<Icon icon="close" />}
              onClick={() => {
                this.setState({ loading: true });
                if (this.state.type === "New") {
                  this.postNew(this.state.new);
                } else if (this.state.type === "Edit") {
                  this.postEdit(this.state.new);
                } else if (this.state.type === "Delete") {
                  this.postDelete(this.state.new);
                }
              }}
            >
              {this.state.type === "Edit"
                ? "Save Changes"
                : this.state.type === "New"
                ? "Create New Track"
                : "Yes Delete"}
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );

    let modalbody;
    if (this.state.type === "Delete") {
      modalbody = (
        <div>
          <h5>Are you sure you want to Delete this Track?</h5>
          <hr />
          <div style={{ marginLeft: "5%" }}>
            <h6>
              <b>Title: </b>
              {this.state.new.title}
            </h6>
            <h6>
              <b>Status: </b>
              {this.state.new.status}
            </h6>
            <h6>
              <b>Date: </b>
              {moment(this.state.new.date).format("DD-MMM-YY")}
            </h6>
            <h6>
              <b>Remarks: </b>
              {this.state.new.remarks}
            </h6>
          </div>
        </div>
      );
    } else {
      modalbody = (
        <div>
          <FlexboxGrid>
            <Col md={24} sm={24} xs={24}>
              <InputGroup>
                <InputGroup.Addon>Title</InputGroup.Addon>
                <Input
                  size="lg"
                  disabled={this.state.loading}
                  value={this.state.new.title}
                  onChange={(e) => this.changeVal("title", e)}
                />
                {this.state.new.title !== this.state.old.title && (
                  <InputGroup.Addon>
                    <Icon icon="check-circle" style={{ color: "green" }} />
                  </InputGroup.Addon>
                )}
              </InputGroup>
            </Col>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Date</InputGroup.Addon>
                <DatePicker
                  value={moment(this.state.new.date)}
                  onChange={(e) => this.changeVal("date", e)}
                  clearIcon={false}
                  size="large"
                  style={{ width: "100%" }}
                  format="DD-MMM-YY"
                />
                {this.state.new.date !== this.state.old.date && (
                  <InputGroup.Addon>
                    <Icon icon="check-circle" style={{ color: "green" }} />
                  </InputGroup.Addon>
                )}
              </InputGroup>
            </Col2>
            <Col2>
              <InputGroup>
                <InputGroup.Addon>Status</InputGroup.Addon>
                <Select
                  size="large"
                  showSearch
                  value={this.state.new.status}
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  onChange={(e) => this.changeVal("status", e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"Pending"}> Pending </Option>
                  <Option value={"Complete"}> Complete </Option>
                </Select>
                {this.state.new.status !== this.state.old.status && (
                  <InputGroup.Addon>
                    <Icon icon="check-circle" style={{ color: "green" }} />
                  </InputGroup.Addon>
                )}
              </InputGroup>
            </Col2>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            <InputGroup>
              <InputGroup.Addon>Remarks</InputGroup.Addon>
              <Input
                size="lg"
                disabled={this.state.loading}
                value={this.state.new.remarks}
                onChange={(e) => this.changeVal("remarks", e)}
              />
              {this.state.new.remarks !== this.state.old.remarks && (
                <InputGroup.Addon>
                  <Icon icon="check-circle" style={{ color: "green" }} />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </FlexboxGrid>
        </div>
      );
    }

    return (
      <div>
        <UnifiedModal
          title={`${this.state.type} IM Tracking`}
          show={this.state.show}
          onHide={this.showHideModal}
          footer={footer}
        >
          {modalbody}
        </UnifiedModal>

        <MatTableSTD
          data={tracks}
          title="IM Tracking"
          columns={columns}
          actions={userStore.user.staffaccount && actions}
          loading={this.state.loading}
        />
        <hr />
        <StaticCalendar
          start={"date"}
          end={"date"}
          title={"title"}
          data={tracks}
          onSelectEvent={() => console.log(" ")}
        />
      </div>
    );
  }
}

export default observer(IMTracking);
