import { Empty } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";

class AccountNotFound extends Component {
  render() {
    return (
      <div>
        <br />
        <Empty description="This page no longer exists. Please contact Easy Move Staff for Support." />
      </div>
    );
  }
}

export default withRouter(observer(AccountNotFound));
