import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";

import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import DeliveryTimeline from "../../../../../Components/Timeline/DeliveryTimeline";

class DeliverySummary extends Component {
  render() {
    let del = this.props.del;

    let account = this.props.account;
    let delCont = this.props.delCont;

    let dpt = false;
    if (delCont.maintype === "Fixed Per Type") {
      dpt = true;
    }

    let itemlist = (
      <div
        style={{
          paddingLeft: "1%",
          paddingRight: "1%",
        }}
      >
        <DataGrid
          id="gridContainer"
          dataSource={del.types}
          showBorders={true}
          style={{ fontSize: 12 }}
          showRowLines
          showColumnLines
          rowAlternationEnabled
          wordWrapEnabled
        >
          <Selection mode="single" />
          <Column dataField="type" caption="Item" />
          <Column dataField="subtype" caption="Remarks" />

          {dpt && <Column dataField="price" caption="Price" />}

          {!dpt && <Column dataField="quantity" caption="Qty" />}
          {!dpt && <Column dataField="cbm" caption="CBM" />}
          {!dpt && <Column dataField="length" caption="L (cm)" />}
          {!dpt && <Column dataField="height" caption="H (cm)" />}
          {!dpt && <Column dataField="width" caption="W (cm)" />}

          <Summary>
            <TotalItem
              column="item"
              summaryType="count"
              displayFormat="{0} Items"
            />
            <TotalItem
              column="quantity"
              summaryType="sum"
              displayFormat="{0} Packages"
            />
            <TotalItem
              column="price"
              summaryType="sum"
              displayFormat="{0} KD"
            />
            <TotalItem column="cbm" summaryType="sum" displayFormat="{0} CBM" />
          </Summary>
        </DataGrid>
      </div>
    );

    let prc;
    let tripprice = 0;
    let finalcosts = [];
    if (del.prices) {
      prc = del.prices;
      tripprice = PRKD(prc.tripprice);
      finalcosts = [
        {
          label: "Day Trip Price",
          price: prc.delday,
          hide: prc.delday === 0,
        },
        {
          label: "Pickup Zone Charge",
          price: prc.zonecost,
          hide: prc.zonecost === 0,
        },
        {
          label: "Pickup Non-Ground Floor Charge",
          price: prc.ngfchg,
          hide: prc.ngfchg === 0,
        },
        {
          label: "Delivery Zone Charge",
          price: prc.zonecost,
          hide: prc.zonecost === 0,
        },
        {
          label: "Delivery Non-Ground Floor Charge",
          price: prc.ngfchg,
          hide: prc.ngfchg === 0,
        },
        {
          label: "Total Type Price",
          price: `${prc.typecost} KD for ${del.types.length} type${
            del.types.length === 1 ? "" : "s"
          }`,
          hide: prc.typecost === 0 || delCont.maintype !== "Fixed Per Type",
        },
        {
          label: `Final Price (${del.trucksreq} Trip${
            parseFloat(del.trucksreq) !== 1 ? "s" : ""
          })`,
          price: `${PRKD(prc.ppt)} KD/trip x ${del.trucksreq} trips = ${PRKD(
            prc.tripprice
          )} KD`,
          hide: delCont.maintype === "Fixed Per Type",
        },
        {
          label: `Final Price (KWD)`,
          price: prc.tripprice,
          hide: delCont.maintype !== "Fixed Per Type",
        },
      ];
    }

    let costs = finalcosts.filter((co) => !co.hide);

    return (
      <div>
        <DeliveryTimeline del={del} />
        <FlexboxGrid>
          <Col2>
            <Div14>Delivery Details</Div14>
            <p>
              <b>Account: </b> {account.client.name}
              <br />
              <b>Delivery Type: </b> {del.type}
              <br />
              <b>Date: </b> {moment(del.date).format("DD-MMM-YY")}
              <br />
            </p>
            <Div14>Items List</Div14>
            {itemlist}
          </Col2>
          <Col2>
            <Div14>Receiving Client:</Div14>
            <p>
              <b>Name: </b> {del.toname}
              <br />
              <b>Number: </b> {del.tonumber}
              <br />
            </p>
            <Div14>From</Div14>
            <p>
              <b>Type: </b> {del.fromtype}
              <br />
              <b>City: </b> {del.fromcity}
              <br />
              <b>Floor #</b> {del.fromfloor}
              <br />
              <b>Address: </b> {del.from}
              <br />
            </p>
            <Div14>To</Div14>
            <p>
              <b>Type: </b> {del.totype}
              <br />
              <b>City: </b> {del.tocity}
              <br />
              <b>Floor #</b> {del.tofloor}
              <br />
              <b>Address: </b> {del.to}
              <br />
            </p>
          </Col2>
          <Div14>Pricing {tripprice} KD </Div14>
          <div>
            <DataGrid
              id="gridContainer"
              dataSource={costs}
              showBorders={true}
              style={{ fontSize: 12 }}
              showRowLines
              showColumnLines
              rowAlternationEnabled
              wordWrapEnabled
            >
              <Selection mode="single" />
              <Column dataField="label" caption="Item" />
              <Column dataField="price" caption="Price (KD)" />
            </DataGrid>
          </div>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(DeliverySummary);
