import { computed, makeObservable, observable } from "mobx";
import axios from "axios";

// import jwt_decode from "jwt-decode";
// import moment from "moment";

import moment from "moment";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Notification } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class ACInvoiceStore {
  constructor() {
    this.newacinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: "",
      clientname: "",
      clientemail: "",
      clientphone: "",
      acInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
    this.recs = [];
    this.acinvoices = [];
    this.charges = [];
    this.client = { storageContract: {}, _id: "" };
    this.payments = [];
    this.loading = false;
    this.cancelled = false;
    this.invoiceopts = [];

    makeObservable(this, {
      acinvoices: observable,
      newacinvoice: observable,
      recs: observable,
      charges: observable,
      payments: observable,
      invoiceopts: observable,

      client: observable,
      loading: observable,
      cancelled: observable,
      newrecs: computed,
    });
  }

  // FETCH
  getInvoiceAndPaymentByClient(newclientid, overide) {
    let clid = this.client._id;
    if (newclientid) clid = newclientid;
    if (!overide) {
      if (this.loading) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/acinvoice/getinvoiceandpaymentbyclient/${clid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.acinvoices = res.data.invoices;
        this.client = res.data.client;
        this.payments = res.data.payments;
        this.loading = false;

        console.log("AC Invoices and Payments Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getInvoiceByClient(newclientid, overide) {
    let clid = this.client._id;
    if (newclientid) clid = newclientid;
    if (!overide) {
      if (this.loading) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/acinvoice/getbyclient/${clid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.acinvoices = res.data.invoices;
        this.client = res.data.client;
        this.cancelled = false;
        this.loading = false;
        console.log("AC Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getRemainingInvoiceItems(clientid, overide) {
    if (!overide) {
      if (this.loading || this.clientid === clientid) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/acinvoice/getremaining/${clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.acinvoices = res.data.invoices;
        this.recs = res.data.remaining;
        this.client = res.data.client;
        this.loading = false;
        console.log("AC Remaining Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getACInvoiceById(acinvid) {
    this.loading = true;
    return instance
      .get(`/jf/acinvoice/getbyacinvid/${acinvid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.newacinvoice = res.data.invoice;
        this.recs = res.data.remaining;
        this.client = res.data.client;
        this.loading = false;
        console.log("AC Remaining Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getCancelledByClient() {
    this.loading = true;
    return instance
      .get(`/jf/acinvoice/cancelledbyclient/${this.client._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.cancelled = true;
        this.acinvoices = [...this.acinvoices, ...res.data];
        this.loading = false;
        console.log("AC Cancelled Invoices Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }
  getPaymentsByClient(clientid) {
    this.loading = true;
    return instance
      .get(`/jf/payment/getstoragepaymentbyclient/${clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.payments = res.data.payments;
        this.invoiceopts = res.data.invoiceopts;
        this.loading = false;
        console.log("AC Payments Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  }

  createNewInvoice() {
    this.newacinvoice = {
      _id: "",
      invtype: "New",
      title: "Easy Move Invoice",
      date: moment(),
      status: "Pending",
      remarks: "",
      client: this.client._id,
      clientname: this.client.name,
      clientemail: this.client.email,
      clientphone: this.client.phone,
      acInvoiceNumId: "New",
      showBank: "KFH",
      charges: [],
      invnotes: "",
    };
  }

  selectInvoice(inv) {
    this.newacinvoice = { ...inv };
  }

  editInvoice(val, label) {
    acInvoiceStore.newacinvoice[label] = val;
  }

  changeCharge(chg) {
    let newchg;
    if (chg.selected) {
      newchg = this.newacinvoice.charges.filter(
        (oldchg) => oldchg.freqtime !== chg.freqtime
      );
    } else {
      let newchgs = [...this.newacinvoice.charges, chg];
      newchg = newchgs.sort((a, b) => moment(a.startday) - moment(b.startday));
    }
    this.newacinvoice.charges = [...newchg];
  }

  get newrecs() {
    let single = {};
    let final = [];
    for (let i = 0; i < this.recs.length; i++) {
      single = { ...this.recs[i], selected: false };
      for (let j = 0; j < this.newacinvoice.charges.length; j++) {
        if (
          moment(this.recs[i].startday).format("DD-MMM-YY") ===
          moment(this.newacinvoice.charges[j].startday).format("DD-MMM-YY")
        ) {
          single.selected = true;
        }
      }
      final = [...final, single];
    }
    return final;
  }
}

const acInvoiceStore = new ACInvoiceStore();
export default acInvoiceStore;
