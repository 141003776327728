import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import StorageOrderPDF from "../../../../../Components/PDFs/Accounts/PrintStorOrder/StorageOrderPDF";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";

class PrintOrderTab extends Component {
  constructor(props) {
    super(props);
    this.state = { type: "cbm" };
  }

  render() {
    let sto = newStorageStore.oldorder;
    let account = newStorageStore.account;
    let inout = newStorageStore.delstoinout.inout;

    return (
      <div>
        <Div14>Print Storage Order</Div14>
        <FlexboxGrid>
          <Col2>
            <IconButton
              icon={
                <Icon
                  icon={this.state.type === "cbm" ? "check-circle" : "circle"}
                />
              }
              block
              color={this.state.type === "cbm" ? "green" : "red"}
              appearance={this.state.type === "cbm" ? "default" : "ghost"}
              onClick={() => this.setState({ type: "cbm" })}
            >
              Only CBM
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              icon={
                <Icon
                  icon={this.state.type !== "cbm" ? "check-circle" : "circle"}
                />
              }
              block
              color={this.state.type !== "cbm" ? "green" : "red"}
              appearance={this.state.type !== "cbm" ? "default" : "ghost"}
              onClick={() => this.setState({ type: "dimensions" })}
            >
              Dimension + CBM
            </IconButton>
          </Col2>
        </FlexboxGrid>
        {this.state.type === "cbm" ? (
          <StorageOrderPDF sto={sto} client={account} type={inout} />
        ) : (
          <StorageOrderPDF sto={sto} client={account} type={inout} showdimen />
        )}
      </div>
    );
  }
}

export default withRouter(observer(PrintOrderTab));
