import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";

import { Button, Col, FlexboxGrid } from "rsuite";
import allPaymentsStore from "../../../../Stores/Financial/AllPaymentsStore";

class PaymentHeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let data = allPaymentsStore.paymentheatmap;
    let series = [
      {
        data: data.output,
      },
    ];
    let options = {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
      title: {
        text: "Payment Breakdown",
        align: "center",
      },

      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: true,
          useFillColorAsStroke: true,
          shadeIntensity: 0.5,
          colorScale: {
            ranges: [
              {
                from: -10000000,
                to: 0,
                color: "#ff0026",
              },
              {
                from: 0.001,
                to: 1000000000,
                color: "#00910a",
              },
            ],
          },
        },
      },
    };

    let categoryopts = [
      { label: "Type", val: "type" },
      { label: "Sub Type", val: "subtype" },
      { label: "Specific", val: "specific" },
      { label: "Method", val: "method" },
      { label: "Account", val: "account" },
      { label: "Customer Type", val: "accounttype" },
    ];

    let buttons = categoryopts.map((cat, index) => (
      <Col md={4} sm={6} xs={8} key={index}>
        <Button
          size="sm"
          block
          onClick={() => allPaymentsStore.changeGraphFactor(cat.val)}
          color="green"
          appearance={
            cat.val === allPaymentsStore.graphfactor ? "primary" : "ghost"
          }
        >
          {cat.label}
        </Button>
      </Col>
    ));

    return (
      <div>
        <FlexboxGrid justify="center">{buttons}</FlexboxGrid>
        <ReactApexChart
          options={options}
          series={series}
          type="treemap"
          height={350}
        />
      </div>
    );
  }
}

export default withRouter(observer(PaymentHeatMap));
