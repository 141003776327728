// Parse and Round Calc
function PRKD(val) {
  let parsed = Parsed(val);
  let rounded = Math.round(1000 * parsed) / 1000;
  return rounded;
}
function Parsed(val) {
  let parsed = parseFloat(val);
  return parsed;
}
export { PRKD, Parsed };
