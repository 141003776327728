import { observer } from "mobx-react";
import React, { Component } from "react";

import TimelineComp from "../../../../Components/Timeline/TimelineComp";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { Icon, IconButton, Notification } from "rsuite";
import { PageHeader } from "antd";
import { Fab } from "@material-ui/core";
import NextBackButton from "./Supporting/NextBackButton";
import zoneStore from "../../../../Stores/Admin/Lists/ZoneStore";
import newDeliveryStore from "../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import DACStep1 from "./Steps/DACStep1";
import DACStep3 from "./Steps/DACStep3";
import Div14 from "../../../../Components/Dividers/Div14";
import PerTypeStep from "./Steps/PerTypeStep/PerTypeStep";
import DACStep5 from "./Steps/DACStep5";
import OrderSummary from "./Supporting/OrderSummary";
import DACStep2 from "./Steps/DACStep2";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import ItemsStep from "./Steps/ItemsList/ItemsStep";
import { TRC } from "../../../../Functions/Timing/TimingRulesCalc";

const instance = axios.create({ baseURL: baseURL });

class NewDeliveryRequestMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      loading: false,
      show: false,
      type: "New",
    };
    this.changeStep = this.changeStep.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postNew = this.postNew.bind(this);
  }

  componentDidMount() {
    zoneStore.getZones();
    if (this.props.match.path === "/dac/new/:clientid") {
      this.setState({ type: "New" });
      newDeliveryStore.getAccount(this.props.match.params.clientid);
      newDeliveryStore.createNewOrder();
    } else {
      this.setState({ type: "Edit" });
    }
  }
  postNew = async () => {
    let del = newDeliveryStore.newdel;

    let data = {
      client: newDeliveryStore.account._id,
      remarks: del.remarks,
      jobdate: del.jobdate,
      status: "Requested",
      maintype: del.maintype,
      fromaddress: del.fromaddress,
      toaddress: del.toaddress,
      prices: del.prices,
      orderoptions: del.orderoptions,
      addcharge: del.addcharge,
    };

    this.setState({ loading: true });
    let url = "/ac/delivery/new";
    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });
        Notification["success"]({
          title: `New Delivery Request Created.`,
        });
        this.props.history.push(`/dac/dash/${data.client}`);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });

        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  onHide() {
    this.setState({ show: false });
  }
  changeStep(val) {
    if ((this.state.step === 1) & (val < 0)) {
      this.props.history.push(
        `/ac/dashboard/${this.props.match.params.clientid}`
      );
    } else if (this.state.step === 1) {
      newDeliveryStore.createNewOrder();
      let earliest = TRC(newDeliveryStore.delCont, false, false);
      newDeliveryStore.editOrder(earliest, "jobdate");
      this.setState({ step: this.state.step + val });
    } else {
      this.setState({ step: this.state.step + val });
    }
  }
  render() {
    let delCont = newDeliveryStore.delCont;
    let maintype = delCont.maintype;
    let allsteps = ["Pickup Location", "Dropoff Location", "Pickup Time"];

    if (maintype === "Fixed Per Type") {
      allsteps = [...allsteps, "Types"];
    } else {
      allsteps = [...allsteps, "Items"];
    }

    allsteps = [...allsteps, "Extras", "Confirmation"];

    let step = this.state.step;
    let showfab = true;
    let content;
    if (this.state.step === 1) {
      content = <DACStep1 type={"From"} />;
    } else if (this.state.step === 2) {
      content = <DACStep2 type={"To"} />;
    } else if (this.state.step === 3) {
      content = <DACStep3 />;
    } else if (step === 4) {
      if (maintype === "Fixed Per Type") {
        content = <PerTypeStep />;
      } else {
        content = <ItemsStep />;
      }
    } else if (step === 5) {
      content = <DACStep5 />;
    } else if (step === 6) {
      content = (
        <div style={{ padding: "2%" }}>
          <OrderSummary />
        </div>
      );
      showfab = false;
    }

    return (
      <div>
        <LoadBack loading={this.state.loading}>
          <UnifiedModal
            show={this.state.show}
            onHide={this.onHide}
            title={"Order Summary"}
            footer={
              <IconButton
                icon={<Icon icon="close" />}
                color="red"
                onClick={this.onHide}
              >
                Close
              </IconButton>
            }
          >
            <OrderSummary />
          </UnifiedModal>
          <PageHeader
            onBack={() =>
              this.props.history.push(
                `/ac/dashboard/${newDeliveryStore.account._id}`
              )
            }
            title={`${this.state.type} Delivery Request`}
            subTitle={`Create A New Delivery Request`}
          />

          {showfab && (
            <div
              style={{
                right: "5%",
                bottom: "5%",
                position: "fixed",
                zIndex: 1000,
              }}
            >
              <Fab
                variant="extended"
                size="small"
                onClick={() => this.setState({ show: true })}
              >
                <ReceiptLongIcon sx={{ mr: 1 }} fontSize="small" />
                <span> </span> Order Summary
              </Fab>
            </div>
          )}
          <Div14>New Delivery Request - Type: ({maintype})</Div14>
          <TimelineComp steps={allsteps} step={step - 1} />
          <NextBackButton
            step={this.state.step}
            changeStep={this.changeStep}
            allsteps={allsteps}
            postNew={this.postNew}
          />

          {content}
          <hr />
        </LoadBack>
      </div>
    );
  }
}

export default observer(NewDeliveryRequestMain);
