import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import PaymentHeatMap from "./Graphs/PaymentHeatMap";
import PaymentGraph from "./Graphs/PaymentGraph";

class PaymentStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <PaymentHeatMap />
        <PaymentGraph />
      </div>
    );
  }
}

export default withRouter(observer(PaymentStats));
