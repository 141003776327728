import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../Components/Dividers/Div14";
import ServiceMain from "./Service/ServiceMain";
import { Tabs } from "antd";
import ACServiceMain from "./ACService/ACServiceMain";
import PaymentOptionMain from "../PaymentOptions/PaymentOptionMain";
import ZoneTab from "../Lists/Tabs/ZoneTab";
import TruckTab from "../Lists/Tabs/TruckTab";
import LocationTab from "../Lists/Tabs/LocationTab";

const { TabPane } = Tabs;

class AdminServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "Service",
    };
  }
  render() {
    return (
      <div>
        <Div14>Admin Services:</Div14>
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"Service"}
          tabPosition={"top"}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={<div>Services</div>}
            key={"Service"}
            disabled={this.state.locked}
          >
            <ServiceMain />
          </TabPane>
          <TabPane
            tab={<div>AC Orders</div>}
            key={"AC Orders"}
            disabled={this.state.locked}
          >
            <ACServiceMain />
          </TabPane>
          <TabPane
            tab={<div>Payment Options</div>}
            key={"Payment Opts"}
            disabled={this.state.locked}
          >
            <PaymentOptionMain />
          </TabPane>
          <TabPane tab={"Zones"} key={"Zones"} disabled={this.state.locked}>
            <ZoneTab />
          </TabPane>
          <TabPane tab={"Trucks"} key={"Trucks"} disabled={this.state.locked}>
            <TruckTab />
          </TabPane>
          <TabPane
            tab={"Location"}
            key={"Location"}
            disabled={this.state.locked}
          >
            <LocationTab />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default observer(AdminServices);
