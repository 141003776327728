import React, { Component } from "react";
import { FlexboxGrid, Col, Notification } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";

import axios from "axios";
import TextInputSTU from "../../../../Components/Inputs/Updatable/TextInputSTU";
import { observer } from "mobx-react";
import { message } from "antd";
import userStore from "../../../../Stores/UserStore";
import baseURL from "../../../../Static/baseURL/baseURL";
import Col2 from "../../../../Components/Columns/Col2";

import SelectInputSTU from "../../../../Components/Inputs/Updatable/SelectInputSTU";
import stafflisttype from "../../../../Static/Lists/hr/stafftypelist";
import TextAreaInputSTU from "../../../../Components/Inputs/Updatable/TextAreaInputSTU";
import NumbInputSTU from "../../../../Components/Inputs/Updatable/NumbInputSTU";
import DateInputSTU from "../../../../Components/Inputs/Updatable/DateInputSTU";
import singleStaffHRStore from "../../../../Stores/HR/SingleStaffHRStore";

const instance = axios.create({ baseURL: baseURL });

class SingleStaff extends Component {
  constructor(props) {
    super(props);
    this.state = { load: {} };
    this.updateVal = this.updateVal.bind(this);
  }

  updateVal = async (label, title, sj) => {
    let staff = singleStaffHRStore.singleedit;
    let val = staff[label];

    let url = `hr/staff/edit/${staff._id}`;

    let data = { [label]: val };
    console.log(data);
    this.setState({ load: { [label]: true } });

    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.editMainSingle(val, label);
        this.setState({ load: { [label]: false } });
        message.success(`${title} Updated.`);
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };
  render() {
    let staff = singleStaffHRStore.singlestaff;
    let edit = singleStaffHRStore.singleedit;
    let load = this.state.load;

    return (
      <div>
        <Div14>{staff.name} Personal Info</Div14>

        <FlexboxGrid>
          <Col2>
            <SelectInputSTU
              val={edit.active ? "Yes" : "No"}
              org={staff.active ? "Yes" : "No"}
              label="active"
              load={load.active}
              title="Active"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
              opts={["Yes", "No"]}
            />
          </Col2>
          {!staff.active && (
            <Col2>
              <DateInputSTU
                val={edit.exitdate}
                org={staff.exitdate}
                label="exitdate"
                load={load.exitdate}
                title="Exit Date"
                size="sm"
                editVal={singleStaffHRStore.editSingle}
                updateVal={this.updateVal}
              />
            </Col2>
          )}
        </FlexboxGrid>
        <hr />
        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <SelectInputSTU
              val={edit.hideatt ? "Hide" : "Show"}
              org={staff.hideatt ? "Hide" : "Show"}
              label="hideatt"
              load={load.hideatt}
              title="Attendance"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
              opts={["Show", "Hide"]}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={edit.civilid}
              org={staff.civilid}
              label="civilid"
              title="Civil ID"
              load={load.civilid}
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={edit.name}
              org={staff.name}
              label="name"
              title="Name"
              load={load.name}
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={edit.title}
              org={staff.title}
              label="title"
              title="Title"
              load={load.title}
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={edit.base}
              org={staff.base}
              label="base"
              load={load.base}
              title="Location"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <SelectInputSTU
              val={edit.type}
              org={staff.type}
              label="type"
              load={load.type}
              title="Type"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
              opts={stafflisttype}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={edit.email}
              org={staff.email}
              label="email"
              load={load.email}
              title="Email"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={edit.phone}
              org={staff.phone}
              label="phone"
              load={load.phone}
              title="Phone"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col md={24} sm={24} xs={24}>
            <TextAreaInputSTU
              val={edit.jobdesc}
              org={staff.jobdesc}
              label="jobdesc"
              load={load.jobdesc}
              title="Job Desc."
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col>
        </FlexboxGrid>

        <FlexboxGrid style={{ padding: "1%" }}>
          <Col2>
            <NumbInputSTU
              val={edit.monthlyholiday}
              org={staff.monthlyholiday}
              label="monthlyholiday"
              load={load.monthlyholiday}
              title="Monthly Days Off"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <DateInputSTU
              val={edit.joined}
              org={staff.joined}
              label="joined"
              load={load.joined}
              title="Join Date"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={edit.remaining}
              org={staff.remaining}
              label="remaining"
              load={load.remaining}
              title="Leaves Remaining"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={edit.sickremaining}
              org={staff.sickremaining}
              label="sickremaining"
              load={load.sickremaining}
              title="Sick Days Remaining"
              size="sm"
              editVal={singleStaffHRStore.editSingle}
              updateVal={this.updateVal}
            />
          </Col2>
        </FlexboxGrid>

        {/* <div>
          <Div14>Assign Staff</Div14>
          {staff.user ? (
            <div>
              <b>Assigned User: </b> {staff.user.group}: {staff.user.username} /{" "}
              {staff.user.email}
            </div>
          ) : (
            <b> No User Assigned </b>
          )}
          <AssignStaffToUser
            editVal={singleStaffHRStore.editSingle}
            updateVal={this.updateVal}
          />
        </div> */}
      </div>
    );
  }
}

export default observer(SingleStaff);
