import React, { Component } from "react";

import DataGrid, { Column, Grouping } from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";

import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

import { observer } from "mobx-react";
import {
  Parsed,
  PRKD,
} from "../../../../../../../../Functions/Pricing/PRKDCalc";
import newStorageStore from "../../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { Icon, IconButton } from "rsuite";

class AddedItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = { autoExpandAll: true };
    this.EditActionButton = this.EditActionButton.bind(this);
    this.DeleteActionButton = this.DeleteActionButton.bind(this);
  }

  EditActionButton(cellData) {
    return (
      <IconButton
        icon={<Icon icon="edit" />}
        color="green"
        circle
        size="xs"
        onClick={() => {
          this.props.selectItem(cellData);
        }}
      />
    );
  }

  DeleteActionButton(cellData) {
    return (
      <IconButton
        icon={<Icon icon="trash" />}
        color="red"
        circle
        size="xs"
        onClick={() => {
          newStorageStore.removeItem(cellData.rowIndex, this.props.index);
        }}
      />
    );
  }
  DiffCell(cellData) {
    return <div>{cellData.data.acItemMoveNumId > 0 ? "Saved" : "New"}</div>;
  }
  Dimension(cellData) {
    return `${PRKD(cellData.data.itemlength / 100)} m x ${PRKD(
      cellData.data.itemwidth / 100
    )} m x ${PRKD(cellData.data.itemheight / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.itemlength) *
        Parsed(cellData.data.itemwidth) *
        Parsed(cellData.data.itemheight)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.itemlength) *
        Parsed(cellData.data.itemwidth) *
        Parsed(cellData.data.quantity) *
        Parsed(cellData.data.itemheight)) /
      1000000;
    return PRKD(cbmperitem);
  }
  render() {
    let hideaction = this.props.hideaction;

    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
        }}
      >
        <DataGrid
          dataSource={this.props.acitemmove}
          keyExpr="acItemNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          hoverStateEnabled={true}
          noDataText="No Items Added"
        >
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />

          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />

          <ColumnFixing enabled={true} />
          {!hideaction && (
            <Column
              caption="Edit"
              alignment={"center"}
              cellRender={this.EditActionButton}
              width={45}
            />
          )}
          {!hideaction && (
            <Column
              dataField="acItemMoveNumId"
              dataType="number"
              caption="Move ID"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              minWidth={80}
              cellRender={this.DiffCell}
            />
          )}
          <Column caption="Item Details" alignment="center">
            <Column
              dataField="acItemNumId"
              dataType="number"
              caption="Item ID"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              minWidth={80}
            />
            <Column
              dataField="itemname"
              caption="Item"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="itemserialnumber"
              caption="Ser. No."
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="itemproductid"
              caption="Prod. No."
              alignment="center"
              minWidth={80}
            />
          </Column>
          <Column
            dataField="dimensions"
            caption="Dimensions"
            alignment="center"
            minWidth={140}
            cellRender={this.Dimension}
          />
          <Column
            dataField="quantity"
            dataType="number"
            caption="Quantity"
            alignment="center"
            format={{ type: "fixedPoint", precision: 0 }}
            minWidth={80}
          />
          <Column caption="CBM Calcs" alignment="center">
            <Column
              dataField="cbmperitem"
              dataType="number"
              caption="CBM Per Item"
              alignment="center"
              minWidth={80}
              cellRender={this.CBMPerItem}
            />
            <Column
              dataField="totalcbm"
              dataType="number"
              caption="Total CBM"
              alignment="center"
              minWidth={80}
              cellRender={this.TotalCBM}
            />
          </Column>
          {!hideaction && (
            <Column
              caption="Delete"
              alignment={"center"}
              cellRender={this.DeleteActionButton}
              width={45}
            />
          )}
        </DataGrid>
      </div>
    );
  }
}

export default observer(AddedItemsList);
