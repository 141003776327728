import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Icon, Notification, HelpBlock } from "rsuite";
import { Loader, FlexboxGrid, IconButton } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import paymentStore from "../../../../../Stores/SideJobs/PaymentStore";
import singleJobStore from "../../../../../Stores/SingleJobStore";
import userStore from "../../../../../Stores/UserStore";
import PaymentButtons from "./PaymentButtons";
import PaymentEdit from "./PaymentEdit";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import ReceiptPDF from "../../../../../Components/PDFs/Payments/ReceiptPDF";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import invoiceStore from "../../../../../Stores/SideJobs/InvoiceStore";

const instance = axios.create({ baseURL: baseURL });

class PaymentMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      type: "New",
      showReceipt: false,
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.resetState = this.resetState.bind(this);
    this.postChange = this.postChange.bind(this);
  }

  resetState() {
    this.setState({ show: true, showReceipt: false, type: "New" });
  }
  showHideModal(val) {
    if (val) {
      this.setState({ show: false, showReceipt: false });
      if (val === "New") this.resetState();
    } else {
      this.setState({ show: false, showReceipt: false });
    }
  }

  componentDidMount() {
    paymentStore.getPaymentById(singleJobStore.job._id);
  }

  postChange = async () => {
    this.setState({ loading: true });
    let pay = paymentStore.newpayment;

    let data = {
      type: pay.type,
      subtype: pay.subtype,
      specific: pay.specific,
      accounttype: "Customer",
      cash: pay.cash,
      date: pay.date,
      method: pay.method,
      account: pay.account,
      partyname: pay.partyname,
      paymentref: pay.paymentref,
      amount: pay.amount,
      remarks: pay.remarks,
    };
    if (pay.type === "Income") {
      if (pay.invoiceids) {
        if (pay.invoiceids._id !== "") {
          data.invoiceids = pay.invoiceids._id;
        }
      }
    }

    if (this.state.type === "New") {
      data.jobid = singleJobStore.job._id;

      return instance
        .post(`/jf/payment/new`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          paymentStore.getPaymentById(singleJobStore.job._id, 1);
          this.setState({ loading: false });
          Notification["success"]({
            title: `New Payment Created.`,
            description: (
              <div>
                {data.title} for {data.amount} KD
              </div>
            ),
          });
          this.showHideModal(false);
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else if (this.state.type === "Edit") {
      return instance
        .put(`/jf/payment/edit/${pay._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          paymentStore.getPaymentById(singleJobStore.job._id, 1);
          this.setState({ loading: false });
          Notification["success"]({
            title: `Edited Payment #${pay.paymentNumId}.`,
            description: (
              <div>
                {data.title} for {data.amount} KD
              </div>
            ),
          });
          this.showHideModal(false);
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else if (this.state.type === "Delete") {
      return instance
        .put(`/jf/payment/deletebyid/${pay._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          paymentStore.getPaymentById(singleJobStore.job._id, 1);
          this.setState({ loading: false });
          Notification["success"]({
            title: `Payment #${pay.paymentNumId} Deleted.`,
            description: (
              <div>
                {data.title} for {data.amount} KD
              </div>
            ),
          });
          this.showHideModal(false);
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    }
  };

  render() {
    let payment = paymentStore.newpayment;
    let data = paymentStore.payments;
    let editview = this.props.editview;

    if (!editview) {
      data = data.filter((dat) => dat.specific === "Customer Payment");
    }

    let columns = [
      { title: "ID", field: "paymentNumId" },
      { title: "Type", field: "type" },
      { title: "SubType", field: "subtype" },
      { title: "Item", field: "specific" },
      { title: "Amount", field: "amount" },
      { title: "Method", field: "method" },
      { title: "Account", field: "account", hidden: true },
      {
        title: "Date",
        field: "date",
        render: (data) => moment(data.date).format("DD-MMM-YY"),
        defaultSort: "asc",
      },
      { title: "Ref", field: "paymentref" },
      {
        title: "Invoice",
        field: "data.invoiceids",
        render: (data) => {
          if (data.type === "Income") {
            if (data.invoiceids) {
              if (data.invoiceids.invoiceNumId) {
                return `Inv #${data.invoiceids.invoiceNumId}`;
              }
            }
          } else {
            return `Expense`;
          }
          return "";
        },
      },
      { title: "Remarks", field: "remarks" },
    ];

    let disabled = false;
    if (this.state.type !== "Delete") {
      if (parseFloat(payment.amount) === 0) disabled = true;
      if (payment.date === "") disabled = true;
      if (payment.type === "") disabled = true;
      if (payment.subtype === "") disabled = true;
      if (payment.specific === "") disabled = true;
      if (payment.cash === "") disabled = true;
      if (payment.method === "") disabled = true;
      if (payment.account === "") disabled = true;
    }

    let footer = (
      <FlexboxGrid>
        <Col2>
          <IconButton
            icon={<Icon icon="close" />}
            block
            onClick={() => this.showHideModal(false)}
            loading={this.state.loading}
          >
            Cancel
          </IconButton>
        </Col2>
        <Col2>
          <IconButton
            icon={<Icon icon="close" />}
            block
            color={this.state.type === "Delete" ? "red" : "green"}
            onClick={() => this.postChange("New")}
            disabled={disabled}
            loading={this.state.loading}
          >
            {this.state.type === "Delete"
              ? "Yes Delete"
              : this.state.type === "Edit"
              ? "Save Changes"
              : "Create Payment"}
          </IconButton>
        </Col2>
      </FlexboxGrid>
    );
    let total = 0;
    let totalexpense = 0;

    for (let i = 0; i < paymentStore.payments.length; i++) {
      if (paymentStore.payments[i].type === "Income") {
        total += parseFloat(paymentStore.payments[i].amount);
      } else {
        totalexpense += parseFloat(paymentStore.payments[i].amount);
      }
    }
    let invoices = invoiceStore.invoices.filter(
      (inv) => inv.status !== "Cancelled"
    );
    let totalinv = 0;
    for (let i = 0; i < invoices.length; i++) {
      totalinv += parseFloat(invoices[i].total);
    }
    let diff = PRKD(parseFloat(totalinv) - parseFloat(total));
    let qualitytext;
    if (this.props.quality) {
      if (diff < 0) {
        qualitytext = (
          <HelpBlock style={{ color: "red", fontWeight: "bold" }}>
            {diff} KD Over Paid.
          </HelpBlock>
        );
      } else if (diff > 0) {
        qualitytext = (
          <HelpBlock style={{ color: "red", fontWeight: "bold" }}>
            {diff} KD Remaining to Pay.
          </HelpBlock>
        );
      } else {
        qualitytext = (
          <HelpBlock style={{ color: "green", fontWeight: "bold" }}>
            Payments and Invoices Match.
          </HelpBlock>
        );
      }
    }

    return (
      <div>
        <h4 style={{ textAlign: "center" }}>
          Payments (Total {PRKD(total)} KWD)
          {qualitytext}
        </h4>
        {editview && (
          <h6 style={{ textAlign: "center" }}>
            Total Expenses {PRKD(totalexpense)} KD (
            {PRKD(PRKD(total) - PRKD(totalexpense))} KD{" "}
            {PRKD(total) > PRKD(totalexpense) ? "Profit" : "Loss"})
          </h6>
        )}
        <UnifiedModal
          show={this.state.showReceipt}
          title={`Receipt #${payment.paymentNumId}`}
          onHide={this.showHideModal}
          footer={<div />}
        >
          <ReceiptPDF rct={payment} />
        </UnifiedModal>
        <UnifiedModal
          show={this.state.show}
          title={`${this.state.type} Payment`}
          onHide={this.showHideModal}
          footer={footer}
        >
          {this.state.type === "Delete" ? (
            <div>
              <h6>
                Are you sure you want to delete payment #{payment.paymentNumId}?
              </h6>
              <p>
                <b>Type: </b>
                {payment.type} - {payment.subtype} - {payment.specific}
                <br />
                <b>Amount: </b>
                {payment.amount} on {moment(payment.date).format("DD-MMM-YY")}
                <br />
                <b>Method - Account: </b>
                {payment.method} - {payment.account}
                <br />
              </p>
            </div>
          ) : (
            <PaymentEdit />
          )}
        </UnifiedModal>
        {paymentStore.loading ? (
          <Loader center size="large" />
        ) : (
          <div>
            {editview && <PaymentButtons showHideModal={this.showHideModal} />}
            <MaterialTable
              title={<h6>{`${data.length} Payments`}</h6>}
              columns={columns}
              style={{ fontSize: 12 }}
              data={data}
              options={{
                actionsColumnIndex: 0,
                exportAllData: editview,
                paging: false,
                pageSizeOptions: [5, 10, 15, 20, 25],
                toolbarButtonAlignment: "left",
                maxBodyHeight: 1000,
                tableLayout: "auto",
                exportButton: editview,
                exportFileName: `Payments as of ${moment(new Date()).format(
                  "DD-MMM-YY"
                )}`,
                padding: "dense",
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.status === "Cancelled" && "#ffafa3",
                }),
              }}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Refresh",
                  isFreeAction: true,
                  onClick: (event, rowData) => {
                    paymentStore.getPaymentById(singleJobStore.job._id, 1);
                  },
                },
                (rowData) => ({
                  icon: "save",
                  tooltip: "View Receipt",
                  disabled: rowData.type !== "Income",
                  onClick: (event, rowData) => {
                    paymentStore.changePayment(rowData);
                    this.setState({ payment: rowData, showReceipt: true });
                  },
                }),
                (rowData) => ({
                  icon: "edit",
                  tooltip: "Edit Payment",
                  hidden: rowData.status === "Cancelled" || !editview,
                  onClick: (event, rowData) => {
                    paymentStore.changePayment(rowData);
                    this.setState({ show: true, type: "Edit" });
                  },
                }),
                (rowData) => ({
                  icon: "delete",
                  tooltip: "Delete Payment",
                  hidden: !userStore.user.admin || !editview,
                  onClick: (event, rowData) => {
                    paymentStore.changePayment(rowData);
                    this.setState({ show: true, type: "Delete" });
                  },
                }),
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default observer(PaymentMain);
