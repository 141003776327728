import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";

class GaugeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let series = [0];
    if (this.props.value) series = [this.props.value];

    let color = "#e7e7e7";
    if (this.props.color) color = this.props.color;

    let options = {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,

          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            color: color,
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },

      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        colors: [color],
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      labels: ["Percent"],
    };

    return (
      <ReactApexChart options={options} series={series} type="radialBar" />
    );
  }
}

export default withRouter(observer(GaugeGraph));
