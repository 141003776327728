import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import { Notification } from "rsuite";

const instance = axios.create({ baseURL: baseURL });

class PaymentStore {
  constructor() {
    this.newpayment = {
      title: "",
      specific: "Income",
      detailed: "Job Related",
      paymentref: "",
      partyname: "",
      date: moment(),
      method: "Online Link",
      account: "Boubyan",
      cash: "Cash",
      amount: 0,
      jobid: "",
      invoiceids: { _id: "", invoiceNumId: -1 },
      receiptids: { _id: "", receiptNumId: -1 },
      user: "",
      remarks: "",
      _id: "",
      paymentNumId: "",
    };
    this.payments = [];
    this.jobid = "";
    this.loading = false;

    makeObservable(this, {
      newpayment: observable,
      payments: observable,
      jobid: observable,
      loading: observable,
    });
  }

  // FETCH
  getPaymentById(jobid, overide) {
    if (!overide) {
      if (this.loading || this.jobid === jobid) return null;
    }
    this.loading = true;
    return instance
      .get(`/jf/payment/getbyjobid/${jobid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.jobid = jobid;
        this.payments = [...res.data];
        this.loading = false;
        console.log("Payments Fetched");
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Payments.`,
        });
      });
  }

  createNewPayment() {
    this.newpayment = {
      title: "",
      specific: "Income",
      detailed: "Job Related",
      paymentref: "",
      partyname: "",
      date: moment(),
      method: "Online Link",
      account: "Boubyan",
      cash: "Cash",
      amount: 0,
      jobid: "",
      invoiceids: { _id: "", invoiceNumId: -1 },
      receiptids: { _id: "", receiptNumId: -1 },
      accounttype: "",

      user: "",
      remarks: "",
      _id: "",
      paymentNumId: "",
    };
  }

  changePayment(payment) {
    paymentStore.newpayment = payment;
  }

  editPayment(val, label) {
    paymentStore.newpayment[label] = val;
  }
}

const paymentStore = new PaymentStore();
export default paymentStore;
