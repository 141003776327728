import { observer } from "mobx-react";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock, InputNumber } from "rsuite";
import { Tooltip } from "antd";

import sjStore from "../../../Stores/SideJobs/SJStore";

class SJNumbInput extends Component {
  render() {
    let ind = this.props.ind;
    let label = this.props.label;
    let title = this.props.title;
    let load = this.props.load;

    let org = sjStore.sjs[ind][label];
    let val = sjStore.esjs[ind][label];

    let sj = this.props.sj;

    if (sj) {
      org = sjStore.sjs[ind][sj][label];
      val = sjStore.esjs[ind][sj][label];
    }

    let read = false;
    let size = "lg";
    let step = 1;
    let max = 10000000;
    let min = 0;
    let disabled = false;

    if (this.props.value) val = this.props.value;
    if (this.props.value) org = this.props.value;
    if (this.props.read) read = this.props.read;
    if (this.props.size) size = this.props.size;
    if (this.props.step) step = this.props.step;
    if (this.props.max) max = this.props.max;
    if (this.props.min) min = this.props.min;
    if (this.props.disabled) disabled = this.props.disabled;

    if (read) step = 0;

    let change = false;

    if (org !== val) change = true;

    if (read || disabled) change = false;

    let quality = this.props.quality;
    let qualitytext = false;
    let flag = false;

    if (quality) {
      if (val <= 0) {
        flag = true;
        qualitytext = `${title} must be greater than 0.`;
      }
    }

    return (
      <div style={{ marginTop: "5px" }}>
        <InputGroup>
          {change && (
            <InputGroup.Button
              loading={load}
              onClick={() => this.props.editVal(org, label, sj)}
            >
              <Icon icon="refresh" />
            </InputGroup.Button>
          )}
          <Tooltip title={qualitytext}>
            <InputGroup.Addon style={{ backgroundColor: flag && "#ff968c" }}>
              {title}
            </InputGroup.Addon>
          </Tooltip>
          <InputNumber
            readOnly={read}
            step={step}
            min={min}
            max={max}
            size={size}
            value={val}
            disabled={disabled}
            onChange={(e) => this.props.editVal(e, label, sj)}
          />
          {change && (
            <InputGroup.Button
              color="green"
              loading={load}
              onClick={() => this.props.updateVal(label, title, sj)}
              disabled={val === ""}
            >
              <Icon icon="check" />
            </InputGroup.Button>
          )}
        </InputGroup>
        {change && (
          <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
            Changed from <i>{org}</i>
          </HelpBlock>
        )}
      </div>
    );
  }
}

export default observer(SJNumbInput);
