import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class ACClearanceStore {
  constructor() {
    this.clientId = "";
    this.cancelledorders = [];
    this.pastorders = [];
    this.calendarjobs = [];
    this.loading = {
      order: false,
      tracking: false,
      finance: false,
      calendar: false,
    };
    this.loaded = {
      pastorders: false,
      cancelledorders: false,
      order: false,
      tracking: false,
      finance: false,
      calendar: false,
    };
    this.client = {};
    this.orders = [];
    this.singledel = {};

    this.startdate = moment().subtract(180, "days");

    makeObservable(this, {
      clientId: observable,
      loading: observable,
      loaded: observable,
      startdate: observable,
      singledel: observable,
      orders: observable,
      cancelledorders: observable,
      pastorders: observable,
      calendarjobs: observable,
    });
  }

  // FETCH

  getOrders(clientId, override) {
    let newclient = this.clientId;
    let loaded = false;
    if (this.loading.order & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;
    if (clientId) newclient = clientId;

    if (!loaded) {
      this.loading.order = true;
      this.loaded.delcharges = false;

      this.loaded.order = false;
      let url = `/ac/accorder/get/byclient/${newclient}`;
      this.clientId = newclient;
      let data = { startdate: this.startdate };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.orders = data.orders;
          this.client = data.client;
          this.loading.order = false;
          this.loaded.order = true;
          this.loaded.pastorders = false;
          this.loaded.cancelledorders = false;
          console.log("Orders Fetched");
        })
        .catch((err) => {
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Orders.`,
          });
        });
    }
  }
  getCalendarJobs(override) {
    let newclient = this.client._id;

    this.loading.calendar = true;
    this.loaded.delcharges = false;

    this.loaded.calendar = false;
    let url = `/ac/accorder/calendar/byclient/${newclient}`;
    this.clientId = newclient;
    let data = { startdate: this.startdate };
    return instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.calendarjobs = data;
        this.loading.calendar = false;
        this.loaded.calendar = true;

        console.log("Calendar Jobs Fetched");
      })
      .catch((err) => {
        this.loading.calendar = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Calendar Jobs.`,
        });
      });
  }

  getCustomOrderStatus(status, override) {
    let loaded = false;
    let clID = this.clientId;
    let urlcode;
    if (status === "Cancelled") {
      if (this.loaded.cancelledorders) loaded = true;
      urlcode = "cancelledbyclient";
    } else if (status === "Completed") {
      if (this.loaded.pastorders) loaded = true;
      urlcode = "completedbyclient";
    }
    if (override) loaded = false;
    if (!loaded) {
      this.loading.order = true;
      this.loaded.order = false;
      let data = { startdate: this.startdate };

      return instance
        .put(`/ac/accorder/get/${urlcode}/${clID}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((orders) => {
          if (status === "Cancelled") {
            this.cancelledorders = orders;
            this.loaded.cancelledorders = true;
            console.log("Cancelled Clearance Jobs Fetched for Client");
          } else if (status === "Completed") {
            this.pastorders = orders;
            this.loaded.pastorders = true;
            console.log("Past Clearance Jobs Fetched for Client");
          }
          this.loading.order = false;
        })
        .catch((err) => {
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching ${status} Clearance Jobs for Client.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  editDate(val) {
    acClearanceStore.startdate = val;
  }

  // ----- DATE ----
}

const acClearanceStore = new ACClearanceStore();
export default acClearanceStore;
