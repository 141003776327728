import { Statistic } from "antd";
import MaterialTable from "material-table";
import { observer } from "mobx-react";

import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import HolidayCalendar from "../../../../Components/Calendar/HolidayCalendar";

import userStore from "../../../../Stores/UserStore";
import NewHolidayModal from "./NewHolidayModal";

class ProfileHoliday extends Component {
  constructor(props) {
    super(props);
    this.state = { hideRej: true };
  }
  render() {
    let user = userStore.user;

    if (!user) return null;
    let staff = user.staff;
    if (!staff) return null;

    let data = staff.holidays.filter((dt) => dt.type !== "Monthly Allowance");
    if (!data) return null;
    let filtdata = data.filter((dt) => dt.status !== "Reject");
    if (this.state.hideRej) {
      data = data.filter((dt) => dt.status !== "Reject");
    }
    let columnnames = [
      { title: "Status", field: "status" },
      { title: "Type", field: "type" },
      {
        title: "From",
        field: "date",
        render: (row) => moment(row.date).format("DD-MMM-YY"),
        defaultSort: "asc",
      },
      {
        title: "To",
        field: "dateto",
        render: (row) => moment(row.dateto).format("DD-MMM-YY"),
      },
      { title: "Days", field: "days" },
      { title: "Remarks", field: "remark" },
    ];
    let sickdays = 0;
    let totaldays = 0;
    let currentyear = data.filter(
      (dt) =>
        (dt.status !== "Reject") & (moment(dt.date).year() === moment().year())
    );
    for (let i = 0; i < currentyear.length; i++) {
      if (currentyear[i].type === "Sick Leave") {
        sickdays += parseFloat(currentyear[i].days);
      } else {
        totaldays += parseFloat(currentyear[i].days);
      }
    }
    return (
      <div>
        <FlexboxGrid justify="space-around">
          <Statistic
            title="Available Days"
            value={staff.remaining}
            valueStyle={{
              color: userStore.user.remaining < 0 ? "red" : "green",
              fontWeight: "bold",
            }}
            suffix="days"
          />
          <Statistic
            title={`Leaves in ${moment().year()}`}
            value={totaldays}
            valueStyle={{ fontWeight: "bold" }}
            suffix="days"
          />
          <Statistic
            title={`Sick Days in ${moment().year()}`}
            value={sickdays}
            valueStyle={{ fontWeight: "bold" }}
            suffix="days"
          />
        </FlexboxGrid>
        <hr />
        <NewHolidayModal />
        <MaterialTable
          title={data.length + " Holiday List"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : "Staff List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.status === "Approve"
                  ? "#e3ffe7"
                  : rowData.status === "Pending"
                  ? "yellow"
                  : rowData.status === "Reject" && "#ffc9c9",
            }),
          }}
          actions={[
            {
              icon: "refresh",
              isFreeAction: true,
              tooltip: "Refresh",
              onClick: (event, rowData) => {
                userStore.fetchProfile(true);
              },
            },
            {
              icon: "close",
              isFreeAction: true,
              hidden: this.state.hideRej,
              tooltip: "Hide Rejected",
              onClick: (event, rowData) => {
                this.setState({ hideRej: !this.state.hideRej });
              },
            },
            {
              icon: "check",
              isFreeAction: true,
              hidden: !this.state.hideRej,
              tooltip: "Show Rejected",
              onClick: (event, rowData) => {
                this.setState({ hideRej: !this.state.hideRej });
              },
            },
          ]}
        />
        <hr />
        <HolidayCalendar
          start={"date"}
          end={"dateto"}
          title={"type"}
          data={filtdata}
          height={300}
          onSelectEvent={() => console.log(" ")}
        />
      </div>
    );
  }
}

export default observer(ProfileHoliday);
