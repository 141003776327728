import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import singleAccountFinancialStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountFinancialStore";
import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import Div14 from "../../../../Components/Dividers/Div14";
import LoadBack from "../../../../Components/Loaders/LoadBack";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataGrid, {
  Column,
  Grouping,
  ColumnChooser,
  ColumnFixing,
  GroupPanel,
  Export,
  Scrolling,
  SearchPanel,
  Summary,
  TotalItem,
  FilterRow,
  Item,
  Toolbar,
  LoadPanel,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import { Tooltip } from "@material-ui/core";
import { Icon, IconButton } from "rsuite";
import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import MainDelInvoice from "../../../../Components/PDFs/DelInvoices/MainDelInvoice.js";
import MainACInvoice from "../../../../Components/PDFs/ACInvoices/MainACInvoice.js";
import MainACCInvoice from "../../../../Components/PDFs/ACCInvoice/MainACCInvoice";
import MainAIMInvoice from "../../../../Components/PDFs/AIMInvoice/MainAIMInvoice";

class AllAccountInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoExpandAll: true,
      acinvoice: {},
      show: false,
      showedit: false,
      load: false,
      type: "",
    };
    this.onExporting = this.onExporting.bind(this);
    this.DownloadInvoice = this.DownloadInvoice.bind(this);
    this.StatusCell = this.StatusCell.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    this.setState({
      show: false,
      showedit: false,
      acinvoice: {},
      load: false,
      type: "",
    });
  }
  componentDidMount() {
    singleAccountFinancialStore.getInvoices(singleAccountStore.account._id);
  }
  StatusCell(cellData) {
    let status = cellData.data.status;
    let color;
    if (status === "Pending") {
      color = "red";
    } else {
      color = "green";
    }
    return <div style={{ color: color, fontWeight: "bold" }}>{status}</div>;
  }

  DownloadInvoice(cellData) {
    return (
      <>
        <Tooltip title={`View Invoice`} arrow>
          <IconButton
            icon={<Icon icon="save" />}
            color="blue"
            circle
            size="xs"
            onClick={() => {
              let type = cellData.data.invId.slice(0, 3);

              this.setState({
                show: true,
                acinvoice: cellData.data,
                type: type,
              });
            }}
          />
        </Tooltip>
      </>
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Client_Invoice_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  render() {
    let type = this.state.type;
    let inv = this.state.acinvoice;
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title={`${type} Invoice PDF View`}
          footer={null}
        >
          {type === "DEL" && <MainDelInvoice inv={inv} />}
          {type === "STO" && <MainACInvoice inv={inv} />}
          {type === "ACC" && <MainACCInvoice inv={inv} />}
          {type === "AIM" && <MainAIMInvoice inv={inv} />}
        </UnifiedModal>
        <Div14>Account Invoices</Div14>
        <LoadBack loading={singleAccountFinancialStore.loading.invoices}>
          <DataGrid
            dataSource={singleAccountFinancialStore.invoices}
            keyExpr="invId"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <LoadPanel enabled={true} />
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />

            <Column
              dataField="invId"
              dataType="number"
              caption="ID"
              alignment="center"
              width={80}
            />
            <Column
              dataField="date"
              dataType="date"
              caption="Invoice Date"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
            />
            <Column
              dataField="invoicetype"
              dataType="text"
              caption="Invoice Type"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="title"
              dataType="text"
              caption="Invoice Title"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="status"
              caption="Status"
              alignment="center"
              format={"dd-MMM-yy"}
              minWidth={80}
              cellRender={this.StatusCell}
            />

            <Column
              dataField="totalinvoicecost"
              dataType="number"
              caption="Final Cost (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minWidth={80}
            />
            <Column
              caption="Download Invoice"
              alignment="center"
              minWidth={80}
              cellRender={this.DownloadInvoice}
            />

            <Summary>
              <TotalItem
                column="invId"
                summaryType="count"
                displayFormat={"{0}"}
              />
              <TotalItem
                column="totalinvoicecost"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Toolbar>
              <Item name="Refresh" location="before">
                <Button
                  icon="refresh"
                  onClick={() =>
                    singleAccountFinancialStore.getInvoices(false, true)
                  }
                />
              </Item>
              <Tooltip title={`Choose Columns`} arrow placement="left">
                <Item name="columnChooserButton" />
              </Tooltip>
              <Item name="exportButton" />
            </Toolbar>
            <Export enabled={true} />
          </DataGrid>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(AllAccountInvoices));
