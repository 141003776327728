import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";
import hrStore from "../../../../Stores/HR/HRStore";
import axios from "axios";

import { DatePicker, Tooltip } from "antd";
import {
  Icon,
  IconButton,
  FlexboxGrid,
  Col,
  Notification,
  Loader,
} from "rsuite";
import SalaryModal from "./SalaryModal";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import SalaryGrid from "./StaffTable/SalaryGrid";
import SalaryMTable from "./StaffTable/SalaryMTable";

const instance = axios.create({ baseURL: baseURL });
const { RangePicker } = DatePicker;
class SalaryMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment(),
      loading: false,
      staff: {
        name: "",
        salary: "",
        hrStaffNumId: "",
        type: "",
      },
      show: false,
      type: "New",
      salary: {
        type: "Expense Fixed",
        subtype: "Staff Related",
        specific: "Salary",
        cash: "Cash",
        date: moment(),
        salarymonth: moment().subtract(1, "month").startOf("month"),
        method: "Bank Transfer",
        account: "KFH",
        amount: 0,
        hrstaff: "",
        paymentref: "",
        accounttype: "General",
        partyname: "",
        remarks: "",
        _id: "",
      },
    };
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postEdit = this.postEdit.bind(this);
    this.startNewPayment = this.startNewPayment.bind(this);
    this.changeMethod = this.changeMethod.bind(this);
  }

  changeMethod(type) {
    let salary = { ...this.state.salary };
    salary.method = type;
    if (type === "Bank Transfer") {
      salary.account = "KFH";
    } else if (type === "Cash") {
      salary.account = "Cash";
    }
    this.setState({ salary: salary });
  }

  postNew = async () => {
    this.setState({ loading: true });
    let pay = this.state.salary;
    let data = {
      type: pay.type,
      subtype: pay.subtype,
      specific: pay.specific,
      cash: pay.cash,
      date: pay.date,
      salarymonth: pay.salarymonth,
      method: pay.method,
      account: pay.account,
      accounttype: pay.accounttype,
      amount: pay.amount,
      paymentref: pay.paymentref,
      partyname: pay.partyname,
      remarks: pay.remarks,
      hrstaff: pay.hrstaff,
    };
    return instance
      .post(`/jf/payment/new`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getSalaries(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `New Salary Payment Created.`,
          description: <div>{data.amount} KD</div>,
        });
        this.onHide();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  postEdit = async () => {
    this.setState({ loading: true });
    let pay = this.state.salary;
    let data = {
      type: pay.type,
      subtype: pay.subtype,
      specific: pay.specific,
      cash: pay.cash,
      date: pay.date,
      salarymonth: pay.salarymonth,
      method: pay.method,
      account: pay.account,
      accounttype: pay.accounttype,
      amount: pay.amount,
      paymentref: pay.paymentref,
      partyname: pay.partyname,
      remarks: pay.remarks,
      hrstaff: pay.hrstaff,
    };
    return instance
      .put(`/jf/payment/edit/${pay._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getSalaries(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Updated Salary Payment Details.`,
          description: <div>{data.amount} KD</div>,
        });
        this.onHide();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  editVal(val, label) {
    if (label === "salarymonth") val = moment(val).startOf("month");
    let salary = { ...this.state.salary };
    salary[label] = val;
    this.setState({ salary: salary });
  }

  startNewPayment(type, month, staff, sal) {
    let salary = { ...this.state.salary };
    salary.salarymonth = moment(month).startOf("month");
    salary.amount = staff.totalsalary;
    salary.hrstaff = staff._id;

    let newstaff = { ...this.state.newstaff };
    newstaff.name = staff.name;
    newstaff.totalsalary = staff.totalsalary;
    newstaff.type = staff.type;
    newstaff.hrStaffNumId = staff.hrStaffNumId;
    if (type === "Edit") {
      salary.salarymonth = sal.salarymonth;
      salary.amount = parseFloat(sal.amount);
      salary.date = sal.date;
      salary.paymentref = sal.paymentref;
      salary.method = sal.method;
      salary.account = sal.account;
      salary.remarks = sal.remarks;
      salary._id = sal._id;
    }

    this.setState({
      show: true,
      type: type,
      salary: salary,
      staff: newstaff,
    });
  }

  onHide() {
    let staff = {
      name: "",
      salary: "",
      hrStaffNumId: "",
      type: "",
    };
    let salary = {
      type: "Expense Fixed",
      subtype: "Staff Related",
      specific: "Salary",
      cash: "Cash",
      date: moment(),
      salarymonth: moment().subtract(1, "month").startOf("month"),
      method: "Bank Transfer",
      account: "KFH",
      accounttype: "General",
      partyname: "",
      amount: 0,
      hrstaff: "",
      paymentref: "",
      remarks: "",
      _id: "",
    };
    this.setState({ show: false, salary: salary, type: "New", staff: staff });
  }
  componentDidMount() {
    hrStore.getSalaries();
  }
  render() {
    let fd = hrStore.filtdates;

    return (
      <div>
        <Div14>Salaries</Div14>
        <FlexboxGrid style={{ padding: "1%" }} justify="space-between">
          <Col md={20} sm={18} xs={16}>
            <RangePicker
              style={{ width: "100%" }}
              format={"MMM-YY"}
              picker={"month"}
              value={[moment(fd.startsalary), moment(fd.endsalary)]}
              allowClear={false}
              showMinute={false}
              onChange={(date) => {
                hrStore.changeFiltDate(
                  moment(date[0]).startOf("month"),
                  moment(date[1]).startOf("month"),
                  "Salary"
                );
              }}
            />
          </Col>
          <Col md={4} sm={6} xs={8}>
            <Tooltip title="Refresh">
              <IconButton
                circle
                color="green"
                icon={<Icon icon="refresh" />}
                onClick={() => hrStore.getSalaries(true)}
              />
            </Tooltip>
          </Col>
        </FlexboxGrid>
        <SalaryModal
          show={this.state.show}
          type={this.state.type}
          salary={this.state.salary}
          onHide={this.onHide}
          postNew={this.postNew}
          postEdit={this.postEdit}
          editVal={this.editVal}
          staff={this.state.staff}
          loading={this.state.loading}
          changeMethod={this.changeMethod}
        />
        {hrStore.loading ? (
          <Loader center />
        ) : (
          <div>
            <SalaryGrid startNewPayment={this.startNewPayment} />
            <SalaryMTable startNewPayment={this.startNewPayment} />
          </div>
        )}
      </div>
    );
  }
}

export default observer(SalaryMain);
