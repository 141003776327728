import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import SelectInputSTU from "../../../../../Components/Inputs/Updatable/SelectInputSTU";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import shippingmodetypes from "../../../../../Static/Lists/jobsubtypes/cclists/shippingmodetypes";
import commoditytypes from "../../../../../Static/Lists/jobsubtypes/imlists/commoditytypes";
import Div14 from "../../../../../Components/Dividers/Div14";
import imtypes from "../../../../../Static/Lists/jobsubtypes/imtypes";
import TextInputSTU from "../../../../../Components/Inputs/Updatable/TextInputSTU";
import TextAreaInputSTU from "../../../../../Components/Inputs/Updatable/TextAreaInputSTU";
import NumbInputSTU from "../../../../../Components/Inputs/Updatable/NumbInputSTU";
import inboundlist from "../../../../../Static/Lists/imlists/inboundlist";
import outboundlist from "../../../../../Static/Lists/imlists/outboundlist";
import { Parsed } from "../../../../../Functions/Pricing/PRKDCalc";
import shippinglines from "../../../../../Static/Lists/jobsubtypes/imlists/shippinglinelistterminal49";

import { InputGroup, Icon, HelpBlock } from "rsuite";
import { Select } from "antd";

const { Option } = Select;

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let newaim = newAimStore.newaim;
    let oldaim = newAimStore.oldaim;
    let load = this.props.load;
    let list = inboundlist;
    if (oldaim.direction === "Outbound") {
      list = outboundlist;
    }

    let costmap = list.map((ch, index) => (
      <Col2 key={index}>
        <NumbInputSTU
          val={newaim[ch.label]}
          org={oldaim[ch.label]}
          label={ch.label}
          load={load[ch.label]}
          title={ch.title}
          size="sm"
          min={0}
          editVal={this.props.editVal}
          updateVal={this.props.postChanges}
        />
      </Col2>
    ));
    let totalcost = 0;
    for (let i = 0; i < list.length; i++) {
      totalcost += Parsed(oldaim[list[i].label]);
    }
    let options = shippinglines.map((val, index) => (
      <Option value={val.scac} key={index}>
        {val.scac}: {val.name}
      </Option>
    ));

    let trackchg = false;
    if (newaim.scac !== oldaim.scac) trackchg = true;
    return (
      <div>
        <Div14>General Information:</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <SelectInputSTU
              val={newaim.status}
              org={oldaim.status}
              label="status"
              load={load.status}
              title="Type"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
              opts={[
                "Requested",
                "Booked",
                "In Transit",
                "Completed",
                "Cancelled",
              ]}
            />
          </Col2>
          <Col2>
            <SelectInputSTU
              val={newaim.direction}
              org={oldaim.direction}
              label="direction"
              load={load.direction}
              title="Direction"
              size="sm"
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
              opts={["Inbound", "Outbound"]}
            />
          </Col2>
          <Col2>
            <SelectInputSTU
              val={newaim.commodity}
              org={oldaim.commodity}
              label="commodity"
              load={load.commodity}
              title="Commodity"
              size="sm"
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
              opts={commoditytypes}
            />
          </Col2>
          <Col2>
            <SelectInputSTU
              val={newaim.subtype}
              org={oldaim.subtype}
              label="subtype"
              load={load.subtype}
              title="Subtype"
              size="sm"
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
              opts={imtypes}
            />
          </Col2>
          <Col2>
            <div style={{ marginTop: "5px" }}>
              <InputGroup>
                {trackchg && (
                  <InputGroup.Button
                    loading={load.scac}
                    onClick={() => this.props.editVal(newaim.scac, "scac")}
                  >
                    <Icon icon="undo" />
                  </InputGroup.Button>
                )}

                <InputGroup.Addon>Shipping Line</InputGroup.Addon>

                <Select
                  disabled={oldaim.shippingmethod !== "Sea"}
                  showSearch
                  value={newaim.scac}
                  style={{ width: "100%" }}
                  placeholder={this.props.label}
                  optionFilterProp="children"
                  onChange={(e) => this.props.clientEditVal(e, "scac")}
                >
                  {options}
                </Select>
                {trackchg && (
                  <InputGroup.Button
                    color="green"
                    loading={load.scac}
                    onClick={() =>
                      this.props.postChanges("scac", "Shipping Line")
                    }
                    disabled={newaim.scac === ""}
                  >
                    <Icon icon="check" />
                  </InputGroup.Button>
                )}
              </InputGroup>
              {trackchg && (
                <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                  Changed from <i>{oldaim.scac}</i>
                </HelpBlock>
              )}
            </div>
          </Col2>
          <Col2>
            <TextInputSTU
              val={newaim.blnumber}
              org={oldaim.blnumber}
              label="blnumber"
              load={load.blnumber}
              title="BL Number"
              size="sm"
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <SelectInputSTU
              val={newaim.shippingmethod}
              org={oldaim.shippingmethod}
              label="shippingmethod"
              load={load.shippingmethod}
              title="Method"
              size="sm"
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
              opts={shippingmodetypes}
            />
          </Col2>
          <Col2>
            <TextAreaInputSTU
              val={newaim.containertype}
              org={oldaim.containertype}
              label="containertype"
              load={load.containertype}
              title="Container"
              size="sm"
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
        </FlexboxGrid>
        <Div14>Dimensions:</Div14>
        <FlexboxGrid justify="center">
          <Col2>
            <NumbInputSTU
              val={newaim.length}
              org={oldaim.length}
              label="length"
              load={load.length}
              title="Length (M)"
              size="sm"
              min={0}
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={newaim.width}
              org={oldaim.width}
              label="width"
              load={load.width}
              title="Width (M)"
              size="sm"
              min={0}
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={newaim.height}
              org={oldaim.height}
              label="height"
              load={load.height}
              title="Height (M)"
              size="sm"
              min={0}
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={newaim.weight}
              org={oldaim.weight}
              label="weight"
              load={load.weight}
              title="Ch. Weight (KG)"
              size="sm"
              min={0}
              editVal={this.props.clientEditVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
        </FlexboxGrid>
        <Div14>General Cost:</Div14>
        <FlexboxGrid justify="center">
          {costmap}
          <Col2>
            <NumbInputSTU
              val={totalcost}
              org={totalcost}
              label="weight"
              load={false}
              title="Total Cost"
              size="sm"
              min={0}
              editVal={() => console.log("")}
              updateVal={this.props.postChanges}
            />
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(JobDetails));
