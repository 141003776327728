import { observer } from "mobx-react";
import React, { Component } from "react";
import sideJobStore from "../../../../Stores/SideJobs/SideJobStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { Divider, Tooltip } from "antd";

class ViewButtons extends Component {
  render() {
    let type = this.props.type;

    return (
      <div>
        <br />
        <FlexboxGrid
          justify="end"
          align="middle"
          style={{ marginLeft: "5%", marginRight: "5%" }}
        >
          <Tooltip title="Card View">
            <IconButton
              icon={<Icon icon="th-large" />}
              circle
              appearance={this.props.view === "card" ? "primary" : "ghost"}
              color="green"
              onClick={() => this.props.changeView("card")}
            />
          </Tooltip>
          <Tooltip title="List View">
            <IconButton
              icon={<Icon icon="list" />}
              circle
              appearance={this.props.view === "list" ? "primary" : "ghost"}
              color="green"
              onClick={() => this.props.changeView("list")}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Refresh Data">
            <IconButton
              icon={<Icon icon="refresh" />}
              circle
              appearance={"primary"}
              color="yellow"
              onClick={() => sideJobStore.getPending(type)}
              loading={sideJobStore.loadsjs}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Load Converted">
            <IconButton
              icon={<Icon icon="check" />}
              circle
              appearance={sideJobStore.loadconv ? "primary" : "ghost"}
              color="green"
              onClick={() => sideJobStore.getOther(type, "Converted")}
              disabled={sideJobStore.loadconv}
              loading={sideJobStore.loadsjs}
            />
          </Tooltip>
          <Tooltip title="Load Cancellations">
            <IconButton
              icon={<Icon icon="close" />}
              circle
              appearance={sideJobStore.loadcanc ? "primary" : "ghost"}
              color="red"
              onClick={() => sideJobStore.getOther(type, "Cancelled")}
              disabled={sideJobStore.loadcanc}
              loading={sideJobStore.loadsjs}
            />
          </Tooltip>
        </FlexboxGrid>
        {sideJobStore.loadconv || sideJobStore.loadcanc ? (
          <>
            <br />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default observer(ViewButtons);
