let kuwaitzones = [
  { name: "Abdali", govt: "Jahra", arabic: "العبدلي", zone: 3 },
  {
    name: "Abdulla Al-Salem",
    govt: "Al Asima",
    arabic: "ضاحية عبد الله السالم",
    zone: 1,
  },
  {
    name: "Abdullah Al-Mubarak",
    govt: "Farwaniya",
    arabic: "عبدالله المبارك",
    zone: 5,
  },
  { name: "Abdullah Port", govt: "Ahmadi", arabic: "ميناء عبد الله", zone: 4 },
  {
    name: "Abu Al Hasaniya",
    govt: "Mubarak Al-Kabeer",
    arabic: "أبو الحصانية",
    zone: 5,
  },
  {
    name: "Abu Futaira",
    govt: "Mubarak Al-Kabeer",
    arabic: "أبو فطيرة",
    zone: 5,
  },
  { name: "Abu Halifa", govt: "Ahmadi", arabic: "أبو حليفة", zone: 4 },
  { name: "Adailiya", govt: "Al Asima", arabic: "العديلية", zone: 1 },
  { name: "Adan", govt: "Mubarak Al-Kabeer", arabic: "العدان", zone: 5 },
  { name: "Ahmadi", govt: "Ahmadi", arabic: "الأحمدي", zone: 4 },
  {
    name: "Airport District",
    govt: "Farwaniya",
    arabic: "منطقة المطار",
    zone: 5,
  },
  {
    name: "Al Nahda",
    govt: "Jahra",
    arabic: "النهضة / شرق الصليبخات",
    zone: 3,
  },
  { name: "Al Qurain", govt: "Mubarak Al-Kabeer", arabic: "القرين", zone: 5 },
  { name: "Al-Qusour", govt: "Mubarak Al-Kabeer", arabic: "القصور", zone: 5 },
  { name: "Al-Riggae", govt: "Farwaniya", arabic: "الرقعي", zone: 5 },
  { name: "Ali As-Salim", govt: "Ahmadi", arabic: "علي صباح السالم", zone: 4 },
  { name: "Amghara", govt: "Jahra", arabic: "أمغرة", zone: 3 },
  { name: "Andalous", govt: "Farwaniya", arabic: "الأندلس", zone: 5 },
  { name: "Anjafa", govt: "Hawalli", arabic: "أنجفة", zone: 2 },
  { name: "Aqila", govt: "Ahmadi", arabic: "العقيلة", zone: 4 },
  { name: "Ardiya", govt: "Farwaniya", arabic: "العارضية", zone: 5 },
  {
    name: "Ardiya Herafiya",
    govt: "Farwaniya",
    arabic: "العارضية حرفية",
    zone: 5,
  },
  {
    name: "Ardiya Industrial Area",
    govt: "Farwaniya",
    arabic: "العارضية المنطقة الصناعية",
    zone: 5,
  },
  { name: "Ashbelya", govt: "Farwaniya", arabic: "اشبيلية", zone: 5 },
  { name: "Bar Al Ahmadi", govt: "Ahmadi", arabic: "بر الأحمدي", zone: 4 },
  { name: "Bar Jahra", govt: "Jahra", arabic: "بر الجهراء", zone: 3 },
  { name: "Bayan", govt: "Hawalli", arabic: "بيان", zone: 2 },
  { name: "Bidda", govt: "Hawalli", arabic: "البدع", zone: 2 },
  { name: "Bnaid Al-Qar", govt: "Al Asima", arabic: "بنيد القار", zone: 1 },
  { name: "Bneidar", govt: "Ahmadi", arabic: "بنيدر", zone: 4 },
  { name: "Dasma", govt: "Al Asima", arabic: "الدسمة", zone: 1 },
  { name: "Daʿiya", govt: "Al Asima", arabic: "الدعية", zone: 1 },
  { name: "Dhaher", govt: "Ahmadi", arabic: "بر الأحمدي", zone: 4 },
  { name: "Dhajeej", govt: "Farwaniya", arabic: "الضجيج", zone: 5 },
  { name: "Doha", govt: "Al Asima", arabic: "الدوحة", zone: 1 },
  { name: "Doha Port", govt: "Al Asima", arabic: "ميناء الدوحة", zone: 1 },
  {
    name: "East Sulaibikhat",
    govt: "Jahra",
    arabic: "النهضة / شرق الصليبخات",
    zone: 3,
  },
  { name: "Fahad Al-Ahmad", govt: "Ahmadi", arabic: "فهد الأحمد", zone: 4 },
  { name: "Fahaheel", govt: "Ahmadi", arabic: "الفحيحيل", zone: 4 },
  { name: "Faiha", govt: "Al Asima", arabic: "الفيحاء", zone: 1 },
  { name: "Failaka", govt: "Al Asima", arabic: "فيلكا", zone: 1 },
  { name: "Farwaniya", govt: "Farwaniya", arabic: "الفروانية", zone: 5 },
  { name: "Fintās", govt: "Mubarak Al-Kabeer", arabic: "الفنطاس", zone: 5 },
  { name: "Fordous", govt: "Farwaniya", arabic: "الفردوس", zone: 5 },
  { name: "Funaitis", govt: "Mubarak Al-Kabeer", arabic: "الفنيطيس", zone: 5 },
  { name: "Granada (Kuwait)", govt: "Al Asima", arabic: "غرناطة", zone: 1 },
  { name: "Hadiya", govt: "Ahmadi", arabic: "هدية", zone: 4 },
  { name: "Hawally", govt: "Hawalli", arabic: "حولي", zone: 2 },
  { name: "Hittin", govt: "Hawalli", arabic: "حطين", zone: 2 },
  {
    name: "Jaber Al-Ahmad City",
    govt: "Al Asima",
    arabic: "مدينة جابر الأحمد",
    zone: 1,
  },
  { name: "Jaber Al-Ali", govt: "Ahmadi", arabic: "جابر العلي", zone: 4 },
  { name: "Jabriya", govt: "Hawalli", arabic: "الجابرية", zone: 2 },
  { name: "Jahra", govt: "Jahra", arabic: "الجهراء", zone: 3 },
  {
    name: "Jahra Industrial Area",
    govt: "Jahra",
    arabic: "الجهراء المنطقة الصناعية",
    zone: 3,
  },
  {
    name: "Jawaher Al Wafra",
    govt: "Ahmadi",
    arabic: "جواخير الوفرة",
    zone: 4,
  },
  { name: "Jibla", govt: "Al Asima", arabic: "جِبْلَة", zone: 1 },
  { name: "Jilea", govt: "Ahmadi", arabic: "الجليعة", zone: 4 },
  {
    name: "Jleeb Al-Shuyoukh",
    govt: "Farwaniya",
    arabic: "جليب الشيوخ",
    zone: 5,
  },
  { name: "Kabad", govt: "Jahra", arabic: "كبد", zone: 3 },
  { name: "Kaifan", govt: "Al Asima", arabic: "كيفان", zone: 1 },
  { name: "Khairan", govt: "Ahmadi", arabic: "الخيران", zone: 4 },
  { name: "Khaitan", govt: "Farwaniya", arabic: "خيطان", zone: 5 },
  { name: "Khaldiya", govt: "Al Asima", arabic: "الخالدية", zone: 1 },
  { name: "Mahbula", govt: "Ahmadi", arabic: "المهبولة", zone: 4 },
  { name: "Maidan Hawalli", govt: "Hawalli", arabic: "ميدان حولي", zone: 2 },
  { name: "Mangaf", govt: "Ahmadi", arabic: "المنقف", zone: 4 },
  { name: "Mansuriya", govt: "Al Asima", arabic: "المنصورية", zone: 1 },
  { name: "Miqwa", govt: "Ahmadi", arabic: "المقوع", zone: 4 },
  { name: "Mirgab", govt: "Al Asima", arabic: "المرقاب", zone: 1 },
  { name: "Mishrif", govt: "Hawalli", arabic: "مشرف", zone: 2 },
  { name: "Misīla", govt: "Mubarak Al-Kabeer", arabic: "المسيلة", zone: 5 },
  {
    name: "Mubarak Al-Jabir",
    govt: "Hawalli",
    arabic: "مبارك الجابر",
    zone: 2,
  },
  {
    name: "Mubarak Al-Kabeer",
    govt: "Mubarak Al-Kabeer",
    arabic: "مبارك الكبير",
    zone: 5,
  },
  { name: "Naeem", govt: "Jahra", arabic: "النعيم", zone: 3 },
  { name: "Nahdha", govt: "Al Asima", arabic: "النهضة", zone: 1 },
  { name: "Nasseem", govt: "Jahra", arabic: "النسيم", zone: 3 },
  {
    name: "New Khairan City",
    govt: "Ahmadi",
    arabic: "مدينة الخيران الجديدة",
    zone: 4,
  },
  { name: "New Wafra", govt: "Ahmadi", arabic: "الوفرة الجديدة", zone: 4 },
  { name: "Nigra", govt: "Hawalli", arabic: "النقرة", zone: 2 },
  {
    name: "North West Sulaibikhat",
    govt: "Al Asima",
    arabic: "شمال غرب الصليبيخات",
    zone: 1,
  },
  { name: "Nuwaiseeb", govt: "Ahmadi", arabic: "النويصيب", zone: 4 },
  { name: "Nuzha", govt: "Al Asima", arabic: "النزهة", zone: 1 },
  { name: "Omariya", govt: "Farwaniya", arabic: "العمرية", zone: 5 },
  { name: "Oyoun", govt: "Jahra", arabic: "العيون", zone: 3 },
  { name: "Qadsiya", govt: "Al Asima", arabic: "القادسية", zone: 1 },
  {
    name: "Qairawan",
    govt: "Jahra",
    arabic: "جنوب الدوحة / القيروان",
    zone: 3,
  },
  { name: "Qasr", govt: "Jahra", arabic: "القصر", zone: 3 },
  { name: "Qurtuba", govt: "Al Asima", arabic: "قرطبة", zone: 1 },
  { name: "Rabiya", govt: "Farwaniya", arabic: "الرابية", zone: 5 },
  { name: "Rai", govt: "Farwaniya", arabic: "الري", zone: 5 },
  { name: "Rawda", govt: "Al Asima", arabic: "الروضة", zone: 1 },
  { name: "Rihab", govt: "Farwaniya", arabic: "الرحاب", zone: 5 },
  { name: "Riqqa", govt: "Ahmadi", arabic: "الرقة", zone: 4 },
  { name: "Rumaithiya", govt: "Hawalli", arabic: "الرميثية", zone: 2 },
  {
    name: "Saad Al Abdullah City",
    govt: "Jahra",
    arabic: "مدينة سعد العبدالله",
    zone: 3,
  },
  {
    name: "Sabah Al Ahmad Sea City",
    govt: "Ahmadi",
    arabic: "مدينة صباح الأحمد البحرية",
    zone: 4,
  },
  {
    name: "Sabah Al-Ahmad City",
    govt: "Ahmadi",
    arabic: "مدينة صباح الأحمد",
    zone: 4,
  },
  {
    name: "Sabah Al-Nasser",
    govt: "Farwaniya",
    arabic: "صباح الناصر",
    zone: 5,
  },
  {
    name: "Sabah Al-Salem",
    govt: "Mubarak Al-Kabeer",
    arabic: "صباح السالم",
    zone: 5,
  },
  { name: "Sabahiya", govt: "Ahmadi", arabic: "الصباحية", zone: 4 },
  { name: "Sabaq Al Hajan", govt: "Farwaniya", arabic: "سباق الهجن", zone: 5 },
  { name: "Sabhan", govt: "Mubarak Al-Kabeer", arabic: "صبحان", zone: 5 },
  { name: "Salam", govt: "Hawalli", arabic: "سلام", zone: 2 },
  { name: "Salmi", govt: "Jahra", arabic: "السالمي", zone: 3 },
  { name: "Salmiya", govt: "Hawalli", arabic: "السالمية", zone: 2 },
  { name: "Salwa", govt: "Hawalli", arabic: "سلوى", zone: 2 },
  { name: "Shaab", govt: "Hawalli", arabic: "الشعب", zone: 2 },
  { name: "Shamiya", govt: "Al Asima", arabic: "الشامية", zone: 1 },
  { name: "Sharq", govt: "Al Asima", arabic: "شرق‎", zone: 1 },
  { name: "Shuwaiba", govt: "Ahmadi", arabic: "الشعيبة", zone: 4 },
  { name: "Shuhada", govt: "Hawalli", arabic: "الشهداء", zone: 2 },
  { name: "Shuwaikh", govt: "Al Asima", arabic: "الشويخ", zone: 1 },
  {
    name: "Shuwaikh Industrial Area",
    govt: "Al Asima",
    arabic: "الشويخ الصناعية",
    zone: 1,
  },
  { name: "Shuwaikh Port", govt: "Al Asima", arabic: "ميناء الشويخ", zone: 1 },
  { name: "Siddiq", govt: "Hawalli", arabic: "الصديق", zone: 2 },
  { name: "Sikrab", govt: "Jahra", arabic: "السكراب", zone: 3 },
  {
    name: "South Doha",
    govt: "Jahra",
    arabic: "جنوب الدوحة / القيروان",
    zone: 3,
  },
  { name: "South Sabahiya", govt: "Ahmadi", arabic: "جنوب الصباحية", zone: 4 },
  { name: "South Surra", govt: "Hawalli", arabic: "جنوب السرة", zone: 2 },
  {
    name: "South Wista",
    govt: "Mubarak Al-Kabeer",
    arabic: "جنوب وسطي",
    zone: 5,
  },
  { name: "Subiya", govt: "Jahra", arabic: "الصبية", zone: 3 },
  { name: "Sulaibikhat", govt: "Al Asima", arabic: "الصليبخات", zone: 1 },
  { name: "Sulaibiya", govt: "Jahra", arabic: "الصليبية", zone: 3 },
  {
    name: "Sulaibiya Agricultural Area",
    govt: "Jahra",
    arabic: "الصليبية المنطقة الزراعية",
    zone: 3,
  },
  { name: "Surra", govt: "Al Asima", arabic: "السرة", zone: 1 },
  { name: "Taima", govt: "Jahra", arabic: "تيماء", zone: 3 },
  {
    name: "Umm an Namil Island",
    govt: "Al Asima",
    arabic: "جزيرة ام النمل",
    zone: 1,
  },
  { name: "Wafra", govt: "Ahmadi", arabic: "الوفرة", zone: 4 },
  { name: "Waha", govt: "Jahra", arabic: "الواحة", zone: 3 },
  { name: "Wista", govt: "Mubarak Al-Kabeer", arabic: "وسطي", zone: 5 },
  { name: "Yarmouk", govt: "Al Asima", arabic: "اليرموك", zone: 1 },
  { name: "Zahra", govt: "Hawalli", arabic: "الزهراء", zone: 2 },
  { name: "Zoor", govt: "Ahmadi", arabic: "الزور", zone: 4 },
  { name: "Zuhar", govt: "Ahmadi", arabic: "الظهر", zone: 4 },
];
export default kuwaitzones;
