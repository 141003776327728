import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableCol100blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol50blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "50%",
  },
  tableCol100white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableCol50white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "50%",
  },
});
class LocationPDF extends Component {
  render() {
    let to = this.props.to;
    let title = this.props.title;

    return (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol100blue}>{title}:</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol50blue}>Address Name</Text>
          <Text style={styles.tableCol50white}>{to.name}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol50blue}>Contact Information</Text>
          <Text style={styles.tableCol50white}>
            phone: {to.phone} | email: {to.email}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol50blue}>Address Type</Text>
          <Text style={styles.tableCol50white}>
            {to.type}
            {to.floor !== 0 ? `| Floor ${to.floor}` : ""}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol50blue}>City</Text>
          <Text style={styles.tableCol50white}>{to.city}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol50blue}>Address</Text>
          <Text style={styles.tableCol50white}>{to.address}</Text>
        </View>
      </View>
    );
  }
}

export default observer(LocationPDF);
