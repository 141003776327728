import { observer } from "mobx-react";
import React, { Component } from "react";
import { CardActionArea } from "@material-ui/core";
import moment from "moment";
import { StatusColorCalc } from "../../../../Functions/Colors/StatusColorCalc";
import { IMPriceCalc } from "../../../../Functions/Pricing/IMPriceCalc";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import { LMPriceCalc } from "../../../../Functions/Pricing/LMPriceCalc";
import { CCPriceCalc } from "../../../../Functions/Pricing/CCPriceCalc";
import { STPriceCalc } from "../../../../Functions/Pricing/STPriceCalc";
class SingleCard extends Component {
  render() {
    let item = this.props.item;
    let focalpointname = "Not Assigned";

    if (item.job) {
      if (item.job.focalpoint) {
        if (item.job.focalpoint.username) {
          focalpointname = item.job.focalpoint.username;
        }
      }
    }

    let type = item.jobtype;
    let label = "sjNumId";

    let backColor = StatusColorCalc(item.status);

    let minJobDate = "No Dates Yet";
    if (item.jobdate.length === 1) {
      minJobDate = `(${moment(item.jobdate[0].date).format("DD-MMM")})`;
    } else if (item.jobdate.length > 1) {
      minJobDate = `(${moment(item.jobdate[0].date).format(
        "DD-MMM"
      )} to ${moment(item.jobdate[item.jobdate.length - 1].date).format(
        "DD-MMM"
      )})`;
    }

    let body;
    if (type === "LM") {
      let lmprice = LMPriceCalc(item);
      body = (
        <div>
          {item.subtype}
          <br />
          {item.cbm} CBM for {PRKD(lmprice[0])} KD
          <br />
          <i> {minJobDate && minJobDate}</i>
        </div>
      );
    } else if (type === "IM") {
      let imprice = IMPriceCalc(item);

      body = (
        <div>
          {item.im.direction} ({item.im.shippingmethod})
          <br />
          {item.cbm} CBM for {PRKD(imprice[0])} KD
          <br />
          <i> {minJobDate && minJobDate}</i>
        </div>
      );
    } else if (type === "CC") {
      let ccprice = CCPriceCalc(item);
      body = (
        <div>
          {item.subtype}
          <br />
          {item.cbm} CBM for {PRKD(ccprice[0])} KD
          <br />
          <i style={{ fontSize: 10 }}> BL# {item.blnumber}</i>
        </div>
      );
    } else if (type === "ST") {
      let stprice = STPriceCalc(item);
      body = (
        <div>
          {item.subtype} - {item.storagestatus}
          <br />
          <i style={{ fontSize: 10 }}>
            {PRKD(stprice[3])} KD/{stprice[5]} ({item.cbm} cbm)
          </i>
          <br />
          <i style={{ fontSize: 10 }}>{item.facility}</i>
          <br />
          <i style={{ fontSize: 10 }}>
            {moment(item.start).format("DD-MMM-YY")} to{" "}
            {moment(stprice[6]).format("DD-MMM-YY")}
          </i>
        </div>
      );
    }

    return (
      <div style={{ padding: "1%", width: "100%", maxWidth: 150 }}>
        <CardActionArea onClick={() => this.props.showModal(true, item)}>
          <div
            style={{
              borderRadius: 5,
              boxShadow: "1px 1px 5px black",
              textAlign: "left",
            }}
          >
            <div style={{ textAlign: "center", fontSize: 12 }}>
              <div
                style={{
                  backgroundColor: backColor,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  padding: "2%",
                }}
              >
                <strong>
                  {type}-{item[label]} (Job# {item.job.jobNumId})
                </strong>
                <br />
                {item.status}
                <br />
                {item.job.client.name}
              </div>
              {body}
              <div
                style={{
                  backgroundColor: backColor,
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  padding: "2%",
                }}
              >
                (
                {focalpointname.charAt(0).toUpperCase() +
                  focalpointname.slice(1)}
                )
                <br />
              </div>
            </div>
          </div>
        </CardActionArea>
      </div>
    );
  }
}

export default observer(SingleCard);
