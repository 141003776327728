import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import warehouseAccountStore from "../../../../../Stores/AccountUpdated/Storage/WarehouseAccountStore";
import ReactApexChart from "react-apexcharts";

class WarehouseAccountsMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let data = warehouseAccountStore.activeclientdata;
    let series = [
      {
        data: data,
      },
    ];
    let options = {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
      title: {
        text: "Warehouse CBM Distribution By Client",
        align: "center",
      },
      colors: [
        "#ff0026",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
        "#0a6316",
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: true,
          useFillColorAsStroke: true,
        },
      },
    };

    return (
      <div>
        <ReactApexChart
          options={options}
          series={series}
          type="treemap"
          height={350}
        />
      </div>
    );
  }
}

export default withRouter(observer(WarehouseAccountsMap));
