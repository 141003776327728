import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Loader } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Col3 from "../../../../../Components/Columns/Col3";
import Div14 from "../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";

import { message, Tabs } from "antd";
import ItemHistoryGraph from "../Graphs/ItemHistoryGraph";
import { withRouter } from "react-router";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";

import modalItemStore from "../../../../../Stores/ModalStores/ModalItemStore";
import AddLocationTags from "../AddLocationTags/AddLocationTags";
import TextInputSTU from "../../../../../Components/Inputs/Updatable/TextInputSTU";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";

const { TabPane } = Tabs;

const instance = axios.create({ baseURL: baseURL });

class ItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "1",
      showtrack: false,
      order: { acOrderNumId: "", _id: "" },
      load: {},
    };
    this.handleModeChange = this.handleModeChange.bind(this);
    this.onHideAll = this.onHideAll.bind(this);
    this.onHideTrack = this.onHideTrack.bind(this);
    this.onShowTrack = this.onShowTrack.bind(this);
    this.updateData = this.updateData.bind(this);
  }
  updateData = async (label) => {
    let item = modalItemStore.item;

    let data = { [label]: item[label] };
    this.setState({ load: { [label]: false } });
    await instance
      .put(`/ac/basicedititem/edititem/${item._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        modalItemStore.editOrg(data[label], label);

        this.setState({ load: { [label]: false } });
        message.success(`Product ID Updated`);
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };
  onShowTrack(acorder) {
    this.setState({ showtrack: true, order: acorder });
  }
  onHideTrack() {
    this.setState({ showtrack: false });
  }
  onHideAll() {
    this.setState({ tab: "1" });
    this.onHideTrack();
    this.props.onHide();
  }
  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };
  render() {
    let store = modalItemStore;
    let item = store.item;
    let org = store.original;
    let past = store.pastmoves;
    let upco = store.upcomoves;
    let data = past;
    if (this.state.tab !== "1") data = upco;

    let content = <Loader center />;
    let columns = [
      { title: "Order ID", field: "acOrderNumId" },
      {
        title: "Date",
        field: "jobdate",
        render: (row) => {
          if (row.jobdate === null) {
            return moment(row.acorder.jobdate).format("DD-MMM-YY");
          } else {
            return moment(row.jobdate).format("DD-MMM-YY");
          }
        },
      },
      { title: "Type.", field: "fulltype" },
      {
        title: "Qty",
        field: "actualquantity",
        render: (row) => {
          let color = "green";
          if (row.type === "Out") color = "red";
          return (
            <b style={{ color: color }}>
              {color === "green" ? "+" : "-"} {row.actualquantity}
            </b>
          );
        },
      },
      { title: "Warehouse", field: "warehouseshort" },
      {
        title: "AfterStock",
        field: "afterstock",
      },
    ];

    if (!store.loading.item) {
      content = (
        <div>
          <FlexboxGrid>
            <Col2>
              <b>Client: </b>

              <span> </span>
              <IconButton
                size="xs"
                color="green"
                appearance="ghost"
                icon={<Icon icon="web" />}
                onClick={() =>
                  this.props.history.push(`/ac/dashboard/${item.client._id}`)
                }
              >
                {item.clientshort}
              </IconButton>
            </Col2>
            <Col2>
              <b>Warehouse: </b>
              {item.warehouseshort}
            </Col2>
          </FlexboxGrid>
          <Div14>Item Details:</Div14>

          <FlexboxGrid>
            <Col3>
              <b>Item name: </b>
              {item.name}
              <br />

              <b>Serial Number: </b>
              {item.serialnumber}
              <br />
              <b>Product Number: </b>
              {item.productid}
            </Col3>
            <Col3>
              <b>Current Stock: </b>
              {item.currentplusreserve} ({item.onreserve} On Reserve)
              <br />
              <i>
                <b>Damaged Stock: </b>
                {item.damaged}
                <br />
                <b>Entering Stock: </b>
                {item.enteringstock}
              </i>
            </Col3>
            <Col3>
              <b>CBM: </b>
              {item.cbm} CBM/peice
              <br />
              <b>Dimensions: </b>
              {item.dimensions}
              <br />
              <b>Total CBM: </b>
              {item.totalcbm} CBM
            </Col3>
          </FlexboxGrid>
          <Div14>Tags:</Div14>
          <FlexboxGrid>
            <Col2>
              <AddLocationTags />
            </Col2>
            <Col2>
              <TextInputSTU
                val={item.productid}
                org={org.productid}
                label="productid"
                title="Product No.:"
                load={this.state.load.productid}
                size="sm"
                editVal={modalItemStore.editItem}
                updateVal={this.updateData}
              />
            </Col2>
          </FlexboxGrid>
          <Div14>Movement:</Div14>
          <Tabs
            size="large"
            activeKey={this.state.tab}
            defaultActiveKey={"home"}
            tabPosition={"top"}
            centered
            onChange={(e) => this.setState({ tab: e })}
          >
            <TabPane tab={`${past.length} Past Movement`} key="1" />
            <TabPane
              tab={`${upco.length} Upcoming Movement`}
              key="2"
              disabled={upco.length === 0}
            />
          </Tabs>
          <MaterialTable
            title={false}
            columns={columns}
            style={{ fontSize: 12 }}
            data={data}
            onRowClick={(event, rowData) => {
              this.onShowTrack(rowData.acorder);
            }}
            options={{
              actionsColumnIndex: 0,
              exportAllData: false,
              paging: false,
              toolbar: false,
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: false,
              padding: "dense",
              sorting: false,
            }}
          />
          <ItemHistoryGraph store={store} />
        </div>
      );
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showtrack}
          onHide={this.onHideTrack}
          title={`Order# ${this.state.order.acOrderNumId} - ${this.state.order.status}`}
          width="70%"
          footer={
            <ModalFooter
              icon={["close", "chevron-right"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={[
                "Cancel",
                `Yes, go to Order ${this.state.order.acOrderNumId}`,
              ]}
              oC1={() => this.onHideTrack()}
              oC2={() => {
                this.props.history.push(`/acorder/${this.state.order._id}`);
              }}
            />
          }
        >
          Go to Tracking Page for Order # {this.state.order.acOrderNumId}
          <hr />
          <h6>Type: {this.state.order.jobtype}</h6>
          <h6>
            Storage Date: {moment(this.state.order.jobdate).format("DD-MMM-YY")}
          </h6>
        </UnifiedModal>
        <UnifiedModal
          show={this.props.show}
          onHide={this.onHideAll}
          title={`ID# ${item.acItemNumId} - ${item.name} - Item Details`}
          footer={false}
        >
          {content}
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(ItemModal));
