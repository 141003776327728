import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import FlexboxButton from "../../../../Components/Modal/FlexboxButton";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import {
  FlexboxGrid,
  Col,
  Icon,
  IconButton,
  CheckboxGroup,
  Checkbox,
  HelpBlock,
  Notification,
} from "rsuite";
import axios from "axios";
import Div14 from "../../../../Components/Dividers/Div14";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import accountAdminStore from "../../../../Stores/AccountUpdated/Admin/AccountAdminStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
class InviteNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: "",
      compadmin: false,
      comprequest: true,
      loading: false,
    };
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
  }
  onHide() {
    this.setState({
      show: false,
      email: "",
      compadmin: false,
      comprequest: true,
    });
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  updateVal = async () => {
    this.setState({ loading: true });
    let data = {
      compadmin: this.state.compadmin,
      comprequest: this.state.comprequest,
      email: this.state.email,
    };

    await instance
      .put(
        `auth/referral/createnewreferral/${accountAdminStore.account._id}`,
        data,
        {
          headers: { authtoken: userStore.token },
        }
      )
      .then((res) => {
        message.success(`User Email Sent.`);
        this.setState({ loading: false });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let emailsuccess = validateEmail(this.state.email);
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          title="Invite New User"
          onHide={this.onHide}
          footer={
            <FlexboxGrid>
              <Col md={12} sm={12} xs={24}>
                <IconButton
                  block
                  icon={<Icon icon="close" />}
                  onClick={() => {
                    this.onHide(false);
                  }}
                >
                  Cancel
                </IconButton>
              </Col>
              <Col md={12} sm={12} xs={24}>
                <IconButton
                  block
                  color="green"
                  icon={<Icon icon="envelope" />}
                  disabled={!emailsuccess}
                  onClick={() => {
                    this.updateVal();
                  }}
                >
                  Send Email Invite
                </IconButton>
              </Col>
            </FlexboxGrid>
          }
        >
          <CheckboxGroup>
            <FlexboxGrid>
              <Col md={24} sm={24} xs={24}>
                <TextInputSTD
                  title="User Email"
                  val={this.state.email}
                  label="email"
                  size="large"
                  editVal={this.editVal}
                />
                {!emailsuccess && (
                  <HelpBlock
                    style={{
                      color: "red",
                      fontStyle: "italic",
                    }}
                  >
                    Please enter a valid email.
                  </HelpBlock>
                )}
              </Col>
              <Col md={24} sm={24} xs={24}>
                <Div14>User Access</Div14>
                <Checkbox
                  checked={this.state.compadmin}
                  onClick={() =>
                    this.setState({ compadmin: !this.state.compadmin })
                  }
                >
                  Company Admin (Can Invite Users)
                </Checkbox>
                <Checkbox
                  checked={this.state.comprequest}
                  onClick={() =>
                    this.setState({ comprequest: !this.state.comprequest })
                  }
                >
                  Company Requester (Can Request Jobs)
                </Checkbox>
              </Col>
            </FlexboxGrid>
          </CheckboxGroup>
        </UnifiedModal>
        <FlexboxButton
          icon="plus"
          text="Invite New User"
          color="green"
          app="primary"
          loading={this.state.show}
          oC={() => {
            this.setState({ show: true });
          }}
        />
      </div>
    );
  }
}

export default withRouter(observer(InviteNewUser));
