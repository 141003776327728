import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";

const instance = axios.create({ baseURL: baseURL });

class IMSupplierStore {
  constructor() {
    this.suppliers = [];
    this.editsuppliers = [];
    this.loading = false;
    this.dataloaded = false;
    this.selectedType = "Freight Forwarder";

    makeObservable(this, {
      suppliers: observable,
      editsuppliers: observable,
      loading: observable,
      dataloaded: observable,
      selectedType: observable,
      filtlist: computed,
    });
  }

  // FETCH
  getSuppliers(override) {
    let loaded = false;
    if (this.loading || this.dataloaded) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading = true;
      return instance
        .get(`/imadditional/supplier/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((suppliers) => {
          this.suppliers = suppliers;
          this.editsuppliers = [...suppliers];
          this.loading = false;
          this.dataloaded = true;
          console.log("IM Suppliers Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get filtlist() {
    return this.suppliers.filter(
      (supplier) => supplier.type === this.selectedType
    );
  }

  // FUNCTIONS:

  changeSupplierType(type) {
    this.selectedType = type;
  }

  // ----- DATE ----
  editSupplier(type, val, i) {
    this.editsuppliers[i][type] = val;
  }

  editMainDateLms(type, val, i) {
    this.suppliers[i][type] = val;
  }

  addNewSupplier(suppliers) {
    let newSupplier = [...this.suppliers];
    newSupplier.push(suppliers);
    this.suppliers = [...newSupplier];
    this.editsuppliers = [...newSupplier];
  }

  startLoading() {
    this.loading = true;
  }
  endLoading() {
    this.loading = false;
  }
}

const imSupplierStore = new IMSupplierStore();
export default imSupplierStore;
