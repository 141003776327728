import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Icon, IconButton } from "rsuite";

class AddSingleItemRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let toadd = this.props.toadds;
    let dritems = this.props.dritems;
    let singleitem = {};
    let acitemmove = [];
    let add = true;

    for (let i = 0; i < toadd.length; i++) {
      for (let k = 0; k < toadd[i].acitemmove.length; k++) {
        add = true;
        for (let j = 0; j < dritems.length; j++) {
          if (
            dritems[j].acitem.acItemNumId === toadd[i].acitemmove[k].acItemNumId
          ) {
            add = false;
          }
        }
        if (add) {
          singleitem = { ...toadd[i].acitemmove[k] };
          singleitem.loc = ` ${i + 1}: ${toadd[i].city}`;
          acitemmove = [...acitemmove, { ...singleitem }];
        }
      }
    }

    return (
      <div>
        {acitemmove.map((it, index) => (
          <>
            <IconButton
              size="xs"
              icon={<Icon icon="circle" />}
              onClick={() => this.props.selectItem(it)}
            >
              {it.acItemNumId}: {it.itemname} (SN: {it.itemserialnumber}) x{" "}
              {it.actualquantity}
            </IconButton>
            <br />
          </>
        ))}
      </div>
    );
  }
}

export default withRouter(observer(AddSingleItemRow));
