import { observer } from "mobx-react";
import React, { Component } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import WhitePaymentRows from "./WhitePaymentRows";

const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 3,
  },
  tableCol20white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "20%",
  },
  tableCol80white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "80%",
  },
  tableCol80blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "80%",
  },
  tableCol20blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 3,
    width: "20%",
  },
  subheader: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
    textAlign: "left",
    color: "black",
    fontWeight: "extrabold",
  },
});

class SJBreakdownTable extends Component {
  render() {
    let sj = this.props.sj;

    let ammount = 0;
    let total = 0;

    for (let j = 0; j < sj.proposals.length; j++) {
      ammount += Parsed(sj.proposals[j].ammount);
    }
    let summary = {
      title: `${sj.jobtype}-${sj.numId} Job`,
      ammount: PRKD(ammount),
      proposals: sj.proposals,
    };
    total += ammount;

    let breakdownrows = (
      <WhitePaymentRows
        proposals={summary.proposals}
        title={`${summary.title} Charges`}
      />
    );

    return (
      <View>
        <Text style={{ lineHeight: 1 }}> </Text>
        <Text style={styles.tableHeaderStyle}>Price Breakdown:</Text>
        {breakdownrows}
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol80blue}>Total Charges</Text>
          <Text style={styles.tableCol20blue}>{PRKD(total)} KD</Text>
        </View>
      </View>
    );
  }
}

export default observer(SJBreakdownTable);
