let seatypes = [
  "LCL",
  "20 Foot",
  "40 Foot",
  "40 Foot-HC",
  "45 Foot",
  "Open Top",
  "Reefer",
  "Flat Rack",
  "Other",
];
export default seatypes;
