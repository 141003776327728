import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Badge, message, Tag } from "antd";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import { Col, Divider, FlexboxGrid, HelpBlock, Notification } from "rsuite";
import axios from "axios";
import NumbInputSTU from "../../../../../../Components/Inputs/Updatable/NumbInputSTU";
import TextInputSTU from "../../../../../../Components/Inputs/Updatable/TextInputSTU";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import SelectInputSTU from "../../../../../../Components/Inputs/Updatable/SelectInputSTU";
import locationtypelist from "../../../../../../Static/Lists/LocationType";
import zoneStore from "../../../../../../Stores/Admin/Lists/ZoneStore";
import Col2 from "../../../../../../Components/Columns/Col2";
import TextAreaInputSTU from "../../../../../../Components/Inputs/Updatable/TextAreaInputSTU";

const instance = axios.create({ baseURL: baseURL });

class FromLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: {},
    };
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
  }
  editVal(val, label, type) {
    if (!newDeliveryStore.stopedit) {
      newDeliveryStore.editLoc(val, label, type);
    }
  }
  updateVal = async (label) => {
    let oldloc = { ...newDeliveryStore.olddel.fromaddress };
    let delid = newDeliveryStore.olddel._id;
    let data = { fromaddress: oldloc };
    data.fromaddress[label] = newDeliveryStore.newdel.fromaddress[label];
    if (label === "floor") {
      data.fromaddress.ngfchg = newDeliveryStore.newdel.fromaddress.ngfchg;
    }
    if (label === "city") {
      data.fromaddress.zonecost = newDeliveryStore.newdel.fromaddress.zonecost;
      data.fromaddress.zonetype = newDeliveryStore.newdel.fromaddress.zonetype;
    }

    this.setState({ load: { [label]: true } });
    return instance
      .put(`/ac/delivery/edit/${delid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.updateOrgLoc(data.fromaddress, label);
        message.success(`Successfully Updated Pickup Address (${label})`);
        this.setState({ load: { [label]: false } });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ load: { [label]: false } });
      });
  };

  render() {
    let loc = newDeliveryStore.newdel.fromaddress;
    let oldloc = newDeliveryStore.olddel.fromaddress;
    let load = this.state.load;
    let tagcolor = "orange";
    if (loc.status === "Completed") tagcolor = "green";
    return (
      <div>
        <div
          style={{
            borderRadius: "5px",
            margin: "1%",
            padding: "1%",
            boxShadow: "1px 1px 8px #a3a3a3",
          }}
        >
          <Div14>
            <b>
              <Tag color={tagcolor} key={1}>
                <Badge
                  status={loc.status === "Completed" ? "default" : "processing"}
                  style={{ color: tagcolor }}
                  color={tagcolor}
                  text={loc.status}
                />
              </Tag>
              <Divider vertical />
              Pickup Location:{" "}
            </b>
          </Div14>
          <TextInputSTU
            val={loc.name}
            org={oldloc.name}
            label="name"
            title="Name:"
            size="sm"
            load={load.name}
            editVal={this.editVal}
            updateVal={this.updateVal}
          />
          <FlexboxGrid>
            <Col2>
              <TextInputSTU
                val={loc.phone}
                org={oldloc.phone}
                label="phone"
                load={load.phone}
                title="Phone:"
                size="sm"
                editVal={this.editVal}
                updateVal={this.updateVal}
              />
            </Col2>
            <Col2>
              <SelectInputSTU
                val={loc.type}
                org={oldloc.type}
                label="type"
                load={load.type}
                title="Type:"
                size="sm"
                editVal={this.editVal}
                updateVal={this.updateVal}
                opts={locationtypelist}
              />
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            align="middle"
            style={{ textAlign: "center" }}
          >
            <Col md={16} sm={14} xs={24}>
              <SelectInputSTU
                val={loc.city}
                org={oldloc.city}
                label="city"
                load={load.city}
                title="City:"
                size="sm"
                editVal={this.editVal}
                updateVal={this.updateVal}
                opts={zoneStore.zones.map((zone) => zone.name)}
              />
            </Col>
            <Col md={8} sm={10} xs={12}>
              <HelpBlock
                style={{
                  textAlign: "center",
                  color: loc.zonecost > 0 ? "red" : "green",
                }}
              >
                {loc.zonecost > 0 &&
                  `${loc.city} in Zone ${loc.zonetype} (+ ${PRKD(
                    loc.zonecost
                  )} KD Zone Delivery Charge)`}
              </HelpBlock>
            </Col>
          </FlexboxGrid>
          <FlexboxGrid
            justify="center"
            align="middle"
            style={{ textAlign: "center" }}
          >
            <Col md={16} sm={14} xs={24}>
              <NumbInputSTU
                val={loc.floor}
                org={oldloc.floor}
                label="floor"
                load={load.floor}
                title="Floor:"
                size="sm"
                min={-100}
                editVal={this.editVal}
                updateVal={this.updateVal}
              />
            </Col>
            <Col md={8} sm={10} xs={12}>
              <HelpBlock
                style={{
                  textAlign: "center",
                  color: loc.ngfchg > 0 ? "red" : "green",
                }}
              >
                {loc.ngfchg > 0 &&
                  `Non Ground Floor Charge (+ ${PRKD(loc.ngfchg)} KD)`}
              </HelpBlock>
            </Col>
          </FlexboxGrid>
          <TextAreaInputSTU
            val={loc.address}
            org={oldloc.address}
            label="address"
            load={load.address}
            title="Address:"
            size="sm"
            editVal={this.editVal}
            updateVal={this.updateVal}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(observer(FromLocation));
