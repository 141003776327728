import { observer } from "mobx-react";
import React, { Component } from "react";

import { PDFViewer } from "@react-pdf/renderer";

import { Document } from "@react-pdf/renderer";
import moment from "moment";

import HeaderFooterPDF from "../Template/HeaderFooterPDF";
import ChargesTableSummary from "./Tables/ChargesTableSummary";
import PaymentNotes from "../Invoices/StandardOnAll/PaymentPages/PaymentNotes";

import InvoiceNotes from "./Tables/InvoiceNotes";
import HeaderSection from "./StandardOnAll/HeaderSection";
import TermsAndConditions from "./StandardOnAll/TermsAndConditions";

class IntroPage extends Component {
  render() {
    let inv = this.props.inv;

    let invoiceID = "New";
    let clientname = inv.clientname;

    return (
      <div>
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`ACC Invoice ACC${invoiceID}-
                ${moment().format("YYMMDDHH")} for ${clientname}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Custom Clearance Account Invoice ACC${invoiceID}-
                ${moment().format("YYMMDDHH")} for ${clientname}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection inv={inv} />
              <ChargesTableSummary inv={inv} />
            </HeaderFooterPDF>
            {inv.invnotes.length > 12 && (
              <HeaderFooterPDF>
                <InvoiceNotes inv={inv} />
              </HeaderFooterPDF>
            )}
            <HeaderFooterPDF>
              <PaymentNotes inv={inv} />
            </HeaderFooterPDF>
            <HeaderFooterPDF>
              <TermsAndConditions />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(IntroPage);
