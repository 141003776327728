var packinglist = [
  {
    id: 1,
    name: "1 seater sofa",
    height: 100,
    width: 70,
    length: 107,
    catI: 2,
    catII: 10,
  },
  {
    id: 2,
    name: "2 Dr Full Wardrobe",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 22,
  },
  {
    id: 3,
    name: "2 seater sofa",
    height: 100,
    width: 70,
    length: 186,
    catI: 2,
    catII: 10,
  },
  {
    id: 4,
    name: "3 Dr Full Wardrobe",
    height: 100,
    width: 100,
    length: 70,
    catI: 3,
    catII: 22,
  },
  {
    id: 5,
    name: "3 seater bench ",
    height: 40,
    width: 50,
    length: 150,
    catI: 2,
    catII: 13,
  },
  {
    id: 6,
    name: "3 seater sofa",
    height: 100,
    width: 70,
    length: 257,
    catI: 2,
    catII: 10,
  },
  {
    id: 7,
    name: "4 Dr Full Wardrobe",
    height: 100,
    width: 100,
    length: 130,
    catI: 3,
    catII: 22,
  },
  {
    id: 8,
    name: "4 seater bench ",
    height: 40,
    width: 50,
    length: 250,
    catI: 2,
    catII: 13,
  },
  {
    id: 9,
    name: "6 Dr Full Wardrobe",
    height: 100,
    width: 100,
    length: 170,
    catI: 3,
    catII: 22,
  },
  {
    id: 10,
    name: "A/C Split type",
    height: 100,
    width: 100,
    length: 50,
    catI: 4,
    catII: 26,
  },
  {
    id: 11,
    name: "A/C Window type",
    height: 100,
    width: 100,
    length: 45,
    catI: 4,
    catII: 26,
  },
  {
    id: 12,
    name: "Antenna",
    height: 100,
    width: 100,
    length: 14,
    catI: 4,
    catII: 28,
  },
  {
    id: 13,
    name: "Arm chair ",
    height: 100,
    width: 70,
    length: 81,
    catI: 2,
    catII: 11,
  },
  {
    id: 14,
    name: "Baby cot",
    height: 100,
    width: 70,
    length: 50,
    catI: 2,
    catII: 16,
  },
  {
    id: 15,
    name: "Bar",
    height: 100,
    width: 100,
    length: 99,
    catI: 6,
    catII: 12,
  },
  {
    id: 16,
    name: "Bar stools",
    height: 100,
    width: 40,
    length: 48,
    catI: 2,
    catII: 14,
  },
  {
    id: 17,
    name: "BBQ grill ",
    height: 100,
    width: 100,
    length: 65,
    catI: 4,
    catII: 24,
  },
  {
    id: 18,
    name: "Bicycle   small",
    height: 100,
    width: 100,
    length: 25,
    catI: 6,
    catII: 7,
  },
  {
    id: 19,
    name: "Bicycle  Large",
    height: 100,
    width: 100,
    length: 50,
    catI: 6,
    catII: 7,
  },
  {
    id: 20,
    name: "Bunk bed Full",
    height: 40,
    width: 120,
    length: 313,
    catI: 2,
    catII: 15,
  },
  {
    id: 21,
    name: "Carpet",
    height: 100,
    width: 100,
    length: 28,
    catI: 6,
    catII: 6,
  },
  {
    id: 22,
    name: "Chairs",
    height: 100,
    width: 45,
    length: 44,
    catI: 2,
    catII: 11,
  },
  {
    id: 23,
    name: "Chest of drawers ",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 19,
  },
  {
    id: 24,
    name: "Cloth Drying Rack ",
    height: 100,
    width: 100,
    length: 10,
    catI: 4,
    catII: 25,
  },
  {
    id: 25,
    name: "Coffee table",
    height: 60,
    width: 80,
    length: 83,
    catI: 3,
    catII: 17,
  },
  {
    id: 26,
    name: "Cooker",
    height: 100,
    width: 100,
    length: 60,
    catI: 4,
    catII: 24,
  },
  {
    id: 27,
    name: "Desk ",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 17,
  },
  {
    id: 28,
    name: "Desks/Tables",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 17,
  },
  {
    id: 29,
    name: "Dining chairs",
    height: 100,
    width: 45,
    length: 44,
    catI: 2,
    catII: 11,
  },
  {
    id: 30,
    name: "Dishwasher",
    height: 100,
    width: 100,
    length: 35,
    catI: 4,
    catII: 25,
  },
  {
    id: 31,
    name: "Dog kennel",
    height: 100,
    width: 100,
    length: 25,
    catI: 6,
    catII: 9,
  },
  {
    id: 32,
    name: "Dresser",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 21,
  },
  {
    id: 33,
    name: "Dryer",
    height: 100,
    width: 100,
    length: 40,
    catI: 4,
    catII: 25,
  },
  {
    id: 34,
    name: "Exercise machine",
    height: 100,
    width: 100,
    length: 100,
    catI: 4,
    catII: 27,
  },
  {
    id: 35,
    name: "Fish tank",
    height: 100,
    width: 100,
    length: 22,
    catI: 6,
    catII: 9,
  },
  {
    id: 36,
    name: "Foot rest",
    height: 50,
    width: 60,
    length: 67,
    catI: 2,
    catII: 12,
  },
  {
    id: 37,
    name: "Freezer  ( large) ",
    height: 100,
    width: 100,
    length: 70,
    catI: 4,
    catII: 24,
  },
  {
    id: 38,
    name: "Freezer ( small )",
    height: 100,
    width: 100,
    length: 40,
    catI: 4,
    catII: 24,
  },
  {
    id: 39,
    name: "Home Setter (Large)",
    height: 100,
    width: 100,
    length: 75,
    catI: 6,
    catII: 12,
  },
  {
    id: 40,
    name: "Home Setter (Small)",
    height: 100,
    width: 100,
    length: 30,
    catI: 6,
    catII: 12,
  },
  {
    id: 41,
    name: "Ironing board ",
    height: 100,
    width: 100,
    length: 17,
    catI: 4,
    catII: 25,
  },
  {
    id: 42,
    name: "King Bed (190 cm)",
    height: 60,
    width: 190,
    length: 206,
    catI: 2,
    catII: 15,
  },
  {
    id: 43,
    name: "Kitchen chairs ",
    height: 60,
    width: 50,
    length: 50,
    catI: 2,
    catII: 11,
  },
  {
    id: 44,
    name: "Kitchen table",
    height: 100,
    width: 100,
    length: 30,
    catI: 3,
    catII: 17,
  },
  {
    id: 45,
    name: "Large Book Shelf",
    height: 100,
    width: 100,
    length: 100,
    catI: 3,
    catII: 23,
  },
  {
    id: 46,
    name: "Large box ",
    height: 100,
    width: 100,
    length: 23,
    catI: 1,
    catII: 2,
  },
  {
    id: 47,
    name: "Large Dining Table",
    height: 100,
    width: 100,
    length: 100,
    catI: 3,
    catII: 17,
  },
  {
    id: 48,
    name: "Large Fridge (750L)",
    height: 100,
    width: 100,
    length: 115,
    catI: 4,
    catII: 24,
  },
  {
    id: 49,
    name: "Large TV (48-65'')",
    height: 100,
    width: 100,
    length: 50,
    catI: 5,
    catII: 29,
  },
  {
    id: 50,
    name: "Linen basket",
    height: 100,
    width: 100,
    length: 8,
    catI: 4,
    catII: 25,
  },
  {
    id: 51,
    name: "Mattress (120 cm)",
    height: 40,
    width: 120,
    length: 115,
    catI: 2,
    catII: 15,
  },
  {
    id: 52,
    name: "Mattress (160 cm)",
    height: 40,
    width: 160,
    length: 117,
    catI: 2,
    catII: 15,
  },
  {
    id: 53,
    name: "Mattress (190 cm)",
    height: 40,
    width: 190,
    length: 132,
    catI: 2,
    catII: 15,
  },
  {
    id: 54,
    name: "Medium box",
    height: 100,
    width: 100,
    length: 16,
    catI: 1,
    catII: 2,
  },
  {
    id: 55,
    name: "Microwave",
    height: 100,
    width: 100,
    length: 20,
    catI: 4,
    catII: 24,
  },
  {
    id: 56,
    name: "Mirror",
    height: 100,
    width: 100,
    length: 40,
    catI: 5,
    catII: 30,
  },
  {
    id: 57,
    name: "Paintings ",
    height: 100,
    width: 100,
    length: 19,
    catI: 5,
    catII: 31,
  },
  {
    id: 58,
    name: "Piano ( l )",
    height: 100,
    width: 100,
    length: 200,
    catI: 6,
    catII: 4,
  },
  {
    id: 59,
    name: "Piano ( s ) ",
    height: 100,
    width: 100,
    length: 150,
    catI: 6,
    catII: 4,
  },
  {
    id: 60,
    name: "Plants (large) ",
    height: 100,
    width: 100,
    length: 25,
    catI: 6,
    catII: 5,
  },
  {
    id: 61,
    name: "Plants (medium)",
    height: 100,
    width: 100,
    length: 12,
    catI: 6,
    catII: 5,
  },
  {
    id: 62,
    name: "Plants (small) ",
    height: 100,
    width: 100,
    length: 10,
    catI: 6,
    catII: 5,
  },
  {
    id: 63,
    name: "Queen Bed (160 cm)",
    height: 40,
    width: 160,
    length: 305,
    catI: 2,
    catII: 15,
  },
  {
    id: 64,
    name: "Shoe rack ",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 20,
  },
  {
    id: 65,
    name: "Side Board Large",
    height: 100,
    width: 100,
    length: 100,
    catI: 6,
    catII: 12,
  },
  {
    id: 66,
    name: "Side Board Small",
    height: 100,
    width: 100,
    length: 50,
    catI: 6,
    catII: 12,
  },
  {
    id: 67,
    name: "Side server",
    height: 100,
    width: 100,
    length: 28,
    catI: 6,
    catII: 12,
  },
  {
    id: 68,
    name: "Side table",
    height: 100,
    width: 100,
    length: 14,
    catI: 3,
    catII: 17,
  },
  {
    id: 69,
    name: "Side tables",
    height: 100,
    width: 100,
    length: 20,
    catI: 3,
    catII: 17,
  },
  {
    id: 70,
    name: "Single Bed (120 cm)",
    height: 40,
    width: 120,
    length: 260,
    catI: 2,
    catII: 15,
  },
  {
    id: 71,
    name: "Small Book Shelf",
    height: 100,
    width: 100,
    length: 57,
    catI: 3,
    catII: 23,
  },
  {
    id: 72,
    name: "Small box ",
    height: 100,
    width: 100,
    length: 10,
    catI: 1,
    catII: 2,
  },
  {
    id: 73,
    name: "Small Dining Table",
    height: 100,
    width: 100,
    length: 50,
    catI: 3,
    catII: 17,
  },
  {
    id: 74,
    name: "Small Fridge (500L)",
    height: 100,
    width: 100,
    length: 70,
    catI: 4,
    catII: 24,
  },
  {
    id: 75,
    name: "Small TV (32-46'')",
    height: 100,
    width: 100,
    length: 30,
    catI: 5,
    catII: 29,
  },
  {
    id: 76,
    name: "Sofa cum bed ",
    height: 100,
    width: 70,
    length: 150,
    catI: 2,
    catII: 10,
  },
  {
    id: 77,
    name: "Speakers",
    height: 100,
    width: 100,
    length: 14,
    catI: 4,
    catII: 28,
  },
  {
    id: 78,
    name: "Steel ladder",
    height: 100,
    width: 100,
    length: 10,
    catI: 6,
    catII: 3,
  },
  {
    id: 79,
    name: "Stereo ",
    height: 100,
    width: 100,
    length: 14,
    catI: 4,
    catII: 28,
  },
  {
    id: 80,
    name: "Swing set ",
    height: 100,
    width: 100,
    length: 150,
    catI: 6,
    catII: 8,
  },
  {
    id: 81,
    name: "Table fan ",
    height: 100,
    width: 100,
    length: 10,
    catI: 4,
    catII: 26,
  },
  {
    id: 82,
    name: "Trampoline",
    height: 100,
    width: 100,
    length: 65,
    catI: 6,
    catII: 8,
  },
  {
    id: 83,
    name: "TV cabinet Large",
    height: 100,
    width: 100,
    length: 100,
    catI: 3,
    catII: 19,
  },
  {
    id: 84,
    name: "TV stand / Table",
    height: 100,
    width: 100,
    length: 35,
    catI: 3,
    catII: 18,
  },
  {
    id: 85,
    name: "Vacuum cleaner",
    height: 100,
    width: 100,
    length: 10,
    catI: 4,
    catII: 25,
  },
  {
    id: 86,
    name: "Wall unit/ Showcase",
    height: 100,
    width: 100,
    length: 50,
    catI: 6,
    catII: 12,
  },
  {
    id: 87,
    name: "Wall Unit/Show Case",
    height: 100,
    width: 100,
    length: 130,
    catI: 6,
    catII: 12,
  },
  {
    id: 88,
    name: "Washing machine",
    height: 100,
    width: 100,
    length: 40,
    catI: 4,
    catII: 25,
  },
  {
    id: 89,
    name: "Water dispenser ",
    height: 100,
    width: 100,
    length: 20,
    catI: 4,
    catII: 24,
  },
];

export default packinglist;
