import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../Static/baseURL/baseURL";
import userStore from "../UserStore";
import moment from "moment";
import { Parsed } from "../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class JobStatsMainStore {
  constructor() {
    this.alljobs = [];

    this.startdate = moment().subtract(3, "months").startOf("month");
    this.enddate = moment().endOf("month");
    this.newstart = moment().subtract(3, "months").startOf("month");
    this.newend = moment().endOf("month");
    this.filter = { LM: true, IM: false, CC: false, ST: false };
    this.loading = {
      alljobs: false,
      clients: false,
    };
    this.loaded = {
      alljobs: false,
      clients: false,
    };

    makeObservable(this, {
      alljobs: observable,

      startdate: observable,
      enddate: observable,
      newstart: observable,
      newend: observable,
      filter: observable,
      loading: observable,
      loaded: observable,
      checkLoading: computed,
      joblist: computed,
      jobgraphs: computed,
    });
  }

  // FETCH
  getCalendarJobs(override) {
    let loaded;
    if (this.loading.alljobs || this.loaded.alljobs) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.alljobs = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/job/sidejobs/sjs/getcalendarstartend`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let dates = [...data];

          this.alljobs = dates;

          this.loading.alljobs = false;
          this.loaded.alljobs = true;
          this.newstart = this.startdate;
          this.newend = this.enddate;
          console.log("Calendar Jobs Fetched");
        })
        .catch((err) => {
          this.loading.alljobs = false;

          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get checkLoading() {
    if (this.loading.clients) return true;
    if (this.loading.local) return true;

    return false;
  }

  get joblist() {
    let jobs = this.alljobs;
    if (!this.filter.LM) jobs = jobs.filter((job) => job.jobtype !== "LM");
    if (!this.filter.IM) jobs = jobs.filter((job) => job.jobtype !== "IM");
    if (!this.filter.ST) jobs = jobs.filter((job) => job.jobtype !== "ST");
    if (!this.filter.CC) jobs = jobs.filter((job) => job.jobtype !== "CC");

    return jobs;
  }
  get jobgraphs() {
    let xaxis = [];
    let combinedprice = [];
    let totalprice = 0;
    let combineddays = [];
    let totaldays = [];
    let jobs = [];

    let diff = moment(this.newend).diff(moment(this.newstart), "month") + 1;

    for (let i = 0; i < diff; i++) {
      xaxis = [
        ...xaxis,
        moment(this.newstart).add(i, "month").format("MMM-YY"),
      ];

      totalprice = 0;
      totaldays = 0;
      jobs = this.joblist.filter(
        (job) =>
          moment(job.start).format("MMM-YY") ===
          moment(this.newstart).add(i, "month").format("MMM-YY")
      );
      for (let j = 0; j < jobs.length; j++) {
        totalprice +=
          Parsed(jobs[j].statperday.price) *
          (moment(jobs[j].end).diff(moment(jobs[j].start), "days") + 1);
        totaldays +=
          moment(jobs[j].end).diff(moment(jobs[j].start), "days") + 1;
      }
      combinedprice = [...combinedprice, totalprice];
      combineddays = [...combineddays, totaldays];
    }
    return { x: xaxis, price: combinedprice, days: combineddays };
  }
  // FUNCTIONS:
  changeDate(val) {
    jobStatsMainStore.startdate = val[0];
    jobStatsMainStore.enddate = moment(val[1]).endOf("month");
  }

  changeFilter(type) {
    this.filter[type] = !this.filter[type];
  }
  updateMonths() {
    jobStatsMainStore.orgmonths = jobStatsMainStore.months;
    jobStatsMainStore.loaded = {
      local: false,
      clients: false,
    };
  }
}

const jobStatsMainStore = new JobStatsMainStore();
export default jobStatsMainStore;
