import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@material-ui/core";

import SingleLink from "../SingleLink";
import { FlexboxGrid, Avatar } from "rsuite";
import userStore from "../../../../Stores/UserStore";

class AdvStaffJobs extends Component {
  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={
            <Tooltip title="Staff Stats" arrow placement="right">
              <Avatar circle size="sm" style={{ background: "#303f5e" }}>
                SS
              </Avatar>
            </Tooltip>
          }
        >
          {this.props.open && <Typography>Staff Stats</Typography>}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <FlexboxGrid>
            <SingleLink
              page="/clientbalances"
              icon="money"
              title="Job Balances"
              hdc={this.props.hdc}
            />

            <SingleLink
              page="/jobstats"
              icon="line-chart"
              title="Job Stats"
              hdc={this.props.hdc}
            />

            {userStore.user.admin && (
              <SingleLink
                page="/calendar/all"
                icon="calendar"
                title="*Leave Calendar"
                hdc={this.props.hdc}
              />
            )}
            {userStore.user.admin && (
              <SingleLink
                page="/imtracking"
                icon="map"
                title="*IM Tracking"
                hdc={this.props.hdc}
              />
            )}
            <SingleLink
              page="/supplier"
              icon="ship"
              title="IM Suppliers"
              hdc={this.props.hdc}
            />
          </FlexboxGrid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withRouter(observer(AdvStaffJobs));
