import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../Components/Dividers/Div14";
import SJDateToggle from "../../../../../../Components/Inputs/SJ/SJDateToggle";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";
import axios from "axios";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../Stores/UserStore";
import { message } from "antd";
import { Notification } from "rsuite";
import ServicesTab from "../Others/Services/ServicesTab";
import SJDateInput from "../../../../../../Components/Inputs/SJ/SJDateInput";

const instance = axios.create({ baseURL: baseURL });

class MainDates extends Component {
  constructor(props) {
    super(props);
    this.state = { load: {} };
    this.editVal = this.editVal.bind(this);
    this.updateVal = this.updateVal.bind(this);
  }

  editVal(val, label, sj) {
    let ind = this.props.ind;
    sjStore.esjEditJob(label, val, ind, sj);
  }

  updateVal = async (label, title, sj) => {
    let ind = this.props.ind;
    let esj = sjStore.esjs[ind];
    let val = esj.cc[label];

    let labeldate = `${label}date`;
    let valdate = esj[labeldate];

    this.setState({ load: { [label]: true } });
    let data = { [label]: val, [labeldate]: valdate };

    await instance
      .put(`job/sidejobs/sjs/editunique/${esj._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        sjStore.eMainJob(label, val, ind, sj);
        sjStore.eMainJob(labeldate, valdate, ind, sj);
        this.setState({ load: { [label]: false } });
        message.success(`${title} Updated.`);
      })
      .catch((err) => {
        this.setState({ load: { [label]: false } });
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let quality = this.props.quality;
    let sj = sjStore.sjs[ind];

    if (sj.status !== "Converted") {
      quality = false;
    }

    return (
      <div>
        <Div14>Main Dates</Div14>
        <SJDateToggle
          label={"doccol"}
          ind={ind}
          title={"Documents Collected"}
          editVal={this.editVal}
          updateVal={this.updateVal}
          load={this.state.load.doccol}
          quality={quality}
          sj="cc"
          read={!editview}
        />
        <SJDateInput
          label={"vesselarrival"}
          ind={ind}
          title={"Vessal Arrival"}
          editVal={this.editVal}
          updateVal={this.updateVal}
          load={this.state.load.vesselarrival}
          quality={quality}
          sj="cc"
          read={!editview}
        />
        <SJDateToggle
          label={"bayanissue"}
          ind={ind}
          title={"Bayan Issued"}
          editVal={this.editVal}
          updateVal={this.updateVal}
          load={this.state.load.bayanissue}
          quality={quality}
          sj="cc"
          read={!editview}
        />
        <SJDateToggle
          label={"cleardone"}
          ind={ind}
          title={"Clearance Done"}
          editVal={this.editVal}
          updateVal={this.updateVal}
          load={this.state.load.cleardone}
          quality={quality}
          sj="cc"
          read={!editview}
        />
        <br />
        <Div14>Approval Dates</Div14>
        <SJDateToggle
          label={"epa"}
          ind={ind}
          title={"EPA/Other"}
          editVal={this.editVal}
          updateVal={this.updateVal}
          load={this.state.load.epa}
          sj="cc"
          read={!editview}
        />
        <SJDateToggle
          label={"pai"}
          ind={ind}
          title={"PAI"}
          editVal={this.editVal}
          updateVal={this.updateVal}
          load={this.state.load.pai}
          sj="cc"
          read={!editview}
        />
        <br />
        <Div14>Services</Div14>
        <ServicesTab ind={ind} editview={editview} />
        <br />
      </div>
    );
  }
}

export default observer(MainDates);
