import { Statistic } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Col4 from "../../../../../../Components/Columns/Col4";
import Div14 from "../../../../../../Components/Dividers/Div14";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import SACStep4 from "./SACStep4";

class SACStep4Main extends Component {
  render() {
    return (
      <div>
        <Div14>Select Items</Div14>
        <FlexboxGrid justify="center">
          <Col4>
            <FlexboxGrid justify="center">
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 5px black",
                  width: 250,
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Locations"
                  value={newStorageStore.neworder.toaddress.length}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </FlexboxGrid>
          </Col4>
          <Col4>
            <FlexboxGrid justify="center">
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 5px black",
                  width: 250,
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Items"
                  value={newStorageStore.orderstat.itemcount}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </FlexboxGrid>
          </Col4>
          <Col4>
            <FlexboxGrid justify="center">
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 5px black",
                  width: 250,
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Packages"
                  value={newStorageStore.orderstat.packagecount}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </FlexboxGrid>
          </Col4>
          <Col4>
            <FlexboxGrid justify="center">
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 5px black",
                  width: 250,
                  textAlign: "center",
                }}
              >
                <Statistic
                  title="Total CBM"
                  value={newStorageStore.orderstat.totalcbm}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                />
              </div>
            </FlexboxGrid>
          </Col4>
        </FlexboxGrid>
        <hr />
        {newStorageStore.neworder.toaddress.map((loc, index) => (
          <SACStep4 loc={loc} key={index} index={index} />
        ))}
      </div>
    );
  }
}

export default observer(SACStep4Main);
