import React, { Component } from "react";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton, Col, InputGroup } from "rsuite";
import { Notification, Input, CheckboxGroup, Checkbox } from "rsuite";
import axios from "axios";

import moment from "moment";
import serviceStore from "../../../../Stores/AdditionalStores/ServiceStore";
import ParagraphLoader from "../../../../Components/Loaders/ParagraphLoader";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class ACServiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      selectedService: { title: "", type: "" },
      editedService: { title: "", type: "" },
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNewService = this.postNewService.bind(this);
  }

  changeVal(label, val) {
    let service = { ...this.state.editedService };
    service[label] = val;
    this.setState({ editedService: service });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ showEdit: val });
    } else {
      this.setState({
        selectedService: { title: "", type: "" },
        editedService: { title: "", type: "" },
        showEdit: false,
      });
    }
  }

  postNewService = async (service) => {
    this.changeVal("loading", true);

    return instance
      .put(`/additional/services/edit/${service._id}`, service, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        serviceStore.getServices(true);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Updated AC Order ${service.title}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ showEdit: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    if (serviceStore.loading) {
      return <ParagraphLoader />;
    }

    let data = this.props.services;
    let tableTitle = this.props.exportTitle;

    let columnnames = [
      {
        title: "AC Order Charge",
        field: "title",
        defaultSort: "asc",
      },
      {
        title: "Description",
        field: "description",
      },
      {
        title: "ST In",
        field: "showSTin",
        render: (rowData) => {
          let backgroundcolor = "#0a9103";
          let output = "Shown";
          if (!rowData.showSTin) {
            backgroundcolor = "#610017";
            output = "Hidden";
          }
          return (
            <p style={{ color: backgroundcolor }}>
              <strong>{output}</strong>
            </p>
          );
        },
      },
      {
        title: "ST Out",
        field: "showSTout",
        render: (rowData) => {
          let backgroundcolor = "#0a9103";
          let output = "Shown";
          if (!rowData.showSTout) {
            backgroundcolor = "#610017";
            output = "Hidden";
          }
          return (
            <p style={{ color: backgroundcolor }}>
              <strong>{output}</strong>
            </p>
          );
        },
      },
      {
        title: "Delivery Jobs",
        field: "showDelivery",
        render: (rowData) => {
          let backgroundcolor = "#0a9103";
          let output = "Shown";
          if (!rowData.showDelivery) {
            backgroundcolor = "#610017";
            output = "Hidden";
          }
          return (
            <p style={{ color: backgroundcolor }}>
              <strong>{output}</strong>
            </p>
          );
        },
      },
    ];

    let disabled = false;
    if (this.state.selectedService === this.state.editedService) {
      disabled = true;
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showEdit}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={disabled}
                    loading={this.state.loading}
                    onClick={() =>
                      this.postNewService(this.state.editedService)
                    }
                  >
                    Save Changes
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h5>Edit {this.state.editedService.type} Details</h5>
          <InputGroup>
            <InputGroup.Addon>Title</InputGroup.Addon>

            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.editedService.title}
              onChange={(e) => this.changeVal("title", e)}
            />
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroup.Addon>Description</InputGroup.Addon>
            <Input
              as="textarea"
              rows={3}
              value={this.state.editedService.description}
              onChange={(e) => this.changeVal("description", e)}
            />
          </InputGroup>
          <CheckboxGroup inline name="checkboxList">
            <Checkbox
              checked={this.state.editedService.showSTin}
              onClick={() =>
                this.changeVal("showSTin", !this.state.editedService.showSTin)
              }
            >
              Storage In
            </Checkbox>
            <Checkbox
              checked={this.state.editedService.showSTout}
              onClick={() =>
                this.changeVal("showSTout", !this.state.editedService.showSTout)
              }
            >
              Storage Out
            </Checkbox>
            <Checkbox
              checked={this.state.editedService.showDelivery}
              onClick={() =>
                this.changeVal(
                  "showDelivery",
                  !this.state.editedService.showDelivery
                )
              }
            >
              Delivery Jobs
            </Checkbox>
          </CheckboxGroup>
        </UnifiedModal>

        {!serviceStore.loading && (
          <MaterialTable
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  this.setState({ selectedService: rowData });
                  this.setState({ editedService: rowData });
                  this.showHideModal(true);
                },
              },
              {
                icon: "refresh",
                isFreeAction: true,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  serviceStore.getServices(true);
                },
              },
            ]}
          />
        )}
        <br />
      </div>
    );
  }
}

export default withRouter(observer(ACServiceTable));
