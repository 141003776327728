import { Fab } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, IconButton, Loader, Notification } from "rsuite";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import TimelineComp from "../../../../../Components/Timeline/TimelineComp";
import { TRC } from "../../../../../Functions/Timing/TimingRulesCalc";
import newStorageStore from "../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import zoneStore from "../../../../../Stores/Admin/Lists/ZoneStore";
import SACStep1 from "./Steps/SACStep1";
import SACStep2 from "./Steps/SACStep2";
import SACStep5 from "./Steps/SACStep5";
import NextBackButton from "./Steps/Supporting/NextBackButton";
import SACStep6 from "./Steps/SACStep6";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import ChargesTable from "./Steps/Supporting/OrderSummarySupport/ChargesTable";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import { PageHeader, Spin } from "antd";
import SACToLocMain from "./Steps/SACToLocMain";
import SACStep4Main from "./Steps/SACStep4Main";

const instance = axios.create({ baseURL: baseURL });

class NewEditStorageMain extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 1, type: "New", show: false, loading: false };
    this.changeStep = this.changeStep.bind(this);
    this.onHide = this.onHide.bind(this);
    this.postOrder = this.postOrder.bind(this);
  }
  onHide() {
    this.setState({ show: false });
  }

  postOrder = async () => {
    let order = newStorageStore.neworder;
    let storCont = newStorageStore.storCont;
    let clientid = newStorageStore.account._id;

    if (order.jobtype === "Delivery In" || order.jobtype === "Storage In") {
      order.services.stincost = storCont.incharge;
    } else if (
      order.jobtype === "Delivery In" ||
      order.jobtype === "Storage In"
    ) {
      order.services.stincost = storCont.outcharge;
    }
    order.services.tripmultiplier = newStorageStore.delCont.dropoffmult;
    order.client = clientid;
    order.emlocation = storCont.emlocation;
    order.emlocationid = storCont.emlocation._id;
    let data = order;

    let url = "/ac/acorder/new";

    this.setState({ loading: true });

    return instance
      .post(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ loading: false });

        Notification["success"]({
          title: `New Order Created.`,
          description: "Please allow a few minutes for the items to load.",
        });
        this.props.history.push(`/sac/dash/${order.client}`);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });

        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  changeStep(val) {
    if ((this.state.step === 1) & (val < 0)) {
      this.props.history.push(
        `/ac/dashboard/${this.props.match.params.clientid}`
      );
    } else if (this.state.step === 1) {
      newStorageStore.createNewOrder();
      let earliest = TRC(newStorageStore.delCont, false, false);
      newStorageStore.editOrder(earliest, "jobdate");
      this.setState({ step: this.state.step + val });
    } else {
      this.setState({ step: this.state.step + val });
    }
  }
  componentDidMount() {
    zoneStore.getZones();
    if (this.props.match.path === "/sac/new/:clientid") {
      this.setState({ type: "New" });
      newStorageStore.getAccount(this.props.match.params.clientid);
    } else {
      this.setState({ type: "Edit" });
    }
  }
  componentDidUpdate() {
    if (!newStorageStore.loading.account) {
      if (newStorageStore.account._id !== this.props.match.params.clientid) {
        newStorageStore.getAccount(this.props.match.params.clientid, true);
        this.setState({ step: 1 });
      }
    }
  }
  render() {
    let content = <Loader center />;
    let step = this.state.step;

    if (newStorageStore.loading.account) {
      return <Loader center />;
    }
    let delsto = newStorageStore.delstoinout.delsto;
    let showfab = true;
    if (!newStorageStore.loading.account) {
      if (this.state.step === 1) {
        showfab = false;
        content = <SACStep1 />;
      } else if (this.state.step === 2) {
        content = <SACStep2 />;
      } else if (this.state.step > 2) {
        let newstep = this.state.step;
        if (delsto === "Storage") {
          newstep += 1;
        }
        if (newstep === 3) {
          content = <SACToLocMain />;
        } else if (newstep === 4) {
          content = <SACStep4Main />;
        } else if (newstep === 5) {
          content = <SACStep5 />;
        } else if (newstep === 6) {
          content = <SACStep6 />;
          showfab = false;
        }
      }
    }
    let allsteps = [
      "Delivery Type",
      "Timing",
      "Items",
      "Extras",
      "Confirmation",
    ];
    if (delsto === "Delivery") {
      allsteps = [
        "Delivery Type",
        "Timing",
        "Location",
        "Items",
        "Extras",
        "Confirmation",
      ];
    }

    return (
      <div>
        <Spin
          spinning={this.state.loading}
          size="large"
          tip="Loading..."
          style={{ color: "black" }}
        >
          <UnifiedModal
            show={this.state.show}
            onHide={this.onHide}
            title={"Order Summary"}
            footer={
              <IconButton
                icon={<Icon icon="close" />}
                color="red"
                onClick={this.onHide}
              >
                Close
              </IconButton>
            }
          >
            <ChargesTable />
          </UnifiedModal>
          <PageHeader
            onBack={() =>
              this.props.history.push(
                `/ac/dashboard/${newStorageStore.account._id}`
              )()
            }
            title={`${this.state.type} AC Order`}
            subTitle={
              this.state.type === "New"
                ? `Create A New Order`
                : `Edit Order #${newStorageStore.neworder.acOrderNumId}`
            }
          />

          {showfab && (
            <div
              style={{
                right: "5%",
                bottom: "5%",
                position: "fixed",
                zIndex: 1000,
              }}
            >
              <Fab
                variant="extended"
                size="small"
                onClick={() => this.setState({ show: true })}
              >
                <ReceiptLongIcon sx={{ mr: 1 }} fontSize="small" />
                <span> </span> Order Summary
              </Fab>
            </div>
          )}

          <TimelineComp steps={allsteps} step={step - 1} />
          <NextBackButton
            step={this.state.step}
            changeStep={this.changeStep}
            allsteps={allsteps}
            postOrder={this.postOrder}
          />

          {content}
          <hr />
        </Spin>
      </div>
    );
  }
}

export default withRouter(observer(NewEditStorageMain));
