import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ItemsList from "../ItemsList/ItemsList";

class ACCStep3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ItemsList />
      </div>
    );
  }
}

export default withRouter(observer(ACCStep3));
