import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import equipmentStore from "../../../../../Stores/EquipmentStores/EquipmentStore";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import moment from "moment";
import MaterialTable from "material-table";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class FuelGraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "",
    };
  }

  render() {
    let eq = equipmentStore;
    let fuelbuttons = eq.fuelbytrucklist.map((seq, index) => (
      <IconButton
        color={eq.selectedfuel === seq.name ? "green" : "default"}
        icon={
          <Icon
            icon={
              seq.type === "Generator"
                ? "bolt"
                : seq.type === "Pickup"
                ? "car"
                : "truck"
            }
          />
        }
        size="xs"
        key={index}
        onClick={() => equipmentStore.selectSingleFuel(seq.name)}
      >
        {seq.name}
      </IconButton>
    ));

    let tableTitle = `Fuel Records for ${eq.selectedfuel}`;
    let data = eq.filteredfuel;
    let hidecols = true;
    if (eq.filteredfuel.length > 0) {
      if (
        eq.filteredfuel[0].type === "Truck" ||
        eq.filteredfuel[0].type === "Pickup"
      ) {
        hidecols = false;
      }
    }
    let columnnames = [
      {
        title: "ID",
        field: "equipmentFuelNumId",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Type", field: "type", removable: true },
      { title: "Plate", field: "license", removable: true },
      {
        title: "Date",
        field: "date",
        removable: true,
        render: (rowData) => (
          <div>{moment(rowData.date).format("DD-MMM-YY")}</div>
        ),
      },
      { title: "KM", field: "kilometer", removable: true, hidden: hidecols },
      { title: "Old KM", field: "oldkm", removable: true, hidden: hidecols },
      { title: "KM Diff", field: "kmdiff", removable: true, hidden: hidecols },
      { title: "Price", field: "price", removable: true },
      { title: "Liters", field: "liter", removable: true },
      { title: "Old Liter", field: "oldliter", removable: true },
      { title: "KM/Liter", field: "kmperliter", removable: true },
      {
        title: "KD/Liter",
        field: "priceperliter",
        removable: true,
        render: (rowData) => {
          return PRKD(rowData.priceperliter);
        },
      },
    ];

    return (
      <div>
        <b>Fuel Graphs</b>
        <FlexboxGrid justify="space-around">{fuelbuttons}</FlexboxGrid>
        {eq.selectedfuel !== "" && (
          <MaterialTable
            isLoading={eq.loading.fuel}
            title={data.length + " " + tableTitle}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 20,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `${
                this.props.exportTitle ? this.props.exportTitle : tableTitle
              } as of ${moment(new Date()).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(FuelGraphs));
