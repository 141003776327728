import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import moment from "moment";
import baseURL from "../../../../Static/baseURL/baseURL";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import userStore from "../../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class StaffStorageStatStore {
  constructor() {
    this.loading = { stats: false, staticitems: false, clientbreakdown: false };
    this.loaded = { stats: false, staticitems: false, clientbreakdown: false };

    this.startdate = moment().subtract(2, "month").startOf("M");
    this.enddate = moment().endOf("month");

    this.staticitems = [];
    this.movestat = [];
    this.clientbreakdown = { chart: {} };
    this.stats = {
      currentplusreserve: 0,
      onreserve: 0,
      cbm: 0,
      items: 0,
      stoin: 0,
      stoout: 0,
      delin: 0,
      delout: 0,
      cbmin: 0,
      cbmout: 0,
      qtyin: 0,
      qtyout: 0,
      barchart: [{ x: "", y: 0 }],
      dels: [],
    };

    this.selectedcode = {
      code: "totalqty",
      title: "Quantity",
      move: true,
      nomove: true,
    };

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      startdate: observable,
      enddate: observable,

      staticitems: observable,
      movestat: observable,
      stats: observable,
      selectedcode: observable,
      clientbreakdown: observable,

      addmovestats: computed,
      topten: computed,
      staticitemstat: computed,
    });
  }

  // FETCH
  getStats(override) {
    let loaded = false;

    if (this.loaded.stats) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.stats = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/ac/storstats/storagestats/all`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((stats) => {
          this.stats = stats;
          this.loaded.stats = true;
          this.loading.stats = false;
          console.log("Basic Stats Fetched");
        })
        .catch((err) => {
          this.loaded.stats = false;
          this.loading.stats = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }
  getStaticItems(override) {
    let loaded = false;

    if (this.loaded.staticitems) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.staticitems = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/ac/storstats/staticitemslist/all`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((output) => {
          this.staticitems = output.items;
          this.movestat = output.movestat;
          this.loaded.staticitems = true;
          this.loading.staticitems = false;
          console.log("Static Items Fetched");
        })
        .catch((err) => {
          this.loaded.staticitems = false;
          this.loading.staticitems = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }
  getClientBreakdown(override) {
    let loaded = false;

    if (this.loaded.clientbreakdown) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.clientbreakdown = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/ac/storstats/customerbreakdown`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((output) => {
          this.clientbreakdown = output;
          this.loaded.clientbreakdown = true;
          this.loading.clientbreakdown = false;
          console.log("Static Items Fetched");
        })
        .catch((err) => {
          this.loaded.clientbreakdown = false;
          this.loading.clientbreakdown = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get addmovestats() {
    let output = {
      avgitemmove: 0,
      staticitems: [],
      staticcbm: 0,
      monthlyprice: 0,
    };
    output.staticitems = this.staticitems.filter((it) => it.totalqty === 0);
    for (let i = 0; i < output.staticitems.length; i++) {
      output.staticcbm += output.staticitems[i].currentplusreservecbm;
      output.monthlyprice += output.staticitems[i].currentplusreserveprice;
    }

    return output;
  }

  get topten() {
    let code = this.selectedcode.code;
    let olddata = [...this.staticitems];
    let top = olddata.sort((a, b) => moment(b[code]) - moment(a[code]));
    let initial = top.slice(0, 20);

    let x = [];
    let y = [];
    for (let i = 0; i < initial.length; i++) {
      x = [...x, PRKD(initial[i][code])];
      y = [...y, `${initial[i].acItemNumId}) ${initial[i].name}`];
    }

    return { value: x, name: y };
  }

  get staticitemstat() {
    let items = [...this.staticitems];

    let movement = items.filter((it) => it.totalqty > 0);
    let nomovement = items.filter((it) => it.totalqty === 0);
    let finallist = [...items];
    if (!this.selectedcode.move) {
      finallist = finallist.filter((it) => it.totalqty === 0);
    }
    if (!this.selectedcode.nomove) {
      finallist = finallist.filter((it) => it.totalqty > 0);
    }

    return { items: finallist, movement: movement, nomovement: nomovement };
  }

  // FUNCTIONS:
  changeSelectedCode(code, title) {
    staffStorageStatStore.selectedcode.code = code;
    staffStorageStatStore.selectedcode.title = title;
  }
  changeMoveFilt(type) {
    let other = "nomove";
    if (type === "nomove") other = "move";
    if (
      staffStorageStatStore.selectedcode[type] &
      !staffStorageStatStore.selectedcode[other]
    ) {
      staffStorageStatStore.selectedcode.move = true;
      staffStorageStatStore.selectedcode.nomove = true;
    } else {
      staffStorageStatStore.selectedcode.move = false;
      staffStorageStatStore.selectedcode.nomove = false;
    }
    staffStorageStatStore.selectedcode[type] = true;
  }

  changeDate(date) {
    staffStorageStatStore.startdate = moment(date).startOf("M");
  }
  changeDateRange(date) {
    staffStorageStatStore.startdate = moment(date[0]).startOf("M");
    staffStorageStatStore.enddate = moment(date[1]).endOf("M");
  }
  changeFrequency(freq) {
    staffStorageStatStore.frequency = freq;
  }
  // ----- DATE ----
}

const staffStorageStatStore = new StaffStorageStatStore();
export default staffStorageStatStore;
