import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import acInternationalStore from "../../../../../../Stores/AccountUpdated/AIM/ACInternationalStore";
import { Divider, Icon, InputGroup } from "rsuite";
import StaticCalendar from "../../../../../../Components/Calendar/StaticCalendar";
import DatePickerSTD from "../../../../../../Components/Inputs/STD/DatePickerSTD";
import Div14 from "../../../../../../Components/Dividers/Div14";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import modalACOrderStore from "../../../../../../Stores/ModalStores/ModalACOrderStore";
import OrderModal from "../../../Modals/OrderModal";

class IMTrack extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onHide = this.onHide.bind(this);
  }
  componentDidMount() {
    acInternationalStore.getIMtracks();
  }
  onHide() {
    this.setState({ show: false });
  }

  render() {
    let tracks = acInternationalStore.imtracks;

    return (
      <LoadBack loading={acInternationalStore.loading.imtracks}>
        <OrderModal show={this.state.show} onHide={this.onHide} />
        <Div14>Shipment Tracking</Div14>
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <DatePickerSTD
              title={"From Date"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={acInternationalStore.startdate}
              size="large"
              editVal={acInternationalStore.editDate}
            />
            <InputGroup.Button
              color="green"
              loading={acInternationalStore.loading.order}
              onClick={() => {
                acInternationalStore.getIMtracks(true);
              }}
            >
              Reload <Divider vertical />
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <br />
        <StaticCalendar
          start={"date"}
          end={"date"}
          title={"title"}
          data={tracks}
          onSelectEvent={(e) => {
            modalACOrderStore.getAIMfromTrack(e.aimOrder);
            this.setState({ show: true });
          }}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(IMTrack));
