import { observer } from "mobx-react";
import React, { Component } from "react";
import SJDates from "./SJDates";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import HeaderFooterPDF from "../../../Template/HeaderFooterPDF";
import Html from "react-pdf-html";
import PackingList from "../PackingList/PackingList";
import SJBreakdownTable from "../Prices/SJBreakdownTable";
import moment from "moment";

const stylesheet = {
  // clear margins for all <p> tags
  p: {
    margin: 0,
    padding: 0,
    lineHeight: 1,
    fontSize: 10,
  },
  strong: {
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    lineHeight: 1,
    fontSize: 10,
  },
};
const styles = StyleSheet.create({
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableCol70white: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableCol30blue: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
});

class SJRows extends Component {
  render() {
    let sj = this.props.sj;

    let jdrows = <SJDates sj={sj} />;

    let incser = <View />;
    if (sj.incservice.length > 0) {
      incser = (
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Included Services</Text>
          <Text style={styles.tableCol70white}>{sj.incservice}.</Text>
        </View>
      );
    }
    let excser = <View />;
    if (sj.excservice.length > 0) {
      excser = (
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Excluded Services</Text>
          <Text style={styles.tableCol70white}>{sj.excservice}.</Text>
        </View>
      );
    }

    let jobtype = "Local Move";
    let detailsrows = <View />;
    if (sj.jobtype === "LM") {
      jobtype = "Local Move";
      detailsrows = (
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Service</Text>
            <Text style={styles.tableCol70white}>{sj.subtype}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>CBM</Text>
            <Text style={styles.tableCol70white}>{sj.cbm}</Text>
          </View>
        </View>
      );
    } else if (sj.jobtype === "IM") {
      jobtype = "International Move";
      detailsrows = (
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>IM Type</Text>
            <Text style={styles.tableCol70white}>{sj.direction}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Method</Text>
            <Text style={styles.tableCol70white}>{sj.shippingmethod}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Service</Text>
            <Text style={styles.tableCol70white}>{sj.subtype}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>From:</Text>
            <Text style={styles.tableCol70white}>{sj.shipfrom}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>To:</Text>
            <Text style={styles.tableCol70white}>{sj.shipto}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Container Info</Text>
            <Text style={styles.tableCol70white}>{sj.containertype}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Commodity</Text>
            <Text style={styles.tableCol70white}>{sj.commodity}</Text>
          </View>
        </View>
      );
    } else if (sj.jobtype === "ST") {
      let time = "Month";
      if (sj.subtype === "Weekly") time = "Week";
      if (sj.subtype === "Daily") time = "Day";
      jobtype = "Storage Job";
      detailsrows = (
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Type</Text>
            <Text style={styles.tableCol70white}>{sj.subtype}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Facility:</Text>
            <Text style={styles.tableCol70white}>{sj.facility}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>CBM:</Text>
            <Text style={styles.tableCol70white}>{sj.cbm}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Price Per {time} (KD):</Text>
            <Text style={styles.tableCol70white}>{sj.pricepertime}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Start Date:</Text>
            <Text style={styles.tableCol70white}>
              {moment(sj.start).format("DD-MMM-YY")}
            </Text>
          </View>
        </View>
      );
    } else if (sj.jobtype === "CC") {
      jobtype = "Custom Clearance";
      detailsrows = (
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Type</Text>
            <Text style={styles.tableCol70white}>{sj.subtype}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Shipping Mode / Line:</Text>
            <Text style={styles.tableCol70white}>
              {sj.shippingmode} / {sj.shippingline}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Origin:</Text>
            <Text style={styles.tableCol70white}>{sj.originlocation}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>BL Number:</Text>
            <Text style={styles.tableCol70white}>{sj.blnumber}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Invoice TAs:</Text>
            <Text style={styles.tableCol70white}>{sj.invoicetas}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.tableCol30blue}>Packages:</Text>
            <Text style={styles.tableCol70white}>{sj.packages}</Text>
          </View>
        </View>
      );
    }

    let invnotes;
    if (sj.showinvnotes) {
      invnotes = (
        <Html
          collapse
          style={{
            fontSize: 10,
            margin: 0,
            padding: 0,
            lineHeight: 1,
            marginLeft: 5,
            marginRight: 5,
          }}
          stylesheet={stylesheet}
        >
          {sj.invnotes}
        </Html>
      );
    }

    let packinglist = null;
    if (sj.items.length > 0) {
      packinglist = <PackingList sj={sj} />;
    }
    let breakdown = null;
    if (sj.proposals.length > 0) {
      breakdown = <SJBreakdownTable sj={sj} />;
    }

    return (
      <HeaderFooterPDF>
        <Text style={styles.header}>
          {jobtype} Details ({sj.jobtype}-{sj.numId})
        </Text>
        <Text style={styles.tableHeaderStyle}>Basic Job Details</Text>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Service ID</Text>
          <Text style={styles.tableCol70white}>
            {sj.jobtype}-{sj.numId}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCol30blue}>Job</Text>
          <Text style={styles.tableCol70white}>{jobtype}</Text>
        </View>

        {detailsrows}
        {incser}
        {excser}
        <Text style={{ lineHeight: 0.5 }}> </Text>
        {jdrows}
        <Text style={{ lineHeight: 0.5 }}> </Text>
        {breakdown}
        {sj.showinvnotes && (
          <View>
            <Text style={{ lineHeight: 0.5 }}> </Text>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
              Invoice Notes:
            </Text>
            {invnotes}
          </View>
        )}
        <Text style={{ lineHeight: 0.5 }}> </Text>
        {packinglist}
      </HeaderFooterPDF>
    );
  }
}

export default observer(SJRows);
