import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import singleJobStore from "../../../../Stores/SingleJobStore";
import userStore from "../../../../Stores/UserStore";
import JobFeedback from "../../../Tracking/Feedback/JobFeedback";

class FeedbackTab extends Component {
  render() {
    let content;

    if (singleJobStore.job.feedback) {
      content = <JobFeedback />;
    } else {
      if (userStore.user.jobs) {
        content = (
          <div style={{ textAlign: "center" }}>
            <h4>Must be filled in by the Customer.</h4>
            <h6>
              Copy the below link and share it with the Customer for their
              feedback.
            </h6>
            <br />
            <FlexboxGrid justify="center">
              <IconButton
                icon={<Icon icon="star" />}
                color="yellow"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `Thank you for choosing Easy Move. Your feedback is important to help us improve. It would be appreciated if you can rate our services using the below link: \n https://www.emlkw.com/feedback/job/${singleJobStore.job._id}\n\nThank you.`
                  )
                }
              >
                Copy Feedback Link
              </IconButton>
            </FlexboxGrid>
          </div>
        );
      } else {
        content = <JobFeedback />;
      }
    }

    return (
      <div>
        <Div14>Customer Feedback</Div14>
        {content}
      </div>
    );
  }
}

export default observer(FeedbackTab);
