import React, { Component } from "react";

import DataGrid, {
  Column,
  ColumnFixing,
  Scrolling,
  SearchPanel,
  FilterRow,
} from "devextreme-react/data-grid";
import { observer } from "mobx-react";
import { Parsed, PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";

class ChargesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.DelsIn = this.DelsIn.bind(this);
    this.DelsOut = this.DelsOut.bind(this);
    this.TotalChargeCell = this.TotalChargeCell.bind(this);
  }

  DelsIn(cellData) {
    let dels = cellData.data.acorders.filter(
      (del) => del.jobtype === "Delivery In" || del.jobtype === "Storage In"
    );

    return <div>{dels.length}</div>;
  }
  DelsOut(cellData) {
    let dels = cellData.data.acorders.filter(
      (del) => del.jobtype === "Delivery Out" || del.jobtype === "Storage Out"
    );

    return <div>{dels.length}</div>;
  }
  TotalChargeCell(cellData) {
    let stochg = cellData.data.storchg;
    let delchg = cellData.data.deltotalchg;

    return <div>{PRKD(Parsed(stochg) + Parsed(delchg))}</div>;
  }

  render() {
    let data = this.props.data.data.charges;

    return (
      <div>
        <DataGrid
          dataSource={data}
          keyExpr="freqtime"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
        >
          <FilterRow visible={false} applyFilter />
          <SearchPanel visible={true} />
          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnFixing enabled={true} />
          <Column
            dataField="freqtime"
            dataType="number"
            caption="Charges"
            alignment="center"
            minwidth={80}
          />
          <Column
            dataField="startday"
            dataType="date"
            caption="From"
            alignment="center"
            format={"dd-MMM-yy"}
            minwidth={80}
          />
          <Column
            dataField="endday"
            dataType="date"
            caption="From"
            alignment="center"
            format={"dd-MMM-yy"}
            minwidth={80}
          />
          <Column caption="Storage">
            <Column
              dataField="cbmstart"
              dataType="number"
              caption="Start CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              minwidth={80}
            />
            <Column
              dataField="storin"
              dataType="number"
              caption="In CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              minwidth={80}
            />

            <Column
              dataField="storout"
              dataType="number"
              caption="Out CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              minwidth={80}
            />
            <Column
              dataField="cbmend"
              dataType="number"
              caption="End CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              minwidth={80}
            />
            <Column
              dataField="freqchg"
              dataType="number"
              caption="Chargeable CBM"
              alignment="center"
              format={{ type: "fixedPoint", precision: 2 }}
              minwidth={80}
            />
          </Column>
          <Column caption="Orders">
            <Column
              caption="Orders In"
              alignment="center"
              cellRender={this.DelsIn}
              minwidth={80}
            />
            <Column
              caption="Orders Out"
              alignment="center"
              cellRender={this.DelsOut}
              minwidth={80}
            />
          </Column>
          <Column caption="Charges">
            <Column
              dataField="storchg"
              dataType="number"
              caption="Storage Charge KD"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minwidth={80}
            />
            <Column
              dataField="deltotalchg"
              dataType="number"
              caption="Order Charge KD"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              minwidth={80}
            />
          </Column>
          <Column
            dataField="tia"
            dataType="number"
            caption="Order Charge KD"
            alignment="center"
            cellRender={this.TotalChargeCell}
            minwidth={80}
          />
        </DataGrid>
      </div>
    );
  }
}

export default observer(ChargesTable);
