import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";
import HeaderFooterPDF from "../../Template/HeaderFooterPDF";
import { Empty } from "antd";
import DelHeaderSection from "./DelHeaderSection";

class PickupNotePDF extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
    this.editVal = this.editVal.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val, loading: false });
  }
  render() {
    let del = this.props.del;
    let to = this.props.to;
    let client = this.props.client;

    return (
      <div>
        {to.status === "Completed" ? (
          <>
            <PDFViewer
              width={"100%"}
              height={600}
              filename={`Delivery Note ${del.deliveryNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
            >
              <Document
                author={"Easy Move Logistics Co."}
                title={`Pickup Delivery Note ${del.deliveryNumId} -
                ${moment(to.delSignature.date).format("YYMMDDHH")} for ${
                  client.name
                }`}
                creator={"Easy Move Logistics Co."}
              >
                <HeaderFooterPDF>
                  <DelHeaderSection
                    del={del}
                    client={client}
                    type={"Pickup"}
                    to={to}
                  />
                </HeaderFooterPDF>
              </Document>
            </PDFViewer>
          </>
        ) : (
          <Empty description="Delivery Pending" />
        )}
      </div>
    );
  }
}

export default observer(PickupNotePDF);
