import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import moment from "moment";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import singleAccountStore from "../SingleAccount/SingleAccountStore";
import { Parsed, PRKD } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class ACStorStatStore {
  constructor() {
    this.loading = { stats: false, staticitems: false, monthly: false };
    this.loaded = { stats: false, staticitems: false, monthly: false };

    this.startdate = moment().subtract(2, "month").startOf("M");
    this.enddate = moment().endOf("month");

    this.staticitems = [];
    this.movestat = [];
    this.stats = {
      currentplusreserve: 0,
      onreserve: 0,
      cbm: 0,
      items: 0,
      stoin: 0,
      stoout: 0,
      delin: 0,
      delout: 0,
      cbmin: 0,
      cbmout: 0,
      qtyin: 0,
      qtyout: 0,
      barchart: [{ x: "", y: 0 }],
      dels: [],
    };
    this.monthlyoverview = {
      months: [],
      stoin: [],
      stoout: [],
      delin: [],
      delout: [],
      cbmin: [],
      cbmout: [],
      qtyin: [],
      qtyout: [],
      orderin: [],
      orderout: [],
      totalsto: [],
      totaldel: [],
      items: [],
    };

    this.selectedcode = {
      code: "totalqty",
      title: "Quantity",
      move: true,
      nomove: true,
    };

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      startdate: observable,
      enddate: observable,

      staticitems: observable,
      movestat: observable,
      stats: observable,
      selectedcode: observable,
      monthlyoverview: observable,

      addmovestats: computed,
      topten: computed,
      staticitemstat: computed,
      monthlyoverviewsummary: computed,
    });
  }

  // FETCH
  getStats(override) {
    let loaded = false;
    let clientid = singleAccountStore.account._id;
    if (this.loaded.stats) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.stats = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/ac/storstats/storagestats/${clientid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((stats) => {
          this.stats = stats;
          this.loaded.stats = true;
          this.loading.stats = false;
          console.log("Basic Stats Fetched");
        })
        .catch((err) => {
          this.loaded.stats = false;
          this.loading.stats = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }
  getStaticItems(override) {
    let loaded = false;
    let clientid = singleAccountStore.account._id;
    if (this.loaded.staticitems) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.staticitems = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/ac/storstats/staticitemslist/${clientid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((output) => {
          this.staticitems = output.items;
          this.movestat = output.movestat;
          this.loaded.staticitems = true;
          this.loading.staticitems = false;
          console.log("Static Items Fetched");
        })
        .catch((err) => {
          this.loaded.staticitems = false;
          this.loading.staticitems = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }
  getMonthlyOverview(override) {
    let loaded = false;
    let clientid = singleAccountStore.account._id;
    if (this.loaded.monthly) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.monthly = true;
      let start = moment(this.startdate).add(3, "day");
      let end = moment(this.enddate).add(3, "day");
      let data = { startdate: start, enddate: end };
      return instance
        .put(`/ac/storstats/monthlyoverview/${clientid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((stats) => {
          this.monthlyoverview = stats;

          this.loaded.monthly = true;
          this.loading.monthly = false;
          console.log("Monthly Overview Fetched");
        })
        .catch((err) => {
          this.loaded.monthly = false;
          this.loading.monthly = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Monthly Overview.`,
          });
        });
    }
  }
  // POST

  // COMPUTATIONS:

  get addmovestats() {
    let output = {
      avgitemmove: 0,
      staticitems: [],
      staticcbm: 0,
      monthlyprice: 0,
    };
    output.staticitems = this.staticitems.filter((it) => it.totalqty === 0);
    for (let i = 0; i < output.staticitems.length; i++) {
      output.staticcbm += output.staticitems[i].currentplusreservecbm;
      output.monthlyprice += output.staticitems[i].currentplusreserveprice;
    }

    return output;
  }

  get topten() {
    let code = this.selectedcode.code;
    let olddata = [...this.staticitems];
    let top = olddata.sort((a, b) => moment(b[code]) - moment(a[code]));
    let initial = top.slice(0, 20);

    let x = [];
    let y = [];
    for (let i = 0; i < initial.length; i++) {
      x = [...x, PRKD(initial[i][code])];
      y = [...y, `${initial[i].acItemNumId}) ${initial[i].name}`];
    }

    return { value: x, name: y };
  }

  get staticitemstat() {
    let items = [...this.staticitems];

    let movement = items.filter((it) => it.totalqty > 0);
    let nomovement = items.filter((it) => it.totalqty === 0);
    let finallist = [...items];
    if (!this.selectedcode.move) {
      finallist = finallist.filter((it) => it.totalqty === 0);
    }
    if (!this.selectedcode.nomove) {
      finallist = finallist.filter((it) => it.totalqty > 0);
    }

    return { items: finallist, movement: movement, nomovement: nomovement };
  }

  get monthlyoverviewsummary() {
    let monthly = this.monthlyoverview;
    let output = {
      stoin: 0,
      stoout: 0,
      delin: 0,
      delout: 0,
      cbmin: 0,
      cbmout: 0,
      qtyin: 0,
      qtyout: 0,
      orderin: 0,
      orderout: 0,
      totalsto: 0,
      totaldel: 0,
      damagereports: 0,
      stagnantcbm: 0,
      stagnantcost: 0,
    };
    for (let i = 0; i < monthly.months.length; i++) {
      output.stoin += Parsed(monthly.stoin[i]);
      output.stoout += Parsed(monthly.stoout[i]);
      output.delin += Parsed(monthly.delin[i]);
      output.delout += Parsed(monthly.delout[i]);
      output.cbmin += Parsed(monthly.cbmin[i]);
      output.cbmout += Parsed(monthly.cbmout[i]);
      output.qtyin += Parsed(monthly.qtyin[i]);
      output.qtyout += Parsed(monthly.qtyout[i]);
      output.orderin += Parsed(monthly.orderin[i]);
      output.orderout += Parsed(monthly.orderout[i]);
      output.totalsto += Parsed(monthly.totalsto[i]);
      output.totaldel += Parsed(monthly.totaldel[i]);
      output.damagereports += Parsed(monthly.damagereports[i]);
    }
    for (let i = 0; i < monthly.items.length; i++) {
      output.stagnantcbm += parseFloat(monthly.items[i].currentplusreservecbm);
      output.stagnantcost += parseFloat(
        monthly.items[i].currentplusreserveprice
      );
    }

    output.stoin = Math.round(output.stoin);
    output.stoout = Math.round(output.stoout);
    output.delin = Math.round(output.delin);
    output.delout = Math.round(output.delout);
    output.cbmin = Math.round(1000 * output.cbmin) / 1000;
    output.cbmout = Math.round(1000 * output.cbmout) / 1000;
    output.qtyin = Math.round(output.qtyin);
    output.qtyout = Math.round(output.qtyout);
    output.orderin = Math.round(output.orderin);
    output.orderout = Math.round(output.orderout);
    output.totalsto = Math.round(output.totalsto);
    output.totaldel = Math.round(output.totaldel);
    output.damagereports = Math.round(output.damagereports);

    output.stagnantcbm = Math.round(1000 * output.stagnantcbm) / 1000;
    output.stagnantcost = Math.round(1000 * output.stagnantcost) / 1000;

    return output;
  }

  // FUNCTIONS:
  changeSelectedCode(code, title) {
    acStorStatStore.selectedcode.code = code;
    acStorStatStore.selectedcode.title = title;
  }
  changeMoveFilt(type) {
    let other = "nomove";
    if (type === "nomove") other = "move";
    if (
      acStorStatStore.selectedcode[type] & !acStorStatStore.selectedcode[other]
    ) {
      acStorStatStore.selectedcode.move = true;
      acStorStatStore.selectedcode.nomove = true;
    } else {
      acStorStatStore.selectedcode.move = false;
      acStorStatStore.selectedcode.nomove = false;
    }
    acStorStatStore.selectedcode[type] = true;
  }

  changeDate(date) {
    acStorStatStore.startdate = moment(date).startOf("M");
  }
  changeDateRange(date) {
    acStorStatStore.startdate = moment(date[0]).startOf("M");
    acStorStatStore.enddate = moment(date[1]).endOf("M");
  }
  changeFrequency(freq) {
    acStorStatStore.frequency = freq;
  }
  // ----- DATE ----
}

const acStorStatStore = new ACStorStatStore();
export default acStorStatStore;
