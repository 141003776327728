import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import moment from "moment";

const instance = axios.create({ baseURL: baseURL });

class WHMPlanningStore {
  constructor() {
    this.plans = [];
    this.singleplan = { _id: "" };
    this.originalplan = { _id: "" };
    this.loading = {
      plans: false,
      singleplan: false,
    };
    this.loaded = {
      plans: false,
      singleplan: false,
    };

    makeObservable(this, {
      plans: observable,
      singleplan: observable,
      originalplan: observable,
      loading: observable,
      loaded: observable,
      singlePlanTimeline: computed,
    });
  }

  // FETCH
  getPlans(override) {
    let loaded;
    if (this.loading.plans || this.loaded.plans) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.plans = true;
      return instance
        .get(`/whm/plan/allplans`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.plans = data;
          this.loading.plans = false;
          this.loaded.plans = true;
          console.log("WHM Plans Fetched");
        })
        .catch((err) => {
          this.loading.plans = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getSinglePlan(id, override) {
    let loaded;
    let planid = this.singleplan._id;
    if (this.loading.singleplan || this.loaded.singleplan) loaded = true;
    if (id) {
      if (planid !== id) {
        loaded = false;
        planid = id;
      }
    }
    if (override) loaded = false;
    if (!loaded) {
      this.loading.singleplan = true;
      return instance
        .get(`/whm/plan/singleplan/${planid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let newdata = { ...data };
          newdata.starttime = moment(data.starttime).add(3, "hours");
          newdata.endtime = moment(data.endtime).add(3, "hours");
          newdata.breakstart = moment(data.breakstart).add(3, "hours");
          newdata.breakend = moment(data.breakend).add(3, "hours");
          this.singleplan = newdata;
          this.originalplan = newdata;

          this.loading.singleplan = false;
          this.loaded.singleplan = true;
          console.log("WHM Plans Fetched");
        })
        .catch((err) => {
          this.loading.singleplan = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get singlePlanTimeline() {
    let data = whmPlanningStore.originalplan;
    let breaktime = moment(data.endbreak).diff(data.startbreak, "minutes");
    let totaltime =
      moment(data.endtime).diff(data.starttime, "minutes") - breaktime;
    return totaltime;
  }
  // Functions:
  editPlan(val, label) {
    whmPlanningStore.singleplan[label] = val;
  }
  updatePlan(label) {
    whmPlanningStore.originalplan[label] = whmPlanningStore.singleplan[label];
  }
}

const whmPlanningStore = new WHMPlanningStore();
export default whmPlanningStore;
