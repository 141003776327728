import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import userStore from "../../../Stores/UserStore";
import moment from "moment";
import TextInputSTD from "../../../Components/Inputs/STD/TextInputSTD";
import { FlexboxGrid, HelpBlock, Badge } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import NumbInputSTD from "../../../Components/Inputs/STD/NumbInputSTD";
import Col3 from "../../../Components/Columns/Col3";
import TimePickerSTD from "../../../Components/Inputs/STD/TimePickerSTD";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import { message } from "antd";
import Div14 from "../../../Components/Dividers/Div14";
import { Calendar } from "rsuite";
import DatePickerSTD from "../../../Components/Inputs/STD/DatePickerSTD";

const instance = axios.create({ baseURL: baseURL });

class WHMNewPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      pallets: 0,
      trucks: 3,
      dailytrips: 12,
      palletspertruck: 20,
      offdays: [],
      minutestoload: 30,
      minutestounload: 30,
      minutestotransit: 10,
      starttime: moment().startOf("day").add(7, "hours"),
      endtime: moment().startOf("day").add(17, "hours"),
      startbreak: moment().startOf("day").add(12, "hours"),
      endbreak: moment().startOf("day").add(13, "hours"),
      startday: moment().startOf("day").add(13, "hours"),
      user: userStore.user._id,
    };
    this.onHide = this.onHide.bind(this);
    this.changeVal = this.changeVal.bind(this);
    this.postNewPlan = this.postNewPlan.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.addDate = this.addDate.bind(this);
  }
  addDate(date) {
    let found = false;
    let offdays = [...this.state.offdays];
    let newoffs = [];
    for (let i = 0; i < offdays.length; i++) {
      if (
        moment(date).format("DD-MMM-YY") !==
        moment(offdays[i]).format("DD-MMM-YY")
      ) {
        newoffs = [...newoffs, moment(offdays[i])];
      } else {
        found = true;
      }
    }
    if (!found) {
      newoffs = [...newoffs, moment(date).startOf("day")];
    }
    newoffs = newoffs.sort(function (a, b) {
      return a - b;
    });
    this.setState({ offdays: newoffs });
  }

  renderCell(date) {
    for (let i = 0; i < this.state.offdays.length; i++) {
      if (
        moment(date).format("DD-MMM-YY") ===
        moment(this.state.offdays[i]).format("DD-MMM-YY")
      ) {
        return <Badge />;
      }
    }

    return null;
  }
  postNewPlan = async () => {
    let data = this.state;

    this.setState({ loading: true });
    return instance
      .post(`/whm/plan/newplan`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.setState({ loading: false });
        this.onHide();
        message.success("New Plan Created");
      })
      .catch((err) => {
        this.setState({ loading: false });

        console.log(err);
        message.error("An Error Occured");
      });
  };
  changeVal(val, label) {
    this.setState({ [label]: val });
  }
  onHide() {
    this.setState({
      name: "",
      pallets: 0,
      trucks: 3,
      dailytrips: 12,
      palletspertruck: 20,
      minutestoload: 30,
      minutestounload: 30,
      minutestotransit: 10,
      offdays: [],
      starttime: moment().startOf("day").add(7, "hours"),
      endtime: moment().startOf("day").add(17, "hours"),
      startbreak: moment().startOf("day").add(12, "hours"),
      endbreak: moment().startOf("day").add(13, "hours"),
      startday: moment().startOf("day").add(13, "hours"),
    });
    this.props.onHide();
  }

  render() {
    let disabled = false;
    let errormsgs = [];
    if (this.state.name.length < 2) {
      errormsgs = [...errormsgs, "Please add a Name to your plan"];
    }
    let numberchecks = [
      { label: "pallets", title: "Number of Pallets" },
      { label: "trucks", title: "Number of Trucks" },
      { label: "dailytrips", title: "Daily Trips" },
      { label: "palletspertruck", title: "Pallets per Truck" },
      { label: "minutestoload", title: "Time to Load" },
      { label: "minutestounload", title: "Time to Unload" },
      { label: "minutestotransit", title: "Transit Time" },
    ];
    for (let i = 0; i < numberchecks.length; i++) {
      if (this.state[numberchecks[i].label] <= 0) {
        errormsgs = [...errormsgs, `Please add ${numberchecks[i].title}`];
      }
    }
    if (errormsgs.length > 0) disabled = true;

    return (
      <UnifiedModal
        show={this.props.show}
        onHide={this.onHide}
        title="Create a New Plan"
        footer={
          <ModalFooter
            icon={["close", "save"]}
            color={["red", "green"]}
            app={["ghost", "default"]}
            text={["Cancel", "Create New"]}
            oC1={() => this.onHide()}
            oC2={() => this.postNewPlan()}
            disabled2={disabled}
            loading1={this.state.loading}
            loading2={this.state.loading}
          />
        }
      >
        <FlexboxGrid>
          <Col2>
            <TextInputSTD
              title="Name"
              editVal={this.changeVal}
              val={this.state.name}
              label="name"
            />
            <br />
          </Col2>
          <Col2>
            <DatePickerSTD
              title="Start Day"
              editVal={this.changeVal}
              val={this.state.startday}
              label="startday"
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <NumbInputSTD
              min={0}
              title="Total Trucks"
              editVal={this.changeVal}
              val={this.state.trucks}
              label="trucks"
            />
            <br />
          </Col2>
          <Col2>
            <NumbInputSTD
              min={0}
              title="Total Pallets"
              editVal={this.changeVal}
              val={this.state.pallets}
              label="pallets"
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <NumbInputSTD
              min={0}
              title="Pallets Per Truck"
              editVal={this.changeVal}
              val={this.state.palletspertruck}
              label="palletspertruck"
            />
            <br />
          </Col2>
          <Col2>
            <NumbInputSTD
              min={0}
              title="Daily Trips"
              editVal={this.changeVal}
              val={this.state.dailytrips}
              label="dailytrips"
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col3>
            <NumbInputSTD
              min={0}
              title="Loading Time (min)"
              editVal={this.changeVal}
              val={this.state.minutestoload}
              label="minutestoload"
            />
            <br />
          </Col3>
          <Col3>
            <NumbInputSTD
              min={0}
              title="Transit Time (min)"
              editVal={this.changeVal}
              val={this.state.minutestotransit}
              label="minutestotransit"
            />
            <br />
          </Col3>
          <Col3>
            <NumbInputSTD
              min={0}
              title="Unloading Time (min)"
              editVal={this.changeVal}
              val={this.state.minutestounload}
              label="minutestounload"
            />
            <br />
          </Col3>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <TimePickerSTD
              title="Start Time"
              editVal={this.changeVal}
              val={this.state.starttime}
              label="starttime"
            />
            <br />
          </Col2>
          <Col2>
            <TimePickerSTD
              title="End Time"
              editVal={this.changeVal}
              val={this.state.endtime}
              label="endtime"
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <TimePickerSTD
              title="Break Start"
              editVal={this.changeVal}
              val={this.state.startbreak}
              label="startbreak"
            />
            <br />
          </Col2>
          <Col2>
            <TimePickerSTD
              title="Break End"
              editVal={this.changeVal}
              val={this.state.endbreak}
              label="endbreak"
            />
            <br />
          </Col2>
        </FlexboxGrid>
        <hr />
        <FlexboxGrid>
          <Col2>
            <div style={{ width: 280 }}>
              <Calendar
                compact
                bordered
                renderCell={this.renderCell}
                onSelect={(date) => this.addDate(date)}
              />
            </div>
          </Col2>
          <Col2>
            <Div14>Off Days</Div14>
            {this.state.offdays.map((od, index) => (
              <p>{moment(od).format("DD-MMM-YY")}</p>
            ))}
          </Col2>
        </FlexboxGrid>
        <hr />

        <HelpBlock style={{ color: "red", fontStyle: "italic" }}>
          {errormsgs.join(", ")}
        </HelpBlock>
      </UnifiedModal>
    );
  }
}

export default withRouter(observer(WHMNewPlan));
