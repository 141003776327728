import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";

class NextBackButton extends Component {
  render() {
    let step = this.props.step;
    let allsteps = this.props.allsteps;

    let nextBut = `Go to ${allsteps[step]} Details`;
    let backBut = `Back to ${allsteps[step - 2]} Details`;
    let disablenext = false;

    let aim = newAimStore.newaim;

    if (step === 1) {
      backBut = "Back to Account";
      if (aim.shipfrom === "") disablenext = true;
      if (aim.shipto === "") disablenext = true;
    } else if (step === 4) {
      nextBut = "Create Order";
    }
    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              icon={<Icon icon="left" />}
              color="red"
              block
              onClick={() => this.props.changeStep(-1)}
            >
              {backBut}
            </IconButton>
            <br />
          </Col2>
          <Col2>
            <IconButton
              icon={<Icon icon="right" />}
              color="green"
              block
              disabled={disablenext}
              onClick={() => {
                if (step === 4) {
                  this.props.postNew();
                } else {
                  this.props.changeStep(1);
                }
              }}
              placement="right"
            >
              {nextBut}
            </IconButton>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(NextBackButton);
