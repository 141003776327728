import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmPalletListStore from "../WHMStores/WHMPalletListStore";
import { Divider, Icon, IconButton } from "rsuite";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";
import Div14 from "../../../Components/Dividers/Div14";
import WHMPalletListTable from "./WHMPalletListTable";
import TabPane from "antd/lib/tabs/TabPane";
import { Tabs } from "antd";
import LoadBack from "../../../Components/Loaders/LoadBack";

class WHMFullPalletList extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, tab: "Prepared Pallets" };
    this.onHide = this.onHide.bind(this);
    this.onEdit = this.onEdit.bind(this);

    this.QTYColumn = this.QTYColumn.bind(this);
    this.CBMColumn = this.CBMColumn.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }
  componentDidMount() {
    whmPalletListStore.getPreparedPallets();
  }
  onRefresh(tab, override) {
    if (tab === "Prepared Pallets") {
      whmPalletListStore.getPreparedPallets(override);
    } else if (tab === "Loaded Pallets") {
      whmPalletListStore.getLoadedPallets(override);
    } else if (tab === "Unloaded Pallets") {
      whmPalletListStore.getUnLoadedPallets(override);
    } else if (tab === "Stacked Pallets") {
      whmPalletListStore.getStackedPallets(override);
    }
  }
  QTYColumn(cellData) {
    let data = cellData.data.qty;
    let qty = 0;
    if (data > 0) return PRKD(qty);
    return "-";
  }
  CBMColumn(cellData) {
    let data = cellData.data.cbm;
    let cbm = 0;
    if (data > 0) return PRKD(cbm);
    return "-";
  }
  onSelect(data) {
    if (data) {
      if (data.selectedRowsData) {
        if (data.selectedRowsData[0]) {
          whmSinglePalletStore.getPallet(data.selectedRowsData[0]._id);
          this.setState({ show: true, pallet: data.selectedRowsData[0] });
        }
      }
    }
  }
  onHide() {
    this.setState({ show: false });
    // whmSinglePalletStore.getPallet(data.selectedRowsData[0]._id);
  }
  onEdit(pallet) {
    this.props.history.push(`/whm/company/${pallet.client._id}`);
    this.onHide();
  }

  render() {
    let loading = whmPalletListStore.loading;
    return (
      <div>
        <Div14>
          Pallet List
          <Divider vertical />
          <IconButton
            circle
            size="xs"
            icon={<Icon icon="refresh" />}
            onClick={() => this.onRefresh(this.state.tab, true)}
            loading={
              loading.prepared ||
              loading.loadpallet ||
              loading.unloadpallet ||
              loading.stacked
            }
          />
        </Div14>

        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
            this.onRefresh(e);
          }}
        >
          <TabPane tab={"Prepared Pallets"} key={"Prepared Pallets"}>
            <LoadBack loading={whmPalletListStore.loading.prepared}>
              <WHMPalletListTable data={whmPalletListStore.prepared} prepared />
            </LoadBack>
          </TabPane>
          <TabPane tab={"Loaded Pallets"} key={"Loaded Pallets"}>
            <LoadBack loading={whmPalletListStore.loading.loadpallet}>
              <WHMPalletListTable
                data={whmPalletListStore.loadpallet}
                prepared
                loaded
              />
            </LoadBack>
          </TabPane>
          <TabPane tab={"Unloaded Pallets"} key={"Unloaded Pallets"}>
            <LoadBack loading={whmPalletListStore.loading.unloadpallet}>
              <WHMPalletListTable
                data={whmPalletListStore.unloadpallet}
                prepared
                loaded
                unloaded
              />
            </LoadBack>
          </TabPane>
          <TabPane tab={"Stacked Pallets"} key={"Stacked Pallets"}>
            <LoadBack loading={whmPalletListStore.loading.stacked}>
              <WHMPalletListTable
                data={whmPalletListStore.stacked}
                prepared
                loaded
                unloaded
                stacked
              />
            </LoadBack>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(WHMFullPalletList));
