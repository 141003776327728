import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Col, Button } from "rsuite";
import Div14 from "../../../../../Components/Dividers/Div14";
import DatePickerSTD from "../../../../../Components/Inputs/STD/DatePickerSTD";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import banklist from "../../../../../Static/Lists/financial/banklist";

import departmentcustomer from "../../../../../Static/Lists/financial/departmentcustomer";
import paymentOptionStore from "../../../../../Stores/AdditionalStores/PaymentOptionStore";
import invoiceStore from "../../../../../Stores/SideJobs/InvoiceStore";
import paymentStore from "../../../../../Stores/SideJobs/PaymentStore";

class PaymentEdit extends Component {
  componentDidMount() {
    paymentOptionStore.getOptions();
  }
  render() {
    let pay = paymentStore.newpayment;
    let opts = paymentOptionStore.options;
    let subtypeopts = [];
    let specificopts = [];

    for (let i = 0; i < opts.length; i++) {
      if (pay.type === opts[i].type) {
        subtypeopts.push(opts[i].subtype);
        if (pay.subtype === opts[i].subtype) {
          specificopts.push(opts[i].title);
        }
      }
    }
    subtypeopts = [...new Set(subtypeopts)];
    specificopts = [...new Set(specificopts)];

    let selects1 = [
      { title: "Type", label: "type", opts: ["Income", "Expense Variable"] },
      { title: "Sub type", label: "subtype", opts: subtypeopts },
      { title: "Specific", label: "specific", opts: specificopts },
      { title: "Cash", label: "cash", opts: ["Cash"] },
      {
        title: "Method",
        label: "method",
        opts: ["Cash", "Bank Transfer", "KNET", "Cheque", "Online Link"],
      },
      {
        title: "Account",
        label: "account",
        opts: banklist,
      },
    ];

    let selectinputs = selects1.map((sel, index) => (
      <Col md={12} sm={24} xs={24} style={{ padding: "1%" }} key={index}>
        <SelectInputSTD
          title={sel.title}
          val={pay[sel.label]}
          label={sel.label}
          size="large"
          opts={sel.opts}
          editVal={paymentStore.editPayment}
        />
      </Col>
    ));

    let invoices = invoiceStore.invoices.filter(
      (inv) => inv.status !== "Cancelled"
    );

    let invoicesect = invoices.map((inv, index) => (
      <Button
        key={index}
        style={{
          color: pay.invoiceids
            ? pay.invoiceids._id === inv._id
              ? "white"
              : "black"
            : "black",
          backgroundColor: pay.invoiceids
            ? pay.invoiceids._id === inv._id
              ? "black"
              : "white"
            : "white",
          border: "1px solid black",
        }}
        onClick={() =>
          paymentStore.editPayment(
            { _id: inv._id, invoiceNumId: inv.invoiceNumId },
            "invoiceids"
          )
        }
      >
        Invoice# {inv.invoiceNumId}-{moment(inv.createdAt).format("YYMMDDHH")}:{" "}
        ({PRKD(inv.total)} KD) {inv.title}
      </Button>
    ));

    return (
      <div>
        <Div14>Payment Details</Div14>
        <FlexboxGrid>
          <Col md={12} sm={24} xs={24} style={{ padding: "1%" }}>
            <NumbInputSTD
              title="Amount"
              editVal={paymentStore.editPayment}
              val={pay.amount}
              label="amount"
            />
          </Col>
          <Col md={12} sm={24} xs={24} style={{ padding: "1%" }}>
            <TextInputSTD
              title="Payment Ref."
              editVal={paymentStore.editPayment}
              val={pay.paymentref}
              label="paymentref"
            />
          </Col>
          <Col md={12} sm={24} xs={24} style={{ padding: "1%" }}>
            <DatePickerSTD
              title="Date"
              editVal={paymentStore.editPayment}
              val={pay.date}
              label="date"
            />
          </Col>
          <Col md={12} sm={24} xs={24} style={{ padding: "1%" }}>
            <SelectInputSTD
              title="Department"
              editVal={paymentStore.editPayment}
              val={pay.accounttype}
              label="accounttype"
              size="large"
              opts={departmentcustomer}
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ padding: "1%" }}>
            <TextInputSTD
              title="Party Name"
              editVal={paymentStore.editPayment}
              val={pay.partyname}
              label="partyname"
            />
          </Col>
          <Col md={24} sm={24} xs={24} style={{ padding: "1%" }}>
            <TextAreaInputSTD
              title="Remarks"
              editVal={paymentStore.editPayment}
              val={pay.remarks}
              label="remarks"
            />
          </Col>
          <Div14>Payment Options</Div14>
          <Col md={24} sm={24} xs={24} style={{ padding: "1%" }}>
            {selectinputs}
            {pay.type === "Income" && (
              <div>
                <Div14>Invoices ({invoices.length})</Div14>
                {invoicesect}
              </div>
            )}
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(PaymentEdit);
