import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../../../../../Components/Dividers/Div14.js";

import MainDelInvoice from "../../../../../../../../Components/PDFs/DelInvoices/MainDelInvoice.js.js";
import delInvoiceStore from "../../../../../../../../Stores/Financial/DelInvoiceStore";

class Step4 extends Component {
  render() {
    return (
      <div>
        <Div14>Step 4: Finalize Invoice</Div14>
        <MainDelInvoice inv={delInvoiceStore.newdelinvoice} />
      </div>
    );
  }
}

export default observer(Step4);
