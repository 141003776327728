import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import { FlexboxGrid, IconButton, Loader, Notification } from "rsuite";
import { Icon } from "rsuite";
import userStore from "../../../Stores/UserStore";
import { Result } from "antd";
import Col2 from "../../../Components/Columns/Col2";
import Div14 from "../../../Components/Dividers/Div14";

const instance = axios.create({ baseURL: baseURL });

class LinkAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: { name: "", clientNumId: "", _id: "" },
      comprequest: false,
      compadmin: false,
      expired: false,
      user: { company: {} },
      loading: false,
      linkused: false,
      token: "",
      success: false,
    };
    this.updateVal = this.updateVal.bind(this);
  }

  updateVal = async () => {
    this.setState({ loading: true });
    return instance
      .get(`/auth/referral/joincompany/${this.state.token}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.setState({
          loading: false,
          client: data.client,
          user: data.user,
          success: true,
        });
        console.log("Referral Link Fetched");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);

        Notification["error"]({
          title: `Error.`,
        });
      });
  };

  componentDidMount() {
    this.setState({ loading: true });
    instance
      .get(`/auth/referral/signedin/${this.props.match.params.tokenid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.setState({
          token: data.token,
          linkused: data.linkused,
          expired: data.expired,
          client: data.client,
          user: data.user,
          success: false,
          loading: false,
        });
        console.log("Referral Link Fetched");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);

        Notification["error"]({
          title: `Error.`,
        });
      });
  }

  render() {
    let client = this.state.client;
    let expired = this.state.expired;
    let linkused = this.state.linkused;
    let formerror = false;
    let clientdata;
    let user = this.state.user;
    let samecompany = false;
    if (user.company) {
      if (user.company._id) {
        if (client._id === user.company._id) {
          samecompany = true;
        }
      }
    }

    if (client.clientNumId === "" || expired) {
      formerror = true;
      clientdata = (
        <Result
          status="warning"
          title="Link has expired."
          subTitle="Kindly note the link has expired or is not available. Please contact your staff to share another link or our Easy Move office for support. You can use the below buttons to Login or Register."
        />
      );
    } else if (linkused) {
      formerror = true;
      clientdata = (
        <Result
          status="warning"
          title="Link has already been used."
          subTitle="Kindly note the link has already been used and is not available. Please contact your staff to share another link or our Easy Move office for support. You can use the below buttons to Login or Register."
        />
      );
    } else if (samecompany) {
      formerror = true;
      clientdata = (
        <>
          <Result
            status="success"
            title="User Already has Access"
            subTitle="Please note the signed in User already has access to the mentioned company."
          />
          <FlexboxGrid justify="center">
            <Col2>
              <div
                style={{
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px black",
                  margin: "2%",
                  padding: "2%",
                }}
              >
                <Div14>New Company</Div14>
                <b>ID: </b>
                {client.clientNumId}
                <br />
                <b>Name: </b>
                {client.name}
                <hr />
                <IconButton
                  icon={<Icon icon="close" />}
                  block
                  color="green"
                  onClick={() => this.props.history.push("/")}
                >
                  Go to Profile Page
                </IconButton>
              </div>
            </Col2>
          </FlexboxGrid>
        </>
      );
    } else if (user.company) {
      formerror = true;
      clientdata = (
        <>
          <Result
            status="warning"
            title="User Already Has a Client."
            subTitle="Kindly note that this user is already connected to a Client."
          />
          <FlexboxGrid>
            <Col2>
              <div
                style={{
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px black",
                  margin: "2%",
                  padding: "2%",
                }}
              >
                <Div14>Old Company</Div14>
                <b>ID: </b>
                {user.company.clientNumId}
                <br />
                <b>Name: </b>
                {user.company.name}
                <hr />
                <IconButton
                  icon={<Icon icon="close" />}
                  block
                  color="orange"
                  onClick={() => this.props.history.push("/")}
                >
                  Keep Old Company
                </IconButton>
              </div>
            </Col2>
            <Col2>
              <div
                style={{
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px black",
                  margin: "2%",
                  padding: "2%",
                }}
              >
                <Div14>New Company</Div14>
                <b>ID: </b>
                {client.clientNumId}
                <br />
                <b>Name: </b>
                {client.name}
                <hr />
                <IconButton
                  icon={<Icon icon="save" />}
                  block
                  color="green"
                  onClick={() => this.updateVal()}
                >
                  Replace with New Company
                </IconButton>
              </div>
            </Col2>
          </FlexboxGrid>
        </>
      );
    }
    if (this.state.success) {
      formerror = true;
      clientdata = (
        <>
          <Result
            status="success"
            title="User Access Successful"
            subTitle="Please Refresh to active changes."
          />
          <FlexboxGrid justify="center">
            <Col2>
              <div
                style={{
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px black",
                  margin: "2%",
                  padding: "2%",
                }}
              >
                <Div14>New Company</Div14>
                <b>ID: </b>
                {client.clientNumId}
                <br />
                <b>Name: </b>
                {client.name}
                <hr />
                <IconButton
                  icon={<Icon icon="close" />}
                  block
                  color="green"
                  onClick={() => this.props.history.push("/")}
                >
                  Go to Profile Page
                </IconButton>
              </div>
            </Col2>
          </FlexboxGrid>
        </>
      );
    }
    if (this.state.loading) {
      return <Loader center />;
    }
    if (formerror) {
      return clientdata;
    }

    return (
      <div>
        <Result
          status="info"
          title={`Link to ${this.state.client.name} - ID# ${this.state.client.clientNumId}`}
          subTitle="You have been invited to join the mentioned company. Kindly confirm with the buttons below."
        />
        <FlexboxGrid justify="center">
          <Col2>
            <div
              style={{
                borderRadius: "5px",
                boxShadow: "0px 0px 5px black",
                margin: "2%",
                padding: "2%",
              }}
            >
              <Div14>Company</Div14>
              <b>ID: </b>
              {client.clientNumId}
              <br />
              <b>Name: </b>
              {client.name}
              <hr />
              <IconButton
                icon={<Icon icon="save" />}
                block
                color="green"
                onClick={() => this.updateVal()}
              >
                Join Company
              </IconButton>
            </div>
          </Col2>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(LinkAccount));
