import { observer } from "mobx-react";
import React, { Component } from "react";
import SJRows from "./SJRows";

class SJDetails extends Component {
  render() {
    let inv = this.props.inv;
    let sjs = inv.subJobs;

    let sjrows = sjs.map((sj, index) => <SJRows sj={sj} key={index} />);

    return sjrows;
  }
}

export default observer(SJDetails);
