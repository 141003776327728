import { makeObservable, observable } from "mobx";
import axios from "axios";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class AccountStore {
  constructor() {
    this.accounts = [];
    this.expired = [];
    this.pending = [];

    this.newaccount = {};
    this.loading = {
      accounts: false,
      acorders: false,
      expired: false,
      pending: false,
    };
    this.loaded = {
      accounts: false,
      acorders: false,
      expired: false,
      pending: false,
    };

    makeObservable(this, {
      accounts: observable,
      pending: observable,
      expired: observable,

      loading: observable,
      loaded: observable,
    });
  }

  // FETCH
  getAccounts(override) {
    let loaded = false;
    if (this.loaded.accounts) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loaded.accounts = false;
      this.loading.accounts = true;
      this.expired = [];
      this.pending = [];
      this.loaded.expired = false;
      this.loaded.pending = false;

      return instance
        .get(`/ac/account/allaccounts`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          this.accounts = accounts;
          this.loaded.accounts = true;
          this.loading.accounts = false;
          console.log("Accounts Fetched");
        })
        .catch((err) => {
          this.loading.accounts = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getCustom(type, override) {
    let loaded = false;
    let status = "";
    if (type === "Expired") {
      if (this.loaded.expired) loaded = true;
      if (override) loaded = false;
      if (!loaded) status = "expired";
    } else if (type === "Pending") {
      if (this.loaded.pending) loaded = true;
      if (override) loaded = false;
      if (!loaded) status = "pending";
    } else {
      loaded = true;
    }
    if (!loaded) {
      this[status] = [];
      this.loaded[status] = false;
    }

    if (!loaded) {
      this.loading.accounts = true;
      return instance
        .get(`/ac/account/customaccountstatus/${status}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          this[status] = accounts;

          this.loaded[status] = true;
          this.loading.accounts = false;
          console.log(`${type} Accounts Fetched`);
        })
        .catch((err) => {
          this.loading.accounts = false;
          console.log(err);
          Notification["error"]({
            title: `Error Fetching ${type} Account.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:

  startLoading() {
    this.loading = true;
  }
}

const accountStore = new AccountStore();
export default accountStore;
