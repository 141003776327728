import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import Col2 from "../../../../../Components/Columns/Col2";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../Components/Modal/ModalFooter";
import OrderSummaryTable from "../../Modals/Supporting/OrderSummaryTable";
import userStore from "../../../../../Stores/UserStore";

class CancelJobModal extends Component {
  constructor(props) {
    super(props);
    this.state = { staffoveride: false, loading: false };
    this.cancelJob = this.cancelJob.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  onHide() {
    this.setState({ staffoveride: false });
    this.props.onHide();
  }
  cancelJob(free) {
    let finalfree = free;
    if (!free) {
      finalfree = this.state.staffoveride;
    }
    let type = "Cancelled";
    if (this.props.del.status === "Cancelled") type = "Reactivated";
    this.props.cancelJob(finalfree, type);
  }
  render() {
    let del = this.props.del;
    let timechg = this.props.timechg;
    let delCont = this.props.delCont;
    let account = this.props.account;

    let currenttime = moment(del.jobdate);
    let graceperiod = moment(del.jobdate).subtract(delCont.cancelhours, "hour");
    let freeofcharge = moment().isSameOrBefore(graceperiod);

    if (!freeofcharge & (delCont.cancelprice <= 0)) {
      freeofcharge = true;
    }

    if (del.status === "Cancelled") freeofcharge = true;

    let cancelnote = (
      <h5>
        Please note that the cancellation grace period has passed and cancelling
        this job would result in{" "}
        <span style={{ color: "red", fontSize: 20 }}>
          {PRKD(delCont.cancelprice)} KD.{" "}
        </span>
        Do you wish to proceed in cancellation?
      </h5>
    );

    let staffAction = (
      <FlexboxGrid justify="center" style={{ paddingTop: "20px" }}>
        <Col2>
          <IconButton
            icon={<Icon icon={this.state.staffoveride ? "circle-o" : "usd"} />}
            color={this.state.staffoveride ? "green" : "red"}
            appearance={this.state.staffoveride ? "ghost" : "primary"}
            onClick={() =>
              this.setState({ staffoveride: !this.state.staffoveride })
            }
            block
          >
            <b>
              {this.state.staffoveride
                ? "This job will be FREE for the client"
                : `The client will be charged a cancellation fee of ${PRKD(
                    delCont.cancelprice
                  )} KD`}
            </b>
          </IconButton>
        </Col2>
      </FlexboxGrid>
    );
    if (freeofcharge || del.status === "Cancelled") staffAction = <div />;

    let approvetext = "Yes, Cancel Job";
    if (!freeofcharge) {
      approvetext = `Yes, Cancel for ${PRKD(delCont.cancelprice)} KD`;
    }
    if (freeofcharge || this.state.staffoveride) {
      approvetext = "Yes, Cancel for Free";
    }
    if (del.status === "Cancelled") approvetext = "Yes, Re-Activate Job";

    return (
      <div>
        <UnifiedModal
          show={this.props.show}
          onHide={this.props.onHide}
          title={`Are you sure you want to ${
            del.status === "Cancelled" ? "Reactive" : "Cancel"
          } the Storage Request #${del.deliveryNumId} for ${account.name}`}
          footer={
            <ModalFooter
              icon={["undo", del.status === "Cancelled" ? "check" : "trash"]}
              color={["green", del.status === "Cancelled" ? "green" : "red"]}
              app={["ghost", "default"]}
              text={["No Changes", approvetext]}
              oC1={() => this.onHide()}
              oC2={() => this.cancelJob(freeofcharge)}
              loading1={this.props.load}
              loading2={this.props.load}
            />
          }
        >
          {del.status === "Cancelled" ? (
            <div>
              <h5>Re-Activate Job #{del.acOrderNumId}</h5>
              <OrderSummaryTable
                del={del}
                account={account}
                hideloc
                timechg={timechg}
              />
            </div>
          ) : (
            <p style={{ fontWeight: "bold", fontSize: 14 }}>
              Contract Cancellation Time:{" "}
              <span style={{ fontWeight: "normal" }}>
                {delCont.cancelhours} Hours before the Job
              </span>
              <br />
              Job Date / Time:{" "}
              <span style={{ fontWeight: "normal" }}>
                {moment(currenttime).format("DD-MMM-YY HH:mm")}
              </span>
              <br />
              Maximum Free Cancellation:{" "}
              <span style={{ fontWeight: "normal" }}>
                {moment(graceperiod).format("DD-MMM-YY HH:mm")}
              </span>
              <br />
              <span style={{ color: freeofcharge ? "black" : "red" }}>
                Time Remaining To Cancel:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {moment(graceperiod).fromNow()}
                </span>
              </span>
              <br />
            </p>
          )}
          <hr />
          {!freeofcharge && cancelnote}
          {userStore.user.staffaccount && staffAction}
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(CancelJobModal));
