import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ItemsChecklistPDF from "../../../../../../Components/PDFs/Accounts/ItemsList/ItemsChecklistPDF";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import { Icon, IconButton } from "rsuite";

class ItemsChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  render() {
    let sto = newStorageStore.oldorder;
    let account = newStorageStore.account;
    let inout = newStorageStore.delstoinout.inout;
    return (
      <div>
        <IconButton
          icon={<Icon icon={"list"} />}
          onClick={() => this.setState({ show: !this.state.show })}
          size="xs"
          color="green"
        >
          {this.state.show ? "Hide" : "Show"} Checklist
        </IconButton>
        {this.state.show && (
          <ItemsChecklistPDF sto={sto} client={account} type={inout} />
        )}
      </div>
    );
  }
}

export default withRouter(observer(ItemsChecklist));
