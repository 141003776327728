let heardofuslist = [
  "Google",
  "Google Maps",
  "Instagram",
  "Facebook",
  "Referral",
  "Returning Customer",
  "Referral",
  "Other",
  "Not Applicable",
];
export default heardofuslist;
