import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import moment from "moment";
import { Parsed } from "../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });

class WHMTruckStore {
  constructor() {
    this.trucks = [];
    this.trucktrips = [];
    this.movestats = {
      prepared: 0,
      loaded: 0,
      unloaded: 0,
      stacked: 0,
      itemsremaining: 0,
      palletlength: 0,
      trucktrips: 0,
      itemreq: 0,
      itempal: 0,
      itemunpal: 0,
    };
    this.selecteddate = moment().startOf("day");
    this.targets = {
      loading: 36,
      unloading: 20,
    };
    this.loading = {
      trucks: false,
      trucktrips: false,
      movestats: false,
    };
    this.loaded = {
      trucks: false,
      trucktrips: false,
      movestats: false,
    };

    makeObservable(this, {
      trucks: observable,
      trucktrips: observable,
      movestats: observable,
      selecteddate: observable,
      targets: observable,
      loading: observable,
      loaded: observable,
      singledaytrucktrips: computed,
      trucktripwithavg: computed,
      dailysummary: computed,
    });
  }

  // FETCH
  getTrucks(override) {
    let loaded;
    if (this.loading.trucks || this.loaded.trucks) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.trucks = true;
      return instance
        .get(`/whm/trucks/alltrucks`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          let trucks = [...data];

          for (let i = 0; i < trucks.length; i++) {
            trucks[i].lateloading = 0;
            trucks[i].lateunloading = 0;
            trucks[i].loading = 0;
            trucks[i].unloading = 0;
            trucks[i].totalloading = 0;
            trucks[i].totalunloading = 0;
            trucks[i].loaddelay = 0;
            trucks[i].unloaddelay = 0;
            trucks[i].avgloading = 0;
            trucks[i].avgunloading = 0;
            if (trucks[i].trucktrips.length > 0) {
              for (let j = 0; j < trucks[i].trucktrips.length; j++) {
                let tr = trucks[i].trucktrips[j];
                if (tr.status === "Loading Start") {
                } else {
                  trucks[i].loading += 1;
                  trucks[i].totalloading += moment(tr.loadingend).diff(
                    tr.createdAt,
                    "minutes"
                  );
                  trucks[i].loaddelay +=
                    moment(tr.loadingend).diff(tr.createdAt, "minutes") -
                    whmTruckStore.targets.loading;
                  if (
                    moment(tr.loadingend).diff(tr.createdAt, "minutes") >
                    whmTruckStore.targets.loading
                  ) {
                    trucks[i].lateloading += 1;
                  }

                  if (tr.status === "Unloading End") {
                    trucks[i].unloading += 1;
                    trucks[i].totalunloading += moment(tr.unloadingend).diff(
                      tr.unloadingstart,
                      "minutes"
                    );
                    trucks[i].unloaddelay +=
                      moment(tr.unloadingend).diff(
                        tr.unloadingstart,
                        "minutes"
                      ) - whmTruckStore.targets.unloading;
                    if (
                      moment(tr.unloadingend).diff(
                        tr.unloadingstart,
                        "minutes"
                      ) > whmTruckStore.targets.unloading
                    ) {
                      trucks[i].lateunloading += 1;
                    }
                  }
                }
              }
              trucks[i].avgloading =
                Math.round((10 * trucks[i].totalloading) / trucks[i].loading) /
                10;
              trucks[i].avgunloading =
                Math.round(
                  (10 * trucks[i].totalunloading) / trucks[i].unloading
                ) / 10;
            }
          }
          this.trucks = [...trucks];
          this.loading.trucks = false;
          this.loaded.trucks = true;
          console.log("WHM Trucks Fetched");
        })
        .catch((err) => {
          this.loading.trucks = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getMoveStats(override) {
    let loaded;
    if (this.loading.movestats || this.loaded.movestats) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.movestats = true;
      return instance
        .get(`/whm/trucktrip/latestsummary`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.movestats = data;
          this.loading.movestats = false;
          this.loaded.movestats = true;

          console.log("WHM Move Stats Fetched");
        })
        .catch((err) => {
          this.loading.movestats = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getTruckTrips(truckid, override) {
    let loaded;
    if (this.loading.trucktrips || this.loaded.trucktrips) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.trucktrips = true;
      return instance
        .get(`/whm/trucktrip/trucktripbytruckid/${truckid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.trucktrips = data;
          this.loading.trucktrips = false;
          this.loaded.trucktrips = true;
          console.log("WHM Truck Trips for a truck Fetched");
        })
        .catch((err) => {
          this.loading.trucktrips = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }
  getAllTruckTrips(override) {
    let loaded;
    if (this.loading.trucktrips || this.loaded.trucktrips) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.trucktrips = true;
      return instance
        .get(`/whm/trucktrip/alltrucktrips`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.trucktrips = data;
          this.loading.trucktrips = false;
          this.loaded.trucktrips = true;
          console.log("All WHM Truck Trips Fetched");
        })
        .catch((err) => {
          this.loading.trucktrips = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  // Functions
  changeDate(val) {
    whmTruckStore.selecteddate = moment(val).startOf("day");
  }

  // POST

  // COMPUTATIONS:
  get singledaytrucktrips() {
    return whmTruckStore.trucktrips.filter(
      (tr) =>
        moment(tr.createdAt).format("DD-MMM-YY") ===
        moment(whmTruckStore.selecteddate).format("DD-MMM-YY")
    );
  }

  get trucktripwithavg() {
    let data = [...whmTruckStore.singledaytrucktrips];

    for (let i = 0; i < data.length; i++) {
      data[i].timetoload = 0;
      data[i].timetounload = 0;
      data[i].transit = 0;
      if (data[i].loadingend) {
        data[i].timetoload = moment(data[i].loadingend).diff(
          data[i].createdAt,
          "minutes"
        );
      }
      if (data[i].unloadingend) {
        data[i].timetounload = moment(data[i].unloadingend).diff(
          data[i].unloadingstart,
          "minutes"
        );
        data[i].transit = moment(data[i].unloadingstart).diff(
          data[i].loadingend,
          "minutes"
        );
      }
    }
    return whmTruckStore.trucktrips.filter(
      (tr) =>
        moment(tr.createdAt).format("DD-MMM-YY") ===
        moment(whmTruckStore.selecteddate).format("DD-MMM-YY")
    );
  }

  get dailysummary() {
    let output = {
      dailytrucktrip: this.trucktripwithavg.length,
      averageloading: 0,
      averageunloading: 0,
      palletsmoved: 0,
      cbmmoved: 0,
      t1pallet: 0,
      t2pallet: 0,
      t3pallet: 0,
      t1cbm: 0,
      t2cbm: 0,
      t3cbm: 0,
    };

    let totalload = 0;
    let totalunload = 0;
    let loadjobs = 0;
    let unloadjobs = 0;
    for (let i = 0; i < this.trucktripwithavg.length; i++) {
      if (this.trucktripwithavg[i]) {
        if (this.trucktripwithavg[i].whmTruckNumId === 1) {
          output.t1pallet += this.trucktripwithavg[i].pallets.length;
          output.t1cbm += Parsed(this.trucktripwithavg[i].cbm);
        } else if (this.trucktripwithavg[i].whmTruckNumId === 2) {
          output.t2pallet += this.trucktripwithavg[i].pallets.length;
          output.t2cbm += Parsed(this.trucktripwithavg[i].cbm);
        } else if (this.trucktripwithavg[i].whmTruckNumId === 3) {
          output.t3pallet += this.trucktripwithavg[i].pallets.length;
          output.t3cbm += Parsed(this.trucktripwithavg[i].cbm);
        }
      }
      if (this.trucktripwithavg[i].status !== "Loading") {
        loadjobs += 1;
        totalload += moment(this.trucktripwithavg[i].loadingend).diff(
          this.trucktripwithavg[i].createdAt,
          "minutes"
        );
        if (this.trucktripwithavg[i].status === "Unloading End") {
          unloadjobs += 1;
          totalunload += moment(this.trucktripwithavg[i].unloadingend).diff(
            this.trucktripwithavg[i].unloadingstart,
            "minutes"
          );
        }
      }
    }

    output.averageloading = Math.round((10 * totalload) / loadjobs) / 10;
    output.averageunloading = Math.round((10 * totalunload) / unloadjobs) / 10;
    output.palletsmoved = output.t1pallet + output.t2pallet + output.t3pallet;
    output.cbmmoved = output.t1cbm + output.t2cbm + output.t3cbm;

    return output;
  }
}

const whmTruckStore = new WHMTruckStore();
export default whmTruckStore;
