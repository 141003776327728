import React, { Component } from "react";

import DataGrid, {
  Column,
  Grouping,
  GroupItem,
  Summary,
} from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/data-grid";
import { FilterRow } from "devextreme-react/data-grid";
import { ColumnFixing } from "devextreme-react/data-grid";

import { observer } from "mobx-react";

import { Parsed, PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import userStore from "../../../../../Stores/UserStore";

class ItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = { autoExpandAll: true };
  }

  MissingCell(cellData) {
    let diff = PRKD(
      Parsed(cellData.data.quantity) - Parsed(cellData.data.actualquantity)
    );
    let text = "";
    if (diff > 0) text = `${diff} Missing`;
    if (diff < 0) text = `${-1 * diff} Extra`;
    return text;
  }
  Dimension(cellData) {
    return `${PRKD(cellData.data.itemlength / 100)} m x ${PRKD(
      cellData.data.itemwidth / 100
    )} m x ${PRKD(cellData.data.itemheight / 100)} m`;
  }
  CBMPerItem(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.itemlength) *
        Parsed(cellData.data.itemwidth) *
        Parsed(cellData.data.itemheight)) /
      1000000;
    return PRKD(cbmperitem);
  }
  TotalCBM(cellData) {
    let cbmperitem =
      (Parsed(cellData.data.itemlength) *
        Parsed(cellData.data.itemwidth) *
        Parsed(cellData.data.actualquantity) *
        Parsed(cellData.data.itemheight)) /
      1000000;
    return PRKD(cbmperitem);
  }
  LocTags(cellData) {
    return cellData.data.acitem.loctags.join(", ");
  }
  render() {
    let toadd = this.props.toadd;

    let acitemmove = [];
    let singleitem = {};
    for (let i = 0; i < toadd.length; i++) {
      for (let k = 0; k < toadd[i].acitemmove.length; k++) {
        singleitem = { ...toadd[i].acitemmove[k] };
        singleitem.loc = ` ${i + 1}: ${toadd[i].city}`;
        acitemmove = [...acitemmove, { ...singleitem }];
      }
    }

    return (
      <div
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          textAlign: "center",
        }}
      >
        <DataGrid
          dataSource={acitemmove}
          keyExpr="acItemNumId"
          allowColumnReordering={true}
          allowColumnResizing={true}
          showBorders={true}
          rowAlternationEnabled
          style={{ fontSize: 10 }}
          searchPanel={false}
          hoverStateEnabled={true}
          noDataText="No Items Added"
          wordWrapEnabled={true}
        >
          <FilterRow visible={false} applyFilter />
          <GroupPanel visible={false} />
          <SearchPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <Scrolling mode="standard" showScrollbar="always" />

          <ColumnFixing enabled={true} />

          <Column caption="Item Details" alignment="center">
            <Column
              dataField="loc"
              caption="Location"
              alignment="center"
              groupIndex={0}
            />
            <Column
              dataField="acItemNumId"
              dataType="number"
              caption="Item ID"
              alignment="center"
              format={{ type: "fixedPoint", precision: 0 }}
              minWidth={80}
            />
            <Column
              dataField="itemname"
              caption="Item"
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="itemserialnumber"
              caption="Ser. No."
              alignment="center"
              minWidth={80}
            />
            <Column
              dataField="itemproductid"
              caption="Prod. No."
              alignment="center"
              minWidth={80}
            />
          </Column>
          <Column
            dataField="dimensions"
            caption="Dimensions"
            alignment="center"
            minWidth={140}
            cellRender={this.Dimension}
          />
          <Column
            dataField="actualquantity"
            dataType="number"
            caption="Quantity"
            alignment="center"
            format={{ type: "fixedPoint", precision: 0 }}
            minWidth={80}
          />
          {this.props.showmissing && (
            <Column caption="Quantity Difference" alignment="center">
              <Column
                dataField="quantity"
                dataType="number"
                caption="Original Quantity"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                minWidth={80}
              />
              <Column
                dataType="number"
                caption="Missing Items"
                alignment="center"
                minWidth={80}
                cellRender={this.MissingCell}
              />
            </Column>
          )}
          <Column caption="CBM Calcs" alignment="center">
            <Column
              dataField="cbmperitem"
              dataType="number"
              caption="CBM Per Item"
              alignment="center"
              minWidth={80}
              cellRender={this.CBMPerItem}
            />
            <Column
              dataField="totalcbm"
              dataType="number"
              caption="Total CBM"
              alignment="center"
              minWidth={80}
              cellRender={this.TotalCBM}
            />
            {userStore.user.group !== "Company" && (
              <Column
                dataField="loctags"
                caption="Location"
                alignment="center"
                minWidth={80}
                cellRender={this.LocTags}
              />
            )}
          </Column>
          <Summary>
            <GroupItem
              column="actualquantity"
              summaryType="sum"
              displayFormat={"{0} Packages"}
            />
          </Summary>
        </DataGrid>
      </div>
    );
  }
}

export default observer(ItemsList);
