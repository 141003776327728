import moment from "moment";
import { TRC } from "./TimingRulesCalc";

function TimingCheck(delCont, deltime, reqtime) {
  let days = {
    0: "sun",
    1: "mon",
    2: "tue",
    3: "wed",
    4: "thu",
    5: "fri",
    6: "sat",
  };

  let hrs = {
    0: "Yes",
    1: "Yes",
    2: "Yes",
    3: "Yes",
    4: "Yes",
    5: "Yes",
    6: "Yes",
    7: "Yes",
    8: "Yes",
    9: "Yes",
    10: "Yes",
    11: "Yes",
    12: "Yes",
    13: "Yes",
    14: "Yes",
    15: "Yes",
    16: "Yes",
    17: "Yes",
    18: "Yes",
    19: "Yes",
    20: "Yes",
    21: "Yes",
    22: "Yes",
    23: "Yes",
    sun: "Yes",
    mon: "Yes",
    tue: "Yes",
    wed: "Yes",
    thu: "Yes",
    fri: "Yes",
    sat: "Yes",
  };
  if (delCont.excludedhours) {
    hrs = delCont.excludedhours;
  }
  let current = moment();
  if (reqtime) current = moment(reqtime);
  let exp = false;
  let afh = false;

  let express = TRC(delCont, true, false, current);
  let earliest = TRC(delCont, false, false, current);
  let earliestaf = TRC(delCont, false, true, current);

  if (moment(deltime) >= moment(earliest)) {
  } else if (moment(deltime) >= moment(express)) {
    exp = true;
  } else if (moment(deltime) >= moment(earliestaf)) {
    afh = true;
  } else {
    exp = true;
    afh = true;
  }

  let day = moment(deltime).day();
  let curhr = moment(deltime).hour();
  let dayind;
  if (days[day]) {
    if (hrs[days[day]]) {
      dayind = hrs[days[day]];
    }
  }
  if (dayind === "After" || hrs[curhr] === "After") {
    afh = true;
  }

  return [exp, afh];
}

export { TimingCheck };
