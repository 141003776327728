import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Button, FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import hrAttendanceStore from "../../../../Stores/HR/HRAttendanceStore";
import DataGrid, {
  Column,
  HeaderFilter,
  Selection,
} from "devextreme-react/data-grid";
import { GroupPanel, Scrolling } from "devextreme-react/data-grid";

import { ColumnChooser, Item, Toolbar } from "devextreme-react/data-grid";
import { ColumnFixing, Export, Search } from "devextreme-react/data-grid";

import moment from "moment";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import Div14 from "../../../../Components/Dividers/Div14";

import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";

import MonthPickerSTU from "../../../../Components/Inputs/Updatable/MonthPickerSTU";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import { Alert } from "antd";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

const instance = axios.create({ baseURL: baseURL });

class StaffAttendanceApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showlock: false,
      loading: false,
      statusloading: false,
    };
    this.SingleDate = this.SingleDate.bind(this);
    this.editVal = this.editVal.bind(this);
    this.onHide = this.onHide.bind(this);
    this.updateAttTime = this.updateAttTime.bind(this);
    this.updateMonthLock = this.updateMonthLock.bind(this);
    this.onExporting = this.onExporting.bind(this);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");
    console.log("Testing");
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `Stafff Monthly Attendance.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  editVal(val, label) {
    this.setState({ [label]: val });
  }
  onHide() {
    this.setState({ show: false, showlock: false });
  }
  componentDidMount() {
    hrAttendanceStore.getStaffAttendance();
  }
  updateAttTime = async () => {
    let eatt = hrAttendanceStore.esingleatt;
    this.setState({ loading: true });
    let data = { status: eatt.status };

    let url = `/hr/attendanceinout/editsignin/${eatt._id}`;
    if (eatt._id === "Missing") {
      url = `/hr/attendanceinout/newmanualattendance/${eatt.staffid}`;
      data.date = moment.utc(eatt.date).format("DD-MMM-YY");
    }

    return instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((orders) => {
        this.setState({ loading: false });
        hrAttendanceStore.getStaffAttendance(true);
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `Error Updating Attendance.`,
        });
      });
  };
  updateMonthLock = async (newstat) => {
    let eatt = hrAttendanceStore.esingleatt;
    this.setState({ loading: true });
    let status = "Locked";
    if (newstat) status = newstat;
    let data = { date: moment.utc(hrAttendanceStore.date), status: status };

    let url = `/hr/attendanceinout/newlockedmonth/${eatt._id}`;

    return instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((orders) => {
        this.setState({ loading: false });
        hrAttendanceStore.getStaffAttendance(true);
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `Error Updating Attendance.`,
        });
      });
  };

  SingleDate(cellData) {
    let colind = cellData.columnIndex - 1;
    if (colind < 10) {
      colind = `0${colind}`;
    }

    colind = `${colind}`;
    let color = "red";
    let status = "";

    if (cellData.data) {
      if (cellData.data[colind]) {
        if (cellData.data[colind].status) {
          status = cellData.data[colind].statusshort;
          if (status === "P") color = "green";
          if (status === "E") color = "grey";
          if (status === "A") color = "red";
          if (status === "M") color = "red";
          if (status === "L") color = "orange";
          if (status === "N") color = "orange";
        }
      }
    }

    return (
      <>
        <Button
          color={color}
          size="xs"
          onClick={() => {
            hrAttendanceStore.selectAttendance(
              cellData.data[colind],
              cellData.data.name,
              cellData.columnIndex,
              cellData.data.staff._id
            );
            this.setState({ show: true });
          }}
        >
          {status}
        </Button>
      </>
    );
  }
  render() {
    let singleatt = hrAttendanceStore.singleattendance;
    let esingleatt = hrAttendanceStore.esingleatt;
    let disableinput = hrAttendanceStore.lockedApprovalMonth;
    let disabled = disableinput;

    let datetext = moment.utc(hrAttendanceStore.date).format("MMM-YY");

    let disableapproval = false;
    if (moment().isBefore(moment(hrAttendanceStore.date).endOf("month"))) {
      disableapproval = true;
    }
    let adminoveride = false;
    if (hrAttendanceStore.lockedMonth) {
      if (hrAttendanceStore.lockedApprovalMonth) {
        if (userStore.user.admin) adminoveride = true;
      }
    }

    return (
      <LoadBack loading={hrAttendanceStore.loading.attendance}>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="Edit Attendance"
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Save"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateAttTime()}
              disabled2={disabled}
              loading2={this.state.loading}
              loading1={this.state.loading}
            />
          }
        >
          <h5 style={{ textAlign: "center" }}>
            {moment.utc(esingleatt.date).format("DD-MMM-YY")}
          </h5>
          <Div14>Status:</Div14>
          <FlexboxGrid justify="center">
            <Col2>
              <TextInputSTD
                title="Staff Name"
                val={singleatt.staffname}
                label={"staffname"}
                size="lg"
                editVal={hrAttendanceStore.editSingleAttendance}
              />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Status"
                val={esingleatt.status}
                label={"status"}
                size="large"
                editVal={hrAttendanceStore.editSingleAttendance}
                opts={[
                  "Present",
                  "Late",
                  "Off",
                  "Absent",
                  "Holiday",
                  "Medical",
                ]}
              />
            </Col2>
          </FlexboxGrid>
          <Div14>Sign In and Sign Out:</Div14>

          <FlexboxGrid>
            <Col2>
              <DatePickerSTD
                title="Sign In"
                format={"HH:mm"}
                picker="time"
                val={esingleatt.signInTime}
                label={"signInTime"}
                size="large"
                showMinute={true}
                editVal={() => console.log("Locked")}
              />
            </Col2>
            <Col2>
              <DatePickerSTD
                title="Sign Out"
                format={"HH:mm"}
                picker="time"
                val={esingleatt.signOutTime}
                label={"signOutTime"}
                size="large"
                showMinute={true}
                editVal={() => console.log("Locked")}
              />
            </Col2>
          </FlexboxGrid>
          <hr />
          <h4 style={{ textAlign: "center" }}>
            Calculated Worktime: {hrAttendanceStore.worktime} hours
          </h4>
          {hrAttendanceStore.lockstatus === "Open" && (
            <Alert
              message="This month is still not Sent for Approval. Please coordinate with Daily Attendance Focal Point."
              type="warning"
              showIcon
            />
          )}
          {hrAttendanceStore.lockstatus === "Locked" && (
            <Alert
              message="This month is Closed and Approved. Please coordinate with IT for changes."
              type="warning"
              showIcon
            />
          )}
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showlock}
          onHide={this.onHide}
          title="Confirm Attendance"
          footer={
            <ModalFooter
              icon={["close", "lock"]}
              color={["red", "yellow"]}
              app={["ghost", "default"]}
              text={["Cancel", "Finalize Attendance"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateMonthLock()}
              loading2={this.state.loading}
              loading1={this.state.loading}
            />
          }
        >
          <h3>
            Are you sure you want to Confirm the Attendance Month of{" "}
            {moment(hrAttendanceStore.date).format("MMMM YYYY")} for Approval?
            You will no longer be able to edit the attendance for this month and
            any changes will require IT.
          </h3>
        </UnifiedModal>
        <FlexboxGrid justify="center">
          <Col2>
            <MonthPickerSTU
              val={hrAttendanceStore.edate}
              org={hrAttendanceStore.date}
              label="startday"
              format={"MMM-YYYY"}
              title="Select Month"
              load={hrAttendanceStore.loading.attendance}
              editVal={hrAttendanceStore.changeDate}
              updateVal={() =>
                hrAttendanceStore.changeOrgDate(hrAttendanceStore.edate)
              }
            />
          </Col2>
        </FlexboxGrid>
        <Div14>
          {moment.utc(hrAttendanceStore.date).format("MMMM YYYY")} Attendance
          Approval
        </Div14>
        {hrAttendanceStore.lockstatus === "Open" && (
          <Alert
            message="This month is still not Sent for Approval. Please coordinate with Daily Attendance Focal Point."
            type="warning"
            showIcon
          />
        )}
        {hrAttendanceStore.lockstatus === "Locked" && (
          <Alert
            message="This month is Closed and Approved. Please coordinate with IT for changes."
            type="warning"
            showIcon
          />
        )}
        <DataGrid
          dataSource={hrAttendanceStore.attendance}
          keyExpr="name"
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled
          onExporting={this.onExporting}
          style={{ fontSize: 10 }}
          searchPanel={false}
          paging={false}
          hoverStateEnabled={true}
          groupPanel={true}
          onSelectionChanged={this.onSelect}
        >
          <Selection mode="single" />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />

          <Scrolling mode="standard" showScrollbar="always" />
          <ColumnChooser enabled={true} mode="select" />
          <ColumnFixing enabled={true} />
          <Column
            dataField="name"
            caption="Name"
            alignment="center"
            minWidth={80}
            fixed={true}
          >
            <HeaderFilter>
              <Search enabled={true} />
            </HeaderFilter>
          </Column>

          <Column
            dataField="staff.title"
            caption="Title"
            alignment="center"
            minWidth={80}
            fixed={true}
            groupIndex={0}
          />
          {hrAttendanceStore.dateList.map((date, index) => (
            <Column
              key={index}
              dataField={`${[date]}.status`}
              caption={`${moment(date + "-" + datetext, "DD-MMM-YY").format(
                "ddd"
              )} ${date}`}
              alignment="center"
              minWidth={60}
              cellRender={this.SingleDate}
              headerFilter={{ visible: false }}
            />
          ))}

          <Column
            dataField="empty"
            caption="Empty"
            alignment="center"
            minWidth={80}
          />
          <Column
            dataField="present"
            caption="Present"
            alignment="center"
            minWidth={80}
          />
          <Toolbar>
            <Item name="Refresh" location="after">
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => hrAttendanceStore.getStaffAttendance(true)}
              >
                Refresh
              </IconButton>
            </Item>
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
          </Toolbar>
          <Export enabled={true} />
        </DataGrid>
        <hr />
        <FlexboxGrid justify="center">
          {!hrAttendanceStore.lockedApprovalMonth && (
            <Col2>
              <IconButton
                block
                color="yellow"
                icon={<Icon icon="lock" />}
                disabled={disableapproval}
                onClick={() => this.setState({ showlock: true })}
              >
                Finalize Attendance
              </IconButton>
            </Col2>
          )}
          {adminoveride && (
            <Col2>
              <IconButton
                block
                color="red"
                icon={<Icon icon="lock" />}
                disabled={disableapproval}
                loading={this.state.loading}
                onClick={() => this.updateMonthLock("Pending Approval")}
              >
                Change Status to Pending Approval
              </IconButton>
            </Col2>
          )}
        </FlexboxGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(StaffAttendanceApproval));
