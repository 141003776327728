function addToProps(allprops, newprop) {
  let final = [...allprops];
  newprop.added = false;
  newprop = { ...newprop };

  final = [...final, newprop];

  return final;
}

function SJProposals(sjs, job) {
  let newprop = [];
  let prop = {};

  if (sjs.length > 0) {
    for (let i = 0; i < sjs.length; i++) {
      prop = {
        type: sjs[i].jobtype,
        title: "Local Move",
        typeid: sjs[i]._id,
        sjid: sjs[i]._id,
        ammount: 0,
        jobtype: sjs[i].jobtype,
        numId: -1,
      };
      prop.numId = sjs[i].sjNumId;

      if (sjs[i].jobtype === "LM") {
        prop.ammount = parseFloat(sjs[i].lm.lastprice);
        newprop = addToProps(newprop, prop);
      } else if (sjs[i].jobtype === "CC") {
        prop.title = "Custom Clearance";
        prop.ammount = parseFloat(sjs[i].cc.lastprice);
        newprop = addToProps(newprop, prop);
      } else if (sjs[i].jobtype === "IM") {
        if (sjs[i].im.direction === "Inbound") {
          prop.title = "Unloading and Unpacking";
          prop.ammount = parseFloat(sjs[i].im.cpunpack);
          newprop = addToProps(newprop, prop);
          prop.title = "Transportation";
          prop.ammount = parseFloat(sjs[i].im.cpintrans);
          newprop = addToProps(newprop, prop);
          prop.title = "Import Clearance";
          prop.ammount = parseFloat(sjs[i].im.cpimport);
          newprop = addToProps(newprop, prop);
          prop.title = "Ministry Approval";
          prop.ammount = parseFloat(sjs[i].im.cpministry);
          newprop = addToProps(newprop, prop);
          prop.title = "Inspection";
          prop.ammount = parseFloat(sjs[i].im.cpinspect);
          newprop = addToProps(newprop, prop);
        } else if (sjs[i].im.direction === "Outbound") {
          prop.title = "Packing and Loading";
          prop.ammount = parseFloat(sjs[i].im.cppack);
          newprop = addToProps(newprop, prop);
          prop.title = "Transportation";
          prop.ammount = parseFloat(sjs[i].im.cpouttrans);
          newprop = addToProps(newprop, prop);
          prop.title = "Export Clearance";
          prop.ammount = parseFloat(sjs[i].im.cpexport);
          newprop = addToProps(newprop, prop);
          prop.title = "Shipping";
          prop.ammount = parseFloat(sjs[i].im.cpship);
          newprop = addToProps(newprop, prop);
          prop.title = "Destination";
          prop.ammount = parseFloat(sjs[i].im.cpdest);
          newprop = addToProps(newprop, prop);
        }
      }

      for (let j = 0; j < sjs[i].addcharge.length; j++) {
        prop = {
          type: "Add Charge",
          title: sjs[i].addcharge[j].name,
          typeid: sjs[i].addcharge[j]._id,
          ammount: parseFloat(sjs[i].addcharge[j].price),
          sjid: sjs[i]._id,
          jobtype: sjs[i].jobtype,
        };
        prop.numId = sjs[i].sjNumId;
        newprop = addToProps(newprop, prop);
      }
      // for (let k = 0; k < sjs[i].receipts.length; k++) {
      //   prop = {
      //     type: "Receipt",
      //     title: sjs[i].receipts[k].name,
      //     typeid: sjs[i].receipts[k]._id,
      //     ammount: parseFloat(sjs[i].receipts[k].cprice),
      //     sjid: sjs[i]._id,
      //     jobtype: sjs[i].jobtype,
      //   };
      //   prop.numId = sjs[i][`${sjs[i].jobtype.toLowerCase()}NumId`];
      //   newprop = addToProps(newprop, prop);
      // }
    }
  }

  return newprop;
}

export { SJProposals };
