import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import TimelineDate from "./TimelineDate";

class DeliverySummary extends Component {
  render() {
    let del = this.props.del;
    let allsteps = [
      {
        title: "Requested",
        desc: moment(del.createdAt).format("DD-MMM-YY"),
        status: "finish",
      },
      {
        title: "Booked",
        status: del.bookeddate === undefined ? "wait" : "finish",
        desc:
          del.bookeddate === undefined
            ? ""
            : moment(del.bookeddate).format("DD-MMM-YY"),
      },
      {
        title: "Completed",
        status:
          del.status !== "Completed" || del.delivereddate === undefined
            ? "wait"
            : "finish",
        desc:
          del.status !== "Completed" || del.delivereddate === undefined
            ? ""
            : moment(del.delivereddate).format("DD-MMM-YY"),
      },
    ];
    if (del.status === "In Transit") {
      allsteps = [
        {
          title: "Requested",
          desc: moment(del.createdAt).format("DD-MMM-YY"),
          status: "finish",
        },
        {
          title: "Booked",
          status: del.bookeddate === undefined ? "wait" : "finish",
          desc:
            del.bookeddate === undefined
              ? ""
              : moment(del.bookeddate).format("DD-MMM-YY"),
        },
        {
          title: "In Transit",
          status: "finish",
          desc: "Currently In Progress",
        },
        {
          title: "Completed",
          status: "wait",
          desc: "",
        },
      ];
    }
    if (del.status === "Cancelled") {
      allsteps = [
        ...allsteps,
        {
          title: "Cancelled",
          desc: moment(del.canceldate).format("DD-MMM-YY"),
          status: "error",
        },
      ];
    }
    let step = 0;
    if (del.status === "Booked") step = 1;
    if (del.status === "In Transit") step = 2;
    if (del.status === "Completed") step = 2;
    if (del.status === "Cancelled") step = 3;

    return (
      <div>
        <TimelineDate steps={allsteps} step={step} />
      </div>
    );
  }
}

export default observer(DeliverySummary);
