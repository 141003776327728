import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import whmTruckTripStore from "../../WHMStores/WHMTruckTripStore";
import TruckTripSummary from "../TruckTripSummary";
import Div14 from "../../../../Components/Dividers/Div14";
import TruckTripPalletTable from "../TruckTripPalletTable";
import FlexboxButton from "../../../../Components/Modal/FlexboxButton";
import whmOldWarehouseStore from "../../WHMStores/WHMOldWarehouseStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import SelectAvailablePallet from "../SelectAvailablePallet";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import ModalFooter from "../../../../Components/Modal/ModalFooter";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import { message } from "antd";

import TruckTripNotFound from "../TruckTripNotFound";
import whmNewWarehouseStore from "../../WHMStores/WHMNewWarehouseStore";

import CountDownFunc from "../../../../Components/Counters/CountDownFunc";
import CountDownUnloading from "../../../../Components/Counters/CountDownUnloading";

const instance = axios.create({ baseURL: baseURL });

class UnloadingTruckTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showdelete: false,
      showcomplete: false,
      showcancel: false,
    };
    this.onHide = this.onHide.bind(this);
    this.deleteTruckTrip = this.deleteTruckTrip.bind(this);
    this.completeUnloading = this.completeUnloading.bind(this);
    this.cancelUnloading = this.cancelUnloading.bind(this);
  }
  deleteTruckTrip = async (trucktripid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/deletetrucktrip/${trucktripid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.props.history.push("/whm/newwarehouse");
        whmNewWarehouseStore.getPallets(true);
        whmNewWarehouseStore.getReadyTruckTrip(true);
        message.success(`Truck Trip Deleted`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };
  completeUnloading = async (trucktripid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/completeunloading/${trucktripid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.props.history.push("/whm/newwarehouse");
        whmNewWarehouseStore.getPallets(true);
        whmNewWarehouseStore.getReadyTruckTrip(true);
        message.success(`Truck Trip Unloaded`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };
  cancelUnloading = async (trucktripid) => {
    this.setState({ loading: true });
    await instance
      .put(`whm/trucktrip/cancelunloading/${trucktripid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.props.history.push("/whm/newwarehouse");
        whmNewWarehouseStore.getPallets(true);
        whmNewWarehouseStore.getReadyTruckTrip(true);
        message.success(`Unloading Cancelled`);
        this.onHide();

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };

  onHide() {
    this.setState({
      show: false,
      showdelete: false,
      showcomplete: false,
      showcancel: false,
    });
  }
  componentDidMount() {
    whmTruckTripStore.getTruckTrip(this.props.match.params.trucktrip);
  }

  render() {
    let data = whmTruckTripStore.trucktrip;
    let showdelete = true;
    let pallets = [];
    if (data) {
      if (data.pallets) {
        pallets = data.pallets;
        if (data.pallets.length > 0) showdelete = false;
      }
    }
    let whmtruck = [];
    if (data) {
      whmtruck = data.whmtruck;

      if (
        (data.status !== "Unloading Start") &
        !whmTruckTripStore.loading.trucktrip
      ) {
        return <TruckTripNotFound />;
      }
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.showdelete}
          onHide={this.onHide}
          title="Delete Truck Trip"
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.deleteTruckTrip(this.props.match.params.trucktrip)
              }
            />
          }
        >
          <h4>Are you sure you want to delete this Truck Trip?</h4>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showcomplete}
          onHide={this.onHide}
          title="Complete Unloading Job"
          footer={
            <ModalFooter
              icon={["close", "check"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes, Complete Unloading"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.completeUnloading(this.props.match.params.trucktrip)
              }
            />
          }
        >
          <h4>Are you sure you want to complete the Unloading?</h4>
          <h4>
            All the Pallets will be added into the Warehouse and cannot be
            undone.
          </h4>
        </UnifiedModal>
        <UnifiedModal
          show={this.state.showcancel}
          onHide={this.onHide}
          title="Cancel Unloading Job"
          footer={
            <ModalFooter
              icon={["close", "check"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Cancel Unloading"]}
              oC1={() => this.onHide()}
              oC2={() =>
                this.cancelUnloading(this.props.match.params.trucktrip)
              }
            />
          }
        >
          <h4>Are you sure you want to complete the Unloading?</h4>
        </UnifiedModal>
        <UnifiedModal show={this.state.show} onHide={this.onHide} footer={null}>
          <SelectAvailablePallet onHide={this.onHide} />
        </UnifiedModal>
        <br />
        <FlexboxGrid justify="end" style={{ marginRight: "5%" }}>
          <IconButton
            icon={<Icon icon="refresh2" />}
            color="orange"
            onClick={() => whmTruckTripStore.getTruckTrip(false, true)}
          >
            Refresh
          </IconButton>
        </FlexboxGrid>
        <TruckTripSummary
          truck={whmtruck}
          trucktrip={data}
          loading={whmTruckTripStore.loading.trucktrip}
        />
        <Div14>
          Truck Trip Pallet List <Divider vertical />
          <IconButton
            icon={<Icon icon="refresh" />}
            onClick={() =>
              whmTruckTripStore.getTruckTrip(
                this.props.match.params.trucktrip,
                true
              )
            }
            size="xs"
            circle
          />
        </Div14>
        <FlexboxButton
          icon="plus"
          text="Add a Pallet"
          color="green"
          app="ghost"
          oC={() => {
            this.setState({ show: true });
            whmOldWarehouseStore.getPallets(true);
          }}
        />
        <TruckTripPalletTable data={pallets} />
        <br />
        <div style={{ textAlign: "center" }}>
          <b>Unloading Time: </b>
          <CountDownUnloading starttime={data.unloadingstart} />
        </div>
        {showdelete ? (
          <FlexboxButton
            icon="trash"
            text="Delete Truck Trip"
            color="red"
            app="primary"
            oC={() => {
              this.setState({ showdelete: true });
            }}
          />
        ) : (
          <ModalFooter
            icon={["trash", "check-circle"]}
            color={["red", "green"]}
            app={["default", "default"]}
            text={["Cancel Unloading", "Complete Unloading"]}
            oC1={() => this.setState({ showcancel: true })}
            oC2={() => this.setState({ showcomplete: true })}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(UnloadingTruckTrip));
