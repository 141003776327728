import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import whmCompanyStore from "../WHMStores/WHMCompanyStore";
import Div14 from "../../../Components/Dividers/Div14";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col3 from "../../../Components/Columns/Col3";
import WHMItemsList from "./ItemSelection/WHMItemsList";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import { Statistic, message } from "antd";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import Col2 from "../../../Components/Columns/Col2";
import MaterialTable from "material-table";
import { PRKD } from "../../../Functions/Pricing/PRKDCalc";
import whmSinglePalletStore from "../WHMStores/WHMSinglePalletStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import Col4 from "../../../Components/Columns/Col4";

const instance = axios.create({ baseURL: baseURL });

class WHMEditPalletModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showadd: false,
      showdelete: false,
      loading: false,
      itempallet: { _id: "" },
    };
    this.onHide = this.onHide.bind(this);
    this.deletePalletItem = this.deletePalletItem.bind(this);
    this.addItemsToPallet = this.addItemsToPallet.bind(this);
  }
  onHide() {
    this.setState({
      showadd: false,
      showdelete: false,
      itempallet: { _id: "" },
    });
  }
  deletePalletItem = async (itempalletid) => {
    this.setState({ loading: true });
    let reset = false;
    let url = `/whm/palletitem/deleteitempallet/${itempalletid}`;
    if (whmSinglePalletStore.palletitems.length <= 1) {
      url = `/whm/palletitem/deleteitemandpallet/${itempalletid}`;
      reset = true;
    }
    return instance
      .put(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        if (reset) {
          whmCompanyStore.getSingleCompany(whmCompanyStore.company._id, true);
          this.props.onHide();
          message.success("Pallet Deleted");
        } else {
          message.success("Pallet Item Deleted");

          whmSinglePalletStore.getPallet(false, true);
        }
        this.setState({ loading: false });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });

        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };
  addItemsToPallet = async (palletitems, palletid) => {
    let data = palletitems;

    this.setState({ loading: true });
    return instance
      .put(`/whm/palletitem/newitems/${palletid}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        whmSinglePalletStore.getPallet(palletid, true);
        this.setState({ loading: false });
        this.onHide();
        message.success("New Pallet Items Added");
      })
      .catch((err) => {
        this.setState({ loading: false });

        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  render() {
    let company = whmCompanyStore.company;
    let columnnames = [
      { title: "ID", field: "acitem.acItemNumId", defaultSort: "desc" },
      { title: "Quantity", field: "quantity" },
      { title: "Name", field: "acitem.name" },
      { title: "Serial", field: "acitem.serialnumber" },
      {
        title: "CBM",
        field: "totalcbm",
        render: (rowData) => PRKD(rowData.totalcbm),
      },
    ];
    let itemdeets = {
      name: "",
      quantity: 0,
      acItemNumId: "",
      serialnumber: "",
    };
    let itpallet = this.state.itempallet;
    if (itpallet.acitem) {
      if (itpallet.acitem.name) {
        itemdeets = { ...itpallet.acitem };
        itemdeets.quantity = itpallet.quantity;
      }
    }

    return (
      <LoadBack loading={whmSinglePalletStore.loading.pallet}>
        <UnifiedModal
          title={`Delete Item  #${itemdeets.acItemNumId} from Pallet`}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes Delete"]}
              oC1={() => this.onHide()}
              oC2={() => {
                this.deletePalletItem(this.state.itempallet._id);
              }}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          show={this.state.showdelete}
          onHide={this.onHide}
        >
          <h3>Are you sure you want to delete this Item from this pallet?</h3>
          <h3>Item Name = {itemdeets.name}</h3>
          <h3>Item Serial# = {itemdeets.serialnumber}</h3>
          <h3>Quantity = {itemdeets.quantity}</h3>
          <hr />
          <h2 style={{ color: "red" }}>
            {whmSinglePalletStore.palletitems.length <= 1 &&
              "This is the last item for this Pallet. Deleting this item will delete the whole pallet."}
          </h2>
        </UnifiedModal>
        <UnifiedModal
          title={`Add New Items for Pallet  #${whmSinglePalletStore.pallet.whmPalletNumId}`}
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Yes Add Items"]}
              oC1={() => this.onHide()}
              oC2={() => {
                this.addItemsToPallet(
                  whmCompanyStore.singlePallet,
                  whmSinglePalletStore.pallet._id
                );
              }}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          show={this.state.showadd}
          onHide={this.onHide}
        >
          <h3>Items List:</h3>
          <WHMItemsList data={whmCompanyStore.unpalletized} />
        </UnifiedModal>
        <Div14>
          Edit Pallet #{whmSinglePalletStore.pallet.whmPalletNumId} for{" "}
          {company.name}
        </Div14>
        <FlexboxGrid>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Status"
                value={whmSinglePalletStore.pallet.status}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Companies"
                value={whmSinglePalletStore.palletstats.items}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Quantity"
                value={whmSinglePalletStore.palletstats.qty}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="CBM"
                value={whmSinglePalletStore.palletstats.cbm}
                precision={3}
                valueStyle={{ color: "#3f8600" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <hr />
        <FlexboxGrid>
          <Col3>
            <IconButton
              icon={<Icon icon={"close"} />}
              color="red"
              block
              onClick={() => this.props.onHide()}
            >
              Close
            </IconButton>
          </Col3>
          <Col3></Col3>
          <Col3>
            <IconButton
              icon={<Icon icon={"plus"} />}
              color="green"
              block
              onClick={() => {
                whmCompanyStore.getUnpalletized(
                  whmCompanyStore.company._id,
                  true
                );
                this.setState({ showadd: true });
              }}
            >
              Add New Item
            </IconButton>
          </Col3>
        </FlexboxGrid>
        <br />
        <FlexboxGrid>
          <Col2>Add Item</Col2>
        </FlexboxGrid>

        <MaterialTable
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={whmSinglePalletStore.pallet.itempallet}
          title={null}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            search: false,
            toolbar: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: false,
            padding: "dense",
          }}
          actions={[
            {
              icon: "delete",
              tooltip: "Delete Item",

              onClick: (event, rowData) =>
                this.setState({
                  showdelete: true,
                  itempallet: rowData,
                }),
            },
          ]}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMEditPalletModal));
