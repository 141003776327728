import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class DamageReportStore {
  constructor() {
    this.pendingdrs = [];
    this.closeddrs = [];
    this.loading = { pending: false, closed: false };
    this.loaded = { pending: false, closed: false };

    makeObservable(this, {
      pendingdrs: observable,
      closeddrs: observable,
      loading: observable,
      loaded: observable,
    });
  }

  // FETCH
  getPendingDamageReports(override) {
    let loaded = this.loaded.pending;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.pending = true;
      this.loaded.pending = false;

      return instance
        .get(`/ac/damagereport/pending`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((drs) => {
          this.pendingdrs = drs;

          this.loading.pending = false;
          this.loaded.pending = true;
          console.log("Pending Damage Reports Fetched");
        })
        .catch((err) => {
          this.loading.pending = false;
          this.loaded.pending = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Damage Reports.`,
          });
        });
    }
  }
  getClosedDamageReports(override) {
    let loaded = this.loaded.pending;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.pending = true;
      this.loaded.pending = false;

      return instance
        .get(`/ac/damagereport/pending`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((drs) => {
          this.pendingdrs = drs;

          this.loading.pending = false;
          this.loaded.pending = true;
          console.log("Pending Damage Reports Fetched");
        })
        .catch((err) => {
          this.loading.pending = false;
          this.loaded.pending = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Damage Reports.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  // ----- DATE ----
}

const damageReportStore = new DamageReportStore();
export default damageReportStore;
