import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import { message } from "antd";
import { Parsed, PRKD } from "../../../Functions/Pricing/PRKDCalc";
import { TRC } from "../../../Functions/Timing/TimingRulesCalc";
import { TimingCheck } from "../../../Functions/Timing/TimingCheck";
import zoneStore from "../../Admin/Lists/ZoneStore";

const instance = axios.create({ baseURL: baseURL });

class NewStorageStore {
  constructor() {
    this.account = { _id: "", name: "", clientNumId: "", createdAt: moment() };
    this.storCont = {};
    this.delCont = {
      remarks: "",
      maintype: "Fixed Per Trip",
      fixedweekly: 0,
      freeweeklytrips: 0,

      pricepertruck: 35,
      dropoffmult: 1.2,
      staffcount: 0,
      carcount: 1,
      maxcbmpertrip: 0,

      nongroundfloorprice: 10,

      cancelhours: 8,
      cancelprice: 40,
      timetoreq: 24,
      afterhourfee: 20,
      expresstime: 8,
      expressfee: 20,

      pricepertrip: {
        sun: 20,
        mon: 20,
        tue: 20,
        wed: 20,
        thu: 20,
        fri: 20,
        sat: 20,
      }, // Price Per Job
      zoneadd: {
        z1: 0,
        z2: 0,
        z3: 0,
        z4: 0,
        z5: 0,
      }, // Price Per Job

      excludedhours: {
        0: "No",
        1: "No",
        2: "No",
        3: "No",
        4: "No",
        5: "No",
        6: "After",
        7: "After",
        8: "Yes",
        9: "Yes",
        10: "Yes",
        11: "Yes",
        12: "Yes",
        13: "Yes",
        14: "Yes",
        15: "Yes",
        16: "Yes",
        17: "Yes",
        18: "After",
        19: "After",
        20: "After",
        21: "After",
        22: "No",
        23: "No",
        sun: "Yes",
        mon: "Yes",
        tue: "Yes",
        wed: "Yes",
        thu: "Yes",
        fri: "No",
        sat: "After",
      },
    };
    this.neworder = {
      status: "Requested",
      createdAt: moment().set({ second: 0 }),
      jobdate: moment().set({ second: 0 }),
      bookeddate: undefined,
      loading: false,
      canceldate: undefined,
      jobtype: "Storage In",
      emlocation: { ...this.storCont.emlocation },
      toaddress: [],
      orderoptions: [],
      attachments: [],
      services: {
        timetype: "",
        timeprice: 0,
        weekday: "",
        weekdayprice: 0,
        stincost: 0,
        stoutcost: 0,
        canctime: 0,
        canccost: 0,
      },
      addcharge: [],
      discount: [],
      trucktrack: null,
      charges: { totaljob: 0 },
    };
    this.oldorder = {
      status: "Requested",
      createdAt: moment().set({ second: 0 }),
      jobdate: moment().set({ second: 0 }),
      bookeddate: undefined,
      canceldate: undefined,
      jobtype: "Delivery In",
      emlocation: { ...this.storCont.emlocation },
      toaddress: [],
      orderoptions: [],
      attachments: [],
      services: {
        timetype: "",
        timeprice: 0,
        weekday: "",
        weekdayprice: 0,
        stincost: 0,
        stoutcost: 0,
        canctime: 0,
        canccost: 0,
      },
      addcharge: [],
      discount: [],
      trucktrack: null,
      charges: { totaljob: 0 },
    };
    this.locations = [];
    this.acitemmove = [];
    this.existingitems = [];
    this.existsingles = [];
    this.pastsingles = [];

    this.filter = "";

    this.pastitems = [];
    this.singleitemmove = { acitem: {} };
    this.loading = {
      account: false,
      neworder: false,
      items: false,
      past: false,
    };
    this.loaded = { items: false };

    makeObservable(this, {
      account: observable,
      storCont: observable,
      delCont: observable,
      neworder: observable,
      oldorder: observable,
      locations: observable,
      acitemmove: observable,
      singleitemmove: observable,
      existingitems: observable,
      existsingles: observable,
      pastsingles: observable,

      filter: observable,
      pastitems: observable,
      loading: observable,
      loaded: observable,
      orderstat: computed,
      timechgs: computed,
      delstoinout: computed,
      stopedit: computed,
      unselecteditems: computed,
      selecteditems: computed,
      unselectpast: computed,
      totalexisting: computed,
      delopts: computed,
    });
  }

  // FETCH
  getAccount(clientid, override) {
    let loaded = false;
    let clID = this.account._id;
    if (clientid) clID = clientid;
    if (clID === this.account._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.account = true;

      return instance
        .get(`/ac/account/getaccount/${clID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((account) => {
          this.account = account;
          this.storCont = account.storageContract;
          this.delCont = account.deliveryContract;
          this.locations = account.locations;
          this.loading.account = false;
          console.log("Accounts Fetched");
        })
        .catch((err) => {
          this.loading.account = false;
          this.account = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getSingleACOrder(acorderid, override) {
    let loaded = false;
    let oID = this.neworder._id;
    if (acorderid) oID = acorderid;
    if (oID === this.neworder._id) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.loading.neworder = true;

      return instance
        .get(`/ac/warehouse/singleacorder/${oID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((order) => {
          this.account = order.account;
          this.neworder = { ...order.order };

          this.oldorder = { ...order.order };
          this.delCont = order.account.deliveryContract;
          this.stoCont = order.account.storageContract;
          this.locations = order.account.locations;

          this.loading.neworder = false;
          console.log("Single AC Order Fetched");
        })
        .catch((err) => {
          this.loading.neworder = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getExistingItems(clientid, override, toaddressindex) {
    if (toaddressindex) this.selectExisting(toaddressindex);
    let loaded = false;
    let cId = this.account._id;
    if (clientid) cId = clientid;
    if (this.loaded.items) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.items = true;
      return instance
        .get(`/ac/itemmove/getexisting/${cId}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((items) => {
          this.existingitems = [...items];

          this.loaded.items = true;
          this.loading.items = false;
          if (toaddressindex) this.selectExisting(toaddressindex);
          console.log("Existing Items Fetched");
        })
        .catch((err) => {
          this.loaded.items = false;
          this.loading.items = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Existing Items.`,
          });
        });
    }
  }
  getPastItems(clientid, override, toaddressindex) {
    if (toaddressindex >= 0) this.selectPast(toaddressindex);
    let loaded = false;
    let cId = this.account._id;
    if (clientid) cId = clientid;
    if (this.loaded.past) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.items = true;
      return instance
        .get(`/ac/itemmove/getpast/${cId}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((items) => {
          this.pastitems = [...items];

          this.loaded.past = true;
          this.loading.items = false;
          if (toaddressindex >= 0) this.selectPast(toaddressindex);
          console.log("Past Items Fetched");
        })
        .catch((err) => {
          this.loaded.past = false;
          this.loading.items = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Past Items.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  get selecteditems() {
    let selected = [];
    let toadd = newStorageStore.neworder.toaddress;
    let singleitem = {};
    for (let i = 0; i < toadd.length; i++) {
      for (let k = 0; k < toadd[i].acitemmove.length; k++) {
        singleitem = { ...toadd[i].acitemmove[k] };
        singleitem.loc = ` ${i + 1}: ${toadd[i].city}`;
        selected = [...selected, { ...singleitem }];
      }
    }
    return selected;
  }

  get unselecteditems() {
    let unselect = [];

    let singleitem = {};
    let caught = false;
    for (let i = 0; i < newStorageStore.existingitems.length; i++) {
      caught = false;
      singleitem = { ...newStorageStore.existingitems[i] };
      for (let j = 0; j < newStorageStore.neworder.toaddress.length; j++) {
        for (
          let k = 0;
          k < newStorageStore.neworder.toaddress[j].acitemmove.length;
          k++
        ) {
          if (
            newStorageStore.existingitems[i].acItemNumId ===
            newStorageStore.neworder.toaddress[j].acitemmove[k].acItemNumId
          ) {
            caught = true;
            if (
              newStorageStore.neworder.jobtype === "Delivery Out" ||
              newStorageStore.neworder.jobtype === "Storage Out"
            ) {
              console.log("CAUGHT!!!!!!!!!");
              singleitem.onreserve += Parsed(
                newStorageStore.neworder.toaddress[j].acitemmove[k]
                  .actualquantity
              );
            }
          }
        }
      }
      if (!caught) unselect = [...unselect, { ...singleitem }];
    }

    return unselect;
  }
  get unselectpast() {
    let unselect = [];
    let found = false;
    for (let i = 0; i < this.pastitems.length; i++) {
      found = false;
      for (let j = 0; j < this.acitemmove.length; j++) {
        if (this.pastitems[i].acItemNumId === this.acitemmove[j].acItemNumId) {
          found = true;
        }
      }

      if (!found) unselect = [...unselect, this.pastitems[i]];
    }
    return unselect;
  }

  get totalexisting() {
    return [
      ...this.unselecteditems.filter((un) => Parsed(un.newquant) > 0),
      ...this.unselectpast.filter((un) => Parsed(un.newquant) > 0),
    ];
  }

  get stopedit() {
    if (newStorageStore.neworder.loading) {
      return true;
    }
    if (!userStore.user.staffaccount) {
      if (
        moment().isAfter(
          moment(newStorageStore.neworder.jobdate).subtract(2, "hours")
        )
      ) {
        return true;
      }
      if (
        newStorageStore.neworder.status === "Booked" ||
        newStorageStore.neworder.status === "In Transit" ||
        newStorageStore.neworder.status === "Completed"
      ) {
        return true;
      }
    }
    return false;
  }

  get delstoinout() {
    let delsto = "Delivery";
    let inout = "Out";
    if (
      this.neworder.jobtype === "Storage In" ||
      this.neworder.jobtype === "Storage Out"
    ) {
      delsto = "Storage";
    }
    if (
      this.neworder.jobtype === "Storage In" ||
      this.neworder.jobtype === "Delivery In"
    ) {
      inout = "In";
    }
    return { delsto: delsto, inout: inout };
  }

  get delopts() {
    let inout = this.delstoinout.inout;
    let allopts = this.delCont.options.filter(
      (opt) => opt.delstor === "Storage" || opt.delstor === "Both"
    );

    allopts = allopts.filter(
      (opt) => opt.inout === inout || opt.inout === "Both"
    );

    for (let i = 0; i < allopts.length; i++) {
      allopts[i].selected = false;

      for (let j = 0; j < this.neworder.orderoptions.length; j++) {
        if (this.neworder.orderoptions[j]._id === allopts[i]._id) {
          allopts[i].selected = true;
        }
      }
    }
    return allopts;
  }

  get orderstat() {
    let toadd = newStorageStore.neworder.toaddress;

    let itemcount = 0;
    let packagecount = 0;
    let totalcbm = 0;

    if (toadd.length > 0) {
      for (let i = 0; i < toadd.length; i++) {
        for (let j = 0; j < toadd[i].acitemmove.length; j++) {
          itemcount += 1;
          packagecount += Parsed(toadd[i].acitemmove[j].actualquantity);
          totalcbm +=
            (Parsed(toadd[i].acitemmove[j].itemlength) *
              Parsed(toadd[i].acitemmove[j].itemwidth) *
              Parsed(toadd[i].acitemmove[j].itemheight) *
              Parsed(toadd[i].acitemmove[j].actualquantity)) /
            1000000;
        }
      }
    }

    return {
      itemcount: itemcount,
      packagecount: packagecount,
      totalcbm: totalcbm,
    };
  }

  get timechgs() {
    let delCont = this.delCont;
    let storCont = this.storCont;
    let order = this.neworder;
    let inout = this.delstoinout.inout;
    let express = TRC(delCont, true, false, order.createdAt);
    let earliest = TRC(delCont, false, false, order.createdAt);
    let expressaf = TRC(delCont, true, true, order.createdAt);
    let earliestaf = TRC(delCont, false, true, order.createdAt);

    let hideexpress = false;
    let hideaf = false;
    let hideexpressaf = false;

    if (
      moment(earliest).format("DD-MMM-YY HH:mm A") ===
      moment(express).format("DD-MMM-YY HH:mm A")
    ) {
      hideexpress = true;
    }
    if (
      moment(earliest).format("DD-MMM-YY HH:mm A") ===
      moment(earliestaf).format("DD-MMM-YY HH:mm A")
    ) {
      hideaf = true;
    }

    if (
      moment(earliestaf).format("DD-MMM-YY HH:mm A") ===
        moment(expressaf).format("DD-MMM-YY HH:mm A") ||
      moment(express).format("DD-MMM-YY HH:mm A") ===
        moment(expressaf).format("DD-MMM-YY HH:mm A")
    ) {
      hideexpressaf = true;
    }

    let charges = [
      {
        title: "Storage In Charge",
        value: storCont.incharge,
        show: inout === "In",
        unit: "KD / CBM",
      },
      {
        title: "Storage Out Charge",
        value: storCont.outcharge,
        show: inout === "Out",
        unit: "KD / CBM",
      },
    ];

    let ppt = delCont.pricepertrip;

    let alldays = [
      { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
      { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
      { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
      { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
      { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
      { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
      { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
    ];

    let day = moment(order.jobdate).day();
    if (order.jobtype === "Delivery In" || order.jobtype === "Delivery Out") {
      charges = [
        ...charges,
        {
          title: alldays[day].title,
          value: alldays[day].price,
          show: PRKD(alldays[day].price) > 0,
          unit: "KD",
        },
      ];
    }

    let toadd = order.toaddress;

    for (let i = 0; i < toadd.length; i++) {
      if (i > 0) {
        charges = [
          ...charges,
          {
            title: `Multiple Location Charge (${
              PRKD(1000 * Parsed(delCont.dropoffmult)) / 10
            }%): Location ${i + 1}`,
            value: Parsed(alldays[day].price) * Parsed(delCont.dropoffmult),
            show:
              PRKD(Parsed(alldays[day].price) * Parsed(delCont.dropoffmult)) >
              0,
            unit: "KD",
          },
        ];
      }
    }

    let timcost2 = TimingCheck(delCont, order.jobdate, order.createdAt);

    let fasttime = [];
    if (timcost2[0] & timcost2[1] & !hideexpressaf) {
      fasttime = [
        {
          price: PRKD(
            Parsed(delCont.expressfee) + Parsed(delCont.afterhourfee)
          ),
          title: "Express + After Hour Delivery",
        },
      ];
    } else if (timcost2[1] & !hideaf) {
      fasttime = [
        {
          price: PRKD(delCont.afterhourfee),
          title: "After Hour Delivery",
        },
      ];
    } else if (timcost2[0] & !hideexpress) {
      fasttime = [
        {
          price: PRKD(delCont.expressfee),
          title: "Express Delivery",
        },
      ];
    }
    if (fasttime.length > 0) {
      charges = [
        ...charges,
        {
          title: `${fasttime[0].title} Charge`,
          value: fasttime[0].price,
          show: true,
          unit: "KD",
        },
      ];
    }
    charges = charges.filter((ch) => ch.show);

    let allcharges = [...charges];

    let loccharges = [];

    for (let i = 0; i < toadd.length; i++) {
      if (Parsed(toadd[i].zonecost) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Location ${i + 1}: Zone ${toadd[i].zonetype} (${
              toadd[i].city
            }) Delivery Charge`,
            value: toadd[i].zonecost,
            show: true,
            unit: "KD",
          },
        ];
      }
      if (Parsed(toadd[i].ngfchg) > 0) {
        loccharges = [
          ...loccharges,
          {
            title: `Location ${i + 1}: Non-Ground Floor Charge`,
            value: toadd[i].ngfchg,
            show: true,
            unit: "KD",
          },
        ];
      }
    }

    let totalloccharge = 0;
    if (loccharges) {
      for (let i = 0; i < loccharges.length; i++) {
        totalloccharge += Parsed(loccharges[i].value);
      }
    }

    let mainjobcost = 0;
    for (let i = 0; i < allcharges.length; i++) {
      if (allcharges[i].unit === "KD") {
        mainjobcost += Parsed(allcharges[i].value);
      } else if (allcharges[i].unit === "KD / CBM") {
        mainjobcost += Parsed(
          Parsed(allcharges[i].value) * this.orderstat.totalcbm
        );
      }
    }

    let totaladdchg = 0;
    if (order.addcharge) {
      for (let i = 0; i < order.addcharge.length; i++) {
        totaladdchg += Parsed(order.addcharge[i].price);
      }
    }

    let totalopts = 0;
    let totalcbm = this.orderstat.totalcbm;
    if (order.orderoptions) {
      for (let i = 0; i < order.orderoptions.length; i++) {
        if (order.orderoptions[i].type === "KD") {
          totalopts += Parsed(order.orderoptions[i].price);
        } else if (order.orderoptions[i].type === "KD / CBM") {
          totalopts += Parsed(
            Parsed(order.orderoptions[i].price) * Parsed(totalcbm)
          );
        }
      }
    }

    let finaljobcost =
      Parsed(mainjobcost) +
      Parsed(totalloccharge) +
      Parsed(totaladdchg) +
      Parsed(totalopts);

    if (order.status === "Cancelled") {
      console.log("CATUGHT");
      finaljobcost = order.services.canccost;
      totalopts = 0;
      totaladdchg = 0;
      totalloccharge = 0;
      mainjobcost = 0;
      allcharges = [
        {
          title: `Cancellation Charge`,
          value: finaljobcost,
          show: true,
          unit: "KD",
        },
      ];
      charges = [];
    }

    return {
      finaljobcost: finaljobcost,
      totalopts: totalopts,
      totaladdchg: totaladdchg,
      mainjobcost: mainjobcost,
      allcharges: allcharges,
      charges: charges,
      earliest: earliest,
      express: express,
      earliestaf: earliestaf,
      expressaf: expressaf,
      loccharges: loccharges,
      totalloccharge: totalloccharge,
    };
  }

  // FUNCTIONS:

  removeList(label, index) {
    let oldList = [...newStorageStore.oldorder[label]];
    oldList.splice(index, 1);
    newStorageStore.neworder[label] = [...oldList];
    newStorageStore.oldorder[label] = [...oldList];
  }
  newList(label, item) {
    newStorageStore.neworder[label] = [
      ...newStorageStore.neworder[label],
      { ...item },
    ];
    newStorageStore.oldorder[label] = [
      ...newStorageStore.oldorder[label],
      { ...item },
    ];
    newStorageStore.getSingleACOrder(false, true);
  }

  editListItem(label, index, itemlabel, itemval) {
    newStorageStore.neworder[label][index][itemlabel] = itemval;
    newStorageStore.oldorder[label][index][itemlabel] = itemval;
  }
  editAttachmentTitle(index, val) {
    newStorageStore.neworder.attachments[index].title = val;
  }

  addNewTo(to) {
    let newto = { ...to, service: {}, orderoptions: [], acitemmove: [] };
    newStorageStore.neworder.toaddress = [
      ...newStorageStore.neworder.toaddress,
      newto,
    ];
  }
  moveNewTo(index, type) {
    let oldorder = [...newStorageStore.neworder.toaddress];
    let removedArray = oldorder.splice(index, 1)[0];
    let newindex = index;
    if (type === "up") newindex -= 1;
    if (type === "down") newindex += 1;
    oldorder.splice(newindex, 0, removedArray);
    newStorageStore.neworder.toaddress = [...oldorder];
  }
  deleteTo(index) {
    let oldorder = [...newStorageStore.neworder.toaddress];
    let neworder = [];
    for (let i = 0; i < oldorder.length; i++) {
      if (i !== index) neworder = [...neworder, oldorder[i]];
    }
    newStorageStore.neworder.toaddress = [...neworder];
  }

  changeExisting(val, label, acItemNumId) {
    let found = false;
    for (let i = 0; i < newStorageStore.existsingles.length; i++) {
      if (newStorageStore.existsingles[i].acItemNumId === acItemNumId) {
        found = true;
        console.log("Found Existing");
        newStorageStore.existsingles[i].newquant = val;
        i = 10000;
      }
    }
    if (!found) {
      for (let i = 0; i < newStorageStore.pastsingles.length; i++) {
        if (newStorageStore.pastsingles[i].acItemNumId === acItemNumId) {
          console.log("Found Past");
          found = true;
          newStorageStore.pastsingles[i].newquant = val;
          i = 10000;
        }
      }
    }
  }

  selectExisting(toaddressindex) {
    let exists = [...this.existingitems];
    let final = [];
    let index = -1;

    for (let i = 0; i < exists.length; i++) {
      index = -1;
      if (this.neworder.toaddress[toaddressindex].acitemmove) {
        if (this.neworder.toaddress[toaddressindex].acitemmove.length > 0) {
          index = this.neworder.toaddress[toaddressindex].acitemmove.findIndex(
            (ex) => ex.acItemNumId === exists[i].acItemNumId
          );
        }
      }

      if (index < 0) final = [...final, { ...exists[i] }];
    }

    if (
      this.neworder.jobtype === "Delivery Out" ||
      this.neworder.jobtype === "Storage Out"
    ) {
      let toadd = this.neworder.toaddress;
      for (let i = 0; i < final.length; i++) {
        for (let j = 0; j < toadd.length; j++) {
          for (let k = 0; k < toadd[j].acitemmove.length; k++) {
            if (toadd[j].acitemmove[k].acItemNumId === final[i].acItemNumId) {
              final[i].onreserve += Parsed(toadd[j].acitemmove[k].newquant);
              final[i].currentstock -= Parsed(toadd[j].acitemmove[k].newquant);
            }
          }
        }
      }
    }

    this.existsingles = [...final];
  }
  selectPast(toaddressindex) {
    let past = [...this.pastitems];

    let final = [];
    let index = -1;

    for (let i = 0; i < past.length; i++) {
      index = -1;
      if (this.neworder.toaddress[toaddressindex].acitemmove) {
        if (this.neworder.toaddress[toaddressindex].acitemmove.length > 0) {
          index = this.neworder.toaddress[toaddressindex].acitemmove.findIndex(
            (ex) => ex.acItemNumId === past[i].acItemNumId
          );
        }
      }

      if (index < 0) final = [...final, { ...past[i] }];
    }

    this.pastsingles = [...final];
  }

  editOrder(val, label) {
    newStorageStore.neworder[label] = val;

    if (label === "jobdate") {
      let order = newStorageStore.neworder;
      let timcost2 = TimingCheck(newStorageStore.delCont, val, order.createdAt);
      let expfee = parseFloat(newStorageStore.delCont.expressfee);
      let affee = parseFloat(newStorageStore.delCont.afterhourfee);
      if (timcost2[0] & timcost2[1]) {
        order.services.timeprice = expfee + affee;
        order.services.timetype = "Express + After Hour Delivery";
      } else if (timcost2[0]) {
        order.services.timeprice = affee;
        order.services.timetype = "After Hour Delivery";
      } else if (timcost2[1]) {
        order.services.timeprice = expfee;
        order.services.timetype = "Express Delivery";
      } else {
        order.services.timeprice = 0;
        order.services.timetype = "";
      }
      let day = moment(order.jobdate).day();
      let ppt = newStorageStore.delCont.pricepertrip;
      let alldays = [
        { data: "sun", title: "Sunday Delivery Charge", price: ppt.sun },
        { data: "mon", title: "Monday Delivery Charge", price: ppt.mon },
        { data: "tue", title: "Tuesday Delivery Charge", price: ppt.tue },
        { data: "wed", title: "Wednesday Delivery Charge", price: ppt.wed },
        { data: "thu", title: "Thursday Delivery Charge", price: ppt.thu },
        { data: "fri", title: "Friday Delivery Charge", price: ppt.fri },
        { data: "sat", title: "Saturday Delivery Charge", price: ppt.sat },
      ];
      if (order.jobtype === "Delivery In" || order.jobtype === "Delivery Out") {
        if (alldays[day].price > 0) {
          console.log("CHARGE!!!!");
          order.services.weekday = alldays[day].title;
          order.services.weekdayprice = alldays[day].price;
        }
      }
      if (order.jobtype === "Delivery In" || order.jobtype === "Storage In") {
        order.services.stincost = newStorageStore.storCont.incharge;
        order.services.stoutcost = 0;
      }
      if (order.jobtype === "Delivery Out" || order.jobtype === "Storage Out") {
        order.services.stincost = 0;
        order.services.stoutcost = newStorageStore.storCont.outcharge;
      }
    }
  }

  selectItem(item, ind) {
    let newitem = { ...item };
    newitem.orgquant = item.actualquantity;
    newitem.index = ind;
    if (item.acItemNumId === "New")
      newitem.index = newStorageStore.singleitemmove.length;
    newStorageStore.singleitemmove = newitem;
  }
  editSingleItemMove(val, label) {
    newStorageStore.singleitemmove[label] = val;
  }
  replaceSingleItemMoveData(item, index) {
    newStorageStore.acitemmove[index] = item;
  }
  addNewItemMove() {
    console.log("Added");
    newStorageStore.acitemmove = [
      ...newStorageStore.acitemmove,
      { ...newStorageStore.singleitemmove },
    ];
  }
  addExistingItem(item, toaddressindex) {
    newStorageStore.neworder.toaddress[toaddressindex].acitemmove = [
      ...newStorageStore.neworder.toaddress[toaddressindex].acitemmove,
      { ...item },
    ];
  }
  addExistingItems(items, toaddressindex) {
    newStorageStore.neworder.toaddress[toaddressindex].acitemmove = [
      ...newStorageStore.neworder.toaddress[toaddressindex].acitemmove,
      ...items,
    ];
  }
  addBulkItemMoves(items, toaddressindex) {
    newStorageStore.neworder.toaddress[toaddressindex].acitemmove = [
      ...newStorageStore.neworder.toaddress[toaddressindex].acitemmove,
      [...items],
    ];
  }

  editService(val, label) {
    newStorageStore.neworder.services[label] = val;
  }
  updateService(label) {
    newStorageStore.oldorder.services[label] =
      newStorageStore.neworder.services[label];
  }

  editDR(val, label) {
    if (userStore.user.staffaccount) {
      newStorageStore.neworder.damagereport[label] = val;
    }
  }
  updateDR(label) {
    if (userStore.user.staffaccount) {
      newStorageStore.oldorder.damagereport[label] =
        newStorageStore.neworder.damagereport[label];
    }
  }

  updateZone(city) {
    let zoneind = zoneStore.zones.findIndex((zone) => zone.name === city);
    let zonecost = 0;

    let zonenum;
    if (zoneind >= 0) {
      zonenum = zoneStore.zones[zoneind].zone;

      zonecost = this.delCont.zoneadd[`z${zonenum}`];
    }
    this.neworder.services.zonecost = zonecost;
    this.neworder.services.zonetype = zonenum;
  }

  editLoc(val, label) {
    newStorageStore.neworder.location[label] = val;
    if (label === "city") {
      newStorageStore.updateZone(val);
    } else if (label === "floor") {
      if (val > 0 || val < 0) {
        newStorageStore.neworder.services.ngfchg =
          newStorageStore.delCont.nongroundfloorprice;
      } else {
        newStorageStore.neworder.services.ngfchg = 0;
      }
    }
  }

  addNewLoc(newloc) {
    newStorageStore.locations = [...newStorageStore.locations, newloc];
    newStorageStore.neworder.location.selected = newloc.name;
  }

  selectLoc(newloc) {
    newStorageStore.neworder.location = newloc;
    newStorageStore.updateZone(newloc.city);
    if (newloc.floor > 0 || newloc.floor > 0) {
      newStorageStore.neworder.services.ngfchg =
        newStorageStore.delCont.nongroundfloorprice;
    } else {
      newStorageStore.neworder.services.ngfchg = 0;
    }
  }

  addEditSingleItem(type, item, bulk, toaddressindex) {
    let newit;
    let olditems = [...this.neworder.toaddress[toaddressindex].acitemmove];
    if (type === "New") {
      newit = {
        acItemMoveNumId: "New",
        acItemNumId: "New",
        itemid: "New",
        itemname: item.itemname,
        itemserialnumber: item.itemserialnumber,
        itemproductid: item.itemproductid,
        itemlength: item.itemlength,
        itemwidth: item.itemwidth,
        itemheight: item.itemheight,
        quantity: item.quantity,
        actualquantity: item.actualquantity,
      };
      this.neworder.toaddress[toaddressindex].acitemmove = [...olditems, newit];

      // this.acitemmove = [...this.acitemmove, newit];
      if (!bulk) message.success(`${item.itemname} Added`);
    } else {
      olditems[item.index] = item;
      this.neworder.toaddress[toaddressindex].acitemmove = [...olditems];
      message.success(`${item.itemname} Edited`);
    }
  }

  removeItem(index, toaddressindex) {
    let newitems = [...this.neworder.toaddress[toaddressindex].acitemmove];
    newitems.splice(index, 1);
    this.neworder.toaddress[toaddressindex].acitemmove = [...newitems];
    message.success("Item Removed");
  }

  updateOption(option) {
    let order = { ...newStorageStore.neworder };

    let ind = order.orderoptions.findIndex((opt) => opt.desc === option.desc);
    if (ind < 0) {
      order.orderoptions = [...order.orderoptions, option];
    } else {
      let newopts = [];
      for (let i = 0; i < order.orderoptions.length; i++) {
        if (i !== ind) {
          newopts = [...newopts, order.orderoptions[i]];
        }
      }
      order.orderoptions = [...newopts];
    }
    newStorageStore.neworder = { ...order };
  }
  resetorderopts(oldopts) {
    newStorageStore.neworder.orderoptions = [...oldopts];
  }

  editAddCharge(val, label, index) {
    newStorageStore.neworder.addcharge[index][label] = val;
  }
  editDiscount(val, label, index) {
    newStorageStore.neworder.discount[index][label] = val;
  }

  newAddCharge() {
    newStorageStore.neworder.addcharge = [
      ...newStorageStore.neworder.addcharge,
      { name: "", price: 0 },
    ];
  }
  removeAddCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newStorageStore.neworder[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newStorageStore.neworder[label][i]];
      }
    }
    newStorageStore.neworder[label] = [...newchgs];
  }
  removeOrgCharge(index, label) {
    let newchgs = [];
    for (let i = 0; i < newStorageStore.oldorder[label].length; i++) {
      if (i !== index) {
        newchgs = [...newchgs, newStorageStore.oldorder[label][i]];
      }
    }
    newStorageStore.oldorder[label] = [...newchgs];
  }

  updateOrgService(label) {
    let newservice = { ...newStorageStore.neworder.services };
    newStorageStore.oldorder.services[label] = newservice[label];
  }
  updateOrgOrder(label) {
    let neworder = { ...newStorageStore.neworder };
    newStorageStore.oldorder[label] = neworder[label];
  }
  updateOrgOrdOptions(opts) {
    newStorageStore.oldorder.orderoptions = [...opts];
  }
  updateOrgAddChg(val, label, index) {
    newStorageStore.oldorder[label] = val;
  }
  updateOrgAddChgNew(charge, label) {
    newStorageStore.oldorder[label] = [
      ...newStorageStore.oldorder[label],
      charge,
    ];
    newStorageStore.neworder[label] = [
      ...newStorageStore.neworder[label],
      charge,
    ];
  }
  updateOrgLoc(label) {
    let neworder = { ...newStorageStore.neworder };
    newStorageStore.oldorder.location[label] = neworder.location[label];
  }

  changefilter(val) {
    this.filter = val;
  }

  createNewOrder() {
    this.loaded.items = false;
    this.acitemmove = [];
    this.filter = "";
    this.neworder = {
      jobtype: this.neworder.jobtype,
      loading: false,
      status: "Requested",
      createdAt: moment().set({ second: 0 }),
      jobdate: moment().set({ second: 0 }),
      bookeddate: undefined,
      deliverdate: undefined,
      canceldate: undefined,
      emlocation: { ...this.storCont.emlocation },

      toaddress: [],

      orderoptions: [],

      services: {
        timetype: "",
        timeprice: 0,
        weekday: "",
        weekdayprice: 0,
        stincost: 0,
        stoutcost: 0,
        canctime: 0,
        canccost: 0,
      },
      addcharge: [],
      discount: [],
      trucktrack: null,
    };
    if (
      this.neworder.jobtype === "Storage In" ||
      this.neworder.jobtype === "Storage Out"
    ) {
      if (this.storCont.emlocation) {
        this.neworder.toaddress = [
          {
            type: this.storCont.emlocation.type,
            city: this.storCont.emlocation.city,
            floor: this.storCont.emlocation.floor,
            address: this.storCont.emlocation.address,
            name: this.storCont.emlocation.name,
            phone: this.storCont.emlocation.phone,
            zonetype: "",
            zonecost: 0,
            ngfchg: 0,
            acitemmove: [],
          },
        ];
      } else {
        this.neworder.toaddress = [];
      }
    }
  }

  startLoading() {
    this.loading = true;
  }
}

const newStorageStore = new NewStorageStore();
export default newStorageStore;
