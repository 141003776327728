import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import { Col, FlexboxGrid } from "rsuite";

import newACCOrderStore from "../../../../../Stores/AccountUpdated/ACC/NewACCStore";
import DateToggleSTU from "../../../../../Components/Inputs/Updatable/DateToggleSTU";
import DateInputSTU from "../../../../../Components/Inputs/Updatable/DateInputSTU";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let newacc = newACCOrderStore.newacc;
    let oldacc = newACCOrderStore.oldacc;
    let load = this.props.load;
    return (
      <div>
        <Div14>Main Dates:</Div14>
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24}>
            <DateInputSTU
              val={newacc.vesselarrival}
              org={oldacc.vesselarrival}
              label="vesselarrival"
              load={load.vesselarrival}
              title="Vessel Arrival Date"
              format={"DD-MMM-YY"}
              picker="date"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
              size="sm"
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <DateToggleSTU
              valtoggle={newacc.doccol}
              orgtoggle={oldacc.doccol}
              val={newacc.doccoldate}
              org={oldacc.doccoldate}
              label="doccol"
              load={load.doccol}
              title="Document Collection"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postDate}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <DateToggleSTU
              valtoggle={newacc.bayanissue}
              orgtoggle={oldacc.bayanissue}
              val={newacc.bayanissuedate}
              org={oldacc.bayanissuedate}
              label="bayanissue"
              load={load.bayanissue}
              title="Bayan Issued"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postDate}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <DateToggleSTU
              valtoggle={newacc.cleardone}
              orgtoggle={oldacc.cleardone}
              val={newacc.cleardonedate}
              org={oldacc.cleardonedate}
              label="cleardone"
              load={load.cleardone}
              title="Clearance Done"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postDate}
            />
          </Col>
        </FlexboxGrid>
        <Div14>Approval Dates:</Div14>
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24}>
            <DateToggleSTU
              valtoggle={newacc.epa}
              orgtoggle={oldacc.epa}
              val={newacc.epadate}
              org={oldacc.epadate}
              label="epa"
              load={load.epa}
              title="EPA / Other Approval"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postDate}
            />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <DateToggleSTU
              valtoggle={newacc.pai}
              orgtoggle={oldacc.pai}
              val={newacc.paidate}
              org={oldacc.paidate}
              label="pai"
              load={load.pai}
              title="PAI Approval"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postDate}
            />
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(Events));
