import { observer } from "mobx-react";
import React, { Component } from "react";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";
import editAccountStore from "../../../../../Stores/Account/EditAccountStore";
import SelectedOptions from "./SelectedOptions";
class OptionCheckBox extends Component {
  render() {
    let opt = this.props.opt;
    let title = opt.title;
    let price = opt.price;
    let type = opt.type;
    let desc = opt.desc;
    let inout = opt.inout;

    return (
      <IconButton
        block
        icon={<Icon icon={"circle"} />}
        onClick={() =>
          editAccountStore.addDeliveryOption(title, desc, price, type, inout)
        }
        color={"green"}
        appearance="ghost"
      >
        <div style={{ textAlign: "left" }}>
          {desc} (For {inout} Storage: {price} KD)
        </div>
      </IconButton>
    );
  }
}

class StorDelOptions extends Component {
  render() {
    let basicOpts = [
      {
        title: "Palletization",
        desc: "Items Need Pallets and Pallet Strapping",
        price: 2,
        type: "per CBM",
        inout: "In",
      },
      {
        title: "Shrink Wrap",
        desc: "Items Need Shrink Wraps",
        price: 1,
        type: "per CBM",
        inout: "In",
      },
      {
        title: "Sorting",
        desc: "Packing List and Items Dimensions are not available, Requires Sorting.",
        price: 0.5,
        type: "per CBM",
        inout: "In",
      },
      {
        title: "Strapping",
        desc: "Items require Pallet Strapping.",
        price: 0.5,
        type: "per CBM",
        inout: "In",
      },
      {
        title: "Damage Inspection Report",
        desc: "Easy Move Staff to provide Damage Inspection Report.",
        price: 1,
        type: "per CBM",
        inout: "In",
      },
    ];
    let eopts = editAccountStore.edited.storageContract.options;
    let oopts = editAccountStore.original.storageContract.options;

    let unselected = [];
    for (let i = 0; i < basicOpts.length; i++) {
      if (eopts.length <= 0) {
        unselected = [...unselected, basicOpts[i]];
      } else if (
        eopts.findIndex((opt) => opt.title === basicOpts[i].title) < 0
      ) {
        unselected = [...unselected, basicOpts[i]];
      }
    }
    let optchange = eopts.length !== oopts.length;

    if (!optchange) {
      let efopts = eopts
        .map(
          (opt) =>
            `${opt.title} ${opt.desc} ${opt.type} ${PRKD(opt.price)} ${
              opt.inout
            }`
        )
        .sort();
      let ofopts = oopts
        .map(
          (opt) =>
            `${opt.title} ${opt.desc} ${opt.type} ${PRKD(opt.price)} ${
              opt.inout
            }`
        )
        .sort();
      for (let i = 0; i < efopts.length; i++) {
        if (efopts[i] !== ofopts[i]) optchange = true;
      }
    }

    return (
      <div>
        <Div14>
          {optchange && (
            <IconButton
              icon={<Icon icon="undo" />}
              circle
              onClick={() => editAccountStore.resetopts()}
            />
          )}
          <Divider vertical />
          Storage and Delivery Options:
          <Divider vertical />
          {optchange && (
            <IconButton
              icon={<Icon icon="save" />}
              color="green"
              circle
              onClick={() => editAccountStore.updateSTO("options", "options")}
            />
          )}
        </Div14>
        <br />
        {unselected.length !== 0 && <Div14>Standard Options:</Div14>}
        {unselected.map((opt, ind) => (
          <OptionCheckBox opt={opt} key={ind} />
        ))}
        <Div14>Selected Options</Div14>
        {eopts.map((opt, ind) => (
          <SelectedOptions opt={opt} key={ind} index={ind} />
        ))}
        <br />
        <FlexboxGrid>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="plus" />}
              color="green"
              appearance="ghost"
              onClick={() =>
                editAccountStore.addDeliveryOption(
                  "New",
                  "",
                  0.5,
                  "Per CBM",
                  "In"
                )
              }
            >
              New IN Option
            </IconButton>
          </Col2>
          <Col2>
            <IconButton
              block
              icon={<Icon icon="plus" />}
              color="green"
              appearance="ghost"
              onClick={() =>
                editAccountStore.addDeliveryOption(
                  "New",
                  "",
                  0.5,
                  "Per CBM",
                  "Out"
                )
              }
            >
              New OUT Option
            </IconButton>
          </Col2>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default observer(StorDelOptions);
