import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import userStore from "../UserStore";
import baseURL from "../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });

class AccountStore {
  constructor() {
    this.clientid = "";
    this.accounts = [];
    this.selectedaccount = {};
    this.loadingacc = false;
    this.loadingdeltype = false;
    this.loadingdel = false;
    this.accountloaded = false;
    this.deliveryTypes = [];
    this.delTypeLoaded = false;
    this.deliveries = [];
    this.delloaded = false;
    this.acorders = [];
    this.acorderloaded = false;
    this.showcanc = false;

    makeObservable(this, {
      clientid: observable,
      accounts: observable,
      loadingacc: observable,
      loadingdel: observable,
      loadingdeltype: observable,
      accountloaded: observable,
      deliveryTypes: observable,
      delTypeLoaded: observable,
      deliveries: observable,
      delloaded: observable,
      showcanc: observable,
      acorders: observable,
      acorderloaded: observable,
      upcomingdels: computed,
      pastdels: computed,
      cancdels: computed,
    });
  }

  // FETCH
  getAccounts(clientID, override) {
    let loaded = false;
    if ((this.clientid === clientID) & this.accountloaded) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.clientid = clientID;
      this.accountloaded = false;

      this.loadingacc = true;
      return instance
        .get(`/ac/account/accountbyclient/${clientID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((accounts) => {
          if (accounts.account) {
            this.selectedaccount = accounts.account;
            this.getDeliveryTypes(true);
          } else if (!accounts.client) {
            this.selectedaccount = accounts.account;
          } else {
            this.selectedaccount = { _id: "" };
          }
          this.accountloaded = true;
          this.loadingacc = false;
          console.log("Accounts Fetched from Account Store");
        })
        .catch((err) => {
          this.selectedaccount = null;
          this.loadingacc = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Account.`,
          });
        });
    }
  }
  getCanc() {
    if (!this.showcanc) {
      this.showcanc = true;
      this.getDeliveries(true);
    }
  }

  getDeliveries(override) {
    let accountID = this.selectedaccount._id;
    let loaded = false;
    if (this.delloaded) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.delloaded = false;
      this.loadingdel = true;
      let url = `/ac/delivery/get/byaccount/${accountID}`;
      if (this.showcanc) {
        url = `/ac/delivery/get/byaccount/all/${accountID}`;
      }

      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((deliveries) => {
          this.deliveries = deliveries;
          this.delloaded = true;
          this.loadingdel = false;

          console.log("Deliveries Fetched");
        })
        .catch((err) => {
          this.loadingdel = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Deliveries.`,
          });
        });
    }
  }
  getACOrders(override) {
    let accountID = this.selectedaccount._id;
    let loaded = false;
    if (this.acorderloaded) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.acorderloaded = false;
      this.loadingdel = true;
      let url = `/ac/delivery/get/byaccount/acorders/${accountID}`;

      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((acorders) => {
          this.acorders = acorders;
          this.acorderloaded = true;
          this.loadingdel = false;

          console.log("AC Orders Fetched");
        })
        .catch((err) => {
          this.loadingdel = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching AC Orders.`,
          });
        });
    }
  }
  getDeliveryTypes(override) {
    let accountID = this.selectedaccount._id;
    let loaded = false;
    if (this.delTypeLoaded) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.delTypeLoaded = false;

      this.loadingdeltype = true;
      return instance
        .get(`/ac/deliverytype/get/byaccount/${accountID}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((deltypes) => {
          this.deliveryTypes = deltypes;
          this.delTypeLoaded = true;
          this.loadingdeltype = false;
          console.log("Delivery Types Fetched");
        })
        .catch((err) => {
          this.loadingdeltype = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Delivery Types.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:
  get upcomingdels() {
    let alldels = [
      ...this.deliveries.filter(
        (del) =>
          del.status === "Booked" ||
          del.status === "Requested" ||
          del.status === "In Transit" ||
          del.status === "Scheduled"
      ),
    ];
    alldels = [
      ...alldels,
      ...this.acorders.filter(
        (del) =>
          del.status === "Booked" ||
          del.status === "Requested" ||
          del.status === "In Transit" ||
          del.status === "Scheduled"
      ),
    ];
    return alldels;
  }
  get pastdels() {
    let alldels = [
      ...this.deliveries.filter((del) => del.status === "Completed"),
      ...this.acorders.filter((del) => del.status === "Completed"),
    ];
    return alldels;
  }
  get cancdels() {
    let alldels = [
      ...this.deliveries.filter((del) => del.status === "Cancelled"),
      ...this.acorders.filter((del) => del.status === "Cancelled"),
    ];
    return alldels;
  }

  // FUNCTIONS:
  // ----- DATE ----

  startLoading() {
    this.loading = true;
  }
}

const accountStore = new AccountStore();
export default accountStore;
