import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import {
  ButtonToolbar,
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
} from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";
import { Result, Empty, message } from "antd";
import Div14 from "../../../Components/Dividers/Div14";
import userStore from "../../../Stores/UserStore";
import moment from "moment";
import Col2 from "../../../Components/Columns/Col2";
import PasswordInput from "../../../Components/Inputs/Auth/PasswordInput";

const instance = axios.create({ baseURL: baseURL });

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userId: "",
      email: "",
      available: false,
      changed: false,
      expired: false,
      password: "",
      passconf: "",
      try: false,
    };
    this.changeView = this.changeView.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  changeView(type, val) {
    this.setState({ [type]: val });
  }

  componentDidMount = async () => {
    await instance
      .get(
        `auth/passreset/getuserdetails/${this.props.match.params.resettoken}`
      )
      .then((res) => {
        if (moment().isSameOrBefore(res.data.resetPasswordExpires)) {
          message.success("User Found");
          this.setState({
            loading: false,
            expired: false,
            available: true,
            userId: res.data._id,
            email: res.data.email,
          });
        } else {
          message.warning("Password Link Expired");
          this.setState({ loading: false, expired: true, available: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, available: false, expired: false });
        console.log(err);
        Notification["error"]({ title: `An error occured` });
      });
  };
  updatePassword = async () => {
    this.setState({ loading: true });
    let data = { userId: this.state.userId, password: this.state.password };
    await instance
      .post(
        `auth/passreset/updateuserpass/${this.props.match.params.resettoken}`,
        data
      )
      .then((res) => {
        this.setState({ loading: false, changed: true });
        userStore.loginUser({
          email: this.state.email,
          password: this.state.password,
          validation: true,
        });
        Notification["success"]({ title: `Password Reset Successful.` });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let content = <Empty description="Loading Account" />;

    let passerror = { val: false, message: "" };
    let passconferr = { val: false, message: "" };

    if (this.state.password.length < 8) passerror.val = true;
    if (passerror.val) passerror.message = "Password must +8 Characters.";

    if (this.state.password !== this.state.passconf) passconferr.val = true;
    if (passconferr.val) passconferr.message = "Passwords do not match.";

    if (!this.state.loading) {
      if (this.state.expired || !this.state.available) {
        content = (
          <Result
            status="warning"
            title="Password Link has Expired."
            subTitle="The password link has expired, please use the below links to try again:"
            extra={[
              <ButtonToolbar>
                <IconButton
                  size="lg"
                  color="red"
                  icon={<Icon icon="key" size="lg" />}
                  onClick={() => this.props.history.push("/forgotpass")}
                >
                  Forgot Password
                </IconButton>
                <IconButton
                  size="lg"
                  color="green"
                  icon={<Icon icon="user" size="lg" />}
                  onClick={() => this.props.history.push("/login")}
                >
                  Login
                </IconButton>
              </ButtonToolbar>,
            ]}
          />
        );
      } else if (this.state.changed) {
        content = (
          <Result
            status="success"
            title="Password Reset Successful"
            subTitle="You have successfully updated yourr password. You can proceed to Login:"
            extra={[
              <ButtonToolbar>
                <IconButton
                  size="lg"
                  color="green"
                  icon={<Icon icon="user" size="lg" />}
                  onClick={() => this.props.history.push("/login")}
                >
                  Login
                </IconButton>
              </ButtonToolbar>,
            ]}
          />
        );
      } else {
        content = (
          <FlexboxGrid justify="center">
            <Col2>
              <div
                style={{
                  padding: "2%",
                  margin: "2%",
                  borderRadius: "5px",
                  boxShadow: "1px 1px 5px black",
                }}
              >
                <Div14>
                  <b>
                    Reset Password for <br />
                    <i>{this.state.email}</i>
                  </b>
                </Div14>
                <PasswordInput
                  data={this.state.password}
                  err={passerror.val}
                  errmessage={passerror.message}
                  type={"password"}
                  label="Password"
                  disabled={this.state.loading}
                  autoFocus={false}
                  action={this.changeView}
                />
                <PasswordInput
                  data={this.state.passconf}
                  err={passconferr.val}
                  errmessage={passconferr.message}
                  type={"passconf"}
                  label="Confirm Password"
                  disabled={this.state.loading}
                  autoFocus={false}
                  action={this.changeView}
                />
                <hr />
                <IconButton
                  block
                  color="green"
                  icon={<Icon icon="save" />}
                  loading={this.state.loading}
                  onClick={() => this.updatePassword()}
                  disabled={passerror.val || passconferr.val}
                >
                  Save New Password
                </IconButton>
              </div>
            </Col2>
          </FlexboxGrid>
        );
      }
    }
    return (
      <LoadBack loading={this.state.loading}>
        <Div14>Reset Password Page</Div14>
        {content}
      </LoadBack>
    );
  }
}

export default withRouter(observer(ResetPassword));
