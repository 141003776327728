import { observer } from "mobx-react";
import React, { Component } from "react";
import { Statistic } from "antd";
import { FlexboxGrid, HelpBlock } from "rsuite";

import moment from "moment";
import acInventoryStore from "../../../../../../../Stores/AccountUpdated/Storage/ACInventoryStore";
import singleAccountStore from "../../../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import { Parsed, PRKD } from "../../../../../../../Functions/Pricing/PRKDCalc";
import Col4 from "../../../../../../../Components/Columns/Col4";

import acInvoiceStore from "../../../../../../../Stores/Financial/ACInvoiceStore";
import FlexboxButton from "../../../../../../../Components/Modal/FlexboxButton";
import LoadBack from "../../../../../../../Components/Loaders/LoadBack";

class StorageCharges extends Component {
  render() {
    let data = singleAccountStore.account;
    let daysactive = moment().diff(data.startdate, "days");
    if (data.status !== "Active") {
      daysactive = moment(data.enddate).diff(data.startdate, "days");
    }
    let storCont = data.storageContract;

    let totalchg = 0;
    let totalinv = 0;
    let totalpaid = 0;

    if (acInventoryStore.finance.weekly) {
      if (acInventoryStore.finance.weekly.length) {
        if (acInventoryStore.finance.weekly.length > 0) {
          for (let i = 0; i < acInventoryStore.finance.weekly.length; i++) {
            totalchg += Parsed(acInventoryStore.finance.weekly[i].totalchg);
          }
        }
      }
    }

    for (let i = 0; i < acInvoiceStore.acinvoices.length; i++) {
      totalinv += Parsed(acInvoiceStore.acinvoices[i].totalcharges);
    }
    for (let i = 0; i < acInvoiceStore.payments.length; i++) {
      totalpaid += Parsed(acInvoiceStore.payments[i].amount);
    }

    let totalremaining = totalchg - totalpaid;

    let newcontent = (
      <FlexboxButton
        icon="reload"
        text="Load Storage Charges"
        color="green"
        app="primary"
        loading={acInventoryStore.loading.finance}
        oC={() => {
          acInventoryStore.getStorageFinanceHistory(
            singleAccountStore.account._id,
            true
          );
          acInvoiceStore.getInvoiceAndPaymentByClient(
            acInventoryStore.clientId
          );
        }}
      />
    );

    return (
      <LoadBack loading={acInventoryStore.loading.finance}>
        {!this.props.hidedetails && (
          <>
            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Contract Status"
                  value={data.status}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Start Date"
                  value={moment(data.startdate).format("DD-MMM-YYYY")}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="End Date"
                  value={moment(data.enddate).format("DD-MMM-YYYY")}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Days Active"
                  value={daysactive}
                  suffix="days"
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
            </FlexboxGrid>

            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Type"
                  value={storCont.maintype}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Charge Type"
                  value={storCont.subtype}
                  style={{ textAlign: "center" }}
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Minimum CBM"
                  value={storCont.mincbm}
                  style={{ textAlign: "center" }}
                  suffix="CBM"
                />
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Price Per CBM"
                  value={storCont.pricepercbm}
                  style={{ textAlign: "center" }}
                  suffix="CBM"
                />
                <hr />
              </Col4>
            </FlexboxGrid>
          </>
        )}
        {acInventoryStore.loaded.finance ? (
          <>
            <FlexboxGrid
              style={{ marginLeft: "2%", marginRight: "2%" }}
              justify="space-around"
            >
              <Col4>
                <Statistic
                  title="Total Charge"
                  value={PRKD(totalchg)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  As of {moment().format("DD-MMM-YY")}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Total Invoiced"
                  value={PRKD(totalinv)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalinv) === PRKD(totalchg) ? "green" : "red",
                  }}
                >
                  {PRKD(totalinv) === PRKD(totalchg)
                    ? "Charges Matched"
                    : PRKD(totalinv) > PRKD(totalchg)
                    ? "Over Invoiced"
                    : "Under Invoiced"}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title="Total Paid"
                  value={PRKD(totalpaid)}
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalpaid) === PRKD(totalinv) ? "green" : "red",
                  }}
                >
                  {PRKD(totalpaid) === PRKD(totalinv)
                    ? "Invoices Matched"
                    : PRKD(totalpaid) > PRKD(totalinv)
                    ? "Over Paid"
                    : "Under Paid"}
                </HelpBlock>
                <hr />
              </Col4>
              <Col4>
                <Statistic
                  title={
                    totalremaining >= 0
                      ? "Remaining Charges"
                      : "Available Credit"
                  }
                  value={
                    PRKD(totalremaining) === 0
                      ? "-"
                      : Math.abs(PRKD(totalremaining))
                  }
                  style={{ textAlign: "center" }}
                  suffix="KD"
                />
                <HelpBlock
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontStyle: "italic",
                    fontSize: 10,
                    color: PRKD(totalremaining) === 0 ? "green" : "red",
                  }}
                >
                  {PRKD(totalremaining) === 0
                    ? "All Charges Met"
                    : PRKD(totalremaining) < 0
                    ? "Over Paid"
                    : "Overdue Balance"}
                </HelpBlock>
                <hr />
              </Col4>
            </FlexboxGrid>
          </>
        ) : (
          newcontent
        )}
      </LoadBack>
    );
  }
}

export default observer(StorageCharges);
