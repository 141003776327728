import React, { Component } from "react";
import { Tabs } from "antd";
import { observer } from "mobx-react";

import IMPrice from "./IMPrice";
import { FlexboxGrid, Notification } from "rsuite";
import SJSelectInput from "../../../../../../Components/Inputs/SJ/SJSelectInput";

import statuslist from "../../../../../../Static/Lists/StatusList";
import sjStore from "../../../../../../Stores/SideJobs/SJStore";

import ItemTab from "../Others/Items/ItemTab";
import JobDateTab from "../Others/JobDate/JobDateTab";
import AddAttTemplate from "../../Attachments/Attachment/Additional/AddAttTemplate";

import IMCharges from "./IMCharges";
import IMTracking from "./IMTracking";
import IMQuotes from "./IMQuotes";
import { IMPriceCalc } from "../../../../../../Functions/Pricing/IMPriceCalc";

import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import Col2 from "../../../../../../Components/Columns/Col2";
import imtypes from "../../../../../../Static/Lists/jobsubtypes/imtypes";
import commoditytypes from "../../../../../../Static/Lists/jobsubtypes/imlists/commoditytypes";
import DraftEditor from "../../../../../../Components/Inputs/SJ/DraftEditor";
import userStore from "../../../../../../Stores/UserStore";
import QBadge from "../../../../../../Components/Quality/QBadge";
import {
  IMQualityCharges,
  IMQualityMain,
  IMQualityTrack,
} from "../../../../../../Functions/Quality/IMQualityCalc";
import { JDQualityCalc } from "../../../../../../Functions/Quality/SupportingQuality/JDQualityCalc";
import QualityMain from "../Others/Quality/QualityMain";
import { AttQualityCalc } from "../../../../../../Functions/Quality/SupportingQuality/ATTQualityCalc";
import SJTextAreaInput from "../../../../../../Components/Inputs/SJ/SJTextAreaInput";
import cancreasonlist from "../../../../../../Static/Lists/CancellationList";
import SJNumbInput from "../../../../../../Components/Inputs/SJ/SJNumbInput";

const { TabPane } = Tabs;

class SJIMTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "home", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }
  render() {
    let editview = this.props.editview;
    let ind = this.props.ind;
    let load = this.props.load;
    let tabOrientation = "left";
    if (window.innerWidth < 800) tabOrientation = "top";

    let sj = sjStore.sjs[ind];

    let imprice = IMPriceCalc(sj);

    let datelength = sj.jobdate.length;
    let itemlength = sj.items.length;
    let tracklength = sj.im.imtracks.length;
    let quotelength = sj.im.imquotes.length;

    let quality = userStore.user.quality;

    return (
      <div>
        <FlexboxGrid justify="center">
          {editview && (
            <div>
              {" "}
              <b>{PRKD(imprice[7])} KD Profit </b> ={" "}
            </div>
          )}
          <div style={{ color: "green" }}>
            ({PRKD(imprice[1])} IM) + ({PRKD(imprice[2])} Add){" "}
          </div>
          {editview ? (
            <div style={{ color: "red" }}>
              - ({PRKD(imprice[3])} Origin) - ({PRKD(imprice[4])} Shipping) - (
              {PRKD(imprice[5])} Destination){" "}
              {sj.commodity === "Dangerous Goods" &&
                `- ${PRKD(imprice[6])} Dang. Goods`}
            </div>
          ) : (
            <div style={{ fontWeight: "bold" }}>
              {" "}
              = {PRKD(parseFloat(imprice[1]) + parseFloat(imprice[2]))} KD
            </div>
          )}
        </FlexboxGrid>
        <FlexboxGrid>
          <Col2>
            <SJSelectInput
              read={!editview}
              ind={ind}
              label="status"
              title="Status"
              load={load.status}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={statuslist}
            />
            <br />
            {sj.status === "Cancelled" && (
              <SJSelectInput
                ind={ind}
                label="cancreason"
                title="Canc. Reason"
                load={load.cancreason}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                opts={cancreasonlist}
                read={!editview}
              />
            )}
          </Col2>
          <Col2>
            <SJSelectInput
              read={!editview}
              ind={ind}
              label="direction"
              title="In/Out"
              load={load.direction}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={["Inbound", "Outbound"]}
              sj="im"
            />
            <br />
            {sj.status === "Cancelled" && (
              <SJTextAreaInput
                ind={ind}
                label="cancremarks"
                title="Canc. Remarks"
                load={load.cancremarks}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                read={!editview}
              />
            )}
          </Col2>
          <Col2>
            <SJSelectInput
              read={!editview}
              ind={ind}
              label="subtype"
              title="Sub Type"
              load={load.subtype}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={imtypes}
            />
          </Col2>
          <Col2>
            <SJSelectInput
              read={!editview}
              ind={ind}
              label="commodity"
              title="Commodity"
              load={load.commodity}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              opts={commoditytypes}
              sj="im"
            />
          </Col2>
          <Col2>
            <SJNumbInput
              ind={ind}
              read={!editview}
              label="cbm"
              title="CBM"
              load={load.cbm}
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
            />
          </Col2>
        </FlexboxGrid>

        <br />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={tabOrientation}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={
              <div>
                Job Details {quality && <QBadge value={IMQualityMain(sj)[0]} />}
              </div>
            }
            key={"home"}
            disabled={this.state.locked}
          >
            <IMPrice
              ind={ind}
              load={this.props.load}
              editVal={this.props.editVal}
              updateVal={this.props.updateVal}
              quality={quality}
              editview={editview}
            />
          </TabPane>
          {editview && (
            <TabPane
              tab={
                <div>
                  IM Charges{" "}
                  {quality && <QBadge value={IMQualityCharges(sj)[0]} />}
                </div>
              }
              key={1}
              disabled={this.state.locked}
            >
              <IMCharges
                ind={ind}
                load={this.props.load}
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                quality={quality}
              />
            </TabPane>
          )}
          <TabPane
            tab={
              <div>
                {datelength} Date{datelength !== 1 ? "s" : ""}{" "}
                {quality && <QBadge value={JDQualityCalc(sj)[0]} />}
              </div>
            }
            key={2}
            disabled={this.state.locked}
          >
            <JobDateTab ind={ind} quality={quality} editview={editview} />
          </TabPane>
          <TabPane
            tab={
              <div>
                {tracklength} Track{tracklength !== 1 ? "s" : ""}{" "}
                {quality && <QBadge value={IMQualityTrack(sj)[0]} />}
              </div>
            }
            key={3}
            disabled={this.state.locked}
          >
            <IMTracking ind={ind} quality={quality} editview={editview} />
          </TabPane>
          {editview && (
            <TabPane
              tab={`${quotelength} Quote${quotelength !== 1 ? "s" : ""}`}
              key={4}
              disabled={this.state.locked}
            >
              <IMQuotes ind={ind} />
            </TabPane>
          )}
          <TabPane
            tab={`${itemlength} Item${itemlength !== 1 ? "s" : ""}`}
            key={5}
            disabled={this.state.locked}
          >
            <ItemTab ind={ind} editview={editview} />
          </TabPane>
          <TabPane
            tab={
              <div>
                Documents {quality && <QBadge value={AttQualityCalc(sj)[0]} />}
              </div>
            }
            key={6}
            disabled={this.state.locked}
          >
            <AddAttTemplate
              ind={ind}
              attType={"Additional"}
              quality={quality}
              editview={editview}
            />
          </TabPane>

          {editview && (
            <TabPane tab={`Inv Notes`} key={8} disabled={this.state.locked}>
              <DraftEditor
                label="invnotes"
                title="Invoice Notes"
                load={load.invnotes}
                size="sm"
                editVal={this.props.editVal}
                updateVal={this.props.updateVal}
                ind={ind}
              />
            </TabPane>
          )}
          {quality && (
            <TabPane tab={`Quality`} key={10} disabled={this.state.locked}>
              <QualityMain sj={sj} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default observer(SJIMTabs);
