import ReactGA4 from "react-ga4";

const TrackGoogleAnalyticsEvent = (category, action, label) => {
  if (action === "") action = "Visitor";
  if (action === "Jazzaf" || action === "jazzaf") {
  } else {
    // Send UA Event
    // Send GA4 Event
    ReactGA4.event({
      category: category,
      action: action,
      label: label,
    });
  }
};

export default TrackGoogleAnalyticsEvent;
export { TrackGoogleAnalyticsEvent };
