import { observer } from "mobx-react";
import React, { Component } from "react";

import { Tabs } from "antd";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import MainAccountInfo from "../../StorageAccount/SubContractSummary/MainAccountInfo";
import OrderMain from "./Tabs/OrderTable/OrderMain";
import IMTrack from "./Tabs/IMTrack/IMTrack";
import AIMCharges from "./Tabs/Charges/AIMCharges";
import MainInvoicePage from "./Tabs/AIMInvoiceModals/MainInvoicePage";
import PaymentMain from "./Tabs/Payments/PaymentMain";
const { TabPane } = Tabs;

class DeliveryACCTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Orders" };
  }

  componentDidMount() {
    singleAccountStore.getAccount(this.props.clientid);
  }
  render() {
    return (
      <div>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Orders"} key={"Orders"}>
            <OrderMain clientid={this.props.clientid} />
          </TabPane>
          <TabPane tab={"Shipment Tracking"} key={"Shipment Tracking"}>
            <IMTrack />
          </TabPane>
          <TabPane tab={"Charges"} key={"Charges"}>
            <AIMCharges />
          </TabPane>
          <TabPane tab={"Invoices"} key={"Invoices"}>
            <MainInvoicePage />
          </TabPane>
          <TabPane tab={"Payments"} key={"Payments"}>
            <PaymentMain />
          </TabPane>
          <TabPane tab={"Contract Info"} key={"Contract Info"}>
            <FlexboxGrid justify="end">
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => singleAccountStore.getAccount(false, true)}
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <MainAccountInfo client={singleAccountStore.account} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default observer(DeliveryACCTabs);
