import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid } from "rsuite";
import Col2 from "../../../../../../Components/Columns/Col2";
import Div14 from "../../../../../../Components/Dividers/Div14";
import SelectInputSTD from "../../../../../../Components/Inputs/STD/SelectInputSTD";
import newStorageStore from "../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import storagejoblist from "../../../../../../Static/Lists/storagejoblist";
class SACStep1 extends Component {
  render() {
    let order = newStorageStore.neworder;
    let jobdesc = storagejoblist.filter((st) => st.type === order.jobtype);

    let code = <div />;
    if (jobdesc[0]) {
      if (jobdesc[0].code) code = jobdesc[0].code;
    }
    return (
      <div>
        <Div14>Step 1: Main Request</Div14>
        <hr />
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
          {order.jobtype === "Delivery In" || order.jobtype === "Delivery Out"
            ? "Job Done By Easy Move"
            : "Job Done By Client"}
        </h2>
        <FlexboxGrid justify="center" style={{ textAlign: "center" }}>
          <Col2>
            <SelectInputSTD
              title="Job Type"
              val={order.jobtype}
              label="jobtype"
              size="large"
              opts={[
                "Storage In",
                "Storage Out",
                "Delivery In",
                "Delivery Out",
              ]}
              editVal={newStorageStore.editOrder}
            />
          </Col2>
        </FlexboxGrid>
        <hr />

        <FlexboxGrid justify="center">{code}</FlexboxGrid>
      </div>
    );
  }
}

export default observer(SACStep1);
