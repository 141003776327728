import { observer } from "mobx-react";
import React, { Component } from "react";
import { Col, FlexboxGrid, HelpBlock, Icon, IconButton } from "rsuite";
import Div14 from "../../../../Components/Dividers/Div14";
import newAccountStore from "../../../../Stores/AccountUpdated/General/NewAccountStore";
import Col2 from "../../../../Components/Columns/Col2";
import Col3 from "../../../../Components/Columns/Col3";
import heardofuslist from "../../../../Static/Lists/HeardOfUs";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import { DatePicker, message } from "antd";
import { InputGroup, Notification } from "rsuite";
import moment from "moment";
import EditStorCont from "./EditStorCont";
import axios from "axios";

import EditDelCont from "./EditDelCont";
import TextInputSTU from "../../../../Components/Inputs/Updatable/TextInputSTU";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import SelectInputSTU from "../../../../Components/Inputs/Updatable/SelectInputSTU";

const instance = axios.create({ baseURL: baseURL });

class EditAccountTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { load: {} };
    this.updateAcc = this.updateAcc.bind(this);
  }
  updateAcc = async (label) => {
    let account = newAccountStore.account;
    let data = { [label]: account[label] };
    if (label === "adel") {
      if (account[label] === false) {
        data.ast = false;
      }
    }
    this.setState({ load: { [label]: true } });
    return instance
      .put(`/ac/account/edit/${account._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newAccountStore.updateAccount(account[label], label);
        message.success(`Successfully Updated Account Info (${label})`);
        this.setState({ load: { [label]: false } });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ load: { [label]: false } });
      });
  };
  render() {
    let data = newAccountStore.orgaccount;
    let newdata = newAccountStore.account;

    let dataquality = [];
    if (data.name === "") dataquality.push("Client Name Missing");
    if ((data.email === "") & (data.phone === "") & (data.phone2 === "")) {
      dataquality.push("Add at least an Email or a Phone Number");
    }

    let load = this.state.load;

    return (
      <div style={{ padding: "1%" }}>
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          <Div14>Client Details:</Div14>
          <FlexboxGrid>
            <Col2>
              <TextInputSTU
                val={newdata.name}
                org={data.name}
                label="name"
                title="Name"
                size="large"
                load={load.label}
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTU
                val={newdata.status}
                org={data.status}
                label="status"
                load={load.label}
                title="Status"
                size="large"
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
                opts={["Pending", "Active", "Cancelled", "Expired"]}
              />
              <br />
            </Col2>
          </FlexboxGrid>

          <FlexboxGrid>
            <Col3>
              <TextInputSTU
                val={newdata.email}
                org={data.email}
                label="email"
                title="Email"
                size="large"
                load={load.label}
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
              />
              <br />
            </Col3>
            <Col3>
              <TextInputSTU
                val={newdata.phone}
                org={data.phone}
                label="phone"
                title="Phone"
                size="large"
                load={load.label}
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
              />
              <br />
            </Col3>
            <Col3>
              <TextInputSTU
                val={newdata.phone2}
                org={data.phone2}
                label="phone2"
                title="Phone 2"
                size="large"
                load={load.label}
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
              />
              <br />
            </Col3>
          </FlexboxGrid>

          <FlexboxGrid>
            <Col2>
              <SelectInputSTU
                val={newdata.heardofus}
                org={data.heardofus}
                label="heardofus"
                load={load.label}
                title="Heard of Us"
                size="large"
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
                opts={heardofuslist}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTU
                val={newdata.leadtype}
                org={data.leadtype}
                label="leadtype"
                load={load.label}
                title="Lead Type"
                size="large"
                editVal={newAccountStore.editAccount}
                updateVal={this.updateAcc}
                opts={heardofuslist}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid justify="center" align="middle">
            <Col3>
              <InputGroup>
                {moment(data.startdate).isSame(newdata.startdate) === false && (
                  <InputGroup.Button
                    loading={load.startdate}
                    onClick={() =>
                      newAccountStore.editAccount(data.startdate, "startdate")
                    }
                  >
                    <Icon icon="undo" />
                  </InputGroup.Button>
                )}
                <DatePickerSTD
                  title="Start Date"
                  val={newdata.startdate}
                  label="startdate"
                  editVal={newAccountStore.editAccount}
                />

                {moment(data.startdate).isSame(newdata.startdate) === false && (
                  <InputGroup.Button
                    color="green"
                    loading={load.startdate}
                    onClick={() => this.updateAcc("startdate")}
                  >
                    <Icon icon="check" />
                  </InputGroup.Button>
                )}
              </InputGroup>
              <br />
            </Col3>
            <Col3>
              <InputGroup>
                {moment(data.enddate).isSame(newdata.enddate) === false && (
                  <InputGroup.Button
                    loading={load.enddate}
                    onClick={() =>
                      newAccountStore.editAccount(data.enddate, "enddate")
                    }
                  >
                    <Icon icon="undo" />
                  </InputGroup.Button>
                )}
                <InputGroup.Addon>End Date</InputGroup.Addon>
                <DatePicker
                  style={{ width: "100%" }}
                  //format={"DD-MMM-YY @ HA"}
                  format={"DD-MMM-YY"}
                  value={moment(newdata.enddate)}
                  allowClear={false}
                  size={"large"}
                  picker={"date"}
                  showMinute={false}
                  disabledDate={(date) =>
                    moment(date).isBefore(
                      moment(newdata.startdate).add(1, "day")
                    )
                  }
                  onChange={(date) => {
                    newAccountStore.editAccount(date, "enddate");
                  }}
                />
                {moment(data.enddate).isSame(newdata.enddate) === false && (
                  <InputGroup.Button
                    color="green"
                    loading={load.enddate}
                    onClick={() => this.updateAcc("enddate")}
                  >
                    <Icon icon="check" />
                  </InputGroup.Button>
                )}
              </InputGroup>

              <br />
            </Col3>
            <Col3>
              <h6 style={{ textAlign: "center" }}>
                Contract Length ={" "}
                {moment(data.enddate).from(data.startdate, true)}
              </h6>
              {moment(data.startdate).isSame(newdata.startdate) === false && (
                <HelpBlock
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Start Date Changed from{" "}
                  {moment(data.startdate).format("DD-MMM-YY")}
                </HelpBlock>
              )}
              {moment(data.enddate).isSame(newdata.enddate) === false && (
                <HelpBlock
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  End Date Changed from{" "}
                  {moment(data.enddate).format("DD-MMM-YY")}
                </HelpBlock>
              )}
              <br />
              {}
            </Col3>
          </FlexboxGrid>
        </div>
        <br />
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          <Div14>Jobs</Div14>
          <Div14>
            International Move and Custom Clearance
            <br />
            <HelpBlock>Pricing will be individually per Job</HelpBlock>
          </Div14>

          <FlexboxGrid>
            <Col2>
              <IconButton
                icon={<Icon icon="ship" />}
                appearance={newdata.aim ? "primary" : "ghost"}
                color="blue"
                onClick={() => newAccountStore.editAccount(!newdata.aim, "aim")}
                block
              >
                {newdata.aim ? "Remove" : "Add"} International Move
              </IconButton>
              {newdata.aim !== data.aim && (
                <div>
                  <FlexboxGrid justify="center">
                    <Col md={24} sm={24} xs={24}>
                      <HelpBlock
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >{`${
                        data.aim ? "De-Activated" : "Activated"
                      } International Move Jobs`}</HelpBlock>
                    </Col>
                    <Col2>
                      <IconButton
                        icon={<Icon icon="undo" />}
                        onClick={() =>
                          newAccountStore.editAccount(data.aim, "aim")
                        }
                        block
                        color="red"
                      >
                        Undo Changes
                      </IconButton>
                    </Col2>
                    <Col2>
                      <IconButton
                        icon={<Icon icon="save" />}
                        onClick={() => this.updateAcc("aim")}
                        block
                        color="green"
                      >
                        Save Changes
                      </IconButton>
                    </Col2>
                  </FlexboxGrid>
                </div>
              )}
              <br />
            </Col2>
            <Col2>
              <IconButton
                icon={<Icon icon="plane" />}
                appearance={newdata.acc ? "primary" : "ghost"}
                color="blue"
                placement="right"
                onClick={() => newAccountStore.editAccount(!newdata.acc, "acc")}
                block
              >
                {newdata.acc ? "Remove" : "Add"} Custom Clearance
              </IconButton>
              {newdata.acc !== data.acc && (
                <div>
                  <FlexboxGrid justify="center">
                    <Col md={24} sm={24} xs={24}>
                      <HelpBlock
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >{`${
                        data.acc ? "De-Activated" : "Activated"
                      } Custom Clearance Jobs`}</HelpBlock>
                    </Col>
                    <Col2>
                      <IconButton
                        icon={<Icon icon="undo" />}
                        onClick={() =>
                          newAccountStore.editAccount(data.acc, "acc")
                        }
                        block
                        color="red"
                      >
                        Undo Changes
                      </IconButton>
                    </Col2>
                    <Col2>
                      <IconButton
                        icon={<Icon icon="save" />}
                        onClick={() => this.updateAcc("acc")}
                        block
                        color="green"
                      >
                        Save Changes
                      </IconButton>
                    </Col2>
                  </FlexboxGrid>
                </div>
              )}
            </Col2>
          </FlexboxGrid>
        </div>
        <br />
        <EditDelCont updateAcc={this.updateAcc} />
        <br />
        <EditStorCont updateAcc={this.updateAcc} />
      </div>
    );
  }
}

export default observer(EditAccountTemplate);
