import MaterialTable from "material-table";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import Div14 from "../../../../../Components/Dividers/Div14";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import hrStore from "../../../../../Stores/HR/HRStore";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import { message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class SalaryMTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeAll: false,
      show: false,
      loading: false,
      salary: {
        _id: "",
        paymentNumId: "",
        salarymonth: moment(),
        amount: 0,
        method: "",
        account: "",
        date: moment(),
        remarks: "",
        paymentref: "",
        hrstaff: { name: "", type: "" },
      },
    };
    this.onHide = this.onHide.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  onDelete = async (paymentid) => {
    this.setState({ loading: true });

    return instance
      .put(`/jf/payment/deletebyid/${paymentid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        hrStore.getSalaries(true);
        this.setState({ loading: false });
        message.success("Payment Deleted");
        this.onHide();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  onHide() {
    this.setState({ show: false });
  }
  render() {
    let data = hrStore.filtsalaries;
    if (this.state.seeAll) data = hrStore.allpayments;
    let columnnames = [
      { title: "Payment ID", field: "paymentNumId" },
      { title: "Pay. Ref", field: "paymentref" },
      {
        title: "Month",
        field: "salarymonth",
        render: (row) => moment(row.salarymonth).format("MMM-YY"),
      },
      { title: "Name", field: "hrstaff.name" },
      { title: "Amount (KD)", field: "amount" },
      {
        title: "Date",
        field: "date",
        render: (row) => moment(row.date).format("DD-MMM-YY"),
        defaultSort: "desc",
      },
      { title: "Method", field: "method" },
      { title: "Account", field: "account" },
      { title: "Remarks", field: "remarks" },
    ];
    let sal = this.state.salary;
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title={`Delete Salary`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="trash" />}
                  onClick={() => this.onDelete(sal._id)}
                >
                  Yes Delete
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <h4>Are you sure you want to delete this payment?</h4>
          <br />
          <h6>
            <b>Payment ID:</b> {sal.paymentNumId}
            <br />
            <b>Reference:</b> {sal.paymentref}
            <br />
            <hr />
            <b>Staff Name:</b> {sal.hrstaff.name} ({sal.hrstaff.type})<br />
            <b>Salary Month:</b> {moment(sal.salarymonth).format("MMMM YYYY")}
            <br />
            <b>Amount:</b> {sal.amount}
            <br />
            <hr />
            <b>Method:</b> {sal.method}
            <br />
            <b>Account:</b> {sal.account}
            <br />
            <b>Paid On:</b> {moment(sal.date).format("DD-MMM-YY")}
            <br />
            <b>Remarks:</b> {sal.remarks}
            <br />
          </h6>
        </UnifiedModal>

        <Div14>All Payments </Div14>
        <MaterialTable
          title={data.length + " Payments"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={data}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle ? this.props.exportTitle : "Salaries"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: this.state.seeAll ? "close" : "visibilityoff",
              tooltip: this.state.seeAll ? "Filter Dates" : "See All",
              isFreeAction: true,
              onClick: (event, rowData) => {
                this.setState({ seeAll: !this.state.seeAll });
              },
            },
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.props.startNewPayment(
                  "Edit",
                  moment(rowData.salarymonth),
                  rowData.hrstaff,
                  rowData
                );
              },
            },
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                this.setState({ show: true, salary: rowData });
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default observer(SalaryMTable);
