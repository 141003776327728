import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Div14 from "../../../../Components/Dividers/Div14";
import editAccountStore from "../../../../Stores/Account/EditAccountStore";

class IMCCJobs extends Component {
  render() {
    let edited = editAccountStore.edited;
    let original = editAccountStore.original;
    let load = editAccountStore.load;

    let imccs = [
      { title: "IM Jobs", label: "aim" },
      { title: "CC Jobs", label: "acc" },
    ];
    let imccrows = imccs.map((imcc, index) => (
      <Col2 key={index}>
        <IconButton
          icon={<Icon icon={edited[imcc.label] ? "check-circle" : "circle"} />}
          block
          appearance={edited[imcc.label] ? "primary" : "ghost"}
          color={edited[imcc.label] ? "green" : "red"}
          onClick={() =>
            editAccountStore.editmain(!edited[imcc.label], imcc.label)
          }
          loading={load.imcc}
        >
          {imcc.title} {edited[imcc.label] ? "Included" : "Not Included"}
        </IconButton>
      </Col2>
    ));

    let changed = false;
    if (edited.aim !== original.aim) changed = true;
    if (edited.acc !== original.acc) changed = true;

    return (
      <div>
        <Div14>IM and CC Contracts:</Div14>

        <FlexboxGrid>
          <Col2 style={{ paddingBottom: "5px" }}>
            <IconButton
              block
              icon={<Icon icon="save" />}
              color="green"
              appearance="ghost"
              loading={load.imcc}
              onClick={() =>
                editAccountStore.updateAccountMain(["aim", "acc"], "imcc")
              }
              size="xs"
              disabled={!changed}
            >
              Save Changes
            </IconButton>
          </Col2>
          <Col2 style={{ paddingBottom: "5px" }}>
            <IconButton
              block
              appearance="ghost"
              icon={<Icon icon="refresh2" />}
              color="red"
              loading={load.imcc}
              onClick={() => editAccountStore.resetMain(["aim", "acc"])}
              size="xs"
              disabled={!changed}
            >
              Cancel Changes
            </IconButton>
          </Col2>
        </FlexboxGrid>

        <FlexboxGrid>{imccrows}</FlexboxGrid>
      </div>
    );
  }
}

export default observer(IMCCJobs);
