import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import Col4 from "../../../Components/Columns/Col4";
import { Statistic } from "antd";
import clientBalanceStore from "../../../Stores/Financial/ClientBalanceStore";
import LoadBack from "../../../Components/Loaders/LoadBack";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Div14 from "../../../Components/Dividers/Div14";
import MonthlySalesTable from "./MonthlySalesTable";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class FinancialStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    clientBalanceStore.getMonthlySales();
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";
    let textColor = "black";
    backgroundColor = "red";
    if (event.type === "Booked") {
      backgroundColor = "#90e0ad";
    } else {
      backgroundColor = "#18572f";
      textColor = "white";
    }
    if (event.jobs <= 0) {
      backgroundColor = "#fcbdb6";
      textColor = "black";
    }

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "1px solid black",
      borderColor: "black",
      display: "block",
      fontSize: 12,
      fontWeight: "bold",
      height: 20,
      color: textColor,
    };
    return {
      style: style,
    };
  };
  render() {
    let data = clientBalanceStore.totalbreakdown;

    return (
      <LoadBack loading={clientBalanceStore.loading.sales}>
        <Div14>Converted Stats</Div14>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #18572f",
              }}
            >
              <Statistic
                title="Local"
                value={data.Converted.LM}
                precision={3}
                suffix="KD"
                valueStyle={{ color: "#18572f" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #18572f",
              }}
            >
              <Statistic
                title="International"
                value={data.Converted.IM}
                precision={3}
                suffix="KD"
                valueStyle={{ color: "#18572f" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #18572f",
              }}
            >
              <Statistic
                title="Custom Clearance"
                value={data.Converted.CC}
                precision={3}
                suffix="KD"
                valueStyle={{ color: "#18572f" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #18572f",
              }}
            >
              <Statistic
                title="Storage"
                value={data.Converted.ST}
                precision={3}
                valueStyle={{ color: "#18572f" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <Div14>Booked Stats</Div14>
        <FlexboxGrid justify="space-around">
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #90e0ad",
              }}
            >
              <Statistic
                title="Local"
                value={data.Booked.LM}
                precision={3}
                suffix="KD"
                valueStyle={{ color: "#90e0ad" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #90e0ad",
              }}
            >
              <Statistic
                title="International"
                value={data.Booked.IM}
                precision={3}
                suffix="KD"
                valueStyle={{ color: "#90e0ad" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #90e0ad",
              }}
            >
              <Statistic
                title="Custom Clearance"
                value={data.Booked.CC}
                precision={3}
                suffix="KD"
                valueStyle={{ color: "#90e0ad" }}
              />
            </div>
          </Col4>
          <Col4>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
                border: "2px solid #90e0ad",
              }}
            >
              <Statistic
                title="Storage"
                value={data.Booked.ST}
                precision={3}
                valueStyle={{ color: "#90e0ad" }}
              />
            </div>
          </Col4>
        </FlexboxGrid>
        <IconButton
          icon={<Icon icon="reload" />}
          onClick={() => clientBalanceStore.getMonthlySales(true)}
          loading={clientBalanceStore.loading.sales}
        >
          Refresh
        </IconButton>
        <hr />
        <Calendar
          localizer={localizer}
          events={clientBalanceStore.dailycal}
          eventPropGetter={this.eventStyleGetter}
          startAccessor={"date"}
          endAccessor={"date"}
          style={{ height: 400 }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor={"title"}
          popup
          onSelectEvent={(e) => console.log(e)}
          onNavigate={(e) => {
            clientBalanceStore.changeStartCal(e);
          }}
        />
        <MonthlySalesTable />
      </LoadBack>
    );
  }
}

export default withRouter(observer(FinancialStats));
