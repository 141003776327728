import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Loader,
  Steps,
  Notification,
} from "rsuite";
import axios from "axios";
import { withRouter } from "react-router";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import delInvoiceStore from "../../../../../../../Stores/Financial/DelInvoiceStore";
import userStore from "../../../../../../../Stores/UserStore";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import Col4 from "../../../../../../../Components/Columns/Col4";
import Col2 from "../../../../../../../Components/Columns/Col2";
import Col3 from "../../../../../../../Components/Columns/Col3";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";

const instance = axios.create({ baseURL: baseURL });

class NewDelInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, load: false };
    this.postNewInvoice = this.postNewInvoice.bind(this);
  }

  componentDidMount() {
    delInvoiceStore.getRemainingInvoiceItems(
      this.props.match.params.clientid,
      true
    );
  }

  postNewInvoice = async () => {
    let data = delInvoiceStore.newdelinvoice;

    let charges = [];
    let singlecharge = {};
    let deliveryids = [];
    let deliverys = [];
    let singledel = {};

    for (let i = 0; i < data.charges.length; i++) {
      deliverys = [];
      deliveryids = [];

      if (data.charges[i].deliverys) {
        if (data.charges[i].deliverys.length > 0) {
          for (let j = 0; j < data.charges[i].deliverys.length; j++) {
            singledel = {
              deliveryNumId: data.charges[i].deliverys[j].deliveryNumId,
              jobdate: data.charges[i].deliverys[j].jobdate,
              status: data.charges[i].deliverys[j].status,
              dropoffs: data.charges[i].deliverys[j].toaddress.length,
              charges: {
                additional: data.charges[i].deliverys[j].charges.additional,
                cancel: data.charges[i].deliverys[j].charges.cancel,
                discount: data.charges[i].deliverys[j].charges.discount,
                location: data.charges[i].deliverys[j].charges.location,
                main: data.charges[i].deliverys[j].charges.main,
                option: data.charges[i].deliverys[j].charges.option,
                totaljob: data.charges[i].deliverys[j].charges.totaljob,
              },
            };
            deliveryids = [...deliveryids, data.charges[i].deliverys[j]._id];
            deliverys = [...deliverys, singledel];
          }
        }
      }

      singlecharge = {
        startday: data.charges[i].startday,
        endday: data.charges[i].end,
        additionalchg: data.charges[i].additionalchg,
        discountchg: data.charges[i].discountchg,
        locationchg: data.charges[i].locationchg,
        mainchg: data.charges[i].mainchg,
        missingtruckjob: data.charges[i].missingtruckjob,
        minchg: data.charges[i].minchg,
        finalcost: data.charges[i].finalcost,
        optionchg: data.charges[i].optionchg,
        totaljobchg: data.charges[i].totaljobchg,
        deliveryids: deliveryids,
        deliverys: deliverys,
        missing: data.charges[i].missing,
      };
      charges = [...charges, singlecharge];
    }

    let final = {
      delInvoiceNumId: data.delInvoiceNumId,
      clientname: data.clientname,
      clientemail: data.clientemail,
      clientphone: data.clientphone,
      title: data.title,
      date: data.date,
      status: data.status,
      remarks: data.remarks,
      client: data.client,
      showBank: data.showBank,
      invnotes: data.invnotes,
      charges: charges,
    };

    let url = "";
    url = `jf/delinvoice/new`;

    if (final.delInvoiceNumId === "New") {
      delete final.delInvoiceNumId;

      url = `jf/delinvoice/new`;

      this.setState({ load: true });
      await instance
        .post(url, final, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.setState({ load: false });
          this.props.history.push(
            `/dac/dash/${this.props.match.params.clientid}`
          );
          Notification["success"]({ title: `New Del Invoice Created` });
        })
        .catch((err) => {
          this.setState({ load: false });
          Notification["error"]({ title: `An error occured` });
        });
    } else {
      url = `jf/delinvoice/edit/${data._id}`;
    }
  };

  render() {
    if ((this.state.step === 0) & delInvoiceStore.loading) {
      return <Loader center />;
    }
    let inv = delInvoiceStore.newdelinvoice;
    let content;
    let footerButs;
    if (this.state.step === 1) content = <Step1 />;
    if (this.state.step === 2) content = <Step2 />;
    if (this.state.step === 3) content = <Step3 />;
    if (this.state.step === 4) content = <Step4 />;
    let disabled = false;
    if (this.state.step === 1) {
      if (inv.title === "") disabled = true;
      if (inv.date === undefined) disabled = true;
    } else if (this.state.step === 2) {
      if (inv.charges.length === 0) disabled = true;
    }

    if (this.state.step === 0) {
      footerButs = (
        <>
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="close" />}
              color="red"
              onClick={() =>
                this.props.history.push(
                  `/ac/dashboard/${delInvoiceStore.client._id}`
                )
              }
              block
            >
              Cancel and Go Back
            </IconButton>
          </Col4>
        </>
      );
    } else if (this.state.step < 4) {
      footerButs = (
        <>
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="left" />}
              color="orange"
              onClick={() => this.setState({ step: this.state.step - 1 })}
              block
            >
              Back
            </IconButton>
          </Col4>
          <Col2 />
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="right" />}
              color="green"
              onClick={() => {
                this.setState({ step: this.state.step + 1 });
                delInvoiceStore.editFinalInvoice(inv);
              }}
              block
              placement="right"
              disabled={disabled}
            >
              Next
            </IconButton>
          </Col4>
        </>
      );
    } else {
      footerButs = (
        <>
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="left" />}
              color="orange"
              onClick={() => this.setState({ step: this.state.step - 1 })}
              block
            >
              Back
            </IconButton>
          </Col4>
          <Col2 />
          <Col4>
            <IconButton
              loading={this.state.load}
              icon={<Icon icon="save" />}
              color="green"
              onClick={() => this.postNewInvoice()}
              block
              placement="right"
            >
              Save
            </IconButton>
          </Col4>
        </>
      );
    }

    let step0content;

    step0content = (
      <FlexboxGrid justify="center">
        <Col3>
          <IconButton
            icon={<Icon icon="plus" />}
            color="green"
            block
            onClick={() => {
              delInvoiceStore.createNewInvoice();
              this.setState({ step: 1 });
            }}
          >
            Create a New Invoice
          </IconButton>
        </Col3>
      </FlexboxGrid>
    );

    return (
      <div
        style={{
          margin: "1%",
          padding: "2%",
          border: "1px solid black",
          borderRadius: "5px",
          boxShadow: "1px 1px 5px black",
        }}
      >
        <FlexboxGrid>{footerButs}</FlexboxGrid>
        <Div14>New Del Invoice</Div14>
        {this.state.step === 0 ? (
          step0content
        ) : (
          <Steps current={this.state.step - 1}>
            <Steps.Item title="Invoice Main Info" />
            <Steps.Item title="Select Charges" />
            <Steps.Item title="Invoice Notes" />
            <Steps.Item title="Save" />
          </Steps>
        )}
        {content}
      </div>
    );
  }
}

export default withRouter(observer(NewDelInvoice));
