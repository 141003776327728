import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import { ButtonToolbar, Icon, IconButton, Notification } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";
import { Result, Button, Empty } from "antd";
import Div14 from "../../../Components/Dividers/Div14";
import userStore from "../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, available: false };
  }
  componentDidMount = async () => {
    await instance
      .get(`auth/basic/confirmemail/${this.props.match.params.userid}`)
      .then((res) => {
        Notification["success"]({
          title: `Thank you for confirming your email address`,
        });
        this.setState({ loading: false, available: true });
      })
      .catch((err) => {
        this.setState({ loading: false, available: false });
        console.log(err);
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let content = <Empty description="Loading Account" />;
    if (!this.state.loading) {
      if (this.state.available) {
        content = (
          <Result
            status="success"
            title="Your account has been verified."
            subTitle="Your email account has been verified. You can use the below links to begin:"
            extra={[
              userStore.signedIn ? (
                <Button
                  type="primary"
                  key="console"
                  onClick={() => this.props.history.push("/profile")}
                >
                  Go to your Profile Page
                </Button>
              ) : (
                <Button
                  type="primary"
                  key="console"
                  onClick={() => this.props.history.push("/login")}
                >
                  Click here to Login
                </Button>
              ),
            ]}
          />
        );
      } else {
        content = (
          <Result
            status="warning"
            title="We could not find your account."
            subTitle="An error occured in the verification. Please contact our office to share another link:"
            extra={[
              <ButtonToolbar>
                <IconButton
                  size="lg"
                  circle
                  color="green"
                  icon={<Icon icon="whatsapp" size="lg" />}
                  onClick={() =>
                    (window.location.href = `https://wa.me/96522060969`)
                  }
                />
                <IconButton
                  size="lg"
                  circle
                  color="green"
                  icon={<Icon icon="phone" size="lg" />}
                  onClick={() => (window.location.href = `tel:+965-22060969`)}
                />
                <IconButton
                  size="lg"
                  circle
                  color="green"
                  icon={<Icon size="lg" icon="envelope" />}
                  onClick={() =>
                    (window.location.href = `mailto:info@emsupplychain.com`)
                  }
                />
              </ButtonToolbar>,
            ]}
          />
        );
      }
    }
    return (
      <LoadBack loading={this.state.loading}>
        <Div14>Verify Account Page</Div14>
        {content}
      </LoadBack>
    );
  }
}

export default withRouter(observer(ConfirmEmail));
