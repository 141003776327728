import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import axios from "axios";
import { FlexboxGrid, Icon, IconButton, Col, Button, InputGroup } from "rsuite";
import { Notification, Input, CheckboxGroup, Checkbox } from "rsuite";

import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import userStore from "../../../../Stores/UserStore";
import serviceStore from "../../../../Stores/AdditionalStores/ServiceStore";
import baseURL from "../../../../Static/baseURL/baseURL";
import ParagraphLoader from "../../../../Components/Loaders/ParagraphLoader";

import Div14 from "../../../../Components/Dividers/Div14";
import ACServiceTable from "./ACServiceTable";

const instance = axios.create({ baseURL: baseURL });

class ServiceMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      type: "AC Order",
      description: "",
      showSTin: true,
      showSTout: true,
      showDelivery: true,
      loading: false,
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.changeVal = this.changeVal.bind(this);
  }

  componentDidMount() {
    serviceStore.getServices();
  }

  changeVal(type, val) {
    this.setState({ [type]: val });
  }

  showHideModal(val) {
    if (val) {
      this.setState({ show: val });
    } else {
      this.setState({
        show: false,
        title: "",
        type: "AC Order",
        description: "",
        showSTin: true,
        showSTout: true,
        showDelivery: true,
        loading: false,
      });
    }
  }

  postNewService = async () => {
    let newservice = {
      title: this.state.title,
      description: this.state.description,
      type: this.state.type,

      showSTin: this.state.showSTin,
      showSTout: this.state.showSTout,
      showDELin: this.state.showDELin,
      showDELout: this.state.showDELout,
      showDelivery: this.state.showDelivery,
    };
    this.changeVal("loading", true);
    let url = "/additional/services/new";

    return instance
      .post(url, newservice, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        serviceStore.getServices(true);
        this.changeVal("loading", false);
        Notification["success"]({
          title: `Added New AC Order ${newservice.title}.`,
          description: <div></div>,
        });
        this.showHideModal(false);
        this.setState({ show: false });
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.changeVal("loading", false);
      });
  };

  render() {
    if (serviceStore.loading) {
      return <ParagraphLoader />;
    }

    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.showHideModal}
          footer={
            <div>
              <FlexboxGrid>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="close" />}
                    color="red"
                    block
                    onClick={() => this.showHideModal(false)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </IconButton>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <IconButton
                    icon={<Icon icon="plus" />}
                    color="green"
                    block
                    disabled={this.state.title === ""}
                    loading={this.state.loading}
                    onClick={() => this.postNewService()}
                  >
                    Create {this.state.type} Type
                  </IconButton>
                </Col>
              </FlexboxGrid>
            </div>
          }
        >
          <h5>New {this.state.type} Details</h5>
          <InputGroup>
            <InputGroup.Addon>Title</InputGroup.Addon>
            <Input
              size="lg"
              disabled={this.state.loading}
              value={this.state.title}
              onChange={(e) => this.changeVal("title", e)}
            />
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroup.Addon>Description</InputGroup.Addon>
            <Input
              as="textarea"
              rows={3}
              value={this.state.description}
              onChange={(e) => this.changeVal("description", e)}
            />
          </InputGroup>
          <CheckboxGroup inline name="checkboxList">
            <Checkbox
              checked={this.state.showSTin}
              onClick={() => this.changeVal("showSTin", !this.state.showSTin)}
            >
              Storage In
            </Checkbox>
            <Checkbox
              checked={this.state.showSTout}
              onClick={() => this.changeVal("showSTout", !this.state.showSTout)}
            >
              Storage Out
            </Checkbox>
            <Checkbox
              checked={this.state.showDelivery}
              onClick={() =>
                this.changeVal("showDelivery", !this.state.showDelivery)
              }
            >
              Delivery Jobs
            </Checkbox>
          </CheckboxGroup>
        </UnifiedModal>
        <Div14>AC Order Charges: </Div14>
        <FlexboxGrid justify="center">
          <h6>Meant to Edit and Add New Services for Inclusion or Exclusion</h6>
        </FlexboxGrid>
        <div style={{ margin: "1%" }}>
          <ACServiceTable
            services={serviceStore.allaccounts}
            exportTitle="AC Orders Charges List"
          />
        </div>
        <FlexboxGrid justify="center">
          <Col md={6} sm={8} xs={24}>
            <Button
              color="green"
              onClick={() => {
                this.setState({ type: "AC Order" });
                this.showHideModal(true);
              }}
              block
            >
              Add New AC Order
            </Button>
          </Col>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default withRouter(observer(ServiceMain));
