import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Empty, Statistic, message } from "antd";
import FlexboxButton from "../../../Components/Modal/FlexboxButton";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import whmOldWarehouseStore from "../WHMStores/WHMOldWarehouseStore";
import Col3 from "../../../Components/Columns/Col3";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import userStore from "../../../Stores/UserStore";
import baseURL from "../../../Static/baseURL/baseURL";
import axios from "axios";
import Div14 from "../../../Components/Dividers/Div14";

const instance = axios.create({ baseURL: baseURL });

class WHMNewLoadingJob extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, whmTruckNumId: "", truckid: "" };
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.selectTruck = this.selectTruck.bind(this);
  }
  onHide() {
    this.setState({ show: false, whmTruckNumId: "", truckid: "" });
  }
  onShow() {
    this.setState({ show: true, whmTruckNumId: "", truckid: "" });
  }
  selectTruck(truck) {
    if (this.state.whmTruckNumId === truck.whmTruckNumId) {
      this.setState({ whmTruckNumId: "", truckid: "" });
    } else {
      this.setState({ whmTruckNumId: truck.whmTruckNumId, truckid: truck._id });
    }
  }
  startLoading = async (truckid) => {
    let data = { truckid: truckid };

    this.setState({ loading: true });
    await instance
      .post(`whm/trucktrip/newtrucktrip`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        if (res.data.created) {
          whmOldWarehouseStore.getPallets(true);
          whmOldWarehouseStore.getTrucks(true);
          whmOldWarehouseStore.getTruckTrip(true);
          message.success(`New Truck Added`);
          this.onHide();
        } else {
          message.success(res.data.message);
        }

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });

        message.error("An Error Occured");
      });
  };

  render() {
    let readytrucks;
    if (whmOldWarehouseStore.freetruck.length > 0) {
      readytrucks = whmOldWarehouseStore.freetruck.map((tr, index) => (
        <Col3 key={index}>
          <div
            style={{
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
              textAlign: "center",
              margin: "2%",
            }}
          >
            <Statistic
              title={`${tr.name}`}
              value={tr.whmTruckNumId}
              valueStyle={{ color: "#3f8600" }}
            />
          </div>
        </Col3>
      ));
    }
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          title="Start Loading"
          footer={
            <ModalFooter
              icon={["close", "save"]}
              color={["red", "green"]}
              app={["ghost", "default"]}
              text={["Cancel", "Start Loading"]}
              oC1={() => this.onHide()}
              oC2={() => this.startLoading(this.state.truckid)}
              disabled2={this.state.whmTruckNumId === ""}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>Select a Truck to Begin Loading:</h3>
          <FlexboxGrid>
            {whmOldWarehouseStore.freetruck.map((tr, index) => (
              <Col3 key={index}>
                <IconButton
                  icon={
                    <Icon
                      icon={
                        tr.whmTruckNumId === this.state.whmTruckNumId
                          ? "check-circle"
                          : "circle"
                      }
                    />
                  }
                  color="green"
                  block
                  onClick={() => this.selectTruck(tr)}
                  appearance={
                    tr.whmTruckNumId === this.state.whmTruckNumId
                      ? "default"
                      : "ghost"
                  }
                >
                  {tr.name}
                </IconButton>
              </Col3>
            ))}
          </FlexboxGrid>
        </UnifiedModal>
        <Empty description="No Active Loading Jobs" />
        <Div14>Available Trucks:</Div14>
        <FlexboxGrid justify="center">{readytrucks}</FlexboxGrid>
        <FlexboxButton
          icon="truck"
          color="green"
          text={
            whmOldWarehouseStore.freetruck.length <= 0
              ? "All Trucks Are Busy"
              : `Create a Loading Job`
          }
          disabled={whmOldWarehouseStore.freetruck.length <= 0}
          oC={() => this.setState({ show: true })}
        />
      </div>
    );
  }
}

export default withRouter(observer(WHMNewLoadingJob));
