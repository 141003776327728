import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";

import allPaymentsStore from "../../../../Stores/Financial/AllPaymentsStore";
import Div14 from "../../../../Components/Dividers/Div14";

class PaymentGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let data = allPaymentsStore.dailypayments;

    let series = [
      { name: "Income", data: data.finalin },
      { name: "Expense", data: data.finalex },
      { name: "Balance", data: data.final },
    ];
    let options = {
      chart: { height: 350, type: "area" },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth" },
      xaxis: { type: "datetime", categories: data.labels },
      tooltip: { x: { format: "dd-MMM-yy" } },
      colors: ["#00ff2a", "#f50c46", "#0c79f5"],
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "black",
            label: {
              show: true,
              text: "",
              style: {
                color: "#fff",
                background: "#00E396",
              },
            },
          },
        ],
      },
    };

    return (
      <div>
        <Div14>Daily Payments</Div14>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
    );
  }
}

export default withRouter(observer(PaymentGraph));
