import { Tag, PageHeader, Alert, Badge } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, IconButton } from "rsuite";
import newDeliveryStore from "../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import userStore from "../../../../Stores/UserStore";
import OrderSummaryTable from "../Modals/Supporting/OrderSummaryTable";

class SingleACOrderHeader extends Component {
  render() {
    let del = newDeliveryStore.olddel;
    let account = newDeliveryStore.account;
    let tagcolor = "orange";
    if (del.status === "Requested") tagcolor = "orange";
    if (del.status === "Booked") tagcolor = "green";
    if (del.status === "In Transit") tagcolor = "blue";
    if (del.status === "Completed") tagcolor = "darkgreen";
    if (del.status === "Cancelled") tagcolor = "darkred";

    let cancbut = (
      <IconButton
        icon={<Icon icon="trash" />}
        color="red"
        size="sm"
        disabled={newDeliveryStore.stopedit}
        onClick={() => this.props.changeST(true, "showCancel")}
      >
        Cancel Job
      </IconButton>
    );
    if (del.status === "Cancelled") {
      cancbut = <div />;
      if (userStore.user.staffaccount) {
        cancbut = (
          <IconButton
            icon={<Icon icon="check" />}
            color="green"
            size="sm"
            appearance="ghost"
            disabled={newDeliveryStore.stopedit}
            onClick={() => this.props.changeST(true, "showCancel")}
          >
            Re-Activate Job
          </IconButton>
        );
      }
    }
    return (
      <div>
        <PageHeader
          ghost={true}
          onBack={() => this.props.history.push(`/ac/dashboard/${account._id}`)}
          title={`Delivery Order #${del.deliveryNumId} for ${account.name}`}
          tags={
            <Tag color={tagcolor} key={1}>
              <Badge
                status={
                  del.status === "Cancelled" || del.status === "Completed"
                    ? "default"
                    : "processing"
                }
                style={{
                  color:
                    del.status === "Cancelled" || del.status === "Completed"
                      ? "white"
                      : "black",
                }}
                color={
                  del.status === "Cancelled" || del.status === "Completed"
                    ? "white"
                    : tagcolor
                }
                text={del.status}
              />
            </Tag>
          }
          extra={[
            <IconButton
              size="sm"
              icon={<Icon icon="web" />}
              appearance="ghost"
              color="green"
              onClick={() =>
                this.props.history.push(`/ac/dashboard/${account._id}`)
              }
            >
              Company Dash
            </IconButton>,
            <IconButton
              size="sm"
              icon={<Icon icon="refresh" />}
              appearance="primary"
              onClick={() => newDeliveryStore.getSingleACOrder(false, true)}
            >
              Refresh Data
            </IconButton>,
            cancbut,
          ]}
        >
          <OrderSummaryTable
            del={del}
            timechg={newDeliveryStore.ctimechg}
            account={newDeliveryStore.account}
            hidetitle
          />
        </PageHeader>
        {newDeliveryStore.stopedit && (
          <Alert
            message={
              del.status === "In Transit" || del.status === "Completed"
                ? `Job has already started with the status being ${del.status}. For order modifications please contact our office.`
                : !userStore.user.staffaccount & !userStore.user.comprequest
                ? "Kindly note editing access has not been provided, please contact our team to provide request editing access."
                : "Job is within 2 hours, for order modifications please contact our office."
            }
            type="warning"
            showIcon
            closable
            style={{ paddingLeft: "2%", paddingRight: "2%" }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(SingleACOrderHeader));
