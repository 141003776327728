import { observer } from "mobx-react";
import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import moment from "moment";
import HeaderFooterPDF from "../../Template/HeaderFooterPDF";
import HeaderSection from "./HeaderSection";
import { pdf } from "@react-pdf/renderer";
import { InputGroup, Input, Icon, HelpBlock, Notification } from "rsuite";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import newStorageStore from "../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import userStore from "../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class StorageNotePDF extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", loading: false };
    this.editVal = this.editVal.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
  editVal(val, label) {
    this.setState({ [label]: val, loading: false });
  }

  async sendEmail() {
    try {
      let sto = this.props.sto;
      let client = this.props.client;
      let type = this.props.type;
      let inout = newStorageStore.delstoinout.inout;

      this.setState({ loading: true });

      let docTitle = `Storage Note ${sto.acOrderNumId}-
      ${moment().format("YYMMDDHH")} for ${client.name}`;
      const blob = await pdf(
        <Document
          author={"Easy Move Logistics Co."}
          title={`Storage Note ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
          creator={"Easy Move Logistics Co."}
        >
          <HeaderFooterPDF>
            <HeaderSection sto={sto} client={client} type={type} />
          </HeaderFooterPDF>
        </Document>
      ).toBlob();
      var file = new File([blob], `${docTitle}.pdf`, {
        lastModified: new Date().getTime(),
        type: "pdf",
      });

      const formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("emailtitle", `Storage ${inout}`);
      formData.append("title", docTitle);
      formData.append("numId", sto.acOrderNumId);
      formData.append(
        "date",
        moment(sto.storSignature.createdAt).format("DD-MMM-YY")
      );
      formData.append("city", sto.emlocation.city);
      formData.append("totype", sto.emlocation.type);
      formData.append("maintype", sto.jobtype);
      formData.append("address", sto.emlocation.address);
      formData.append("accountname", client.name);
      formData.append("Attachment", file, `${docTitle}.pdf`);

      return instance
        .put(`track/emailstorage/emailsinglefrom/${sto._id}`, formData, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((res) => {
          Notification["success"]("Email Sent.");
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          Notification["error"]("Not able to send");
        });
    } catch (e) {
      this.setState({ loading: false });
      Notification["error"]("Not able to send");
      console.error(e);
    }
  }
  render() {
    let sto = this.props.sto;
    let client = this.props.client;
    let type = this.props.type;

    let showbutton = false;
    let showtext = false;
    let disabled = true;
    if (this.state.email.length > 3) {
      showbutton = true;
      disabled = !validateEmail(this.state.email);
      showtext = !validateEmail(this.state.email);
    }
    return (
      <div>
        <InputGroup>
          <InputGroup.Addon>Send Email</InputGroup.Addon>
          <Input
            size={"lg"}
            value={this.state.email}
            disabled={this.state.loading}
            onChange={(e) => this.editVal(e, "email")}
          />
          {showbutton && (
            <>
              <InputGroup.Button
                color="green"
                loading={this.state.loading}
                onClick={() => this.sendEmail()}
                disabled={disabled}
              >
                <Icon icon="send" />
              </InputGroup.Button>
            </>
          )}
        </InputGroup>
        {showtext && (
          <HelpBlock style={{ fontStyle: "italic", color: "orange" }}>
            Please Enter a Valid Email.
          </HelpBlock>
        )}
        <hr />
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Storage Note ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Storage Note ${sto.acOrderNumId}-
                ${moment().format("YYMMDDHH")} for ${client.name}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection sto={sto} client={client} type={type} />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(StorageNotePDF);
