import { AddChargeCalc } from "./AddChargeCalc";
import { Parsed } from "./PRKDCalc";

function CCPriceCalc(job) {
  let lastprice = Parsed(job.cc.lastprice);
  let addcharge = AddChargeCalc(job.addcharge);
  let price = Parsed(lastprice) + Parsed(addcharge);
  return [price, lastprice, addcharge];
}

export { CCPriceCalc };
