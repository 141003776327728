import { Card, CardActionArea } from "@material-ui/core";
import { message, PageHeader, Statistic } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { FlexboxGrid, Loader } from "rsuite";
import Col2 from "../../../Components/Columns/Col2";
import Col3 from "../../../Components/Columns/Col3";
import Div14 from "../../../Components/Dividers/Div14";
import UnifiedModal from "../../../Components/Modal/UnifiedModal";
import completeDeliveryStore from "../../../Stores/Account/CompleteDeliveryStore";
import ActualQuantityRow from "./Supporting/ActualQuantityRow";
import ExistingItemIn from "./Supporting/ExistingItemIn";
import NewItemModal from "./Supporting/NewItemModal";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../../Stores/UserStore";
import ModalFooter from "../../../Components/Modal/ModalFooter";
import CloseDelivery from "./CloseDelivery";
import HeaderInfo from "./Supporting/HeaderInfo";

const instance = axios.create({ baseURL: baseURL });

class CompleteDelMain0 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showclose: false,
      loading: false,
      itemmove: {
        acitem: { name: "", productid: "", serialnumber: "", acItemNumId: 0 },
        quantity: 1,
        actualquantity: 1,
      },
    };
    this.onHide = this.onHide.bind(this);
    this.delItem = this.delItem.bind(this);
    this.postDelete = this.postDelete.bind(this);
    this.showClose = this.showClose.bind(this);
  }
  showClose() {
    this.setState({ showclose: true });
  }
  resetitemmove() {
    let itmove = {
      acitem: { name: "", productid: "", serialnumber: "", acItemNumId: 0 },
      quantity: 1,
      actualquantity: 1,
    };
    this.setState({ itemmove: itmove });
  }
  onHide() {
    this.resetitemmove();
    this.setState({ show: false, showclose: false });
  }
  delItem(itemmove) {
    this.setState({ show: true, itemmove: itemmove });
  }
  componentDidMount() {
    completeDeliveryStore.getSingleOrder(this.props.match.params.orderid, true);
  }
  postDelete(itemmove) {
    let data = {};

    let url = `/ac/advitemmove/delete/${itemmove._id}`;
    this.setState({ loading: true });
    return instance
      .put(`${url}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        completeDeliveryStore.removeItemMove(itemmove);
        message.success("Deleted Item from Delivery");
        this.setState({ loading: false });
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Count Not Delete Item from Delivery");
      });
  }
  render() {
    let cds = completeDeliveryStore;
    let disabled = false;

    if (cds.neworder.jobdate) disabled = true;

    let accountid = cds.neworder.account._id;
    let orderid = cds.neworder._id;
    let warehouseid;
    if (cds.neworder.emlocid) {
      warehouseid = cds.neworder.emlocid;
    }
    if (cds.loading.order) {
      return <Loader center />;
    }
    let inout = cds.stordelinout.inout;
    let stordel = cds.stordelinout.stordel;

    let rowdata;
    if (cds.finalitemmoves) {
      if (cds.finalitemmoves.length > 0) {
        rowdata = cds.finalitemmoves.map((itemmove, index) => (
          <ActualQuantityRow
            itemmove={itemmove}
            key={index}
            index={index}
            delItem={this.delItem}
            disabled={disabled}
          />
        ));
      }
    }
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["trash", "close"]}
              color={["red", "green"]}
              app={["default", "default"]}
              text={["Delete from Order", "Cancel"]}
              oC1={() => this.postDelete(this.state.itemmove)}
              oC2={this.onHide}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
          title={"Remove Item from Delivery"}
        >
          <h6>Are you sure you want to Delete this item from the delivery?</h6>
          <p>
            <b>Item Name: </b> {this.state.itemmove.acitem.name} <br />
            <b>Product ID: </b> {this.state.itemmove.acitem.productid} <br />
            <b>Serial Number: </b> {this.state.itemmove.acitem.serialnumber}{" "}
            <br />
            <b>Quantity: </b> {this.state.itemmove.quantity} <br />
          </p>
        </UnifiedModal>

        <PageHeader
          title={`Confirm ${cds.neworder.jobtype} #${cds.neworder.acOrderNumId}`}
          subTitle={`(Status: ${cds.neworder.status})`}
          onBack={() =>
            this.props.history.push(`/ac/dashboard/${cds.client._id}`)
          }
        />
        <CloseDelivery
          stordel={stordel}
          inout={inout}
          sto={cds.neworder}
          showClose={this.showClose}
        />
        <br />
        <HeaderInfo cds={cds} />

        {!disabled && (
          <div>
            <Div14>Staff Actions:</Div14>
            <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
              {inout === "In" ? (
                <FlexboxGrid>
                  <Col2>
                    <NewItemModal
                      accountid={accountid}
                      orderid={orderid}
                      warehouseid={warehouseid}
                    />
                  </Col2>
                  <Col2>
                    <ExistingItemIn
                      accountid={accountid}
                      orderid={orderid}
                      warehouseid={warehouseid}
                    />
                  </Col2>
                </FlexboxGrid>
              ) : (
                <ExistingItemIn
                  accountid={accountid}
                  orderid={orderid}
                  warehouseid={warehouseid}
                />
              )}
            </div>
          </div>
        )}
        <Div14>Order Stats:</Div14>
        <FlexboxGrid style={{ textAlign: "center", fontWeight: "bold" }}>
          <Col3>
            <Card
              style={{
                border:
                  cds.filter === "confirmed" || cds.filter === ""
                    ? "4px solid #3f8600"
                    : "",
              }}
            >
              <CardActionArea
                onClick={() => completeDeliveryStore.changeFilt("confirmed")}
              >
                <Statistic
                  title="Confirmed"
                  value={
                    cds.itemMoveStats.confirmed === 0
                      ? "-"
                      : cds.itemMoveStats.confirmed
                  }
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </CardActionArea>
            </Card>
          </Col3>
          <Col3>
            <Card
              style={{
                border:
                  cds.filter === "modified" || cds.filter === ""
                    ? "4px solid orange"
                    : "",
              }}
            >
              <CardActionArea
                onClick={() => completeDeliveryStore.changeFilt("modified")}
              >
                <Statistic
                  title="Modified"
                  value={
                    cds.itemMoveStats.modified === 0
                      ? "-"
                      : cds.itemMoveStats.modified
                  }
                  precision={0}
                />
              </CardActionArea>
            </Card>
          </Col3>
          <Col3>
            <Card
              style={{
                border:
                  cds.filter === "missing" || cds.filter === ""
                    ? "4px solid #cf1322"
                    : "",
              }}
            >
              <CardActionArea
                onClick={() => completeDeliveryStore.changeFilt("missing")}
              >
                <Statistic
                  title="Missing"
                  value={
                    cds.itemMoveStats.missing === 0
                      ? "-"
                      : cds.itemMoveStats.missing
                  }
                  precision={0}
                  valueStyle={{ color: "#cf1322" }}
                />
              </CardActionArea>
            </Card>
          </Col3>
        </FlexboxGrid>

        <Div14>
          ({cds.finalitemmoves.length}) Item List:{" "}
          {cds.filter !== "" &&
            cds.filter.charAt(0).toUpperCase() + cds.filter.slice(1)}{" "}
        </Div14>
        <div style={{ minHeight: 500 }}>{rowdata}</div>
      </div>
    );
  }
}

export default withRouter(observer(CompleteDelMain0));
