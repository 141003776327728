import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { Parallax } from "react-parallax";

class ParallaxImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Parallax
        bgImage={this.props.image}
        blur={{ min: -15, max: 15 }}
        bgImageAlt={this.props.alt}
        strength={-200}
        bgStyle={{ margin: 0, padding: 0 }}
        renderLayer={() => (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: `rgba(0, 0, 0, 0.6)`,
            }}
          />
        )}
      >
        <div
          style={{
            padding: "10%",
            textAlign: "center",
            top: "5%",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "10px",
          }}
        >
          {this.props.children}
        </div>
      </Parallax>
    );
  }
}

export default withRouter(observer(ParallaxImage));
