import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../Static/baseURL/baseURL";
const instance = axios.create({ baseURL: baseURL });

class ModalItemStore {
  constructor() {
    this.item = {
      name: "",
      loctags: [],
      searchtags: [],
      currentplusreserve: 0,
    };
    this.original = {
      name: "",
      productid: "",
      serialnumber: "",
      length: 0,
      width: 0,
      height: 0,
    };
    this.itemIn = [];
    this.itemOut = [];
    this.itemInv = [];
    this.moves = [];
    this.loading = { item: false };
    this.loaded = { item: false };

    makeObservable(this, {
      item: observable,
      original: observable,
      itemIn: observable,
      itemOut: observable,
      itemInv: observable,
      moves: observable,
      loading: observable,
      loaded: observable,
      pastmoves: computed,
      upcomoves: computed,
    });
  }

  getEditItem(itemId) {
    this.loading.item = true;
    this.moves = [];
    let url = `/modal/item/singleitem/${itemId}`;
    return instance
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        this.item = data;
        this.original = data;
        console.log("Edit Item Details Fetched");
      })
      .then((data) => (this.loading.item = false))
      .catch((err) => {
        console.log(err);

        this.loading.item = false;
        Notification["error"]({
          title: `Error Fetching Edit Item Details.`,
        });
      });
  }

  // FETCH
  getSingleItem(itemId, override) {
    let loaded = false;
    if (this.loading.item) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.item = true;
      this.moves = [];
      let url = `/modal/item/itemhistory/${itemId}`;

      return instance
        .get(url)
        .then((res) => res.data)
        .then((data) => {
          this.item = data.item;
          this.original = data.item;
          this.itemIn = data.itemIn;
          this.itemOut = data.itemOut;
          this.itemInv = data.itemInv;
          this.moves = data.itemmove;

          console.log("Item Details Fetched");
        })
        .then((data) => (this.loading.item = false))
        .catch((err) => {
          console.log(err);

          this.loading.item = false;
          Notification["error"]({
            title: `Error Fetching Item Details.`,
          });
        });
    }
  }

  // ----- Computed ----

  get pastmoves() {
    let moves = this.moves.filter((move) => move.orderstatus === "Completed");
    let newmoves = [];
    let afterstock = 0;
    for (let i = 0; i < moves.length; i++) {
      if (moves[i].type === "In") {
        afterstock += moves[i].actualquantity;
      } else {
        afterstock -= moves[i].actualquantity;
      }
      newmoves = [...newmoves, { ...moves[i], afterstock: afterstock }];
    }
    return newmoves;
  }
  get upcomoves() {
    let moves = this.moves.filter(
      (move) =>
        move.orderstatus === "Booked" ||
        move.orderstatus === "Requested" ||
        move.orderstatus === "In Transit"
    );
    let newmoves = [];
    let afterstock = this.item.currentplusreserve;
    for (let i = 0; i < moves.length; i++) {
      if (moves[i].type === "In") {
        afterstock += moves[i].actualquantity;
      } else {
        afterstock -= moves[i].actualquantity;
      }
      newmoves = [...newmoves, { ...moves[i], afterstock: afterstock }];
    }
    return newmoves;
  }

  // ----- Functions ----

  editItem(val, label) {
    modalItemStore.item[label] = val;
  }
  editOrg(val, label) {
    modalItemStore.original[label] = val;
  }
  updateSearchTags(tags) {
    this.item.searchtags = [...tags];
  }
  updateLocTags(tags) {
    this.item.loctags = [...tags];
  }
}

const modalItemStore = new ModalItemStore();
export default modalItemStore;
