import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import AuthMain from "./AuthMain";
import UserNameInput from "../../../Components/Inputs/Auth/UserNameInput";
import userStore from "../../../Stores/UserStore";
import { Button, HelpBlock, Notification } from "rsuite";
import { Result } from "antd";
import axios from "axios";
import baseURL from "../../../Static/baseURL/baseURL";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const instance = axios.create({ baseURL: baseURL });

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      email: "",
      checked: false,
      loading: true,
      available: true,
    };
    this.changeView = this.changeView.bind(this);
    this.sendPassLink = this.sendPassLink.bind(this);
  }
  sendPassLink = async () => {
    this.setState({ loading: true });
    let data = { email: this.state.email };
    await instance
      .post(`auth/passreset/resetpassword`, data)
      .then((res) => {
        Notification["success"]({
          title: `Password Reset Link Sent`,
        });
        this.setState({ loading: false, checked: true });
      })
      .catch((err) => {
        this.setState({ loading: false, checked: false });
        console.log(err);
        Notification["error"]({
          title: `Please check your email is accurate.`,
        });
      });
  };

  changeView(type, val) {
    if (type === "email") {
      //   val = val.replace(/[^a-zA-Z1-9]/g, "").toLowerCase();
      val = val.toLowerCase();
    }
    this.setState({ [type]: val });
  }

  render() {
    let emailerror = { val: false, message: "" };
    let disabledButton = false;
    let user = {
      email: this.state.email,
    };

    if (user.email.length < 3 || !validateEmail(this.state.email)) {
      disabledButton = true;
      emailerror = {
        val: true,
        message: "Please write a valid Email Address.",
      };
    }

    let content = (
      <Result
        status="success"
        title="Password Reset Link Sent."
        subTitle="The password reset link has been sent to your email. Please note that it expires within a few hours. Please contact our office if you did not receive any email."
      />
    );

    return (
      <AuthMain type="passreset">
        <h4 style={{ textAlign: "center" }}> Forgot Password </h4>
        <hr />
        {this.state.checked ? (
          content
        ) : (
          <>
            <UserNameInput
              data={user.email}
              err={emailerror.val}
              errmessage={emailerror.message}
              type={"email"}
              label="Email"
              disabled={userStore.loading}
              autoFocus={true}
              action={this.changeView}
            />
            <HelpBlock>Please write in your email above.</HelpBlock>
            <br />
            <Button
              block
              color="green"
              disabled={disabledButton}
              style={{ boxShadow: "0px 0px 3px black" }}
              onClick={() => {
                this.sendPassLink();
              }}
            >
              {userStore.loading ? "Loading ..." : "Get Password Reset Link"}
            </Button>
          </>
        )}
      </AuthMain>
    );
  }
}

export default withRouter(observer(ForgotPass));
