import { observer } from "mobx-react";
import React, { Component } from "react";
import { Icon, IconButton, FlexboxGrid, Col, Divider, HelpBlock } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import Div14 from "../../../../Components/Dividers/Div14";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";
import userStore from "../../../../Stores/UserStore";
import Col3 from "../../../../Components/Columns/Col3";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import editAccountStore from "../../../../Stores/Account/EditAccountStore";

import TimingSection from "./StorageSupport/TimingSection";
import StorDelOptions from "./StorageSupport/StorDelOptions";
import SelectInputSTU from "../../../../Components/Inputs/Updatable/SelectInputSTU";
import NumbInputSTU from "../../../../Components/Inputs/Updatable/NumbInputSTU";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import TextAreaInputSTU from "../../../../Components/Inputs/Updatable/TextAreaInputSTU";

const instance = axios.create({ baseURL: baseURL });

class StorageLocation extends Component {
  render() {
    let loc = this.props.loc;
    let sto = this.props.sto;

    let indexVal = sto.emlocations.findIndex((stol) => stol._id === loc._id);
    let selected = true;
    if (indexVal < 0) selected = false;

    return (
      <Col2>
        <IconButton
          block
          onClick={() => this.props.editLoc(loc)}
          appearance={selected ? "default" : "ghost"}
          color="green"
          icon={<Icon icon={selected ? "check-circle" : "circle"} />}
        >
          {loc.name}
        </IconButton>
      </Col2>
    );
  }
}

class StorageSections extends Component {
  constructor(props) {
    super(props);
    this.state = { locloading: false, locs: [] };
  }
  componentDidMount() {
    this.fetchEMLocations();
  }
  fetchEMLocations = async () => {
    this.setState({ locloading: true });
    await instance
      .get(`/additional/location/all`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({ locloading: false, locs: res.data });
      })
      .catch((err) => {
        this.setState({ locloading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  render() {
    let edited = editAccountStore.edited;
    let original = editAccountStore.original;
    let load = editAccountStore.load;
    let esto = edited.storageContract;
    let sto = original.storageContract;

    let rateperday = [
      { title: "Sun", label: "sun" },
      { title: "Mon", label: "mon" },
      { title: "Tue", label: "tue" },
      { title: "Wed", label: "wed" },
      { title: "Thu", label: "thu" },
      { title: "Fri", label: "fri" },
      { title: "Sat", label: "sat" },
    ];
    let numbs = [
      { title: "Weekly Rate (KD)", label: "fixedweekly" },
      { title: "Staff Count", label: "staffcount" },
      { title: "Car Count", label: "carcount" },
      { title: "Free Weekly Trips", label: "freeweeklytrips" },
      { title: "Max CBM Per Trip", label: "maxcbmpertrip" },
    ];

    let zones = [
      { title: "Zone 1", label: "z1" },
      { title: "Zone 2", label: "z2" },
      { title: "Zone 3", label: "z3" },
      { title: "Zone 4", label: "z4" },
      { title: "Zone 5", label: "z5" },
    ];
    let zonerows = zones.map((zone, index) => (
      <Col3 key={index}>
        <NumbInputSTD
          title={zone.title}
          editVal={editAccountStore.editstozone}
          val={esto.zoneadd[zone.label]}
          min={0}
          size="medium"
          label={zone.label}
        />
      </Col3>
    ));
    let dayrate = rateperday.map((rate, index) => (
      <Col3 key={index}>
        <NumbInputSTD
          title={rate.title}
          editVal={editAccountStore.editstodayrate}
          val={esto.pricepertrip[rate.label]}
          min={0}
          size="medium"
          label={rate.label}
        />
      </Col3>
    ));

    let dayratechange = false;
    for (let i = 0; i < rateperday.length; i++) {
      if (
        parseFloat(esto.pricepertrip[rateperday[i].label]) !==
        parseFloat(sto.pricepertrip[rateperday[i].label])
      ) {
        dayratechange = true;
      }
    }
    let originaldayrate = (
      <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
        Changed from{" "}
        <i>
          Sun ({PRKD(sto.pricepertrip.sun)} KD); Mon (
          {PRKD(sto.pricepertrip.mon)} KD); Tue ({PRKD(sto.pricepertrip.tue)}{" "}
          KD); Wed ({PRKD(sto.pricepertrip.wed)} KD); Thu (
          {PRKD(sto.pricepertrip.thu)} KD); Fri ({PRKD(sto.pricepertrip.fri)}{" "}
          KD); Sat ({PRKD(sto.pricepertrip.sat)} KD);{" "}
        </i>
      </HelpBlock>
    );

    let zonechange = false;
    for (let i = 0; i < zones.length; i++) {
      if (
        parseFloat(esto.zoneadd[zones[i].label]) !==
        parseFloat(sto.zoneadd[zones[i].label])
      ) {
        zonechange = true;
      }
    }
    let originalzone = (
      <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
        Changed from{" "}
        <i>
          Zone 1 ({PRKD(sto.zoneadd.z1)} KD); Zone 2 ({PRKD(sto.zoneadd.z2)}{" "}
          KD); Zone 3 ({PRKD(sto.zoneadd.z3)} KD); Zone 4 (
          {PRKD(sto.zoneadd.z4)} KD); Zone 5 ({PRKD(sto.zoneadd.z5)} KD);
        </i>
      </HelpBlock>
    );
    let eflocs = [];
    let flocs = [];
    let elocs = editAccountStore.edited.storageContract.emlocations;
    let locs = editAccountStore.original.storageContract.emlocations;
    let locchange = elocs.length !== locs.length;
    if (!locchange) {
      for (let i = 0; i < elocs.length; i++) {
        eflocs = [...eflocs, PRKD(elocs[i].locationNumId)];
      }
      for (let i = 0; i < locs.length; i++) {
        flocs = [...flocs, PRKD(locs[i].locationNumId)];
      }
      flocs = flocs.sort();
      eflocs = eflocs.sort();
      for (let i = 0; i < flocs.length; i++) {
        if (PRKD(flocs[i]) !== PRKD(eflocs[i])) locchange = true;
      }
    }

    return (
      <div>
        <Div14>Storage Section: </Div14>

        <div
          style={{
            borderRadius: "5px",
            border: "1px solid black",
            paddingLeft: "1%",
            paddingRight: "1%",
            boxShadow: "0px 0px 5px black",
          }}
        >
          <Div14>Main Details:</Div14>
          <FlexboxGrid>
            <Col2 style={{ paddingBottom: "5px" }}>
              <Div14>Storage:</Div14>
              <div style={{ paddingBottom: "5px" }}>
                <SelectInputSTU
                  val={esto.maintype}
                  org={sto.maintype}
                  label="maintype"
                  load={load.maintype}
                  title="Main Type"
                  size="medium"
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  opts={["Daily Rate", "Weekly Rate", "Monthly Rate"]}
                />
              </div>
              <div style={{ paddingBottom: "5px" }}>
                <SelectInputSTU
                  val={esto.subtype}
                  org={sto.subtype}
                  label="subtype"
                  load={load.subtype}
                  title="Sub Type"
                  size="medium"
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  opts={["Average", "Maximum"]}
                />
              </div>
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <Div14>Delivery:</Div14>
              <div style={{ paddingBottom: "5px" }}>
                <SelectInputSTU
                  val={esto.delmaintype}
                  org={sto.delmaintype}
                  label="delmaintype"
                  load={load.delmaintype}
                  title="Delivery Type"
                  size="medium"
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  opts={[
                    "Dedicated Staff",
                    "Dedicated Staff - Monthly",
                    "Fixed Per Type",
                    "Fixed Per Trip",
                    "Fixed Per Type",
                  ]}
                />
              </div>
            </Col2>
          </FlexboxGrid>
          <br />
          <Div14>Pricing Details:</Div14>
          <FlexboxGrid>
            <Col2 style={{ paddingBottom: "5px" }}>
              <Div14>Storage:</Div14>
              <div style={{ paddingBottom: "5px" }}>
                <NumbInputSTU
                  val={esto.pricepercbm}
                  org={sto.pricepercbm}
                  label={"pricepercbm"}
                  title={"Price Per CBM"}
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  min={0}
                  size="medium"
                />
              </div>
              <div style={{ paddingBottom: "5px" }}>
                <NumbInputSTU
                  val={esto.mincbm}
                  org={sto.mincbm}
                  label={"mincbm"}
                  title={"Minimum CBM"}
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  min={0}
                  size="medium"
                />
              </div>
              <div style={{ paddingBottom: "5px" }}>
                <NumbInputSTU
                  val={esto.incharge}
                  org={sto.incharge}
                  label={"incharge"}
                  title={"In Charge (KD/CBM)"}
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  min={0}
                  size="medium"
                />
              </div>
              <div style={{ paddingBottom: "5px" }}>
                <NumbInputSTU
                  val={esto.outcharge}
                  org={sto.outcharge}
                  label={"outcharge"}
                  title={"Out Charge (KD/CBM)"}
                  editVal={editAccountStore.editSto}
                  updateVal={editAccountStore.updateSTO}
                  min={0}
                  size="medium"
                />
              </div>
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <Div14>Delivery:</Div14>
              <div>
                {numbs.map((num, index) => (
                  <div key={index} style={{ paddingBottom: "5px" }}>
                    <NumbInputSTU
                      val={esto[num.label]}
                      org={sto[num.label]}
                      title={num.title}
                      label={num.label}
                      editVal={editAccountStore.editSto}
                      updateVal={editAccountStore.updateSTO}
                      min={0}
                      size="medium"
                    />
                  </div>
                ))}
              </div>
              <div>
                <br />
                <FlexboxGrid justify="space-between">
                  <Col md={20} sm={16} xs={12}>
                    <p>Daily Delivery Rates: (KD Per Trip) </p>
                  </Col>

                  <Col md={4} sm={8} xs={12}>
                    {dayratechange && (
                      <div>
                        <IconButton
                          icon={<Icon icon="save" />}
                          color="green"
                          circle
                          size="xs"
                          onClick={() =>
                            editAccountStore.updateSTO(
                              "pricepertrip",
                              "pricepertrip"
                            )
                          }
                        />
                        <Divider vertical />
                        <IconButton
                          icon={<Icon icon="undo" />}
                          color="grey"
                          circle
                          size="xs"
                          onClick={() => editAccountStore.resetdayrate()}
                        />
                      </div>
                    )}
                  </Col>
                </FlexboxGrid>
                {dayratechange && originaldayrate}
                <FlexboxGrid>{dayrate}</FlexboxGrid>
              </div>

              <div>
                <br />
                <FlexboxGrid justify="space-between">
                  <Col md={20} sm={16} xs={12}>
                    <p>Zone Additions: (KD Extra Per Zone)</p>
                  </Col>

                  <Col md={4} sm={8} xs={12}>
                    {zonechange && (
                      <div>
                        <IconButton
                          icon={<Icon icon="save" />}
                          color="green"
                          circle
                          size="xs"
                          onClick={() =>
                            editAccountStore.updateSTO("zoneadd", "zoneadd")
                          }
                        />
                        <Divider vertical />
                        <IconButton
                          icon={<Icon icon="undo" />}
                          color="grey"
                          circle
                          size="xs"
                          onClick={() => editAccountStore.resetzone()}
                        />
                      </div>
                    )}
                  </Col>
                </FlexboxGrid>
                {zonechange && originalzone}

                <FlexboxGrid>{zonerows}</FlexboxGrid>
              </div>
            </Col2>
          </FlexboxGrid>

          <br />

          <Div14>Additional Details:</Div14>
          <FlexboxGrid>
            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                val={esto.timetoreq}
                org={sto.timetoreq}
                title={"Time to Request (hrs)"}
                label={"timetoreq"}
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                min={0}
                size="medium"
              />
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                title={"After Hour Request Fees (KD)"}
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                val={esto.afterhourfee}
                org={sto.afterhourfee}
                min={0}
                size="medium"
                label={"afterhourfee"}
              />
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                val={esto.expresstime}
                org={sto.expresstime}
                title={"Express Request Time (hrs)"}
                label={"expresstime"}
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                min={0}
                size="medium"
              />
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                title={"Express Request Fees (KD)"}
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                val={esto.expressfee}
                org={sto.expressfee}
                min={0}
                size="medium"
                label={"expressfee"}
              />
            </Col2>

            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                title="Hours to Cancel"
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                val={esto.cancelhours}
                org={sto.cancelhours}
                min={0}
                size="medium"
                label={"cancelhours"}
              />
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                title="Cancellation Fee (KD)"
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                val={esto.cancelprice}
                org={sto.cancelprice}
                min={0}
                size="medium"
                label={"cancelprice"}
              />
            </Col2>
            <Col2 style={{ paddingBottom: "5px" }}>
              <NumbInputSTU
                title="Non-Ground Addition"
                editVal={editAccountStore.editSto}
                updateVal={editAccountStore.updateSTO}
                val={esto.nongroundfloorprice}
                org={sto.nongroundfloorprice}
                min={0}
                size="medium"
                label={"nongroundfloorprice"}
              />
            </Col2>
          </FlexboxGrid>
          <div style={{ padding: "1%" }}>
            <TextAreaInputSTU
              title="Storage Contract Remarks"
              editVal={editAccountStore.editSto}
              val={esto.remarks}
              updateVal={editAccountStore.updateSTO}
              org={sto.remarks}
              size="medium"
              label={"remarks"}
            />
          </div>
          <br />
          <FlexboxGrid justify="space-between">
            <Col md={20} sm={16} xs={12}>
              <p>
                Warehouse Access:
                {locchange && (
                  <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                    Changed from{" "}
                    <i>
                      {" "}
                      {locs.length} Location{locs.length !== 1 ? "s" : ""}:{" "}
                      {locs.map((loc) => `${loc.name}; `)}
                    </i>
                  </HelpBlock>
                )}
              </p>
            </Col>
            <Col md={4} sm={8} xs={12}>
              {locchange && (
                <div>
                  <IconButton
                    icon={<Icon icon="save" />}
                    color="green"
                    circle
                    onClick={() =>
                      editAccountStore.updateSTO("emlocations", "emlocations")
                    }
                  />
                  <Divider vertical />
                  <IconButton
                    icon={<Icon icon="undo" />}
                    color="grey"
                    circle
                    onClick={() =>
                      editAccountStore.resetSingleLabel("emlocations")
                    }
                  />
                </div>
              )}
            </Col>
          </FlexboxGrid>
          <br />
          <FlexboxGrid>
            {this.state.locs.map((loc, index) => (
              <StorageLocation
                loc={loc}
                key={index}
                sto={esto}
                editLoc={editAccountStore.editLoc}
              />
            ))}
          </FlexboxGrid>
          <br />
          <TimingSection />
          <StorDelOptions />
        </div>
      </div>
    );
  }
}

export default observer(StorageSections);
