import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import Div14 from "../../../../Components/Dividers/Div14";
import { Badge, Descriptions, Statistic } from "antd";

import moment from "moment";
import { FlexboxGrid } from "rsuite";
import { Collapse } from "antd";

const { Panel } = Collapse;

class MainAccountInfo extends Component {
  render() {
    let client = this.props.client;
    let status = "default";
    if (client.status === "Active") {
      status = "processing";
    }
    let daystostart = moment(client.startdate).fromNow();
    let daystoend = moment(client.enddate).fromNow();

    let dayscomplete = moment().diff(client.startdate, "days");
    let totaltime = moment(client.enddate).diff(client.startdate, "days");
    let percentcomplete = dayscomplete / totaltime;
    if (percentcomplete > 1) percentcomplete = 1;

    percentcomplete = Math.round(percentcomplete * 100);
    let hidetabs = this.props.hidetabs;
    return (
      <div>
        <br />
        <FlexboxGrid justify="space-around">
          <Statistic
            title={"Status"}
            value={client.status}
            prefix={
              <Badge
                status={status}
                span={3}
                size="large"
                color={
                  client.status === "Active"
                    ? "green"
                    : client.status === "Cancelled"
                    ? "red"
                    : client.status === "Pending"
                    ? "orange"
                    : "darkgreen"
                }
              />
            }
            style={{
              border: `3px solid ${
                client.status === "Active"
                  ? "green"
                  : client.status === "Cancelled"
                  ? "red"
                  : client.status === "Pending"
                  ? "orange"
                  : "darkgreen"
              }`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
          <Statistic
            title={`Start (${daystostart})`}
            value={moment(client.startdate).format("DD-MMM-YY")}
            style={{
              border: `3px solid ${
                moment().diff(client.startdate) < 0 ? "orange" : "green"
              }`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
          <Statistic
            title={`End (${daystoend})`}
            value={moment(client.enddate).format("DD-MMM-YY")}
            style={{
              border: `3px solid ${
                moment().diff(client.enddate) < 0 ? "orange" : "green"
              }`,
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
          <Statistic
            title={"Duration"}
            value={`${totaltime} Days`}
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              boxShadow: "1px 1px 5px black",
              minWidth: 200,
              textAlign: "center",
            }}
          />
        </FlexboxGrid>
        {!hidetabs && (
          <>
            <Div14>Main Account Info</Div14>
            <Collapse>
              <Panel header="Main Account Info">
                <Descriptions title={false} bordered size="small">
                  <Descriptions.Item label="Status">
                    <Badge
                      status={status}
                      span={3}
                      text={client.status}
                      color={
                        client.status === "Active"
                          ? "green"
                          : client.status === "Cancelled"
                          ? "red"
                          : client.status === "Pending"
                          ? "orange"
                          : "darkgreen"
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Client">
                    {client.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {client.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone">
                    {client.phone} {client.phone2 && `/ ${client.phone2}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Start Date">
                    {moment(client.startdate).format("DD-MMM-YY")} (
                    {moment(client.startdate).fromNow()})
                  </Descriptions.Item>
                  <Descriptions.Item label="End Date">
                    {moment(client.enddate).format("DD-MMM-YY")} (
                    {moment(client.enddate).fromNow()})
                  </Descriptions.Item>
                  <Descriptions.Item label="Time Completed">
                    {dayscomplete} Days completed of {totaltime} days (
                    {percentcomplete}
                    %)
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
          </>
        )}
      </div>
    );
  }
}

export default observer(withRouter(MainAccountInfo));
