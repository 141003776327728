import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../Components/Dividers/Div14";
import { Tabs } from "antd";
import { Divider, Icon } from "rsuite";
import mgtAttendanceStore from "../../../Stores/Management/MgtAttendanceStore";
import LoadBack from "../../../Components/Loaders/LoadBack";
import AttendanceTable from "./AttendanceTable";
import { InputGroup } from "rsuite";
import DatePickerSTD from "../../../Components/Inputs/STD/DatePickerSTD";
import FlexboxButton from "../../../Components/Modal/FlexboxButton";
import AttendanceGraph from "./AttendanceGraph";

const { TabPane } = Tabs;

class AttendanceMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Staff List", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }
  componentDidMount() {
    mgtAttendanceStore.getStaff();
  }

  render() {
    let tabOrientation = "left";
    if (window.innerWidth < 800) tabOrientation = "top";
    tabOrientation = "top";
    let mgtloading = mgtAttendanceStore.loading;
    let loading =
      mgtloading.clients || mgtloading.staff || mgtloading.singleuser;

    return (
      <LoadBack loading={loading}>
        <Div14>Main Attendance</Div14>
        <div style={{ marginTop: "5px" }}>
          <InputGroup>
            <DatePickerSTD
              title={"From Date"}
              format={"DD-MMM-YY"}
              picker="date"
              label={"startdate"}
              val={mgtAttendanceStore.startdate}
              size="large"
              editVal={mgtAttendanceStore.changeDate}
            />
            <InputGroup.Button
              color="green"
              onClick={() => {
                if (this.state.tab === "Staff List") {
                  mgtAttendanceStore.getStaff(true);
                }
              }}
            >
              Reload <Divider vertical />
              <Icon icon="refresh" />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <br />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"Staff List"}
          tabPosition={tabOrientation}
          type="card"
          centered
          onChange={(e) => {
            mgtAttendanceStore.resetAttLoad();
            this.setState({ tab: e });
          }}
        >
          <TabPane
            tab={<div>EM Staff List</div>}
            key={"Staff List"}
            disabled={this.state.locked}
          >
            <AttendanceTable
              data={mgtAttendanceStore.staff}
              title={"EM Staff List"}
              emstaff
              loading={loading}
            />
          </TabPane>
          <TabPane
            tab={<div>Clients List</div>}
            key={"Clients List"}
            disabled={this.state.locked}
          >
            {mgtAttendanceStore.loaded.clients ? (
              <AttendanceTable
                data={mgtAttendanceStore.clients}
                title={"Clients List"}
                loading={loading}
              />
            ) : (
              <FlexboxButton
                icon="user"
                text="Get Client User List"
                color="green"
                loading={false}
                oC={() => {
                  mgtAttendanceStore.getClient(true);
                }}
              />
            )}
          </TabPane>
        </Tabs>
        <hr />
        <AttendanceGraph />
      </LoadBack>
    );
  }
}

export default withRouter(observer(AttendanceMain));
