import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import acDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/ACDeliveryStore";
import LoadBack from "../../../../../../Components/Loaders/LoadBack";
import DeliveryChargeHeader from "./DeliveryChargeHeader";

import DataGrid, { Column, Grouping } from "devextreme-react/data-grid";
import { GroupPanel, Export, Scrolling } from "devextreme-react/data-grid";
import { SearchPanel, Summary, TotalItem } from "devextreme-react/data-grid";
import { FilterRow, ColumnChooser } from "devextreme-react/data-grid";
import { MasterDetail, ColumnFixing } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import Div14 from "../../../../../../Components/Dividers/Div14";
import { Divider, Icon, IconButton } from "rsuite";
import { PRKD } from "../../../../../../Functions/Pricing/PRKDCalc";
import moment from "moment";
import DeliveryDetailView from "./DeliveryDetailView";

class DeliveryCharges extends Component {
  constructor(props) {
    super(props);
    this.state = { autoExpandAll: true };
    this.onExporting = this.onExporting.bind(this);
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Export");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      let delCont = acDeliveryStore.client.deliveryContract;
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${delCont.maintype}_Charges_${moment().format("DD_MMM_YYYY")}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
  DiffCell(cellData, label) {
    let data = cellData.data[label];
    if (data < 0) return <>- {PRKD(data)}</>;
    if (data > 0) return <>{PRKD(data)}</>;

    return <>-</>;
  }
  render() {
    let delCont = acDeliveryStore.client.deliveryContract;

    return (
      <LoadBack loading={acDeliveryStore.loading.delcharges}>
        <DeliveryChargeHeader />

        <div
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            textAlign: "center",
          }}
        >
          <Div14>
            {delCont.maintype === "Dedicated Staff - Monthly"
              ? "Monthly"
              : "Weekly"}{" "}
            Delivery Charges <Divider vertical />
            <IconButton
              icon={<Icon icon="refresh" />}
              size="xs"
              onClick={() => {
                acDeliveryStore.getDeliveryCharges();
              }}
              circle
            />
          </Div14>

          <DataGrid
            dataSource={acDeliveryStore.delcharges}
            keyExpr="startday"
            allowColumnReordering={true}
            allowColumnResizing={true}
            showBorders={true}
            rowAlternationEnabled
            onExporting={this.onExporting}
            style={{ fontSize: 10 }}
            searchPanel={false}
            hoverStateEnabled={true}
          >
            <FilterRow visible={false} applyFilter />
            <GroupPanel visible={false} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={this.state.autoExpandAll} />
            <Scrolling mode="standard" showScrollbar="always" />
            <ColumnChooser enabled={true} mode="select" />
            <ColumnFixing enabled={true} />
            <Column caption="Dates">
              <Column
                dataField="startday"
                dataType="date"
                caption="Start"
                alignment="center"
                format={"dd-MMM-yy"}
                width={80}
              />
              <Column
                dataField="end"
                dataType="date"
                caption="End"
                alignment="center"
                format={"dd-MMM-yy"}
                width={80}
              />
            </Column>
            <Column caption="Job Details" alignment="center">
              <Column
                dataField="jobs"
                dataType="number"
                caption="Jobs"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                width={80}
              />
              <Column
                dataField="dropoffs"
                dataType="number"
                caption="Dropoffs"
                alignment="center"
                format={{ type: "fixedPoint", precision: 0 }}
                width={80}
              />
              {delCont.maintype === "Daily Per Truck" && (
                <Column
                  dataField="missingtruckjob"
                  dataType="number"
                  caption="Jobless Days"
                  alignment="center"
                  format={{ type: "fixedPoint", precision: 0 }}
                  width={80}
                />
              )}
            </Column>
            {delCont.maintype !== "Fixed Per Type" && (
              <Column caption="Item Details" alignment="center">
                <Column
                  dataField="items"
                  caption="Items"
                  width={80}
                  alignment="center"
                  format={{ type: "fixedPoint" }}
                />

                <Column
                  dataField="qty"
                  dataType="number"
                  caption="Packages"
                  alignment="center"
                  format={{ type: "fixedPoint", precision: 0 }}
                  width={80}
                />

                <Column
                  dataField="cbm"
                  dataType="number"
                  caption="CBM"
                  alignment="center"
                  format={{ type: "fixedPoint", precision: 2 }}
                  width={80}
                />
              </Column>
            )}

            <Column caption="Delivery Charges" alignment="center">
              <Column
                dataField="mainchg"
                dataType="number"
                caption="Main Job"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={80}
                cellRender={(e) => this.DiffCell(e, "mainchg")}
              />
              <Column
                dataField="locationchg"
                dataType="number"
                caption="Location"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={80}
                cellRender={(e) => this.DiffCell(e, "locationchg")}
              />
              <Column
                dataField="optionchg"
                dataType="number"
                caption="Options"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={80}
                cellRender={(e) => this.DiffCell(e, "optionchg")}
              />
              <Column
                dataField="additionalchg"
                dataType="number"
                caption="Additional"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={80}
                cellRender={(e) => this.DiffCell(e, "additionalchg")}
              />
              <Column
                dataField="cancelchg"
                dataType="number"
                caption="Cancel Fee"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={80}
                cellRender={(e) => this.DiffCell(e, "cancelchg")}
              />
              <Column
                dataField="discountchg"
                dataType="number"
                caption="Discount"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={80}
                cellRender={(e) => this.DiffCell(e, "discountchg")}
              />
            </Column>

            <Column caption="Final Charges" alignment="center">
              <Column
                dataField="totaljobchg"
                dataType="number"
                caption="Delivery Charges KD"
                alignment="center"
                format={{ type: "fixedPoint", precision: 3 }}
                width={150}
                cellRender={(e) => this.DiffCell(e, "totaljobchg")}
              />
              {delCont.maintype === "Daily Per Truck" && (
                <Column
                  dataField="minchg"
                  dataType="number"
                  caption="Min Weekly Charge"
                  alignment="center"
                  format={{ type: "fixedPoint", precision: 3 }}
                  width={80}
                  cellRender={(e) => this.DiffCell(e, "minchg")}
                />
              )}
              {delCont.maintype === "Dedicated Staff" && (
                <Column
                  dataField="fixedweekly"
                  dataType="number"
                  caption="Weekly Charge"
                  alignment="center"
                  format={{ type: "fixedPoint", precision: 3 }}
                  width={150}
                  cellRender={(e) => this.DiffCell(e, "fixedweekly")}
                />
              )}
              {delCont.maintype === "Dedicated Staff - Monthly" && (
                <Column
                  dataField="fixedweekly"
                  dataType="number"
                  caption="Monthly Charge"
                  alignment="center"
                  format={{ type: "fixedPoint", precision: 3 }}
                  width={150}
                  cellRender={(e) => this.DiffCell(e, "fixedweekly")}
                />
              )}
            </Column>
            <Column
              dataField="finalcost"
              dataType="number"
              caption="Final Charge (KD)"
              alignment="center"
              format={{ type: "fixedPoint", precision: 3 }}
              width={150}
              cellRender={(e) => this.DiffCell(e, "finalcost")}
            />
            <MasterDetail enabled={true} component={DeliveryDetailView} />
            <Summary>
              <TotalItem
                column="startday"
                summaryType="count"
                displayFormat={`Weeks: `}
              />
              <TotalItem
                column="endday"
                summaryType="count"
                displayFormat={`${acDeliveryStore.delcharges.length}`}
              />
              <TotalItem column="jobs" summaryType="sum" />
              <TotalItem column="dropoffs" summaryType="sum" />
              <TotalItem column="missingtruckjobs" summaryType="sum" />
              <TotalItem column="items" summaryType="sum" />
              <TotalItem column="qty" summaryType="sum" />

              <TotalItem
                column="cbm"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0}"}
              />
              <TotalItem
                column="mainchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="locationchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="optionchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="additionalchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="discountchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="minchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="totaljobchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="cancelchg"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="finalcost"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
              <TotalItem
                column="fixedweekly"
                summaryType="sum"
                valueFormat={"#,##0.000"}
                displayFormat={"{0} KD"}
              />
            </Summary>
            <Export enabled={true} />
          </DataGrid>
        </div>
      </LoadBack>
    );
  }
}

export default withRouter(observer(DeliveryCharges));
