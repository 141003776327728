import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid } from "rsuite";

import pic1 from "./BenefitImages/cart.png";
import pic2 from "./BenefitImages/delivery.png";
import pic3 from "./BenefitImages/fast.png";
import pic4 from "./BenefitImages/support.png";

import pic6 from "./BenefitImages/statistics.png";
import pic7 from "./BenefitImages/kuwait.png";
import { Image } from "antd";

import VisiblityCode from "../../../../Components/Advanced/VisiblityCode";

class SingleLoop extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, visible: false };
  }

  render() {
    let title = this.props.title;
    let image = this.props.image;

    return (
      <Col md={4} sm={8} xs={12}>
        <div
          onMouseOver={() => this.setState({ show: true })}
          onMouseOut={() => this.setState({ show: false })}
          style={{ transform: this.state.show ? "scale(1.1)" : "scale(0.9)" }}
        >
          <Image src={image} preview={false} height="150px" />
          <h6>{title}</h6>
        </div>
      </Col>
    );
  }
}

class SingleBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  render() {
    return (
      <div style={{ padding: "2%" }}>
        <h2>Why Choose Easy Move?</h2>
        <VisiblityCode>
          <FlexboxGrid>
            <SingleLoop image={pic1} title="Full In-House Supply Chain" />
            <SingleLoop image={pic2} title="Dedicated Support Team" />
            <SingleLoop image={pic3} title="Digitalized Operations" />
            <SingleLoop image={pic4} title="Customer Care" />
            <SingleLoop image={pic6} title="Local Kuwaiti Company" />
            <SingleLoop image={pic7} title="Local Kuwaiti Company" />
          </FlexboxGrid>
        </VisiblityCode>
      </div>
    );
  }
}

export default withRouter(observer(SingleBenefit));
