import { Tag, PageHeader, Alert, Badge } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, IconButton } from "rsuite";
import newAimStore from "../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import userStore from "../../../../Stores/UserStore";
import OrderSummaryTable from "../Modals/Supporting/OrderSummaryTable";

class SingleACOrderHeader extends Component {
  render() {
    let aim = newAimStore.oldaim;
    let account = newAimStore.account;
    let tagcolor = "orange";
    if (aim.status === "Requested") tagcolor = "orange";
    if (aim.status === "Booked") tagcolor = "green";
    if (aim.status === "In Transit") tagcolor = "blue";
    if (aim.status === "Completed") tagcolor = "darkgreen";
    if (aim.status === "Cancelled") tagcolor = "darkred";

    return (
      <div>
        <PageHeader
          ghost={true}
          onBack={() => this.props.history.push(`/ac/dashboard/${account._id}`)}
          title={`International Account Order #${aim.aimOrderNumId} for ${account.name}`}
          tags={
            <Tag color={tagcolor} key={1}>
              <Badge
                status={
                  aim.status === "Cancelled" || aim.status === "Completed"
                    ? "default"
                    : "processing"
                }
                style={{
                  color:
                    aim.status === "Cancelled" || aim.status === "Completed"
                      ? "white"
                      : "black",
                }}
                color={
                  aim.status === "Cancelled" || aim.status === "Completed"
                    ? "white"
                    : tagcolor
                }
                text={aim.status}
              />
            </Tag>
          }
          extra={[
            <IconButton
              size="sm"
              icon={<Icon icon="web" />}
              appearance="ghost"
              color="green"
              onClick={() =>
                this.props.history.push(`/ac/dashboard/${account._id}`)
              }
            >
              Company Dash
            </IconButton>,
            <IconButton
              size="sm"
              icon={<Icon icon="refresh" />}
              appearance="primary"
              onClick={() => newAimStore.getSingleACOrder(false, true)}
            >
              Refresh Data
            </IconButton>,
          ]}
        >
          <OrderSummaryTable aim={aim} account={account} hidetitle />
        </PageHeader>
        {newAimStore.stopedit && (
          <Alert
            message={
              aim.status === "Booked" ||
              aim.status === "In Transit" ||
              aim.status === "Completed"
                ? `The Order is already ${aim.status}. For order modifications please contact our office.`
                : !userStore.user.staffaccount & !userStore.user.comprequest
                ? "Kindly note editing access has not been provided, please contact our team to provide request editing access."
                : `For order modifications please contact our office.`
            }
            type="warning"
            showIcon
            closable
            style={{ paddingLeft: "2%", paddingRight: "2%" }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(SingleACOrderHeader));
