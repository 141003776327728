import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import Col2 from "../../../../../../Components/Columns/Col2";
import completeDelStore from "../../../../../../Stores/AccountUpdated/Delivery/CompleteDelStore";
import Col4 from "../../../../../../Components/Columns/Col4";

class PickupDropoffButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let del = newDeliveryStore.newdel;

    let deliverybuts;

    if (del.status === "Booked") {
      deliverybuts = (
        <Col2>
          <IconButton
            icon={<Icon icon="truck" />}
            block
            color="green"
            onClick={() => {
              completeDelStore.getSingleACOrder(del._id, true);
              this.props.history.push(`/delconfpickup/${del._id}`);
            }}
          >
            Complete Pickup
          </IconButton>
        </Col2>
      );
    } else if (del.status === "In Transit") {
      deliverybuts = del.toaddress.map((to, index) => (
        <Col4>
          <IconButton
            icon={<Icon icon="cube" />}
            block
            color="blue"
            disabled={to.status === "Completed"}
            onClick={() => {
              completeDelStore.getSingleACOrderFromTo(to._id, true);
              this.props.history.push(`/delconfdropoff/${to._id}`);
            }}
          >
            <b>
              Dropoff #{to.order}: {to.city}
            </b>
          </IconButton>
        </Col4>
      ));
    }
    return (
      <div>
        <FlexboxGrid justify="center">{deliverybuts}</FlexboxGrid>
        <hr />
      </div>
    );
  }
}

export default withRouter(observer(PickupDropoffButtons));
