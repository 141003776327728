import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Icon, IconButton } from "rsuite";
import UnifiedModal from "../../../../../../../Components/Modal/UnifiedModal";
import ModalFooter from "../../../../../../../Components/Modal/ModalFooter";
import newStorageStore from "../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";
import axios from "axios";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import { Image, message } from "antd";

const instance = axios.create({ baseURL: baseURL });

class DRAttachmentImage extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, loading: false };
    this.onHide = this.onHide.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }
  onHide() {
    this.setState({ show: false });
  }

  updateItem = async () => {
    let items = newStorageStore.oldorder.damagereport.item;
    let url = `ac/damagereport/edit/${newStorageStore.neworder.damagereport._id}`;
    let finalitem = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].acitem.acItemNumId !== this.props.acItemNumId) {
        finalitem = [...finalitem, { ...items[i] }];
      } else {
        finalitem = [
          ...finalitem,
          {
            acitem: items[i].acitem,
            qty: items[i].qty,
            type: items[i].type,
            remarks: items[i].remarks,
            attachment: null,
          },
        ];
      }
    }
    let data = { item: finalitem };

    this.setState({ loading: true });
    await instance
      .put(url, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
        message.success("Attachment Deleted");
        this.onHide();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Error Removing Attachment");
      });
  };

  render() {
    return (
      <div>
        <UnifiedModal
          show={this.state.show}
          onHide={this.onHide}
          footer={
            <ModalFooter
              icon={["close", "trash"]}
              color={["green", "red"]}
              app={["ghost", "default"]}
              text={["No, Keep", "Yes, Delete"]}
              oC1={() => this.onHide()}
              oC2={() => this.updateItem()}
              loading1={this.state.loading}
              loading2={this.state.loading}
            />
          }
        >
          <h3>
            Are you sure you want to Delete this attachment? This cannot be
            undone.
          </h3>
        </UnifiedModal>
        <Image src={`${this.props.attachment}?alt=media`} width={"100%"} />
        <hr />
        <IconButton
          icon={<Icon icon="trash" />}
          color="red"
          size="xs"
          onClick={() => this.setState({ show: true })}
          disabled={!userStore.user.staffaccount}
        >
          Delete Image
        </IconButton>
      </div>
    );
  }
}

export default withRouter(observer(DRAttachmentImage));
