import { Parsed } from "../Pricing/PRKDCalc";

function IMQuoteCalc(quotes) {
  let count = 0;
  let price = 0;

  if (quotes.length > 0) {
    count = quotes.length;
    for (let i = 0; i < quotes.length; i++) {
      price += Parsed(quotes[i].price);
    }
  }
  return [count, price];
}

export { IMQuoteCalc };
