import { message } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Loader, Uploader, Notification, FlexboxGrid, Icon } from "rsuite";

import axios from "axios";
import AttachmentModal from "./AttachmentModal";
import baseURL from "../../../../../../Static/baseURL/baseURL";
import newDeliveryStore from "../../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import userStore from "../../../../../../Stores/UserStore";
import ImageCropModal from "../../../../../SingleJob/IndividualTabs/SubJobs/Attachments/ImageCropper/ImageCropModal";

const instance = axios.create({ baseURL: baseURL });

class NewAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      src: null,
      newtitle: "",
    };
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.setState({ show: false, src: null });
  }

  checkFileType = async (file) => {
    if (file.blobFile.type.substring(0, 3) === "ima") {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(file.blobFile);
    } else {
      this.uploadDocument(file.blobFile);
    }
  };

  uploadDocument = async (file) => {
    let attType = this.props.attType;
    let acc = newDeliveryStore.olddel;

    this.setState({ loading: true });
    let fd = new FormData();
    fd.append(
      "file",
      file,
      `${attType}_${Math.round(Math.random() * 100)}_${file.name}`
    );
    fd.append("delivery", acc._id);
    fd.append("acc", "DEL");
    fd.append("orderNumId", acc.deliveryNumId);
    fd.append("clientNumId", acc.client.clientNumId);
    fd.append("type", attType);
    fd.append("title", attType);

    await instance
      .post("media/account/upload/single", fd, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newDeliveryStore.newList("attachments", res.data);
        this.setState({ loading: false, show: false, src: null });
        message.success(`${attType} Uploaded.`);
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let content = (
      <Uploader
        multiple={false}
        fileListVisible={false}
        onChange={(file) => {
          let finalfile = file[file.length - 1];

          return new Promise((resolve, reject) => {
            let fileSize = finalfile.blobFile.size / 1000000;

            if (
              (finalfile.blobFile.type.substring(0, 3) !== "ima") &
              (fileSize > 10)
            ) {
              message.error("Not Uploaded. Must be less than 10 MB.");
              reject();
            } else {
              this.checkFileType(finalfile);
              resolve();
            }
          });
        }}
        draggable
      >
        <div
          style={{
            height: 50,
            margin: "1%",
            backgroundColor: "rgba(96,214,112,0.26)",
            boxShadow: "0px 0px 3px black",
            border: "1px solid black",
            width: "100%",
          }}
        >
          <Icon icon="plus" size="lg" />
          <p>Add New</p>
        </div>
      </Uploader>
    );
    if (this.state.loading) {
      content = (
        <div>
          <div active style={{ height: 70 }}>
            <Loader content="Uploading Document..." />
          </div>
        </div>
      );
    }

    return (
      <div>
        <AttachmentModal
          hideModal={this.hideModal}
          changeDelete={this.changeDelete}
          deleteDocument={this.deleteDocument}
          show={this.state.show}
          attachment={this.state.attachment}
          deleteactive={this.state.delete}
        />
        <ImageCropModal
          loading={this.state.loading}
          uploadDocument={this.uploadDocument}
          hideModal={this.hideModal}
          src={this.state.src}
        />

        <FlexboxGrid align="middle">{content}</FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(NewAdd));
