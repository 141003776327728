import { observer } from "mobx-react";
import React, { Component } from "react";
import Div14 from "../../../../Components/Dividers/Div14";

import { Tooltip } from "@material-ui/core";
import { IconButton, Icon, FlexboxGrid, Notification, HelpBlock } from "rsuite";
import MaterialTable from "material-table";
import moment from "moment";
import axios from "axios";
import { Col } from "rsuite";
import baseURL from "../../../../Static/baseURL/baseURL";
import zoneStore from "../../../../Stores/Admin/Lists/ZoneStore";
import userStore from "../../../../Stores/UserStore";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../Components/Columns/Col2";
import Col3 from "../../../../Components/Columns/Col3";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import { Parsed, PRKD } from "../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });
class AdminAccountStandardItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      type: "New",
      show: false,
      accountId: "",
      item: {
        name: "",
        serialnumber: "",
        length: 1,
        width: 1,
        height: 1,
      },
      loading: false,
    };
    this.editItem = this.editItem.bind(this);
  }
  componentDidMount = async () => {
    this.setState({ loading: true });
    await instance
      .get(`/ac/adminaccountitem/getbyclient/${this.props.clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({
          loading: false,
          items: res.data,
          accountId: this.props.clientid,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
    zoneStore.getZones();
  };

  fetchItems = async () => {
    this.setState({ loading: true });
    await instance
      .get(`/ac/adminaccountitem/getbyclient/${this.props.clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        this.setState({
          loading: false,
          items: res.data,
          accountId: this.props.clientid,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
      });
  };

  onHide = async () => {
    this.setState({ show: false });
  };
  editItem(data, label) {
    let item = { ...this.state.item };
    item[label] = data;
    this.setState({ item: item });
  }

  postAccountItem = async (data, type) => {
    this.setState({ loading: true });
    let newdata = { ...data };
    newdata.client = this.state.accountId;

    if (type === "New") {
      return instance
        .post(`/ac/adminaccountitem/new`, newdata, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchItems();
          Notification["success"]({
            title: `New Account Item Created.`,
            description: <div>In {newdata.city}</div>,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else if (type === "Delete") {
      return instance
        .put(`/ac/adminaccountitem/delete/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchItems();

          Notification["success"]({
            title: `Deleted Account Item Details.`,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else {
      return instance
        .put(`/ac/adminaccountitem/edit/${data._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.fetchItems();
          Notification["success"]({
            title: `Updated Account Item Details.`,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    }
  };

  deleteAccountItem() {}

  newAccountItem() {
    this.setState({
      type: "New",
      show: true,
      item: {
        name: "",
        serialnumber: "",
        length: 1,
        width: 1,
        height: 1,
      },
    });
  }

  render() {
    let data = this.state.items;
    let columnnames = [
      { title: "ID", field: "accountItemNumId", defaultSort: "desc" },
      { title: "Name", field: "name" },
      { title: "Serial Number", field: "serialnumber" },
      { title: "Length", field: "length" },
      { title: "Width", field: "width" },
      { title: "Height", field: "height" },
    ];

    let type = this.state.type;
    let item = this.state.item;
    let disable = false;
    if (item.name === "") disable = true;
    if (item.serialnumber === "") disable = true;

    let modal = (
      <UnifiedModal
        onHide={this.onHide}
        show={this.state.show}
        title={`${type === "New" ? "Add New " : "Edit Existing "} Item`}
        footer={
          <FlexboxGrid>
            <Col2>
              <IconButton
                block
                loading={this.state.loading}
                color="red"
                icon={<Icon icon="close" />}
                onClick={() => this.onHide()}
              >
                Cancel
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                disabled={disable}
                block
                loading={this.state.loading}
                color="green"
                icon={<Icon icon="save" />}
                onClick={() => {
                  this.postAccountItem(this.state.item, this.state.type);
                }}
              >
                {type === "New" ? "Create New " : "Save Edited "} Item
              </IconButton>
            </Col2>
          </FlexboxGrid>
        }
      >
        <FlexboxGrid>
          <Col md={24} sm={24} xs={24}>
            <TextInputSTD
              title="Item Name"
              val={item.name}
              label="name"
              size="large"
              editVal={this.editItem}
            />
            <br />
          </Col>
          <Col md={24} sm={24} xs={24}>
            <TextInputSTD
              title="Serial Number"
              val={item.serialnumber}
              label="serialnumber"
              size="large"
              editVal={this.editItem}
            />
            <br />
          </Col>
          <Col3>
            <NumbInputSTD
              min={1}
              title="Length (cm)"
              val={item.length}
              label="length"
              size="large"
              editVal={this.editItem}
            />
            <br />
          </Col3>
          <Col3>
            <NumbInputSTD
              min={1}
              title="Width (cm)"
              val={item.width}
              label="width"
              size="large"
              editVal={this.editItem}
            />
            <br />
          </Col3>
          <Col3>
            <NumbInputSTD
              min={1}
              title="Height (cm)"
              val={item.height}
              label="height"
              size="large"
              editVal={this.editItem}
            />
            <br />
          </Col3>
        </FlexboxGrid>
        <HelpBlock>
          Item Dimensions = {PRKD(item.length)} cm x {PRKD(item.width)} cm x{" "}
          {PRKD(item.height)} cm{" "}
        </HelpBlock>
        <HelpBlock>
          Item CBM ={" "}
          {PRKD(
            (Parsed(item.length) * Parsed(item.width) * Parsed(item.height)) /
              1000000
          )}{" "}
          CBM
        </HelpBlock>
      </UnifiedModal>
    );

    if (type === "Delete") {
      modal = (
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`Delete Account Item`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  No, Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.postAccountItem(this.state.item, this.state.type);
                  }}
                >
                  Yes, Delete Account Item
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <h6>Are you sure you want to delete this Item?</h6>
          <p>
            <b>Name: </b>
            {item.name} <br />
            <b>Serial Number: </b>
            {item.serialnumber} <br />
            <b>Length: </b>
            {PRKD(item.length)} cm <br />
            <b>Width: </b>
            {PRKD(item.width)} cm <br />
            <b>Height: </b>
            {PRKD(item.height)} cm <br />
          </p>
        </UnifiedModal>
      );
    }

    return (
      <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        {modal}

        <Div14>
          Saved Account Items:
          <span style={{ width: "10px" }} />{" "}
          <Tooltip title={"Refresh List"} arrow placement="top">
            <IconButton
              circle
              size="xs"
              icon={<Icon icon="refresh" />}
              onClick={() => this.fetchItems()}
            />
          </Tooltip>
        </Div14>

        <div>
          <MaterialTable
            isLoading={this.state.loading}
            title={data.length + " Account Items"}
            columns={columnnames}
            style={{ fontSize: 12 }}
            data={data}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: false,
              toolbarButtonAlignment: "left",
              maxBodyHeight: 1000,
              tableLayout: "auto",
              exportButton: true,
              exportFileName: `Account Item List as of ${moment(
                new Date()
              ).format("DD-MMM-YY")}`,
              padding: "dense",
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Account Item",
                onClick: (event, rowData) =>
                  this.setState({ item: rowData, show: true, type: "Edit" }),
              },
              {
                icon: "add",
                tooltip: "Open New Account Item",
                isFreeAction: true,
                onClick: (event, rowData) => this.newAccountItem(),
              },
              {
                icon: "delete",
                tooltip: "Delete Account Item",

                onClick: (event, rowData) =>
                  this.setState({ item: rowData, show: true, type: "Delete" }),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default observer(AdminAccountStandardItems);
