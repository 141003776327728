import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../../../../Components/Dividers/Div14";
import { FlexboxGrid } from "rsuite";
import Col3 from "../../../../../../../../../Components/Columns/Col3";

import acStorStatStore from "../../../../../../../../../Stores/AccountUpdated/Storage/ACStorStatStore";

import { Statistic } from "antd";
import { PRKD } from "../../../../../../../../../Functions/Pricing/PRKDCalc";
import moment from "moment";

class MovementSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let output = acStorStatStore.movestat;
    let startdate = acStorStatStore.startdate;
    let enddate = acStorStatStore.enddate;
    let monthdiff = moment(enddate).diff(startdate, "month") + 1;
    return (
      <div>
        <Div14>
          Movement Stat from {moment(startdate).format("MMM-YY")} to{" "}
          {moment(enddate).format("MMM-YY")}
        </Div14>
        <FlexboxGrid justify="space-around">
          <Col3>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Quantity Change"
                value={PRKD(output.totalqty)}
              />
            </div>
          </Col3>
          <Col3>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="CBM Change"
                precision={1}
                value={PRKD(output.totalcbm)}
              />
            </div>
          </Col3>
          <Col3>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Static Items"
                value={PRKD(acStorStatStore.addmovestats.staticitems.length)}
              />
            </div>
          </Col3>
          <Col3>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic title="Months" precision={0} value={PRKD(monthdiff)} />
            </div>
          </Col3>
          <Col3>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="Quantity Per Month"
                precision={1}
                value={PRKD(output.totalqtypertime)}
              />
            </div>
          </Col3>

          <Col3>
            <div
              style={{
                borderRadius: "10px",
                boxShadow: "0px 0px 10px black",
                textAlign: "center",
                margin: "2%",
              }}
            >
              <Statistic
                title="CBM Per Month"
                precision={1}
                value={PRKD(output.totalcbmpertime)}
              />
            </div>
          </Col3>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(MovementSummary));
