import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { withRouter } from "react-router-dom";
import SummaryMain from "../../../SingleJob/IndividualTabs/Summary/SummaryMain";
import SJSection from "../../../SingleJob/IndividualTabs/Summary/Sections/SJSection";
import sjStore from "../../../../Stores/SideJobs/SJStore";
import Col2 from "../../../../Components/Columns/Col2";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import singleJobStore from "../../../../Stores/SingleJobStore";

class JobDetailsModal extends Component {
  render() {
    let show = this.props.show;
    let subjob = this.props.subjob;
    let jobId = this.props.jobId;
    let loading = this.props.loading;
    let sjs = sjStore.sjs;
    let type = this.props.type;
    let title;
    let numId;
    if (type) {
      numId = `sjNumId`;
      title = `Local Move (${type}-${subjob[numId]})`;
    }

    let jobdetails;

    if (subjob._id) {
      let indexVal = sjStore.sjs.findIndex(
        (sj) => `${sj._id}` === `${subjob._id}`
      );

      if (indexVal > -1) {
        jobdetails = <SJSection data={sjStore.sjs[indexVal]} short />;
      }
    }

    let checkLoad = sjStore.loading || singleJobStore.loading;

    return (
      <div>
        <UnifiedModal
          title={title}
          show={show}
          onHide={this.props.showModal}
          footer={false}
        >
          <LoadBack loading={checkLoad}>
            <div>
              <FlexboxGrid>
                <Col2>
                  <IconButton
                    icon={<Icon icon="chevron-left" />}
                    block
                    onClick={() => this.props.showModal(false)}
                    disabled={loading}
                  >
                    Cancel
                  </IconButton>
                </Col2>
                <Col2>
                  <IconButton
                    icon={<Icon icon="list" />}
                    color="green"
                    block
                    disabled={title === ""}
                    loading={loading}
                    onClick={() => {
                      this.props.history.push(`/singlejob/${jobId}`);
                      singleJobStore.getJob(jobId, true);
                      sjStore.getSJs(jobId, true);
                    }}
                  >
                    Open Job
                  </IconButton>
                </Col2>
              </FlexboxGrid>
            </div>
            <hr />
            {jobdetails}
            <SummaryMain sjs={sjs} />
          </LoadBack>
        </UnifiedModal>
      </div>
    );
  }
}

export default withRouter(observer(JobDetailsModal));
