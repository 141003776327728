import { DatePicker, message } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { InputGroup, Icon, HelpBlock, FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import newDeliveryStore from "../../../../../Stores/AccountUpdated/Delivery/NewDeliveryStore";
import axios from "axios";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";

const instance = axios.create({ baseURL: baseURL });

class StartAndEndDate extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.postJobDate = this.postJobDate.bind(this);
  }

  postJobDate = async (label) => {
    let data = { [label]: newDeliveryStore.newdel[label] };

    this.setState({ loading: true });
    await instance
      .put(`ac/delivery/edit/${newDeliveryStore.olddel._id}`, data, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        message.success(`Date Updated`);
        newDeliveryStore.getSingleACOrder(false, true);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Notification["error"]({ title: `An error occured` });
      });
  };
  render() {
    let newdel = newDeliveryStore.newdel;
    let olddel = newDeliveryStore.olddel;

    let changedstart = false;
    let changedend = false;

    if (
      moment.utc(olddel.startdate).format("DD-MMM-YY HH:mm") !==
      moment.utc(newdel.startdate).format("DD-MMM-YY HH:mm")
    ) {
      changedstart = true;
    }
    if (
      moment.utc(olddel.enddate).format("DD-MMM-YY HH:mm") !==
      moment.utc(newdel.enddate).format("DD-MMM-YY HH:mm")
    ) {
      changedend = true;
    }
    let load = this.state.loading;

    return (
      <FlexboxGrid>
        <Col2>
          <div style={{ marginTop: "5px" }}>
            <InputGroup>
              {changedstart && (
                <InputGroup.Button
                  loading={load}
                  onClick={() =>
                    newDeliveryStore.editOrder(
                      moment(olddel.startdate),
                      "startdate"
                    )
                  }
                >
                  <Icon icon="undo" />
                </InputGroup.Button>
              )}
              <InputGroup.Addon>Start Date</InputGroup.Addon>
              <DatePicker
                style={{ width: "100%" }}
                showTime={{ format: "HH:mm" }}
                format="DD-MMM-YY HH:mm"
                minuteStep={15}
                showSecond={false}
                allowClear={false}
                size="small"
                picker="date"
                showNow={false}
                showToday={false}
                inputReadOnly
                value={moment(newdel.startdate)}
                onChange={(date) =>
                  newDeliveryStore.editOrder(date, "startdate")
                }
                onOk={(date) => newDeliveryStore.editOrder(date, "startdate")}
              />
              {changedstart && (
                <InputGroup.Button
                  color="green"
                  loading={load}
                  onClick={() => this.postJobDate("startdate", "Job Date")}
                >
                  <Icon icon="check" />
                </InputGroup.Button>
              )}
            </InputGroup>

            {changedstart && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from{" "}
                <i>"{moment(olddel.startdate).format("DD-MMM-YY HH:mm")}"</i>
              </HelpBlock>
            )}
          </div>
          <br />
        </Col2>
        <Col2>
          <div style={{ marginTop: "5px" }}>
            <InputGroup>
              {changedend && (
                <InputGroup.Button
                  loading={load}
                  onClick={() =>
                    newDeliveryStore.editOrder(
                      moment(olddel.enddate),
                      "enddate"
                    )
                  }
                >
                  <Icon icon="undo" />
                </InputGroup.Button>
              )}
              <InputGroup.Addon>Ends Date</InputGroup.Addon>
              <DatePicker
                style={{ width: "100%" }}
                showTime={{ format: "HH:mm" }}
                format="DD-MMM-YY HH:mm"
                minuteStep={15}
                showSecond={false}
                allowClear={false}
                size="small"
                picker="date"
                showNow={false}
                showToday={false}
                inputReadOnly
                value={moment(newdel.enddate)}
                onChange={(date) => newDeliveryStore.editOrder(date, "enddate")}
                onOk={(date) => newDeliveryStore.editOrder(date, "enddate")}
              />
              {changedend && (
                <InputGroup.Button
                  color="green"
                  loading={load}
                  onClick={() => this.postJobDate("enddate", "Job Date")}
                >
                  <Icon icon="check" />
                </InputGroup.Button>
              )}
            </InputGroup>

            {changedend && (
              <HelpBlock style={{ fontSize: 8, color: "#ab4400" }}>
                Changed from{" "}
                <i>"{moment(olddel.enddate).format("DD-MMM-YY HH:mm")}"</i>
              </HelpBlock>
            )}
          </div>
          <br />
        </Col2>
      </FlexboxGrid>
    );
  }
}

export default observer(StartAndEndDate);
