import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";

import { Loader } from "rsuite";
import mgtOverviewStore from "../../../../Stores/Management/MgtOverviewStore";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import OverviewBarGraph from "../Graph/OverviewBarGraph";
import StackedBarGraph from "../Graph/StackedBarGraph";
import { Tabs } from "antd";

const { TabPane } = Tabs;
class EMOverviewMain extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Revenue" };
  }
  componentDidMount() {
    mgtOverviewStore.getOverview();
  }
  render() {
    let jobtype = this.props.jobtype;
    if (!mgtOverviewStore.loaded.local) {
      return <Loader />;
    }
    if (jobtype === "all") {
      return (
        <div style={{ padding: "1%" }}>
          <LoadBack loading={mgtOverviewStore.loading.local}>
            <Tabs
              activeKey={this.state.tab}
              defaultActiveKey={0}
              tabPosition={"top"}
              type="card"
              centered
              onChange={(e) => {
                this.setState({ tab: e });
              }}
            >
              <TabPane tab={"Revenue"} key={"Revenue"}>
                <StackedBarGraph
                  type="price"
                  xaxis={mgtOverviewStore.local.cats}
                  label="Revenue"
                  title={`All Monthly Revenue`}
                />
              </TabPane>

              <TabPane tab={"CBM"} key={"CBM"}>
                <StackedBarGraph
                  type="cbm"
                  xaxis={mgtOverviewStore.local.cats}
                  label="CBM"
                  title={`All Monthly CBM`}
                />
              </TabPane>
              <TabPane tab={"Jobs"} key={"Jobs"}>
                <StackedBarGraph
                  type="job"
                  xaxis={mgtOverviewStore.local.cats}
                  label="Jobs"
                  title={`All Monthly Jobs`}
                />
              </TabPane>
            </Tabs>
          </LoadBack>
        </div>
      );
    }

    return (
      <div style={{ padding: "1%" }}>
        <LoadBack loading={mgtOverviewStore.loading.local}>
          <Tabs
            activeKey={this.state.tab}
            defaultActiveKey={0}
            tabPosition={"top"}
            type="card"
            centered
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <TabPane tab={"Revenue"} key={"Revenue"}>
              <OverviewBarGraph
                data={mgtOverviewStore.local.price[jobtype]}
                xaxis={mgtOverviewStore.local.cats}
                label="Revenue"
                title={`${jobtype} Monthly Revenue`}
              />
            </TabPane>

            <TabPane tab={"CBM"} key={"CBM"}>
              <OverviewBarGraph
                data={mgtOverviewStore.local.cbm[jobtype]}
                xaxis={mgtOverviewStore.local.cats}
                label="CBM"
                title={`${jobtype} Monthly CBM`}
              />
            </TabPane>
            <TabPane tab={"Jobs"} key={"Jobs"}>
              <OverviewBarGraph
                data={mgtOverviewStore.local.job[jobtype]}
                xaxis={mgtOverviewStore.local.cats}
                label="Jobs"
                title={`${jobtype} Monthly Jobs`}
              />
            </TabPane>
          </Tabs>
        </LoadBack>
      </div>
    );
  }
}

export default withRouter(observer(EMOverviewMain));
