import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import ProfileMain from "../Pages/UserRelated/Profile/Main";
import userStore from "../Stores/UserStore";
import ClientList from "../Pages/Lists/ClientsList/ClientList";
import SignUpPage from "../Pages/UserRelated/Authentication/SignUpPage";
import LoginPage from "../Pages/UserRelated/Authentication/LoginPage";
import SingleJobMain from "../Pages/SingleJob/SingleJobMain";
import IMSupplierMain from "../Pages/IMStaff/IMSupplier/IMSupplierMain";
import UserList from "../Pages/Admin/Users/UserList";
import SJListTemplate from "../Pages/Lists/SideJobLists/Template/SJListTemplate";
import MainCalendarPage from "../Pages/Lists/MainCalendar/MainCalendarPage";

import StaffTabs from "../Pages/Management/HR/HRTabs";

import AccountClients from "../Pages/Accounts/AccountMain/AccountClients";

import AllTruckMap from "../Pages/Management/CarMaps/AllTruckMap";
import MainDeliveryTracking from "../Pages/Tracking/DeliveryTracking/MainDeliveryTracking";
import DeliveryFeedback from "../Pages/Tracking/Feedback/DeliveryFeedback";
import JobFeedback from "../Pages/Tracking/Feedback/JobFeedback";
import FeedbackMain from "../Pages/Management/ClientReviews/FeedbackMain";
import AccountCalendarTab from "../Pages/Accounts/Dashboard/Tabs/AccountCalendar/AccountCalendarTab";
import NewRequestMain from "../Pages/Accounts/Requests/NewRequestMain";
import AdminServices from "../Pages/Admin/Services/AdminServices";
import EditAccountMain from "../Pages/Accounts/EditAccount/EditAccountMain";

import CompleteDelMain0 from "../Pages/Accounts/CompleteDelivery/CompleteDelMain0";

import SingleItemPage from "../Pages/Accounts/StoragePages/SingleItemPage/SingleItemPage";

import SingleItemQR from "../Pages/Accounts/StoragePages/QRPages/SingleItemQR";
import MultipleQRs from "../Pages/Accounts/StoragePages/QRPages/MultipleQRs";

import NewEditAccount from "../Pages/Accounts/NewAccount/NewEditAccount";
import AccountDashboard from "../Pages/Accounts/AccountDashboard/AccountDashboard";
import StorageACCDash from "../Pages/Accounts/StorageAccount/Dashboard/StorageACCDash/StorageACCDash";
import NewEditStorageMain from "../Pages/Accounts/StorageAccount/Dashboard/NewStorage/NewEditStorageMain";

import SingleACOrderPage from "../Pages/Accounts/StorageAccount/SingleACOrderPage/SingleACOrderPage";
import PickupDelIn from "../Pages/Accounts/StorageAccount/SingleACOrderPage/PickupDropoff/PickupDelIn";
import NewACInvoice from "../Pages/Accounts/StorageAccount/Dashboard/StorageACCDash/Tabs/Invoices/InvoicePages/NewACInvoice";
import EditACInvoice from "../Pages/Accounts/StorageAccount/Dashboard/StorageACCDash/Tabs/Invoices/InvoicePages/EditACInvoice";
import StaffStorageMain from "../Pages/Accounts/StaffView/Storage/MainDashboard/StaffStorageMain";
import SACCalendarPage from "../Pages/Accounts/StorageAccount/Calendar/SACCalendarPage";
import DeliveryACCDash from "../Pages/Accounts/DeliveryAccount/Dashboard/DeliveryACCDash";
import NewDeliveryRequestMain from "../Pages/Accounts/DeliveryAccount/NewRequest/NewDeliveryRequestMain";
import AIMDashboard from "../Pages/Accounts/AIMAccount/Dashboard/AIMDashboard";
import ACCDashboard from "../Pages/Accounts/ACCAccount/Dashboard/ACCDashboard";
import StorageDelIn from "../Pages/Accounts/StorageAccount/SingleACOrderPage/PickupDropoff/StorageDelIn";
import StorageDelOut from "../Pages/Accounts/StorageAccount/SingleACOrderPage/PickupDropoff/StorageDelOut";
import DropoffDelOut from "../Pages/Accounts/StorageAccount/SingleACOrderPage/PickupDropoff/DropoffDelOut";
import StorageStoIn from "../Pages/Accounts/StorageAccount/SingleACOrderPage/PickupDropoff/StorageStoIn";
import ConfirmEmail from "../Pages/UserRelated/Authentication/ConfirmEmail";
import ForgotPass from "../Pages/UserRelated/Authentication/ForgotPass";
import ResetPassword from "../Pages/UserRelated/Authentication/ResetPassword";
import SingleDelOrderPage from "../Pages/Accounts/DeliveryAccount/SingleDeliveryOrderPage/SingleDelOrderPage";
import CompletePickup from "../Pages/Accounts/DeliveryAccount/SingleDeliveryOrderPage/Tabs/PickupDropoff/CompletePickup";
import CompleteDropoff from "../Pages/Accounts/DeliveryAccount/SingleDeliveryOrderPage/Tabs/PickupDropoff/CompleteDropoff";
import DACCalendarPage from "../Pages/Accounts/DeliveryAccount/Calendar/DACCalendarPage";
import NewDelInvoice from "../Pages/Accounts/DeliveryAccount/Dashboard/Tabs/DelInvoice/NewEdit/NewDelInvoice";
import EditDelInvoice from "../Pages/Accounts/DeliveryAccount/Dashboard/Tabs/DelInvoice/NewEdit/EditDelInvoice";
import NewAIMOrderMain from "../Pages/Accounts/AIMAccount/NewOrder/NewAIMOrderMain";
import SingleAIMOrderPage from "../Pages/Accounts/AIMAccount/SingleAIMOrder/SingleAIMOrderPage";
import NewACCOrderMain from "../Pages/Accounts/ACCAccount/NewRequest/NewACCOrderMain";
import SingleACCOrderPage from "../Pages/Accounts/ACCAccount/SingleACCOrder/SingleACCOrderPage";
import AdminAccountUsers from "../Pages/Accounts/Admin/Users/AdminAccountUsers";
import AccAdminSettings from "../Pages/Accounts/Admin/AdminSettings/AccAdminSettings";
import CombinedCalendar from "../Pages/Accounts/StaffAccount/Calendar/CombinedCalendar";
import ReferralLogin from "../Pages/UserRelated/Referral/ReferralLogin";
import ReferralRegister from "../Pages/UserRelated/Referral/ReferralRegister";
import LinkAccount from "../Pages/UserRelated/Referral/LinkAccount";
import PendingTLAccountPage from "../Pages/TLPages/PendingAccounts/PendingTLAccountPage";
import MainHomePage from "../Pages/HomePage/MainHomePage";

import backgroundpic from "../Static/Images/slide9.jpg";
import AboutUs from "../Pages/HomePage/AboutUs";
import AllPaymentsMain from "../Pages/Financial/AllPayments/AllPaymentsMain";
import ClientBalancesMain from "../Pages/Financial/ClientBalances/ClientBalancesMain";
import AttendanceMain from "../Pages/Management/Attendance/AttendanceMain";
import { Box } from "@material-ui/core";
import FooterPage from "../Components/HeaderFooter/FooterPage";
import WarehouseAccounts from "../Pages/Accounts/StaffView/Storage/Accounts/WarehouseAccounts";
import FinancialStats from "../Pages/Financial/Statistics/FinancialStats";
import CombinedCalendarMain from "../Pages/CombinedCalendar/CombinedCalendarMain";
import ItemLocationsMain from "../Pages/Accounts/StaffView/Storage/MainDashboard/Tabs/ItemLocations/ItemLocationsMain";
import SingleStaffMain from "../Pages/Management/HR/Staff/SingleStaffMain";
import HolidayMain from "../Pages/Management/HR/Holiday/HolidayMain";
import StorageSolution from "../Pages/HomePage/SolutionPages/StorageSolution";
import LastMile from "../Pages/HomePage/SupplyChain/LastMile";
import LocalMoveSolution from "../Pages/HomePage/SolutionPages/LocalMoveSolution";
import InterMoveSolution from "../Pages/HomePage/SolutionPages/InterMoveSolution";
import CustomClearSolution from "../Pages/HomePage/SolutionPages/CustomClearSolution";
import WarehousingSupplyChain from "../Pages/HomePage/SupplyChain/WarehousingSupplyChain";
import FreightForwarding from "../Pages/HomePage/SupplyChain/FreightForwarding";
import FullFillment from "../Pages/HomePage/SupplyChain/FullFillment";
import EMOverviewMain from "../Pages/Management/EMOverview/EMOverviewMain";
import WarehouseSummary from "../Pages/Financial/Statistics/WarehouseSummary";
import MainDamageReport from "../Pages/Accounts/DamageReports/MainDamageReport";
import TrackGoogleAnalyticsEvent from "../Static/GoogleAnalytics/TrackGoogleAnalytics";
import EquipmentMain from "../Pages/TLPages/Equipment/EquipmentMain";
import SingleEquipmentPage from "../Pages/TLPages/SingleEquipment/SingleEquipmentPage";
import JobStatMain from "../Pages/JobStats/JobStatMain";
import WHMCompanyMain from "../Pages/WarehouseMoveAug2023/Companies/WHMCompanyMain";
import WHMSingleCompanyMain from "../Pages/WarehouseMoveAug2023/WHMSingleCompany/WHMSingleCompanyMain";

import WHMOldWarehouseMain from "../Pages/WarehouseMoveAug2023/WHMOldWarehouse/WHMOldWarehouseMain";

import LoadingTruckTrip from "../Pages/WarehouseMoveAug2023/TruckTrip/Loading/LoadingTruckTrip";
import WHMNewWarehouseMain from "../Pages/WarehouseMoveAug2023/WHMNewWarehouse/WHMNewWarehouseMain";
import UnloadingTruckTrip from "../Pages/WarehouseMoveAug2023/TruckTrip/Unloading/UnloadingTruckTrip";
import WHMOverview from "../Pages/WarehouseMoveAug2023/Overview/WHMOverview";
import WHMPlanningPageMain from "../Pages/WarehouseMoveAug2023/WHMPlanningPage/WHMPlanningPageMain";
import WHMSinglePlan from "../Pages/WarehouseMoveAug2023/WHMPlanningPage/WHMSinglePlan";
import StaffDailyAttendance from "../Pages/Attendance/Staff/StaffDailyAttendance";
import StaffAttendanceMain from "../Pages/Attendance/Staff/Main/StaffAttendanceMain";
import StaffAttendanceApproval from "../Pages/Attendance/Staff/Main/StaffAttendanceApproval";
import UnderConstructionPage from "./UnderConstructionPage";

class Views extends Component {
  constructor(props) {
    super(props);
    this.state = { hide: false };
  }

  componentDidUpdate = async (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      TrackGoogleAnalyticsEvent(
        "Page Change",
        userStore.user.username,
        this.props.location.pathname
      );
    }
  };

  getView() {
    let user = userStore.user;
    let underconstruction = this.props.underconstruction;

    let EMstaff = [];
    let EMaccounts = [];
    let AdminStaff = [];
    let HRStaff = [];
    let MgtStaff = [];
    let OptHead = [];
    let ClientAccount = [];
    let ClientTabs = [];
    let WHMovePages = [];
    if (user) {
      if (user.whmoveview) {
        WHMovePages = [
          { path: "/whm/companies", component: WHMCompanyMain },
          { path: "/whm/company/:companyid", component: WHMSingleCompanyMain },

          //Old Warehouse Loading
          { path: "/whm/oldwarehouse", component: WHMOldWarehouseMain },
          { path: "/whm/loading/:trucktrip", component: LoadingTruckTrip },
          //New Warehouse Unloading
          { path: "/whm/newwarehouse", component: WHMNewWarehouseMain },
          { path: "/whm/unloading/:trucktrip", component: UnloadingTruckTrip },
          //Warehouse Overview
          { path: "/whm/overview", component: WHMOverview },
          //Warehouse Planning
          { path: "/whm/planning", component: WHMPlanningPageMain },
          { path: "/whm/singleplan/:planid", component: WHMSinglePlan },
          //Warehouse Statistics
          { path: "/whm/statistics", component: WHMOverview },
        ];
      }
    }
    if (user) {
      if (user.group) {
        if (user.group === "EMStaff") {
          EMstaff = [
            { path: "/clientslist", component: ClientList },
            { path: "/dashboard/:type", component: SJListTemplate },
            { path: "/supplier", component: IMSupplierMain },
            { path: "/calendar/:type", component: MainCalendarPage },
            { path: "/tlaccounts", component: PendingTLAccountPage },
            { path: "/clientbalances", component: ClientBalancesMain },
            { path: "/jobstats", component: JobStatMain },
            { path: "/combinedcalendar", component: CombinedCalendarMain },

            { path: "/whaccounts/clients", component: WarehouseAccounts },
            { path: "/accounts/clients", component: AccountClients },
            { path: "/accounts/calendar", component: CombinedCalendar },
            { path: "/accounts/new", component: NewEditAccount },
            { path: "/editaccount/:clientid", component: NewEditAccount },
            { path: "/ac/dashboard/:clientid", component: AccountDashboard },
            { path: "/acorder/:acorderid", component: SingleACOrderPage },

            { path: "/delorder/:deliveryid", component: SingleDelOrderPage },
            { path: "/aimorder/:aimorderid", component: SingleAIMOrderPage },
            { path: "/accorder/:accorderid", component: SingleACCOrderPage },

            { path: "/acorderconfdelin/:toaddressid", component: PickupDelIn },
            {
              path: "/acorderconfdelout/:toaddressid",
              component: DropoffDelOut,
            },
            {
              path: "/acorderconfstodelin/:acorderid",
              component: StorageDelIn,
            },
            {
              path: "/acorderconfstodelout/:acorderid",
              component: StorageDelOut,
            },
            { path: "/acorderconfstoin/:acorderid", component: StorageStoIn },
            { path: "/acorderconfstoout/:acorderid", component: StorageStoIn },

            { path: "/delconfpickup/:deliveryid", component: CompletePickup },
            {
              path: "/delconfdropoff/:toaddressid",
              component: CompleteDropoff,
            },

            { path: "/sacstaff/dash", component: StaffStorageMain },
            { path: "/sacstaff/locations", component: ItemLocationsMain },
            { path: "/sac/cal/:clientid", component: SACCalendarPage },
            { path: "/sac/dash/:clientid", component: StorageACCDash },
            { path: "/sac/new/:clientid", component: NewEditStorageMain },
            { path: "/sac/edit/:acorderid", component: NewEditStorageMain },
            {
              path: "/sacinvoice/newinvoice/:clientid",
              component: NewACInvoice,
            },
            {
              path: "/sacinvoice/editinvoice/:acinvid",
              component: EditACInvoice,
            },

            {
              path: "/dacinvoice/newinvoice/:clientid",
              component: NewDelInvoice,
            },
            {
              path: "/dacinvoice/editinvoice/:delinvid",
              component: EditDelInvoice,
            },
            { path: "/dac/cal/:clientid", component: DACCalendarPage },
            { path: "/dac/dash/:clientid", component: DeliveryACCDash },
            { path: "/dac/new/:clientid", component: NewDeliveryRequestMain },
            { path: "/acc/dash/:clientid", component: ACCDashboard },
            { path: "/acc/new/:clientid", component: NewACCOrderMain },
            { path: "/aim/dash/:clientid", component: AIMDashboard },
            { path: "/aim/new/:clientid", component: NewAIMOrderMain },
            { path: "/ac/calendar/:clientid", component: AccountCalendarTab },
            { path: "/ac/newrequest/:clientid", component: NewRequestMain },
            { path: "/acconfirmdel0/:orderid", component: CompleteDelMain0 },
            { path: "/qrprintitem/:itemid", component: SingleItemQR },
            { path: "/qrprintorder/:acordernumid", component: MultipleQRs },
            { path: "/damagereports", component: MainDamageReport },
            { path: "/equipment/:equipid", component: SingleEquipmentPage },
            { path: "/equipment", component: EquipmentMain },
            { path: "/staffdailyattendance", component: StaffDailyAttendance },
          ];
        }
      }

      if (user.finance) {
        EMaccounts = [
          ...EMaccounts,
          { path: "/finance/payments", component: AllPaymentsMain },
          { path: "/finance/clientbalance", component: ClientBalancesMain },
          { path: "/finance/statistics", component: FinancialStats },
          { path: "/finance/warehousesummary", component: WarehouseSummary },
        ];
      }

      if (user.admin) {
        AdminStaff = [
          { path: "/admin/services", component: AdminServices },
          { path: "/admin/userlist", component: UserList },
        ];
      }

      if (user.hr) {
        HRStaff = [
          { path: "/staffhr/leaves", component: HolidayMain },

          {
            path: "/staffhr/attendanceapproval",
            component: StaffAttendanceApproval,
          },
        ];
      }

      if (user.mgt) {
        MgtStaff = [
          { path: "/mgt/hr", component: StaffTabs },
          { path: "/mgt/hrstaff/:staffid", component: SingleStaffMain },
          { path: "/truckmap", component: AllTruckMap },
          { path: "/mgt/feedback", component: FeedbackMain },
          { path: "/acedit/:clientid", component: EditAccountMain },
          { path: "/mgt/attendance", component: AttendanceMain },
          { path: "/mgt/emoverview", component: EMOverviewMain },
        ];
      }
      if (user.operhead) {
        OptHead = [
          { path: "/truckmap", component: AllTruckMap },
          { path: "/staffhr/attendancemain", component: StaffAttendanceMain },
        ];
      }
      if (user.company) {
        ClientAccount = [
          { path: "/ac/dashboard/:clientid", component: AccountDashboard },
        ];
        if (user.company.acc) {
          ClientAccount = [
            ...ClientAccount,
            { path: "/accorder/:accorderid", component: SingleACCOrderPage },
            { path: "/acc/dash/:clientid", component: ACCDashboard },
          ];
          if (user.comprequest) {
            ClientAccount = [
              ...ClientAccount,

              { path: "/acc/new/:clientid", component: NewACCOrderMain },
            ];
          }
        }
        if (user.company.aim) {
          ClientAccount = [
            ...ClientAccount,
            { path: "/aimorder/:aimorderid", component: SingleAIMOrderPage },
            { path: "/aim/dash/:clientid", component: AIMDashboard },
          ];
          if (user.comprequest) {
            ClientAccount = [
              ...ClientAccount,
              { path: "/aim/new/:clientid", component: NewAIMOrderMain },
            ];
          }
        }
        if (user.company.ast) {
          ClientAccount = [
            ...ClientAccount,
            { path: "/acorder/:acorderid", component: SingleACOrderPage },
            { path: "/sac/dash/:clientid", component: StorageACCDash },
          ];
          if (user.comprequest) {
            ClientAccount = [
              ...ClientAccount,
              { path: "/sac/new/:clientid", component: NewEditStorageMain },
              { path: "/sac/edit/:acorderid", component: NewEditStorageMain },
            ];
          }
        }
        if (user.company.adel) {
          if (!user.company.hidedel) {
            ClientAccount = [
              ...ClientAccount,
              { path: "/delorder/:deliveryid", component: SingleDelOrderPage },
              { path: "/dac/dash/:clientid", component: DeliveryACCDash },
            ];
            if (user.comprequest) {
              ClientAccount = [
                ...ClientAccount,
                {
                  path: "/dac/new/:clientid",
                  component: NewDeliveryRequestMain,
                },
              ];
            }
          }
        }
        if (user.compadmin) {
          ClientAccount = [
            ...ClientAccount,
            {
              path: "/ac/admin/settings/:clientid",
              component: AccAdminSettings,
            },
            { path: "/ac/admin/users/:clientid", component: AdminAccountUsers },
          ];
        }
      }
    }

    let EMhome = [
      { path: "/trackdelivery/:delid", component: MainDeliveryTracking },
      { path: "/confirmemail/:userid", component: ConfirmEmail },
      { path: "/singlejob/:singlejobid", component: SingleJobMain },
      { path: "/feedback/delivery/:delid", component: DeliveryFeedback },
      { path: "/feedback/job/:singlejobid", component: JobFeedback },
      { path: "/itemtrack/:acitemid", component: SingleItemPage },
      { path: "/em-home", component: MainHomePage },
      { path: "/em-aboutus", component: AboutUs },
      { path: "/em-storage", component: StorageSolution },
      { path: "/em-localmove", component: LocalMoveSolution },
      { path: "/em-internationalmove", component: InterMoveSolution },
      { path: "/em-lastmile", component: LastMile },
      { path: "/em-customclearance", component: CustomClearSolution },
      { path: "/em-contactus", component: FooterPage },
      { path: "/em-warehousing", component: WarehousingSupplyChain },
      { path: "/em-freightforwarding", component: FreightForwarding },
      { path: "/em-fullfillment", component: FullFillment },
    ];

    let AllUsers = [];
    if (userStore.signedIn) {
      AllUsers = [
        { path: "/profile", component: ProfileMain },
        { path: "/referral/login/:tokenid", component: LinkAccount },
        { path: "/referral/signup/:tokenid", component: LinkAccount },
        { path: "/", component: MainHomePage },
      ];
    }

    if (underconstruction) {
      return (
        <Switch>
          {EMhome.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          <Route path="/underconstruction" component={UnderConstructionPage} />

          <Redirect to="/underconstruction" />
        </Switch>
      );
    }
    if (!userStore.signedIn) {
      return (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/referral/login/:tokenid" component={ReferralLogin} />
          <Route path="/signup" component={SignUpPage} />
          <Route
            path="/referral/signup/:tokenid"
            component={ReferralRegister}
          />
          <Route path="/forgotpass" component={ForgotPass} />
          <Route path="/resetpassword/:resettoken" component={ResetPassword} />

          {EMhome.map((route, index) => (
            <Route key={index} path={route.path} component={route.component} />
          ))}
          <Route path="/" component={MainHomePage} />
          <Redirect to="/em-home" />
        </Switch>
      );
    }

    return (
      <Switch>
        {WHMovePages.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {EMhome.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {EMaccounts.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {ClientTabs.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {EMstaff.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {AdminStaff.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {HRStaff.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {MgtStaff.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {OptHead.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {ClientAccount.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
        {AllUsers.map((route, index) => (
          <Route key={index} path={route.path} component={route.component} />
        ))}
      </Switch>
    );
  }
  render() {
    return (
      <div
        style={{
          minHeight: "80vh",
          backgroundImage: `url(${backgroundpic})`,
          // height: "100vh",
          // backgroundSize: "cover",
          // backgroundRepeat: "repeat-y",
        }}
      >
        {this.getView()}
        {window.location.pathname.slice(1) !== "em-contactus" && (
          <Box displayPrint="none">
            <FooterPage />
          </Box>
        )}
      </div>
    );
  }
}

export default withRouter(observer(Views));
