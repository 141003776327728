import moment from "moment";

function TRC(delCont, express, afterhours, reqtime) {
  let mintime = delCont.timetoreq;
  let start = moment();
  if (reqtime) start = moment(reqtime);
  if (express) mintime = delCont.expresstime;
  let earliest = moment(start).add(mintime, "hours");
  let curhr = moment(earliest).hour();
  let days = {
    0: "sun",
    1: "mon",
    2: "tue",
    3: "wed",
    4: "thu",
    5: "fri",
    6: "sat",
  };

  let hrs = {
    0: "Yes",
    1: "Yes",
    2: "Yes",
    3: "Yes",
    4: "Yes",
    5: "Yes",
    6: "Yes",
    7: "Yes",
    8: "Yes",
    9: "Yes",
    10: "Yes",
    11: "Yes",
    12: "Yes",
    13: "Yes",
    14: "Yes",
    15: "Yes",
    16: "Yes",
    17: "Yes",
    18: "Yes",
    19: "Yes",
    20: "Yes",
    21: "Yes",
    22: "Yes",
    23: "Yes",
    sun: "Yes",
    mon: "Yes",
    tue: "Yes",
    wed: "Yes",
    thu: "Yes",
    fri: "Yes",
    sat: "Yes",
  };
  if (delCont.excludedhours) {
    hrs = delCont.excludedhours;
  }

  let day = moment(earliest).day();

  let dayind;
  if (days[day]) {
    if (hrs[days[day]]) {
      dayind = hrs[days[day]];
    }
  }

  for (let i = 0; i < 200; i++) {
    day = moment(earliest).day();
    dayind = hrs[days[day]];
    curhr = moment(earliest).hour();
    if (hrs[curhr] === "No" || dayind === "No") {
      earliest = moment(earliest)
        .add(1, "hour")
        .set({ minute: 0, second: 0, millisecond: 0 });
    } else if (!afterhours & (hrs[curhr] === "After" || dayind === "After")) {
      earliest = moment(earliest)
        .add(1, "hour")
        .set({ minute: 0, second: 0, millisecond: 0 });
    } else {
      i = 200;
    }
  }

  return earliest;
}

export { TRC };
