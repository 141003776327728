import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import tlStore from "../../../Stores/StaffStores/TLStore";
import Div14 from "../../../Components/Dividers/Div14";
import JobRows from "./JobRows";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import LoadBack from "../../../Components/Loaders/LoadBack";

class PendingTLAccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    tlStore.getTLJobs(true);
  }

  render() {
    let deljobs = tlStore.delivery;

    return (
      <LoadBack loading={tlStore.loading.jobs}>
        <Div14>
          Pending Delivery Jobs{" "}
          <IconButton
            circle
            size="xs"
            loading={tlStore.loading.jobs}
            icon={<Icon icon="refresh" />}
            onClick={() => {
              tlStore.getTLJobs(true);
            }}
          />
        </Div14>
        <FlexboxGrid justify="center">
          {deljobs.map((job, index) => (
            <JobRows job={job} key={index} />
          ))}
        </FlexboxGrid>
      </LoadBack>
    );
  }
}

export default withRouter(observer(PendingTLAccountPage));
