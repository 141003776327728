import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { FlexboxGrid, Col } from "rsuite";
import newAimStore from "../../../../../Stores/AccountUpdated/AIM/NewAIMStore";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import Div14 from "../../../../../Components/Dividers/Div14";

class AIMStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let aim = newAimStore.newaim;

    return (
      <div>
        <Div14>Step 2: Main Shipment Information</Div14>
        <div
          style={{
            padding: "2%",
            border: "1px solid black",
            borderRadius: "5px",
            boxShadow: "1px 1px 5px black",
          }}
        >
          <FlexboxGrid justify="center">
            <Col md={12} sm={20} xs={24}>
              <NumbInputSTD
                title="Length (m)"
                val={aim.length}
                min={0}
                label="length"
                size="large"
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <NumbInputSTD
                title="Width (m)"
                val={aim.width}
                min={0}
                label="width"
                size="large"
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <NumbInputSTD
                title="Height (m)"
                val={aim.height}
                min={0}
                label="height"
                size="large"
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
            <Col md={12} sm={20} xs={24}>
              <NumbInputSTD
                title="Ch. Weight (KG)"
                val={aim.weight}
                min={0}
                label="weight"
                size="large"
                editVal={newAimStore.editOrder}
              />
              <br />
            </Col>
          </FlexboxGrid>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(AIMStep2));
