import { observer } from "mobx-react";
import React, { Component } from "react";

import { FlexboxGrid, Icon, IconButton } from "rsuite";
import singleAccountStore from "../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import MainAccountInfo from "../../StorageAccount/SubContractSummary/MainAccountInfo";
import { Tabs } from "antd";
import OrderMain from "./Tabs/OrderTable/OrderMain";
import ACCCharges from "./Tabs/Charges/ACCCharges";
import MainInvoicePage from "./Tabs/ACCInvoiceModals/MainInvoicePage";
import PaymentMain from "./Tabs/Payments/PaymentMain";
import ACCCalendar from "../Calendar/ACCCalendar";

const { TabPane } = Tabs;

class DeliveryACCTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Shipments" };
  }

  componentDidMount() {
    singleAccountStore.getAccount(this.props.clientid);
  }
  render() {
    return (
      <div>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Calendar"} key={"Calendar"}>
            <ACCCalendar />
          </TabPane>
          <TabPane tab={"Shipments"} key={"Shipments"}>
            <OrderMain clientid={this.props.clientid} />
          </TabPane>
          <TabPane tab={"Job Charges"} key={"Job Charges"}>
            <ACCCharges />
          </TabPane>
          <TabPane tab={"Invoices"} key={"Invoices"}>
            <MainInvoicePage />
          </TabPane>
          <TabPane tab={"Payments"} key={"Payments"}>
            <PaymentMain />
          </TabPane>
          <TabPane tab={"Contract Info"} key={"Contract Info"}>
            <FlexboxGrid justify="end">
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => singleAccountStore.getAccount(false, true)}
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <MainAccountInfo client={singleAccountStore.account} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default observer(DeliveryACCTabs);
