import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@material-ui/core";

import SingleLink from "../SingleLink";
import { FlexboxGrid, Avatar } from "rsuite";

class ClientTab extends Component {
  render() {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={
            <Tooltip title="Client Tab" arrow placement="right">
              <Avatar circle size="sm" style={{ background: "#303f5e" }}>
                CT
              </Avatar>
            </Tooltip>
          }
        >
          {this.props.open && <Typography>Client Tab</Typography>}
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <FlexboxGrid>
            <SingleLink
              page="/calendar"
              icon="calendar"
              title="Calendar"
              hdc={this.props.hdc}
            />
          </FlexboxGrid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withRouter(observer(ClientTab));
