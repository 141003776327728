import { Tooltip } from "antd";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { FlexboxGrid, Button, Col, InputGroup, Input, Icon } from "rsuite";
import { DarkColorCalc } from "../../../../Functions/Colors/DarkColorCalc";
import sideJobStore from "../../../../Stores/SideJobs/SideJobStore";

class FilterButtons extends Component {
  render() {
    let checkconv = sideJobStore.loadconv;
    let checkcanc = sideJobStore.loadcanc;

    let type = this.props.type;
    let jobbutdata = [
      {
        tooltip: "Filter Local Move Jobs",
        title: "Local Move",
        label: "lm",
        show: true,
      },
      {
        tooltip: "Filter International Move Jobs",
        title: "International Move",
        label: "im",
        show: true,
      },
      {
        tooltip: "Filter Custom Clearance Jobs",
        title: "Custom Clearance",
        label: "cc",
        show: true,
      },
      {
        tooltip: "Filter Storage Jobs",
        title: "Storage Jobs",
        label: "st",
        show: true,
      },
    ];
    let jobtypebut;
    if (type === "all") {
      jobtypebut = jobbutdata.map((but, index) => (
        <Col md={6} sm={6} xs={12} key={index}>
          <Tooltip title={but.tooltip}>
            <Button
              size="xs"
              block
              style={{
                backgroundColor: sideJobStore.filter[but.label]
                  ? "black"
                  : "white",
                border: `1px solid black`,
                color: sideJobStore.filter[but.label] ? "white" : "black",
                fontWeight: "bold",
              }}
              onClick={() =>
                sideJobStore.editJobFilt(
                  but.label,
                  !sideJobStore.filter[but.label]
                )
              }
            >
              {but.title}
            </Button>
          </Tooltip>
        </Col>
      ));
    }

    let buttondata = [
      {
        tooltip: "Filter Leads",
        title: "Lead",
        label: "lead",
        show: true,
      },
      {
        tooltip: "Filter Proposals",
        title: "Proposal",
        label: "proposal",
        show: true,
      },
      {
        tooltip: "Filter Booked",
        title: "Booked",
        label: "booked",
        show: true,
      },
      {
        tooltip: "Filter Converted",
        title: "Converted",
        label: "converted",
        show: checkconv,
      },
      {
        tooltip: "Filter Cancelled",
        title: "Cancelled",
        label: "cancelled",
        show: checkcanc,
      },
    ];

    let buttonlist = buttondata.filter((but) => but.show);

    let finalButtons = buttonlist.map((but, index) => (
      <Col md={3} sm={7} xs={9} key={index}>
        <Tooltip title={but.tooltip}>
          <Button
            block
            style={{
              color: sideJobStore.filter[but.label]
                ? "white"
                : DarkColorCalc(but.title),
              backgroundColor: sideJobStore.filter[but.label]
                ? DarkColorCalc(but.title)
                : "white",
              border: `1px solid ${DarkColorCalc(but.title)}`,
              fontWeight: "bold",
            }}
            onClick={() =>
              sideJobStore.editFilt(but.label, !sideJobStore.filter[but.label])
            }
          >
            {but.title}
          </Button>
        </Tooltip>
      </Col>
    ));

    return (
      <div>
        {type === "all" && (
          <div style={{ paddingBottom: "5px" }}>
            <FlexboxGrid align="middle" justify="center">
              {jobtypebut}
            </FlexboxGrid>
          </div>
        )}
        <FlexboxGrid align="middle" justify="center">
          <Col md={1} sm={1} xs={3}>
            <Tooltip title={"Reset Filters"}>
              <Button
                appearance="primary"
                block
                onClick={() => sideJobStore.resetFilt()}
              >
                <Icon icon="refresh" />
              </Button>
            </Tooltip>
          </Col>

          {finalButtons}

          <Col md={4} sm={8} xs={12}>
            <InputGroup inside>
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
              <Input
                value={sideJobStore.filter.text}
                onChange={(e) => sideJobStore.editFilt("text", e)}
              />
              {sideJobStore.filter.text !== "" && (
                <InputGroup.Button
                  onClick={() => sideJobStore.editFilt("text", "")}
                  style={{ color: "red" }}
                >
                  <Icon icon="close-circle" />
                </InputGroup.Button>
              )}
            </InputGroup>
          </Col>
        </FlexboxGrid>
      </div>
    );
  }
}

export default observer(FilterButtons);
