import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../../../Components/Dividers/Div14";
import { FlexboxGrid, Icon, IconButton, Uploader } from "rsuite";

import { message } from "antd";
import ImageCropModal from "../../../../../../SingleJob/IndividualTabs/SubJobs/Attachments/ImageCropper/ImageCropModal";

import axios from "axios";
import baseURL from "../../../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../../../Stores/UserStore";
import newStorageStore from "../../../../../../../Stores/AccountUpdated/Storage/NewStorageStore";

const instance = axios.create({ baseURL: baseURL });

class DRAttachmentMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      attachment: null,
      delete: false,
      src: null,
    };
    this.onHide = this.onHide.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
  }

  checkFileType = async (file) => {
    if (file.blobFile.type.substring(0, 3) === "ima") {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(file.blobFile);
    } else {
      // this.uploadDocument(file.blobFile);
    }
  };

  onHide() {
    this.setState({ show: false, delete: false, src: null });
  }

  uploadDocument = async (file) => {
    let attType = "Damage Report Picture";

    this.setState({ loading: true });
    let fd = new FormData();
    fd.append(
      "file",
      file,
      `${attType}_${Math.round(Math.random() * 100)}_${file.name}`
    );

    fd.append("damageReportNumId", this.props.dr.damageReportNumId);
    fd.append("acitemid", this.props.item.acItemNumId);

    await instance
      .post(`ac/damagereport/addattachment/${this.props.dr._id}`, fd, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        newStorageStore.getSingleACOrder(false, true);
        this.setState({ loading: false, show: false, src: null });
        message.success(`Damage Report Item Picture Uploaded.`);
      })
      .catch((err) => {
        Notification["error"]({ title: `An error occured` });
      });
  };

  render() {
    let item = this.props.item;

    return (
      <div>
        <ImageCropModal
          loading={this.state.loading}
          uploadDocument={this.uploadDocument}
          hideModal={this.onHide}
          src={this.state.src}
        />
        <Div14>
          Add Attachment to Item # {item.acItemNumId}: {item.name} (
          {item.serialnumber})
        </Div14>
        <hr />
        {item.attachment ? (
          <>
            <h1>Show Picture</h1>
          </>
        ) : (
          <FlexboxGrid justify="center">
            <Uploader
              multiple={false}
              accept="image/*"
              fileListVisible={false}
              onChange={(file) => {
                let finalfile = file[file.length - 1];

                return new Promise((resolve, reject) => {
                  let fileSize = finalfile.blobFile.size / 1000000;

                  if (
                    (finalfile.blobFile.type.substring(0, 3) !== "ima") &
                    (fileSize > 10)
                  ) {
                    message.error("Not Uploaded. Must be less than 10 MB.");
                    reject();
                  } else {
                    this.checkFileType(finalfile);
                    resolve();
                  }
                });
              }}
              draggable
            >
              <IconButton
                icon={<Icon icon="plus" />}
                color="green"
                onClick={() => this.setState({ show: true })}
              >
                Add Image
              </IconButton>
            </Uploader>
          </FlexboxGrid>
        )}
      </div>
    );
  }
}

export default withRouter(observer(DRAttachmentMain));
