import { observer } from "mobx-react";
import React, { Component } from "react";

import { PDFViewer } from "@react-pdf/renderer";

import { Document } from "@react-pdf/renderer";
import moment from "moment";

import PaymentNotes from "./StandardOnAll/PaymentPages/PaymentNotes";
import TermsAndConditions from "./StandardOnAll/Terms/TermsAndConditions";
import HeaderSection from "./StandardOnAll/HeaderSection/HeaderSection";

import HeaderFooterPDF from "../Template/HeaderFooterPDF";

import TotalTable from "./SubPages/Prices/TotalTable";
import SJDetails from "./SubPages/JobDetails/SJDetails";

class IntroPage extends Component {
  render() {
    let inv = this.props.inv;

    let invoiceID = 123;
    let clientname = "Abdullah AlAwadhi";

    return (
      <div>
        <PDFViewer
          width={"100%"}
          height={600}
          fileName={`Invoice ${invoiceID}-
                ${moment().format("YYMMDDHH")} for ${clientname}`}
        >
          <Document
            author={"Easy Move Logistics Co."}
            title={`Invoice ${invoiceID}-
                ${moment().format("YYMMDDHH")} for ${clientname}`}
            creator={"Easy Move Logistics Co."}
          >
            <HeaderFooterPDF>
              <HeaderSection inv={inv} />
              <TotalTable inv={inv} />
            </HeaderFooterPDF>
            <SJDetails inv={inv} />
            <HeaderFooterPDF>
              <PaymentNotes inv={inv} />
            </HeaderFooterPDF>
            <HeaderFooterPDF>
              <TermsAndConditions />
            </HeaderFooterPDF>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default observer(IntroPage);
