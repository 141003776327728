let shippinglines = [
  {
    scac: "Not Available",
    name: "Not Available",
    short_name: "Not Available",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "ACLU",
    name: "Atlantic Container Line",
    short_name: "ACL",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "ANNU",
    name: "Australia National Line",
    short_name: "ANL",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "APLU",
    name: "American President Lines",
    short_name: "APL",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "CHVW",
    name: "Swire Shipping",
    short_name: "Swire",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "CMDU",
    name: "CMA CGM",
    short_name: "CMA CGM",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "COSU",
    name: "COSCO",
    short_name: "COSCO",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "CULU",
    name: "China United Lines",
    short_name: "CULines",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "EGLV",
    name: "Evergreen",
    short_name: "Evergreen",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "HDMU",
    name: "Hyundai Merchant Marine",
    short_name: "Hyundai",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "HLCU",
    name: "Hapag-Lloyd",
    short_name: "Hapag-Lloyd",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "MAEU",
    name: "Maersk",
    short_name: "Maersk",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "MATS",
    name: "Matson Navigation Company",
    short_name: "Matson",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "MSCU",
    name: "Mediterranean Shipping Company",
    short_name: "MSC",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "ONEY",
    name: "Ocean Network Express",
    short_name: "ONE",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "OOLU",
    name: "Orient Overseas Container Line",
    short_name: "OOCL",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "PCIU",
    name: "Pacific International Lines",
    short_name: "PIL",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "SAFM",
    name: "Safmarine Container Line",
    short_name: "Safmarine Container Line",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "SEAL",
    name: "Sealand Americas",
    short_name: "SeaLand Americas",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "SEJJ",
    name: "SeaLand Europe",
    short_name: "SeaLand Europe",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "SEJJ",
    name: "Seago Line",
    short_name: "Seago Line",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "SMLM",
    name: "SM Line",
    short_name: "SM Line",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "SUDU",
    name: "Hamburg Sud",
    short_name: "Hamburg Sud",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: true,
  },
  {
    scac: "WHLC",
    name: "Wan Hai Lines",
    short_name: "Wan Hai Lines",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "WWSU",
    name: "Westwood Shipping Lines",
    short_name: "Westwood",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "YMLU",
    name: "Yangming Marine Transport",
    short_name: "Yangming",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
  {
    scac: "ZIMU",
    name: "Zim American Integrated Shipping Services",
    short_name: "Zim Line",
    bill_of_lading_tracking_support: true,
    booking_number_tracking_support: true,
    container_number_tracking_support: false,
  },
];
export default shippinglines;
