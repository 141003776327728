import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import LoadBack from "../../../../Components/Loaders/LoadBack";
import whmCompanyStore from "../../WHMStores/WHMCompanyStore";
import ExistingRows from "./ExistingRows";

import { Empty, Pagination } from "antd";
import { FlexboxGrid } from "rsuite";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import Col2 from "../../../../Components/Columns/Col2";

class WHMItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
    };
  }

  render() {
    let data = this.props.data;
    if (whmCompanyStore.filter !== "") {
      data = data.filter((item) =>
        `${item.name} ${item.acItemNumId} ${item.cbm} ${item.serialnumber} ${item.productid}  ${item.dimensions}`
          .toLowerCase()
          .includes(whmCompanyStore.filter.toLowerCase())
      );
    }

    let showdata = data.slice(
      (this.state.page - 1) * this.state.pageSize,
      this.state.page * this.state.pageSize
    );
    let list = showdata.map((it, index) => (
      <ExistingRows item={it} key={index} index={index} />
    ));
    if (data.length <= 0) {
      list = <Empty description="All Items are Palletized" />;
    }
    return (
      <LoadBack loading={whmCompanyStore.loading.unpalletized}>
        <FlexboxGrid>
          <Col2>
            <TextInputSTD
              title="Search"
              label="filter"
              size="sm"
              val={whmCompanyStore.filter}
              editVal={(val) => whmCompanyStore.itemSearch(val)}
            />
          </Col2>
          <Col2>
            {data.length > this.state.pageSize && (
              <Pagination
                pageSize={this.state.pageSize}
                total={data.length}
                showQuickJumper
                onChange={(e) => this.setState({ page: e })}
                onShowSizeChange={(current, size) =>
                  this.setState({ pageSize: size })
                }
                pageSizeOptions={[10, 20, 50]}
              />
            )}
          </Col2>
        </FlexboxGrid>

        <br />
        {list}
      </LoadBack>
    );
  }
}

export default withRouter(observer(WHMItemsList));
