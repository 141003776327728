import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Divider, FlexboxGrid, Icon, IconButton } from "rsuite";
import moment from "moment";
import notificationStore from "../../../Stores/NotificationStore";

class NotificationRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let notify = this.props.notify;
    return (
      <div>
        <div>
          {notify.map((not, index) => (
            <div key={index}>
              <FlexboxGrid>
                <IconButton
                  icon={<Icon icon="trash" />}
                  color="red"
                  size="xs"
                  onClick={() => notificationStore.deleteNotification(not._id)}
                >
                  Delete
                </IconButton>
                <Divider vertical />
                <h5>
                  <>{not.title}</>
                  <div style={{ fontSize: 8, fontWeight: "normal" }}>
                    <i>
                      {moment(not.createdAt).format("DD-MMM-YY HH:mm")} (
                      {moment(not.createdAt).fromNow()})
                    </i>
                  </div>
                </h5>
                {not.actionbut && (
                  <>
                    <Divider vertical />
                    <IconButton
                      icon={<Icon icon="chevron-right" />}
                      color="green"
                      size="xs"
                      onClick={() => {
                        this.props.history.push(not.link);
                        this.props.onHide();
                      }}
                    >
                      {not.actionbut}
                    </IconButton>
                  </>
                )}
              </FlexboxGrid>
              <p>{not.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(observer(NotificationRow));
