import { observer } from "mobx-react";
import React, { Component } from "react";

import { Tabs } from "antd";
import ItemInventory from "./Tabs/InventoryTable/ItemInventory";
import singleAccountStore from "../../../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import OrderMain from "./Tabs/OrderTable/OrderMain";
import InvGraph from "./Tabs/InvGraph/InvGraph";
import ItemMoveHistory from "./Tabs/ItemMoveHistory/ItemMoveHistory";
import StorageCharges from "./Tabs/StorageCharges/StorageCharges";
import ACInvoiceMain from "./Tabs/Invoices/ACInvoiceMain";
import PaymentMain from "./Tabs/Payments/PaymentMain";
import SACCalendarPage from "../../Calendar/SACCalendarPage";
import StorageContractInfo from "../../SubContractSummary/StorageContractInfo";
import MainAccountInfo from "../../SubContractSummary/MainAccountInfo";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import DeliveryContractInfo from "../../SubContractSummary/DeliveryContractInfo";

import StatisticsMain from "./Tabs/StorageStats/StatisticsMain";

const { TabPane } = Tabs;

class StorageACCTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "Inventory" };
  }

  componentDidMount() {
    singleAccountStore.getAccount(this.props.clientid);
  }
  render() {
    return (
      <div>
        <Tabs
          activeKey={this.state.tab}
          defaultActiveKey={0}
          tabPosition={"top"}
          type="card"
          centered
          onChange={(e) => {
            this.setState({ tab: e });
          }}
        >
          <TabPane tab={"Inventory"} key={"Inventory"}>
            <ItemInventory clientid={this.props.clientid} />
          </TabPane>
          <TabPane tab={"Orders"} key={"Orders"}>
            <OrderMain clientid={this.props.clientid} />
          </TabPane>
          <TabPane tab={"Inv Graph"} key={"Inv Graph"}>
            <InvGraph />
          </TabPane>
          <TabPane tab={"Item Movement"} key={"Item Movement"}>
            <ItemMoveHistory />
          </TabPane>
          <TabPane tab={"Storage Charges"} key={"Storage Charges"}>
            <StorageCharges />
          </TabPane>
          <TabPane tab={"Invoices"} key={"Invoices"}>
            <ACInvoiceMain />
          </TabPane>
          <TabPane tab={"Payments"} key={"Payments"}>
            <PaymentMain />
          </TabPane>
          <TabPane tab={"Calendar"} key={"Calendar"}>
            <SACCalendarPage />
          </TabPane>
          <TabPane tab={"Statistics"} key={"Statistics"}>
            <StatisticsMain />
          </TabPane>
          <TabPane tab={"Storage Contract"} key={"Storage Contract"}>
            <FlexboxGrid justify="end">
              <IconButton
                icon={<Icon icon="refresh" />}
                onClick={() => singleAccountStore.getAccount(false, true)}
              >
                Refresh
              </IconButton>
            </FlexboxGrid>
            <MainAccountInfo client={singleAccountStore.account} />
            <DeliveryContractInfo
              delCont={singleAccountStore.account.deliveryContract}
            />
            <StorageContractInfo
              storCont={singleAccountStore.account.storageContract}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default observer(StorageACCTabs);
