import React, { Component } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

class ExcellentFeedback extends Component {
  render() {
    return (
      <div>
        <h5>
          We rely on our customer's feedback to improve and your feedback has
          been sent to our Management.
        </h5>
        <h5>
          It would be helpful if you can support us with a Google Maps rating
          and share your experience with others.
        </h5>
        <br />
        <FlexboxGrid
          justify="center"
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <IconButton
            icon={<Icon icon="globe" />}
            color="green"
            onClick={() =>
              window.open(`https://g.page/r/CcDSDhNEKEeYEB0/review`)
            }
          >
            Create Google Maps Review
          </IconButton>
        </FlexboxGrid>
        <br />
      </div>
    );
  }
}

export default ExcellentFeedback;
