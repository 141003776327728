import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import moment from "moment";
import { Notification } from "rsuite";

import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class NewAccountStore {
  constructor() {
    this.account = {
      name: "",
      email: "",
      type: "",
      phone: "",
      phone2: "",
      heardofus: "Other",
      leadtype: "Other",
      remarks: "",
      status: "Pending",
      startdate: moment().startOf("D"),
      enddate: moment().startOf("D").add(1, "Y"),
      aim: false,
      acc: false,
      ast: false,
      adel: false,
      hidedel: false,
      focalpoint: userStore.user,
    };
    this.orgaccount = {};
    this.storCont = {};
    this.orgstorCont = {};
    this.delCont = {
      remarks: "",
      maintype: "Fixed Per Trip",
      fixedweekly: 0,
      freeweeklytrips: 0,

      pricepertruck: 35,
      dropoffmult: 1,
      staffcount: 0,
      carcount: 1,
      maxcbmpertrip: 0,

      nongroundfloorprice: 0,

      cancelhours: 8,
      cancelprice: 40,
      timetoreq: 24,
      afterhourfee: 20,
      expresstime: 8,
      expressfee: 20,

      pricepertrip: {
        sun: 0,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
      }, // Price Per Job
      zoneadd: {
        z1: 0,
        z2: 0,
        z3: 0,
        z4: 0,
        z5: 0,
      }, // Price Per Job

      excludedhours: {
        0: "No",
        1: "No",
        2: "No",
        3: "No",
        4: "No",
        5: "No",
        6: "After",
        7: "After",
        8: "Yes",
        9: "Yes",
        10: "Yes",
        11: "Yes",
        12: "Yes",
        13: "Yes",
        14: "Yes",
        15: "Yes",
        16: "Yes",
        17: "Yes",
        18: "After",
        19: "After",
        20: "After",
        21: "After",
        22: "No",
        23: "No",
        sun: "Yes",
        mon: "Yes",
        tue: "Yes",
        wed: "Yes",
        thu: "Yes",
        fri: "No",
        sat: "After",
      },
      options: [],
    };
    this.orgdelCont = {};
    this.loading = false;
    this.accloading = false;
    this.emlocsloaded = false;
    this.emlocs = [];
    this.allservices = [];
    this.deltypes = [];

    makeObservable(this, {
      account: observable,
      orgaccount: observable,
      storCont: observable,
      orgstorCont: observable,
      delCont: observable,
      orgdelCont: observable,
      emlocs: observable,
      emlocsloaded: observable,
      loading: observable,
      accloading: observable,
      allservices: observable,
      deltypes: observable,
      services: computed,
    });
  }

  // FETCH

  getAccount(clientid) {
    this.accloading = true;

    return instance
      .get(`/ac/account/getaccount/${clientid}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((account) => {
        this.account = account;
        this.orgaccount = account;
        if (account.storageContract) {
          this.storCont = account.storageContract;
          this.orgstorCont = account.storageContract;
        }
        if (account.deliveryContract) {
          this.delCont = account.deliveryContract;
          this.deltypes = account.deliveryContract.types;
          this.orgdelCont = account.deliveryContract;
        }

        this.accloading = false;
        console.log("Accounts Fetched from New Account");
      })
      .catch((err) => {
        this.accloading = false;
        this.account = false;
        console.log(err);

        Notification["error"]({
          title: `Error Fetching Account.`,
        });
      });
  }

  getServices(override) {
    let loaded = false;
    if (this.allservices.length > 0) loaded = true;

    if (override) loaded = false;
    if (!loaded) {
      return instance
        .get(`/additional/services/acorder`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((services) => {
          this.allservices = services;
          console.log("Services Fetched");
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  }

  fetchEMLocations = async (override) => {
    let loaded = false;
    if (this.emlocsloaded) loaded = true;
    if (override) loaded = false;

    if (!loaded) {
      this.emlocsloaded = true;
      await instance
        .get(`/additional/location/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          this.emlocs = res.data;
          this.emlocsloaded = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
        });
    }
  };

  // POST

  // COMPUTATIONS:

  get services() {
    let allservs = this.allservices;
    if (!this.account.adel) {
      return [];
    } else if (this.account.ast) {
      if (this.account.hidedel) {
        return allservs.filter((serv) => serv.showSTin || serv.showSTin);
      } else {
        return allservs;
      }
    }
    return [];
  }

  // FUNCTIONS: --- Edit Val
  editAccount(val, label) {
    newAccountStore.account[label] = val;
  }
  editStorCont(val, label) {
    newAccountStore.storCont[label] = val;
  }
  editDelCont(val, label) {
    newAccountStore.delCont[label] = val;
  }
  editDelZone(val, label) {
    newAccountStore.delCont.zoneadd[label] = val;
  }
  editDelDay(val, label) {
    newAccountStore.delCont.pricepertrip[label] = val;
  }
  editDelTime(val, label) {
    newAccountStore.delCont.excludedhours[label] = val;
  }
  addOption(option, type) {
    let newopt;

    if (type === "Add") {
      let delstor = "Delivery";
      let inout = "Both";
      let chgtype = "KD";
      if (option.showSTin || option.showSTout) {
        delstor = "Storage";
        chgtype = "KD / CBM";
        if (option.showSTin & !option.showSTout) inout = "In";
        if (!option.showSTin & option.showSTout) inout = "Out";
      }
      newopt = {
        title: option.title,
        desc: option.description,
        price: 0,
        delstor: delstor,
        inout: inout,
        type: chgtype,
      };
      newAccountStore.delCont.options = [
        ...newAccountStore.delCont.options,
        newopt,
      ];
    } else if (type === "Remove") {
      let newopts = [];
      for (let i = 0; i < newAccountStore.delCont.options.length; i++) {
        if (i !== option)
          newopts = [...newopts, newAccountStore.delCont.options[i]];
      }
      newAccountStore.delCont.options = [...newopts];
    }
  }
  editOption(val, label, index) {
    newAccountStore.delCont.options[index][label] = val;
  }
  resetDelTime(val, label) {
    newAccountStore.delCont.excludedhours[label] = val;
  }

  addDelType(type) {
    newAccountStore.deltypes = [...newAccountStore.deltypes, { ...type }];
  }
  removeDelType(index) {
    let newtypes = [];
    for (let i = 0; i < newAccountStore.deltypes.length; i++) {
      if (index !== i) {
        newtypes = [...newtypes, newAccountStore.deltypes[i]];
      }
    }
    newAccountStore.deltypes = [...newtypes];
  }
  addBulkDelType(types) {
    newAccountStore.deltypes = [...newAccountStore.deltypes, ...types];
  }
  editDelType(type, index) {
    newAccountStore.deltypes[index].remarks = type.remarks;
    newAccountStore.deltypes[index].type = type.type;
    newAccountStore.deltypes[index].description = type.description;
    newAccountStore.deltypes[index].price = type.price;
  }

  resetDelTypes() {
    newAccountStore.deltypes = [];
    newAccountStore.deltypes = [...newAccountStore.delCont.types];
  }

  // FUNCTIONS: --- Update Original Val
  updateAccount(val, label) {
    newAccountStore.orgaccount[label] = val;
  }
  updateStorCont(val, label) {
    newAccountStore.orgstorCont[label] = val;
  }
  updateDelCont(val, label) {
    newAccountStore.orgdelCont[label] = val;
  }
  updateDelZone(val, label) {
    newAccountStore.orgdelCont.zoneadd[label] = val;
  }
  updateDelDay(val, label) {
    newAccountStore.orgdelCont.pricepertrip[label] = val;
  }
  updateDelTime(data) {
    newAccountStore.orgdelCont.excludedhours = { ...data.excludedhours };
  }
  updateOptions(data) {
    newAccountStore.orgdelCont.options = [...data.options];
  }
  updateDelTypes(types) {
    newAccountStore.delCont.types = [];
    newAccountStore.delCont.types = [...types];
    newAccountStore.deltypes = [];
    newAccountStore.deltypes = [...types];
  }

  createNewAccount() {
    this.account = {
      name: "",
      email: "",
      type: "",
      phone: "",
      phone2: "",
      heardofus: "Other",
      leadtype: "Other",
      remarks: "",
      status: "Pending",
      startdate: moment().startOf("D"),
      enddate: moment().startOf("D").add(1, "Y"),
      aim: false,
      acc: false,
      ast: false,
      adel: false,
      hidedel: false,
      focalpoint: userStore.user,
    };
  }
  createNewStorCont() {
    this.storCont = {
      remarks: "",
      maintype: "Monthly Rate",
      subtype: "Maximum",
      incharge: 1.5,
      outcharge: 1.5,
      pricepercbm: 4,
      mincbm: 30,
      emlocation: { _id: "" },
      options: [],
    };
  }
  createNewDelCont() {
    this.delCont = {
      remarks: "",
      maintype: "Fixed Per Trip",
      fixedweekly: 0,
      freeweeklytrips: 0,

      pricepertruck: 35,
      dropoffmult: 1,
      staffcount: 0,
      carcount: 1,
      maxcbmpertrip: 0,

      nongroundfloorprice: 0,

      cancelhours: 8,
      cancelprice: 40,
      timetoreq: 24,
      afterhourfee: 20,
      expresstime: 8,
      expressfee: 20,

      pricepertrip: {
        sun: 45,
        mon: 45,
        tue: 45,
        wed: 45,
        thu: 45,
        fri: 45,
        sat: 45,
      }, // Price Per Job
      zoneadd: {
        z1: 0,
        z2: 0,
        z3: 0,
        z4: 0,
        z5: 0,
      }, // Price Per Job
      excludedhours: {
        0: "No",
        1: "No",
        2: "No",
        3: "No",
        4: "No",
        5: "No",
        6: "After",
        7: "After",
        8: "Yes",
        9: "Yes",
        10: "Yes",
        11: "Yes",
        12: "Yes",
        13: "Yes",
        14: "Yes",
        15: "Yes",
        16: "Yes",
        17: "Yes",
        18: "After",
        19: "After",
        20: "After",
        21: "After",
        22: "No",
        23: "No",
        sun: "Yes",
        mon: "Yes",
        tue: "Yes",
        wed: "Yes",
        thu: "Yes",
        fri: "No",
        sat: "After",
      },
      options: [],
    };
  }
  startLoading() {
    this.loading = true;
  }
}

const newAccountStore = new NewAccountStore();
export default newAccountStore;
