import React, { Component } from "react";
import { Tabs } from "antd";
import { observer } from "mobx-react";

import Div14 from "../../../Components/Dividers/Div14";
import StaffMainPage from "./Staff/StaffMainPage";
import HolidayMain from "./Holiday/HolidayMain";
import RenewalPage from "./Renewals/RenewalPage";
import SalaryMain from "./Salary/SalaryMain";
import { withRouter } from "react-router";

const { TabPane } = Tabs;

class SJSTTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { tab: "home", locked: false, step: 1 };
    this.lockstate = this.lockstate.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
  }

  handleModeChange = (e) => {
    this.setState({ tab: e.target.value });
  };

  lockstate() {
    this.setState({ locked: !this.state.locked });
  }
  render() {
    let tabOrientation = "left";
    if (window.innerWidth < 800) tabOrientation = "top";
    tabOrientation = "top";
    return (
      <div style={{ padding: "1%" }}>
        <Div14>Staff Management</Div14>

        <br />
        <Tabs
          size="large"
          activeKey={this.state.tab}
          defaultActiveKey={"home"}
          tabPosition={tabOrientation}
          type="card"
          onChange={(e) => {
            if (this.state.locked) {
              Notification["error"]({
                title: "Editing In Progress",
                description:
                  "Please save or cancel changes of this tab before changing.",
              });
            } else {
              this.setState({ tab: e });
            }
          }}
        >
          <TabPane
            tab={<div>Staff List</div>}
            key={"home"}
            disabled={this.state.locked}
          >
            <StaffMainPage />
          </TabPane>
          <TabPane
            tab={<div>Holidays</div>}
            key={"holidays"}
            disabled={this.state.locked}
          >
            <HolidayMain />
          </TabPane>
          <TabPane
            tab={<div>Staff Renewals</div>}
            key={"staff renewals"}
            disabled={this.state.locked}
          >
            <RenewalPage page={"Staff"} />
          </TabPane>
          <TabPane
            tab={<div>Company Renewals</div>}
            key={"renewals"}
            disabled={this.state.locked}
          >
            <RenewalPage page={"Company"} />
          </TabPane>
          <TabPane
            tab={<div>Salaries</div>}
            key={"salaries"}
            disabled={this.state.locked}
          >
            <SalaryMain />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(observer(SJSTTabs));
