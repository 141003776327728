import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import staffStorageStatStore from "../../../../../../../../Stores/AccountUpdated/StaffSummary/StorageStores/StaffStorageStatStore";
import LoadBack from "../../../../../../../../Components/Loaders/LoadBack";
import Div14 from "../../../../../../../../Components/Dividers/Div14";
import MovementSummary from "./MovementSummary";
import TopMovingItems from "./TopMovingItems";

class StaticItemsList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    staffStorageStatStore.getStaticItems();
  }

  render() {
    return (
      <LoadBack loading={staffStorageStatStore.loading.staticitems}>
        <MovementSummary />

        <Div14>Top Moving Items</Div14>
        <TopMovingItems />
      </LoadBack>
    );
  }
}

export default withRouter(observer(StaticItemsList));
