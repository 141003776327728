import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Col, FlexboxGrid, Icon } from "rsuite";

class IconItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let icon = this.props.icon;
    let text = this.props.text;
    let iconsize = "2x";
    let color = "white";
    if (this.props.iconsize) iconsize = this.props.iconsize;
    if (this.props.color) color = this.props.color;

    return (
      <FlexboxGrid justify="center">
        <Col md={18} sm={24} xs={24}>
          <FlexboxGrid
            style={{ fontSize: 16, fontWeight: "bold" }}
            justify="start"
            align="middle"
          >
            <div>
              <Icon
                icon={icon}
                style={{ color: color, paddingRight: "5px" }}
                size={iconsize}
              />
              {text}
            </div>
          </FlexboxGrid>
        </Col>
      </FlexboxGrid>
    );
  }
}

export default withRouter(observer(IconItem));
