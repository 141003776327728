import { PageHeader, Statistic } from "antd";
import React, { Component } from "react";
import { FlexboxGrid, IconButton, Icon } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import DatePickerSTD from "../../../../Components/Inputs/STD/DatePickerSTD";
import NumbInputSTD from "../../../../Components/Inputs/STD/NumbInputSTD";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextAreaInputSTD from "../../../../Components/Inputs/STD/TextAreaInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import { PRKD } from "../../../../Functions/Pricing/PRKDCalc";
import banklist from "../../../../Static/Lists/financial/banklist";
import departmentcustomer from "../../../../Static/Lists/financial/departmentcustomer";

class SalaryModal extends Component {
  render() {
    let type = this.props.type;
    let staff = this.props.staff;
    let salary = this.props.salary;

    let match = parseFloat(salary.amount) - parseFloat(staff.totalsalary);

    let disable = false;
    if (salary.amount <= 0) disable = true;
    return (
      <div>
        <UnifiedModal
          onHide={this.props.onHide}
          show={this.props.show}
          title={`${type === "New" ? "Pay New " : "Edit Existing "} Salary`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.props.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.props.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    if (type === "New") {
                      this.props.postNew();
                    } else {
                      this.props.postEdit();
                    }
                  }}
                >
                  {type === "New" ? "Create New " : "Save Edited "} Salary
                  Payment
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid>
            <Col2>
              <IconButton
                block
                color="green"
                appearance={
                  salary.method === "Bank Transfer" ? "primary" : "ghost"
                }
                icon={<Icon icon="bank" />}
                onClick={() => this.props.changeMethod("Bank Transfer")}
              >
                Bank Transfer
              </IconButton>
            </Col2>
            <Col2>
              <IconButton
                block
                color="green"
                appearance={salary.method === "Cash" ? "primary" : "ghost"}
                icon={<Icon icon="money" />}
                onClick={() => this.props.changeMethod("Cash")}
              >
                Cash
              </IconButton>
            </Col2>
          </FlexboxGrid>
          <PageHeader
            title={`ID# ${staff.hrStaffNumId}) ${staff.name} Salary: ${staff.type} Staff`}
          >
            <Statistic
              title="Base Salary"
              value={staff.totalsalary}
              suffix="KD"
            />
          </PageHeader>
          <FlexboxGrid style={{ padding: "1%" }}>
            <Col2>
              <DatePickerSTD
                title="Payment Date"
                val={salary.date}
                label={"date"}
                size="large"
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <DatePickerSTD
                title="Salary Month"
                format={"MMM-YY"}
                picker="month"
                val={salary.salarymonth}
                label={"salarymonth"}
                size="large"
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Method"
                val={salary.method}
                label="method"
                size="large"
                opts={[
                  "Bank Transfer",
                  "Cash",
                  "KNET",
                  "Cheque",
                  "Online Link",
                ]}
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Account"
                val={salary.account}
                label="account"
                size="large"
                opts={banklist}
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Department"
                val={salary.accounttype}
                label="accounttype"
                size="large"
                opts={departmentcustomer}
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Party Name."
                val={salary.partyname}
                label={"partyname"}
                size="large"
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Amount (KD)"
                val={salary.amount}
                label={"amount"}
                size="large"
                editVal={this.props.editVal}
              />
              <h6
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: match === 0 ? "green" : "red",
                }}
              >
                {match === 0
                  ? "Amount Matches Salary"
                  : `${PRKD(match)} KD Difference`}
              </h6>
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Payment Ref."
                val={salary.paymentref}
                label={"paymentref"}
                size="large"
                editVal={this.props.editVal}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid style={{ padding: "1%" }} align="top">
            <TextAreaInputSTD
              title="Remarks"
              val={salary.remarks}
              label={"remarks"}
              size="large"
              editVal={this.props.editVal}
            />
          </FlexboxGrid>
        </UnifiedModal>
      </div>
    );
  }
}

export default SalaryModal;
