import { Statistic, DatePicker } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { IconButton, Icon, FlexboxGrid, Notification } from "rsuite";
import Col2 from "../../../../Components/Columns/Col2";
import SelectInputSTD from "../../../../Components/Inputs/STD/SelectInputSTD";
import TextInputSTD from "../../../../Components/Inputs/STD/TextInputSTD";
import UnifiedModal from "../../../../Components/Modal/UnifiedModal";
import userStore from "../../../../Stores/UserStore";
import axios from "axios";
import baseURL from "../../../../Static/baseURL/baseURL";

const instance = axios.create({ baseURL: baseURL });
const { RangePicker } = DatePicker;
class NewHolidayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Planned Leave",
      date: moment(),
      dateto: moment().add(0, "day"),
      remark: "",
      loading: false,
      show: false,
    };
    this.hideModal = this.hideModal.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
  }
  hideModal() {
    this.setState({ show: false });
  }

  editVal(val, type) {
    this.setState({ [type]: val });
  }
  postNew = async (days) => {
    let newleave = {
      type: this.state.type,
      staff: userStore.user.staff._id,
      date: this.state.date,
      dateto: this.state.dateto,
      remark: this.state.remark,
      days: days,
    };
    this.setState({ loading: true });
    let url = "/hr/holiday/new";

    return instance
      .post(url, newleave, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        Notification["success"]({
          title: `Added New Holiday: ${newleave.type}.`,
          description: <div></div>,
        });
        this.hideModal();
        this.setState({ loading: false });
        userStore.fetchProfile(true);
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };
  render() {
    let disabled = false;

    let diff = moment(this.state.dateto).diff(this.state.date, "days") + 1;
    let visdiff = diff;

    let daysavailable = userStore.user.staff.remaining;
    if (this.state.type === "Sick Leave") {
      daysavailable = userStore.user.staff.sickremaining;
    }

    let remaining = parseFloat(daysavailable) - parseFloat(visdiff);

    let holidaytypes = ["Planned Leave", "Unplanned Leave"];
    if (this.state.type === "Sick Leave") holidaytypes = ["Sick Leave"];

    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <IconButton
              block
              size="sm"
              icon={<Icon icon="plus" />}
              color="green"
              onClick={() =>
                this.setState({ show: true, type: "Planned Leave" })
              }
            >
              New Holiday
            </IconButton>
            <br />
          </Col2>
          <Col2>
            <IconButton
              block
              size="sm"
              icon={<Icon icon="plus" />}
              color="red"
              onClick={() => this.setState({ show: true, type: "Sick Leave" })}
            >
              New Sick Leave
            </IconButton>
            <br />
          </Col2>
        </FlexboxGrid>
        <UnifiedModal
          title={"Create a New Holiday"}
          show={this.state.show}
          onHide={this.hideModal}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  icon={<Icon icon="close" />}
                  color="red"
                  block
                  onClick={() => this.hideModal(false)}
                  disabled={this.state.loading}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="plus" />}
                  color="green"
                  block
                  disabled={disabled}
                  loading={this.state.loading}
                  onClick={() => this.postNew(diff)}
                >
                  Create {this.state.type} Holiday
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid style={{ padding: "1%" }}>
            <Col2>
              <SelectInputSTD
                title="Type"
                val={this.state.type}
                label="type"
                size="large"
                opts={holidaytypes}
                editVal={this.editVal}
              />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Calc Days"
                val={diff}
                label="base"
                size="large"
                editVal={this.editVal}
              />
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid style={{ padding: "1%" }}>
            <RangePicker
              style={{ width: "100%" }}
              //format={"DD-MMM-YY @ HA"}
              format={"DD-MMM-YY"}
              value={[moment(this.state.date), moment(this.state.dateto)]}
              allowClear={false}
              showMinute={false}
              onChange={(date) => {
                this.setState({ date: date[0], dateto: date[1] });
              }}
            />
          </FlexboxGrid>
          {this.state.type === "Sick Leae" ? (
            <FlexboxGrid justify="space-around">
              <Statistic
                title="Sick Days Remaining"
                value={daysavailable}
                suffix="days"
              />
              <Statistic
                title=" - "
                value={"-"}
                valueStyle={{ color: "red" }}
              />
              <Statistic
                title="New Sick Days"
                value={diff}
                suffix="days"
                valueStyle={{ color: "red" }}
              />
              <Statistic
                title=" = "
                value={"="}
                valueStyle={{ color: "red" }}
              />
              <Statistic
                title="After Leave"
                value={remaining}
                suffix="days"
                valueStyle={{ color: remaining < 0 ? "red" : "green" }}
              />
            </FlexboxGrid>
          ) : (
            <FlexboxGrid justify="space-around">
              <Statistic
                title="Available Days"
                value={daysavailable}
                suffix="days"
              />
              <Statistic
                title=" - "
                value={"-"}
                valueStyle={{ color: "red" }}
              />
              <Statistic
                title="New Holiday"
                value={diff}
                suffix="days"
                valueStyle={{ color: "red" }}
              />
              <Statistic
                title=" = "
                value={"="}
                valueStyle={{ color: "red" }}
              />
              <Statistic
                title="After Holiday"
                value={remaining}
                suffix="days"
                valueStyle={{ color: remaining < 0 ? "red" : "green" }}
              />
            </FlexboxGrid>
          )}
        </UnifiedModal>
      </div>
    );
  }
}

export default NewHolidayModal;
