import { makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";

const instance = axios.create({ baseURL: baseURL });

class ZoneStore {
  constructor() {
    this.zones = [];
    this.loading = false;
    this.dataloaded = false;

    makeObservable(this, {
      zones: observable,
      loading: observable,
      dataloaded: observable,
    });
  }

  // FETCH
  getZones(overide) {
    if (!this.dataloaded || overide) {
      this.loading = true;
      return instance
        .get(`/additional/zone/all`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((zones) => {
          this.zones = zones;
          this.dataloaded = true;
          this.loading = false;
          console.log("All Zones Fetched");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Zones.`,
          });
        });
    }
  }

  // POST

  // COMPUTATIONS:

  // FUNCTIONS:
  // ----- DATE ----
}

const zoneStore = new ZoneStore();
export default zoneStore;
