import React, { Component } from "react";

import { observer } from "mobx-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import sjStore from "../../../Stores/SideJobs/SJStore";
import { FlexboxGrid, IconButton, Icon, HelpBlock } from "rsuite";
import Col2 from "../../Columns/Col2";

class MyEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      modules: {
        toolbar: [
          ["italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      },
    };
  }
  setValue(e) {
    this.setState({ value: e });
  }

  render() {
    let ind = this.props.ind;
    let label = this.props.label;
    let title = this.props.title;
    let load = this.props.load;

    let val = sjStore.esjs[ind][label];
    let org = sjStore.sjs[ind][label];

    let change = false;
    if (org !== val) change = true;

    return (
      <div>
        <FlexboxGrid>
          <Col2>
            <h4>Invoice Notes</h4>
            <HelpBlock>
              <div style={{ color: "black" }}>
                Notes to be added in the invoice section if this job is
                selected.
              </div>
              All font sizes will be reset, Bold and Colors will not show.
            </HelpBlock>
          </Col2>
          {change && (
            <Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="save" />}
                  block
                  color="green"
                  onClick={() => this.props.updateVal(label, title)}
                  loading={load}
                >
                  Submit
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  icon={<Icon icon="refresh" />}
                  block
                  onClick={() => this.props.editVal(org, label)}
                  loading={load}
                >
                  Reset
                </IconButton>
              </Col2>
            </Col2>
          )}
        </FlexboxGrid>

        <ReactQuill
          theme="snow"
          value={val}
          onChange={(e) => this.props.editVal(e, label)}
          modules={this.state.modules}
        />
        <br />
      </div>
    );
  }
}

export default observer(MyEditor);
