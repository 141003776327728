import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import AccountInfo from "../../Accounts/AccountDashboard/SubTabs/AccountInfo";
import singleAccountStore from "../../../Stores/AccountUpdated/SingleAccount/SingleAccountStore";
import userStore from "../../../Stores/UserStore";
import Div14 from "../../../Components/Dividers/Div14";

class CompanyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    singleAccountStore.getAccount(userStore.user.company._id);
  }
  render() {
    return (
      <div>
        <Div14>Company Information:</Div14>
        <AccountInfo />
      </div>
    );
  }
}

export default withRouter(observer(CompanyInformation));
