import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import Div14 from "../../../../../Components/Dividers/Div14";
import { Col, FlexboxGrid } from "rsuite";
import Col2 from "../../../../../Components/Columns/Col2";
import NumbInputSTU from "../../../../../Components/Inputs/Updatable/NumbInputSTU";

import newACCOrderStore from "../../../../../Stores/AccountUpdated/ACC/NewACCStore";
import Col3 from "../../../../../Components/Columns/Col3";
import SelectInputSTU from "../../../../../Components/Inputs/Updatable/SelectInputSTU";
import shippingmodetypes from "../../../../../Static/Lists/jobsubtypes/cclists/shippingmodetypes";
import seatypes from "../../../../../Static/Lists/jobsubtypes/imlists/seatypes";
import TextInputSTU from "../../../../../Components/Inputs/Updatable/TextInputSTU";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

class JobDetailTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let newacc = newACCOrderStore.newacc;
    let oldacc = newACCOrderStore.oldacc;
    let load = this.props.load;
    return (
      <div>
        <Div14>Job Status:</Div14>
        <SelectInputSTU
          val={newacc.status}
          org={oldacc.status}
          label="status"
          load={load.status}
          title="Method"
          size="sm"
          editVal={this.props.editVal}
          updateVal={this.props.postChanges}
          opts={["Requested", "Booked", "In Transit", "Completed", "Cancelled"]}
        />
        <Div14>Pricing:</Div14>
        <FlexboxGrid>
          <Col2>
            <NumbInputSTU
              val={newacc.lastprice}
              org={oldacc.lastprice}
              label="lastprice"
              load={load.lastprice}
              title="Job Price (KD)"
              size="sm"
              min={0}
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <NumbInputSTU
              val={newacc.cbm}
              org={oldacc.cbm}
              label="cbm"
              load={load.cbm}
              title="Total CBM"
              size="sm"
              min={0}
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col md={24} sm={24} xs={24} style={{ textAlign: "center" }}>
            {PRKD(oldacc.lastprice)} KD (Service Price) +{" "}
            {PRKD(oldacc.totaladdchg)} KD (Additional Charges){" "}
            {PRKD(oldacc.totaldiscount) !== 0 &&
              `${PRKD(oldacc.totaldiscount)} KD (Discount)`}{" "}
            = <b>{PRKD(oldacc.totalcost)} Total Job Price</b>
          </Col>
        </FlexboxGrid>
        <Div14>Shipment Details</Div14>
        <FlexboxGrid>
          <Col2>
            <SelectInputSTU
              val={newacc.shippingmode}
              org={oldacc.shippingmode}
              label="shippingmode"
              load={load.shippingmode}
              title="Method"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
              opts={shippingmodetypes}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={newacc.shippingline}
              org={oldacc.shippingline}
              label="shippingline"
              load={load.shippingline}
              title="Shipping Line"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <SelectInputSTU
              val={newacc.container}
              org={oldacc.container}
              label="container"
              load={load.container}
              title="Container Type"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
              opts={seatypes}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={newacc.commodity}
              org={oldacc.commodity}
              label="commodity"
              load={load.commodity}
              title="Commodity"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={newacc.brandsupplier}
              org={oldacc.brandsupplier}
              label="brandsupplier"
              load={load.brandsupplier}
              title="Brand / Supplier"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col2>
            <TextInputSTU
              val={newacc.originlocation}
              org={oldacc.originlocation}
              label="originlocation"
              load={load.originlocation}
              title="Origin Location"
              size="sm"
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col2>
          <Col3>
            <NumbInputSTU
              val={newacc.containercount}
              org={oldacc.containercount}
              label="containercount"
              load={load.containercount}
              title="# of Containers"
              size="sm"
              min={1}
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col3>
          <Col3>
            <NumbInputSTU
              val={newacc.packages}
              org={oldacc.packages}
              label="packages"
              load={load.packages}
              title="Packages"
              size="sm"
              min={0}
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col3>
          <Col3>
            <NumbInputSTU
              val={newacc.weight}
              org={oldacc.weight}
              label="weight"
              load={load.weight}
              title="Weight (KG)"
              size="sm"
              min={0}
              editVal={this.props.editVal}
              updateVal={this.props.postChanges}
            />
          </Col3>
        </FlexboxGrid>
      </div>
    );
  }
}

export default withRouter(observer(JobDetailTab));
