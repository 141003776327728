import { computed, makeObservable, observable } from "mobx";
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import moment from "moment";
import { Notification } from "rsuite";
import moment from "moment";
import baseURL from "../../../Static/baseURL/baseURL";
import userStore from "../../UserStore";
import singleAccountStore from "../SingleAccount/SingleAccountStore";

const instance = axios.create({ baseURL: baseURL });

class ACInventoryStore {
  constructor() {
    this.clientId = "";
    this.loading = {
      inventory: false,
      finance: false,
      order: false,
      storagehistory: false,
      itemmovement: false,
      item: false,
      items: false,
      stats: false,
    };
    this.loaded = {
      inventory: false,
      finance: false,
      order: false,
      storagehistory: false,
      itemmovement: false,
      items: false,
      stats: false,
    };
    this.inventory = [];
    this.storagehistory = [];
    this.startdate = moment().subtract(1, "month").startOf("M");
    this.enddate = moment().endOf("month");
    this.frequency = "Daily";
    this.orders = [];
    this.selecteditem = {
      name: "",
      loctags: [],
      searchtags: [],
      currentplusreserve: 0,
      _id: "Not Selected",
      acItemNumId: "",
    };
    this.moves = [];
    this.itemIn = [];
    this.itemOut = [];
    this.itemInv = [];
    this.itemmovement = [];
    this.finance = { weekly: [], daily: [] };
    this.allitems = [];
    this.selectedItemId = "";
    this.stats = {
      currentplusreserve: 0,
      onreserve: 0,
      cbm: 0,
      items: 0,
      stoin: 0,
      stoout: 0,
      delin: 0,
      delout: 0,
      cbmin: 0,
      cbmout: 0,
      qtyin: 0,
      qtyout: 0,
      barchart: [{ x: "", y: 0 }],
      dels: [],
    };

    makeObservable(this, {
      clientId: observable,
      loading: observable,
      loaded: observable,
      inventory: observable,
      orders: observable,
      selecteditem: observable,
      moves: observable,
      storagehistory: observable,
      startdate: observable,
      enddate: observable,
      frequency: observable,
      finance: observable,
      pastorders: computed,
      upcoorders: computed,
      curritems: computed,
      pastitems: computed,
      itemIn: observable,
      itemOut: observable,
      itemInv: observable,
      itemmovement: observable,
      allitems: observable,
      selectedItemId: observable,
      stats: observable,
    });
  }

  // FETCH
  getStats(override) {
    let loaded = false;
    let clientid = singleAccountStore.account._id;
    if (this.loaded.stats) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.stats = true;
      let data = { startdate: this.startdate, enddate: this.enddate };
      return instance
        .put(`/ac/storstats/storagestats/${clientid}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((stats) => {
          this.stats = stats;
          this.loaded.stats = true;
          this.loading.stats = false;
          console.log("All Items Fetched");
        })
        .catch((err) => {
          this.loaded.stats = false;
          this.loading.stats = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Stats.`,
          });
        });
    }
  }
  getExistingItems(clientid, override) {
    let loaded = false;
    if (this.loaded.items) loaded = true;
    if (override) loaded = false;
    if (!loaded) {
      this.loading.items = true;
      return instance
        .get(`/ac/itemmove/getall/${clientid}`, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((items) => {
          this.allitems = [...items];
          this.loaded.items = true;
          this.loading.items = false;
          console.log("All Items Fetched");
        })
        .catch((err) => {
          this.loaded.items = false;
          this.loading.items = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Existing Items.`,
          });
        });
    }
  }
  getItemMoveHistory(newclientid, override) {
    let loaded = false;
    let clientId = this.clientId;
    if (newclientid) {
      clientId = newclientid;
    }
    if (this.loading.itemmovement & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.itemmovement = true;
      this.loaded.itemmovement = false;
      let url = `/ac/inventory/itemmovehistory/${clientId}`;
      this.clientId = clientId;
      let data = { startdate: this.startdate };
      return instance
        .get(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.itemmovement = data;

          this.loading.itemmovement = false;
          this.loaded.itemmovement = true;

          console.log("Item Movement History Fetched");
        })
        .catch((err) => {
          this.loading.itemmovement = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Item Movement History.`,
          });
        });
    }
  }
  getStorageHistory(newclientid, override) {
    let loaded = false;
    let clientId = this.clientId;
    if (newclientid) {
      clientId = newclientid;
    }
    if (this.loading.storagehistory & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.storagehistory = true;
      this.loaded.storagehistory = false;
      let url = `/ac/inventory/invhistory/${clientId}`;
      this.clientId = clientId;
      let data = { startdate: this.startdate, frequency: this.frequency };
      return instance
        .put(url, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.storagehistory = data;

          this.loading.storagehistory = false;
          this.loaded.storagehistory = true;

          console.log("Storage History Fetched");
        })
        .catch((err) => {
          this.loading.storagehistory = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage History.`,
          });
        });
    }
  }
  getStorageFinanceHistory(newclientid, override) {
    let loaded = false;
    let clientId = this.clientId;
    if (newclientid) {
      clientId = newclientid;
    }
    if (this.loading.finance & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.finance = true;
      this.loaded.finance = false;
      let url = `/ac/invfinance/invhistory/${clientId}`;
      this.clientId = clientId;

      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.finance = data;

          this.loading.finance = false;
          this.loaded.finance = true;

          console.log("Storage Finance Fetched");
        })
        .catch((err) => {
          this.loading.finance = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Storage Finance.`,
          });
        });
    }
  }
  getInventory(clientId, override) {
    let loaded = false;
    if (this.loading.inventory & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.inventory = true;
      this.loaded.inventory = false;
      let url = `/ac/inventory/fetchitems/${clientId}`;
      this.clientId = clientId;
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.inventory = data;

          this.loading.inventory = false;
          this.loaded.inventory = true;

          console.log("Inventory Fetched");
        })
        .catch((err) => {
          this.loading.inventory = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Inventory.`,
          });
        });
    }
  }

  getOrders(clientId, override) {
    let loaded = false;
    if (this.loading.order & (this.clientId === clientId)) {
      loaded = true;
    }
    if (override) loaded = false;

    if (!loaded) {
      this.loading.order = true;
      this.loaded.order = false;
      let url = `/ac/inventory/fetchorders/${clientId}`;
      this.clientId = clientId;
      return instance
        .get(url, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => res.data)
        .then((data) => {
          this.orders = data;

          this.loading.order = false;
          this.loaded.order = true;

          console.log("Orders Fetched");
        })
        .catch((err) => {
          this.loading.order = false;
          console.log(err);

          Notification["error"]({
            title: `Error Fetching Orders.`,
          });
        });
    }
  }

  getSingleItem(newitemid, override) {
    let itemid = this.selectedItemId;
    if (newitemid) itemid = newitemid;
    this.loading.item = true;
    this.moves = [];
    let url = `/modal/item/itemhistory/${itemid}`;

    return instance
      .get(url, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => res.data)
      .then((data) => {
        this.selecteditem = data.item;
        let oldmoves = data.itemmove.filter(
          (move) => move.orderstatus === "Completed"
        );
        let newmoves = [];
        let afterstock = 0;
        for (let i = 0; i < oldmoves.length; i++) {
          if (oldmoves[i].type === "In") {
            afterstock += oldmoves[i].actualquantity;
          } else {
            afterstock -= oldmoves[i].actualquantity;
          }
          newmoves = [...newmoves, { ...oldmoves[i], afterstock: afterstock }];
        }
        this.moves = [...newmoves];

        this.itemIn = data.itemIn;
        this.itemOut = data.itemOut;
        this.itemInv = data.itemInv;

        this.loading.item = false;

        console.log("Item Details Fetched");
      })
      .catch((err) => {
        console.log(err);

        this.loading.item = false;
        Notification["error"]({
          title: `Error Fetching Item Details.`,
        });
      });
  }

  // POST

  // COMPUTATIONS:

  get curritems() {
    return this.allitems.filter(
      (item) => parseFloat(item.currentplusreserve) > 0
    );
  }
  get pastitems() {
    return this.allitems.filter(
      (item) => parseFloat(item.currentplusreserve) <= 0
    );
  }

  get pastorders() {
    return this.orders.filter((order) => order.status === "Completed");
  }
  get upcoorders() {
    return this.orders.filter(
      (order) =>
        order.status === "Booked" ||
        order.status === "Requested" ||
        order.status === "In Transit"
    );
  }

  // FUNCTIONS:
  selectItemId(itemid) {
    acInventoryStore.selectedItemId = itemid;
  }
  changeDate(date) {
    acInventoryStore.startdate = moment(date).startOf("M");
  }
  changeDateRange(date) {
    acInventoryStore.startdate = moment(date[0]).startOf("M");
    acInventoryStore.enddate = moment(date[1]).endOf("M");
  }
  changeFrequency(freq) {
    acInventoryStore.frequency = freq;
  }
  // ----- DATE ----
}

const acInventoryStore = new ACInventoryStore();
export default acInventoryStore;
