import React, { Component } from "react";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import moment from "moment";
import { Empty } from "antd";
import { Col, FlexboxGrid } from "rsuite";
import Div14 from "../../../Components/Dividers/Div14";
import whmTruckStore from "../WHMStores/WHMTruckStore";

class GanttTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.TaskListColumn = this.TaskListColumn.bind(this);
    this.TaskListHeader = this.TaskListHeader.bind(this);
  }
  TaskListColumn = ({ tasks }) => {
    return (
      <div style={{ width: "240px" }}>
        {tasks.map((item, index) => {
          return (
            <FlexboxGrid
              style={{ height: 30, textAlign: "center" }}
              key={index}
            >
              <Col xs={5}>{item.name}</Col>
              <Col xs={5}>
                {item.start ? moment(item.start).format("HH:mm") : ""}
              </Col>
              <Col xs={5}>{moment(item.end).format("HH:mm")}</Col>
              <Col
                xs={4}
                style={{
                  fontWeight: "bold",
                  color:
                    moment(item.end).diff(item.start, "minutes") > 30
                      ? "red"
                      : "green",
                }}
              >
                {item.start ? moment(item.end).diff(item.start, "minutes") : ""}
              </Col>
              <Col xs={5}>
                {index < tasks.length - 1
                  ? tasks[index + 1].start
                    ? moment(tasks[index + 1].start).diff(item.end, "minutes")
                    : ""
                  : ""}
              </Col>
            </FlexboxGrid>
          );
        })}
      </div>
    );
  };
  TaskListHeader = () => {
    return (
      <FlexboxGrid
        style={{
          height: 50,
          textAlign: "center",
          fontWeight: "bold",
          paddingTop: "10px",
        }}
      >
        <Col xs={5}>Name</Col>
        <Col xs={5}>Start</Col>
        <Col xs={5}>End</Col>
        <Col xs={4}>Min</Col>
        <Col xs={5}>DT</Col>
      </FlexboxGrid>
    );
  };
  render() {
    let data = this.props.data;

    let tasks = [];
    let oldload = [];
    let newunload = [];

    if (data.length <= 0) {
      return <Empty description="No Truck trips" />;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== "Loading Start") {
        tasks = [
          ...tasks,
          {
            key: `${data[i].whmTruckTripNumId} L`,
            start: new Date(moment(data[i].createdAt)),
            end: new Date(moment(data[i].loadingend)),
            trucktripid: data[i].whmTruckTripNumId,
            name: `${data[i].whmTruckTripNumId} L`,
            id: data[i]._id,
            isDisabled: true,
            type: "Loading",
            progress:
              moment(data[i].loadingend).diff(data[i].createdAt, "minutes") <
              whmTruckStore.targets.loading
                ? 100
                : 0,
          },
        ];

        oldload = [
          ...oldload,
          {
            key: `${data[i].whmTruckTripNumId} L`,
            start: new Date(moment(data[i].createdAt)),
            end: new Date(moment(data[i].loadingend)),
            trucktripid: data[i].whmTruckTripNumId,
            name: `${data[i].whmTruckTripNumId} L`,
            id: data[i]._id,
            isDisabled: true,
            type: "Loading",
            progress:
              moment(data[i].loadingend).diff(data[i].createdAt, "minutes") <
              whmTruckStore.targets.loading
                ? 100
                : 0,
          },
        ];
      }
      if (data[i].status === "Unloading End") {
        tasks = [
          ...tasks,
          {
            key: `${data[i].whmTruckTripNumId} UL`,
            start: new Date(moment(data[i].unloadingstart)),
            end: new Date(moment(data[i].unloadingend)),
            trucktripid: data[i].whmTruckTripNumId,
            name: `${data[i].whmTruckTripNumId} UL`,
            id: data[i]._id,
            isDisabled: true,
            type: "Unloading",
            progress:
              moment(data[i].unloadingend).diff(
                data[i].unloadingstart,
                "minutes"
              ) < whmTruckStore.targets.unloading
                ? 100
                : 0,
          },
        ];
        newunload = [
          ...newunload,
          {
            key: `${data[i].whmTruckTripNumId} UL`,
            start: new Date(moment(data[i].unloadingstart)),
            end: new Date(moment(data[i].unloadingend)),
            trucktripid: data[i].whmTruckTripNumId,
            name: `${data[i].whmTruckTripNumId} UL`,
            id: data[i]._id,
            isDisabled: true,
            type: "Unloading",
            progress:
              moment(data[i].unloadingend).diff(
                data[i].unloadingstart,
                "minutes"
              ) < whmTruckStore.targets.unloading
                ? 100
                : 0,
          },
        ];
      }
    }
    for (let i = 0; i < tasks.length; i++) {
      console.log(tasks);
      console.log(tasks[i].start);
    }
    tasks = tasks.sort((a, b) => moment(a.start) - moment(b.start));
    if (tasks.length <= 0) {
      return <Empty description="No Completed Truck trips" />;
    }

    let oldwarehousedowntime = 0;
    for (let i = 0; i < oldload.length - 1; i++) {
      if (moment(oldload[i].end).isBefore(oldload[i + 1].start)) {
        oldwarehousedowntime += moment(oldload[i + 1].start).diff(
          oldload[i].end,
          "minutes"
        );
      }
    }
    let newwarehousedowntime = 0;
    for (let i = 0; i < newunload.length - 1; i++) {
      if (moment(newunload[i].end).isBefore(newunload[i + 1].start)) {
        newwarehousedowntime += moment(newunload[i + 1].start).diff(
          newunload[i].end,
          "minutes"
        );
      }
    }

    return (
      <div>
        <Div14>All Truck Activity</Div14>
        <Gantt
          tasks={tasks}
          viewMode={ViewMode.Hour}
          rowHeight={30}
          columnWidth={100}
          barBackgroundColor="red"
          barProgressColor="green"
          // onDateChange={onTaskChange}
          // onTaskDelete={onTaskDelete}
          // onProgressChange={onProgressChange}
          // onDoubleClick={onDblClick}
          onClick={() => console.log("test")}
          TaskListTable={this.TaskListColumn}
          TaskListHeader={this.TaskListHeader}
        />
        <Div14>Old Warehouse Loading</Div14>
        <h6 style={{ textAlign: "center", fontStyle: "italic" }}>
          Downtime = {oldwarehousedowntime} minutes
        </h6>
        <Gantt
          tasks={oldload}
          viewMode={ViewMode.Hour}
          rowHeight={30}
          columnWidth={100}
          barBackgroundColor="red"
          barProgressColor="green"
          // onDateChange={onTaskChange}
          // onTaskDelete={onTaskDelete}
          // onProgressChange={onProgressChange}
          // onDoubleClick={onDblClick}
          onClick={() => console.log("test")}
          TaskListTable={this.TaskListColumn}
          TaskListHeader={this.TaskListHeader}
        />
        <Div14>New Warehouse Unloading</Div14>

        <h6 style={{ textAlign: "center", fontStyle: "italic" }}>
          Downtime = {newwarehousedowntime} minutes
        </h6>
        <Gantt
          tasks={newunload}
          viewMode={ViewMode.Hour}
          rowHeight={30}
          columnWidth={100}
          barBackgroundColor="red"
          barProgressColor="green"
          // onDateChange={onTaskChange}
          // onTaskDelete={onTaskDelete}
          // onProgressChange={onProgressChange}
          // onDoubleClick={onDblClick}
          onClick={() => console.log("test")}
          TaskListTable={this.TaskListColumn}
          TaskListHeader={this.TaskListHeader}
        />
      </div>
    );
  }
}

export default withRouter(observer(GanttTrial));
