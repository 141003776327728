import React, { Component } from "react";
import axios from "axios";

import { withRouter } from "react-router";
import { observer } from "mobx-react";
import singleStaffHRStore from "../../../../../Stores/HR/SingleStaffHRStore";
import moment from "moment";
import LoadBack from "../../../../../Components/Loaders/LoadBack";
import MaterialTable from "material-table";
import Div14 from "../../../../../Components/Dividers/Div14";
import { FlexboxGrid, Icon, IconButton, Notification } from "rsuite";
import Col3 from "../../../../../Components/Columns/Col3";
import UnifiedModal from "../../../../../Components/Modal/UnifiedModal";
import Col2 from "../../../../../Components/Columns/Col2";
import DatePickerSTD from "../../../../../Components/Inputs/STD/DatePickerSTD";
import SelectInputSTD from "../../../../../Components/Inputs/STD/SelectInputSTD";
import banklist from "../../../../../Static/Lists/financial/banklist";
import departmentcustomer from "../../../../../Static/Lists/financial/departmentcustomer";
import TextInputSTD from "../../../../../Components/Inputs/STD/TextInputSTD";
import NumbInputSTD from "../../../../../Components/Inputs/STD/NumbInputSTD";
import TextAreaInputSTD from "../../../../../Components/Inputs/STD/TextAreaInputSTD";
import baseURL from "../../../../../Static/baseURL/baseURL";
import userStore from "../../../../../Stores/UserStore";
import ReactApexChart from "react-apexcharts";
import { PRKD } from "../../../../../Functions/Pricing/PRKDCalc";

const instance = axios.create({ baseURL: baseURL });
class StaffPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      showdelete: false,
      type: "New",
      payment: {
        type: "Expense Fixed",
        subtype: "Staff Related",
        specific: "Salary",
        cash: "Cash",
        date: moment(),
        salarymonth: moment().subtract(1, "month").startOf("month"),
        method: "Bank Transfer",
        account: "Boubyan",
        amount: 0,
        hrstaff: "",
        paymentref: "",
        accounttype: "General",
        partyname: "",
        remarks: "",
        _id: "",
      },
    };
    this.onHide = this.onHide.bind(this);
    this.editVal = this.editVal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postDelete = this.postDelete.bind(this);
  }

  postNew = async () => {
    this.setState({ loading: true });
    let pay = this.state.payment;
    let data = {
      type: pay.type,
      subtype: pay.subtype,
      specific: pay.specific,
      cash: pay.cash,
      date: pay.date,
      salarymonth: pay.salarymonth,
      method: pay.method,
      account: pay.account,
      accounttype: pay.accounttype,
      amount: pay.amount,
      paymentref: pay.paymentref,
      partyname: pay.partyname,
      remarks: pay.remarks,
      hrstaff: singleStaffHRStore.singlestaff._id,
    };

    if (this.state.type === "New") {
      return instance
        .post(`/jf/payment/new`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          singleStaffHRStore.getPayments(true);
          this.setState({ loading: false });
          Notification["success"]({
            title: `New Staff Payment Created.`,
            description: <div>{data.amount} KD</div>,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    } else {
      return instance
        .put(`/jf/payment/edit/${this.state.payment._id}`, data, {
          headers: { authtoken: userStore.token },
        })
        .then((res) => {
          singleStaffHRStore.getPayments(true);
          this.setState({ loading: false });
          Notification["success"]({
            title: `Staff Payment Edited.`,
            description: <div>{data.amount} KD</div>,
          });
          this.onHide();
        })
        .catch((err) => {
          console.log(err);
          Notification["error"]({
            title: `An Error Occured.`,
          });
          this.setState({ loading: false });
        });
    }
  };

  postDelete = async () => {
    this.setState({ loading: true });
    return instance
      .put(`/jf/payment/deletebyid/${this.state.payment._id}`, {
        headers: { authtoken: userStore.token },
      })
      .then((res) => {
        singleStaffHRStore.getPayments(true);
        this.setState({ loading: false });
        Notification["success"]({
          title: `Staff Payment Deleted.`,
        });
        this.onHide();
      })
      .catch((err) => {
        console.log(err);
        Notification["error"]({
          title: `An Error Occured.`,
        });
        this.setState({ loading: false });
      });
  };

  editVal(val, label) {
    if (label === "salarymonth") val = moment(val).startOf("month");
    let payment = { ...this.state.payment };
    payment[label] = val;
    this.setState({ payment: payment });
  }

  onHide() {
    let payment = {
      type: "Expense Fixed",
      subtype: "Staff Related",
      specific: "Salary",
      cash: "Cash",
      date: moment(),
      salarymonth: moment().subtract(1, "month").startOf("month"),
      method: "Bank Transfer",
      account: "Boubyan",
      accounttype: "General",
      partyname: "",
      amount: 0,
      hrstaff: "",
      paymentref: "",
      remarks: "",
      _id: "",
    };
    this.setState({
      show: false,
      showdelete: false,
      loading: false,
      payment: payment,
      type: "New",
    });
  }

  componentDidMount() {
    singleStaffHRStore.getPayments();
  }
  render() {
    let options = {
      chart: {
        type: "column",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            zoom: false,
            selection: false,
            reset: false,
            pan: false,
          },
        },
        stacked: true,
      },
      markers: {
        size: 10,
        style: "hollow",
      },
      xaxis: {
        //type: "datetime",
        tickAmount: 6,
        categories: singleStaffHRStore.paymentGraph.months,
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return PRKD(val);
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: "Payments",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      tooltip: {
        x: {
          format: "MMM yyyy",
        },
      },
      colors: ["green", "#96ffaa", "#34e8eb"],
      dropShadow: { enabled: true, top: 0, left: 0, blur: 3, opacity: 0.5 },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.1,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: [undefined, "black", "black"],
        width: 0.5,
        dashArray: 0,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        formatter: function (val, opts) {
          if (val > 0) {
            return `${PRKD(val)} KD`;
          } else {
            return "";
          }
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    };
    let seriesValue = [
      {
        data: singleStaffHRStore.paymentGraph.salary,
        name: "Salary",
        type: "column",
      },
      {
        data: singleStaffHRStore.paymentGraph.bonus,
        name: "Bonus",
        type: "column",
      },
      {
        data: singleStaffHRStore.paymentGraph.add,
        name: "Additional",
        type: "column",
      },
    ];

    let columnnames = [
      { title: "id", field: "paymentNumId" },
      {
        title: "Date",
        field: "date",
        defaultSort: "desc",
        render: (row) => moment(row.date).format("DD-MMM-YY"),
      },
      {
        title: "Salary Month",
        field: "salarymonth",
        defaultSort: "desc",
        render: (row) => moment(row.salarymonth).format("MMM-YY"),
      },
      { title: "Specific", field: "specific" },
      { title: "Type", field: "type" },
      { title: "Sub Type", field: "subtype" },
      { title: "Department", field: "accounttype" },
      { title: "Method", field: "method" },
      { title: "Account", field: "account" },
      { title: "Party", field: "partyname" },
      { title: "Pay. Ref", field: "paymentref" },
      { title: "Remarks", field: "remarks" },
      { title: "Amount", field: "amount" },
    ];
    let disable = this.state.payment.amount <= 0;
    let type = this.state.type;
    let payment = this.state.payment;

    return (
      <LoadBack loading={singleStaffHRStore.loading.payments}>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.show}
          title={`${type === "New" ? "New " : "Edit"} ${
            payment.specific
          } Staff Payment`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  Cancel
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="save" />}
                  onClick={() => {
                    this.postNew();
                  }}
                >
                  {type === "New" ? "Create New " : "Save Edited "}{" "}
                  {payment.specific} Payment
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid style={{ padding: "1%" }}>
            <Col2>
              <DatePickerSTD
                title="Payment Date"
                val={payment.date}
                label={"date"}
                size="large"
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <DatePickerSTD
                title="Salary Month"
                format={"MMM-YY"}
                picker="month"
                val={payment.salarymonth}
                label={"salarymonth"}
                size="large"
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Method"
                val={payment.method}
                label="method"
                size="large"
                opts={[
                  "Bank Transfer",
                  "Cash",
                  "KNET",
                  "Cheque",
                  "Online Link",
                ]}
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Account"
                val={payment.account}
                label="account"
                size="large"
                opts={banklist}
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <SelectInputSTD
                title="Department"
                val={payment.accounttype}
                label="accounttype"
                size="large"
                opts={departmentcustomer}
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Party Name."
                val={payment.partyname}
                label={"partyname"}
                size="large"
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <NumbInputSTD
                title="Amount (KD)"
                val={payment.amount}
                label={"amount"}
                size="large"
                editVal={this.editVal}
              />
              <br />
            </Col2>
            <Col2>
              <TextInputSTD
                title="Payment Ref."
                val={payment.paymentref}
                label={"paymentref"}
                size="large"
                editVal={this.editVal}
              />
              <br />
            </Col2>
          </FlexboxGrid>
          <FlexboxGrid style={{ padding: "1%" }} align="top">
            <TextAreaInputSTD
              title="Remarks"
              val={payment.remarks}
              label={"remarks"}
              size="large"
              editVal={this.editVal}
            />
          </FlexboxGrid>
        </UnifiedModal>
        <UnifiedModal
          onHide={this.onHide}
          show={this.state.showdelete}
          title={`Delete Staff Payment`}
          footer={
            <FlexboxGrid>
              <Col2>
                <IconButton
                  block
                  loading={this.state.loading}
                  color="green"
                  icon={<Icon icon="close" />}
                  onClick={() => this.onHide()}
                >
                  No, Keep
                </IconButton>
              </Col2>
              <Col2>
                <IconButton
                  disabled={disable}
                  block
                  loading={this.state.loading}
                  color="red"
                  icon={<Icon icon="trash" />}
                  onClick={() => {
                    this.postDelete();
                  }}
                >
                  Yes, Delete
                </IconButton>
              </Col2>
            </FlexboxGrid>
          }
        >
          <FlexboxGrid style={{ padding: "1%" }}>
            <Col3>
              <b>Date:</b> {moment(payment.date).format("DD-MMM-YY")}
              <br />
              <b>Salary Month:</b>{" "}
              {moment(payment.salarymonth).format("MMM-YY")}
              <br />
              <b>Specific:</b> {payment.specific}
              <br />
              <b>Amount:</b> {payment.amount} KD
              <br />
            </Col3>
            <Col3>
              <b>Method:</b> {payment.method}
              <br />
              <b>Account:</b> {payment.account}
              <br />
              <b>Department:</b> {payment.accounttype}
              <br />
              <b>Type:</b> {payment.type}
              <br />
            </Col3>
            <Col3>
              <b>Sub Type:</b> {payment.subtype}
              <br />
              <b>Party Name:</b> {payment.partyname}
              <br />
              <b>Pay. Ref:</b> {payment.paymentref}
              <br />
              <b>Remarks:</b> {payment.remarks}
              <br />
            </Col3>
          </FlexboxGrid>
          <hr />
          <h3 style={{ color: "red", fontWeight: "bold" }}>
            Are you sure you want to Delete this payment?
          </h3>
        </UnifiedModal>

        <Div14>Single Staff payments</Div14>
        <ReactApexChart
          options={options}
          series={seriesValue}
          type="area"
          height={200}
        />
        <FlexboxGrid>
          <Col3>
            <IconButton
              size="sm"
              block
              color="green"
              icon={<Icon icon="plus" />}
              onClick={() => this.setState({ show: true, type: "New" })}
            >
              Add Salary
            </IconButton>
          </Col3>
          <Col3>
            <IconButton
              size="sm"
              block
              color="green"
              icon={<Icon icon="plus" />}
              onClick={() => {
                let payment = { ...this.state.payment };
                payment.type = "Expense Variable";
                payment.specific = "Bonus";
                this.setState({ payment: payment, show: true, type: "New" });
              }}
            >
              Bonus
            </IconButton>
          </Col3>
          <Col3>
            <IconButton
              size="sm"
              block
              color="green"
              icon={<Icon icon="plus" />}
              onClick={() => {
                let payment = { ...this.state.payment };
                payment.type = "Expense Variable";
                payment.specific = "Additional";
                this.setState({ payment: payment, show: true, type: "New" });
              }}
            >
              Additional
            </IconButton>
          </Col3>
        </FlexboxGrid>
        <MaterialTable
          title={singleStaffHRStore.payments.length + " Payments"}
          columns={columnnames}
          style={{ fontSize: 12 }}
          data={singleStaffHRStore.payments}
          onRowClick={(event, rowData) => {
            this.setState({ ind: rowData.tableData.id, single: true });
          }}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: `${
              this.props.exportTitle
                ? this.props.exportTitle
                : "Staff Salary List"
            } as of ${moment(new Date()).format("DD-MMM-YY")}`,
            padding: "dense",
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.setState({ payment: rowData, type: "Edit", show: true });
              },
            },
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                this.setState({
                  payment: rowData,
                  type: "Delete",
                  showdelete: true,
                });
              },
            },

            {
              icon: "refresh",
              isFreeAction: true,
              tooltip: "Refresh",
              onClick: (event, rowData) => {
                singleStaffHRStore.getPayments(true);
              },
            },
          ]}
        />
      </LoadBack>
    );
  }
}

export default withRouter(observer(StaffPayments));
