import moment from "moment";

function JDQualityCalc(data) {
  let errors = 0;
  let total = 0;
  let items = [];

  let dates = data.jobdate;
  let pastOpen = dates.filter(
    (date) => (moment(date.dateto) < moment()) & (date.status === "Pending")
  );
  for (let i = 0; i < pastOpen.length; i++) {
    items.push(
      `Pending ${pastOpen[i].type}: Past Due ${moment(
        pastOpen[i].dateto
      ).fromNow()}`
    );
  }

  errors = items.length;
  total = dates.length;
  if (errors > total) total = errors;

  return [errors, total, items];
}

export { JDQualityCalc };
